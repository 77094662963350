<template>
    <v-checkbox v-bind="$attrs" v-on="$listeners" :id="computedID" :label="translatedLabel" v-model="getModel"></v-checkbox>
</template>

<script>
export default {
    name: "av-checkbox",
    props: {
        translateLabel: {required: false, default: true},
        dynamicID: { required: false, default: '' },
        model: { required: true }
    },
    data() {
        return {
        }
    },
    computed: {
        computedID() {
            if (!this.$isEmpty(this.$attrs.id)) {
                return this.$attrs.id
            }
            let dynamicPageName = this.$router.history.current.params.routeId ? this.$router.history.current.params.routeId : "";
            return this.toCamelCase(this.$router.history.current.name + dynamicPageName + this.dynamicID + 'Checkbox' + this.$attrs.label.trim())
        },
        translatedLabel() {
            return this.$attrs.label
        },
        getModel: {
            get: function () {
                return this.model
            },
            set: function(value) {
                this.$emit('modelUpdate', value)
            }
        }
    },
    mounted() {
    },
    methods: {},
}
</script>

<style scoped>

</style>