<template>
    <DynamicElementBase>
        <v-layout v-if="shiftRunning" row fill-height>
            <v-flex xs6>
                <v-layout column fill-height mx-2>
                    <label class="title"><translate>From:</translate> {{shiftFrom}} <translate>to:</translate> {{shiftTo}}</label>
                    <label class="subheading mt-2 font-weight-regular" ><translate>Completed:</translate><span class="font-weight-medium">{{" " + shiftPercent}}%</span></label>
                    <div style="height: 85% ; width: 85%; margin:auto">
                        <WidgetTimeSpanClock :timeStart="shiftFrom" :timeEnd="shiftTo"/>
                    </div>
                    <!--<v-btn v-if="$grants.get().operations.shiftChange" @click="openShiftChangeDialog" color="info"><av-icon left light>fas fa-exchange-alt</av-icon><translate>Change shift</translate></v-btn>-->
                </v-layout>
            </v-flex>
            <v-flex xs6>
                <v-layout column px-2>
                    <label v-for="manager in managers" style="margin-bottom: 10px" class="subheading font-weight-regular"><span style="color: var(--av-blue)">{{manager.title}}</span>: <span class="font-weight-medium">{{manager.name}}</span></label>
                    <label v-for="value in values" style="margin-bottom: 10px" class="subheading font-weight-regular">{{value.key}}: <span class="font-weight-medium">{{value.value}}</span></label>
                </v-layout>
            </v-flex>
        </v-layout>
        <label v-else class="title ma-1" v-translate>No shift running</label>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
    import WidgetTimeSpanClock from './WidgetTimeSpanClock'
    import Shifts from "@/api/shifts"

    export default {
        name: 'WidgetShifts',
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            WidgetTimeSpanClock
        },
        data() {
            return {
                shiftFrom: "",
                shiftTo: "",
                managers: [],
                headers: [],
                shift: {},
                blink: false,
                firstRun: true,
                timerRefresh: null,
                collectionRefresh: null,
                shiftPercent: "",
                currentShiftIndex: 0,
                shiftRunning: false,
                values: []
            }
        },
        props: {},
        mounted: function () {
            let self = this;
            this.properties.showTimeWindow = false;
            setTimeout(() => { self.properties.timeWindow.setPredefined("shift") }, 1000);
            this.timerRefresh = setInterval(() => { self.refresh() }, 60000);
            this.refresh();
        },
        methods: {
            openShiftChangeDialog() {
                Shifts.openShiftChangeDialog(this, this.$root.userName, this.shiftChangeCallBack);
            },
            refresh() {
                let self = this;
                Shifts.getCurrentShift()
                    .then(currentShift => {
                        if (!currentShift)
                            self.shiftRunning = false;
                        else {
                            self.shiftRunning = true;
                            self.shiftFrom = currentShift.from;
                            self.shiftTo = currentShift.to;
                            self.shiftPercent = Math.round(currentShift.progress);
                            self.managers.clear();
                            for (let i = 0; i < currentShift.variables.length; i++) {
                                self.managers.push({title: currentShift.variables[i].key, name: currentShift.variables[i].value})
                            }
                        }
                    })
            },
            refreshData(dataValues) {
                this.values.clear();
                // //Adjust mobile height on content
                // this.fixedHeight = 60 * dataValues.length;
                //Unwrap data and place each value at correct place
                dataValues.forEach(dataSet => {
                    if (dataSet) {
                        if (Array.isUseful(dataSet.data))
                            this.values.push({
                                key: dataSet.label,
                                value: dataSet.data.last().y
                            });
                        else
                            this.values.push({key: dataSet.label, value: ""});
                    }
                });
            }
        }
    }
</script>

<style scoped>


</style>