<template>
    <v-layout column ma-2 pl-4 align-start justify-start>
        <template v-for="variable in variables">
            <template v-if="showNode(variable)">
                <v-layout row my-0>
                    <v-layout column ma-0>
                        <label class="title text-xs-left" style="font-weight: normal">
                            {{ variable.title }}: <span v-if="variable.type !== 'Files' && showValue(variable)" style="font-weight: bold">{{ variable.value }}</span>
                            <template v-if="showValue(variable)">
                                <av-icon class="ml-2" v-if="variable.valid" color="green">fa-check</av-icon>
                                <av-icon class="ml-2" v-else color="red">fa-times</av-icon>
                            </template>
                            <template v-if="variable.type === 'Files'">
                                <v-container class="pa-0">
                                    <v-layout row align-left class="pa-0">
                                        <template v-for="file in variable.value">
                                            <av-file-viewer :file-name="file.FileName" :type="file.Type"
                                                            :file="file.Value" :mediaId="file.MediaId"
                                                            :small="true" :vertical="true" class="pa-0">
                                            </av-file-viewer>
                                        </template>
                                    </v-layout>
                                </v-container>
                            </template>
                            <template v-if="variable.type ==='repeatableGroup'">
                                <div style="height: 16px"></div>
                                <v-layout ml-3 row v-for="repeatedChild in variable.repeatableChildren">
                                    <av-icon small style="margin-top: -16px">fas fa-circle</av-icon>
                                    <variables-viewer :variables="repeatedChild" @showPicture="openPicture($event)"/>
                                </v-layout>
                            </template>
                        </label>
                        <br>
                        <div>{{ variable.validationMessage }}</div>
                    </v-layout>
                    <img style="height:100px; margin-top: -10px; margin-left: 24px" v-if="variable.evidence" :src="variable.evidence" @click="openPicture(variable.evidence)"/>
                </v-layout>
                <variables-viewer v-if="variable.type !=='repeatableGroup' && Array.isUseful(variable.children)" :variables="variable.children" @showPicture="openPicture($event)"/>
            </template>
        </template>
    </v-layout>
</template>

<script>

    import AvFileViewer from "@/components/av-components/av-file-viewer";

    export default {
        name: "VariablesViewer",
        components: {AvFileViewer},
        props: {
            variables: {
                required: true,
                default: () => {
                    return []
                }
            }
        },
        computed: {
            showNode() {
                return variable => {
                    return !(variable.type === 'Boolean' && Array.isUseful(variable.children)) || variable.value;
                }
            },
            showValue() {
                return variable => {
                    return !(variable.type === 'Boolean' && Array.isUseful(variable.children)) && variable.type !== "repeatableGroup";
                }
            }
        },
        methods: {
            openPicture(pic) {
                this.$emit('showPicture', pic)
            }
        }
    }

</script>

<style scoped>

</style>