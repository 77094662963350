
import Defines from './defines'
import Types from './defines'
import Utils from './jsutils'
import VueInstance from './vueinstance'

function error(oee, error) {
    oee.errors.push(error);
    return oee;
}

function warning(oee, warning) {
    oee.warnings.push(warning);
    return oee;
}

export default {

    targets: { uptime: 100, totalItems: 101, rejectedItems: 102, itemsToProduce: 103, scheduledTime: 104, availability: 105, efficiency: 106, quality: 107, total: 108, maxSpeed: 109, averageSpeed: 110  },
    errors: { NoError: 0, UnknownRecipe: 1, NoData: 2 },
    warnings: { NoWarning: 0, Inactive: 1, Overproducing: 2, UnderEstimatedRecipe: 3 },

    translateError(error) {
        if(!error)
            return "";
        let errors = error.split("|");
        let returning = [];
        for(let error of errors) {
            switch (error) {
                case "NoWarning":
                case "NoError":
                case "NoPerformance":
                    break; //Not shown to user, just to indicate an invalid performance value
                case "Inactive":
                    returning.push(VueInstance.get().$gettext("Line not scheduled for production")); break;
                case "Overproducing":
                    returning.push(VueInstance.get().$gettext("Line produced more than scheduled")); break;
                case "OverSpeed":
                    returning.push(VueInstance.get().$gettext("Configured recipe nominal speed is low")); break;
                case "UnknownRecipe":
                    returning.push(VueInstance.get().$gettext("Recipe is not configured")); break;
                case "NoData":
                    returning.push(VueInstance.get().$gettext("Data are wrong or missing, check configuration")); break;
                case "MissingData":
                    returning.push(VueInstance.get().$gettext("Data from one or more production lines are missing have been discarded")); break;
                default:
                    if (error.startsWith("MissingData") && error.indexOf(".")>0){
                        let line= error.split(".")[1];
                        returning.push(VueInstance.get().$gettext("Data from {0} are missing and have been discarded").format(line));
                        break;
                    }
            }
        }
        return returning.join(", ");
    },

    isIndicatorValid(error, warning) {
        let errors = [];
        let warnings = [];
        if(error)
            errors = error.split("|");
        if(warning)
            warnings = warning.split("|");
        return (!errors.includes("UnknownRecipe") && !errors.includes("NoData")
            && !warnings.includes("Inactive") && !warnings.includes("NoPerformance"));
    },

    //New data pattern generation based on backend oee calculation
    getDataDescriptor(devices, getAdvancedIndicators, additionalFilters = []) {

        let returning = { filters: [], data: [], aggregations: []};

        //Common index-less filters
        returning.filters = [];

        if(devices.length > 1) {
            returning.aggregations.push(
                {
                    type: Defines.allAggregations.avg.id,
                    buckets: [],
                    target: this.targets.availability,
                    aggregationWindow: 0,
                    defaultName: 'Availability',
                    name: 'Availability',
                    enabled: true
                },
                {
                    type: Defines.allAggregations.avg.id,
                    buckets: [],
                    target: this.targets.efficiency,
                    aggregationWindow: 0,
                    defaultName: 'Efficiency',
                    name: 'Efficiency',
                    enabled: true
                },
                {
                    type: Defines.allAggregations.avg.id,
                    buckets: [],
                    target: this.targets.quality,
                    aggregationWindow: 0,
                    defaultName: 'Quality',
                    name: 'Quality',
                    enabled: true
                },
                {
                    type: Defines.allAggregations.avg.id,
                    buckets: [],
                    target: this.targets.total,
                    aggregationWindow: 0,
                    defaultName: 'Total',
                    name: 'Total',
                    enabled: true
                }
            );
            if(getAdvancedIndicators) {
                returning.aggregations.push(
                    {
                        type: Defines.allAggregations.avg.id,
                        buckets: [],
                        target: this.targets.maxSpeed,
                        aggregationWindow: 0,
                        defaultName: 'Max Speed',
                        name: 'Max Speed',
                        enabled: true
                    },
                    {
                        type: Defines.allAggregations.avg.id,
                        buckets: [],
                        target: this.targets.averageSpeed,
                        aggregationWindow: 0,
                        defaultName: 'Average Speed',
                        name: 'Average Speed',
                        enabled: true
                    }
                )
            }
        }

        for(let devIdx = 0 ; devIdx < devices.length ; devIdx++) {

            let device = devices[devIdx].device;
            let index = devices[devIdx].dataIndex;
            let namePrefix = devices.length > 1 ? device + " " : "";

            if(!index.startsWith("OEE_"))
                index = "OEE_" + index;

            returning.data.push(
                {
                    index: index,
                    root: device,
                    name: 'Availability',
                    type: Types.WellKnownTypes.OEEComponent.id,
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: Defines.allAggregations.oeeraw.id,
                            filters: [],
                            target: this.targets.availability,
                            show: devices.length === 1,
                            aggregationWindow: 0,
                            defaultName: namePrefix + 'Availability',
                            name: namePrefix + 'Availability',
                            id: "availability_" + device + "_" + index, //Create a unique id that survives changes
                            enabled: true
                        },
                    ],
                },
                {
                    index: index,
                    root: device,
                    name: 'Efficiency',
                    type: Types.WellKnownTypes.OEEComponent.id,
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: Defines.allAggregations.oeeraw.id,
                            filters: [],
                            target: this.targets.efficiency,
                            show: devices.length === 1,
                            aggregationWindow: 0,
                            defaultName: namePrefix + 'Efficiency',
                            name: namePrefix + 'Efficiency',
                            id: "efficiency_" + device + "_" + index, //Create a unique id that survives changes
                            enabled: true
                        },
                    ],
                },
                {
                    index: index,
                    root: device,
                    name: 'Quality',
                    type: Types.WellKnownTypes.OEEComponent.id,
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: Defines.allAggregations.oeeraw.id,
                            filters: [],
                            target: this.targets.quality,
                            show: devices.length === 1,
                            aggregationWindow: 0,
                            defaultName: namePrefix + 'Quality',
                            name: namePrefix + 'Quality',
                            id: "quality_" + device + "_" + index, //Create a unique id that survives changes
                            enabled: true
                        },
                    ],
                },
                {
                    index: index,
                    root: device,
                    name: 'Total',
                    type: Types.WellKnownTypes.OEEComponent.id,
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: Defines.allAggregations.oeeraw.id,
                            filters: [],
                            target: this.targets.total,
                            show: devices.length === 1,
                            aggregationWindow: 0,
                            defaultName: namePrefix + 'Total',
                            name: namePrefix + 'Total',
                            id: "total_" + device + "_" + index, //Create a unique id that survives changes
                            enabled: true
                        },
                    ],
                }
            );

            if(getAdvancedIndicators) {
                returning.data.push(
                    {
                        index: index,
                        root: device,
                        name: 'MaxSpeed',
                        type: Types.WellKnownTypes.OEEComponent.id,
                        selectedForVisualization: true,
                        representations: [
                            {
                                type: Defines.allAggregations.oeeraw.id,
                                filters: [],
                                target: this.targets.maxSpeed,
                                show: devices.length === 1,
                                aggregationWindow: 0,
                                defaultName: namePrefix + 'MaxSpeed',
                                name: namePrefix + 'MaxSpeed',
                                id: "maxspeed_" + device + "_" + index, //Create a unique id that survives changes
                                enabled: true
                            },
                        ],
                    },
                    {
                        index: index,
                        root: device,
                        name: 'AverageSpeed',
                        type: Types.WellKnownTypes.OEEComponent.id,
                        selectedForVisualization: true,
                        representations: [
                            {
                                type: Defines.allAggregations.oeeraw.id,
                                filters: [],
                                target: this.targets.averageSpeed,
                                show: devices.length === 1,
                                aggregationWindow: 0,
                                defaultName: namePrefix + 'AverageSpeed',
                                name: namePrefix + 'AverageSpeed',
                                id: "averagespeed_" + device + "_" + index, //Create a unique id that survives changes
                                enabled: true
                            },
                        ],
                    }
                )
            }

            if(devices.length > 1) {
                returning.aggregations[0].buckets.push("availability_" + device + "_" + index);
                returning.aggregations[1].buckets.push("efficiency_" + device + "_" + index);
                returning.aggregations[2].buckets.push("quality_" + device + "_" + index);
                returning.aggregations[3].buckets.push("total_" + device + "_" + index);
                if(getAdvancedIndicators) {
                    returning.aggregations[4].buckets.push("maxspeed_" + device + "_" + index);
                    returning.aggregations[5].buckets.push("averagespeed_" + device + "_" + index);
                }
            }

            // if(Array.isUseful(additionalFilters)) {
            //     if(additionalFilters.includes(this.filters.Workorder)) {
            //         returning.filters[this.filters.Workorder].filters.push({
            //             conditions: [{operator: '=', value: '@CurrentWorkOrder'}],
            //             defaultName: "Select current workorder",
            //             name: "Select current workorder",
            //             enabled: true,
            //             filterId: "SelectCurrentWorkorder" //Create a unique id that survives changes
            //         });
            //
            //         for(let item of returning.data)
            //             item.representations[0].filters.push(returning.filters[this.filters.Workorder].filters[0].filterId);
            //     }
            //
            //     if(additionalFilters.includes(this.filters.Recipe)) {
            //         returning.filters[this.filters.Recipe].filters.push({
            //             conditions: [{operator: '=', value: '@CurrentRecipe'}],
            //             defaultName: "Select current Recipe",
            //             name: "Select current Recipe",
            //             enabled: true,
            //             filterId: Md5.md5(new Date() + "Select current Recipe"/* + device*/) //Create a unique id that survives changes
            //         });
            //
            //         for(let item of returning.data)
            //             item.representations[0].filters.push(returning.filters[this.filters.Recipe].filters[0].filterId);
            //     }
            // }
        }

        return returning;

    },

    calculate(dataValues) {

        let validData = false;
        let oee = {
            availability: 0,
            efficiency: 0,
            quality: 0,
            total: 0,
            rejects: 0,
            maxSpeed: 0,
            averageSpeed: 0,
            errors: [],
            warnings: []
        };

        for(const dataSet of dataValues) {
            if (dataSet && dataSet.data && dataSet.data.length) {
                validData = true;
                if (dataSet.target === this.targets.availability)
                    oee.availability = Utils.roundToDigits(dataSet.data.last().y, 1);
                else if (dataSet.target === this.targets.efficiency)
                    oee.efficiency = Utils.roundToDigits(dataSet.data.last().y, 1);
                else if (dataSet.target === this.targets.quality)
                    oee.quality = Utils.roundToDigits(dataSet.data.last().y, 1);
                else if (dataSet.target === this.targets.total)
                    oee.total = Utils.roundToDigits(dataSet.data.last().y, 2);
                else if (dataSet.target === this.targets.maxSpeed)
                    oee.maxSpeed = dataSet.data.last().y;
                else if (dataSet.target === this.targets.averageSpeed)
                    oee.averageSpeed = dataSet.data.last().y;
                if(Object.isUseful(dataSet.data.last().error) && dataSet.data.last().error !== "NoError")
                    oee.errors.push(dataSet.data.last().error);
                if(Object.isUseful(dataSet.data.last().warning) && dataSet.data.last().warning !== "NoWarning")
                    oee.warnings.push(dataSet.data.last().warning);
            }
        }

        if(!validData) {
            oee.errors.push("No data available");
        }

        return oee;
    },

    calculate_old(dataValues, indexResolutionInSeconds, timeWindowInSeconds) {

        let timeMultiplier = 60 / indexResolutionInSeconds;

        let oee = { availability: 0, efficiency: 0, quality: 0, total: 0, rejects: 0, maxSpeed: 0, averageSpeed: 0, error: this.errors.NoError, warning: this.warnings.NoWarning };

        if(dataValues.length === 0)
            return error(oee, this.errors.NoData);

        let targets = [ this.targets.uptime, this.targets.totalItems, this.targets.rejectedItems, this.targets.itemsToProduce, this.targets.scheduledTime ];

        //Create data structure to hold components values
        let data = {};
        targets.forEach(target => {
            data[target] = -1;
        });

        //Process sum aggregations and place in correct data value
        for(let target = this.targets.uptime ; target <= this.targets.scheduledTime ; target++) {
            if(dataValues[target].data.length > 0) {
                data[target] = 0;
                for(let item = 0 ; item < dataValues[target].data.length ; item++)
                    if(target !== this.targets.totalItems && target !== this.targets.rejectedItems)
                        data[target] += (dataValues[target].data[item].y / timeMultiplier);
                    else
                        data[target] += (dataValues[target].data[item].y);
            }
            else {
                //TODO manage incomplete data
                data[target] = 0;
            }
        }

        //Process total items to produce
        if(data[this.targets.itemsToProduce] === 0)
            return error(oee, this.errors.NoData);

        if(data[this.targets.itemsToProduce] < 0)
            return error(oee, this.errors.UnknownRecipe);

        // let uptimeRatio = data[this.targets.uptime] / (timeWindowInSeconds / 60);
        // data[this.itemsToProduce] = data[this.itemsToProduce] * uptimeRatio;

        //TODO find and report missing recipe
        // let unknownRecipes = [];
        // //TODO check if we received multiple aggregation time frames, even though we should never receive
        // if(dataValues[this.targets.itemsToProduce].data.length > 0) {
        //     dataValues[this.targets.itemsToProduce].data[0].y.forEach(item => {
        //         let recipeFound = false;
        //         settings.lineSpeeds.forEach(lineSpeed => {
        //             if (lineSpeed.key.toLowerCase() === item.key.toLowerCase()) {
        //                 if (data[this.targets.itemsToProduce] === -1)
        //                     data[this.targets.itemsToProduce] = 0;
        //                 data[this.targets.itemsToProduce] += (item.doc_count * lineSpeed.value / timeMultiplier);
        //                 recipeFound = true;
        //             }
        //         });
        //         if (!recipeFound)
        //             unknownRecipes.push(item.key);
        //     });
        // }

        // if(unknownRecipes.length > 0)
        //     return { error: this.errors.UnknownRecipe, unknownRecipes: unknownRecipes };

        //Calculate availability
        //We need the total expected working time within window
        let scheduledUpTime = data[this.targets.scheduledTime];//this.calculateScheduledUpTime(timeWindowInSeconds);

        //Window is within a non working time frame, TODO verify correct behaviour in this case 100% or disabled
        if(scheduledUpTime === 0) {
            oee.availability = 100;
            oee = warning(oee, this.warnings.Inactive);
        }
        else
            oee.availability = Math.round((data[this.targets.uptime] / scheduledUpTime) * 100);

        if(oee.availability > 100) {
            oee.availability = 100;
            oee = warning(oee, this.warnings.Overproducing);
        }

        //Calculate efficiency
        //TODO add more checks depending on how datasource will be structured TODO verify correct behaviour in this case 100% or disabled
        if(data[this.targets.itemsToProduce] === 0)
            oee.efficiency = 100;  //We should not be producing here, set to 100% to not affect overall OEE
        else
            oee.efficiency = Math.round((data[this.targets.totalItems] / data[this.targets.itemsToProduce]) * 100);

        if(oee.efficiency > 100) {
            oee.efficiency = 100;
            oee = warning(oee, this.warnings.UnderEstimatedRecipe);
        }

        if(data[this.targets.totalItems] > 0)
            oee.quality = Math.round(((data[this.targets.totalItems] - data[this.targets.rejectedItems]) / data[this.targets.totalItems]) * 100);
        else
            oee.quality = 100;

        if(oee.quality > 100)
            oee.quality = 100;

        if(oee.quality < 0)
            oee.quality = 0;

        oee.total = Math.round((oee.availability * oee.efficiency * oee.quality) / 10000);

        if(oee.total > 100)
            oee.total = 100;

        oee.rejects = data[this.targets.rejectedItems];
        oee.maxSpeed = Math.round(data[this.targets.itemsToProduce] / timeMultiplier);
        if(oee.maxSpeed < 0)
            oee.maxSpeed = 0;
        if(data[this.targets.uptime])
            oee.averageSpeed = Math.round(data[this.targets.totalItems] / data[this.targets.uptime]);
        else oee.averageSpeed = 0;

        return oee;
    },

    // calculateScheduledUpTime(timeWindowInSeconds) {
    //
    //     let startDateTime = DateTime.getDateTimeFromNow(timeWindowInSeconds);
    //     let windowStartTime = DateTime.getDateTimeFromNow(timeWindowInSeconds);
    //     let endDateTime = new Date();
    //     let settings = LineSettings.GetLineSettings();
    //     //Set window start to monday 00:00
    //     windowStartTime.setToPreviousDayOfWeek(1);
    //     windowStartTime.setHours(0, 0);
    //     //Get window start as minute of the year
    //     let windowStartMinute = windowStartTime.getMinuteOfYear();
    //     let startMinute = startDateTime.getMinuteOfYear();
    //     let endMinute = endDateTime.getMinuteOfYear();
    //
    //     //Calculate daily work time windows in minutes
    //     let dailyWorkSpansMinutes = [];
    //     if(settings.workHours.length === 0) {
    //         settings.workHours = [{ from: "00:00", to: "23:59" }];
    //     }
    //     settings.workHours.forEach(span => {
    //         dailyWorkSpansMinutes.push( { from: DateTime.getMinuteOfDay(new Date('1970-01-01 ' + span.from)), to: DateTime.getMinuteOfDay(new Date('1970-01-01 ' + span.to)) } );
    //     });
    //
    //     //Initialize time mask to all false
    //     let timeMask = [];
    //     for(let minute = 0 ; minute < (endMinute - windowStartMinute) ; minute++)
    //         timeMask.push(false);
    //
    //     //Calculate working days array as minutes of week
    //     let weeklyWorkingDaysMinutes = [];
    //     if(settings.workDays.length === 0) {
    //         settings.workDays = [0,1,2,3,4,5,6];
    //     }
    //     settings.workDays.forEach(day => {
    //         weeklyWorkingDaysMinutes.push( day * 1440 );
    //     });
    //
    //     complete: {
    //         for (let day = 0; day < weeklyWorkingDaysMinutes.length; day++) {
    //             for (let minuteOfYear = 0; minuteOfYear < timeMask.length; minuteOfYear++) {
    //                 let minuteOfWeek = minuteOfYear % 10080;
    //                 if (minuteOfWeek === weeklyWorkingDaysMinutes[day]) {
    //                     for (let minuteOfDay = 0; minuteOfDay < 1440; minuteOfDay++, minuteOfYear++) {
    //                         for(let dailyWorkSpansIndex = 0 ; dailyWorkSpansIndex < dailyWorkSpansMinutes.length ; dailyWorkSpansIndex++) {
    //                             if (minuteOfDay >= dailyWorkSpansMinutes[dailyWorkSpansIndex].from && minuteOfDay <= dailyWorkSpansMinutes[dailyWorkSpansIndex].to)
    //                                 if ((minuteOfYear) < timeMask.length)
    //                                     timeMask[minuteOfYear] = true;
    //                                 else {
    //                                     break complete;
    //                                 }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //
    //     let scheduledUpTimeMinutes = 0;
    //     for(let minute = (startMinute - windowStartMinute) ; minute < timeMask.length ; minute++) {
    //         if(timeMask[minute])
    //             scheduledUpTimeMinutes++;
    //     }
    //
    //     return scheduledUpTimeMinutes;
    // },

}