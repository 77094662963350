<template>
    <v-container style="position: relative;top: 13%; width: 400px" class="text-xs-center" id="login-container-flex-container">
        <v-card flat id="login-container-flex-container-vcard" class="elevation-4">
            <img style="width: 340px" src="@/assets/logo.png" class="mb-2">
            <v-divider />
            <v-card-title class="headline">
                <h4>{{ '{appName} Login'.format({appName: $config.appName})  }}</h4>
                <v-spacer></v-spacer>
                <v-btn slot="activator" flat icon id="toolbar-osk-button" @click="switchOnOffOsk">
                    <av-icon v-if="toggleOsk" id="toolbar-osk-on">far fa-keyboard</av-icon>
                    <av-icon v-else id="toolbar-osk-off">fas fa-keyboard</av-icon>
                </v-btn>
            </v-card-title>
            <v-form @submit="doLogin" v-on:submit.prevent id="login-vform" class="px-4">
                <v-layout column >
                    <v-text-field class="osk fieldset" outline prepend-inner-icon="fas fa-user" label="Username" v-model="username" id="login-username"></v-text-field>
                    <v-text-field class="osk" outline prepend-inner-icon="fas fa-lock" label="Password" type="password" v-model="password" id="login-password"></v-text-field>
                    <v-alert v-model="alert" :type="alertType" id="login-alert">{{alertText }}</v-alert>
                    <v-card-actions class="mb-3">
                        <v-spacer></v-spacer>
                        <v-btn large style="color: white" color="#5a5a5a" light v-if="showInDialog" @click="$emit('cancel')">Cancel</v-btn>
                        <v-btn large style="color: white" color="#5a5a5a" light v-if="!loggingIn" type="submit" id="login-button"><av-icon left light large>fas fa-sign-in-alt</av-icon>Login</v-btn>
                        <img v-else :src="loaderGif" style="width:50px; height:50px;" />
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-layout>
            </v-form>
        </v-card>
    </v-container>
</template>
<script>

    import AuthApi from '@/api/auth'

    export default {
        name: 'Login',
        props:{
            autoLoginEnabled:{
                default:()=>{return true}
            },
            showInDialog:{
                default:()=>{ return false}
            }
        },
        data: () => ({
            username: '',
            password: '',
            alertText:'',
            alert: false,
            alertType: 'success',
            loggingIn: false
        }),
        computed: {
            toggleOsk(){
                return this.$root.toggleOsk;
            },
            loaderGif() { return require('@/assets/avloader.png') },
        },
        mounted() {
            this.$root.addListenerOsk();
            this.$store.state.globalHeader = false;
            this.$store.state.globalDrawer = false;
            let self = this;
            if(this.autoLoginEnabled){
                AuthApi.doDefaultLogin()
                    .then(t => {
                        self.doLoginSuccess(t)
                        if(t.data.Data!=="DEFAULT_PROFILE_NOT_FOUND" && t.data.Data!=="UNAUTHENTICATED_LOGIN_DISABLED"){
                            this.$store.dispatch("setUnauthenticatedLogin", true)
                        }
                    })
                    .catch(t => {
                        self.doLoginError(t)
                    })
            }
        },
        methods: {
            async doLogin() {
                // if(!this.username || !this.password) {
                //     this.alert = true;
                //     this.alertType = 'error';
                //     this.alertText = "Please provide user and password";
                //     return;
                // }
                this.loggingIn = true;
                let self = this;
                this.alert = false;

                //Handle demo username and password to show only specific application modules
                if (this.username.startsWith('demo.') && this.password.startsWith('demo.')) {
                    let demoUserName = this.username.split('.').slice(1);
                    if (demoUserName.length > 1) {
                        this.username = 'av.' + demoUserName[1];
                        this.password = 'av.' + demoUserName[1];
                    } else {
                        this.username = 'av.administrator';
                        this.password = 'av.administrator';
                    }
                    this.$license.setDemoModule(demoUserName);
                }
                AuthApi.doLogin(this.username, this.password)
                    .then(t => {
                        self.doLoginSuccess(t)
                    })
                    .catch(t => {
                        self.doLoginError(t)
                    }).finally(()=>{
                        setTimeout(() => {self.loggingIn = false}, 500)
                    }
                );

                return false;
            },
            async doLoginSuccess(t){
                let status = t.data.Status;
                if(status === "failed") {
                    this.alert = true;
                    this.alertType = 'error';
                    this.alertText = t.data.ErrorMessage.Message.format(t.data.ErrorMessage.Parameters);
                }
                else {
                    let answer = JSON.parse(t.data.Data);
                    this.$store.dispatch('setSession', {
                        token: answer.access_token,
                        userName: answer.userName,
                        addresses: answer.AdditionalData.Addresses,
                        groups: answer.groups
                    });
                    this.$store.dispatch('setProfiles', answer.profiles);
                    if (answer.LicenseExpired) {
                        this.alert = true;
                        this.alertType = 'error';
                        this.alertText = "License key is expired or wrong.";
                        this.openLicenceManagement();
                    } else {
                        //TODO move headers visibility after startup and manage loading error page
                        this.$store.dispatch('setHeaderVisibility', true);
                        this.$store.dispatch('setToolbarVisibility', true);
                        this.$store.dispatch('setSidebarVisibility', true);

                        await this.$root.applicationStartUp("login");
                    }
                    this.$emit('loggedIn')
                }
            },
            doLoginError(t){
                let self = this;
                console.log(t);
                self.alert = true;
                self.alertType = 'error';
                //Check this
                if(!Object.isUseful(t.response))
                    self.alertText = self.$gettext("An error occurred. Check application status.");
                else if (t.response.status === 423) {
                    //License expired
                    self.alertText = "License key expired or wrong.";
                    self.openLicenceManagement();
                } else {
                    self.alertText = self.$gettext("Error authenticating user. Wrong username or password.");
                }
            },
            openLicenceManagement() {
                this.$router.push({name: 'licensemanagement'});
            },
            openTermAndCondition(data) {
                return {name: 'termandcondition', params: {data: data}};
            },
            switchOnOffOsk() {
                this.$root.toggleOsk = !this.$root.toggleOsk;
            }
        },
    }
</script>
