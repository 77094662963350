<template>
    <iframe id="frame" style="border: 0px; width: 100%; height: calc(100%)"></iframe>
</template>

<script>
    import embeddedPageAPI from '@/api/embeddedPage'
    export default {
        name: "embeddedPage",
        data:()=> {
            return{
                page:""
            }
        },
        async mounted() {
            this.registerHooks();
            this.page = this.$route.params.page;
            this.$store.dispatch('setAutoSaveEnabled', true);
            embeddedPageAPI.embedPage(this.page).then(url => {
                if (url) {
                    document.getElementById('frame').src = url;
                }
            });

        },
        beforeDestroy(){
            if (this.page==="groupsandprivileges"){
                this.$grants.loadProfiles();
            }
        },
        methods:{
            registerHooks(){

                this.$store.dispatch('SetWebSocketHook', {
                    Scope: 'OnRequestToStoreChange',
                    Function: this.OnRequestToStoreChange
                })
                this.$store.dispatch('SetWebSocketHook', {
                    Scope: 'RaiseSharedStoreChanged',
                    Function: this.RaiseSharedStoreChanged
                })
                this.$store.dispatch('SetWebSocketHook', {
                    Scope: 'OnWebSocketConnected',
                    Function: this.OnWebSocketConnected
                })
                this.$store.dispatch('SetWebSocketHook', {
                    Scope: 'OnShowNotificationRequest',
                    Function: this.OnShowNotificationRequest
                })

                this.$store.dispatch('SetWebSocketHook', {Scope: 'OnAPICall', Function: this.onAPICall})


                this.$store.dispatch('SetWebSocketHook', {
                    Scope: 'OnRequestBreadcrumbUpdate', Function: ()=>{}
                })
                this.$store.dispatch('SetWebSocketHook', {
                    Scope: 'OnLogoutRequest', Function: () => {
                    }
                })
                //this.$root.registerWebSocketHooks()
            },
            RaiseSharedStoreChanged () {
                if (this.$isEmpty(this.$store.state.shared.token)) {
                    console.log('please make login before to use notification')
                    return
                }
                this.OrchestratorAPI.notifyEvent('OnSharedStoreChanged', this.$store.state.shared).then(t => {
                    if (t.data.Status !== 'success') {
                        this.$notifyError(t.data.ErrorMessage, [{ label: 'Close', color: 'white', handler: () => { } }])
                    }
                }).catch(t => {
                    this.$notifyError({ Message: 'Error occurred: {0}', Parameters: [t] }, [{ label: 'Close', color: 'white', handler: () => { } }])
                }).finally(() => {
                    this.loading = false
                })
            },
            OnRequestToStoreChange (val) {
                this.$store.dispatch(val.Message.mutationName, val.Message.payload)
            },
            OnWebSocketConnected () {
                // Trigger a store changed event in this way it can send to the new web socket the current shared store
                this.RaiseSharedStoreChanged()
            },
            OnShowNotificationRequest(request){
                let msg = request.Message.request;
                try {
                    msg = JSON.parse(msg);
                    this.$root.showNotification(msg.Message.format(msg.Parameters?msg.Parameters:[]), true, true,request.Message.severity.toLowerCase())
                } catch (e) {
                    debugger
                    if(typeof msg === "string") {
                        this.$root.showNotification(msg, true, true, request.Message.severity.toLowerCase())
                    }
                }

            },

            onAPICall (request) {
                this.$store.dispatch('callingApi', request.Message.status)
            },

        }
    }
</script>

<style scoped>


</style>