export default ({ app, router, store, Vue }) => {
  Vue.prototype.$parseError = function (errorJSON) {
    if (!Vue.prototype.$isJSONValid(errorJSON)) {
      return errorJSON
    }
    return JSON.parse(errorJSON)
  }
  Vue.prototype.$isJSONValid = function (errorJSON) {
    try {
      JSON.parse(errorJSON)
      return true
    } catch (e) {
      return false
    }
  }
  Vue.prototype.$getTranslatedMessage = function (message) {
    let translatedMessage = message.Message
    try {
      translatedMessage = this.$t(translatedMessage)
    } catch (e) {}
    if (!this.$isEmpty(message.Parameters)) {
      for (let i = 0; i < message.Parameters.length; i++) {
        let parameter = message.Parameters[i]
        try {
          if (isNaN(parameter)) {
            parameter = this.$t(parameter)
          }
        } catch (e) {}
        var re = new RegExp('\\{' + i + '\\}', 'g')
        translatedMessage = translatedMessage.replace(re, parameter)
      }
    }
    if (!Vue.prototype.$isEmpty(message.Code)) {
      translatedMessage = translatedMessage + ' (0x' + message.Code.toString().padStart(8, '0') + ')'
    }
    return translatedMessage
  }
}
