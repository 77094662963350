import Vue from 'vue'

import VueInstance from './vueinstance'

import GetTextPlugin from 'vue-gettext'
import translations from '@/translations/translations.json'

let predefinedLanguagesAvaliable = {
    default: 'English',
    it: 'Italiano',
    fr: 'Français',
    es: 'Español',
    de: 'Deutsch',
    pt: 'Português',
    ru: 'Pусский'
};

Vue.use(GetTextPlugin, {
    availableLanguages: predefinedLanguagesAvaliable,
    defaultLanguage: 'default',
    languageVmMixin: {
        computed: {
            currentKebabCase: function () {
                return this.current.toLowerCase().replace('_', '-')
            },
        },
    },
    translations: translations,
    silent: true,
});

Vue.prototype.$extracttext = function(text) { return text };

export default {

    CommonAttributes() {
        return {
            "type here": VueInstance.get()? VueInstance.get().$gettext("Type here"): "Type here",
            "select": VueInstance.get()? VueInstance.get().$gettext("Select"): "Select",
            "click to set": VueInstance.get()? VueInstance.get().$gettext("Click to set"): "Click to set",
        }
    },

    getPredefinedLanguagesAvaliable() {
        return {
            default: 'English',
            it: 'Italiano',
            fr: 'Français',
            es: 'Español',
            de: 'Deutsch',
            pt: 'Português',
            ru: 'Pусский'
        };
    },

    getPredefinedDateTimeFormat() {
        if (VueInstance.get() !== null)
            return VueInstance.get().$gettext("yyyy-MM-dd HH:mm:ss")
        else
            return "yyyy-MM-dd HH:mm:ss"
    }
}