/**
 **  Disclaimer: XLSX Code comes from http://oss.sheetjs.com/js-xlsx/
 **  This is just a project to show Vue.js working in conjunction with HTML5 Drag and Drop and XLSX
 **/

const XLSX = require('xlsx');
//import XLSX from '@/assets/xlsx.js'

export default {

    /** HELPERS **/
    get_header_row(sheet) {
        var headers = [], range = XLSX.utils.decode_range(sheet['!ref']);
        var C, R = range.s.r; /* start in the first row */
        for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
            var cell = sheet[XLSX.utils.encode_cell({c: C, r: R})] /* find the cell in the first row */
            var hdr = "UNKNOWN " + C; // <-- replace with your desired default
            if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
            headers.push(hdr);
        }
        return headers;
    },

    workbook_to_json(workbook) {
        var result = {};
        workbook.SheetNames.forEach(function (sheetName) {
            var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            if (roa.length > 0) {
                result[sheetName] = roa;
            }
        });
        return result;
    },

    /** PARSING and DRAGDROP **/
    readExcel(file) {

        return new Promise((resolve, reject) => {

            try {
                console.log("Reading excel file " + file.name);
                let reader = new FileReader();
                let name = file.name;
                reader.onload = function (e) {
                    let results;
                    let data = e.target.result;
                    let fixedData = "", l = 0, w = 10240;
                    for (; l < data.byteLength / w; ++l) fixedData += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
                    fixedData += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
                    let workbook = XLSX.read(btoa(fixedData), {type: 'base64'});
                    let firstSheetName = workbook.SheetNames[0];
                    let worksheet = workbook.Sheets[firstSheetName];
                    let returning = {};
                    returning["headers"] = [];
                    let range = XLSX.utils.decode_range(worksheet['!ref']);
                    let C, R = range.s.r; /* start in the first row */
                    for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
                        let cell = worksheet[XLSX.utils.encode_cell({c: C, r: R})] /* find the cell in the first row */
                        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
                        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
                        returning["headers"].push(hdr);
                    }
                    returning["records"] = XLSX.utils.sheet_to_json(worksheet);
                    resolve(returning);
                };
                reader.readAsArrayBuffer(file);
            }
            catch(ex) {
                reject("An error occurred while loading " + file.name + ". Error: " + ex);
            }
        });
    }

}