<template>
    <div>
        <canvas :id=id></canvas>
    </div>
</template>
<script>
import {Chart, registerables} from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";


export default {
    name: "PieChart",
    props: {
        chartdata: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            id: Date.now(),
            chart: undefined,
            chartData: {
                type: "pie",
                data: this.chartdata,
                options: this.options
            }
        }
    },
    created() {
        Chart.register(...registerables);
        Chart.register(ChartDataLabels);
        Chart.register({
            id: 'zoomCSS',
            beforeEvent(chart, ctx) {
                // let bounds = ctx.event.native.target.getBoundingClientRect();
                // let correctionX = bounds.left - bounds.left * 0.8;
                // let correctionY = bounds.top - bounds.top * 0.8;
                // ctx.event.native.x = (ctx.event.native.x + correctionX) * 1.25;
                // ctx.event.native.y = (ctx.event.native.y + correctionY) * 1.25;
                ctx.event.x = ctx.event.x * 1.25;
                ctx.event.y = ctx.event.y * 1.25;
            }
        });
    },
    methods: {
        refreshGraph() {
            this.chartData.data = this.chartdata;
            this.chartData.options = this.options;
            this.chart.update();
        },
        resetVisualization() {
            this.chart.resetZoom();
        },

        createChart() {
            // this.options.plugins = {
            //     legend: {
            //         display: false
            //     },
            //     datalabels: {
            //         display: false
            //     }
            // }
            this.chart = new Chart(document.getElementById(this.id), this.chartData);
        },
    },

    mounted() {
        this.createChart();
        this.chart.render();
    },
    watch: {
        data: {
            handler(n,o){
                this.chartData.data = n
                this.chart.render()
            }
        },
        options: {
            handler(n,o) {
                this.chartData.options = n
                this.chart.render()
            }
        }
    },
    beforeDestroy() {
        if (this.chart)
            this.chart.destroy()
    }
}
</script>
