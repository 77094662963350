
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import VueInstance from "@/api/vueinstance";

export default {
    async GetIP(param, payload) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.landingUrl + "/getIp", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                    reject(VueInstance.get().$gettext("Unable to get ip"));
                });
        })
    },
    async GetConf() {
        return new Promise((resolve, reject) => {

            OrchestratorAPI.proxyCall('get', Microservices.landingUrl + '/loadConfiguration')
                .then(t => {
                    let settings = null;
                     if (t.DocumentContent)
                        settings = JSON.parse(t.DocumentContent);

                    resolve(settings);
                })
                .catch(t => {
                    debugger;
                    console.error(t);
                    resolve(null)
                });
        });
    }
}
