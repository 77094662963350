import DataApis from "./data";
import Defines from "./defines";
import DateTimeUtils from "./datetimeutils";
import Config from "@/api/config"
import Settings from "@/api/settings"
import Lines from "@/api/lines"

export default {

    async getAvailable(from, to, includeReferenceMachine, includeLine, index) {
        if(!Config.isAvionics)
            return [];
        return new Promise((resolve, reject) => {
            DataApis.getDistinctValues(index || 'production counters@5s', 'tag', 'Device', 'keyword', from, to)
                .then(devices => {
                    if (!Array.isUseful(devices))
                        devices = [];
                    if(includeReferenceMachine)
                         devices.unshift("@ReferenceMachine");
                    if(includeLine)
                        devices.unshift("Line");
                    resolve(devices);
                })
                .catch(err => {
                    console.log(err);
                    reject("Unable to discover available devices");
                });
        });
    },
    async getAvailableByLine(line) {
        let index = undefined;
        if (Config.isL3()) {
            index = "*~|~*~|~" + line + "~|~production counters@5s";
        }
        return await this.getAvailable(undefined, undefined, false, false, index)
    },

    async getAll(){
        let lines =await Lines.getAvailable();
        let self=this;
        return new Promise((resolve,reject)=>{
            let machines=[];
            lines.forEach(async (line,i)=>{
                let m=await self.getDevicesFromIndexPattern({index:i,name:line})
                machines.push(...m.map(machine=>{
                    return {
                        text:line + " - " + machine.device,
                        device:machine.device,
                        line:line
                    }
                }));
                resolve(machines.sortBy("text",false))
            })
        })

    },

    async getDevicesFromIndexPattern(pattern, includeLine) {
        let returning = [];
        let self = this;
        return new Promise((resolve, reject) => {
            self.getAvailable(0, 0, false, includeLine, pattern.index)
                .then(devs => {
                    for (let dev of devs) {
                        returning.push({
                            text: dev + " in " + pattern.name,
                            value: dev + pattern.index,
                            device: dev,
                            dataIndex: pattern.index,
                        });
                    }
                    resolve(returning)
                })
                .catch(ex => {
                    debugger
                    resolve([])
                });
        });
    },

    selectDeviceFromIndexPatterns(compatibleDataPatterns, selectLineNode, selectAllDevs) {
        let self = this;
        return new Promise((resolve, reject) => {
            DataApis.loadDataDefinitions()
                .then(data => {
                    //All Index compatibles
                    let indexPatterns = DataApis.selectIndexesByPatterns(data, compatibleDataPatterns);
                    let devices = [];
                    let promises = [];
                    for (let selectedIndex = 0; selectedIndex < indexPatterns.multiSelect.length; selectedIndex++) {
                        // return un array of devices in an index and that must be selected the first
                        promises.push(
                            self.getDevicesFromIndexPattern(indexPatterns.multiSelect[selectedIndex], selectLineNode)
                                .then(devicesPerIndex => {
                                    if (Array.isUseful(devicesPerIndex)) {
                                        if(selectAllDevs)
                                            devices.push(devicesPerIndex);
                                        else {
                                            let lineSettings = Settings.getLineSettings();
                                            let refMachine = null;
                                            if(lineSettings)
                                                refMachine = lineSettings.masterMachine;
                                            let refMachineNode = devicesPerIndex.find(dev => dev.device === (selectLineNode ? "Line" : refMachine));
                                            devices.push(refMachineNode || devicesPerIndex[0]);
                                        }
                                    }
                                })
                        )
                    }
                    Promise.all(promises)
                        .then(() => {
                            resolve(devices)
                        });
                })
                .catch(t => {
                    console.log(t);
                    reject([])
                })
        });
    },
}