import Api from '@/api/api'
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import VueInstance from './vueinstance'
import License from '@/api/licensing'
import Vue from 'vue'
import Grants from "./grants";
var savedDashboardsNames = null;

async function updateDashboardsList() {
    return new Promise((resolve, reject) => {
        VueInstance.get().$dynamicElements.listAll("dashboards", false)
            .then(dashboards => {
                savedDashboardsNames = dashboards.active;
                resolve("ok");
            })
            .catch(error => {
                reject(error);
            });
    });
}

function validateName(name) {
    if(!Array.isUseful(savedDashboardsNames))
        return true;
    if(savedDashboardsNames.includes(name))
        return false;
    return true;
}

async function loadAll(visibleOnly, editableOnly, exposableOnly) {
    return new Promise((resolve, reject) => {
        let tokenFilteringProgiles=""
        if (editableOnly){
            tokenFilteringProgiles="editableToLevels"
        }
        if (visibleOnly){
            tokenFilteringProgiles="visibleToLevels"
        }

        if (exposableOnly){
            tokenFilteringProgiles="exposableToLevels"
        }
        OrchestratorAPI.proxyCall('get', Microservices.documentsUrl + '/dashboards/'+tokenFilteringProgiles+'/*')
            .then(dashboards => {
                savedDashboardsNames = [];
                let dashboardResult = [];
                for(let i = 0 ; i < dashboards.length ; i++) {
                    let descriptor = JSON.parse(dashboards[i].descriptor);

                    if (dashboards[i].name)
                        savedDashboardsNames.push(dashboards[i].name);
                    if (!License.hasModules(descriptor.modules))
                        continue;
                    dashboardResult.push(dashboards[i]);
                }
                resolve(dashboardResult)
            })
            .catch(err => {reject(err)})
    })
}

export default {
    validateName: validateName,
    loadAll: loadAll,

    async LoadDashboard(name) {
        return OrchestratorAPI.proxyCall('get', Microservices.documentsUrl + '/dashboards/visibleToLevels/' + name)
        //return Api().get('/dashboards/' + name);
    },

    async SaveDashboard(name, descriptor) {
        let grants = {
            visibleToLevels: descriptor.showLevel !== null ? descriptor.showLevel : ["all"],
            editableToLevels: descriptor.editLevel !== null ? descriptor.editLevel : ["all"],
            exposableToLevels: descriptor.exposableToLevels
        }
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.documentsUrl + '/dashboards/' + name, { "name": name, "descriptor": descriptor, "grants":grants })
            //Api().post('/dashboards/' + name, { "name": name, "descriptor": descriptor })
                .then(result => {
                    updateDashboardsList();
                   resolve(result)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async DeleteVisualization(name) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('delete', Microservices.documentsUrl + '/dashboards/' + name)
                .then(result => {
                    updateDashboardsList();
                    resolve(result)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async getMetadataList(visibleOnly,editableOnly,exposableOnly) {
        return new Promise((resolve, reject) => {
            this.loadAll(visibleOnly,editableOnly,exposableOnly)
                .then((t) => {
                    let dashboards = {};
                    for (let i = 0; i < t.length; i++)
                        if (!t[i].name.startsWith("builtin-")) {
                            let descriptor = JSON.parse(t[i].descriptor);
                            let dashboard = {
                                name: t[i].name,
                            };
                            if (Object.isUseful(descriptor.group))
                                Array.isUseful(dashboards[descriptor.group]) ? dashboards[descriptor.group].push(dashboard) : dashboards[descriptor.group] = [dashboard];
                            else {
                                Array.isUseful(dashboards.ungrouped) ? dashboards.ungrouped.push(dashboard) : dashboards.ungrouped = [dashboard]
                            }
                        }
                    resolve(dashboards);
                })
                .catch((err) => {
                    debugger;
                    console.error(err);
                    reject([])
                });
        })
    }

}