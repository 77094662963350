<template>
    <DynamicElementBase>
        <av-loader :loading="synLoading" message="" minTime="0"/>
        <div ref="myCanvas" id="my-canvas" v-bind:style="canvasStyle">
            <vue-draggable-resizable v-if="descriptor.shapes.length>0" v-for="shape in descriptor.shapes" :key="shape.id"
                                     :w="shapeWidth(shape)"
                                     :h="shapeHeight(shape)"
                                     :x="shapeX(shape)"
                                     :y="shapeY(shape)"
                                     :draggable="expanded" :resizable="expanded"
                                     :class="!expanded || !editMode?'hide-vdr':''"
                                     :min-heght="10" :active="shape.active"
                                     @dragstop="(left, top) => onDragEnd(shape.id, left, top)"
                                     @resizestop="(left, top,width,height) => onResize(shape.id, left, top,width,height)"
                                     @activated="()=>onActivated(shape.id)"
                                     @deactivated="()=>onDeactivated(shape.id)" :ref="'shape-'+shape.id"
            >
                <v-card :width="shapeWidth(shape)-15" v-if="shape.type==='shape'" :key="shape.id"
                        :height="shapeHeight(shape)-15" :style="cardStyle(shape)">
                    <v-card-text :style="cardTitleStyle" v-if="expanded || shape.name || shape.dashboardLinks">
                        <v-layout row justify-space-between align-center fill-height style="min-height: 25px">
                            <v-text-field label="Name" class="font-weight-bold" v-model="shape.name" @change="()=>onNameChange(shape)" v-if="expanded"></v-text-field>
                            <div v-else-if="getTweakValue('AutoFontSize')" class="font-weight-bold" v-resize-text="{ratio:1, minFontSize: '12px', maxFontSize: '200px', delay: 200}">{{shape.name}}</div>
                            <div v-else class="font-weight-bold" :style="fontSize">{{shape.name}}</div>
                            <div>
                                <v-layout align-center>
                                    <!-- info and links menu -->
                                    <v-menu offset-y transition="slide-y-transition" left>
                                        <v-layout row slot="activator">
                                            <av-icon class="ml-1" v-if="Array.isUseful(shape.dashboardLinks) || hasAssetsLink(shape)" id="toolbar-link" :size="iconSize" color="info">fas fa-link</av-icon>
                                            <av-icon class="ml-1" v-if="shape.status === 'red' && Array.isUseful(shape.statusItems)" id="toolbar-status" :size="iconSize" color="red" >fas fa-exclamation-triangle</av-icon>
                                        </v-layout>
                                        <v-list id="toolbar-dropdown-menu">
                                            <v-list-tile>
                                                <v-list-tile-title>{{shape.name}}</v-list-tile-title>
                                            </v-list-tile>
                                            <template v-if="shape.status === 'red' && Array.isUseful(shape.statusItems)">
                                                <v-divider></v-divider>
                                                <v-subheader v-translate>Alert conditions</v-subheader>
                                                <v-list-tile v-for="alert in shape.statusItems">
                                                    <v-list-tile-action>
                                                        <av-icon color="red" small>fas fa-exclamation-triangle</av-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-title>{{alert}}</v-list-tile-title>
                                                </v-list-tile>
                                            </template>
                                            <template v-if="Array.isUseful(shape.dashboardLinks)">
                                                <v-divider></v-divider>
                                                <v-subheader v-translate>Go to dashboard</v-subheader>
                                                <v-list-tile @click="openDashboard(item)" v-for="item in shape.dashboardLinks">
                                                    <v-list-tile-action>
                                                        <av-icon>grid_on</av-icon>
                                                    </v-list-tile-action>
                                                    <v-list-tile-title>{{item}}</v-list-tile-title>
                                                </v-list-tile>
                                            </template>
                                            <template v-if="hasAssetsLink(shape)">
                                                <v-divider></v-divider>
                                                <av-button flat buttonIcon="far fa-conveyor-belt" iconColor="info" :size="iconSize" :text="$gettext('Go to related assets')" @click="goToAssetsRegister(shape.listOfAssets, shape.machineName)" />
                                            </template>
                                        </v-list>
                                    </v-menu>
                                </v-layout>
                            </div>
                        </v-layout>
                    </v-card-text>
                    <v-card-text :style="cardTextStyle">
                        <v-layout column justify-start v-for="label in shape.labels" :style="cardTextStyle" :key="label.key">
                            <div v-if="getTweakValue('AutoFontSize')" v-resize-text="{ratio:1, minFontSize: '10px', maxFontSize: '200px', delay: 200}"
                                 class="font-weight-regular pa-0 pl-1">{{label.key}}{{label.key ? ': ' : ' '}}
                                <span class="font-weight-bold pa-0">{{label.value}}</span>
                            </div>
                            <div v-else class="font-weight-regular pa-0 pl-1" :style="fontSize">{{label.key}}{{label.key ? ': ' : ' '}}
                                <span class="font-weight-bold pa-0" :style="fontSize">{{label.value}}</span>
                            </div>
                        </v-layout>
                    </v-card-text>
                </v-card>
                <av-draw-line v-else-if="shape.type==='line'" :width="shapeWidth(shape)" :height="shapeHeight(shape)" :direction="shape.lineDirection" :color="borderColor"></av-draw-line>
                <div v-else-if="shape.type==='widget'" style="width: 100%; height: 100%;">
                    <!--                    <DashBoardElement :globalParams="dashboardElementDescriptor(shape).shapeOptions.useTimeWindowDefinedInWidget.value ? {} : params" :play="!editMode" style="width: 100%; height: 100%; overflow: auto" :descriptor="dashboardElementDescriptor(shape)" v-on:globalParamUpdated="globalParamUpdated($event)"/>-->
                    <DashBoardElement :globalParams="parseTimeWindowParams(shape)" :play="!editMode" style="width: 100%; height: 100%; overflow: auto" :descriptor="dashboardElementDescriptor(shape)" v-on:globalParamUpdated="globalParamUpdated($event, shape)"/>
                </div>
                <img :src="shape.image" v-else :style="imgStyle(shape)"/>
            </vue-draggable-resizable>
        </div>
        <v-flex xs12 style="position: relative; top:0px; left:20px; float: left; ">
            <template  >
                <v-menu offset-y v-model="openDashboardOptions" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-show="showOptionsButton"
                            fab class="flat-button" right
                            @click="openDashboardsOption(lastSelectedShape)"
                            v-on="on"
                            color="yellow darken-1"
                        >
                            <v-icon id="icon-optionsbtn" color="white">fa-bars</v-icon>
                        </v-btn>
                    </template>
                    <v-list v-if="lastSelectedShape && lastSelectedShape.dashboardElementDescriptor">
                        <v-list-tile v-for="(item, key) in lastSelectedShape.dashboardElementDescriptor.shapeOptions" :key="key">
                            <av-switch :label="item.name" :value="item.value" v-model="lastSelectedShape.dashboardElementDescriptor.shapeOptions[key].value" @change="widgetOptionChanged"/>
                        </v-list-tile>
                    </v-list>
                </v-menu>
            </template>
        </v-flex>
        <v-flex xs12 style="position: relative; top:0px; float: right; ">
            <!--<input type="file" name="uploadFile" style="visibility: hidden" @change="uploadImageCallback($event)">-->
            <UploadButton ref="button" style="visibility: hidden; height: 1px;"  @file-update="uploadImageCallback" >
            </UploadButton>
            <v-btn v-if="!expanded && editMode"  id="editbtn" right medium fab @click="onEditMode(true)" class="flat-button">
                <v-icon id="icon-cancelbtn2" color="white">fa-pen</v-icon>
            </v-btn>
            <v-btn slot="activator" id="addwbtn" @click="addShape('widget','')" fab class="flat-button" right v-if="expanded " >
                <v-icon id="icon-addwbtn" color="white">fas fa-edit</v-icon>
            </v-btn>
            <v-btn slot="activator" id="addbtn" @click="addShape('shape','')" fab class="flat-button" right v-if="expanded " >
                <v-icon id="icon-addbtn" color="white">fa-vector-square</v-icon>
            </v-btn>
            <v-btn slot="activator" id="addlinebtn" @click="addShape('line','right')" fab class="flat-button" right v-if="expanded " color="yellow darken-1">
                <v-icon id="icon-addlinebtn" color="white">fa-slash</v-icon>
            </v-btn>
            <v-btn slot="activator" id="addlineLeftbtn" @click="addShape('line','left')" fab class="flat-button" right v-if="expanded " color="yellow darken-1">
                <v-icon id="icon-addlineleftbtn" color="white">fa-slash fa-rotate-90</v-icon>
            </v-btn>

            <v-btn slot="activator" id="addImagetbtn" @click="addShape('image','')" fab class="flat-button" right v-if="expanded " color="yellow darken-1">
                <v-icon id="icon-addimagebtn" color="white">fa-image</v-icon>
            </v-btn>
            <v-btn slot="activator" id="addlinkbtn"  @click="chooseDashboard(selectedShape.id)" fab class="flat-button" right
                   v-if="expanded && selectedShape.id && selectedShape.type==='shape'" color="yellow darken-1">
                <v-icon id="icon-addlinkbtn" color="white">fa-link</v-icon>
            </v-btn>
            <v-btn slot="activator" id="delbtn" @click="deleteShape" v-if="expanded && selectedShape.id>=0 " fab class="flat-button" right >
                <v-icon id="icon-cancelbtn" >fa-trash</v-icon>
            </v-btn>
        </v-flex>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-card-title class="headline" v-translate>Choose dashboard to link</v-card-title>
                <div v-if="selectedDashboards.length > 0">
                    <div v-for="(item, key) in selectedDashboards">
                        <v-layout row style="width: 100%; padding: 10px" justify-space-around fill-height align-center>
                            <v-select :items="dashboards" label="dashboard" @input="dashboardChanged(key, selectedDashboards[key])"  v-model="selectedDashboards[key]" :value="item" />
                            <av-button @click="deleteDashboardCombobox(key)" buttonIcon="fa-trash" color="error" iconColor="light" class="ma-1" fab small style="margin-left: 10px" />
                        </v-layout>
                    </div>
                    <v-layout justify-space-around align-center>
                        <av-button @click="selectedDashboards.push('')" buttonIcon="fa-plus" color="info" iconColor="light" fab big />
                    </v-layout>
                </div>
                <div v-else>
                    <v-layout row style="width: 100%; padding: 10px" justify-space-around fill-height align-center>
                        <v-card-text >
                            <v-select :items="dashboards" label="dashboard" @input="dashboardChanged(0, selectedDashboard)" v-model="selectedDashboard" />
                        </v-card-text>
                    </v-layout>
                </div>
                <v-divider class="mt-2"></v-divider>
                <v-card-title class="headline" v-translate>Asset register link</v-card-title>
                <v-layout row style="width: 100%; margin-left: 30px" justify-space-around fill-height align-center>
                    <v-checkbox v-if="autoAssetBinding" v-model="automaticBinding" :label="$gettext('Automatic asset binding')" @change="automaticBindChange"></v-checkbox>
                </v-layout>
                <v-layout row style="width: 100%; padding: 10px" justify-space-around fill-height align-center>
                    <v-select v-if="listOfAssetsForMachine.length > 0" :items="listOfAssetsForMachine" :item-text="'itemName'" label="Asset" multiple v-model="selectedAsset"
                              style="width: 90%; margin-left: 20px" :disabled="automaticBinding"/>
                    <label v-else style="width: 90%; margin-left: 20px">{{ $gettext('No assets to bind this machine') }}</label>
                </v-layout>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" flat="flat" @click="closeDashboardDialog" v-translate>Close</v-btn>
                    <v-btn color="green darken-1" flat="flat" @click="addDashboardLinkToShape(false)" v-translate>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </DynamicElementBase>
</template>

<script>
import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
import UploadButton from 'vuetify-upload-button';
import ResizeText from 'vue-resize-text'
import Entity from "@/api/entities";
import DashBoardElement from "@/components/DashboardElement";

export default {
    name: "WidgetSynoptic",
    extends: DynamicElementBase,
    components: {
        DashBoardElement,
        DynamicElementBase, UploadButton
    },
    directives: {
        ResizeText
    },
    data(){
        return{
            openDashboardOptions: false,
            selectedShape:{},
            descriptor:{
                backgroundImage:"",
                shapes:[]
            },
            synLoading:false,
            borderColor:'blue',
            imageStyle:{},
            dashboards:[],
            dialog:false,
            selectedDashboards:[],
            selectedDashboard:"",
            selectedShapeId:0,
            wasAutoDataSourcing: false,
            // APicaro crop white space before scaling
            cropX: 0,
            cropY: 0,
            cropW: 0,
            cropH: 0,
            dontSaveDescriptor: false,
            listOfAssets: [],
            listOfAssetsId: [],
            selectedAsset: [],
            lastSelectedShape: {},
            globalParams: null,
            params: {},
            automaticBinding: false,
            listOfAssetsForMachine: [],
            customized: false,
            stateChanged: false,
            dataItemsLength: 0
        }
    },
    computed:{
        canvasStyle(){
            return {
                height:(this.canvasHeight-80)+"px",
                width:"100%",
                "vertical-align":"middle",
            };
        },
        fontSize(){
            return{
                "font-size": this.xScale+"em !important",
            }
        },
        cardStyle() {
            return shape => {
                let borderColor = this.$avStyle.colors.lightGrey;
                switch(shape.status) {
                    case 'green': borderColor = this.$avStyle.colors.green; break;
                    case 'red': borderColor = this.$avStyle.colors.red; break;
                    case 'blue': borderColor = this.$avStyle.colors.blue; break;
                }
                return{
                    margin: "5px 0 0 5px",
                    border: "3px solid",
                    "border-color": borderColor,
                    "border-radius": "5px",
                    cursor: shape.dashboardLinks !== [] && !this.editMode ? "pointer" : "auto"
                }
            }
        },
        imgStyle(){
            return shape=>{
                return{
                    width:this.shapeWidth(shape)-10+"px",
                    height:this.shapeHeight(shape)-10+"px"
                }
            }
        },
        cardTitleStyle(){
            return {
                padding:!this.expanded?"2px":"7px",
                "text-align": "left",
                "border-bottom":"1px solid grey"
            }
        },
        cardTextStyle(){
            return {
                padding:!this.expanded?"2px":"7px",
                "text-align":!this.expanded?"left !important":"",
            }
        },
        canvasHeight(){
            return this.containerHeight
        },
        canvasWidth(){
            return this.containerWidth;
        },
        xScale(){
            //APicaro scale shapes only if there is no space to move stuff around
            if(this.editMode && this.expanded)
                return 1;
            if(this.cropW <= this.canvasWidth)
                return 1;
            else
                return this.canvasWidth / this.cropW;
        },
        yScale(){
            //APicaro scale shapes only if there is no space to move stuff around
            if(this.editMode && this.expanded)
                return 1;
            if(this.cropH <= this.canvasHeight )
                return 1;
            else
                return this.canvasHeight / this.cropH;
        },
        shapeWidth() {
            return shape => {
                return parseInt(shape.width*this.xScale);
            }
        },
        shapeHeight(){
            return shape=>{
                return parseInt(shape.height*this.yScale);
            }
        },
        shapeX(){
            return shape=>{
                if(this.editMode && this.expanded)
                    return shape.x;
                let margin = this.canvasWidth - this.cropW;
                let croppedX = shape.x - this.cropX;
                if(margin > 0)
                    return parseInt(croppedX + margin / 2);
                else return parseInt(croppedX * this.xScale);
            }
        },
        shapeY(){
            return shape=>{
                if(this.editMode && this.expanded)
                    return shape.y;
                let margin = this.canvasHeight - this.cropH;
                let croppedY = shape.y - this.cropY;
                if(margin > 0)
                    return parseInt(croppedY + margin / 2);
                else return parseInt(croppedY * this.yScale);
            }
        },
        hasAssetsLink() {
            return shape=> {
                return (Array.isUseful(shape.selectedAsset) && this.$license.hasMaintenance() && this.$grants.get().maintenance.canVisualizeAssetsRecords);
            }
        },
        snapToParent() {
            return !this.getTweakValue("AutomaticRuntimeDatasource")
        },
        iconSize() {
            return this.xScale * 23 + 'px'
        },
        showOptionsButton() {
            if (!this.lastSelectedShape.active && Object.isEmpty(this.selectedShape))
                return false
            return this.expanded && !Object.isEmpty(this.lastSelectedShape)
        },
        autoAssetBinding() {
            return this.getTweakValue("AutomaticRuntimeDatasource");
        },
        dashboardElementDescriptor() {
            return shape => {
                if(shape.type === "widget" && !Object.isUseful(shape.dashboardElementDescriptor) && Object.isUseful(shape.widget)) {
                    shape.dashboardElementDescriptor = {
                        id: shape.id,
                        widget: shape.widget,
                        state: shape.state,
                        shapeOptions: shape.shapeOptions
                    }
                }
                return shape.dashboardElementDescriptor
            }
        },
        parseTimeWindowParams() {
            return shape => {
                let useTimeWindowDefinedInWidget = this.dashboardElementDescriptor(shape).shapeOptions.useTimeWindowDefinedInWidget.value;
                if (!useTimeWindowDefinedInWidget) {//FN Using the Synoptic's timeWindow with the filter of globalFilter
                    if (Object.isUseful(this.globalParams) && !(Object.keys(this.globalParams.filters).length === 0 && this.globalParams.filters.constructor === Object)) {
                        let tmpGlobalParams = this.$utils.detach(this.globalParams);
                        tmpGlobalParams.time = this.params.time;
                        return tmpGlobalParams;
                    } else {//FN Using the Synoptic's timeWindow without the filter of globalFilter
                        return this.params;
                    }
                } else {//FN Using widget's TimeWindow with globalParams' filters
                    if (Object.isUseful(this.globalParams) && !(Object.keys(this.globalParams.filters).length === 0 && this.globalParams.filters.constructor === Object)) {
                        let tmpParams = this.$utils.detach(this.params);
                        tmpParams.filters = this.globalParams.filters;
                        return tmpParams;
                    }//FN Using widget's TimeWindow without globalParams' filters
                    return this.params;
                }
            }
        },
    },
    created() {
        this.childHandlers.getCustomDescriptor = this.getCustomDescriptor;
        this.childHandlers.setCustomDescriptor = this.setCustomDescriptor;
    },
    mounted() {
        this.fixedHeight = false;
        this.visualizationTweaks = [
            {
                name: this.$gettext("Automatic runtime datasource"),
                id: "AutomaticRuntimeDatasource",
                type: "bool",
                default: function() {
                    return false;
                }
            },
            {
                name: this.$gettext("Auto size text to fit boxes"),
                id: "AutoFontSize",
                type: "bool",
                default: function() {
                    return false;
                }
            },
            {
                name: this.$gettext("Default box color with data"),
                id: "boxColorWithData",
                type: "option",
                options: [
                    {text: this.$gettext('GREEN'), value: 'green'},
                    {text: this.$gettext('RED'), value: 'red'},
                    {text: this.$gettext('BLUE'), value: 'blue'},
                    {text: this.$gettext('GREY'), value: 'grey'}
                ],
                default: function() {
                    return this.options[2];
                }
            },
        ];
        this.visualizationTargets = [];
        this.preferredAggregations = [ this.$defines.allAggregations.last.id ];
        this.loadDashboard();
        //Keep initial tweak state to avoid asking user decisions about auto-sourcing (managed in watch) in case another tweak is changed
        this.wasAutoDataSourcing = this.getTweakValue("AutomaticRuntimeDatasource");
        this.$nextTick(function () {
            if (!Array.isUseful(this.dataItems) && !this.editMode && this.getTweakValue("AutomaticRuntimeDatasource"))
                this.loadDataMappings();
        });
        this.getAllAssets();
        this.childHandlers.onTimeWindowUpdate = this.timeWindowSelectionComplete;
        this.childHandlers.onDataItemsChanged = this.onDataItemsChanged;
        this.childHandlers.onElementLoaded = this.manageEmbeddedWidgetsBackCompatibility;
    },
    methods: {
        //Catch the value emitted by the dashboardElement
        globalParamUpdated(globalParams, shape) {
            let useTimeWindowDefinedInWidget = this.dashboardElementDescriptor(shape).shapeOptions.useTimeWindowDefinedInWidget.value;
            if(!useTimeWindowDefinedInWidget) {
                this.globalParams = globalParams;
            } else {
                this.params = this.$utils.detach(globalParams);
            }
        },

        //Avionics V5.1 routine covers back-compatibility with old item containing embedded widgets.
        // DashboardElement descriptor has been moved to a separate sub-property of shape to
        // decouple synoptic and dashboardElement watches
        manageEmbeddedWidgetsBackCompatibility() {
            for(let shape of this.descriptor.shapes) {
                if(shape.type === "widget" && !Object.isUseful(shape.dashboardElementDescriptor) && Object.isUseful(shape.widget)) {
                    shape.dashboardElementDescriptor = {
                        id: shape.id,
                        widget: shape.widget,
                        state: shape.state,
                        shapeOptions: shape.shapeOptions
                    }
                    delete(shape, "widget")
                    delete(shape, "state")
                    delete(shape, "shapeOptions")
                }
            }
        },
        timeWindowSelectionComplete() {
            this.params = {time: this.properties.timeWindow,filters:Object.create(null),variables:""};
        },
        onDataItemsChanged() {
            if (this.stateChanged) {
                this.customized = false;
                this.stateChanged = false;
            }
            else {
                this.customized = true;
                this.setTweakValue("AutomaticRuntimeDatasource", false);
            }
        },
        openDashboardsOption(selectedShape){
            this.selectedShape = selectedShape
            this.selectedShape.active = true
        },
        widgetOptionChanged(){
            this.selectedShape = this.lastSelectedShape;
            this.selectedShape.active = true
        },
        goToAssetsRegister(listOfAssets, machineName) {
            this.$router.push({ name: 'assetsRegister', params: { listOfAssetsForMachine : listOfAssets, machine: machineName }})
        },
        getAllAssets() {
            let self = this;
            Entity.getAllEntities().then(t => {
                if(t){
                    self.listOfAssets = [];
                    self.listOfAssetsId = [];
                    self.listOfAssets = t.filter(item => {return item.EntityKeys.SystemEntity === 'Asset'});
                    self.listOfAssets.map(item => {
                        self.listOfAssetsId.push(item[item.Name].assetId);
                    })

                }
            })
        },
        dashboardChanged(key, selectedDashboard) {
            this.selectedDashboards[key] = selectedDashboard;
        },
        deleteDashboardCombobox(key){
            this.selectedDashboards.splice(key, 1);
            this.selectedDashboard = '';
        },
        loadDataMappings() {
            let self = this;
            this.$devices.selectDeviceFromIndexPatterns([ this.$defines.getDataPatternDescriptor(["production", "counters"], true) ], false, true)
                .then(devices => {
                    if(Array.isUseful(devices))
                        self.createDataItems(devices[0]);
                })
                .finally(() => {
                    self.calculateLinks();
                    self.dataItemsLength = self.dataItems.length;
                    self.stateChanged = true;
                })
        },

        parseLoadedLineMachines(devices) {
            let lineMachines = this.$settings.getLineSettings().machines;
            let parsedArray = [];

            if(Array.isUseful(lineMachines)) {
                let self = this;
                lineMachines.forEach((lineMachine, index) => {
                    let obj = {"dataIndex": "", "device": lineMachine, "text": "", "value": "", "custom": true} ;

                    devices.forEach(item => {
                        if(lineMachine === item.device) {
                            obj.dataIndex = item.dataIndex;
                            obj.text = item.text;
                            obj.value = item.value;
                            obj.custom = false;
                        }
                    });

                    parsedArray.push(obj);
                });
            } else {
                parsedArray = devices;
            }

            return parsedArray
        },

        createDataItems(devices) {
            this.filterItems.clear();
            this.dataItems.clear();
            this.aggregationItems.clear();
            this.descriptor.shapes.clear();
            this.visualizationTargets.clear();

            let parsedLineMachines = this.parseLoadedLineMachines(devices);
            let cellWidth = ((this.containerWidth - parsedLineMachines.length * 20) / parsedLineMachines.length) /*/ this.xScale*/;
            let cellHeight = 250 /*/ this.yScale*/;

            for(let [idx, element] of parsedLineMachines.entries()) {
                let device = element.device;
                let index = element.dataIndex;
                let oeeIndex = index;
                let customDevice = element.custom;
                if(!oeeIndex.startsWith("OEE_"))
                    oeeIndex = "OEE_" + oeeIndex;

                let shapeId = this.addShape('shape','', device.toUpperCase(), idx * cellWidth, (this.containerHeight - cellHeight) / 2, cellWidth, cellHeight, device, false);
                if(!customDevice) {
                    this.dataItems.push(
                        {
                            index: oeeIndex,
                            root: device,
                            name: 'Total',
                            type: this.$defines.WellKnownTypes.OEEComponent.id,
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.oeeraw.id,
                                    filters: [],
                                    target: shapeId,
                                    aggregationWindow: 0,
                                    defaultName: 'Total',
                                    name: 'Total',
                                    id: "total_" + device + "_" + index, //Create a unique id that survives changes
                                    normalization: '+ " %"',
                                    enabled: true,
                                    show: true
                                },
                            ],
                        },
                        {
                            index: oeeIndex,
                            root: device,
                            name: 'Availability',
                            type: this.$defines.WellKnownTypes.OEEComponent.id,
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.oeeraw.id,
                                    filters: [],
                                    target: shapeId,
                                    aggregationWindow: 0,
                                    defaultName: 'Availability',
                                    name: 'Availability',
                                    id: "availability_" + device + "_" + index, //Create a unique id that survives changes
                                    normalization: '+ " %"',
                                    enabled: true,
                                    show: true
                                },
                            ],
                        },
                        {
                            index: oeeIndex,
                            root: device,
                            name: 'Efficiency',
                            type: this.$defines.WellKnownTypes.OEEComponent.id,
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.oeeraw.id,
                                    filters: [],
                                    target: shapeId,
                                    aggregationWindow: 0,
                                    defaultName: 'Efficiency',
                                    name: 'Efficiency',
                                    id: "efficiency_" + device + "_" + index, //Create a unique id that survives changes
                                    normalization: '+ " %"',
                                    enabled: true,
                                    show: true
                                },
                            ],
                        },
                        {
                            index: oeeIndex,
                            root: device,
                            name: 'Quality',
                            type: this.$defines.WellKnownTypes.OEEComponent.id,
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.oeeraw.id,
                                    filters: [],
                                    target: shapeId,
                                    aggregationWindow: 0,
                                    defaultName: 'Quality',
                                    name: 'Quality',
                                    id: "quality_" + device + "_" + index, //Create a unique id that survives changes
                                    normalization: '+ " %"',
                                    enabled: true,
                                    show: true
                                },
                            ],
                        },
                        {
                            index: index,
                            root: device,
                            name: 'AverageSpeed',
                            type: this.$defines.WellKnownTypes.OEEComponent.id,
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.oeeraw.id,
                                    filters: [],
                                    target: shapeId,
                                    aggregationWindow: 0,
                                    defaultName: 'Average Speed',
                                    name: 'Average Speed',
                                    id: "Average Speed" + device + "_" + index, //Create a unique id that survives changes
                                    normalization: '+ " ppm"',
                                    enabled: true,
                                    show: true
                                },
                            ],
                        },
                        {
                            index: index,
                            root: device,
                            name: 'Status',
                            type: this.$defines.avionicsDataTypes.number.id,
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.last.id,
                                    filters: [],
                                    target: shapeId + "GREEN",
                                    aggregationWindow: 0,
                                    defaultName: 'Status up',
                                    name: 'Status up',
                                    id: "statusup_" + device + "_" + index, //Create a unique id that survives changes
                                    enabled: true,
                                    show: true
                                },
                                {
                                    type: this.$defines.allAggregations.last.id,
                                    filters: [],
                                    target: shapeId + "RED",
                                    aggregationWindow: 0,
                                    defaultName: 'Status down',
                                    name: 'Status down',
                                    id: "statusdown_" + device + "_" + index, //Create a unique id that survives changes
                                    enabled: true,
                                    show: true
                                }
                            ],
                        }
                    );
                }
            }

            this.calculateCrop();
            this.buildVisualizationTargets();
            this.$emit('dataItemsUpdated');
        },
        uploadImageCallback(file){
            if (file){
                const reader = new FileReader();
                let self = this;
                reader.onload = () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    let id=this.getShapeId()
                    let img = new Image();
                    let shapeImage={
                        type:"image",
                        active:true,
                        lineDirection:"",
                        id:id,
                        statusId: self.descriptor.shapes.length + 1 + 1000,
                        x:0,
                        y:0,
                        width:100,
                        height:150,
                        dashboardLinks:[],
                        name:"",
                        labels:[],
                        borderColor: "",
                        image:"data:image/png;base64," + encoded
                    };
                    img.onload = () => {
                        let xScale=self.canvasWidth/img.width;
                        let yScale=self.canvasHeight/img.height;
                        let imgHeight=img.height*Math.min(xScale,yScale);
                        let imgWidth=img.width*Math.min(xScale,yScale);
                        shapeImage.width=imgWidth - 5;
                        shapeImage.height=imgHeight - 5;
                        if(self.descriptor.shapes.length>0)
                            self.descriptor.shapes.unshift(shapeImage);
                        else
                            self.descriptor.shapes.push(shapeImage);
                    };

                    img.src = "data:image/png;base64," + encoded;
                };
                reader.readAsDataURL(file);
            }
        },
        onEditMode(editMode){

            let event = new CustomEvent("expandElement", {detail:{expanded:editMode}});
            document.dispatchEvent(event);
            if(!editMode && this.synLoading){
                setTimeout(()=>{
                    this.synLoading=false;
                },1500);
            }
        },
        onActivated (id) {
            let self=this;
            this.descriptor.shapes.map(el => {
                if(el.id===id) {
                    self.lastSelectedShape = el;
                    self.lastSelectedShape.active = true;
                    setTimeout(()=>{
                        self.selectedShape = el;
                    },301);
                }
            });
        },
        onDeactivated (id) {
            let self=this;
            this.descriptor.shapes.map(el => {
                if(el.id===id){
                    el.active=false
                }});
            setTimeout(() => {
                self.selectedShape = {};
            },300)
        },
        onResize: function (id, x, y, width, height) {
            let self = this;
            self.setShapeProperties(id, shape => {
                shape.x = x;
                shape.width = width;
                shape.height = height;
            })
            //Needed to recalculate text autosizing which only watches for window resizes and not container resizes
            window.dispatchEvent(new Event('resize'));
        },
        onDragEnd(id, left, top) {
            let self = this;
            self.setShapeProperties(id, shape => {
                shape.x = left;
                shape.y = top;
            })
        },
        getShapeId(){
            let id=0;
            let found = false;
            while(!found) {
                id++;
                let shape=this.descriptor.shapes.find(el=>{return el.id===id});
                if (!shape){
                    found = true;
                    break;
                }
            }
            return id;
        },
        addShape(type, direction, name, x, y, w, h, machineName, saveDescriptor = true) {
            let newShape = {}
            this.customized = saveDescriptor;
            if (type === "image") {
                document.getElementsByName("uploadFile")[0].click()
            } else {
                let id = this.getShapeId();
                newShape = {
                    type: type,
                    active: true,
                    id: id,
                    statusId: this.descriptor.shapes.length + 1 + 1000,
                    x: x || 0,
                    y: y || 0,
                    width: w || 100,
                    height: h || 150,
                    dashboardLinks: [],
                    name: name || "",
                    labels: [],
                    borderColor: this.$avStyle.colors.lightGrey,
                    image: "",
                    machineName: machineName
                };
                if (type === 'line') {
                    newShape = {
                        ...newShape,
                        lineDirection: direction,
                    }
                }
                if (type === 'widget') {
                    newShape = {
                        ...newShape,
                        dashboardElementDescriptor: {
                            state: 0,
                            id: Date.now().toString(),
                            widget: "",
                            shapeOptions: {
                                hideBorder: {
                                    name: this.$gettext("Hide border"),
                                    value: false
                                },
                                showTimeWindow: {
                                    name: this.$gettext('Show Time Window'),
                                    value: false
                                },
                                menuButtonHidden: {
                                    name: this.$gettext('Hide Menu Button'),
                                    value: false
                                },
                                showTitleBar: {
                                    name: this.$gettext('Show Title Bar'),
                                    value: false
                                },
                                useTimeWindowDefinedInWidget: {
                                    name: this.$gettext('Use time window defined in widget'),
                                    value: false
                                }
                            }
                        }
                    };
                }
                this.descriptor.shapes.push(newShape);
                return id;
            }
        },
        deleteShape(){
            this.descriptor.shapes.removeItem(this.selectedShape);
            let self=this;
            let vtExist=this.visualizationTargets.map(item=> {
                if(item.id === self.lastSelectedShape.id) {
                    item.name=self.lastSelectedShape.name;
                    return item;
                }
            });
            this.visualizationTargets.removeItem(vtExist[0]);
            this.lastSelectedShape={};
            this.buildVisualizationTargets();
        },
        onNameChange(){
            this.buildVisualizationTargets();
        },
        buildVisualizationTargets() {
            this.visualizationTargets.clear();
            this.descriptor.shapes.forEach(shape => {
                this.visualizationTargets.push({show: shape.name, id: shape.id});
                this.visualizationTargets.push({show: shape.name + " GREEN", id: shape.id + "GREEN"});
                this.visualizationTargets.push({show: shape.name + " RED", id: shape.id + "RED"});
                this.visualizationTargets.push({show: shape.name + " BLUE", id: shape.id + "BLUE"});
                this.visualizationTargets.push({show: shape.name + " GREY", id: shape.id + "GREY"});
            });
        },
        async refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view
            if (!dataValues) {
                return;
            }

            this.dontSaveDescriptor = true;

            this.descriptor.shapes.forEach(shape => {
                if(shape.type === 'shape')
                    shape.labels.clear();
            });

            dataValues.forEach(dataSet => {
                if (Array.isUseful(dataSet.data)) {
                    //Data labels
                    this.descriptor.shapes.map(shape => {
                        if (shape.id === dataSet.target) {
                            shape.labels.push({
                                key: dataSet.label,
                                value: dataSet.data.last().y
                            })
                        }
                    });
                }
            });

            //Colors coding
            for (let shapeIndex = 0; shapeIndex < this.descriptor.shapes.length; shapeIndex++) {
                if(this.descriptor.shapes[shapeIndex].type !== 'shape')
                    continue;
                if(Array.isUseful(this.descriptor.shapes[shapeIndex].statusItems))
                    this.descriptor.shapes[shapeIndex].statusItems.clear();
                let shapeData = dataValues.filter((item) => {
                    if(!isNaN(item.target))
                        return (item.target === this.descriptor.shapes[shapeIndex].id);
                    else if(typeof item.target === 'string')
                        return item.target.startsWith(this.descriptor.shapes[shapeIndex].id);
                    return false;
                });
                //No data binded or currently flowing through shape. Set it grey and go on
                if(!Array.isUseful(shapeData) || !Array.isUseful(shapeData.filter(item => Array.isUseful(item.data)))) {
                    this.descriptor.shapes[shapeIndex].status = "grey";
                    continue;
                } else { //If we have data let's start binding the configured default color
                    this.descriptor.shapes[shapeIndex].status = this.getTweakValue("boxColorWithData");
                }
                let data = {
                    green: null,
                    red: null,
                    grey: null,
                    blue: null
                };
                for (let dataIndex = 0 ; dataIndex < shapeData.length ; dataIndex++) {
                    if(!Array.isUseful(shapeData[dataIndex].data))
                        continue;
                    let target = "";
                    if(shapeData[dataIndex].target === this.descriptor.shapes[shapeIndex].id + "GREEN")
                        target = "green";
                    else if(shapeData[dataIndex].target === this.descriptor.shapes[shapeIndex].id + "RED")
                        target = "red";
                    else if(shapeData[dataIndex].target === this.descriptor.shapes[shapeIndex].id + "BLUE")
                        target = "blue";
                    else if(shapeData[dataIndex].target === this.descriptor.shapes[shapeIndex].id + "GREY")
                        target = "grey";
                    else continue;

                    let item = shapeData[dataIndex].data.last();
                    //Check if value can concur to item color assignation
                    if(item.y) {
                        if(data[target] === null)   //It will assign color if that's the only one associated to that color
                            data[target] = { value: item, dataSetIndexes: [] };
                        else if (item.x > data[target].x) //Or if status is more recent than the other datasets
                            data[target].value = item;
                        //Nevertheless store all datasets concurring to color so that we can later show the full set of conditions
                        data[target].dataSetIndexes.push(dataIndex)
                    }
                }

                //In case of multiple conditions keep the latest
                for(let target in data) {
                    if(data[target]) {
                        for(let targetAgain in data) {
                            if(data[targetAgain] && data[target].value.x < data[targetAgain].value.x) {
                                data[target] = null;
                                break;
                            }
                        }
                    }
                }

                //A special case for legacy configs were the same boolean variable was binded to both green and red
                //this was typically used for machine status. In this case a true value maps to green, red otherwise
                if(data.red && data.green) {
                    if(data.red.value.y === data.green.value.y && data.green.value.y) {
                        this.descriptor.shapes[shapeIndex].status = "green";
                        continue;
                    }
                }

                //Prioritize remaining based on color
                if(data.red !== null) {
                    this.descriptor.shapes[shapeIndex].status = "red";
                    this.descriptor.shapes[shapeIndex].statusItems = this.getStatusItemsToShow(shapeData, data.red.dataSetIndexes);
                } else if(data.grey !== null) {
                    this.descriptor.shapes[shapeIndex].status = "grey";
                } else if(data.blue !== null) {
                    this.descriptor.shapes[shapeIndex].status = "blue";
                } else if(data.green !== null) {
                    this.descriptor.shapes[shapeIndex].status = "green";
                }
            }
        },
        getStatusItemsToShow(dataSets, dataSetsIndexes) {
            let returning = [];
            if(!Array.isUseful(dataSetsIndexes))
                return [];
            for(let index of dataSetsIndexes) {
                returning.push(dataSets[index].label + ": " + dataSets[index].data.last().y)
            }
            return returning;
        },
        getCustomDescriptor(){
            if (!this.customized)
                return { shapes: [] };
            return this.descriptor;
        },
        setCustomDescriptor(descriptor) {
            let self = this;
            if (this.editMode) {
                this.synLoading = true;
                this.onEditMode(true);
            }
            setTimeout(() => {
                self.descriptor = descriptor;
                if (descriptor.shapes.length === 0) {
                    setTimeout(() => {
                        self.customized = false;
                        self.loadDataMappings();
                    }, 3000);
                } else {
                    self.buildVisualizationTargets();
                    self.calculateCrop();
                    self.customized = true;
                }
                setTimeout(() => {
                    self.onEditMode(false)
                }, 300)
            }, 400)

        },

        loadDashboard(){
            let self = this;
            self.$dynamicElements.listAll("dashboards", false)
                .then(result => {
                    self.dashboards = result.active;
                })
                .catch(err => {
                    console.log(err);
                })
        },
        chooseDashboard(shapeId){
            this.selectedShapeId = shapeId;
            let self = this;
            this.descriptor.shapes.map(shape=> {
                if (shape.id === shapeId) {
                    self.selectedDashboards = shape.dashboardLinks ? shape.dashboardLinks : [];
                    self.selectedAsset = shape.selectedAsset ? shape.selectedAsset : [];
                    if (self.autoAssetBinding) {
                        self.automaticBinding = shape.automaticBinding;
                        self.listOfAssetsForMachine = shape.listOfAssets;
                        self.selectedAsset = shape.selectedAsset;
                    }
                }
            });
            this.dialog = true;
        },
        setShapeProperties(shapeId, setterFunction) {
            let self = this;
            self.descriptor.shapes.map(shape => {
                if(shape.id === shapeId) {
                    self.customized = true;
                    self.setTweakValue("AutomaticRuntimeDatasource", false);
                    if(setterFunction)
                        setterFunction(shape);
                    return shape;
                }
            });
        },
        addDashboardLinkToShape(clear = false) {
            let self = this;
            if(clear)
                self.selectedDashboards = [];
            if (self.selectedDashboard)
                self.selectedDashboards = Array.from(new Set(self.selectedDashboards));
            self.setShapeProperties(self.selectedShapeId, shape => {
                shape.dashboardLinks = self.selectedDashboards;
                shape.selectedAsset = self.selectedAsset;
                shape.automaticBinding = self.automaticBinding;
                if(!Object.isUseful(shape.listOfAssets))
                    shape.listOfAssets = [];
                if (Array.isUseful(shape.listOfAssets) && shape.selectedAsset.length !== shape.listOfAssets.length) {
                    shape.selectedAsset.forEach(asset => {
                        shape.listOfAssets = shape.listOfAssets.filter(item => item.itemName === asset);
                    })
                }
                if (!self.autoAssetBinding) {
                    shape.selectedAsset.forEach(asset => {
                        shape.listOfAssets.push(self.listOfAssets.find(item => item.itemName === asset));
                    })
                }
                self.selectedShapeId = 0;
                self.selectedDashboards = [];
                self.dialog = false;
                setTimeout(() => {
                    shape.active = true;
                }, 500)
            })

        },
        closeDashboardDialog(){
            this.dialog=false;
            this.descriptor.shapes.map(shape=>{
                if(shape.id===this.selectedShapeId){
                    setTimeout(()=>{
                        shape.active=true;
                    },500);
                }
            });
            this.selectedShapeId=0;
            this.selectedDashboards=[];
        },
        openDashboard(dashboardLink){
            if (dashboardLink && !this.editMode){
                this.$root.openDashboard(dashboardLink, null)
            }
        },
        //APicaro calculate white space around initial graphics
        //in order to crop all available space before scaling
        calculateCrop() {
            if (this.descriptor === null) {
                return;
            }
            if(!Array.isUseful(this.descriptor.shapes))
                return;
            //First of all calculate global dimensions of shapes
            let xMin = 10000000;
            let xMax = 0;
            let yMin = 10000000;
            let yMax = 0;
            this.descriptor.shapes.forEach(shape=>{
                if(shape.x < xMin)
                    xMin = shape.x;
                if(shape.y < yMin)
                    yMin = shape.y;
                if((shape.x + shape.width) > xMax)
                    xMax = shape.x + shape.width;
                if((shape.y + shape.height) > yMax)
                    yMax = shape.y + shape.height;
            });

            this.cropX = xMin;
            this.cropY = yMin;
            this.cropW = xMax - xMin;
            this.cropH = yMax - yMin;
        },
        autoDataSourcingConfirm() {
            this.saveTweaks();
            this.loadDataMappings();
        },
        autoDataSourcingUndo() {
            this.wasAutoDataSourcing = false;
            this.setTweakValue("AutomaticRuntimeDatasource", false);
            this.saveTweaks();
        },
        checkIfAssetsContainsMachine(machine) {
            let self = this;
            let selectedAssets = [];
            let listOfAssets = [];
            for (let asset of self.listOfAssets) {
                asset.itemName = asset[asset.NameSpace].assetId;
                if (asset[asset.NameSpace].LinkedMachines && asset[asset.NameSpace].LinkedMachines.includes(machine)) {
                    selectedAssets.push(asset[asset.NameSpace].assetId);
                    listOfAssets.push(asset);
                }
            }
            return { selectedAssets:  selectedAssets, listOfAssets: listOfAssets };
        },
        automaticBindChange() {
            let self = this;
            self.selectedAsset.clear();
            self.listOfAssetsForMachine.clear();
            if (self.automaticBinding) {
                self.descriptor.shapes.map(shape=> {
                    if (shape.id === self.selectedShapeId) {
                        let result = self.checkIfAssetsContainsMachine(shape.name.toLowerCase());
                        shape.selectedAsset = result.selectedAssets;
                        shape.listOfAssets = result.selectedAssets;
                        self.selectedAsset = shape.selectedAsset;
                        self.listOfAssetsForMachine = shape.listOfAssets;
                    }
                });
            } else {
                for (let asset of self.listOfAssets) {
                    self.listOfAssetsForMachine.push(asset);
                }
                self.selectedAsset = [];
            }
        },
        calculateLinks() {
            let self = this;
            if (self.descriptor != null) {
                for (let shape of self.descriptor.shapes) {
                    let result = self.checkIfAssetsContainsMachine(shape.name.toLowerCase());
                    shape.selectedAsset = result.selectedAssets;
                    shape.listOfAssets = result.listOfAssets;
                    shape.automaticBinding = true;
                }
            }
        },
        resetLinks() {
            let self = this;
            // if (self.descriptor != null) {
            //     for (let shape of self.descriptor.shapes) {
            //         if (shape.automaticBinding === true) {
            //             shape.selectedAsset = [];
            //             shape.listOfAssets = [];
            //             shape.automaticBinding = false;
            //         }
            //     }
            // }
            for (let asset of self.listOfAssets) {
                asset.itemName = asset[asset.NameSpace].assetId;
                self.listOfAssetsForMachine.push(asset);
            }
            self.selectedAsset = [];
        }
    },
    watch:{

        openDashboardOptions: function(val) {
            if(!val)
                this.lastSelectedShape = {}
        },
        'dataItems' : {
            handler: function () {
                if(!Array.isUseful(this.dataItems)) {
                    for(let i=0;i<this.descriptor.shapes.length;i++) {
                        this.descriptor.shapes[i].labels.clear();
                    }
                }
            },
            deep: true,
        },
        containerHeight(newValue) {
            this.calculateCrop();
        },
        descriptor:{
            deep:true,
            handler() {
                //Avoid falsy saves when descriptor is updated by data refresh
                if(this.dontSaveDescriptor) {
                    this.dontSaveDescriptor = false;
                    return;
                }
                let self = this;
                setTimeout(()=>{
                    if (self.editMode){
                        self.saveKeepingVersion()
                    }
                }, 2000)
            }
        },
        visualizationTweaks: {
            handler: function () {
                let autoSourcing = this.getTweakValue("AutomaticRuntimeDatasource");
                if(autoSourcing && autoSourcing !== this.wasAutoDataSourcing) {
                    this.wasAutoDataSourcing = autoSourcing;
                    if(this.editMode) {
                        this.$root.showDialogBox(this.$gettext("Item is set to source data automatically, do you want to overwrite previous configuration?"),
                            null, this.$gettext("Yes"), this.autoDataSourcingConfirm, this.$gettext("No"), this.autoDataSourcingUndo);
                    }
                    else {
                        this.saveTweaks();
                    }
                } else {
                    this.wasAutoDataSourcing = autoSourcing;
                    this.saveTweaks();
                    setTimeout(() => {
                        this.resetLinks()
                    }, 2000);
                }
            },
            deep: true
        },
    }
}
</script>

<style scoped>
#my-canvas {
    text-align: center;
}
.hide-vdr{
    border:none
}
</style>
