<template>
    <v-dialog transition="fade-transition" v-model="show" fullscreen full-width>
        <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.0);">
            <v-layout justify-center align-center>
                <!--<v-progress-circular-->
                        <!--indeterminate-->
                        <!--color="primary">-->
                <!--</v-progress-circular>-->
                <img :src="loaderGif" class="baseLoader" />
                <!--<label style="position: absolute; top: calc(50% + 80px);" class="title">{{message}}</label>-->
                <label style="position: absolute; top: calc(50% + 30px);" class="title">{{message}}</label>
            </v-layout>
        </v-container>
    </v-dialog>
</template>

<script>
    export default {
        name: "AvLoader.vue",
        data() {
            return {
                minTimeExpired: true,
                timer: null
            }
        },
        props: {
            loading: false,
            message: "",
            minTime: 0
        },
        computed: {
            loaderGif() {
                return require('@/assets/avloader.png');
            },
            show() {
                let self = this;
                if(this.minTime > 0 && this.loading && this.timer === null) {
                    this.minTimeExpired = false;
                    this.timer = setTimeout(() => {
                        self.minTimeExpired = true;
                        self.timer = null;
                    }, this.minTime)
                }
                if(this.loading)
                    return true;
                else {
                    return !this.minTimeExpired;
                }
            }
        },
    }
</script>

<style scoped>

    .baseLoader {
        position: absolute;
        top: calc(50% - 50px);
        /*left: calc(50% - 132px);*/
        left: calc(50% - 125px);
        /*height: 100px;*/
        /*width: 265px;*/
        width: 250px;
        margin: 0;
        padding: 0;
    }

</style>