/*
    Rule descriptor prototype example
    {
       "properties":{
          "name":"Rule descriptor test",
          "timeWindow": 1,
          "ruleType":18,
          "schedule": "cron expression"
       },
       "inputs": {
           "queryDescriptor":{
              "agg":[

              ],
              "comp":[

              ],
              "raw":[
                 {
                    "name":"raw_0",
                    "q":"index=production counters@5s\\&fields=Line.WorkorderID,@timestamp\\&sort=@timestamp,asc,last"
                 }
              ],
              "time_from":"2020-04-02T18:12:30+02:00",
              "time_to":"2020-04-02T18:13:30+02:00"
           }
       },
       "processing": [],
       "conditions": [
           {
               "name": "Condition 1",
               "type": "expression",
               "value":[
                  {
                     "variable":"raw_0",
                     "operator":"IsEmpty",
                     "value":null
                  },
                  "AND",
                  "NOT",
                  [
                     {
                        "variable":"raw_1",
                        "operator":"IsEmpty",
                        "value":null
                     },
                     "OR",
                     {
                        "variable":"raw_1",
                        "operator":"=",
                        "value": "NO WORKORDER"
                     }
                  ]
               ]
           }
       ],
        output: [
            {
                name:"BatchStartedNotificationMail",
                trigger: "conditions.BatchStarted",
                value: {
                    type:"mail",
                    recipients: [
                        "andrea.picaro@antaresvision.com",
                        "domenico.difilippo@anataresvision.com"
                    ],
                    subject: "A new batch is starting",
                    body: "Batch {{.inputs.raw_0}} is starting"
                }
            },
            {
                name:"BatchStartedNotificationApi",
                trigger: "conditions.BatchChanged",
                value: {
                    type:"api",
                    url: "http://localhost:80/api/v1/doc/TestRuleHttpOut",
                    method: "POST",
                    body: JSON.stringify({
                        id: "Rule output {{.inputs.raw_0}}",
                        value: "{{.inputs.raw_0}}",
                        '@timestamp': DateTime.getRfc3339TimeStamp(new Date())
                    })
                }
            }
        ]
    }
*/

import Defines from '@/api/defines'
import Settings from '@/api/settings'
import VueInstance from '@/api/vueinstance'
import Config from '@/api/config'
import Utils from '@/api/jsutils'
import Data from '@/api/data'
import Audits from "./audits"
import DateTime from "./datetimeutils";
import DataEntry from "@/api/dataentry"
import Microservices from '@/api/microservices'
import OrchestratorAPI from "./orchestrator";
import Vue from 'vue'
import audits from "./audits";
import microservices from "./microservices";
import Normalizations from "./normalizations";
import jsutils from "@/api/jsutils";

export default {

    async getRuleDescriptorFromMeta(meta, user) {
        if (!Object.isUseful(meta))
            return;
        let rule = {
            type: "rule-engine",
            Name: meta.properties.name,
            properties: {
                name: meta.properties.name,
                type: meta.properties.type,
                schedule: this.getRTESchedule(meta.properties),
                initialReferenceTime: DateTime.getRfc3339TimeStamp(new Date(meta.properties.ruleScheduling.initialReferenceTime)),
                oneShoot: meta.properties.ruleScheduling.oneShoot
            },
            inputs: this.unwrapInputs(meta),
            processing: this.unwrapNormalizations(meta.data),
            conditions: this.unwrapConditions(meta.rules, meta.data),
            outputs: await this.unwrapOutputs(meta, meta.properties.visibleToLevels.map((item) => item.key), meta.properties.editableToLevels.map((item) => item.key)),
            version: meta.version
        };
        rule.finally = meta.properties.isActivity && meta.rules.length > 0 ? await this.unwrapFinally(meta, rule.conditions) : [];
        rule.init = meta.properties.isActivity && meta.rules.length > 0 ? this.unwrapInit(meta) : [];


        //DS take recursive items
        let recursiveItems = meta.data.filter(di => {
            return di.representations.find(dir => {
                return dir.recursiveParentId && dir.id !== dir.recursiveParentId
            })
        })

        //DS if recursive items exists apply conditions and outputs for each recursive item
        if (Array.isUseful(recursiveItems)) {
            let queryIdMap = {}
            //DS take non-recursive items
            let nonRecursiveItems = meta.data.filter(di => {
                return di.representations.find(dir => {
                    return dir.id === dir.recursiveParentId
                })
            })
            //DS calc map of association between not recursive items query id and recursive items query id
            nonRecursiveItems.forEach(di => {
                di.representations.forEach(re => {
                    recursiveItems.forEach(ri => {
                        ri.representations.forEach(rp => {
                            if (rp.recursiveParentId === re.id) {
                                if(!queryIdMap[re.queryId]){
                                    queryIdMap[re.queryId]={}
                                }
                                queryIdMap[re.queryId][ri.root] = rp.queryId
                            }
                        })
                    })
                })
            })
            //DS calc conditions for each recursionItem
            let baseConditions = jsutils.detach(rule.conditions)
            recursiveItems.forEach((ri, idx) => {
                ri.representations.forEach(re => {
                    let recursiveCondition = jsutils.detach(baseConditions)
                    let addCondition = true;
                    recursiveCondition.forEach(rc => {
                        rc.name = rc.name + "_" + idx
                        rc.value.every((rcv, idxval) => {
                            if(Array.isUseful(rcv)) {
                                rcv.every(rcvv => {
                                    if (typeof (rcvv) === 'object') {
                                        //take query id from value and variable
                                        let queryIdVar = rcvv.variable.indexOf('.') > -1 ? rcvv.variable.split('.').last() : rcvv.variable
                                        let queryIdVal = rcvv.value.indexOf('.') > -1 ? rcvv.value.split('.').last() : rcvv.value

                                        let typeQueryRecursiveConditions = queryIdVar.split("_")[0];
                                        let typeQueryRepresentation = re.queryId.split("_")[0];
                                        //FN Get the queryId children of the non recursive item query id
                                        const getValues = Object.values(queryIdMap[queryIdVar]);

                                        //FN Check the first condition group , the check is valid for all other conditions of the same group
                                        if(idxval === 0) {
                                            //FN Check the matching between the type of base condition query Id and recursive item query id check
                                            // and if recursive item queryID is a child of  non-recursive item query id in base condition
                                            addCondition = typeQueryRecursiveConditions === typeQueryRepresentation && getValues.includes(re.queryId);
                                        }

                                        if (!addCondition) {
                                            return addCondition;
                                        }

                                        //FN It's really useful??
                                        //if query id exist in map then replace it in value
                                        if (queryIdMap[queryIdVal]) {
                                            rcvv.value = rcvv.value.replace(queryIdVal, queryIdMap[queryIdVal][ri.root])
                                        }
                                        //if query id exist in map then replace it in variable
                                        if (queryIdMap[queryIdVar]) {
                                            rcvv.variable = rcvv.variable.replace(queryIdVar, queryIdMap[queryIdVar][ri.root])
                                        }

                                        // rule.conditions.push(rc);
                                    }
                                    return addCondition;
                                })
                            }
                            return addCondition;
                        })//
                        if(addCondition) {
                            rule.conditions.push(rc);
                        }
                    })
                });
            })

            //DS calc outputs for each recursionItem
            let baseOutputs = jsutils.detach(rule.outputs)
            recursiveItems.forEach((ri, idx) => {
                let recursiveOutputs = jsutils.detach(baseOutputs)
                recursiveOutputs.forEach(ro => {
                    ro.trigger = ro.trigger + "_" + idx
                    //DN check if in txt of outputs is present one or more variable to replace
                    let replaceRecursiveDataPlaceholders = function(fieldName) {
                        if(!ro.value[fieldName])
                            return;
                        let itemsToReplace = [... ro.value[fieldName].matchAll('[^{{\\}}]+(?=}})')]
                        //DN extract distinct of query id in matches
                        if (Array.isUseful(itemsToReplace)) {
                            itemsToReplace = itemsToReplace.map(itr => {
                                return itr[0];
                            }).filter((value, index, self) => {
                                return self.indexOf(value) === index;
                            })
                            //DN replace query id in text of output
                            itemsToReplace.forEach(itr => {
                                let queryId = itr.split('.').last();
                                if(queryIdMap[queryId]){
                                    ro.value[fieldName] = ro.value[fieldName].replaceAll(queryId, queryIdMap[queryId][ri.root]);
                                }
                            })
                        }
                    }
                    replaceRecursiveDataPlaceholders("arg")
                    replaceRecursiveDataPlaceholders("body")
                    replaceRecursiveDataPlaceholders("endpoint")
                })
                rule.outputs.push(...recursiveOutputs)
            })
        }

        console.log(JSON.stringify((rule)));
        return rule;
    },

    async getDescriptorForActivityRule(meta) {
        console.log(meta)
        if(!Object.isUseful(meta))
            return;
        let initialReferenceTime = meta.properties.ruleScheduling.initialReferenceTime === "" ? DateTime.getRfc3339TimeStamp(new Date()) : DateTime.getRfc3339TimeStamp(new Date(meta.properties.ruleScheduling.initialReferenceTime))
        return {
            name: meta.properties.name,
            Description: meta.properties.description,
            ActivityType: "deterministic",
            InitialReferenceTime: initialReferenceTime,
            NextTriggerTime: initialReferenceTime,
            OneShoot: meta.properties.ruleScheduling.oneShoot,
            Schedule: this.getRTESchedule(meta.properties),
            Duration: meta.properties.ruleScheduling.duration,
            Status: "backlog",
            Actions: await this.unwrapActivityOutputs(meta),
            tag: {
                VisibleTo: meta.properties.visibleToLevels,
                EditableBy: meta.properties.editableToLevels
            },
            '@timestamp': DateTime.getRfc3339TimeStamp(new Date()),
            Id: meta.properties.name,
            OpeningTimeSettings: {
                TimeSchedulingRestrictions: meta.properties.ruleScheduling.timeSchedulingRestrictions,
                WorkDays: meta.properties.ruleScheduling.workDays,
                WorkHours: meta.properties.ruleScheduling.workHours,
                NotWorkingDaysOfYear: meta.properties.ruleScheduling.notWorkingDaysOfYear,
            }
        };
    },

    async compileRule(meta) {
        let rule = await this.getRuleDescriptorFromMeta(meta);
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.rulesUrl + '/verify', rule)
                .then(result => {
                    if(!result)
                        resolve("");
                    if(result.error)
                        reject(result);
                    else resolve(result)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async deployRule(meta, user) {
        if (meta.properties.isActivity && meta.rules.length === 0){
            await this.deployActivityRule(meta)
        }
        else {
            let rule = await this.getRuleDescriptorFromMeta(meta, user);
            return new Promise((resolve, reject) => {
                OrchestratorAPI.proxyCall('post', Microservices.rulesUrl + '/start', rule)
                    .then(result => {
                        resolve(result)
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },

    async stopRule(ruleName, isDeterministic, isActivity, meta) {
        if (isDeterministic && isActivity) {
            await this.stopActivityRule(meta)
        } else {
            return new Promise((resolve, reject) => {
                OrchestratorAPI.proxyCall('post', Microservices.rulesUrl + '/stop', ruleName)
                    .then(result => {
                        resolve(result)
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },

    async stopActivityRule(meta) {
        return this.deployActivityRule(meta,true)
        // return new Promise((resolve) => {
        //     OrchestratorAPI.proxyCall('delete', Microservices.activityRuleUrl + "/" + ruleId)
        //         .then(response => {
        //             resolve(response);
        //         })
        //         .catch(response => {
        //             console.log(response);
        //         });
        // });
    },

    async deployActivityRule(meta, disabled=false) {
        let rule = await this.getDescriptorForActivityRule(meta)
        if (disabled){
            rule.Status="disabled"
        }
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.activityRuleUrl + '/{0}'.format(rule.Id), rule)
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async GetActivitiesWithinTimeWindow(startTime, endTime) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.activityRuleUrl + '/*/{0}/{1}'.format(startTime, endTime))
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    getBackendBaseUrl() {
        return window.location.protocol + "//" + window.location.hostname + (Config.debug ? ":80" : (window.location.port ? (":" + window.location.port) : "")) + "/api/v1";
    },

    getRTESchedule(properties) {
        if(properties.type === 2100)
            return "triggered";
        if(properties.ruleScheduling.cron.startsWith("interval"))
            return "{0}m".format(this.getScheduleIntervalInMinutes(properties.ruleScheduling.cron));
        return properties.ruleScheduling.cron;
    },

    getScheduleIntervalInMinutes(schedule) {
        let interval = schedule.replace("interval", "").trim();
        let num = parseInt(interval);
        let type = interval.slice(-1);
        switch (type) {
            case "m":
                return num;
            case "h":
                return num * 60;
            case "d":
                return num * 1440;
            case "w":
                return num * 10080;
        }
    },

    unwrapInputs(descriptor) {
        let inputs = [];
        if(descriptor.queryDescriptor && (Array.isUseful(descriptor.queryDescriptor.raw) ||
            Array.isUseful(descriptor.queryDescriptor.agg) || Array.isUseful(descriptor.queryDescriptor.comp))) {

            //Resolve aggregation windows
            let body = Data.calculateTimeWindows(descriptor.queryDescriptor, descriptor.properties.timeWindow.getStart(), descriptor.properties.timeWindow.getEnd(), descriptor.properties.ruleScheduling.queryFromLastUsefulRuleResult);
            if(descriptor.properties.ruleScheduling.queryFromLastUsefulRuleResult) {
                body.time_from = "{{if .cache.LastUsefulResultTime}}{{.cache.LastUsefulResultTime}}{{else if .cache.InitialReferenceTime}}{{.cache.InitialReferenceTime}}{{else}}{{now}}{{end}}"; //Not a regular time window, query start time is calculated based on cache state starting from last useful rule result
                body.time_to = "{{now}}";   //Query up to current rule execution time
            } else if(descriptor.properties.timeWindow.predefined) {
                body.time_from = ("{{TimeWindowStart" + (typeof descriptor.properties.timeWindow.predefined ==='string'? "_{0}":" {0}") + "}}").format(descriptor.properties.timeWindow.predefined);
                body.time_to = ("{{TimeWindowEnd" + (typeof descriptor.properties.timeWindow.predefined ==='string'? "_{0}":" {0}") + "}}").format(descriptor.properties.timeWindow.predefined);
            } else {
                body.time_from = DateTime.getRfc3339TimeStamp(descriptor.properties.timeWindow.getStart());
                body.time_to = DateTime.getRfc3339TimeStamp(descriptor.properties.timeWindow.getEnd());
            }
            inputs.push({
                type:"microservice-call",
                microservice: "elastic-query",
                method: "GetDataJSON",
                arg: JSON.stringify(body),
                outmode:"raw"
            });
        }
        return inputs;
    },

    getExportStep(descriptor, exportItem) {
        let time_from;
        let time_to;
        if(descriptor.properties.timeWindow.predefined) {
            time_from = ("{{TimeWindowStart" + (typeof descriptor.properties.timeWindow.predefined ==='string'? "_{0}":" {0}") + "}}").format(descriptor.properties.timeWindow.predefined);
            time_to = ("{{TimeWindowEnd" + (typeof descriptor.properties.timeWindow.predefined ==='string'? "_{0}":" {0}") + "}}").format(descriptor.properties.timeWindow.predefined);
        } else {
            time_from = DateTime.getRfc3339TimeStamp(descriptor.properties.timeWindow.getStart());
            time_to = DateTime.getRfc3339TimeStamp(descriptor.properties.timeWindow.getEnd());
        }
        return {
            type: "microservice-call",
            microservice: "avionics-reports",
            method: "Export",
            "output-format":[{
                type:"from.base64",
                field: exportItem.name.split('.')[0],
                to: exportItem.name.replace('.', '')
            }],
            arg: JSON.stringify({
                format: exportItem.type,
                type: exportItem.itemType ? exportItem.itemType : "reports",
                name: exportItem.name.split('.')[0],
                from: time_from,
                to: time_to,
                filters: this.getGlobalFiltersObject(exportItem.parametricFilters, descriptor.data)
            }),
            outmode: "raw",
        }
    },

    getGlobalFiltersObject(requestedFilters, dataItems) {
        let filters = {};
        if(!Array.isUseful(requestedFilters))
            return filters;
        for(let filter of requestedFilters)
            Data.forEachRepresentation(dataItems, (representation, dataIndex) => {
                if(filter.root === dataItems[dataIndex].root && filter.name === dataItems[dataIndex].name)
                    filters[dataItems[dataIndex].root + "." + dataItems[dataIndex].name] = "{{.inputs.previous.{0}}}".format(representation.queryId)
            });
        return filters;
    },

    async unwrapFinally(descriptor,conditions){
        //todo if is activity type
        //activityTipe: 0 -> timeBased 1 -> countersBased 2 -> machineRuntimeBased
        let activities = [];
        let activity = {};
        let type = "";
        switch (descriptor.properties.activityType) {
            case 0:
                type="timeBased";
                break;
            case 1:
                type="countersBased";
                break;
            case 2:
                type="machineRuntimeBased";
                break;
            default:
                type="timeBased"
        }

        let body = {
            '@timestamp': DateTime.getRfc3339TimeStamp(new Date()),
            Id: descriptor.properties.name,
            name: descriptor.properties.name,
            ActivityType: "datadriven",
            Description:descriptor.properties.description,
            InitialReferenceTime: "{{.cache.InitialReferenceTime}}",
            LastTriggerTime:"{{.cache.LastUsefulResultTime}}",
            // NextTriggerTime:"{{.cache.NextTriggerTime}}",
            OneShoot: descriptor.properties.ruleScheduling.oneShoot,
            Schedule: this.getRTESchedule(descriptor.properties),
            IsUseFull: "{{.cache.IsUseful}}",
            Status: "{{if .cache.IsUseful}}backlog{{else}}forecast{{end}}",
            Duration: descriptor.properties.ruleScheduling.duration,
            Actions: await this.unwrapActivityOutputs(descriptor),
            Name: descriptor.properties.name,
            tag: {
                VisibleTo: descriptor.properties.visibleToLevels.map( (item) => item.key),
                EditableBy: descriptor.properties.editableToLevels.map( (item) => item.key)
            },

            [descriptor.properties.name]:{}
        }

        if (conditions.length === 1 && conditions[0].value.length === 1 && conditions[0].value[0].length === 1 &&
            (conditions[0].value[0][0].operator === ">" || conditions[0].value[0][0].operator === ">=") && type !== "timeBased") {
            body[body.name].currentValue="{{"+conditions[0].value[0][0].variable+"}}";
            body[body.name].targetValue=conditions[0].value[0][0].value;
        }
        // else if (type!=="timeBased") {
        //     body[body.Name].msg="Not estimable"
        // }

        let arg = {
            id: descriptor.properties.name,
            body: JSON.stringify(body)
        };

        activity={
            name:"",
            trigger:"",
            triggerMode:"",
            value:{
                type: "microservice-call",
                microservice: "avionics-activities",
                method: "SaveOrUpdateActivity",
                arg: JSON.stringify(arg),
                outmode:"raw"
            }
        };
        activities.push(activity);
        return activities

    },

    unwrapInit(descriptor){
        let activities=[];
        let activity={};
        let type="";
        switch (descriptor.properties.activityType) {
            case 0:
                type="timeBased";
                break;
            case 1:
                type="countersBased";
                break;
            case 2:
                type="machineRuntimeBased";
                break;
            default:
                type="timeBased";
        }

        let body = {
            Id: descriptor.properties.name,
            Type: "activities",
            Name: descriptor.properties.name,
            Description:descriptor.properties.description,
            '@timestamp': DateTime.getRfc3339TimeStamp(new Date()),
            InitialReferenceTime: "{{.cache.InitialReferenceTime}}",
            NextTriggerTime:"{{.cache.NextTriggerTime}}",
            ActivityType:type,
            [descriptor.properties.name]:{}
        };
        // if (type!=="timeBased"){
        //     body[body.Name].msg="Not estimable"
        // }

        let arg={
            scope:"user",
            index:"activities",
            method:"POST",
            body: JSON.stringify(body)
        };
        activity = {
            name:"",
            trigger:"",
            triggerMode:"",
            value:{
                type: "microservice-call",
                microservice: "elastic-documents",
                method: "ManageInternalIndex",
                arg: JSON.stringify(arg),
                outmode:"raw"
            }
        };
        activities.push(activity);
        return activities
    },

    unwrapNormalizations(dataItems) {
        let normalizations = [];
        let self = this;
        Data.forEachRepresentation(dataItems, (representation, itemIndex) => {
            if(Array.isUseful(representation.normalizations)) {
                let variable = self.getVariableId(representation, dataItems, false);
                // let variableSrc = "data" + variable;
                // let variableDst = variable.substring(1);
                for(let normalization of representation.normalizations) {
                    normalizations.push({
                        type: "javascript",
                        to: variable,
                        value: [self.processNormalization(normalization, variable)]
                    })
                }
            }
        });

        return normalizations;
    },

    async unwrapOutputs(descriptor, visibleTo, editableTo){
        let resOutputs = [];
        let mailSettings = Object.isUseful(Settings.getMailSettings()) ? Settings.getMailSettings() : await Settings.loadMailSettings();
        let fields = {};


        for (let i = 0 ; i < descriptor.outputs.length ; i++){
            let output = descriptor.outputs[i];
            let resOutput = {};

            switch (output.outputType) {
                case 'email': {
                    let attachments = {};
                    resOutput = [];
                    for (let i = 0; i < output.email.attach.length; i++) {
                        let exportConfig = this.getExportStep(descriptor, output.email.attach[i]);
                        resOutput.push({
                            name:"",
                            trigger: (output.trigger.name ? "conditions."+output.trigger.name : ""),
                            triggerMode: output.triggerMode,
                            useful: true,
                            value: exportConfig
                        });
                        attachments[output.email.attach[i].name.split('.')[0] + " " + new Date().format("yyyyMMdd HH.mm") + '.' + output.email.attach[i].type] = output.email.attach[i].name.replace(".", "");
                    }
                    resOutput.push({
                        name:"",
                        trigger: (output.trigger.name ? "conditions." + output.trigger.name : ""),
                        triggerMode: output.triggerMode,
                        useful: true,
                        value: {
                            type:"mail",
                            recipients: output.email.recipients,
                            subject: output.email.subject,
                            body: this.replaceDataItemsInText(output.email.data, output.emailPlaceholders, descriptor.data),
                            attachments: (output.email.attach ? attachments : {}),
                            sender: mailSettings.sender, //"from@example.io",
                            server: mailSettings.server, //"smtp.mailtrap.io",
                            port: mailSettings.port.toString(), //"2525",
                            login: mailSettings.login, //"122aa2f0870716",
                            password: mailSettings.password //"ae9aa113f0564a"
                        }
                    });

                    break;
                }
                case 'restapi':
                    resOutput = {
                        name: "",
                        trigger: (output.trigger.name ? "conditions."+output.trigger.name : ""),
                        triggerMode:output.triggerMode,
                        value: {
                            type: "api",
                            endpoint: this.replaceDataItemsInText(output.api.url, output.apiPlaceholders, descriptor.data),
                            method: output.api.method,
                            body: this.replaceDataItemsInText(JSON.stringify(output.api.data), output.apiPlaceholders, descriptor.data),
                            headers: this.encodeHttpHeaders(output.api.headers)
                        }
                    };
                    break;

                // case 'redirect':
                //     resOutput = {
                //         name: "",
                //         trigger: (output.trigger.name ? "conditions." + output.trigger.name : ""),
                //         triggerMode: output.triggerMode,
                //         value: {
                //             type: "triggered_result",
                //             result: {
                //                 type: "redirect",
                //                 endpoint: this.replaceDataItemsInText(JSON.stringify(output.redirect.url), output.redirectPlaceholders, descriptor.data)
                //             }
                //         }
                //     };
                //     break;
                //
                // case 'error':
                //     resOutput = {
                //         name: "",
                //         trigger: (output.trigger.name ? "conditions." + output.trigger.name : ""),
                //         triggerMode: output.triggerMode,
                //         value: {
                //             type: "triggered_result",
                //             result: {
                //                 type: "error",
                //                 message: output.error.message
                //             }
                //         }
                //     };
                //     break;

                case 'setvalues':
                    for(let field of output.setvalues.fields) {
                        fields[field.key] = field.value;
                    }
                    resOutput = {
                        name: "",
                        trigger: (output.trigger.name ? "conditions." + output.trigger.name : ""),
                        triggerMode: output.triggerMode,
                        value: {
                            type: "set-value",
                            to: "TransactionResults",
                            result: fields
                        }
                    };
                    break;

                case 'uinotification':
                    resOutput = {

                        name:"",
                        trigger: (output.trigger.name ? "conditions."+output.trigger.name : ""),
                        triggerMode: output.triggerMode,
                        useful: true,
                        value: {
                            type: "microservice-call",
                            microservice: "orchestrator",
                            method: "SendEventToWebsockets",
                            "input-format":[{
                                type:"base64",
                                field:"Message"
                            }],
                            arg: JSON.stringify({
                                Scope: "uinotification",
                                Message: this.replaceDataItemsInText(JSON.stringify({
                                        message: output.uiNotification.data,
                                        //2.0 compatibility
                                        recipients: output.uiNotification.recipients.filter(value => isNaN(value)),
                                        level: output.uiNotification.level,
                                        blocking: output.uiNotification.blocking
                                    }),
                                    output.uiNotificationPlaceholders, descriptor.data)
                            }),
                            outmode: "raw",
                        }
                    };
                    break;

                case 'formfill':
                    resOutput = [
                        {
                            name:"",
                            trigger: (output.trigger.name ? "conditions."+output.trigger.name : ""),
                            triggerMode:output.triggerMode,
                            useful: true,
                            value: {
                                type: "microservice-call",
                                microservice: "orchestrator",
                                method: "SendEventToWebsockets",
                                "input-format":[{
                                    type:"base64",
                                    field:"Message"
                                }],
                                arg: JSON.stringify({
                                    Scope: "uinotification",
                                    Message: this.replaceDataItemsInText(JSON.stringify({
                                            message: output.formFill.data,
                                            //2.0 compatibility
                                            recipients: output.formFill.recipients.filter(value => isNaN(value)),
                                            level: output.formFill.level,
                                            blocking: output.formFill.blocking,
                                        }),
                                        output.formFillPlaceholders, descriptor.data)
                                }),
                                outmode: "raw"
                            }
                        },
                        {
                            name:"",
                            trigger: (output.trigger.name ? "conditions." + output.trigger.name : ""),
                            triggerMode:  output.triggerMode,
                            value: {
                                type: "microservice-call",
                                microservice: "elastic-audits",
                                method: "PostAuditInternal",
                                arg: JSON.stringify({operator: "Avionics System - Rule " + descriptor.properties.name, action: Audits.items().formFillRequested, previous: '', next: output.formFill.recipients.filter(value => isNaN(value)), id: output.formFill.form.properties.name}),
                                outmode:"raw"
                            }
                        },
                        {
                            name:"",
                            trigger: (output.trigger.name ? "conditions." + output.trigger.name : ""),
                            triggerMode:output.triggerMode,
                            value: {

                                type: "microservice-call",
                                microservice: "elastic-documents",
                                method: "ManageInternalIndex",
                                arg: JSON.stringify({
                                    scope:DataEntry.targetPendingActions.split("/")[0],
                                    index:DataEntry.targetPendingActions.split("/")[1],
                                    method:"POST",
                                    body: JSON.stringify({
                                        id: "auto",
                                        type: "formfill",
                                        name: !output.formFill.customFormSelect? 'system-DefaultForm' : output.formFill.form.properties.name,
                                        Name: descriptor.properties.name,
                                        recipients: output.formFill.recipients.filter(value => isNaN(value)),
                                        level: output.formFill.level,
                                        blocking: output.formFill.blocking,
                                        '@timestamp': DateTime.getRfc3339TimeStamp(new Date()),
                                        variables:this.unwrapFormFillVariables(output.formFill.form.formVariables, descriptor.data),
                                        Action: {
                                            name: !output.formFill.customFormSelect? 'system-DefaultForm' : output.formFill.form.properties.name,
                                        },
                                        Resolution: "Open",
                                        tag: {
                                            VisibleTo: output.formFill.recipients.filter(value => isNaN(value)),
                                            EditableBy: editableTo
                                        },
                                    })
                                }),
                                outmode:"raw"
                            }
                        }
                    ];
                    break;
                //TODO Align to new next gen format
                case 'automaticworkorderscheduling': {

                    let document = {};

                    if(output.workorderscheduling.unSchedule) {
                        document["StatusString"] = "completed";
                        document["RequestedStatus"] = "completed";
                    }
                    else {
                        let fields = this.unwrapFormFillVariables(output.workorderscheduling.variables, descriptor.data);
                        for (let field of fields)
                            document[field.name] = field.value;
                        document["StatusString"] = "running";
                        document["RequestedStatus"] = "running";
                    }

                    resOutput = [
                        {
                            name: "",
                            trigger: (output.trigger.name ? "conditions." + output.trigger.name : ""),
                            triggerMode: output.triggerMode,
                            value: {
                                type: "microservice-call",
                                microservice: "avionics-workorders",
                                method: "SetWoFromUI",
                                arg: JSON.stringify( { workorder: document } ),
                                outmode:"raw"
                            }
                        }
                    ];
                    break;
                }

                case 'activity': {
                    break;
                }

                case 'reportExport': {
                    let attachments = {};
                    resOutput = [];
                    let exportConfig = this.getExportStep(descriptor, output.reportExport.report);
                    resOutput.push({
                        name:"",
                        trigger: (output.trigger.name ? "conditions." + output.trigger.name : ""),
                        triggerMode: output.triggerMode,
                        useful: true,
                        value: exportConfig
                    });
                    attachments['file://' + this.replaceDataItemsInText(output.reportExport.report.newName, output.reportExportPlaceholders, descriptor.data) + '.' + output.reportExport.report.type] = output.reportExport.report.name.replace(".", "");


                    resOutput.push({
                        name: "",
                        trigger: (output.trigger.name ? "conditions." + output.trigger.name : ""),
                        triggerMode: output.triggerMode,
                        useful: true,
                        value: {
                            type: "store-on-file",
                            attachments: attachments,
                        }
                    });
                    break;
                }

                case 'customMessage': {
                    resOutput = {
                        name: "",
                        trigger: (output.trigger.name ? "conditions."+output.trigger.name : ""),
                        triggerMode:output.triggerMode,
                        value: {
                            type: 'joyce-send',
                            to: output.customMessage.topic,
                            body: this.replaceDataItemsInText(JSON.stringify(output.customMessage.data), output.customMessagePlaceholders, descriptor.data),
                            kind: descriptor.properties.name
                        }
                    };
                    break;
                }

                default:
                    throw new Error("ruleValidationError:" + VueInstance.get().$gettext("Please specify output type."));
            }
            if(Array.isArray(resOutput)) {
                for (let output of resOutput)
                    resOutputs.push(output);
            }
            else {
                if (!Object.isEmpty(resOutput))
                    resOutputs.push(resOutput);
            }
        }
        return resOutputs;
    },

    async unwrapActivityOutputs(descriptor) {
        let resOutputs = [];
        let mailSettings = Object.isUseful(Settings.getMailSettings()) ? Settings.getMailSettings() : await Settings.loadMailSettings();
        for (let i = 0 ; i < descriptor.outputs.length ; i++) {
            let output = descriptor.outputs[i];
            let resOutput = {};
            switch (output.outputType) {
                case 'email':
                    let attachments = {};
                    resOutput = [];
                    for (let i = 0; i < output.email.attach.length; i++) {
                        let exportConfig = this.getExportStep(descriptor, output.email.attach[i]);
                        resOutput.push({
                            name:"",
                            trigger: (output.trigger.name ? "conditions."+output.trigger.name : ""),
                            triggerMode: output.triggerMode,
                            useful: true,
                            value: exportConfig
                        });
                        attachments[output.email.attach[i].name.split('.')[0] + " " + new Date().format("yyyyMMdd HH.mm") + '.' + output.email.attach[i].type] = output.email.attach[i].name.replace(".", "");
                    }
                    resOutput.push({
                        type: "mail",
                        recipients: output.email.recipients,
                        subject: output.email.subject,
                        body: output.email.data,
                        attachments: (output.email.attach ?  attachments : {}),
                        sender: mailSettings.sender, //"from@example.io",
                        server: mailSettings.server, //"smtp.mailtrap.io",
                        port: mailSettings.port.toString(), //"2525",
                        login: mailSettings.login, //"122aa2f0870716",
                        password: mailSettings.password //"ae9aa113f0564a"
                    });
                    break;
                case 'uinotification':
                    resOutput = {
                        type: "uinotification",
                        Message: {
                            message: output.uiNotification.data,
                            recipients: output.uiNotification.recipients.filter(value => isNaN(value)),
                            level: output.uiNotification.level,
                            blocking: output.uiNotification.blocking
                        },
                    };
                    break;
                case 'activity':
                    resOutput = [
                        {
                            type: "activity",
                            formName: !output.activityOutput.customFormSelect? 'system-DefaultForm' : output.activityOutput.form.properties.name,
                            asset: output.activityOutput.asset ? output.activityOutput.asset : '',
                            operator: "Avionics System - Rule " + descriptor.properties.name,
                            action: Audits.items().taskCreated,
                            recipients: output.activityOutput.recipients.filter(value => isNaN(value)),
                            name: descriptor.properties.name,
                            Message: {
                                message: output.activityOutput.data,
                                recipients: output.activityOutput.recipients.filter(value => isNaN(value)),
                                level: output.activityOutput.level,
                                blocking: output.activityOutput.blocking
                            },
                        }
                    ];
                    break;
                default:
                    throw new Error("ruleValidationError:" + VueInstance.get().$gettext("Please specify output type."));
            }
            if(Array.isArray(resOutput)) {
                for (let output of resOutput)
                    resOutputs.push(output);
            }
            else resOutputs.push(resOutput);
        }
        return resOutputs;
    },

    encodeHttpHeaders(headers) {
        if(!Array.isUseful(headers))
            return null;
        let returning = {};
        for(let header of headers)
            returning[header.key] = header.value;
        return returning;
    },

    replaceDataItemsInText(body, placeHolders, dataItems) {
        if(placeHolders)
            for (let i = 0 ; i < placeHolders.length ; i++) {
                let varId = this.getVariableId(placeHolders[i], dataItems);
                if(varId) {
                    let strToReplace = "{{" + placeHolders[i].text + "}}";
                    if (strToReplace === "{{@timestamp}}")
                        varId = "now|pathallowed";
                    let strQueryId = "{{" + varId + "}}";
                    body = body.replace(strToReplace, strQueryId);
                }
            }
        return body;
    },

    processNormalization(normalization, variable) {

        let expression = Normalizations.resolve(normalization);
        //If "value" unspecified just put it at beginning
        if (!expression.includes("value"))
            expression = "value" + expression;

        //Cross aggs normalizations on intermediate variables, up to 20 datasets in expression should be enough.
        //We reference intermediate datasets by their "q" index
        for(let j = 1 ; j < 20 ; j++)
            expression = expression.replace(new RegExp("value" + j, "g"), variable + ".q" + j);

        //Finally replace the value placeholder with actual variable
        return expression.replace(/value/g, variable);
    },

    unwrapConditions(rawConditions, dataItems) {

        if(!Array.isUseful(rawConditions) || !Array.isUseful(rawConditions[0].value))
            return [];

        let conditions = Utils.detach(rawConditions);

        let conditionsCrawler = function(conditions, dataItems, self) {
            for (let [index, condition] of conditions.entries()) {
                if(Array.isArray(condition))    //Nested condition
                    conditionsCrawler(condition, dataItems, self);
                else if(Object.isNestedPropertyUseful(condition, "variable", "id")) { //It's a condition, and it's not yet unwrapped (not result of a special case unrolling)
                    if(condition.variable.error)    //can't go ahead, we have an undefined variable
                        throw new Error("ruleValidationError:" + VueInstance.get().$gettext("Non existing variable {0} referenced in rule conditions. Please fix it.").format(condition.variable.text));

                    //Unroll special conditions
                    //TMW Demo stuff TODO remove it later once we are sure that also demo environment won't be used any more
                    // if(condition.variable.wellKnown && condition.variable.wellKnown.id === Defines.WellKnownItems.Serial) {
                    //     if (condition.operator === "VALID") {
                    //         let unwrappedConditions = [];
                    //         unwrappedConditions.push({variable: ".inputs.Serial", operator: "=", value: "'0000'"});
                    //         unwrappedConditions.push({variable: ".inputs.Serial", operator: "=", value: "'0001'"});
                    //         unwrappedConditions.push({variable: ".inputs.Serial", operator: "=", value: "'0002'"});
                    //         unwrappedConditions.push({variable: ".inputs.Serial", operator: "=", value: "'0003'"});
                    //         unwrappedConditions.push({variable: ".inputs.Serial", operator: "=", value: "'0004'"});
                    //         unwrappedConditions.push({variable: ".inputs.Serial", operator: "=", value: "'0005'"});
                    //         conditions.removeAt(index);
                    //         let subIndex = index;
                    //         for(let i = 0 ; i < unwrappedConditions.length ; i++) {
                    //             if(i > 0) {
                    //                 conditions.insertItem(subIndex, "OR");
                    //                 subIndex++;
                    //             }
                    //             conditions.insertItem(subIndex, unwrappedConditions[i]);
                    //             subIndex++;
                    //         }
                    //         continue;
                    //     }
                    // }
                    if(condition.variable.isRoot && condition.variable.wellKnown.id === Defines.WellKnownItems.Location) {
                        if(condition.value && condition.value.match && condition.value.match.valid) {
                            let unwrappedConditions = [];
                            if(condition.value.match.city)
                                unwrappedConditions.push({variable: ".inputs.Location.City", operator: condition.operator, value: "'{0}'".format(condition.value.match.city)});
                            if(condition.value.match.state)
                                unwrappedConditions.push({variable: ".inputs.Location.State", operator: condition.operator, value: "'{0}'".format(condition.value.match.state)});
                            if(condition.value.match.postcode)
                                unwrappedConditions.push({variable: ".inputs.Location.PostalCode", operator: condition.operator, value: "'{0}'".format(condition.value.match.postcode)});
                            if(condition.value.match.country)
                                unwrappedConditions.push({variable: ".inputs.Location.Country", operator: condition.operator, value: "'{0}'".format(condition.value.match.country)});
                            conditions.removeAt(index);
                            let subIndex = index;
                            for(let i = 0 ; i < unwrappedConditions.length ; i++) {
                                if(i > 0) {
                                    conditions.insertItem(subIndex, "AND");
                                    subIndex++;
                                }
                                conditions.insertItem(subIndex, unwrappedConditions[i]);
                                subIndex++;
                            }
                            continue;
                        } else continue;
                    }

                    let isString = condition.variable.type === "keyword";
                    condition.variable = self.getVariableId(condition.variable, dataItems, false);
                    if(Object.isNestedPropertyUseful(condition, "value", "id")) {
                        if(condition.value.error)    //can't go ahead, we have an undefined variable
                            throw new Error("ruleValidationError:" + VueInstance.get().$gettext("Non existing variable {0} referenced in rule conditions. Please fix it.").format(condition.value.text));
                        condition.value = self.getVariableId(condition.value, dataItems, false);
                    }
                    else if(!Object.isUseful(condition.value) || (condition.value.trim && condition.value.trim() === ''))
                        condition.value = "''"; //Avoid sending nulls or undefined to backend
                    else {
                        let value = (condition.value.trim ? condition.value.trim() : condition.value);
                        if(!isNaN(value) && !isString) {
                            if(condition.operator === "RAND")
                                condition.operator = "<=";
                            if(!String(value).includes("."))
                                value += ".0";
                            condition.value = "{0}".format(value);
                        } else
                            condition.value = "'{0}'".format(value);  //Escape literal strings to allow discrimination of strings and variables
                    }
                }
            }
        };
        if (Array.isUseful(conditions))
            for (let condition of conditions) {
                condition.useful = true
                conditionsCrawler(condition.value, dataItems, this);
            }


        return conditions;
    },

    unwrapFormFillVariables(variables,dataItems) {
        let ffVariables = [];
        let self = this;
        function gV(variables, aV) {
            variables.forEach(v => {
                if (v.defaultValue){
                    if(Object.areDefined(v.defaultValue.root, v.defaultValue.name))
                        aV.push({
                            name: v.name,
                            value: "{{" + self.getVariableId(v.defaultValue, dataItems) + "}}"
                        });
                    else
                        aV.push({
                            name: v.name,
                            value: v.defaultValue
                        });
                }
                if (Array.isUseful(v.children)) {
                    gV(v.children, aV)
                }
            });
        }

        gV(variables, ffVariables);
        return ffVariables;
    },

    getVariableId(variable, dataItems, goTemplateFormat = true) {
        let variableId = "";
        if(variable.isField) {
            variableId = ".inputs." + variable.name;
        } else {
            Data.forEachRepresentation(dataItems, (representation, itemIndex) => {
                if (representation.id === variable.id || (representation.id + ".oldValue" === variable.id))
                    variableId = ".inputs." + (variable.oldValue ? "previous." : "") + representation.queryId;
            });
        }
        if(!goTemplateFormat && variableId.startsWith("."))
            variableId = variableId.slice(1)

        return variableId;
    },

    //Reduce a full data descriptors array to a smaller structure that keeps all the necessary properties
    //to identify representation from user assigned name (for instance in rules representation)
    //and to resolve all the necessary information regarding item type, supported operators or special values pickers.
    //If field mode is enabled only selected variables names will be used instead of representations
    //Exclude root items doesn't enumerate items that are selected as root nodes instead of variables (like location)
    //root nodes are fine for comparison and filtering not for outputs placeholders
    getVariablesDescriptors(dataItems, fieldMode, excludeRootItems = false, activityType = false) {
        let variables = [];
        if(fieldMode) {
            for(let item of dataItems) {
                if(!(excludeRootItems && item.isRoot))
                    variables.push(
                        {
                            id: item.index + item.root + item.name,
                            text: item.name,
                            wellKnown: Defines.getWellKnownItem(item),
                            root: item.root,
                            name: item.name,
                            type: item.type,
                            comparers: activityType === true ? ["<=", ">="] : Defines.getComparersForDataItem(item, true),
                            isRoot: item.isRoot,
                            isField: true,
                            oldValue: false,
                            error: false
                        }
                    );
            }
        } else {
            Data.forEachRepresentation(dataItems, (representation, itemIndex) => {
                let comparers = Defines.getComparersForRepresentation(dataItems[itemIndex], representation, true);
                variables.push(
                    {
                        id: representation.id,
                        text: representation.name,
                        wellKnown: Defines.getWellKnownItem(dataItems[itemIndex]),
                        root: dataItems[itemIndex].root,
                        name: dataItems[itemIndex].name,
                        type: dataItems[itemIndex].type,
                        comparers: activityType === true ? ["<=", ">="] : comparers,
                        isField: false,
                        oldValue: false,
                        error: false
                    },
                    {
                        id: representation.id + ".oldValue",
                        text: representation.name + ".oldValue",
                        wellKnown: Defines.getWellKnownItem(dataItems[itemIndex]),
                        root: dataItems[itemIndex].root,
                        name: dataItems[itemIndex].name,
                        type: dataItems[itemIndex].type,
                        comparers: activityType === true ? ["<=", ">="] : comparers,
                        isField: false,
                        oldValue: true,
                        error: false
                    })
            });
        }
        return variables;
    },

    async isRuleRunning(rule) {
        let rules = await this.getRunningRules();
        return rules.includes(rule);
    },

    async getRunningRules(needActivities = true) {
        let rules = [];
        //get activity rules
        if (needActivities){
            rules = await this.getRunningDeterministicActivities();
        }

        //append running rules on activities array
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.rulesUrl + '/list', "")
                .then(result => {
                    for(let rule of result)
                        rules.push(rule.replace("runtime-task-executor_", ""));
                    resolve(rules)
                })
                .catch(error => {
                    debugger;
                    console.error(error);
                    reject(rules);
                });
        });
    },

    async getRunningDeterministicActivities() {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.activityRuleUrl + '/list/active', "")
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    if(Microservices.isIndexEmptyError(error))
                        resolve([]);
                    else {
                        debugger;
                        reject([]);
                    }
                });
        });
    },

    async getRulesScheduling() {
        // return new Promise((resolve, reject) => {
        //     // Orchestrator.proxyCall('get', Microservices.rules, Microservices.rulesUrl + '/manager/configuration', "")
        //     //     .then(result => {
        //     //         debugger
        //     //         resolve(result)
        //     //     })
        //     //     .catch(error => {
        //     //         debugger
        //     //         reject(error);
        //     //     });
        //     "taskman",
        //         "Option": "json",
        //         "Type": "runtime-tasks-manager"
        return Settings.load("runtime-tasks-manager", Settings.Null, Settings.Null, Settings.InstanceScope)
        //});
    },

    async setRulesScheduling(scheduling) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.rulesUrl + '/manager/configuration', scheduling)
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
        });
    },

    validateRuleName(name) {
        //Rule doesn't start with character "_" inside visualization name
        return (/^[A-Za-z0-9 -]*$/.test(name));
    },

    async testMailConfiguration(mailConfig) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post',   Microservices.rulesUrl + '/testMail', mailConfig)
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
        });
    },

    loadRules() {
        return new Promise((resolve, reject) => {
            Vue.prototype.$dynamicElements.LoadItems('rules', false, true, false)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(error);
                })
        });
    },

    loadActivities() {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.activityRuleUrl + '/*', "")
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    if(Microservices.isIndexEmptyError(error))
                        resolve([]);
                    else {
                        debugger;
                        reject(error);
                    }
                });
        });
    },

    async getActivities() {
        let activities = await this.loadActivities();
        let rulesDescriptors = await this.loadRules();
        if (Array.isUseful(activities)) {

            activities.forEach(a => {
                if (a.ActivityType === "deterministic") {
                    let lastTime = a.LastTriggerTime;
                    if (!lastTime || lastTime.includes("no value"))
                        lastTime = a.InitialReferenceTime;
                    a[a.Name] = {};
                    a[a.Name].currentValue = new Date().getTime() - new Date(lastTime).getTime();
                    a[a.Name].targetValue = new Date(a.NextTriggerTime).getTime() - new Date(lastTime).getTime();
                    a.nextTriggerAccuracy = 1;
                }
                a.visualization = rulesDescriptors.find(item => item.properties.name === a.name )
            });

            activities = activities.filter(a => {
                return Object.isUseful(a.visualization)
            });

            return activities.sortBy("nextTriggerTime",true)
        }
        return []
    },

    async schedulingInAdvance(id, nextSchedules) {
        console.log(id, nextSchedules)
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.activityRuleUrl + '/{0}/nextschedules'.format(id), nextSchedules)
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
        });
    },

    callApiRule(ruleName, ruleBody) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.rulesUrl + '/manager/{0}/run'.format(ruleName), ruleBody)
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
        });
    }
}
