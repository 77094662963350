<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" class="page-container" grid-list-md text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card class="ma-0 av-card" elevation="0" style="height: 80px;">
            <v-layout row class="pa-3" style="margin-top: -10px">
                <label :class="warning ? 'sectionTitleWarning': 'sectionTitle'">{{$gettext("{appName} Version: {version}").format({appName: $config.appName, version: version})}}</label>
                <label class="subheading mx-5 mb-1" style="width:700px"><span class="font-weight-bold"><translate>Licensed modules</translate></span>: {{licensedModules}} <br><span class="font-weight-bold"><translate>License expiration</translate></span>: <translate>no expiration date</translate></label>
                <v-spacer></v-spacer>
                <vue-excel-xlsx
                        :data="exportDataCollection"
                        :columns="exportFields"
                        :fileName="exportFileName"
                        :sheetName="exportWorksheet"
                >
                    <v-btn class="ma-2">
                        <v-icon style="color: #0d47a1;" left>fas fa-file-excel</v-icon>
                        <v-icon style="color: #0d47a1;" left>fas fa-arrow-right</v-icon>
                            <translate>Export excel</translate>
                    </v-btn>
                </vue-excel-xlsx>
            </v-layout>
        </v-card>
        <v-card  class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)">
            <v-card-text  class="av-card-content" style="height: calc(100vh - 20px)">
                <v-data-table hide-actions
                              :rows-per-page-items="[15, 10, 20, 25, {'text':'$vuetify.dataIterator.rowsPerPageAll','value':-1}]"
                              :headers="headers" :items="dataCollection"
                              class="elevation-0 subheading my-data-table fixed-header v-table__overflow"
                              ma-0 pa-0 style="max-height: calc(100vh - 50px)">
                    <template v-slot:items="props">
                        <tr>
                            <td class="text-xs-left subheading" style="width: 200px">
                                {{ props.item.timestamp }}
                            </td>
                            <td class="text-xs-left subheading">
                                {{ props.item.type }}
                            </td>
                            <td class="text-xs-left subheading">
                                {{ props.item.process }}
                            </td>
                            <td class="text-xs-left subheading" style="width: 100px">
                                {{ props.item.build }}
                            </td>
                            <td class="text-xs-left subheading">
                                <div :class="props.item.status"
                                     style="width:20px; height:20px; -webkit-border-radius:20px;">&nbsp;
                                </div>
                                <v-tooltip>{{props.item.status}}</v-tooltip>
                            </td>
                            <td class="text-xs-left subheading pre-formatted">
                                <div class="pre-formatted">{{ props.item.statusString }}</div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import OrchestratorAPI from '@/api/orchestrator'

    export default {
        name: "SystemInfo",
        data() {
            return {
                dataCollection: [],
                processes: [],
                headers: [],
                refresher: null,
                version: '',
                warning: false,
            }
        },
        computed: {
            licensedModules() {
                return this.$license.modulesList().join(", ")
            },
            exportFields() {
                if (this.dataCollection && this.dataCollection.length) {
                    let returning = [{label: "Date", field: "timestamp"},
                        {label: "Process Type", field: "type"},
                        {label: "Process Name", field: "process"},
                        {label: "Build", field: "build"},
                        {label: "Status", field: "status"},
                        {label: "Status Info", field: "statusString"},
                    ];
                    return returning;
                }
                return [];
            },
            exportWorksheet() {
                return "{appName} system Info".format({appName: this.$config.appName});
            },
            exportFileName() {
                return "{appName} System Info {time}".format({appName: this.$config.appName, time: (new Date()).format()});
            },
            exportDataCollection() {
                let returning = [];
                if (this.dataCollection && this.dataCollection.length) {
                    returning.push(...this.dataCollection);
                    returning.unshift({
                        timestamp: '',
                        type: '',
                        process: '{0} : '.format(this.$config.appName),
                        build: this.version,
                        statyus: '',
                        statusString: ''
                    });
                }
                return returning;
            },
        },
        mounted: function () {
            this.headers = [{
                    text: this.$gettext("Date"),
                    class: "subheading text-xs-left",
                    sortable: true,
                    value: "timestamp",
                    align: 'left',
                    width: "200px"
                },
                {
                    text: this.$gettext("Process Type"),
                    class: "subheading ",
                    sortable: true,
                    value: "type"
                },
                {
                    text: this.$gettext("Process Name"),
                    class: "subheading ",
                    sortable: true,
                    value: "process"
                },
                {
                    text: this.$gettext("Build"),
                    class: "subheading",
                    sortable: true,
                    value: "build",
                    width: "100px"
                },
                {
                    text: this.$gettext("Status"),
                    class: "subheading ",
                    sortable: true,
                    value: "status"
                },
                {
                    text: this.$gettext("Status Info"),
                    class: "subheading",
                    sortable: true,
                    value: "statusString"
                },
            ];

            this.loadProcessesList();
            this.refresher = setInterval(this.loadProcessesList, 15000);
        },
        beforeDestroy: function () {
            clearInterval(this.refresher);
        },
        methods: {
            loadProcessesList() {
                let self = this;
                OrchestratorAPI.applicationVersion()
                    .then((version) => {
                        if (version.data.Data)
                            self.version = JSON.parse(version.data.Data);
                    })
                    .finally(() => {
                        this.$processesStatus.getProcessesStatusList()
                            .then(result => {
                                self.processes = result;
                                self.refreshData(self.processes);
                            })
                    })

            },

            refreshData(processes) {
            debugger
                if (!processes) {
                    this.dataCollection.clear();
                    return;
                }
                this.warning = false;
                this.dataCollection.clear();

                for (let i = 0; i < processes.length; i++) {
                    let process = processes[i];

                    //Check if there are Components Versions Mismatch
                    if (i + 1 < processes.length && !this.warning) {
                        this.warning = process.Version !== processes[i + 1].Version;
                    }
                    this.dataCollection.push({
                        timestamp: process["@timestamp"].format(),
                        process: this.$processesStatus.getProcessName(process.microserviceName),
                        type: this.$processesStatus.getProcessType(process.microserviceName),
                        status: process.isRunning ? "Ok" : "Error",
                        statusString: process["ExtraInfo"],
                        build: process.Version,
                    });
                }

                if (this.warning)
                    this.version = "Components Versions Mismatch: " + this.version + " (Dirty)";
            },
        },
    }
</script>

<style scoped>
    .my-data-table {
        display: flex;
        flex-direction: column;
    }

    .my-data-table .v-datatable.v-table {
        order: -1;
    }

    .pre-formatted {
        white-space: pre;
    }

    .Ok {
        background-color: var(--av-green);
    }

    .Error {
        background-color: var(--av-red);
    }

    .Busy {
        background-color: var(--av-yellow);
    }

    .Aligner {
        display: flex !important;
        align-items: flex-end !important;
        justify-content: flex-end !important;
        max-width: 100% !important;
        width: 100% !important;
        margin-left: auto !important;
    }

</style>