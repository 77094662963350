<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" class="page-container" grid-list-md text-xs-center
                 pa-3 ma-0 style="max-width: 100%; overflow: auto">
        <v-card class="ma-0 av-card" elevation="0" style="overflow: hidden; height: auto">
            <br/>
            <v-card-text class="av-card-content" style="overflow: hidden; height: auto">
                <!--<v-btn style="margin-left: 20px" color="info" @click="reboot">-->
                <!--<av-icon left light>fas fa-power-off</av-icon>-->
                <!--Restart system-->
                <!--</v-btn>-->
                <ConfigurationsEditor :descriptor="processesConfigurations" @value-Changed="valueChanged" style="overflow: hidden"/>
            </v-card-text>
        </v-card>
        <v-card class="mt-2 av-card" elevation="0" style="overflow: hidden; height: 400px">
            <v-card-text class="av-card-content">
                <br/>
                <v-layout class="align-start justify-start column px-4 py-2">
                    <label class="headline font-weight-bold" style="margin-top: 40px; margin-bottom: 5px"><translate>Import configurations</translate></label>
                    <hr style="height: 1px; background-color: black; width: 100%"/>
                </v-layout>
                <v-layout row align-start justify-start mt-4 pt-2 ml-5 :style="'width: 90%'">
                    <div class="text-xs-left">
                        <label class="subheading font-weight-bold"><translate>Choose scope</translate>:</label>
                        <v-divider></v-divider>
                        <v-radio-group class="pa-2" row v-model="importDocScope">
                            <v-radio :label="instance" value="instance"></v-radio>
                            <v-radio :label="system" value="system"></v-radio>
                            <v-radio :label="user" value="user"></v-radio>
                        </v-radio-group>
                        <upload-btn ref="confFileInput" class="btn btn-default" color="#f5f5f5" :title="$gettext('Import configuration')" @file-update="importConfig">
                            <template slot="icon-left">
                                <av-icon color="#0d47a1" style="margin-right: 10px">fas fa-file</av-icon>
                                <av-icon color="#0d47a1" style="margin-right: 10px">fas fa-arrow-left</av-icon>
                            </template>
                        </upload-btn>
                    </div>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

import VJsoneditor from 'v-jsoneditor/src/index';
import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
import Supervisor from "@/api/supervisorApi";
import ImportExport from "@/components/utilities/ImportExport";
import UploadButton from 'vuetify-upload-button';
import ElasticBasedBootstrap from "@/api/elasticBasedBootstrap";

export default {
        name: "SystemSettings",
        components: {
            VJsoneditor, ConfigurationsEditor, ImportExport, 'upload-btn': UploadButton
        },
        data() {
            return {
                processesConfigurations: {
                    instanceSettings: {
                        visible: this.$grants.get().settings.avionicsSystem,
                        groupName: this.$gettext("Current instance configuration files"),
                        settings: {}
                    },
                    systemSettings: {
                        visible: this.$grants.get().settings.avionicsSystem,
                        groupName: this.$gettext("System standard configuration files"),
                        settings: {}
                    },
                    userSettings: {
                        visible: this.$grants.get().settings.avionicsSystem,
                        groupName: this.$gettext("Special user configuration files"),
                        settings: {}
                    }
                },
                bulkReplaceSources: [],
                actionTrail: "",
                actionTrailId: "",
                nextValTrail: "",
                previousValTrail: "",
                containerHeight:0,
                importDocScope: "instance",
                dataCollection: [],
                processControlItems: [
                    {
                        title: 'Deploy',
                        action: this.deployMicroservice,
                        icon: 'fas fa-play',
                        iconColor: 'info',
                        show: false
                    },
                    {
                        title: 'Relaunch',
                        action: this.relaunchMicroservice,
                        icon: 'fas fa-repeat',
                        iconColor: 'warning',
                        show: true,
                    },
                    {
                        title: 'Suspend',
                        action: this.suspendMicroservice,
                        icon: 'fas fa-stop',
                        iconColor: 'red',
                        show: true
                    },
                ],
            }
        },
        mounted: function () {
            this.handleResize();
            window.addEventListener("resize",this.handleResize);
            this.loadProcessesList();
        },
        computed:{
            cardStyle(){
                let self=this;
                return{
                    height: '90%',
                    'overflow-y':"auto"
                }
            },
            instance() {
                return this.$gettext("Instance");
            },
            system() {
                return this.$gettext("System");
            },
            user() {
                return this.$gettext("User");
            },
        },

        methods: {
            handleResize(){
                this.containerHeight = window.innerHeight;
            },
            reboot() {
                this.$root.showDialogBox(this.$gettext("THIS COULD CAUSE {appName1} TO STOP WORKING. Hit CANCEL if you don't know exactly what you are doing. Are you sure you want to restart {appName2} system? This may take several minutes").format({appName1: this.$config.appName.toUpperCase(), appName2: this.$config.appName}), null, "Yes", this.rebootConfirm, "CANCEL", null);
            },
            rebootConfirm() {
                Supervisor().get("/restart");
            },
            getSettingId(setting) {
                return "{0}.{1}.{2}".format(setting.Type, setting.Name, setting.Option);
            },
            getSettingName(setting) {
                // if(setting.Type.toLowerCase() === "grants")
                //     return "Users grants configuration file";
                // else if(["backnote", "avopcua", "avmodbus", "etl", "elasticsearch", "avionicsrest", "telegraf", "opcemulator", "analysis", "retention"].includes(setting.Type.toLowerCase()))
                //     return "Process {0} of type {1} ({2} configuration file)".format(setting.Name, setting.Type, setting.Option);
                // else return this.getSettingId(setting); //New or unknown file, in loadSystemSettings we should filter out known items that must not appear to allow configuring new stuff dynamically
                return this.getSettingId(setting); //New or unknown file, in loadSystemSettings we should filter out known items that must not appear to allow configuring new stuff dynamically
            },
            getSettingValue(setting) {
                if(setting.Option === "json" || setting.Type.toLowerCase() === "grants") {
                    try {
                        return { content: JSON.parse(setting.DocumentContent), type: "jsonFile"};
                    } catch (e) {
                        return { content: setting.DocumentContent, HasJsonError: true, type: "textFile" };
                    }
                }
                else
                    return { content: setting.DocumentContent, type: "textFile" };
            },
            loadProcessesList() {
                let self = this;
                self.$processesStatus.getProcessesStatusList()
                    .then(result => {
                        result.forEach(item => {
                            self.dataCollection.push({
                                timestamp: item["@timestamp"].format(),
                                process: this.$processesStatus.getProcessName(item.microserviceName),
                                type: this.$processesStatus.getProcessType(item.microserviceName),
                                status: item.isRunning ? "Ok" : "Error",
                                statusString: item["ExtraInfo"],
                                build: item.Version,
                            });
                        });
                        self.loadSystemSettings();
                    })
            },
            loadSystemSettings() {
                let self = this;
                let lineSettings = this.$settings.getLineSettings();
                if(lineSettings) {
                    this.bulkReplaceSources = [ lineSettings.customerId, lineSettings.factoryId, lineSettings.lineId ];
                    this.bulkReplaceTargets = [ "CustomerId", "FactoryId", "LineId" ];
                    if(lineSettings.masterMachine) {
                        this.bulkReplaceSources.push(lineSettings.masterMachine);
                        this.bulkReplaceTargets.push("ReferenceMachine");
                    }
                }
                this.processesConfigurations.instanceSettings.settings = {};
                this.processesConfigurations.systemSettings.settings = {};
                this.processesConfigurations.userSettings.settings = {};
                this.$settings.loadAll(this.$settings.InstanceScope)
                    .then(settings => {
                        settings.sortOnProperty("Type");
                        for(let setting of settings) {
                            let detailedSetting = self.dataCollection.find(item => item.process === setting.Name);
                            //Calculate control items based on status (deployed, suspended..) and type (AvOpcUa, runtime-task-executor .. )
                            if (detailedSetting)
                                self.controlItemsForTypeAndStatus(detailedSetting.type, detailedSetting.status);

                            this.$set(self.processesConfigurations.instanceSettings.settings, self.getSettingId(setting), {
                                name: self.getSettingName(setting),
                                value: self.getSettingValue(setting).content,
                                description: this.$gettext("Configuration file for a {appName} system component. Do Not change it unless you exactly know what you are doing").format({appName: this.$config.appName}),
                                type: self.getSettingValue(setting).type,
                                fileName: self.getSettingId(setting),
                                readOnly: false,
                                processControl: !!detailedSetting && setting.Option !== 'proc',
                                processControlItems: self.processControlItems.map(a => ({...a})),
                                status: detailedSetting && detailedSetting.status === "Ok"?
                                    { infoMsg: self.$gettext("Microservice is running"), status: 1 }: { infoMsg: self.$gettext("Microservice is suspended"), status: 0 },
                            });
                        }
                    });
                this.$settings.loadAll(this.$settings.SystemScope)
                    .then(settings => {
                        settings.sortOnProperty("Type");
                        for(let setting of settings) {
                            let detailedSetting = self.dataCollection.find(item => item.process === setting.Name);
                            //Calculate control items based on status (deployed, suspended..) and type (AvOpcUa, runtime-task-executor .. )
                            if (detailedSetting)
                                self.controlItemsForTypeAndStatus(detailedSetting.type, detailedSetting.status);

                            this.$set(self.processesConfigurations.systemSettings.settings, self.getSettingId(setting), {
                                name: self.getSettingName(setting),
                                value: self.getSettingValue(setting).content,
                                description: this.$gettext("Configuration file for a {appName} system component. Do Not change it unless you exactly know what you are doing").format({appName: this.$config.appName}),
                                type: self.getSettingValue(setting).type,
                                fileName: self.getSettingId(setting),
                                readOnly: false,
                                processControl: !!detailedSetting && setting.Option !== 'proc',
                                processControlItems: self.processControlItems.map(a => ({...a})),
                                status: detailedSetting && detailedSetting.status === "Ok"?
                                    { infoMsg: self.$gettext("Microservice is running"), status: 1 }: { infoMsg: self.$gettext("Microservice is suspended"), status: 0 },
                            });
                        }
                    });
                this.$settings.load("Workflow","Workorder")
                    .then(setting => {
                        if(setting) {
                            this.$set(self.processesConfigurations.userSettings.settings, "Workflow.Workorder.NULL", {
                                name: "Workflow.Workorder.NULL",
                                value: setting,
                                description: this.$gettext("Production workflow descriptor"),
                                type: "jsonFile",
                                fileName: "Workflow.Workorder.NULL",
                                readOnly: false
                            });
                        }
                    });
            },
            overwriteConfigFile(groupName, settingName, file, action = "") {

                let self = this;
                let scope = this.$settings.InstanceScope;
                if(groupName === "systemSettings")
                    scope = this.$settings.SystemScope;
                if(groupName === "userSettings")
                    scope = this.$settings.UserScope;

                //Delete
                if(!file) {
                    this.$settings.deleteById(settingName, scope)
                        .then(() => {
                            setTimeout(()=>{
                                self.loadSystemSettings()
                            },2000);
                            self.actionTrail = self.$audits.items().configFileDeleted;
                            self.actionTrailId = "";
                            self.nextValTrail = "";
                            self.previousValTrail = settingName;
                            self.saveAudits();
                        })
                        .catch(err => {
                            self.$root.showErrorNotification(err, true);
                        });
                    return;
                } else {
                    self.actionTrail = (action === 'loaded') ? self.$audits.items().configFileImported : self.$audits.items().configFileModified;
                    self.actionTrailId = "";
                    self.nextValTrail = settingName;
                    self.previousValTrail = "";
                }

                //Update
                this.$settings.saveById(file, settingName, scope)
                    .then(() => {
                        self.processesConfigurations[groupName].settings[settingName].value = file;
                        self.saveAudits();
                    })
                    .catch(err => {
                        self.$root.showErrorNotification(err, true);
                    })
            },
            valueChanged(groupName, settingName, action) {
                let self = this;
                if(action) {
                    if((action.action === "loaded" || action.action === "modified") && action.item)
                        this.$root.showDialogBox("THIS COULD CAUSE {appName} TO STOP WORKING. Hit CANCEL if you don't know exactly what you are doing. Are you sure you want to REWRITE the configuration for {setting}?".format({appName: this.$config.appName, setting: settingName}), null, "Yes", function() { self.overwriteConfigFile(groupName, settingName, action.item, action.action) }, "CANCEL", null);
                    else if(action.action === "loaded" && !action.item)
                        this.$root.showDialogBox("Selected file is empty. Will be ignored", null, "OK", null, "", null);
                    else if(action.action === "deleted")
                        this.$root.showDialogBox("THIS COULD CAUSE {appName} TO STOP WORKING. Hit CANCEL if you don't know exactly what you are doing. Are you sure you want to DELETE the configuration for {setting}?".format({appName: this.$config.appName, setting: settingName}), null, "Yes", function() { self.overwriteConfigFile(groupName, settingName, "") }, "CANCEL", null);
                }
            },
            saveAudits() {
                this.$audits.save(this.$root.userName, this.actionTrail, this.previousValTrail, this.nextValTrail, this.actionTrailId)
                    .catch(() => {
                        debugger
                        this.$root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                    });
            },
            importConfig(file) {
                let self = this;
                this.$root.setLoading(true, "Config file loading..");

                if (file.size === 0) {
                    self.$root.showErrorNotification(self.$gettext("No data in config file!"), true);
                    self.$root.setLoading(false);
                    return
                }

                const reader = new FileReader();
                reader.readAsText(file);

                reader.onload = () => {
                    this.$settings.saveById(reader.result, file.name, this.importDocScope)
                        .then(() => {
                            self.$root.showInfoNotification(self.$gettext("Config file successfully loaded"), true);
                            this.actionTrail = "configFileAdded"
                            this.nextValTrail = file.name
                            this.previousValTrail = ""
                            this.actionTrailId = ""
                            self.saveAudits();
                        })
                        .catch(err => {
                            self.$root.showErrorNotification(err, true);
                        })
                        .finally(() => {
                            self.$root.setLoading(false);
                        });
                };

                reader.onerror = () => {
                    console.log(reader.error);
                }
                this.$refs.confFileInput.clear();
            },
            controlItemsForTypeAndStatus(type, status) {
                let allowDeploySuspend = ["runtime-task-executor", "AvOpcUa", "Antares OPC-UA client"];
                if (allowDeploySuspend.includes(type)) {
                    if (status === "Ok") {
                        this.processControlItems.find(item => item.title === 'Deploy').show = false;
                        this.processControlItems.find(item => item.title === 'Relaunch').show = true;
                        this.processControlItems.find(item => item.title === 'Suspend').show = true;
                    } else {
                        this.processControlItems.find(item => item.title === 'Deploy').show = true;
                        this.processControlItems.find(item => item.title === 'Relaunch').show = false;
                        this.processControlItems.find(item => item.title === 'Suspend').show = false;
                    }
                } else {
                    this.processControlItems.find(item => item.title === 'Deploy').show = false;
                    this.processControlItems.find(item => item.title === 'Relaunch').show = true;
                    this.processControlItems.find(item => item.title === 'Suspend').show = false;
                }
            },
            deployMicroservice(microservice) {
                let self = this;
                let microserviceInfo = microservice.name.split('.');
                self.$root.setLoading(true, "Deploying microservice..");
                ElasticBasedBootstrap.deployNewProcess({ Name: microserviceInfo[1], Type: microserviceInfo[0], Status: microservice.status.status })
                    .then(() => {
                        self.$root.showInfoNotification(self.$gettext("Success running microservice."), true);
                        microservice.processControlItems.find(item => item.title === 'Deploy').show = false;
                        microservice.processControlItems.find(item => item.title === 'Relaunch').show = true;
                        microservice.processControlItems.find(item => item.title === 'Suspend').show = true;
                        microservice.status.status = 1;
                        microservice.status.infoMsg = self.$gettext("Microservice is running");
                        self.$root.setLoading(false);
                        self.$audits.save(self.$root.userName, this.$audits.items().microserviceDeployed, "", "", microserviceInfo[0] + '_' + microserviceInfo[1])
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .catch(err => {
                        self.$root.setLoading(false);
                        console.log(err);
                        self.$root.showErrorNotification(self.$gettext("Microservices is suspended"), true);
                    })
            },
            suspendMicroservice(microservice) {
                let self = this;
                let microserviceInfo = microservice.name.split('.');
                self.$root.setLoading(true, "Suspending microservice..");
                ElasticBasedBootstrap.stopProcess({ Name: microserviceInfo[1], Type: microserviceInfo[0], Status: microservice.status.status })
                    .then(() => {
                        self.$root.showInfoNotification(self.$gettext("Success suspending opc device."), true);
                        microservice.processControlItems.find(item => item.title === 'Deploy').show = true;
                        microservice.processControlItems.find(item => item.title === 'Relaunch').show = false;
                        microservice.processControlItems.find(item => item.title === 'Suspend').show = false;
                        microservice.status.status = 0;
                        microservice.status.infoMsg = self.$gettext("Microservices is suspended");
                        self.$root.setLoading(false);
                        self.$audits.save(self.$root.userName, this.$audits.items().microserviceSuspended, "", "", microserviceInfo[0] + '_' + microserviceInfo[1])
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .catch(err => {
                        self.$root.setLoading(false);
                        console.log(err);
                        self.$root.showErrorNotification(this.$gettext("Error suspending microservice."), true);
                    })
            },
            relaunchMicroservice(microservice) {
                let self = this;
                let microserviceInfo = microservice.name.split('.');
                self.$root.setLoading(true, "Relaunching microservice..");
                ElasticBasedBootstrap.relaunchProcess({ Name: microserviceInfo[1], Type: microserviceInfo[0], Status: microservice.status.status })
                    .then(() => {
                        self.$root.showInfoNotification(self.$gettext("Success relaunching microservice."), true);
                        self.$root.setLoading(false);
                        self.$audits.save(self.$root.userName, this.$audits.items().microserviceRelaunched, "", "", microserviceInfo[0] + '_' + microserviceInfo[1])
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .catch(err => {
                        self.$root.setLoading(false);
                        console.log(err);
                        self.$root.showErrorNotification(this.$gettext("Error relaunching microservice."), true);
                    })
            },
        }
    }

</script>

<style scoped>

</style>