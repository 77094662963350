<template>
    <av-page toolbar-activation-on="true">
        <template slot="toolbarFixedItems">
            <av-text-field class="ma-4" :label="$gettext('Filter records')" v-model="filterByItemName" style="width: 100%; max-width: 300px" ></av-text-field>
            <av-select class="ma-4" :label="$gettext('Filter by type')" :items="availableDocumentTypes" v-model="docTypeSelected"></av-select>
            <span class="subheading mt-5">{{filterMessage}}</span>
        </template>
        <template slot="toolbarDynamicItems">
            <av-button :text="$gettext('Upload files')" color="info" buttonIcon="fas fa-plus" iconColor="light"
                       @click="openUploadFileDialog" v-if="$grants.get().documentationPage.canUserManageDocuments"></av-button>
            <av-button v-if="backPath !== ''" class="mr-4" :text="$gettext('BACK')" color="error" buttonIcon="fas fa-arrow-left"
                       iconColor="light" @click="goBack"></av-button>
        </template>
        <template slot="pageContent">
            <div v-for="(visualization, index) in filteredItems" :key="visualization.groupName" :style="index !== 0? 'margin-top: 50px' : ''" v-if="visualization.visible">
                <div class="display-1 font-weight-bold mt-4 ml-2 text-xs-left"> {{ visualization.groupName }} </div>
                <hr style="height: 1px; background-color: black; width: 100%"/>
                <v-expansion-panel class="mb-2 ml-0 elevation-0"
                                   v-for="(item,i) in visualization.items" :key="item.ItemName" v-if="item.visible"
                                   :style="{width: 'calc(100%)', border:'1px solid lightgray', 'border-top':i===0?'0':'1px solid lightgray'}">
                    <v-expansion-panel-content class="pa-0 mt-2 expansionPanelHeader">
                        <template v-slot:header>
                            <div class="headline pa-0 ma-0 ml-4" style="width: 90%;">
                                <label> {{ item.ItemName }}</label>
                                <v-badge color="info" class="ml-2" style="line-height: 1!important;">
                                    <template v-slot:badge>
                                        <span>{{ calculateDocumentsNumber(item) }}</span>
                                    </template>
                                    <v-icon color="grey darken-3" size="20" class="mb-1"> fa fa-file </v-icon>
                                </v-badge>
                            </div>
                        </template>
                        <v-data-table :headers="documentsHeaders" :items="item.documents"
                                      class="elevation-0 ma-2 ml-0" hide-actions>
                            <template v-slot:headers="props">
                                <tr v-if="Array.isUseful(item.documents.filter(el => el.visible))">
                                    <th v-for="header in props.headers" class="column text-xs-left subheading">
                                        <b>{{ header.text }}</b>
                                    </th>
                                    <th class="text-xs-left subheading column">
                                        <b class="ml-4"><translate >Actions</translate></b>
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <template v-if="props.item.visible">
                                    <td class="text-xs-left subheading">{{ props.item.FieldName }}</td>
                                    <td class="text-xs-left subheading">{{ props.item.FormName }}</td>
                                    <td class="text-xs-left subheading">{{ props.item.FileName }}</td>
                                    <td class="text-xs-left subheading">{{ props.item.Type }}</td>
                                    <td class="text-xs-left subheading" :key="props.item.MediaId">
                                        <av-file-viewer :file-name="props.item.FileName" :type="props.item.Type"
                                                        :file="props.item.Value" :mediaId="props.item.MediaId" :key="props.item.MediaId"
                                                        :vertical="true" :showDocument="false" class="pa-0" :largeIcons="true"
                                                        style="background: rgba(255, 255, 255, 0)">
                                            <template slot="appendButton" v-if="props.item.IsDeleteble">
                                                <v-btn flat icon @click="deleteFile(props.item)" v-if="$grants.get().documentationPage.canUserManageDocuments">
                                                    <av-icon color="error" small>fa-trash</av-icon>
                                                </v-btn>
                                            </template>
                                        </av-file-viewer>
                                    </td>
                                </template>
                            </template>
                        </v-data-table>
                        <v-expansion-panel class="mb-2 ml-4" style="width: calc(100% - 24px);"
                                           v-if="item.linkedDocuments && Array.isUseful(item.linkedDocuments) && Array.isUseful(linked.documents.filter(el => el.visible))"
                                           v-for="(linked, key) in item.linkedDocuments" :key="key">
                            <v-expansion-panel-content class="pa-0 mt-3 expansionPanelHeader">
                                <template v-slot:header>
                                    <div class="title pa-0 ma-0" style="width: 90%;">
                                        {{ linked.ItemName }}
                                        <v-badge color="info" class="ml-2">
                                            <template v-slot:badge>
                                                <span>{{ calculateDocumentsNumber(linked) }}</span>
                                            </template>
                                            <v-icon color="grey darken-3" small> fa fa-file </v-icon>
                                        </v-badge>
                                    </div>
                                </template>
                                <v-data-table :headers="documentsHeaders" :items="linked.documents" class="elevation-1 ma-2 ml-4" hide-actions>
                                    <template v-slot:headers="props">
                                        <tr>
                                            <th v-for="header in props.headers" class="column text-xs-left subheading">
                                                {{ header.text }}
                                            </th>
                                            <th class="text-xs-left subheading column">
                                                <translate style="margin-left: 15px">Actions</translate>
                                            </th>
                                        </tr>
                                    </template>
                                    <template v-slot:items="props">
                                        <template v-if="props.item.visible">
                                            <td class="text-xs-left subheading">{{ props.item.FieldName }}</td>
                                            <td class="text-xs-left subheading">{{ props.item.FormName }}</td>
                                            <td class="text-xs-left subheading">{{ props.item.FileName }}</td>
                                            <td class="text-xs-left subheading">{{ props.item.Type }}</td>
                                            <td class="text-xs-left subheading">
                                                <av-file-viewer :file-name="props.item.FileName" :type="props.item.Type"
                                                                :file="props.item.Value" :mediaId="props.item.MediaId"
                                                                :vertical="true" :showDocument="false" class="pa-0" :largeIcons="true">
                                                </av-file-viewer>
                                            </td>
                                        </template>
                                    </template>
                                </v-data-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </div>
            <v-dialog v-model="uploadFileDialog" width="unset" height="350px" :persistent="true">
                <v-card min-width="720px">
                    <v-card-title class="headline grey lighten-2" primary-title>
                        {{ $gettext('Upload new files')}}
                    </v-card-title>
                    <v-layout column pa-3>
                        <v-spacer />
                        <v-spacer />
                        <av-text-field class="mt-4" :label="$gettext('File description')" v-model="uploadFileObject.fieldName"
                                       :placeholder="$gettext('Add a description for file')" :rules="[rules.required]"/>
                        <v-combobox multiple class="mt-5" :placeholder="$gettext('Add tags to categorize this file')"
                                    :items="tags" :label="$gettext('File tags')" v-model="uploadFileObject.tags"/>
                        <v-layout row align-left class="pa-0 mt-5">
                            <template v-for="file in uploadFileObject.files">
                                <av-file-viewer :file-name="file.FileName" :type="file.Type" :file="file.Value" :mediaId="file.MediaId" :medium="true" :vertical="true" class="pa-0">
                                    <template slot="appendButton">
                                        <v-btn flat icon small @click="removeFile(file)">
                                            <av-icon color="error" small>fa-trash</av-icon>
                                        </v-btn>
                                    </template>
                                </av-file-viewer>
                            </template>
                        </v-layout>
                        <v-layout row align-left class="pa-0 mt-4">
                            <av-icon color="orange darken-3" size="15" class="mr-2 ml-2" style="margin-top: 12px;">fas fa-exclamation-triangle</av-icon>
                            <label class="subheading" style="color: #EF6C00; margin-top: 12px">{{$gettext('Please note that {appName} can only open pdf and image files. All other file types can be stored and downloaded but require an external application').format({appName: $config.appName})}}</label>
                        </v-layout>
                        <v-layout row align-left class="pa-0 mt-2">
                            <UploadButton no-title-update color="info" :title="$gettext('Load file')" @file-update="uploadImageCallback" >
                                <template slot="icon-left">
                                    <av-icon left light>fas fa-upload</av-icon>
                                </template>
                            </UploadButton>
                        </v-layout>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" flat="flat" v-translate @click="uploadDocument" :disabled="disableUploadButton">
                                Upload
                            </v-btn>
                            <v-btn color="red darken-1" flat="flat" v-translate @click="uploadFileDialog = false">
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-layout>
                </v-card>
            </v-dialog>
        </template>
    </av-page>
</template>

<script>
import DataApis from '@/api/data';
import DateTime from '@/api/datetimeutils.js';
import AvFileViewer from "@/components/av-components/av-file-viewer";
import UploadButton from 'vuetify-upload-button';
import avPage from "@/components/av-components/av-page";

export default {
    name: "DocumentationPage",
    components: {AvFileViewer, UploadButton, avPage},
    data() {
        return {
            documentsHeaders: [
                { text: this.$gettext('Document scope'), value: 'FieldName'},
                { text: this.$gettext('Source form'), value: 'FormName'},
                { text: this.$gettext('Document name'), value: 'FileName'},
                { text: this.$gettext('Type'), value: 'Type' },
            ],
            filterByItemName: '',
            visualizations: [],
            uploadFileDialog: false,
            uploadFileObject: {
                tags: [],
                fieldName: '',
                files: [],
                id: 'auto',
            },
            tags: [],
            rules: {
                required: value => !!value || 'Required.',
            },
            backPath: '',
            assetId: '',
            maintenanceId: '',
            filterMessage: '',
            availableDocumentTypes: ['All'],
            docTypeSelected: "All"
        }
    },
    computed: {
        filteredItems() {
            let self = this;
            let filtered = this.$utils.detach(self.visualizations);
            if (self.docTypeSelected !== 'All')
                filtered = self.matchType(filtered);
            if (self.filterByItemName || self.docTypeSelected !== 'All')
                self.matchFilter(filtered);
            else if (self.docTypeSelected === 'All' && !self.filterByItemName)
                for (let item of filtered)
                    self.setVisible(item);

            filtered.sort((a,b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));
            return filtered;
        },
        disableUploadButton() {
            return this.uploadFileObject.fieldName === '' || this.uploadFileObject.files.length === 0;

        }
    },
    mounted() {
        this.$root.setCurrentPageTitle(this.$gettext("Documentation"));
        this.checkRouteParams();
        this.loadItems();
    },
    methods: {
        checkRouteParams() {
            let self = this;
            if(self.$route.params && self.$route.params.backPath)
                self.backPath = self.$route.params.backPath;
            if(self.$route.params && self.$route.params.assetId) {
                self.assetId = self.$route.params.assetId;
                self.filterMessage = self.$gettext("Documents filtered by asset: {0}").format(self.$route.params.assetId);
            }
            if(self.$route.params && self.$route.params.maintenanceId) {
                self.maintenanceId = self.$route.params.maintenanceId;
                self.filterMessage = self.$gettext("Documents filtered by maintenance: {0}").format(self.$route.params.maintenanceId);
            }
        },
        matchFilter(dataset) {
            let datasetResult = false
            for (let item of dataset) {
                let itemResult = false
                for (let key in item) {
                    if (typeof item[key] === 'string') {
                        if (item[key].toLowerCase().includes(this.filterByItemName.toLowerCase()))
                            itemResult = true;
                        if (itemResult) {
                            this.setVisible(item);
                        }
                    } else if (Array.isArray(item[key])) {
                        if (this.matchFilter(item[key]))
                            itemResult = true;
                    }
                    // if (itemResult) {
                    //     break
                    // }
                }

                if (itemResult) {
                    item.visible = true;
                    datasetResult = true;
                    // break
                }
            }
            return datasetResult
        },
        matchType(dataset) {
            for (let data of dataset) {
                if (data.items && Array.isUseful(data.items)) {
                    for (let item of data.items) {
                        if (item.documents && Array.isUseful(item.documents)) {
                            if (this.docTypeSelected === 'image')
                                item.documents = item.documents.filter(item => item.FileName.split('.').pop().match(/(jpg|jpeg|png|gif)$/i));
                            else if (this.docTypeSelected === 'video')
                                item.documents = item.documents.filter(item => item.FileName.split('.').pop().match(/(mp4|avi|mkv|mov|wmv)$/i));
                            else
                                item.documents = item.documents.filter(item => item.FileName.split('.').pop() === this.docTypeSelected);
                        }
                    }
                    data.items = data.items.filter(di => di.documents.length !== 0);
                }
            }
            return  dataset.filter(ds => ds.items.length !== 0);
        },
        setVisible(dataset) {
            if (String.prototype.isString(dataset))
                return
            dataset.visible = true
            for (let key in dataset) {
                if (Array.isArray(dataset[key])) {
                    for (let item of dataset[key]) {
                        this.setVisible(item)
                    }
                }
            }
        },
        loadItems() {
            let self = this;
            self.$root.setLoading(true, this.$gettext("Loading records"));
            let assetRedirect = false;
            let maintenanceRedirect = false;
            try {
                let queryAsset = {
                    raw: [{
                        continueOnError: true, max_results: 3000, name: "raw_0",
                        q: "index=Assets&fields=*EntityKeys*,*.Type,*.MediaId,*.FileName,@timestamp&sort=@timestamp,asc,timeless"
                    }]
                };
                let queryMaintenance = {
                    raw: [{
                        continueOnError: true, max_results: 3000, name: "raw_0",
                        q: "index=Maintenance&fields=*EntityKeys*,*NameSpace*,Transaction*.Type,Transaction*.MediaId,Transaction*.FileName,@timestamp&sort=@timestamp,asc,timeless"
                    }]
                };

                //Redirection from asset
                if (self.assetId !== '') {
                    queryAsset = {
                        raw: [{
                            continueOnError: true, max_results: 3000, name: "raw_0",
                            q: "index=Assets&fields=*EntityKeys*,*.Type,*.MediaId,*.FileName,@timestamp&filter=EntityKeys.assetId.keyword,=,{0}&sort=@timestamp,asc,timeless"
                                .format(self.assetId)
                        }]
                    };
                    assetRedirect = true;
                }
                //Redirection from maintenance
                if (self.maintenanceId !== '') {
                    queryMaintenance = {
                        raw: [{
                            continueOnError: true, max_results: 3000, name: "raw_0",
                            q: "index=Maintenance&fields=*EntityKeys*,*NameSpace*,Transaction*.Type,Transaction*.MediaId,Transaction*.FileName,@timestamp&filter=id.keyword,=,{0}&sort=@timestamp,asc,timeless"
                                .format(self.maintenanceId)
                        }]
                    };
                    maintenanceRedirect = true;
                }

                let queryOfflineMeasurements = {
                    raw: [{
                        continueOnError: true, max_results: 3000, name: "raw_0",
                        q: "index=data_data.entry.records&fields=NameSpace,*.Type,*.MediaId,*.FileName,@timestamp&sort=@timestamp,asc,timeless"
                    }]
                };
                let queryUserDocuments = {
                    raw: [{
                        continueOnError: true, max_results: 3000, name: "raw_0",
                        q: "index=user_documents*&sort=@timestamp,asc,timeless"
                    }]
                };
                let queryWizardDocuments = {
                    raw: [{
                        continueOnError: true, max_results: 3000, name: "raw_0",
                        q: "index=data_wizards.records&fields=*.NameSpace,*.Type,*.MediaId,*.FileName&sort=@timestamp,asc,timeless"
                    }]
                };
                DataApis.getDataBlob(queryAsset, DateTime.getRfc3339TimeStamp(new Date(Date.now() - 60000)), DateTime.getRfc3339TimeStamp(new Date()))
                    .then(result => {
                        if (result['raw_0'].length > 0 && !maintenanceRedirect)
                            self.calculateAssetDocuments(result['raw_0']);
                        DataApis.getDataBlob(queryMaintenance, DateTime.getRfc3339TimeStamp(new Date(Date.now() - 60000)), DateTime.getRfc3339TimeStamp(new Date()))
                            .then(result => {
                                // console.log(result['raw_0']);
                                if (result['raw_0'].length > 0)
                                    self.calculateMaintenanceDocuments(result['raw_0'], assetRedirect, maintenanceRedirect);
                                if (assetRedirect || maintenanceRedirect) {
                                    self.$root.setLoading(false);
                                    return;
                                }
                                DataApis.getDataBlob(queryOfflineMeasurements, DateTime.getRfc3339TimeStamp(new Date(Date.now() - 60000)), DateTime.getRfc3339TimeStamp(new Date()))
                                    .then(result => {
                                        let freeFormDocuments = result['raw_0'];
                                        //TODO How to handle wizard records documents (CameraImage, InferenceImage, Validation Attempts images..)
                                        DataApis.getDataBlob(queryWizardDocuments, DateTime.getRfc3339TimeStamp(new Date(Date.now() - 60000)), DateTime.getRfc3339TimeStamp(new Date()))
                                            .then(result => {
                                                self.calculateWizardsDocuments(result['raw_0'], freeFormDocuments);
                                                DataApis.getDataBlob(queryUserDocuments, DateTime.getRfc3339TimeStamp(new Date(Date.now() - 60000)), DateTime.getRfc3339TimeStamp(new Date()))
                                                    .then(result => {
                                                        self.calculateUserDocuments(result['raw_0']);
                                                        self.$root.setLoading(false);
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                        self.$root.setLoading(false);
                                                    })
                                                    .finally(() => {self.$root.setLoading(false);})
                                            })
                                            .catch(err => {
                                                console.log(err);
                                                self.$root.setLoading(false);
                                            })
                                            .finally(() => {self.$root.setLoading(false);})
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        self.$root.setLoading(false);
                                    })
                                    .finally(() => {self.$root.setLoading(false);})
                            })
                            .catch(err => {
                                console.log(err);
                                self.$root.setLoading(false);
                            })
                            .finally(() => {self.$root.setLoading(false);})
                    })
                    .catch(err => {
                        console.log(err);
                        self.$root.setLoading(false);
                    })
                    .finally(() => {self.$root.setLoading(false);})
            } catch(ex) {
                debugger;
                console.error(ex);
                self.$root.setLoading(false);
            }
        },
        calculateAssetDocuments(items) {
            let self = this;
            let newItem = {};
            let documents = [];
            let assetObject = {
                groupName: 'Assets',
                sort: 1,
                items: [],
            };
            items.forEach(item => {
                if(item.descriptor)
                    return;
                newItem.ItemName = item.EntityKeys.assetId;
                for (const itemProps in item[item.EntityKeys.EntityName]) {
                    let propertyValue = item[item.EntityKeys.EntityName][itemProps];
                    if (Array.isArray(propertyValue) && self.isArrayOfDocuments(propertyValue)) {
                        propertyValue.forEach(item2 => {
                            item2.FieldName = itemProps;
                            item2.FormName = item.EntityKeys.EntityName;
                            documents.push({...item2});
                            self.calculateDocumentsTypeSelect(item2);
                        });
                    } else if (Object.isUseful(propertyValue)) {
                        self.findDocumentsRecursive(propertyValue, documents, item.EntityKeys.EntityName, itemProps);
                    }
                }
                newItem.documents = [...documents];
                newItem.linkedDocuments = [];
                documents.clear();
                assetObject.items.push({...newItem});
            });
            self.visualizations.push({...assetObject});
        },
        calculateMaintenanceDocuments(items, assetRedirect, maintenanceRedirect) {
            let self = this;
            let helpObj = {};
            let assetVisualization = self.visualizations.find(v => v.groupName === 'Assets');
            items.forEach(item => {
                let maintenanceDocument = {
                    ItemName: 'Maintenance documents',
                    documents: []
                };
                let asset = null;
                if (!maintenanceRedirect)
                    asset = assetVisualization.items.find(asset => asset.ItemName === item.Entity.EntityKeys.assetId);
                if (asset === undefined)
                    return
                let transaction = item.Transaction;

                if (!helpObj.hasOwnProperty(item.Transaction.NameSpace))
                    helpObj[item.Transaction.NameSpace] = {};
                if (!helpObj[item.Transaction.NameSpace].hasOwnProperty('items'))
                    helpObj[item.Transaction.NameSpace].items = [];

                for (const itemProps in transaction[transaction.NameSpace]) {
                    let propertyValue = transaction[transaction.NameSpace][itemProps];
                    if (Array.isArray(propertyValue) && self.isArrayOfDocuments(propertyValue)) {
                        propertyValue.forEach(item2 => {
                            item2.FieldName = itemProps;
                            item2.FormName = transaction.NameSpace;
                            //Populate asset with maintenance
                            maintenanceDocument.documents.push({...item2});
                            //For maintenance section
                            helpObj[item.Transaction.NameSpace].items.push({...item2});
                        });
                    } else if (Object.isUseful(propertyValue)) {
                        //Populate asset with maintenance
                        self.findDocumentsRecursive(propertyValue, maintenanceDocument.documents, transaction.NameSpace, itemProps);
                        //For maintenance section
                        self.findDocumentsRecursive(propertyValue, helpObj[item.Transaction.NameSpace].items, transaction.NameSpace, itemProps);
                    }
                }
                if (!maintenanceRedirect) {
                    if (asset.linkedDocuments && Array.isUseful(asset.linkedDocuments)) {
                        asset.linkedDocuments[0].documents = asset.linkedDocuments[0].documents.concat(maintenanceDocument.documents);
                    } else {
                        asset.linkedDocuments.push(maintenanceDocument);
                    }
                }
            });
            //Maintenance section
            if (Object.isUseful(helpObj) && !assetRedirect) {
                let maintenanceSection = { groupName: 'Maintenance', sort: 2, items: []};
                for (const prop in helpObj) {
                    maintenanceSection.items.push({ItemName: prop, documents: helpObj[prop].items});
                }
                self.visualizations.push(maintenanceSection);
            }
        },
        calculateFreeFormDocuments(items) {
            let self = this;
            let addOtherVisualization = false;
            let tags = self.$settings.getTagsSettings();
            //Get distinct list of tags from tags settings
            self.calculateDistinctTags(tags);
            let helpObj = {};
            items.forEach(item => {
                //Check for every item if items form name is in tags
                if (tags != null && tags.hasOwnProperty('bindings') && tags.bindings.hasOwnProperty(item.NameSpace))
                    item.Tags = tags.bindings[item.NameSpace];
                else {
                    item.Tags = [];
                    addOtherVisualization = true;
                }

                if (!helpObj.hasOwnProperty(item.NameSpace))
                    helpObj[item.NameSpace] = {};
                if (!helpObj[item.NameSpace].hasOwnProperty('items'))
                    helpObj[item.NameSpace].items = [];

                for (const itemProps in item[item.NameSpace]) {
                    let propertyValue = item[item.NameSpace][itemProps];
                    //Excluded line clearance files!
                    if ((propertyValue.Type && propertyValue.Type === 'CameraImageData')) {
                        delete helpObj[item.NameSpace];
                    } else if (Array.isArray(propertyValue) && self.isArrayOfDocuments(propertyValue)) {
                        propertyValue.forEach(item2 => {
                            item2.FormName = item.NameSpace;
                            item2.FieldName = itemProps;
                            helpObj[item.NameSpace].items.push({...item2});
                        });
                    } else if (Object.isUseful(propertyValue)) {
                        self.findDocumentsRecursive(propertyValue, helpObj[item.NameSpace].items, item.NameSpace, itemProps);
                    }
                }
            });

            if (addOtherVisualization)
                self.visualizations.push({ groupName: "Other", sort: 4, items: []});


            self.visualizations.forEach(visualization => {
                let helpArray = [];
                if (visualization.groupName !== 'Assets' && visualization.groupName !== 'Maintenance') {
                    items.forEach(item => {
                        //First if added to exclude items with line clearance files
                        if (helpObj[item.NameSpace]) {
                            if (item.Tags.length > 0 && item.Tags.includes(visualization.groupName) && !helpArray.some(item2 => item2.ItemName === item.NameSpace)) {
                                helpArray.push({ItemName: item.NameSpace, documents: helpObj[item.NameSpace].items});
                            } else if (item.Tags.length === 0 && visualization.groupName === 'Other' && !helpArray.some(item2 => item2.ItemName === item.NameSpace)) {
                                helpArray.push({ ItemName:  item.NameSpace, documents: helpObj[item.NameSpace].items });
                            }
                        }
                    });
                    visualization.items = [...helpArray];
                    helpArray.clear();
                }
            });
        },
        calculateWizardsDocuments(items, freeFormDocuments) {
            let self = this;
            //TODO merge wizards documents
            // items.forEach(item => {
            //     for (let prop in item) {
            //         if (item[prop][item[prop]['NameSpace']])
            //             freeFormDocuments.push(item[prop]);
            //     }
            // })
            //Calculate free documents after merging forms from wizards
            self.calculateFreeFormDocuments(freeFormDocuments);
        },
        findDocumentsRecursive(propertyValue, documents, formName, propName) {
            let self = this;
            let skipName = false;
            if (Array.isArray(propertyValue) && !self.isArrayOfDocuments(propertyValue))
                skipName = true;
            for (const item2Props in propertyValue) {
                let propertyValue2 = propertyValue[item2Props];
                if (Array.isArray(propertyValue2) && self.isArrayOfDocuments(propertyValue2)) {
                    propertyValue2.forEach(item2 => {
                        item2.FieldName = propName + " / " + item2Props;
                        item2.FormName = formName;
                        documents.push({...item2});
                    })
                } else if (Object.isUseful(propertyValue2)) {
                    if (skipName)
                        self.findDocumentsRecursive(propertyValue2, documents, formName, propName);
                    else
                        self.findDocumentsRecursive(propertyValue2, documents, formName, propName + " / " + item2Props);
                }
            }
        },
        isArrayOfDocuments(array) {
            return array[0].hasOwnProperty('FileName') && array[0].hasOwnProperty('MediaId') && array[0].hasOwnProperty('Type');
        },
        calculateDistinctTags(tags) {
            let self = this;
            let fullTagsArray = [];
            let visualization = {
                groupName: "",
                sort: 3,
                items: [],
            }
            if (tags != null &&tags.hasOwnProperty('bindings')) {
                for (const formName in tags.bindings) {
                    fullTagsArray = fullTagsArray.concat(tags.bindings[formName]);
                }
            }
            let uniqueTags = [...new Set(fullTagsArray)];
            uniqueTags.forEach(tag => {
               visualization.groupName = tag;
                self.visualizations.push({...visualization});
            });
        },
        calculateDocumentsNumber(item) {
            let documents = 0;
            if (Array.isUseful(item.documents.filter(el => el.visible)))
                documents += item.documents.filter(el => el.visible).length;
            if (item.linkedDocuments && Array.isUseful(item.linkedDocuments)) {
                item.linkedDocuments.forEach(linked => {
                    if (Array.isUseful(linked.documents.filter(el => el.visible)))
                        documents += linked.documents.filter(el => el.visible).length;
                });
            }
            return documents;
        },
        openUploadFileDialog() {
            let self = this;
            self.uploadFileDialog = true;
            self.tags = self.$settings.getTagsSettings()['forms'];
        },
        uploadDocument() {
            let self = this;
            self.uploadFileObject.isDeletable = true;
            let checkDocument = self.checkIfDocumentAlreadyExist();
            if (checkDocument.hasDocument) {
                self.$root.showErrorNotification(checkDocument.message, true);
                return;
            }
            self.$dataEntry.saveForm(self.uploadFileObject, 'user/documents')
                .then(t => {
                    self.$root.showInfoNotification(self.$gettext('Files uploaded successfully'), false);
                    self.addUserDocuments(t.document);
                    let variable = t.document.fieldName + '/' + t.document.tags.join() + '/';
                    t.document.files.forEach(file => {
                        if (t.document.files[t.document.files.length - 1] === file)
                            variable += file.FileName;
                        else
                            variable += file.FileName + ", ";
                    });
                    self.$audits.save(self.$root.userName, self.$audits.items().documentUploaded, "", "", variable)
                        .catch(err => {
                            self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
            self.uploadFileDialog = false;
            self.resetUploadFileObject();
        },
        checkIfDocumentAlreadyExist() {
            let self = this;
            let result = {
                hasDocument: false,
                message: ''
            }
            if (!Array.isUseful(self.uploadFileObject.tags)) {
                self.ifDocumentExist('Other', result);
                return result;
            } else {
                self.uploadFileObject.tags.forEach(tag => {
                    self.ifDocumentExist(tag, result);
                })
                return result
            }

        },
        ifDocumentExist(tag, result) {
            let self = this;
            let other = self.visualizations.find(v => v.groupName === tag);
            if (other !== undefined) {
                let userDocuments = other.items.find(v => v.ItemName === 'UserDocuments');
                if (userDocuments !== undefined) {
                    for (const file of self.uploadFileObject.files) {
                        if (userDocuments.documents.some(doc => doc.FieldName === self.uploadFileObject.fieldName && doc.FileName === file.FileName)) {
                            result.hasDocument = true;
                            result.message = self.$gettext("Documents with description: {0} and name: {1} already exist in section: {2}/UserDocuments")
                                .format(self.uploadFileObject.fieldName, file.FileName, tag);
                            break;
                        }
                    }
                }
            }
        },
        saveTagsSettings(elementAdded) {
            let tags = this.$settings.getTagsSettings();
            if (!Array.isUseful(tags['forms']) || tags['forms'].filter(at => at.toLowerCase().includes(elementAdded.toLowerCase())).length === 0) {
                tags['forms'].push(elementAdded);
                this.$settings.saveTagsSettings(tags)
                    .catch(err => {
                        console.log(err);
                    })
            }
        },
        uploadImageCallback(file) {
            if (file) {
                if (file.size === 0) {
                    this.$root.showErrorNotification(this.$gettext("File is empty!"), true);
                    return
                }
                const reader = new FileReader();
                let self = this;
                reader.onload = () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    self.uploadFileObject.files.push({
                        FileName:file.name,
                        Type:file.type,
                        Value: encoded
                    });
                };
                reader.readAsDataURL(file);
            }
        },
        removeFile(file) {
            this.uploadFileObject.files = this.uploadFileObject.files.filter(item => item.FileName !== file.FileName);
        },
        resetUploadFileObject() {
            this.uploadFileObject = {
                tags: [],
                fieldName: '',
                files: [],
                id: 'auto'
            }
        },
        calculateUserDocuments(userDocuments) {
            userDocuments.forEach(userDocument => {
               this.addUserDocuments(userDocument);
            });
        },
        addUserDocuments(userDocuments) {
            let self = this;
            let userDocumentToAdd = {
                ItemName: 'UserDocuments',
                documents: []
            }
            userDocuments.files.forEach(file => {
                file.FieldName = userDocuments.fieldName;
                file.FormName = 'User documents';
                file.IsDeleteble = userDocuments.isDeletable;
                file.Id = userDocuments.id;
                file.Tags = userDocuments.tags;
                userDocumentToAdd.documents.push({...file});
                self.calculateDocumentsTypeSelect(file);
            });
            //If no tag selected
            if (userDocuments.tags.length === 0) {
                self.userDocumentsToVisualizations(userDocumentToAdd, 'Other')
                return
            }
            userDocuments.tags.forEach(tag => {
                self.userDocumentsToVisualizations(userDocumentToAdd, tag)
            });
        },
        userDocumentsToVisualizations(userDocumentToAdd, tag) {
            let self = this;
            let visualization = self.visualizations.find(v => v.groupName === tag);
            //if there is already tag in visualizations, check if there is already some userDocuments if true push to new documents else push new userDocuments
            // section with new documents
            if (visualization) {
                let item = visualization.items.find(i => i.ItemName === 'UserDocuments');
                if (item)
                    item.documents = item.documents.concat(userDocumentToAdd.documents);
                else
                    visualization.items.push({...userDocumentToAdd});
            } else {
                // if there is no tag create new group with new tag name
                let newVisualization = {
                    groupName: tag,
                    items: [userDocumentToAdd],
                    sort: tag === 'Other'? 4: 3
                }
                self.visualizations.push({ ...newVisualization });
            }
        },
        deleteFile(userDocument) {
            let self = this;
            self.$root.showDialogBox(this.$gettext("This will delete document in all declared tags"), null, this.$gettext("Ok"), () => {
                self.$dataEntry.deleteForm('user/documents', userDocument.Id)
                    .then(t => {
                        if (t === 'deleted') {
                            self.removeUserDocuments(userDocument);
                            self.$root.showInfoNotification(this.$gettext('Files deleted successfully'), false);
                            let variable = userDocument.FieldName + '/' + userDocument.Tags.join() + '/' + userDocument.FileName;
                            self.$audits.save(self.$root.userName, self.$audits.items().documentDeleted, "", "", variable)
                                .catch(err => {
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, this.$gettext("Cancel"), null);
        },
        removeUserDocuments(userDocuments) {
            let self = this;
            if (userDocuments.Tags.length === 0) {
                self.removeUserDocumentsFromVisualization('Other', userDocuments.Id);
                return
            }
            userDocuments.Tags.forEach(tag => {
                self.removeUserDocumentsFromVisualization(tag, userDocuments.Id)
            });
        },
        removeUserDocumentsFromVisualization(tag, id) {
            let self = this;
            let visualization = self.visualizations.find(v => v.groupName === tag);
            let item = visualization.items.find(i => i.ItemName === 'UserDocuments');
            item.documents = item.documents.filter(document => document.Id !== id);
            if (item.documents.length === 0)
                visualization.items = visualization.items.filter(item => item.ItemName !== 'UserDocuments');
            if (visualization.items.length === 0)
                self.visualizations = self.visualizations.filter(v => v.groupName !== tag);
        },
        goBack() {
            if(this.backPath)
                this.$router.push(this.backPath);
            else
                this.$router.go(-1);
        },
        calculateDocumentsTypeSelect(file) {
            let fileExtension = file.FileName.split('.').pop();
            if (fileExtension.match(/(jpg|jpeg|png|gif)$/i))
                fileExtension = 'image';
            if (fileExtension.match(/(mp4|avi|mkv|mov|wmv)$/i))
                fileExtension = 'video';
            if (!this.availableDocumentTypes.includes(fileExtension))
                this.availableDocumentTypes.push(fileExtension);
        }
    },
    watch: {
        'uploadFileObject.tags'(after, before) {
            if (!(after instanceof Array) || !(before instanceof Array))
                return;

            if (after.length > before.length)
                this.saveTagsSettings(after.last());
        }
    }
}
</script>

<style scoped>

</style>
