import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function (/* { store, ssrContext } */) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    //mode: 'history'//process.env.VUE_ROUTER_MODE,
    //base: '/avionics-core-ui/static'//process.env.VUE_ROUTER_BASE
  })

  let history=[]
  let currentIndexHistory=0
  let saveHistory=true

  Router.beforeEach((to, from, next) => {
    const originalPush = VueRouter.prototype.push;
    VueRouter.prototype.push = function push(location) {
        return originalPush.call(this, location).catch(err => err)
    };
    let params = new URLSearchParams(location.search);
    if(!to.query.t)
        to.query.t = params.get("t");
    if(!to.query.r)
      to.query.r = params.get("r");

    if(!to.query.t) {
      // redirect to login page if not logged in and trying to access a restricted page
      const publicPages = ['/login', '/reportingservice','/licensemanagement'];
      const authRequired = !publicPages.includes(to.path) && !to.path.match("/dashboards/.*/maximize")&& !to.path.match("/scan.*");

      if (authRequired && !Vue.prototype.$store.state.shared.token) {
        next({ name: 'login', query: { redirect: to.fullPath } });
      }
    }
    pushRouteToHistory(to)
    next();
});

  Vue.prototype.goBack=function (){
    history.splice(-1)
    currentIndexHistory=history.length
    Vue.prototype.haveHistory=history.length>1
    saveHistory=false
    Vue.prototype.$store.commit('setHaveHistory',false)
    Router.push(history[history.length-1])
  }


  function pushRouteToHistory(route){
    if (!saveHistory){
      saveHistory=true
      return
    }
    if (history.length  === 15){
      history.shift()
    }
    if(route.name==='home'){
      history=[]
      currentIndexHistory=0
      Vue.prototype.$store.commit('setHaveHistory',false)
      return
    }

    history.push({
      fullPath: route.fullPath,
      meta: route.meta,
      name: route.name,
      params: route.params,
      path: route.path,
      query: route.query
    })
    currentIndexHistory=history.length -1


  }



  return Router
}
