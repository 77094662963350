<template>
    <v-layout column>
        <v-card v-if="messages.length > 1" class="ma-0 pa-0">
            <v-layout row>
                <v-card-title class="headline">{{messages.length + ' ' + $gettext('Notifications pending')}}</v-card-title>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-btn v-if="messages.length > 1" color="blue darken-1" flat @click="nextMessage()" >
                        ({{currentMessageIndex + 1}}/{{messages.length}}) {{$gettext('NEXT')}}
                    </v-btn>
                    <v-btn color="green darken-1" flat="flat" @click="messages.clear()">
                        {{$gettext('CLOSE ALL')}}
                    </v-btn>
                </v-card-actions>
            </v-layout>
        </v-card>
        <div :style="colorFull ? ('background-color: ' + color) : ''">
            <v-layout v-if="0 === 1" row class="ma-2">
                <v-layout v-if="currentMessage.icon">
                    <v-flex xsi style="padding-top: 8px; padding-left: 30px">
                        <av-icon color="white" size="50">{{currentMessage.icon}}</av-icon>
                    </v-flex>
                    <v-flex v-html="currentMessage.message" style="color: white; font-weight: bold; font-size: large; padding-left: 30px ">
                        {{currentMessage.message}}
                    </v-flex>
                </v-layout>
                <span v-else style="color: white; font-weight: bold; width: 100%; text-align: center; font-size: large;"  v-html="currentNotification.message">
                    {{currentMessage.message}}
                </span>
                <v-btn flat @click="clearMessage()" >
                    <av-icon color="white" >fa-times</av-icon>
                </v-btn>
            </v-layout>
            <v-card class="ma-0" :style="colorFull ? ('background-color: ' + color) : ''">
                <v-card-title v-if="currentMessage.title" class="headline">{{currentMessage.title }}</v-card-title>
                <v-card-text>
                    <v-layout row v-if="currentMessage.icon && currentMessage.message">
                        <v-flex xsi style="padding-top:8px; padding-left: 25px">
                            <av-icon size="50" :color="colorFull ? 'white' : color">{{currentMessage.icon}}</av-icon>
                        </v-flex>
                        <v-flex v-html="currentMessage.message" :style="'font-weight: bold; font-size: large; padding-left: 30px;' + (colorFull ? 'color: white;' : '')">
                            {{currentMessage.message}}
                        </v-flex>
                    </v-layout>
                    <v-layout column v-else v-html="currentMessage.message">
                        {{currentMessage.message}}
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <label v-if="currentMessage.timestamp" class="subheading" :style="'margin-left: 20px;' + (colorFull ? 'color: white;' : '')">{{currentMessage.timestamp.format()}}</label>
                    <v-spacer></v-spacer>
                    <v-btn v-if="currentMessage.yesLabel" :color="colorFull ? 'white' : 'green darken-1'" flat="flat" @click="clearMessage(true)">
                        {{ currentMessage.yesLabel  }}
                    </v-btn>
                    <v-btn v-if="currentMessage.noLabel" :color="colorFull ? 'white' : 'red darken-1'" flat="flat" @click="clearMessage(false)">
                        {{ currentMessage.noLabel  }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-layout>
</template>

<script>
    export default {
        name: "AvNotificationsQueue",
        props: {
            messages: {
                type: Array,
                default: () => []
            },
            colorFull: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                currentMessageIndex: 0,
                autocloseTimer: null
            }
        },
        computed: {
            hasMessages() {
                return Array.isUseful(this.messages);
            },
            currentMessage() {
                if(!this.hasMessages)
                    return {};
                return this.messages[this.currentMessageIndex];
            },
            color() {
                if(!this.hasMessages)
                    return "";
                return this.currentMessage.color
            }
        },
        mounted() {
            this.checkNewMessages();
        },
        watch: {
            messages() {
                this.checkNewMessages();
            }
        },
        methods: {
            checkNewMessages() {
                if(this.hasMessages)
                    this.currentMessageIndex = this.messages.length - 1;
                else this.currentMessageIndex = 0;
                if(!this.autocloseTimer)
                    this.autocloseTimer = setInterval(this.checkMessagesPersistence, 1000);
            },
            clearMessage(result, clearAtIndex = -1) {
                let index = this.currentMessageIndex;
                if(clearAtIndex >= 0)
                    index = clearAtIndex;
                if (result) {
                    if (this.messages[index].yesFunction !== null)
                        this.messages[index].yesFunction();
                } else {
                    if (this.messages[index].noFunction !== null)
                        this.messages[index].noFunction();
                }
                this.messages.splice(index, 1);
                if(this.currentMessageIndex >= this.messages.length)
                    this.currentMessageIndex--;
                if(this.currentMessageIndex < 0)
                    this.currentMessageIndex = 0;
            },
            nextMessage() {
                this.currentMessageIndex++;
                if(this.currentMessageIndex >= this.messages.length)
                    this.currentMessageIndex = 0;
            },
            checkMessagesPersistence() {
                if(!this.hasMessages) {
                    clearInterval(this.autocloseTimer);
                    this.autocloseTimer = null;
                }
                let anyActive = false;
                for(let i = 0 ; i < this.messages.length ; i++) {
                    if(this.messages[i].time > 0) {
                        this.messages[i].time -= 1000;
                        if(this.messages[i].time <= 0) {
                            this.clearMessage(true, i);
                            i -= 1;
                        } else anyActive = true;
                    }
                }
                if(!anyActive) {
                    clearInterval(this.autocloseTimer);
                    this.autocloseTimer = null;
                }
            }
        }
    }
</script>