<template>
    <FormBase />
</template>
<script>
    import FormBase from '@/components/dynamic-elements/forms/FormBase.vue';
    import VueInstance from "@/api/vueinstance";
    import Transactions from "@/api/transactions";

    export default {
        name: "FormEntity",
        extends: FormBase,
        components: {
            FormBase
        },
        data() {
            return {
                tweaksUpdating: false,
                entityPrototype: {id: "", properties: [], namespace: "", EntityKeys: []},
                systemEntity: "",
                exposedName: ""
            }
        },
        mounted() {
            this.formConfigurationMode.requiresJoin = true;
            this.formConfigurationMode.requiresPrimaryKey = true;
            this.formConfigurationMode.requiresTargetEntity = false;
            this.formConfigurationMode.isEntity = true;
            this.formConfigurationMode.isTransaction = false;
            this.properties.unDeletable = true;
            this.childHandlers.canBeDeleted = this.canBeDeleted;
            this.childHandlers.onDeploy = this.onDeploy;
            this.childHandlers.onUnDeploy = this.deactivateAllInstances;
            this.childHandlers.getDefaultName = function() { return "Entity" };
            this.childHandlers.onNewElementCreated.push(this.onNewEntity);
            this.childHandlers.onElementLoaded.push(this.onOpenEntity);
            this.documentFormatHandler = this.buildEntityDocument;
            this.documentSaveHandler = this.saveEntity;
            this.visualizationTweaks.insertItem(0, {
                    name: this.$gettext("Basic entity (Can only be inherited and won't show in entities menu)"),
                    id: "basicentity",
                    type: "bool",
                    hidden: true,
                    default: function () {
                        return false;
                    }
                },
                {
                    name: this.$gettext("allow user to fill in entity"),
                        id: "fillableByHumans",
                    type: "bool",
                    hidden: true,
                    default: function () {
                        return true;
                    }
                },
                {
                    name: this.$gettext("allow devices to fill in entity"),
                    id: "fillableByMachine",
                    type: "bool",
                    hidden: true,
                    default: function () {
                        return false;
                    }
                });

            this.validators.push({
                onDeploy: true,
                onChange: false,
                validator: this.checkValidKeys
            });
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    if(this.tweaksUpdating) {
                        this.tweaksUpdating = false;
                        return;
                    }

                    //let basicEntity = this.getTweakValue('basicentity');
                    let previousState = [this.getTweak("fillableByHumans"), this.getTweak("fillableByMachine")];
                    //For basic entities fill source is not very relevant, set to human and hide. The fill mode will be driven by inheriting entity
                    // this.getTweak("fillableByHumans").hidden = basicEntity;
                    // this.getTweak("fillableByMachine").hidden = basicEntity;
                    // if(basicEntity) {
                    //     this.setTweakValue("fillableByHumans", true);
                    //     this.setTweakValue("fillableByMachine", false);
                    // }
                    let fillableByHuman = this.getTweakValue('fillableByHumans');
                    let fillableByMachine = this.getTweakValue('fillableByMachine');
                    if (fillableByHuman === false && fillableByMachine === false) {
                        VueInstance.get().$root.showErrorNotification(this.$gettext("At least one option must be checked: allow user to fill in entity or allow devices to fill in entity"), true, true);
                        this.setTweakValue("fillableByHumans", true);
                        return
                    }
                    if (fillableByHuman === true && !fillableByMachine) {
                        this.properties.fillableMode = 1
                    } else if (!fillableByHuman && fillableByMachine) {
                        this.properties.fillableMode = 2
                    } else if (fillableByHuman && fillableByMachine) {
                        this.properties.fillableMode = 3
                    }
                    this.properties.sidebarVisibilityLink = (fillableByHuman /*&& !basicEntity*/);
                    if(JSON.stringify([this.getTweak("fillableByHumans"), this.getTweak("fillableByMachine")]) !== JSON.stringify(previousState))
                        this.tweaksUpdating = true;
                    this.saveTweaks()
                },
                deep: true,
            },

            'properties.name': {
                handler(value) {
                    debugger
                    this.entityPrototype.id = value
                }
            }
        },
        methods: {
            setAppropriateTweaks() {
                this.setTweakValue("target", 1001);
                for (let tweak of this.visualizationTweaks) {
                    tweak.hidden = !(/*tweak.id === 'basicentity' || */tweak.id === 'ImportTemplate' || tweak.id === 'fillableByHumans' || tweak.id === 'fillableByMachine');
                }
            },
            onOpenEntity() {
                //In case generic entities are restored a subtemplate is used to set tweaks (Like for Assets)
                //this.setAppropriateTweaks();
                //When an entity form is imported from outside, in case source entity was activated
                // we import it activated to simplify user experience. Yet prototypes are not imported.
                //here we check for prototype existence on loading and in case we create it.
                let self = this;
                if(this.properties.deployStatus > 0)    //If item is deployed prototype shall exist
                    this.$entities.getPrototype(this.properties.name)
                        .then(prototype => {
                            if(!Array.isUseful(prototype))
                                throw "";
                        })
                        .catch(err => {
                            //We are not handling error for simplicity. This would fail only in case of severe
                            //system malfunctioning. Anyway error would be later intercepted during entity saving in backend
                            //and user is prompted to try deactivating and reactivating entity
                            self.createPrototype();
                        })
            },
            onNewEntity() {
                // this.setAppropriateTweaks();
                // this.formVariables = [];
                // this.$emit('dataItemsUpdated');
            },
            checkValidKeys() {
                //Check keys only if entity is not a basic reusable type
                // if(this.getTweakValue("basicentity"))
                //     return true;
                //Check key constraints
                let hasKeys = false;
                for(let variable of this.scope().formVariables)
                    if(variable.primaryCharacteristic) {
                        hasKeys = true;
                        break
                    }

                if(!hasKeys) {
                    this.$root.showErrorNotification(this.$gettext("No key fields configured. Entity must have at least one field configured as key"), true, true);
                    return false;
                }

                return true;
            },
            //Check whether there are any instances attached to entity. If not entity could be deleted and modified
            async canBeDeleted() {

                // if(this.getTweakValue("basicentity"))
                //     return true;
                return await new Promise((resolve) => {
                    this.$entities.entityInstancesCount(this.scope().properties.name).then(count => {
                        resolve(count === 0);
                    })
                    .catch(error => {
                        debugger
                        console.log(error);
                        resolve(false);  //TODO check what happens if count is 0
                    })
                });
            },
            async setInstancesActive(active) {
                let self = this;
                let verb = active ? self.$gettext("activated") : self.$gettext("deactivated");
                return new Promise((resolve) => {
                    this.$entities.setInstancesActive(this.properties.name, active)
                        .then(count => {
                            self.$root.showInfoNotification(this.$gettext("Entity was {0} together with all its instances").format(verb), true, true);
                            resolve(true);
                        })
                        .catch(error => {
                            debugger
                            active ? self.properties.deployStatus = 1 : self.dataExplorationMode.requiresDoubleActivation ? self.properties.deployStatus = -1 : self.properties.deployStatus = 0
                            console.log(error);
                            self.$root.showErrorNotification(this.$gettext("An error occurred during operation. Entity will not be {0}").format(verb), true, true);
                            resolve(false)
                        })
                });
            },
            async deactivateAllInstances() {
                this.$audits.save(this.$root.userName, this.$audits.items().formDeactivated, "", "", this.entityPrototype.id);
                return this.setInstancesActive(false);
            },
            async createPrototype() {
                let self = this;
                return new Promise((resolve, reject) => {
                    //This is return object useful to identify possible orphan transactions belonging to the newborn entity
                    let newPrototypeInfo = {
                        keysForSearch: [],
                        entityName: ""
                    };

                    self.formVariables.forEach(element => {
                        if (!element.join || (!element.join.entity && !element.join.key))
                            self.entityPrototype.properties.push({name: element.name, type: element.type, uniquenessLevel: (element.primaryCharacteristic ? element.uniquenessLevel : "none")})
                        else if (element.join && element.join.entity && element.join.key)
                            self.entityPrototype.properties.push({name: element.name, type: element.type, uniquenessLevel: (element.primaryCharacteristic ? element.uniquenessLevel : "none"), join: element.join})
                        else if (element.join) {
                            self.$root.showErrorNotification(self.$gettext("You must fill both of join entity and key"), true, true);
                            reject(false);
                        }
                        if (element.primaryCharacteristic) {
                            self.entityPrototype.EntityKeys.push(element.name);
                            newPrototypeInfo.keysForSearch.push(element.name);
                        }
                    });
                    self.entityPrototype.EntityKeys.push(self.entityPrototype.id);
                    if(self.systemEntity)
                        self.entityPrototype.EntityKeys.push(self.systemEntity);
                    newPrototypeInfo.entityName = self.entityPrototype.id;
                    //Deploy prototype
                    self.$entities.createPrototype(self.entityPrototype)
                        .then(() => {
                            resolve(newPrototypeInfo)
                        })
                        .catch(() => {
                            debugger;
                            reject(null)
                        });
                })
            },
            async onDeploy() {
                let self = this;
                return new Promise(async (resolve, reject) => {
                    //Deploy prototype
                    try {
                        let prototypeInfo = await this.createPrototype()
                        self.activateAllInstances()
                            .then(() => {
                                self.$audits.save(self.$root.userName, self.$audits.items().formActivated, "", "", prototypeInfo.EntityName);
                                resolve(true)
                            });
                    } catch (err) {
                        debugger;
                        reject(false)
                    }

                    //Transactions.updateOrphanTransactionsAddEntityName(prototypeInfo)
                })
            },
            async activateAllInstances() {
                let self = this;
                return new Promise((resolve) => {
                    self.setInstancesActive(true)
                        .then((result) => {
                            //If deploy was ok, turn all variables to not modifiable in name and type to avoid mapping conflicts
                            if(result) {
                                let variableLocker = function(variables) {
                                    for(let variable of variables) {
                                        if(!variable.undeletable) {
                                            variable.editableName = false;
                                            variable.editableType = false;
                                        }
                                        if(Array.isUseful(variable.children))
                                            variableLocker(variable.children);
                                    }
                                };
                                variableLocker(self.scope().formVariables)
                            }
                            resolve(result)
                        })
                });
            },
            buildEntityDocument(document, existingEntity = false) {
                let primaryKeyVariablesNameAndValue = [];
                let primaryKeyVariables = this.variables.filter(variable => variable.primaryCharacteristic === true);
                primaryKeyVariables.forEach(variable => { primaryKeyVariablesNameAndValue.push({name: variable.name, value: variable.value}) });
                let EntityKeys = { EntityName: this.scope().properties.name };
                primaryKeyVariables.forEach(item => {
                    //All keys must be stringified to avoid type conflicts in ES
                    EntityKeys[item.name] = item.value.toString()
                });
                if(this.systemEntity)
                    EntityKeys.SystemEntity = this.systemEntity;
                //If we are editing an existing entity leave id untouched to prevent mangling history
                //otherwise assign the assetId as document Id
                if(!existingEntity)
                    document.id = EntityKeys.assetId;
                document["EntityKeys"] = EntityKeys;
                document.PrimaryKeyVariables = primaryKeyVariablesNameAndValue;
                document.IsEntityActive = this.scope().properties.deployStatus > 0; //Must be true by construction
                document.Result = this.$dataEntry.getFormResult(this.variables);
            },
            saveEntity(document, update) {
                let primaryKeyVariables = document.PrimaryKeyVariables;
                delete document.PrimaryKeyVariables;
                let self = this;
                return new Promise((resolve, reject) => {
                    self.$entities.saveEntity(document, self.exposedName, update)
                        .then(t => {
                            if (t === 'created' || t === 'updated') {
                                // if (t === 'updated') {
                                //     let payload = {};
                                //     self.oldFormVariables.forEach(item => {
                                //         payload[item.name] = item.value
                                //     });
                                //     payload["EntityName"] = document.Name;
                                //     Transactions.updateOrphanTransactionsStatusAfterEntityInstanceDelete(payload);
                                // }
                                // let payload = {};
                                // primaryKeyVariables.forEach(item => {
                                //     payload[item.name] = item.value
                                // });
                                // payload["EntityName"] = document.Name;
                                // Transactions.updateOrphanTransactionsStatus(payload).then((result) => {
                                //     debugger
                                //     if (result.status === "updated" && result.orphanTransactionsResolvable > 0) {
                                //         this.$root.showNotification(`New orphan transactions are resolvable, number: ${result.orphanTransactionsResolvable}`, true, false)
                                //     }
                                // });
                                resolve();
                            } else {
                                debugger
                                reject(t);
                            }
                        })
                        .catch(err => {
                            debugger
                            reject(err);
                        });
                });
            }
        },
    }

</script>

<style scoped>
    table th {
        border: 3px solid #dddddd;
        height: 50px !important;
        max-height: 50px !important;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }

    table td {
        border: 3px solid #dddddd;
        height: 50px !important;
        max-height: 50px !important;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }

    table tr {
        border: 3px solid #dddddd;
        height: 50px !important;
        max-height: 50px !important;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }
</style>
