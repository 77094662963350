export default function () {
  return {
    socket: {
      status: false,
      message: 'closed'
    },
    modules: [],
    shared: {
      token: '',
      loggedUserName: '',
      profiles:[],
      groups: [],
      languages: [],
      alarms: [],
      breadcrumbs: {},
      preferences: [],
      options: [],
      settings: [],
      addresses: [],
      autoSaveEnabled: false
    },
    callingApi: false,
    intervalIDList: [],
    privateIntervalIDList: [],
    webSocketHooks: {},
    isEmbedded: false,
    globalDrawer: true,
    globalHeader: true,
    globalMenu: true,
    hideHeaderBar: false,
    unAuthenticatedLogin:false,
    haveHistory:false
  }
}
