let instance = null;

module.exports = {
    get: function() {
        return instance;
    },
    set: function(newVueInstance) {
        if(instance)
            throw "Vue instance was already registered";
        instance = newVueInstance;
    }
}