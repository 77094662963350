<template>
    <div style="position: relative; padding-right: 12px">
        <av-icon v-bind="$attrs" v-on="$listeners">{{mainIcon}}</av-icon>
        <i style="position: absolute; top: 50%; left: 50%; font-size: 13px; color: #ffffff;" class="fas fa-circle" />
        <av-icon v-bind="$attrs" v-on="$listeners" style="position: absolute; top: 55%; left: 55%; font-size: 12px">{{slaveIcon}} fa-xs</av-icon>
    </div>
</template>

<script>

    export default {
        name: "AvStackedIcon",
        props: {
            mainIcon: {
                type: String
            },
            slaveIcon: {
                type: String
            }
        },
        computed: {},
    }
</script>

<style scoped>

</style>