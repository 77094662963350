<template>
    <DynamicElementBase>
        <RawDataTable class="ma-0 pa-0" :dataCollection="dataCollection" :containerHeight="containerHeight"/>
    </DynamicElementBase>
</template>

<script>
    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
    import RawDataTable from '@/components/graphics/RawDataView.vue';

    export default {
        name: "ReportView",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            RawDataTable
        },
        data() {
            return {
                dataCollection: null,
            }
        },
        mounted() {
            this.fixedHeight = false;
            this.dataExplorationMode.enableDependentFilters = true;
            this.visualizationTweaks = [
                {
                    name: this.$gettext("Import excel template"),
                    id: "ImportTemplate",
                    type: "file",
                    importCallback: this.importExcelCallback,
                    deleteCallback: this.deleteTemplateCallback,
                    default: function() {
                        return "No template loaded";
                    }
                },
                {
                    name: this.$gettext("Queries grouping mode"),
                    id: "grouping",
                    type: "indexed_option",
                    options: [
                        {text: this.$gettext('No grouping'), value: 'No grouping'},
                        {text: this.$gettext('Inner join'), value: 'Inner join'},
                        {text: this.$gettext('Outer join'), value: 'Outer join'}
                    ],
                    default: function() {
                        return 0;
                    }
                },
                {
                    name: this.$gettext("Automatic runtime components"),
                    id: "AutomaticRuntimeComponents",
                    type: "bool",
                    default: function () {
                        return false;
                    }
                },
            ];
            this.visualizationTargets = [];
        },
        watch: {
            visualizationTweaks: {
                handler: async function () {
                    if(this.getTweakValue("AutomaticRuntimeComponents")) {
                        await this.reloadComponents();
                    }
                    let grouping = this.getTweakValue("grouping");
                    if(grouping === 0)
                        this.queriesGrouping = null;
                    else if(grouping === 1)
                        this.queriesGrouping = this.$defines.allAggregations.inner.id;
                    else if(grouping === 2)
                        this.queriesGrouping = this.$defines.allAggregations.outer.id;
                    this.saveTweaks();
                    this.recalculateQuery();
                    this.dataRefresh();
                },
                deep: true,
            },
        },
        methods: {
            importExcelCallback(file) {
                this.visualizationTweaks[0].value = file.name;
                this.saveTweaks();
                this.importExcel(file);
            },
            deleteTemplateCallback() {
                this.deleteExcel();
                this.visualizationTweaks[0].value = this.visualizationTweaks[0].default();
                this.saveTweaks();
            },
            refreshData(dataValues) {
                this.dataCollection = dataValues;
            },
            //FN Used to fetch dinamically node parent mapping(with all mapping children) in order to avoid missing new children added
            async reloadComponents() {
                let tmp = this.dataItemsComponents;
                let tmp2;
                let self = this;
                if (Array.isUseful(this.dataItemsComponents)) {
                    await this.$datalayer.getNodeMapping(this.dataItemsComponents[0].index, this.dataItemsComponents[0].name)
                        .then(data => {
                            if (Object.isUseful(data)) {
                                this.$utils.forEachItemInObject(data, function (item) {
                                    if (item.name && typeof item.name === 'string' && item.name.endsWith(".keyword")) {
                                        item.name = item.name.replace(".keyword", "");
                                    }
                                });
                                if (Array.isUseful(data.children)) {
                                    self.dataItemsComponents[0].children = data.children;
                                } else {
                                    self.dataItemsComponents[0].children = [];
                                }

                                self.$emit('dataItemsUpdated');
                            }
                        })
                        .catch(t => {
                            debugger
                            console.error(t);
                            self.$root.showErrorNotification(self.$gettext("Error in retrieving data definitions from DB."), true);
                        })
                }
            }
        }
    }

</script>
