import Defines from "./defines"
import Utils from "./jsutils"
import VueInstance from "./vueinstance"

export default {
    getNormalizationsTypes(item, aggregationType) {
        let type = Defines.avionicsDataTypes.unknown;
        if(item)
            type = Defines.getAvionicsDataType(item.type);
        let normalizations = [];
        if(type.id === Defines.avionicsDataTypes.number.id || type.id === Defines.avionicsDataTypes.unknown.id ||
            type.id === Defines.WellKnownTypes.OEEComponent.id) {
            normalizations.push( { text: "Round to digits", value: "roundToDigits" } );
            normalizations.push( { text: "Round", value: "round" } );
            normalizations.push( { text: "Floor", value: "floor" } );
            normalizations.push( { text: "Ceil", value: "ceil" } );
            normalizations.push( { text: "Negate", value: "negate" } );
            normalizations.push( { text: "Thousands separator", value: "thousandsSeparator" } );
        } else if(type.id === Defines.avionicsDataTypes.boolean.id) {
            normalizations.push( { text: "Negate", value: "negate" } );
        }
        if(aggregationType === Defines.allAggregations.sum.id || aggregationType === Defines.allAggregations.count.id ||
            aggregationType === Defines.allAggregations.terms.id || type.id === Defines.avionicsDataTypes.unknown.id) {
            normalizations.push({text: "Convert to seconds", value: "toSeconds"});
            normalizations.push({text: "Convert to minutes", value: "toMinutes"});
            normalizations.push({text: "Convert to hours", value: "toHours"});
        }
        if(aggregationType === Defines.allAggregations.duration.id) {
            normalizations.push({text: "Convert to minutes", value: "durationToMinutes"});
            normalizations.push({text: "Convert to hours", value: "durationToHours"});
        }
        if(item) {
            if (Defines.isWellKnownItem(item, Defines.WellKnownItems.DayOfWeek))
                normalizations.push({text: "Convert to day of week", value: "toDayOfWeek"});
            if (Defines.isWellKnownItem(item, Defines.WellKnownItems.DayOfYear))
                normalizations.push({text: "Convert to day of year", value: "toDayOfYear"});
            if (Defines.isWellKnownItem(item, Defines.WellKnownItems.Month))
                normalizations.push({text: "Convert to month", value: "toMonth"});
            if (Defines.isWellKnownItem(item, Defines.WellKnownItems.MinuteOfDay))
                normalizations.push({text: "Convert to time of day", value: "toTimeOfDay"});
        }

        if (type.id === Defines.avionicsDataTypes.time.id)
            normalizations.push( {text: "Timestamp format", value: "timestampFormat" } );

        normalizations.push( { text: "Prepend text", value: "prepend" } );
        normalizations.push( { text: "Append text", value: "append" } );
        normalizations.push( {text: "Remap Value", value: "remapValue" } );
        normalizations.push( {text: "Custom javascript", value: "custom" } );
        normalizations.push( {text: "Variable name to category", value: "varNameToCategory"} );
        normalizations.push( {text: "Tokenize value", value: "tokenizer"} );
        return normalizations;
    },
    getNormalizator(normalizatorType) {
        switch (normalizatorType) {
            case "roundToDigits": return { type: "roundToDigits", args: ["int"], argsValues:[], expression: "Math.round(value * Math.pow(10, {0}) ) / Math.pow(10, {0})" };
            case "round": return { type: "round", args: [], argsValues:[], expression: "Math.round(value)" };
            case "floor": return { type: "floor", args: [], argsValues:[], expression: "Math.floor(value)" };
            case "ceil": return { type: "ceil", args: [], argsValues:[], expression: "Math.ceil(value)" };
            case "negate": return { type: "negate", args: [], argsValues:[], expression: "!value" };
            case "toSeconds": return { type: "toSeconds", args: [], argsValues:[], expression: "value * 5" };
            case "toMinutes": return { type: "toMinutes", args: [], argsValues:[], expression: "Math.round( (value / 12) * 10 ) / 10" };
            case "toHours": return { type: "toHours", args: [], argsValues:[], expression: "Math.round( (value / 12 / 60) * 100 ) / 100" };
            case "durationToMinutes": return { type: "durationToMinutes", args: [], argsValues:[], expression: "Math.round( (value / 60) * 10 ) / 10" };
            case "durationToHours": return { type: "durationToHours", args: [], argsValues:[], expression: "Math.round( (value / 3600) * 100 ) / 100" };
            case "prepend": return { type: "prepend", args: ["any"], argsValues:[], expression: "'{0}' + value" };
            case "append": return { type: "append", args: ["any"], argsValues:[], expression: "value + '{0}'" };
            case "custom": return { type: "custom", large: true, args: ["any"], argsValues:[], expression: "{0}" };

            case "toDayOfWeek": return { type: "toDayOfWeek", args: [], argsValues:[], expression: "['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'sun'][value]" };
            case "toDayOfYear": return { type: "toDayOfYear", args: [], argsValues:[], expression: "var setDayOfYear = function(date, day) {var month = -1; var dayOfMonth = 0; var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365]; for(var i = 0 ; i < dayCount.length - 1 ; i++)  if(day >= dayCount[i] && day < dayCount[i+1]) {month = i; dayOfMonth = day - dayCount[i] + 1; break;} date.setMonth(month, dayOfMonth); return date;}; var date = new Date('2019-01-01'); var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][setDayOfYear(date, value).getMonth()]; var day = setDayOfYear(date, value).getDate(); month + ' ' + day" };
            case "toMonth": return { type: "toMonth", args: [], argsValues:[], expression: "['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][value]" };
            case "toTimeOfDay": return { type: "toTimeOfDay", args: [], argsValues:[], expression: "Math.floor(value / 60) + ':' + value % 60" };
            case "timestampFormat": return { type: "timestampFormat", args: [], argsValues:[], expression: "new Date(value).format()" };
            case "remapValue": return { type: "remapValue", args: ["any","any"], argsValues:[], expression: "value=='{0}' ? '{1}' : value" };
            case "varNameToCategory": return { type: "varNameToCategory", args: ["int"], argsValues:[], expression: "value.split('.')[{0}]", applyTo: "x", sourceValue: "identifier" };
            case "tokenizer": return { type: "tokenizer", args: ["string","int"], argsValues: ['.',0], expression: "value.split('{0}')[{1}]" }
            case "thousandsSeparator": return { type: "thousandsSeparator", args: ["string"], argsValues:[], expression: "var addSeparator = function(val) { return val.toString().replace(/\\B(?<!\\.\\d*)(?=(\\d{3})+(?!\\d))/g, '{0}') }; var val = addSeparator(value); val" }
        }
    },
    getCustomNormalization(expression) {
        return { type: "custom", large: true, args: ["any"], argsValues:[expression], expression: "{0}" };
    },
    resolve(normalization) {
        return normalization.expression.format(normalization.argsValues)
    },
    getAllRepresentationNormalizations(representation) {
        let normalizations = [];
        if(Array.isUseful(representation.normalizations))
            normalizations = Utils.detach(representation.normalizations);
        //This is used to create new aggregation types that implicitly uses a normalization to work
        if (representation.type === Defines.allAggregations.duration.id) {
            normalizations.insertItem(0, this.getNormalizator("toSeconds"));
        }
        return normalizations;
    }
}