<template>
    <v-dialog v-model="showDialog" width="480px" height="640px" min-height="640px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-variable-dialog">
        <v-card id="antares-variables-dialog-card">
            <v-container fill-height pa-3>
                <v-layout column>
                    <v-toolbar-title class="headline text-xs-center">
                        <v-layout fill-height align-center row px-0 py-1 wrap>
                            <label><translate>Select a variable</translate></label>
                        </v-layout>
                     </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" label="Search variable" :clearable="!searchDisabled" class="mx-2 mb-2 mt-3" :disabled="searchDisabled"/>
                    <v-layout column align-start justify-start fill-height style="height: 580px; overflow:auto;">
                        <av-expansion-panel v-if="Array.isUseful(groups)" expand class="pa-0">
                            <v-expansion-panel-content v-for="group in groups" class="pa-0 expansionPanelHeader">
                                <template v-slot:header>
                                    <label class="ml-0 pl-0 sectionTitle"
                                           style=" transform: translateX(-18px) scale(.9);">{{ group }}</label>
                                </template>
                                <data-mapping-tree-view :mapping="mapping.filter( ( el ) =>  el.group === group ) || []"
                                                        :search="search" :field-select="selectField" :root-select="selectRoot" :multiple="multiple" :exclude-item="excludeItem" :disable-search="searchDisabled"
                                                        :variables="variables" @selectedItem="selectedItem($event)"  @selectedItems="selectedItems($event)"/>
                            </v-expansion-panel-content>
                        </av-expansion-panel>
                        <data-mapping-tree-view v-else :fieldSelect="selectField" :root-select="selectRoot" :mapping="mapping || []" :search="search"  @selectedItem="selectedItem($event)"/>
                    </v-layout>
                    <v-spacer></v-spacer>
                    <!--<label v-if="error" style="color: var(&#45;&#45;av-red); font-size: 1.4em">{{error}}</label>-->
                    <v-card-actions id="antares-annotation-dialog-card-actions">
                        <v-spacer></v-spacer>
                      <v-btn v-if="multiple" id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat"  v-translate @click="onOk">
                        OK
                      </v-btn>
                        <v-btn id="antares-variable-dialog-card-actions-no" color="red darken-1" flat="flat" @click="selectedItem(null)" v-translate>
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-layout>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>

    import DataMappingTreeView from '@/components/utilities/DataMappingTreeView'

    export default {
        name: 'VariablePickerDialog',
        components: { DataMappingTreeView },
        props: {
            showDialog: false,
            search: "",
            searchDisabled:false,
            multiple:false,
            selectField:false,
            selectRoot:false,
            excludeItem:{
              type: Object,
              required:false,
              default:()=>{
                return undefined
              },
            },
            variables:[],
            filteredMapping: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        data() {
            return {
                groups: [],
                mapping: null,
                multipleSelection:[]
            }
        },
        mounted: function() {
                if (Array.isUseful(this.filteredMapping)) {
                    this.mapping = this.filteredMapping;
                    return
                }
                this.$datalayer.loadDataDefinitions()
                    .then(data => {
                        if(Array.isUseful(data)){
                            this.$utils.forEachItemInObject(data, function (item) {
                                if (item.name && typeof item.name === 'string' && item.name.endsWith(".keyword")) {
                                    item.name = item.name.replace(".keyword", "");
                                }
                            });
                            this.groups = [];
                            for (let index of data)
                                if (index.group)
                                    if (!this.groups.includes(index.group))
                                        this.groups.push(index.group);

                            // //Create group for suitable auto data sourcing incexes
                            // if(this.autoDataSourcing) {
                            //     autoDataSourcing
                            // }
                            this.mapping = data;
                        }
                        //console.log(JSON.stringify(this.mapping));
                    })
                    .catch(t => {
                        debugger
                        console.error(t);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving data definitions from DB."), true);
                    })
                    .finally(() => {
                        //this.$root.setLoading(false);
                    });

        },
        computed: {},
        methods: {
            selectedItem(item) {
              if(this.multiple && item===null){
                this.$emit("close")
              }
                this.$emit("selectedItem", item);
                this.showDialog = false
            },
          selectedItems(items){
              this.multipleSelection=items;
          },
          onOk(){
            this.$emit("selectedItems", this.multipleSelection );
          }
        },
    }

</script>

<style>

</style>
