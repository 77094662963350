<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer"  class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card  class="mt-0 av-card" elevation="0" style="height: 80px;">
            <v-layout row ma-3>
                <v-flex xs2 style="padding: 0">
                    <v-select :items="devices" v-model="device" :label="$gettext('Select a device')" @change="getDeviceAlarmsDescriptions($event)"/>
                </v-flex>
                <v-flex xs5 style="padding: 0">
                    <v-btn v-if="device" @click="addLanguage()"><av-icon color="lightgreen" style="margin-right: 10px">fas fa-language</av-icon><translate>New language</translate></v-btn>
                    <v-btn v-if="device" @click="addAlarm()"><av-icon color="red" style="margin-right: 10px">fas fa-exclamation-circle</av-icon><translate>New alarm</translate></v-btn>
                    <v-btn v-if="device" @click="editSearchPattern()"><av-icon color="red" style="margin-right: 10px">edit</av-icon><translate>Search pattern</translate></v-btn>
                    <v-btn v-if="false" @click="checkSearchPattern()"><av-icon color="red" style="margin-right: 10px">edit</av-icon><translate>Check Search pattern</translate></v-btn>
                </v-flex>
                <v-flex xs5>
                    <ImportExportExcel v-if="device" :text-icon-visible="true" :horizontal="true" :headers="exportFields" :data-collection="exportRecords" :work-sheet="exportWorksheet" :file-name="exportFileName" @fileLoaded="save($event)"/>
                </v-flex>
            </v-layout>
        </v-card>
        <v-card  class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)">
            <v-card-text  class="av-card-content" style="height: calc(100vh - 20px)">
                <v-layout row wrap>
                    <template  v-for="lang in importedLanguages">
                        <v-btn small :id ="lang" fab :key="lang" v-if="importedLanguages.length > 1"  pa-0 ma-0 @click="selectLanguage(lang)">{{lang}}</v-btn>
                    </template>
                </v-layout>
                <v-data-table ref="table" v-if="alarmsDescriptor.alarmsDescriptions" :items="alarmsDescriptor.alarmsDescriptions" :headers="headers"
                              :pagination.sync="pagination" :rows-per-page-items="[20, 30, 50]" class="elevation-1 fixed-header v-table__overflow"
                              :style="'max-height: calc(100vh - 100px); backface-visibility: hidden; scrollBottom: ' + scrollTop">
                    <template v-slot:headers="props">
                        <tr style="border-bottom: none;">
                            <th :class="['text-xs-left title', header.visibility]" v-for="header in props.headers">
                                 {{ header.name }}
                            </th>
                        </tr>
                    </template>
                    <template slot="items" slot-scope="values">
                        <td style="border-bottom: none;" :class="['text-xs-left body-1', header.visibility]" v-for="header in headers"  @click="edit(getCurrentIndex(values.index), header)"> <!-- @click="edit(values.index, header)">-->
                            <v-checkbox v-if="header.isCheck" v-bind:true-value="1" v-bind:false-value="0" v-model="values.item[header.dataProperty]" @change="saveChange((values.index),values.item[header.dataProperty],header)" />
                            <v-select v-else-if="header.dataProperty==='severity'"
                                      :items="severity"
                                      v-model="values.item[header.dataProperty]"
                                      label="Select One"
                                      single-line
                                      @change="saveChange((values.index),values.item[header.dataProperty],header)"
                            ></v-select>
                            <template v-else-if="header.dataProperty==='stopCause'" >
                                <v-layout row>
                                    <av-icon>fas fa-edit</av-icon>
                                    <av-icon style="margin-left: 8px" color="red" @click="stopCauseDeletion=true" v-if="values.item[header.dataProperty]">fas fa-trash</av-icon>
                                    <label style="margin-left: 12px">{{ formatTimeTracker(values.item[header.dataProperty]) }}</label>
                                </v-layout>
                            </template>
                            <label  v-else>{{ values.item[header.dataProperty] }}</label>
                        </td>
                    </template>
                </v-data-table>
                <av-input-box id="av-inputbox-alarms" ref="inputBox" />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import UploadButton from 'vuetify-upload-button';
    import ImportExport from "@/components/utilities/ImportExport";
    import ImportExportExcel from "@/components/utilities/ImportExportExcel";

    export default {
        name: "AlarmsDescriptions",
        components: { 'upload-btn': UploadButton, ImportExport,ImportExportExcel},
        data () {
            return {
                alarmIdElements : [],
                pagination: {},
                devices: [],
                device: "",
                alarmsDescriptor: {'alarmSearchPattern' : null, 'alarmSearchPatternIndices' : null, 'alarmsDescriptions' : null},
                scroll: 100000,
                importedHeaders: null,
                importedRecords: null,
                exportRecords: [],
                languages: [],
                language: "",
                importedLanguages: [],
                stopCauseDeletion: false,
                languageSelected: 'en',
                duration: 300,
                offset: 0,
                easing: 'easeInOutCubic',
                timerCheckSearchPattern : null,
                severity : [{
                    "text":"High",
                    "value": "High",
                },
                    {
                        "text":"Medium",
                        "value": "Medium",
                    },
                    {
                        "text":"Low",
                        "value": "Low",
                    }],
                // alarmSearchPattern: '',
                actionTrail: "",
                actionTrailId: "",
                nextValTrail: "",
                previousValTrail: "",
                saveAudits: false,
            }
        },
        mounted: function () {
            if(this.$root.startedUp)
                this.loadConfiguredDevicesList();
        },
        beforeDestroy () {
            clearInterval(this.timerCheckSearchPattern);
        },
        computed: {
            exportFields() {
                if(this.alarmsDescriptor.alarmsDescriptions && this.alarmsDescriptor.alarmsDescriptions.length) {
                    this.importedLanguages = [];
                    //let returning = [{ label: "Alarm identifier", field:"id" }];
                    let returning = [{ label: "id", field:"id" }];
                    for(let lang in this.alarmsDescriptor.alarmsDescriptions[0]) {
                        if (lang!=='id' && lang!=='suppress' && lang !== "stopCondition" && lang!=='requiresAnnotation' && lang!=='stopCause' && lang!=='severity' ) {
                            returning.push({label: lang, field: lang});
                            this.importedLanguages.push(lang);
                            if (lang ==='default' || lang === 'en')
                                lang = this.languageSelected;
                        }
                    }
                        // returning.push({ label: "Description" + lang , field: "description['" + lang + "']"});
                    returning.push(
                        {label: "suppress", field: "suppress"},
                        //{label: "requiresAnnotation" , field: "requiresAnnotation"},
                        {label: "stopCause", field: "stopCause"},
                        {label: "stopCondition", field: "stopCondition"},
                        {label: "severity", field: "severity"}
                        );
                    return returning;
                }
                return [];
            },
            exportWorksheet() {
                //Sheet name must be max 31 chars
                let shitName = '';
                if (this.device.length < 31 ) {
                    if (this.device.length < 16 )
                    shitName = "{appName} {device} alarms".format({appName: this.$config.appName, device: this.device});
                    else {
                        return "{appName} alarms".format({appName: this.$config.appName});
                    }
                } else {
                    return "{appName} alarms".format({appName: this.$config.appName});
                }

            },
            exportFileName() {
                return "{appName}_{device}_alarms_{time}".format({appName: this.$config.appName, device: this.device, time: (new Date()).format("yyyyMMddHHmmss")});
            },
            scrollTop() {
                return this.scroll;
            },
            headers() {
                if(this.alarmsDescriptor.alarmsDescriptions && this.alarmsDescriptor.alarmsDescriptions.length) {
                    let returning = [ { name: this.$gettext("Alarm identifier"), dataProperty: "id" } ];
                    for(let item in this.alarmsDescriptor.alarmsDescriptions[0]) {
                        if (item!=='suppress' && item !== "stopCondition" && item!=='requiresAnnotation' && item!=='id' && item!=='stopCauses' && item!=='severity') {
                            if (item === "default" || item === "en") {
                                returning.splice(1, 0, {
                                    name: ("Description " + item),
                                    dataProperty: item,
                                    locale: item,
                                    isLanguage: false
                                });
                            }
                            else if (item !== this.languageSelected) {
                                returning.push({
                                    name: (this.$gettext("Description ") + item),
                                    dataProperty: item,
                                    locale: item,
                                    isLanguage: true,
                                    visibility: 'text- d-none',
                                });
                            }
                            else if (item === this.languageSelected) {
                                returning.push({
                                    name: (this.$gettext("Description ") + item),
                                    dataProperty: item,
                                    locale: item,
                                    isLanguage: true,
                                    visibility: '',
                                });
                            }
                        }
                    }
                    returning.push({name: this.$gettext("Ignored"), dataProperty: "suppress", isCheck: true});
                    returning.push({name: this.$gettext("Stopping"), dataProperty: "stopCondition", isCheck: true});
                    // returning.push({name: "Notify", dataProperty: "requiresAnnotation", isCheck: true});
                    returning.push({name: this.$gettext("Severity"), dataProperty: "severity", isCheck: false});
                    if(this.$license.hasPerformance())
                        returning.push({name: this.$gettext("Default stop cause"), dataProperty: "stopCause", isCheck: false});
                    return returning;
                }

                return [];
            }
        },
        watch: {
            'alarmsDescriptor.alarmsDescriptions' : {
                handler: function () {
                    this.formatAlarmsDescriptions();
                },
                deep: true,
            },
        },
        methods: {
            newSearchPattern() {
                let searchPattern = '';
                let placeholder = '{0}';
                let lowerBound = Math.min( ...this.alarmsDescriptor.alarmSearchPatternIndices );
                let upperBound = Math.max(...this.alarmsDescriptor.alarmSearchPatternIndices);
                let added = false;
                this.alarmIdElements.forEach((element, index) => {
                    if (index < lowerBound || index > upperBound) {
                        searchPattern += element;
                    }
                    else if(!added) {
                        added = true;
                        searchPattern += placeholder;
                    }
                });
                this.alarmsDescriptor.alarmSearchPattern = searchPattern;
            },
            splitAlarmIdentifier() {
                this.alarmIdElements = this.alarmIdentifier.split('');
                let max = Math.max(...this.alarmsDescriptor.alarmSearchPatternIndices);
                if (max > this.alarmIdElements.length)
                    this.alarmsDescriptor.alarmSearchPatternIndices.removeItem(max);
            },
            getCurrentIndex(index) {
               return (this.pagination.page -1 ) * this.pagination.rowsPerPage + index
            },

            editSearchPattern() {
                this.$alarms.openAlarmSearchPatternDialog(this,this.$root.userName, this.alarmsDescriptor.alarmSearchPattern, this.alarmsDescriptor.alarmSearchPatternIndices, this.alarmsDescriptor.alarmsDescriptions[0].id,
                                                           this.alarmCallback,
                                                            this.checkSearchPattern);
            },

            alarmCallback(value) {
                if(value) {
                    this.saveAudits = true;
                    this.actionTrail = this.$audits.items().alarmSearchPatternEdited;
                    this.actionTrailId = this.device;
                    this.nextValTrail = "";
                    this.previousValTrail = "";
                    this.alarmsDescriptor.alarmSearchPattern = value.alarmSearchPattern;
                    this.alarmsDescriptor.alarmSearchPatternIndices = value.alarmSearchPatternIndices;
                    this.save();
                }
            },

            formatAlarmsDescriptions() {
                this.exportRecords = this.$alarms.filterExportedData(this.alarmsDescriptor.alarmsDescriptions);
            },

            formatTimeTracker(value) {
                return this.$timeTracking.formatTimeTracker(value);
            },

            selectLanguage(lang) {
                let btnSelected = document.querySelectorAll('.selectedLanguage');
                if(btnSelected.length>0) {
                    btnSelected[0].classList.remove("selectedLanguage");
                }
                const btn =  document.getElementById(lang);
                btn.classList.add("selectedLanguage");
                this.languageSelected = lang;
            },

            loadConfiguredDevicesList() {
                let self = this;
                //Get the list of devices that has a descriptions file saved in DB
                self.devices.clear();
                self.$devices.getAvailable()
                    .then(devices => {
                        for(let i = 0 ; i < devices.length ; i++) {
                            if(devices[i])
                                self.devices.push(devices[i]);
                        }

                    })
                    .catch(err => {
                        self.device = "";
                        self.error = "Unable to retrieve devices info. Please try again";
                    })
                    .finally(()=>{
                        self.loadTranslations();
                        // self.timerCheckSearchPattern = setInterval(() => { self.checkSearchPattern() }, 30000);
                    });
            },

            loadTranslations() {
                for(let lang in this.$language.available)
                    this.languages.push( { key: lang, show: this.$language.available[lang] } )
            },

            getDeviceAlarmsDescriptions(device) {
                this.$root.setLoading(true, "Loading alarms descriptions...");
                this.device = device;
                this.$settings.load(this.$settings.AlarmsDescriptions, device)
                    .then(descriptor => {
                        if (Object.isUseful(descriptor) && Array.isUseful(descriptor.alarmsDescriptions)) {
                            this.importedLanguages = [];
                            this.alarmsDescriptor.alarmsDescriptions = descriptor.alarmsDescriptions;
                            this.alarmsDescriptor.alarmSearchPattern = descriptor.alarmSearchPattern;
                            this.alarmsDescriptor.alarmSearchPatternIndices = descriptor.alarmSearchPatternIndices;
                            this.checkSearchPattern();
                        } else {
                            this.addDevice();
                        }
                    })
                    .catch(err => {
                        debugger;
                    })
                    .finally( () => {
                        this.$root.setLoading(false);
                    })
            },
            edit(index, header) {
                let self = this;
                if(header.dataProperty==='severity')
                    this.previousValTrail = self.alarmsDescriptor.alarmsDescriptions[index].severity;
                if (!header.isCheck && header.dataProperty!=='severity') {
                    if (header.dataProperty === "id") {
                        let self = this;
                        this.$refs.inputBox.ShowInputBox("Enter new ID for alarm", "", this.alarmsDescriptor.alarmsDescriptions[index].id, "OK", function (value) {
                            if (self.alarmsDescriptor.alarmsDescriptions[index].id !== value) {
                                if (self.$alarms.validateAlarmIdentifier(self.alarmsDescriptor.alarmsDescriptions, value)) {
                                    self.$root.showErrorNotification(self.$gettext("Alarm identifier already used, please choose a different one"), false);
                                } else
                                    self.textChanged(index, header, value);
                            } else
                                self.checkSearchPattern();
                        }, "Cancel", null);
                    } else if (header.dataProperty === "stopCause") {
                        if (this.stopCauseDeletion)
                            self.textChanged(index, header, 0);
                        else this.$timeTracking.openTimeTrackingDialog(this, this.$root.userName, false, true, (value) => { if (value!==0) self.textChanged(index, header, value)});
                        this.stopCauseDeletion = false;
                    } else {
                        this.$refs.inputBox.ShowInputBox("Enter new " + header.locale + " description for alarm: " + self.alarmsDescriptor.alarmsDescriptions[index].id, "", self.alarmsDescriptor.alarmsDescriptions[index][header.locale], "OK", function (value) {
                            self.textChanged(index, header, value)
                        }, "Cancel", null);
                    }
                }

            },
            textChanged(index, header, value) {
                this.saveAudits = true;
                if(header.dataProperty === "id") {
                    this.actionTrail = this.$audits.items().alarmIdEdited;
                    this.actionTrailId = this.device;
                    this.nextValTrail = value;
                    this.previousValTrail = this.alarmsDescriptor.alarmsDescriptions[index].id;

                    this.alarmsDescriptor.alarmsDescriptions[index].id = value;
                    //FN It's the alarm identifier
                    if(index===0) {
                        this.alarmIdentifier = this.alarmsDescriptor.alarmsDescriptions[0].id;
                        this.splitAlarmIdentifier();
                        this.newSearchPattern();
                    }
                    this.checkSearchPattern();
                }
                else if (header.dataProperty === "stopCause") {
                    let timeTrackingDescription = this.$timeTracking.getTimeTrackerFromId(value).join(' --> ');
                    this.nextValTrail = timeTrackingDescription;
                    this.previousValTrail = "";
                    if(this.stopCauseDeletion) {
                        this.actionTrail = this.$audits.items().stopCauseDeletedFromAlarm;
                        this.actionTrailId = this.device +'.' + this.alarmsDescriptor.alarmsDescriptions[index].id;
                        this.previousValTrail = this.$timeTracking.getTimeTrackerFromId(this.alarmsDescriptor.alarmsDescriptions[index].stopCause).join(' --> ');
                        this.nextValTrail = "";
                    } else {
                        this.actionTrail = this.$audits.items().stopCauseAddedToAlarm;
                        this.actionTrailId = this.device +'.' + this.alarmsDescriptor.alarmsDescriptions[index].id;
                        this.nextValTrail = timeTrackingDescription;
                        this.previousValTrail = this.$timeTracking.getTimeTrackerFromId(this.alarmsDescriptor.alarmsDescriptions[index].stopCause).join(' --> ');
                    }
                    //this.alarmsDescriptor.alarmsDescriptions[index].stopCause = value;
                     this.$set(this.alarmsDescriptor.alarmsDescriptions[index], header.dataProperty, value);
                }
                else {
                    this.actionTrail = this.$audits.items().alarmDescriptionEdited;
                    this.actionTrailId = this.device;
                    this.nextValTrail = value;
                    this.previousValTrail = this.alarmsDescriptor.alarmsDescriptions[index][header.dataProperty];

                    // this.alarmsDescriptor.alarmsDescriptions[index][header.locale] = value;
                    this.$set(this.alarmsDescriptor.alarmsDescriptions[index], header.locale,  value);
                }
                this.save();
            },
            saveChange(index,value,header){
                this.saveAudits = true;
                this.actionTrailId = this.device + '.' + this.alarmsDescriptor.alarmsDescriptions[index].id;
                if(header.isCheck) {
                    if(value)//checked
                        this.actionTrail = header.dataProperty ==='suppress' ? this.$audits.items().alarmSuppressEnabled : this.$audits.items().alarmStoppingEnabled;
                    else//Unchecked
                        this.actionTrail = header.dataProperty ==='suppress' ? this.$audits.items().alarmSuppressDisabled : this.$audits.items().alarmStoppingDisabled;
                } else {//severity
                    this.actionTrail = this.$audits.items().alarmSeverityEdited;
                    this.nextValTrail = value;
                }
                // this.actionTrail = "";

                this.save();
            },
            save(importedData = []) {
                let self = this;
                if(Array.isUseful(importedData.records)) {
                    let imported = this.$alarms.filterImportedData(importedData);
                    if(!Array.isUseful(imported)) {
                        this.$root.showErrorNotification(self.$gettext("An error occurred while importing selected file"), true);
                        return
                    }
                    self.alarmsDescriptor.alarmsDescriptions = imported;
                    //FN Update the search pattern
                    this.alarmIdentifier = self.alarmsDescriptor.alarmsDescriptions[0].id;
                    this.splitAlarmIdentifier();
                    this.newSearchPattern();
                    this.saveAudits = true;
                    this.actionTrail = this.$audits.items().alarmsDescriptionsImported;
                    this.actionTrailId = this.device;
                    this.nextValTrail = "";
                    this.previousValTrail = "";
                }
                this.$settings.save(this.alarmsDescriptor, this.$settings.AlarmsDescriptions, this.device)
                    .then( () => {
                        //Update descriptor in alarms module
                        self.$alarms.updateAlarmsDescriptor(this.device, this.alarmsDescriptor);
                        if(self.saveAudits)
                            self.$audits.save(self.$root.userName, self.actionTrail, self.previousValTrail, self.nextValTrail, self.actionTrailId)
                                .catch(err => {
                                debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                });
                        self.saveAudits = false;
                    })
                    .catch(err => {
                        self.$root.showErrorNotification("Error saving alarms descriptions to DB.", true);
                    })
                    .finally( () => {
                        self.loadConfiguredDevicesList();
                        if(Array.isUseful(importedData.records)) {
                            self.checkSearchPattern();
                        }
                    })
            },
            checkSearchPattern(pattern) {
                let notify = true;
                let searchPattern = this.alarmsDescriptor.alarmSearchPattern;
                if (pattern) {
                    searchPattern = pattern;
                    notify = false;
                }
                // let value = this.$alarms.checkSearchPattern('', this.alarmsDescriptor.alarmsDescriptions);
                // if (!this.$alarms.checkSearchPattern('', this.alarmsDescriptor.alarmsDescriptions)) {
                //     this.$root.showDialogBox(this.$gettext("The search pattern doesn't match with one or more Id"), "", "OK", null, "", null);
                // }
                if (!Object.isUseful(searchPattern)) {
                    // return false;
                    return null;
                }
                let result = this.$alarms.checkSearchPattern(searchPattern, this.alarmsDescriptor.alarmsDescriptions);

                if (result) {
                     if(notify)
                        this.$root.showDialogBox(this.$gettext("The configured alarm search pattern doesn't match one or more alarms Ids: ") + result, "", "OK", null, "", null);
                      else
                        return result;
                }
                return null;
            },
            // checkSearchPattern2(searchPattern, alarmsIdentifier) {
            // //     let regExp = new RegExp('\\D+[0-9]+' );
            // //     // ^[0-9]+
            // //     // let result = regExp.test(alarmsIdentifier);
            // //     // let regExp = /\D+[0-9]+;
            // //     for (let i = 0; i < alarmsIdentifier.length; i++) {
            // //         let result = regExp.test(alarmsIdentifier[i].id);
            // //     debugger;
            // //         if (!regExp.test(alarmsIdentifier[i].id)) {
            // //         debugger;
            // //             return false
            // //         }
            // //     }
            // // debugger;
            // //     return true;
            //     let splittedSearchPattern = searchPattern.replace('{0}', '//{0}//');
            //     // splittedSearchPattern = searchPattern.split('{0}');
            //     splittedSearchPattern = splittedSearchPattern.split('//');
            //     //remove possible empty string from array
            //     splittedSearchPattern = splittedSearchPattern.filter(item => item);
            //
            //    let  regExpSource = '';
            //     splittedSearchPattern.forEach(elem => {
            //         // regExpSource +=  elem!=='{0}' ? '\\D+' : '[0-9]+';
            //         regExpSource += elem!=='{0}' ? elem : '[0-9]+';
            //         //regExpSource +=  elem!='{0}' ? '\\d+\\w+|\\w+\\d+' : '[0-9]+';
            //     });
            //     // let regExp = new RegExp('\\D+[0-9]+' );
            //     let regExp = new RegExp(regExpSource);
            //     for (let i = 0; i < alarmsIdentifier.length; i++) {
            //         let arrayRegExp = regExp.exec(alarmsIdentifier[i].id);
            //         if (Array.isUseful(arrayRegExp)) {
            //             if (arrayRegExp[0] !==alarmsIdentifier[i].id ) {
            //                 return  false;
            //             }
            //         }
            //         else {
            //             return false;
            //         }
            //     }
            //     debugger;
            //     return true;
            // },
            addDevice() {
                // let self = this;
                // this.$refs.inputBox.ShowInputBox("Enter identifier of new device. It should match device name configured in data sourcing components", "", "", "OK", function(value) { self.device=value, self.defaultDescriptions(),self.save() }, "Cancel", null);
                //setting of the default "id" searchPasttern
                this.alarmsDescriptor.alarmSearchPattern = 'Alarm.{0}';
                this.alarmsDescriptor.alarmSearchPatternIndices = [];
                this.alarmsDescriptor.alarmSearchPatternIndices.push(6,7);
                this.defaultDescriptions();
                this.save();
            },
            addAlarm() {
                let record = this.$utils.detach(this.alarmsDescriptor.alarmsDescriptions[0]);
                for(let locale in record)
                    record[locale] = "";
                //incremental id for avoiding key duplication
                let idAlarm = this.alarmsDescriptor.alarmsDescriptions.length <= 9 ? '0' + this.alarmsDescriptor.alarmsDescriptions.length : this.alarmsDescriptor.alarmsDescriptions.length;
                // idAlarm = (this.alarmsDescriptor.alarmsDescriptions[this.alarmsDescriptor.alarmsDescriptions.length-1].id).slice(Math.min( ...this.alarmsDescriptor.alarmSearchPatternIndices ));
                let match;
                let id ;
                let regex = /[+-]?\d+(?:\.\d+)?/g;
                while ((match = regex.exec(this.alarmsDescriptor.alarmsDescriptions[this.alarmsDescriptor.alarmsDescriptions.length-1].id)) !== null) {
                     id = match[0]
                }
                idAlarm = isNaN(parseInt(id) + 1) ? idAlarm : (parseInt(id) + 1) <= 9 ? '0' + (parseInt(id) + 1) : parseInt(id) + 1;
                record.id = "Alarm." + idAlarm;
                record.suppress = 0;//false;
                record.requiresAnnotation = 0;//false;
                record.stopCondition = 0;//false;
                record.severity = 0;
                this.alarmsDescriptor.alarmsDescriptions.push(record);
                this.scroll = 10000;
                this.$nextTick(() => {
                    let dataTable = this.$refs.table;
                    if ( dataTable !== undefined ) {
                        let dt = dataTable.$el.querySelector(".v-table__overflow");
                        dt.scrollTop = 780978;
                    }
                });
                this.saveAudits = true;
                this.actionTrail = this.$audits.items().alarmCreated;
                this.actionTrailId = this.device;
                this.nextValTrail = record.id;
                this.previousValTrail = "";
                this.save();
                this.checkSearchPattern();
            },
            addLanguage() {
                let self = this;
                let tmpLangs = [...self.languages];
                this.$refs.inputBox.ShowInputBox(this.$gettext("Select a new language"), "", "", "OK", function(value) { self.addLanguageString(value) }, "Cancel", null, tmpLangs);
                tmpLangs = [];
            },

            addLanguageString(code) {
                let self = this;
                if(code !== '') {
                    code = (code === 'default' ? 'en' : code);
                    if (self.importedLanguages.includes(code))
                        this.$root.showDialogBox(this.$gettext("Language code requested {0} already available for this device.").format(code), "", "OK", null, "", null);
                    else {
                        self.importedLanguages.push(code);
                        for (let i = 0; i < self.alarmsDescriptor.alarmsDescriptions.length; i++)
                            this.$set(self.alarmsDescriptor.alarmsDescriptions[i], code, '');  // self.alarmsDescriptor.alarmsDescriptions[i][code] = '';

                        let newLanguage = self.languages.find(language => language.key === code).show;
                        self.saveAudits = true;
                        self.actionTrail = self.$audits.items().alarmsDescriptionsNewLanguageAdded;
                        self.actionTrailId = self.device;
                        self.nextValTrail = newLanguage;
                        self.previousValTrail = "";
                        self.save();

                        let focusOnLanguageButton = function () {
                            self.$nextTick(() => {
                                //button of the new language
                                const btn = document.getElementById(code);
                                btn.click();
                                btn.focus();
                            });
                        };
                        focusOnLanguageButton();
                    }
                }
            },
            defaultDescriptions(){
                this.alarmsDescriptor.alarmsDescriptions = [ { id: "Alarm.00",  [this.languageSelected]: "Enter default description" , suppress: 0, stopCondition: 0, requiresAnnotation: 0, severity: 0 } ];
            },
        }
    }
</script>

<style scoped>

    /*.theme--light.v-text-field>.v-input__control>.v-input__slot {*/
        /*border-color: #90C143;*/
    /*}*/



    .selectedLanguage {
        opacity: 0.6;
        background-color: darkgray !important;

    }

</style>