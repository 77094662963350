<template>
    <FormTransaction />
</template>
<script>
    import FormTransaction from '@/components/dynamic-elements/forms/FormTransactions';

    export default {
        name: "FormMaintenance",
        extends: FormTransaction,
        components: {
            FormTransaction
        },
        data() { return {} },
        mounted() {
            let self = this;
            this.childHandlers.getDefaultName = function() { return self.$gettext("MaintenanceRecording") };
            this.transactionProperties.type = "independent";
            this.transactionProperties.systemTargets = [this.$dynamicElements.Types.FormAsset];
            this.exposedName = "Maintenance"
        },
        watch: {
        },
        methods: {
        },
    }

</script>

<style scoped>
</style>
