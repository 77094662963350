
import Settings from "./settings";


export default {
    demo: false,
    debug: false,
    isAvionics: true, //Clean-up avionics reference by moving every option to AppConfig
    deployment: 0,
    l3Collapsed: false,
    appName: "Digital Factory",
    /* operationMode options:
        "standard": standard application mode
        "reporting": embedded reporting service
        "scan": transaction on scan
    */
    operationMode: "standard",
    options: {
        forms: {
            entities: false,
            transactions: false
        },
        statusSection: {
            show: true,
            showLine: true,
            showUser: true,
            ShowWorkorder: true,
            showShift: true,
            showTimeTracking: true,
            additionalFields: [
                // {
                //     text: "Example",
                //     value: "print-and-check.TotalItems",
                //     position: 2
                // }
            ],
            showGroups: true,
            showProfile: true
        }
    },
    experimental: {
        machineProcesses: false
    },

    load() {
        let self = this;
        return new Promise((resolve, reject) => {
            Settings.load(Settings.AppConfig, Settings.Null, Settings.Null, Settings.SystemScope)
                .then(cfg => {
                    if(Object.isUseful(cfg.demo))
                        self.demo = cfg.demo;
                    if(Object.isUseful(cfg.uiDebug))
                        self.debug = cfg.uiDebug;
                    if(Object.isUseful(cfg.appName) && cfg.appName !== "")
                        self.appName = cfg.appName;
                    if(Object.isUseful(cfg.experimental))
                        self.experimental = cfg.experimental
                    if(Object.isUseful(cfg.options)) {
                        if(Object.isUseful(cfg.options.forms))
                            self.options.forms = cfg.options.forms
                        if(Object.isUseful(cfg.options.statusSection))
                            self.options.statusSection = cfg.options.statusSection
                    }
                    if(Object.isUseful(cfg.deploy)) {
                        self.deployment = parseInt(cfg.deploy[1]);
                        if(cfg.deploy === "L23")
                            self.l3Collapsed = true;
                        resolve();
                    } else {
                        debugger
                        //TODO this reject will lock app start up. Evaluate a parameter to exclude deployment when it's not applicable (TMW)
                        console.error("Error in retrieving system deploy level.");
                        reject();
                    }
                })
                .catch(t => {
                    debugger
                    console.error("Error in retrieving application config");
                    reject();
                });
        });
    },

    isL2() {
        return this.deployment === 2;
    },

    isL3() {
        return this.deployment === 3;
    },

    isStandardOperationMode() {
        return this.operationMode === "standard";
    },

    isReportingService() {
        return this.operationMode === "reporting";
    },

    isTransactionMode() {
        return this.operationMode === "scan";
    },

    isDashboardMaximizeOperationMode() {
        return this.operationMode === "maximize";
    }
}
