import Api from '@/api/api'
import Vue from 'vue'
import VueInstance from "./vueinstance";

let hasPerformance = false;
let hasQuality = false;
let hasManager = false;
let hasMaintenance = false;
let demoModule = [];

function getInstalledModules() {
    hasPerformance = false;
    hasQuality = false;
    return new Promise((resolve, reject) =>{
        return Api().get('/auth/license/modules')
            .then((modules) => {
                modules = JSON.parse(modules.data.Data);
                for(let module of modules) {
                    if(module.toLowerCase() === "performance")
                        hasPerformance = Array.isUseful(demoModule) && VueInstance.get().$config.demo? demoModule[0] === 'performance' : true

                    if(module.toLowerCase() === "quality")
                        hasQuality = Array.isUseful(demoModule) && VueInstance.get().$config.demo? demoModule[0] === 'quality' : true

                    if(module.toLowerCase() === "manager")
                        hasManager = Array.isUseful(demoModule) && VueInstance.get().$config.demo? demoModule[0] === 'manager' : true

                    if(module.toLowerCase() === "maintenance")
                        hasMaintenance = Array.isUseful(demoModule) && VueInstance.get().$config.demo? demoModule[0] === 'maintenance' : true
                }
                resolve( { hasPerformance: hasPerformance, hasQuality: hasQuality, hasManager: hasManager, hasMaintenance: hasMaintenance } )
            })
            .catch(response => {
                debugger;
                console.log(response);
                reject(VueInstance.get().$gettext("Unable to load licensed modules"));
            });
    })
}

export default {

    hasPerformance: () => { return hasPerformance },
    hasQuality: () => { return hasQuality },
    hasManager: () => { return hasManager },
    hasMaintenance: () => { return hasMaintenance },
    getInstalledModules: getInstalledModules,

    QualityModule: "quality",
    PerformanceModule: "performance",
    ManagerModule: "manager",
    MaintenanceModule: "maintenance",

    modulesList() {
        let modules = [];
        if(hasPerformance)
            modules.push(this.PerformanceModule);
        if(hasQuality)
            modules.push(this.QualityModule);
        if(hasManager)
            modules.push(this.ManagerModule);
        if(hasMaintenance)
            modules.push(this.MaintenanceModule);
        return modules
    },

    async uploadLicense (licenseFiles) {
        return Vue.prototype.$axios.post('/auth/license/activate', JSON.stringify(licenseFiles))
    },

    async downloadLicense(newLicense) {
        if (!newLicense) {
            return Vue.prototype.$axios.get('/auth/license/id/')
        } else {
            return Vue.prototype.$axios.get('/auth/license/id/create')
        }
    },

    hasModules(modules) {
        if(!Array.isUseful(modules))
            return true;
        for(let module of modules) {
            if(module === this.QualityModule && hasQuality)
                return true;
            if(module === this.PerformanceModule && hasPerformance)
                return true;
            if(module === this.ManagerModule && hasManager)
                return true;
            if(module === this.MaintenanceModule && hasMaintenance)
                return true;
        }
        return false;
    },

    setDemoModule(demoUsername) {
        demoModule = demoUsername;
    }
}
