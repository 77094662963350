<template>
    <div>
        <v-switch :label="ColorPickerLb" v-model="colorPickerVisibility" @change="colorPickerVisibilityChange" />
        <div class="float-container" v-if="colorPickerVisibility">
            <div class="float-child">
                <v-combobox :items="colorsOptions" :label="fieldName" v-model="colorValue" @change="updateFromInput">
                    <template v-slot:item="{ index, item }">
                        <v-chip :color="item" small text-color="white"> {{ item }} </v-chip>
                    </template>
                </v-combobox>
            </div>
            <div class="float-child-right">
                <div ref="colorpicker" class="color-picker-outer" >
                    <v-icon :color="colorValue" @click="togglePicker">fas fa-palette</v-icon>
                    <compact-picker class="vc-compact" :value="colors" @input="updateFromPicker" v-if="displayPicker"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Sketch } from "vue-color";
    import ColorSequence from '@/api/colorsequences';
    export default {
        name: "ColorPicker",
        props: {
            fieldName: String,
            initColor: String,
        },
        components: {
            "compact-picker": Sketch
        },
        data() {
            return {
                colors: {
                    hex: "#4A4A4A"
                },
                colorValue: this.initColor,
                displayPicker: false,
                colorsOptions: ColorSequence.avPalette,
                colorPickerVisibility: this.initColor !== "auto"
            };
        },
        computed: {
            ColorPickerLb() {
                return this.$gettext("Select custom color")
            }
        },
        methods: {
            setColor(color) {
                this.updateColors(color);
                this.colorValue = color;
            },
            updateColors(color) {
                if (color.slice(0, 1) === "#") {
                    this.colors = {
                        hex: color
                    };
                } else if (color.slice(0, 4) === "rgba") {
                    let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(","),
                        hex = `#${(
                            (1 << 24) +
                            (parseInt(rgba[0]) << 16) +
                            (parseInt(rgba[1]) << 8) +
                            parseInt(rgba[2])
                        )
                            .toString(16)
                            .slice(1)}`;
                    this.colors = {
                        hex,
                        a: rgba[3]
                    };
                }
            },
            showPicker() {
                document.addEventListener("click", this.documentClick);
                this.displayPicker = true;
            },
            hidePicker() {
                document.removeEventListener("click", this.documentClick);
                this.displayPicker = false;
            },
            togglePicker() {
                this.displayPicker ? this.hidePicker() : this.showPicker();
            },
            updateFromInput() {
                this.updateColors(this.colorValue);
                this.$emit("update-color", this.colorValue, this.fieldName);
            },
            updateFromPicker(color) {
                this.colors = color;
                if (color.rgba.a === 1) {
                    this.colorValue = color.hex;
                } else {
                    this.colorValue = `rgba(${color.rgba.r}, ${color.rgba.g}, ${
                        color.rgba.b
                    }, ${color.rgba.a})`;
                }
            },
            documentClick(e) {
                let el = this.$refs.colorpicker;
                let target = e.target;
                if (el !== target && !el.contains(target)) {
                    this.hidePicker();
                }
                this.$emit("update-color", this.colorValue, this.fieldName);
            },
            colorPickerVisibilityChange() {
                if (this.colorPickerVisibility)
                    this.colorValue = "#3C63AD"
                else
                    this.colorValue = "auto"
                this.$emit("update-color", this.colorValue, this.fieldName);
            }
        },
        watch: {
            initColor(newVal, oldVal) {
                this.colorValue = newVal;
            }
        }
    };
</script>

<style scoped>
    div.color-picker-outer {
        width: 20px;
        height: 20px;
        display: inline-block;
        position: relative;
        top: 18px;
    }
    /*overwrite the default compact style*/
    .vc-compact {
        position: absolute;
        /*top: 0px;
          left: 55px; */
        bottom: 28px;
        right: 0px;
        z-index: 9;
    }

    .float-container {
        padding: 0px;
    }

    .float-child {
        width: 50%;
        float: left;
        padding: 0px;
    }

    .float-child-right {
        width: 50%;
        float: left;
        padding-left: 10px;
    }
</style>
