<template>
    <WidgetGraph ref="graph" @selectedDevicesChanged="selectedDevicesChanged" @addedTweaks="switchedAutomaticRuntimeDatasource"/>
</template>

<script>

    import WidgetGraph from './WidgetGraph'

    let targets = { "uptime" : 0 };

    export default {
        name: 'WidgetPlantCapacity',
        components: {
            WidgetGraph,
        },
        data () {
            return {
                lineBreaks: [],
                initializer: null
            }
        },
        mounted: function() {
            setTimeout((function(self) {
                return function() {
                    self.base().visualizationTweaks.clear();
                    self.base().visualizationTweaks = [
                        {
                            name: self.$gettext("Automatic runtime datasource"),
                            id: "AutomaticRuntimeDatasource",
                            type: "bool",
                            default: function() {
                                return false;
                            }
                        },
                    ];
                    self.$refs.graph.childHandlers.inLineDataTransformer = self.inLineDataProcessor;
                    self.$refs.graph.labelsPosition = "right";
                    self.$refs.graph.chartType = "Linear gauge";
                    self.$refs.graph.units = "%";
                    self.base().mergeVisualizationTweaksValues(self.base().properties.visualizationTweaksValues);
                    self.createDataItems();
                }
            })(this), 1000);

            this.base().dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], true));
            this.base().dataExplorationMode.autoDataSourcing = true;
            this.base().dataExplorationMode.deviceSelectMode = true;
            this.base().dataExplorationMode.enumerateLineInDevices = true;
        },
        methods: {
            base() {
                return this.$refs.graph;
            },
            selectedDevicesChanged() {
                if(!this.base().loading && !this.base().getTweakValue("AutomaticRuntimeDatasource"))
                    this.$root.showDialogBox(this.$gettext("Do you want to automatically create data sources for plant capacity?"), null, this.$gettext("Yes"), this.createDataItems, this.$gettext("No"), null);
            },
            switchedAutomaticRuntimeDatasource() {
                if (this.base().getTweakValue("AutomaticRuntimeDatasource")) {
                    this.loadDataMappings();
                }
            },
            loadDataMappings() {
                let self = this;
                this.$devices.selectDeviceFromIndexPatterns(this.base().dataExplorationMode.compatibleDataPatterns, true)
                    .then(devices => {
                        self.createDataItems(devices);
                    })
            },
            createDataItems(devices) {
                //Create data items for each line, ensure that even in case user selects
                //more than one device per line, we consider only one per line
                this.lineBreaks.clear();
                this.lineBreaks.push(0);
                this.base().filterItems = [];
                this.base().dataItems = [];
                let representationsCount = 0;
                let selectedIndexes = [];
                let selectedDevices = (Array.isUseful(devices) ? devices : this.base().devices);
                for(let devIndex = 0 ; devIndex < selectedDevices.length ; devIndex++) {
                    if(!(selectedIndexes.includes(selectedDevices[devIndex].dataIndex))) {
                        let descriptor = this.createLineDataItem(selectedDevices[devIndex].dataIndex, selectedDevices[devIndex].device);
                        this.base().dataItems = this.base().dataItems.concat(descriptor);
                        representationsCount += descriptor.representations.length;
                        this.lineBreaks.push(representationsCount);
                        selectedIndexes.push(selectedDevices[devIndex].dataIndex);
                    }
                }
                // for(let devIndex = 0 ; devIndex < this.base().devices.length ; devIndex++) {
                //     if(!(selectedIndexes.includes(this.base().devices[devIndex].dataIndex))) {
                //         let descriptor = this.createLineDataItem(this.base().devices[devIndex].dataIndex, this.base().devices[devIndex].device);
                //         this.base().dataItems = this.base().dataItems.concat(descriptor);
                //         representationsCount += descriptor.representations.length;
                //         this.lineBreaks.push(representationsCount);
                //         selectedIndexes.push(this.base().devices[devIndex].dataIndex);
                //     }
                // }
            },
            createLineDataItem(productionCountersSource, device) {

                return {
                    index: productionCountersSource,
                    root: device,
                    name: 'Status',
                    type: 'integer',
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: this.$defines.allAggregations.last.id,
                            filters: [  ],
                            target: targets.uptime,
                            defaultName: 'Line status',
                            name: 'Line status',
                            enabled: true
                        },
                    ],
                };
            },
            inLineDataProcessor(dataValues) {

                if(!dataValues)
                    return null;

                if(dataValues.length !== this.lineBreaks[this.lineBreaks.length - 1])
                    return null;

                let totalLines = this.lineBreaks.length - 1;
                let totalLinesActive = 0;

                for(let lineIndex = 0 ; lineIndex < dataValues.length ; lineIndex++)
                    if(Array.isUseful(dataValues[lineIndex].data))
                        totalLinesActive += dataValues[lineIndex].data.last().y > 0 ? 1 : 0;

                let returning = [];
                returning.push(
                    {
                        label: "Plant capacity",
                        target: this.$refs.graph.visualizationTargetsIndexes.graph,
                        representation: this.$defines.allAggregations.raw.id,
                        data: [{ x: Date.now(), y: Math.round((totalLinesActive / totalLines)*100)}]
                    },
                    // {
                    //     label: "Production sites",
                    //     target: this.$refs.graph.visualizationTargetsIndexes["label 1"],
                    //     representation: this.$defines.allAggregations.raw.id,
                    //     data: [{ x: Date.now(), y: 1 }]
                    // },
                    {
                        label: "Total lines",
                        target: this.$refs.graph.visualizationTargetsIndexes.label,
                        representation: this.$defines.allAggregations.raw.id,
                        data: [{ x: Date.now(), y: totalLines }]
                    },
                    {
                        label: "Lines processing",
                        target: this.$refs.graph.visualizationTargetsIndexes.label,
                        representation: this.$defines.allAggregations.raw.id,
                        data: [{ x: Date.now(), y: totalLinesActive }]
                    }
                );

                return returning;
            },
        },
    }
</script>

<style scoped>



</style>