
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import Defines from '@/api/defines'
import VueInstance from '@/api/vueinstance'
import Config from '@/api/config'
import TimeTracking from '@/api/timetracking'
import Utils from "./jsutils"
import Quality from "./quality"
import Normalizations from "./normalizations"
import DateTimeUtils from "./datetimeutils"
import Audits from '@/api/audits'

let queryIndex = 0;
let startTimes = {};
let defaultMappingDescriptor = null;
let cachedMapping = null;
let cachedInternalMapping = null;

export default {

    getCachedMapping(internal = false) {
        if (internal)
            return cachedInternalMapping;
        return cachedMapping;
    },
    setCachedMapping(mapping, internal = false) {
        if (internal)
            cachedInternalMapping = mapping;
        else cachedMapping = mapping;
    },
    setDefaultMappingDescriptor(descriptor) {
        defaultMappingDescriptor = descriptor;
    },
    //Loads logical DB indexes mapping.
    //remappingDescriptor allows to further group and rename indexes to customize view
    //prototype:
    // {
    //     "ignored":[ "ignored index pattern*" ],
    //     "rename":[
    //         {
    //             "source":"alarms",
    //             "dest":"Alarms (raw events)"
    //         }
    //     ],
    //     "fieldsRemapping": [
    //         {
    //              "index": "alarms",
    //              "function": (item) => {
    //                   let alarmName = Alarms.formatAlarmName(item.name, item.root);
    //                   if(alarmName) {
    //                          item["displayName"] = alarmName;
    //                   }
    //              }
    //         }
    //     ],
    //     "groups":[
    //         {
    //             "display":"Main data sources",
    //             "match":[
    //                 "alarms",
    //                 "production counters*"
    //             ]
    //         },
    //         {
    //             "display":"Raw machine data",
    //             "match":[
    //                 "avopcua*",
    //                 "avmodbus*"
    //             ]
    //         },
    //         {
    //             "display":"Other data sources",
    //             "match":[
    //                 "*"
    //             ]
    //         }
    //     ]
    // }

    async getNodeMapping(index, node) {
        return new Promise( (resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.queryUrl + '/getnodemapping/' + index + '/' + node)
                .then(response => {
                    // console.log("mapping loaded");
                    // this.setCachedMapping(Utils.detach(response), internal);
                    // resolve(this.transformMapping(response, remappingDescriptor, internal))
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async loadDataDefinitions(remappingDescriptor, forceReload = false, internal = false) {
        if (!forceReload)
            if (Array.isUseful(this.getCachedMapping(internal)))
                return this.transformMapping(Utils.detach(this.getCachedMapping(internal)), remappingDescriptor, internal);

        debugger    //Mapping loadings shall be the less possible as it could be a heavy operation
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.queryUrl + '/mapping' + (internal ? "/all" : ""))
                .then(response => {
                    console.log("mapping loaded");
                    this.setCachedMapping(Utils.detach(response), internal);
                    resolve(this.transformMapping(response, remappingDescriptor, internal))
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    transformMapping(rawMapping, remappingDescriptor, skipIgnored = false) {
        //Sanitize data to avoid visualization errors and add some additional info where useful
        let mappingCrawler = function (items, callback, recursive, withRemoval) {
            if (Array.isUseful(items)) {
                for (let index = 0; index < items.length; index++) {
                    let item = items[index];
                    if (recursive)
                        mappingCrawler(item.children, callback, recursive);
                    if (Object.isUseful(item.name)) {
                        try {
                            let toBeRemoved = callback(item, items, index);
                            if (withRemoval && toBeRemoved) {
                                items.removeAt(index);
                                index--;
                            }
                        } catch (e) { /*keep on going */
                        }
                    }
                }
            }
        };

        //If an external descriptor is set use that one, otherwise use the global one, otherwise return mapping as-is
        if (!remappingDescriptor) {
            if (defaultMappingDescriptor)
                remappingDescriptor = defaultMappingDescriptor;
        }

        let aggregatedMapping = [];

        if (rawMapping) {

            //First of all execute some cleanup and preparation
            for (let i = 0; i < rawMapping.length; i++) {
                //Broken nodes, this shouldn't happen in production
                if (!rawMapping[i]) {
                    rawMapping.splice(i, 1);
                    i--;
                    continue;
                }

                //Ensure also root nodes have the index, TODO this is a TMW patch to be moved to BE mapping API
                mappingCrawler(rawMapping[i].children, (item, items, index) => {
                    if (item.isRoot)
                        item.index = rawMapping[i].index;
                }, true);

                //Assign usique key to items to facilitate UI binding
                rawMapping[i].key = rawMapping[i].name + i;
                mappingCrawler(rawMapping[i].children, (item, items, index) => {
                    item.key = item.name + item.index + item.root + index;
                }, true);
            }

            // let findItem = function(items, name) {
            //     let results = items.filter(item => item.name === name);
            //     if(Array.isUseful(results))
            //         return results[0];
            //     return null;
            // };

            aggregatedMapping = rawMapping;
            for (let i = 0; i < rawMapping.length; i++) {
                let tokens = rawMapping[i].index.split("~|~");
                if (tokens.length > 1) {
                    //rawMapping[i].index = tokens.last();
                    rawMapping[i].scope = [];
                    for (let tokIndex = 0; tokIndex < tokens.length - 1; tokIndex++) {
                        if (tokens[tokIndex])
                            rawMapping[i].scope.push(tokens[tokIndex]);
                    }
                }
            }
            //Aggregate indexes with similar scopes but different tenancies (AVionics L3/4)
            // for (let i = 0; i < rawMapping.length; i++) {
            //     let tokens = rawMapping[i].index.split("~|~");
            //     if(tokens.length > 1) {
            //         let rootItem = findItem(aggregatedMapping, tokens.last());
            //         if(!rootItem) {
            //             rootItem = {
            //                 isRoot: true,
            //                 key: tokens.last(),
            //                 name: tokens.last(),
            //                 index: tokens.last(),
            //                 children: []
            //             };
            //             aggregatedMapping.push(rootItem);
            //         }
            //
            //         for(let tokIndex = 0 ; tokIndex < tokens.length - 1 ; tokIndex++) {
            //             if(!tokens[tokIndex])
            //                 continue;
            //             let item = findItem(rootItem.children, tokens[tokIndex]);
            //             if(!item) {
            //                 item = {
            //                     isRoot: true,
            //                     key: tokens[tokIndex],
            //                     name: tokens[tokIndex],
            //                     index: tokens[tokIndex],
            //                     children: []
            //                 };
            //                 rootItem.children.push(item);
            //             }
            //             rootItem = item;
            //         }
            //         rootItem.children = rawMapping[i].children;
            //     } else aggregatedMapping.push(rawMapping[i])
            // }
            // debugger

            //Apply the per index remapping rules (if any)
            if (remappingDescriptor) {
                for (let i = 0; i < aggregatedMapping.length; i++) {
                    //jump ignored indexes
                    let next = false;
                    if (!skipIgnored && Array.isUseful(remappingDescriptor.ignored)) {
                        for (let ignorePattern of remappingDescriptor.ignored) {
                            if (Utils.matchWildcardString(aggregatedMapping[i].index, ignorePattern)) {
                                aggregatedMapping.splice(i, 1);
                                next = true;
                                break;
                            }
                        }
                        if (next) {
                            i--;
                            continue;
                        }
                    }

                    //Remove unused fields
                    if (Array.isUseful(remappingDescriptor.fieldsRemoval)) {
                        for (let fieldsRemovalPattern of remappingDescriptor.fieldsRemoval) {
                            for (let matchPattern of fieldsRemovalPattern.match) {
                                if (Utils.matchWildcardString(aggregatedMapping[i].index, matchPattern)) {
                                    mappingCrawler(aggregatedMapping[i].children, fieldsRemovalPattern.func, fieldsRemovalPattern.recursive || !Object.isUseful(fieldsRemovalPattern.recursive), true);
                                }
                            }
                        }
                    }

                    //Rename requested items
                    if (Array.isUseful(remappingDescriptor.rename)) {
                        for (let renamePattern of remappingDescriptor.rename) {
                            if (aggregatedMapping[i].index === renamePattern.source ||
                                aggregatedMapping[i].index.endsWith("~|~" + renamePattern.source)) {
                                if (!Array.isUseful(aggregatedMapping[i].scope))
                                    aggregatedMapping[i].name = renamePattern.dest;
                                else aggregatedMapping[i].name = "{0} {1}".format(aggregatedMapping[i].scope.last(), renamePattern.dest);
                                mappingCrawler(aggregatedMapping[i].children, (item) => {
                                    item.indexDisplayName = aggregatedMapping[i].name
                                }, true);
                                break;
                            }
                        }
                    }

                    //Apply field-base reformatting
                    if (Array.isUseful(remappingDescriptor.fieldsRemapping)) {
                        for (let fieldsRemappingPattern of remappingDescriptor.fieldsRemapping) {
                            for (let matchPattern of fieldsRemappingPattern.match) {
                                if (Utils.matchWildcardString(aggregatedMapping[i].index, matchPattern)) {
                                    mappingCrawler(aggregatedMapping[i].children, fieldsRemappingPattern.func, fieldsRemappingPattern.recursive || !Object.isUseful(fieldsRemappingPattern.recursive));
                                }
                            }
                        }
                    }
                }
                //Apply grouping (if any). This will keep the original structure of mapping
                //adding group field to each item and resorting them based on grouping descriptor
                //all indexes not matching any group will be finally discarded. In order to obtain all
                //indexes add a final group with match all ("*")
                if (Array.isUseful(remappingDescriptor.groups)) {
                    let returning = [];
                    for (let group of remappingDescriptor.groups) {
                        for (let indexPattern of group.match) {
                            for (let i = 0; i < aggregatedMapping.length; i++) {
                                if (Utils.matchWildcardString(aggregatedMapping[i].index, indexPattern)) {
                                    aggregatedMapping[i].group = group.display;
                                    returning.push(aggregatedMapping[i]);
                                    aggregatedMapping.splice(i, 1);
                                    i--
                                }
                            }
                        }
                    }
                    return returning;
                }
            }
        }

        return aggregatedMapping;
    },

    async dataDefinitionsForIndex(index, forceReload = false, internal = false) {
        let mapping = [];
        return new Promise((resolve, reject) => {
            this.loadDataDefinitions(null, forceReload, internal)
                .then(data => {
                    if (Array.isUseful(data)) {
                        let mappingForIndex = data.find(item => {return Config.isL3()?(item.index.indexOf(index)>-1):(item.index === index)});
                        if (mappingForIndex && mappingForIndex.children) {
                            if (index === 'workorders')
                                mappingForIndex.children = mappingForIndex.children.filter(item => !item.children);
                            else
                                mappingForIndex.children = mappingForIndex.children.filter(item => item.name !== 'tag');
                            mapping.push(mappingForIndex);
                        }
                        resolve(mapping);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async checkFilterCompatibility(dataItems, filterItems) {
        let self = this;
        for (const data of dataItems) {
            if (data.hasOwnProperty('filtersFromDifferentIndex'))
                data.filtersFromDifferentIndex.clear();

            for (const filter of filterItems) {
                if (filter.index === data.virtualIndexSource) {
                    if (data.index.startsWith("OEE_")) {
                        console.log("OEE")
                    } else if (data.index.startsWith("BATCH_")) {
                        let filterAlias = Defines.isWellKnowAlias(filter);
                        if (filterAlias && filterAlias === "workorderid") {
                            debugger;
                            console.log("workorderid")
                        } else {
                            debugger;
                            VueInstance.get().$set(data, 'filtersFromDifferentIndex', []);
                            data.filtersFromDifferentIndex.push({filterFound: false, filter: filter})
                        }
                    }
                } else if (data.index !== filter.index) {
                    if (!data.hasOwnProperty('filtersFromDifferentIndex'))
                        VueInstance.get().$set(data, 'filtersFromDifferentIndex', []);
                    data.filtersFromDifferentIndex.push(await self.indexContainsField(data.index, {...filter}));
                }
            }
        }
    },


    async indexContainsField(index, filterAdded) {
        let indexMapping = await this.dataDefinitionsForIndex(index);
        let mapping = indexMapping;
        let filterFound = false;
        let alias = null;
        if (filterAdded.root === "")
            filterFound = !!mapping[0].children.some(c => c.root === filterAdded.root && c.name.split('.')[0] === filterAdded.name && c.type === filterAdded.type);
        else {
            let item = Array.isUseful(mapping) ? mapping[0].children.findItemByKeyRecursive("name", filterAdded.root) : null;
            if (item)
                filterFound = !!item.items[item.itemIndex].children.some(c => c.root === filterAdded.root && c.name.split('.')[0] === filterAdded.name && c.type === filterAdded.type);
        }
        //Check for aliases
        if (!filterFound) {
            let aliases = Defines.getWellKnownAliases(filterAdded);
            if (Array.isUseful(aliases)) {
                let result = this.indexContainsAlias(mapping, aliases);
                filterFound = result.filterFound;
                alias = result.alias;
            }
        }
        return {filterFound: filterFound, filter: filterAdded, alias: alias}
    },
    indexContainsAlias(mapping, aliases) {
        let filterFound = false;
        let aliasObj = null;
        for (let alias of aliases) {
            if (alias.root === "") {
                if (mapping[0].children.some(c => c.root === alias.root && c.name.split('.')[0] === alias.name && c.type === alias.type)) {
                    filterFound = true;
                    aliasObj = alias;
                    break;
                }
            } else {
                let item = mapping[0].children.findItemByKeyRecursive("name", alias.root);
                if (item) {
                    if (item.items[item.itemIndex].children.some(c => c.root === alias.root && c.name.split('.')[0] === alias.name && c.type === alias.type)) {
                        filterFound = true;
                        aliasObj = alias;
                        break;
                    }
                }
            }
        }
        return {filterFound: filterFound, alias: aliasObj}
    },
    //Execute search of "searchString" in "mapping" object recursively.
    //Search is executed on "name" property of mapping. "exactMatch" forces literal matches,
    //otherwise "searchString" is sub-searched case-insensitive in any node name including roots and leafs.
    //Returned mapping contains the full tree structure of results.
    //(With property onlyLeaves === trye , will be returned only the leaf nodes)
    //Function does not modify original object reference, a copy is returned.
    searchDataItems(mapping, searchString, exactMatch = false, property="name", onlyLeaves = false, filteredLeaves= [] ) {
        let self = this
        let filterFunction = (items) => {
            let filtered = [];
            items.forEach(_item => {
                let item = Utils.detach(_item);
                if(Array.isUseful(item.children))
                    item.children = self.searchDataItems(item.children, searchString, exactMatch, property, onlyLeaves, filteredLeaves);
                if((exactMatch ? item[property] === searchString : (item[property].toLowerCase().indexOf(searchString.toLowerCase()) > -1))
                    || Array.isUseful(item.children)) { //Preserve root structure of leaf results if not requested flat
                    if(onlyLeaves) {
                        if(!Array.isUseful(item.children)) {
                            filteredLeaves.push(item);
                        }
                    } else {
                        filtered.push(item);
                    }

                }
            })
            return onlyLeaves ? filteredLeaves : filtered
        }
        return ((searchString && searchString.trim().length > 2) ? filterFunction(mapping) : mapping);
    },

    //Virtual indexes are still managed in 2.0 in case they will return useful in the future
    //Starting from 2.0 the Production performance virtual index has been deprecated in favour of
    //OEE and BATCH variables inside the production counters.
    selectIndexesByPatterns(data, compatibleDataPatterns) {
        //Loop through data mappings and check root components index patterns in search of an item that contains all the words within the compatible patterns
        let indexPatterns = {singleSelect: [], multiSelect: []};

        for (const [i, dataItem] of data.entries()) {
            if (dataItem && dataItem.isRoot) {
                for (const pattern of compatibleDataPatterns) {
                    if ((!pattern.virtualIndexes && dataItem.index.toLowerCase().includesMulti(pattern.pattern)) ||
                        (pattern.virtualIndexes && Object.isUseful(dataItem.virtualIndexSource) && dataItem.virtualIndexSource.toLowerCase().includesMulti(pattern.pattern))) {
                        if (pattern.multiSelect)
                            indexPatterns.multiSelect.push({
                                name: dataItem.name,
                                index: (pattern.virtualIndexes ? dataItem.virtualIndexSource : dataItem.index),
                                id: i
                            });
                        else
                            indexPatterns.singleSelect.push({
                                name: dataItem.name,
                                index: (pattern.virtualIndexes ? dataItem.virtualIndexSource : dataItem.index),
                                id: i
                            });
                        break;
                    }
                }
            }
        }

        return indexPatterns;
    },

    //Updates dataItems saved with older versions to latest. Function loops in the order all data items,
    //all aggregations and all functions and performs all needed transformations on each item.
    //Modifications log:
    //- Update normalizations: V2.0 introduced predefined normalizations. Change old pure manual normalizations to new format
    //- Update cross aggregation: V2.0 deprecated the cross-aggregation target. Now all queries can be automatically selected
    //  both for cross-aggregation and visualization. If a query is only meant for cross aggregation and is not meant to be
    //  visualized, a specific switch "show" was introduced to disable visualization.
    //  Old cross aggregation targeted queries were never visualized
    //- Update items ordering: V2.1.0 before items indexing was based on an offset value applied over a progressive index.
    //  Now offset is removed and a single index is applied and calculated every time a new item is added.
    //- V5.1 Added property virtualIndexSource on "production counters@5s" for Batch and OEE
    updateDataItemsToCurrentVersion(filters, data, aggregations, funcs) {

        if (!Array.isUseful(data) && !Array.isUseful(aggregations))
            return;

        //V4.0 running.production index was deprecated, redirect all queries to PDM index
        for (let item of data)
            if (item.index && item.index === "running.production") {
                item.index = "production counters@5s";
                item.root = "Workorder";
            }

        //Default mapping items update
        //Update batch items mapping to 3.0 and 5.1
        for (let item of data) {
            if (item.index && item.index.startsWith("BATCH_")) {
                if (item.name === "PlannedEndMinutes")
                    item.name = "TheoreticalNetDuration";
                else if (item.name === "EstimatedEndMinutes")
                    item.name = "EstimatedNetDuration";
            }
            if (item.index && (item.index.startsWith("BATCH_") || item.index.startsWith("OEE_"))) {
                //Update to 5.1 to satisfy filters matching
                if (!item.virtualIndexSource) {
                    item.virtualIndexSource = "production counters@5s";
                }
            }
        }

        this.forEachRepresentation(data, function (representation) {
            //Update normalizations
            if (Object.isUseful(representation.normalization)) {
                if (representation.normalization)
                    representation.normalizations = [Normalizations.getCustomNormalization(representation.normalization)];
                else representation.normalizations = [];
                delete representation.normalization;
            }
            //Update cross aggregation
            if (representation.target === Defines.crossAggregationTarget.id) {
                VueInstance.get().$set(representation, "show", false);
                representation.target = {};
            }
            if (!Object.isUseful(representation.show))
                VueInstance.get().$set(representation, "show", true);
            //Update indexing
            if (Object.isUseful(representation.zOffset)) {
                representation.zIndex += representation.zOffset;
                delete representation.zOffset;
            }
            //Create suggestions if they don't exist
            if (!Array.isUseful(representation.suggestions))
                VueInstance.get().$set(representation, "suggestions", []);
        });
        for (let aggregation of aggregations) {
            //Update normalizations
            if (Object.isUseful(aggregation.normalization)) {
                //In manual aggregations, normalization field was used to store aggregation script. We move it to a specific property
                if (aggregation.type === Defines.allAggregations.manual.id) {
                    aggregation.script = aggregation.normalization;
                    aggregation.normalizations = [];
                } else {
                    if (aggregation.normalization)
                        aggregation.normalizations = [Normalizations.getCustomNormalization(aggregation.normalization)];
                    else aggregation.normalizations = [];
                }
                delete aggregation.normalization;
            }
            //Update cross aggregation
            if (aggregation.target === Defines.crossAggregationTarget.id) {
                VueInstance.get().$set(aggregation, "show", false);
                aggregation.target = {};
            }
            if (!Object.isUseful(aggregation.show))
                VueInstance.get().$set(aggregation, "show", true);
            //Update indexing
            if (Object.isUseful(aggregation.zOffset)) {
                aggregation.zIndex += aggregation.zOffset;
                delete aggregation.zOffset;
            }
        }
        for (let func of funcs) {
            /****V2.0 introduced predefined normalizations. Change old pure manual normalizations to new format****/
            //Functions were introduced in 2.0 together with new normalizations framework, thus this code will never be executed
            //in production. Yet, it's here to sanitize all dev elements
            if (Object.isUseful(func.normalization)) {
                if (func.normalization)
                    func.normalizations = [Normalizations.getCustomNormalization(func.normalization)];
                else func.normalizations = [];
                delete func.normalization;
            }
            //Func were never cross-aggregated at least before 2.0, yet we add the show flag for reactivity
            if (!Object.isUseful(func.show))
                VueInstance.get().$set(func, "show", true);
            //Update indexing
            if (Object.isUseful(func.zOffset)) {
                func.zIndex += func.zOffset;
                delete func.zOffset;
            }
        }
    },

    getDefaultDataRepresentation(dataItem, zIndex = -1, preferredAggregations = null, elementType = -1) {

        let representationType = '';
        let availableRepresentationTypes = Defines.getAggregationsForDataItem(dataItem);
        if (availableRepresentationTypes.length > 0) {
            //Set predefined sum or cumusum aggregation for delta variables
            if (dataItem.name.includes('_Delta')) {
                representationType = elementType === 3 ? 'cumusum' : 'sum'
            } else if (Array.isUseful(preferredAggregations)) {
                for (let aggregation of preferredAggregations) {
                    let preferredAggregation = availableRepresentationTypes.find(agg => agg.id === aggregation);
                    if (preferredAggregation)
                        representationType = preferredAggregation.id;
                }
            } else {
                representationType = availableRepresentationTypes[0].id;
            }
        }
        let representation = {
            type: representationType,
            filters: [],
            target: {},
            visualizationOptions: {},
            aggregationWindow: 0,
            aggregationWindowUnit: "full",
            histogramStep: 1,
            orderBy: "categories",
            order: "ascending",
            normalizations: [],
            recursions: [],
            defaultName: '',
            name: dataItem.displayName || dataItem.name,
            dataType: Defines.getAvionicsDataType(dataItem.type).id,
            enabled: true,
            zIndex: zIndex,
            //V2.0 introduced show to say whether we want to show dataset on element.
            //Typically, it will be alternative to the old "Aggregate with other data" target.
            //When a dataSet is ment for cross aggregation and not for visualization we will just not send it to a visualization target
            show: true,
            //V5.1.0 introduced suggestions to encourage users to make changes in order to have a widget that is more lightweight and uses less resources
            suggestions: []
        };
        representation.defaultName = this.getDefaultDataRepresentationName(dataItem, representation);
        representation.id = Utils.timeTag(representation.defaultName); //Create a unique id that survives changes, needed for cross aggregations
        return representation;
    },

    getDefaultFilterRepresentation(filterItem) {

        let filterBaseName = filterItem.displayName || filterItem.name;
        let filter = {
            parametric: false,
            conditions: [],
            defaultName: (filterBaseName + " " + (filterItem.filters ? filterItem.filters.length : 0)),
            enabled: true,
            filterMode: 0,
        };
        filter.name = filter.defaultName;
        filter.filterId = Utils.timeTag(filter.defaultName); //Create a unique id that survives changes, needed to bind filters to data items
        filter.conditions.push(this.getDefaultFilterCondition(filterItem));
        return filter;
    },

    getDefaultCrossAggregation(aggregationItems, zIndex = -1) {

        let crossAggregation = {
            type: Defines.crossAggregations[0].id,
            buckets: [],
            target: {},
            normalizations: [],
            visualizationOptions: {},
            aggregationWindow: 0,
            aggregationWindowUnit: "full",
            defaultName: '',
            name: '',
            enabled: true,
            zIndex: zIndex,
            //V2.0 introduced show to say whether we want to show dataset on element.
            //Typically, it will be alternative to the old "Aggregate with other data" target.
            //When a dataSet is ment for cross aggregation and not for visualization we will just not send it to a visualization target
            show: true,
            orderBy: "categories",
            order: "ascending"
        };
        crossAggregation.defaultName = "Aggregation " + aggregationItems.length;
        crossAggregation.id = "cross_" + Utils.timeTag(crossAggregation.defaultName); //Create a unique id that survives changes, needed for cross aggregations
        return crossAggregation;
    },

    getDefaultFunction(zIndex = -1) {
        return {
            type: Defines.functions[0].id,
            value: "",
            target: {},
            parameters: [],
            normalizations: [],
            visualizationOptions: {},
            aggregationWindow: 0,
            aggregationWindowUnit: "full",
            defaultName: "",
            name: '',
            enabled: true,
            zIndex: zIndex,
            //V2.0 introduced show to say whether we want to show dataset on element.
            //Typically, it will be alternative to the old "Aggregate with other data" target.
            //When a dataSet is ment for cross aggregation and not for visualization we will just not send it to a visualization target
            show: true,
        };
    },

    getDefaultFilterCondition(filterItem) {

        //Funcion was refactored after introduction of exists operator. If in the future default condition
        //differentiation based on data type will be needed, just restore the commented code
        // let itemType = this.getAvionicsDataType(filterItem.type);
        // if (itemType.id === Defines.avionicsDataTypes.number.id)
        //     return {operator: 'exists', value: null};
        // else
        let wellKnownItemComparers = Defines.getWellKnownItemComparers(filterItem);
        if (Array.isUseful(wellKnownItemComparers))
            return {operator: wellKnownItemComparers[0], value: Defines.getWellKnownItemCompareValue(filterItem)};

        return {operator: 'exists', value: null};
    },

    getDefaultDataRepresentationName(dataItem, representation) {
        return ((representation.type && representation.type !== Defines.allAggregations.raw.id) ? Defines.allAggregations[representation.type].show + ' of ' : '') + dataItem.index + '.' + this.getFullFieldName(dataItem, representation.type);
    },

    //Callback signature must be callback(representation, dataItemIndex, representationIndex);
    forEachRepresentation(dataItems, callback, filter = null) {
        if (!Array.isUseful(dataItems) || !callback)
            return;

        for (let dataItemIndex = 0; dataItemIndex < dataItems.length; dataItemIndex++) {
            if (filter && dataItems[dataItemIndex].filtersFromDifferentIndex
                && dataItems[dataItemIndex].filtersFromDifferentIndex.some(f => !f.filterFound && f.filter.name === filter.name && f.filter.root === filter.root))
                continue

            for (let representationIndex = 0; representationIndex < dataItems[dataItemIndex].representations.length; representationIndex++) {
                if (callback)
                    callback(dataItems[dataItemIndex].representations[representationIndex], dataItemIndex, representationIndex);
            }
        }
    },

    async getDistinctValues(index, root, variable, type, from, to) {
        if (!from)
            from = new Date(0);

        if (!to)
            to = new Date();

        let varFullName = "{0}.{1}.{2}".format(index, root, variable);

        let dataItems = [
            {
                index: index,
                root: root,
                name: variable,
                type: type,
                selectedForVisualization: true,
                representations: [
                    {
                        type: Defines.allAggregations.terms.id,
                        filters: [],
                        name: "Values survey of {0}".format(varFullName),
                    },
                ],
            }
        ];

        let queryDescriptor = this.getDataQueryDescriptor(dataItems);

        return new Promise((resolve, reject) => {

            //Execute query
            this.getDataBlob(queryDescriptor, DateTimeUtils.getRfc3339TimeStamp(from), DateTimeUtils.getRfc3339TimeStamp(to))
                .then(result => {
                    let data = this.unwrapDataSets(dataItems, [], [], result);
                    let values = [];
                    if (Array.isUseful(data[0].data))
                        for (let i = 0; i < data[0].data.length; i++)
                            if (data[0].data[i].x)
                                values.push(data[0].data[i].x);
                    resolve(values);
                })
                .catch(err => {
                    console.log(err);
                    reject("Error while surveying {0}".format(varFullName));
                });
        })
    },

    //JSON prototype for api
    ///api/v1/query
    //
    // {
    //     "time_from": "2018-03-20T13:28:56.4039228Z",
    //     "time_to": "2019-03-20T13:28:56.4039228Z",
    //     "raw": [{
    //              "name": "query 1",
    //              "q": "index=myIndexName&fields=process_statistics.CPU_Load,@timestamp&filter=process_statistics.CPU_Load,>,50&sort=@timestamp,desc"
    //          },
    //          {
    //              "name": "query 2",
    //              "q": "index=myOtherIndexName&fields=process_statistics.CPU_Load,@timestamp&filter=process_statistics.CPU_Load,<,50&sort=@timestamp,desc"
    //          }
    //      ],
    //     "agg": [{
    //                  "name": "query 3",
    //                  "q": "index=myIndexName&type=avg,process_statistics.Memory_Used&source=@timestamp,2m,asc"
    //          },
    //          {
    //              "name": ""query 4",
    //              "q": "index=myOtherIndexName&type=count,process_statistics.Runtime_NumGC&filter=process_statistics.Runtime_NumGC>3"
    //          }
    //      ]
    // }

    getFieldsQueryComponent(dataItem, aggregationType) {
        //Check whether requested fields requires full document select. Currently it is the case for events
        if (dataItem.index === "audit_trails")
            return {queryFields: "", fullDocumentSelected: dataItem.index};
        else
            return {
                queryFields: "&fields=" + this.getFullFieldName(dataItem, aggregationType) + ",@timestamp",
                fullDocumentSelected: ""
            }

    },

    getFullFieldName(dataItem, aggregationType) {
        return (dataItem.root && !dataItem.selectedForRootVisualization ? dataItem.root + '.' : '') + dataItem.name +
            (!Defines.isRawAggregation(aggregationType) ? ((dataItem.type === 'keyword' || dataItem.type === 'text') ? ".keyword" : "") : "");
    },

    getVisualizableFieldName(dataItem) {
        return dataItem.root + (dataItem.root ? '.' : '') + dataItem.name;
    },

    getAggregationArguments(representation) {
        let additionalArguments = [];
        if (representation.type === Defines.allAggregations.histogram.id)
            additionalArguments.push((Object.isUseful(representation.histogramStep) ? representation.histogramStep : 1));
        if (representation.type === Defines.allAggregations.histogram.id || Defines.isTermsAggregation(representation.type)) {
            additionalArguments.push((Object.isUseful(representation.orderBy) ? Defines.OrderByItems[representation.orderBy] : Defines.OrderByItems.categories));
            additionalArguments.push((Object.isUseful(representation.order) ? Defines.OrderItems[representation.order] : Defines.OrderByItems.ascending));
        }
        if (representation.type === Defines.allAggregations.ttsurvey.id || representation.type === Defines.allAggregations.ttranking.id)
            additionalArguments.push("(count,TimeTracking.StopDuration)");    //Add stops statistics to time tracking
        if (Array.isUseful(additionalArguments))
            return "," + additionalArguments.join();
        return ""
    },

    getFiltersConditionString(representation, filterItems) {

        let filters = "";

        if (representation.filters && representation.filters.length > 0) {
            representation.filters.forEach(filterId => {
                for (let filterItemIndex = 0; filterItemIndex < filterItems.length; filterItemIndex++) {
                    for (let filterIndex = 0; filterIndex < filterItems[filterItemIndex].filters.length; filterIndex++) {
                        let filter = filterItems[filterItemIndex].filters[filterIndex];
                        if (filter.filterId === filterId && (!filter.parametric || filter.assigned)) {
                            for (let conditionIndex = 0; conditionIndex < filter.conditions.length; conditionIndex++) {
                                if (filters)
                                    filters += ",";
                                if (representation.filterAliases) {
                                    let alias = representation.filterAliases.find(fa => fa.filter.name === filterItems[filterItemIndex].name && fa.filter.root === filterItems[filterItemIndex].root &&
                                        fa.filter.type === filterItems[filterItemIndex].type);
                                    if (alias) {
                                        filters += this.getFilterCondition(alias.alias, filter.conditions[conditionIndex], filter.filterMode);
                                    } else {
                                        filters += this.getFilterCondition(filterItems[filterItemIndex], filter.conditions[conditionIndex], filter.filterMode);
                                    }
                                } else {
                                    filters += this.getFilterCondition(filterItems[filterItemIndex], filter.conditions[conditionIndex], filter.filterMode);
                                }
                            }
                        }
                    }
                }
            });
            if (filters)
                filters = "&filter=" + filters;
        }
        return filters;
    },

    getFilterCondition(filterItem, filterCondition, filterMode) {

        if (!filterCondition.operator)
            return "";

        let cleanedConditions = [];

        //If OR filter is chosen
        if (filterMode === 1)
            filterCondition.modifier = "should";

        //Sanitize eventual wrong filter conditions
        if (!Object.isUseful(filterCondition.value) || filterCondition.value === "") {  //Comparing against empty or null must be done with specific operator

            if (filterCondition.operator === "!=")
                filterCondition.operator = "IsNotEmpty";
            else if (filterCondition.operator === "=")
                filterCondition.operator = "IsEmpty";

            cleanedConditions.push(filterCondition);

        }
        //check if filter is a type of timeWindow
        else if (filterCondition.value.type === "timeWindow") {
            if (filterCondition.operator === '=') {
                cleanedConditions.push({operator: ">=", value: filterCondition.value.from});
                cleanedConditions.push({operator: "<=", value: filterCondition.value.to});
            }
            if (filterCondition.operator === '!=') {
                cleanedConditions.push({operator: "<=", value: filterCondition.value.from, modifier: "should"});
                cleanedConditions.push({operator: ">=", value: filterCondition.value.to, modifier: "should"});
            }
        } else {
            //If value is good verify weather it is a range expression to be expanded (for instance time tracking filters)
            if (Array.isArray(filterCondition.value)) {
                if (filterCondition.value.length !== 2) //Not a valid range, can't do anything
                    return "";
                if (filterCondition.value[0] === filterCondition.value[1])   //If range limits are the same, just normally compare to a single value
                    cleanedConditions.push({operator: filterCondition.operator, value: filterCondition.value[0]});
                else {  //We have a real range. Split range in 2 separate conditions based on operator
                    if (filterCondition.operator === "=") {
                        cleanedConditions.push({operator: ">=", value: filterCondition.value[0]});
                        cleanedConditions.push({operator: "<=", value: filterCondition.value[1]});
                    } else if (filterCondition.operator === "!=") {
                        cleanedConditions.push({modifier: "should", operator: "<", value: filterCondition.value[0]});
                        cleanedConditions.push({modifier: "should", operator: ">", value: filterCondition.value[1]});
                    } else if (filterCondition.operator === ">=" || filterCondition.operator === "<") {
                        cleanedConditions.push({operator: filterCondition.operator, value: filterCondition.value[0]});
                    } else if (filterCondition.operator === "<=" || filterCondition.operator === ">") {
                        cleanedConditions.push({operator: filterCondition.operator, value: filterCondition.value[1]});
                    }
                }
            } else cleanedConditions.push(filterCondition);
        }

        let returning = "";

        for (let condition of cleanedConditions) {

            if (returning.length > 0)
                returning += ",";

            let filterString = (condition.modifier ? condition.modifier + "," : "") + this.getFullFilterFieldName(filterItem) + "," + condition.operator;
            if (["exists", "not_exists", "IsEmpty", "IsNotEmpty"].includes(condition.operator)) {
                returning += filterString;
                continue;
            }
            returning += (filterString + "," + condition.value)
        }

        return returning;
    },

    getFullFilterFieldName(dataItem) {
        return dataItem.root + (dataItem.root ? '.' : '') + dataItem.name + (dataItem.type === 'keyword' ? ".keyword" : "");
    },

    getFiltersGroupKey(representation) {
        if (!representation.filters)
            return "NoFilters";

        let returning = "";

        for (let filterIndex = 0; filterIndex < representation.filters.length; filterIndex++) {
            returning += representation.filters[filterIndex];
        }

        return returning;
    },

    //Deprecated at moment
    // getFilterOnlyQueryDescriptor(filterItems) {
    //
    //     //At moment we support filter only queries on a single index, if we find an index different from first one return nothing for simplicity
    //     //this function at moment is only used to get full documents from an index pattern.
    //     let index = filterItems[0].index;
    //     let queryObject = {};
    //     queryObject.name = "full_document_" + index;    //Assign query name based on item index and aggregation, will match id in data representations
    //     queryObject.q = "index=" + index + "&filters=";
    //     let filters = "";
    //     for(let filterIndex = 0 ; filterIndex < filterItems.length ; filterIndex++) {
    //         if(filterItems[filterIndex].index != index)
    //             continue;
    //         for(let conditionIndex = 0 ; conditionIndex < filterItems[filterIndex].filters[0].conditions.length ; conditionIndex++) {
    //             if (filters)
    //                 filters += ",";
    //             filters += this.getFullFilterFieldName(filterItems[filterIndex]) + "," + filterItems[filterIndex].filters[0].conditions[conditionIndex].operator + "," + filterItems[filterIndex].filters[0].conditions[conditionIndex].value;
    //         }
    //     }
    //     queryObject.q += filters;
    //     queryObject.q += "&sort=@timestamp,asc";
    //     let queryDescriptor = {};
    //     queryDescriptor.raw = [ queryObject ];
    //     queryDescriptor.agg = [];
    //     return queryDescriptor;
    // },

    //TODO timeless indexes requires a better management
    getTimeLessToken(index, representation) {
        if (representation && representation.timeless)
            return ",timeless";
        let timelessIndexes = ["_workorders_", "_recipes"/*, "_lines"*/];
        for (let i = 0; i < timelessIndexes.length; i++) {
            if (index.indexOf(timelessIndexes[i]) > -1)
                return ",timeless";
        }
        return "";
    },

    getFirstLastToken(representation) {
        if (representation && representation.type === Defines.allAggregations.first.id)
            return ",first";
        else if (representation && representation.type === Defines.allAggregations.last.id)
            return ",last";
        return "";
    },

    getParameterItems(representation) {
        let result = "";

        if (Array.isArray(representation)) {
            for (let i = 0; i < representation.length; i++) {
                result += `&${representation[i].key}=${representation[i].value}`;
            }
        }

        return result;
    },

    expandReusableQueries(dataItems, filterItems, aggregationItems, functionItems) {

        let concatByRef = function (src, dst) {
            for (let item of src)
                dst.push(item);
        };

        for (let fn of functionItems) {
            if (fn.isReusableQuery && Array.isUseful(fn.parameters) && fn.parameters[0].descriptor) {
                for (let binding of fn.parameters[0].targetBindings) {
                    let found = false;
                    for (let item of fn.parameters[0].descriptor.data) {
                        for (let representation of item.representations) {
                            if (representation.name === binding.dataSet && !representation.binded) {
                                representation.show = binding.show;
                                representation.target = binding.target;
                                representation.name = binding.alias;
                                representation.binded = true;
                                if (!Array.isUseful(fn.filters)) {
                                    // If we have filters attached we should keep looping on all representations
                                    found = true;
                                    break;
                                }
                            }
                            if (Array.isUseful(fn.filters)) {
                                representation.filters = representation.filters.concat(fn.filters);
                            }
                        }
                        if (found)
                            break;
                    }
                    if (!found)
                        for (let aggregation of fn.parameters[0].descriptor.aggregations)
                            if (aggregation.name === binding.dataSet && !aggregation.binded) {
                                aggregation.show = binding.show;
                                aggregation.target = binding.target;
                                aggregation.name = binding.alias;
                                aggregation.binded = true;
                                found = true;
                                break;
                            }
                }
                concatByRef(fn.parameters[0].descriptor.data, dataItems);
                concatByRef(fn.parameters[0].descriptor.filters, filterItems);
                concatByRef(fn.parameters[0].descriptor.aggregations, aggregationItems);
                concatByRef(fn.parameters[0].descriptor.functions, functionItems);
            }
        }

        for (let i = 0; i < functionItems.length; i++) {
            if (functionItems[i].isReusableQuery) {
                functionItems.removeAt(i);
                i--;
            }
        }
    },

    //Widgets request max data sets length based on their own needs, yet some kind of aggregations
    //requires a minimum volume of results in order to than reduce them based on subsampling algorithms.
    //We adjust the requested length based on the aggregation type needs
    adjustMaxTimeWindowOnQueryType(aggregationType, requestedMaxTimeWindow) {
        if (aggregationType === Defines.allAggregations.intervals.id
            || aggregationType === Defines.allAggregations.changes.id
            || aggregationType === Defines.allAggregations.ttlog.id
            && requestedMaxTimeWindow > 0 && requestedMaxTimeWindow < 10000) {
            return 10000
        }
        return requestedMaxTimeWindow
    },

    getDataQueryDescriptor(dataItems, filterItems = [], aggregationItems = [], parameterItems = [], functionItems = [], maxDatasetLength, grouping) {
        //Filter only queries are deprecated at moment
        // if((!dataItems || dataItems.length == 0) && filterItems && filterItems.length)
        //     return this.getFilterOnlyQueryDescriptor(filterItems);
        // console.log('DataItems:', dataItems);
        // console.log('FilterItems:', filterItems);
        let queryDescriptor = {
            raw: [],
            agg: [],
            comp: [],
            func: [],
            groups: [],
            info: [],
        };

        let queryObjects = [];

        let rawsCount = 0;
        let aggsCount = 0;
        let compsCount = 0;
        let funcsCount = 0;
        let variablesInfoCount = 0;

        let fullDocumentSelections = {};
        let foundAggregationItemsRecursion;

        this.expandFilterItemsForRecursions(dataItems, filterItems);

        //FN It is necessary to check whether recursive aggregations are present,
        // otherwise aggregations that do not have recursions could be expanded, generating malfunctions in cross aggregations.
        foundAggregationItemsRecursion = this.expandRecursiveVariables(dataItems, [], filterItems);
        if (foundAggregationItemsRecursion) {
            this.expandAggregationItemsForRecursions(dataItems, aggregationItems);
        }

        this.expandReusableQueries(dataItems, filterItems, aggregationItems, functionItems);

        let startTime = new Date();
        //Loop through dataItems, build query for each representation and split data in raw, aggregate and cross aggregate
        for (let itemIndex = 0; itemIndex < dataItems.length; itemIndex++) {

            let item = dataItems[itemIndex]; //Shortcut

            if (!item.representations)
                continue;

            for (let representationIndex = 0; representationIndex < item.representations.length; representationIndex++) {

                let representation = item.representations[representationIndex]; //Shortcut

                //Inform each representation about possible filter alias
                if (item.filtersFromDifferentIndex) {
                    //Check if alias exist
                    let aliases = item.filtersFromDifferentIndex.filter(f => f.filterFound && f.alias !== null);
                    if (Array.isUseful(aliases)) {
                        representation['filterAliases'] = aliases;
                    }
                }

                //Build raw queries
                if (!(representation.type) || representation.type === Defines.allAggregations.raw.id
                    || representation.type === Defines.allAggregations.intervals.id || representation.type === Defines.allAggregations.changes.id
                    || representation.type === Defines.allAggregations.first.id || representation.type === Defines.allAggregations.last.id
                    || representation.type === Defines.allAggregations.ttlog.id || representation.type === Defines.allAggregations.oeeraw.id) {

                    let queryObject = {name: "raw_" + rawsCount, q: ""};
                    let fieldsToQuery = this.getFieldsQueryComponent(item, representation.type);
                    let fullDocumentQuery = "";
                    if (fieldsToQuery.fullDocumentSelected) {
                        if (fullDocumentSelections.hasOwnProperty(fieldsToQuery.fullDocumentSelected)) {
                            fullDocumentQuery = fullDocumentSelections[fieldsToQuery.fullDocumentSelected].queryName;
                        } else {
                            fullDocumentSelections[fieldsToQuery.fullDocumentSelected] = {queryName: queryObject.name};
                        }
                    }
                    if (!fullDocumentQuery) {
                        // console.log('DataItem', item);
                        // console.log('Reprezentation', representation);
                        queryObject.q = "index=" + item.index + fieldsToQuery.queryFields
                            + this.getFiltersConditionString(representation, filterItems) + "&sort=@timestamp,asc"
                            + this.getTimeLessToken(item.index, representation)
                            + this.getFirstLastToken(representation)
                            + this.getRawSourceToken(item, representationIndex);
                        queryObject.q += this.getParameterItems(parameterItems);
                        queryObject.type = "raw";
                        queryObject.id = representation.id;
                        queryObject.needed = Object.isUseful(representation.show) ? representation.show : true;
                        if (representation.type === Defines.allAggregations.intervals.id)
                            queryObject.downsample = "intervals";
                        if (representation.type === Defines.allAggregations.changes.id)
                            queryObject.downsample = "changes";
                        queryObject.max_results = this.adjustMaxTimeWindowOnQueryType(representation.type, maxDatasetLength);
                        queryObjects.push(queryObject);
                        rawsCount++;
                    }
                    representation.queryId = fullDocumentQuery ? fullDocumentQuery : queryObject.name;
                    if (this.adjustMaxTimeWindowOnQueryType(representation.type, maxDatasetLength) !== maxDatasetLength)
                        representation.requestedMaxDataSize = 0;
                    else representation.requestedMaxDataSize = maxDatasetLength;
                    //FN Build variable info queries
                } else if (representation.type === Defines.allAggregations["variable.index"].id || representation.type === Defines.allAggregations["variable.root"].id || representation.type === Defines.allAggregations["variable.name"].id) {
                    let queryObject = {name: "info_" + variablesInfoCount, q: ""};
                    queryObject.q = "index=" + item.index + "&type=" + Defines.getElasticRepresentationType(representation) + "&fields=" + this.getFullFieldName(item, representation.type);
                    representation.queryId = "info_" + variablesInfoCount;
                    queryObject.type = "info";
                    queryObject.id = representation.id;
                    queryObject.needed = Object.isUseful(representation.show) ? representation.show : true;
                    queryObjects.push(queryObject);
                    variablesInfoCount++;
                }
                //Build aggregate queries
                else {
                    let queryObject = {name: "", q: ""};
                    queryObject.q = "index=" + item.index + "&type=" + Defines.getElasticRepresentationType(representation);
                    queryObject.q += "," + this.getFullFieldName(item, representation.type);
                    queryObject.q += this.getAggregationArguments(representation);
                    //V5.1 movesum is deprecated due to unusage
                    //queryObject.q += this.getMoveSumPointsPlaceholder(representation.type);
                    queryObject.q += this.getAggregationWindowPlaceholder(representation);
                    queryObject.q += this.getTimeLessToken(item.index, representation);
                    queryObject.q += this.getFiltersConditionString(representation, filterItems);
                    queryObject.q += this.getParameterItems(parameterItems);

                    representation.queryId = "agg_" + aggsCount;
                    queryObject.name = representation.queryId;
                    queryObject.type = "agg";
                    queryObject.id = representation.id;
                    queryObject.needed = Object.isUseful(representation.show) ? representation.show : true;
                    queryObjects.push(queryObject);
                    aggsCount++;
                }
            }
        }
        if (Config.debug) {
            console.log("Queries created in {0}".format(new Date() - startTime));
        }
        startTime = new Date();
        if (Array.isUseful(aggregationItems)) {

            for (let crossIndex = 0; crossIndex < aggregationItems.length; crossIndex++) {
                let item = aggregationItems[crossIndex]; //Shortcut
                if (Array.isUseful(item.buckets)) {
                    //Buckets aggregations and all other operator have totally different management
                    if (item.type === Defines.allAggregations.buckets.id) {
                        //Buckets aggregations goes into the "agg" array, not in "comp" and have special sintax to define buckets nesting
                        let baseQueryTokens = [];
                        let filters = [];
                        let queryTypeTokenIndex = -1;
                        let filtersTokenIndex = -1;
                        let queryObject = {name: "", q: ""};

                        for (let i = item.buckets.length - 1; i >= 0; i--) {
                            //Use data representation Ids referenced by cross aggregations to look for source queries that were previously compiled
                            for (let queryIndex = 0; queryIndex < queryObjects.length; queryIndex++) {
                                if (queryObjects[queryIndex].id === item.buckets[i]) {
                                    //Get the base aggregation bucket query as master for the query.
                                    //Basically we get: query structure, index, first aggregation bucket and source from the base bucket.
                                    //We will then collect all filters from all queries and add them to output query
                                    //All query must have a single index and source clause and is imposed by base bucket.
                                    if (!queryObject.q) {
                                        queryObject.q = queryObjects[queryIndex].q;
                                        baseQueryTokens = queryObject.q.split("&");
                                        for (let [index, token] of baseQueryTokens.entries()) {
                                            if (token.startsWith("type="))
                                                queryTypeTokenIndex = index;
                                            if (token.startsWith("filter=")) {
                                                filters.push(token.replace("filter=", ""));
                                                filtersTokenIndex = index;
                                            }
                                        }
                                        //Remove filters token, this will simplify reassembling
                                        if (filtersTokenIndex >= 0)
                                            baseQueryTokens.removeAt(filtersTokenIndex);
                                        continue;
                                    }
                                    if (queryTypeTokenIndex !== 1) {  //Something went wrong, query is unbuildable
                                        queryObject.q = "";
                                        break;
                                    }
                                    //For all other buckets we just insert tokens to the base query and collect filters
                                    //Split query in tokens to extract the aggregation type and fields token and append them to the source
                                    //Bucketed OEE has a special sintax
                                    let sourceQueryTokens = queryObjects[queryIndex].q.split("&");
                                    if (sourceQueryTokens[0].includes("index=OEE_")) {
                                        let dev = (sourceQueryTokens[1].split("."))[0].replace("fields=", "");
                                        baseQueryTokens[queryTypeTokenIndex] += ",(oee," + dev;
                                    } else {
                                        for (let token of sourceQueryTokens) {
                                            if (token.startsWith("type=")) {
                                                baseQueryTokens[queryTypeTokenIndex] += ",(" + token.replace("type=", "");
                                                break;
                                            }
                                        }
                                    }
                                    //Get filters
                                    for (let token of sourceQueryTokens) {
                                        if (token.startsWith("filter=")) {
                                            filters.push(token.replace("filter=", ""));
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        if (queryObject.q) {
                            //Complete the bucketing syntax by closing brackets
                            for (let i = 1; i < item.buckets.length; i++)
                                baseQueryTokens[queryTypeTokenIndex] += ")";

                            //reassemble query
                            queryObject.q = baseQueryTokens[0];
                            for (let i = 1; i < baseQueryTokens.length; i++)
                                queryObject.q += "&" + baseQueryTokens[i];

                            //Add filters
                            if (Array.isUseful(filters)) {
                                queryObject.q += "&filter=" + filters[0];
                                for (let i = 1; i < filters.length; i++)
                                    queryObject.q += "," + filters[i];
                            }
                            //Assign unique name to both query and representation, will be used to find data regarding this representation within query results
                            item.queryId = "agg_" + aggsCount;
                            queryObject.name = item.queryId;
                            queryObject.type = "agg";
                            queryObject.id = item.id;
                            queryObject.needed = Object.isUseful(item.show) ? item.show : true;
                            queryObjects.push(queryObject);
                            aggsCount++;
                        }
                    }
                        //Both manual and groupings just operate on the results from other queries and they have special formats
                        //yet, both of them needs the referenced queries to be executed (set to needed). Then, manual aggregations won't appear at all
                    //in query since reaggregation is (currently) managed at front-end level, while grouping is a specified in a separate query object
                    else if (item.type === Defines.allAggregations.inner.id || item.type === Defines.allAggregations.outer.id || item.type === Defines.allAggregations.manual.id) {
                        let queries = [];
                        //Use data representation Ids referenced by cross aggregations to look for source queries that were previously compiled
                        for (let queryIndex = 0; queryIndex < queryObjects.length; queryIndex++)
                            if (item.buckets.includes(queryObjects[queryIndex].id)) {
                                //Execute query regardless of show flag since we need values for cross-aggregation
                                queryObjects[queryIndex].needed = true;
                                //In case of groupings we also need to store query names to pass to query
                                queries.push(queryObjects[queryIndex].name)
                            }
                        //Create group node
                        if (item.type !== Defines.allAggregations.manual.id) {
                            queryDescriptor.groups.push({
                                queries: queries,
                                mode: item.type
                            });
                        }
                    }
                    //Table grouping is not affecting BE query
                    else if (item.type === Defines.allAggregations.tablegroup.id) {
                    }
                    //FN Category grouping is not effecting BE query
                    else if (item.type === Defines.allAggregations.categorygroup.id) {
                    }
                    //Backend based cross-aggregations (AVG, SUM, MIN, MAX, etc...)
                    else {
                        let queryObject = {name: "", script: "", queries: []};
                        for (let sourceIndex = 0; sourceIndex < item.buckets.length; sourceIndex++) {
                            let source = item.buckets[sourceIndex]; //Shortcut for current source data item
                            let sourceQueryObject = {name: "q" + (sourceIndex + 1), q: ""}; //Initialize source query item descriptor, use an arbitrary progressive name, it will only be used to name source query data in results
                            for (let queryIndex = 0; queryIndex < queryObjects.length; queryIndex++) { //Use data representation Ids referenced by cross aggregations to look for source queries that were previously compiled
                                if (queryObjects[queryIndex].id === source)
                                    sourceQueryObject.q = queryObjects[queryIndex].q;
                            }
                            queryObject.queries.push(sourceQueryObject);
                        }
                        //Assign unique name to both query and cross-aggregation descriptor, will be used to find data regarding this aggregation within query results
                        item.queryId = "cross_" + compsCount;
                        queryObject.name = item.queryId;
                        queryObject.type = "comp";
                        queryObject.id = item.id;
                        //Assign cross-aggregation algorithm
                        //check if type is cross recursive aggregation
                        let type = item.type
                        if (type === "cavg" || type === "cmin" || type === "cmax" || type === "csum") {
                            type = type.replace('c', '')
                        }
                        queryObject.script = type;
                        queryObject.needed = Object.isUseful(item.show) ? item.show : true;
                        queryObjects.push(queryObject);
                        compsCount++;
                    }
                }
            }
        }

        if (Config.debug) {
            console.log("Aggregations created in {0}".format(new Date() - startTime));
        }

        if (Array.isUseful(functionItems)) {

            for (let functionIndex = 0; functionIndex < functionItems.length; functionIndex++) {
                if (!(Object.isUseful(functionItems[functionIndex].show) && functionItems[functionIndex].show))
                    continue;
                let func = Utils.detach(functionItems[functionIndex]); //Shortcut
                if (func.type === Defines.allFunctions.value.id)
                    continue;    //Managed at frontend level
                if (func.type === Defines.allFunctions.cp.id || func.type === Defines.allFunctions.cpk.id) {
                    func = Quality.getFunctionQuery(func);
                }
                if (func.type === Defines.allFunctions.statmerge.id) {
                    func = Quality.getFunctionQueryStatMerge(func);
                }
                if (!func)
                    continue;   //Not enough data
                //Unroll parameters queries
                for (let i = 0; i < func.parameters.length; i++) {
                    //If parameter is referencing a query...
                    if (func.parameters[i].type === "query") {
                        //...Use data representation Ids referenced by cross aggregations to look for source queries that were previously compiled
                        for (let queryIndex = 0; queryIndex < queryObjects.length; queryIndex++)
                            if (queryObjects[queryIndex].id === func.parameters[i].value.id) {
                                func.parameters[i].query = {
                                    name: queryObjects[queryIndex].name,
                                    q: queryObjects[queryIndex].q
                                };
                                func.parameters[i].type = queryObjects[queryIndex].type + "_query";
                                delete func.parameters[i].value;
                                break
                            }
                    }
                }

                // Add query to final request
                functionItems[functionIndex].queryId = "funcs_" + funcsCount;
                queryDescriptor.func.push({
                    name: functionItems[functionIndex].queryId,
                    parameters: func.parameters,
                    type: func.type
                });
                funcsCount++;
            }
        }
        //Now scroll-back all queries and execute only the needed
        for (let queryIndex = 0; queryIndex < queryObjects.length; queryIndex++) {
            if (queryObjects[queryIndex].needed) {
                if (queryObjects[queryIndex].type === "comp") {
                    queryDescriptor.comp.push({
                        name: queryObjects[queryIndex].name,
                        queries: queryObjects[queryIndex].queries,
                        script: queryObjects[queryIndex].script
                    })
                } else {
                    queryDescriptor[queryObjects[queryIndex].type].push({
                        name: queryObjects[queryIndex].name,
                        q: queryObjects[queryIndex].q,
                    })
                }
                if (queryObjects[queryIndex].max_results)
                    queryDescriptor[queryObjects[queryIndex].type].last().max_results = queryObjects[queryIndex].max_results;
                if (queryObjects[queryIndex].downsample)
                    queryDescriptor[queryObjects[queryIndex].type].last().downsample = queryObjects[queryIndex].downsample;
                queryDescriptor[queryObjects[queryIndex].type].last().continueOnError = true;
            }
        }
        if (grouping && !Array.isUseful(queryDescriptor.groups)) {
            let queries = [];

            for (let queryIndex = 0; queryIndex < queryObjects.length; queryIndex++)
                if (queryObjects[queryIndex].needed)
                    queries.push(queryObjects[queryIndex].name);
            for (let queryIndex = 0; queryIndex < queryDescriptor.func.length; queryIndex++)
                queries.push(queryDescriptor.func[queryIndex].name);
            //Create group node
            queryDescriptor.groups.push({
                queries: queries,
                mode: grouping
            });
        }
        return queryDescriptor;
    },

    expandRecursiveVariables(dataItems, aggregationItems, filterItems) {
        let aggregationItemsRecursion = [];
        let dataItemsRecursion = [];
        let self = this;
        let foundAggregationItemsRecursion = false

        //FN Skip the creation of recursive aggregations if the length of recursive elements is different
        //FN TODO manage the condition to skipAggregations
        let skipAggregations = false;
        let tmpLength = 0;

        let startTime = new Date();

        dataItems.forEach((di, indexDi) => {
            for (let indexRep = 0; indexRep < di.representations.length; indexRep++) {
                let dataItemsRecursionTmp = [];
                let r = di.representations[indexRep];
                if (Array.isUseful(r.recursions)) {
                    let representionClone = Utils.detach(r);
                    delete representionClone.recursions;
                    //FN used to insert into recursion the variable used for generate recursion
                    let parentDataItem = Utils.detach(di);
                    r.recursions.insertItem(0, parentDataItem);

                    r.recursions.forEach(rv => {
                        let dataItem = {
                            index: rv.index,
                            root: rv.root,
                            name: rv.name,
                            type: rv.type,
                            key: rv.key,
                            matched: true,
                            selectedForFiltering: false,
                            selectedForVisualization: true,
                            representations: []
                        }

                        let rootToken = Object.isUseful(dataItem.root) ? dataItem.root.split(".") : [];
                        //FN Find the min length to compare
                        tmpLength = tmpLength === 0 ? rootToken.length : tmpLength < rootToken.length ? tmpLength : rootToken.length;

                        dataItem.representations.push(self.cloneRepresentation(dataItem, representionClone, rv.label, rv.root === di.root));

                        //apply correct filter for recursion
                        if (r.filters) {
                            r.filters.forEach(dif => {
                                //search filter to replace in filterItems
                                let currentFilterId = dif

                                //find in filterItems same filterItem with parentFilterId==currentFilterId and root==dataItem.root
                                let filterItem = filterItems.find(fi => {
                                    return fi.root === dataItem.root && fi.filters.find(fif => {
                                        return fif.parentFilterId === currentFilterId
                                    })
                                })
                                if (filterItem) {
                                    //if found search the correct filter in filteritem filters
                                    let filter = filterItem.filters.find(fif => {
                                        return fif.parentFilterId === currentFilterId
                                    })
                                    if (filter) {
                                        //if found filter apply filter id to recursion data item
                                        //replace filterId in representations
                                        dataItem.representations.forEach(dir => {
                                            let idx = dir.filters.findIndex(dirf => {
                                                return dirf === currentFilterId
                                            })
                                            if (idx > -1) {
                                                dir.filters[idx] = filter.filterId
                                            }
                                        })
                                    }

                                }

                            })
                        }


                        dataItemsRecursion.push(dataItem);
                        dataItemsRecursionTmp.push(Utils.detach(dataItem));

                    });
                    //FN used to remove from the representations of the dataItem
                    // the representation of the source data used for the recursions and inserted itself in the recursions
                    if (di.representations[indexRep].name === dataItemsRecursionTmp[0].representations[indexRep].name) {
                        di.representations.removeAt(indexRep);
                        if (indexRep < di.representations.length) {
                            indexRep--;
                        }
                        if (di.representations.length === 0) {
                            di.toDelete = true
                        }
                    }
                }
            }
        });

        if (Config.debug) {
            console.log("Recursive queries created in {0}".format(new Date() - startTime));
        }

        //TODO check if can deleted (i think so) -> DS
        //OLD LOGIC OF AGGREGATION
        //THIS PART OF CODE IS SKYPPED PASSING EMPTY ARRAY TO METHOD
        /********THIS CODE IS DEPRECATED AND NOT USED ***/
        let recursiveAggregations = [];
        startTime = new Date();
        if (!skipAggregations) {
            aggregationItems.forEach((aggregationItem, aggregationIndex) => {
                if (aggregationItem.type === Defines.allAggregations.categorygroup.id) {
                    return;
                }
                let recursiveIndex = 0;
                let tmpBuckets = [];
                //FN Used to skip an item with a root visited
                let roots = [];
                dataItemsRecursion.forEach((dir, idxDir) => {
                    if (!roots.includes(dir.root)) {
                        let tmpDataItemRoot = dir.root;
                        let token = dir.root.split(".");
                        if (token.length > tmpLength) {
                            token = token.slice(0, tmpLength);
                            tmpDataItemRoot = token.join(".");
                        }
                        roots.push(tmpDataItemRoot);
                        //FN Find the dataItemsRecursion whith the same root
                        let filteredDataItemsRecursion = dataItemsRecursion.filter(obj => {

                            let tmpObjRoot = obj.root;
                            let tokenRoot = tmpObjRoot.split(".");
                            if (tokenRoot.length > tmpLength) {
                                tokenRoot = tokenRoot.slice(0, tmpLength);
                                tmpObjRoot = tokenRoot.join(".");
                            }

                            return tmpObjRoot === tmpDataItemRoot;

                        });

                        let filteredAggregationItemsRecursion = aggregationItemsRecursion.filter(obj => {

                            let tmpObjRoot = obj.identifier;
                            let tokenRoot = tmpObjRoot.split(".");
                            if (tokenRoot.length > tmpLength) {
                                tokenRoot = tokenRoot.slice(0, tmpLength);
                                tmpObjRoot = tokenRoot.join(".");
                            }

                            return tmpObjRoot === tmpDataItemRoot;

                        });

                        if (filteredAggregationItemsRecursion.length > 0) {
                            filteredDataItemsRecursion.push(...filteredAggregationItemsRecursion);
                        }

                        filteredDataItemsRecursion.forEach(filteredDataItemRecursion => {
                            let filteredDataItemRecursionRoot = "";
                            if (Object.isUseful(filteredDataItemRecursion.identifier)) {
                                filteredDataItemRecursionRoot = filteredDataItemRecursion.identifier;
                                    aggregationItem.buckets.forEach(bucket => {
                                        if (bucket === filteredDataItemRecursion.recursiveParentId) {
                                            if(tmpBuckets.length < aggregationItem.buckets.length) {
                                                if (!tmpBuckets.includes(bucket)) {
                                                    tmpBuckets.push(filteredDataItemRecursion.id);
                                                }
                                            }
                                        }
                                    });

                            } else {
                                filteredDataItemRecursionRoot = filteredDataItemRecursion.root;
                                filteredDataItemRecursion.representations.forEach((rep, idxRep) => {
                                    aggregationItem.buckets.forEach(bucket => {
                                        if (bucket === rep.recursiveParentId) {
                                            if(tmpBuckets.length < aggregationItem.buckets.length) {
                                                if (!tmpBuckets.includes(bucket)) {
                                                    tmpBuckets.push(rep.id);
                                                }
                                            }
                                        }
                                    });
                                });
                            }
                            if (!roots.includes(filteredDataItemRecursionRoot)) {
                                roots.push(filteredDataItemRecursionRoot);
                            }
                        });
                        if(tmpBuckets.length > 0) {
                            let tmpAggregationItem = Utils.detach(aggregationItem);
                            tmpAggregationItem.id = "cross_" + Utils.timeTag(tmpAggregationItem.defaultName + recursiveIndex); //Create a unique id that survives changes, needed for cross aggregations
                            tmpAggregationItem.buckets = tmpBuckets;
                            tmpAggregationItem.identifier = tmpDataItemRoot + "." + recursiveIndex; //dataItemsRecursion[idxDir].root;
                            tmpAggregationItem.recursiveParentId = aggregationItem.id;
                            aggregationItemsRecursion.push(tmpAggregationItem);
                            tmpBuckets = [];
                            recursiveAggregations.push(aggregationIndex);
                            recursiveIndex++;
                        }
                    }
                });
            });
            if (Config.debug) {
                console.log("Recursive aggregations loaded in {0}".format(new Date() - startTime));
            }
        }

        if (Array.isUseful(recursiveAggregations)) {
            for (let i = recursiveAggregations.length; i > 0; i--)
                aggregationItems.removeAt(recursiveAggregations[i]);
        }
        aggregationItems.push(...aggregationItemsRecursion);
        dataItems.push(...dataItemsRecursion)
        while(dataItems.find(di=>{return di.toDelete && di.toDelete})){
            dataItems.removeItem(dataItems.find(di=>{return di.toDelete && di.toDelete}))
        }
        foundAggregationItemsRecursion = dataItemsRecursion.length > 0;
        return foundAggregationItemsRecursion;
    },
    expandFilterItemsForRecursions(dataItems, filterItems) {
        let self = this
        let filterItemsRecursion = []
        if (Array.isUseful(filterItems)) {
            filterItems.forEach(fi => {
                fi.filters.forEach(fif => {
                    fif.parentFilterId = fif.filterId
                })
                if (fi.applyOnRecursions) {
                    let dataItemFound = dataItems.find(di => {
                        return di.name === fi.name
                    })
                    //expand recursions of dataItem
                    if (dataItemFound) {
                        dataItemFound.representations.forEach(dir => {
                            if (Array.isUseful(dir.recursions)) {
                                dir.recursions.forEach(dire => {
                                    let fiRepresentationClone = Utils.detach(dir);
                                    delete fiRepresentationClone.recursions;
                                    let filterItem = {
                                        filters: Utils.detach(fi.filters),
                                        index: dire.index,
                                        root: dire.root,
                                        name: dire.name,
                                        type: dire.type,
                                        key: dire.key,
                                        matched: true,
                                        selectedForFiltering: true,
                                        selectedForVisualization: true,
                                        representations: [],
                                    };
                                    filterItem.filters.forEach(fif => {
                                        fif.parentFilterId = fif.filterId
                                        fif.filterId = Utils.timeTag(fif.defaultName)
                                    })
                                    // filterItem.representations.push(self.cloneRepresentation(filterItem, fiRepresentationClone, dir.label,dir.root===fi.root));
                                    filterItemsRecursion.push(filterItem);
                                })
                            }

                        })

                    }
                }
            })
        }
        filterItems.push(...filterItemsRecursion)
    },
    expandAggregationItemsForRecursions(dataItems, aggregationItems) {
        let aggregationItemsRecursion = []
        let buckets = {}
        let bucketsAggr = {}

        //calc uniquer roots for dataitems selected
        let uniqueRoots = []
        dataItems.forEach(di => {
            if (!dataItems.find(dir => {
                return di.root.indexOf(dir.root) > -1 && di.id !== dir.id
            })) {
                if (uniqueRoots.findItem(di.root) < 0 && !uniqueRoots.find(r => {
                    return di.root.indexOf(r + ".") > -1
                })) {
                    uniqueRoots.push(di.root)
                }
            }
        })

        if (Array.isUseful(aggregationItems)) {
            aggregationItems.forEach(ag => {
                buckets = {}
                let aggregationFound = undefined
                let aggregationFounds = []
                let haveDataItem = false
                //iterate buckets of aggregation item
                ag.buckets.forEach(agb => {
                    //find data item related to aggregation item
                    let dataItemFound = dataItems.find(di => {
                        return di.representations.find(dir => {
                            return dir.id === agb
                        })
                    })
                    if (dataItemFound) {
                        haveDataItem = true
                        if (!ag.identifier) {
                            ag.identifier = dataItemFound.root + '.' + (ag.name ? ag.name : ag.defaultName)
                        }
                        //find other data items(are data items calculated by expand recursion method)
                        let otherDataItemsToAggregate = dataItems.filter(di => {
                            return di.representations.find(dir => {
                                return dataItemFound.representations.find(difr => {
                                    return difr.recursiveParentId === dir.recursiveParentId
                                }) && dir.recursiveParentId !== dir.id
                            })
                        })
                        //append founded dataitems id to bucket map by root (machine)
                        otherDataItemsToAggregate.forEach(odi => {
                            //check if root is included in other root of buckets map
                            let root = odi.root
                            //take unique root
                            root = uniqueRoots.find(ur => {
                                return odi.root === ur || odi.root.indexOf(ur + ".") > -1
                            })

                            if (!buckets[root]) {
                                buckets[root] = []
                            }
                            odi.representations.map(r => {
                                return r.id
                            }).forEach(id => {
                                if (!buckets[root].find(br => {
                                    return br === id
                                })) {
                                    buckets[root].push(id)
                                }
                            })
                        })

                    } else {
                        //else find related aggregation
                        aggregationFound = aggregationItems.find(agg => {
                            return agg.id === agb
                        })
                        if (aggregationFound)
                            aggregationFounds.push(aggregationFound)
                    }

                })
                let i = 1
                //check if type of aggregation is cross recursion and append bucket to current aggregation item
                if (ag.type === "cavg" || ag.type === "cmin" || ag.type === "cmax" || ag.type === "csum") {
                    if (!bucketsAggr[ag.id])
                        bucketsAggr[ag.id] = {}
                    if (haveDataItem && aggregationFound) {
                        for (let key in buckets) {
                            let bucketFoundAggr = bucketsAggr[aggregationFound.id]
                            ag.buckets.push(...[...buckets[key], ...bucketFoundAggr[key]])
                            if (!bucketsAggr[ag.id][key]) {
                                bucketsAggr[ag.id][key] = []
                            }
                            bucketsAggr[ag.id][key].push(ag.id)
                            i++
                        }
                    } else if (haveDataItem) {
                        for (let key in buckets) {
                            ag.buckets.push(...buckets[key])
                            if (!bucketsAggr[ag.id][key]) {
                                bucketsAggr[ag.id][key] = []
                            }
                            bucketsAggr[ag.id][key].push(ag.id)
                            i++
                        }
                    } else if (aggregationFound) {
                        let bucketFoundAggr = bucketsAggr[aggregationFound.id]
                        for (let key in bucketFoundAggr) {
                            aggregationFounds.map(afs => {
                                return afs.id
                            }).forEach(agid => {
                                ag.buckets.push(...bucketsAggr[agid][key])
                            })
                            // let bucketFoundAggr=bucketsAggr[aggregationFound.id]
                            // for(let key in bucketFoundAggr) {
                            //     ag.buckets.push(...bucketFoundAggr[key])
                            //     if(!bucketsAggr[ag.id][key]){
                            //         bucketsAggr[ag.id][key]=[]
                            //     }
                            // }
                            if (!bucketsAggr[ag.id][key]) {
                                bucketsAggr[ag.id][key] = []
                            }
                            bucketsAggr[ag.id][key].push(ag.id)
                            i++
                        }
                    }

                } else {
                    if (haveDataItem && aggregationFound) {
                        //if aggregation have referencied dataItem and aggregation
                        for (let key in buckets) {
                            let aggregationItem = Utils.detach(ag)
                            aggregationItem.id = "cross_" + Utils.timeTag(ag.defaultName + i)
                            aggregationItem.buckets = [...buckets[key]]
                            aggregationFounds.map(afs => {
                                return afs.id
                            }).forEach(agid => {
                                aggregationItem.buckets.push(...bucketsAggr[agid][key])
                            })
                            //assign identifier for this recursion
                            aggregationItem.identifier = key + '.' + (aggregationItem.name ? aggregationItem.name : aggregationItem.defaultName)
                            aggregationItemsRecursion.push(aggregationItem)
                            if (!bucketsAggr[ag.id])
                                bucketsAggr[ag.id] = {}
                            if (!bucketsAggr[ag.id][key]) {
                                bucketsAggr[ag.id][key] = []
                            }
                            bucketsAggr[ag.id][key].push(aggregationItem.id)
                            i++
                        }
                    } else {
                        //if have only dataItem and not recursion
                        if (haveDataItem) {
                            for (let key in buckets) {
                                let aggregationItem = Utils.detach(ag)
                                aggregationItem.id = "cross_" + Utils.timeTag(ag.defaultName + i)
                                aggregationItem.buckets = buckets[key]
                                //assign identifier for this recursion
                                aggregationItem.identifier = key + '.' + (aggregationItem.name ? aggregationItem.name : aggregationItem.defaultName)
                                aggregationItemsRecursion.push(aggregationItem)
                                //set a map of related id aggregation to each machine (key)
                                if (!bucketsAggr[ag.id])
                                    bucketsAggr[ag.id] = {}
                                if (!bucketsAggr[ag.id][key]) {
                                    bucketsAggr[ag.id][key] = []
                                }
                                bucketsAggr[ag.id][key].push(aggregationItem.id)
                                i++
                            }
                        } else if (aggregationFound) {
                            //set current identifier of aggregation calculated by aggregation found identifier until last '.'
                            //and added name of current aggregation
                            let ids = aggregationFound.identifier.split('.')
                            ag.identifier = ids.slice(0, ids.length - 1).join('.') + '.' + (ag.name ? ag.name : ag.defaultName)
                            //take related buckets for aggregation found on map
                            let bucketFoundAggr = bucketsAggr[aggregationFound.id]
                            for (let key in bucketFoundAggr) {
                                //create a copy of current aggregation item and change paremeters for recursion
                                let aggregationItem = Utils.detach(ag)
                                aggregationItem.id = "cross_" + Utils.timeTag(ag.defaultName + i)
                                //add all buckets from aggregationfounds id and add from aggregationFounds
                                aggregationItem.buckets = []
                                aggregationFounds.map(afs => {
                                    return afs.id
                                }).forEach(agid => {
                                    aggregationItem.buckets.push(...bucketsAggr[agid][key])
                                })
                                //aggregationItem.buckets=[...bucketFoundAggr[key]]
                                //set a new identifier for new aggregation
                                aggregationItem.identifier = key + '.' + (aggregationItem.name ? aggregationItem.name : aggregationItem.defaultName)
                                aggregationItemsRecursion.push(aggregationItem)
                                //set a map of related id aggregation to each machine (key)
                                if (!bucketsAggr[ag.id])
                                    bucketsAggr[ag.id] = {}
                                if (!bucketsAggr[ag.id][key]) {
                                    bucketsAggr[ag.id][key] = []
                                }
                                bucketsAggr[ag.id][key].push(aggregationItem.id)
                                i++
                            }
                        }
                    }
                }

            })
        }
        aggregationItems.push(...aggregationItemsRecursion)
    },
    cloneRepresentation(dataItem, representation, label, keepId) {
        let newRepresentation = Utils.detach(representation);
        let parentId = newRepresentation.id;
        newRepresentation.name = label && label !== newRepresentation.name ? label : newRepresentation.name;
        newRepresentation.defaultName = this.getDefaultDataRepresentationName(dataItem, newRepresentation);
        if (!keepId) {
            newRepresentation.id = Utils.timeTag(newRepresentation.defaultName); //Create a unique id that survives changes, needed for cross aggregations
        }
        newRepresentation.recursiveParentId = parentId;

        return newRepresentation
    },
    calculateAggregationWindow(representation) {
        let unit = (Object.isUseful(representation.aggregationWindowUnit) ? representation.aggregationWindowUnit : "s");
        let value = (Object.isUseful(representation.aggregationWindow) ? representation.aggregationWindow : 0);
        if (value === 0)
            return 0;   //Aggregate on full window
        if (unit === "s")
            return value;
        else if (unit === "m")
            return value * 60;
        else if (unit === "h")
            return value * 3600;
        else if (unit === "d")
            return value * 86400;

        return value;
    },

    getAggregationWindowPlaceholder(representation) {
        let aggregationWindow = this.calculateAggregationWindow(representation);
        // let forceTimeSource = ((representation.target === Defines.crossAggregationTarget.id) || Defines.isCategoricalAggregation(representation.type));
        //
        // if ((aggregationWindow === 0) && !forceTimeSource)
        //     return "";

        //V5.1 movesum is deprecated due to unusage
        // if (representation.type === Defines.allAggregations.movesum.id)
        //     return "&source=@timestamp,~|~movesum_sample_size={0}~|~,asc".format(aggregationWindow);
        // else
        return "&source=@timestamp,~|~aggregation_window={0}~|~,asc".format(aggregationWindow);
    },

    //Some data are not queried to DB but calculated in backend (OEE for instance). These data are requested to backend as raw series,
    //yet a source token with an aggregation window is requested to define calculation step. Set to 0 to calculate a single point over the full search time window.
    //If no window is specified impose a default reasonable minimum sample step.
    getRawSourceToken(item, representationIndex) {

        //At moment OEE is the only backend calculation
        if (item.type !== Defines.WellKnownTypes.OEEComponent.id)
            return "";
        if (Object.isUseful(item.representations[representationIndex].aggregationWindow)) {
            return ("&source=@timestamp,~|~aggregation_window={0}~|~".format(this.calculateAggregationWindow(item.representations[representationIndex])));
        } else
            return "&source=@timestamp,~|~aggregation_window=300";
    },

    //V5.1 movesum is deprecated due to unusage
    // getMoveSumPointsPlaceholder(aggregation) {
    //
    //     if (aggregation === Defines.allAggregations.movesum.id)
    //         return ",~|~movesum_aggregation_window~|~";
    //     else
    //         return "";
    // },

    calculateTimeWindow(query, timeStart, timeEnd, forceTimeless = false) {
        //Expand variable parameters delimited by "~|~"
        let windowSizeMillis = 0;
        if (query && query.indexOf("~|~") > -1) {    //We have a query and contains a parameter at least
            let queryTokens = query.split("~|~");
            if (query.indexOf("aggregation_window=") >= -1) {
                for (let tkIndex = 0; tkIndex < queryTokens.length; tkIndex++) {
                    //Expand aggregations time windows
                    let paramStart = queryTokens[tkIndex].indexOf("aggregation_window=");
                    if (paramStart > -1) {
                        let windowSize = Number(queryTokens[tkIndex].substr(queryTokens[tkIndex].indexOf("=") + 1)) * 1000;
                        if (!isNaN(windowSize) && windowSize === 0) {
                            if (query.indexOf(",timeless") > -1 || forceTimeless) {
                                windowSizeMillis = 3153600000000;
                            } else {
                                windowSizeMillis = parseInt(((Date.parse(timeEnd) - Date.parse(timeStart))) * 2);
                                if (windowSizeMillis < 1000)
                                    windowSizeMillis = 1000;
                            }
                            query = query.replace("~|~" + queryTokens[tkIndex] + "~|~", Math.round(windowSizeMillis) + "ms")
                        } else if (!isNaN(windowSize) && windowSize > 0) {
                            if (windowSize < 1000)
                                windowSize = 1000;
                            query = query.replace("~|~" + queryTokens[tkIndex] + "~|~", Math.round(windowSize) + "ms")
                        } else if (!isNaN(windowSize) && windowSize < 0) {
                            windowSizeMillis = parseInt(((Date.parse(timeEnd) - Date.parse(timeStart))) / 3000);
                            if (windowSizeMillis < 60000)
                                windowSizeMillis = 60000;
                            query = query.replace("~|~" + queryTokens[tkIndex] + "~|~", Math.round(windowSizeMillis) + "ms")
                        }
                    }
                }
            }
            //V5.1 movesum is deprecated due to unusage
            //else if (query.indexOf("movesum_aggregation_window") > -1) {
            //     //Expand aggregations time windows
            //     let requestedWindowSize = 60;
            //     windowSizeMillis = parseInt(((Date.parse(timeEnd) - Date.parse(timeStart))));
            //     windowSizeMillis = Math.round(windowSizeMillis / 10000) * 10000;
            //     if (windowSizeMillis < 10000)
            //         windowSizeMillis = 10000;
            //     for (let tkIndex = 0; tkIndex < queryTokens.length; tkIndex++) {
            //         let paramStart = queryTokens[tkIndex].indexOf("movesum_sample_size=");
            //         if (paramStart > -1) {
            //             let windowSize = Number(queryTokens[tkIndex].substr(queryTokens[tkIndex].indexOf("=") + 1));
            //             if (!isNaN(windowSize))
            //                 requestedWindowSize = windowSize !== 0 ? Math.round(windowSize / 10) * 10000 : windowSizeMillis;
            //             query = query.replace("~|~" + queryTokens[tkIndex] + "~|~", 10000 + "ms")
            //         }
            //     }
            //     for (let tkIndex = 0; tkIndex < queryTokens.length; tkIndex++) {
            //         let paramStart = queryTokens[tkIndex].indexOf("movesum_aggregation_window");
            //         if (paramStart > -1) {
            //             query = query.replace("~|~" + queryTokens[tkIndex] + "~|~", Math.round(requestedWindowSize / 10000));
            //         }
            //     }
            // }
        }
        return query;
    },

    calculateTimeWindows(queryDescriptor, timeStart, timeEnd, forceTimeless = false) {
        let localQueryDescriptor = Utils.detach(queryDescriptor);

        if (Array.isUseful(localQueryDescriptor.raw))
            for (let i = 0; i < localQueryDescriptor.raw.length; i++)
                localQueryDescriptor.raw[i].q = this.calculateTimeWindow(localQueryDescriptor.raw[i].q, timeStart, timeEnd, forceTimeless);

        if (Array.isUseful(localQueryDescriptor.agg))
            for (let i = 0; i < localQueryDescriptor.agg.length; i++)
                localQueryDescriptor.agg[i].q = this.calculateTimeWindow(localQueryDescriptor.agg[i].q, timeStart, timeEnd, forceTimeless);

        if (Array.isUseful(localQueryDescriptor.comp))
            for (let i = 0; i < localQueryDescriptor.comp.length; i++)
                for (let j = 0; j < localQueryDescriptor.comp[i].queries.length; j++)
                    localQueryDescriptor.comp[i].queries[j].q = this.calculateTimeWindow(localQueryDescriptor.comp[i].queries[j].q, timeStart, timeEnd, forceTimeless);

        if (Array.isUseful(localQueryDescriptor.func))
            for (let i = 0; i < localQueryDescriptor.func.length; i++)
                if (Array.isUseful(localQueryDescriptor.func[i].parameters))
                    for (let j = 0; j < localQueryDescriptor.func[i].parameters.length; j++)
                        if (Object.isUseful(localQueryDescriptor.func[i].parameters[j].query))
                            localQueryDescriptor.func[i].parameters[j].query.q = this.calculateTimeWindow(localQueryDescriptor.func[i].parameters[j].query.q, timeStart, timeEnd, forceTimeless);

        return localQueryDescriptor;
    },

    async getDataBlob(queryDescriptor, timeStart, timeEnd) {

        queryDescriptor.time_from = timeStart;
        queryDescriptor.time_to = timeEnd;

        let localQueryDescriptor = this.calculateTimeWindows(queryDescriptor, timeStart, timeEnd);

        if (Config.debug) {
            let actualQueryIndex = queryIndex;
            queryIndex++;
            startTimes[actualQueryIndex] = new Date();
            return new Promise((resolve, reject) => {
                console.log(new Date().format("HH:mm:ss fff") + " - query: " + actualQueryIndex + " - " + JSON.stringify(localQueryDescriptor));
                OrchestratorAPI.proxyCall('post', Microservices.queryUrl + '/query', localQueryDescriptor)
                    .then((result) => {
                        console.log(new Date().format("HH:mm:ss fff") + " - query: " + actualQueryIndex + " response in: " + (new Date() - startTimes[actualQueryIndex]));
                        delete startTimes[actualQueryIndex];
                        result["queryIndex"] = actualQueryIndex;
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(new Date().format("HH:mm:ss fff") + " - query: " + actualQueryIndex + " response in: " + (new Date() - startTimes[actualQueryIndex]));
                        delete startTimes[actualQueryIndex];
                        reject(error)
                    })
            })
        } else return OrchestratorAPI.proxyCall('post', Microservices.queryUrl + '/query', localQueryDescriptor)
    },

    // downSampleDataSets(dataSets, maxSize) {
    //     if(!maxSize)
    //         return dataSets;
    //     dataSets.forEach((dataSet) => {
    //         if(Array.isUseful(dataSet.data) && dataSet.data.length > maxSize) {
    //             dataSet.downSamplingStep = dataSet.data.length / maxSize;
    //             dataSet.originalLength = dataSet.data.length;
    //             dataSet.requestedMaxSize = maxSize;
    //             let downSampled = [];
    //             let i = 0;
    //             let index = 0;
    //             while(index < dataSet.data.length) {
    //                 downSampled.push(dataSet.data[index]);
    //                 i += dataSet.downSamplingStep;
    //                 index = Math.round(i);
    //             }
    //             dataSet.data = downSampled;
    //         }
    //     });
    //     return dataSets;
    // },

    orderDataSets(dataSets) {
        dataSets.sortOnProperty("zIndex");
        // for(let i = 0 ; i < dataSets.length ; i++) {
        //     if(dataSets[i].zOffset) {
        //         let offset = dataSets[i].zOffset;
        //         if(i + offset < 0)
        //             offset = -i;
        //         else if(i + offset >= dataSets.length)
        //             offset = dataSets.length - i - 1;
        //         dataSets.moveItem(i, i + offset, false);
        //         if(offset < 0)
        //             i++;
        //         if(offset > 0)
        //             i--;
        //     }
        // }
        return dataSets;
    },
    //Transforms raw dataValues structure as returned by query in an easily bindable structure based on descriptor dataItems
    unwrapDataSets(dataItems, aggregationItems, functionItems, dataValues, queryIndex, dataItemsComponents) {
        if(Config.debug && Object.isUseful(queryIndex) && queryIndex > 0)
            startTimes[queryIndex] = new Date();

        try {

            if (!Array.isUseful(dataItems) && Array.isUseful(aggregationItems))
                return dataValues;

            //Create target data collection
            let dataSets = [];
            let dataSetsComponents = []

            //FN used to explode the data contained in the roots
            let dataItemRoots = [];
            let itemIndex = 0;
            let representationIndex = 0;

            //Unwrap data
            for (itemIndex = 0; itemIndex < dataItems.length; itemIndex++) {   //Loop on data items and on each representation within them
                if (!dataItems[itemIndex].representations)
                    continue;
                if (Object.isDefined(dataItems[itemIndex].selectedForRootVisualization) && dataItems[itemIndex].selectedForRootVisualization && Array.isUseful(dataItemsComponents)) {
                    continue;
                }
                for (representationIndex = 0; representationIndex < dataItems[itemIndex].representations.length; representationIndex++) {

                    let representation = dataItems[itemIndex].representations[representationIndex]; //Shortcut
                    if (representation.queryId) { //Jump cross aggregation data items
                        let dataSet = this.getDataSet(representation, dataItems[itemIndex], dataValues);

                        //Place the restructured item information in returning collection
                        dataSets.push(
                            {
                                label: representation.name ?
                                    representation.name :
                                    representation.defaultName,
                                isDefaultLabel: !representation.name,
                                target: representation.target,
                                representation: representation.type,
                                aggregationWindow: this.calculateAggregationWindow(representation),
                                isCategorical: Defines.isCategoricalAggregation(representation.type),
                                data: this.normalizeDataSet(Normalizations.getAllRepresentationNormalizations(representation), dataSet.data, dataItems[itemIndex].root + "." + dataItems[itemIndex].name),
                                dataFormat: dataSet.format,
                                id: representation.id,
                                recursiveParentId: representation.recursiveParentId,
                                identifier: dataItems[itemIndex].root + "." + dataItems[itemIndex].name,
                                dataType: dataSet.type || representation.dataType,
                                zIndex: representation.zIndex,
                                visualizationOptions: representation.visualizationOptions,
                                show: Object.isUseful(representation.show) ? representation.show : true,
                                //This is to give user the warning that dataset was subsampled.
                                //I know, it's terrible, yet it was too complex at moment to return information explictly from backend
                                //since some subsampling logics are too nested. We will make it better in the future. At moment this is working just fine.
                                //TODO get info from backend and remove this comment
                                subSampled: representation.requestedMaxDataSize > 0 && (dataSet.data.length >= representation.requestedMaxDataSize * 0.95 && dataSet.data.length <= representation.requestedMaxDataSize * 1.05),
                                error: dataSet.error ? dataSet.error : ""
                            }
                        );
                    }
                }
            }

            let manualAggregationsList = [];
            let tableGroupingsList = [];
            let categoryGroupingList = [];

            //Unwrap cross aggregations
            for (let crossIndex = 0; crossIndex < aggregationItems.length; crossIndex++) {   //Loop on data items and on each representation within them

                let aggregation = aggregationItems[crossIndex]; //Shortcut
                let dataSet = null;
                //Manual cross aggregations are processed later to allow manual reaggregation of cross aggregations
                if (aggregation.type === Defines.allAggregations.manual.id) {
                    manualAggregationsList.push(crossIndex);
                    continue;
                } else if (aggregation.type === Defines.allAggregations.tablegroup.id) {
                    tableGroupingsList.push(crossIndex);
                    continue;
                } else if (aggregation.type === Defines.allAggregations.categorygroup.id) {
                    categoryGroupingList.push(crossIndex);
                    continue;
                }
                //Buckets aggregations have special format
                else if (aggregation.type === Defines.allAggregations.buckets.id)
                    dataSet = this.unwrapBucketsResult(aggregation, dataValues, dataItems);
                else if (!Defines.isGrouping(aggregation.type))
                    dataSet = this.getDataSet(aggregation, null, dataValues);
                else continue;

                //Place the restructured item information in returning collection
                dataSets.push(
                    {
                        label: (aggregation.name ? aggregation.name : aggregation.defaultName),
                        isDefaultLabel: !aggregation.name,
                        target: aggregation.target,
                        representation: aggregation.type,
                        data: this.normalizeDataSet(Normalizations.getAllRepresentationNormalizations(aggregation), dataSet.data),
                        dataFormat: dataSet.format,
                        id: aggregation.id,
                        identifier: aggregation.identifier,
                        isCategorical: (aggregation.type === Defines.allAggregations.buckets.id),
                        zIndex: aggregation.zIndex,
                        visualizationOptions: aggregation.visualizationOptions,
                        show: Object.isUseful(aggregation.show) ? aggregation.show : true
                    });
                //Used by buckets aggregations only
                if (dataSet.bucketsNames)
                    dataSets.last().bucketsNames = dataSet.bucketsNames;
            }

            //Unwrap manual cross aggregations, since they use results from other queries we process them after all other unwrappings
            //There could be cross dependencies also between manual queries. To allow bothering user with items ordering
            //we jump aggregations that requires source buckets not yet processed and then retry them until convergence
            let anyAggregationsProcessed = true;
            let finalPassage = false;

            while (Array.isUseful(manualAggregationsList) && (anyAggregationsProcessed || finalPassage)) {
                anyAggregationsProcessed = false;
                // console.log("new run");
                for (let manualCrossIndex = 0; manualCrossIndex < manualAggregationsList.length; manualCrossIndex++) {
                    // console.log(Utils.detach(dataSets));
                    let aggregation = aggregationItems[manualAggregationsList[manualCrossIndex]]; //Shortcut
                    let result = this.unwrapManualAggregation(aggregation, dataSets, finalPassage);
                    // console.log(Utils.detach(aggregation));
                    // console.log(Utils.detach(result));

                    //Place the restructured item information in returning collection
                    if (!result.error) {
                        dataSets.push({
                            label: (aggregation.name ? aggregation.name : aggregation.defaultName),
                            isDefaultLabel: !aggregation.name,
                            target: aggregation.target,
                            representation: aggregation.type,
                            data: this.normalizeDataSet(Normalizations.getAllRepresentationNormalizations(aggregation), result.data),
                            dataFormat: result.format,
                            id: aggregation.id,
                            isCategorical: result.isCategorical,
                            zIndex: aggregation.zIndex,
                            visualizationOptions: aggregation.visualizationOptions,
                            show: Object.isUseful(aggregation.show) ? aggregation.show : true,
                            identifier: aggregation.identifier,
                        });
                    }
                    //If no errors or there is some error but all buckets this aggregation depends were already processed
                    //(unrecoverable error) remove agg from list since it has been processed. Otherwise, if some other
                    //agg is needed before processing this one, just retry it at next loop
                    if (!result.error || !result.missingBucket) {
                        manualAggregationsList.removeAt(manualCrossIndex);
                        manualCrossIndex--;
                        anyAggregationsProcessed = true;
                        finalPassage = false;
                    }
                }
                //If we didn't process anything do a last passage by ignoring missing buckets
                //(this might jump buckets misconfigurations) if also finalPassage doesn't return results we are done
                if (!anyAggregationsProcessed)
                    if (finalPassage)
                        break;
                    else finalPassage = true;
            }

            //Unwrap table groupings, since they use results from other queries we process
            // them after all other unwrappings and manual aggregations
            for (let tableGroupIndex = 0; tableGroupIndex < tableGroupingsList.length; tableGroupIndex++) {
                let aggregation = aggregationItems[tableGroupingsList[tableGroupIndex]]; //Shortcut
                let sourceTableDataSets = [];
                for (let bucket of aggregation.buckets) {
                    let dataSetIndex = dataSets.findIndex((dataSet) => dataSet.id === bucket);
                    if (dataSetIndex >= 0) {
                        let dataSet = dataSets[dataSetIndex];
                        dataSets.removeAt(dataSetIndex);
                        sourceTableDataSets.push(dataSet);
                    }
                }
                let tableDataSets = this.showAsTable(sourceTableDataSets, "@timestamp", false, aggregation["order"]);
                dataSets.push({
                    label: (aggregation.name ? aggregation.name : aggregation.defaultName),
                    isDefaultLabel: !aggregation.name,
                    target: aggregation.target,
                    representation: aggregation.type,
                    data: tableDataSets.values,
                    headers: tableDataSets.headers,
                    errors: tableDataSets.errors,
                    dataFormat: Defines.DatasetFormats.table,
                    id: aggregation.id,
                    isCategorical: false,
                    zIndex: aggregation.zIndex,
                    visualizationOptions: aggregation.visualizationOptions,
                    show: Object.isUseful(aggregation.show) ? aggregation.show : true
                });
            }

            //Unwrap category groupings, since they use results from other queries we process
            // them after all other unwrappings and manual aggregations
            while (Array.isUseful(categoryGroupingList)) {
                let sourceCategoryAggregationDataSets = [];
                let categoryGroupAggregation = aggregationItems[categoryGroupingList[0]];
                for (let categoryGroupIndex = 0; categoryGroupIndex < categoryGroupingList.length; categoryGroupIndex++) {
                    let aggregation = aggregationItems[categoryGroupingList[categoryGroupIndex]];
                    for (let bucket of aggregation.buckets) {
                        let dataSetIndex = dataSets.findIndex((dataSet) => dataSet.id === bucket);
                        if (dataSetIndex >= 0) {
                            let dataSet = dataSets[dataSetIndex];
                            dataSets.removeAt(dataSetIndex);
                            sourceCategoryAggregationDataSets.push(dataSet);
                        }
                        let dataSetsToRemove = [];
                        for (let [index, dataSet] of dataSets.entries()) {
                            if (dataSet.recursiveParentId === bucket) {
                                sourceCategoryAggregationDataSets.push(dataSet);
                                dataSetsToRemove.push(index);
                            }
                        }

                        for (let i = dataSets.length - 1; i >= 0; i--) {
                            if (dataSetsToRemove.includes(i)) {
                                dataSets.removeAt(i);
                            }
                        }

                    }
                    categoryGroupingList.removeAt(categoryGroupIndex);
                    categoryGroupIndex--;
                }

                let CategoryAggregationDataSets = this.aggregateByCategory(sourceCategoryAggregationDataSets, categoryGroupAggregation);
                dataSets.push({
                    label: (categoryGroupAggregation.name ? categoryGroupAggregation.name : categoryGroupAggregation.defaultName),
                    isDefaultLabel: !categoryGroupAggregation.name,
                    target: categoryGroupAggregation.target,
                    representation: categoryGroupAggregation.type,
                    data: CategoryAggregationDataSets,
                    headers: CategoryAggregationDataSets.headers,
                    errors: CategoryAggregationDataSets.errors,
                    dataFormat: Defines.DatasetFormats.xy,
                    id: categoryGroupAggregation.id,
                    identifier: categoryGroupAggregation.identifier,
                    isCategorical: true,
                    zIndex: categoryGroupAggregation.zIndex,
                    visualizationOptions: categoryGroupAggregation.visualizationOptions,
                    show: Object.isUseful(categoryGroupAggregation.show) ? categoryGroupAggregation.show : true
                });
            }

            //Unwrap functions
            for (let functionIndex = 0; functionIndex < functionItems.length; functionIndex++) {   //Loop on functions items and convert them to datasets
                let data = [];
                let func = functionItems[functionIndex]; //Shortcut

                if (func.type === Defines.allFunctions.value.id) {
                    if (!func.value)
                        continue;
                    data.push({x: Date.now(), y: func.value});
                } else {
                    if (!Array.isUseful(dataValues[func.queryId]))
                        continue;
                    //Loop through returned dataset, transform it and load into returning dataset
                    dataValues[func.queryId].forEach(item => {
                        if (Object.isUseful(item[func.type])) {
                            data.push({
                                x: (Object.isUseful(item["@timestamp"]) ? Date.parse(item["@timestamp"]) : 0),
                                y: item[func.type],
                                warning: item.Warning,
                                error: item.Error
                            });
                        }
                    });
                }

                dataSets.push(
                    {
                        label: (func.name ? func.name : func.defaultName),
                        isDefaultLabel: !func.name,
                        target: func.target,
                        representation: func.type,
                        data: this.normalizeDataSet(Normalizations.getAllRepresentationNormalizations(func), data),
                        dataFormat: Defines.DatasetFormats.xy,
                        isCategorical: false,
                        zIndex: func.zIndex,
                        visualizationOptions: func.visualizationOptions,
                        show: Object.isUseful(func.show) ? func.show : true
                    })
            }

            //Components
            //Make representatiosn for "Components" ' s children
            if (Array.isUseful(dataItemsComponents)) {
                for (itemIndex = 0; itemIndex < dataItemsComponents.length; itemIndex++) {   //Loop on data items and on each representation within them
                    if (!dataItemsComponents[itemIndex].representations)
                        continue;
                    if (Object.isDefined(dataItemsComponents[itemIndex].selectedForRootVisualization) && dataItemsComponents[itemIndex].selectedForRootVisualization) {
                        let tmpDataItemRoots = this.searchDataItems(dataItemsComponents[itemIndex].children, dataItemsComponents[itemIndex].name, false, "root", true);
                        let itemRootsIndex = 0;
                        tmpDataItemRoots.forEach(dataItem => {
                            let tokens = dataItem["root"].split(".");
                            dataItem.label = tokens[1] + "." + dataItem.name;
                            dataItem.component = true;
                            if (!Array.isUseful(dataItem.representations)) {
                                let representation = this.getDefaultDataRepresentation(dataItem);
                                representation.queryId = dataItems[dataItemsComponents[itemIndex].dataItemsIndex].representations[0].queryId;
                                dataItem.representations = [];
                                dataItem.representations.push(representation);
                            } else {
                                dataItem.representations[0].queryId = dataItemsComponents[itemIndex].representations[0].queryId;
                            }
                            dataItem.representations[0].zIndex = ((itemIndex + 1) * 900) + itemRootsIndex;
                            itemRootsIndex++;
                        });
                        if (Object.isUseful(tmpDataItemRoots)) {
                            dataItemRoots = dataItemRoots.concat(tmpDataItemRoots);
                        }
                    }
                }

                //unwrap data for root parent.Used for "Components"
                for (itemIndex = 0; itemIndex < dataItemRoots.length; itemIndex++) {   //Loop on data items and on each representation within them
                    if (!dataItemRoots[itemIndex].representations)
                        continue;
                    if (Object.isDefined(dataItemRoots[itemIndex].selectedForRootVisualization) && dataItemRoots[itemIndex].selectedForRootVisualization) {
                        dataItemRoots = this.searchDataItems(dataItemRoots[itemIndex].children, dataItemRoots[itemIndex].name, false, "root", true)
                        debugger
                        continue;
                    }
                    for (representationIndex = 0; representationIndex < dataItemRoots[itemIndex].representations.length; representationIndex++) {

                        let representation = dataItemRoots[itemIndex].representations[representationIndex]; //Shortcut
                        if (representation.queryId) { //Jump cross aggregation data items
                            let dataSet = this.getDataSet(representation, dataItemRoots[itemIndex], dataValues);
                            //Place the restructured item information in returning collection
                            if (dataSet.data.length > 0) {
                                dataSetsComponents.push(
                                    {
                                        label: dataItemRoots[itemIndex].label ?
                                            dataItemRoots[itemIndex].label :
                                            representation.defaultName,
                                        isDefaultLabel: !representation.name,
                                        target: representation.target,
                                        representation: representation.type,
                                        aggregationWindow: this.calculateAggregationWindow(representation),
                                        isCategorical: Defines.isCategoricalAggregation(representation.type),
                                        data: this.normalizeDataSet(Normalizations.getAllRepresentationNormalizations(representation), dataSet.data, dataItemRoots[itemIndex].root + "." + dataItemRoots[itemIndex].name),
                                        dataFormat: dataSet.format,
                                        id: representation.id,
                                        recursiveParentId: representation.recursiveParentId,
                                        identifier: dataItemRoots[itemIndex].root + "." + dataItemRoots[itemIndex].name,
                                        dataType: dataSet.type || representation.dataType,
                                        zIndex: representation.zIndex,
                                        visualizationOptions: representation.visualizationOptions,
                                        show: Object.isUseful(representation.show) ? representation.show : true,
                                        //This is to give user the warning that dataset was subsampled.
                                        //I know, it's terrible, yet it was too complex at moment to return information explictly from backend
                                        //since some subsampling logics are too nested. We will make it better in the future. At moment this is working just fine.
                                        //TODO get info from backend and remove this comment
                                        subSampled: representation.requestedMaxDataSize > 0 && (dataSet.data.length >= representation.requestedMaxDataSize * 0.95 && dataSet.data.length <= representation.requestedMaxDataSize * 1.05),
                                        error: dataSet.error ? dataSet.error : ""
                                    }
                                );
                            }
                        }
                    }
                }
            }

            if (dataSetsComponents.length > 0) {
                dataSets.push(...dataSetsComponents);
            }
            //Now remove all datasets with false show flag. If a dataset is returned it means that it was needed somewhere,
            //maybe as a cross aggregation argument. Now it's all done so don't show datasets can be dropped
            for (let i = dataSets.length - 1; i >= 0; i--) {
                if (!dataSets[i].show)
                    dataSets.removeAt(i);
            }

            //Done, all stuff was marshaled and ready for binding
            //return this.downSampleDataSets(this.orderDataSets(dataSets), maxDatasetLength); //Downsampling has been moved to backend
            return this.orderDataSets(dataSets);
        } finally {
            if (Config.debug && Object.isUseful(startTimes[queryIndex])) {
                console.log(new Date().format("HH:mm:ss fff") + " - query: " + queryIndex + " unwrapped in: " + (new Date() - startTimes[queryIndex]));
                delete startTimes[queryIndex];
            }
        }
    },
    getDataSet(representation, dataItem, dataValues) {

        let dataItemRoot = "";
        let dataItemName = "";
        let dataItemIndex = "";
        let dataItemIsComponent = false

        if (dataItem) {
            dataItemRoot = dataItem.root;
            dataItemName = dataItem.name;
            dataItemIndex = dataItem.index;
            dataItemIsComponent = Object.isUseful(dataItem.component) ? dataItem.component : dataItemIsComponent;
        }

        if (!Array.isUseful(dataValues[representation.queryId])) {//Data for current item was returned by query
            return {
                data: [],
                format: Defines.DatasetFormats.xy,
                error: (dataValues.errors && dataValues.errors.hasOwnProperty(representation.queryId) ? dataValues.errors[representation.queryId] : "")
            };
        }

        let data = [];

        //Queries on events (aka audits) index shall be converted to readable datasets, they are received as a full document record
        //we transform full dataset just once and mark it as transformed.
        if (dataItemIndex === "audit_trails") {
            if (Array.isUseful(dataValues[representation.queryId]) && !dataValues[representation.queryId][0].humanReadable) {
                Audits.formatAudits(dataValues[representation.queryId], false);
            }
        }

        dataValues[representation.queryId].forEach(item =>      //Loop through returned dataset, transform it and load into returning datatset
        {
            if (representation.queryId.startsWith("raw_") || representation.queryId.startsWith("agg_"))  //raw and aggregate results have the same format
            {
                let value = undefined;
                let warning = "";
                let error = "";
                try {
                    if (dataItemRoot !== "") {
                        //First case: data in the form root.name
                        if (Object.isUseful(item[dataItemRoot + "." + dataItemName])) {
                            value = item[dataItemRoot + "." + dataItemName];
                        } else {
                            //Second case: data in the form root: { name }
                            let root = item;
                            let dataItemNameTmp = "";
                            let tokens = dataItemRoot.split(".");
                            let tokensDepthParsed = 0
                            if (Object.isUseful(root[dataItemRoot])) {
                                root = root[dataItemRoot]
                            } else {
                                for (let index = 0; index < tokens.length; index++) {
                                    if (tokens[index] && Object.isUseful(root[tokens[index]])) {
                                        root = root[tokens[index]];
                                        dataItemNameTmp = Object.keys(root)[0];
                                        tokensDepthParsed++;
                                    }
                                }
                            }
                            //FN IF BE returns fewer values than variables then value is undefinied then skip the variable
                            if(dataItemIsComponent && tokens.length  !== tokensDepthParsed) {
                                // value = undefined;
                                return;
                            } else {
                                value = Object.isUseful(root[dataItemName]) ? root[dataItemName] : root[dataItemNameTmp];
                            }

                            if (Object.isUseful(root.Warning))
                                warning = root.Warning;
                            if (Object.isUseful(root.Error))
                                error = root.Error;
                        }

                    } else value = item[dataItemName];  //Third case: no root

                    //Push a new data point in dataset
                    if (Object.isUseful(value))
                        data.push({
                            x: (Object.isUseful(item["@timestamp"]) ? Date.parse(item["@timestamp"]) : 0),
                            y: value,
                            warning: warning,
                            error: error
                        });
                } catch (ex) {
                    debugger
                }
            } else if (representation.queryId.startsWith("cross_")) { //cross aggregate results have different formats
                //Push a new data point in dataset
                if (Object.isUseful(item["crossagg"])) {
                    data.push({
                        x: (Object.isUseful(item["@timestamp"]) ? Date.parse(item["@timestamp"]) : 0),
                        y: item["crossagg"]
                    });
                    //Also add partial results from source queries since cross aggregations supports
                    //referencing the source values in normalization scripts
                    for (let i = 1; ; i++) {
                        if (!Object.isUseful(item["q" + i]) && !Object.isUseful(item["q" + i + ".Warning"]) && !Object.isUseful(item["q" + i + ".Error"])) {
                            break
                        }
                        if (Object.isUseful(item["q" + i]))
                            data.last()["value" + i] = item["q" + i];
                        if (Object.isUseful(item["q" + i + ".Warning"]) && item["q" + i + ".Warning"] !== 'NoWarning') {
                            data.last()["warning"] = item["q" + i + ".Warning"]
                        }
                        if (Object.isUseful(item["q" + i + ".Error"]) && item["q" + i + ".Error"] !== 'NoError') {
                            data.last()["error"] = item["q" + i + ".Error"]
                        }
                    }
                }
            } else if (representation.queryId.startsWith("info_")) {
                let rootTokens = ""
                let varDesc = ""
                if (dataItemRoot !== "") {
                    rootTokens = dataItemRoot.split(".");
                    if (rootTokens.length > 1) {
                        varDesc = rootTokens.slice(1, rootTokens.length).join(".")
                        varDesc += "." + dataItemName
                    } else {
                        varDesc = dataItemName;
                    }
                }
                data.push({
                    x: (Object.isUseful(item["@timestamp"]) ? Date.parse(item["@timestamp"]) : 0),
                    y: item[rootTokens[0]][varDesc]
                })
            }
        });

        //Queries on TimeTracking.Activity are special, activity variable is not human readable, we need to explode results into a useful structs
        if (dataItemRoot === "TimeTracking" && dataItemName === "Activity") {
            let result = this.unwrapTimeTrackingObject(data, representation);
            if (result !== null)
                return result;
        }

        if (representation.type === Defines.allAggregations.countdistinct.id) {
            let returning = {data: [], format: Defines.DatasetFormats.xy};
            if (Array.isUseful(data)) {
                returning.data = data;
                for (let i = 0; i < returning.data.length; i++)
                    returning.data[i].y = returning.data[i].y.length;
            }
            return returning;
        }
        // Transform terms aggregations results to make them representable on bar graphs
        if (Defines.isCategoricalAggregation(representation.type)) {
            return this.unwrapTermsResult(data, representation.type);
        }

        return {data: data, format: Defines.DatasetFormats.xy};
    },

    unwrapTermsResult(data, type) {

        let returning = {data: [], format: Defines.DatasetFormats.categories};

        if (!Array.isUseful(data))
            return returning;

        for (const dataset of data) {
            if (Array.isUseful((dataset.y))) {
                for (const item of dataset.y) {
                    returning.data.push({
                        x: item.key,
                        y: item.doc_count,
                        category: item.key,
                        value: item.doc_count
                    })
                }
            }
        }

        return returning;
    },

    unwrapManualAggregation(aggregation, data, ignoreMissingBuckets) {

        let emptyReturn = {
            data: [],
            isCategorical: false,
            error: true,
            missingBucket: false,
            errorMessage: "",
            format: Defines.DatasetFormats.xy
        };
        let errorResponse = function (errorMessage) {
            let returning = Utils.detach(emptyReturn);
            returning.errorMessage = errorMessage;
            return returning;
        };
        if (!Array.isUseful(aggregation.buckets) || !aggregation.script)
            return emptyReturn;

        let dataSetsIndexes = [];
        let longestDatsSetLength = 0;
        let referenceDataSetIndex = 0;
        let isDataSetCategorical = false;
        //Collect the dataSets to be cross aggregated and find longest one for reference and eventual categorical data
        for (let bucket of aggregation.buckets) {
            let bucketFound = false;
            for (let [index, dataSet] of data.entries()) {
                if (dataSet.id === bucket) {
                    bucketFound = true;
                    if (Array.isUseful(dataSet.data)) {
                        dataSetsIndexes.push(index);
                        if (dataSet.data.length > longestDatsSetLength) {
                            longestDatsSetLength = dataSet.data.length;
                            referenceDataSetIndex = index;
                        }
                        if (dataSet.isCategorical)
                            isDataSetCategorical = true;
                    } else return emptyReturn; //All datasets must be populated
                    break;
                }
            }
            if (!bucketFound && !ignoreMissingBuckets) {
                return {error: true, missingBucket: true}
            }
        }

        //Let's keep it simple. Impose that datasets must have same cardinality and be of the same type
        //Only exception are length = 1 datasets that can be operate on all items of other arrays
        for (let dataSetIndex of dataSetsIndexes) {
            if (data[dataSetIndex].data.length !== 1 && data[dataSetIndex].data.length !== longestDatsSetLength)
                return errorResponse(VueInstance.get().$gettext("Incompatible dataset lengths"));
            if (data[dataSetIndex].data.length !== 1 && data[dataSetIndex].isCategorical !== isDataSetCategorical)
                return errorResponse(VueInstance.get().$gettext("Incompatible dataset cannot mix categorical and time-based datasets"));
        }

        //Datasets are homogeneous, start applying normalization to items one by one
        let compositeDataSet = [];
        for (let i = 0; i < longestDatsSetLength; i++) {
            //Get timestamp from reference dataset, ideally they should be identical on all datasets
            compositeDataSet.push({
                x: data[referenceDataSetIndex].data[i].x,
                y: ''
            });
            for (let j = 0; j < dataSetsIndexes.length; j++) {
                //If dataset is single value, replicate it on all samples, otherwise copy the correct one
                if (data[dataSetsIndexes[j]].data.length === 1)
                    compositeDataSet.last()["value" + (j + 1)] = data[dataSetsIndexes[j]].data[0].y;
                else
                    compositeDataSet.last()["value" + (j + 1)] = data[dataSetsIndexes[j]].data[i].y;
            }
        }
        let normalizedDataSet = this.normalizeDataSet([Normalizations.getCustomNormalization(aggregation.script)], compositeDataSet);
        if (isDataSetCategorical) {
            for (let i = 0; i < normalizedDataSet.length; i++) {
                normalizedDataSet[i].category = normalizedDataSet[i].x;
                normalizedDataSet[i].value = normalizedDataSet[i].y;
            }
        }
        return {
            data: normalizedDataSet,
            isCategorical: isDataSetCategorical,
            error: false,
            format: Defines.DatasetFormats.xy
        }
    },

    unwrapBucketsResult(aggregation, dataValues, dataItems) {

        // {
        //     "@timestamp": "2020-05-04T13:50:10.000+02:00",
        //     "tag": {
        //     "Device": [
        //         {
        //             "Line": {
        //                 "RecipeId": [
        //                     {
        //                         "key": "Demo recipe",
        //                         "print-and-check": {
        //                             "EstimatedSpeed": null
        //                         }
        //                     }
        //                 ]
        //             },
        //             "key": "NoDev"
        //         },
        //         {
        //             "Line": {
        //                 "RecipeId": [
        //                     {
        //                         "key": "Demo recipe",
        //                         "print-and-check": {
        //                             "EstimatedSpeed": 325.1297071129707
        //                         }
        //                     }
        //                 ]
        //             },
        //             "key": "print-and-check"
        //         }
        //     ]
        // }
        // },
        let returning = {data: [], format: Defines.DatasetFormats.buckets, bucketsNames: []};
        let data = [];

        if (Array.isUseful(dataValues[aggregation.queryId])) {  //Data for current item was returned by query
            data = dataValues[aggregation.queryId].last(); //At moment do not consider timestamp since we do not support double axis visualizations
            delete data.key //For the same reason as before, remove the outermost key property as it is the timestamp of the time bucket and would cause troubles in the unwrapper
        } else return [];

        let bucketsCrawler = function (bucketIndex, rootValue, dataItems, data, self, keyNormalization) {
            self.forEachRepresentation(dataItems, (representation, dataIndex) => {
                if (representation.id === aggregation.buckets[bucketIndex]) {
                    if (returning.bucketsNames.length < aggregation.buckets.length)
                        returning.bucketsNames.push(representation.name);
                    //Get next leaf data object
                    let value = null;
                    if (dataItems[dataIndex].root)
                        value = data[dataItems[dataIndex].root][dataItems[dataIndex].name];
                    else value = data[dataItems[dataIndex].name];
                    //Update nested key value
                    if (Object.isUseful(data.key)) {
                        //Concatenate keys with a splittable placeholder to allow subsequent splitting and reconstruction
                        //In the first version we tried rootValue as array but was causing problems because of array sharing
                        rootValue += ((rootValue ? "~|~" : "") + self.normalizeSingleValue(keyNormalization, data.key));
                    }
                    if (bucketIndex === 0) {
                        //In the last leaf, write values
                        if (Object.isUseful(value)) {
                            let rootTokens = rootValue.split("~|~");
                            let rootString = rootTokens.join(" - ");
                            let normalized = self.normalizeSingleValue(Normalizations.getAllRepresentationNormalizations(representation), value);
                            returning.data.push({
                                x: rootString,
                                y: normalized,
                                category: rootString,
                                value: normalized,
                                rawValue: rootTokens.concat(normalized)
                            });
                        }
                    } else {
                        //Walk through next leaf
                        if (Array.isUseful(value)) {
                            for (let val of value)
                                bucketsCrawler(bucketIndex - 1, rootValue, dataItems, val, self, Normalizations.getAllRepresentationNormalizations(representation));
                        }
                    }
                }
            });
        };

        bucketsCrawler(aggregation.buckets.length - 1, "", dataItems, data, this, "");

        // if(type === Defines.allAggregations.histogram.id)
        //     returning = returning.sortOnProperty("category");

        return returning;
    },

    //TODO move this stuff to time tracking
    unwrapTimeTrackingObject(data, representation) {
        let includeInactiveResults = true;
        if (Object.isNestedPropertyUseful(representation, "visualizationOptions", "includeInactiveResults"))
            includeInactiveResults = representation.visualizationOptions.includeInactiveResults;
        if (representation.type === Defines.allAggregations.ttsurvey.id)
            return this.unwrapTimeTrackingSurvey(data, includeInactiveResults);
        else if (representation.type === Defines.allAggregations.ttranking.id)
            return this.unwrapTimeTrackingRanking(data, includeInactiveResults);
        else if (representation.type === Defines.allAggregations.ttlog.id)
            return this.unwrapTimeTrackingLog(data);
        else if (Defines.isRawAggregation(representation.type))
            return this.unwrapTimeTrackingValue(data);
        else return null;
    },

    unwrapTimeTrackingValue(data) {
        let returning = {data: [], format: Defines.DatasetFormats.xy, type: Defines.avionicsDataTypes.text.id};
        if (Array.isUseful(data)) {
            for (let point of data) {
                let tracker = TimeTracking.getTimeTrackerFromId(point.y);
                returning.data.push({
                    x: point.x,
                    tracker: tracker,
                    activity: point.y,
                    y: Array.isUseful(tracker) ? tracker.join(' -> ') : ""
                });
            }
        }
        return returning;
    },

    unwrapTimeTrackingLog(data) {
        return {data: TimeTracking.getTimeTrackingInfo(data, false), format: Defines.DatasetFormats.ttlog};
    },

    unwrapTimeTrackingSurvey(data, includeInactiveResults = true) {
        let timeTracking = [
            {show: VueInstance.get().$gettext("Total workorder duration"), duration: 0},
            {show: VueInstance.get().$gettext("Idle time"), duration: 0},
            {show: VueInstance.get().$gettext("Production"), duration: 0},
            {show: VueInstance.get().$gettext("Uncategorized stops"), duration: 0}
        ];

        let initNode = function (node) {
            node.duration = 0;
            node.eventsCount = 0;
            node.averageDuration = 0;
        };

        let categories = TimeTracking.getFilteredLineStopCauses(includeInactiveResults);
        if (Object.isUseful(categories)) {
            for (let category of categories) {
                initNode(category);
                if (category.children) {
                    for (let subCategory of category.children) {
                        initNode(subCategory);
                        if (subCategory.children) {
                            for (let subSubCategory of subCategory.children) {
                                initNode(subSubCategory);
                                if (subSubCategory.children) {
                                    for (let subSubSubCategory of subSubCategory.children)
                                        initNode(subSubSubCategory);
                                }
                            } // end for
                        }
                    }
                }
                timeTracking.push(category);
            }
        }

        if (!Array.isUseful(data)) {
            return {data: timeTracking, format: Defines.DatasetFormats.ttsurvey};
        }

        for (const dataset of data) {
            if (Array.isUseful(dataset.y)) {
                for (const item of dataset.y) {
                    let category = Math.floor(item.key / 1000000);
                    let activity = Math.floor((item.key % 1000000) / 10000);
                    let subActivity = Math.floor((item.key % 10000) / 100);
                    let subSubActivity = item.key % 100;
                    if (category === 0) {
                        if (subSubActivity !== 0) //Not Idle line time -> increase total workorder duration
                            timeTracking[0].duration += item.doc_count * 5;
                        if (subSubActivity < 4) {
                            timeTracking[subSubActivity + 1].duration += item.doc_count * 5;
                            let stats = this.calcTimeTrackingExtendedStats(item);
                            if (stats) {
                                timeTracking[subSubActivity + 1].eventsCount = stats.eventsCount;
                                timeTracking[subSubActivity + 1].averageDuration = stats.averageDuration;
                            }
                        }
                    } else {
                        timeTracking[0].duration += (item.doc_count * 5);   //Everything that is not idle time also increases workorder duration
                        let children = this.setDuration(timeTracking, category, item);
                        children = this.setDuration(children, activity, item);
                        children = this.setDuration(children, subActivity, item);
                        this.setDuration(children, subSubActivity, item);
                    }
                }
            }
        }
        return {data: timeTracking, format: Defines.DatasetFormats.ttsurvey};
    },

    unwrapTimeTrackingRanking(data, includeInactiveResults) {

        let timeTracking = [];

        for (const dataset of data) {
            if (Array.isUseful(dataset.y)) {
                for (const item of dataset.y) {
                    let tracker = TimeTracking.getTimeTrackerFromId(item.key, includeInactiveResults);
                    if (Array.isUseful(tracker)) {
                        timeTracking.push({
                            tracker: tracker,
                            category: tracker.join(' -> '),
                            x: tracker.join(' -> '),
                            value: item.doc_count * 5,
                            y: item.doc_count * 5,
                            duration: item.doc_count * 5
                        });
                        let stats = this.calcTimeTrackingExtendedStats(item);
                        if (stats) {
                            timeTracking.last().eventsCount = stats.eventsCount;
                            timeTracking.last().averageDuration = stats.averageDuration;
                        }
                    }
                }
            }
        }

        return {data: timeTracking, format: Defines.DatasetFormats.ttranking};
    },

    setDuration(items, id, item) {
        if (id > 0 && Array.isUseful(items)) {
            for (let index = 0; index < items.length; index++) {
                if (Object.isUseful(items[index].id) && items[index].id === id) {
                    items[index].duration += item.doc_count * 5;
                    let stats = this.calcTimeTrackingExtendedStats(item);
                    if (stats) {
                        items[index].eventsCount += stats.eventsCount;
                        if (items[index].eventsCount)
                            items[index].averageDuration = items[index].duration / items[index].eventsCount;
                    }
                    if (Array.isUseful(items[index].children))
                        return items[index].children;
                    // else {
                    //     let stats = this.calcTimeTrackingExtendedStats(item);
                    //     if(stats) {
                    //         items[index].eventsCount = stats.eventsCount;
                    //         items[index].averageDuration = stats.averageDuration;
                    //     }
                    // }
                    break;
                }
            }
        }
        return null;
    },

    calcTimeTrackingExtendedStats(item) {
        if (Object.isNestedPropertyUseful(item, "TimeTracking", "StopDuration") && item.TimeTracking.StopDuration > 0) {
            let extendedStats = {eventsCount: item.TimeTracking.StopDuration};
            extendedStats.averageDuration = (item.doc_count * 5) / extendedStats.eventsCount;
            return extendedStats;
        }
        return null;
    },

    normalizeDataSet(normalizations, data, itemIdentifier = "") {
        if (!Array.isUseful(normalizations) || !Array.isUseful(data))
            return data;

        for (let normalization of normalizations) {
            let expression = Normalizations.resolve(normalization);
            if (!expression.includes("value"))
                expression = "value" + expression;
            for (let i = 0; i < data.length; i++) {
                let temp = expression;
                for (let j = 1; ; j++) {
                    if (Object.isUseful(data[i]["value" + j]))
                        temp = temp.replace(new RegExp("value" + j, "g"), data[i]["value" + j]);
                    else break;
                }

                let value = data[i].y;

                if (normalization.sourceValue === "identifier") {
                    value = itemIdentifier
                } else if (normalization.sourceValue === "x") {
                    value = data[i].x;
                }

                if (normalization.applyTo === "x") {
                    data[i].x = this.normalizeValue(temp, value);
                } else {
                    data[i].y = this.normalizeValue(temp, value);
                }
            }
        }

        return data;
    },
    normalizeSingleValue(normalizations, data) {
        let normalized = this.normalizeDataSet(normalizations, [{y: data}]);
        if (Array.isUseful(normalized))
            return normalized[0].y;
        return data;
    },
    normalizeValue(expression, value) {

        if (value && typeof value === 'string')
            value = "'{0}'".format(value);  //Make strings appear as strings in expressions (with quotes around)

        expression = expression.replace(/value/g, value);
        try {
            return eval(expression);
        } catch (ex) {
            //console.error(ex);
            debugger
            if (value)
                return value;
            else return expression;
        }
    },

    showAsTable(dataValues, keyHeaderName = "", excludeKeyColumn = false, sort = "") {
        //TODO review comment
        //On data tables we reprocess results to obtain the following:
        //- datasets may come from different indices, different documents, different aggregations. We cannot expect to have plain records with common timestamps on all fields
        //- we create a single timestamp column as key using the longest time series available
        //- reprocess all other datasets to place values on correct rows using the reference dataset as clock source.
        //- in case of aggregations a single value may span many values of a raw query, in this case assign the same value to many records spanned by the aggregation window
        //Find the longest timestamped dataset to use as clock source

        if (sort === "ascending")
            sort = false;
        else sort = true;

        let returning = {
            headers: [],
            values: [],
            errors: []
        };

        let dataSetsToBeRemoved = [];
        let incompatibleDataSets = [];

        let dataSetFormat = "";
        let keys = [];
        let referenceDataSetIndex = 0;
        let referenceDataSetLength = 0;
        //First of all have a look into datasets to verify dataSet type and compatibility
        //While looping also extract some dataset geometry like longest dataSet and table key
        for (let [index, dataSet] of dataValues.entries()) {
            if (!dataSet.show) { //Jump datasets marked invisible
                dataSetsToBeRemoved.push(index);
                continue;
            }
            if (Array.isUseful(dataSet.data)) {
                if (Object.isUseful(dataSet.data[0].x) || Object.isUseful(dataSet.data[0].start)) {
                    //We treat categories and buckets in the same way
                    if (dataSet.dataFormat === "categories" || dataSet.dataFormat === "buckets") {
                        dataSet.rawDataFormat = dataSet.dataFormat;
                        dataSet.dataFormat = Defines.DatasetFormats.categories;
                    }
                    //Check dataSet format compatibility
                    if (dataSetFormat && dataSet.dataFormat !== dataSetFormat) {
                        dataSetsToBeRemoved.push(index);
                        incompatibleDataSets.push(dataSet);
                        continue;
                    }
                    dataSetFormat = dataSet.dataFormat;
                    if (dataSet.data.length > referenceDataSetLength) {
                        referenceDataSetIndex = index;
                        referenceDataSetLength = dataSet.data.length;
                    }
                }
            }
        }
        //Signal format incompatibilities
        if (Array.isUseful(incompatibleDataSets)) {
            let removed = incompatibleDataSets.map((dataSet) => {
                return dataSet.label
            }).join(", ");
            returning.errors.push(VueInstance.get().$gettext("Dataset {0} not compatible with other datasets").format(removed));
        }
        //Remove ignored stuff
        for (let index of dataSetsToBeRemoved)
            dataValues.removeAt(index);

        if (referenceDataSetLength === 0) {
            //Show a not representable error since only available datasets were not representable
            if (Array.isUseful(incompatibleDataSets))
                returning.errors.push(VueInstance.get().$gettext("Selected dataset is not representable on a table"));
            return returning;
        }
        let dataPropertyName = "y";
        let keyPropertyName = "x";

        let starts = [];
        let stops = [];
        let durations = [];

        //Extract keys array
        if (dataSetFormat === Defines.DatasetFormats.ttlog) {
            returning.headers.push({
                value: "start",
                text: "Start",
                sortable: false,
                isSortable: true,
                sorted: 'none',
                align: 'left',
                class: "text-xs-left subheading"
            });
            returning.headers.push({
                value: "stop",
                text: "Stop",
                sortable: false,
                isSortable: true,
                sorted: 'none',
                align: 'left',
                class: "text-xs-left subheading"
            });
            returning.headers.push({
                value: "duration",
                text: "Duration",
                sortable: false,
                isSortable: true,
                sorted: 'none',
                align: 'left',
                class: "text-xs-left subheading"
            });
            starts = dataValues[referenceDataSetIndex].data.map((value) => value.start);
            keys = starts;
            stops = dataValues[referenceDataSetIndex].data.map((value) => value.stop);
            durations = dataValues[referenceDataSetIndex].data.map((value) => value.duration);
            dataPropertyName = "show";
            keyPropertyName = "start";
        } else {
            keys = dataValues[referenceDataSetIndex].data.map((value) => value.x);
            if (dataSetFormat === Defines.DatasetFormats.xy && !keyHeaderName)
                keyHeaderName = "Timestamp"
        }
        //Create key column header
        if (!excludeKeyColumn && dataSetFormat !== Defines.DatasetFormats.ttlog)
            returning.headers.push({
                value: "key",
                text: keyHeaderName,
                sortable: false,
                isSortable: true,
                sorted: 'none',
                align: 'left',
                class: "text-xs-left subheading"
            });


        for (let valueIndex = 0; valueIndex < dataValues[referenceDataSetIndex].data.length; valueIndex++)
            returning.values.push({});

        //Now process all dataset in the order defined by user
        for (let [dataSetIndex, dataSet] of dataValues.entries()) {

            let header = {
                value: dataSet.id,
                text: dataSet.label,
                sortable: false,
                isSortable: true,
                sorted: 'none',
                align: 'left',
                class: "text-xs-left subheading"
            };
            //Manage special targets, these are only handled by widgetDataTable
            let isGlobalParam = false;
            if (dataSet.target === "globalparams") {
                header.value = dataSet.target;
                let tmpHeader = returning.headers.find(header => {
                    return (header.value === dataSet.target)
                });
                if (!Object.isUseful(tmpHeader))
                    returning.headers.push(header);
                isGlobalParam = true;
            }
            let isRowEvidence = false;
            if (dataSet.target === "rowevidence") {
                header.value = dataSet.target;
                header.text = "";
                let tmpHeader = returning.headers.find(header => {
                    return (header.value === dataSet.target)
                });
                if (!Object.isUseful(tmpHeader))
                    returning.headers.unshift(header); //TODO check this unshift
                isRowEvidence = true;
            }
            if (!isGlobalParam && !isRowEvidence) {
                returning.headers.push(header);
            }

            let singleValue = null;
            if (!Array.isUseful(dataSet.data))
                singleValue = "";

            //Raw datasets must match the keys otherwise we create new record with its own key and value
            for (let [sampleIndex, sample] of dataSet.data.entries()) {
                let indexOfKey = keys.indexOf(sample[keyPropertyName], sampleIndex);
                if (sampleIndex > 0 && indexOfKey < 0) {
                    indexOfKey = keys.indexOf(sample[keyPropertyName])
                }
                if (indexOfKey >= 0) {
                    if (isRowEvidence) {
                        returning.values[indexOfKey][dataSet.target] = sample[dataPropertyName];
                    } else {
                        if (dataSetFormat === Defines.DatasetFormats.ttlog) {
                            starts.push(sample.start);
                            stops.push(sample.stop);
                            durations.push(sample.duration);
                        }
                        returning.values[indexOfKey][dataSet.id] = DateTimeUtils.convertDateTime(sample[dataPropertyName]);
                        if (isGlobalParam)
                            returning.values[indexOfKey][dataSet.target] = this.getGlobalParams(returning.values[indexOfKey][dataSet.target], sample[dataPropertyName], dataSet.identifier);
                    }
                } else {
                    keys.push(sample[keyPropertyName]);
                    returning.values.push({});
                    returning.values.last()[dataSet.id] = DateTimeUtils.convertDateTime(sample[dataPropertyName]);
                    if (isGlobalParam) {
                        returning.values.last()[dataSet.target] = this.getGlobalParams(returning.values.last()[dataSet.target], sample[dataPropertyName], dataSet.identifier);
                    }
                }
            }

            // //Aggregation dataset won't match the timestamps of raw data we replicate each value
            // //on all rows with timestamp that is within its aggregation window
            // else {
            //     //Simple case, if only one result the aggregation is full window, just replicate value on all rows
            //     if (dataSet.data.length === 1 && !dataSet.isCategorical) {
            //         singleValue = this.convertDateTime(dataSet.data[0].y);
            //     }
            //     //time bucketed aggregations values are distributed on all rows within their aggregation window
            //     else if (!dataSet.isCategorical) {
            //         for (let sampleIndex = 0 ; sampleIndex < dataSet.data.length - 1 ; sampleIndex++) {
            //             for (let timeIndex = 0; timeIndex < timestamps.length; timeIndex++) {
            //                 if (timestamps[timeIndex] >= dataSet.data[sampleIndex].x && timestamps[timeIndex] <= dataSet.data[sampleIndex + 1].x) {
            //                     this.values[timeIndex][dataSet.label] = this.convertDateTime(dataSet.data[sampleIndex].y);
            //                     if (isGlobalParam) {
            //                         this.values[timeIndex][dataSet.target] = this.getGlobalParams(this.values[timeIndex][dataSet.target], this.convertDateTime(dataSet.data[sampleIndex].y), dataSet.identifier);
            //                     }
            //                 }
            //             }
            //         }
            //     }
            //     //Non representable datasets
            //     else {
            //         singleValue = VueInstance.get().$gettext("Error: Not representable")
            //     }
            // }

            // //In case of dataSets either empty or full window aggregate assign the same value to all records
            // if (Object.isUseful(singleValue))
            //     for (let valueIndex = 0; valueIndex < this.values.length; valueIndex++) {
            //         this.values[valueIndex][dataSet.label] = this.convertDateTime(singleValue);
            //         if (isGlobalParam) {
            //             this.values[valueIndex][dataSet.target] = this.getGlobalParams(this.values[valueIndex][dataSet.target], this.convertDateTime(singleValue), dataSet.identifier);
            //         }
            //     }

            //this.values.last()[dataValues[referenceDataSetIndex].label] = this.convertDateTime(dataValues[referenceDataSetIndex].data[valueIndex].y);

        }
        for (let valueIndex = 0; valueIndex < returning.values.length; valueIndex++) {
            if (dataSetFormat === Defines.DatasetFormats.ttlog) {
                returning.values[valueIndex]["start"] = new Date(starts[valueIndex]).format();
                returning.values[valueIndex]["stop"] = new Date(stops[valueIndex]).format();
                returning.values[valueIndex]["duration"] = new Date(durations[valueIndex]).toISOString().substr(11, 8);
                returning.values.sortOnProperty("start", sort);
            } else {
                if (dataSetFormat === Defines.DatasetFormats.xy)
                    returning.values[valueIndex]["key"] = new Date(keys[valueIndex]).format();
                else
                    returning.values[valueIndex]["key"] = DateTimeUtils.convertDateTime(keys[valueIndex]);
                returning.values.sortOnProperty("key", sort);
            }
        }
        return returning;
    },

    aggregateByCategory(dataValues, aggregation) {
        let dataSet = [];
        for (let item of dataValues) {
            if (!isNaN(item.data[0].y)) {
                //FN added the property absolute that is calculated on the y
                item.data[0].absolute = Math.abs(item.data[0].y);
            }
            dataSet.push(item.data[0]);
        }

        if (Array.isUseful(dataSet)) {
            switch (aggregation.orderBy) {
                case Defines.OrderByItems.categories:
                    dataSet.sortOnProperty("x", Defines.OrderItems.descending === Defines.OrderItems[aggregation.order], true);
                    break;
                case  Defines.OrderByItems.values:
                    dataSet.sortOnProperty("y", Defines.OrderItems.descending === Defines.OrderItems[aggregation.order]);
                    break;
                case  Defines.OrderByItems["absoluteValues"]:
                    dataSet.sortOnProperty("absolute", Defines.OrderItems.descending === Defines.OrderItems[aggregation.order]);
                    break;
            }
        }

        return dataSet;
    },

    getGlobalParams(current, value, key) {
        if (!current) {
            current = {};
        }
        current[key] = value;
        return current;
    },

    dataItemsValidator(dataItems, visualizationTargets) {
        let anySuggestion = false;
        let maxAggregationsWindow = [];
        let minutes = [
            1, 2, 5, 10, 15, 30,
            60, 120, 240, 480, 720,
            1440, 2880, 7200, 10080, 43200, 86400, 129600, 259200,
            525600, 1051200, 1576800, 2628000];
        dataItems.forEach((item, index) => {
            item.representations.forEach(representation => {
                if (Array.isUseful(representation.suggestions))
                    representation.suggestions = [];
                //if aggregation window is not auto or full time window
                if (representation.aggregationWindowUnit === 's' || representation.aggregationWindowUnit === 'm' || representation.aggregationWindowUnit === 'h' || representation.aggregationWindowUnit === 'd') {
                    let aggregationWindow = representation.aggregationWindow
                    switch (representation.aggregationWindowUnit) {
                        case "s":
                            aggregationWindow = parseInt(aggregationWindow) / 60;
                            break
                        case "m":
                            parseInt(aggregationWindow)
                            break
                        case "h":
                            aggregationWindow = parseInt(aggregationWindow) * 60;
                            break
                        case "d":
                            aggregationWindow = parseInt(aggregationWindow) * 1440;
                            break
                    }
                    let maxAggregationWindow = aggregationWindow * 3000
                    //check if current AggregationWindow is bigger than maxAggregationWindow
                    let index = minutes.findIndex((element) => element > maxAggregationWindow);
                    if (index > 0) {
                        //add suggestion
                        if (Array.isUseful(representation.suggestions)) {
                            representation.suggestions.push({
                                target: "aggregationWindow",
                                message: VueInstance.get().$gettext("Your query could generate too many points if the time window is bigger than {0}. You can set aggregation window to auto or you can increase aggregation step.").format(DateTimeUtils.secondsToString(minutes[index - 1] * 60))
                            })
                            anySuggestion = true;
                        }
                        maxAggregationsWindow.push(minutes[index - 1]);
                    }
                }
                //aggregation type validation
                if (representation.type === 'raw') {
                    let visualizationTarget = visualizationTargets.find(target => target.id === representation.target)
                    if (visualizationTarget && visualizationTarget.expectsSingleValue) {
                        representation.suggestions.push({
                            target: "singleValueSuggestion",
                            message: VueInstance.get().$gettext("It's useless to choose raw aggregation. As you selected '{0}' that expects a single value, we suggest you to use 'First Value' or 'Last Value'").format(visualizationTarget.show)
                        })
                        anySuggestion = true
                    }
                }
                // validate if data type is string and user is using 'Data Changes' or 'Data Intervals'
                if (representation.dataType === 'text' && (representation.type === Defines.allAggregations.raw.id)) {
                    representation.suggestions.push({
                        target: "stringValueSuggestion",
                        message: VueInstance.get().$gettext("For strings we suggest to use 'Data Changes' or 'Data Intervals' aggregation instead of {0}").format(representation.type)
                    })
                    anySuggestion = true
                }
                // filtersFromDifferentIndexForbidden
                if (item.filtersFromDifferentIndex && Array.isUseful(item.filtersFromDifferentIndex)) {
                    let forbiddenFilters = item.filtersFromDifferentIndex.filter(f => !f.filterFound);
                    if (Array.isUseful(forbiddenFilters)) {
                        let filtersMessage = '';
                        forbiddenFilters.forEach(filter => {
                            let filterName = filter.filter.root ? filter.filter.root + '.' + filter.filter.name : filter.filter.name;
                            filtersMessage += ' ' + filterName;
                        })
                        //TODO check: should propertiy suggestions exist at all?
                        if (!Array.isUseful(representation.suggestions)) {
                            representation.suggestions = [];
                        }
                        representation.suggestions.push({
                            target: "filtersFromDifferentIndexForbidden",
                            message: VueInstance.get().$gettext("Some filters are not available for this variable since they are not in the target index. " +
                                "Restricted filters: {0}").format(filtersMessage)
                        });
                        anySuggestion = true
                    }
                }

            })
        })

        return {
            hasSuggestions: anySuggestion,
            maxAggregationWindow: Array.isUseful(maxAggregationsWindow) ? Math.min(...maxAggregationsWindow) : 0
        }
    },

    checkDataTypeAggregation(dataType) {
        return !(dataType === Defines.allAggregations.raw.id
            || dataType === Defines.allAggregations.intervals.id || dataType === Defines.allAggregations.changes.id
            || dataType === Defines.allAggregations.first.id || dataType === Defines.allAggregations.last.id
            || dataType === Defines.allAggregations.ttlog.id || dataType === Defines.allAggregations.oeeraw.id
            || dataType === Defines.allAggregations["variable.index"].id || dataType === Defines.allAggregations["variable.root"].id
            || dataType === Defines.allAggregations["variable.name"].id);
    },

    checkAggregationTypeAggregation(aggregationType) {
        return aggregationType === Defines.allAggregations.avg.id
            || aggregationType === Defines.allAggregations.sum.id
            || aggregationType === Defines.allAggregations.min.id
            || aggregationType === Defines.allAggregations.max.id;
    },
}


