<template>
    <v-container id="recipeConfigurator" ref="recipeConfigurator" class="ma-0 pa-0 page-container" style="margin-left: 4px!important; padding-right:8px!important; max-width:100% !important;">
        <av-split-pane v-if="recipeType==='template'" style="overflow: hidden; width: 100%" :min-percent='10' :default-percent='30' split="vertical">
            <template slot="paneL">
                <v-card class="ma-0 pa-0 av-card" elevation="0" style="height: calc(100% - 8px); overflow: auto; margin-right: 4px!important;" >
                    <v-card-actions v-if="recipeType === 'template'">
                        <v-layout column>
                            <av-multi-select class="mt-3" v-model="filterByFlows" :label="$gettext('Filter by Flows')"
                                             item-text="Show" :placeholder="$i18n.CommonAttributes()['select']"
                                             :items="flows"></av-multi-select>
                            <av-button :text="addBtnText" style="float: right;" flat @click="addStep()" id="AddBtn" size="medium" iconColor="info" buttonIcon="fas fa-plus" />

                        </v-layout>
                    </v-card-actions>
                    <v-treeview flat :items="filteredStep" class="title pa-0" style="margin-left: -16px" v-if="Array.isUseful(recipe)"  id="Steps" @change="tab=1">
                        <template v-slot:prepend="{ item }">
                            <template>
                                <av-button iconColor="info" buttonIcon="fas fa-edit" fab small flat :dynamicID="item.name"
                                           @click="edit(item)" :toolTip="$gettext('Edit configuration')"
                                           class="my-0" :style="Array.isUseful(item.configuration) ? 'margin-left: 8px' : ''"
                                           :disabled="moveItemToEnabled"/>
                                <av-button iconColor="info" buttonIcon="fas fa-copy" fab small flat
                                           @click="cloneStep(item)" style="margin-left: -8px" class="my-0"
                                           :toolTip="$gettext('Clone step')" :disabled="moveItemToEnabled"/>
                                <av-button iconColor="error" buttonIcon="fas fa-trash" fab small flat :dynamicID="item.name"
                                           @click="deleteStep(item)" style="margin-left: -8px" class="my-0"
                                           :toolTip="$gettext('Delete field (and sub-fields)')" :disabled="moveItemToEnabled"/>
                                <av-button v-if="moveItemToEnabled" iconColor="info" buttonIcon="fas fa-arrow-right" fab small flat :dynamicID="item.name"
                                           @click="moveItem(itemToMove,recipe.findItem(item))" style="margin-left: -8px" class="my-0"
                                           :toolTip="$gettext('Move item here')" />
                            </template>
                        </template>
                        <template v-slot:append="{item}">
                            <v-icon color="info" small :toolTip="$gettext('Move one step up')"
                                    @click="itemUp(item)" v-if="recipe.findIndex(r=>{return r===item})>0" class="ml-2"
                                    :disabled="moveItemToEnabled">fas fa-arrow-up</v-icon>
                            <v-icon color="info" small :toolTip="$gettext('Move one step down')"
                                    @click="itemDown(item)" v-if="recipe.findIndex(r=>{return r===item})<recipe.length-1" class="ml-2"
                                    :disabled="moveItemToEnabled">fas fa-arrow-down</v-icon>
                            <v-icon color="info" small :toolTip="$gettext('Move step')" :disabled="recipe.findItem(item)!==recipe.findItem(itemToMove) && moveItemToEnabled"
                                    class="ml-2" @click="enableMoveItem(item)" v-if="recipe.length>0">fas fa-sort-alt</v-icon>

                        </template>
                        <template v-slot:label="{item}">
                            <span :style="{opacity:recipe.findItem(item)!==recipe.findItem(itemToMove) && moveItemToEnabled ?'0.5': isDisabled(item)?'0.7':'1'}">{{item.name}}</span>
                        </template>
                    </v-treeview>
                </v-card>
            </template>
            <template slot="paneR">
                <v-card class="ma-0 pa-2 av-card" elevation="0" style="height: calc(100% - 10px); overflow: auto; margin-left: 4px!important" >
                    <template v-if="selectedItem">
                        <v-layout row>
                            <v-flex xs4>
                                <av-select class="mt-3" :label="$gettext('Type')"
                                           item-text="show" :placeholder="$i18n.CommonAttributes()['select']"
                                           :items="configurationTypes" v-model="selectedItem.type" @change="onChangeStepType" >
                                </av-select>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex xs7>
                                <av-text-field class="mt-3" v-model="selectedItem.name" :label="$gettext('Name')"></av-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <av-text-field v-model="selectedItem.description" :label="$gettext('Description')"></av-text-field>
                            <v-flex xs3 class="pr-2 pt-2 text-xs-right" style="font-size: 1.2em">
<!--                                <v-switch  :label="$gettext('Disable all Values')" :value="isDisabled(selectedItem)" @click="enableDisableAllValues(selectedItem,!isDisabled(selectedItem))"></v-switch>-->
                                <v-icon @click="enableDisableAllValues(selectedItem,!isDisabled(selectedItem))" color="info">
                                    {{isDisabled(selectedItem) ? 'far fa-check-square':'far fa-square' }}</v-icon>
                                {{ isDisabled(selectedItem) ? $gettext('All Values are disabled') : $gettext('Disable all Values')}}
                            </v-flex>

                        </v-layout>
                        <v-card class="pa-0 ma-0" elevation="0" style="border-radius: 5px; border: 1px solid lightgrey; " v-if="selectedItem.type!==''">
                            <v-card-text :style="styleForm">
                                <v-tabs v-model="tab" v-if="tabsVisible" style="overflow-y: auto; height: calc(100%);">
                                    <v-tab>
                                        Variable
                                    </v-tab>
                                    <v-tab>
                                        Values
                                    </v-tab>
                                    <v-tabs-items  class="pt-1" >
                                        <v-tab-item>
                                            <v-layout column class="pt-2">
                                                <av-button v-if="Array.isUseful(opcMachineModel) && !isModbus <0" :text="$gettext('Get from machine model')"
                                                           @click="getOpcVariableDialog = true"
                                                           buttonIcon="fa fa-cogs" iconColor="white" color="info" size="medium" class=" ma-0 mb-3"/>
                                                <av-select class="mt-3" v-model="selectedItem.configuration.nameInServerModel" :label="$gettext('Workoder Variable')" v-if="isSystemRead"
                                                           item-text="text" item-value="value" :placeholder="$i18n.CommonAttributes()['select']"
                                                           :items="systemVariables" @change="onChangeSystemVariable" >
                                                </av-select>
                                                <av-text-field v-if="isOpcMethodCall" v-model="selectedItem.configuration.objectId" :label="$gettext('Object node Id')"></av-text-field>
                                                <av-text-field v-if="!isModbus && !isSystemRead" class="mt-2" v-model="selectedItem.configuration.nameInServerModel" :label="isOpcMethodCall ? $gettext('Method node Id') : $gettext('Variable node Id')"></av-text-field>
                                                <template v-else-if="isModbus">
                                                    <v-layout row>
                                                        <av-text-field class="mt-2 mr-2" v-model="selectedItem.configuration.rangeAddress.from" :label=" $gettext('Address From')" type="number"></av-text-field>
                                                        <av-text-field class="mt-2 mr-2" v-model="selectedItem.configuration.rangeAddress.to" :label=" $gettext('Address to')"  type="number"></av-text-field>
                                                        <av-text-field class="mt-2 mr-2" v-model="selectedItem.configuration.rangeAddress.offset" :label=" $gettext('Address Offset')"  type="number"></av-text-field>
                                                        <av-text-field class="mt-2 mr-2" v-model="selectedItem.configuration.scale" :label=" $gettext('Scale')"  type="number"></av-text-field>
                                                    </v-layout>
                                                </template>
                                                <template v-if="isOpcMethodCall">
                                                    <v-expansion-panel v-for="(parameter,i) in selectedItem.configuration.parameters" :key="i">
                                                        <v-expansion-panel-content class="pa-2" >
                                                            <template v-slot:header>
                                                                <div class="title pa-0 ma-0" >{{ parameter.label.length>0 ? parameter.label : "Parameter" }}
                                                                    <av-button icon-color="info" flat fab button-icon="fas fa-arrow-up"
                                                                               :disabled="i===0 || selectedItem.configuration.parameters.length===1" small :toolTip="$gettext('Move one step up')"
                                                                                @click.native="parameterUp($event,parameter,i)">
                                                                    </av-button>
                                                                    <av-button icon-color="info" flat fab button-icon="fas fa-arrow-down"
                                                                               :disabled="i===selectedItem.configuration.parameters.length-1 || selectedItem.configuration.parameters.length===1"
                                                                               small :toolTip="$gettext('Move one step down')"
                                                                                @click.native="parameterDown($event,parameter,i)">
                                                                    </av-button>

                                                                    <av-button  iconColor="error" buttonIcon="fas fa-trash" fab flat  small
                                                                                @click="deleteParameterToConfiguration(parameter,selectedItem.configuration)"
                                                                                class="my-0" style="margin-left: 5px"
                                                                                :toolTip="$gettext('Delete')" />
                                                                </div>
                                                            </template>
                                                            <v-text-field v-model="parameter.label" :label="$gettext('Label')"></v-text-field>
                                                            <av-select v-model="parameter.dataType" :items="variableTypes" item-text="type"
                                                                       item-value="type" @change="(val)=>{ onChangeVariableType(val,parameter) }" :label="$gettext('Type')"/>

                                                            <v-layout row v-if="parameter.dataType!=='boolean'">
                                                                <v-flex>
                                                                    <av-text-field v-model="parameter.min" :label="$gettext('Min value')"></av-text-field>
                                                                </v-flex>
                                                                <v-spacer></v-spacer>
                                                                <v-flex>
                                                                    <av-text-field v-model="parameter.max" :label="$gettext('MAX value')"></av-text-field>
                                                                </v-flex>
                                                            </v-layout>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                    <av-button text="Add Parameter" style="float: right;" flat @click="addParameterDefinitionToConfiguration(selectedItem.configuration)"  size="medium" iconColor="info" buttonIcon="fas fa-plus" />
                                                </template>
                                                <template v-else>
                                                    <v-layout :row="isModbus" :column="!isModbus">
                                                        <av-select v-model="selectedItem.configuration.dataType" :items="variableTypes" :class="isModbus?'mr-2':''" item-text="type"
                                                                   item-value="type" @change="(val)=>{ onChangeVariableType(val,selectedItem.configuration) }" :label="$gettext('Type')" :disabled="isSystemRead"/>
                                                        <av-select v-if="isModbus" v-model="selectedItem.configuration.conversion" :items="conversionsTypes" :label="$gettext('Conversion')"
                                                                   item-text="text" item-value="value"/>

                                                    </v-layout>

                                                    <v-layout row v-if="selectedItem.configuration.dataType!=='boolean' && selectedItem.configuration.dataType!=='bool'">
                                                        <v-flex>
                                                            <av-text-field v-model="selectedItem.configuration.min" :label="$gettext('Min value')" type="number"  :disabled="isSystemRead"></av-text-field>
                                                        </v-flex>
                                                        <v-spacer></v-spacer>
                                                        <v-flex>
                                                            <av-text-field v-model="selectedItem.configuration.max" :label="$gettext('MAX value')" type="number"  :disabled="isSystemRead"></av-text-field>
                                                        </v-flex>
                                                    </v-layout>
                                                </template>


                                                <av-text-field v-model="selectedItem.configuration.maxRetries" type="number" :label="$gettext('Max retries')"></av-text-field>
<!--                                                <av-text-field v-model="selectedItem.configuration.delayRetries" type="number" :label="$gettext('Delay retries')"></av-text-field>-->
                                                <av-duration v-model="selectedItem.configuration.delayRetries" :label="$gettext('Delay retries')"></av-duration>


                                            </v-layout>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <recipe-value style="margin-bottom: 10px" elevation="0" expand
                                                          v-for="(value,i) in configurationValues(selectedItem)" :type="valueType(selectedItem.configuration)"
                                                          :value="value" @onUpdate="(v)=>{updateValue(i,v)}" :reference-variables="recipe.filter(r=>{return r.name!==selectedItem.name && r.type!=='opcmethodcall'})"
                                                          @deleteValue="selectedItem.configuration.values.removeItemRecursive(value)"
                                                          @onReferenceVariableSelected="(v)=>{ onReferenceVariableSelected(v,i)}"
                                                          :exclude-flows="excludedFlows(value)"
                                                          :mapping="mapping" :parameters="selectedItem.configuration.parameters" :key="value.id">
                                            </recipe-value>

                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-tabs>
                                <v-layout v-if="selectedItem.type==='delay'" column class="pt-2" style="overflow-y: auto; height: calc(100%);" >
                                    <av-expansion-panel
                                        style="margin-bottom: 10px; width: calc(100% - 20px); border-radius: 5px; border: 1px solid lightgrey; "
                                        class="elevation-0 pl-1 pr-1"
                                        expand v-for="(delay,i) in selectedItem.configuration.values" :key="delay.id">
                                        <v-expansion-panel-content class="pa-2" >
                                            <template v-slot:header>
                                                <v-layout row  class="pr-2">
                                                    <v-flex class="title pt-4" >{{ "delay " + (i + 1) }}</v-flex>
                                                    <v-spacer></v-spacer>
                                                    <v-flex xs2 class="pt-4">
                                                        <v-switch v-model="delay.disabled" @click.native.stop :label="delay.disabled ? $gettext('Disabled') : $gettext('Disable')"></v-switch>
                                                    </v-flex>
                                                    <v-flex xs1 class="pa-2 mr-2 pr-2">
                                                        <av-button  iconColor="error" buttonIcon="fas fa-trash" fab small
                                                                                               @click="selectedItem.configuration.values.removeItemRecursive(delay)"
                                                                                               class="ma-0 mr-2" style="margin-left: 5px"
                                                                                               :toolTip="$gettext('Delete')"/>
                                                    </v-flex>
                                                </v-layout>
                                            </template>
                                            <v-layout column>
                                                <v-switch :label="$gettext('Choose specific flows to apply this value')" v-model="delay.chooseFlow" @change="onChangeFlow(delay)"  :disabled="delay.disabled"></v-switch>
                                                <av-multi-select v-if="delay.chooseFlow" class="mt-3" v-model="delay.flows" :label="$gettext('Flows')"
                                                                 item-text="Show" :placeholder="$i18n.CommonAttributes()['select']"
                                                                 :items="usableDelayFlows(delay)"  :disabled="delay.disabled">
                                                </av-multi-select>
                                                <av-duration v-model="delay.delay" :label="$gettext('Delay')"  :disabled="delay.disabled"></av-duration>

                                    </v-layout>
                                        </v-expansion-panel-content>
                                    </av-expansion-panel>
                                </v-layout>
                            </v-card-text>
                            <v-divider v-if="tab===1 || selectedItem.type==='delay'" ></v-divider>
                            <v-card-actions >
                                <v-spacer></v-spacer>
                                <av-button v-if="tab===1" :disabled="disableAddValueButton" color="info" iconColor="white" buttonIcon="fas fa-plus" fab small
                                           @click="addValueToConfiguration(selectedItem.configuration)" />

                                <av-button v-if="selectedItem.type==='delay'" color="info" iconColor="white" buttonIcon="fas fa-plus" fab small
                                           @click="addDelayToConfiguration(selectedItem.configuration)" />
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-card>
            </template>
        </av-split-pane>
        <v-card v-else style="width: 100%" elevation="0" :style="recipeType==='line' || recipeType==='machine'?{height:'calc(100% - 10px)','border-radius': '5px', 'border': '1px solid lightgrey'}:''">
            <v-card-actions  v-if="showMsgValidationError">
                <v-layout row>
                    <v-spacer></v-spacer>
                    <v-flex  class="pt-2">
                        <v-icon color="error" small class="mr-2">fas fa-exclamation-triangle</v-icon>
                        <label class="subheading" style="color: var(--av-red);">{{msgValidationError}}</label>
                    </v-flex>
                </v-layout>

            </v-card-actions>
            <v-divider  v-if="showMsgValidationError"></v-divider>
            <template v-if="recipeType==='machine'">
                <v-card-text :style="styleStep">
                    <FormTree :variables="machineVariables" ></FormTree>
                </v-card-text>
            </template>

            <template v-if="recipeType==='line'">
                <line-recipe :recipe="recipe" :machines="machines" @change="onChangeLineRecipe"></line-recipe>
            </template>
        </v-card>

        <v-dialog v-model="getOpcVariableDialog" max-width="720" :persistent="true">
            <v-card>
                <v-toolbar card dense>
                    <v-toolbar-title v-translate>Choose Opc variable</v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text :style="styleForm" >
                    <v-layout column  style="height:calc(100%); overflow-y: auto">
                        <v-treeview :items="Array.isUseful(opcMachineModel)?opcMachineModel:[]" item-text="BrowseName" item-key="NodeID" item-children="Children">
                        <template v-slot:prepend="{ item }">
                            <av-button :toolTip="$gettext('Select')" @click="selectOpcItem(item), getOpcVariableDialog = false"
                                       buttonIcon="fa-check" iconColor="info" size="small" fab small/>
                        </template>
                        <template v-slot:label="{ item }">
                            <label class="v-treeview-node__label">
                                {{item.BrowseName + " (" + item.NodeID + ")"}}
                            </label>
                        </template>
                    </v-treeview>
                    </v-layout>
                    <!--<v-layout v-for="item in opcMachineModel" row>-->
                        <!--<av-button :toolTip="$gettext('Select')" @click="selectOpcItem(item), getOpcVariableDialog = false"-->
                                   <!--buttonIcon="fa-check" iconColor="info" size="small" fab small/>-->
                        <!--<label>{{item.BrowseName + " (" + item.NodeID + ")"}}</label>-->
                        <!--<v-layout v-if="Array.isUseful(item.Children)" v-for="subItem in item.Children" row class="ml-4">-->
                            <!--<av-button :toolTip="$gettext('Select')" @click="selectOpcItem(subItem), getOpcVariableDialog = false"-->
                                       <!--buttonIcon="fa-check" iconColor="info" size="small" fab small/>-->
                            <!--<label>{{subItem.BrowseName + " (" + subItem.NodeID + ")"}}</label>-->
                        <!--</v-layout>-->
                    <!--</v-layout>-->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" flat="flat" @click="getOpcVariableDialog = false">
                        <translate>
                            Cancel
                        </translate>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import Field from '@/components/dynamic-elements/forms/Field';
    import recipes from '@/api/recipes'
    import devices from '@/api/devices'
    import FormTree from '@/components/dynamic-elements/forms/FormTree'
    import jsutils from "../../../api/jsutils";
    import DataEntryApi from '@/api/dataentry'
    import RecipeValue from "./RecipeValue"
    import AvDuration from "@/components/av-components/av-duration";
    import OpcUa from "@/api/opcclients"
    import LineRecipe from "@/components/dynamic-elements/recipes/LineRecipe";

    export default {
        name: "RecipeConfigurator",
        props:{
            recipe: {
                required:true,
                default:()=>{ return []}
            },
            currentRecipe:{
                required:true,
                default:()=>{ return {} }
            },
            mapping:{
                required:true,
                default:()=>{ return []}
            }
        },
        components:{LineRecipe, AvDuration, RecipeValue, FormTree, Field},
        data: ()=> {
            return {
                getOpcVariableDialog: false,
                opcMachineModel: null,
                height: 0,
                tab:"",
                name:"",
                target: [],
                configurationTypes:["opcwrite","opcread","systemread","delay","opcmethodcall","modbuswrite","modbusread"],
                selectedItem:undefined,
                templates:[],
                selectedTemplate:undefined,
                machineVariables:[],
                machineRecipes:[],
                machines:[],
                flows:[],
                filterByFlows:[],
                moveItemToEnabled:false,
                itemToMove:{},
                systemVariables:[],
                msgValidationError: "",
                showMsgValidationError:false
            }
        },
        asyncComputed: {
            extendable() {
               return false;
            },
        },
        computed:{
            addBtnText(){
              switch (this.recipeType) {
                  case "template":
                      return this.$gettext("Add Step");

              }
            },
            styleForm() {
                return {
                    height: (this.height > 0 ? this.height - (this.tab===0 && this.selectedItem.type !== 'delay'?160:220) : 800) + 'px'
                }
            },
            styleStep(){
                let height= (this.height > 0 ? this.height - 200 : 800);
                if (this.tab===1){
                    height-=0
                }
                return {
                    'overflow-y': 'auto',
                    height: height + 'px'
                }
            },
            styleTreeOfLine() {
                return {
                    height: '250px',
                    'overflow-y': 'auto',
                    padding: '5px 0 0 0'
                }
            },
            styleFormOfLine() {
                return {
                    height: (this.height > 0 ? this.height - 320 : 400) + 'px'
                }
            },
            styleCardRecipe(){
                let height= (this.height > 0 ? this.height - 100 : 480);
                return {
                    'overflow-y': 'auto',
                    height: height + 'px'
                }
            },
            configurationValues(){
                return step=>{
                    return step.configuration.values;
                }
            },
            variableTypes() {
                return this.isModbus ? recipes.modbusVariableTypes : recipes.variableTypes;
            },
            valueType(){
                let self=this
                return configuration=> {
                    let t = self.variableTypes.find(t => {
                        return !self.isOpcMethodCall? t.type === configuration.dataType :
                            configuration.parameters.find(p=>{return p.dataType===t.type})
                    });
                    return t;
                }
            },
            recipeType(){
                let type="template";
                switch (this.currentRecipe.properties.type) {
                    case this.$dynamicElements.Types.TemplateRecipe:
                        type= 'template';
                        break;
                    case this.$dynamicElements.Types.MachineRecipe:
                        type = 'machine';
                        break;
                    case this.$dynamicElements.Types.LineRecipe:
                        type = 'line';
                        break;
                }
                return type;
            },
            excludedFlows() {
                return currentValue => {
                    let eFlows = [];
                    this.selectedItem.configuration.values.filter(v => {
                        return v.id !== currentValue.id
                    }).forEach(value => {
                        if(!(value.flows.length === 1 && value.flows[0].Id === 'default')){
                            eFlows.push(...value.flows)
                        }
                    });
                    return eFlows;
                }
            },
            excludedDelayFlows(){
                return currentDelay => {
                    let eFlows = [];
                    this.selectedItem.configuration.values.filter(v => {
                        return v.id !== currentDelay.id
                    }).forEach(delay => {
                        if(!(delay.flows.length === 1 && delay.flows[0].Id === 'default')){
                            eFlows.push(...delay.flows)
                        }
                    });
                    return eFlows;
                }
            },

            usableDelayFlows(){
                return currentDelay =>{
                    if(this.excludedDelayFlows(currentDelay).length===0){
                        return this.flows;
                    }
                    let ss=[];
                    let self=this;
                    this.flows.forEach(flow=>{
                        if(!self.excludedDelayFlows(currentDelay).find(es=>{return es.Id===flow.Id})){
                            ss.push(flow)
                        }
                    });
                    return ss;
                }

            },
            tabsVisible(){
                switch (this.selectedItem.type){
                    case "delay":
                        return false
                }
                return true
            },
            disableAddValueButton(){
                return !this.isOpcMethodCall? (this.selectedItem.configuration.dataType && !this.selectedItem.configuration.dataType.length>0) :
                        this.selectedItem.configuration.parameters && this.selectedItem.configuration.parameters.find(p=>{return p.dataType.length  === 0})
            },
            isOpcMethodCall(){
                return this.selectedItem.type==='opcmethodcall'
            },
            isSystemRead(){
              return this.selectedItem.type==='systemread'
            },
            isModbus(){
             return this.selectedItem.type.indexOf('modbus') > -1
            },
            filteredStep(){
                if(this.filterByFlows.length===0){
                    return this.recipe
                }
               // let filteredSteps=[]
                let self=this;
                return this.recipe.filter(r=>{
                    if (r.type==='' || (r.type==='delay' && r.configuration.values.length===0) ||
                        (r.type!=='delay' && r.configuration.values.length===0)){
                        return r
                    }
                    if (r.type==='delay'){
                        return r.configuration.values.find(d=>{
                            return d.flows.length===0 || d.flows.find(cs=>{
                                return self.filterByFlows.find(fr=>{return fr.Id===cs.Id})
                            })
                        })
                    }else{
                        return r.configuration.values.find(d=>{
                            return d.flows.length===0 || d.flows.find(cs=>{
                                return self.filterByFlows.find(fr=>{return fr.Id===cs.Id})
                            })
                        })
                    }
                })
                //return []

            },
            isDisabled(){
                return item=>{
                    let valuesDisabled=item.configuration.values?item.configuration.values.filter(v=>{return !v.disabled}):[]
                    return valuesDisabled && valuesDisabled.length===0
                }
            },
            conversionsTypes() {
                let exludedTypes = ['bit','int16', 'uint16', 'string']
                let conversions = [
                    {text: "Little Endian", value: "LittleEndian"},
                    {text: "Big Endian", value: "BigEndian"}]
                if (!exludedTypes.includes(this.selectedItem.configuration.dataType)) {
                    conversions.push(...[
                        {text: "Little Endian Inverted", value: "LittleEndianInverted"},
                        {text: "Big Endian Inverted", value: "BigEndianInverted"}])
                }
                return conversions
            }
        },
        async mounted() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            this.loadFlows();
            this.currentRecipe.childHandlers.getItemStatus = this.getFormItemStatus;
            if (this.recipeType==='machine'){
                this.templates = await recipes.getRecipes();
                this.currentRecipe.validators.push({
                    onDeploy: true,
                    onChange: false,
                    validator: this.validateMachineRecipe
                })

                if(this.currentRecipe.properties.templateRecipe && Object.isUseful(this.recipe)){
                    let template=this.templates.find(r=>{return r.name===this.currentRecipe.properties.templateRecipe});
                    this.selectedTemplate=template;
                    this.machineVariables=await recipes.unwrapMachineRecipe(this.recipe,template);
                }
            }
            if(this.recipeType ==='line' && this.currentRecipe.properties.target){
                this.machines=await devices.getAvailableByLine(this.currentRecipe.properties.target);
                if(this.recipeType ==='line'){
                    this.currentRecipe.validators.push({
                        onDeploy: true,
                        onChange: false,
                        validator: ()=>{
                            return this.validateLineRecipe(true)
                        }
                    })
                    this.currentRecipe.validators.push({
                        onDeploy: true,
                        onChange: true,
                        validator: ()=>{
                            return this.validateLineRecipe(false)
                        }
                    })

                }
                this.validateLineRecipe()

            }
            if(this.recipeType === 'template') {
                this.loadMachineOpcModel();
            }
            this.unwrapMapping()
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.handleResize)
        },
        methods:{
            selectOpcItem(item) {
                this.selectedItem.configuration.nameInServerModel = item.NodeID;
                this.selectedItem.configuration.dataType = item.DataType;
                this.onChangeVariableType(item.DataType, this.selectedItem.configuration);
            },
            handleResize() {
                if(document.getElementById('recipeConfigurator'))
                    this.height = document.getElementById('recipeConfigurator').offsetParent.clientHeight;
            },
            addStep(){
                this.selectedItem={}
                switch (this.recipeType) {
                    case "template":
                        let step={
                            type:'',
                            name:"",
                            description:"",
                            configuration:{}
                        }
                        let filteredIndex=this.filteredStep.length + 1
                        let filteredStepLength=this.filteredStep.length
                        let recipeLength=this.recipe.length
                        this.recipe.push(step)
                        if (filteredStepLength<recipeLength){
                            console.log(this.recipe.findItem(step))
                            while (this.recipe.findItem(step) > filteredIndex){
                                this.itemUp(step)
                            }
                            this.selectedItem=this.recipe[filteredIndex];
                            return
                        }
                        break;
                }
                this.selectedItem=this.recipe.last()
            },
            cloneStep(step) {
                let newStep = this.$utils.detach(step);
                newStep.name = "Clone of " + step.name;
                if(Array.isUseful(newStep.configuration.values))
                    for(let value of newStep.configuration.values)
                        value.id = jsutils.timeTag();
                this.recipe.push(newStep);
                this.selectedItem = this.recipe.last()
            },
            async edit(item) {
                this.selectedItem = item;
                this.tab=0
                if(this.recipeType==='template'){
                    if(!this.selectedItem.configuration.maxRetries){
                        this.selectedItem.configuration.maxRetries=3
                    }
                    if(!this.selectedItem.configuration.delayRetries){
                        this.selectedItem.configuration.delayRetries=500
                    }
                }
            },
            itemUp(item) {
                let itemIndex = this.recipe.findItem(item);
                if(itemIndex > 0)
                    this.recipe.moveItem(itemIndex, itemIndex - 1);
            },
            itemDown(item) {
                let itemIndex = this.recipe.findItem(item);
                if(itemIndex >= 0 && itemIndex < this.recipe.length - 1)
                    this.recipe.moveItem(itemIndex, itemIndex + 1);
            },
            moveItem(item,index){
                let itemIndex = this.recipe.findItem(item);
                this.recipe.moveItem(itemIndex, index);
                this.moveItemToEnabled=false
            },
            enableMoveItem(item){
                if (this.moveItemToEnabled && this.recipe.findItem(item)===this.recipe.findItem(this.itemToMove)){
                    this.moveItemToEnabled = false
                    this.itemToMove={}
                    return
                }
                this.moveItemToEnabled=true;
                this.itemToMove=item
            },

            parameterUp(e, item, i) {
                e.cancelBubble=true
                let itemIndex = i;
                if(itemIndex > 0){
                    this.selectedItem.configuration.parameters.moveItem(itemIndex, itemIndex - 1);
                    this.selectedItem.configuration.values.forEach(v=>{
                        v.value.moveItem(itemIndex, itemIndex - 1);
                    })
                }

            },
            parameterDown(e, item, i) {
                e.cancelBubble=true
                let itemIndex = i;
                if(itemIndex >= 0 && itemIndex < this.selectedItem.configuration.parameters.length - 1){
                    this.selectedItem.configuration.parameters.moveItem(itemIndex, itemIndex + 1);
                    this.selectedItem.configuration.values.forEach(v=>{
                        v.value.moveItem(itemIndex, itemIndex + 1);
                    })
                }

            },
            deleteStep(item) {
                this.recipe.removeItemRecursive(item,"configuration");
                this.selectedItem = null;
            },
            addValueToConfiguration(configuration){
                let value = {
                    id: jsutils.timeTag(),
                    flows: [ this.$workflows.getAllFlowsDescriptor() ],
                };
                if (this.isOpcMethodCall){
                    let self=this;
                    value.value = [];
                   configuration.parameters.forEach(param=>{
                       let type=self.variableTypes.find(t => {
                           return  t.type === param.dataType
                       });
                       let defaultValue="";
                       if(type.fieldType === "number" || type.fieldType === "text-number") {
                           defaultValue = 0;
                       }
                       if(type.fieldType === "boolean") {
                           defaultValue = false
                       }
                       value.value.push({
                           id:param.label.replace(" ","")+jsutils.timeTag(),
                           label:param.label,
                           value:defaultValue,
                           restriction:"fixed",
                           assignMode:"assignvalue",
                           type:type,
                           min:type.min,
                           max:type.max
                       })
                   })
                }else{
                    let type=this.variableTypes.find(t => {
                        return  t.type === configuration.dataType
                    });
                    let defaultValue="";
                    if(type.fieldType === "number" || type.fieldType === "text-number") {
                        defaultValue = 0;
                    }
                    if(type.fieldType === "boolean") {
                        defaultValue = false
                    }
                    value.value=defaultValue
                    value.restriction="fixed"
                    value.assignMode="assignvalue"
                }
                value.flows=this.filterByFlows.length>0?this.filterByFlows:[]
                value.ifSuccess=""
                value.ifFail=""
                value.disabled=false
                value.failInSimulationMode=false
                configuration.values.push(value)
            },
            addDelayToConfiguration(configuration) {
                let self = this;
                configuration.values.push({
                    id: "delay_" + jsutils.timeTag(),
                    flows: self.filterByFlows.length > 0 ? self.filterByFlows : [],
                    delay: 0,
                    chooseFlow: false,
                })
            },
            onChangeVariableType(vType,value) {
                let type = this.variableTypes.find(t => {
                    return t.type === vType
                })
                if (this.isOpcMethodCall) {
                    //value is parameter
                    //search value in values
                    this.selectedItem.configuration.values.forEach(v => {
                        let find = v.value.find(vv => {
                            return vv.label === value.label
                        })
                        if (!find) {
                            //value not extist then add
                            v.value.push({
                                    id:value.label.replace(" ","")+jsutils.timeTag(),
                                    label: value.label,
                                    value: "",
                                    restriction: "fixed",
                                    assignMode: "assignvalue",
                                    type: type,
                                    min:type.min,
                                    max:type.max
                                    })
                        } else {
                            find.type=type
                        }
                        }
                    )
                }
                    value.min = type.min;
                    value.max = type.max
            },
            onChangeStepType(type){
                let configuration ={};
                switch (type) {
                    case "opcwrite":
                    case "opcread":
                    case "systemread":
                        configuration={
                            nameInServerModel:"",
                            dataType: "",
                            min: 0,
                            max: 0,
                            values:[],
                            maxRetries:3,
                            delayRetries:500
                        };
                        break;
                        case "modbuswrite":
                        case "modbusread":
                            configuration={
                                rangeAddress:{
                                    from:0,
                                    to:0,
                                    offset:1
                                },
                                conversion:"",
                                scale:1.0,
                                dataType:"",
                                min:0,
                                max:0,
                                values:[],
                                maxRetries:3,
                                delayRetries:500
                            }
                            break
                    case "delay":
                        let self=this
                        configuration={
                            id:jsutils.timeTag(),
                            values:[
                                {
                                    id:"delay_"+jsutils.timeTag(),
                                    flows:self.filterByFlows.length>0?self.filterByFlows:[],
                                    chooseFlow:self.filterByFlows.length>0,
                                    delay:0,
                                    disabled:false,
                                }
                            ],
                        };
                        break;
                        case "opcmethodcall":
                            configuration={
                                nameInServerModel:"",
                                objectId:"",
                                parameters:[],
                                maxRetries:3,
                                delayRetries:500,
                                values:[],
                            };
                }
                this.selectedItem.configuration=configuration;
            },
            addParameterDefinitionToConfiguration(configuration){
                configuration.parameters.push({
                    label:this.calcParameterLabel(configuration),
                    dataType: "",
                    min: 0,
                    max: 0,
                })

            },
            deleteParameterToConfiguration(param,configuration){
              configuration.parameters.removeItem(param);
              configuration.values.forEach(v=>{
                  let find =  v.value.find(vv=>{return vv.label === param.label})
                   if (find){
                       v.value.removeItemRecursive(find)
                   }
              })
            },
            calcParameterLabel(configuration) {
                let label = "param ";
                let i = 1
                while (configuration.parameters.find(p=>{return p.label=== label + i})) {
                    i++
                }
                return label + i
            },
            updateValue(i,v){
                this.selectedItem.configuration.values[i]=v;
            },
            onReferenceVariableSelected(v,i){
                let value=this.selectedItem.configuration.values[i];
                if(value.assignMode==='applyfromvariable' && v){
                    let find= this.recipe.find(step=>{return step.name===v});
                    let flows=[];
                    find.configuration.values.forEach(v=>{
                        v.flows.forEach(s=>{
                            if(s.Id!=='default')
                                flows.push(s);
                        })
                    });
                    value.flows=flows;
                }
            },
            onChangeFlow(v) {
                //todo check if is applyfromvariable and flows are included in reference variable
                if(!v.chooseFlow) {
                    v.flows = [this.$workflows.getAllFlowsDescriptor()]
                } else {
                    v.flows=[];
                }
                // let value=this.selectedItem.configuration.values[i]
                // if(value.assignMode==='applyfromvariable' && v) {
                //     let find = this.recipe.find(step => {
                //         return step.name === v
                //     })
                //     let flows = [];
                //     find.configuration.values.forEach(v => {
                //     })
                // }
            },

            async onChangeMachine(){
                this.machineRecipes= await recipes.getAvailableRecipesByMachines(this.selectedItem.target, true);
                if (this.selectedItem.idRecipe){
                    if(!this.machineRecipes.find(mr=>{return mr===this.selectedItem.idRecipe})){
                        this.selectedItem.idRecipe=""
                    }
                }
            },
            async loadFlows() {
                this.flows = await this.$workflows.flows();
            },

            loadMachineOpcModel() {
                let self = this;
                if(Array.isUseful(this.currentRecipe.properties.targetMachine)) {
                    OpcUa.getOpcModelByMachine(this.currentRecipe.properties.targetMachine[0].device)
                        .then(model => {
                            self.opcMachineModel = model;
                            //Just to test nesting
                            //self.opcMachineModel[0].Children = self.opcMachineModel
                        })
                }
            },
            enableDisableAllValues(item, newVal){
                if(item.configuration.values){
                    item.configuration.values.forEach(v=>{
                        v.disabled=newVal;
                    })
                }
            },

            onChangeSystemVariable(ev) {
                let sv = this.systemVariables.find(sv => {
                    return sv.value === ev
                })
                this.selectedItem.configuration.dataType = this.checkVariableType(sv.type)
                this.onChangeVariableType(this.selectedItem.configuration.dataType,this.selectedItem.configuration)
            },
            onChangeLineRecipe(ev){
                this.recipe.clear()
                let self=this
                ev.forEach(r=>{
                    self.recipe.push(r)
                })
            },

            unwrapMapping() {
                let woMapping=[];
                let self = this;
                this.mapping.forEach(m => {
                    if (m.children.find(v => {
                        return v.index === 'workorders'
                    })) {
                        woMapping.push(...m.children.filter(v => {
                            return v.index === 'workorders'
                        }).filter(item => {
                            return item.name !== 'Transitions' && item.name !== 'tag'
                        }))
                    }
                });

                this.systemVariables.clear();
                this.mapWoVariable(woMapping)
                console.log(this.systemVariables)
            },
            mapWoVariable(variables) {
                for(let variable of variables) {
                    if(!Array.isUseful(variable.children)) {
                        this.systemVariables.push({
                            value: "{{.workorder." + (!variable.root ? "" : variable.root + ".") + variable.name + "}}",
                            text: (!variable.root ? "" : variable.root + ".") + variable.name,
                            type: variable.type
                        })
                    } else {
                        this.mapWoVariable(variable.children)
                    }
                }

            },
            checkVariableType(elasticType){
                switch (elasticType) {
                    case "date":
                    case "keyword":
                    case "geo_point":
                    case "text":
                        return 'string';
                    case  "long":
                        return 'int64';
                    case "float":
                    case "half_float":
                    case "scaled_float":
                        return "float";
                    case "integer":
                    case "OEEComponent":
                        return "int32";
                    case "short":
                        return "int16";
                    case "byte":
                        return "byte";
                    case "bool":
                    case "boolean":
                        return "boolean";
                    default:
                        return elasticType;
                }
            },
            validateMachineRecipe(){
                if(!DataEntryApi.isFormValid(this.machineVariables)){
                    this.$root.showErrorNotification(this.$gettext("The data entered is not valid, It's not possible to activate the recipe"), true);
                    return false
                }
                return true

            },
            async validateLineRecipe(showAlert) {
                this.msgValidationError = ""
                this.showMsgValidationError = false
                if (!Array.isUseful(this.recipe)) {
                    this.msgValidationError = this.$gettext("Recipe can not be empty")

                } else {
                    for (let i = 0; i < this.recipe.length; i++) {
                        let recipe = this.recipe[i]

                        if (!recipe.idRecipe) {
                            this.msgValidationError = this.$gettext("There are one or more enabled machines without recipe")
                            break
                        }
                        for (let j = 0; j < recipe.target.length; j++) {
                            if (!this.machines.find(m => {
                                return m === recipe.target[j]
                            })) {
                                this.msgValidationError = this.$gettext("The previously selected target machine {0} is no longer valid").format(recipe.target[j])
                                break
                            }
                        }
                        if (this.msgValidationError !== '')
                            break
                        let availableRecipesByMachines = await recipes.getAvailableRecipesByMachines(recipe.target, true)
                        if (availableRecipesByMachines.length === 0) {
                            this.msgValidationError = this.$gettext("There aren't recipes for {0}").format(recipe.target.join(" - "))
                            break
                        }
                        i
                        if (!availableRecipesByMachines.find(rm => {
                            return rm === recipe.idRecipe
                        })) {
                            this.msgValidationError = this.$gettext("The previously selected recipe {0} is no longer valid").format(recipe.idRecipe)
                            break
                        }
                    }
                }
                if (this.msgValidationError !== "") {
                    this.showMsgValidationError = true
                    if (showAlert) {
                        this.$root.showErrorNotification(this.msgValidationError, true)
                    }
                    return false
                }
                return true
            },
            getFormItemStatus() {
                if(!this.$dynamicElements.isItemDeployed(this.currentRecipe))
                    return {status: 0, message: this.$gettext("This recipe is draft")};
                else if(this.currentRecipe.hasUndeployedChanges())
                    return {status: 2, message: this.$gettext("Recipe is active with a different configuration")};
                else
                    return {status: 1, message: this.$gettext("This recipe is active")};
            }
        },
        watch: {
            machineVariables: {
                deep: true,
                handler() {
                    //DataEntryApi.isFormValid(this.machineVariables)
                    this.recipe.clear();
                    let values=recipes.wrapVariables(this.machineVariables, this.selectedTemplate);
                    let self=this;
                    if(Array.isUseful(values)){
                        values.forEach(v=>{
                            self.recipe.push(v)
                        })
                    }
                }
            },
            'currentRecipe.properties.templateRecipe':{
                async handler(){
                    let template=this.templates.find(r=>{return r.name===this.currentRecipe.properties.templateRecipe});
                    this.selectedTemplate=template;
                    this.machineVariables=await recipes.unwrapMachineRecipe(this.recipe,template);
                }
            },
            'currentRecipe.properties.target':{
                async handler() {
                    this.machines = await devices.getAvailableByLine(this.currentRecipe.properties.target);
                    this.loadMachineOpcModel();
                }
            }

        }
    }
</script>

<style >
.v-tabs .v-window{
    height:calc(100% - 80px)!important;
}
.v-tabs .v-window .v-window__container{
    height:calc(100%)!important;
}
.v-tabs .v-window .v-window__container .v-window-item{
    height:calc(100%)!important;
}
</style>