<template>
    <v-layout ma-2 row>
        <v-data-table :headers="headers"
                      :items="values"
                      hide-actions
                      :search="search"
                      :pagination.sync="pagination"
                      class="elevation-1"
                      style="width: calc(100% - 50px);">
            <template slot="headers" slot-scope="props">
                <template v-for="headers in [processTableHeaders(props.headers)]">
                    <tr>
                        <th v-for="header in headers.parents"
                            :key="Math.random() *10"
                            :rowspan="header.rowspan"
                            :colspan="header.colspan"
                            :width="header.width"
                            :class="header.class">
                            {{ header.text }}
                        </th>
                    </tr>
                    <tr v-if="headers.children">
                        <th v-for="(header, ii) in headers.children"
                            :key="ii"
                            :width="header.width"
                            :class="header.class + ' ' + (header.align ? `text-xs-${header.align}` : '')">
                            {{ header.text }}
                        </th>
                    </tr>
                </template>
            </template>
            <template slot="items" slot-scope="props">
                <td v-if="!v.children"
                    v-for="v in headers"
                    :key="Math.random() *100"
                    :class="`text-xs-${v.align}`">
                    {{ v.format ? v.format(props.item[v.value]) : props.item[v.value] }}
                </td>
                <td v-else
                    v-for="c in v.children"
                    :key="Math.random() *1000"
                    :class="`text-xs-${c.align}`">
                    {{ c.format ? c.format(props.item[c.value]) : props.item[c.value] }}
                </td>
            </template>
        </v-data-table>
        <div class="text-xs-center pt-2">
            <v-pagination style="display: unset" color="info" :total-visible="totalVisible" prev-icon="fas fa-angle-up" next-icon="fas fa-angle-down" v-model="pagination.page"  :length="pages" ></v-pagination>
        </div>
    </v-layout>
</template>

<script>

    import DateTime from '@/api/datetimeutils.js';

    export default {
        name: "RawDataView",
        data() {
            return {
                headers: [],
                values: [],
                search: '',
                pagination: {},
                timeTracking: [],
                rowsPerPage: 5,
            }
        },
        props: {
            dataCollection: {
                type: Array,
                default: null
            },
            containerHeight: {
                type: Number,
                default: 100
            }
        },
        computed: {
            totalVisible() {
                let count = Math.round((this.containerHeight - 100) / 50);
                if(count <= 0)
                    count = 1;
                return count;
            },
            pages() {
                this.pagination.rowsPerPage = Math.floor((this.containerHeight - 30) / 50.74) - 2;
                if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null || this.pagination.rowsPerPage < 1) {
                    this.pagination.rowsPerPage = 1;
                    return 1;
                }
                return Math.ceil(this.values.length / this.pagination.rowsPerPage)
            }
        },
        watch: {
            dataCollection() {
                this.refreshData();
            }
        },
        methods: {

            processTableHeaders(headers) {
                //FN: The first ! converts the value to boolean and inverts its logical value.
                // The second ! inverts the logical value back.
                const nested = !!headers.some(h => h.children);

                if (nested) {
                    let children = [];

                    const h = headers.map(h => {
                        if (h.children && h.children.length > 0) {
                            children.push(...h.children);

                            return {
                                rowspan: 1,
                                colspan: h.children.length,
                                text: h.text,
                                align: h.align,
                                class: h.class
                            }
                        }
                        return {
                            rowspan: 2,
                            colspan: 1,
                            text: h.text,
                            align: h.align,
                            class: h.class
                        }
                    });

                    return {
                        children: children,
                        parents: h,
                    }
                }
                return {
                    parents: headers
                }
            },
            refreshData() {
                let self = this;
                self.headers.clear();
                self.values.clear();
                //array used for populate the column of TimeTracking from  datavalues where timeTracking data are nested arrays)
                self.timeTracking.clear();
                let maxRows = 0;
                let totRowsCategories = 0;
                let indexDatasetTimeTracking = -1;
                //Unwrap headers and subHeaders, count rows and place each setting at correct place
                this.dataCollection.forEach((dataSet, index) => {
                    if (dataSet) {
                        if (Array.isUseful(dataSet.data)) {
                            maxRows = dataSet.data.length > maxRows ? dataSet.data.length : maxRows;
                            //TimeTracking survey has special format
                            if (dataSet.identifier === 'TimeTracking.Activity' && dataSet.representation === self.$defines.allAggregations.ttsurvey.id) {
                                indexDatasetTimeTracking = index;
                                totRowsCategories = self.visit(dataSet.data);
                            }
                        }
                        self.headers.push({
                            text: dataSet.label,
                            children : this.getSubheaders(dataSet),
                            class: 'subheading'
                        });
                    }
                });
                //number of rows to unwrap
                maxRows = maxRows < totRowsCategories ? totRowsCategories : maxRows;
                if(indexDatasetTimeTracking >= 0)
                    if(Array.isUseful(this.dataCollection[indexDatasetTimeTracking].data))
                        self.unwrapTimeTrackingSubHeaders(this.dataCollection[indexDatasetTimeTracking].data);

                //Unwrap data  place each value at correct place
                for(let i = 0 ; i < maxRows ; i++) {
                    let element = {};
                    this.dataCollection.forEach((dataSet, index) => {
                        if (dataSet) {
                            if (dataSet.data && dataSet.data.length > 0) {
                                let indexRow = Object.isUseful(dataSet.data[i]) ? i : 0;
                                if (dataSet.identifier === 'TimeTracking.Activity' && dataSet.representation === self.$defines.allAggregations.ttsurvey.id) {
                                    let indexRowTimeTracking = Object.isUseful(self.timeTracking[i]) ? i : 0;
                                    Object.entries(self.headers[indexDatasetTimeTracking].children).forEach(([key, properties]) => {
                                        let subHeader = properties.value;
                                        element[subHeader] = indexRowTimeTracking === i ? self.timeTracking[indexRowTimeTracking][subHeader] : "";
                                    });
                                } else {
                                    Object.entries(dataSet.data[indexRow]).forEach(([key, value]) => {
                                        if (key !== 'tracker' && key !== 'warning' && key !== 'error' && key !== 'time tracking' && key !== 'timeTrackingId' && key !== 'value1' && key !== 'value2') {
                                            let subHeader = dataSet.label + '.' + key;
                                            if (key === 'start' || key === 'stop' || (key === 'x' && !dataSet.isCategorical))
                                                element[subHeader] = indexRow === i ? new Date((DateTime.getRfc3339TimeStamp(value > 0 ? new Date(value) : new Date()))).format() : "";
                                            else
                                                element[subHeader] = indexRow === i ? value : "";
                                        }
                                    });
                                }
                            }
                        }
                    });
                    self.values.push(element);
                }
            },

            visit(categories) {
                let rows = 0;
                for (let i = 0; i<categories.length; i++) {
                    rows += 1;
                    if (Array.isUseful(categories[i].children)) {
                        rows += this.visit(categories[i].children);
                    }
                }
                return rows;
            },

            unwrapTimeTrackingSubHeaders(categories) {
                let rows = 0;
                let element;
                for(let category of categories) {
                    element = {};
                    element['Time Tracking.show'] = category['show'];
                    element['Time Tracking.sub1'] = "";
                    element['Time Tracking.sub2'] = "";
                    element['Time Tracking.sub3'] = "";
                    element['Time Tracking.duration'] = category['duration'];
                    this.timeTracking.push(element);

                    if (Array.isUseful(category.children)) {
                        if(category.children.length > 0) {
                            for (let subCategory of category.children) {
                                element = {};
                                element['Time Tracking.show'] = category['show'];
                                element['Time Tracking.sub1'] = subCategory['show'];
                                element['Time Tracking.sub2'] = "";
                                element['Time Tracking.sub3'] = "";
                                element['Time Tracking.duration'] = subCategory['duration'];
                                this.timeTracking.push(element);
                                if (Array.isUseful(subCategory.children)) {
                                    if(subCategory.children.length > 0) {
                                        for (let subSubCategory of subCategory.children) {
                                            element = {};
                                            element['Time Tracking.show'] = category['show'];
                                            element['Time Tracking.sub1'] = subCategory['show'];
                                            element['Time Tracking.sub2'] = subSubCategory['show'];
                                            element['Time Tracking.sub3'] = "";
                                            element['Time Tracking.duration'] = subSubCategory['duration'];
                                            this.timeTracking.push(element);
                                            if (Array.isUseful(subSubCategory.children)) {
                                                if(subSubCategory.children.length > 0) {
                                                    for (let subSubSubCategory of subSubCategory.children) {
                                                        element = {};
                                                        element['Time Tracking.show'] = category['show'];
                                                        element['Time Tracking.sub1'] = subCategory['show'];
                                                        element['Time Tracking.sub2'] = subSubCategory['show'];
                                                        element['Time Tracking.sub3'] = subSubSubCategory['show'];
                                                        element['Time Tracking.duration'] = subSubSubCategory['duration'];
                                                        this.timeTracking.push(element);
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            },

            getSubheaders(dataset) {

                if(dataset.dataFormat === this.$defines.DatasetFormats.table) {
                    for(let i = 0 ; i < dataset.headers.length ; i++)
                        dataset.headers[i].value = dataset.label + "." + dataset.headers[i].value;
                    return dataset.headers;
                }

                let subheaders = [];
                let addSubHeaderObject = function(text, value) {
                    subheaders.push({
                        text: text,
                        value: value,
                        align: 'right',
                        class: 'subheading'
                    });
                };
                switch(dataset.dataFormat) {
                    case this.$defines.DatasetFormats.xy:
                        addSubHeaderObject("x", "{0}.x".format(dataset.label));
                        addSubHeaderObject("y", "{0}.y".format(dataset.label));
                        break;
                    case this.$defines.DatasetFormats.categories:
                    case this.$defines.DatasetFormats.buckets:
                    case this.$defines.DatasetFormats.ttranking:
                        addSubHeaderObject("category", "{0}.x".format(dataset.label));
                        addSubHeaderObject("value", "{0}.y".format(dataset.label));
                        break;
                    case this.$defines.DatasetFormats.ttsurvey:
                        addSubHeaderObject("category", "Time Tracking.show".format(dataset.label));
                        addSubHeaderObject("sub1", "Time Tracking.sub1".format(dataset.label));
                        addSubHeaderObject("sub2", "Time Tracking.sub2".format(dataset.label));
                        addSubHeaderObject("sub3", "Time Tracking.sub3".format(dataset.label));
                        addSubHeaderObject("duration", "Time Tracking.duration".format(dataset.label));
                        break;
                    case this.$defines.DatasetFormats.ttlog:
                        addSubHeaderObject("start", "{0}.start".format(dataset.label));
                        addSubHeaderObject("stop", "{0}.stop".format(dataset.label));
                        addSubHeaderObject("duration", "{0}.duration".format(dataset.label));
                        addSubHeaderObject("category", "{0}.show".format(dataset.label));
                        break;
                }
                return subheaders;
            },
        }
    }
</script>

<style scoped>
    table th { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table td { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table tr { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
</style>