  <template>
  <v-select v-if="!readonly" :values="computedValues" v-bind="$attrs" v-on="$listeners" :id="computedID" :items="translatedOptions" :label="translatedLabel" :placeholder="translatedPlaceholder" :hint="translatedHint" :menu-props="{transition:'scale-transition'}">
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" :prefix="translatedPrefix"/>
  </v-select>
  <v-input v-else v-bind="$attrs" :readonly="readonly" v-on="$listeners" :label="translatedLabel" :hint="translatedHint">
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" :prefix="translatedPrefix"/>
  </v-input>
</template>
<script>
export default {
  name: 'av-select',
  props: {
    readonly: { required: false, default: false },
    translateLabel: { required: false, default: true },
    translateOptions: { required: false, default: true },
    translateHint: { required: false, default: true },
    translatePrefix: { required: false, default: true },
    translatePlaceholder: { required: false, default: true },
    items: { required: true },
    dynamicID: { required: false, default: '' },
  },
  data () {
    return {}
  },
  computed: {
    computedValues () {
      return JSON.stringify(this.items)
    },
    computedID () {
      if (!this.$isEmpty(this.$attrs.id)) {
        return this.$attrs.id
      }
      let dynamicPageName = this.$router.history.current.params.routeId ? this.$router.history.current.params.routeId : "";
      return this.toCamelCase(this.$router.history.current.name + dynamicPageName + this.dynamicID + 'Combobox' + this.translatedLabel.trim())
    },
    translatedOptions () {
      if (!Array.isUseful(this.items))
        return [];
      if (!this.translateOptions) {
        return this.items
      }
      let arr = [];
      let key = 'text';
      if (!this.$isEmpty(this.$attrs['item-text'])) {
        key = this.$attrs['item-text']
      }
      for (let i = 0; i < this.items.length; i++) {
          if (typeof this.items[i] === "string"){
            arr.push(this.items[i])
          } else if (typeof this.items[i] === "object" && this.items[i][key]){
            let opt = JSON.parse(JSON.stringify(this.items[i]));
            opt[key] = opt[key];
            arr.push(opt)
          }else{
            arr.push(this.items[i])
          }
      }
      return arr
    },
    translatedLabel () {
      if(!this.$attrs.label)
        return "";
      if (!this.translateLabel) {
        return this.$attrs.label
      }
      return this.$attrs.label
    },
    translatedHint () {
      if (!this.translateHint) {
        return this.$attrs.hint
      }
      return this.$attrs.hint
    },
    translatedPrefix () {
      if (!this.translatePrefix) {
        return this.$attrs.prefix
      }
      return this.$attrs.hint
    },
    translatedPlaceholder () {
      if(!this.$attrs.placeholder)
        return "";
      if (!this.translatePlaceholder) {
        return this.$attrs.placeholder
      }
      return this.$attrs.placeholder
    },
  },
  mounted () {},
  methods: {}
}
</script>
