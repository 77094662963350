<template>
    <av-page :toolbar-activation-on="device" @onToolbarUpdate="(evt)=>{toolbarButton=evt}">
        <template slot="toolbarFixedItems">
            <v-btn class="mt-4 mb-4 ml-4 mr-2" @click="addDevice()">
                <av-icon color="#2196f3" left>fas fa-microchip fa-5x</av-icon>
                <translate>New device</translate>
            </v-btn>
            <v-select class="mt-4 mb-4 ml-2 mr-2" style="max-width: 300px;" v-model="detailedDevice" :items="devices"
                      :label="$gettext('Select a device')" @change="getDeviceOpcDescriptions($event)" item-text="Name"
                      return-object/>
        </template>
        <template slot="toolbarDynamicItems">
            <v-spacer></v-spacer>
            <span class="title mt-3 ml-2 mr-1" style="height: 40px;"> {{ processStatus }} </span>
            <v-menu transition="slide-y-transition" v-if="$grants.get().settings.avionicsSystem">
                <template v-slot:activator="{ on }">
                    <v-btn class="ml-2 mr-0" v-on="on">
                        <av-icon color="red" left>fas fa-cog</av-icon>
                        <translate>Process control</translate>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-tile v-for="(item, i) in processControlItems" :key="i" v-if="item.show">
                        <v-list-tile-content>
                                <span @click="functionCall(item.action)" class="context-menu-span">
                                    <av-icon :color="item.iconColor" small left>{{ item.icon }}</av-icon>
                                    {{ item.title }}
                                </span>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile>
                        <v-list-tile-content>
                                <span @click="deleteDevice()" class="context-menu-span">
                                    <av-icon color="red" small left>fas fa-trash fa-5x</av-icon>
                                    <translate>Delete device</translate>
                                </span>
                        </v-list-tile-content>
                    </v-list-tile>
                </v-list>
            </v-menu>
            <v-btn class=" ml-3 mr-0" @click="addVariables()">
                <av-icon color="info" left>fas fa-plus-circle</av-icon>
                <translate>Add Variable</translate>
            </v-btn>
            <v-btn class=" ml-3 mr-0" @click="exportOpc('xlsx')">
                <av-icon color="blue" style="margin-right: 10px;">fas fa-file-excel</av-icon>
                <av-icon color="blue" style="margin-right: 10px;">fas fa-arrow-right</av-icon>
                Export excel
            </v-btn>
            <ImportExportExcel :class="'mt-1 ml-0 import-btn '+ (toolbarButton.show? 'import-btn-multi-rows': '')"
                               :text-icon-visible="true" :enableExport="false" :horizontal="false"
                               @fileLoaded="filterImportedData($event)"/>
        </template>
        <template slot="pageContent">
            <ConfigurationsEditor style="max-height: calc(100vh - 100px)" v-if="opcDescription"
                                  :descriptor="opcDescription" :dense="true" @value-Changed="save"/>
            <av-input-box ref="inputBox" :validator="machineNameValidator"/>
        </template>
    </av-page>
</template>

<script>

import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
import Supervisor from "@/api/supervisorApi";
import ImportExportExcel from "@/components/utilities/ImportExportExcel";
import ElasticBasedBootstrap from "@/api/elasticBasedBootstrap";
import avPage from "@/components/av-components/av-page";

export default {
    name: "OpcDescriptions",
    components: {avPage, ConfigurationsEditor, ImportExportExcel},
    data() {
        return {
            actionTrail: "",
            actionTrailId: "",
            nextValTrail: "",
            previousValTrail: "",
            devices: [],
            device: "",
            detailedDevice: null,
            opcDescription: null,
            tmpOpcDescription: null,
            variablesDescription: null,
            scroll: 100000,
            opcFileContainer: null,
            templateOpcDescription: [
                {
                    //groupId: "connectionInfo",
                    visible: true,
                    groupName: this.$gettext("OPC Connection Info"),
                    settings: {
                        Name: {
                            name: this.$gettext("Device Name"),
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        EndPoint: {
                            name: this.$gettext("EndPoint"),
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        Security: {
                            name: this.$gettext("Security"),
                            value: 0,
                            description: "",
                            type: "option",
                            options: [{text: this.$gettext("No security"), value: 0}, {
                                text: "Basic128Rsa15",
                                value: 1
                            }, {text: "Basic256", value: 2}, {text: "Basic256Sha256", value: 3}],
                            readOnly: false,
                        },
                        SecurityMode: {
                            name: this.$gettext("Security Mode"),
                            value: 0,
                            description: "",
                            type: "option",
                            options: [{text: this.$gettext("Auto"), value: 0}, {
                                text: this.$gettext("None"),
                                value: 1
                            }, {text: "Sign", value: 2}, {text: "SignAndEncrypt", value: 3}],
                            readOnly: false,
                        },
                        User: {
                            name: this.$gettext("Username"),
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        Password: {
                            name: this.$gettext("Password"),
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        TargetNode: {
                            name: this.$gettext("TargetNode"),
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                    }
                }
            ],
            templateVariablesOpcDescription: null,
            fieldsExportImport: ["MachineOrParentGroup", "VariableNameOpcUa", "VariableNameAvionics", "VariableType", "DataType"],
            showImportWarning: false,
            fileImported: false,
            fieldsExport: ["MachineOrParentGroup", "ServerName", "ClientName", "VariableType", "ClientDataType"],
            dataCollection: [],
            processControlItems: [
                {
                    title: this.$gettext('Deploy'),
                    action: 'deployProcess',
                    icon: 'fas fa-play',
                    iconColor: 'info',
                    show: true
                },
                {
                    title: this.$gettext('Relaunch'),
                    action: 'relaunchProcess',
                    icon: 'fas fa-repeat',
                    iconColor: 'warning',
                    show: false,
                },
                {
                    title: this.$gettext('Suspend'),
                    action: 'suspendProcess',
                    icon: 'fas fa-stop',
                    iconColor: 'red',
                    show: false
                },
            ],
            status: 0,
            hiddenButtons: false,
            toolbarButton: {
                show: false,
                height: 80
            }
        }
    },
    mounted: function () {
        if (this.$root.startedUp) {
            this.loadProcessesList();
        }

    },
    computed: {
        scrollTop() {
            return this.scroll;
        },
        processStatus() {
            if (this.status === 1)
                return this.$gettext("Client is running")
            else
                return this.$gettext("Client is suspended")
        }
    },
    methods: {

        reboot() {
            this.$root.showDialogBox(this.$gettext("THIS COULD CAUSE {appName1} TO STOP WORKING. Hit CANCEL if you don't know exactly what you are doing. Are you sure you want to restart {appName2} system? This may take several minutes").format({
                appName1: this.$config.appName.toUpperCase(),
                appName2: this.$config.appName
            }), null, "Yes", Supervisor().get("/restart"), "CANCEL", null);
        },
        deleteDevice() {
            this.$root.showDialogBox(this.$gettext("THIS COULD CAUSE {appName} TO STOP WORKING. Hit CANCEL if you don't know exactly what you are doing. Are you really sure you want to delete this device?").format({appName: this.$config.appName.toUpperCase()}), null, "Yes", this.delete, "CANCEL", null);
        },
        delete() {
            let self = this;
            if (self.status === 1) {
                ElasticBasedBootstrap.stopProcess(self.detailedDevice)
                    .then(() => {
                        self.$root.showInfoNotification(self.$gettext("Success stopping opc device."), true);
                    })
                    .catch(err => {
                        self.$root.showErrorNotification(this.$gettext("Error stopping opc device."), true);
                        console.log(err);
                    });
            }
            this.$settings.delete(this.$settings.OpcDescriptions, this.device, "json", this.$settings.InstanceScope)
                .then(() => {
                    self.$root.showInfoNotification(this.$gettext("Success deleting opc device from DB."), true);
                    self.clear();
                    self.$audits.save(self.$root.userName, self.$audits.items().opcUaDeviceDeleted, "", "", self.detailedDevice.Name)
                        .catch(() => {
                            debugger
                            self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                        });

                })
                .catch(err => {
                    self.$root.showErrorNotification(this.$gettext("Error deleting opc device from DB."), true);
                })
        },
        clear() {
            this.devices = this.devices.filter(device => device.Name !== this.device);
            this.device = "";
            this.opcDescription = null;
        },
        machineNameValidator(name) {
            if (!name)
                return false;
            let valid = /^[a-z0-9]+$/;
            for (let i = 0; i < name.length; i++)
                if (!name[i].match(valid) && name[i] !== "-")
                    return false;

            return true;
        },
        loadConfiguredDevicesList() {
            //Get the list of devices that has a descriptions file saved in DB
            let self = this;
            this.devices.clear();
            this.$settings.list(this.$settings.OpcDescriptions, this.$settings.InstanceScope)
                .then(devices => {
                    for (let i = 0; i < devices.length; i++) {
                        if (devices[i].Name) {
                            let detailedDevice = self.dataCollection.find(item => item.process === devices[i].Name);
                            self.devices.push({
                                Name: devices[i].Name,
                                Type: devices[i].Type,
                                Status: detailedDevice && detailedDevice.status === "Ok" ? 1 : 0
                            });
                        }
                    }
                });
        },
        getDeviceOpcDescriptions(device) {
            this.detailedDevice = device;
            this.device = device.Name;
            this.$settings.load(this.$settings.OpcDescriptions, device.Name, "json", this.$settings.InstanceScope)
                .then(descriptions => {
                    this.opcDescription = descriptions;
                    this.getOpcDescription();
                });
            this.status = device.Status;
            if (this.status === 1) {
                this.processControlItems.find(item => item.title === 'Deploy').show = false;
                this.processControlItems.find(item => item.title === 'Relaunch').show = true;
                this.processControlItems.find(item => item.title === 'Suspend').show = true;
            } else {
                this.processControlItems.find(item => item.title === 'Deploy').show = true;
                this.processControlItems.find(item => item.title === 'Relaunch').show = false;
                this.processControlItems.find(item => item.title === 'Suspend').show = false;
            }
        },
        getTemplateVariables() {
            return this.templateVariablesOpcDescription = [
                {
                    visible: true,
                    groupName: "New Variable",
                    isDeletable: true,
                    settings: {
                        // //FN: Do not delete , if needed uncomment wih other parts linked
                        // Destination: {
                        //     name: "Alarm",
                        //     value: false,
                        //     description: "",
                        //     type: "bool",
                        //     readOnly: false,
                        // },
                        VariableType: {
                            name: this.$gettext("Variable Type"),
                            value: "",
                            description: "",
                            type: "option",
                            options: [{
                                text: this.$gettext("Free configurable"),
                                value: ""
                            }, {
                                text: this.$gettext("Alarms (In folder with numerical ids)"),
                                value: "numericalNodeAlarms"
                            }, {
                                text: this.$gettext("Alarms (In folder with textual ids)"),
                                value: "textualNodeAlarms"
                            }, {
                                text: this.$gettext("Single alarm"),
                                value: "singleAlarm"
                            }, {
                                text: this.$gettext("Counter"),
                                value: "counter"
                            }, {text: this.$gettext("Machine status"), value: "status"}],
                            readOnly: false,
                        },
                        ClientName: {
                            name: this.$gettext("Client Name"),
                            value: "Status",
                            description: "",
                            type: "editableOption",
                            options: [{text: "Status", value: "Status"}, {
                                text: "TotalItems",
                                value: "TotalItems"
                            }, {text: "GoodItems", value: "GoodItems"}, {
                                text: "RejectedItems",
                                value: "RejectedItems"
                            }],
                            readOnly: false,
                        },
                        ServerName: {
                            name: this.$gettext("Server Name"),
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        Monitored: {
                            name: this.$gettext("Monitored"),
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                            visible: true,
                        },
                        Subscribed: {
                            name: this.$gettext("Subscribed"),
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                            visible: true,
                        },
                        DoNotPoll: {
                            name: this.$gettext("DoNotPoll"),
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                            visible: true,
                        },
                        ClientDataType: {
                            name: this.$gettext("ClientDataType"),
                            value: "",
                            description: "",
                            type: "option",
                            options: [{text: "int", value: "int"}, {text: "bool", value: "bool"}, {
                                text: "float",
                                value: "float"
                            }, {text: "string", value: "string"}, {text: "node", value: "node"}, {
                                text: "bitnode",
                                value: "bitnode"
                            }, {text: "bitmask", value: "bitmask"}],
                            readOnly: false,
                        },
                        NodeVariablesNamingMode: {
                            name: this.$gettext("NodeVariablesNamingMode"),
                            value: "fullname",
                            description: "",
                            type: "option",
                            options: [{
                                text: this.$gettext("fullname"),
                                value: "fullname"
                            }, {text: this.$gettext("indexed"), value: "indexed"}],
                            readOnly: false,
                            visible: true,
                        },
                        Incremental: {
                            name: this.$gettext("Incremental"),
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                        },
                        Continuous: {
                            name: this.$gettext("Continuous"),
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                        },
                        PollingInterval: {
                            name: this.$gettext("PollingInterval"),
                            value: 1000,
                            description: "",
                            type: "number",
                            readOnly: false,
                        },
                        Normalization: {
                            name: this.$gettext("Normalization"),
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        IsAlarm: {
                            name: "IsAlarm",
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                            visible: true,
                        },
                        AlarmMask: {
                            name: this.$gettext("AlarmMask"),
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                            visible: false,
                        },
                        // //FN: Do not delete , if needed uncomment wih other parts linked
                        // Notify: {
                        //     name: "Notify",
                        //     value: false,
                        //     description: "",
                        //     type: "bool",
                        //     readOnly: false,
                        // },
                        // DoNotSplitArray: {
                        //     name: this.$gettext("DoNotSplitArray"),
                        //     value: false,
                        //     description: "",
                        //     type: "bool",
                        //     readOnly: false,
                        //     visible: false,
                        // },
                        // UseAsObjectNamespace: {
                        //     name: this.$gettext("UseAsObjectNamespace"),
                        //     value: false,
                        //     description: "",
                        //     type: "bool",
                        //     readOnly: false,
                        //     visible: false,
                        // },
                    }

                }

            ]
        },
        getTemplateNumericalIdsAlarms() {
            return this.templateVariablesOpcDescription = [
                {
                    visible: true,
                    groupName: "New Variable",
                    isDeletable: true,
                    settings: {
                        VariableType: {
                            name: "Variable Type",
                            value: "numericalNodeAlarms",
                            description: "",
                            type: "option",
                            options: [{
                                text: "Free configurable",
                                value: ""
                            }, {
                                text: "Alarms (In folder with numerical ids)",
                                value: "numericalNodeAlarms"
                            }, {
                                text: "Alarms (In folder with textual ids)",
                                value: "textualNodeAlarms"
                            }, {text: "Single alarm", value: "singleAlarm"}, {
                                text: "Counter",
                                value: "counter"
                            }, {text: "Machine status", value: "status"}],
                            readOnly: false,
                        },
                        ClientName: {
                            name: "Client Name",
                            value: "Alarms",
                            description: "",
                            type: "editableOption",
                            options: [{text: "Status", value: "Status"}, {
                                text: "TotalItems",
                                value: "TotalItems"
                            }, {text: "GoodItems", value: "GoodItems"}, {
                                text: "RejectedItems",
                                value: "RejectedItems"
                            }, {text: "Alarms", value: "Alarms"}],
                            readOnly: false,
                            visible: false,
                        },
                        ServerName: {
                            name: "Server Name",
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        }
                    }
                }
            ]
        },
        getTemplateTextualIdsAlarms() {
            return this.templateVariablesOpcDescription = [
                {
                    visible: true,
                    groupName: "New Variable",
                    isDeletable: true,
                    settings: {
                        VariableType: {
                            name: "Variable Type",
                            value: "textualNodeAlarms",
                            description: "",
                            type: "option",
                            options: [{
                                text: "Free configurable",
                                value: ""
                            }, {
                                text: "Alarms (In folder with numerical ids)",
                                value: "numericalNodeAlarms"
                            }, {
                                text: "Alarms (In folder with textual ids)",
                                value: "textualNodeAlarms"
                            }, {text: "Single alarm", value: "singleAlarm"}, {
                                text: "Counter",
                                value: "counter"
                            }, {text: "Machine status", value: "status"}],
                            readOnly: false,
                        },
                        ClientName: {
                            name: "Client Name",
                            value: "Alarms",
                            description: "",
                            type: "editableOption",
                            options: [{text: "Status", value: "Status"}, {
                                text: "TotalItems",
                                value: "TotalItems"
                            }, {text: "GoodItems", value: "GoodItems"}, {
                                text: "RejectedItems",
                                value: "RejectedItems"
                            }, {text: "Alarms", value: "Alarms"}],
                            readOnly: false,
                            visible: false,
                        },
                        ServerName: {
                            name: "Server Name",
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                    }
                }
            ]
        },
        getTemplateSingleAlarm() {
            return this.templateVariablesOpcDescription = [
                {
                    visible: true,
                    groupName: "New Variable",
                    isDeletable: true,
                    settings: {
                        // //FN: Do not delete , if needed uncomment wih other parts linked
                        // Destination: {
                        //     name: "Alarm",
                        //     value: false,
                        //     description: "",
                        //     type: "bool",
                        //     readOnly: false,
                        // },
                        VariableType: {
                            name: "Variable Type",
                            value: "singleAlarm",
                            description: "",
                            type: "option",
                            options: [{
                                text: "Free configurable",
                                value: ""
                            }, {
                                text: "Alarms (In folder with numerical ids)",
                                value: "numericalNodeAlarms"
                            }, {
                                text: "Alarms (In folder with textual ids)",
                                value: "textualNodeAlarms"
                            }, {text: "Single alarm", value: "singleAlarm"}, {
                                text: "Counter",
                                value: "counter"
                            }, {text: "Machine status", value: "status"}],
                            readOnly: false,
                        },
                        ClientName: {
                            name: "Client Name",
                            value: "Status",
                            description: "",
                            type: "editableOption",
                            options: [{text: "Status", value: "Status"}, {
                                text: "TotalItems",
                                value: "TotalItems"
                            }, {text: "GoodItems", value: "GoodItems"}, {
                                text: "RejectedItems",
                                value: "RejectedItems"
                            }],
                            readOnly: false,
                        },
                        ServerName: {
                            name: "Server Name",
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        Monitored: {
                            name: "Monitored",
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                            visible: true,
                        },
                        Subscribed: {
                            name: "Subscribed",
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                            visible: false,
                        },
                        DoNotPoll: {
                            name: "DoNotPoll",
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                            visible: false,
                        },
                        ClientDataType: {
                            name: "ClientDataType",
                            value: "",
                            description: "",
                            type: "option",
                            options: [{text: "int", value: "int"}, {text: "bool", value: "bool"}, {
                                text: "float",
                                value: "float"
                            }, {text: "string", value: "string"}, {text: "node", value: "node"}, {
                                text: "bitnode",
                                value: "bitnode"
                            }, {text: "bitmask", value: "bitmask"}],
                            readOnly: false,
                        },
                        NodeVariablesNamingMode: {
                            name: "NodeVariablesNamingMode",
                            value: "fullname",
                            description: "",
                            type: "option",
                            options: [{text: "fullname", value: "fullname"}, {text: "indexed", value: "indexed"}],
                            readOnly: false,
                            visible: true,
                        },
                        Incremental: {
                            name: "Incremental",
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                        },
                        Continuous: {
                            name: "Continuous",
                            value: false,
                            description: "",
                            type: "bool",
                            readOnly: false,
                        },
                        PollingInterval: {
                            name: "PollingInterval",
                            value: 1000,
                            description: "",
                            type: "number",
                            readOnly: false,
                        },
                        Normalization: {
                            name: "Normalization",
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        AlarmMask: {
                            name: "AlarmMask",
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                            visible: true,
                        },
                        // //FN: Do not delete , if needed uncomment wih other parts linked
                        // Notify: {
                        //     name: "Notify",
                        //     value: false,
                        //     description: "",
                        //     type: "bool",
                        //     readOnly: false,
                        // },
                    }

                }

            ]
        },
        getTemplateCounter() {
            return this.templateVariablesOpcDescription = [
                {
                    visible: true,
                    groupName: "New Variable",
                    isDeletable: true,
                    settings: {
                        VariableType: {
                            name: "Variable Type",
                            value: "counter",
                            description: "",
                            type: "option",
                            options: [{
                                text: "Free configurable",
                                value: ""
                            }, {
                                text: "Alarms (In folder with numerical ids)",
                                value: "numericalNodeAlarms"
                            }, {
                                text: "Alarms (In folder with textual ids)",
                                value: "textualNodeAlarms"
                            }, {text: "Single alarm", value: "singleAlarm"}, {
                                text: "Counter",
                                value: "counter"
                            }, {text: "Machine status", value: "status"}],
                            readOnly: false,
                        },
                        ClientName: {
                            name: "Client Name",
                            value: "TotalItems",
                            description: "",
                            type: "editableOption",
                            options: [{text: "TotalItems", value: "TotalItems"}, {
                                text: "GoodItems",
                                value: "GoodItems"
                            }, {text: "RejectedItems", value: "RejectedItems"}],
                            readOnly: false,
                        },
                        ServerName: {
                            name: "Server Name",
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        // ClientDataType: {
                        //     name: "ClientDataType",
                        //     value: "int",
                        //     description:"",
                        //     type: "option",
                        //     options: [{text: "int", value: "int"}, {text: "bool", value: "bool"}, {text: "float", value: "float"}, {text: "string", value: "string"}],
                        //     readOnly: false,
                        //     visible: false,
                        // },
                    }
                }
            ]
        },
        getTemplateMachineStatus() {
            return this.templateVariablesOpcDescription = [
                {
                    visible: true,
                    groupName: "New Variable",
                    isDeletable: true,
                    settings: {
                        VariableType: {
                            name: "Variable Type",
                            value: "status",
                            description: "",
                            type: "option",
                            options: [{
                                text: "Free configurable",
                                value: ""
                            }, {
                                text: "Alarms (In folder with numerical ids)",
                                value: "numericalNodeAlarms"
                            }, {
                                text: "Alarms (In folder with textual ids)",
                                value: "textualNodeAlarms"
                            }, {text: "Single alarm", value: "singleAlarm"}, {
                                text: "Counter",
                                value: "counter"
                            }, {text: "Machine status", value: "status"}],
                            readOnly: false,
                        },
                        ClientName: {
                            name: "Client Name",
                            value: "Status",
                            description: "",
                            type: "editableOption",
                            options: [{text: "Status", value: "Status"}, {
                                text: "TotalItems",
                                value: "TotalItems"
                            }, {text: "GoodItems", value: "GoodItems"}, {
                                text: "RejectedItems",
                                value: "RejectedItems"
                            }],
                            readOnly: false
                        },
                        ServerName: {
                            name: "Server Name",
                            value: "",
                            description: "",
                            type: "string",
                            readOnly: false,
                        },
                        // ClientDataType: {
                        //     name: "ClientDataType",
                        //     value: "int",
                        //     description:"",
                        //     type: "option",
                        //     options: [{text: "int", value: "int"}, {text: "bool", value: "bool"}, {text: "float", value: "float"}, {text: "string", value: "string"}],
                        //     readOnly: false,
                        //     visible: false,
                        // },
                    }
                }
            ]
        },
        getTemplateUnhandled() {
            return this.templateVariablesOpcDescription = [
                {
                    visible: true,
                    groupName: "New Variable",
                    isDeletable: false,
                    unHandledTemplate: true,
                    settings: {
                        ClientName: {
                            name: this.$gettext("Client Name"),
                            value: "Status",
                            description: "",
                            type: "editableOption",
                            options: [{text: "Status", value: "Status"}, {
                                text: "TotalItems",
                                value: "TotalItems"
                            }, {text: "GoodItems", value: "GoodItems"}, {
                                text: "RejectedItems",
                                value: "RejectedItems"
                            }],
                            readOnly: false,
                        },
                    }
                }
            ]
        },
        getOpcDescription() {
            this.opcFileContainer = null;
            if (!this.opcDescription)
                return;
            this.opcFileContainer = this.$utils.detach(this.opcDescription);
            this.opcFileContainer.Devices = [];
            let templateOpcInfoConnection = this.$utils.detach(this.templateOpcDescription);
            let opcInfoConnectionValue = this.opcDescription.Devices[0];
            let templateVariables = [];

            templateOpcInfoConnection[0].settings.Name.value = opcInfoConnectionValue.Name;
            templateOpcInfoConnection[0].settings.EndPoint.value = opcInfoConnectionValue.EndPoint;
            //Fn check for backwards compatibility
            if (opcInfoConnectionValue.Security === 4) {
                opcInfoConnectionValue.Security = 3;
            }
            templateOpcInfoConnection[0].settings.Security.value = opcInfoConnectionValue.Security;
            templateOpcInfoConnection[0].settings.SecurityMode.value = opcInfoConnectionValue.SecurityMode;
            templateOpcInfoConnection[0].settings.TargetNode.value = opcInfoConnectionValue.TargetNode;
            templateOpcInfoConnection[0].settings.User.value = opcInfoConnectionValue.User;
            templateOpcInfoConnection[0].settings.Password.value = opcInfoConnectionValue.Password;

            this.opcDescription = templateOpcInfoConnection;
            this.tmpOpcDescription = this.$utils.detach(this.opcDescription);
            if (opcInfoConnectionValue.hasOwnProperty('Variables')) {
                let opcVariablesValue = opcInfoConnectionValue.Variables;

                for (let i = 0; i < opcVariablesValue.length; i++) {
                    let tmpTemplateVariables = [];
                    if (opcVariablesValue[i].hasOwnProperty('IsAlarm')) {
                        if (opcVariablesValue[i].IsAlarm) {
                            opcVariablesValue[i].VariableType = "singleAlarm"
                        }
                    }
                    switch (Object.isUseful(opcVariablesValue[i].VariableType) ? opcVariablesValue[i].VariableType : "") {
                        //Free configuration
                        case "":
                            if (opcVariablesValue[i].hasOwnProperty('ClientDataType')) {
                                if (opcVariablesValue[i].ClientDataType.toLowerCase() === "extensionobject") {
                                    tmpTemplateVariables = this.getTemplateUnhandled();
                                    tmpTemplateVariables[0].settings = opcVariablesValue[i];
                                } else {
                                    tmpTemplateVariables = this.getTemplateVariables();
                                }
                            } else {
                                tmpTemplateVariables = this.getTemplateVariables();
                            }
                            break;
                        // Alarms config
                        case "numericalNodeAlarms":
                            tmpTemplateVariables = this.getTemplateNumericalIdsAlarms();
                            break;
                        case "textualNodeAlarms":
                            tmpTemplateVariables = this.getTemplateTextualIdsAlarms();
                            break;
                        // Single Alarm
                        case "singleAlarm":
                            tmpTemplateVariables = this.getTemplateSingleAlarm();
                            break;
                        //Counter config
                        case "counter":
                            tmpTemplateVariables = this.getTemplateCounter();
                            break;
                        //Machine status config
                        case "status":
                            tmpTemplateVariables = this.getTemplateMachineStatus();
                            break;
                        default:
                            tmpTemplateVariables = this.getTemplateUnhandled();
                            tmpTemplateVariables[0].settings = opcVariablesValue[i];
                            break;
                    }
                    if (i > 0) {
                        templateVariables = templateVariables.concat(tmpTemplateVariables)
                    } else {
                        templateVariables = tmpTemplateVariables;
                    }
                    //
                    // if (typeof opcVariablesValue[i].ClientName === 'string') {
                    //     opcVariablesValue[i].ClientName = {
                    //         text: opcVariablesValue[i].ClientName,
                    //         value: opcVariablesValue[i].ClientName
                    //     };
                    // }

                    // templateVariables[i].groupName = "Variable: " + opcVariablesValue[i].ClientName.value;
                    templateVariables[i].groupName = "Variable: " + opcVariablesValue[i].ClientName;
                    if (templateVariables[i].unHandledTemplate) {
                        templateVariables[i].groupName += " (" + this.$gettext("This Variable can't be managed from UI") + ")"
                    }

                    // //FN: Do not delete , if needed uncomment wih other parts linked
                    // if(opcVariablesValue[i].hasOwnProperty('IsAlarm')) {
                    //     if (opcVariablesValue[i].IsAlarm) {
                    //         templateVariables[i].settings.Destination.visible = false;
                    //         templateVariables[i].settings.Notify.visible = false;
                    //
                    //     }
                    // }

                    if (!templateVariables[i].unHandledTemplate) {
                        for (const [item, value] of Object.entries(templateVariables[i].settings)) {
                            if (Object.isUseful(opcVariablesValue[i][item]))
                                templateVariables[i].settings[item].value = opcVariablesValue[i][item];
                        }
                    }

                    //Do not delete , if needed uncomment wih other parts linked

                    // templateVariables[i].settings.Destination.value = opcVariablesValue[i].Destination === "alarm";
                    // templateVariables[i].settings.Destination.value = opcVariablesValue[i].Destination;
                    // templateVariables[i].settings.Notify.value = opcVariablesValue[i].Notify;

                    // end block of code for parameters : Destination/alarm and Notify
                    if (!templateVariables[i].unHandledTemplate) {
                        this.opcDescription = this.opcDescription.concat(templateVariables[i]);
                        this.tmpOpcDescription = this.tmpOpcDescription.concat(templateVariables[i]);
                    } else {
                        this.tmpOpcDescription = this.tmpOpcDescription.concat(templateVariables[i]);
                        let tmp = this.$utils.detach(templateVariables[i]);
                        // let set = this.getTemplateUnhandled().settings
                        tmp.settings = {};//""
                        this.opcDescription = this.opcDescription.concat(tmp);
                    }
                }
            }
        },
        addDevice() {
            let self = this;
            this.$refs.inputBox.ShowInputBox(this.$gettext("Enter identifier of new device. Device will appear with this name in {appName}. Allows only lower-case letters, numbers and '-' as separator.").format({appName: this.$config.appName}), "", "", "OK", function (value) {
                self.newDevice(value)
            }, "Cancel", null);
        },
        newDevice(name) {
            try {
                if (!this.machineNameValidator(name) || this.devices.some(device => device.Name === name)) {
                    this.$root.showErrorNotification(this.$gettext("Device with provided name already exist"), true);
                    return;
                }

                this.$root.setLoading(true, "Creating device...");
                this.device = name;
                this.opcDescription = this.$utils.detach(this.templateOpcDescription);
                this.opcDescription[0].settings.Name.value = this.device;
                this.opcFileContainer = null;

                this.actionTrail = this.$audits.items().opcUaDeviceAdded;
                this.previousValTrail = "";
                this.nextValTrail = name;

                this.checkOpcFileContainer();
                this.save();
                let self = this;
                setTimeout(() => {
                    self.loadConfiguredDevicesList();
                    self.getDeviceOpcDescriptions({Name: name, Type: "AvOpcUa", Status: 0});
                    self.$root.setLoading(false);
                }, 3000)
            } catch (e) {
                this.$root.setLoading(false);
            }
        },
        checkOpcFileContainer() {
            if (!this.opcFileContainer) {
                this.opcFileContainer = {};
            }
            //Delete very old legacy settings (if any)
            delete this.opcFileContainer.CollectorType;
            delete this.opcFileContainer.TelegrafIp;
            delete this.opcFileContainer.CollectorHost;
            delete this.opcFileContainer.CollectorPort;
            delete this.opcFileContainer.TelegrafBasePort;
            delete this.opcFileContainer.CollectorIndex;
            delete this.opcFileContainer.MaxBulkSize;
            delete this.opcFileContainer.NoTarget;
            if (!Object.isUseful(this.opcFileContainer.LogLevel))
                this.opcFileContainer.LogLevel = "INFO";

            if (!Object.isUseful(this.opcFileContainer.StatusReport))
                this.opcFileContainer.StatusReport = {
                    Host: "127.0.0.1",
                    Port: 9200,
                    Type: "Elasticsearch"
                };

            //This is not currently migrating old "strange" configurations like elastic-bulk
            //into new collectors format. Those cases shall be handled manually
            if (!Object.isUseful(this.opcFileContainer.Collectors)) {
                this.opcFileContainer.Collectors = {
                    Default: {
                        Type: "Influx",
                        Ip: "127.0.0.1",
                        Port: 8095
                    },
                    Alarms: {
                        Type: "Influx",
                        Ip: "127.0.0.1",
                        Port: 8095
                    }
                }
            }

            this.opcFileContainer.Devices = [];
        },
        addVariables() {
            if (Array.isUseful(this.opcDescription)) {
                this.variablesDescription = this.getTemplateVariables();
                this.opcDescription = this.opcDescription.concat(this.variablesDescription);
            }
        },
        remapHeaderToFieldVariable(value) {
            let result = value;
            switch (value) {
                case "VariableNameAvionics":
                    result = "ClientName";
                    break;
                case "DataType":
                    result = "ClientDataType";
                    break;
                case "VariableNameOpcUa":
                    result = "ServerName";
                    break;
                case "MachineOrParentGroup":
                    result = "Machine";
                    break;
            }

            return result;
        },
        remapValueVariableType(value) {
            let result = "";
            switch (value) {
                case "Free configurable":
                    break;
                case "Alarms (In folder with numerical ids)":
                    result = "numericalNodeAlarms";
                    break;
                case "Alarms (In folder with textual ids)":
                    result = "textualNodeAlarms";
                    break;
                case "Single alarm":
                    result = "singleAlarm";
                    break;
                case "Counter":
                    result = "counter";
                    break;
                case "Machine status":
                    result = "status";
                    break;
            }

            return result;
        },
        remapToTextVariableType(value) {
            let result = "";
            switch (value) {
                case "":
                    result = "Free configurable";
                    break;
                case "numericalNodeAlarms":
                    result = "Alarms (In folder with numerical ids)";
                    break;
                case "textualNodeAlarms":
                    result = "Alarms (In folder with textual ids)";
                    break;
                case "singleAlarm":
                    result = "Single alarm";
                    break;
                case "counter":
                    result = "Counter";
                    break;
                case "status":
                    result = "Machine status";
                    break;
            }

            return result;
        },
        mapOut(sourceObject, removeKeys = []) {
            let prefixClientName = "";
            const sourceKeys = Object.keys(sourceObject);
            const returnKeys = sourceKeys.filter(k => !removeKeys.includes(k));
            let returnObject = {};
            let valueVariableType = this.remapValueVariableType(String(sourceObject["VariableType"]));
            switch (valueVariableType) {
                //Free configuration
                case "":
                    returnObject = this.getTemplateVariables()[0];
                    break;
                // Alarms config
                case "numericalNodeAlarms":
                    returnObject = this.getTemplateNumericalIdsAlarms()[0];
                    break;
                case "textualNodeAlarms":
                    returnObject = this.getTemplateTextualIdsAlarms()[0];
                    break;
                // Single Alarm
                case "singleAlarm":
                    returnObject = this.getTemplateSingleAlarm()[0];
                    break;
                //Counter config
                case "counter":
                    returnObject = this.getTemplateCounter()[0];
                    break;
                //Machine status config
                case "status":
                    returnObject = this.getTemplateMachineStatus()[0];
                    break;
            }

            returnKeys.forEach(k => {
                let field = this.remapHeaderToFieldVariable(k);
                if (returnObject.settings[field]) {
                    switch (k) {
                        case "VariableType":
                            if (valueVariableType === '')
                                this.showImportWarning = true;
                            returnObject.settings[field].value = valueVariableType;
                            break;
                        case "DataType":
                            returnObject.settings[field].value = String(sourceObject[k]);
                            break;
                        case "VariableNameAvionics":
                            returnObject.settings[field].value = prefixClientName + sourceObject[k];
                            returnObject.groupName = "Variable: " + sourceObject[k];
                            break;
                        case "VariableNameOpcUa":
                            returnObject.settings[field].value = String(sourceObject[k]);
                            break;
                        case "MachineOrParentGroup":
                            prefixClientName = String(sourceObject[k]) + ".";
                            break;
                    }
                }
            });

            return returnObject;
        },
        filterImportedData(importedData) {
            if (importedData.records.length > 0) {
                //The headers array from excel could have less fields than records array
                importedData.headers = Object.keys(importedData.records[0]);
                // Taking the case badheaders < goodHeaders as the best case
                let removeKeys = importedData.headers.filter(x => !this.fieldsExportImport.includes(x));
                if (removeKeys.length === importedData.headers.length) {
                    this.$root.showErrorNotification(this.$gettext("File not imported. There are values only in non-valid headers."), true);
                } else {
                    const newArray = importedData.records.map(obj => this.mapOut(obj, removeKeys));
                    this.opcDescription.splice(1);
                    this.opcDescription = this.opcDescription.concat(newArray);
                    if (this.showImportWarning) {
                        this.$root.showWarningNotification(this.$gettext("One or more records are without VariableType.<br/>The variable was created with <br/>VariableType: Free configurable."), true);
                        this.showImportWarning = false;
                    }
                    this.actionTrail = this.$audits.items().opcUaConfigurationImported;
                    this.previousValTrail = "";
                    this.nextValTrail = "";
                    this.actionTrailId = this.device;
                    this.fileImported = true;
                    this.save();
                }
            } else {
                this.$root.showWarningNotification(this.$gettext("Empty file not imported."), true);
            }
        },
        exportOpc(format) {
            let opcVariables = [];
            let tmpOpcDescription = [];
            let k = 0;
            for (let i = 0; i < this.opcDescription.length; i++) {
                if (this.opcDescription[i].unHandledTemplate) {
                    this.$root.showWarningNotification(this.$gettext("This variable cannot be exported: {0}").format(this.opcDescription[i].settings.ClientName), true);
                } else {
                    tmpOpcDescription[k] = this.$utils.detach(this.opcDescription[i]);
                    k++;
                }
            }


            if (tmpOpcDescription.length > 1) {
                for (let i = 1; i < tmpOpcDescription.length; i++) {
                    opcVariables.push({});

                    for (const [item, value] of Object.entries(tmpOpcDescription[i].settings)) {
                        if (item === 'ClientName') {
                            opcVariables[i - 1]["MachineOrParentGroup"] = "";
                            let tmp = typeof value.value === 'string' ? value.value.split(".") : value.value.text.split(".");
                            for (let j = 0; j < tmp.length - 1; j++) {
                                opcVariables[i - 1]["MachineOrParentGroup"] = j === tmp.length - 2 ? opcVariables[i - 1]["MachineOrParentGroup"].concat(tmp[j]) : opcVariables[i - 1]["MachineOrParentGroup"].concat(tmp[j]) + ".";
                            }
                            opcVariables[i - 1][item] = tmp[tmp.length - 1];
                        } else if (item === 'VariableType') {
                            opcVariables[i - 1][item] = this.remapToTextVariableType(value.value);
                        } else {
                            opcVariables[i - 1][item] = value.value;
                        }
                    }
                }
                let data = this.$reportingService.exportTemplate(this.fieldsExport, opcVariables);
                let template = require('@/export-templates/opc-ua-export-template.xlsx');
                template = template.split(",", 2);
                let request = {
                    type: format,
                    data: [{
                        dataFormat: "datamatrix",
                        dataMatrix: data,
                    }],
                };
                this.$dynamicElements.ExportVisualization(format, request, template[1], "Variables", true)
                    .then(r => {
                        const link = document.createElement('a');
                        link.href = r;
                        link.setAttribute('download', "opcVariables" + "." + format);
                        document.body.appendChild(link);
                        link.click();
                    });
            } else {
                this.$root.showWarningNotification(this.$gettext("Empty file not exported. Add new variables before."), true);
            }
        },
        duplicateKey() {
            let self = this;
            if (this.opcFileContainer.Devices[0].Variables) {
                for (let i = 0; i < this.opcFileContainer.Devices[0].Variables.length; i++) {
                    let v = this.opcFileContainer.Devices[0].Variables[i];
                    if (self.opcFileContainer.Devices[0].Variables.filter(vv => v.ClientName === vv.ClientName).length > 1) {
                        return v.ClientName
                    }
                }
            }
            return undefined
        },
        save(groupName, settingName, actionObj) {
            let self = this;
            this.checkOpcFileContainer();
            this.opcFileContainer.Devices.push({});
            if (Object.isUseful(actionObj) && actionObj.action !== "deleted") {
                if (settingName === 'Monitored' || settingName === 'Subscribed') {
                    if (this.opcDescription[groupName].settings.Monitored.value && this.opcDescription[groupName].settings.Subscribed.value) {
                        {
                            this.$root.showErrorNotification(this.$gettext("Conflicting Monitored and Subscribed flags. Change is not saved."), true);
                            return;
                        }
                    }
                }
                if (settingName === 'VariableType') {
                    //tmp variables used to save the old configuration
                    let clientNameValueTmp = this.opcDescription[groupName].settings.ClientName.value;
                    let serverNameValueTmp = this.opcDescription[groupName].settings.ServerName.value;

                    switch (actionObj.value) {
                        //Free configuration
                        case "":
                            this.opcDescription[groupName].settings = this.getTemplateVariables()[0].settings;
                            break;
                        // Alarms config
                        case "numericalNodeAlarms":
                            this.opcDescription[groupName].settings = this.getTemplateNumericalIdsAlarms()[0].settings;
                            break;
                        case "textualNodeAlarms":
                            this.opcDescription[groupName].settings = this.getTemplateTextualIdsAlarms()[0].settings;
                            break;
                        //Single Alarm config
                        case "singleAlarm":
                            this.opcDescription[groupName].settings = this.getTemplateSingleAlarm()[0].settings;
                            break;
                        //Counter config
                        case "counter":
                            this.opcDescription[groupName].settings = this.getTemplateCounter()[0].settings;
                            break;
                        //Machine status config
                        case "status":
                            this.opcDescription[groupName].settings = this.getTemplateMachineStatus()[0].settings;
                            break;
                    }
                    //check if  old clientNameValue configuration (ie: status for Free configuration) is valid for COUNTER configuration
                    this.opcDescription[groupName].settings.ClientName.value = actionObj.value === "counter" && clientNameValueTmp.value === "Status" ? this.opcDescription[groupName].settings.ClientName.value : clientNameValueTmp;
                    this.opcDescription[groupName].settings.ServerName.value = serverNameValueTmp;
                }

                let oldGroupName = this.opcDescription[groupName].groupName.replace("Variable:", "");
                if (this.opcDescription[groupName].settings.ClientName) {
                    this.opcDescription[groupName].groupName = typeof this.opcDescription[groupName].settings.ClientName.value === 'string' ? "Variable: " + this.opcDescription[groupName].settings.ClientName.value : "Variable: " + this.opcDescription[groupName].settings.ClientName.value.text;

                }

                //Trails
                //new variable
                this.previousValTrail = "";
                if (oldGroupName === 'New Variable') {
                    this.actionTrail = this.$audits.items().opcUaVariableAdded;
                    this.actionTrailId = this.device + "." + "ClientName";
                    // this.nextValTrail = this.opcDescription[groupName].settings["ClientName"].value;
                    this.nextValTrail = this.opcDescription[groupName].settings["ClientName"].value.hasOwnProperty("value") ? this.opcDescription[groupName].settings["ClientName"].value.value : this.opcDescription[groupName].settings["ClientName"].value;
                } else {
                    this.actionTrail = this.$audits.items().opcUaVariableEdited;
                    this.actionTrailId = this.device + "." + settingName;
                    if (settingName === 'ClientName')
                        this.previousValTrail = oldGroupName;
                    this.nextValTrail = this.opcDescription[groupName].settings[settingName].value.hasOwnProperty("value") ? this.opcDescription[groupName].settings[settingName].value.value : this.opcDescription[groupName].settings[settingName].value;
                }


            } else if (Object.isUseful(actionObj) && actionObj.action === "deleted") {
                this.actionTrail = this.$audits.items().opcUaVariableDeleted;
                this.actionTrailId = this.device + "." + "ClientName";
                this.nextValTrail = "";
                this.previousValTrail = settingName.replace("Variable:", "");
            }

            for (let i = 0; i < this.opcDescription.length; i++) {
                if (i === 1) {
                    this.opcFileContainer.Devices[0]['Variables'] = [{}]
                } else if (i > 1) {
                    this.opcFileContainer.Devices[0]['Variables'].push({})
                }
                if (!this.opcDescription[i].unHandledTemplate) {
                    for (const [item, value] of Object.entries(this.opcDescription[i].settings)) {
                        if (i === 0) {
                            this.opcFileContainer.Devices[0][item] = value.value
                        } else {
                            if (item === 'Destination') {
                                this.opcFileContainer.Devices[0].Variables[i - 1][item] = value.value ? "alarms" : ""
                            } else if (item === 'ClientName') {
                                this.opcFileContainer.Devices[0].Variables[i - 1][item] = typeof value.value === 'string' ? value.value : value.value.text
                            } else {
                                debugger
                                if (value.value !== "" && value.value !== false) {
                                    this.opcFileContainer.Devices[0].Variables[i - 1][item] = value.value
                                }
                            }
                        }
                    }
                } else { //Manage unHandledTemplate saving
                    this.opcFileContainer.Devices[0].Variables[i - 1] = this.tmpOpcDescription[i].settings
                }
            }

            if (this.duplicateKey()) {
                this.$root.showErrorNotification(this.$gettext("Attention! {0} name has already been entered for another variable").format(this.duplicateKey()), true);
                return
            }

            this.$settings.save(this.opcFileContainer, this.$settings.OpcDescriptions, this.device, "json", false, this.$settings.InstanceScope)
                .then(() => {
                    this.$audits.save(this.$root.userName, this.actionTrail, this.previousValTrail, this.nextValTrail, this.actionTrailId)
                        .then(() => {
                            if (this.fileImported)
                                this.$root.showInfoNotification(this.$gettext("New OPC UA configuration imported."), true);
                            this.fileImported = false;
                        })
                        .catch(() => {
                            debugger
                            this.$root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                        });
                })
                .catch(() => {
                    self.$root.showErrorNotification("Error saving opc devices descriptions to DB.", true);
                })
                .finally(() => {
                    // let device = self.devices.filter(device => device.Name === self.device);
                    self.loadConfiguredDevicesList();
                    // self.getDeviceOpcDescriptions(device)
                })
        },
        deployProcess() {
            let self = this;
            self.$root.setLoading(true, "Deploying device..");
            return new Promise((resolve, reject) => {
                ElasticBasedBootstrap.deployNewProcess(self.detailedDevice)
                    .then(() => {
                        self.$root.showInfoNotification(self.$gettext("Success deploying opc device."), true);
                        self.processControlItems.find(item => item.title === 'Deploy').show = false;
                        self.processControlItems.find(item => item.title === 'Relaunch').show = true;
                        self.processControlItems.find(item => item.title === 'Suspend').show = true;
                        self.status = 1;
                        self.$root.setLoading(false);
                        self.$audits.save(self.$root.userName, this.$audits.items().opcUaDeviceDeployed, "", "", self.detailedDevice.Name)
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                        setTimeout(() => {
                            self.loadProcessesList()
                        }, 4000);
                        resolve(true);
                    })
                    .catch(err => {
                        self.$root.showErrorNotification(self.$gettext("Error deploying opc device."), true);
                        reject(false);
                    })
            });
        },
        relaunchProcess() {
            let self = this;
            return new Promise((resolve, reject) => {
                ElasticBasedBootstrap.relaunchProcess(self.detailedDevice)
                    .then(() => {
                        self.$root.showInfoNotification(self.$gettext("Success relaunching opc device."), true);
                        self.$audits.save(self.$root.userName, this.$audits.items().opcUaDeviceRelaunched, "", "", self.detailedDevice.Name)
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                        resolve(true);
                    })
                    .catch(err => {
                        self.$root.showErrorNotification(this.$gettext("Error relaunching opc device."), true);
                        reject(false);
                    })
            });
        },
        suspendProcess() {
            let self = this;
            return new Promise((resolve, reject) => {
                ElasticBasedBootstrap.stopProcess(self.detailedDevice)
                    .then(() => {
                        self.$root.showInfoNotification(self.$gettext("Success suspending opc device."), true);
                        self.processControlItems.find(item => item.title === 'Deploy').show = true;
                        self.processControlItems.find(item => item.title === 'Relaunch').show = false;
                        self.processControlItems.find(item => item.title === 'Suspend').show = false;
                        self.status = 0;
                        self.$audits.save(self.$root.userName, this.$audits.items().opcUaDeviceSuspended, "", "", self.detailedDevice.Name)
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                        resolve(true);
                    })
                    .catch(err => {
                        self.$root.showErrorNotification(this.$gettext("Error suspending opc device."), true);
                        reject(false);
                    })
            });
        },
        functionCall(name) {
            this[name]();
        },
        loadProcessesList() {
            let self = this;
            self.$processesStatus.getProcessesStatusList()
                .then(result => {
                    result.forEach(item => {
                        self.dataCollection.push({
                            timestamp: item["@timestamp"].format(),
                            process: this.$processesStatus.getProcessName(item.microserviceName),
                            type: this.$processesStatus.getProcessType(item.microserviceName),
                            status: item.isRunning ? "Ok" : "Error",
                            statusString: item["ExtraInfo"],
                            build: item.Version,
                        });
                    });
                    this.loadConfiguredDevicesList();
                })
        }
    }
}
</script>

<style scoped>
.import-btn >>> .upload-btn {
    margin-left: 0 !important;
}

.import-btn >>> .btn {
    padding-right: 0 !important;
}

.import-btn-multi-rows {
    margin-left: 16px !important;
    position: relative;
    left: 5px;
}

.import-btn-multi-rows >>> .btn {
    padding-left: 0 !important;
}

</style>