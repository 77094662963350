
export default ({ app, router, store, Vue }) => {
  Vue.prototype.$buttonVisible = function (section, action) {
    if (Vue.prototype.$isEmpty(Vue.prototype.$store.state.shared.modules)) {
      return false
    }
    if (Vue.prototype.$isEmpty(Vue.prototype.$store.state.shared.modules[section])) {
      return false
    }
    for (let i = 0; i < Vue.prototype.$store.state.shared.modules[section].length; i++) {
      if (Vue.prototype.$store.state.shared.modules[section][i].Action === action) {
        return true
      }
    }
    return false
  }
  Vue.prototype.$buttonDisabled = function (section, action) {
    if (Vue.prototype.$isEmpty(Vue.prototype.$store.state.shared.modules) || Vue.prototype.$isEmpty(Vue.prototype.$store.state.shared.modules[section])) {
      return false
    }
    for (let i = 0; i < Vue.prototype.$store.state.shared.modules[section].length; i++) {
      if (Vue.prototype.$store.state.shared.modules[section][i].Action === action) {
        return Vue.prototype.$store.state.shared.modules[section][i].Disabled
      }
    }
    return false
  }
  Vue.prototype.$buttonDisabledTooltip = function (section, action) {
    if (Vue.prototype.$isEmpty(Vue.prototype.$store.state.shared.modules) || Vue.prototype.$isEmpty(Vue.prototype.$store.state.shared.modules[section])) {
      return ''
    }
    for (let i = 0; i < Vue.prototype.$store.state.shared.modules[section].length; i++) {
      if (Vue.prototype.$store.state.shared.modules[section][i].Action === action) {
        return Vue.prototype.$store.state.shared.modules[section][i].DisabledTooltip
      }
    }
    return ''
  }
  Vue.prototype.waitForPageLoading = async function(){
    while (!store.state.commonStore.pageReady) {
      await new Promise(resolve => setTimeout(resolve, 10))
    }
  }
  Vue.prototype.isPageLoaded = async function(){
    return store.state.commonStore.pageReady
  }
  Vue.prototype.setPageInLoading = function(){
    Vue.prototype.$store.dispatch('setPageReady', false)
  }
  Vue.prototype.setPageLoadingCompleted = function(){
    Vue.prototype.$store.dispatch('setPageReady', true)
  }
}
