<template>
    <DynamicElementBase>
        <v-layout column fill-height>
            <LineChart ref="graph" style="width: 100%; height: 100%" v-if="loaded" :chartdata="dataCollection.chartData" :options="options"></LineChart>
        </v-layout>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
    import LineChart from '@/components/graphics/LineChart.vue'
    import BarChart from '@/components/graphics/BarChart.vue'
    import JsUtils from '@/api/jsutils'
    import DateTimeUtils from "@/api/datetimeutils";

    let traceColors = [
        "blue",
        "red",
        "purple",
        "orange",
        "green",
        "pink",
        "brown",
        "yellow",
        "indigo",
        "blue-grey"
    ];

    const statusBarOffset = -1000;

    export default {
        name: "WidgetProductionTrend",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            LineChart,
            BarChart,
        },
        data () {
            return {
                zoomOrPanned: false,
                RecipeId: "",
                QuantityToProduce: 0,
                targetOee: 0.6,
                recipeSpeeds: [],
                recipeSpeed: 0,
                visualizationTargetsIndexes: { "graph": 0, "label 1": 1, "label 2": 2, "label 3": 3, "label 4": 4, "graph max": 5, "theoretical trend start": 6, "production start": 7 },
                dataCollection: null,
                loaded: false,
                labelsAreaVisible: false,
                labelsPosition: "right",
                //Graphs stuff
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false,
                    elements: {
                        line: {
                            fill: true,
                            tension: 0
                        }
                    },
                    propagate: false,
                    legend: {
                        display: true,
                        position: "top"
                    },
                    scales: {
                        x: {
                            type: "linear",
                            time:{

                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Production hours'
                            },
                            grid: {
                                display: true
                            },
                            ticks:{
                                stepSize: 0.01,
                                maxTickLimit: 100,
                                display: true,
                                callback: function(value, index, values) {
                                    return "{0}h {1}m".format(Math.floor(value), Math.abs(Math.round((value * 60) % 60 * 100) / 100));
                                },
                                source: 'data'
                            },
                        },
                        y: {
                            min: statusBarOffset - 1000,
                            max: undefined,
                            ticks: {
                                callback: function(label, index, labels) {
                                    // when the floored value is the same as the value we have a whole number
                                    if (label === statusBarOffset)
                                        return "Status"
                                    else if(label < 0)
                                        return ""
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                }
                            }
                        }
                    },
                    plugins: {
                        zoom: {
                            pan: {
                                enabled: true,
                                mode: 'x',
                                onPanComplete: null
                            },
                            zoom: {
                                enabled: true,
                                mode: 'x',
                                onZoomComplete: null
                            }
                        },
                        legend: {
                            labels: {
                                filter: (legendItem, chartData) => (legendItem.text === 'Production progress' || legendItem.text === 'Expected trend' || legendItem.text === 'Ideal trend')
                            }
                        },
                        tooltip: {
                            callbacks: {
                                title: function (context) {
                                    if (context[0].raw.y === statusBarOffset){
                                        let minutes = Math.floor(context[0].raw.x * 60);
                                        let start = new Date(context[0].dataset.start).format("dd/MM/yyyy HH:mm:ss");
                                        let stop = new Date(context[0].dataset.stop).format("dd/MM/yyyy HH:mm:ss");
                                        return "Duration: " + minutes + " minutes\nStart: " + start +  "\nStop: " + stop
                                    }
                                    else
                                        return context[0].label
                                },
                                label: function (context) {
                                    if (context.raw.y === statusBarOffset)
                                        return context.dataset.label;
                                    else
                                        return context.dataset.label + ": " + context.formattedValue;
                                }
                            }
                        },
                        datalabels: {
                            display: false,
                            color: 'black',
                            anchor: 'end',
                            align: 'top',
                            offset: 5,
                            font: {
                                size: 16
                            }
                        }
                    }
                },
            }
        },
        props: {
        },
        mounted () {
            this.options.plugins.zoom.pan.onPanComplete = this.onPanOrZoom;
            this.options.plugins.zoom.zoom.onZoomComplete = this.onPanOrZoom;
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], false));
            this.dataExplorationMode.autoDataSourcing = true;
            this.dataExplorationMode.deviceSelectMode = true;
            this.properties.forcedDataRefreshInterval = 10;

            this.visualizationTweaks = [
                {
                    name: this.$gettext("Show legend"),
                    id: "ShowLegend",
                    type: "bool",
                    default: function() {
                        return true;
                    }
                },
                {
                    name: this.$gettext("Legend position"),
                    id: "LegendPosition",
                    type: "option",
                    options: [
                        {text: this.$gettext('left'), value: 'left'},
                        {text: this.$gettext('top'), value: 'top'},
                        {text: this.$gettext('right'), value: 'right'},
                        {text: this.$gettext('bottom'), value: 'bottom'}
                    ],
                    default: function() {
                        return this.options[1];
                    }
                },
                {
                    name: this.$gettext("Text position"),
                    id: "TextPosition",
                    type: "option",
                    options: [
                        {text: this.$gettext('right'), value: 'right'},
                        {text: this.$gettext('bottom'), value: 'bottom'}
                    ],
                    default: function() {
                        return this.options[0];
                    }
                }
            ];

            this.visualizationTargets = [
                { show: "graph",   id: this.visualizationTargetsIndexes.graph, default: true },
                { show: "label 1", id: this.visualizationTargetsIndexes["label 1"] },
                { show: "label 2", id: this.visualizationTargetsIndexes["label 2"] },
                { show: "label 3", id: this.visualizationTargetsIndexes["label 3"] },
                { show: "label 4", id: this.visualizationTargetsIndexes["label 4"] },
                { show: "theoretical trend start time", id: this.visualizationTargetsIndexes["theoretical trend start"] },
                { show: "production start point", id: this.visualizationTargetsIndexes["production start"] }
            ];

            let settings = this.$settings.getLineSettings();
            if(settings && settings.targetOEE)
                this.targetOee = settings.targetOEE / 100;
            else this.targetOee = 0.6;
            if(settings && Array.isUseful(settings.lineSpeeds))
                this.recipeSpeeds = settings.lineSpeeds;
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    if (this.visualizationTweaks.length > 0) {
                        this.options.legend.display = this.visualizationTweaks[0].value;
                        this.options.legend.position = this.visualizationTweaks[1].value;
                        this.labelsPosition = this.visualizationTweaks[2].value;
                        if(this.$refs.graph)
                            this.$refs.graph.refreshGraph();
                    }
                    //Update visualization tweaks values and save widget
                    this.saveTweaks();
                },
                deep: true,
            },
            devices() {
                if(!this.loading)
                    this.$root.showDialogBox(this.$gettext("Do you want to automatically create data sources for production trend?"), null, this.$gettext("Yes"), this.createDataItems, this.$gettext("No"), null);

                if (Array.isArray(this.devices) && Array.isUseful(this.devices))
                    this.getStandardVariableName(this.devices[0]);
            }
        },
        computed: {
            devices() {
                return this.properties.selectedDevices;
            },
            zoomOrPannedNew () {
                if (this.$refs.graph) {
                    return this.$refs.graph.zoomOrPanned()
                }
                else return false
            }
        },
        methods: {
            createDataItems() {

                this.filterItems.clear();
                this.dataItems.clear();
                this.aggregationItems.clear();

                let device = this.properties.selectedDevices[0].device;
                let dataIndex = this.properties.selectedDevices[0].dataIndex;

                this.filterItems.push(this.$workorders.getProductionCountersWorkordersFilter());
                let woFilterId = this.$workorders.setProductionCountersWorkordersFilter(this.filterItems.last());
                this.filterItems.push({
                    index: dataIndex,
                    root: "TimeTracking",
                    name: 'Activity',
                    type: 'integer',
                    selectedForFiltering: true,
                    filters: [{
                        conditions: [{operator: '=', value: 1}],
                        defaultName: this.$gettext("Production start"),
                        name: this.$gettext("Production start"),
                        enabled: true,
                        filterId: "Production start"
                    }]
                });


                this.dataItems.push({
                    index: dataIndex,
                    root: 'Line',
                    name: 'WorkorderID',
                    type: 'keyword',
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: this.$defines.allAggregations.first.id,
                            filters: [ woFilterId ],
                            target: this.visualizationTargetsIndexes["theoretical trend start"],
                            defaultName: "Workorder",
                            name: "Workorder",
                            enabled: true,
                            timeless: true,
                        },
                    ],
                },{
                    index: dataIndex,
                    root: device,
                    name: 'TotalItems_Delta',
                    type: 'integer',
                    selectedForVisualization: true,
                    autoGeneratedTotalItems: true,
                    representations: [
                        {
                            type: this.$defines.allAggregations.first.id,
                            filters: [ "Production start", woFilterId ],
                            target: this.visualizationTargetsIndexes["production start"],
                            defaultName: "Production start",
                            name: "Production start",
                            enabled: true,
                            timeless: true,
                        },
                        {
                            type: this.$defines.allAggregations.cumusum.id,
                            filters: [ woFilterId ],
                            target: this.visualizationTargetsIndexes.graph,
                            aggregationWindow: -1,
                            defaultName: "Production progress",
                            name: "Production progress",
                            enabled: true,
                            timeless: true,
                        },
                    ],
                }, {
                    index: dataIndex,
                    root: 'Line',
                    name: 'RecipeId',
                    type: 'keyword',
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: this.$defines.allAggregations.last.id,
                            filters: [  ],
                            target: 501,
                            defaultName: "CurrentRecipe",
                            name: "CurrentRecipe",
                            enabled: true,
                            timeless: true,
                        },
                ]}, {
                    index: 'BATCH_' + dataIndex,
                    root: 'Line',
                    name: 'QuantityToProduce',
                    type: 'OEEComponent',
                    indexDisplayName: 'Main production index (5 seconds)',
                    key: 'QuantityProducedBATCH_production counters@5sLine19',
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: this.$defines.allAggregations.oeeraw.id,
                            filters: [  ],
                            target: 502,
                            defaultName: "qty",
                            name: "qty",
                            enabled: true,
                            timeless: true,
                        },
                    ]});


                this.$emit('dataItemsUpdated');
            },
            onPanOrZoom() {
                this.zoomOrPanned = true;
            },
            draw(dataValues) {

                let timeStart = new Date();
                let graphToBeRefreshed = false;

                let thoreticalTrend = {
                    start: -1,
                    end: -1,
                    value: 0,
                    valid: false,
                    slope: 0
                };

                let expectedTrend = {
                    start: -1,
                    end: -1,
                    value: 0,
                    valid: false,
                    slope: 0
                };

                let firstTimeSample = "";
                let lastTimeSample = "";

                //Clear data collection
                let newDataCollection = {
                    chartData: {
                        labels: [],
                        datasets: []
                    },
                    value1: { label: "", data: ""},
                    value2: { label: "", data: ""},
                    value3: { label: "", data: ""},
                    value4: { label: "", data: ""},
                };

                if(dataValues === null) {
                    this.dataCollection = null;
                    return;
                }

                let workorderStartPoint = -1;

                //Unwrap graph targeted data
                let self = this;
                dataValues.forEach(function (dataSet, index) {
                    if(dataSet) {
                        if(dataSet.target === self.visualizationTargetsIndexes.graph) {
                            if(dataSet.data) {
                                newDataCollection.chartData.datasets.push({label: dataSet.label, data: dataSet.data});
                            }
                        }
                        else if(dataSet.target === self.visualizationTargetsIndexes["theoretical trend start"]) {
                            thoreticalTrend.start = Array.isUseful(dataSet.data) ? dataSet.data.last().x : "";
                            workorderStartPoint = thoreticalTrend.start;
                            thoreticalTrend.start = 0;
                            thoreticalTrend.value = self.QuantityToProduce;
                            self.recipeSpeed = 0;
                            for(let i = 0 ; i < self.recipeSpeeds.length ; i++) {
                                if (self.$utils.matchWildcardString(self.RecipeId, self.recipeSpeeds[i].recipe))
                                    self.recipeSpeed = self.recipeSpeeds[i][self.properties.selectedDevices[0].device];
                            }
                            if(self.recipeSpeed !== 0) {
                                thoreticalTrend.end = ((thoreticalTrend.value / self.recipeSpeed) / self.targetOee) / 60;
                            }
                            else {
                                if(self.RecipeId)
                                    self.setError(self.$gettext("Recipe {0} in current workorder has no nominal speed configured. Please add it in Line Settings").format(self.RecipeId));
                                else
                                    self.setError(self.$gettext("No recipe or workorder scheduled on the line"));
                                return
                            }
                            expectedTrend.end = thoreticalTrend.end;
                            expectedTrend.value = thoreticalTrend.value;
                        }
                        else if(dataSet.target === self.visualizationTargetsIndexes["production start"]) {
                            expectedTrend.start = Array.isUseful(dataSet.data) ? dataSet.data.last().x - workorderStartPoint : 0;//TODO
                            expectedTrend.start /= 3600000;
                        }
                    }
                });

                if(workorderStartPoint > -1 && Array.isUseful(newDataCollection.chartData.datasets) && Array.isUseful(newDataCollection.chartData.datasets[0].data)) {
                    for(let index = 0 ; index < newDataCollection.chartData.datasets[0].data.length ; index++) {
                        newDataCollection.chartData.datasets[0].data[index].x = (index * 60) / 3600;
                    }
                }

                //Apply trend line color coding (if any)
                if(expectedTrend.start > -1 && expectedTrend.end > -1 && expectedTrend.value) {
                    try {
                        // expectedTrend.start = new Date(expectedTrend.start);
                        // expectedTrend.end = Date.parse(expectedTrend.end);
                        if(!isNaN(expectedTrend.start) && !isNaN(expectedTrend.end)) {
                            expectedTrend.slope = expectedTrend.value / (expectedTrend.end - expectedTrend.start);
                            newDataCollection.chartData.datasets.push({
                                label: "Expected trend",
                                backgroundColor: "#00000000",
                                borderColor: this.$avStyle.colors.lightblue,
                                data: [ { x: expectedTrend.start, y: 0 }, { x: expectedTrend.end, y: this.QuantityToProduce } ]
                            });
                            expectedTrend.valid = true;
                        }
                    } catch {}
                }

                //Apply trend line color coding (if any)
                if(thoreticalTrend.start > -1 && thoreticalTrend.end > -1 && thoreticalTrend.value) {
                    try {
                        // thoreticalTrend.start = Date.parse(thoreticalTrend.start);
                        // expectedTrend.end = Date.parse(thoreticalTrend.end);
                        if(!isNaN(thoreticalTrend.start) && !isNaN(thoreticalTrend.end)) {
                            thoreticalTrend.slope = thoreticalTrend.value / (thoreticalTrend.end - thoreticalTrend.start);
                            newDataCollection.chartData.datasets.push({
                                label: "Ideal trend",
                                backgroundColor: "#00000000",
                                borderColor: this.$avStyle.colors.blue,
                                data: [ { x: thoreticalTrend.start, y: 0 }, { x: thoreticalTrend.end, y: this.QuantityToProduce } ]
                            });
                            thoreticalTrend.valid = true;
                        }
                    } catch {}
                }


                //Apply line styles automatically
                if(!expectedTrend.valid || !thoreticalTrend.valid) {
                    newDataCollection.chartData.datasets.forEach(function (dataset, index) {
                        dataset.borderColor = JsUtils.ColorWithOpacity(JsUtils.NamedColorToHex(traceColors[index % traceColors.length]), 90);
                        dataset.backgroundColor = JsUtils.ColorWithOpacity(JsUtils.NamedColorToHex(traceColors[index % traceColors.length]), 40);
                    });
                } else {
                    for(const [index,dataset] of newDataCollection.chartData.datasets.entries()) {
                        if(Array.isUseful(dataset.data) && index < (newDataCollection.chartData.datasets.length - 2)) {
                            dataset.borderColor = [];
                            for(const [pindex,point] of dataset.data.entries()) {
                                let trendValue = this.getTrendValue(expectedTrend, point.x);
                                if(point.y >= trendValue) {
                                    dataset.borderColor = (JsUtils.ColorWithOpacity(this.$avStyle.colors.green, 90));
                                    if(pindex === (dataset.data.length - 1)) dataset.backgroundColor = JsUtils.ColorWithOpacity(this.$avStyle.colors.green, 40);
                                }
                                else {
                                    dataset.borderColor = (JsUtils.ColorWithOpacity(this.$avStyle.colors.red, 90));
                                    if(pindex === (dataset.data.length - 1)) dataset.backgroundColor = JsUtils.ColorWithOpacity(this.$avStyle.colors.red, 40);
                                }
                            }
                        }
                    }
                }

                // Verify whether previous data set contains same series as this one, in this switch data points only to avoid flickering
                if(this.dataCollection && this.dataCollection.chartData.datasets.length === newDataCollection.chartData.datasets.length) {
                    for (let dataSetIndex = 0; dataSetIndex < this.dataCollection.chartData.datasets.length; dataSetIndex++) {
                        if (this.dataCollection.chartData.datasets[dataSetIndex].label === newDataCollection.chartData.datasets[dataSetIndex].label) {
                            //Datasets match, only replace data points

                            //if(!this.dataCollection.chartData.datasets[dataSetIndex].isScrolling)
                            this.dataCollection.chartData.datasets[dataSetIndex].data = newDataCollection.chartData.datasets[dataSetIndex].data;
                            this.dataCollection.chartData.datasets[dataSetIndex].borderColor = newDataCollection.chartData.datasets[dataSetIndex].borderColor;
                            this.dataCollection.chartData.datasets[dataSetIndex].backgroundColor = newDataCollection.chartData.datasets[dataSetIndex].backgroundColor;
                            //Graph must be refreshed manually, this is because the watch on data running within the chart component
                            //requires the deep property to catch changing to data points only but on the other hand this causes a
                            //continuous triggering of the watch (for unknown reasons, maybe something internal to graph.js),
                            //thus deep watching was removed and manual refresh is required somewhere
                            graphToBeRefreshed = true;
                        }
                        else {
                            //Datasets don't match
                            this.dataCollection = newDataCollection;
                            break;
                        }
                    }
                }
                else this.dataCollection = newDataCollection;   //Datasets don't match

                //Unwrap labels data
                let showLabels = false;

                dataValues.forEach(dataSet => {
                    if(dataSet && dataSet.target !== this.visualizationTargetsIndexes.graph) {
                        if (dataSet.target === this.visualizationTargetsIndexes["label 1"]) {
                            this.dataCollection.value1.label = dataSet.label;
                            this.dataCollection.value1.data = dataSet.data.length > 0 ? dataSet.data[dataSet.data.length - 1].y : "";
                            showLabels = true;
                        }
                        else if (dataSet.target === this.visualizationTargetsIndexes["label 2"]) {
                            this.dataCollection.value2.label = dataSet.label;
                            this.dataCollection.value2.data = dataSet.data.length > 0 ? dataSet.data[dataSet.data.length - 1].y : "";
                            showLabels = true;
                        }
                        else if (dataSet.target === this.visualizationTargetsIndexes["label 3"]) {
                            this.dataCollection.value3.label = dataSet.label;
                            this.dataCollection.value3.data = dataSet.data.length > 0 ? dataSet.data[dataSet.data.length - 1].y : "";
                            showLabels = true;
                        }
                        else if (dataSet.target === this.visualizationTargetsIndexes["label 4"]) {
                            this.dataCollection.value4.label = dataSet.label;
                            this.dataCollection.value4.data = dataSet.data.length > 0 ? dataSet.data[dataSet.data.length - 1].y : "";
                            showLabels = true;
                        }
                    }
                });

                var startWorkorder = dataValues.filter(obj => {
                    return obj.target === this.visualizationTargetsIndexes["theoretical trend start"]
                })
                if (Array.isUseful(startWorkorder) && Array.isUseful(startWorkorder[0].data) &&
                    startWorkorder[0].data[0].y && startWorkorder[0].data[0].y !== "NO WORKORDER")
                {
                    let start = DateTimeUtils.getRfc3339TimeStamp(new Date(startWorkorder[0].data[0].x))
                    let end = DateTimeUtils.getRfc3339TimeStamp(new Date())
                    let timeTrackingArray = []
                    let timeTrackingHistory = this.$timeTracking.getHistory(start, end, startWorkorder[0].data[0].y, 10000)
                    timeTrackingHistory.then((timeTracking) => {
                        let sumDuration = 0
                        timeTracking.forEach(item => {
                            sumDuration += item.duration
                            let isProduction = item.timeTrackingId === 'Production'
                            let timeTrackingItem = {
                                start: item.start,
                                stop: item.stop,
                                label: item.timeTrackingId,
                                backgroundColor: isProduction ? 'green' : 'red',
                                borderColor: "black",
                                data: [
                                    {
                                        x: (item.duration / (1000 * 60 * 60)),
                                        y: statusBarOffset
                                    }
                                ],
                                type: 'bar',
                                tension: 0.4,
                                indexAxis: 'y',
                                stack: 1,
                                barThickness: 8,
                                maxBarThickness: 10,
                                minBarThickness: 6
                            };
                            timeTrackingArray.push(timeTrackingItem)
                        })
                        // this.$refs.graph.refreshGraph();
                    }).finally(() => {
                        this.dataCollection.chartData.datasets = this.dataCollection.chartData.datasets.concat(timeTrackingArray)
                        if (this.$refs.graph)
                            this.$refs.graph.refreshGraph();
                    })
                }
                this.labelsAreaVisible = showLabels;
                let axisIsScaled = false;

                if(graphToBeRefreshed && this.$refs.graph)
                    this.$refs.graph.refreshGraph();

                this.loaded = true;
            },
            refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view

                this.clearErrors();
                this.clearWarnings();
                let self = this;

                let workOrder = this.filterItems[0].filters[0].conditions[0].value;
                if(!workOrder) {
                    this.setError(this.$gettext("No workorder specified"));
                    return
                }
                if(workOrder === "@CurrentWorkOrder") {
                    let self = this;
                    dataValues.forEach(function (dataSet, index) {
                        if(dataSet) {
                            if(dataSet.target === 501) {
                                if(dataSet.data) {
                                    self.RecipeId = dataSet.data.last().y;
                                }
                            }
                            if(dataSet.target === 502) {
                                if(dataSet.data) {
                                    self.QuantityToProduce = dataSet.data.last().y;
                                }
                            }
                        }
                    });
                    self.draw(dataValues);
                    return;
                }
                this.$workorders.get(workOrder)
                    .then(wo => {
                        if(!wo.found) {
                            self.workorder = null;
                            self.setError(this.$gettext("Unable to find selected workorder"));
                            return
                        }
                        self.RecipeId = wo.RecipeId;
                        self.QuantityToProduce = wo.QuantityToProduce;
                        self.draw(dataValues)
                    })
                    .catch( () => {
                        self.RecipeId = "";
                        self.QuantityToProduce = 0;
                        self.setError(this.$gettext("Unable to find selected workorder"));
                        return
                    });

            },

            getTrendValue(trend, x) {
                if(x < trend.start)
                    return 0;
                return (trend.slope * (x - trend.start));
            },
            getStandardVariableName(device) {
                let self = this;
                self.dataItems.forEach(dataItem => {
                    if (dataItem.autoGeneratedTotalItems) {
                        self.$aliases.getStandardVariableName(device.device, dataItem.name.split('_')[0])
                            .then(result => {
                                if (result)
                                    dataItem.name = result + '_Delta';
                            })
                            .catch(err => {
                                debugger;
                                console.log(err);
                            });
                    }
                });

            },
        }
    }

</script>

<style scoped>


</style>
