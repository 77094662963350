<template>
    <v-layout row>
        <vfa-picker v-model="value">
            <template v-slot:activator="{ on }">
                <v-btn @click="on" id="selectFaIcon" color="info">{{buttonText}}</v-btn>
            </template>
        </vfa-picker>
        <template v-if="value">
            <av-icon>fas fa-{{value}}</av-icon>
            <span id="faIconName" class="subheading ma-3">{{value}}</span>
            <v-btn color="error" id="clearFaIcon" fab small @click="value = ''">
                <av-icon light>fas fa-trash</av-icon>
            </v-btn>
        </template>
    </v-layout>
</template>

<script>

    import { fontAwesomePicker } from 'font-awesome-picker';

    export default {
        name: "IconSelector",
        components: {
            'font-awesome-picker': fontAwesomePicker
        },
        props: {
            value: ""
        },
        watch: {
            value() {
                this.$emit('input', this.value);
            }
        },
        computed: {
            buttonText() {
                if(this.value)
                    return this.$gettext("Change icon");
                else return this.$gettext("Add icon");
            }
        }
    }
</script>

<style scoped>

</style>