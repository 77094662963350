import Vue from 'vue'

export default ({ app, router, store, Vue }) => {
    Vue.prototype.WebUIAPI = {}

    Vue.prototype.WebUIAPI.bookRequestForSharedStorage = function (page) {
        return Vue.prototype.OrchestratorAPI.proxyCall('post', '/api/v1/auth/webuimanager/shared/book', JSON.stringify({ page: page }))
    }

    Vue.prototype.WebUIAPI.getTokenFromServer = function (tokenKey) {
        return Vue.prototype.OrchestratorAPI.proxyCall('post', '/api/v1/webuimanager/shared/request', JSON.stringify({ token: tokenKey }))
    }

    Vue.prototype.WebUIAPI.menu = function (menuID, category) {
        if (Vue.prototype.$isEmpty(category)) {
            return Vue.prototype.OrchestratorAPI.proxyCall('get', '/api/v1/auth/webuimanager/menu/' + menuID + '/')
        } else {
            return Vue.prototype.OrchestratorAPI.proxyCall('get', '/api/v1/auth/webuimanager/menu/' + menuID + '/' + category)
        }
    }

    Vue.prototype.WebUIAPI.menuAll = function () {
        return Vue.prototype.OrchestratorAPI.proxyCall('get', '/api/v1/auth/webuimanager/menu/all')
    }

    Vue.prototype.WebUIAPI.saveMenu = function (menu) {
        return Vue.prototype.OrchestratorAPI.proxyCall('post', '/api/v1/auth/webuimanager/menu', JSON.stringify(menu))
    }
}