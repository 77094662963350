<template>
    <v-menu
            v-model="menuOpened"
            :close-on-content-click="false"
            lazy
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            full-width
            :max-width="small ? '350px' : '450px'"
            :min-width="small ? '350px' : '450px'">
        <template v-slot:activator="{ on }">
            <v-text-field
                class="osk"
                :value="tracker"
                :label="$i18n.CommonAttributes()['click to set']"
                prepend-icon="fas fa-stopwatch"
                readonly
                v-on="on"
            ></v-text-field>
        </template>
        <TimeTracking v-if=menuOpened :noTitleBar="small" touchLayout picker showSystemCategories @complete="menuOpened = false" @picked="menuOpened = false, onPicked($event)"/>
    </v-menu>
</template>

<script>

    import TimeTracking from '@/components/TimeTracking.vue'

    export default {
        name: "TimeTrackingPicker",
        components: { TimeTracking },
        data () {
            return {
                menuOpened: false,
            }
        },
        props: {
            value:  [Number, Array],
            small: {
                type: Boolean,
                default: true
            }
        },
        computed : {
            tracker() {
                if(!Object.isUseful(this.value))
                    return "";
                let valueToShow = 0;
                if(Array.isArray(this.value)) {
                    if (Array.isUseful(this.value))
                        valueToShow = this.value[0];
                    else return "";
                }
                else valueToShow = this.value;

                return this.$timeTracking.formatTimeTracker(valueToShow, true);
            }
        },
        methods: {
            onPicked(tracker) {
                this.value = tracker;
                this.$emit('input', this.value);
                this.$emit('change', this.value);
            }
        }
    }
</script>

<style scoped>

</style>