<template>
    <GenericTable/>
</template>

<script>

    import Entity from "@/api/entities";
    import FormBase from "@/components/dynamic-elements/forms/FormBase";
    import avPage from "@/components/av-components/av-page";
    import GenericTable from "@/components/utilities/GenericTable";

    export default {
        name: "Processes",
        components: { FormBase, avPage, GenericTable },
        extends: GenericTable,
        data() {
            return {
                headers: [],
                currentSort: 'name',
                currentSortDir: 'asc',
                processSort: true,
                machineSort: true
            }
        },
        computed: {

            computedSortByNameId() {
                return this.toCamelCase(this.$router.history.current.name + 'Table')
            },
            computedSortByStatusId() {
                return this.toCamelCase(this.$router.history.current.name + 'Table')
            },
            sorted: function () {
                return Array.isUseful(this.listOfItems) ? this.listOfItems.sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc')
                        modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return modifier;

                    return 0;
                }):[];
            },

        },
        async mounted() {

            await this.waitForPageLoading();
            this.$root.setCurrentPageTitle(this.$gettext("Active processes"));
            this.getListOfItems = this.listProcesses;
            this.scopeIndex = "Machine process";
            this.customActions = {
                icon: 'fas fa-pen',
                    text: '',
                    color: 'blue',
                    children: [
                    {
                        icon: 'fa-pen',
                        text: 'Edit process',
                        color: 'blue',
                        handler: 'editItem',
                        local: true,
                        getGrant: () => { return true; }
                    },
                    {
                        icon: 'fa-stop',
                        text: 'Stop process',
                        color: 'red',
                        handler: 'deleteProcess',
                        local: false,
                        getGrant: () => { return true; }
                    }
                ]
            },
            this.newItemButton = {
                type: this.$dynamicElements.Types.FormMachineProcess,
                backPath: "processesPage",
                itemForms: [],
                newItemButtonName: this.$gettext("New process"),
                selectedForm: ""
            },
            this.itemDetails = this.processDetails;
            this.editItem = this.editProcess;
            this.timeWindow.setPredefined(2628000);
            this.scopeType = "machinePropertiesVisualizationOptions";
            this.auditType = "Machine properties";
            this.needActionAndDetails = true;
            this.defaultVisualizationProfile = [{name: 'MachineId', text: this.$gettext("Machine")}, {name: 'Name', text: this.$gettext("Process")}, {name: '@timestamp', text: this.$gettext("Started")}];
            await this.loadData();
            await this.loadForms();
        },
        methods: {
            sort: function (s) {
                if (s === "Process")
                    this.processSort = !this.processSort;
                else if (s === "Machine")
                    this.machineSort = !this.machineSort;

                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                }
                this.currentSort = s;
            },
            styleCell(index) {
                if (index === 0) {
                    return "min-width: 200px";
                } else return {
                    border: this.type === "custom" ? "1px solid #eee" : "",
                }
            },
            async listProcesses() {
                this.listOfItems.clear();
                let self = this;
                this.waitItems = new Promise((resolve, reject) => {
                    self.$dataEntry.getProductionData()
                        .then(result => {
                            if(Array.isUseful(result)) {
                                result = result.filter(item => {
                                    return item.target === 2
                                })
                                self.listOfItems = result;
                            }
                            resolve(Array.isUseful(self.listOfItems))
                        })
                        .catch(error => {
                            console.log(error);
                            this.$root.showErrorNotification(this.$gettext("Error in retrieving active processes from DB."), true);
                        })
                })
            },
            processDetails(data) {
                this.$router.push({name: 'entityDetails', params: {entity: data, backPath:{name: 'processesPage'}}})
            },
            deleteProcess(id) {
                let self = this;
                let item = this.listOfItems.filter(item => item.id === id);
                this.$root.showDialogBox(this.$gettext("Are you sure you want to complete process {0} on machine {1}?").format(item[0].Name, item[0].MachineId), null, "Yes", function () {
                    Entity.deleteProductionDataInstance(id)
                        .then(() => {
                            self.listOfItems.removeItem(item[0]);
                            setTimeout(function(){
                                self.loadData()
                            }, 2000);
                        })
                        .catch(err => {
                            self.$root.showErrorNotification(err, true, false);
                        })
                }, "No", null);
            },
            editProcess(entity) {
                this.$router.push({name: 'formsEdit', params: {routeId: entity.Name, entity: entity, backPath:{name: 'processesPage'}}})
            },
        }
    }
</script>
