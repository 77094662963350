<template>
    <v-app v-resize="onResize">
        <v-toolbar style="background-color: var(--av-lightblue); color: white; height:65px!important" app
                   v-if="this.$store.state.globalHeader && !this.$store.state.hideHeaderBar && !hideFrame" flat
                   id="toolbar">
            <div style="display:none;">
                <input type="text">
                <input type="password">
            </div>
            <v-toolbar-side-icon @click.stop="handleToolbarSize" id="toolbar-icon">
                <av-icon color="white">fas fa-bars</av-icon>
            </v-toolbar-side-icon>
            <v-layout column>
                <v-toolbar-title class="display-1 ml-3 mt-0">{{ $config.appName }}</v-toolbar-title>
                <v-layout row class="ml-3">
                    <av-icon light small @click="redirectToHomePage">fas fa-home</av-icon>
                    <v-toolbar-title class="title"
                                     v-text="this.$root.currentPageTitle ? '> '+this.$root.currentPageTitle : this.title"></v-toolbar-title>
                </v-layout>
            </v-layout>
            <v-spacer></v-spacer>
            <v-toolbar-items id="toolbar-items">
                <v-btn flat v-if="iterateDashboards" @click="startStopShuffle" color="white">
                    <v-icon>{{ isShufflePlaying ? 'fa-pause' : 'fa-play' }}</v-icon>
                </v-btn>
                <v-btn flat color="white" v-if="backPath" @click="goBack">
                    <av-icon color="white" left>fas fa-chevron-left</av-icon>
                    {{ $gettext('Back') }}
                </v-btn>
                <v-menu offset-y transition="slide-y-transition" left id="toolbar-user"
                        v-if="!$store.state.unAuthenticatedLogin">
                    <v-btn slot="activator" flat icon id="toolbar-user-button">
                        <av-icon id="toolbar-user-dropdown" color="white">fa-user</av-icon>
                    </v-btn>
                    <v-list id="toolbar-user-dropdown-menu">
                        <v-list-tile @click="doLogout" id="toolbar-user-dropdown-menu-logout">
                            <v-list-tile-content id="toolbar-user-dropdown-menu-logout-text">
                                <translate>Logout</translate>
                            </v-list-tile-content>
                            <v-list-tile-action id="toolbar-user-dropdown-menu-logout-icon">
                                <av-icon>exit_to_app</av-icon>
                            </v-list-tile-action>
                        </v-list-tile>
                    </v-list>
                </v-menu>

                <v-btn v-else flat icon @click="loginDialog = true">
                    <av-icon color="white">fas fa-user-unlock</av-icon>
                </v-btn>
                <v-menu offset-y transition="slide-y-transition" left id="toolbar-lang">
                    <v-btn slot="activator" color="white" flat id="toolbar-lang-button">
                        <av-icon id="toolbar-lang-dropdown" left color="white" class="mr-1">fa-flag</av-icon>
                        <span style="margin-top: 16px; font-size: 18px;">{{
                                $language.current === 'default' ? 'EN' : $language.current
                            }}</span>
                    </v-btn>
                    <v-list id="toolbar-lang-dropdown-menu" style="width: 220px">
                        <v-list-tile v-for="(language, key) in $language.available" @click="changeLanguage(key)">
                            <v-list-tile-action>
                                <av-icon v-if="$language.current === key" color="info">fas fa-check</av-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>{{ language }} ({{ key }})</v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-menu>
                <v-btn flat icon id="toolbar-osk-button" @click="switchOnOffOsk">
                    <v-layout column>
                        <av-icon v-if="toggleOsk" id="toolbar-osk-on" color="white">fas fa-keyboard</av-icon>
                        <av-icon v-else id="toolbar-osk-off" color="#cccccc">fas fa-keyboard</av-icon>
                        <label style="color: white" v-if="toggleOsk">KB ON</label>
                        <label style="color: #cccccc" v-else>KB OFF</label>
                    </v-layout>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-navigation-drawer enable-resize-watcher fixed app mobile-break-point="960" v-model="drawer"
                             :mini-variant.sync="miniVariant"
                             v-if="this.$store.state.globalHeader && this.$store.state.globalMenu && !hideFrame">
            <v-toolbar flat class="transparent">
                <v-list>
                    <v-list-tile>
                        <v-list-tile-action style="margin: -2px; cursor: pointer;" @click="redirectToHomePage">
                            <img :src="sideLogoSrc" :class="sideLogoStyle">
                        </v-list-tile-action>
                    </v-list-tile>
                </v-list>
            </v-toolbar>

            <v-spacer/>

            <v-list
                :style="'height: calc(100% - 65px - {0}px);'.format(statusAreaHeight) + ( (miniVariant || this.$config.deployment !== 2) ? '' : 'overflow: auto;')">

                <v-list-group id="group-menu-dashboards"
                              v-if="this.$root.configured && ($grants.get().dashboards.edit || $grants.get().dataExploration.dataExplorer || !Object.isEmpty(dashboards))"
                              prepend-icon="fas fa-tachometer-alt">
                    <v-list-tile slot="activator" id="menu-dashboards">
                        <v-list-tile-content id="menu-dashboards-content">
                            <v-list-tile-title id="menu-dashboards-title">
                                <translate>Dashboards</translate>
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile id="menu-dashboards-edit" v-if="$grants.get().dashboards.edit && !$root.isMobile"
                                 class="listSubItem" to="/explore/dashboard">
                        <v-list-tile-action id="menu-dashboards-edit-action">
                            <av-icon>fas fa-edit</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-dashboards-edit-title">
                            <translate>Edit dashboards</translate>
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile :id="'menu-dashboards-'+getItemLabel('explore').toLowerCase().replaceAll(' ','-')"
                                 v-if="$grants.get().dataExploration.dataExplorer && !$root.isMobile"
                                 class="listSubItem" to="/explore/widgets">
                        <v-list-tile-action>
                            <av-icon>fas fa-search</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title
                            :id="'menu-dashboards-'+getItemLabel('explore').toLowerCase().replaceAll(' ','-')+'-title'">
                            {{ getItemLabel("explore") }}
                        </v-list-tile-title>
                    </v-list-tile>
                    <template v-for="(group, key) in dashboards">
                        <v-divider/>
                        <v-subheader v-if="isItemsGroupValid(dashboards, key)">{{ key }}</v-subheader>
                        <div :style="'margin-top: {0}px'.format(isItemsGroupValid(dashboards, key) ? -25 : 0)">
                            <v-list-tile :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')" class="listSubItem"
                                         :style="'margin-top: {0}px'.format(itemIndex !== 0 ? -15 : 0)"
                                         v-for="(item, itemIndex) in group" :v-key="item"
                                         :to="'/dashboards/' + item.name + '/show'">
                                <v-list-tile-action>
                                    <av-icon>grid_on</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-title'"
                                                   class="subheading">{{ item.name }}
                                </v-list-tile-title>
                            </v-list-tile>
                        </div>
                    </template>
                </v-list-group>

                <v-list-group id="group-menu-reports"
                              v-if="this.$root.configured && ($grants.get().dataExploration.reports || !Object.isEmpty(reports))"
                              prepend-icon="fas fa-file-excel">
                    <v-list-tile slot="activator" id="menu-reports">
                        <v-list-tile-content id="menu-reports-content">
                            <v-list-tile-title id="menu-reports-title">
                                <translate>Reports</translate>
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile id="menu-reports-edit" v-if="$grants.get().dataExploration.reports && !$root.isMobile"
                                 class="listSubItem" to="/explore/reports">
                        <v-list-tile-action id="menu-reports-edit-action">
                            <av-icon>fas fa-edit</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-reports-edit-title">{{
                                getItemLabel("reports")
                            }}
                        </v-list-tile-title>
                    </v-list-tile>
                    <template v-for="(group, key) in reports">
                        <v-divider/>
                        <v-subheader v-if="isItemsGroupValid(reports, key)">{{ key }}</v-subheader>
                        <div :style="'margin-top: {0}px'.format(isItemsGroupValid(reports, key) ? -25 : 0)">
                            <v-list-tile class="listSubItem"
                                         :style="'margin-top: {0}px'.format(itemIndex !== 0 ? -15 : 0)"
                                         v-for="(item, itemIndex) in group" :v-key="item"
                                         :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-list-tile-action
                                            :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-action'">
                                            <av-icon>grid_on</av-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-title
                                            :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-title'"
                                            class="subheading" v-on="on">{{ item.name }}
                                        </v-list-tile-title>
                                    </template>
                                    <v-list>
                                        <v-list-tile
                                            :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-download-xls'"
                                            @click="downloadReport(item.name, 'xlsx')">
                                            <v-list-tile-action
                                                :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-download-xls-action'">
                                                <av-icon>fas fa-file-excel</av-icon>
                                            </v-list-tile-action>
                                            <v-list-tile-title
                                                :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-download-xls-title'"
                                                class="subheading" v-translate>Export excel
                                            </v-list-tile-title>
                                        </v-list-tile>
                                        <v-list-tile
                                            :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-download-pdf'"
                                            @click="downloadReport(item.name, 'pdf')">
                                            <v-list-tile-action
                                                :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-download-pdf-action'">
                                                <av-icon>fas fa-file-pdf</av-icon>
                                            </v-list-tile-action>
                                            <v-list-tile-title
                                                :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-download-pdf-title'"
                                                class="subheading" v-translate>Export pdf
                                            </v-list-tile-title>
                                        </v-list-tile>
                                    </v-list>
                                </v-menu>
                                <img v-show="reportsLoading.includes(item.name)" :src="loaderGif"
                                     style="width:50px; height:50px;"/>
                            </v-list-tile>
                        </div>
                    </template>
                </v-list-group>

                <!-- FORMS -->
                <v-list-group id="group-menu-forms" v-if="canViewForms">
                    <av-icon slot="prependIcon" :color="blinkProductionData ? 'red' : ''">fas fa-tasks</av-icon>
                    <v-list-tile slot="activator" id="menu-forms">
                        <v-list-tile-content id="menu-forms-action">
                            <v-list-tile-title id="menu-forms-title"
                                               :style="blinkProductionData ? 'color: var(--av-red)' : ''">
                                <translate>Forms</translate>
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile id="menu-forms-edit" v-if="$grants.get().dataExploration.forms && !$root.isMobile"
                                 class="listSubItem" to="/explore/forms">
                        <v-list-tile-action id="menu-forms-edit-action">
                            <av-icon>fas fa-edit</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-forms-edit-title">{{ getItemLabel("forms") }}</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile :id="'menu-'+getItemLabel('entities').toLowerCase().replaceAll(' ','-')"
                                 v-if="false && $grants.get().dataExploration.entities && !$root.isMobile"
                                 class="listSubItem" to="/entities">
                        <v-list-tile-action
                            :id="'menu-'+getItemLabel('entities').toLowerCase().replaceAll(' ','-')+'-action'">
                            <av-icon>fas fa-list</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title
                            :id="'menu-'+getItemLabel('entities').toLowerCase().replaceAll(' ','-')+'-title'">
                            {{ getItemLabel("entities") }}
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile id="menu-production-instances" class="listSubItem" to="/productionInstances"
                                 v-if="$grants.get().dataExploration.canManageActiveProductionData">
                        <v-list-tile-action id="menu-production-instances-action">
                            <av-icon :color="blinkProductionData ? 'red' : ''">fas fa-list</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-production-instances-title"
                                           :style="blinkProductionData ? 'color: var(--av-red)' : ''">
                            {{ getItemLabel("productionInstances") }}
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile :id="'menu-'+getItemLabel('orphanTransactions').toLowerCase().replaceAll(' ','-')"
                                 v-if="false && $grants.get().dataExploration.orphanTransactions && !$root.isMobile"
                                 class="listSubItem" to="/orphanTransactions">
                        <v-list-tile-action
                            :id="'menu-'+getItemLabel('orphanTransactions').toLowerCase().replaceAll(' ','-')+'-action'">
                            <av-icon>fas fa-list</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title
                            :id="'menu-'+getItemLabel('orphanTransactions').toLowerCase().replaceAll(' ','-')+'-title'">
                            {{ getItemLabel("orphanTransactions") }}
                        </v-list-tile-title>
                    </v-list-tile>
                    <template v-for="(group, key) in forms">
                        <v-divider/>
                        <v-subheader v-if="isItemsGroupValid(forms, key)">{{ key }}</v-subheader>
                        <div :style="'margin-top: {0}px'.format(isItemsGroupValid(forms, key) ? -25 : 0)">
                            <v-list-tile :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')" class="listSubItem"
                                         :style="'margin-top: {0}px'.format(itemIndex !== 0 ? -15 : 0)"
                                         v-for="(item, itemIndex) in group" :v-key="item" :to="'/forms/' + item.name">
                                <v-list-tile-action :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-action'">
                                    <av-icon>grid_on</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-title'"
                                                   class="subheading">{{ item.name }}
                                </v-list-tile-title>
                            </v-list-tile>
                        </div>
                    </template>
                </v-list-group>

                <!-- WIZARDS -->
                <v-list-group id="group-menu-wizards" v-if="canViewWizards">
                    <av-icon slot="prependIcon">fa-hat-wizard</av-icon>
                    <v-list-tile slot="activator" id="menu-wizards">
                        <v-list-tile-content id="menu-wizards-action">
                            <v-list-tile-title id="menu-wizards-title">
                                <translate>Wizards</translate>
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile id="menu-wizards-edit" class="listSubItem" to="/explore/wizards">
                        <v-list-tile-action id="menu-wizards-edit-action">
                            <av-icon>fas fa-edit</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-wizards-edit-title">{{ getItemLabel("wizards") }}</v-list-tile-title>
                    </v-list-tile>
                    <template v-for="(group, key) in wizards">
                        <v-divider/>
                        <v-subheader v-if="isItemsGroupValid(wizards, key)">{{ key }}</v-subheader>
                        <div :style="'margin-top: {0}px'.format(isItemsGroupValid(wizards, key) ? -25 : 0)">
                            <v-list-tile :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')" class="listSubItem" :style="'margin-top: {0}px'.format(itemIndex !== 0 ? -15 : 0)"
                                         v-for="(item, itemIndex) in group" :v-key="item" :to="'/wizards/' + item.name">
                                <v-list-tile-action :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-action'">
                                    <av-icon>grid_on</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title :id="'menu-'+item.name.toLowerCase().replaceAll(' ','-')+'-title'" class="subheading">{{ item.name }}</v-list-tile-title>
                            </v-list-tile>
                        </div>
                    </template>
                </v-list-group>

              <!-- PRODUCTION -->
              <v-list-group id="group-menu-production" v-if="this.$config.isAvionics && this.$root.configured && this.$license.hasManager() && !this.$root.isMobile && ($grants.hasRecipes() || $grants.get().operations.workOrderSheduling)" prepend-icon="fas fa-play">
                    <v-list-tile id="'menu-production" slot="activator">
                        <v-list-tile-content id="menu-production-content">
                            <v-list-tile-title id="menu-production-title">{{
                                    getItemLabel("production")
                                }}
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile id="menu-line-recipes-configurator" class="listSubItem"
                                 v-if="this.$root.configured && !this.$root.isMobile && this.$grants.hasRecipes()"
                                 to="/line-recipes-configuration"> <!--$grants.get().dataExploration.recipes && -->
                        <v-list-tile-action id="menu-line-recipes-configurator-action">
                            <av-icon>fas fa-books</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-line-recipes-configurator-title">
                            {{ getItemLabel("linerecipesconfigurator") }}
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile id="menu-recipes" class="listSubItem"
                                 v-if="this.$root.configured && !this.$root.isMobile && this.$grants.hasRecipes()"
                                 to="/explore/recipes"> <!--$grants.get().dataExploration.recipes && -->
                        <v-list-tile-action id="menu-recipes-action">
                            <av-icon>fas fa-book</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-recipes-title">{{ getItemLabel("recipes") }}</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile id="menu-workorders" class="listSubItem"
                                 v-if="this.$root.configured && !$root.isMobile && $grants.get().operations.workOrderSheduling"
                                 to="/workordersPage">
                        <v-list-tile-action id="menu-workorders-action">
                            <av-icon>fas fa-file-alt</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-workorders-title">{{
                                getItemLabel("workorders")
                            }}
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile id="menu-processes" class="listSubItem"
                                 v-if="this.$config.experimental.machineProcesses && this.$root.configured && !$root.isMobile"
                                 to="/processesPage">
                        <v-list-tile-action id="menu-processes-action">
                            <av-icon>fas fa-wave-square</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-processes-title">{{ getItemLabel("processes") }}</v-list-tile-title>
                    </v-list-tile>
                </v-list-group>

                <!-- SHIFTS -->
                <v-list-group id="group-menu-shifts" v-if="this.$config.isAvionics && this.$root.configured && this.$config.deployment === 2 &&
                    ($grants.get().operations.shiftChange && $grants.get().operations.shiftScheduling)"
                              prepend-icon="fas fa-users">
                    <v-list-tile slot="activator" id="menu-shifts">
                        <v-list-tile-content id="menu-shifts-content">
                            <v-list-tile-title id="menu-shifts-title">{{
                                    getItemLabel("shiftsparent")
                                }}
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile id="menu-shift" class="listSubItem" @click="openShiftChangeDialog">
                        <v-list-tile-action id="menu-shift-action">
                            <av-icon>fa fa-exchange-alt</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-shift-title">{{ getItemLabel("shift") }}</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile id="menu-shift-scheduler" class="listSubItem" :to="'/shiftscheduler'">
                        <v-list-tile-action id="menu-shift-scheduler-action">
                            <av-icon>fa fa-tasks</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-shift-scheduler-title">{{
                                getItemLabel("shift-scheduler")
                            }}
                        </v-list-tile-title>
                    </v-list-tile>
                </v-list-group>
                <v-list-tile id="menu-shift-change"
                             v-else-if="this.$config.isAvionics && this.$root.configured && $grants.get().operations.shiftChange && this.$config.deployment === 2"
                             @click="openShiftChangeDialog">
                    <v-list-tile-action id="menu-shift-change-action">
                        <av-icon>fa fa-users</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title id="menu-shift-change-title">{{ getItemLabel("shift") }}</v-list-tile-title>
                </v-list-tile>
                <v-list-tile id="menu-shift_scheduler"
                             v-else-if="this.$config.isAvionics && this.$root.configured && $grants.get().operations.shiftScheduling && this.$config.deployment === 2"
                             :to="'/shiftscheduler'">
                    <v-list-tile-action id="menu-shift_scheduler-action">
                        <av-icon>fa fa-users</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title id="menu-shift_scheduler_title">{{
                            getItemLabel("shift-scheduler")
                        }}
                    </v-list-tile-title>
                </v-list-tile>

                <!-- ACTIVITIES -->
                <v-list-group id="group-menu-activities"
                              v-if="this.$config.isAvionics && this.$root.configured && (this.$license.hasQuality() || this.$license.hasMaintenance()) && (!Object.isEmpty(pendingActions) || $grants.get().activities.canVisualizeActivities)"
                              @click="scrollListener">
                    <av-icon slot="prependIcon" :color="blinkPendingActions ? 'red' : ''">fas fa-tasks-alt</av-icon>
                    <v-list-tile slot="activator" id="menu-activities">
                        <v-list-tile-content id="menu-activities-content">
                            <v-list-tile-title id="menu-activities-title"
                                               :style="blinkPendingActions ? 'color: var(--av-red)' : ''">
                                <translate>Activities</translate>
                                ({{ pendingActions.length }})
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile id="menu-activities-page" class="listSubItem" to="/activitiesPage"
                                 v-if="$grants.get().activities.canVisualizeActivities">
                        <v-list-tile-action id="menu-activities-page-action">
                            <av-icon>far fa-tasks-alt</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-activities-page-title">{{
                                getItemLabel("activities")
                            }}
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-divider/>
                    <div class="list-group-wrapper">
                        <transition name="fade">
                            <div class="loading" v-show="pendingActionLoading">
                                <span class="fa fa-spinner fa-spin"></span> Loading
                            </div>
                        </transition>
                        <ul class="list-group" ref="infiniteList">
                            <template v-for="action in pendingActionsShow">
                                <v-list-tile id="menu-pending-action" class="listSubItem" @click="openForm(action)">
                                    <v-list-tile-action id="menu-pending-action-action">
                                        <av-icon>fas fa-pen</av-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-title id="menu-pending-action-title">{{
                                            action.Action.name === 'system-DefaultForm' ? action.Name : action.Action.name
                                        }}
                                    </v-list-tile-title>
                                </v-list-tile>
                            </template>
                        </ul>
                    </div>
                </v-list-group>

                <v-list-tile id="menu-change-time-tracking"
                             v-if="this.$config.isAvionics && this.$root.configured && $grants.get().operations.timeTrackingChange && this.$config.deployment === 2 && this.$license.hasPerformance()"
                             @click="openTimeTrackingDialog" :disabled="currentTimeTracker.value < 2">
                    <v-list-tile-action id="menu-change-time-tracking-action">
                        <av-icon :disabled="currentTimeTracker.value < 2">fas fa-stopwatch</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title id="menu-change-time-tracking-title">{{ getItemLabel("tt") }}</v-list-tile-title>
                </v-list-tile>

                <v-list-tile id="menu-stops-categorization"
                             :style="blinkPendingTimeTracking ? 'color: var(--av-red)' : ''"
                             v-if="this.$config.isAvionics && this.$root.configured && $grants.get().operations.timeTrackingChange && this.$config.deployment === 2 && this.$license.hasPerformance()"
                             @click="openBackAnnotationTimeTrackingDialog">
                    <v-list-tile-action id="menu-stops-categorization-action">
                        <av-icon :color="blinkPendingTimeTracking ? 'red' : ''">fa fa-history</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title id="menu-stops-categorization-title">{{
                            getItemLabel("pasttt")
                        }}
                    </v-list-tile-title>
                </v-list-tile>

                <v-list-tile id="menu-change-workorder" v-if="this.$config.isAvionics && this.$root.configured && $grants.get().operations.workOrderSheduling &&
                    this.$config.deployment === 2 && !this.$license.hasManager()" @click="openWorkOrderDialog">
                    <v-list-tile-action id="menu-change-workorder-action">
                        <av-icon>fas fa-file-alt</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title id="menu-change-workorder-title">{{ getItemLabel("wo") }}</v-list-tile-title>
                </v-list-tile>

                <v-list-tile id="menu-change-production-counters"
                             v-if="this.$config.isAvionics && this.$root.configured && $grants.get().dataAdjustments.counters && this.$config.deployment === 2"
                             @click="openBackAnnotationDialog">
                    <v-list-tile-action id="menu-change-production-counters-action">
                        <av-icon>edit</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title id="menu-change-production-counters-title">{{
                            getItemLabel("quantities")
                        }}
                    </v-list-tile-title>
                </v-list-tile>

                <!-- Documentation page -->
                <v-list-tile id="menu-documentation"
                             v-if="this.$root.configured && $grants.get().documentationPage.canVisualizeDocumentationPage && !$root.isMobile"
                             to="/documentationPage">
                    <v-list-tile-action id="menu-documentation-action">
                        <av-icon>fas fa-folder-open</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title id="menu-documentation-title">{{ $gettext('Documentation') }}</v-list-tile-title>
                </v-list-tile>

                <v-list-tile id="menu-asset-register" to="/assetsRegister"
                             v-if="$grants.get().maintenance.canVisualizeAssetsRecords">
                    <v-list-tile-action id="menu-asset-register-action">
                        <av-icon>far fa-conveyor-belt</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title id="menu-asset-register-title">
                        <translate>Assets register</translate>
                    </v-list-tile-title>
                </v-list-tile>

                <!-- Maintanance -->
                <v-list-group id="group-menu-maintanance"
                              v-if="this.$config.isAvionics && this.$root.configured && this.$license.hasMaintenance() && $grants.hasMaintenance()">
                    <av-icon slot="prependIcon">fas fa-tools</av-icon>
                    <v-list-tile id="menu-maintanance" slot="activator">
                        <v-list-tile-content id="menu-maintanance-content">
                            <v-list-tile-title id="menu-maintanance-title">
                                <translate>Maintenance</translate>
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile id="menu-maintanance-register" class="listSubItem" to="/maintenanceRegister"
                                 v-if="this.$license.hasMaintenance() && $grants.get().maintenance.canVisualizeMaintenanceRecords">
                        <v-list-tile-action id="menu-maintanance-register-action">
                            <av-icon>fas fa-books</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-maintanance-register-title">
                            <translate>Maintenance Register</translate>
                        </v-list-tile-title>
                    </v-list-tile>
                </v-list-group>

                <v-list-group id="group-menu-past-data-adjustment" v-if="this.$config.isAvionics && this.$root.configured && this.$config.deployment === 2 &&
                    ($grants.get().dataAdjustments.workOrderScheduling || $grants.get().dataAdjustments.timeTracking) && !$root.isMobile"
                              prepend-icon="fas fa-backward">
                    <v-list-tile id="menu-past-data-adjustment" slot="activator">
                        <v-list-tile-content id="menu-past-data-adjustment-content">
                            <v-list-tile-title id="menu-past-data-adjustment-title">{{
                                    getItemLabel("pdadjust")
                                }}
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile id="menu-workorders-adjustment" class="listSubItem"
                                 v-if="$grants.get().dataAdjustments.workOrderScheduling"
                                 @click="openBackAnnotationWorkordersDialog">
                        <v-list-tile-action id="menu-workorders-adjustment-action">
                            <av-icon>description</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-workorders-adjustment-title">{{
                                getItemLabel("woadjust")
                            }}
                        </v-list-tile-title>
                    </v-list-tile>

                    <v-list-tile id="menu-time-tracking-adjustment" class="listSubItem"
                                 v-if="$grants.get().dataAdjustments.timeTracking  && this.$license.hasPerformance()"
                                 @click="openAdjustementTimeTrackingDialog">
                        <v-list-tile-action id="menu-time-tracking-adjustment-action">
                            <av-icon>fas fa-stopwatch</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-time-tracking-adjustment-title">{{
                                getItemLabel("ttadjust")
                            }}
                        </v-list-tile-title>
                    </v-list-tile>
                </v-list-group>

                <v-list-tile id="menu-rules"
                             v-if="this.$root.configured && $grants.get().dataExploration.rules && !$root.isMobile"
                             to="/explore/rules">
                    <v-list-tile-action id="menu-rules-action">
                        <av-icon>fas fa-project-diagram</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title id="menu-rules-title">{{ getItemLabel("rules") }}</v-list-tile-title>
                </v-list-tile>

                <!--CONFIGURATION-->
                <v-list-group id="group-menu-configuration"
                              v-if="this.$config.isAvionics && this.$grants.ready() && Array.isUseful(menuConfiguration.filter((item) => { return item.visible === true }))"
                              prepend-icon="fas fa-cog">
                    <v-list-tile slot="activator" id="menu-configuration">
                        <v-list-tile-content id="menu-configuration-content">
                            <v-list-tile-title id="menu-configuration-title">{{
                                    getItemLabel("configuration")
                                }}
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile :id="'menu-'+m.title.toLowerCase().replaceAll(' ','-')" v-for="m in menuConfiguration"
                                 @click="openPage(m)" v-if="m.visible" class="listSubItem">
                        <v-list-tile-action :id="'menu-'+m.title.toLowerCase().replaceAll(' ','-')+'-action'">
                            <av-icon>{{ m.icon }}</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title :id="'menu-'+m.title.toLowerCase().replaceAll(' ','-')+'-title'">
                            {{ m.title }}
                        </v-list-tile-title>
                    </v-list-tile>
                </v-list-group>

                <!--INFO-->
                <v-list-group id="group-menu-info" v-if="$grants.hasInfo()" prepend-icon="as fa-info">
                    <v-list-tile slot="activator" id="menu-info">
                        <v-list-tile-content id="menu-info-content">
                            <v-list-tile-title id="menu-info-title">{{ getItemLabel("info") }}</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile :id="'menu-'+m.title.toLowerCase().replaceAll(' ','-')" v-for="m in infoConfiguration"
                                 @click="openPage(m)" v-if="m.visible" class="listSubItem">
                        <v-list-tile-action :id="'menu-'+m.title.toLowerCase().replaceAll(' ','-')+'-action'">
                            <av-icon>{{ m.icon }}</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title :id="'menu-'+m.title.toLowerCase().replaceAll(' ','-')+'-title'">
                            {{ m.title }}
                        </v-list-tile-title>
                    </v-list-tile>
                </v-list-group>

                <v-list-group id="group-menu-demo"
                              v-if="false && this.$config.isAvionics && this.$root.configured && $config.demo"
                              prepend-icon="fas fa-plus">
                    <v-list-tile slot="activator" id="menu-demo">
                        <v-list-tile-content id="menu-demo-content">
                            <v-list-tile-title id="menu-demo-title">
                                <translate>Demo functions</translate>
                            </v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <!--<v-list-tile v-if="this.$root.configured" @click="openDemoWoCloseBackAnnotationDialog">-->
                    <!--<v-list-tile-action><av-icon>edit</av-icon></v-list-tile-action>-->
                    <!--<v-list-tile-title v-translate>Simulate workorder closing</v-list-tile-title>-->
                    <!--</v-list-tile>-->
                    <v-list-tile v-if="this.$root.configured" id="menu-shuffle">
                        <v-list-tile-action id="meun-shuffle-action">
                            <v-checkbox
                                v-model="iterateDashboards"
                                label=""
                                @change="toIterateDashboards"
                                id="chk-menu-shuffle"
                            ></v-checkbox>
                        </v-list-tile-action>
                        <v-list-tile-title id="menu-shuffle-title" v-translate>Shuffle Mode</v-list-tile-title>
                    </v-list-tile>
                </v-list-group>
            </v-list>
            <template
                v-if="Array.isUseful(lineStatusInfo) && this.$config.isAvionics && !miniVariant && this.$config.deployment === 2">
                <v-list>
                    <v-divider></v-divider>
                    <v-divider></v-divider>
                    <v-list-tile>
                        <v-list-tile-action>
                            <av-icon>fas fa-info-circle</av-icon>
                        </v-list-tile-action>
                        <v-list-tile-title v-translate>Line status info</v-list-tile-title>
                    </v-list-tile>
                </v-list>
                <div v-for="info in lineStatusInfo" style="height: auto">
                    <template v-if="info.value === '_timeTracking'">
                        <div style=" height: auto">
                            <label class="caption font-weight-regular"
                                   style="float: left; position:relative; left: 16px; top:-16px; width: calc(95% - 16px);"><b>
                                <translate>Current time tracking</translate>
                                :</b></label>
                            <div style="clear: both"></div>
                        </div>
                        <div v-for="(category,index) in currentTimeTracker.text" style=" height: auto">
                            <label class="caption font-weight-regular"
                                   :style="'float: left; position:relative; top:-18px; width: calc(95% - ' + (20 + index * 5) + 'px); left:' + (20 + index * 5) + 'px;'">
                                <i class="fas fa-caret-right"></i> {{ category }}</label>
                            <div style="clear: both"></div>
                        </div>
                    </template>
                    <label v-else class="caption font-weight-regular status-label">
                        <b>{{ info.text }}:</b> {{ info.value }}</label>
                    <div style="clear: both"></div>
                </div>
            </template>

            <!--<v-toolbar flat class="transparent">-->
            <!--<v-list>-->
            <!--<v-list-tile>-->
            <!--<v-list-tile-action style="margin: -2px; width: auto; height: auto">-->
            <!--<img :src="secondLogoSrc" :class="sideLogoStyle">-->
            <!--</v-list-tile-action>-->
            <!--</v-list-tile>-->
            <!--</v-list>-->
            <!--</v-toolbar>-->

        </v-navigation-drawer>

        <v-content id="container-content" pa-0 ma-0 style="height: 100%">
            <router-view :key="$route.fullPath" ref="page"/>
        </v-content>

        <av-queued-snackbar right top :messages="$root.notifications"/>
        <av-message-box :messages="$root.messageBoxes"/>
        <av-loader :loading="loading" :message="loadingMessage" :minTime="loadingMinTime"/>

        <v-dialog fullscreen v-model="showVirtualKeyboard" persistent>
            <draggable-container v-if="showVirtualKeyboard" :splitHeader="true"
                                 style="height: 50%; min-height: 350px; opacity: 0.85">
                <template slot="header">
                    <v-toolbar color="var(--av-lightblue)"
                               :height="$root.virtualKeyboard.target.type !== 'textarea' ? '80px' : '120px'"
                               style="padding-bottom: 20px">
                        <v-btn icon>
                            <v-icon>fas fa-arrows-alt</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>
                <template slot="noDraggableHeader">
                    <v-toolbar color="var(--av-lightblue)"
                               :height="$root.virtualKeyboard.target.type!=='textarea' ? '80px' : '120px'"
                               style="padding-bottom: 20px;">
                        <v-textarea v-if="$root.virtualKeyboard.target.type!=='password'"
                                    :rows="$root.virtualKeyboard.target.type!=='textarea' ? 1 : 3"
                                    class="inputBoxSkeyboard pa-0" box
                                    :single-line="$root.virtualKeyboard.target.type!=='textarea'"
                                    :value="input"
                                    type="$root.virtualKeyboard.target.type"
                                    @click="onInputChange"/>
                        <v-text-field v-else
                                      :value="input"
                                      class="inputBoxSkeyboard"
                                      :type="$root.virtualKeyboard.target.type"
                                      @click="onInputChange"
                        />
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeVirtualKeyboard">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>
                <template slot="main">
                    <v-card class="full-size mb-1">
                        <SimpleKeyboard class="full-size" @onChange="onChange" @onKeyPress="onKeyPress" :input="input"
                                        :enableNewLine="this.$root.virtualKeyboard.target.type === 'textarea'"/>
                    </v-card>
                </template>
            </draggable-container>
        </v-dialog>
        <v-dialog fullscreen v-model="loginDialog">
            <login :auto-login-enabled="false" show-in-dialog="true" @loggedIn="onLoggedIn"
                   @cancel="loginDialog=false"></login>
        </v-dialog>
    </v-app>
</template>

<script>

import MenuApi from '@/api/menu.js'
import Shifts from '@/api/shifts'
import BackAnnotation from '@/api/backannotation'
import SimpleKeyboard from '@/components/SimpleKeyboard'
import DraggableContainer from '@/components/utilities/DraggableContainer'
import Vue from 'vue'
import Login from "@/views/Login";

export default {
    name: 'App',
    components: {
        Login,
        SimpleKeyboard,
        DraggableContainer
    },
    data() {
        return {
            clipped: false,
            drawer: true,
            fixed: true,
            miniVariant: false,
            right: true,
            rightDrawer: false,
            title: 'Antares Vision {appName}'.format({appName: this.$config.appName}),
            dashboards: {},
            reports: {},
            forms: {},
            selectedDashBoardToDelete: "",
            notifyPendingUncategorizedStops: false,
            notifyPendingActions: false,
            blinkPendingTimeTracking: false,
            blinkPendingActions: false,
            blinkProductionData: false,
            notifyProductionData: false,
            blinker: null,
            pendingActions: [],
            pendingActionsShow: [],
            pendingActionsStart: 0,
            pendingActionsTake: 50,
            pendingActionLoading: false,
            currentWorkorderName: "",
            currentTimeTracker: {text: [], value: -1},
            iterateDashboards: false,
            shuffleDashboards: [],
            shuffleTimer: null,
            shuffleIndex: 0,
            input: "",
            reportsLoading: [],
            windowSize: {w: 0, h: 0},
            page: undefined,
            currentShiftStatus: "",
            loginDialog: false,
            configMenu: [],
            infoMenu: []
        }
    },
    methods: {
        onChange(input) {
            let element = this.$root.virtualKeyboard.target;
            //write the input above the keyboard
            this.input = input;
            //used to set the input value and then create an 'Input' event,
            // which is the event that the 'v-model' directive listens to
            this.$root.virtualKeyboard.target.focus();
            element.value = input;
            element.dispatchEvent(new Event('input', element));
        },
        onKeyPress(button) {
            this.$root.virtualKeyboard.target.focus();
            if (button === "{enter}" && this.$root.virtualKeyboard.target.type !== "textarea") {
                this.closeVirtualKeyboard();
            }
        },
        onInputChange(input) {
            this.input = input.target.value;
            this.$root.virtualKeyboard.target.focus();
            // this.onChange(this.input);
        },
        setInputCaretPosition(elem, pos) {
            if (elem.setSelectionRange) {
                elem.focus();
                elem.setSelectionRange(pos, pos);
            }
        },
        closeVirtualKeyboard() {
            //condition used for v-autocomplete
            if (this.input !== this.$root.virtualKeyboard.target.value)
                this.$root.virtualKeyboard.target.value = this.input;
            this.input = "";
            this.$root.virtualKeyboard.target.readOnly = false;
            //used to lost focus
            this.$root.virtualKeyboard.target.blur();
            this.$root.virtualKeyboard.showVirtualKeyboard = false;
        },

        switchOnOffOsk() {
            this.$root.toggleOsk = !this.$root.toggleOsk;
        },

        handleToolbarSize() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.miniVariant = false;
                this.drawer = !this.drawer;
            } else {
                this.miniVariant = !this.miniVariant;
            }
        },
        getItemLabel(labelId) {
            switch (labelId) {
                case 'explore':
                    return this.$gettext("Data exploration");
                case 'tt':
                    return this.$gettext("Change time tracking");
                case "pasttt":
                    return this.$gettext("Stops categorization");
                case "woadjust":
                    return this.$gettext("Workorders");
                case 'shiftsparent':
                    return this.$gettext("Shifts");
                case 'shift':
                    return this.$gettext("Change shift");
                case 'shift-scheduler':
                    return this.$gettext("Shift Scheduler");
                case 'wo':
                    return this.$gettext("Change workorder");
                case 'quantities':
                    return this.$gettext("Change production counters");
                case 'settings':
                    return this.$gettext("Settings");
                case 'configuration':
                    return this.$gettext("Configuration");
                case 'info':
                    return this.$gettext("Info");
                case 'reports':
                    return this.$gettext("Edit reports");
                case "ttadjust":
                    return this.$gettext("Time tracking");
                case "pdadjust":
                    return this.$gettext("Past data adjustment");
                case 'rules':
                    return this.$gettext("Rules Configurator");
                case "forms":
                    return (!this.$config.options.forms.entities ? this.$gettext("Form list") : this.$gettext("Edit entities"));
                case "entities":
                    return this.$gettext("Entities instances");
                case "productionInstances":
                    return this.$gettext("Active production data");
                case "recipes":
                    return this.$gettext("Recipes Configurator");
                case "linerecipesconfigurator":
                    return this.$gettext('Line Recipes Configurator')
                case "wizards":
                    return this.$gettext("Edit wizards");
                case "activities":
                    return this.$gettext("Activities Register");
                case "orphanTransactions":
                    return this.$gettext("Orphan transactions");
                case "workorders":
                    return this.$gettext("Workorders");
                case "machines-settings":
                    return this.$gettext("Machines Settings");
                case "sublines-settings":
                    return this.$gettext("Sublines Settings");
                case "production":
                    return this.$gettext("Production");
                case "processes":
                    return this.$gettext("Processes");
            }
        },
        onLoggedIn() {
            this.loginDialog = false;
            this.$store.dispatch("setUnauthenticatedLogin", false);
            this.loadConfigurationMenu();
            this.loadDynamicLinks();
            this.loadInfoMenu();
            this.$root.initAutoLogOffTimer();
        },
        doLogout() {
            this.$root.resetApp();
        },
        goToPage(menuItem) {
            this.$router.push({name: menuItem.link});
        },
        loadDynamicLinks() {
            setTimeout((function (self) {
                return function () {
                    if (self.$store.state.isEmbedded) {
                        MenuApi.refreshMenu()
                            .then(t => {
                                console.log(t)
                            }).catch(t=> {
                            debugger;
                            console.error(t)
                        });
                        return
                    }
                    self.dashboards = [];
                    self.$dashboards.getMetadataList(false,false,true)
                        .then((dashboards) => {
                            self.dashboards = dashboards;
                        });

                    self.reports = [];
                    self.$dynamicElements.getMetadataList("reports", false,false, true, true)
                        .then((reports) => {
                            self.reports = reports;
                        });

                    self.forms = [];
                    if (self.$license.hasQuality() || self.$license.hasMaintenance())
                        self.$dynamicElements.getMetadataList("forms", false,false, true,true)
                            .then((forms) => {
                                self.forms = forms;
                            });

                    self.wizards = [];
                    if (self.$license.hasQuality() || self.$license.hasMaintenance())
                        self.$dynamicElements.getMetadataList("wizards", false,false, true, true)
                            .then((wizards) => {
                                self.wizards = wizards;
                            });

                }
            })(this), 1000);
        },

        async downloadReport(report, format) {
            let self = this
            this.$dynamicElements.downloadReport(report, "reports", format, null, null,
                () => {
                    self.reportsLoading.push(report);
                },
                () => {
                    self.reportsLoading.removeItem(report);
                })
        },
        openForm(action) {
            let activityAction = action.Actions.find(action => action.type === 'activity');
            let dynamicDescription = [];
            if (activityAction.Message.message)
                dynamicDescription.push(activityAction.Message.message.replace(/<\/?[^>]+(>|$)/g, ""))
            this.$router.push(
                {
                    name: 'forms',
                    params: {
                        routeId: action.Action.name, action: action, variables: action.variables, isTask: "true",
                        asset: action.ActivityTargets.asset,
                        description: {
                            dynamicDescription: dynamicDescription,
                            description: action.ActivityTargets.asset ? this.$gettext('Target asset for this activity: {0}').format(action.ActivityTargets.asset) : ''
                        }
                    }
                })
        },
        changeLanguage(language) {
            let oldLanguage = this.$language.current;
            this.$language.current = language;
            this.$vuetify.lang.current = this.$language.current !== 'default' ? this.$language.current : 'en';
            this.$root.changeLanguage(this.$language.current, oldLanguage);
            this.loadMenu();
        },
        openTimeTrackingDialog() {
            this.$timeTracking.openTimeTrackingDialog(this, this.$root.userName);
        },
        openBackAnnotationTimeTrackingDialog() {
            this.$timeTracking.openBackAnnotationTimeTrackingDialog(this, this.$root.userName, this.checkUncategorizedStops);
        },
        openWorkOrderDialog() {
            this.$workorders.openWorkOrderDialog(this, this.workorderSchedulingCallBack, this.$root.userName);
        },
        workorderSchedulingCallBack(oldWorkorder, newWorkorder) {
            if (oldWorkorder && newWorkorder === "NoWo") {  //Workorder was unscheduled, force time tracking back annotation if set in workflow
                let workFlowSettings = this.$settings.getWorkFlowSettings();
                if (!workFlowSettings || workFlowSettings.forceStopsCategorizationOnIdle)
                    this.$timeTracking.openBackAnnotationTimeTrackingDialog(this, this.$root.userName, this.checkUncategorizedStops, oldWorkorder, true);
                this.currentWorkorderName = "NO WORKORDER";
            } else if (newWorkorder && newWorkorder !== "NoWo") {
                this.currentWorkorderName = newWorkorder;
                this.openTimeTrackingDialog();
            }
        },
        openBackAnnotationDialog() {
            BackAnnotation.openBackAnnotationDialog(this, this.$root.userName);
        },
        openDemoWoCloseBackAnnotationDialog() {
            BackAnnotation.openBackAnnotationDialog(this, this.$root.userName, true);
        },
        openBackAnnotationWorkordersDialog() {
            BackAnnotation.openBackAnnotationWorkordersDialog(this, this.$root.userName);
        },
        openAdjustementTimeTrackingDialog() {
            this.$timeTracking.openAdjustementTimeTrackingDialog(this, this.$root.userName);
        },
        openShiftChangeDialog() {
            Shifts.openShiftChangeDialog(this, this.$root.userName, this.shiftChangeCallBack);
        },
        shiftChangeCallBack(newShift) {

            let self = this;
            let shiftClose = function () {
                let workFlowSettings = self.$settings.getWorkFlowSettings();
                if (!workFlowSettings || workFlowSettings.forceStopsCategorizationOnShiftClose)
                    self.$timeTracking.openBackAnnotationTimeTrackingDialog(self, self.$root.userName, self.checkUncategorizedStops, "", true);
            };
            let woUnschedule = function () {
                if (!self.$license.hasManager()) {
                    self.$workorders.unScheduleWorkorder(self.currentWorkorderName)
                        .then(() => {
                            self.currentWorkorderName = "NO WORKORDER";
                        })
                        .catch(err => {
                            self.$root.showDialogBox(self.$gettext("An error occurred while suspending workorder. Please try suspension manually."), null, self.$gettext("OK"), shiftClose, null, null);
                        });
                } else {
                    //In manager module just redirect to workorders page to allow user unscheduling workorder
                    //TODO retrieve default suspended status from workflow and execute suspension automatically
                    self.$router.push("/workordersPage")
                }
                shiftClose();
            };
            if (newShift === "NoShift") {  //Shift was closed, maybe they also want to close workorder
                if (this.currentWorkorderName !== "NO WORKORDER" && this.currentWorkorderName !== "" && this.$grants.get().operations.workOrderSheduling)
                    this.$root.showDialogBox(self.$gettext("Do you want to also suspend current workorder?"), null, self.$gettext("Yes"), woUnschedule, self.$gettext("No"), shiftClose);
                else shiftClose();
            }
        },
        loadCurrentWorkOrder() {
            let self = this;
            this.$workorders.getCurrentWorkorderId()
                .then(result => {
                    self.currentWorkorderName = result;
                })
                .catch(err => {
                    self.currentWorkorderName = "";
                    self.$root.showErrorNotification(err);
                });
        },
        loadCurrentTimeTracking() {
            let self = this;
            self.$timeTracking.getCurrentTimeTracker()
                .then(result => {
                    self.currentTimeTracker = result;
                })
                .catch(err => {
                    self.currentTimeTracker = {text: [], value: -1};
                    console.log(err);
                });
        },
        loadCurrentShift() {
            let self = this;
            self.$shifts.getCurrentShiftInfo()
                .then(currentShift => {
                    self.currentShiftStatus = currentShift ? self.$gettext("Shift opened") + ": " + currentShift.id : self.$gettext("No shift");
                })
                .catch(err => {
                    self.currentShiftStatus = "";
                    console.log(err);
                });
        },
        initBlinker() {
            let self = this;
            this.blinker = setInterval(() => {
                if (self.notifyPendingUncategorizedStops)
                    self.blinkPendingTimeTracking = !self.blinkPendingTimeTracking;
                else self.notifyPendingUncategorizedStops = false;
                if (self.notifyPendingActions)
                    self.blinkPendingActions = !self.blinkPendingActions;
                else self.notifyPendingActions = false;
                if (self.notifyProductionData)
                    self.blinkProductionData = !self.blinkProductionData;
                else {
                    self.notifyProductionData = false;
                    self.blinkProductionData = false
                }
            }, 2000);
        },
        checkUncategorizedStops() {
            this.$timeTracking.checkPendingUncategorizedStops(259200, this.showPendingUncategorizedStopsNotification)   //72h
        },
        showPendingUncategorizedStopsNotification(hasAny) {
            this.notifyPendingUncategorizedStops = hasAny;
        },
        checkPendingActions() {
            let self = this;
            this.$dataEntry.getPendingActions()
                .then((actions) => {
                    self.notifyPendingActions = Array.isUseful(actions);
                    self.pendingActions = actions || [];
                    self.pendingActionsShow.clear();
                    self.pendingActionsStart = 0;
                    self.pendingActionsShow = self.pendingActions.slice(self.pendingActionsStart, self.pendingActionsTake) || [];
                })
                .catch(() => {
                    self.pendingActions.clear();
                    self.pendingActionsShow.clear();
                    self.notifyPendingActions = false;
                })
        },
        checkProductionData() {
            let self = this;
            this.$dataEntry.getProductionData()
                .then((actions) => {
                    self.notifyProductionData = Array.isUseful(actions);
                })
                .catch(() => {
                    self.notifyProductionData = false;
                })
        },
        isItemsGroupValid(items, group) {
            return ((Object.keys(items).length > 1) && group.trim() && group !== 'ungrouped');
        },
        async toIterateDashboards() {
            this.shuffleDashboards.clear();
            this.shuffleIndex = 0;
            this.stopShuffle();
            if (this.iterateDashboards) {
                for (let group in this.dashboards) {
                    for (let dashboard in this.dashboards[group]) {
                        if (this.dashboards[group][dashboard].name) {
                            this.shuffleDashboards.push(this.dashboards[group][dashboard].name);
                        }
                    }
                }
                this.startShuffle();
            }
        },
        startStopShuffle() {
            if (this.shuffleTimer > 0) {
                this.stopShuffle()
            } else {
                this.startShuffle()
            }
        },
        startShuffle() {
            let self = this;
            this.shuffleTimer = setInterval(function () {
                self.$root.openDashboard(self.shuffleDashboards[self.shuffleIndex]);
                self.shuffleIndex = (self.shuffleIndex === self.shuffleDashboards.length - 1) ? 0 : self.shuffleIndex + 1;
            }, 15000)
        },
        stopShuffle() {
            clearInterval(this.shuffleTimer);
            this.shuffleTimer = null
        },
        onResize() {
            this.windowSize = {w: window.innerWidth, h: window.innerHeight};
        },
        openPage(menuItem) {
            this.$root.setCurrentPageTitle(menuItem.title);
            this.$router.push(menuItem.to);
        },
        scrollListener() {
            const listElm = this.$refs.infiniteList;
            listElm.addEventListener('scroll', e => {
                if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
                    this.pendingActionLoading = true;
                    setTimeout(() => {
                        this.pendingActionsStart += this.pendingActionsTake;
                        this.pendingActionsShow = this.pendingActionsShow.concat(this.pendingActions.slice(this.pendingActionsStart, this.pendingActionsStart + this.pendingActionsTake));
                        this.pendingActionLoading = false;
                    }, 500)
                }
            });
        },
        loadConfigurationMenu() {
            let self = this;
            this.configMenu = [
                {
                    title: self.$gettext("Plant Settings"),
                    icon: "fa fa-tools",
                    to: "/configuration/plant-settings",
                    visible: self.$config.deployment === 3
                },
                {
                    title: self.$gettext("Line Settings"),
                    icon: "fa fa-tools",
                    to: "/configuration/line-settings",
                    visible: self.$grants.hasLineSettings() && self.$config.deployment === 2
                },
                {
                    title: self.$gettext("Workflow"),
                    icon: "fa fa-sitemap",
                    to: "/configuration/workflow",
                    visible: self.$grants.hasWorkflow() && self.$config.deployment === 2
                },
                // {
                //     title: self.$gettext("Landing Page"),
                //     icon: "fa fa-redo-alt",
                //     to: "/configuration/landing-page",
                //     visible: self.$grants.hasWorkflow() && self.$config.deployment === 2
                // },
                {
                    title: self.$gettext("Line Stop Causes"),
                    icon: "fa fa-stopwatch",
                    to: "/configuration/line-stop-actions",
                    visible: self.$grants.get().settings.lineStopCausesEdit && self.$license.hasPerformance()
                },
                {
                    title: self.$gettext("Standard Times"),
                    icon: "far fa-clock",
                    to: "/configuration/standard-times",
                    visible: (self.$config.demo && self.$grants.get().settings.standardOpeningTime && self.$license.hasPerformance())
                },
                {
                    title: self.$gettext("Translations"),
                    icon: "fa fa-language",
                    to: "/configuration/translation",
                    visible: self.$grants.get().settings.translations
                },
                {
                    title: self.$gettext("System Configuration"),
                    icon: "fa fa-toolbox",
                    to: "/configuration/system-settings",
                    visible: self.$grants.get().settings.avionicsSystem
                },
                {
                    title: self.$gettext("Bulk Import/Export"),
                    icon: "fa fa-exchange",
                    to: "/configuration/bulkimportexport",
                    visible: self.$grants.get().settings.avionicsSystem
                },
                {
                    title: self.$gettext("Alarms Description"),
                    icon: "far fa-bell",
                    to: "/configuration/alarms-description",
                    visible: self.$config.deployment === 2 && self.$grants.get().settings.alarmsDescriptions
                },
                {
                    title: self.$gettext("Opc-ua clients settings"),
                    icon: "fa fa-cogs",
                    to: "/configuration/opc-description",
                    visible: self.$config.deployment === 2 && self.$grants.get().settings.opcUa
                },
                {
                    title: self.getItemLabel("machines-settings"),
                    icon: "fa fa-clone",
                    to: "/configuration/machines-settings",
                    visible: self.$config.deployment === 2 && self.$grants.get().settings.machineSettings
                },
                {
                    title: self.getItemLabel("sublines-settings"),
                    icon: "fas fa-ellipsis-h-alt",
                    to: "/configuration/sublines-settings",
                    visible: self.$config.deployment === 2 && self.$license.hasManager() && self.$grants.get().settings.sublinesSettings
                },
                {
                    title: self.$gettext("Operation Modes"),
                    icon: "fa fa-object-group",
                    to: "/configuration/operation-modes",
                    visible: self.$config.deployment === 2 && self.$license.hasManager() && self.$grants.get().settings.canEditOperationModes
                },
                {
                    title: self.$gettext("Mail settings"),
                    icon: "far fa-envelope",
                    to: "/configuration/mail-settings",
                    visible: self.$grants.get().settings.avionicsSystem
                },
                {
                    title: self.$gettext("Group and privileges"),
                    icon: "fas fa-users-cog",
                    to: "/embeddedpage/groupsandprivileges",
                    visible: self.$grants.get().groupsandprivileges.canadmin
                },
                {
                    title: self.$gettext("External Keys"),
                    icon: "fas fa-key",
                    to: "/embeddedpage/externalkeys",
                    visible: self.$grants.hasGrant("orchestrator", "details")
                },
            ]
        },
        loadInfoMenu() {
            let self = this;
            this.infoMenu = [
                {
                    title: self.$gettext("System Info"),
                    icon: "fa fa-medkit",
                    to: "/info/system",
                    visible: self.$grants.get().info.system
                },
                {
                    title: self.$gettext("Audit trails"),
                    icon: "fa fas fa-notes-medical",
                    to: "/info/audits",
                    visible: self.$grants.get().info.audits
                },
                {
                    title: self.$gettext("Validation dashboard"),
                    icon: "fas fa-check-square",
                    to: "/info/validation",
                    visible: self.$config.deployment === 2 && self.$grants.get().settings.avionicsSystem
                }
            ]
        },
        redirectToHomePage() {
            let workFlowSettings = this.$settings.getWorkFlowSettings();
            if (String.prototype.isString(workFlowSettings.defaultDashboard)) {
                this.$router.push("/dashboards/" + workFlowSettings.defaultDashboard + "/show");
            } else if (workFlowSettings.defaultDashboard[this.$grants.getLevel()[0].key]) {
                this.$router.push("/dashboards/" + workFlowSettings.defaultDashboard[this.$grants.getLevel()[0].key] + "/show");
            }
        },
        goBack() {
            Vue.prototype.goBack();
        }
    },
    computed: {
        routerSize() {
            return {
                w: (this.windowSize.w - 240) * 1.25,
                h: (this.windowSize.h - 50) * 1.25
            }
        },
        contentPadding() {
            if (!this.drawer)
                return ("padding: 50px 0px 0px 0px!important;");
            else if (!this.$store.state.globalDrawer && !this.$store.state.globalHeader)
                return ("padding: 0px 0px 0px 0px!important;");
            else if (this.miniVariant)
                return ("padding: 50px 0px 0px 64px!important;");
            else
                return ("padding: 50px 0px 0px 240px!important;");
        },
        userName() {
            if (this.$root.userName) {
                return this.$root.userName;
            }
            return "Not logged in";
        },
        sideLogoSrc() {
            if (this.miniVariant)
                return require('./assets/logo-avionics-collapsed.png');
            else if (this.$config.isAvionics)
                return require('./assets/logo-avionics.png');
            else return require('./assets/logo-app.png')
        },
        // secondLogoSrc () {
        //     return this.miniVariant ? require('./assets/second-logo-collapsed.jpg') : require('./assets/second-logo.jpg');
        // },
        sideLogoStyle() {
            return this.miniVariant ? "menuHeaderCollapsed" : "menuHeader";
        },
        reloadDynamicLinks() {
            return this.$root.reloadDynamicLinks;
        },
        reloadActions() {
            return this.$root.reloadActions;
        },
        hideFrame() {
            return this.$root.hideFrame;
        },
        startedUp() {
            return this.$root.startedUp;
        },
        severityIcon() {
            let icon = "";
            switch (this.$root.snackBar.severity.toLowerCase()) {
                case "info":
                    icon = "fa-info";
                    break;
                case "warning":
                    icon = "fas fa-exclamation";
                    break;
                case "error":
                    icon = "fa-exclamation-triangle";
                    break;

                }
                return icon;
            },
            isShufflePlaying(){
                return this.shuffleTimer > 0
            },
            loaderGif() { return require('@/assets/avloader.png') },
            showVirtualKeyboard(){
                return this.$root.virtualKeyboard.showVirtualKeyboard && !this.$vuetify.breakpoint.smAndDown;
            },
            toggleOsk(){
                return this.$root.toggleOsk;
            },
            infoConfiguration() {
                return this.infoMenu
            },
            menuConfiguration() {
                return this.configMenu
            },
            notificationInfo() {
                return this.$root.notificationInfo;
            },
            backPath() {
                return this.$store.state.haveHistory;
            },
            loading(){
                return this.$root.loading
            },
            loadingMessage(){
                return this.$root.loadingMessage
            },
            loadingMinTime(){
                return this.$root.loadingMinTime
            },
            canViewForms(){
                return this.$root.configured && (this.$license.hasQuality() || this.$license.hasMaintenance()) && (this.$grants.get().dataExploration.forms || !Object.isEmpty(this.forms) || this.$grants.get().dataExploration.canManageActiveProductionData)
            },
            canViewWizards(){
                //AN Masking wizard for version 5.2 to be restored on further version
                return this.$root.configured && (this.$license.hasQuality() || this.$license.hasMaintenance()) && false
            },
            lineStatusInfo() {
                if(!this.$config.options.statusSection.show)
                    return [];

            let statusInfo = []
            if (this.$config.options.statusSection.showLine) {
                statusInfo.push({
                    text: this.$gettext("Line"),
                    value: this.$root.identification ? this.$root.identification.physicalLineId : ""
                })
            }
            if (this.$config.options.statusSection.showUser)
                statusInfo.push({text: this.$gettext("Operator"), value: this.$root.userName})
            if (this.$config.options.statusSection.ShowWorkorder)
                statusInfo.push({text: this.$gettext("Workorder"), value: this.currentWorkorderName})
            if (this.$config.options.statusSection.showShift)
                statusInfo.push({text: this.$gettext("Shift"), value: this.currentShiftStatus})
            if (this.$config.options.statusSection.showTimeTracking)
                statusInfo.push({text: "", value: "_timeTracking"})
            if (Array.isUseful(this.$config.options.statusSection.additionalFields)) {
                for (let item of this.$config.options.statusSection.additionalFields) {
                    let value = ""
                    if (this.notificationInfo) {
                        value = this.notificationInfo
                        let toks = item.value.split(".")
                        for (let tok of toks)
                            value = value[tok]
                        if (!Object.isUseful(value))
                            value = ""
                    }
                    statusInfo.insertItem(item.position, {text: item.text, value: value})
                }
            }
            if (this.$config.options.statusSection.showProfile)
                if (Array.isUseful(this.$store.state.shared.profiles))
                    statusInfo.push({text: this.$gettext("Profile"), value: this.$store.state.shared.profiles[0].name})


            return statusInfo;
        },
        statusAreaHeight() {
            let value = 0;
            let statusInfo = this.lineStatusInfo;
            if (Array.isUseful(statusInfo))
                value += 65 //Show header
            let singleLines = statusInfo.filter(item => item.value !== "_timeTracking")
            value += singleLines.length * 18 //Some space for each line
            if (singleLines.length < statusInfo.length)
                value += 90    //This means we have time tracking that is bigger

            return value
        }
    },
    watch: {
        reloadDynamicLinks() {
            if (this.reloadDynamicLinks) {
                this.$root.reloadDynamicLinks = false;
                this.loadDynamicLinks();
            }
        },
        reloadActions() {
            if (this.reloadActions) {
                this.$root.reloadActions = false;
                this.checkPendingActions();
            }
        },
        startedUp() {
            if (this.$config.operationMode !== "standard") {
                Vue.prototype.$store.dispatch('setHeaderVisibility', false);
                Vue.prototype.$store.dispatch('setHeaderVisibility', false);
                Vue.prototype.$store.dispatch('setToolbarVisibility', false);
                Vue.prototype.$store.dispatch('setSidebarVisibility', false);
                Vue.prototype.setPageLoadingCompleted();
                return;
            }
            if (this.$store.state.shared.token !== "") {

                Vue.prototype.$store.dispatch('setHeaderVisibility', true);
                Vue.prototype.$store.dispatch('setToolbarVisibility', true);
                Vue.prototype.$store.dispatch('setSidebarVisibility', true);
            }
            this.loadDynamicLinks();
            if (this.$config.isAvionics) {

                this.checkPendingActions();
                setInterval(this.checkPendingActions, 60000);

                if (this.$config.deployment === 2) {
                    this.loadCurrentTimeTracking();
                    this.loadCurrentWorkOrder();
                    this.loadCurrentShift();
                    if (this.$license.hasPerformance()) {
                        this.checkUncategorizedStops();
                        setInterval(this.checkUncategorizedStops, 60000);
                    }
                    if (this.$license.hasPerformance()) {
                        this.checkProductionData();
                        setInterval(this.checkProductionData, 60000);
                    }
                    if (this.$license.hasPerformance() || this.$license.hasQuality() || this.$license.hasMaintenance())
                        this.initBlinker();
                    setInterval(this.$alarms.checkCategoryChanges, 10000);


                }
                Vue.prototype.setPageLoadingCompleted();
                this.loadConfigurationMenu();
                this.loadInfoMenu();
            }
        },
        notificationInfo() {
            this.currentWorkorderName = this.$root.notificationInfo.Line.WorkorderID !== "" ? this.$root.notificationInfo.Line.WorkorderID : this.$gettext("NO WORKORDER");
            this.currentShiftStatus = this.$root.notificationInfo.Shift === null ? this.$gettext("No shift") : this.$gettext("Shift opened") + " " + this.$root.notificationInfo.Shift.Id;
            this.currentTimeTracker = {
                text: this.$timeTracking.getTimeTrackerFromId(this.$root.notificationInfo.TimeTracking.Activity),
                value: this.$root.notificationInfo.TimeTracking.Activity
            }
        },
    },
    async beforeCreate() {
        this.$store.dispatch('setIsEmbedded', window.location !== window.parent.location);
        if (Vue.prototype.$store.state.isEmbedded) {
            Vue.prototype.$store.dispatch('setHeaderVisibility', false);
            Vue.prototype.$store.dispatch('setToolbarVisibility', false);
            Vue.prototype.$store.dispatch('setSidebarVisibility', false);
            console.log('app base mount');
            Vue.prototype.$baseMount(this.$route);
            console.log("app wait for page loading");
            await Vue.prototype.waitForPageLoading();
            console.log("app page loading complete");
            this.$grants.init();
            this.$root.applicationStartUp("embed");
        }
        document.title = this.$config.appName;
    },
    mounted() {
        this.onResize();
        /* operationMode options:
           "standard": standard application mode
           "reporting": embedded reporting service
           "scan": transaction on scan
        */
        if (this.$config.isStandardOperationMode()) {   //If we are coming from a special operation mode keep it
            let operationMode = "standard";
            if (this.$route.path === '/reportingservice')
                operationMode = "reporting";
            if (this.$route.path.match("/scan.*"))
                operationMode = "scan";
            if (this.$route.path.match("/dashboards/.*/maximize"))
                operationMode = "maximize";
            this.$config.operationMode = operationMode;
        }
        //Manage application setup in case we are coming from a refresh instead of login
        if (!Vue.prototype.$store.state.isEmbedded) {
            if (!this.$root.startedUp && !this.$route.path.toLowerCase().includes("login")) {
                this.$root.applicationStartUp("mount");
            }
        }
    },
}

</script>

<style>

.v-picker__body {
    zoom: 125%
}

.v-slider {
    zoom: 125%
}

.jsoneditor-container {
    zoom: 125%
}

.v-dialog--fullscreen {
    padding: 70px 8px 8px 8px;
}

.application {
    width: 125vw !important;
    height: 125vh !important;
    zoom: 80%;
    transform-origin: top left;
    -moz-transform:  scale(0.8,0.8);
    -moz-transform-origin: top left;
}

html {
    height: 100%;
    width: 100%;
    /*zoom: 80%;*/
    overflow-y: auto;
    scrollbar-width: none; /*hide scrollbar on firefox*/
}

.av-card {
    border-radius: 5px;
    border: 1px solid lightgrey !important;
    height: calc(100%);
}

.av-card-borderless {
    border: 0px !important;
    height: calc(100%);
}

.av-card-content {
    height: 100%;
    overflow-y: auto;
}

.page-container {
    /*padding: 10px 5px 0 5px !important;*/
    height: 100% !important;
    hoverflow-y: auto !important;
}

.std-control {
    max-width: 300px
}

.ellipses-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wordwrap {
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap; /* Opera <7 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE */
}

body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: var(--av-grey);
    outline: 1px solid slategrey;
}

.flat-button {
    margin-right: 30px;
}

.speeddial-button {
    margin-right: 30px;
}

.flat-button-left {
    left: 110px;
}

.second-button-position {
    margin-right: 110px
}

.third-button-position {
    margin-right: 190px
}

.av-page-container {
    display: flex;
    flex-direction: column;
    max-height: 100vh
}

.av-spacer {
    font-size: 8px;
}

.av-scrollable-content {
    flex-grow: 1;
    margin-left: 0px;
    margin-right: 0px;
    height: calc(100vh - 300px);
    overflow-y: auto;
}

#backbtn {
    background-color: darkgrey;
}

#savebtn {
    background-color: #00B0FF;
}

#editbtn {
    background-color: #e64a19;
}

#refreshbtn {
    background-color: #2EBFA7;
}

#addbtn {
    background-color: #FDD835;
}

#cancelbtn {
    background-color: #C62828;
}

.menuHeader {
    height: 55px;
    width: 275px;
    margin: 0px;
    padding: 0px;
}

.menuHeaderCollapsed {
    height: 50px;
    width: 91px;
    margin: -20px;
}

/*.sectionTitle {*/
/*background-color: var(--av-lightblue);*/
/*width: 100%;*/
/*color: white;*/
/*font-size: 24px !important;*/
/*font-weight: 500 !important;*/
/*line-height: 60px !important;*/
/*letter-spacing: normal !important;*/
/*font-family: 'Roboto', sans-serif !important;*/
/*padding-left: 10px;*/
/*padding-top: 0px;*/
/*min-height: 60px;*/
/*max-height: 60px;*/
/*line-height: 60px;*/
/*overflow: hidden;*/
/*margin-left: 0px;*/
/*margin-right: 0px;*/
/*}*/

.sectionTitle {
    width: 100%;
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 60px !important;
    letter-spacing: normal !important;
    font-family: 'Roboto', sans-serif !important;
    padding-left: 10px;
    padding-top: 0px;
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    margin-left: 0px;
    margin-right: 0px;
}

.sectionTitleWarning {
    background-color: var(--av-orange);
    width: 100%;
    color: white;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 60px !important;
    letter-spacing: normal !important;
    font-family: 'Roboto', sans-serif !important;
    padding-left: 10px;
    padding-top: 0px;
    min-height: 60px;
    max-height: 60px;
    line-height: 60px;
    overflow: hidden;
    margin-left: 0px;
    margin-right: 0px;
}

.overlayCover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.overlayCoverLight {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.overlayCoverTransparent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
}

.vlist-tile-custom {
    /*white-space: pre;*/
    min-width: 36px;
}

.listSubItem {
    margin-left: 20px;
}

:root {
    --background: rgb(249, 249, 249);
    --light-grey: rgb(217, 217, 217);
    --av-grey: rgb(117, 117, 117);
    --av-red: rgb(231, 29, 36);
    --av-orange: rgb(244, 151, 57);
    --av-yellow: rgb(255, 204, 3);
    --av-lightgreen: rgb(154, 196, 58);
    --av-green: rgb(112, 185, 45);
    --av-lightblue: rgb(57, 139, 203);
    --av-blue: rgb(60, 100, 173);
}

.v-list__group__header__prepend-icon .v-icon {
    color: var(--av-grey);
}

.v-input__icon--prepend .v-icon {
    color: var(--av-grey);
}

.man-1 {
    margin: -4px;
}

.pan-1 {
    padding: -4px;
}

a.jsoneditor-poweredBy {
    display: none;
}

.overlayedSmallIconsContainer {
    position: relative;
    display: block;
    text-align: left;
}

.overlayedSmallIconsCanvas {
    position: absolute;
    top: 13px;
    left: 17px;
}

.overlayedSmallIconsOverlay {
    position: absolute;
    top: 14px;
    left: 19px;
}

.inputBoxSkeyboard {
    width: 100%;
    height: 35px;
    padding: 20px;
    margin-bottom: 35px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
}

.inputBoxSkeyboard .v-text-area {
    padding: 0;
    margin: 0;
    height: 50px;
}

.simple-keyboard {
    width: auto;
    /*max-width: 1850px;*/
}

::-webkit-scrollbar-thumb {
    background-color: var(--av-lightblue);
    outline: 1px solid slategrey;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px
}

::-webkit-scrollbar-thumb:hover {
    background: var(--av-blue) !important;
}

/*.data-identifier{*/
/*text-align: center;*/
/*width: 100%;*/
/*text-transform: uppercase;*/
/*background-color:rgba(80,190,255,0.2);*/
/*padding:5px;*/
/*margin-left: 5px;*/
/*}*/

.data-identifier {
    text-align: center;
    width: 100%;
    height: 25px;
    background-color: transparent;
    padding-top: 7px;
    margin: 0px;
}

.full-size {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
}

.v-input .v-label {
    font-size: 1em;
}

.v-input .v-label--active {
    transform: translateY(-18px) scale(.9);
}

/* Makes treeview label appended slot to be near to label instead of right spaced */
.v-treeview-node__label {
    flex-grow: 0;
}

table.v-table tbody td, table.v-table tbody th {
    height: 25px;
}

/*Needed to avoid line break in text fields prefix when text contains a space (vuetify bug?)*/
.v-text-field__prefix {
    white-space: pre;
}

.v-text-field {
    padding: 0px;
}

.v-input--selection-controls {
    margin: 0px;
    padding: 0px;
}

.v-input__slot {
    /*margin: 0px;*/
    margin-bottom: 0px !important;
}

.scrollingContainer {
    overflow: hidden;
    text-overflow: ellipsis;
}

.scrollingContainer a {
    text-decoration: none;
    margin-top: 0em;
    text-align: center;
    display: inline-block; /* important */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: unset;
}

.scrollingContainer, .scrollingText {
    -webkit-transition: 3.3s;
    -moz-transition: 3.3s;
    transition: 3.3s;

    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    transition-timing-function: linear;
}

.scrollingContainer:hover {
    text-overflow: unset;
}

.scrollingContainer a:hover {
    text-decoration: none;
}

.scrollingText {
    margin-left: 0em;
}

.scrollingContainer:hover .scrollingText {
    margin-left: -300px;
}

/*.expansionPanelHeader {*/
/*background-color: var(--av-lightblue) !important;*/
/*margin-left: 10px;*/
/*padding-left: 10px;*/
/*}*/

.v-expansion-panel__header {
    height: 60px;
}

.v-text-field input {
    font-weight: 500;
}

.v-select__selection {
    font-weight: 500;
}

.v-chip__content {
    font-weight: 500;
}

.av-title {
    font-size: 20px !important;
    font-weight: 500;
    line-height: 1 !important;
    letter-spacing: 0.02em !important;
    font-family: 'Roboto', sans-serif !important;
    color: var(--av-grey);
}

/*Quasar classes*/
.window-height {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 100vh !important;
}

.window-width {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100vw !important;
}

.items-center,
.flex-center {
    align-items: center;
}

.text-white {
    color: #fff !important;
}

.bg-grey-4 {
    background: #e0e0e0 !important;
}

.header-background {
    background-color: #398bcb
}


/*used for fix headers*/
.theme--dark.v-table thead th {
    background-color: #424242;
}

.theme--light.v-table thead th {
    background-color: #fff;
}

/* Theme */
.fixed-header {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.fixed-header table {
    /*table-layout: fixed;*/
}

.fixed-header th {
    position: sticky;
    top: 0;
    z-index: 5;
}

.fixed-header th:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.fixed-header tr.v-datatable__progress th {
    height: 1px;
}

.fixed-header .v-table__overflow {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-x: auto;
    overflow-y: auto;
}

.fixed-header .v-datatable.v-table {
    flex-grow: 0;
    flex-shrink: 1;
}

.fixed-header .v-datatable.v-table .v-datatable__actions {
    /*flex-wrap: nowrap;*/
}

.fixed-header .v-datatable.v-table .v-datatable__actions .v-datatable__actions__pagination {
    /*white-space: nowrap;*/
}

.list-group {
    overflow: auto;
    max-height: 40vh;
}

.loading {
    text-align: center;
    position: absolute;
    z-index: 9;
    color: white;
    background: rgb(57 139 203);
    padding: 8px 18px;
    border-radius: 5px;
    left: calc(50% - 45px);
    top: calc(50% - 18px);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}

.fade-enter, .fade-leave-to {
    opacity: 0
}

.status-label {
    float: left;
    position: relative;
    left: 16px;
    top: -16px;
    width: calc(95% - 16px);
}
</style>
