<template>
    <DynamicElementBase>
        <RawDataTable v-if="cShowDataFlow" :dataCollection="datac" :containerHeight="height"/>
    </DynamicElementBase>
</template>

<script>
    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue';
    import RulesEngine from '@/api/rules.js';
    import RawDataTable from '@/components/graphics/RawDataView.vue';

    export default {
        name: "RuleBase",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            RawDataTable
        },
        data() {
            return {
                dataCollection: [],
                runningRule : false,
                runningRulesTimer: null,
                disableDeploy: false,
                disableStop: false,
                matchingDescriptorVersion: true,
                deploying: false,
                showDataFlow: true,
                loading: false
            }
        },
        computed: {
            datac() {
                return this.scope().dataCollection;
            },
            height() {
                return this.scope().containerHeight - 60
            },
            isRunningRule() {
                return this.scope().runningRule;
            },
            cShowDataFlow() {
                return this.scope().showDataFlow;
            }
        },
        mounted() {
            this.$settings.loadMailSettings();
            this.fixedHeight = false;
            this.validators.push({
                onDeploy: true,
                onChange: true,
                validator: this.validateRule
            });
            this.validators.push({
                onDeploy: true,
                onChange: false,
                validator: this.validateRuleOutput
            });
            this.validators.push({
                onDeploy: true,
                onChange: false,
                validator: this.validateActivityRuleRecipients
            });
            this.validators.push({
                onDeploy: true,
                onChange: false,
                validator: this.validateActivityRuleFormName
            });
            this.validators.push({
                onDeploy: true,
                onChange: false,
                validator: this.validateReportsExportOutput
            });
            this.validators.push({
                onDeploy: true,
                onChange: false,
                validator: this.validateCustomMessageOutput
            });
            this.dataExplorationMode.requiresRulesProcessing = true;
            this.dataExplorationMode.requiresRulesConditions = true;
            this.dataExplorationMode.requiresRulesOutputs = true;
            this.dataExplorationMode.requiresCrossAggregation = false;
            this.dataExplorationMode.requiresFunctions = false;
            this.dataExplorationMode.rulesExecutionSchedulingMode.required = true;
            this.scope().childHandlers.onDeploy = this.ruleDeploy;
            this.scope().childHandlers.onUnDeploy = this.ruleStop;
            this.scope().childHandlers.checkIsDeployed = () => { return this.isRunningRule; };
            this.scope().childHandlers.checkItemUsefulness = () => { return true; };
            this.scope().childHandlers.getItemStatus = this.getRuleStatus;
            setTimeout(this.checkRunningRule, 1000);
            if(!this.scope().runningRulesTimer)
                this.scope().runningRulesTimer = setInterval(this.checkRunningRule, 10000);
        },
        beforeDestroy () {
            clearInterval(this.runningRulesTimer);
        },
        methods: {
            getRuleDescriptor() {
                return {
                    "properties": this.scope().properties,
                    "queryDescriptor": this.scope().queryDescriptor,
                    "data": this.scope().transactionalData.dataItems,
                    "rules": this.scope().rules,   //TODO specialize for rules only
                    "outputs": this.scope().outputs,
                    "formVariables":this.scope().formVariables,
                    "version": this.scope().version
                }
            },
            validateRule() {

                let self = this;
                this.clearErrors(true);

                function showValidationError(error, self) {
                    let err = error;
                    if(error.Message)   //TODO verify if this is still a valid case
                        err = error.Message;
                    if(error.message)
                        err = error.message;
                    console.error("Rule " + self.properties.name + " validation error: " + err);
                    if(err.startsWith("ruleValidationError:"))
                        self.setError(err.replace("ruleValidationError:", ""), true);
                    else
                        self.setError(self.$gettext("Rule is not valid. Please fill-in all necessary parameters"), true);
                }

                return new Promise(resolve => {
                    try {
                        RulesEngine.compileRule(this.getRuleDescriptor())
                            .then(() => {
                                self.onErrorValidation(false);
                                resolve(true)
                            })
                            .catch((error) => {
                                debugger;
                                self.onErrorValidation(true);
                                showValidationError(error, self);
                                resolve(false)
                            })
                    } catch (ex) {
                        debugger;
                        self.onErrorValidation(false);  //TODO maybe true?
                        showValidationError(ex, self);
                        resolve(false)
                    }
                });
            },
            validateRuleOutput() {
                let self = this;
                if (!Array.isUseful(self.scope().outputs)) {
                    self.$root.showErrorNotification(self.$gettext(`Rule output must not be empty`), true, true);
                    return false
                } else
                    return true
            },
            validateActivityRuleRecipients() {
                let self = this;
                if (self.scope().properties.isActivity) {
                    let activityOutput = self.scope().outputs.find(o => o.outputType === 'activity');
                    if (activityOutput.activityOutput.recipients.length === 0) {
                        self.$root.showErrorNotification(self.$gettext(`At least one user profile need to be set in recipients`), true, true)
                        return false
                    }
                }
                return true
            },
            validateActivityRuleFormName() {
                let self = this;
                if (self.scope().properties.isActivity) {
                    let activityOutput = self.scope().outputs.find(o => o.outputType === 'activity');
                    console.log(activityOutput);
                    if (activityOutput.activityOutput.customFormSelect && activityOutput.activityOutput.form === ''){
                        self.$root.showErrorNotification(self.$gettext('Activity output form name must be selected'), true, true)
                        return false
                    }
                }
                return true
            },
            validateReportsExportOutput() {
                let self = this;
                // //TODO Validate reports output type (path provided, selected at least one report to export..)
                if (Array.isUseful(self.scope().outputs) && self.scope().outputs.some(o => o.outputType === 'reportExport')) {
                    let reportOutputs = self.scope().outputs.filter(o => o.outputType === 'reportExport');
                    if (reportOutputs.some(reportOutput => reportOutput.reportExport.report.newName === undefined)) {
                        self.$root.showErrorNotification(self.$gettext('Path and new name must be provided for every Export report output'), true, true);
                        return false;
                    }
                }
                return true
            },
            validateCustomMessageOutput() {
                let self = this;
                //TODO Validate custom message output type (topic provided, no empty body..)
                if (Array.isUseful(self.scope().outputs) && self.scope().outputs.some(o => o.outputType === 'customMessage')) {
                    let customMessageOutputs = self.scope().outputs.filter(o => o.outputType === 'customMessage');
                    if (customMessageOutputs.some(customMessageOutput => customMessageOutput.customMessage.topic === '' || customMessageOutput.customMessage.data === '')) {
                        self.$root.showErrorNotification(self.$gettext('Topic and Json data must be provided for every Custom message output'), true, true);
                        return false;
                    }
                }
                return true
            },
            onErrorValidation(hasError) {
                if(hasError)
                    this.disableLoader();
                if(!this.loading)
                    this.disableDeploy = hasError;
            },
            refreshData(dataValues) {
                this.scope().dataCollection = dataValues;
            },
            deployLoader() {
                this.loading = true;
                this.$root.setLoading(true, this.$gettext("Starting rule..."));
            },
            stopLoader() {
                this.loading = true;
                this.$root.setLoading(true, this.$gettext("Stopping rule..."));
            },
            unLoaderDeploy() {
                if(!this.loading)
                    return;
                this.checkRunningRule();
                if(!this.isRunningRule)
                    setTimeout(this.unLoaderDeploy, 500);
                else this.disableLoader();
            },
            unLoaderStop() {
                if(!this.loading)
                    return;
                this.checkRunningRule();
                if(this.isRunningRule)
                    setTimeout(this.unLoaderStop, 500);
                else this.disableLoader();
            },
            disableLoader() {
                this.$root.setLoading(false);
                this.disableDeploy = false;
                this.disableStop = false;
                this.loading = false;
            },
            async ruleDeploy() {
                if (!RulesEngine.validateRuleName(this.scope().properties.name)) {
                    this.$root.showErrorNotification(this.$gettext("Rule name can only contain letters, numbers, spaces and minus sign (-)"), true);
                    return;
                }
                this.deploying = true;
                this.scope().matchingDescriptorVersion = true;
                this.deployLoader();
                this.disableStop = true;
                this.disableDeploy = true;
                let descriptor =  this.getRuleDescriptor();
				let self = this;
                return new Promise((resolve, reject) => {
                    RulesEngine.deployRule(descriptor, this.$root.userName)
                        .then(() => {
                            self.$audits.save(self.$root.userName, self.$audits.items().ruleStarted, "", "", descriptor.properties.name)
                                .catch(() => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                });
                            resolve()
                        })
                        .catch(err => {
                            console.log(err);
                            reject()
                        })
                        .finally(() => {
                            self.unLoaderDeploy();
                        })
                });
            },
            ruleStop() {
                let self = this;
                this.stopLoader();
                this.disableStop = true;
                this.disableDeploy = true;
                let isDeterministic = this.scope().rules.length === 0;
                return new Promise((resolve, reject) => {
                    let descriptor=undefined
                    if (this.scope().properties.isActivity){
                        descriptor =  this.getRuleDescriptor();
                    }
                    RulesEngine.stopRule(this.scope().properties.name, isDeterministic, this.scope().properties.isActivity,descriptor)
                        .then(() => {
                            self.$audits.save(self.$root.userName, self.$audits.items().ruleStopped, "", "", self.scope().properties.name)
                                .catch(() => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                });
                            resolve();
                        })
                        .catch(err => {
                            debugger
                            reject();
                            console.log(err);
                        })
                        .finally(() => {
                            self.unLoaderStop();
                        })
                });
            },
            async checkRunningRule() {
                try {
                    this.scope().runningRule = await RulesEngine.isRuleRunning(this.scope().properties.name);
                } catch(error) {
                    debugger
                }
                if (this.isRunningRule) {
                    //Very seldomly it happens that deploy flag in WBase got disaligned with RTE status, keep it aligned just in case
                    this.scope().properties.deployStatus = 1;
                    this.checkDescriptionVersion();
                }
            },
            checkDescriptionVersion() {
                let wd =  this.getElementDescriptor();
                this.scope().matchingDescriptorVersion = wd.version === wd.deployedVersion;
            },
            getRuleStatus() {
                if (!this.runningRule)
                    return {status: 0, message: this.$gettext("This rule is not running")}
                else if (!this.matchingDescriptorVersion)
                    return {status: 2, message: this.$gettext("This rule is running with a different configuration")}
                else
                    return {status: 1, message: this.$gettext("This rule is running")}
            }
        }
    }

</script>

<style scoped>
    table th { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table td { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table tr { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
</style>
