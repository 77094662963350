<template>
    <v-layout column>
        <label class="title mt-4">Transaction rules - rules are evaluated top to bottom</label>
        <v-data-table :headers="scheduledRulesHeaders" :items="scheduledRulesDataSource" hide-actions  class="elevation-2 subheading mt-2">
            <template slot="items" slot-scope="props">
                <tr :class="props.item.pinned ? 'disabled' : ''" :style="props.item.blocked ? 'background-color: rgba(255, 0, 0, 0.1)' : ''">
                <!--<td :class="'text-xs-center px-2 ' + canMove(props.index) ? 'handle' : '' " style="width: 30px; background-color: white; border: 0; position: relative; cursor: grab" >-->
                    <!--<av-icon v-if="canMove(props.index)" color="info" style="cursor: grab">fas fa-arrows-alt</av-icon>-->
                <!--</td>-->
                <td v-if="!props.item.pinned" class="text-xs-center px-2" style="width: 60px;">
                    <v-layout row>
                    <v-btn v-if="canMoveUp(props.index)" :disabled="!canManageRulePriority || !canEdit(props.item)" fab small icon color="info" @click="move(props.index, 'up')">
                        <av-icon light>fas fa-arrow-up</av-icon>
                    </v-btn>
                    <v-btn v-if="canMoveDown(props.index)" :disabled="!canManageRulePriority || !canEdit(props.item)" fab small icon color="info" @click="move(props.index, 'down')">
                        <av-icon light>fas fa-arrow-down</av-icon>
                    </v-btn>
                    </v-layout>
                </td>
                <td v-else class="text-xs-center px-2" style="width: 30px;" >
                    <v-tooltip v-if="props.item.pinned" bottom>
                        <av-icon slot="activator">fas fa-thumbtack</av-icon>
                        <span v-translate>This rule is fixed in this position</span>
                    </v-tooltip>
                </td>
                <td class="'text-xs-center px-2" style="width: 30px;" >
                    <v-tooltip v-if="props.item.blocked" bottom>
                        <av-icon slot="activator" color="error">fas fa-exclamation-triangle</av-icon>
                        <span v-translate>Rule is unreachable since an unconditional returning rule is evaluated before it</span>
                    </v-tooltip>
                    <v-tooltip v-else-if="props.item.returnMode" bottom>
                        <av-icon slot="activator" :color="props.item.returnMode.color">{{props.item.returnMode.icon}}</av-icon>
                        <span>{{props.item.returnMode.tooltip}}</span>
                    </v-tooltip>
                </td>
                <!--<td class="text-xs-center px-2" style="width: 30px;">-->
                    <!--<v-btn v-if="props.index > 0" fab small color="info" @click="move(props.item, 'up')">-->
                        <!--<av-icon light>fas fa-arrow-up</av-icon>-->
                    <!--</v-btn>-->
                <!--</td>-->
                <!--<td class="text-xs-center px-2" style="width: 30px;">-->
                    <!--<v-btn v-if="props.index < scheduledRulesDataSource.length - 1" fab small color="info" @click="move(props.item, 'down')">-->
                        <!--<av-icon light>fas fa-arrow-down</av-icon>-->
                    <!--</v-btn>-->
                <!--</td>-->
                <td class="text-xs-left subheading">{{ props.item.name }}</td>
                <td class="text-xs-left">{{ props.item.description }}</td>
                <td class="text-xs-left subheading"><span :style="'color:' + props.item.status.color">{{ props.item.status.text }}</span></td>
                <!--<td class="text-xs-left">-->
                    <!--<v-btn small v-if="props.item.status.status" @click="stopRule(true, props.item)" color="error" style="color: white; width: 100%">-->
                        <!--<av-icon light left>fas fa-stop</av-icon>-->
                        <!--STOP-->
                    <!--</v-btn>-->
                    <!--<v-btn small v-else-->
                           <!--@click="startRule(true, props.item)"-->
                           <!--color="success" style="width: 100%">-->
                        <!--<av-icon light left>fas fa-play</av-icon>-->
                        <!--START-->
                    <!--</v-btn>-->
                <!--</td>-->
                <!--<td class="text-xs-left">-->
                    <!--<v-btn small @click="editRule(props.item.name)" color="info" style="width: 100%">-->
                        <!--<av-icon light left>fas fa-pen</av-icon>-->
                        <!--EDIT-->
                    <!--</v-btn>-->
                <!--</td>-->
                <!--<td class="text-xs-left">-->
                    <!--<v-btn small @click="removeFromTransactions(props.item)" color="error">-->
                        <!--<av-icon small light left>fas fa-trash</av-icon>-->
                        <!--REMOVE FROM TRANSACTIONS-->
                    <!--</v-btn>-->
                <!--</td>-->
                <td class="text-xs-right">
                    <v-menu offset-y transition="slide-y-transition" bottom >
                        <v-btn slot="activator" flat icon small>
                            <av-icon :id="'ruleMenuOpen' + props.index" small >fa-bars</av-icon>
                        </v-btn>
                        <v-list>
                            <!--<v-list-tile key="stop" v-if="props.item.status.status" @click="stopRule(true, props.item)">-->
                                <!--<v-list-tile-action>-->
                                    <!--<av-icon color="error">fas fa-stop</av-icon>-->
                                <!--</v-list-tile-action>-->
                                <!--<v-list-tile-title v-translate>STOP RULE</v-list-tile-title>-->
                            <!--</v-list-tile>-->
                            <!--<v-list-tile key="start" v-else @click="startRule(true, props.item)">-->
                                <!--<v-list-tile-action>-->
                                    <!--<av-icon color="success">fas fa-play</av-icon>-->
                                <!--</v-list-tile-action>-->
                                <!--<v-list-tile-title v-translate>START RULE</v-list-tile-title>-->
                            <!--</v-list-tile>-->
                            <v-list-tile key="pin" @click="toggleRulePinning(props.item)">
                                <v-list-tile-action>
                                    <av-icon color="info">fas fa-thumbtack</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title v-if="props.item.pinned" v-translate>SET RULE AS MOVABLE</v-list-tile-title>
                                <v-list-tile-title v-else v-translate>STICK RULE IN THIS POSITION</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile key="edit" @click="editRule(props.item.name)" :disabled="!canEdit(props.item)">
                                <v-list-tile-action>
                                    <av-icon color="info">fas fa-pen</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title v-translate>EDIT RULE</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile :disabled="!canMoveUp(props.index) || !canEdit(props.item) || !canManageRulePriority" key="up" @click="move(props.index, 'up')">
                                <v-list-tile-action>
                                    <av-icon color="info">fas fa-arrow-up</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title v-translate>MOVE ONE POSITION UP</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile :disabled="!canMoveDown(props.index) || !canEdit(props.item) || !canManageRulePriority" key="down" @click="move(props.index, 'down')">
                                <v-list-tile-action>
                                    <av-icon color="info">fas fa-arrow-down</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title v-translate>MOVE ONE POSITION DOWN</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile key="remove" @click="removeFromTransactions(props.item)">
                                <v-list-tile-action>
                                    <av-icon color="error">fas fa-trash</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title v-translate>REMOVE FROM TRANSACTIONS</v-list-tile-title>
                            </v-list-tile>
                        </v-list>
                    </v-menu>
                </td>
                </tr>
            </template>
        </v-data-table>
        <label class="title mt-4">Other rules</label>
        <v-data-table
                :headers="otherRulesHeaders"
                :items="otherRulesDataSource"
                class="elevation-2 subheading mt-2"
                hide-actions>
            <template v-slot:items="props">
                <td class="text-xs-left subheading">{{ props.item.name }}</td>
                <td class="text-xs-left subheading">{{ props.item.description }}</td>
                <td class="text-xs-left subheading"><span :style="'color:' + props.item.status.color">{{
                        props.item.status.text
                    }}</span></td>
                <!--<td class="text-xs-left">-->
                    <!--<v-btn small v-if="props.item.status.status" @click="stopRule(false, props.item)" color="error"-->
                           <!--style="color: white; width: 100%">-->
                        <!--<av-icon light left>fas fa-stop</av-icon>-->
                        <!--STOP-->
                    <!--</v-btn>-->
                    <!--<v-btn small v-else @click="startRule(false, props.item)" color="success" style="width: 100%">-->
                        <!--<av-icon light left>fas fa-play</av-icon>-->
                        <!--START-->
                    <!--</v-btn>-->
                <!--</td>-->
                <td class="text-xs-left">
                    <v-btn small @click="editRule(props.item.name)" :disabled="!canEdit(props.item)" color="info" style="width: 100%">
                        <av-icon small light left>fas fa-pen</av-icon>
                        EDIT
                    </v-btn>
                </td>
                <td class="text-xs-left">
                    <v-btn small v-if="props.item.transactional" @click="addToTransactions(props.item)" color="info">
                        <av-icon small light left>fas fa-arrow-up</av-icon>
                        ADD TO TRANSACTIONS
                    </v-btn>
                </td>
            </template>
        </v-data-table>
    </v-layout>
</template>

<script>
    import RulesEngine from '@/api/rules.js';
    import Sortable from "sortablejs";

    export default {
        name: 'RulesScheduler',
        data: () => ({
            rawSchedule: null,
            rulesSchedule: null,
            dragAndDrop: [],
            rules: [],
            runningRules: [],
            rulesDescriptors: [],
            scheduledRulesDataSource: [],
            otherRulesDataSource: [],
            scheduledRulesHeaders: [
                {text: "", class: "subheading", sortable: false},
                {text: "", class: "subheading", sortable: false},
                {text: "Rule name", class: "subheading", sortable: false},
                {
                    text: "Description",
                    class: "subheading",
                    sortable: false
                },
                {
                    text: "Status",
                    class: "subheading",
                    sortable: false
                },
                {text: "", class: "subheading", sortable: false},
                {text: "", class: "subheading", sortable: false}
            ],
            otherRulesHeaders: [
                {text: "Rule name", class: "subheading", sortable: false},
                {
                    text: "Description",
                    class: "subheading",
                    sortable: false
                },
                {
                    text: "Status",
                    class: "subheading",
                    sortable: false
                },
                {text: "", class: "subheading", sortable: false},
                {text: "", class: "subheading", sortable: false}
            ],
            editableRules:[]
        }),
        props: {},
        watch: {
            scheduledRulesDataSource: {
                handler: function () {
                    let blocked = false;
                    for(let rule of this.scheduledRulesDataSource) {
                        rule.blocked = blocked;
                        if(blocked) {
                            this.$root.showErrorNotification(this.$gettext("Some rules are unreachable since an unconditional returning rule is evaluated before them"), true, true);
                            continue;
                        }
                        if(rule.returnMode && rule.returnMode.blocking)
                            blocked = true;
                    }
                },
                deep: true,
            },
        },
        computed: {
            ruleStatus() {
                return rule=>{
                    if(this.runningRules.includes(rule.name)) {
                        //if(this.checkIsRunningRule(rule.name)) {
                        if(this.checkDescriptionVersion(rule))
                            return { text: this.$gettext("This rule is running"), color: "var(--av-lightblue)", status: 1 };
                        else return {
                            text: this.$gettext("This rule is running with a different configuration"),
                            color: "var(--av-orange)",
                            status: 2
                        };
                    } else return { text: this.$gettext("This rule is not running"), color: "", status: 0 }
                }
            },

            canEdit(){
                return rule=>{
                    console.log("rule",this.editableRules)
                    let find = this.editableRules.find(r=>r.name===rule.name)
                    return find!==undefined
                }
            },
            canManageRulePriority(){
                return this.$grants.get().dataExploration.rulesPriority
            }
        },
        methods: {
            canMoveTo(fromIndex, toIndex) {
                if(fromIndex === toIndex)
                    return false;
                if(this.scheduledRulesDataSource[fromIndex].pinned)
                    return false;
                if(toIndex >= this.scheduledRulesDataSource.length || toIndex < 0)
                    return false;
                if(this.scheduledRulesDataSource[toIndex].pinned)
                    return false;
                return true
            },
            getNextFreePositionUp(fromIndex) {
                for(let index = fromIndex ; index >= 0 ; index--) {
                    if(this.canMoveTo(fromIndex, index))
                        return index;
                }
                return -1;
            },
            getNextFreePositionDown(fromIndex) {
                for(let index = fromIndex ; index < this.scheduledRulesDataSource.length ; index++) {
                    if(this.canMoveTo(fromIndex, index))
                        return index;
                }
                return -1;
            },
            canMoveUp(fromIndex) {
                return (this.getNextFreePositionUp(fromIndex) >= 0)
            },
            canMoveDown(fromIndex) {
                return (this.getNextFreePositionDown(fromIndex) >= 0)
            },
            canMove(fromIndex) {
                return (this.canMoveUp(fromIndex) || this.canMoveDown(fromIndex));
            },
            move(fromIndex, direction) {
                if(direction === "up" && this.canMoveUp(fromIndex)) {
                    this.scheduledRulesDataSource.moveItem(fromIndex, this.getNextFreePositionUp(fromIndex));
                    this.saveTransactionsScheduling();
                } else if(direction === "down" && this.canMoveDown(fromIndex)) {
                    this.scheduledRulesDataSource.moveItem(fromIndex, this.getNextFreePositionDown(fromIndex));
                    this.saveTransactionsScheduling();
                }
            },
            saveTransactionsScheduling() {
                let rawSchedule = { system: [], user: [] };
                rawSchedule.system = this.rawSchedule.system;
                for(let rule of this.scheduledRulesDataSource)
                    rawSchedule.user.push( { Name: "runtime-task-executor_" + rule.name, Pinned: rule.pinned } );
                //this.rawSchedule = JSON.parse("{\"system\":[{\"Name\": \"runtime-task-executor-transactions_manager\"}],\"user\":[{\"Name\":\"runtime-task-executor-By location\"}]}");
                RulesEngine.setRulesScheduling(rawSchedule)
                    .catch(err => {
                        //TODO notify user
                        debugger
                        console.error(err);
                    })
            },
            editRule(rule) {
                let currentVisualization = this.rules.find(item => item.properties.name === rule);
                this.$emit('OpenElement', currentVisualization);
            },
            toggleRulePinning(rule) {
                debugger
                if(Object.isUseful(rule.pinned))
                    rule.pinned = !rule.pinned;
                else rule.pinned = true;
                this.saveTransactionsScheduling();
            },
            loadRules() {
                this.rules.clear();
                let self = this;

                this.$dynamicElements.getMetadataList("rules", true,false, false,false, true)
                    .then(result => {
                        self.editableRules=result
                    })
                    .catch(error => {
                        debugger
                    })
                this.$dynamicElements.LoadItems('rules',true, false, false, false, true)
                    .then(result => {
                        self.rules = result;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving saved rules from DB."), true);
                    })
                    .finally(() => {
                        self.$root.setLoading(false)
                    })
            },
            // stopRule(con, rule) {
            //     this.$root.setLoading(true, "Stoping rule " + rule.name);
            //     let self = this;
            //     RulesEngine.stopRule(rule.name)
            //         .catch(() => {
            //             debugger
            //             this.$root.showErrorNotification(this.$gettext("Error occurred while stopping rule. Please retry"), true);
            //         })
            //         .finally(() => {
            //             self.getRunningRules();
            //             self.$root.setLoading(false);
            //         })
            // },
            // startRule(con, rule) {
            //     this.$root.setLoading(true, 'Starting rule ' + rule.name);
            //     let self = this;
            //     this.$dynamicElements.LoadItem(rule.name, "rules")
            //         .then(t => {
            //             RulesEngine.deployRule(JSON.parse(t.descriptor), this.$root.userName)
            //                 .catch(() => {
            //                     debugger
            //                     self.$root.showErrorNotification(this.$gettext("Error occurred while starting rule. Please open it to check configuration"), true);
            //                 })
            //                 .finally(() => {
            //                     debugger
            //                     rule.status = { text: this.$gettext("This rule is running"), color: "var(--av-lightblue)", status: 1 };
            //                     self.$root.setLoading(false);
            //                     setTimeout(() => {
            //                         self.getRunningRules();
            //                     }, 4000)
            //                 })
            //         })
            //         .catch(err => {
            //             debugger
            //             self.getRunningRules();
            //             self.$root.setLoading(false);
            //             console.error(err);
            //             self.$root.showErrorNotification(this.$gettext("Error occurred while starting rule. Please open it to check configuration"), true);
            //         })
            // },
            addToTransactions(rule) {
                for(let [index, otherRule] of this.otherRulesDataSource.entries()) {
                    if(otherRule.name === rule.name) {
                        this.otherRulesDataSource.removeAt(index);
                        this.scheduledRulesDataSource.push(rule);
                        this.saveTransactionsScheduling();
                        break;
                    }
                }
            },
            removeFromTransactions(rule) {
                for(let [index, scheduledRule] of this.scheduledRulesDataSource.entries()) {
                    if(scheduledRule.name === rule.name) {
                        this.scheduledRulesDataSource.removeAt(index);
                        this.otherRulesDataSource.push(rule);
                        this.saveTransactionsScheduling();
                        break;
                    }
                }
            },
            async getRunningRules() {
                this.runningRules = await RulesEngine.getRunningRules();
                this.createDataSource();
            },
            createDataSource() {
                this.scheduledRulesDataSource = [];
                this.otherRulesDataSource = [];
                for(let tmp of this.rulesSchedule)
                    this.scheduledRulesDataSource.push( { valid: false} );
                for(let rule of this.rulesDescriptors) {
                    let newRule = {
                        name: rule.name,
                        description: rule.description,
                        status: this.ruleStatus(rule),
                        transactional: rule.type === 2100,
                        returnMode: this.returnLegend(rule)
                    };
                    let scheduled = false;
                    for(let i = 0 ; i < this.rulesSchedule.length ; i++)
                        if(this.rulesSchedule[i].Name  === rule.name) {
                            this.scheduledRulesDataSource[i] = newRule;
                            this.scheduledRulesDataSource[i].pinned = this.rulesSchedule[i].Pinned || false;
                            scheduled = true;
                            break;
                        }
                    if (!scheduled) {
                        this.otherRulesDataSource.push(newRule);
                    }
                }
                for(let i = 0 ; i < this.scheduledRulesDataSource.length ; i++) {
                    if(Object.isUseful(this.scheduledRulesDataSource[i].valid) && !this.scheduledRulesDataSource[i].valid) {
                        this.scheduledRulesDataSource.removeAt(i);
                        i--;
                    }
                }
                this.scheduledRulesDataSource = this.makeArrayItemsObserver(this.scheduledRulesDataSource)
            },
            makeArrayItemsObserver(array) {
                let obsArray = []
                array.forEach(item => {
                    obsArray.push(item)
                })
                return obsArray
            },
            checkIsRunningRule(name) {
                return  this.runningRules.includes(name);
            },
            //checks the matching between the version of the deployed rule and its descriptor
            checkDescriptionVersion(descriptor) {
                return descriptor.descriptorVersion === (Object.isUseful(descriptor.deployVersion) ? descriptor.deployVersion : -1);
            },
            returnLegend(item) {
                if(item.additionalMetaData && Object.isUseful(item.additionalMetaData.returnMode)) {
                    if(item.additionalMetaData.returnMode === 1 || item.additionalMetaData.returnMode === 2)
                        return { icon: "fas fa-times-circle", color: "error", tooltip: this.$gettext("This rule will always break transaction"), blocking: true };
                    else if(item.additionalMetaData.returnMode)
                        return { icon: "fas fa-sign-out-alt", color: "warning", tooltip: this.$gettext("This rule may break transaction if specific conditions are matched"), blocking: false }
                }
                return null;
            },
            initSortable() {
                let self = this;
                let table = document.querySelector(".v-datatable tbody");
                this.dragAndDrop = this.$utils.detach(this.scheduledRulesDataSource);
                Sortable.create(table, {
                    handle: ".handle", // Use handle so user can select text
                    onMove: function (evt) {
                        return evt.related.className.indexOf('disabled') === -1;
                    },
                    onEnd(evt) {
                        if(self.canMoveTo(evt.oldIndex, evt.newIndex)) {
                            self.scheduledRulesDataSource.moveItem(evt.oldIndex, evt.newIndex);
                            self.initSortable();
                            self.saveTransactionsScheduling();
                        }
                    }});
            }
        },
        async mounted() {
            this.$root.setLoading(true, "");
            let self = this;
            this.loadRules();
            this.$dynamicElements.getMetadataList("rules",true,false, false, false, true)
                .then(rulesDescriptors => {
                    self.rulesDescriptors = rulesDescriptors;
                    RulesEngine.getRulesScheduling()
                        .then(rulesScheduling => {
                            self.rawSchedule = rulesScheduling;
                            self.rulesSchedule = rulesScheduling.user;
                            for (let i = 0; i < self.rulesSchedule.length; i++)
                                self.rulesSchedule[i].Name = self.rulesSchedule[i].Name.replace("runtime-task-executor_", "");
                            self.getRunningRules();
                            self.initSortable();
                        })
                        .catch(error => {
                            debugger
                        })
                })
                .finally(() => {
                    self.$root.setLoading(false);
                });
        }
    }
</script>

<style scoped>
</style>
