<template>
    <div>
        <canvas :id=id></canvas>
        <v-btn v-if="zoomOrPanned" @click="resetVisualization(), zoomOrPanned = false" fab style="position: absolute; top: 100px; left: 40px;"><av-icon color="blue">fas fa-undo</av-icon></v-btn>
    </div>
</template>
<script>
import {Chart, registerables} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-plugin-labels";


export default {
    name: "BarChart",
    props: {
        chartdata: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            id: Date.now(),
            chart: undefined,
            chartData: {
                type: "bar",
                data: this.chartdata,
                options: this.options,
                plugins: this.plugins
            },
            zoomOrPanned: false
        }
    },
    created() {
        Chart.register(...registerables);
        Chart.register(ChartDataLabels);
        // Chart.register(ChartPluginLabels);
        Chart.register(zoomPlugin);
        Chart.register({
            id: 'zoomCSS',
            beforeEvent(chart, ctx) {
                // let bounds = ctx.event.native.target.getBoundingClientRect();
                // let correctionX = bounds.left - bounds.left * 0.8;
                // let correctionY = bounds.top - bounds.top * 0.8;
                // ctx.event.native.x = (ctx.event.native.x + correctionX) * 1.25;
                // ctx.event.native.y = (ctx.event.native.y + correctionY) * 1.25;
                ctx.event.x = ctx.event.x * 1.25
                ctx.event.y = ctx.event.y * 1.25
            }
        });

    },
    methods: {
        refreshGraph() {
            this.chartData.data = this.chartdata
            this.chartData.options = this.options
            this.chartData.plugins = this.plugins
            this.chart.update('none')
        },
        resetVisualization() {
            this.chart.resetZoom();
        },
        createChart() {
            let self=this;
            this.chartData.options.plugins.zoom = {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    onZoomComplete: function () {self.zoomOrPanned = true},
                    pinch: {
                        enabled: true
                    },
                },
                pan: {
                    enabled: true,
                    onPanComplete: function () {self.zoomOrPanned = true},
                    mode: 'xy',
                },

            }
            this.chart = new Chart(document.getElementById(this.id), this.chartData)
        },
        onMount(){
            this.createChart()
            if (this.chart)
                this.chart.render()
        }
    },

    mounted() {
        setTimeout(()=>{
            this.onMount()
        },10)
    },
    beforeDestroy() {
        if (this.chart)
            this.chart.destroy()
    },

    watch: {
        data: {
            handler(n,o){
                this.chartData.data = n
                this.chart.render()
            }
        },
        options: {
            handler(n,o) {
                this.chartData.options = n
                this.chart.render()
            }
        }
    }
}
</script>
