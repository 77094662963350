<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer"  class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
    <v-card class="av-card" elevation="0">
            <v-card-text >
                <div class="table-container">
                    <table class="v-datatable v-table theme--light content" style="width: 100%;">
                        <thead>
                        <tr>
                            <th class="column text-md-left text-sm-left" @click="sort('Name')" style="font-size: 1.5em;"
                                :id="computedSortByNameId + 'SortByName'">Entity name
                                <av-icon small>{{ nameSort ? "fa-sort-down" : "fa-sort-up" }}</av-icon>
                            </th>
                            <th class="column text-md-left text-sm-left" style="font-size: 1.5em;">Identification keys</th>
                            <th class="column text-md-center text-sm-center" @click="sort('Status')" style="font-size: 1.5em;"
                                :id="computedSortByStatusId + 'SortByStatus'">Status
                                <av-icon small>{{ statusSort ? "fa-sort-down" : "fa-sort-up" }}</av-icon>
                            </th>
                            <th class="column text-md-left text-sm-left text-xs-left" style="font-size: 1.5em;">Actions</th>
                        </tr>
                        </thead>
                        <tbody id="entitiesTable">
                        <tr v-for="(entity,idx) in sorted" :key="idx" style="height: 40px">
                            <td class="text-start subheading" :style="styleCell">
                                {{ entity.Name }}
                            </td>
                            <td class="text-start subheading" :style="styleCell">
                                {{ formatKeys(entity) }}
                            </td>
                            <!--<td class="text-md-center text-sm-center text-xs-center" v-if="!entity.IsEntityEnabled || !entity.IsInstanceEnabled" :style="styleCell"-->
                            <!--style="color: red; font-weight: bold">-->
                            <!--{{ !entity.IsEntityEnabled ? 'ENTITY IS DISABLED' : 'INSTANCE IS DISABLED' }}-->
                            <!--</td>-->
                            <!--<td class="text-md-center text-sm-center text-xs-center" v-if="!entity.IsInstanceActive" :style="styleCell"-->
                            <!--style="color: red; font-weight: bold">-->
                            <!--INSTANCE IS DISABLED-->
                            <!--</td>-->
                            <td class="text-md-center text-sm-center  text-xs-center subheading" v-if="!entity.IsEntityActive" :style="styleCell"
                                style="color: gray; font-weight: bold">
                                ENTITY IS DRAFT
                            </td>
                            <td class="text-md-center text-sm-center text-xs-center subheading" v-else :style="styleCell"
                                style="color: green; font-weight: bold" >
                                ACTIVE
                            </td>
                            <td class="text-md-left text-xs-left" :style="styleCell">
                                <av-button :text="''" flat icon @click="entityDetails(entity)" size="medium" title="View details"
                                           buttonIcon="fa-list" iconColor="blue"/>
                                <av-button  :text="$gettext('')" flat icon @click="editEntity(entity)" size="medium"
                                           title="Edit entity" buttonIcon="fa-pen" iconColor="blue" :disabled="!canEdit(entity)"/>
                                <av-button :text="$gettext('')" flat icon @click="deleteEntity(idx, entity.EntityKeys, entity.id)" size="medium"
                                           title="Delete entity" buttonIcon="fa-trash" iconColor="red" :disabled="!canEdit(entity)"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import Entity from "@/api/entities";
    import EntityDetails from "@/views/forms-and-entities/EntityDetails";
    import FormBase from "@/components/dynamic-elements/forms/FormBase";
    import Transaction from "@/api/transactions";

    export default {
        name: "entities",
        components: {FormBase, EntityDetails},
        data() {
            return {
                listOfEntities: [],
                headers: [],
                currentSort: 'name',
                currentSortDir: 'asc',
                nameSort: true,
                statusSort: true
            }
        },
        computed: {

            computedSortByNameId() {
                return this.toCamelCase(this.$router.history.current.name + 'Table')
            },
            computedSortByStatusId() {
                return this.toCamelCase(this.$router.history.current.name + 'Table')
            },
            sorted: function () {
                return this.listOfEntities.sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc')
                        modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return modifier;

                    // if ( a["IsEntityEnabled"] < b["IsEntityEnabled"]) return -1 * modifier;
                    // if ( a["IsEntityEnabled"] > b["IsEntityEnabled"]) return modifier;
                    //
                    // if ( a["IsEntityEnabled"] < b["IsEntityEnabled"]) return -1 * modifier;
                    // if ( a["IsEntityEnabled"] > b["IsEntityEnabled"]) return modifier;

                    if ( a["IsEntityActive"] < b["IsEntityActive"]) return -1 * modifier;
                    if ( a["IsEntityActive"] > b["IsEntityActive"]) return modifier;

                    // if ( a["IsInstanceActive"] < b["IsInstanceActive"]) return -1 * modifier;
                    // if ( a["IsInstanceActive"] > b["IsInstanceActive"]) return modifier;

                    return 0;
                });
            },

            canEdit() {
                return async entity=>{
                    let result = await this.$dynamicElements.getMetadataList("forms", true, false,false, true)
                    return result.find(r => r.name === entity.Name) !== undefined
                }
            }

        },
        async mounted() {
            await this.waitForPageLoading();
            this.getAllEntityPrototypes();
            this.$root.setCurrentPageTitle("Entities instances");
            window.addEventListener("resize", this.handleResize)
        },
        methods: {
            sort: function (s) {
                if (s === "Name")
                    this.nameSort = !this.nameSort;
                else if (s === "Status")
                    this.statusSort = !this.statusSort;

                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                }
                this.currentSort = s;
            },
            styleCell(index) {
                if (index === 0) {
                    return "min-width: 200px";
                } else return {
                    border: this.type === "custom" ? "1px solid #eee" : "",
                }
            },
            getAllEntityPrototypes() {
                this.listOfEntities.clear();
                let self = this;
                Entity.getAllEntities().then(t => {
                    if(t)
                        t.map(item => self.listOfEntities.push(item))
                })
            },
            entityDetails(entity) {
                this.$router.push({name: 'entityDetails', params: {entity: entity}})
            },
            deleteEntity(index, keys, id) {
                let self = this;
                this.$root.showDialogBox(this.$gettext("Are you sure you want to delete entity: {0}?").format(this.listOfEntities[index].Name), null, "Yes", function () {
                    Entity.deleteInstance(id)
                    .then(() => {
                        setTimeout(function() {
                            self.getAllEntityPrototypes();
                            Transaction.updateOrphanTransactionsStatusAfterEntityInstanceDelete(keys);
                        }, 1000);
                    })
                    .catch(err => {
                        self.$root.showErrorNotification(err, true, false);
                    })
                }, "No", null);
            },
            editEntity(entity) {
                this.$router.push({name: 'formsEdit', params: {routeId: entity.Name, entity: entity}})
            },
            formatKeys(entity) {
                let keyValues = "";
                for(let key in entity.EntityKeys) {
                    if(key !== "EntityName") {
                        keyValues += "{0}: {1}; ".format(key, entity.EntityKeys[key]);
                    }
                }
                return keyValues;
            },
        }
    }
</script>
