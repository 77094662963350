import OrchestratorAPI from '@/api/orchestrator'
import VueInstance from '@/api/vueinstance'
import Vue from 'vue'

let singletonRequestId = 0;

export default {
    async getWebsocketUrl(){
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', '/api/v1/websocket')
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    if (JSON.parse(t).Code === 1038)
                        resolve([]);
                    else {
                        console.log(t);
                        reject(VueInstance.get().$gettext("Error in retrieving websocket url"));
                    }
                });
        });
    },
    handleExportData(data) {
        if(!data || !data.data) {
            console.log("Empty event received");
            return
        }
        let request = data.data.startsWith("{")?JSON.parse(data.data):JSON.parse(atob(data.data));
        request.error = false;
        request.errorMessage = "";
        request.Id = request.Message.Id

        if(request.Scope && request.Scope === "Token"){
            Vue.prototype.$store.commit("setToken",request.Message.Token);
            return
        }
        if(!request.Scope || request.Scope !== "ExportData") {
            request.error = true;
            request.errorMessage = "Unknown request type";
            Vue.prototype.$socket.send(JSON.stringify(request));
            return;
        }
        if(!request.Message.Id || !request.Message.ItemName || !request.Message.Token || !request.Message.Format ) {
            request.error = true;
            request.errorMessage = "Missing parameter in request";
            Vue.prototype.$socket.send(JSON.stringify(request));
            return;
        }
        Vue.prototype.$store.commit("setToken",request.Message.Token);
        console.log("Export request received");

        if (request.Message.ItemType.startsWith("generic.") || request.Message.ItemType.startsWith("activity.")) {
            //SingletonRequestId used to lock chrome instance in case if there are multiple exportation in same time since exporting of
            //generic data requires redirection on specific page
            if (singletonRequestId === 0)
                //Used to export data from generic table by redirection go generic table page
                Vue.prototype.$reportingService.exportGenericDataSets(request);
            else
                Vue.prototype.$reportingService.exportErrorHandler();

        } else {
            Vue.prototype.$dynamicElements.bulkDataExport(request.Message.ItemName, request.Message.ItemType, request.Message.Format,
                request.Message.From, request.Message.To, Vue.prototype.$reportingService.exportDataHandler,
                Vue.prototype.$reportingService.exportErrorHandler, request.Message.Id, request.Message.Filters, request.Message.Variables);
        }
    },

    exportDataHandler(data, requestId) {
        console.log("Sending report response");
        data.error = false;
        data.errorMessage = "";
        data.Id = requestId;
        Vue.prototype.$socket.send(JSON.stringify(data));
        if (singletonRequestId === requestId) {
            VueInstance.get().$router.push({ name: 'home'});
            singletonRequestId = 0;
        }
    },
    exportErrorHandler(err, requestId) {
        console.log("Sending error response");
        console.log(err);
        Vue.prototype.$socket.send(JSON.stringify({ error: true, errorMessage: err, Id: requestId }));
    },
    exportTemplate(headers, data) {
        let result = [];
        for (let i = 0; i <= data.length - 1; i++) {
            result[i] = [];
            for (let j = 0; j <= headers.length -1; j++) {
                if(data[i].hasOwnProperty(headers[j])) {
                    result[i][j] = data[i][headers[j]];
                } else {
                    result[i][j] = "";
                }
            }
        }
        return result;
    },
    exportGenericDataSets(request) {
        console.log('Export generic datasets');
        singletonRequestId = request.Message.Id;
        VueInstance.get().$router.push({
            name: request.Message.ItemType.split('.')[1], params: {
                requiredExport: true,
                format: request.Message.Format,
                itemName: request.Message.ItemName,
                messageId: request.Message.Id,
                exportDataHandler: Vue.prototype.$reportingService.exportDataHandler,
                exportErrorHandler: Vue.prototype.$reportingService.exportErrorHandler
            }
        });
    }
}