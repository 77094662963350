<template>
    <v-container grid-list-md text-xs-center class="page-container" pa-3 ma-0 style="max-width: 100%;">
        <template v-if="isDefaultState">
            <v-card class="mt-0 av-card" elevation="0" style="height: 80px;">
                <v-layout row justify-end align-center class="sectionTitle mt-2">
                    <v-btn color="info" @click="setNewState">
                        <av-icon light left>fas fa-plus fa-5x</av-icon>
                        {{ newButton }}
                    </v-btn>
                </v-layout>
            </v-card>
            <v-card class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)">
                <v-card-text class="av-card-content">
                    <DashboardNavigator v-on:OpenView="openView" v-on:CloneView="cloneView"/>
                </v-card-text>
            </v-card>
        </template>
    </v-container>
</template>

<script>
    let ComponentStates = Object.freeze({
        "Blank": 0,
        "NewDashboard": 1,
        "OpenDashboard": 2,
        "CloningDashboard": 3,
    });
    import DashboardNavigator from '@/views/dashboards/DashboardNavigator'

    export default {
        name: "DashboardComposer",
        components:{
            DashboardNavigator
        },
        data:()=> {
            return {
                icon:"fas fa-tachometer-alt fa-10x",
                composerHeight:0,
                componentState: ComponentStates.OpenDashboard,
            }
        },
        computed:{
            newButton() {
                return this.$gettext("New Dashboard");
            },
            cloneButton() {
                return this.$gettext("Clone Dashboard");
            },
            isNewState() {
                return this.componentState === ComponentStates.NewDashboard;
            },
            isDefaultState() {
                return this.componentState === ComponentStates.OpenDashboard;
            },
            isCloneState() {
                return this.componentState === ComponentStates.CloningDashboard;
            },
        },
        mounted(){
            this.$root.setCurrentPageTitle("Edit dashboards");
            this.$root.setLoading(true, "");
            if(this.$store.state.isEmbedded){
                this.waitMount()
            }
            this.$root.setLoading(false, "");
        },
        methods:{
            async waitMount(){
                await this.waitForPageLoading();
            },
            setNewState(){
                this.$router.push("/dashboards/new/create")
            },
            setDefaultState() {
                this.statusSwitch(ComponentStates.OpenDashboard);
            },

            setCloneState() {
                this.statusSwitch(ComponentStates.Cloning);
            },
            statusSwitch(newStatus) {
                this.componentState = newStatus;
            },
            openView(dashboard) {
                this.$router.push("/dashboards/"+ dashboard.name + "/edit")
            },
            cloneView(dashboard) {
                this.$router.push("/dashboards/"+ dashboard.name  + "/clone")
            },


        }
    }
</script>

<style scoped>

    .overlayedIconsContainer {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        text-align:center;
    }

    .overlayedIconsCanvas {
        position: absolute;
        top: 62px;
        left: 130px;
    }

    .overlayedIconsOverlay {
        position: absolute;
        top: 70px;
        left: 137px;
    }
</style>
