<template>
    <GenericTable>
    </GenericTable>
</template>

<script>
import GenericTable from '@/components/utilities/GenericTable';
import DateTime from '@/api/datetimeutils';

export default {
    name: "Audits",
    components: {GenericTable},
    extends: GenericTable,
    data: () => ({

    }),
    methods: {
        async loadAuditsList() {
            this.waitItems = new Promise((resolve, reject) => {
                let self = this;
                self.$audits.getAuditsList(DateTime.getRfc3339TimeStamp(self.timeWindow.getStart()),DateTime.getRfc3339TimeStamp(self.timeWindow.getEnd()))
                    .then(result => {
                        self.listOfItems = result;
                        self.$audits.formatAudits(self.listOfItems);
                        resolve(true);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    });
            });
        },
    },
    mounted() {
        this.getListOfItems = this.loadAuditsList;
        this.scopeIndex = 'audit_trails';
        this.scopeType = 'auditsVisualizationOptions';
        this.auditType = 'Audits';
        this.alwaysTimeFilter = true;
        this.timeWindow.setPredefined(15);
        this.defaultVisualizationProfile = [{name: '@timestamp', text: this.$gettext('Date')}, {name: 'Operator', text: this.$gettext('Operator')}, {name: 'Action', text: this.$gettext('Event type')},
            {name: 'ActionId', text: this.$gettext('Subject')}, {name: 'PreviousVal', text: this.$gettext('Value before event')}, {name: 'NextVal', text: this.$gettext('Value after event')}];
        this.loadData();
    }
}
</script>

<style scoped>

</style>