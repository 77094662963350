<template>
    <v-dialog v-model="showDialog" width="960px" height="640px" min-height="640px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-workorder-dialog">
        <TimeSpanPicker v-if="customTimePicker.show" :showDialog="customTimePicker.show" :timespan="customTimePicker.span" @exit="customTimePickerResult($event)"/>
        <v-card id="antares-workorder-dialog-card">
            <v-container fill-height pa-3>
                <v-layout column>
                    <v-toolbar-title class="headline text-xs-center">
                        <v-layout fill-height align-center row px-0 py-1 wrap>
                            <label v-if="notified"><translate>Workorder counters adjustment</translate></label>
                            <label v-else><translate>Good/Rejected product adjustment</translate></label>
                            <template v-if="!notified">
                                <v-spacer></v-spacer>
                                <template>
                                    <v-tooltip bottom>
                                        <v-btn fab small slot="activator" @click="zoomIn">
                                            <av-icon>fas fa-search-plus</av-icon>
                                        </v-btn>
                                        <span>Zoom In</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <v-btn small slot="activator" style="cursor:default;" @click="openTimeWindowPicker">
                                            {{displayedTimeWindow}}
                                        </v-btn>
                                        <span v-translate>Current time window</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <v-btn fab small slot="activator" @click="zoomOut">
                                            <av-icon>fas fa-search-minus</av-icon>
                                        </v-btn>
                                        <span v-translate>Zoom Out</span>
                                    </v-tooltip>
                                </template>
                            </template>
                        </v-layout>
                     </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <label class="headline">{{woLabel}}</label>
                    <v-layout row v-if="!notified">
                        <v-flex xs7 pt-2><label v-if="workorders.length" class="subheading"><translate>Select a workorder from the list to adjust quantities</translate></label></v-flex>
                        <v-flex xs5><v-select solo class="title" :items="workorders" item-text="show" item-value="id" v-model="workorder" @change="loadWorkorder"/></v-flex>
                    </v-layout>
                    <template v-if="workorder">
                        <label class="subheading" style="padding-top: 20px"><translate>Workorder:</translate> {{workorder}}</label>
                        <label class="subheading"><translate>Quantity to produce</translate>: {{quantityToProduce}}</label>
                        <label class="subheading">{{$gettext("Quantity produced (According to {appName}): {quantity}").format({appName: $config.appName, quantity: quantityProduced})}}</label>
                        <label class="subheading">{{$gettext("Quantity rejected (According to {appName}): {quantity}").format({appName: $config.appName, quantity: quantityRejected})}}</label>
                        <v-layout column pt-5 >
                            <v-switch v-if="!notified" ma-0 class="subheading" :label="manualCountersSwitchLabel" v-model="manualCounters" @change="toggleManualCounters" />
                            <template v-if="manualCounters">
                                <v-layout row pl-5 pr-5>
                                    <v-flex xs8 pt-2><label class="subheading">Enter new quantity produced</label></v-flex>
                                    <v-flex xs4><v-text-field class="osk" width="300" solo v-model="newQuantityProduced" type="number" min="0" @input="newQuantityProduced < 0 ? (newQuantityProduced = quantityProduced) : null"/></v-flex>
                                </v-layout>
                                <v-layout row pl-5 pr-5>
                                    <v-flex xs8 pt-2><label class="subheading">Enter new quantity rejected</label></v-flex>
                                    <v-flex xs4><v-text-field class="osk" solo v-model="newQuantityRejected" type="number" min="0" @input="newQuantityRejected < 0 ? (newQuantityRejected = quantityRejected) : null"/></v-flex>
                                </v-layout>
                                <label v-if="showYeld" style="padding-left: 50px" class="subheading">{{yeldString}}</label>
                            </template>
                        </v-layout>
                        <v-layout column pt-2>
                            <v-switch v-if="!notified" ma-0 class="subheading" :label="autoCountersSwitchLabel" v-model="autoCounters" @change="toggleAutoCounters" />
                            <v-layout row v-if="autoCounters"  pl-5 pr-5>
                                <v-flex xs8 pt-2><label class="subheading"><translate>Enter number of items to reject</translate></label></v-flex>
                                <v-flex xs4><v-text-field class="osk" solo v-model="newQuantityRejected" type="number" min="0" @input="newQuantityRejected < 0 ? newQuantityRejected = 0 : null"/></v-flex>
                            </v-layout>
                        </v-layout>
                    </template>
                    <v-spacer></v-spacer>
                    <label v-if="error" style="color: var(--av-red); font-size: 1.4em">{{error}}</label>
                    <v-card-actions id="antares-annotation-dialog-card-actions">
                        <v-spacer></v-spacer>
                        <v-btn v-if="dataValid" id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="callBack(true)">
                            <translate>
                                OK
                            </translate>
                        </v-btn>
                        <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat" @click="callBack(false)">
                            <translate>
                                Cancel
                            </translate>
                        </v-btn>
                    </v-card-actions>
                </v-layout>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>

    import DataApis from '@/api/data'
    import DateTimeUtils from '@/api/datetimeutils'
    import {TimeSpan} from '@/api/datetimeutils'
    import TimeSpanPicker from "@/components/utilities/TimeSpanPickerDialog"
    import BackAnnotation from '@/api/backannotation'

    export default {
        name: 'BackAnnotationDialog',
        components: {TimeSpanPicker},
        props: {},
        data() {
            return {
                dataValid: false,
                workorders: [],
                showDialog: false,
                workorder: "",
                fullWorkorderDocument: null,
                quantityToProduce: 0,
                totalQuantityProduced: 0,
                quantityProduced: 0,
                newQuantityProduced: 0,
                quantityRejected: 0,
                newQuantityRejected: 0,
                user: "",
                error: "",
                notified: false,
                callerInstance: null,
                lastSampleProduced: null,
                manualTotalsLog: null,
                manualRejectsLog: null,
                device: "",
                manualCounters: false,
                autoCounters: true,
                timeWindow: new TimeSpan(), //Time window options
                customTimePicker:   //Object used to manage the advanced time span picker dialog
                    {
                        show: false,
                        span: new TimeSpan(),
                    },
                root: null
            }
        },
        mounted: function() {

        },
        // watch: {
        //     manualCounters() {
        //         this.autoCounters = !this.manualCounters;
        //     }
        // },
        computed: {
            displayedTimeWindow() { return this.timeWindow.toString() },
            showYeld() {
                if(this.fullWorkorderDocument && (this.fullWorkorderDocument.StatusString === "completed" || this.notified))
                    return true;
                return false;
            },
            yeldString() {
                return this.$gettext("Automatically calculated Yeld: {0} items; {1}% of expected workorder size").format(this.yeld, this.$utils.roundToDigits((this.yeld / this.quantityToProduce) * 100, 2));
            },
            yeld() {return (parseInt(this.newQuantityProduced) + parseInt(this.newQuantityRejected))},
            manualCountersSwitchLabel() { return this.$gettext("Change both good and reject counters manually. Requires counting items")},
            autoCountersSwitchLabel() { return this.$gettext("Add rejected items. Use option to record rejects on a machine not monitored by {appName}").format({appName: this.$config.appName})},
            woLabel() {
                if(this.notified)
                    return this.$gettext("Workorder: {0} completed. Please review and adjust production counters").format(this.workorder);
                else
                    return this.$gettext("{0} workorders found in selected period").format(this.workorders.length);
            }
        },
        methods: {
            setVueInstance(instance) {
                this.root = instance;
            },
            setCaller(caller) {
                this.callerInstance = caller;
            },
            Show(event, callBack, user) {
                if(event && event.notified) {
                    this.notified = true;
                    this.manualCounters = true;
                    this.autoCounters = false;
                }
                this.loadWorkordersList();
                this.user = user;
                this.showDialog = true;
            },
            toggleAutoCounters() {
                this.manualCounters = !this.autoCounters;
                if(this.manualCounters)
                    this.newQuantityRejected = this.quantityRejected;
                else
                    this.newQuantityRejected = 0;
            },
            toggleManualCounters() {
                this.autoCounters = !this.manualCounters;
                if(this.manualCounters)
                    this.newQuantityRejected = this.quantityRejected;
                else
                    this.newQuantityRejected = 0;
            },
            callBack(result) {
                this.error = "";
                if (result) {
                    if ((this.manualCounters && this.newQuantityRejected === this.quantityRejected && this.newQuantityProduced === this.quantityProduced) || (this.autoCounters && this.newQuantityRejected === 0)) {
                        this.error = this.$gettext("No changes to data.");
                        return;
                    }
                    this.root.showDialogBox(this.$gettext("Are you sure you want to adjust the quantities?"), null, "Yes", this.confirmChange, "No", null);

                } else {
                    this.showDialog = false;
                }
            },
            confirmChange() {

                let self = this;

                let backannotation = {};

                //audits parameters
                let action = null;
                let previousVal = null;
                let nextVal = null;
                //Save initial values for auditing
                let initialTotals = this.quantityProduced;
                let initialRejects = this.quantityRejected;
                let manualRejections = this.newQuantityRejected;

                //If we are only adding rejections, precalculate the final rejections total
                // so that the rest of calculation will stay uniform
                if(!this.manualCounters) {
                    this.newQuantityRejected = this.quantityRejected + parseInt(this.newQuantityRejected);
                }

                //We select a tight range around last document produced with selected workorder,
                //this will result in a single possible document on production counters index
                let annotationStart = new Date(this.lastSampleProduced - 500);
                let annotationEnd = new Date(this.lastSampleProduced + 500);

                //If we are RE-annotating an already closed workorder or we have multiple very time-tight annotations on a live one
                //will happen that backannotator will select the same document for annotation multiple times. In this case counters will got broken
                //since we will modify the manual counters with a value that is the delta with respect to previous sum of automatic and manual counters
                //This will result in an overall mess with counters going wild. For this reason we check whether there is already an annotation
                //and calculate new manual annotation value taking care of previous one that we are going to override.
                if(Array.isUseful(this.manualTotalsLog)) {
                    for(let manualAnnotation of this.manualTotalsLog) {
                        if(manualAnnotation.x >= annotationStart && manualAnnotation.x < annotationEnd) {
                            this.totalQuantityProduced = this.totalQuantityProduced - manualAnnotation.y;
                        }
                    }
                }
                if(Array.isUseful(this.manualRejectsLog)) {
                    for(let manualAnnotation of this.manualRejectsLog) {
                        if(manualAnnotation.x >= annotationStart && manualAnnotation.x < annotationEnd) {
                            this.quantityRejected = this.quantityRejected - manualAnnotation.y;
                        }
                    }
                }

                backannotation["from"] = DateTimeUtils.getRfc3339TimeStamp(annotationStart);
                backannotation["to"] = DateTimeUtils.getRfc3339TimeStamp(annotationEnd);
                backannotation["rejects"] = parseInt(this.newQuantityRejected) - this.quantityRejected;
                if(this.manualCounters) {
                    backannotation["totals"] = parseInt(this.newQuantityProduced) + parseInt(this.newQuantityRejected) - this.totalQuantityProduced;
                    action = this.$audits.items().quantities;
                    previousVal = [initialTotals, initialRejects];
                    nextVal = [parseInt(this.newQuantityProduced) , parseInt(this.newQuantityRejected)];
                } else {
                    action = this.$audits.items().rejects;
                    nextVal = JSON.stringify([parseInt(manualRejections)]);
                }
                BackAnnotation.annotateQuantities(backannotation.from, backannotation.to, backannotation.totals, backannotation.rejects, this.user)
                    .then(() => {
                        self.showDialog = false;
                        self.$audits.save(self.user, action, previousVal, nextVal, self.workorder);
                    })
                    .catch(err => {
                        debugger;
                        self.showDialog = false;
                        console.error(err);
                        self.error = self.$gettext("An error occurred while changing quantities, please try again");
                    })
            },
            loadWorkordersList() {

                this.workorders.clear();
                let self = this;

                this.$workorders.loadWorkordersList(this.timeWindow.getStart(true), this.timeWindow.getEnd())
                    .then(workorders => {
                        self.workorders = workorders;
                        if(self.workorders.length === 1) {
                            self.workorder = self.workorders[0];
                            self.loadWorkorder();
                        }
                    })
                    .catch(() => {
                        self.workorders = [];
                    });
            },
            loadWorkorder() {

                if(!this.workorder)
                    return;

                let self = this;

                this.$workorders.get(this.workorder)
                    .then(result => {
                        self.fullWorkorderDocument = result;
                        self.quantityToProduce = result.QuantityToProduce;
                    })
                    .catch(err => {
                        console.log(err);
                        self.error = self.$gettext("Unable to retrieve selected workorder information");
                    });

                this.device = this.$settings.getLineSettings().masterMachine;
                if(!this.device) {
                    self.error = this.$gettext("Please configure reference machine in line settings");
                    return
                }

                this.loadWorkorderCounters();
            },
            async loadWorkorderCounters() {

                let self = this;

                this.quantityProduced = 0;
                this.quantityRejected = 0;
                this.lastSampleProduced = 0;
                this.totalQuantityProduced = 0;
                this.manualTotalsLog = [];
                this.manualRejectsLog = [];

                let filterItems = [{
                        index: "",
                        root: "Line",
                        name: 'WorkorderID',
                        type: 'keyword',
                        selectedForFiltering: true,
                        filters: [{
                            conditions: [{operator: '=', value: this.workorder}],
                            defaultName: "Select current workorder",
                            name: "Select current workorder",
                            enabled: true,
                            filterId: "SelectCurrentWorkorder" //Create a unique id that survives changes
                        }],
                    },
                    {
                        index: "",
                        root: "ManualCounters",
                        name: 'TotalItems_Delta',
                        type: 'integer',
                        selectedForFiltering: true,
                        filters: [{
                            conditions: [{operator: '!=', value: 0}],
                            filterId: "ManualTotalsNotZero" //Create a unique id that survives changes
                        }],
                    },
                    {
                        index: "",
                        root: "ManualCounters",
                        name: 'RejectedItems_Delta',
                        type: 'integer',
                        selectedForFiltering: true,
                        filters: [{
                            conditions: [{operator: '!=', value: 0}],
                            filterId: "ManualRejectsNotZero" //Create a unique id that survives changes
                        }],
                    }];

                let dataItems = [{
                    index: "production counters@5s",
                    root: this.device,
                    name: 'TotalItems_Delta',
                    checkForStandardVariable: true,
                    type: 'integer',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.sum.id, aggregationWindow: 0, target: 0, filters: ["SelectCurrentWorkorder"]}]
                },
                {
                    index: "production counters@5s",
                    root: "ManualCounters",
                    name: 'TotalItems_Delta',
                    type: 'integer',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.sum.id, aggregationWindow: 0, target: 1, filters: ["SelectCurrentWorkorder"]}]
                },
                {
                    index: "production counters@5s",
                    root: "ManualCounters",
                    name: 'TotalItems_Delta',
                    type: 'integer',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.raw.id, aggregationWindow: 0, target: 5, filters: ["SelectCurrentWorkorder", "ManualTotalsNotZero"]}]
                },
                {
                    index: "production counters@5s",
                    root: this.device,
                    name: 'RejectedItems_Delta',
                    checkForStandardVariable: true,
                    type: 'integer',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.sum.id, aggregationWindow: 0, target: 2, filters: ["SelectCurrentWorkorder"]}]
                },
                {
                    index: "production counters@5s",
                    root: "ManualCounters",
                    name: 'RejectedItems_Delta',
                    type: 'integer',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.sum.id, aggregationWindow: 0, target: 3, filters: ["SelectCurrentWorkorder"]}]
                },
                {
                    index: "production counters@5s",
                    root: "ManualCounters",
                    name: 'RejectedItems_Delta',
                    type: 'integer',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.raw.id, aggregationWindow: 0, target: 6, filters: ["SelectCurrentWorkorder","ManualRejectsNotZero"]}]
                },
                {
                    index: "production counters@5s",
                    root: this.device,
                    name: 'TotalItems_Delta',
                    checkForStandardVariable: true,
                    type: 'integer',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.last.id, aggregationWindow: 0, target: 4, filters: ["SelectCurrentWorkorder"]}]
                }];

                for (const dataItem of dataItems) {
                    if (dataItem.checkForStandardVariable) {
                        let result = await self.$aliases.getStandardVariableName(this.device, dataItem.name.split('_')[0])
                        if (result)
                            dataItem.name = result + '_Delta';

                    }
                }

                let queryDescriptor = DataApis.getDataQueryDescriptor(dataItems, filterItems);

                //Execute query
                if (queryDescriptor.agg.length > 0 || queryDescriptor.raw.length > 0 || queryDescriptor.comp.length > 0) {
                    DataApis.getDataBlob(queryDescriptor, DateTimeUtils.getRfc3339TimeFromNow(9999999), DateTimeUtils.getRfc3339TimeStamp(new Date()))
                        .then(result => {
                            let data = DataApis.unwrapDataSets(dataItems, [], [], result, this.$config.debug ? result["queryIndex"] : -1);
                            for (let i = 0; i < data.length; i++) {
                                if (Object.isUseful(data[i]))
                                    if (Array.isUseful(data[i].data))
                                        if (data[i].target === 0)
                                            self.totalQuantityProduced += data[i].data.last().y;
                                        else if (data[i].target === 1)
                                            self.totalQuantityProduced += data[i].data.last().y;
                                        else if (data[i].target === 2)
                                            self.quantityRejected += data[i].data.last().y;
                                        else if (data[i].target === 3)
                                            self.quantityRejected += data[i].data.last().y;
                                        else if (data[i].target === 4)
                                            self.lastSampleProduced = data[i].data.last().x;
                                        else if (data[i].target === 5)
                                            self.manualTotalsLog = data[i].data;
                                        else if (data[i].target === 6)
                                            self.manualRejectsLog = data[i].data;
                            }
                            self.quantityProduced = self.totalQuantityProduced - self.quantityRejected;
                            self.newQuantityProduced = self.quantityProduced;
                            if(self.manualCounters)
                                self.newQuantityRejected = self.quantityRejected;
                            else self.newQuantityRejected = 0;
                        })
                        .catch(err => {
                            console.log(err);
                        })
                        .finally(() => {
                            self.dataValid = (self.lastSampleProduced !== 0);
                            if(!self.dataValid)
                                self.error = self.$gettext("Unable to retrieve selected workorder information");
                            else self.error = "";
                        });
                }
            },

            /** TIME WINDOW MANAGEMENT **/
            zoomIn() {
                this.timeWindow.zoomIn();
                this.loadWorkordersList();
            },
            zoomOut() {
                this.timeWindow.zoomOut();
                this.loadWorkordersList();
            },
            openTimeWindowPicker() {
                //Ensure that data in time picker are copied from widget time window and not assigned, otherwise after the first run
                //It will be no more possible to cancel the time picker without affecting the widget time span
                //this.customTimePicker.span = this.properties.timeWindow; //No no no
                this.customTimePicker.span.from(this.timeWindow);
                this.customTimePicker.show = true;
            },
            customTimePickerResult(result) {
                this.customTimePicker.show = false;
                if(result) {
                    //Ensure that data from time picker are copied to widget time window and not assigned, otherwise after the first run
                    //It will be no more possible to cancel the time picker without affecting the widget time span
                    //this.scope().properties.timeWindow = this.customTimePicker.span; //No no no
                    this.timeWindow.from(this.customTimePicker.span);
                }
                this.loadWorkordersList();   //Execute a refresh anyway when getting back to view
            },
        }
    }

</script>

<style>

</style>
