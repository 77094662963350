<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" class="page-container" grid-list-md text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card  class="mt-0 av-card" elevation="0">
            <v-card-text >
                <ConfigurationsEditor :descriptor="landingPageSettings" @value-Changed="valueChanged"></ConfigurationsEditor>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";

    export default {
        name: "LandingPageSettings",
        components: {
            ConfigurationsEditor
        },
        data() {
            return {
                landingPageSettings: {
                    appearance: {
                        groupName: this.$gettext("Appearance"),
                        settings: {
                            logo: {
                                name: this.$gettext("Logo"),
                                value: null,
                                type: "file"
                            },
                            title: {
                                name: this.$gettext("Title"),
                                value: "",
                                type: "string",
                            },
                            subtitle: {
                                name: this.$gettext("Subtitle"),
                                value: "",
                                type: "string",
                            },
                            color: {
                                name: this.$gettext("Background color"),
                                value: "#ffffff",
                                type: "color",
                            },
                            imageLoader: {
                                name: this.$gettext("Loader animation"),
                                value: null,
                                type: "file",
                            },
                            redirectOnError: {
                                name: this.$gettext("Default redirect in case of errors"),
                                value: "",
                                type: "string",
                            },
                        }
                    },
                }
            }
        },
        mounted: function () {
            this.$root.setCurrentPageTitle(this.$gettext("Landing page style"));
            this.loadSettings();
        },
        methods: {

            loadSettings() {
                //Get settings and merge values on descriptor
                this.settingsValues = this.$settings.loadLandingPageSettings();
                this.settingsValues.then(t => {
                    this.mergeSetting(this.landingPageSettings.appearance.settings.logo, t.logo);
                    this.mergeSetting(this.landingPageSettings.appearance.settings.title, t.title);
                    this.mergeSetting(this.landingPageSettings.appearance.settings.subtitle, t.subtitle);
                    this.mergeSetting(this.landingPageSettings.appearance.settings.color, t.color);
                    this.mergeSetting(this.landingPageSettings.appearance.settings.imageLoader, t.imageLoader);
                    this.mergeSetting(this.landingPageSettings.appearance.settings.redirectOnError, t.redirectOnError);
                })
            },
            async valueChanged(groupName, settingName, actionObj) {

                if (this.settingsValues === null)
                    this.settingsValues = {};

                // if (groupName === "color") {
                //   this.settingsValues.color = actionObj.item
                // }

                let trail = this.$audits.getSettingChangedTrailObject(groupName, settingName, actionObj, this.settingsValues, this.landingPageSettings, ["LandingPage"]);

                let values = this.$utils.detach(this.landingPageSettings);
                let self = this;
                this.settingsValues.logo = values.appearance.settings.logo.value;
                this.settingsValues.title = values.appearance.settings.title.value;
                this.settingsValues.subtitle = values.appearance.settings.subtitle.value;
                this.settingsValues.imageLoader = values.appearance.settings.imageLoader.value;
                this.settingsValues.color = values.appearance.settings.color.value;
                this.settingsValues.redirectOnError = values.appearance.settings.color.redirectOnError;

                this.$settings.saveLandingPageSettings(this.settingsValues)
                    .then(() => {
                        //check for avoiding empty "deleted" audits
                        if (!(actionObj.action === 'deleted' && typeof trail.previousVal === 'undefined')) {
                            self.$audits.save(trail.operator, trail.action, trail.previousVal, trail.nextVal, trail.actionId)
                                .catch(() => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                })
                        }
                    })
                    .catch(err => {
                        debugger
                        self.$root.showErrorNotification(self.$gettext("An error occurred while saving settings to DB"), true);
                        self.loadSettings();
                    })
            },
            mergeSetting(target, source) {
                if(Object.isUseful(source))
                    target.value = source;
            }
        }
    }
</script>

<style scoped>

</style>