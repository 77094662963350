<template>
    <v-dialog v-model="showDialog" width="760px" height="640px" min-height="590px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <v-card>
            <v-card-title class="title">
                <translate>
                    Apply filters for export report
                </translate>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: auto">
                <div ref="container" id="globalFilters" :style="'height: {0}px; margin: 0px; padding:0px; overflow: hidden;'.format(winHeight)" />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions id="antares-annotation-dialog-card-actions">
                <v-spacer></v-spacer>
                <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="exportReport" :disabled="isLoading" :loading="isLoading">
                    <translate>
                        Export
                    </translate>
                </v-btn>
                <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat" @click="showDialog=false">
                    <translate>
                        Cancel
                    </translate>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "DynamicFilteringDialog",
    components: {},
    data:()=>{
        return {
            showDialog:false,
            callerInstance: null,
            globalFilters:null,
            appliedFilters:[],
            loading:false,
            dynamicFilters:undefined,
            dependentFilters:[]
        }
    },
    computed: {
        winHeight() {
            if(this.$root.isMobile)
                return window.innerHeight;
            else return 500;
        },
        isLoading(){
            return this.loading
        }
    },
   methods:{
       setCaller(caller) {
           this.callerInstance = caller;
       },
       async Show(_filterItems, _dataItems, timeWindow) {

           if(!_filterItems && !_dataItems)
               return;

           let filterItems = this.$utils.detach(_filterItems)
           let dataItems = this.$utils.detach(_dataItems)

           let roots = [];
           if(dataItems) {
               for(let dataItem of dataItems) {
                   let mapping = await this.$datalayer.dataDefinitionsForIndex(dataItem.index);
                   let filteredMapping = this.$datalayer.searchDataItems(mapping, dataItem.name, true, true)
                   let recursables = filteredMapping.getItemsByKeyRecursive("isRoot", undefined);
                   recursables.push(...filteredMapping.getItemsByKeyRecursive("isRoot", "false"))
                   for(let recursion of recursables) {
                       if(recursion.root)
                           if(roots.indexOf(recursion) === -1) {
                                roots.push({
                                    label: this.$gettext("Choose machine to export"),
                                    identifier: recursion.root + "." + recursion.name,
                                    dataFormat: this.$defines.DatasetFormats.xy,
                                    data: [{ y: recursion.root}],
                                    visualizationOptions: { UseValuesAsParametricVariables: true }
                               });
                           }
                   }
               }
           }

           if(!Object.isUseful(timeWindow)) {
               timeWindow = {
                   end: null,
                   hiddenValues: [],
                   isIndexed: false,
                   predefined: "month",
                   predefinedIndex: 18,
                   shifts: [{
                       from: "10:00",
                       to: "18:00"
                   }, {
                       from: "18:00",
                       to: "03:00"
                   }, {
                       from: "03:00",
                       to: "10:00"
                   }],
                   start: null,
                   timeWindowIndex: 1
               }
           }
           let self=this;
           this.$nextTick(() => {
               let descriptor={
                   aggregations: [],
                   customDescriptor: "",
                   data: [],
                   deploy: null,
                   exports: [],
                   filters: [],
                   formVariables: [],
                   functions: [],
                   outputs: [],
                   properties: {
                       alwaysHideManualRefresh: false,
                       cron: "0 */1 * * 0",
                       dashboardToRedirect: "",
                       dataPatterns: [],
                       description: "",
                       descriptorVersion: 1,
                       editableToLevels: [{
                           level: 0,
                           show: "viewer"
                       }, {
                           level: 1,
                           show: "operator"
                       }, {
                           level: 2,
                           show: "manager"
                       }, {
                           level: 9,
                           show: "admin"
                       }],
                       exposableToLevels: null,
                       forcedDataRefreshInterval: -1,
                       name: "Workorders filter",
                       reloadDynamicLinks: false,
                       selectedDevices: [],
                       showTimeWindow: true,
                       showTitleBar: true,
                       timeWindow: timeWindow,
                       title: "",
                       type: 15,
                       visibleToLevels: [{
                           level: 0,
                           show: "viewer"
                       }, {
                           level: 1,
                           show: "operator"
                       }, {
                           level: 2,
                           show: "manager"
                       }, {
                           level: 9,
                           show: "admin"
                       }],
                   },
                   queryDescriptor: {
                   },
                   reportTemplate: null,
                   reportTemplateName: null,
                   rules: [],
                   version: 9
               }
               self.unwrapDependentFilters(filterItems)
               if(Array.isUseful(this.dependentFilters))
                   descriptor.filters = this.dependentFilters;
               self.unwrapFilterItems(filterItems)
               descriptor.data = filterItems //After unwrapping, previous filterItems are now dataItems
               self.dynamicFilters = self.$dynamicElements.createNew(descriptor.properties.type, false);
               self.dynamicFilters.load(descriptor, { hideBorder: { value:true } });
               self.dynamicFilters.addCustomFilters(roots);
               self.dynamicFilters.$on('globalParamUpdated',(params)=>{
                   self.appliedFilters = params
               })
               self.showDialog = true;
               self.$refs.container.appendChild(self.dynamicFilters.$el)
           })
       },
       exportReport(){
           this.$emit('export',this.appliedFilters)
           this.showDialog=false
       },
       unwrapDependentFilters(filterItems) {
           //Loop through filters to find if any another filter is checked as dependent on it
           this.dependentFilters = this.$utils.detach(filterItems)
           for(let dependentFilterItem of this.dependentFilters) {
               for(let dependentFilter of dependentFilterItem.filters) {
                   for(let filterItem of filterItems) {
                       //We take note of dependencies on main filterItem to speed up filters calculation when converting them into dataItems
                       if(!filterItem.dependsOn)
                           filterItem.dependsOn = [];
                       let dependentFilters = filterItem.filters.filter(item => item.dependsOn === dependentFilter.filterId)
                       //If dependent, we keep dependency target as a filter within GlobalFilterWidget in order to make field
                       //values dependent upon other selected values. In fact, dependent filters are parametric by construction;
                       //whenever user selects something from a variable dropdown, a globalParamsUpdate event is posted and caught also
                       //by the GlobalFiltersWidget itself so that the selected value can be used as a filter to narrow down values
                       //of other variables. When calculating dataItems, we apply these items as filters to the generated representations.
                       if(Array.isUseful(dependentFilters)) {
                           dependentFilter.needed = true;
                           dependentFilterItem.needed = true;
                           filterItem.dependsOn.push(dependentFilter.filterId)
                       }
                   }
               }
           }
           //Now remove filter items that are not needed since no item depends on them
           this.dependentFilters = this.dependentFilters.filter(item => item.needed)
           for(let filter of this.dependentFilters)
               filter.filters = filter.filters.filter(item => item.needed)
       },
       unwrapFilterItems(filterItems) {
           let self = this
           filterItems.forEach(fi => {
               fi.representations = [{
                   aggregationWindow: 0,
                   aggregationWindowUnit: "full",
                   dataType: "text",
                   defaultName: "Values survey of "+fi.index+".Line."+fi.root+"."+fi.name,
                   enabled: true,
                   filters: fi.dependsOn,
                   histogramStep: 1,
                   id: self.$utils.timeTag(),
                   name: "Select "+fi.root+"."+fi.name,
                   normalizations: [],
                   order: "ascending",
                   orderBy: "categories",
                   queryId: "agg_0",
                   show: true,
                   target: {},
                   type: "terms",
                   visualizationOptions: {
                       automaticDependentFilterHandling: Array.isUseful(fi.dependsOn)
                   }
               }]
           })
       }
   },
    watch: {
        "dynamicFilters.loading": {
            handler() {
                this.loading =this.dynamicFilters.loading || this.dynamicFilters.refreshing
            }
        },
        "dynamicFilters.refreshing": {
            handler() {
                this.loading =this.dynamicFilters.loading || this.dynamicFilters.refreshing
            }
        }
    }

}
</script>

<style scoped>

</style>