import Api from '@/api/api'
import Vue from 'vue'


export default {
    async versions (offset, itemsNum, filters) {
        return Api().get('/auth/orchestrator/versions/0/1000/' + JSON.stringify({}))
    },
    async menu (menuID, category, entityID) {
        if (entityID === undefined) {
            return Api().get('/auth/orchestrator/menu/' + menuID + '/' + category + '/')
        } else {
            return Api().get('/auth/orchestrator/menu/' + menuID + '/' + category + '/' + entityID)
        }
    },
    async menuAll () {
        return Api().get('/auth/orchestrator/menu/all')
    },
    async status () {
        return Api().get('/auth/orchestrator/system/status')
    },
    async statusAll () {
        return Api().get('/auth/orchestrator/system/all')
    },
    //TODO evaluate this function to become a common makeApiCall
    async proxyCall (method,  apiURL, payload) {
        if (!Object.isUseful(payload)) {
            payload = ''
        } else {
            payload = JSON.stringify(payload);
        }
        return new Promise((resolve, reject) => {

            Vue.prototype.OrchestratorAPI.proxyCall( method,apiURL,payload)
                .then((t) => {
                    if(t.data.ErrorMessage) {
                        throw(t.data.ErrorMessage);
                    }
                    else if (t.data.Data) {
                        try {
                            resolve(JSON.parse(t.data.Data));
                        }
                        catch (e) {
                            resolve(t.data.Data);
                        }                       
                    } else resolve(t.data.Data);
                    })
                .catch((error) => {
                    //debugger;
                    let err = JSON.parse(error);
                    console.log('Proxy call error: {0} {1} Code: {2} Error: {3}'.format(method, apiURL, err.Code, err.Message.format(err.Parameters)));
                    reject(err)})
        })        
        // return Api().post('/auth/orchestrator/request', { ServiceType: serviceType, APIToCall: apiURL, Payload: payload, Method: method }, { timeout: 60000 * 4 })
    },
    async applicationDetails () {
        return Api().get('/auth/orchestrator/application/details')
    },
    async loadActiveModules () {
        return Api().get('/auth/orchestrator/modules/active')
    },
    async loadModules () {
        return Api().get('/auth/orchestrator/modules')
    },
    async saveModules (modules) {
        return Api().post('/auth/orchestrator/modules', JSON.stringify(modules))
    },
    async saveMenu (menu) {
        return Api().post('/auth/orchestrator/menu', JSON.stringify(menu))
    },
    // async versions () {
    //     return Api().get('/auth/orchestrator/versions')
    // },
    async applicationVersion () {
        return Api().get('/auth/orchestrator/application/version')
    },
    async applicationVersionDetails () {
        return Api().get('/auth/orchestrator/application/version/details')
    },
    async settings () {
        return Api().get('/auth/orchestrator/private/options')
    },
    async options () {
        return Api().get('/auth/orchestrator/options')
    },
    async saveOptions (options) {
        return Api().post('/auth/orchestrator/options', JSON.stringify(options))
    },
    async saveSettings (settings) {
        return Api().post('/auth/orchestrator/private/options', JSON.stringify(settings))
    },
    async preferences () {
        return Api().get('/auth/orchestrator/preferences')
    },
    async savePreferences (preferences) {
        return Api().post('/auth/orchestrator/preferences', JSON.stringify(preferences))
    },

    async createAuthToken(username, password){
        return Api().post('/auth', JSON.stringify({username, password}));
    },   

    async createAuthTokenTest(username, password){
        return Api().post('/authTest', JSON.stringify({username, password}));
    },

    async logout(){
        return Api().post('/auth/logout');
    },
    
    async checkToken() {
        return Api().post('/auth/check');
    },
    
    async loadLanguages () {
        // return Api().get('/auth/orchestrator/languages')
        return Api().get('/languages')
    },
    async saveLanguage (languageID, languageName) {
        return Api().post('/auth/orchestrator/languages/create', JSON.stringify({ Name: languageName, ID: languageID }))
    },
    async loadTranslations (languageID) {
        // return Api().get('/auth/orchestrator/languages/' + languageID + '/strings')
        return Api().get('/languages/' + languageID + '/strings')
    },
    async loadTranslationsList (languageID) {
        // return Api().get('/auth//orchestrator/languages/' + languageID + '/list')
        return Api().get('/languages/' + languageID + '/list')
    },
    async saveTranslations (languageID, translations) {
        return Api().post('/auth/orchestrator/languages/' + languageID + '/strings', JSON.stringify(translations))
    },
    async deleteTranslations (translations) {
        return Api().post('/auth/orchestrator/languages/translations/delete', JSON.stringify(translations))
    },
    async saveMissingTranslations (translations) {
        return Api().post('/auth/orchestrator/languages/missing/save', JSON.stringify(translations))
    },
    async exportLanguageTranslation (languageID) {
        return Api().get('/auth/orchestrator/languages/' + languageID + '/export')
    },
    async importLanguageTranslation (languageID, filecontent) {
        return Api().post('/auth/orchestrator/languages/' + languageID + '/import', JSON.stringify(filecontent))
    },
    async deleteLanguage (languageID) {
        return Api().post('/auth/orchestrator/languages/delete', JSON.stringify({ ID: languageID }))
    }
}
