<template>
    <DynamicElementBase>
        <v-tabs dark :style="'overflow: auto; margin-top: -10px; height: {0}px'.format(containerHeight)"  @input="hideTimeWindow">
            <template>
                <v-tab key="allAlarms">
                    <translate>
                        All alarms
                    </translate>
                </v-tab>
                <v-tab-item key="allAlarms">
                    <v-data-table
                            :headers="headers"
                            :items="filteredAlarms"
                            class="elevation-1 subheading"
                            hide-actions
                            ma-0
                            pa-0
                            :pagination.sync="pagination"
                    >
                        <template slot="headers" slot-scope="props">
                            <tr>
                                <th class="text-xs-left subheading" v-for="header in props.headers" :key="header.text">
                                    {{ header.text }}
                                    <v-btn  v-if="header.sortable" small flat="flat" class="btn"
                                            @click="changeSort(header.value)">
                                        <av-icon ma-0 pa-0>{{pagination.descending?'fas fa-caret-down':'fas fa-caret-up'}}</av-icon>
                                    </v-btn>
                                    <v-menu :close-on-click="true" :close-on-content-click="false"  offset-y transition="slide-y-transition" bottom  >
                                        <v-btn slot="activator" fab flat small v-if="header.value==='filter'" class="btn" @click="handleVisibility">
                                            <v-badge style="font-size: inherit;" v-if="filters.device.length>0 || filters.description.length>0"
                                                    v-model="showBadge"
                                                    color="grey lighten-1"
                                            >
                                                <template v-slot:badge>
                                                    <span>{{filters.device.length + filters.description.length}}</span>
                                                </template>
                                                <av-icon style="font-size: inherit;">fas fa-filter</av-icon>
                                            </v-badge>
                                            <av-icon  v-if="filters.device.length===0 && filters.description.length===0" style="font-size: inherit;">fas fa-filter</av-icon>
                                        </v-btn>
                                        <v-list id="toolbar-dropdown-menu-alarms">
                                            <v-list-tile>
                                                <v-list-tile-content>
                                                     <span class="context-menu-span" v-if="filters.hasOwnProperty('description')">
                                                        Filter Data:
                                                    </span>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </v-list>
                                        <hr/>
                                        <v-list id="toolbar-dropdown-menu" three-line>
                                            <v-list-tile>
                                                <v-list-tile-content>
                                                     <span class="context-menu-span" v-if="filters.hasOwnProperty('device')">
                                                        <v-select chips label="Select Devices" flat hide-details small multiple clearable :items="columnValueList('device')" v-model="filters['device']">
                                                             <template v-slot:selection="{ item, index }">
                                                                  <v-chip v-if="index === 0">
                                                                    <span>{{ item }}</span>
                                                                  </v-chip>
                                                                  <span
                                                                          v-if="index === 1"
                                                                          class="grey--text caption"
                                                                  >(+{{ filters['device'].length - 1 }} others)</span>
                                                                </template>
                                                        </v-select>
                                                    </span>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                            <v-list-tile>
                                                <v-list-tile-content>
                                                     <span class="context-menu-span" v-if="filters.hasOwnProperty('description')">
                                                        <v-select chips label="Select Descriptions" flat hide-details small multiple clearable :items="columnValueList('description')" v-model="filters['description']">
                                                             <template v-slot:selection="{ item, index }">
                                                                  <v-chip v-if="index === 0">
                                                                    <span>{{ item }}</span>
                                                                  </v-chip>
                                                                  <span
                                                                          v-if="index === 1"
                                                                          class="grey--text caption"
                                                                  >(+{{ filters['description'].length - 1 }} others)</span>
                                                                </template>
                                                        </v-select>
                                                    </span>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                            </v-list>
<!--                                            <v-divider></v-divider>-->
<!--                                        <v-list three-line>-->
<!--                                            <v-list-tile>-->
<!--                                                <v-list-tile-content>-->
<!--                                                     <span class="context-menu-span" v-if="filters.hasOwnProperty('description')">-->
<!--                                                        <v-select chips label="Select Descriptions" flat hide-details small multiple clearable :items="columnValueList('description')" v-model="filters['description']">-->
<!--                                                             <template v-slot:selection="{ item, index }">-->
<!--                                                                  <v-chip v-if="index === 0">-->
<!--                                                                    <span>{{ item }}</span>-->
<!--                                                                  </v-chip>-->
<!--                                                                  <span-->
<!--                                                                          v-if="index === 1"-->
<!--                                                                          class="grey&#45;&#45;text caption"-->
<!--                                                                  >(+{{ filters['description'].length - 1 }} others)</span>-->
<!--                                                                </template>-->
<!--                                                        </v-select>-->
<!--                                                    </span>-->
<!--                                                </v-list-tile-content>-->
<!--                                            </v-list-tile>-->
<!--                                        </v-list>-->
                                    </v-menu>
                                </th>
                            </tr>
                        </template>
                        <template v-slot:items="props">
                            <td ma-0 pa-0 class="text-xs-left" style="width: 70px">
                                <av-icon ma-0 pa-0 v-if="props.item.active && blink" color="red">error</av-icon>
                                <av-icon ma-0 pa-0 v-else>error</av-icon>
                            </td>
                            <td class="text-xs-left subheading">{{ props.item.device }}</td>
                            <td class="text-xs-left subheading">{{ props.item.description }}</td>
                            <td class="text-xs-left subheading">{{ props.item.activateTimestamp }}</td>
                            <td class="text-xs-left subheading">{{ props.item.deactivateTimestamp }}</td>
                            <td class="text-xs-left subheading">{{ props.item.duration }}</td>
                            <td v-if="false" class="text-xs-left"><av-icon color="error" @click="annotate(props.item)">fas fa-pen</av-icon></td>
                        </template>
                    </v-data-table>
                </v-tab-item>
            </template>
            <template>
                <v-tab key="activeAlarms">
                    <translate>
                        Active alarms
                    </translate>
                </v-tab>
                <v-tab-item key="activeAlarms">
                    <v-data-table
                            :headers="activeAlarmsHeaders"
                            :items="activeAlarms"
                            class="elevation-1 subheading"
                            hide-actions
                            ma-0
                            pa-0
                    >
                        <template v-slot:items="props">
                            <td ma-0 pa-0 class="text-xs-left" style="width: 70px">
                                <av-icon ma-0 pa-0 v-if="props.item.active && blink" color="red">error</av-icon>
                                <av-icon ma-0 pa-0 v-else>error</av-icon>
                            </td>
                            <td class="text-xs-left subheading">{{ props.item.device }}</td>
                            <td class="text-xs-left subheading">{{ props.item.description }}</td>
                            <td class="text-xs-left subheading">{{ props.item.activateTimestamp }}</td>
                            <td v-if="false" class="text-xs-left"><av-icon color="error" @click="annotate(props.item)">fas fa-pen</av-icon></td>
                        </template>
                    </v-data-table>
                </v-tab-item>
            </template>
        </v-tabs>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
    import alarms from '@/api/alarmsApi'
    import {TimeSpan} from '@/api/datetimeutils'
    import DateTime from '@/api/datetimeutils'
    import annotationView from '@/components/dialogs/EventAnnotationDialog'
    import Vue from 'vue'
    import jsutils from "../../../api/jsutils";

    let Targets = { "name": 0, "active" : 1 };

    export default {
        name: 'WidgetAlarms',
        extends: DynamicElementBase,
        components: {
            DynamicElementBase
        },
        data () {
            return {
                dataCollection: [],
                activeAlarms: [],
                headers: [],
                filters: {
                    device: [],
                    description: []
                },
                activeAlarmsHeaders: [],
                blink: false,
                firstRun: true,
                show:true,
                showBadge:true,
                columnValues: [],
                pagination: {
                    sortBy: 'activation', // The field that you're sorting by
                    descending: true,
                    rowsPerPage: -1
                },
                startActiveTimeWindow: Date.now() - 259200000, //3 days in milliseconds
                timeWindowActiveAlarms: new TimeSpan(),
                blinkTimer: null,
                activeAlarmsTimer: null,
                savedFilters: {},
                devices: [],
            }
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    this.saveTweaks();
                },
                deep: true
            },
        },
        mounted: function() {
            this.loadConfiguredDevicesList();
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["alarms"], false));
            this.dataExplorationMode.autoDataSourcing = true;
            this.fixedMaxTimeWindow = 'week'; //Limit max time window to 1 week
            this.customQueryHandler = this.getData;
            this.childHandlers.checkItemUsefulness = () => { return true; };

            this.visualizationTargets = [
                { show: "Name",   id: Targets.name },
                { show: "Active",   id: Targets.active }
            ];

            this.headers = [{ text: "", class: "subheading", value: "filter" }, /*{ text:"Identifier", class: "subheading" },*/
                { text: this.$gettext("Device"), class: "subheading", value: "device", sortable: false }, { text: this.$gettext("Description"), class: "subheading", value: "description"},
                { text: this.$gettext("Activation"), class: "subheading", value: "activation", sortable: false }, { text: this.$gettext("Deactivation"), class: "subheading" },
                { text: this.$gettext("Duration"), class: "subheading" },
                /*{ text: "Comment", class: "subheading"} */ ];
            this.activeAlarmsHeaders = [{ text: "", class: "subheading" },
                { text: this.$gettext("Device"), class: "subheading" }, { text: this.$gettext("Description"), class: "subheading" },
                { text: this.$gettext("Activation"), class: "subheading" }, /*{ text: "Comment", class: "subheading"} */];
            this.blinkTimer = setInterval(()=> {this.blink = !this.blink;}, 700);
            this.activeAlarmsTimer = setInterval(this.loadActiveAlarms, 5000)

            this.visualizationTweaks = [
                {
                    name: this.$gettext("Apply selected filters statically"),
                    id: "fixedFilters",
                    type: "bool",
                    default: function () {
                        return false;
                    }
                }
            ];
        },
        beforeDestroy: function() {
            clearInterval(this.activeAlarmsTimer);
            clearInterval(this.blinkTimer);
        },
        created() {
            this.childHandlers.getCustomDescriptor = () => {
                return this.filters
            };
            this.childHandlers.setCustomDescriptor = (filters) => {
                if (filters) {
                    this.savedFilters = filters
                    this.filters.device = filters.device
                }
            };
        },
        computed: {
            filteredAlarms() {
                // select first 200 elements of DataCollection (or less if length is < 200)
                let self = this;
                let result = self.dataCollection.filter(d => {
                    return Object.keys(self.filters).every(f => {
                        return self.filters[f].length < 1 || self.filters[f].includes(d[f])
                    })
                }).slice(0,200);

                if(result.length === 200) {
                    self.setWarning(self.$gettext("Found: {0} alarms but showing maximum 200. To increase accuracy either reduce time window or use the rolling mode").format(self.dataCollection.length));
                }

                return result;
            },
        },
        methods: {
            annotate(item) {
                //Create widget
                let componentClass = Vue.extend(annotationView);
                let annotationDialog = new componentClass();
                annotationDialog.$mount();
                annotationDialog.Show(item, this.$root.userName);
            },
            getData() {
                this.clearWarnings();
                return alarms.getAlarmsList(DateTime.getRfc3339TimeStamp(this.properties.timeWindow.getStart()), DateTime.getRfc3339TimeStamp(this.properties.timeWindow.getEnd()), this.properties.dataPatterns[0], false, this.filters.device);
            },
            getDataActiveAlarms() {
                return alarms.getAlarmsList(DateTime.getRfc3339TimeStamp(new Date(this.startActiveTimeWindow)), DateTime.getRfc3339TimeStamp(new Date()), this.properties.dataPatterns[0],false, this.filters.device);
            },
            loadActiveAlarms()
            {
                let self = this;
                    this.getDataActiveAlarms()
                        .then(result => {
                            self.refreshActiveAlarms(result)
                        })
            },
            loadConfiguredDevicesList() {
                let self = this;
                //Get the list of devices that has a descriptions file saved in DB
                self.devices.clear();
                self.$devices.getAvailable()
                    .then(devices => {
                        for(let i = 0 ; i < devices.length ; i++) {
                            if(devices[i])
                                self.devices.push(devices[i]);
                        }

                    })
                    .catch(err => {
                        console.log("Unable to retrieve devices info.");
                    });
            },

            async refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view
                let self = this;
               self.dataCollection.clear();
                let dataCollection = [];

                if (!Array.isUseful(dataValues)) {
                    return;
                }

                dataCollection = await self.formatAlarms(dataValues);

                this.applyStaticFilters();

                if(self.pagination.sortBy==='activation' && !this.pagination.descending) {
                    dataCollection.sort(function (x, y) {
                        return x.timestamp - y.timestamp;
                    });
                }
                else
                    dataCollection.sort(function (x, y) {
                        return y.timestamp - x.timestamp;
                    });

                for(let k = 0 ; k < dataCollection.length ; k++) {
                    if(dataCollection[k].active === false && dataCollection[k].activateTimestamp === "") {
                        for(let j = k ; j < dataCollection.length ; j++) {
                            if(dataCollection[j].rawId === dataCollection[k].rawId && dataCollection[j].active && dataCollection[j].deactivateTimestamp === "") {
                                dataCollection[j].active = false;
                                dataCollection[j].deactivateTimestamp = dataCollection[k].deactivateTimestamp;
                                dataCollection[j].duration = DateTime.formatTime((dataCollection[k].endTimeStamp - dataCollection[j].startTimeStamp) / 1000, true);
                                dataCollection.removeItem(dataCollection[k]);
                                k--;
                                break;
                            }
                        }
                    }
                }

                self.dataCollection = JSON.parse(JSON.stringify(dataCollection));
            },

            async refreshActiveAlarms(dataValues) {
                let self = this;
                let dataCollection = [];
                self.activeAlarms.clear();

                if (!Array.isUseful(dataValues))
                    return;

                dataCollection = await self.formatAlarms(dataValues);

                this.applyStaticFilters();

                dataCollection.sort(function (x, y) {
                    return y.timestamp - x.timestamp;
                });

                //Will host Ids of alarms that activated and deactivated in time window.
                //This will be used to filter out alarms that may remain pending active due to system shoutdowns
                let resolvedAlarms = [];

                for(let k = 0 ; k < dataCollection.length ; k++) {
                    if(dataCollection[k].active === false && dataCollection[k].activateTimestamp === "") {
                        for(let j = k ; j < dataCollection.length ; j++) {
                            if(dataCollection[j].rawId === dataCollection[k].rawId && dataCollection[j].active && dataCollection[j].deactivateTimestamp === "") {
                                resolvedAlarms.push(dataCollection[j].rawId);
                                dataCollection.removeAt(j);
                                dataCollection.removeAt(k);
                                k--;
                                break;
                            }
                        }
                    }
                }
                //Filling array sorted by active alarms
                for(let x = 0 ; x < dataCollection.length ; x++) {
                    if(dataCollection[x].active && !resolvedAlarms.includes(dataCollection[x].rawId)) {
                        self.activeAlarms.push(dataCollection[x])
                    }
                }

                //Update query window to oldest active alarm found with a little bit of margin for mils to secs approximation
                if(Array.isUseful(this.activeAlarms)) {
                    this.startActiveTimeWindow = this.activeAlarms.last().timestamp - 2000;
                } else {
                    this.startActiveTimeWindow = Date.now() - 60000; //No active alarms, keep focusing on last minute
                }
            },

            applyStaticFilters() {
                let machines = [];
                for(let item of this.filterItems) {
                    if(item.name === "Device") {
                        for(let filter of item.filters) {
                            for(let condition of filter.conditions) {
                                if(condition.value) {
                                    machines.push(condition.value);
                                }
                            }
                        }
                    }
                }

                if(Array.isUseful(machines)) {
                    for(let i = 0 ; i < this.dataCollection.length ; i++) {
                        if(!machines.includes(this.dataCollection[i].device)) {
                            this.dataCollection.removeAt(i);
                            i--;
                        }
                    }
                }
            },

            async formatAlarms(dataValues) {
                let dataCollection = [];
                let alarms = await this.$alarms.formatAlarms(dataValues,false);

                for(let i = 0 ; i < alarms.length ; i++) {
                    let alarm = alarms[i];
                    dataCollection.push({
                        timestamp: alarm.timestamp,
                        activateTimestamp: alarm.active ? (new Date(alarm.timestamp)).format() : "",
                        deactivateTimestamp: alarm.active ? "" : (new Date(alarm.timestamp)).format(),
                        startTimeStamp: alarm.active ? alarm.timestamp : "",
                        endTimeStamp: alarm.active ? "" : alarm.timestamp,
                        active: alarm.active,
                        device: alarm.device,
                        description: alarm.alarmDescription,
                        refid: alarm.RefId,
                        duration: null,
                        rawId: alarm.device + alarm.alarmRawIdentifier,
                        id: alarm.device + alarm.alarmRawIdentifier + (alarm.active ? '_ats_' + i : '_dst' + i) + (new Date(alarm.timestamp)).format()
                    })
                }

                dataCollection.sort(function (x, y) {
                    return y.timestamp - x.timestamp;
                });

                return dataCollection;
            },
            changeSort (column) {
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending;
                    if(column==='activation') {
                        if(!this.pagination.descending)
                            this.dataCollection.sort(function (x, y) {
                                return x.timestamp - y.timestamp;
                            });
                        else {
                            this.dataCollection.sort(function (x, y) {
                                return y.timestamp - x.timestamp;
                            });
                        }
                    }
                } else {
                    this.pagination.sortBy = column;
                    this.pagination.descending = false;
                    if(column==='activation')
                        this.dataCollection.sort(function (x, y) {
                            return x.timestamp - y.timestamp;
                        });
                }
            },
            columnValueList(val) {
                //check if static filters are saved
                if (val === 'device' && this.getTweakValue("fixedFilters") && !this.editMode && this.savedFilters){
                    return this.savedFilters.device;
                }
                if(Array.isUseful(this.dataCollection)) {
                    if(this.editMode && val === 'device' && this.devices.length > 0) {
                        return  this.columnValues = [... this.devices];
                    }
                    return this.columnValues = [... this.dataCollection.map(d => d[val])];
                }
                return this.columnValues;
            },
            handleVisibility() {
                this.show = !this.show;
            },
            hideTimeWindow(tab) {
                this.temporaryHideTimeWindows = tab !== 0 //'allAlarms';
            }
        }
    }
</script>

<style scoped>
    /*.fixed_header tbody{*/
    /*    display:block;*/
    /*    overflow:auto;*/
    /*    height:calc(100vh - 150px) !important;*/
    /*    width:100%;*/
    /*}*/
    /*.fixed_header thead tr{*/
    /*    display:block;*/
    /*}*/

    .v-table__overflow {
        overflow-y: auto !important;
        max-height: $ max-height !important;
    }
    .theme--light th {
        background-color: $ material-light . cards . theme--dark th;
        /*background-color: $ material-dark . cards th;*/
        position: sticky;
        top: 0;
        z-index: 4;
    }

    tr.v-datatable__progress {
        top: 55px;
        height: 1px;
    }

    .btn{
        min-width: 0;
        padding: 0 15px 0 0;
        margin: 0;
    }

    div.flex {
        display: flex;
        margin: 4px;
        padding: 5px;
    }

</style>
