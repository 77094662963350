import OrchestratorAPI from '@/api/orchestrator'
import Vue from 'vue'
export  default {
    embedPage (page) {
        let p="#/"+page;
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', "/api/v1/auth/embeddedpage/url/" +page)
                .then(t => {
                    let actionURL = t;
                    //OrchestratorAPI.proxyCall('post', '/api/v1/auth/webuimanager/shared/book', {page: p})
                    Vue.prototype.WebUIAPI.bookRequestForSharedStorage(p, Vue.prototype.$store.state.shared)
                        .then(t => {
                            let requestToken = JSON.parse(t.data.Data);
                            let rand = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                            let baseURL = `//` + window.location.hostname + `:` + Vue.prototype.$serverPort();
                            resolve (location.protocol + baseURL + "//" + actionURL + '?t=' + requestToken + '&r=' + rand + '&u=' + Vue.prototype.$encode(baseURL))
                        }).catch(t => {
                        reject(t)
                    })
                })
        });

    },
}