<script>
    import {HorizontalBar} from 'vue-chartjs'
    import zoom from 'chartjs-plugin-zoom';

    export default {
        extends: HorizontalBar,
        props: {
            chartdata: {
                type: Object,
                default: null
            },
            options: {
                type: Object,
                default: null
            },
        },
        watch: {
            chartdata: {
                handler: function () {
                    this.renderChart(this.chartdata, this.options)
                },
                deep: false
            },
            options: {
                handler: function () {
                    this.renderChart(this.chartdata, this.options)
                },
                deep: true
            }
        },
        methods: {
            refreshGraph() {
                this.$data._chart.update()
            },
            resetVisualization() {
                this.$data._chart.resetZoom();
            }
        },
        mounted() {
            this.addPlugin(zoom);
            this.renderChart(this.chartdata, this.options)
        }
    }
</script>