<template>
    <canvas id="cvsLine" ref="csvLine" :width="width" :height="height"></canvas>
</template>

<script>
    export default {
        name: "AvDrawLine",
        props:{
            x: {
                default: 0
            },
            y: {
                default: 0
            },
            width:{
                default:0
            },
            height:{
                default:0
            },
            lineColor:{
                default:"blue"
            },
            direction:{
                default:"right"
            }
        },
        data(){
            return{
                canvas:null,
                context:null
            }
        },
        mounted(){
            this.canvas=this.$refs.csvLine;
            this.context=this.canvas.getContext("2d");
            this.drawLine();
        },
        methods:{
            drawLine(){
                this.context.beginPath();
                let x=this.x;
                let y=this.y;
                let width=this.width-5;
                let height=this.height-5;
                if(this.direction==="left" && this.height>20 && this.width>20){
                    x=this.x;
                    y=height;
                    width=this.width;
                    height=this.y
                }
                if(this.height<=20){
                    y=this.height/2-3;
                    height=y;
                }
                if(this.width<=20){
                    x=this.width/2-3;
                    width=x;
                }
                this.context.moveTo(x,y);
                this.context.lineTo(width, height);
                this.context.lineWidth = 1.2;
                this.context.strokeStyle = this.lineColor;
                this.context.lineCap = 'round';
                this.context.closePath();
                this.context.stroke();
            },
            clearRect(){
                this.context.clearRect(this.x, this.y, this.canvas.width, this.canvas.height);
            }
        },
        watch:{
            width(){
                this.clearRect();
                setTimeout(()=>{
                    this.drawLine()
                },350)
            },
            height(){
                this.clearRect();
                setTimeout(()=>{
                    this.drawLine()
                },350)
            }
        }
    }
</script>

<style scoped>

</style>
