<template>
    <DynamicElementBase>
        <div ref="map" style="height: 100%; width: 100%; overflow: hidden; text-align: center;" >
        </div>
        <v-btn v-if="customViewPort" fab style="position: absolute; top: 150px; left: 40px; z-index: 9999" @click="resetViewPort()"><av-icon color="blue">fas fa-undo</av-icon></v-btn>
        <template slot="titleButtons">
            <v-tooltip bottom>
                <v-btn fab small slot="activator" @click="play()"  >
                    <av-icon>fas fa-play</av-icon>
                </v-btn>
                <span>Play time log</span>
            </v-tooltip>
        </template>
        <template slot="dropMenuItems">
            <v-list-tile>
                <v-list-tile-content>
                    <v-layout row>
                        <av-icon small left>fas fa-tachometer-alt</av-icon>
                        <label v-translate style="margin-top: 12px">Play time</label>
                        <v-text-field type="number" @click.stop="" v-model="playTime" style="width: 40px; margin-left: 10px; margin-top: 8px" />
                        <label style="margin-top: 12px; margin-left: 5px">secs</label>
                    </v-layout>
                </v-list-tile-content>
            </v-list-tile>
        </template>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
    import gmapsInit from '@/api/gmaps'
    import MarkerClusterer from '@googlemaps/markerclustererplus'

    let Targets = { "value" : 0 };


    export default {
        name: 'WidgetMap',
        extends: DynamicElementBase,
        components: {
            DynamicElementBase
        },
        data () {
            return {
                dataCollection: [],
                rawDataCollection: [],
                map: null,
                google: null,
                clusters: null,
                markerImage: null,
                customViewPort: false,
                autoViewPort: false,
                playTime: 10,
                playStepsCount: 0,
                playStepSize: 0,
                currentPlayStep: 0,
                player: null
            }
        },
        async mounted() {
            //Enumerate all the possible tweaks and assign saved values, later will manage the dependencies
            this.visualizationTweaks = [];
            this.visualizationTargets = [];
            this.queriesGrouping = this.$defines.allAggregations.inner.id;
            this.initializing = true;
            let self = this;

            try {
                const google = await gmapsInit();
                let mapOptions = {
                    center: new google.maps.LatLng(0, 0),
                    zoom: 1,
                    minZoom: 2,
                    maxZoom: 150,
                    restriction: {
                        latLngBounds: {
                            east: 179.9999,
                            north: 85,
                            south: -85,
                            west: -179.9999
                        },
                        strictBounds: false
                    },
                };
                this.map = new google.maps.Map(this.$refs.map, mapOptions);
                this.map.addListener("center_changed", () => {
                    self.customViewPort = !self.autoViewPort;
                    setTimeout(()=>{
                        self.autoViewPort = false;
                    }, 1000)

                });
                this.initOverlays()
            } catch (error) {
                console.error(error)
            }
        },
        beforeDestroy: function() {
            //When widget is no more visualized stop timers otherwise they will survive forever
            clearInterval(this.player);
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                },
                deep: true,
            },
        },
        computed: {
        },
        methods: {
            widgetHeight() {
                return this.$refs.container.clientHeight;
            },
            widgetWidth() {
                return this.$refs.container.clientWidth;
            },
            resize() {
            },
            resetViewPort() {
                this.customViewPort = false;
                this.refreshData(this.rawDataCollection);
            },
            play() {
                if(!Array.isUseful(this.rawDataCollection))
                    return;
                if(this.player)
                    clearInterval(this.player);
                this.player = null;
                this.playStepsCount = this.playTime;
                this.playStepSize = Math.ceil(this.rawDataCollection[0].data.length / this.playStepsCount);
                if(this.playStepsCount < 2)
                    return; //Nothing to play
                this.currentPlayStep = 0;
                let self = this;
                this.player = setInterval(() => {
                    self.refreshData(self.rawDataCollection, self.currentPlayStep);
                    self.currentPlayStep++;
                    if(self.currentPlayStep >= self.playStepsCount) {
                        clearInterval(self.player);
                        self.player = null;
                    }
                }, 1000)
            },
            initOverlays() {
                let self = this;
                gmapsInit().then((res) => {
                    self.google = res;
                    self.markerImage = new self.google.maps.MarkerImage(
                        "https://chart.apis.google.com/chart?cht=mm&chs=24x32&chco=FFFFFF,008CFF,000000&ext=.png",
                        new self.google.maps.Size(24, 32)
                    );
                    self.initClusters();
                    self.initializing = false;
                    console.log(self.clusters)
                })
            },
            initClusters() {
                if(this.clusters)
                    this.clusters.setMap(null);
                this.clusters = new MarkerClusterer(this.map, this.dataCollection, {
                    clusterClass: "custom-clustericon",
                    maxZoom: null,
                    gridSize: null,
                    styles:  [
                        {
                            width: 30,
                            height: 30,
                            className: "custom-clustericon-1",
                        },
                        {
                            width: 40,
                            height: 40,
                            className: "custom-clustericon-2",
                        },
                        {
                            width: 50,
                            height: 50,
                            className: "custom-clustericon-3",
                        },
                    ],
                });
            },
            // updateMap(data) {
            //     var bounds = new this.google.maps.LatLngBounds();
            //
            //     for (var i = 0, length = data.length; i < length; i++) {
            //         var latLng = new google.maps.LatLng(data[i].lat, data[i].lng);
            //         var id = data[i].id;
            //         if (gmarkers[id] && gmarkers[id].setPosition) {
            //             gmarkers[id].setPosition(latLng);
            //         } else {
            //             bounds.extend(latLng);
            //             var marker = new google.maps.Marker({
            //                 position: latLng,
            //                 map: map,
            //                 title: data[i].title
            //             });
            //             var infoWindow = new google.maps.InfoWindow();
            //             google.maps.event.addListener(marker, "click", function (e) {
            //                 infoWindow.setContent(data.description + "<br>" + marker.getPosition().toUrlValue(6));
            //                 infoWindow.open(map, marker);
            //             });
            //             (function (marker, data) {
            //                 google.maps.event.addListener(marker, "click", function (e) {
            //                     infoWindow.setContent(data.description + "<br>" + marker.getPosition().toUrlValue(6));
            //                     infoWindow.open(map, marker);
            //                 });
            //             })(marker, data[i]);
            //             gmarkers[id] = marker;
            //         }
            //     }
            //     if (firstData) {
            //         map.fitBounds(bounds);
            //         firstData = false;
            //     }
            // },

            refreshData(dataValues, step = -1) { //Unwrap new data based on dataItems descriptor and print to view

                if(!this.player)
                    this.rawDataCollection = dataValues;
                else if(step === -1)
                    return; //Data coming from queries. They should be stopped on play, but, just in case...

                let bounds = new this.google.maps.LatLngBounds();

                if (this.clusters)
                    this.clusters.clearMarkers();
                this.dataCollection.clear();

                if(!Array.isUseful(dataValues) || !Array.isUseful(dataValues[0].data)) {
                    return;
                }

                for(let i = 0 ; i < dataValues.length ; i++) {
                    if(dataValues[i].dataType === this.$defines.avionicsDataTypes.geopoint.id) {
                        dataValues.moveItem(i, 0);
                        break;
                    }
                }

                let i;
                let limit = dataValues[0].data.length;
                if(step >= 0) {
                    limit = (step + 1) * this.playStepSize;
                    if(limit > dataValues[0].data.length)
                        limit = dataValues[0].data.length;
                }
                let needsRefresh = true;
                for (i = 0; i < limit ; i++) {

                    let title = new Date(dataValues[0].data[i].x).format() + "\n";
                    for(let j = 1 ; j < dataValues.length ; j++) {
                        for(let item of dataValues[j].data) {
                            if(item.x === dataValues[0].data[i].x) {
                                title += dataValues[j].label + ": " + item.y + "\n";
                                break
                            }
                        }
                    }
                    // let id = dataValues[0].data[i].x+dataValues[0].data[i].y[0]+dataValues[0].data[i].y[1];
                    // if (this.dataCollection[id] && this.dataCollection[id].setPosition)
                    //     this.dataCollection[id].setPosition(new this.google.maps.LatLng(dataValues[0].data[i].y[0], dataValues[0].data[i].y[1]));
                    // else {
                        this.dataCollection.push(new this.google.maps.Marker({
                            position: {
                                lat: parseFloat(dataValues[0].data[i].y.lat),
                                lng: parseFloat(dataValues[0].data[i].y.lon)
                            },
                            icon: this.markerImage,
                            title: title
                        }));
                    bounds.extend(this.dataCollection.last().position)
                    //     needsRefresh = true;
                    // }
                }
                // for(let j = i ; j < this.dataCollection.length ; j++) {
                //     needsRefresh = true;
                //     this.dataCollection.removeAt(j);
                // }

                if(needsRefresh) {
                    this.initClusters();
                }

                if(!this.customViewPort) {
                    this.autoViewPort = true;
                    this.map.fitBounds(bounds, {bottom: 10, left: 10, right: 10, top: 10});
                    this.map.panToBounds(bounds);
                }
            },
        },
    }
</script>

<style>

    .custom-clustericon {
        background: var(--cluster-color);
        color: #fff;
        border-radius: 100%;
        font-weight: bold;
        font-size: 15px;
        display: flex;
        align-items: center;
    }

    .custom-clustericon::before,
    .custom-clustericon::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;

        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background: var(--cluster-color);
        opacity: 0.2;
        border-radius: 100%;
    }

    .custom-clustericon::before {
        padding: 7px;
    }

    .custom-clustericon::after {
        padding: 14px;
    }

    .custom-clustericon-1 {
        --cluster-color: #00a2d3;
    }

    .custom-clustericon-2 {
        --cluster-color: #ff9b00;
    }

    .custom-clustericon-3 {
        --cluster-color: #ff6969;
    }

</style>
