<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" class="page-container" grid-list-md text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card  class="ma-0 av-card" elevation="0">
            <v-card-text class="av-card-content">
                <ConfigurationsEditor :descriptor="workflowSettings" @value-Changed="valueChanged" :filters="$license.modulesList()"></ConfigurationsEditor>
            </v-card-text>
        </v-card>
    </v-container>

</template>

<script>

    import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";

    export default {
        name: "WorkflowSettings",
        components: {
            ConfigurationsEditor
        },
        data() {
            return {
                workflowSettings: {
                    notifications: {
                        visible: this.$grants.get().settings.notifications,
                        groupName: this.$gettext("Notifications settings"),
                        settings: {
                            lineStopped: {
                                name: this.$gettext("Notification on line stoppage"),
                                value: true,
                                description: this.$gettext("If enabled, time assignment dialog will pop up when line stops. Operator can fill-in reason for stop or cancel notification and fill-in later manually"),
                                type: "bool",
                                filters: [this.$license.PerformanceModule],
                                readOnly: false
                            }
                            // },
                            // workorderCompleted: {
                            //     name: this.$gettext("Workorder complete"),
                            //     value: true,
                            //     description: this.$gettext("If enabled, quantities adjustment dialog will pop up when workorder completes. Operator can manually adjust good and rejected quantities by first counting items"),
                            //     type: "bool",
                            // },
                        }
                    },
                    timeTracking: {
                        visible: this.$grants.get().settings.notifications,
                        groupName: this.$gettext("Time tracking settings"),
                        settings: {
                            productionTimeThreshold: {
                                name: this.$gettext("Minimum production time to be recorded (seconds)"),
                                value: 60,
                                description: this.$gettext("If greater than 0, when line resumes producing after a stoppage, {appName} system will wait the configured amount of seconds before switching to production status. This allows filtering very short run tests during change over or a break down. The result is that line stop periods will appear as a single compact item instead of being fragmented in many smaller stops with very short productions between. No notification will be raised if line stops again before the microproduction filter has elapsed.").format({appName: this.$config.appName}),
                                type: "number",
                                readOnly: false,
                            },
                            shortStopsThreshold: {
                                name: this.$gettext("Short stops threshold (seconds)"),
                                value: 180,
                                description: this.$gettext("Define the maximum duration of a line stop to be considered as a short stop. Short stops are managed differently from long stops and they can be selected and categorized in blocks."),
                                type: "number",
                                filters: [this.$license.PerformanceModule],
                                readOnly: false,
                            },
                            autoStopsCategorization: {
                                name: this.$gettext("Automatic stops categorization"),
                                value: false,
                                description: this.$gettext("{appName} will automatically assign root cause to stops whenever there is an alarm linked to a stop cause.").format({appName: this.$config.appName}),
                                type: "bool",
                                filters: [this.$license.PerformanceModule],
                                readOnly: false
                            },
                            forceStopsCategorizationOnShiftClose: {
                                name: this.$gettext("Force stops categorization on shift close"),
                                value: false,
                                description: this.$gettext("If checked system requests categorizing all uncategorized stops when shift is closed."),
                                type: "bool",
                                filters: [this.$license.PerformanceModule],
                                readOnly: false,
                            },
                            forceStopsCategorizationOnIdle: {
                                name: this.$gettext("Force stops categorization on workorder unscheduling"),
                                value: false,
                                description: this.$gettext("If checked system requests categorizing all uncategorized stops when workorder is closed."),
                                type: "bool",
                                filters: [this.$license.PerformanceModule],
                                readOnly: false,
                            },
                            forceCategorizationLongStopsOnly: {
                                name: this.$gettext("Force categorization of long stops only."),
                                value: false,
                                description: this.$gettext("If checked, system will only force long stops categorization."),
                                type: "bool",
                                filters: [this.$license.PerformanceModule],
                                readOnly: false,
                                dependent: true
                            },
                        }
                    },
                    oeeMeasurement: {
                        visible: this.$grants.get().settings.notifications,
                        groupName: this.$gettext("Performance calculation settings"),
                        filters: [this.$license.PerformanceModule],
                        settings: {
                            availabilityCalculationMode: {
                                name: this.$gettext("OEE Availability calculation base"),
                                options: [
                                    this.$gettext("Use standard opening time (configurable in line settings)"),
                                    this.$gettext("Use shift opening time"),
                                    this.$gettext("Use workorder scheduling")
                                ],
                                value: [0,1,2],
                                description: this.$gettext("Specify the criteria to determine if a line should be in production or not. The calculation is necessary to correctly calculate OEE. If standard opening time is used, system will only consider time included within the preconfigured availability periods. If shift is used, system will only consider moments where a shift is opened. If workorder mode is used, system will only calculate moments where a workorder is scheduled to be running on line. By selecting many options, system will consider a specific moment useful only if all selected conditions are met."),
                                type: "multiChecks",
                                readOnly: false,
                            }
                        }
                    },
                    other: {
                        visible: this.$grants.get().settings.notifications,
                        groupName: this.$gettext("Other settings"),
                        settings: {
                            defaultDashboard: {
                                name: this.$gettext("Default dashboard"),
                                description: this.$gettext("Dashboard to be automatically opened after login"),
                                type: "subgroup",
                                groupParentName: "other",
                                settings: {}
                            },
                            autoLogoffSeconds: {
                                name: this.$gettext("Automatic user log off"),
                                description: this.$gettext("Inactivity time (in seconds) after which user is automatically logged-off. Set to 0 to never log-off."),
                                type: "number",
                                value: 0
                            }
                        }
                    },
                    recipes: {
                        visible: this.$grants.get().settings.notifications,
                        groupName: this.$gettext("Recipes management"),
                        settings: {
                            requireDoubleActivationForRecipes: {
                                name: this.$gettext("Require double activation for recipes"),
                                value: true,
                                description: this.$gettext("If checked, system will require a double activation mechanism for recipes. Two different user profiles shall be granted for activation request and confirmation respectively"),
                                type: "bool",
                                readOnly: false,
                            }
                        }
                    }
                },
                profiles: []
            }
        },
        async mounted() {
            await this.loadDashboards();
            this.loadSettings();
        },
        computed: {
            forceCategorizationLongStopsVisibility() {
                return this.workflowSettings.timeTracking.settings.forceStopsCategorizationOnIdle.value ||
                    this.workflowSettings.timeTracking.settings.forceStopsCategorizationOnShiftClose.value;
            }
        },
        watch: {
            forceCategorizationLongStopsVisibility: {
                handler: function () {
                    this.setLongStopsOnlyCategorizationVisibility();
                },
                deep: true,
            }
        },
        methods: {
            setLongStopsOnlyCategorizationVisibility() {
                this.workflowSettings.timeTracking.settings.forceCategorizationLongStopsOnly.visible = this.forceCategorizationLongStopsVisibility;
                if (!this.workflowSettings.timeTracking.settings.forceCategorizationLongStopsOnly.visible)
                    this.$set(this.workflowSettings.timeTracking.settings.forceCategorizationLongStopsOnly, "value", false);
            },
             loadSettings() {
                 //Get settings and merge values on descriptor
                 this.settingsValues = this.$settings.getWorkFlowSettings();
                 if (Object.isUseful(this.settingsValues)) {
                     //This variable is used to create a copy of settings to keep his old values for auditing purposes
                     let values = this.$utils.detach(this.settingsValues);
                     //Merge settings values on descriptor
                     if (this.settingsValues) {
                         this.mergeSetting(this.workflowSettings.notifications.settings.lineStopped, values.lineStopped);
                         //this.mergeSetting(this.workflowSettings.notifications.settings.workorderCompleted, values.workorderCompleted);
                         this.mergeSetting(this.workflowSettings.timeTracking.settings.productionTimeThreshold, values.productionTimeThreshold);
                         this.mergeSetting(this.workflowSettings.timeTracking.settings.shortStopsThreshold, values.shortStopsThreshold);
                         this.mergeSetting(this.workflowSettings.timeTracking.settings.forceStopsCategorizationOnShiftClose, values.forceStopsCategorizationOnShiftClose);
                         this.mergeSetting(this.workflowSettings.timeTracking.settings.forceStopsCategorizationOnIdle, values.forceStopsCategorizationOnIdle);
                         this.mergeSetting(this.workflowSettings.timeTracking.settings.forceCategorizationLongStopsOnly, values.forceCategorizationLongStopsOnly);
                         this.mergeSetting(this.workflowSettings.timeTracking.settings.autoStopsCategorization, values.autoStopsCategorization);
                         this.profiles.forEach(profile => {
                             if (String.prototype.isString(values.defaultDashboard)) {
                                 this.mergeSetting(this.workflowSettings.other.settings.defaultDashboard.settings[profile["key"]], values.defaultDashboard);
                             } else {
                                 this.mergeSetting(this.workflowSettings.other.settings.defaultDashboard.settings[profile["key"]], values.defaultDashboard[profile["key"]]);
                             }
                         });
                         if(values.autoLogoffSeconds !== 0 && values.autoLogoffSeconds < 30)
                             values.autoLogoffSeconds = 30;
                         this.mergeSetting(this.workflowSettings.other.settings.autoLogoffSeconds, values.autoLogoffSeconds);
                         this.mergeSetting(this.workflowSettings.oeeMeasurement.settings.availabilityCalculationMode, (Object.isUseful(values.availabilityCalculationMode) ? values.availabilityCalculationMode : [0, 1, 2]));
                         this.mergeSetting(this.workflowSettings.recipes.settings.requireDoubleActivationForRecipes, values.requireDoubleActivationForRecipes);
                     }
                 }
                 this.setLongStopsOnlyCategorizationVisibility()
             },
             async valueChanged(groupName, settingName, actionObj, subGroup) {
                 if (this.settingsValues === null)
                    this.settingsValues = {};

                 let trail = this.$audits.getSettingChangedTrailObject(groupName, settingName, actionObj, this.settingsValues, this.workflowSettings, ["WorkflowSettings"], subGroup);

                 let values = this.$utils.detach(this.workflowSettings);
                 let self = this;

                 this.settingsValues.lineStopped = values.notifications.settings.lineStopped.value;
                 //this.settingsValues.workorderCompleted = values.notifications.settings.workorderCompleted.value;
                 this.settingsValues.productionTimeThreshold = parseInt(values.timeTracking.settings.productionTimeThreshold.value);
                 this.settingsValues.shortStopsThreshold = parseInt(values.timeTracking.settings.shortStopsThreshold.value);
                 this.settingsValues.forceStopsCategorizationOnShiftClose = values.timeTracking.settings.forceStopsCategorizationOnShiftClose.value;
                 this.settingsValues.forceStopsCategorizationOnIdle = values.timeTracking.settings.forceStopsCategorizationOnIdle.value;
                 this.settingsValues.forceCategorizationLongStopsOnly = this.settingsValues.forceStopsCategorizationOnShiftClose || this.settingsValues.forceStopsCategorizationOnIdle ? values.timeTracking.settings.forceCategorizationLongStopsOnly.value : false;
                 this.settingsValues.autoStopsCategorization = values.timeTracking.settings.autoStopsCategorization.value;
                 if(String.prototype.isString(this.settingsValues.defaultDashboard))
                     this.settingsValues.defaultDashboard = {};
                 this.profiles.forEach(profile => {
                     this.settingsValues.defaultDashboard[profile["key"]] = values.other.settings.defaultDashboard.settings[profile["key"]].value;
                 });
                 this.settingsValues.autoLogoffSeconds = parseInt(values.other.settings.autoLogoffSeconds.value);
                 if(this.settingsValues.autoLogoffSeconds !== 0 && this.settingsValues.autoLogoffSeconds < 30) {
                     this.settingsValues.autoLogoffSeconds = 30;
                     this.workflowSettings.other.settings.autoLogoffSeconds.value = 30;
                 }
                 this.settingsValues.availabilityCalculationMode = (Object.isUseful(values.oeeMeasurement.settings.availabilityCalculationMode.value) ? values.oeeMeasurement.settings.availabilityCalculationMode.value : [0, 1, 2]);
                 this.settingsValues.requireDoubleActivationForRecipes = values.recipes.settings.requireDoubleActivationForRecipes.value;
                 this.$settings.saveWorkFlowSettings(this.settingsValues)
                    .then(() => {
                        //check for avoiding empty "deleted" audits
                        if (!(actionObj.action === 'deleted' &&  typeof trail.previousVal === 'undefined')) {
                            self.$audits.save(trail.operator, trail.action, trail.previousVal, trail.nextVal, trail.actionId)
                                .catch(() => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                })
                        }
                    })
                    .catch(err => {
                        debugger
                        self.$root.showErrorNotification(self.$gettext("An error occurred while saving settings to DB"), true);
                        self.loadSettings();
                    })
            },
            mergeSetting(target, source) {
                if(Object.isUseful(source))
                    target.value = source;
            },
            async loadDashboards(){
                let self = this;
                self.profiles = self.$grants.getLevels();
                return new Promise(resolve => {
                    let counter = 0;
                    self.profiles.forEach((profile, i) => {
                        self.workflowSettings.other.settings.defaultDashboard.settings[profile["key"]] =
                            { name: this.$gettext(profile["show"]), type: "option", options: [], readOnly: false, value: function () { return this.options[0] }, index: this.$gettext(profile["key"]) }
                        let currentUserLevel = [];
                        self.$dashboards.getMetadataList(true, false, false)
                            .then((dashboards) => {
                                self.workflowSettings.other.settings.defaultDashboard.settings[profile["key"]].options = self.dashboardsByUserProfile(dashboards);
                                counter ++;
                                if (counter === self.profiles.length){
                                    resolve(true)
                                }
                            });
                    });
                })
            },
            dashboardsByUserProfile(dashboards) {
                let dashboardsToReturn = [""];
                for (let prop in dashboards) {
                    if (Object.prototype.hasOwnProperty.call(dashboards, prop)) {
                        if (Array.isArray(dashboards[prop]) && dashboards[prop].length > 0) {
                            dashboards[prop].forEach(dashboard => {
                                dashboardsToReturn.push(dashboard.name);
                            })
                        }
                    }
                }
                return dashboardsToReturn;
            }

        }
    }
</script>

<style scoped>

</style>