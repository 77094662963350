<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer"  class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card class="mt-0 av-card" elevation="0" style="height: 80px;">
            <v-flex xs12>
                <v-layout row wrap align-center>
                    <v-spacer></v-spacer>
                    <v-btn class="my-4" color="info" @click="handleHistoryBtn" :key="drawer">
                        <av-icon light small left>fa-history</av-icon>
                        {{ drawer? $gettext('Current version') : $gettext('History View') }}
                    </v-btn>
                    <av-button class="ma-4" :text="$gettext('BACK')" color="error" @click="goBack"
                               buttonIcon="fas fa-arrow-left" iconColor="light"/>
                </v-layout>
            </v-flex>
        </v-card>

        <template>
            <v-navigation-drawer enable-resize-watcher right fixed app  v-model="drawer">
                <v-list three-line>
                    <center  style="margin-bottom: 20px"><b><translate>Versions:</translate></b></center>
                    <template  v-for="(historyItem, index) in formHistory">
                        <v-list-tile :key="index" style="height: 100px"
                            :style="(entityDetails.version === historyItem.version) ? 'background-color: var(--av-lightblue); color: white;': ''"
                            @click="getDocumentForItem(historyItem.internal_name, historyItem.version)"
                        >
                            <v-list-tile-content class="mt-2">
                                <v-layout align-center row>
                                    <v-flex align-center>
                                        <v-list-tile-title :style="(entityDetails.version === historyItem.version) ? 'color: white;': ''"><translate>Version</translate>: {{historyItem.version}}</v-list-tile-title>
                                        <v-list-tile-sub-title :style="(entityDetails.version === historyItem.version) ? 'color: white;': ''"><translate>Modified By</translate>: {{ historyItem.modifiedBy }} </v-list-tile-sub-title>
                                        <v-list-tile-sub-title :style="(entityDetails.version === historyItem.version) ? 'color: white;': ''"><translate>Timestamp</translate>: {{ new Date(historyItem['@timestamp']).format() }} </v-list-tile-sub-title>
                                        <v-list-tile-sub-title :style="(entityDetails.version === historyItem.version) ? 'color: white;': ''"><translate>Form version</translate>: {{ historyItem.descriptor.FormVersion }} </v-list-tile-sub-title>
                                    </v-flex>
                                </v-layout>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-divider
                            v-if="index + 1 < formHistory.length"
                            :key="`divider-${index}`"
                        >
                        </v-divider>
                    </template>
                </v-list>
            </v-navigation-drawer>
        </template>
        <FormVisualizer v-if="form" :form="form" :show-close-button="false" class="mt-3" style="overflow: hidden; height: calc(100vh - 5px)"/>
        <!--<v-layout wrap row style="margin-top: 10px; margin-left: 30px; height: 100%;">-->
        <!--&lt;!&ndash;<v-flex style="max-width:500px">&ndash;&gt;-->
        <!--&lt;!&ndash;<table id="entity">&ndash;&gt;-->
        <!--&lt;!&ndash;<tr>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>Last edit</td>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>{{(new Date(this.$route.params.entity["@timestamp"])).format()}}</td>&ndash;&gt;-->
        <!--&lt;!&ndash;</tr>&ndash;&gt;-->
        <!--&lt;!&ndash;<tr>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>Editor</td>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>{{this.$route.params.entity.Operator}}</td>&ndash;&gt;-->
        <!--&lt;!&ndash;</tr>&ndash;&gt;-->
        <!--&lt;!&ndash;<tr>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>Entity</td>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>{{this.$route.params.entity.Name}}</td>&ndash;&gt;-->
        <!--&lt;!&ndash;</tr>&ndash;&gt;-->
        <!--&lt;!&ndash;<tr v-for="(val, index) in entityDetails">&ndash;&gt;-->
        <!--&lt;!&ndash;<template>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>{{ index }}</td>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>{{ typeof val == 'object' ? ( Array.isArray(val) ? val.toString() : entityDetails[index][entityDetails[index]["DbName"]]) : val }}</td>&ndash;&gt;-->
        <!--&lt;!&ndash;</template>&ndash;&gt;-->
        <!--&lt;!&ndash;</tr>&ndash;&gt;-->
        <!--&lt;!&ndash;<tr>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>Comments</td>&ndash;&gt;-->
        <!--&lt;!&ndash;<td>{{this.$route.params.entity.Annotation}}</td>&ndash;&gt;-->
        <!--&lt;!&ndash;</tr>&ndash;&gt;-->
        <!--&lt;!&ndash;</table>&ndash;&gt;-->
        <!--&lt;!&ndash;</v-flex>&ndash;&gt;-->
        <!--<v-spacer></v-spacer>-->
        <!--</v-layout>-->
    </v-container>
</template>
<script>

    import FormVisualizer from '@/components/dynamic-elements/forms/FormVisualizer'

    export default {
        name: 'entityDetails',
        components: {FormVisualizer},
        data() {
            return {
                entityDetails: {},
                entityKV: {},
                name: "",
                backPath:undefined,
                formHistory: [],
                drawer: false,
            }
        },
        mounted() {
            this.$root.setCurrentPageTitle(this.$gettext("Instance Details"));
            this.entityDetails = this.$route.params.entity;
            if(this.$route.params.backPath)
                this.backPath = this.$route.params.backPath;

            this.$dynamicElements.GetHistoryForIndexAndName("data_entityhistory", this.entityDetails.id)
                .then(item => {
                    this.formHistory = item
                })
        },
        computed: {
            form() {
                return this.entityDetails;
            },
        },
        methods:{
            goBack() {
                if(this.backPath){
                    this.$router.push(this.backPath)
                }else{
                    this.$router.go(-1)
                }
            },
            getDocumentForItem(name, version) {
                this.$dynamicElements.DescriptorForIndexAndVersion("data_entityhistory", name, version)
                    .then(item => {
                        this.entityDetails = item[0].descriptor;
                    })
            },
            handleHistoryBtn() {
                if (this.drawer && Array.isUseful(this.formHistory))
                    this.getDocumentForItem(this.formHistory[0].internal_name, this.formHistory[0].version)
                this.drawer = !this.drawer;
            }
        }
    }
</script>

<style scoped>
    .v-text-field >>> .v-counter {
        margin-top: 5px;
    }

    #entity {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        font-size: 18px;
    }

    #entity td {
        border: 1px solid #ddd;
        padding: 8px;
    }

    #entity tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    #entity tr:hover {
        background-color: #ddd;
    }

</style>
