<template>
    <DashboardContainer embedded-dash="builtin-Validation dashboard" />
</template>

<script>

    import DashboardContainer from "./dashboards/DashboardContainer";

    export default {
        name: "Validation",
        components: {
            DashboardContainer
        },
    }

</script>

<style scoped>

</style>