<template>
    <v-layout column align-center>
        <h2 class="text-xs-center mt-4">{{$gettext("UI version '{0}' is different from application version '{1}'").format(frontEndVersion, appVersion)}}</h2>
        <h2 class="text-xs-center mt-4" translate>Please press CTRL + F5 or clear browser cache</h2>
    </v-layout>
</template>

<script>

import Vue from "vue";

export default {
    name: "VersionMismatchPage",
    data: () => ({
        appVersion: "",
        frontEndVersion: ""
    }),
    mounted: function () {
        Vue.prototype.$store.dispatch('setSidebarVisibility', false);
        Vue.prototype.$store.dispatch('setHeaderVisibility', false);
        Vue.prototype.$store.dispatch('setToolbarVisibility', false);
        this.$root.setCurrentPageTitle("Version mismatch");
        this.appVersion = this.$route.query.appVersion;
        this.frontEndVersion = this.$route.query.frontEndVersion;
    }
}
</script>

<style scoped>

</style>

