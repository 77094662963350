<template>
    <FormEntity />
</template>
<script>
    import FormEntity from '@/components/dynamic-elements/forms/FormEntity.vue';

    export default {
        name: "FormAsset",
        extends: FormEntity,
        components: {
            FormEntity
        },
        data() { return {
            optionsByParent: {},
        } },
        computed: {
            formVariablesAndOptions() {
                return this.formVariables && this.optionsByParent;
            }
        },
        mounted() {
            this.formConfigurationMode.requiresJoin = false;
            this.formConfigurationMode.requiresPrimaryKey = false;
            this.formConfigurationMode.requiresTargetEntity = false;
            this.formConfigurationMode.requiresValueAsCategory = false;
            // this.childHandlers.canBeDeleted = this.canBeDeleted;
            // this.childHandlers.onDeploy = this.onDeploy;
            // this.childHandlers.onUnDeploy = this.deactivateAllInstances;
            let self = this;
            this.childHandlers.getDefaultName = function() { return self.$gettext("Asset") };
            this.childHandlers.onNewElementCreated.push(this.onNewAsset);
            this.childHandlers.onElementLoaded.push(this.onOpenAsset);
            this.properties.sidebarVisibilityLink = true;
            this.systemEntity = "Asset";
            this.exposedName = "Assets";
            this.$devices.getAll()
                .then(result => {
                    self.optionsByParent = self.structureLinesDevices(result);
                })
                .catch(err => {
                    console.log(err)
                });
        },
        watch: {
            visualizationTweaks: {
                handler: function () { //TODO check if needed
                    this.saveTweaks()
                },
                deep: true,
            },
            formVariablesAndOptions: {
                handler: function () {
                    if (Array.isUseful(this.formVariables) && !Object.isEmpty(this.optionsByParent)) {
                        const linkedLine = this.formVariables.findItemByKeyRecursive('name', 'LinkedLine');
                        linkedLine.items[linkedLine.itemIndex].options = Object.keys(this.optionsByParent);
                        const linkedMachines = this.formVariables.findItemByKeyRecursive('name', 'LinkedMachines');
                        linkedMachines.items[linkedMachines.itemIndex].options = this.optionsByParent;
                    }
                }
            }
        },
        methods: {
            setAppropriateTweaks() {
                this.setTweakValue("target", 1003);
                for (let tweak of this.visualizationTweaks) {
                    tweak.hidden = !(tweak.id === 'ImportTemplate');
                }
            },
            onOpenAsset() {
                this.setAppropriateTweaks();
                //V5.0.0 update we changed the behaviour of variables so that now all user defined variables
                //are deletable. This required breaking the logic that was previously locking the system variables
                //This snippet will update definition and lock system variables
                let systemVariableLocker = function(variables) {
                    for(let variable of variables) {
                        if(variable.locked && (!variable.editableName || !variable.editableType))
                            variable.undeletable = true;
                        if(Array.isUseful(variable.children))
                            systemVariableLocker(variable.children);
                    }
                };
                systemVariableLocker(this.formVariables)
            },
            onNewAsset() {
                this.setAppropriateTweaks();
                this.formVariables = [
                    {
                        name: "assetId",
                        title: this.$gettext("Asset identifier"),
                        description: this.$gettext("Provide a unique identification name for the asset"),
                        inputMode: "Normal",
                        type: "Text",
                        mandatory: true,
                        primaryCharacteristic: true,
                        isRootVariable: true,
                        isGlobalRootVariable: true,
                        uniquenessLevel: "entity",
                        editableName: false,
                        editableType: false,
                        undeletable: true,
                        value: "",
                        children: [
                            {
                                name: "LinkedLine",
                                title: this.$gettext("Linked line"),
                                description: this.$gettext("Choose lines for asset binding"),
                                inputMode: "Normal",
                                type: "Multi options",
                                options: [],
                                editableName: false,
                                editableType: false,
                                disableOptions: true,
                                children: [
                                    {
                                        name: "LinkedMachines",
                                        title: this.$gettext("Linked machines"),
                                        description: this.$gettext("Choose machines for asset binding"),
                                        inputMode: "Normal",
                                        type: "Multi options",
                                        options: [],
                                        editableName: false,
                                        editableType: false,
                                        disableOptions: true,
                                        linkedToParent: "LinkedLine",
                                        optionsByParent: {}
                                    }
                                ],
                            },
                            {
                                name: "assetDescription",
                                title: this.$gettext("Asset description"),
                                description: this.$gettext("Provide a brief description of the asset"),
                                inputMode: "Normal",
                                type: "Text",
                                children: [],
                                value: "",
                                editableName: false,
                                editableType: false
                            }
                        ]

                    },
                ];
                this.$emit('dataItemsUpdated');
            },
            groupBy(list, keyGetter) {
                const map = new Map();
                list.forEach((item) => {
                    const key = keyGetter(item);
                    const collection = map.get(key);
                    if (!collection) {
                        map.set(key, [item.device]);
                    } else {
                        collection.push(item.device);
                    }
                });
                return map;
            },
            structureLinesDevices(result) {
                return Object.fromEntries(this.groupBy(result, result => result.line));
            }
        },
    }

</script>

<style scoped>
</style>
