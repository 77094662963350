<template>
  <v-textarea :id="computedID" v-bind="$attrs" :error-message="computedMessage" v-on="$listeners" :label="labelText" :hint="translatedHint">
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
  </v-textarea>
</template>
<script>
export default {
  name: 'av-textarea',
  props: {
    translate: { required: false, default: true },
    readonly: { required: false, default: false },
    dynamicID: { required: false, default: '' },
  },
  data () {
    return {}
  },
  computed: {
    computedID () {
      if (!this.$isEmpty(this.$attrs.id)) {
        return this.$attrs.id
      }
      let dynamicPageName = this.$router.history.current.params.routeId ? this.$router.history.current.params.routeId : "";
      return this.toCamelCase(this.$router.history.current.name + dynamicPageName + this.dynamicID + 'TextArea' + this.$attrs.label.trim())
    },
    translatedHint () {
      if (!this.translate) {
        return this.$attrs.hint
      }
      return this.$attrs.hint
    },
    labelText () {
      if (!this.translate) {
        return this.$attrs.label
      }
      return this.$attrs.label
    },
    computedMessage () {
      if (!this.$isEmpty(this.$attrs['error-message'])) {
        if (this.translate) {
          return this.$attrs['error-message']
        } else {
          return this.$attrs['error-message']
        }
      }
      return ''
    }
  },
  mounted () {
  },
  methods: {}
}
</script>
