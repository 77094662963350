//import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/brands.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import AvColors from '@/api/avpalette'

// Import Vuetify built-in translations
// Available are: af - Afrikaans (Afrikaans) , ar - Arabic (اللغة العربية) , ca - Catalan (català) , de - German (Deutsch) ,
//                el - Greek (ελληνικά) , en - English , es - Spanish (Español) , et - Estonian (eesti) , fa - Persian (فارسی) ,
//                fr - French (Français) , hr - Croatian (hrvatski jezik) , hu - Hungarian (magyar) , id - Indonesian (Indonesian) ,
//                it - Italian (Italiano) , ja - Japanese (日本語) , nl - Dutch (Nederlands) , pl - Polish (język polski) ,
//                pt - Portuguese (Português) , ro - Romanian (limba română) , ru - Russian (Русский) , sl - Slovene (slovenski jezik) ,
//                srCyrl - Serbian (српски језик) , th - Thai (ไทย) , tr - Turkish (Türkçe) , uk - Ukrainian (Українська) ,
//                zhHans - Chinese (中文) , zhHant - Chinese (中文)
import en from 'vuetify/es5/locale/en'
import de from 'vuetify/es5/locale/de'
import it from 'vuetify/es5/locale/it'
import fr from 'vuetify/es5/locale/fr'
import pt from 'vuetify/es5/locale/pt'
import ru from 'vuetify/es5/locale/ru'
import es from 'vuetify/es5/locale/ru'

Vue.use(Vuetify, {
  lang: {
    locales: { en, de, it, fr, pt, ru, es },
    current: 'en'
  },
  theme: {
    primary: AvColors.colors.red,
    secondary: AvColors.colors.background,
    accent: AvColors.colors.lightblue,
    error: AvColors.colors.red,
    info: AvColors.colors.lightblue,
    success: AvColors.colors.green,
    warning: AvColors.colors.orange,
  },
  iconfont: 'fa'
});