<template>
    <v-expansion-panel style="margin-bottom: 10px" class="dense" expand>
        <v-expansion-panel-content class="px-2">
            <template v-slot:header>
                <div class="subheading pa-0 ma-0" style="width: 90%;" >Data transformations ({{normalizationsCount}})</div>
            </template>
            <v-btn v-if="!hasNormalizations" color="info" class="subheading" @click="addNormalization()">
                <av-icon left light>fas fa-plus-circle</av-icon>
              {{$gettext('Add transformation')}}
            </v-btn>
            <template v-else v-for="(normalization, normalizationIndex) in normalizations">
                <v-layout row style="width: 100%" :justify-space-around="!normalization.large" :justify-space-between="normalization.large" fill-height align-center>
                    <v-select style="max-width: 200px; margin-right: 20px"  pa-0 :items="normalizatorsTypes" :value="normalization.type" @change="setNormalizationType(normalizationIndex, $event)"/>
                    <v-text-field v-if="!normalization.large" v-for="(arg, index) in normalization.args" class="ml-4" single-line :number="arg==='int'" v-model="normalization.argsValues[index]" @change="onChange" />
                    <div style="width: 50px; margin-left: 20px">
                        <v-btn v-if="normalizationIndex === normalizations.length - 1" fab small color="info" class="ma-1" @click="addNormalization()">
                            <av-icon light>fas fa-plus</av-icon>
                        </v-btn>
                    </div>
                    <div style="width: 50px">
                        <v-btn fab small color="error" class="ma-1" @click="normalizations.removeAt(normalizationIndex), onChange">
                            <av-icon light>fas fa-trash</av-icon>
                        </v-btn>
                    </div>
                </v-layout>
                <v-text-field v-if="normalization.large" v-for="(arg, index) in normalization.args" placeholder="Expression:" class="ml-4" single-line :number="arg==='int'" v-model="normalization.argsValues[index]" @change="$emit('change')" />
            </template>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>

    import Normalizations from "@/api/normalizations"

    export default {
        name: "DataNormalizationBuilder",
        props: {
            normalizations: null,
            item: null,
            aggregation: null
        },
        computed: {
            hasNormalizations() {
                return Array.isUseful(this.normalizations);
            },
            normalizatorsTypes() {
                return Normalizations.getNormalizationsTypes(this.item, this.aggregation);
            },
            normalizationsCount() {
                if(this.hasNormalizations)
                    return this.normalizations.length;
                return 0;
            }
        },
        methods: {
            getNormalizator(type) {
                return Normalizations.getNormalizator(type)
            },
            getDefaultNormalization() {
                return this.getNormalizator(this.normalizatorsTypes[0].value);
            },
            addNormalization() {
                if(!Object.isUseful(this.normalizations))
                    this.normalizations = [];
                this.normalizations.push(this.getDefaultNormalization());
                this.onChange();
            },
            setNormalizationType(index, type) {
                let self = this;
                if(Array.isUseful(this.normalizations[index].argsValues))
                    this.$root.showDialogBox(this.$gettext("By changing function you will lose current configuration. Are you sure you want to change it?"), null, "Yes", () => { self.setNormalizationTypeConfirm(index, type) }, "No", () => { self.restoreNormalization(index) } );
                else this.setNormalizationTypeConfirm(index, type);
            },
            restoreNormalization(index) {
                let prevNormalization = this.normalizations[index].type;
                this.normalizations[index].type = null;
                this.$nextTick(() => {
                    this.normalizations[index].type = prevNormalization;
                });
            },
            setNormalizationTypeConfirm(index, type) {
                let normalizator = this.getNormalizator(type);
                this.normalizations[index].type = normalizator.type;
                this.normalizations[index].args = normalizator.args;
                this.normalizations[index].argsValues = normalizator.argsValues;
                this.normalizations[index].expression = normalizator.expression;
                this.normalizations[index].large = normalizator.large;
                this.normalizations[index].applyTo = normalizator.applyTo;
                this.normalizations[index].sourceValue = normalizator.sourceValue
                this.onChange();
            },
            onChange() {
                //this.$emit('change');
            }
        }
    }
</script>

<style scoped>
    .dense >>> .v-expansion-panel__header {
        margin: 0;
        padding: 0;
        height: 44px !important;
        min-height: 40px;
    }
</style>