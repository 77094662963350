<template>
    <RuleBase/>
</template>

<script>

    import RuleBase from '@/components/dynamic-elements/rules/RuleBase.vue';

    export default {
        name: "RuleTransaction",
        extends: RuleBase,
        components: {
            RuleBase
        },
        data() {
            return {
            }
        },
        computed: {
            indices() {
                return this.properties.dataPatterns;
            }
        },
        mounted() {
            this.fixedHeight = false;
            this.showDataFlow = false;
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["data_tracking"], false));
            this.dataExplorationMode.autoDataSourcing = false;
            this.dataExplorationMode.deviceSelectMode = false;
            this.dataExplorationMode.documentSelectMode = true;
            this.dataExplorationMode.requiresCrossAggregation = false;
            this.dataExplorationMode.requiresFunctions = false;
            this.dataExplorationMode.requiresDataSelection = false;
            this.dataExplorationMode.requiresDataFiltering = false;
            this.dataExplorationMode.rulesExecutionSchedulingMode.required = false;
            this.dataExplorationMode.noPreview = true;
            this.properties.ruleScheduling.cron = "triggered";
            this.temporaryHideTimeWindows = true;
            this.childHandlers.inLineDataTransformer = this.showAsTime;
            // For future implementations
            this.visualizationTweaks = [{
                    name: this.$gettext("Persistent rule result"),
                    id: "CacheResult",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                }
            ];
        },
        watch: {
            indices() {
            },
            visualizationTweaks: {
                handler: function () {
                    this.saveTweaks();
                },
                deep: true,
            },
            outputs: {
                //Calculate return characteristic of rule to show flow alerts in rules management
                handler: function () {
                    let returnMode = 0; //No returns, rule is pass through
                    let triggeredRules = {};
                    for(let output of this.outputs) {
                        if(output.outputType === "redirect") {
                            if(!output.trigger) {
                                returnMode = 1; //Hard return, it's unconditional
                                break;
                            }
                            else {
                                if(!Object.isUseful(triggeredRules[output.trigger.name]))
                                    triggeredRules[output.trigger.name] = { onTrue: false, onFalse: false };
                                if(output.triggerMode === "onTrue")
                                    triggeredRules[output.trigger.name].onTrue = true;
                                else if(output.triggerMode === "onFalse")
                                    triggeredRules[output.trigger.name].onFalse = true;
                                if(triggeredRules[output.trigger.name].onTrue && triggeredRules[output.trigger.name].onFalse) {
                                    returnMode = 2; //Hard return, but due to bad configuration TODO filter this condition at outputs config level
                                    break;
                                }
                                else if(triggeredRules[output.trigger.name].onTrue || triggeredRules[output.trigger.name].onFalse) {
                                    returnMode = 3; //May return in some conditions
                                }
                            }
                        }
                    }
                    this.scope().properties.additionalMetaData = { returnMode: returnMode};
                },
                deep: true,
            }
        },
        methods: {
        }
    }

</script>

<style scoped>
    table th { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table td { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table tr { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
</style>