
import Defines from './defines'
import Utils from './jsutils'
import Data from './data'

export default {
    getFunctionQuery(rawFunction) {
        let dataItem = null;
        let aggregationWindow = null;
        for(let i = 0 ; i < rawFunction.parameters.length ; i++) {
            if(rawFunction.parameters[i].type === "dataItem") {
                dataItem = Utils.detach(rawFunction.parameters[i].dataItem);
                dataItem.representations = [];
                dataItem.representations.push(Data.getDefaultDataRepresentation(dataItem));
                dataItem.representations[0].type = Defines.allAggregations["stats.std_deviation"].id;
                dataItem.representations[0].aggregationWindow = rawFunction.aggregationWindow;
                dataItem.representations[0].aggregationWindowUnit = rawFunction.aggregationWindowUnit;
                if(rawFunction.type === Defines.allFunctions.cpk.id) {
                    dataItem.representations.push(Data.getDefaultDataRepresentation(dataItem));
                    dataItem.representations[1].type = Defines.allAggregations.avg.id;
                    dataItem.representations[1].aggregationWindow = rawFunction.aggregationWindow;
                    dataItem.representations[1].aggregationWindowUnit = rawFunction.aggregationWindowUnit;
                }
                rawFunction.parameters.removeAt(i);
                i--;
            } else if(rawFunction.parameters[i].type === "aggregationWindow") {
                aggregationWindow = rawFunction.parameters[i].value;
            }
        }
        if(!dataItem)
            return null;
        let query = Data.getDataQueryDescriptor([ dataItem ]);
        if(query && Array.isUseful(query.agg)) {
            rawFunction.parameters.push({
                type: "agg_query",
                name: "sigma",
                query: query.agg[0]
            });
            if(query.agg.length === 2 && rawFunction.type === Defines.allFunctions.cpk.id) {
                rawFunction.parameters.push({
                    type: "agg_query",
                    name: "average",
                    query: query.agg[1]
                });
            }
        }
        return rawFunction;
    },
    getFunctionQueryStatMerge(rawFunction) {
        let dataItem = [];
        let aggregationWindow = null;
        for(let i = 0 ; i < rawFunction.parameters.length ; i++) {
            rawFunction.parameters[i].type = "raw_query"
            let query = Data.getDataQueryDescriptor([rawFunction.parameters[i].dataItem]);
            delete rawFunction.parameters[i].dataItem
            if(query && Array.isUseful(query.raw)) {
                rawFunction.parameters[i].query = query.raw[0]
            }
        }
        return rawFunction;
    }
}