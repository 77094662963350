<template>
    <div>
        <canvas :id=id></canvas>
        <v-btn v-if="zoomOrPanned" @click="resetVisualization(), zoomOrPanned = false" fab style="position: absolute; top: 100px; left: 40px;"><av-icon color="blue">fas fa-undo</av-icon></v-btn>
    </div>
</template>
<script>
import {Chart, registerables} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';
import ChartDataLabels from "chartjs-plugin-datalabels";


export default {
    name: "LineChart",
    props: {
        chartdata: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            id: Date.now(),
            chart: undefined,
            chartData: {
                type: "line",
                data: this.chartdata,
                options: this.options,
                plugins: this.plugins
            },
            zoomOrPanned: false,
            hiddenDatasets:[] //FN used to manage the correct dataset's visibility state
        }
    },
    created() {
        Chart.register(...registerables);
        Chart.register(zoomPlugin);
        Chart.register(ChartDataLabels);
        Chart.register({
            id: 'zoomCSS',
            beforeEvent(chart, ctx) {
                // let bounds = ctx.event.native.target.getBoundingClientRect();
                // let correctionX = bounds.left - bounds.left * 0.8;
                // let correctionY = bounds.top - bounds.top * 0.8;
                // ctx.event.native.x = (ctx.event.native.x + correctionX) * 1.25;
                // ctx.event.native.y = (ctx.event.native.y + correctionY) * 1.25;
                ctx.event.x = ctx.event.x * 1.25
                ctx.event.y = ctx.event.y * 1.25
            }
        });

    },
    methods: {
        refreshGraph() {
            let self = this;
            this.chartData.data = this.chartdata
            this.chartData.options = this.options
            this.chartData.plugins = this.plugins

            //FN Update the Dataset visibility  to  the correct state selected   
            for(let i=0; i < self.hiddenDatasets.length; i++) {
                //FN check if the dataset is initialized
                 if(Object.isUseful(this.chart.getDatasetMeta(i).controller)) {
                    this.chart.setDatasetVisibility(self.hiddenDatasets[i], false);
                 }
            }

            this.chart.update()
        },
        resetVisualization() {
            this.chart.resetZoom();
        },
        createChart() {
            let self = this

            if (self.chartData.options.plugins.legend){
                self.chartData.options.plugins.legend.onClick = function (e, legendItem, legend) {
                    const index = legendItem.datasetIndex;
                    const ci = legend.chart;
                    if (ci.isDatasetVisible(index)) {
                        ci.hide(index);
                        legendItem.hidden = true;
                        if(!self.hiddenDatasets.includes(index)) {
                            self.hiddenDatasets.push(index);
                        }
                    } else {
                        ci.show(index);
                        legendItem.hidden = false;
                        self.hiddenDatasets = self.hiddenDatasets.filter(item => item !== index)
                    }
                    ci.update();
                }
            }

            //add zoom
            this.chartData.options.plugins.zoom = {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    onZoomComplete: function () {self.zoomOrPanned = true},
                    pinch: {
                        enabled: true
                    },
                },
                pan: {
                    enabled: true,
                    onPanComplete: function () {self.zoomOrPanned = true},
                    mode: 'xy',
                },

            }
            this.chart = new Chart(document.getElementById(this.id), this.chartData)
        }
    },

    mounted() {
        this.createChart()
        this.chart.render()
    },

    beforeDestroy() {
        if (this.chart)
            this.chart.destroy()
    },
    watch: {
        data: {
            handler(n,o){
                this.chartData.data = n
                this.chart.render()
            }
        },
        options: {
            handler(n,o) {
                this.chartData.options = n
                this.chart.render()
            }
        },
        plugins: {
            handler(n,o) {
                this.chartData.plugins = n
                this.refreshGraph()
            }
        },
    }
}
</script>
