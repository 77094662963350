<template>
    <PopUpRadioGroupPicker :dialog="true" :options="options" :value="value" icon="fas fa-calendar-week" @change="onChanged($event)"/>
</template>

<script>

    import PopUpRadioGroupPicker from '@/components/utilities/PopUpRadioGroupPicker'

    export default {
        name: "PopUpDayOfWeekPicker",
        components: { PopUpRadioGroupPicker },
        data () {
            return {
                options: [this.$gettext("Monday"),this.$gettext("Tuesday"),this.$gettext("Wednesday"),this.$gettext("Thursday"),this.$gettext("Friday"),this.$gettext("Saturday"),this.$gettext("Sunday")],
            }
        },
        props: {
            value:  [Number],
        },
        methods: {
            onChanged(value) {
                this.$emit('input', value);
                this.$emit('change', value);
            }
        }
    }
</script>

<style scoped>

</style>