<template>
    <v-container id="wizardConfigurator" ref="wizardConfigurator" class="ma-0 pa-0 page-container" style="margin-left: 4px!important; padding-right:8px!important; max-width:100% !important;">
        <av-split-pane style="overflow: hidden; width: 100%" :min-percent='10' :default-percent='40' split="vertical">
            <template slot="paneL">
                <v-card class="ma-0 pa-0 av-card" elevation="0" style="height: calc(100% - 8px); overflow: auto; margin-right: 4px!important;" >
                    <v-card-actions>
                        <v-layout column>
<!--                            <av-multi-select class="mt-3" v-model="filterByFlows" :label="$gettext('Filter by Flows')"-->
<!--                                             item-text="Show" :placeholder="$i18n.CommonAttributes()['select']"-->
<!--                                             :items="flows"></av-multi-select>-->
                            <av-button :text="addBtnText" style="float: right;" flat @click="addStep()" id="AddBtn" size="medium" iconColor="info" buttonIcon="fas fa-plus" />

                        </v-layout>
                    </v-card-actions>
                        <div v-for="(item, key) in wizard.steps" :key="key">
                                <av-button iconColor="info" buttonIcon="fas fa-edit" fab small flat :dynamicID="item.name"
                                           @click="edit(item)" :toolTip="$gettext('Edit configuration')"
                                           class="my-0" :style="Array.isUseful(item.configuration) ? 'margin-left: 8px' : ''"/>
                                <av-button iconColor="info" buttonIcon="fas fa-copy" fab small flat
                                           @click="cloneStep(item)" style="margin-left: -8px" class="my-0"
                                           :toolTip="$gettext('Clone step')"/>
                                <av-button iconColor="error" buttonIcon="fas fa-trash" fab small flat :dynamicID="item.name"
                                           @click="deleteStep(key)" style="margin-left: -8px" class="my-0"
                                           :toolTip="$gettext('Delete field')"/>
                                <span class="body-1">{{ item.name }}</span>
                        </div>
                </v-card>
            </template>
            <template slot="paneR">
                <v-card class="ma-0 pa-2 av-card" elevation="0" style="height: calc(100% - 10px); overflow: auto; margin-left: 4px!important" >
                    <template v-if="selectedItem">
                        <v-layout row>
                            <v-flex xs4>
                                <av-select class="mt-3" :label="$gettext('Type')"
                                           item-text="show" :placeholder="$i18n.CommonAttributes()['select']"
                                           :items="configurationTypes" v-model="selectedItem.type">
                                </av-select>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex xs7>
                                <av-text-field class="mt-3" v-model="selectedItem.name" :label="$gettext('Name')"></av-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <av-text-field v-model="selectedItem.description" :label="$gettext('Description')"></av-text-field>
                        </v-layout>
                        <v-layout row>
                            <av-select v-if="selectedItem.type === 'form'" class="mt-3" :label="$gettext('Form')"
                                       item-text="show" :placeholder="$i18n.CommonAttributes()['select']"
                                       :items="forms" v-model="selectedItem.configuration.name">
                            </av-select>
                        </v-layout>
                    </template>
                </v-card>
            </template>
        </av-split-pane>
    </v-container>

</template>

<script>
    export default {
        name: "WizardConfigurator",
        data: () => {
            return {
                selectedItem: null,
                configurationTypes:["form", "mediaContent", "rule", "process"],
                forms: [],
            }
        },
        computed: {
            addBtnText() {
                return this.$gettext("Add Step");
            }
        },
        props: {
            wizard: {
                required: true,
                default:() => { return {} }
            },
        },
        mounted() {
            this.init();
            if (!this.wizard.hasOwnProperty("steps"))
                this.$set(this.wizard, "steps", []);
            if (Array.isUseful(this.wizard.steps))
                this.selectedItem = this.wizard.steps[0];
        },
        methods: {
            init() {
                //Load forms (step type form) for user to be able to choose
                this.$dynamicElements.getMetadataList("forms", true,false,false,true)
                    .then((forms) => {
                        this.forms = forms.ungrouped.map(f => f.name);
                    });
            },
            addStep() {
                let step = {
                    type: "",
                    name: "",
                    description: "",
                    configuration: {}
                }
                this.wizard.steps.push(step);
            },
            deleteStep(key) {
                this.wizard.steps.splice(key, 1);
            },
            cloneStep(step) {
                let newStep = this.$utils.detach(step);
                newStep.name = "Clone of " + step.name;
                this.wizard.steps.push(newStep);
            },
            edit(item) {
                this.selectedItem = item;
            },
        }

    }
</script>

<style scoped>

</style>