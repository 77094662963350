<template>
    <v-layout align-start justify-start column :class="isChild ? 'pa-0 ml-4' : 'px-4 py-2'" :style="layoutStyle">
        <template v-for="(group, groupName) in descriptor">
            <template v-if="visible(group) || isChild">
                <v-flex  xs2 class="mt-4">
                    <label v-if="group.groupName" class="headline font-weight-bold" style="margin-top: 40px">{{group.groupName}}</label>
                    <v-btn v-if="group.groupName && group.isDeletable" style="margin-left: 20px; margin-top: 0" fab small color="error" @click="descriptor.splice(groupName,1), valueChanged(groupName,group.groupName, { action: 'deleted', item: groupName })">
                        <av-icon light>fas fa-trash</av-icon>
                    </v-btn>
                </v-flex>
                <hr v-if="group.groupName" style="height: 1px; background-color: black; width: 100%"/>
                <template v-for="(setting, settingName) in group.settings">
                    <template v-if="visible(setting)">
                        <v-layout row align-start justify-start mt-4 pt-2 ml-5 :style="'width: 90%'">
                            <v-flex xs8 style="text-align: left">
                                <v-layout :row="dense" :column="!dense" style="width: 100%" :class="setting.dependent ? 'ml-5' : ''">
                                    <v-layout row>
                                        <label :style="'width: ' + (dense ? '30%' : '')" :class="isChild ? 'subheading font-weight-bold' : 'title'">{{setting.name}}</label>
                                        <v-tooltip v-if="setting.copyPaste && $utils.isValorized(setting.value)" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn fab small v-on="on"
                                                       style="margin-top: -10px; margin-left: 24px" @click="copied=setting">
                                                    <av-icon color="info" light>fas fa-copy</av-icon>
                                                </v-btn>
                                            </template>
                                            <span>Copy value</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="setting.copyPaste && copied && copied.type === setting.type" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn fab small v-on="on"
                                                       style="margin-top: -10px; margin-left: 8px" @click="pasteValue(setting ,copied), valueChanged((isChild ? group : groupName), settingName, { action: 'modified', item: '' })"><!--{ action: 'modified', item: (copied.value.length -1) }, true)"> -->
                                                    <av-icon color="green" light>fas fa-paste</av-icon>
                                                </v-btn>
                                            </template>
                                            <span>Paste value</span>
                                        </v-tooltip>
                                        <v-spacer></v-spacer>
                                    </v-layout>
                                    <ImportExportExcel v-if="setting.type === 'keyValuePair' && (setting.hasOwnProperty('enableImportExportExcel') ? setting.enableImportExportExcel : true)" :text-icon-visible="true" :horizontal="true" :headers="keyValuesExportHeaders(setting)" :data-collection="setting.value" :work-sheet="setting.name" :file-name="setting.name + (new Date()).format('yyyyMMddHHmmss')" @fileLoaded="keyValuesImported(groupName, settingName, setting, { action: 'loaded', item: $event })"/>
                                    <div :style="'padding-left: 20px; padding-top:' + (dense ? '0px; margin-top: -8px; width:70%;' : '16px')">
                                        <v-text-field v-if="setting.type === 'string'" class="osk" :readonly="setting.readOnly" :clearable="!setting.readOnly"
                                                      single-line :placeholder="$i18n.CommonAttributes()['type here']" v-model="setting.value"
                                                      @change="valueChanged(groupName,settingName, { action: 'modified', item: '' })" style="max-width: 400px"/>
                                        <v-text-field v-if="setting.type === 'password'" class="osk"  type="password"  autocomplete="current-password"  :readonly="setting.readOnly" :clearable="!setting.readOnly"
                                                      single-line :placeholder="$i18n.CommonAttributes()['type here']" v-model="setting.value"
                                                      @change="valueChanged(groupName,settingName, { action: 'modified', item: '', type: setting.type })" style="max-width: 400px"/>
                                        <v-switch v-if="setting.type === 'bool'" :readonly="setting.readOnly"
                                                  v-model="setting.value" @change="valueChanged(groupName,settingName, { action: 'modified', item: '' })"/>
                                        <v-select v-if="setting.type === 'option'" :readonly="setting.readOnly" :multiple="setting.multiple" :chips="setting.multiple"
                                                  :placeholder="$i18n.CommonAttributes()['select']" :items="setting.options" v-model="setting.value"
                                                  @change="valueChanged((isChild ? group : groupName),settingName, { action: 'modified', item: '', value: setting.value })" style="max-width: 400px"/>
                                        <v-combobox v-if="setting.type === 'editableOption' && visible(setting)" return-object :placeholder="$i18n.CommonAttributes()['select']" :items="setting.options" v-model="setting.value"
                                                    @change="valueChanged(groupName,settingName, { action: 'modified', item: '' })" style="max-width: 400px"/>
                                        <v-text-field v-if="setting.type === 'number'"  single-line :readonly="setting.readOnly" class="osk"
                                                      v-model.number="setting.value" @change="valueChanged(groupName,settingName, { action: 'modified', item: '' })" type="number"
                                                      style="max-width: 400px" append-outer-icon="add"
                                                      @click:append-outer="(!Object.isUseful(setting.max) || setting.value < setting.max) ? (setting.value++, valueChanged(groupName,settingName, { action: 'modified', item: '' })) : ''"
                                                      prepend-icon="remove"
                                                      @click:prepend="(!Object.isUseful(setting.min) || setting.value > setting.min) ? (setting.value--, valueChanged(groupName,settingName, { action: 'modified', item: '' })) : ''"/>
                                        <v-checkbox v-if="setting.type === 'multiChecks'"
                                                    v-for="(option, index) in setting.options" :readonly="setting.readOnly"
                                                    v-model="setting.value" :label="option" :value="index"
                                                    @change="valueChanged(groupName,settingName, { action: 'modified', item: index })"/>
                                        <template v-if="setting.type === 'keyValuePair'">
                                            <table style="width: 100%;">
                                                <tr>
                                                    <td>{{setting.keysLabel}}</td>
                                                    <td>{{setting.valuesLabel}}</td>
                                                </tr>
                                                <tr v-for="(pair, index) in setting.value">
                                                    <td style="padding: 5px">
                                                        <av-autocomplete v-if="!setting.customAction || settingName === 'standardVariables'"
                                                                         :placeholder="$i18n.CommonAttributes()['type here']"
                                                                         style="max-width: 400px"
                                                                         :numItems="(setting.value).length" :readonly="setting.readOnly"
                                                                         :selectOnly="setting.keySelectOnlyKeys"
                                                                         :avoidDuplicateItems="setting.avoidDuplicateItems"
                                                                         :items="setting.avoidDuplicateItems ? getNotDuplicatedItems(setting.keysAutoComplete, setting.value, pair.key, 'key') : setting.keysAutoComplete" :noMatchErrorMessage="setting.noMatchError" wildCardMatch="true" :value="pair.key"
                                                                         @change="pair.key=$event, valueChanged(groupName,settingName, { action: 'modified', item: index })" />
                                                        <v-layout v-else v-show="pair.visible !== undefined? pair.visible : true">
                                                            <av-text-field style="max-width: 300px;" v-model="pair.displayName" disabled></av-text-field>
                                                            <av-button :toolTip="$gettext('Pick database variable')"
                                                                       @click="setting.customAction(groupName, setting, index, settingName, pair.key)"
                                                                       buttonIcon="fa-exchange" iconColor="info" size="medium" fab small class="ml-3"/>
                                                        </v-layout>

                                                    </td>
                                                    <td v-if="!setting.enableTextField" style="padding: 5px">
                                                        <av-autocomplete v-if="!setting.customAction"
                                                                         :placeholder="$i18n.CommonAttributes()['type here']"
                                                                         style="max-width: 400px"
                                                                         :numItems="(setting.value).length" :readonly="setting.readOnly"
                                                                         :selectOnly="setting.valuesSelectOnly"
                                                                         :items="setting.avoidDuplicateItems ? getNotDuplicatedItems(setting.valuesAutoComplete, setting.value, pair.value, 'value') : setting.valuesAutoComplete" :noMatchErrorMessage="setting.noMatchError" wildCardMatch="true" :value="pair.value"
                                                                         @change="pair.value=$event, valueChanged(groupName,settingName, { action: 'modified', item: index })" />
                                                        <v-layout v-else>
                                                            <av-text-field style="max-width: 300px;" v-model="pair.value" disabled></av-text-field>
                                                            <av-button :toolTip="$gettext('Pick database variable')"
                                                                       :disabled="!pair.key"
                                                                       :disabledTooltip="$gettext('Pick variable first')"
                                                                       @click="setting.customAction(groupName, setting, index, settingName, pair.key)"
                                                                       buttonIcon="fa-exchange" iconColor="info" size="medium" fab small class="ml-3"/>
                                                        </v-layout>
                                                    </td>
                                                    <td v-else style="padding: 5px" v-show="pair.visible !== undefined? pair.visible : true">
                                                            <v-text-field :readonly="setting.readOnly" single-line
                                                                          class="osk"
                                                                          style="max-width: 400px"
                                                                          :placeholder="$i18n.CommonAttributes()['type here']" v-model="pair.value"
                                                                          @change="valueChanged(groupName,settingName, { action: 'modified', item: index })"/>
                                                    </td>
                                                    <td v-show="pair.visible !== undefined? pair.visible : true">
                                                        <v-btn fab small color="error" @click="removeSetting(setting, groupName,settingName, index)">
                                                            <av-icon light>fas fa-trash</av-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </table>
                                            <v-btn v-if="setting.multi" ma-0 color="info"
                                                   @click="setting.value.push({key: '', value: ''})">
                                                <av-icon left light>fas fa-plus-circle</av-icon>
                                                <translate>Add item</translate>
                                            </v-btn>
                                        </template>
                                        <template v-if="setting.type === 'timeSpans'">
                                            <table style="width: 100%; max-width: 450px" :key="setting.type+setting.name">
                                                <tr v-if="Array.isUseful(setting.value)">
                                                    <td>{{setting.fromLabel}}</td>
                                                    <td>{{setting.toLabel}}</td>
                                                </tr>
                                                <v-btn v-else color="info" @click="setting.value.push({from: '00:00', to: '00:00'})">
                                                    <av-icon left light>fas fa-plus</av-icon>
                                                    <translate>Add interval</translate>
                                                </v-btn>
                                                <tr v-for="(span, index) in setting.value" :key="span.from.toString()+span.to.toString()+index">
                                                    <td style="padding: 5px; max-width: 200px!important">
                                                        <PopUpTimePicker v-model="span.from" type="minsColonSecs" @change="valueChanged((isChild ? group : groupName),settingName, { action: 'modified', item: index })"/>
                                                    <td style="padding: 5px; max-width: 200px!important">
                                                        <PopUpTimePicker v-model="span.to" type="minsColonSecs" @change="valueChanged((isChild ? group : groupName),settingName, { action: 'modified', item: index })"/>
                                                    </td>
                                                    <td>
                                                        <v-btn fab small color="error" @click="setting.value.splice(index,1), valueChanged((isChild ? group : groupName),settingName,{ action: 'deleted', item: index })">
                                                            <av-icon light>fas fa-trash</av-icon>
                                                        </v-btn>
                                                    </td>
                                                    <td>
                                                        <v-btn v-if="setting.multi" fab small color="info" @click="setting.value.insertItem(index + 1, {from: '00:00', to: '00:00'})">
                                                            <av-icon light>fas fa-plus</av-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </table>
                                        </template>
                                        <template v-if="setting.type === 'dateSpans'">
                                            <table style="width: 100%; max-width: 450px">
                                                <tr>
                                                    <td>{{setting.fromLabel}}</td>
                                                    <td>{{setting.toLabel}}</td>
                                                </tr>
                                                <tr v-for="(span, index) in setting.value">
                                                    <td style="padding: 5px">
                                                        <PopUpDatePicker style="max-width: 200px" type="monthDashDay" v-model="span.from" @change="valueChanged(groupName,settingName, { action: 'modified', item: index })"/>
                                                    <td style="padding: 5px">
                                                        <PopUpDatePicker style="max-width: 200px" type="monthDashDay" v-model="span.to" @change="valueChanged(groupName,settingName, { action: 'modified', item: index })"/>
                                                    </td>
                                                    <td>
                                                        <v-btn fab small color="error" @click="setting.value.splice(index,1), valueChanged(groupName,settingName, { action: 'deleted', item: index })">
                                                            <av-icon light>fas fa-trash</av-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </table>
                                            <v-btn v-if="setting.multi" ma-0 color="info" @click="setting.value.push({from: ((new Date()).format('MM-dd-yyyy')), to: ((new Date()).format('MM-dd-yyyy'))})">
                                                <av-icon left light>fas fa-plus-circle</av-icon>
                                                <translate>Add Interval</translate>
                                            </v-btn>
                                        </template>
                                        <template v-if="setting.type === 'stringsList'">
                                            <table style="width: 100%;">
                                                <tr v-for="(item, index) in setting.value">
                                                    <td v-if="setting.hasOwnProperty('objectsArray') ? setting.objectsArray : false" style="padding: 5px">
                                                        <v-text-field class="osk" :readonly="setting.value[index].readOnly" single-line style="max-width: 400px"
                                                                      :placeholder="$i18n.CommonAttributes()['type here']" v-model="setting.value[index].value"
                                                                      @change="valueChanged(groupName,settingName, { action: 'modified', item: index })"/>
                                                    </td>
                                                    <td v-else style="padding: 5px">
                                                        <v-text-field class="osk" :readonly="setting.readOnly" single-line style="max-width: 400px"
                                                                      :placeholder="$i18n.CommonAttributes()['type here']" v-model="setting.value[index]"
                                                                      @change="valueChanged(groupName,settingName, { action: 'modified', item: index })"/>
                                                    </td>
                                                    <td>
                                                        <av-button v-if="setting.sortable" :text="$gettext('')" @click="moveFunction(groupName, settingName, setting, { action: 'sorted', item: index }, -1)"
                                                                   color="info" buttonIcon="fa-arrow-up" iconColor="light" class="ma-1" :dynamicID="item.name" fab small/>
                                                        <av-button v-if="setting.sortable"  :text="$gettext('')" @click="moveFunction(groupName, settingName, setting, { action: 'sorted', item: index }, 1)"
                                                                   color="info" buttonIcon="fa-arrow-down" iconColor="light" class="ma-1" :dynamicID="item.name" fab small/>
                                                        <v-btn v-if="setting.hasOwnProperty('objectsArray') ? !setting.value[index].readOnly : true" fab small color="error" @click="setting.value.splice(index,1), valueChanged(groupName,settingName,{ action: 'deleted', item: index })">
                                                            <av-icon light>fas fa-trash</av-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </table>
                                            <v-btn v-if="setting.multi" ma-0 color="info" @click="setting.objectsArray ? setting.value.push({}) : setting.value.push('')">
                                                <av-icon left light>fas fa-plus-circle</av-icon>
                                                <translate>Add item</translate>
                                            </v-btn>
                                        </template>
                                        <template v-if="setting.type === 'jsonFile' || setting.type === 'textFile'">
                                            <template v-if="(Object.isUseful(showJsonEditors[settingName]) && showJsonEditors[settingName])">
                                                <v-jsoneditor v-if="setting.type === 'jsonFile'" v-model="jsonEditorTempFiles[settingName]" :plus="false" height="500px" @error="onJsonEditorError" :options="{ modes: ['tree', 'view', 'form', 'code', 'text', 'preview'], mode: 'code' }" />
                                                <v-textarea v-if="setting.type === 'textFile'" v-model="jsonEditorTempFiles[settingName]" height="500px" rows="27" />
                                                <v-layout row>
                                                    <v-btn ma-0 color="info" @click="showJsonEditors[settingName] = false, valueChanged(groupName, settingName, { action: 'modified', item: jsonEditorTempFiles[settingName] })">
                                                        <av-icon left light>fas fa-save</av-icon>
                                                        Save file
                                                    </v-btn>
                                                    <v-btn ma-0 color="error" @click="showJsonEditors[settingName] = false">
                                                        Close (discard unsaved changes)
                                                    </v-btn>
                                                </v-layout>
                                            </template>
                                            <v-layout v-else row>
                                                <ImportExport :textMode="setting.type === 'textFile'" :horizontal="false" :data="setting.value" :fileName="setting.fileName" @fileLoaded="valueChanged(groupName, settingName, { action: 'loaded', item: $event })"/>
                                                <v-layout column>
                                                    <v-btn ma-0 color="info" @click="openJsonEditor(settingName, setting.value)">
                                                        <av-icon left light>fas fa-edit</av-icon>
                                                        <translate>Edit file</translate>
                                                    </v-btn>
                                                    <v-btn ma-0 color="error" @click="valueChanged(groupName,settingName,{ action: 'deleted', item: '' })">
                                                        <av-icon left light>fas fa-trash</av-icon>
                                                        <translate>Delete file</translate>
                                                    </v-btn>
                                                </v-layout>
                                            </v-layout>
                                        </template>
                                        <template v-if="setting.type==='spreadSheet'">
                                            <av-spreadsheet :style="'min-height: ' + spreadSheetHeight(setting) + 'px;'"
                                                            :header-columns="setting.headers"
                                                            :rows="setting.value"
                                                            :rawRows="setting.rawValue"
                                                            :file-name="setting.fileName"
                                                            :type="setting.typeSpreadSheet"
                                                            :enabled-formula="false"
                                                            :raw="setting.raw"
                                                            :max-spreadsheet-height="setting.maxHeight?setting.maxHeight:undefined"
                                                            :importValidatorHandler="setting.importValidator"
                                                            @changed="valueChanged(groupName,settingName, { action: 'modified', item: $event })"
                                                            @onCellClick="onCellClick(groupName, settingName, { action: 'cellClicked', item: $event })"
                                                            @fileLoaded="spreadSheetImported(groupName, settingName, setting, { action: 'loaded', item: $event })"></av-spreadsheet>

                                        </template>
                                        <template v-if="setting.type==='color'">
                                            <ColorPicker

                                                    v-bind:init-color="setting.value"
                                                    :value="setting.value"
                                                    v-on:update-color="setting.value = $event, valueChanged(groupName, settingName, { action: 'modified', item: $event })"
                                                    :label="setting.name"
                                            ></ColorPicker>
                                        </template>

                                        <template v-if="setting.type === 'file'">
                                            <v-layout row>
                                                <upload-btn noTitleUpdate v-if="setting.type === 'file'" @file-update="uploadImageCallback($event, groupName, settingName)"
                                                            labelClass="templateButton" style="margin-top: 30px" :title="setting.name">
                                                    <template slot="icon-left">
                                                        <av-icon left light>fas fa-upload</av-icon>
                                                    </template>
                                                </upload-btn>
                                                <v-container>
                                                    <v-layout row wrap align-center>
                                                        <v-flex v-for="picture in setting.value">
                                                            <img style="width: 150px" :src="picture"/>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                            </v-layout>
                                        </template>
                                      <template v-if="setting.type === 'error'">
                                        <v-text-field v-if="setting.type === 'error'" class="osk" :readonly="setting.readOnly" :clearable="!setting.readOnly"
                                                      single-line :placeholder="$i18n.CommonAttributes()['type here']" v-model="setting.value"
                                                      @change="valueChanged(groupName,settingName, { action: 'modified', item: '' })" style="max-width: 400px"/>
                                      </template>

                                    </div>
                                </v-layout>
                                <ConfigurationsEditor v-if="setting.type === 'subgroup' && setting.settings" isChild
                                                      :descriptor="subGroupDescriptor(setting,settingName,group)" :dense="dense" :copied="copied"
                                                      @value-Changed="valueChanged"/>
                            </v-flex>
                            <v-spacer/>
                            <v-flex xs2 style="text-align: left;" class="mt-4">
                                <label class="body-1">{{setting.description}}</label>
                            </v-flex>
                            <v-flex xs2 class="mt-4">
                                <v-menu v-if="setting.processControl" absolute offset-y transition="slide-y-transition">
                                    <template v-slot:activator="{ on }">
                                        <label v-bind:style="setting.status.status? 'color: #398BCB;' : 'color: #E71D24;'" class="font-weight-bold">
                                            {{ setting.status.infoMsg }}
                                        </label>
                                        <v-btn class="ml-2 mr-0" v-on="on">
                                            <av-icon color="red" left>fas fa-cog</av-icon>
                                            <translate>Process control</translate>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-tile v-for="(item, i) in setting.processControlItems" :key="i"
                                                     v-if="item.show">
                                            <v-list-tile-content @click="item.action(setting)">
                                            <span class="context-menu-span">
                                                <av-icon :color="item.iconColor" small left>{{ item.icon }}</av-icon>
                                                {{ item.title }}
                                            </span>
                                            </v-list-tile-content>
                                        </v-list-tile>
                                    </v-list>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </template>
                </template>
            </template>
        </template>
        <!--</v-layout>-->
    </v-layout>
</template>

<script>

    import PopUpTimePicker from '@/components/utilities/PopUpTimePicker'
    import PopUpDatePicker from '@/components/utilities/PopUpDayOfYearPicker'
    import ImportExport from "@/components/utilities/ImportExport";
    import ImportExportExcel from "@/components/utilities/ImportExportExcel";
    import ColorPicker from "@/components/utilities/ColorPicker";
    import VJsoneditor from 'v-jsoneditor'
    import UploadButton from 'vuetify-upload-button';
    import {camelize} from "vuetify";
    import VariablePickerDialog from '@/components/dialogs/VariablePickerDialog';


    export default {
        name: "ConfigurationsEditor",
        components: {PopUpTimePicker, PopUpDatePicker, ImportExport, VJsoneditor, ImportExportExcel, ColorPicker, 'upload-btn': UploadButton, VariablePickerDialog},
        data() {
            return {
                showJsonEditors: {},
                jsonEditorTempFiles: {},
                settingsValues: null,
                type: ""
            }
        },

        props: {
            descriptor: {
                default: null
            },
            dense: {
                type: Boolean,
                default: false
            },
            filters: [],
            isChild: {
                type: Boolean,
                default: false
            },
            copied: {
                type: Object,
                default: null
            }
        },
        methods: {
            getNotDuplicatedItems(items, allSelectedItems, itemSelected, property) {
                let array2 = this.extractValue(allSelectedItems, property);
                //get the elements never selected plus the one currently selected
                let result = items.filter(x => (!array2.includes(x) || x === itemSelected));
                return result;
            },
            extractValue(arr, prop) {
                // extract value from property
                let extractedValue = arr.map(item => item[prop]);
                return extractedValue;
            },
            filterValue(obj, key, value) {
                return obj.find(function(v){ return v[key] !== value});
            },
            spreadSheetHeight(setting) {
                let height = 110 + 42 * setting.value.length
                if(height > 500) {
                    height = 500
                }
                return height;
            },
            uploadImageCallback(file, groupName, settingName) {
                if (file) {
                    const reader = new FileReader();
                    let self = this;
                    reader.onload = () => {
                        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                        if ((encoded.length % 4) > 0) {
                            encoded += '='.repeat(4 - (encoded.length % 4));
                        }
                        let img = new Image();
                        img.onload = () => {
                            if (!self.descriptor[groupName].settings[settingName].value)
                                self.descriptor[groupName].settings[settingName].value = [];
                            self.descriptor[groupName].settings[settingName].value.push("data:image/png;base64," + encoded);
                            self.valueChanged(groupName, settingName, { action: 'modified', item: "data:image/png;base64," + encoded});
                        };
                        self.descriptor[groupName].settings[settingName].value = null;
                        img.src = "data:image/png;base64," + encoded;
                    };
                    reader.readAsDataURL(file);
                }
            },
            visible(item) {
                if(Object.isUseful(item.visible) && !item.visible)
                    return false;
                if(!Array.isUseful(this.filters))
                    return true;
                if(!Array.isUseful(item.filters))
                    return true;
                if(Array.isUseful(item.filters))
                    for(let filter of item.filters) {
                        if(this.filters.includes(filter))
                            return true;
                    }
                return false;
            },
            subGroupDescriptor(setting,settingName,group) {
                let returning = {};
                let groupParentName = group.settings[settingName].groupParentName;
                returning[setting.name] = { settings: setting.settings, settingParentName : settingName, groupParentName: groupParentName };
                return returning;
            },
            openJsonEditor(whichOne, settingValue) {
                this.$set(this.jsonEditorTempFiles, whichOne, this.$utils.detach(settingValue));
                this.$set(this.showJsonEditors, whichOne, true);
            },
            pasteValue(dest,source) {
                this.$set(dest, 'value', this.$utils.detach(source.value));
            },
            onCellClick(groupName,settingName,action='') {
                this.$emit('on-CellClick',groupName,settingName,action);
            },
            valueChanged(groupName,settingName,action='',isChild = false) {
                this.$emit('value-Changed',groupName,settingName,action,isChild || this.isChild);
            },
            onJsonEditorError(error) {
                this.$root.showWarningNotification(error, true);
            },
            keyValuesExportHeaders(setting) {
                return [ { label: setting.keysLabel, field: "key" }, { label: setting.valuesLabel, field: "value" } ];
            },
            keyValuesImported(groupName, settingName, setting, action) {
                action.item.records= action.item.records.map(e => { return { key: e[action.item.headers[0]], value: e[action.item.headers[1]]}});
                setting.value = action.item.records;
                this.valueChanged(groupName, settingName, action);
            },
            spreadSheetImported(groupName, settingName, setting, action) {
                setting.value = action.item;
                this.valueChanged(groupName, settingName, action);
            },
            moveFunction(groupName, settingName, setting, action, offset) {
                this.changeItemOrder(groupName, settingName, setting, action, offset);
            },
            changeItemOrder(groupName, settingName, setting, action, offset) {
                if(this.checkItemIndex(setting.value, action.item, offset)) {
                    let oldIndex = action.item;
                    let newIndex = action.item + offset;
                    let element = setting.value[oldIndex];
                    setting.value.splice(oldIndex, 1);
                    setting.value.splice(newIndex, 0, element);
                    this.valueChanged(groupName, settingName, action);
                }
            },
            checkItemIndex(array, index, offset) {
                let maxIndex = array.length - 1;
                let requestedPosition = index + offset;
                return !(requestedPosition < 0 || requestedPosition > maxIndex);
            },
            removeSetting(setting, groupName,settingName, index) {
                // For deleted setting remove delta variable if exist
                if (settingName === 'variablesAliases' && setting.value[index].key !== '')
                    setting.value = setting.value.filter(item => item.key !== setting.value[index].key + '_Delta')

                setting.value.splice(index,1);
                this.valueChanged(groupName,settingName,{ action: 'deleted', item: index });
            }
        },
        computed:{
            layoutStyle(){
                let height = this.$parent.$el ? this.$parent.$el.clientHeight : window.innerHeight;
                let self = this;
                return{
                    //'max-height': !self.isChild ? height + "px" : "auto",
                    'overflow-y': !self.isChild ? "auto" : "",
                    'padding-bottom': 0
                }
            },
        }
    }
</script>