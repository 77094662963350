import settings from '@/api/settings'
import VueInstance from './vueinstance'
import Microservices from "./microservices";
import OrchestratorAPI from '@/api/orchestrator'

export default{
    defaultStates: [
        {
            "WorkFlowStatusString": "idle",
            "WorkflowStatus": 0,
            "Default": true,
        },
        {
            "WorkFlowStatusString": "running",
            "IsActiveState": true,
            "WorkflowStatus": 1,
            "WorkOrderStatusString": "running",
            "Default": false,
        },
        {
            "WorkFlowStatusString": "completed",
            "IsActiveState": false,
            "WorkflowStatus": 2,
            "WorkOrderStatusString": "completed",
            "Default": false,
        },
        {
            "WorkFlowStatusString": "suspended",
            "IsActiveState": false,
            "WorkflowStatus": 3,
            "WorkOrderStatusString": "suspended",
            "Default": false,
        },
        {
            "WorkFlowStatusString": "aborted",
            "IsActiveState": false,
            "WorkflowStatus": 4,
            "WorkOrderStatusString": "aborted",
            "Default": false,
        }
    ],
    defaultFlows:[
        {
            "From": "planned",
            "To": "running",
            "AllowedUsers": [],
            "Name": "Start",
            "ShowAction": "start"
        },
        {
            "From": "running",
            "To": "suspended",
            "AllowedUsers": [],
            "Name": "Suspend",
            "ShowAction": "pause"
        },
        {
            "From": "running",
            "To": "aborted",
            "AllowedUsers": [],
            "Name": "Abort",
            "ShowAction": "abort"
        },
        {
            "From": "running",
            "To": "completed",
            "AllowedUsers": [],
            "Name": "Complete",
            "ShowAction": "stop"
        },
        {
            "From": "suspended",
            "To": "running",
            "AllowedUsers": [],
            "Name": "Resume",
            "ShowAction": "start"
        }
    ],

    configuration:undefined,
    async load(){
        //TODO MANAGE. NOW the document's type it's saved  with capital W
        return await settings.load("Workflow","Workorder")
    },

    async states(){
        let configuration = await this.load();
        if (configuration && configuration.States){
            return configuration.States.map(s=>{return s.Name});
        }
        return this.defaultStates
    },

    async flows() {
        let returning = this.defaultFlows;
        let configuration = await this.load();
        if(configuration && configuration.Flows)
            returning = configuration.Flows;

        for(let flow of returning) {
            flow.Show = "From " + flow.From + " To " + flow.To;
            if (!flow.Id)
                flow.Id = flow.From + " -> " + flow.To;
            else flow.Show = flow.Id + ": " + flow.Show
        }

        return returning
    },

    getAllFlowsDescriptor() {
        return {Id: "default", Show: VueInstance.get().$gettext("All state transitions") }
    },

    getCurrentWorkFlow() {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders + "/workflow/*/current")
                .then(response => {
                    if(Array.isArray(response))
                        resolve(response[0])
                    else resolve(response);
                })
                .catch(error => {
                    debugger;
                    if(Microservices.isIndexEmptyError(error)) {
                        resolve([]);
                    } else {
                        reject(error);
                    }
                });
        });
    },

    async getAllowedLineFlows(lineId) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders + '/workflow/flows/allowed/{0}'.format(lineId))
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    debugger;
                    console.error(t);
                    reject(VueInstance.get().$gettext("Error in scheduling workorder"));
                });
        });
    },
    //TODO this is a temp API, remove it in favour of an API that will calculate flows based on workorder real status
    //Currently getAllowedWoFlows above is only retrieving transitions for currently active workorder, later it shall be fixed to work on any one
    async getPossibleFlowsByState(state) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders + '/workflow/flows/possible/{0}'.format(state))
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    debugger;
                    console.error(t);
                    reject(VueInstance.get().$gettext("Error in scheduling workorder"));
                });
        });
    },

    handleTransactionComplete(event) {
        console.log(event)
        // Error: ""
        // Line: "Vials Line"
        // NewWorkFlowState: "ABORTED"
        // NewWorkorderStatus: ""
        // PreviousWorkFlowState: "IDLE"
        // PreviousWorkorderStatus: "planned"
        // TransitionError: ""
        // TransitionFlow: "RESET"
        // TransitionId: "L2_IDLE_RESET__1660207168430917100"
        // WordorderId: "test2"
        if (event.TransitionError) {
            let message = VueInstance.get().$gettext("An error occurred while executing transition {0} for workorder {1} on line {2}").format(event.TransitionFlow, event.WordorderId, event.Line);
            if (event.TransitionError.includes("cannot retrieve recipe"))
                message += ". " + VueInstance.get().$gettext("Cannot retrieve all needed recipes");
            if (event.TransitionError.includes("RecipeNotActive")) {
                let recipeName=this.extractTransitionError(event.TransitionError,"RecipeNotActive")
               message += ". " + VueInstance.get().$gettext("The recipe {0} is not active").format(recipeName);
            }
            if (event.TransitionError.includes("DefaultTemplateNotFound")){
                this.extractTransitionError(event.TransitionError,"DefaultTemplateNotFound")
                message += ". " + VueInstance.get().$gettext("There is not template set as a default recipe");
            }
            if (event.TransitionError.includes("TemplateNotDefault")) {
                let templates=this.extractTransitionError(event.TransitionError,"TemplateNotDefault")
                message += ". " + VueInstance.get().$gettext("One or more templates of {0} can be set as default recipe");
            }
            if (event.TransitionError.includes("DefaultLineRecipeNotFound")) {
                this.extractTransitionError(event.TransitionError,"DefaultLineRecipeNotFound")
                message += ". " + VueInstance.get().$gettext("There is not line recipe set as default");
            }
            if (event.TransitionError.includes("MachineNotFound")) {
                let machine=this.extractTransitionError(event.TransitionError,"MachineNotFound")
                message += ". " + VueInstance.get().$gettext("Machine {0} not found in configuration").format(machine);
            }

            VueInstance.get().$root.showErrorNotification(message, true, false);
        } else {
            let message = VueInstance.get().$gettext("Workorder {0} completed state transition {1} from {2} to {3} on line {4}").format(event.WordorderId, event.TransitionFlow, event.PreviousWorkFlowState, event.NewWorkFlowState, event.Line);
            VueInstance.get().$root.showNotification(message, true, false);

            //A workorder is completed, if configured force stop categorization
            //TODO missing info to understand if workorder is closed
            // let workFlowSettings = settings.getWorkFlowSettings();
            // if (workFlowSettings && workFlowSettings.forceStopsCategorizationOnIdle && requestedTransition && !this.isWorkorderActive(workorder)) {
            //     this.$timeTracking.openBackAnnotationTimeTrackingDialog(this, this.$root.userName, this.checkUncategorizedStops, workorder, true);
            // }
        }
    },
    extractTransitionError(msg,key){
        let rcpMsg = msg.substring(msg.indexOf(key), msg.length)
        if(rcpMsg.indexOf(")")>-1){
            rcpMsg = rcpMsg.replace(")","")
        }
        return rcpMsg.replace(key, "").trim()
    }

}
