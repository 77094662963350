<template>
    <av-select v-if="selectOnly"
               v-model="value"
               :items="items"
               item-text="text"
               item-value="value"
               hide-selected
               @change="$emit('change',value)">
               :label="label">

    </av-select>
    <v-combobox v-else
            v-model="value"
            :filter="filter"
            :hide-no-data="!search"
            :items="items"
            :search-input.sync="search"
            :readonly="readonly"
            hide-selected
            item-text="text"
            item-value="value"
            @change="$emit('change',value)"
            :placeholder="$i18n.CommonAttributes()['type here']"
            :label="label"
    >
        <template v-if="noMatchErrorMessage" v-slot:no-data>
            <v-list-tile>
                <v-layout column>
                    <label class="subheading">{{noMatchErrorMessage}}</label>
                    <label style="color: var(--av-red)"><translate>Value:</translate> {{ search }}</label>
                </v-layout>
            </v-list-tile>
        </template>

        <template v-slot:item="{ index, item }">
            <v-list-tile-content>
                <label color="blue">{{ item }}</label>
            </v-list-tile-content>
        </template>
    </v-combobox>
</template>

<script>
    export default {
        data() {
            return {
                search: null,
                index: -1,
                menu: false,
            }
        },
        props: {
            value: "",
            items: null,
            noMatchErrorMessage: "",
            wildCardMatch: false,
            readonly: false,
            selectOnly: false,
            label: { required: false, default: "", type: String },
        },
        methods: {

            filter(item, queryText, itemText) {
                const hasValue = val => val != null ? val : '';

                const text = hasValue(itemText);
                const query = hasValue(queryText);

                return text.toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1 || (this.wildCardMatch && this.$utils.matchWildcardString(text, query))
            }
        },
    }
</script>