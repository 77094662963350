<template>
    <DynamicElementBase>
        <div v-if="!showAsMatrix" style="height: 100%; width:100%; overflow: auto;">
            <v-data-table :headers="headers" :items="dataCollection" hide-actions>
                <template v-slot:headers="props">
                    <tr>
                        <th style="padding-left: 10px; padding-right: 5px; text-align: left"
                            v-for="header in props.headers" :class="header.class">{{ header.text }}
                        </th>
                    </tr>
                </template>
                <template v-slot:items="props">
                    <tr>
                        <td class="subheading">
                            Line: {{ props.item.physicalLineId }}
                        </td>
                        <td>
                            <v-layout row>
                                <div style="display:inline-block; cursor: pointer"
                                     @click="openLineDetails(props.item);">
                                    <!--@click="/openLineDetails(props.item.physicalLineId);"-->
                                    <!--<a href="l1">-->
                                    <av-icon size="30">open_in_new</av-icon>
                                    <v-tooltip v-translate>Show Details</v-tooltip>
                                    <!--</a>-->
                                </div>
                                <!--<div style="display:inline-block; cursor: pointer" @click="openLinePerformance(props.item.physicalLineId)">-->
                                <!--<v-icon size="30">videocam</v-icon>-->
                                <!--<v-tooltip>Show Devices Performances</v-tooltip>-->
                                <!--</div>-->
                            </v-layout>
                        </td>
                        <!--<td>-->
                        <!--{{ props.item.logicalLineId }}-->
                        <!--</td>-->
                        <td>
                            <div :class="props.item.status"
                                 style="width:25px; height:25px; -webkit-border-radius:25px;">&nbsp;
                            </div>
                            <v-tooltip>{{ props.item.status }}</v-tooltip>
                        </td>
                        <!--                        <td>-->
                        <!--                            <div style="width:300px; display:inline-block;">-->
                        <!--                                <div style="width:200px; display:inline-block; vertical-align: text-bottom;">-->
                        <!--                                    <div-->
                        <!--                                        :style="'text-align: center; height: 20px; borderRadius:5px; border: 1px solid lightgray; backgroundImage:' + props.item.speedBackgroundImage">-->
                        <!--                                        {{ props.item.efficiency }} % of max speed-->
                        <!--                                    </div>-->
                        <!--                                </div>-->
                        <!--                                <v-tooltip v-if="props.item.speed >= 0 && props.item.maxSpeed >= 0">-->
                        <!--                                    {{ props.item.speed }} (actual) / {{ props.item.maxSpeed }} (max)-->
                        <!--                                </v-tooltip>-->
                        <!--                                <span-->
                        <!--                                    class="subheading">&nbsp;{{ props.item.speed >= 0 ? props.item.speed : '' }} ppm</span>-->
                        <!--                            </div>-->
                        <!--                        </td>-->
                        <td>
                            <div style="width:250px; display:inline-block;">
                                <div style="width:250px; display:inline-block; vertical-align: text-bottom;">
                                    <div
                                        :style="'text-align: center; height: 20px; borderRadius:5px; border: 1px solid lightgray; backgroundImage:' + props.item.oeeBackgroundImage">
                                        {{ props.item.oee >= 0 ? props.item.oee + '%' : '' }}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <template>
                                <span class="subheading">{{ props.item.quantityProduced }}</span>
                            </template>
                        </td>
                        <!--                        <td>-->
                        <!--                            <template v-if="props.item.rejects >= 0">-->
                        <!--                                <span class="subheading">{{ props.item.rejects }}</span>-->
                        <!--                            </template>-->
                        <!--                            <template v-else>-->
                        <!--                                &nbsp;-->
                        <!--                            </template>-->
                        <!--                        </td>-->
                        <td>
                            <template v-if="props.item.quality >= 0">
                                <span class="subheading">{{
                                        100 - props.item.quality >= 0 ? (100 - props.item.quality).toFixed(1) + '%' : ""
                                    }}</span>
                            </template>
                        </td>
                        <td>
                            <div class="align-center"
                                 style="min-width:100px;display:inline-block; white-space:nowrap;margin-left: 20px;">
                                <div style="width:250px;display:inline-block; vertical-align: text-bottom;">
                                    <div
                                        v-bind:style="'text-align: center; height: 20px; borderRadius: 5px; border: 1px solid lightgray; backgroundImage:' + props.item.progressBackgroundImage">
                                        {{
                                            props.item.itemsCountPercent >= 0 ? props.item.itemsCountPercent + '%' : ""
                                        }}
                                        of {{ props.item.quantityToProduce }} items
                                    </div>
                                </div>
                                <!--<v-tooltip v-if="props.item.itemsCount >= 0 && props.item.quantityToProduce >= 0">{{props.item.itemsCount}} (actual) / {{props.item.quantityToProduce}} (total)</v-tooltip>-->
                                &nbsp;
                            </div>

                        </td>
                        <!--                        <td>-->
                        <!--                            <template v-if="props.item.inTime">-->
                        <!--                                <div :class="props.item.inTime"-->
                        <!--                                     style="width:25px; height:25px; -webkit-border-radius:20px;">&nbsp;-->
                        <!--                                </div>-->
                        <!--                                <v-tooltip>{{ props.item.inTime }}</v-tooltip>-->
                        <!--                            </template>-->
                        <!--                            <template v-else>-->
                        <!--                                &nbsp;-->
                        <!--                            </template>-->
                        <!--                        </td>-->
                        <td>
                            <template class="subheading" v-if="props.item.lot">
                                {{ props.item.lot }}
                            </template>
                            <template v-else>
                                &nbsp;
                            </template>
                        </td>
                        <!--<td>-->
                        <!--<template v-if="props.item.nextLot">-->
                        <!--{{ props.item.nextLot }}-->
                        <!--</template>-->
                        <!--<template v-else>&nbsp;</template>-->
                        <!--</td>-->
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div v-else style="height: 100%; width:100%; overflow: auto;">
            <v-layout id="layoutLines" row wrap  py-2 justify-center style="height: 100%; width:100%; overflow: auto;">
<!--                <div v-for="(item,indexCol) in dataCollection" :key="indexCol"-->
<!--                     :style="'min-width:{0}px; min-height: 300px; max-width: 400px; height:{1}px; padding: 8px 8px 0 8px;  overflow: auto;'.format(slotWidth-10,slotHeight)">-->
                <div v-for="(item,indexCol) in dataCollection" :key="indexCol"
                     :style="'min-width:{0}px; min-height: {1}px; max-height:{2}px; max-width: 400px; padding: 0px 4px 0 4px;  overflow: hidden;'.format(slotWidth-10,minHeight ,slotHeight)">
<!--                <v-card  elevation="0" :style="'height:{0}'.format(slotHeight-100)">-->
                    <v-card  elevation="0" style="height:80%" >
                        <v-toolbar card dense :class="item.status" >
                            <v-toolbar-title style="height:34px;">
                                <div style="display:inline-block; cursor: pointer"
                                     @click="openLineDetails(item);">
                                    <av-icon size="30">open_in_new</av-icon>
                                    <v-tooltip v-translate>Show Details</v-tooltip>
                                </div>
                                {{ item.physicalLineId }}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-title v-if="item.lot" style="height: 5%">
                            <span class="subheading mr-2">{{ item.lot }}</span>
                        </v-card-title>
                        <v-card-text style="height: 80%">
                            <div style="height: 5%">
                                <div
                                    v-bind:style="'text-align: center; borderRadius: 5px; border: 1px solid lightgray; backgroundImage:' + item.progressBackgroundImage">
                                    {{ item.itemsCountPercent >= 0 ? item.itemsCountPercent + '%' : "" }} of
                                    {{ item.quantityToProduce }} items
                                </div>
                            </div>
                            <div style="height: calc(100%);margin-top: 10px;border-style: none;" :ref="'lineElement-' + indexCol"
                                 :id="`cv${indexCol}`">
                            </div>
                            <div style="height: 5%">
                                <div style="text-align: center;" class="title font-weight-bold">
                                    {{ $gettext('Total stop time') }}: {{ item.totalStopTime }}
                                </div>
                            </div>
                            <div style="height: 5%;margin-top: 10px;border-style: none">

                                <div class="font-weight-bold"
                                     style="display: inline-block;vertical-align: top;width: 49%;padding: 10px; text-align: center;">
                                    {{ $gettext('Produced') }}:
                                    {{ item.quantityProduced }}
                                </div>

                                <div class="font-weight-bold"
                                     style="display: inline-block;vertical-align: top;width: 49%;padding: 10px;text-align: center;">
                                    {{ $gettext('Rejects') }}:
                                    {{ 100 - item.quality >= 0 ? (100 - item.quality).toFixed(1) + '%' : "" }}
                                </div>

                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-layout>
        </div>
    </DynamicElementBase>
</template>

<script>

import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
import Production from '@/api/production'
import Oee from '@/api/oee'
import AvToolbar from "../../av-components/av-toolbar";

export default {
    name: "WidgetPlant",
    extends: DynamicElementBase,
    components: {
        AvToolbar,
        DynamicElementBase
    },
    data() {
        return {
            headers: [{text: this.$gettext("Line"), class: "subheading"}, {
                text: this.$gettext("Line details"),
                class: "subheading"
            },/*{ text: "Logical line", class: "subheading" },*/{
                text: this.$gettext("Status"),
                class: "subheading"
            }, /*{text: this.$gettext("Speed (ppm)"), class: "subheading"},*/
                {text: "OEE", class: "subheading"},
                {text: this.$gettext("Produced"), class: "subheading"},
                {text: this.$gettext("Rejects"), class: "subheading"},
                {text: this.$gettext("Progress"), class: "subheading"},
                // {text: this.$gettext("In Time"), class: "subheading"},
                {
                    text: this.$gettext("Current Lot"),
                    class: "subheading"
                }/*, { text: "Next Lot", class: "subheading" }*/],
            lineBreaks: [],
            dataCollection: [],
            initializer: null,
            isAutoloading: false,
            firstLoad: false,
            minHeight: 416,
            minWidth: 516,
            slotHeight: 0, // TODO set 300?
            slotWidth: 0, // TODO set 250?
            numLinesToDisplay: 0,
            matrixInitialized: false,
            showAsMatrix: false,
            numStaticTweaks: 0,
            // currentElement: null,
            queriesCountForLine: 14,
            currentElements: [],
            previousRunTimeDataSource: false,
            indexNames: [],
            selectedIndexes: [],
            createdDataItems: false,
            counter: 0,
        }
    },
    mounted() {
        //AutomaticRuntimeDatasource is setting a true for level 3
        this.visualizationTweaks = [
            {
                name: this.$gettext("Automatic runtime datasource"),
                id: "AutomaticRuntimeDatasource",
                type: "bool",
                default: function () {
                    return false;
                }
            },
            {
                name: this.$gettext("Show as matrix"),
                id: "ShowAsMatrix",
                type: "bool",
                default: function () {
                    return false;
                }
            }
        ];
        this.firstLoad = true;
        this.numStaticTweaks = this.visualizationTweaks.length;
        this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], true));
        this.dataExplorationMode.autoDataSourcing = true;
        this.dataExplorationMode.deviceSelectMode = true;
        this.dataExplorationMode.enumerateLineInDevices = true;
        this.dataExplorationMode.multipleDevicesSelection = true;
        this.previousRunTimeDataSource = this.getTweakValue("AutomaticRuntimeDatasource");
        // this.properties.autoRefresh = false;
        // this.scope().childHandlers.onSavedChanges = () => {
        //     this.counter++;
        //     console.log("onSavedChanges:" + this.counter)
        //     if(this.editMode) {
        //         this.properties.autoRefresh = false;
        //     }
        //     // this.createdDataItems = true;
        //     this.createTmpDevices(); };
        setTimeout(this.loadSavedTweaks, 1000);
    },
    computed: {
        devices() {
            return this.properties.selectedDevices;
        }
    },
    watch: {
        devices() {
            if (this.firstLoad && !this.getTweakValue("AutomaticRuntimeDatasource")) {
                for (const device of this.devices) {
                    this.indexNames.push(device.value);
                }
                this.firstLoad = false;
            } else if (!this.loading && !this.getTweakValue("AutomaticRuntimeDatasource")) {
                this.$root.showDialogBox(this.$gettext("Do you want to automatically create data sources for Plant View?"), null, this.$gettext("Yes"), this.createDataItems, this.$gettext("No"), null);
            }
            if (this.selectedIndexes.length > this.properties.selectedDevices.length) {
                this.selectedIndexes = this.properties.selectedDevices;
            }
        },
        // dataItems : {
        //     handler: function () {
        //         if (!Array.isUseful(this.dataItems) && !Array.isUseful(this.filterItems)) {
        //             this.previousRunTimeDataSource = false;
        //             this.clearTweaks();
        //             this.properties.selectedDevices.clear();
        //             // this.loadDataMappings();
        //
        //
        //         }
        //     },
        //     deep:true
        // },
        // filterItems: {
        //     handler: function () {
        //         if (!Array.isUseful(this.dataItems) && !Array.isUseful(this.filterItems)) {
        //             this.previousRunTimeDataSource = false;
        //             this.clearTweaks();
        //             this.properties.selectedDevices.clear();
        //             // this.loadDataMappings();
        //
        //
        //         }
        //     },
        //     deep: true
        // },
        visualizationTweaks: {
            handler: function () {
                this.showAsMatrix = this.getTweakValue("ShowAsMatrix");
                if (!this.isAutoloading && !this.loading) {
                    //Update visualization tweaks values and save widget
                    this.saveTweaks();
                    let currentRunTimeDataSource = this.getTweakValue("AutomaticRuntimeDatasource");
                    if (currentRunTimeDataSource && currentRunTimeDataSource !== this.previousRunTimeDataSource) {
                        this.loadDataMappings();
                        this.saveTweaks();
                        this.previousRunTimeDataSource = currentRunTimeDataSource;
                    }
                }
            },
            deep: true,
        },
        containerWidth() {
            if (this.showAsMatrix) {
                this.initMatrix();
            }
        },
        containerHeight() {
            if (this.showAsMatrix) {
                this.initMatrix();
            }
        },
        showAsMatrix() {
            if (this.showAsMatrix) {
                this.createElements();
            }
        }
    },
    methods: {
        initMatrix() {
            this.numLinesToDisplay = this.dataCollection.length;
            let numCardsInRow = this.numLinesToDisplay;
            while (true) {
                if (numCardsInRow < 1)
                    return
                let cellWidth = this.containerWidth / numCardsInRow;
                if (cellWidth < this.minWidth) {
                    numCardsInRow--
                } else {
                    this.slotWidth = cellWidth;
                    this.numLinesToDisplay =  this.numLinesToDisplay / numCardsInRow;
                    if (this.containerHeight < this.minHeight) {
                        this.minHeight = this.containerHeight -20;
                    }
                    this.slotHeight = (this.containerHeight / this.numLinesToDisplay) -20;
                    if (this.slotHeight < this.minHeight) {
                        this.slotHeight = this.minHeight;
                    }
                    break;
                }
            }
        },

        updateElements() {
            for (let i = 0; i < this.dataCollection.length; i++) {
                this.currentElements[i].value = this.dataCollection[i].oee > 0 ? this.dataCollection[i].oee + '%' : this.dataCollection[i].oee;
            }
        },

        createElements() {
            for (let i = 0; i < this.dataCollection.length; i++) {
                let currentElementType = this.$dynamicElements.Types.Gauge;
                let self = this;
                this.$nextTick(() => {
                    //Create element
                    let element = self.$dynamicElements.createNew(currentElementType, false);
                    self.currentElements.push(element);
                    if (!self.currentElements[i])
                        return; //License issue or huge error (unlikely)
                    self.currentElements[i].properties.type = currentElementType;
                    self.currentElements[i].properties.hideBorder = true;
                    self.currentElements[i].properties.showTitleBar = false;
                    self.currentElements[i].newElement();
                    self.$nextTick(() => {
                        self.$refs['lineElement-' + i][0].appendChild(self.currentElements[i].$el);
                        self.currentElements[i].setTweakValue("minValue", 0);
                        self.currentElements[i].setTweakValue("maxValue", 100);
                        // self.currentElements[i].setTweakValue("colorMode", 2);
                        // self.currentElements[i].setTweakValue("highlights", [
                        //     {"from": 0, "to": 30, "color": this.$avStyle.colors.red},
                        //     {"from": 30, "to": 50, "color": this.$avStyle.colors.yellow},
                        //     {"from": 50, "to": 70, "color": this.$avStyle.colors.lightgreen},
                        //     {"from": 70, "to": 100, "color": this.$avStyle.colors.green},
                        // ]);
                        // self.currentElements[i].setTweakValue("height", self.slotHeight);
                        // let divTmp = document.getElementById("cv" + i);
                        // divTmp.getElementsByTagName("canvas")[0].style.height = self.slotHeight + "px";
                        setTimeout(() => {
                            self.currentElements[i].value = this.dataCollection[i].oee;
                        }, 5000);
                    });
                });
            }

        },

        loadDataMappings() {
            this.isAutoloading = true;
            let self = this;
            this.$devices.selectDeviceFromIndexPatterns(this.dataExplorationMode.compatibleDataPatterns, true)
                .then(devices => {
                    self.createDataItems(devices);
                })
                .finally(() => {
                    self.isAutoloading = false;
                });
        },
        loadSavedTweaks() {
            if (this.properties.visualizationTweaksValues) {
                let currentRunTimeDataSource = this.getTweakValue("AutomaticRuntimeDatasource");
                // if (currentRunTimeDataSource && currentRunTimeDataSource !== this.previousRunTimeDataSource && !this.firstLoad) {
                //     this.clearTweaks();
                // }
                if(this.firstLoad === false) {
                    this.clearTweaks();
                }

                let unsortedTweaks = [];
                for (const tweak in this.properties.visualizationTweaksValues) {
                    if (tweak !== "AutomaticRuntimeDatasource" && tweak !== "ShowAsMatrix") {
                        unsortedTweaks.push({
                            name: tweak.split("_")[1],
                            id: tweak,
                            type: "string",
                            value: this.properties.visualizationTweaksValues[tweak]
                        });
                    }
                }
                this.visualizationTweaks.push(...unsortedTweaks.sort(function (a, b) {
                    try {
                        if (parseInt(a.id) > parseInt(b.id))
                            return 1;
                        else
                            return -1;
                    } catch (err) {
                        /*We have a broken widget, this shouldn't happen in production, yet during development unstable widgets could be produced*/
                        return 0;
                    }
                }));
            }
        },
        openLineDetails(item) {
            let lineIndex = item.lineIndex;
            if (Object.isUseful(this.indexNames[lineIndex])) {
                let indexName = this.indexNames[lineIndex];
                if (this.getTweakValue((lineIndex * 3 + 1) + indexName + "_Row " + lineIndex + " details link")) {
                    let detailsTitle = this.getTweakValue((lineIndex * 3) + indexName + "_Row " + lineIndex + " details title") ? this.getTweakValue((lineIndex * 3) + indexName + "_Row " + lineIndex + " details title").replaceAll(/\//g, "£").replaceAll(/#/g, "@") : item.physicalLineId;
                    let dashLink = this.getTweakValue((lineIndex * 3 + 1) + indexName + "_Row " + lineIndex + " details link").replaceAll(/\//g, "£").replaceAll(/#/g, "@");
                    this.$router.push({path: '/realtime/' + detailsTitle + '/' + dashLink});
                }
            }
        },
        // openLinePerformance(physicalLineId) {
        //     if(physicalLineId === 1)
        //         this.environment.router.push({path:'/dashboards/builtin-rejects-l1/show'});
        //     else if(physicalLineId === 2)
        //         this.environment.router.push({path:'/dashboards/builtin-rejects-l2/show'});
        //     else if(physicalLineId === 3)
        //         this.environment.router.push({path:'/dashboards/builtin-rejects-l3/show'});
        // },

        //Clear all tweaks loaded or created a runtime , but not the first static tweak.
        clearTweaks() {
            if (this.visualizationTweaks.length > this.numStaticTweaks) {
                this.visualizationTweaks.splice(this.numStaticTweaks, this.visualizationTweaks.length - 1);
            }
        },

        async createTmpDevices(devices) {
            let self = this;

            let callCreateDataItems = function(self) {
                for (const device of (Array.isUseful(devices) ? devices : self.devices)) {
                    if (!(self.selectedIndexes.includes(device.dataIndex))) {
                        self.$nextTick(() => {
                            self.createDataItems(device);
                        });
                        return;
                    }
                }
                // self.createdDataItems = true;
                self.properties.autoRefresh = true;
            }

            callCreateDataItems(this);

        },

        createDataItems(devices) {
            //Create data items for each line, ensure that even in case user selects
            //more than one device per line, we consider only one per line.
            this.lineBreaks.push(0);
            let filters = [];
            let data = [];
            let representationsCount = 0;
            // let selectedIndexes = [];
            this.selectedIndexes = [];
            this.indexNames = [];
            // this.visualizationTweaks = [];
            let currentRunTimeDataSource = this.getTweakValue("AutomaticRuntimeDatasource");
            if (!this.firstLoad && this.previousRunTimeDataSource !== currentRunTimeDataSource) {
                this.clearTweaks();
            }

            let lineIndex = 0;

            for (const device of (Array.isUseful(devices) ? devices : this.devices)) {
                if (!(this.selectedIndexes.includes(device.dataIndex))) {
                    this.selectedIndexes.push(device.dataIndex);
                     let productionDescriptor = Production.getDataDescriptor(device.dataIndex, [Production.targets.progress, Production.targets.quantityProduced, Production.targets.quantityToProduce, Production.targets.status, Production.targets.workorder], true);
                    filters = filters.concat(productionDescriptor.filters);
                    data = data.concat(productionDescriptor.data);
                    for (let i = 0; i < productionDescriptor.data.length; i++)
                        representationsCount += productionDescriptor.data[i].representations.length;

                    // data.push({
                    //         index: "BATCH_" + device.dataIndex,
                    //         root: 'Line',
                    //         name: 'all',
                    //         type: '',
                    //         selectedForVisualization: true,
                    //         targets: [Production.targets.progress, Production.targets.quantityProduced, Production.targets.quantityToProduce, Production.targets.status, Production.targets.workorder],
                    //         representations: [
                    //             {
                    //                 type: this.$defines.allAggregations.raw.id,
                    //                 filters: [],
                    //                 target: 0,
                    //                 defaultName: '',
                    //                 name: '',
                    //                 enabled: true,
                    //                 timeless: true
                    //             }
                    //         ]
                    //     },
                    // );

                    let oeeDescriptor = Oee.getDataDescriptor([device], true);
                    filters = filters.concat(oeeDescriptor.filters);
                    data = data.concat(oeeDescriptor.data);
                    for (let i = 0; i < oeeDescriptor.data.length; i++)
                        representationsCount += oeeDescriptor.data[i].representations.length;
                    filters.push({
                        index: device.dataIndex,
                        root: "TimeTracking",
                        name: 'Activity',
                        type: 'integer',
                        selectedForFiltering: true,
                        filters: [{
                            conditions: [{operator: '>', value: 1}],
                            defaultName: "lineStoppedFilter",
                            name: "lineStoppedFilter",
                            enabled: true,
                            filterId: "lineStoppedFilter"
                        }],
                    },)
                    data.push({
                            index: device.dataIndex,
                            root: 'Line',
                            name: 'Status',
                            type: 'integer',
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.last.id,
                                    filters: [],
                                    target: 300,
                                    defaultName: 'Line Status',
                                    name: 'Line Status',
                                    enabled: true
                                }
                            ]
                        },
                        {
                            index: device.dataIndex,
                            root: 'Line',
                            name: 'PhysicalLineId',
                            type: 'keyword',
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.last.id,
                                    filters: [],
                                    target: 400,
                                    defaultName: 'LineId',
                                    name: 'LineId',
                                    enabled: true
                                }
                            ]
                        },
                        {
                            index: device.dataIndex,
                            root: 'TimeTracking',
                            name: 'Activity',
                            type: 'integer',
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.duration.id,
                                    filters: ["lineStoppedFilter"],
                                    target: 500,
                                    defaultName: 'TotalStopTime',
                                    name: 'TotalStopTime',
                                    enabled: true
                                }
                            ]
                        }
                    );
                    representationsCount += data.length;
                    this.lineBreaks.push(representationsCount);
                    //FN : commented to create dynamic tweaks everytime
                    // if (!this.getTweakValue("AutomaticRuntimeDatasource")) {
                    //FN It is a sufficient condition that there is no line tweak
                    // if(!this.firstLoad) {
                        let lineTweakLinks = this.getTweak((lineIndex * 3) + device.value + "_Row " + lineIndex + " details title");
                        if (lineTweakLinks === null || !this.firstLoad) {
                            this.visualizationTweaks.push({
                                name: "Row " + lineIndex + " details title",
                                id: (lineIndex * 3) + device.value + "_Row " + lineIndex + " details title",
                                type: "string",
                                value: ""
                            });

                            this.visualizationTweaks.push({
                                name: "Row " + lineIndex + " details link",
                                id: (lineIndex * 3 + 1) + device.value + "_Row " + lineIndex + " details link",
                                type: "string",
                                value: ""
                            });

                            this.visualizationTweaks.push({
                                name: "Row " + lineIndex + " line name",
                                id: (lineIndex * 3 + 2) + device.value + "_Row " + lineIndex + " override line name",
                                type: "string",
                                value: ""
                            });
                            // }
                        // }
                    }
                    this.indexNames.push(device.value);
                    lineIndex++;
                }
            }

            // let tmpFilters = [...this.filterItems , ...filters];
            // let tmpData = [... this.dataItems, ...data];
            //
            // this.filterItems = [... new Set(tmpFilters)];
            // this.dataItems = [... new Set(tmpData)];

            this.filterItems = filters;
            this.dataItems = data;
            // this.loadSavedTweaks();
            if(this.firstLoad) {
                this.firstLoad = false;
            }
            this.$emit('dataItemsUpdated');
        },
        refreshData(dataValues) {

            if (!dataValues)// || this.saving || (!this.createdDataItems && this.editMode))
                return;

            // if(!this.refreshing && this.editMode) {
            //     this.refreshing = true;
            // }

            // if (this.createdDataItems) {
            //     this.createdDataItems = false;
            // }

            if (dataValues.length % this.queriesCountForLine !== 0) {
                this.setError("Wrong data sources");
                return;
            }

            this.clearErrors();
            this.clearWarnings();

            this.dataCollection = [];

            let errors = [];
            let warnings = [];

            for (let lineIndex = 0; lineIndex < (dataValues.length / this.queriesCountForLine); lineIndex++) {
                let item = {};
                let lineDataValues = dataValues.slice(lineIndex * this.queriesCountForLine, (lineIndex + 1) * this.queriesCountForLine);
                for (const dataSet of lineDataValues) {
                    if (dataSet && Array.isUseful(dataSet.data)) {
                        //From OEE
                        let valid = Oee.isIndicatorValid(dataSet.data.last().error, dataSet.data.last().warning);
                        if (dataSet.target === Oee.targets.efficiency)
                            item.efficiency = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.rejectedItems)
                            item.rejects = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.total)
                            item.oee = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.maxSpeed)
                            item.maxSpeed = valid ? dataSet.data.last().y : "N/A";
                        else if (dataSet.target === Oee.targets.averageSpeed)
                            item.speed = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.quality)
                            item.quality = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        //From Batch
                        else if (dataSet.target === Production.targets.quantityToProduce)
                            item.quantityToProduce = dataSet.data.last().y;
                        else if (dataSet.target === Production.targets.quantityProduced)
                            item.quantityProduced = dataSet.data.last().y;
                        else if (dataSet.target === Production.targets.status)
                            item.inTime = dataSet.data.last().y === 1 ? "inTime" : "late";
                        else if (dataSet.target === Production.targets.progress) {
                            item.itemsCountPercent = this.$utils.roundToDigits(dataSet.data.last().y, 1);
                            item.progressBackgroundImage = '-webkit-linear-gradient(left, gray ' + item.itemsCountPercent + '%, white 1%)';
                        } else if (dataSet.target === Production.targets.workorder)
                            item.lot = dataSet.data.last().y;
                        //Line Status
                        else if (dataSet.target === 300) {
                            if (Array.isUseful(dataSet.data))
                                item.status = dataSet.data.last().y > 0 ? "running" : "stopped";
                            else
                                item.status = "Unknown";
                        } else if (dataSet.target === 400) {
                            if (Array.isUseful(dataSet.data))
                                item.physicalLineId = dataSet.data.last().y;
                        } else if (dataSet.target === 500) {
                            if (Array.isUseful(dataSet.data))
                                item.totalStopTime = dataSet.data.last().y;
                        }
                        if (Object.isUseful(dataSet.data.last().error) && dataSet.data.last().error && dataSet.data.last().error !== "NoError")
                            errors.push(Oee.translateError(dataSet.data.last().error));
                        if (Object.isUseful(dataSet.data.last().warning) && dataSet.data.last().warning && dataSet.data.last().warning !== "NoWarning")
                            warnings.push(Oee.translateError(dataSet.data.last().warning));
                    }

                    if (item.efficiency > 80)
                        item.speedBackgroundImage = '-webkit-linear-gradient(left, green ' + item.efficiency + '%, white 1%)';
                    else if (item.efficiency > 60)
                        item.speedBackgroundImage = '-webkit-linear-gradient(left, yellow ' + item.efficiency + '%, white 1%)';
                    else
                        item.speedBackgroundImage = '-webkit-linear-gradient(left, red ' + item.efficiency + '%, white 1%)';

                    if (item.oee > 80)
                        item.oeeBackgroundImage = '-webkit-linear-gradient(left, green ' + item.oee + '%, white 1%)';
                    else if (item.oee > 60)
                        item.oeeBackgroundImage = '-webkit-linear-gradient(left, yellow ' + item.oee + '%, white 1%)';
                    else
                        item.oeeBackgroundImage = '-webkit-linear-gradient(left, red ' + item.oee + '%, white 1%)';

                    if (Object.isUseful(this.indexNames[lineIndex])) {
                        if (this.getTweakValue((lineIndex * 3 + 2) + this.indexNames[lineIndex] + "_Row " + lineIndex + " override line name")) {
                            item.physicalLineId = this.getTweakValue((lineIndex * 3 + 2) + this.indexNames[lineIndex] + "_Row " + lineIndex + " override line name");
                        }
                    }
                    item.logicalLineId = "";
                    item.nextLot = "";
                    item.lineIndex = lineIndex;
                }

                this.dataCollection.push(item);

                for (let i = 0; i < errors.length; i++)
                    if (errors[i])
                        this.setError("Line {0}: {1}".format(item.physicalLineId, errors[i]));
                for (let i = 0; i < warnings.length; i++)
                    if (warnings[i])
                        this.setWarning("Line {0}: {1}".format(item.physicalLineId, warnings[i]));
                errors.clear();
                warnings.clear();

            }
            if (this.showAsMatrix) {
                if (!this.matrixInitialized) {
                    this.initMatrix();
                    this.matrixInitialized = true;
                    this.createElements();
                } else {
                    this.updateElements();
                }
            }
        },
    }
}

</script>

<style scoped>

table.v-table tbody td, table.v-table tbody th {
    height: 35px;
    margin: 0;
    padding: 0;
}

table.v-table tbody tr:not(:last-child) {
    border-bottom: none;
    margin: 0;
    padding: 0;
}

.running {
    background-color: var(--av-green);
}

.stopped {
    background-color: var(--av-red);
}

.inTime {
    background-color: var(--av-green);
}

.late {
    background-color: var(--av-red);
}

/*.canvas-gauges {*/
/*    width: 100% !important;*/
/*    height: 70% !important;*/
/*}*/

</style>
