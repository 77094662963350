import Api from '@/api/api'
import VueInstance from "./vueinstance"
import OrchestratorAPI from '@/api/orchestrator'
import Processes from '@/api/processesStatus'
import Microservices from '@/api/microservices'


export default {
    async getOpcModelByMachine(machineName) {
        let processes = await Processes.getProcessesStatusList();
        let instanceName = "";
        for (let process of processes) {
            if(Processes.getProcessType(process.microserviceName) === Processes.processTypeMap.AvOpcUa) {
                let managedMachines = await this.getManagedMachinesByInstance(Processes.getProcessName(process.microserviceName));
                if(Object.isUseful(managedMachines) && managedMachines.hasOwnProperty(machineName)) {
                    instanceName = Processes.getProcessName(managedMachines[machineName]);
                    break;
                }
            }
        }
        if(instanceName) {
            return new Promise((resolve, reject) =>{
                OrchestratorAPI.proxyCall('get', Microservices.opcuaUrl + instanceName + "/GetServerModel")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        debugger
                        reject(error);
                    });
            });
        }
    },

    getManagedMachinesByInstance(instanceName) {
        return new Promise((resolve, reject) =>{
            OrchestratorAPI.proxyCall('get', Microservices.opcuaUrl + instanceName + "/GetManagedMachineNames")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    debugger
                    reject(error);
                });

        });
    },
}