<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer"  class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card  class="ma-0 av-card" elevation="0">
            <v-card-text class="av-card-content">
                <ConfigurationsEditor :descriptor="lineSettings"  @value-Changed="valueChanged" :filters="$license.modulesList()"></ConfigurationsEditor>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
    import DateTime from "@/api/datetimeutils"

    export default {
        name: "LineSettings",
        components: {
            ConfigurationsEditor
        },
        data() {
            return {
                compatibilityMode: false,
                devices:[],
                recipes : [],
                loading: false,
                lineSettings: {
                    lineIdentification: {
                        visible: this.$grants.get().settings.lineIdentification,
                        groupName: this.$gettext("Line identification"),
                        settings:
                            {
                                customerId: {
                                    name: this.$gettext("Customer domain identificator"),
                                    value: "",
                                    description: this.$gettext("A string that identifies customer; this must be the same on all production lines in this factory"),
                                    type: "string",
                                    readOnly: true
                                },
                                factoryId: {
                                    name: this.$gettext("Factory identificator"),
                                    value: "",
                                    description: this.$gettext("A string that identifies this production factory; this must be unique among all production lines in this factory."),
                                    type: "string",
                                    readOnly: true
                                },
                                physicalLineId: {
                                    name: this.$gettext("Line identificator"),
                                    value: "",
                                    description: this.$gettext("A string that identifies this production line; set it to the physical line id."),
                                    type: "string",
                                    readOnly: true,
                                },
                                externalAlias: {
                                    name:  this.$gettext("External Alias"),
                                    value: [],
                                    description: "",
                                    type: "stringsList",
                                    multi: true,
                                    readonly: false,
                                },
                                machines : {
                                    name: this.$gettext("Line machines"),
                                    value: [],
                                    description: this.$gettext("Specify the list of machine names the line is composed of. Possibly specify them in the order they are in the line."),
                                    type: "stringsList",
                                    objectsArray: true,
                                    sortable: true,
                                    multi: true,
                                    readOnly: false, // used for all items
                                    visible: true //Line machines is unused at moment
                                },
                            }
                    },
                    productionSettings: {
                        visible: this.$grants.get().settings.nominalSpeeds || this.$grants.get().settings.lineStatusThresholds,
                        groupName: this.$gettext("Production settings"),
                        settings:
                            {
                                lineSpeeds: {
                                    name: this.$gettext("Maximum theoretical line speeds"),
                                    keysLabel: this.$gettext("Recipe identifiers"),
                                    valuesLabel: this.$gettext("Max line speed (packets per minute)"),
                                    value: [],
                                    description: this.$gettext("Specify the maximum expected line speed for each recipe. This value will be used as benchmark to calculate OEE performance component."),
                                    type: "spreadSheet",
                                    importValidator: this.lineSpeedImportValidator,
                                    ItemsAutoComplete: [],
                                    noMatchError: this.$gettext("Selected recipe is not in the data base"),
                                    multi: true,
                                    readOnly: false,
                                    visible: this.$grants.get().settings.nominalSpeeds,
                                    headers:[],
                                    maxHeight:200,
                                    fileName:"LineSpeeds_"
                                },
                                masterMachine: {
                                    name: this.$gettext("Reference machine"),
                                    description: this.$gettext("Specifies which machine among those being polled is to be used as reference to calculate line speed and status. The theoretical line speeds values should be referred to this machine. We suggest choosing the last not aggregating machine towards line output side."),
                                    options: [],
                                    value: function() {
                                        if(Array.isUseful(this.options))
                                            return this.options[0];
                                    },
                                    type: "option",
                                    readOnly: false,
                                    visible: this.$grants.get().settings.nominalSpeeds
                                },
                                lineStopThreshold: {
                                    name: this.$gettext("Line stopped packet count threshold (% of rated speed)"),
                                    value: 20,
                                    description: this.$gettext("Specifies the minimum quantity of packets that must flow through the line to consider it in running state. It must be set as a percentage of the rated recipe speed"),
                                    type: "number",
                                    readOnly: false,
                                    visible: this.$grants.get().settings.lineStatusThresholds
                                },
                                lineSteadyThreshold: {
                                    name: this.$gettext("Line slowing packet count threshold (% of rated speed)"),
                                    value: 20,
                                    description: this.$gettext("Specifies the minimum quantity of packets that must flow through the line to consider it fully running in steady state. Below this threshold and above the line stopped threshold line is marked as either ramping up or slowing down. It must be set as a percentage of the rated recipe speed"),
                                    type: "number",
                                    readOnly: false,
                                    visible: this.$grants.get().settings.lineStatusThresholds
                                },
                                targetOEE: {
                                    name: this.$gettext("Target OEE (%)"),
                                    value: 60,
                                    description: this.$gettext("Specifies the resonable target OEE for this line. This will affect the estimation of batch completion"),
                                    type: "number",
                                    readOnly: false,
                                    filters: [this.$license.PerformanceModule],
                                    visible: this.$grants.get().settings.nominalSpeeds
                                },
                            }
                    },
                    shiftsSettings: {
                        visible: this.$grants.get().settings.shifts,
                        groupName: this.$gettext("Shifts settings"),
                        settings : {
                            shiftHours: {
                                name: this.$gettext("Daily shifts"),
                                fromLabel: this.$gettext("From"),
                                toLabel: this.$gettext("to"),
                                value: [],
                                description: this.$gettext("Specify the daily shift hours for this line. Each time span represents a shift."),
                                type: "timeSpans",
                                multi: true,
                                readOnly: false,
                                copyPaste: true,
                            },
                            shiftRoles : {
                                name: this.$gettext("Shift roles"),
                                value: [],
                                description:this.$gettext("Specify the list of shift roles that will be requested when compiling the shift change form."),
                                type: "stringsList",
                                multi:true,
                                readOnly:false,
                            },
                            shiftManagers : {
                                name: this.$gettext("Shift managers"),
                                value: [],
                                description: this.$gettext("Specify the list of names of possible shift managers. They will be used to allow user choosing from a list when switching shift"),
                                type: "stringsList",
                                multi:true,
                                readOnly:false,
                            }
                        }
                    },
                    openingTimeSettings: {
                        visible: this.$grants.get().settings.standardOpeningTime,
                        groupName: this.$gettext("Standard net opening time calculation"),
                        settings: {
                            workDays: {
                                name: this.$gettext("Weekly work days"),
                                options: DateTime.daysOfWeek(),
                                value: [],
                                description: this.$gettext("Specify the days of week this line is available for production. This value will be used as benchmark to calculate OEE availability component."),
                                type: "multiChecks",
                                readOnly: false,
                            },
                            workHours: {
                                name: this.$gettext("Daily work schedule"),
                                description: this.$gettext("Specify the daily working hours this line is available for production. You can differentiate for each working day and many time spans can be specified to exclude breaks. This value will be used as benchmark to calculate OEE availability component."),
                                type: "subgroup",
                                groupParentName: "openingTimeSettings",
                                settings: {
                                    mondayWorkHours: {
                                        name: this.$gettext("Monday work schedule"),
                                        fromLabel: this.$gettext("From"),
                                        toLabel: this.$gettext("to"),
                                        value: [],
                                        type: "timeSpans",
                                        copyPaste: true,
                                        multi: true,
                                        index: 0,
                                    },
                                    tuesdayWorkHours: {
                                        name: this.$gettext("Tuesday work schedule"),
                                        fromLabel: this.$gettext("From"),
                                        toLabel: this.$gettext("to"),
                                        value: [],
                                        type: "timeSpans",
                                        copyPaste: true,
                                        multi: true,
                                        index: 1,
                                    },
                                    wednesdayWorkHours: {
                                        name: this.$gettext("Wednesday work schedule"),
                                        fromLabel: this.$gettext("From"),
                                        toLabel: this.$gettext("to"),
                                        value: [],
                                        type: "timeSpans",
                                        copyPaste: true,
                                        multi: true,
                                        index: 2,
                                    },
                                    thursdayWorkHours: {
                                        name: this.$gettext("Thursday work schedule"),
                                        fromLabel: this.$gettext("From"),
                                        toLabel: this.$gettext("to"),
                                        value: [],
                                        type: "timeSpans",
                                        copyPaste: true,
                                        multi: true,
                                        index: 3,
                                    },
                                    fridayWorkHours: {
                                        name: this.$gettext("Friday work schedule"),
                                        fromLabel: this.$gettext("From"),
                                        toLabel: this.$gettext("to"),
                                        value: [],
                                        type: "timeSpans",
                                        copyPaste: true,
                                        multi: true,
                                        index: 4,
                                    },
                                    saturdayWorkHours: {
                                        name: this.$gettext("Saturday work schedule"),
                                        fromLabel: this.$gettext("From"),
                                        toLabel: this.$gettext("to"),
                                        value: [],
                                        type: "timeSpans",
                                        copyPaste: true,
                                        multi: true,
                                        index: 5,
                                    },
                                    sundayWorkHours: {
                                        name: this.$gettext("Sunday work schedule"),
                                        fromLabel: this.$gettext("From"),
                                        toLabel: this.$gettext("to"),
                                        value: [],
                                        type: "timeSpans",
                                        copyPaste: true,
                                        multi: true,
                                        index: 6,
                                    },
                                }
                            },
                            notWorkingDaysOfYear: {
                                name: this.$gettext("Non working periods of years"),
                                fromLabel: this.$gettext("From"),
                                toLabel: this.$gettext("to"),
                                value: [],
                                description: this.$gettext("Specify the days or periods of the year during which the line is scheduled down for maintenance or holidays"),
                                type: "dateSpans",
                                multi: true,
                                readOnly: false,
                            },
                        },
                    },
                },
            }
        },
        mounted: async function () {
            if (this.$root.startedUp) {
                this.loading = true;
                this.$root.setLoading(this.loading, "");
                try {
                    // await this.loadConfiguredDevicesList();
                    await this.loadMachines();
                } finally {
                    this.loadSettings();
                    this.loading = false;
                    this.$root.setLoading(this.loading, "");
                }
            }
        },

        computed: {
            workDays() {
                return this.lineSettings.openingTimeSettings.settings.workDays.value;
            }
        },

        watch: {
            workDays() {
                this.lineSettings.openingTimeSettings.settings.workHours.settings.mondayWorkHours.visible = this.workDays.includes(0);
                this.lineSettings.openingTimeSettings.settings.workHours.settings.tuesdayWorkHours.visible = this.workDays.includes(1);
                this.lineSettings.openingTimeSettings.settings.workHours.settings.wednesdayWorkHours.visible = this.workDays.includes(2);
                this.lineSettings.openingTimeSettings.settings.workHours.settings.thursdayWorkHours.visible = this.workDays.includes(3);
                this.lineSettings.openingTimeSettings.settings.workHours.settings.fridayWorkHours.visible = this.workDays.includes(4);
                this.lineSettings.openingTimeSettings.settings.workHours.settings.saturdayWorkHours.visible = this.workDays.includes(5);
                this.lineSettings.openingTimeSettings.settings.workHours.settings.sundayWorkHours.visible = this.workDays.includes(6);
            }
        },

        methods: {
            async loadConfiguredDevicesList() {
                //Get the list of devices that has a descriptions file saved in DB
                let self = this;
                this.devices.clear();
                await this.$settings.list(this.$settings.OpcDescriptions, this.$settings.InstanceScope)
                    .then(devices => {
                        for (let i = 0; i < devices.length; i++) {
                            if (devices[i].Name)
                                self.devices.push(devices[i].Name);
                            // self.devices.push({value: devices[i].Name, readOnly: true});
                        }
                    });
            },
            loadSettings() {
                //Get line settings and merge values on descriptor
                try {
                    let self = this;
                    this.settingsValues = this.$settings.getLineSettings();
                    if (Object.isUseful(this.settingsValues)) {
                        //This variable is used to create a copy of settings to keep his old values for auditing purposes
                        let values = this.$utils.detach(this.settingsValues);
                        let i = 0;
                        //Merge settings values on descriptor
                        if (this.settingsValues) {
                            this.compatibilityMode = (Array.isUseful(values.workHours) && !Array.isArray(values.workHours[0]));
                            if(this.compatibilityMode) {
                                values.workHours = [];
                                if(!Object.isUseful(values.workDays)) {
                                    for (i=0 ; i<=6 ; i++) {
                                        values.workHours.push([]);
                                    }
                                } else
                                    for (i=0;i<=6;i++) {
                                        if(Object.isUseful(values.workDays[i])) {
                                            values.workHours.push(this.$utils.detach(this.settingsValues.workHours));
                                        } else
                                            values.workHours.push([]);
                                    }
                            }
                            this.mergeSetting(this.lineSettings.lineIdentification.settings.customerId, values.customerId);
                            this.mergeSetting(this.lineSettings.lineIdentification.settings.physicalLineId, values.physicalLineId);
                            this.mergeSetting(this.lineSettings.lineIdentification.settings.factoryId, values.factoryId);
                            this.mergeSetting(this.lineSettings.lineIdentification.settings.externalAlias, values.externalAlias);
                            this.mergeSetting(this.lineSettings.lineIdentification.settings.machines, this.parseLoadedLineMachines(values.machines));

                            this.mergeSetting(this.lineSettings.productionSettings.settings.lineSpeeds, values.lineSpeeds);
                           // this.mergeSpreadsheetSetting(this.lineSettings.productionSettings.settings.lineSpeeds,values.lineSpeeds);
                            this.mergeSetting(this.lineSettings.productionSettings.settings.masterMachine, values.masterMachine);
                            this.mergeSetting(this.lineSettings.productionSettings.settings.lineStopThreshold, values.lineStopThreshold);
                            this.mergeSetting(this.lineSettings.productionSettings.settings.targetOEE, values.targetOEE);
                            this.mergeSetting(this.lineSettings.productionSettings.settings.lineSteadyThreshold, values.lineSteadyThreshold);

                            for (let j=0; j<=values.workHours.length-1;j++){
                                let dayWeek;
                                 Object.fromEntries(
                                     Object.entries(self.lineSettings.openingTimeSettings.settings.workHours.settings).filter(function(wh) {
                                          if( wh[1].index === j)
                                            dayWeek = wh[0];
                                            return wh[1].index === j;
                                }));
                                self.mergeSetting(self.lineSettings.openingTimeSettings.settings.workHours.settings[dayWeek], values.workHours[j]);
                            }
                            // this.mergeSetting(this.lineSettings.openingTimeSettings.settings.workHours, values.workHours);
                            this.mergeSetting(this.lineSettings.openingTimeSettings.settings.workDays, (Object.isUseful(values.workDays) ? values.workDays.sort() : []));
                            this.mergeSetting(this.lineSettings.openingTimeSettings.settings.notWorkingDaysOfYear, (Object.isUseful(values.notWorkingDaysOfYear) ? values.notWorkingDaysOfYear.sort() : []));
                            this.mergeSetting(this.lineSettings.shiftsSettings.settings.shiftHours, values.shiftHours);
                            this.mergeSetting(this.lineSettings.shiftsSettings.settings.shiftRoles, values.shiftRoles);
                            this.mergeSetting(this.lineSettings.shiftsSettings.settings.shiftManagers, values.shiftManagers);
                        }
                    }
                }
                finally {
                    this.loadRecipes();
                    // this.loadMachines();
                    if(this.compatibilityMode)
                        this.valueChanged();
                }
            },
            //TODO check if useful
            // enableWorkDaysSettings() {
            //     for(let day of this.lineSettings.openingTimeSettings.settings.workDays) {
            //         this.lineSettings.openingTimeSettings.settings.insertItem
            //     }
            // },
            async valueChanged(groupName, settingName, actionObj, subGroup) {
                let trail;
                if (this.settingsValues === null)
                    this.settingsValues = {};
                //If compatibility mode , it doesn't save the audits because is an autosave ,one time only, from the old type of workhours to the new type of [[timeSpan]
                if(!this.compatibilityMode)
                    trail = this.$audits.getSettingChangedTrailObject(groupName, settingName, actionObj, this.settingsValues, this.lineSettings, ["LineSettings"], subGroup);

                // //Manage of audit trails
                // let operator = this.$root.userName;
                // let actionId = groupName + '.' + settingName;
                // let previousVal = '';
                // let nextVal = '';
                // let action = '';
                // let lenOdlObject = ((actionObj.item !== '' && Array.isUseful(this.settingsValues[settingName])) ? this.settingsValues[settingName].length : 0);
                // let lenNewObject = (actionObj.item !== '' ? this.lineSettings[groupName].settings[settingName].value.length : 0);
                //
                // //Check for added action
                // if (actionObj.action != 'loaded') {
                //     if (lenNewObject > lenOdlObject) {
                //         actionObj.action = 'added';
                //     } else if ((lenNewObject < lenOdlObject) && (this.lineSettings[groupName].settings[settingName].type === "multiChecks")) {
                //         actionObj.action = 'deleted';
                //     }
                // }
                //
                // switch (actionObj.action) {
                //     case 'loaded':
                //         action = this.$audits.items().lineSettingsImported;
                //         break;
                //     case 'added':
                //         action = this.$audits.items().lineSettingsAdded;
                //         //case of not object type component (i.e.: number ,..)
                //         if (actionObj.item === '') {
                //             if (this.lineSettings[groupName].settings[settingName].type === "multiChecks") {
                //                 nextVal = JSON.stringify((this.lineSettings[groupName].settings[settingName].option[action.item]));
                //             } else {
                //                 nextVal = JSON.stringify((this.lineSettings[groupName].settings[settingName]));
                //             }
                //         } else {
                //             if (this.lineSettings[groupName].settings[settingName].type === "multiChecks") {
                //                 [nextVal, previousVal] = this.setValueDaysWeekType(groupName, settingName);
                //             } else {
                //                 nextVal = JSON.stringify((this.lineSettings[groupName].settings[settingName].value)[actionObj.item]);
                //             }
                //         }
                //         break;
                //     case 'modified':
                //         action = this.$audits.items().lineSettingsModified;
                //         if (actionObj.item === '') {
                //             previousVal = JSON.stringify(this.settingsValues[settingName]);
                //             nextVal = JSON.stringify((this.lineSettings[groupName].settings[settingName].value));
                //         } else {
                //             previousVal = JSON.stringify(this.settingsValues[settingName][actionObj.item]);
                //             nextVal = JSON.stringify((this.lineSettings[groupName].settings[settingName].value)[actionObj.item]);
                //         }
                //         break;
                //     case 'deleted' :
                //         action = this.$audits.items().lineSettingsDeleted;
                //         if (actionObj.item === '') {
                //             previousVal = JSON.stringify(this.settingsValues[settingName]);
                //         } else {
                //             if (this.lineSettings[groupName].settings[settingName].type === "multiChecks") {
                //                 [nextVal, previousVal] = this.setValueDaysWeekType(groupName, settingName);
                //             } else {
                //                 previousVal = JSON.stringify(this.settingsValues[settingName][actionObj.item]);
                //             }
                //         }
                //         break;
                // }

                //This variable is used to create a copy of settings to keep his old values , new values and use them to save the audits
                let values = this.$utils.detach(this.lineSettings);
                let self = this;
                let workHoursValue = [];
                for (const key in values.openingTimeSettings.settings.workHours.settings) {
                    let whs = values.openingTimeSettings.settings.workHours.settings[key].value;
                    workHoursValue.push(whs);
                }

                this.settingsValues.customerId = values.lineIdentification.settings.customerId.value;
                this.settingsValues.physicalLineId = values.lineIdentification.settings.physicalLineId.value;
                this.settingsValues.factoryId = values.lineIdentification.settings.factoryId.value;
                this.settingsValues.externalAlias = values.lineIdentification.settings.externalAlias.value;
                this.settingsValues.machines = this.parseLineMachinesToSave(values.lineIdentification.settings.machines.value);

                this.settingsValues.lineSpeeds = values.productionSettings.settings.lineSpeeds.value;
                //When imported from excel, numerical recipes are imported as numbers, we need to ensure string conversion
                for(let item of this.settingsValues.lineSpeeds)
                    item.recipe = item.recipe.toString();

                this.settingsValues.masterMachine = values.productionSettings.settings.masterMachine.value;
                this.settingsValues.lineStopThreshold = values.productionSettings.settings.lineStopThreshold.value;
                this.settingsValues.targetOEE = values.productionSettings.settings.targetOEE.value;
                this.settingsValues.lineSteadyThreshold = values.productionSettings.settings.lineSteadyThreshold.value;

                // this.settingsValues.workHours = values.openingTimeSettings.settings.workHours.value;
                this.settingsValues.workHours = workHoursValue;
                let workdays = values.openingTimeSettings.settings.workDays.value;
                this.settingsValues.workDays = (Object.isUseful(workdays) ? workdays.sort() : []);
                this.settingsValues.notWorkingDaysOfYear = values.openingTimeSettings.settings.notWorkingDaysOfYear.value;
                this.settingsValues.shiftHours = values.shiftsSettings.settings.shiftHours.value;
                this.settingsValues.shiftRoles = values.shiftsSettings.settings.shiftRoles.value;
                this.settingsValues.shiftManagers = values.shiftsSettings.settings.shiftManagers.value;
                this.$settings.saveLineSettings(this.settingsValues)
                    .then(() => {
                        self.$root.checkIdentificationSettings(self.settingsValues);
                        // //check for avoiding empty "deleted" audits
                        if (!this.compatibilityMode && !(actionObj.action==='deleted' &&  typeof trail.previousVal==='undefined')) {
                            self.$audits.save(trail.operator , trail.action, trail.previousVal, trail.nextVal, trail.actionId)
                                .catch(() => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                })
                        }
                        if(this.compatibilityMode)
                            this.compatibilityMode = false;
                    })
                    .catch(err => {
                        debugger
                        self.$root.showErrorNotification(self.$gettext("An error occurred while saving settings to DB"), true);
                        self.loadSettings();
                    })
            },
            mergeSetting(target, source) {
                if (source){
                    target.value = source;
                }
            },
            loadRecipes(){

                let self = this;
                this.recipes = [];
                self.$recipes.getRecipesId()
                    .then(result => {
                        self.recipes = result.map((item) => { return item.Id } );
                        self.lineSettings.productionSettings.settings.lineSpeeds.ItemsAutoComplete = self.recipes;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(()=>{
                        self.lineSettings.productionSettings.settings.lineSpeeds.headers.push({
                            name:"recipe",
                            key:"recipe",
                            type:"autocomplete",
                            options:self.recipes,
                            contextMenu:false,
                            sortable:true,
                            editable:true,
                        });
                        self.checkRecipes();
                    });
            },

             async loadMachines() {
                this.lineSettings.productionSettings.settings.masterMachine.options = [];
                let self=this;
                this.devices.clear();
                await this.$devices.getAvailable().then(async (devs) => {
                    this.lineSettings.productionSettings.settings.masterMachine.options = await self.$aliases.getMachinesAliasesObjects(devs);
                    devs.forEach(dev=>{
                        self.devices.push(dev);
                        self.lineSettings.productionSettings.settings.lineSpeeds.headers.push({
                            name:dev,
                            key:dev,
                            type:"number",
                            contextMenu:true,
                            options:[],
                            sortable:false,
                            editable:true,
                        });
                    })
                });
            },

            //Check the matching between the recipes of lineSetting and that in the DB and advise about the missing recipes
            checkRecipes(){

                let missingRecipes = '';
                if(!Array.isUseful(this.recipes))
                    return;
                let lineSettingsRecipes = [];
                if(this.lineSettings.productionSettings.settings.lineSpeeds.value[0].key)
                    lineSettingsRecipes = this.lineSettings.productionSettings.settings.lineSpeeds.value.map(({key})=> key);
                else{
                    lineSettingsRecipes = this.lineSettings.productionSettings.settings.lineSpeeds.value.map(record=>{
                        return record.recipe
                    });
                }

                for(const inputRecipe of lineSettingsRecipes) {
                    let found = false;
                    for(const availableRecipe of this.recipes) {
                        if (this.$utils.matchWildcardString(availableRecipe, inputRecipe))
                            found = true;
                    }
                    if(!found)
                        missingRecipes += inputRecipe + ', '
                }

                if (missingRecipes)
                    this.$root.showErrorNotification(this.$gettext("These recipes are missing from DB: ") + missingRecipes.slice(0,-2), true, true);

            },

            lineSpeedImportValidator(data) {
                let shouldSkip = false;
                let response = true;

                let numbersOfHeaders = data.headers.length;
                for(let item of data.records)  {
                    if (shouldSkip) {
                        return;
            }
                    //TODO : this control should be into ImportExportExcel ?? this last component doesn't import the header of an empty column
                    if(Object.keys(item).length !== numbersOfHeaders) {
                        let missingColumn = data.headers.filter(x => !Object.keys(item).includes(x));
                        this.$root.showErrorNotification(this.$gettext("Unable to import excel. The column {0} must be filled and contain only numbers").format(missingColumn), true);
                        shouldSkip = true;
                        response = false;
                    } else {
                        Object.keys(item).forEach(key => {
                            if (shouldSkip) {
                                return;
        }
                            if (key !== 'recipe') {
                                if (isNaN(item[key])) {
                                    this.$root.showErrorNotification(this.$gettext("Unable to import excel. The column {0} must be filled and contain only numbers").format(key), true);
                                    shouldSkip = true;
                                    response = false;
    }
                            }
                        });
                    }
                }
                return response;
            },

            parseLoadedLineMachines(arr) {
                let parsedArray = [];

                if(Array.isUseful(arr)) {
                    let self = this;
                    arr.forEach(device => {
                        let obj = {"value": device, "readOnly": false} ;
                        if(self.devices.includes(device)) {
                            obj.readOnly = true;
                        }
                        parsedArray.push(obj);
                    });
                } else {
                   this.devices.forEach(device => {
                       parsedArray.push({"value": device, "readOnly": true}
                   )});
                }

                return parsedArray
            },

            parseLineMachinesToSave(arr) {
                return arr.map(a => a.value);
            },
        }
    }
</script>

<style scoped>

</style>