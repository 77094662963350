<template>
  <v-card class="pa-3 ma-3">
    <v-card-text>
      <template>
        <v-layout wrap row style="margin-top: 10px; margin-left: 30px">
          <v-flex style="max-width:500px">
            <table id="entity">
              <tr>
                <td><translate>Last edit</translate></td>
                <td>{{(new Date(this.$route.params.data["@timestamp"])).format()}}</td>
              </tr>
              <tr>
                <td><translate>Editor</translate></td>
                <td>{{this.$route.params.data.Operator}}</td>
              </tr>
              <tr>
                <td><translate>Name</translate></td>
                <td>{{this.$route.params.data.NameSpace}}</td>
              </tr>
              <tr v-for="(val, index) in source">
                <template>
                  <td>{{ index }}</td>
                  <td>{{ typeof val == 'object' ? ( Array.isArray(val) ? val.toString() : dataDetailsPrint(dataDetails, index)) : val }}</td>
                </template>
              </tr>
              <tr>
                <td><translate>Comments</translate></td>
                <td>{{this.$route.params.data.Annotation}}</td>
              </tr>
              <tr>
                <td><translate>Expiration</translate></td>
                <td>
                    <div v-if="this.$route.params.data.Validity.Mode === 'time'">
                        {{ this.$route.params.data.Validity.Value }} seconds
                    </div>
                    <div v-else-if="this.$route.params.data.Validity.Mode === 'workorder'">
                        <translate>Current workorder</translate>
                    </div>
                    <div v-else-if="this.$route.params.data.Validity.Mode === 'once'">
                        <translate>Once</translate>
                    </div>
                    <div v-else>
                        N/A
                    </div>
                </td>
              </tr>
            </table>
          </v-flex>
          <v-spacer></v-spacer>
          <av-button :text="$gettext('BACK')" color="error" @click="$router.go(-1)" buttonIcon="fas fa-arrow-left" iconColor="light"/>
        </v-layout>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  name: 'ProductionDataDetails',
  data() {
    return {
      dataDetails: {},
      entityKV: {},
      name: ""
    }
  },
  mounted() {
    this.$root.setCurrentPageTitle(this.$gettext("Active production data"));
  },
  created: function () {
    this.dataDetails = this.$route.params.data;
  },
  computed: {
      source() {
          let source = this.dataDetails[this.dataDetails.NameSpace];
          if(Object.isUseful(source["RawDocument"]))
              source = source["RawDocument"];
          return source
      }
  },
  methods: {
      dataDetailsPrint(index) {
          let data = this.source[index];
          let result = ""
          for (const dataKey in data) {
              result += dataKey + ": " + data[dataKey] + "; "
          }
          return result;
      }
  },
}
</script>

<style scoped>
.v-text-field >>> .v-counter {
  margin-top: 5px;
}

#entity {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 18px;
}

#entity td {
  border: 1px solid #ddd;
  padding: 8px;
}

#entity tr:nth-child(even) {
  background-color: #f2f2f2;
}

#entity tr:hover {
  background-color: #ddd;
}

</style>
