import OrchestratorAPI from "@/api/orchestrator";

export default {
    async deployNewProcess(device) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', "/api/v1/auth/RunNewProcess", device)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                    reject(response);
                });
        })
    },
    async relaunchProcess(device) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', "/api/v1/auth/RelaunchProcess", device)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                    reject(response);
                });
        })
    },
    async stopProcess(device) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', "/api/v1/auth/StopProcess", device)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                    reject(response);
                });
        })
    },
}