import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import VueInstance from "@/api/vueinstance";

export default {    

    // async executeTransactionRulesChain(body) {
    //     return new Promise((resolve, reject) => {
    //         OrchestratorAPI.proxyCall('post', '/api/v1/rules/manager/transaction', body)
    //             .then(result => {
    //                 debugger
    //                 if(result.error)
    //                     reject(result);
    //                 else resolve(result)
    //             })
    //             .catch(error => {
    //                 debugger
    //                 reject(error);
    //             });
    //     });
    // },

    async getIp(param, payload) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.transactionUrl + "/getIp", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                    reject(VueInstance.get().$gettext("Unable to get ip"));
                });
        })
    },

    async ExecuteTransaction(payload, update = false, exposedName = "") {
        if(exposedName) {
            payload.tag = {
                ExposedName: exposedName
            }
        }
        let action = "/execute";
        if(update)
            action = "/update";
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.transactionNoAuthUrl + action, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                    reject(VueInstance.get().$gettext("Unable to store new transaction"));
                });
        })
    },

    async entityHasTransactions(entityId) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.transactionUrl + "/count/" + entityId)
                .then(response => {
                    resolve(response > 0);
                })
                .catch(err => {
                    if(Microservices.isIndexEmptyError(err))
                        resolve(false);
                    else {
                        debugger;
                        console.log(err);
                        reject();
                    }
                });
        });
    },

    /* ORPHAN TRANSACTIONS APIs UNUSED AT MOMENT */
    async getAllOrphanTransactions() {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.transactionUrl + "/getOrphanTransactions")
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },
    async getGroupedOrphanTransactions() {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.transactionUrl + "/getGroupOrphanTransactions")
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },

    async GetReexecutionOrphanTransactions() {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.transactionUrl + "/getReexecutionOrphanTransactions")
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },

    async getOrphanTransactions(payload) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.transactionUrl + "/getOrphanTransactions", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },

    async updateOrphanTransactionsStatus(payload) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.transactionUrl + "/updateOrphanTransactionsStatus", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },

    async reExecuteOrphanTransactions(payload) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.transactionUrl + "/ReExecuteOrphanTransactions", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },

    async updateOrphanTransactionsAddEntityName(payload) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.transactionUrl + "/updateOrphanTransactionsAddEntityName", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },

    async updateOrphanTransactionsStatusAfterEntityInstanceDelete(payload) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.transactionUrl + "/UpdateOrphanTransactionsStatusAfterEntityInstanceDelete", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },

    async updateOrphanTransactionsStatusAfterEntityDelete(payload) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.transactionUrl + "/UpdateOrphanTransactionsStatusAfterEntityDelete", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    }
}
