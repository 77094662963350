import Vue from 'vue'

export default () => {
  Vue.prototype.$dialog = function (title, message, okFunction) {
    let parsedError = this.$parseError(message)
    this.$q.dialog({
      title: this.$t(title),
      message: this.$getTranslatedMessage(parsedError),
      persistent: true
    }).onOk(() => {
      if (okFunction !== undefined) {
        okFunction()
      }
    })
  }

  Vue.prototype.$confirm = function (title, message, okFunction, cancelFunction) {
    let parsedError = this.$parseError(message)
    this.$q.dialog({
      title: this.$t(title),
      message: this.$getTranslatedMessage(parsedError),
      persistent: true,
      cancel: { label: this.$t('Cancel'), flat: true },
      ok: { label: this.$t('Ok'), flat: true }
    }).onOk(() => {
      if (okFunction !== undefined) {
        okFunction()
      }
    }).onCancel(() => {
      if (cancelFunction !== undefined) {
        cancelFunction()
      }
    })
  }

  // inputType allowed: text or number
  Vue.prototype.$prompt = function (title, message, translate, inputType, initialValue, okFunction, cancelFunction) {
    let parsedMessage = this.$parseError(message)
    let messageToShow = parsedMessage.Message
    if (translate) {
      messageToShow = this.$getTranslatedMessage(parsedMessage)
    }
    this.$q.dialog({
      title: this.$t(title),
      message: messageToShow,
      prompt: {
        model: initialValue,
        type: inputType // optional
      },
      persistent: true,
      cancel: true
    }).onOk(data => {
      if (okFunction !== undefined) {
        okFunction(data)
      }
    }).onCancel(() => {
      if (cancelFunction !== undefined) {
        cancelFunction()
      }
    })
  }
}
