<template>
    <RuleBase />
</template>

<script>

    import RuleBase from '@/components/dynamic-elements/rules/RuleBase.vue';
    import RulesEngine from '@/api/rules.js';

    export default {
        name: "RuleBatch",
        extends: RuleBase,
        components: {
            RuleBase
        },
        data() {
            return {
                eventType: -1,
                eventTypes: {
                    all: 0,
                    start: 1,
                    end: 2,
                    change: 3
                }
            }
        },
        computed: {
            indices() {
                return this.properties.dataPatterns;
            }
        },
        mounted() {
            this.fixedHeight = false;
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], false));
            this.dataExplorationMode.autoDataSourcing = true;
            this.dataExplorationMode.requiresDataFiltering = false;
            this.dataExplorationMode.deviceSelectMode = false;
            this.dataExplorationMode.requiresCrossAggregation = false;
            this.dataExplorationMode.requiresFunctions = false;
            this.properties.ruleScheduling.cron = "*/1 * * * *"; //Check every minute by default
            // For future implementations
            this.visualizationTweaks = [
                {
                    name: this.$gettext("Events to notify"),
                    id: "NotifyOn",
                    type: "option",
                    options: [
                        {text: this.$gettext(''), value: ''},
                        {text: this.$gettext('All batch transitions'), value: 'all'},
                        {text: this.$gettext('Batch start'), value: 'start'},
                        {text: this.$gettext('Batch complete'), value: 'end'},
                        {text: this.$gettext('Batch change'), value: 'change'}
                    ],
                    default: function() {
                        return this.options[0];
                }
            }];
        },
        watch: {
            indices() {
                if(!this.loading)
                    this.createDataItems();
            },
            visualizationTweaks: {
                handler: function () {
                    // Event type change must trigger a recalculation of rule conditions
                    let previousEventType = this.eventType;
                    let tmp = this.eventTypes[this.getTweakValue("NotifyOn")];
                    this.eventType = Object.isUseful(tmp) ? tmp : -1;
                    if(this.eventType !== previousEventType && !(previousEventType === -1 && Array.isUseful(this.rules)))
                        this.createDataItems();

                    this.saveTweaks();
                },
                deep: true,
            },
        },
        methods: {
            workorderActiveCondition(variable) {
                return [
                    {
                        variable: variable,
                        operator: "!=",
                        value: ""
                    },
                    "AND",
                    {
                        variable: variable,
                        operator: "!=",
                        value: "NO WORKORDER"
                    }
                ]
            },
            workorderInactiveCondition(variable) {
                return [
                    {
                        variable: variable,
                        operator: "=",
                        value: ""
                    },
                    "OR",
                    {
                        variable: variable,
                        operator: "=",
                        value: "NO WORKORDER"
                    }
                ]
            },
            workorderChangedCondition(oldVariable, newVariable) {
                return [
                    {
                        variable: newVariable,
                        operator: "!=",
                        value: oldVariable
                    }
                ]
            },
            createDataItems() {
                this.dataItems.clear();
                this.filterItems.clear();
                this.aggregationItems.clear();
                this.rules.clear();
                this.dataItems.push( this.$workorders.getCurrentWorkorderQueryDescriptor(this.indices[0]) );
                let variables = RulesEngine.getVariablesDescriptors(this.dataItems);
                if(this.eventType === this.eventTypes.all || this.eventType === this.eventTypes.start)
                    this.rules.push(
                        {
                            name: "BatchStarted",
                            type: "expression",
                            value: [
                                this.workorderActiveCondition(variables[0]), //New workorder is not null or empty
                                "AND",
                                this.workorderInactiveCondition(variables[1]), //Previous workorder was null or empty
                                "AND",
                                this.workorderChangedCondition(variables[1], variables[0])  //Workorder is changed
                            ]
                        }
                    );
                if(this.eventType === this.eventTypes.all || this.eventType === this.eventTypes.end)
                    this.rules.push(
                        {
                            name: "BatchCompleted",
                            type: "expression",
                            value: [
                                this.workorderActiveCondition(variables[1]), //Previous workorder was not null or empty
                                "AND",
                                this.workorderInactiveCondition(variables[0]), //New workorder is null or empty
                                "AND",
                                this.workorderChangedCondition(variables[1], variables[0])  //Workorder is changed
                            ]
                        }
                    );
                if(this.eventType === this.eventTypes.all || this.eventType === this.eventTypes.change)
                    this.rules.push(
                        {
                            name: "BatchChanged",
                            type: "expression",
                            value: [
                                this.workorderActiveCondition(variables[1]), //Previous workorder was not null or empty
                                "AND",
                                this.workorderActiveCondition(variables[0]), //New workorder is not null or empty
                                "AND",
                                this.workorderChangedCondition(variables[1], variables[0])  //Workorder is changed
                            ]
                        }
                    );

                this.$emit('dataItemsUpdated');
            }
        }
    }

</script>

<style scoped>
    table th { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table td { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table tr { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
</style>
