<template>
    <v-icon v-if="size" :size="size" :color="iconColor" v-bind="$attrs" :id="computedID" :left="left" :small="small" @click="click"><slot /></v-icon>
    <v-icon v-else :color="iconColor" v-bind="$attrs" :id="computedID" :left="left" :small="small" @click="click"><slot /></v-icon>
</template>

<script>

    import AvPalette from '@/api/avpalette'

    export default {
        name: "AvIcon",
        props: {
            color: {
                type: String,
            },
            left: {
                type: Boolean,
                default: false
            },
            light: {
                type: Boolean,
                default:false
            },
            small: {
                type: Boolean,
                default:false
            },
            size: {
                type: [String, Number],
                default: null
            }
        },
        computed: {
            computedID () {
              if (!this.$isEmpty(this.$attrs.id)) {
                return this.$attrs.id
              }
              return 2
              // return this.toCamelCase(this.$router.history.current.name + 'Icon' + this.$slots.default[0].text)
            },
            iconColor() {
                if(this.color) {
                    return AvPalette.getAvColor(this.color);
                }
                if(this.light)
                    return '#ffffff';
                return AvPalette.colors.grey;
            }
        },
        methods: {
            click() {
                this.$emit('click');
            }
        },
    }
</script>

<style scoped>

</style>