<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" grid-list-md text-xs-center pa-3 ma-0  class="page-container" style="max-width: 100%; overflow: hidden">
        <v-card  class="mt-0 av-card" elevation="0">
            <v-card-text>
                <v-layout wrap row>
                <v-flex class="sm3 md4" v-if="viewPanel">
                    <v-card flat style="border-right: 1px solid #eee;">
                        <v-card-title>
                            <h3>Shift</h3>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text style="height:calc(95vh - 50px) ">
                            <v-form ref="form" v-model="validForm" lazy-validation style="height: 100%">
                                <div style="text-align: left; width:70%; margin:auto">
                                    <h4>
                                        <v-icon left>far fa-calendar-alt</v-icon>
                                        <span style="position: relative;top:-3px">{{this.daysOfWeek[this.selectedCell.columnIndex-1].format("dd MMMM yyyy")}}</span>
                                    </h4>
                                    <br/>
                                    <h4>
                                        <v-icon left>far fa-clock</v-icon>
                                        <span style="position: relative;top:-3px">{{this.records[this.selectedCell.row]["shifts"]}}</span>
                                    </h4>
                                </div>
                                <br/>
                                <v-flex >
                                    <template v-for="(shiftRole,index) in shiftRoles">
                                        <v-select pa-0 single-line :prefix="shiftRole + ':  '"
                                                  :items="shiftManagers" prepend-icon="far fa-user"
                                                  v-model="managers[index]"
                                                  :rules="[(v) => !!v || 'Select '+ shiftRole]"
                                                  required />
                                    </template>
                                </v-flex>
                                <v-btn v-if="scheduled" color="error" @click="saveShift(true)" flat style="position: absolute;bottom: 50px;right: 0;" >
                                    <av-icon left small color="primary">fas fa-trash</av-icon>
                                    <translate>Unschedule shift</translate>
                                </v-btn>
                            </v-form>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1"
                                   flat="flat" @click="saveShift()" :disabled="!validForm">
                                <translate>OK</translate>
                            </v-btn>
                            <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1"
                                   flat="flat" @click="viewPanel=false">
                                <translate>Cancel</translate>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
                <v-flex :class="viewPanel ? 'sm9 md8' : ''">

                    <av-spreadsheet :header-columns="headers"
                                    :rows="records"
                                    :file-name="'Scheduler'"
                                    :flat-toolbar="true"
                                    type="custom"
                                    :show-add-button="false"
                                    :show-delete-button="false"
                                    :show-edit-button="false"
                                    :enabled-formula="false"
                                    @onCellClick="onCellClick($event)"
                                    @fileLoaded="saveRecords($event)"
                                    noImportExport
                                    :toolbarLayoutPosition="{zIndex: 5}"
                    >
                        <v-layout row>
                            <v-flex xs1>
                                <v-btn icon @click="prevWeek">
                                    <av-icon color="blue">fa-chevron-left</av-icon>
                                </v-btn>
                            </v-flex>
                            <v-flex xs2>
                                <v-menu
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn text flat x-large v-on="on">{{computedDateFormatted}}</v-btn>
                                    </template>
                                    <v-date-picker
                                            v-model="date"
                                            @change="onSelectDate"
                                    ></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs1>
                                <v-btn icon @click="nextWeek">
                                    <av-icon color="blue">fa-chevron-right</av-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </av-spreadsheet>
                </v-flex>
            </v-layout>
            </v-card-text>
        </v-card>
    </v-container>

</template>

<script>

    import {TimeSpan} from '@/api/datetimeutils'

    export default {
        name: "ShiftScheduler",
        data() {
            return {
                headers: [],
                records: [],
                date: null,
                menu1: false,
                shiftHours: [],
                shiftManagers: [],
                shiftRoles: [],
                viewPanel: false,
                scheduled: false,
                daysOfWeek: [],
                selectedCell: {
                    row: 0,
                    key: "",
                    columnIndex: 0,
                },
                managers: [],
                validForm: false,
                loaded: false
            }
        },
        computed: {
            computedDateFormatted() {
                return this.date ? (new Date(this.date)).format('dd, MMMM yyyy') : ''
            },

        },
        mounted() {
            this.headers.push({
                name: "Shifts",
                key: "shifts",
                type: "number",
                contextMenu: false,
                sortable: false,
                editable: false,
            });
            let settings = this.$settings.getLineSettings();
            this.shiftHours = settings.shiftHours;
            this.shiftManagers = settings.shiftManagers;
            this.shiftRoles = settings.shiftRoles;
            this.date = new Date().toISOString().substr(0, 10);
            this.$root.setCurrentPageTitle(this.$gettext("Shifts scheduler"));
        },
        methods: {
            getDaysOfWeek() {
                let current = new Date(this.date);
                let week = [];
                // Starting Monday not Sunday
                week.push(new Date(current.setDate((current.getDate() - current.getDay()) + 1)));
                for (let i = 0; i < 6; i++) {
                    week.push(
                        new Date(current.setDate(current.getDate()+1))
                    );
                }
                this.daysOfWeek = week;
                return week;
            },
            onSelectDate() {
                this.menu1 = false;
                let days = this.getDaysOfWeek();
                let settingsName = "shift_" + days[0].format("dd_MM_yyyy") + "-" + days.last().format("dd_MM_yyyy");
                this.$settings.load(this.$settings.ShiftScheduler, settingsName)
                    .then(setting => {
                        if (setting) {
                            this.headers = setting.headers;
                            if (setting.records.length > 0) {
                                this.elaborateSettingRecords(setting.records);
                                this.checkShits(setting.records)
                            } else {
                                this.initRecords();
                            }
                        } else {
                            this.clearHeaders();
                            days.forEach(day => {
                                this.headers.push({
                                    name: day.format('dd dddd'),
                                    key: day.format('dd_MM'),
                                    type: "text",
                                    contextMenu: true,
                                    sortable: false,
                                    editable: true,
                                })
                            });
                            this.initRecords();
                        }
                    })
                    .catch(err => {
                        debugger
                    });

            },
            checkShits(settingRecords) {
                this.shiftHours.forEach(hour => {
                    let findShift = settingRecords.find(record => {
                        return record.shifts.from === hour.from && record.shifts.to === hour.to
                    });
                    if (!findShift) {
                        let objTmp = {};
                        this.headers.forEach((header, idx) => {
                            if (header.key === "shifts") {
                                objTmp[header.key] = hour.from + " - " + hour.to;
                            } else {
                                objTmp[header.key] = "";
                            }
                        });
                        this.records.push(objTmp)
                    }
                });
            },
            elaborateSettingRecords(rows) {
                let records = [];
                rows.forEach((row, idx) => {
                    let record = {};
                    for (let key in row) {
                        if (key === "shifts") {
                            record[key] = row[key].from + " - " + row[key].to;
                        } else {
                            record[key] = "";
                            if (row[key].length > 0) {
                                row[key].forEach(item => {
                                    if(item.key === "scheduled")
                                        record[key] += this.$gettext("SCHEDULED;\n");
                                    else
                                        record[key] += item.key + ": " + item.value + ";\n";
                                })
                            }
                        }
                    }
                    records.push(record);
                });
                this.records = records;
            },
            clearHeaders() {
                for (let i = this.headers.length - 1; i > 0; i--) {
                    this.headers.removeAt(i);
                }
            },
            initRecords() {
                this.records = [];
                this.shiftHours.forEach(hour => {
                    let objTmp = {};
                    this.headers.forEach((header, idx) => {
                        if (header.key === "shifts") {
                            objTmp[header.key] = hour.from + " - " + hour.to;
                        } else {
                            objTmp[header.key] = "";
                        }
                    });
                    this.records.push(objTmp)
                })
            },
            onCellClick(cell) {
                this.viewPanel = true;
                this.scheduled = false;
                this.selectedCell.row = cell.row;
                this.selectedCell.key = cell.key;
                this.selectedCell.columnIndex = cell.columnIndex;
                this.managers = [];
                if (this.records[cell.row][cell.key] && this.records[cell.row][cell.key] !== "") {
                    this.scheduled = true;
                    let cellValues = this.records[cell.row][cell.key].split(";");
                    cellValues.removeAt(0);
                    let self = this;
                    cellValues.forEach(value => {
                        if (value && value.trim() !== "")
                            self.managers.push(value.split(":")[1].trim())
                    });
                }
            },
            saveShift(unschedule = false) {
                if(unschedule)
                    this.records[this.selectedCell.row][this.selectedCell.key] = "";
                else {
                    let value = this.$gettext("SCHEDULED;\n");
                    let self = this;
                    this.managers.forEach((manager, index) => {
                        value += self.shiftRoles[index] + ": " + manager + ";\n"
                    });
                    this.records[this.selectedCell.row][this.selectedCell.key] = value;
                }
                this.viewPanel = false;
            },
            nextWeek() {
                let someDate = new Date(this.date);
                someDate.setDate(someDate.getDate() + 7);
                this.date = someDate.format("yyyy-MM-dd");
            },
            prevWeek() {
                let someDate = new Date(this.date);
                someDate.setDate(someDate.getDate() - 7);
                this.date = someDate.format("yyyy-MM-dd");
            },
            saveRecords(records) {
                let rows = [];
                records.forEach((record, idx) => {
                    let row = {};
                    for (let key in record) {
                        if (key === "shifts") {
                            let from = record[key].split("-")[0].trim();
                            let to = record[key].split("-")[1].trim();
                            row[key] = {
                                id: from + " - " + to,
                                from: from,
                                to: to
                            };
                        } else {
                            let tmpValue = [];
                            if (record[key] !== "") {
                                let cellValues = record[key].split(";");
                                //Remove scheduled string and process only roles
                                if(cellValues.length > 0) {
                                    cellValues.removeAt(0);
                                    tmpValue.push({
                                        "key": "scheduled",
                                        "value": true
                                    });
                                }
                                cellValues.forEach(value => {
                                    if (value.trim() !== "")
                                        tmpValue.push({
                                            "key": value.split(":")[0].trim(),
                                            "value": value.split(":")[1].trim()
                                        });

                                });
                            }
                            row[key] = tmpValue
                        }
                    }
                    rows.push(row);
                });
                let days = this.getDaysOfWeek();
                let settingsName = "shift_" + days[0].format("dd_MM_yyyy") + "-" + days.last().format("dd_MM_yyyy");
                let self = this;
                this.$settings.save({
                    headers: this.headers,
                    records: rows
                }, this.$settings.ShiftScheduler, settingsName)
                    .then(() => {
                        self.$audits.save(this.$root.userName , self.$audits.items().shiftScheduled, '' , [days[0].format("dd_MM_yyyy"), days.last().format("dd_MM_yyyy")], '')
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            })
                    })
                    .catch(() => {
                        debugger
                        self.$root.showErrorNotification(self.$gettext("An error occurred while saving scheduling to DB"), true);
                    })
            }
        },
        watch: {
            date() {
                this.onSelectDate();
            },
            records: {
                deep: true,
                handler() {
                    if(!this.loaded) {
                        this.loaded = true;
                        return;
                    }
                    this.saveRecords(this.records);
                }
            }
        }

    }
</script>

<style scoped>
    .page-container {
        padding: 10px 5px 0 5px;
        height: calc(100%);
    }
</style>
