export function setHeaderVisibility(context,visible){
    context.commit('setHeaderVisibility', visible)
}
export function setToolbarVisibility(context,visible){
    context.commit('setToolbarVisibility', visible)
}
export function setSidebarVisibility(context,visible){
    context.commit('setSidebarVisibility', visible)
}
export function setIsEmbedded(context,isEmbedded) {
    context.commit('setIsEmbedded', isEmbedded)
}

export function setAutoSaveEnabled(context,autoSaveEnabled) {
    context.commit('setAutoSaveEnabled', autoSaveEnabled)
}


export function setProfiles(context,profiles) {
    context.commit('setProfiles', profiles)
}

export function setUnauthenticatedLogin(context,value) {
    context.commit('setUnauthenticatedLogin', value)
}


export function setHaveHistory(context,value) {
    context.commit('setHaveHistory', value)
}