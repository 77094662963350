<template>
    <v-layout row style="max-width: 350px" v-if="confirmed" ml-3 align-center>
        <v-layout column>
            <label v-if="confirmedSearchResult.street" class="subheading pa-0 pl-1" style="width: 100%;">Street: <span class="subheading font-weight-medium pa-0">{{confirmedSearchResult.street}}</span></label>
            <label v-if="confirmedSearchResult.city" class="subheading pa-0 pl-1" style="width: 100%;">City: <span class="subheading font-weight-medium pa-0">{{confirmedSearchResult.city}}</span></label>
            <label v-if="confirmedSearchResult.state" class="subheading pa-0 pl-1" style="width: 100%;">State: <span class="subheading font-weight-medium pa-0">{{confirmedSearchResult.state}}</span></label>
            <label v-if="confirmedSearchResult.postcode" class="subheading pa-0 pl-1" style="width: 100%;">Post code: <span class="subheading font-weight-medium pa-0">{{confirmedSearchResult.postcode}}</span></label>
            <label v-if="confirmedSearchResult.country" class="subheading pa-0 pl-1" style="width: 100%;">Country: <span class="subheading font-weight-medium pa-0">{{confirmedSearchResult.country}}</span></label>
        </v-layout>
        <v-btn @click="value.match.valid = false" color="info" v-translate>Change</v-btn>
    </v-layout>
    <v-layout column v-else-if="!confirmed">
        <v-text-field label="Search location here" v-model="value.search.query" />
        <table class="subheading font-weight-regular">
            <tr><td width="20%">Street:</td><td v-for="result in value.search.results" width="20%"><span class="font-weight-medium">{{result.street}}</span></td></tr>
            <tr><td>City:</td><td v-for="result in value.search.results"><span class="font-weight-medium">{{result.city}}</span></td></tr>
            <tr><td>State:</td><td v-for="result in value.search.results"><span class="font-weight-medium">{{result.state}}</span></td></tr>
            <tr><td>Post code:</td><td v-for="result in value.search.results"><span class="font-weight-medium">{{result.postcode}}</span></td></tr>
            <tr><td>Country:</td><td v-for="result in value.search.results"><span class="font-weight-medium">{{result.country}}</span></td></tr>
            <tr>
                <td style="border-bottom: none"></td>
                <td style="border-bottom: none" v-for="(result, i) in value.search.results">
                    <v-btn v-if="value.search.results[i].locationId && !value.match.valid" @click="validate(i)" :disabled="value.match.valid" color="info" v-translate>Choose</v-btn>
                    <v-layout row v-else-if="value.match.valid && value.match.locationId === value.search.results[i].locationId">
                        <av-icon color="green">fas fa-check</av-icon>
                        <label class="subheading ml-2" v-translate>Confirmed</label>
                    </v-layout>
                </td>
            </tr>
        </table>
    </v-layout>
    <!--<label style="margin-bottom: 10px" class="subheading font-weight-regular">Street: <span class="font-weight-medium">{{value.search.street}}</span></label>-->
    <!--<label style="margin-bottom: 10px" class="subheading font-weight-regular">City: <span class="font-weight-medium">{{value.search.city}}</span></label>-->
    <!--<label style="margin-bottom: 10px" class="subheading font-weight-regular">State: <span class="font-weight-medium">{{value.search.state}}</span></label>-->
    <!--<label style="margin-bottom: 10px" class="subheading font-weight-regular">Post code: <span class="font-weight-medium">{{value.search.postcode}}</span></label>-->
    <!--<label style="margin-bottom: 10px" class="subheading font-weight-regular">Country: <span class="font-weight-medium">{{value.search.country}}</span></label>-->
    <!--<v-btn v-if="value.search.locationId && !value.match.valid" @click="validate" :disabled="value.match.valid" color="info" v-translate>Validate location</v-btn>-->
    <!--<v-layout row v-else-if="value.match.valid">-->
    <!--<av-icon color="green">fas fa-check</av-icon>-->
    <!--<label class="subheading ml-2" v-translate>Confirmed</label>-->
    <!--</v-layout>-->
</template>

<script>
    export default {
        name: "HereLocationSelector",
        data() {
            return {};
        },
        props: {
            value: {}
        },
        mounted() {
            if(!this.value || !this.value.search || !this.value.match) {
                this.value = {
                    search: {
                        query: "",
                        results: [],
                    },
                    match: {
                        street: "",
                        city: "",
                        state: "",
                        postcode: "",
                        country: "",
                        locationId: "",
                        valid: false
                    }
                }
            }
        },
        computed: {
            confirmed() {
                debugger
                return (this.value.match.valid/* && this.value.match.locationId === this.value.search.results[i].locationId*/);
            },
            confirmedSearchResult() {
                debugger
                if(Object.isUseful(this.value.match.searchIndex))
                    return (this.value.search.results[this.value.match.searchIndex]);
                else for(let [index, result] of this.value.search.results.entries()) {
                    if(result.locationId === this.value.match.locationId) {
                        this.value.match.searchIndex = index;
                        return result;
                    }
                }
            }
        },
        watch: {
            'value.search.query': function(value) {
                this.value.match.valid = false;
                this.value.search.results = [];
                if(value.length < 2)
                    return;
                fetch(`https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=T194uvwcVD_yOoMrS3OBGBRFTxsUK2TR0G9wsVUIheU&maxresults=4&query=${value}`)
                    .then(result => result.json())
                    .then(result => {
                        if(result.suggestions && result.suggestions.length > 0) {
                            for(let [index, suggestion] of result.suggestions.entries()) {
                                this.value.search.results.push(this.newLocationStruct());
                                if (suggestion.address.houseNumber && suggestion.address.street) {
                                    this.value.search.results[index].street = suggestion.address.houseNumber + " " + suggestion.address.street;
                                } else {
                                    this.value.search.results[index].street = "";
                                }
                                this.value.search.results[index].city = suggestion.address.city ? suggestion.address.city : "";
                                this.value.search.results[index].state = suggestion.address.state ? suggestion.address.state : "";
                                this.value.search.results[index].postcode = suggestion.address.postalCode ? suggestion.address.postalCode : "";
                                this.value.search.results[index].country = suggestion.address.country ? suggestion.address.country : "";
                                this.value.search.results[index].locationId = suggestion.locationId;
                            }
                        } else {
                            this.invalidateLocation();
                        }
                    }, error => {
                        console.error(error);
                        this.invalidateLocation();
                    });
            }
        },
        methods: {
            newLocationStruct() {
                return {
                    street: "",
                    city: "",
                    state: "",
                    postcode: "",
                    country: "",
                    locationId: ""
                }
            },
            invalidateLocation() {
                //Search language results
                this.value.search.results = [];
                //Standardized en results
                this.value.match.street = "";
                this.value.match.city = "";
                this.value.match.state = "";
                this.value.match.postalCode = "";
                this.value.match.country = "";
                this.value.match.locationId = "";
                this.value.match.valid = false;
            },
            validate(index) {
                fetch(`https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=T194uvwcVD_yOoMrS3OBGBRFTxsUK2TR0G9wsVUIheU&maxresults=1&language=en&locationid={0}`.format(this.value.search.results[index].locationId))
                    .then(result => result.json())
                    .then(result => {
                        debugger
                        if(result.Response && Array.isUseful(result.Response.View)) {
                            let address = result.Response.View[0].Result[0].Location.Address;
                            if(address.HouseNumber && address.Street) {
                                this.value.match.street = address.HouseNumber + " " + address.Street;
                            } else {
                                this.value.match.street = "";
                            }
                            this.value.match.city = address.City ? address.City : "";
                            this.value.match.state = address.State ? address.State : "";
                            this.value.match.postcode = address.PostalCode ? address.PostalCode : "";
                            this.value.match.country = address.Country ? address.Country : "";
                            this.value.match.locationId = result.Response.View[0].Result[0].Location.LocationId;
                            this.value.match.valid = true;
                            this.value.match.searchIndex = index;
                        } else {
                            this.invalidateLocation();
                        }
                        this.$emit("input", this.value);
                    }, error => {
                        console.error(error);
                        this.invalidateLocation();
                        this.$emit("input", this.value);
                    });
            }
        }
    }
</script>

<style scoped>

    table tr td {
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        color: black
    }

    table tr td:last-of-type {
        border-right: none;
    }

</style>