<template>
    <v-layout column class="pa-0 ma-0">
        <label class="title">{{label}}</label>
        <v-layout wrap row class="pa-1 ma-0 pt-4">
            <v-text-field :label="$gettext('Duration')" v-model="duration" class="mr-3" type="number" :disabled="disabled"></v-text-field>
            <v-select :label="$gettext('Type')" :items="durationTypes" v-model="durationMultiplier"
                      item-text="text" item-value="multiplier"  :disabled="disabled"></v-select>
        </v-layout>
<!--        <v-label >{{durationConverted}}</v-label>-->
    </v-layout>

</template>

<script>
export default {
    name: "av-duration",
    props: {
        label: {
            required: false,
            default: () => {
                return "Duration"
            }
        },
        value: {
            default: () => {
                return 0
            }
        },
        disabled: {
            default: () => {
                return false
            }
        }
    },
    data:()=>{
        return {
            duration:0,
            durationTypes:[
                {
                    text:"Milliseconds",
                    value:"ms",
                    multiplier:1,
                },
                {
                    text:"Seconds",
                    value:"s",
                    multiplier:1000,
                },
                {
                    text:"Minutes",
                    value:"m",
                    multiplier:60000,
                },
                {
                    text:"Hours",
                    value:"h",
                    multiplier:3600000,
                },
                {
                    text:"Days",
                    value:"D",
                    multiplier:86400000,
                },
                {
                    text:"Week",
                    value:"W",
                    multiplier:604800000,
                },
                {
                    text:"Month",
                    value:"M",
                    multiplier:2629800000,
                },
                {
                    text:"Years",
                    value:"Y",
                    multiplier:31557600000,
                }
            ],
            durationMultiplier:0,
            durationConverted:0,
        }
    },
    methods:{
        convertDuration(){
            this.durationConverted=this.duration*this.durationMultiplier
            this.$emit('input',this.durationConverted)
        }
    },
    mounted() {
        this.durationMultiplier=1
        if(this.value>0){
            let i=this.durationTypes.length-1
            while (this.value % this.durationTypes[i].multiplier > 0){
                i--
            }
            this.duration=this.value/this.durationTypes[i].multiplier
            this.durationMultiplier= this.durationTypes[i].multiplier
            this.durationConverted=this.value
        }
    },
    watch:{
        durationMultiplier(){
            this.convertDuration()
        },
        duration(){
            this.convertDuration()
        }
    }
}
</script>

<style scoped>

</style>