<template>
    <v-card :style="'height: {0}'.format(isMobile && !isEditMode ? getFixedHeight + 'px;' : '100%;')" :class="scope().properties.hideBorder ? 'av-card-borderless' : 'av-card'" elevation="0"
            :id="'dynamic-element-'+properties.name" data-qa-type="card" data-qa-name="dynamic-element-card">
        <div v-if="titleBarVisible" class="dynamic-element-header">
            <v-layout fill-height align-center row px-0 py-1>
                <av-icon v-if="icon" class="ml-2">{{icon}}</av-icon>
                <label class="headline dynamic-element-title" >{{ title }}</label>
                <v-spacer></v-spacer>
                <img v-show="showLoader" :src="loaderGif" style="width:50px; height:50px;" />
                <v-tooltip v-if="showManualRefresh" left bottom>
                    <v-btn fab small slot="activator" @click="dataRefresh()" data-qa-type="button" data-qa-name="time-window-refresh-data">
                        <av-icon>fas fa-sync-alt</av-icon>
                    </v-btn>
                    <span v-translate>Refresh data</span>
                </v-tooltip>
                <template v-if="timeWindowVisible && !isTemporaryHiddenTimeWindows">
                    <v-tooltip left bottom>
                        <v-btn fab small slot="activator" @click="zoomIn"  id="btn-time-window-prev" data-qa-type="button" data-qa-name="time-window-prev">
                            <av-icon>{{isTimeWindowIndexed ? 'fas fa-chevron-left' : 'fas fa-search-plus'}}</av-icon>
                        </v-btn>
                        <span>{{isTimeWindowIndexed ? $gettext('Previous') : $gettext('Zoom In')}}</span>
                    </v-tooltip>
                    <v-tooltip left bottom>
                        <div slot="activator" @click="openTimeWindowPicker" id="btn-time-window-info" class="timeWindowInfo" data-qa-type="button" data-qa-name="time-window-info">
                            {{displayedTimeWindow}}
                        </div>
                        <span v-translate>Current time window</span>
                    </v-tooltip>
                    <v-tooltip left bottom v-if="!isTimeWindowIndexed || showNextTimeWindow">
                        <v-btn fab small slot="activator" @click="zoomOut" id="btn-time-window-next" data-qa-type="button" data-qa-name="time-window-next">
                            <av-icon>{{isTimeWindowIndexed ? 'fas fa-chevron-right' : 'fas fa-search-minus'}}</av-icon>
                        </v-btn>
                        <span>{{isTimeWindowIndexed ? $gettext('Next') : $gettext('Zoom Out')}}</span>
                    </v-tooltip>
                </template>
                <v-btn v-if="isReport" fab :dark="!isExporting" small color="#f5f5f5" @click="exportReport('xlsx')" :disabled="isExporting" data-qa-type="button" data-qa-name="export-excel">
                    <av-icon medium color="#1D6F42">fas fa-file-excel</av-icon>
                </v-btn>
                <v-btn v-if="isReport" fab :dark="!isExporting" small color="#f5f5f5" @click="exportReport('pdf')" :disabled="isExporting" data-qa-type="button" data-qa-name="export-pdf">
                    <av-icon medium color="#F40F02" >fas fa-file-pdf</av-icon>
                </v-btn>
                <slot name="titleButtons"/>
                <v-menu v-if="isWidget" offset-y transition="slide-y-transition" left>
                    <v-btn v-if="!scope().properties.menuButtonHidden" slot="activator" flat icon small :disabled="isExporting" data-qa-type="button" data-qa-name="toolbar-dropdown-menu">
                        <av-icon id="toolbar-dropdown" small >fa-bars</av-icon>
                    </v-btn>
                    <v-list id="toolbar-dropdown-menu">
                        <v-list-tile v-if="!isEditMode && !splitScreenMode" data-qa-type="list-element" data-qa-name="full-screen">
                            <v-list-tile-content >
                                <span @click="onFullScreenClick" class="context-menu-span" data-qa-type="button" data-qa-name="full-screen">
                                    <av-icon small left >{{isFullScreen? 'fa-compress':'fa-expand'}}</av-icon >
                                    {{isFullScreen? 'Close Full Screen':'Full Screen'}}
                                </span>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-if="!isEditMode && !isFullScreen" data-qa-type="list-element" data-qa-name="comparison-view">
                            <v-list-tile-content >
                                <span @click="onSplitScreenClick" class="context-menu-span" data-qa-type="button" data-qa-name="comparison-view">
                                    <av-icon small left>{{ splitScreenMode ? 'fa-compress' : 'fa-divide'}}</av-icon>
                                    {{ splitScreenMode ? $gettext('Close Comparison view') : $gettext('Comparison view') }}
                                </span>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-if="!isEditMode && splitScreenMode" data-qa-type="list-element" data-qa-name="split-view">
                            <v-list-tile-content >
                                <span @click="changeViewMode" class="context-menu-span" data-qa-type="button" data-qa-name="split-view">
                                    <av-icon small left>{{ splitScreenOrientationMode ? 'fa-divide fa-rotate-90' : 'fa-divide' }}</av-icon>
                                    {{ splitScreenOrientationMode ? $gettext('Split Vertical') : $gettext('Split Horizontal') }}
                                </span>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-if="timeWindowVisible" data-qa-type="list-element" data-qa-name="time-window-rolling">
                            <v-list-tile-content >
                                <span @click="showAsTimeWindow" class="context-menu-span" data-qa-type="button" data-qa-name="time-window-rolling">
                                    <av-icon small left >fas fa-clock</av-icon >
                                    <translate>Toggle rolling time mode</translate>
                                </span>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-if="timeWindowVisible" data-qa-type="list-element" data-qa-name="time-window-sliding">
                            <v-list-tile-content >
                                <span @click="showAsTimeSlider" class="context-menu-span" data-qa-type="button" data-qa-name="time-window-sliding">
                                    <av-icon small left >fas fa-sliders-h</av-icon >
                                    <translate>Toggle sliding time mode</translate>
                                </span>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-if="canExportData" data-qa-type="list-element" data-qa-name="export-data">
                            <v-list-tile-content >
                                <span @click="exportReport('xlsx')" class="context-menu-span" data-qa-type="button" data-qa-name="export-data">
                                    <av-icon small left color="#1D6F42" >fas fa-file-excel</av-icon>
                                    <translate>Download as excel</translate>
                                </span>
                            </v-list-tile-content>
                        </v-list-tile>
                        <slot name="toogleViewMode" />
                        <v-list-tile v-if="isEditable && !isEditMode && !isLocked" data-qa-type="list-element" data-qa-name="edit-visualization">
                            <v-list-tile-content>
                                <span @click="editView()" class="context-menu-span" data-qa-type="button" data-qa-name="edit-visualization">
                                    <av-icon small left >fas fa-pen</av-icon>
                                    <translate>Edit visualization</translate>
                                </span>
                            </v-list-tile-content>
                        </v-list-tile>
                        <slot name="dropMenuItems" />
                    </v-list>
                </v-menu>
            </v-layout>
        </div>
        <TimeSpanPicker v-if="customTimePicker.show" :showDialog="customTimePicker.show" :timespan="customTimePicker.span"
                        :maxTimeWindow="scope().maxTimeWindow" @exit="customTimePickerResult($event)"/>
        <template v-if="isTimeWindowSliding">
            <v-layout row class="mx-3 mt-1">
                <label class="subheading">{{ this.scope().properties.timeWindow.getStart().format() }}</label>
                <v-spacer />
                <label class="subheading">{{ this.scope().properties.timeWindow.getEnd().format() }}</label>
            </v-layout>
            <v-range-slider
                    @change="setTimeSlider($event)"
                    :value="scope().properties.timeWindow.slidingBounds"
                    :max="scope().properties.timeWindow.predefinedDuration"
                    :min="0"
                    :step="1"
                    :thumb-size="36"
                    class="mx-3"
                    style="margin-top: -5px"
            ></v-range-slider>
        </template>
        <div ref="container" :style="activeElementAreaHeight" data-qa-type="container">
            <!-- Element content goes here -->
            <slot />
        </div>
        <template v-if="Errors.length > 0">
            <div class="overlayCoverLight">
                <v-layout row align-center fill-height ml-1>
                    <av-icon color="red" size="50">fas fa-exclamation-circle</av-icon>
                    <v-layout column align-center justify-space-around ma-5>
                        <label v-for="error in Errors" class="title" style="color: black">{{error}}</label>
                        <label v-for="warning in Warnings" class="title" style="color: black">{{warning}}</label>
                    </v-layout>
                </v-layout>
            </div>
        </template>
        <template v-else-if="Warnings.length > 0 && showWarnings">
            <div class="overlayCoverLight">
                <v-layout row align-center fill-height ml-1>
                    <av-icon color="yellow" size="50">fas fa-exclamation-triangle</av-icon>
                    <v-layout column align-center justify-space-around ma-5>
                        <label v-for="warning in Warnings" class="title" style="color: black">{{warning}}</label>
                    </v-layout>
                </v-layout>
            </div>
        </template>
    </v-card>
</template>

<script>

import DateTimeUtils, {TimeSpan} from '@/api/datetimeutils'
import DataApis from '@/api/data'
import Vue from 'vue';
import VueHtml2Canvas from 'vue-html2canvas';
import TimeSpanPicker from "@/components/utilities/TimeSpanPickerDialog"
import Grants from '@/api/grants'
import domtoimage from 'dom-to-image';
import Mutex from '@/api/mutex'
import timeTrackingDialog from "@/components/dialogs/TimeTrackingDialog";
import dynamicFilteringDialog from "@/components/dialogs/DynamicFilteringDialog";

Vue.use(VueHtml2Canvas);

    export default {

        name: 'DynamicElementBase',

        components: {TimeSpanPicker},

        data() {
            return {
                currentGlobalFilters: null,

                DYNAMIC_ELEMENT_BASE: true,  //Needed to identify base and derived element, used by scope() function
                QUERY_ERROR_MESSAGE: this.$gettext("Unable to retrieve data from DB"),
                textForField: "",
                textTitle: "",
                dataErrors: [], //Errors related to queries
                editingErrors: [], //Errors related to validation and other editing related stuff
                warnings: [], //Notify warnings to user

                /*AUTOSAVE CONTROL */
                savedName: "",  //Stores the element name saved in DB, used to understand when an element is renamed and overwrite existing instead of creating new record in DB
                saveDebouncer: null,    //Timer to avoid hammering saves when properties are changed quickly (for instance when typing title
                dataItemsChangeNotificationDebouncer: null,
                dontSave: false,    //Exclude element save during temporary params update (like global params update)
                needsScreenShot: false, //Flag is set any time element is saved. Before being destroyed if flag is set a screenshot of element is taken for the thumbnail
                thumbnailSaveTimer: null, //Timer to save periodically the element thumbnail
                saving: false,  //True during a save process
                lockTimer: null,  //Continuously updates a lock record while a user is editing current element
                locked: false,  //Tells this item is locked, used to exclude short-link to edit mode from dashboards
                deleted: false, //A commodity var set by data explorer when element is deleted from data explorer to say that it MUST not autosave on destry otherwise element will pop up back again

                loading: false,    //Exclude saving during element load, when properties are populated from DB, watchers are triggered, flag is true until loading phase extinguishes to avoid loops.
                initializing: false, //Could be set by elements that needs any initial operation to be completed before handling query data
                destroyed: false, //Set on first call of beforeDestroy to avoid multiple destroy procedures due to inheritance
                manualRefresh: false, //If set element will show the refresh button and suspend the autoupdate timer
                refreshing: false, //Flag controls the data refreshing icon
                exporting: false, //Flag controls the report exporting icon
                dataRefreshTimer: null, //Timer to refresh element data
                queryDescriptor: null, //Precompiled query descriptor for dataItems
                dataExplorationMode: {  //Modifies the way data explorer setups controls to allow editing of specific element types
                    compatibleDataPatterns: [], //Derived elements that requires specific index pattern to load data from will add their index patterns here, each index pattern is an object containing:
                                                //- an array of string tokens that must be found in index name
                                                //- a property that specifies whether a single index or many indexes matching the pattern could be selected by user
                                                //- a property that specifies weather concrete or virtual indexes (created by backend like oee and batch) should be selected
                    autoDataSourcing: false, //Derived element will set to true if it has the capability of automatically select needed data items from a specific index or set of indexes
                    deviceSelectMode: false,    //Derived element will set to true if it needs a source device to be selected to perform automatic data sourcing
                    documentSelectMode: false,   //Data explorer will allow selecting full documents from mapping. Used on entities rules in TMW
                    multipleDevicesSelection: false,    //Allows selection of a multiple devices from the list
                    enumerateLineInDevices: false,   //If true "Line" node will be added in device selection drop down to allow for example to query the line OEE
                    requiresDataExploration: true,   //Data explorer will show or not the data exploration tree
                    requiresDataFiltering: true,    //Data explorer will show the filters node
                    requiresDataSelection: true,    //Data explorer will show the data node
                    requiresCrossAggregation: true, //Data explorer will show the aggregations node
                    requiresFunctions: true, //Data explorer will show the functions node
                    requiresRulesProcessing: false,  //Data explorer will show the data processing node
                    requiresRulesConditions: false,  //Data explorer will show the rules node
                    requiresRulesOutputs: false,  //Data explorer will show the outputs node
                    enableDependentFilters: false,  //Enables dependent parametric filters controls. Currently used for parametric reports
                    fixedOutputs: false,  //If true outputs must be specified by template and cannot be added/deleted only configured
                    noDataExploration: false, //Disables all data exploration features, only leaves preview and properties
                    noPreview: false, //If set, item preview won't be visible in data explorer. Set if item has no graphical representation
                    filterVariables: "",
                    ruleType: "",
                    rulesExecutionSchedulingMode: {
                        required: false,
                        periodicOnlyMode: false,
                        fixedAbsoluteQueryWindow: false,
                        requiresFixedAbsoluteQueryWindow: false,
                        fixedPeriod: false,
                        requiresActivityDuration: false,
                        requiresTimeSchedulingRestrictions: false
                    },
                    simplifiedView: true,
                    requiresDoubleActivation: false, //If it is false data explorer will show standard Activate/Deactivate mechanism, else it will show double Activation mechanism
                },

                excludeGlobalParamsUpdate: false,   //Avoids filters updating when global params change
                customQueryHandler: null,   //Derived element can assign its own data query function to be called instead of the standard data refresh function defined by base element.
                                            //DynamicElementBase calls query API on backend to retrieve standard data, this feature is useful if a element needs to call a different API (alarms for instance)

                customTimePicker:   //Object used to manage the advanced time span picker dialog
                    {
                        show: false,
                        span: new TimeSpan(),
                    },

                visualizationTargets: [], //Derived Elements will load their targets here
                visualizationTweaks: [], //Derived Elements will load their visualization options here(it's global for the element)
                visualizationOptions: [],//Derived Elements will load their visualization options here (relating to the dataset of element)
                preferredAggregations: [],//Derived Elements will load their best options for default aggregation type to be selected when a new variable is added in data explorer
                fixedHeight: 0, //Sets element height to a fixed value thus suspending vertical reactivity. Used to show element in mobile dashboards where there is no reasonable vertical confine

                reportDataHandler: null, //TODO move to report base
                reportTemplate: null,  //TODO move to report base
                reportTemplateName: null,  //TODO move to report base

                properties: //Element properties that can be hacked by user (together with data items). This is the actual element descriptor that is saved and loaded
                {
                    name: "",   //Element name, unique in DB
                    title: "",  //Element title (the visualized header)
                    icon: "",   //An icon on the left of title
                    description: "", //A free descriptive test field.
                    dynamicDescription: [], //Used for additional information of task created with activities
                    type: "",   //Type of representation (graph, oee, semaphore etc..)
                    showTimeWindow: true, //Shows/hides the time window control
                    showTitleBar: true, //Shows/hides the title and time window bar
                    hideWarnings: false, //Shows/hides the warning area on elements. Errors are always visible
                    visualizationTweaksValues: {},  //The graphic options container
                    timeWindow: new TimeSpan(), //Time window options
                    autoRefresh: true,  //Element loads data automatically on a time base
                    dataPatterns: [],    //For elements performing automatic data collection, this will hold the selected data index patterns
                    selectedDevices: [], //For elements performing automatic data collection that require source device selection, this will hold the selected device
                    descriptorVersion: 4,   //Needed in case saved properties must be transformed to assign them to newer version element
                    dashboardToRedirect: "",
                    forcedDataRefreshInterval: 0, //If set >= 5 it will force the data refresh interval to the specified value in seconds, otherwise the refresh rate will be autocalculated based on the analysis window
                    exposableToLevels: [], //Users that can see the list of forms/reports in the  menu link
                    visibleToLevels: [],
                    editableToLevels: [],
                    sidebarVisibilityLink: true,
                    isDeployed: false,
                    deployStatus: 0,
                    aggregateDataByRow: false,

                    fillableMode: 1, //TODO move to formBase
                    //Templates can set flag to indicate that they cannot be painless deleted but requires disabling.
                    //Template must also define onEnable() and onDisable() child handlers to manage enable/disabling with their own specific logic
                    //In some cases it could nevertheless be possible for an element to be deleted, in this case element should define a child handler canBeDeleted()
                    //to enclose all the checks needed to decide if it could be just deleted or not. If handler is unspecified the default response of DynamicElementBase to canBeDeleted will be false
                    //An example usage as of March /2021 are entity forms that can only be deleted if no instances are created, otherwise can only be disabled.
                    unDeletable: false,
                    //TODO disabling framework is fully developed, yet it seemed to us a triple-headed monster
                    //with too many statuses and difficult to understand. If needed just uncomment all the stack.
                    //at moment undeletable items will just rely on the deployment status to make them system inactive
                    //disabled: false //If item is unDeletable this marks it as disabled
                    //TODO try to move away from base
                    entityName: null,
                    createdBy: "",
                    modifiedBy: "",
                    //This is free field where elements can publish their own custom information that is somewhat needed by other pages
                    //that are somewhat showing information about this item. This field is returned when calling the getMetaData of elements api
                    additionalMetaData: null,
                    ruleScheduling: {   //TODO move to ruleBase
                        executionSchedulingType: 'evaluatePeriodicallyModel',
                        oneShoot: false,
                        executeRuleOnFirstRun: false,
                        queryFromLastUsefulRuleResult: false,
                        initialReferenceTime: '',
                        cron: "0 */1 * * 0",  //Rule engine time schedule. This must be a valid cron expression to be correctly compiled and executed in backend
                        duration: 0,
                        activityDuration: 15,
                        activityTimeUnit: 'm',
                        timeSchedulingRestrictions: false,
                        workDays: [],
                        workHours: [],
                        notWorkingDaysOfYear: []
                    },
                    activityType: -1,
                    isActivity: false,
                    tags: [],
                    templateRecipe:"",
                    targetMachine:"",
                    target:"",
                    useAsDefaultRecipe:false,
                    activeVersion: 0,
                    sessionId: null,
                    deployTime: ''
                },
                deployedVersion: null,   //TODO move to properties and manage back compatibility
                version: 0,  //TODO move to properties and manage back compatibility
                saveHistory: false,

                childHandlers: {
                    //TODO put all handlers attachable by derived elements here
                    onNewElementCreated: [],  //Called when a new element is created at the end of initialization
                    onElementLoaded: [],   //Called when an existing element is loaded at the end of initialization
                    onTimeWindowUpdate: null,  //A handler to be called when the time window changes.
                    onDataItemsChanged: null,  //A handler to be called when data definition change during query building
                    onGlobalFiltersReceived: null,  //An handler to be called when global filters are received from dashboard
                    //Derived elements can assign a function that will be called every time a data blob is loaded from DB. Element can freely operate on data before binding
                    //This is actually used by some special elements that are direct extensions of some standard elements with only a few specific data tweakings.
                    //For instance, the PlantCapacity Widget is a standard WidgetGraph with a little added logic to calculate the number
                    //of lines processing and the ratio of line processing to total lines.
                    //The possibility of multiple elements inheritance to manage these cases more elegantly was briefly explored with bad results
                    //(TODO the last line of previous comment is no longer true as of March/2021. Realign mentioned elements to new multiple inheritance machanism)
                    inLineDataTransformer: null,
                    getCustomDescriptor: null,  //Child elements can use this to add their own information to descriptor on save
                    setCustomDescriptor: null,  //Child elements can use this to retrieve their own information from descriptor on load
                    canBeDeleted: null, //Child elements declared as undeletable should define this handler in case they support deletion under special circumstances
                    onDeploy: null, //Child elements can define this handler to execute any operations when deployed
                    onUnDeploy: null,  //Child elements can define this handler to execute any operations when undeployed
                    checkIsDeployed: null, //Some items requires special logics to be deployed (rules for instance)
                    //onDisable: null, //Child elements declared as undeletable should define this handler to execute any operations when disabled
                    //onEnable: null //Child elements declared as undeletable should define this handler to execute any operations when enabled
                    getDefaultName: null,  //Child elements can declare their own specific default names when element is created
                    checkItemUsefulness: null,  //If implemented base validation will call this handler instead of default one
                    getItemStatus: null, //Child elements will provide item status information (running, not running, active, draft.. etc)
                    onSavedChanges:null //Child elements can use this to dispatch event after any change is saved and version is updated
                },

                reloadDynamicLinks: false,
                containerHeight: 50,    //Continuously updated by resizer timer with the height of the element content area (full height minus title bar)
                containerWidth: 100,     //Same as before for the width
                resizer: null,      //Continuously acquires element dimensions
                expanded: false,
                editMode: false,
                thumbnail: null,
                isFullScreen: false,
                isSplitScreen: false,
                splitScreenOrientation: true,
                /* Time windows management */
                timeWindowChangeRefreshDebouncer: null, //Avoids accumulating data refreshes when user rapidly moves through time windows
                isTimeWindowIndexed: false,
                previousTimeWindow: null,
                isTimeWindowSliding: false,
                temporaryHideTimeWindows: false,    //Useful to programmatically hide time window control regardless of showTimeWindow option

                /* shared data definitions TODO move element type specific structures to derived base*/
                dataItems: [],  //Holds the structure of data to be queried and shown
                filterItems: [],  //Holds the structure of filters to be applied on data queries
                aggregationItems: [],  //Holds the structure of cross aggregations to be applied to data in post-processing
                functionItems: [], //Holds the structures to describe visualization functions
                dataItemsComponents: [],
                transactionalData: {
                    dataItems: [],
                    filterItems: [],
                    aggregationItems: [],
                    functionItems: [],
                    dataItemsComponents: [],
                },
                parameterItems: [], // Holds the structure of the optional parameters applied to element  TODO evaluate deprecation
                rules: [],  //Logical expressions used by rule engine  TODO move to rulebase
                outputs:[], //Actions descriptors used by rule engine  TODO move to rulebase
                formVariables: [],  //Forms variables structure TODO move to formbase
                queriesGrouping: null,  //Some elements like dataTable can specify queries grouping globally
                maxDataSetSize: 3000, //Some elements may request a maximum results size for rendering performance optimization
                historyItems: [],
                recipe:[],
                wizard: {}, //Wizard descriptor structure  TODO move to wizardbase
                selectedHistoryVersion: "",
                /*
                    Derived components can push here validation functions to be called to perform configuration checks.
                    Each item must be a json in the form:
                    {
                        onChange: true/false,
                        onChange: true/false,
                        onDeploy: true/false,
                        validator: function() bool
                    }
                */
                validators: [],
                isNewElement: false, //User for audits create/modify
                saveAudits: false,
                importedReportTemplate: false,
                maxTimeWindow: "",  //Some elements may reduce the max time window size to avoid dumping backend with monster queries
                fixedMaxTimeWindow: "",  //Fixed maximum time window for a widget
                dataItemsValidationMutex: false //TODO
            }
        },
        props: {},
        beforeDestroy: function() {
            //console.log("destroing " + this.scope().properties.name);
            if(this.scope().destroyed)
                return;
            //When element is no more visualized stop timers otherwise they will survive forever
            clearInterval(this.scope().saveDebouncer); //Abort pending saves since they could cause troubles and we save explicitly at the end
            clearInterval(this.scope().dataRefreshTimer);
            clearInterval(this.scope().resizer);
            clearInterval(this.scope().thumbnailSaveTimer);
            if(this.scope().editMode && this.scope().saveAudits)
                this.saveVarAudits();
            //This save is idempotent for descriptor but ensure pending saves are committed
            if(!this.scope().deleted)
                this.saveKeepingVersion();
            //Unlock item so that other people can edit it
            this.releaseLock();
            this.scope().transactionalData = {
                dataItems: [],
                filterItems: [],
                aggregationItems: [],
                functionItems: [],
                dataItemsComponents: [],
            };
            document.removeEventListener("expandElement", this.onExpandElement);
            this.scope().destroyed = true;
        },
        computed: {
            //These computed are needed to access properties with correct scope while rendering. See comment to scope() method
            isLocked() { return this.scope().locked },
            displayedTimeWindow() {
                return this.scope().properties.timeWindow.toString() + (this.isTimeToActive ? ` + ` + this.scope().properties.timeWindow.timeToToString() : "") + (this.isTimeWindowIndexed ? ` - ` + this.scope().properties.timeWindow.fromToToString() : "")
            },
            currentTimeWindow() {
                return this.scope().properties.timeWindow;
            },
            title() {
                return (this.isRule ? this.scope().properties.name : this.scope().properties.title)
            },
            icon() { return this.scope().properties.icon ? "fas fa-" + this.scope().properties.icon : "" },
            timeWindowVisible() { return this.scope().properties.showTimeWindow },
            type() { return this.$dynamicElements.getItemCategory(this.scope().properties.type) },
            isReport() { return this.scope().type === "reports" },
            isQuery() { return this.scope().type === "queries" },
            isRule() { return this.scope().type === "rules" },
            isForm() { return this.scope().type === "forms" },
            isRecipe() { return this.scope().type==="recipes"},
            isWidget() { return this.scope().type === "widgets" },
            isWizard() { return this.scope().type === "wizards" },
            titleBarVisible() { return this.scope().properties.showTitleBar },
            isMobile() { return this.$root.isMobile && this.scope().fixedHeight !== -1 },
            getFixedHeight() { return (this.scope().fixedHeight > 0 ? this.scope().fixedHeight : 450) },
            Errors() { return this.scope().dataErrors.concat(this.scope().editingErrors) },
            Warnings() { return this.scope().warnings },
            loaderGif() { return require('@/assets/avloader.png') },
            showLoader() { return this.scope().refreshing || this.scope().exporting },
            isExporting() { return this.scope().exporting },
            showManualRefresh() { return (this.scope().manualRefresh || !this.scope().properties.autoRefresh) && !this.scope().refreshing && !this.scope().exporting},
            showNextTimeWindow() { return this.isTimeWindowIndexed && this.scope().properties.timeWindow.timeWindowIndex > 1 },
            showWarnings() { return !this.scope().properties.hideWarnings },
            isEditMode() { return this.scope().editMode },
            splitScreenMode() { return this.scope().isSplitScreen },
            splitScreenOrientationMode() { return this.scope().splitScreenOrientation },
            isTemporaryHiddenTimeWindows() { return this.scope().temporaryHideTimeWindows || this.scope().properties.ruleScheduling.queryFromLastUsefulRuleResult },
            isL2() { return this.$config.isL2() },
            activeElementAreaHeight() {
                let nonUsefulHeight = this.titleBarVisible ? 60 : 0;
                nonUsefulHeight += this.isTimeWindowSliding ? 70 : 0;
                nonUsefulHeight += ((Array.isUseful(this.Errors) || (Array.isUseful(this.Warnings) && this.showWarnings)) ? 60 : 0);
                return 'height: calc(100% - {0}px);'.format(nonUsefulHeight);
            },
            isEditable() { return this.$grants.enabledForEditable(this.scope().properties.editableToLevels) && this.$grants.get().dataExploration.dataExplorer },
            canExportData() { return !this.scope().childHandlers.customQueryHandler },
            isTimeToActive() { return this.scope().properties.timeWindow.timeToPredefined && this.scope().properties.timeWindow.timeToPredefined !== 'now' },
        },
        watch: {
            'properties.sidebarVisibilityLink'() {
                this.reloadDynamicLinks = true
            },
            //On properties change, if we are not in loading phase, write back element on DB
            properties: {
                handler: function () {
                    // if(this.properties.name)
                    //     return;
                    if(this.scope().loading)
                        return;
                    this.scope().saveElement(false);
                    this.scope().startDataRefreshTimer();
                },
                deep: true,
            },
            //On data, filters and aggregations change, if we are not in loading phase, write back element data and filters on DB.
            //In any case recompile the query with updated data and execute a refresh immediately
            dataItems: {
                handler: function () {
                    if (this.scope().dataItemsValidationMutex) {
                        this.scope().dataItemsValidationMutex = false;
                        return
                    }
                    this.$nextTick(()=>{
                        let data = DataApis.dataItemsValidator(this.dataItems, this.scope().visualizationTargets);
                        if (!isNaN(data.maxAggregationWindow) && data.maxAggregationWindow > 0) {
                            this.scope().maxTimeWindow = data.maxAggregationWindow
                            if(this.properties.timeWindow.duration/60 > data.maxAggregationWindow) {
                                this.properties.timeWindow.setPredefined(data.maxAggregationWindow)
                            }
                        }
                        else
                            this.scope().maxTimeWindow = ""
                        if (data.hasSuggestions)
                            this.scope().dataItemsValidationMutex = true;
                        if (this.scope().fixedMaxTimeWindow && (this.scope().fixedMaxTimeWindow < this.scope().maxTimeWindow || this.scope().maxTimeWindow === '')) {
                            this.scope().maxTimeWindow = this.scope().fixedMaxTimeWindow
                        }
                        this.saveAndRefresh();
                    })
                },
                deep: true,
            },
            filterItems: {
                handler: function () {
                    this.saveAndRefresh();
                },
                deep: true,
            },
            aggregationItems: {
                handler: function () {
                    this.saveAndRefresh();
                },
                deep: true,
            },
            dataItemsComponents: {
                handler: function () {
                    this.saveAndRefresh();
                },
                deep: true,
            },
            parameterItems: {
                handler: function () {
                    this.recalculateQuery();
                    this.dataRefresh();
                },
                deep: true,
            },
            functionItems: {
                handler: function () {
                    this.saveAndRefresh();
                },
                deep: true,
            },
            rules: {
                handler: function () {
                    if(this.scope().loading)
                        return;
                    this.scope().saveElement(false);
                },
                deep: true,
            },
            outputs: {
                handler: function () {
                    if(this.scope().loading)
                        return;
                    this.scope().saveElement(false);
                },
                deep: true,
            },
            formVariables: {
                handler: function () {
                    if(this.scope().loading)
                        return;
                    this.scope().saveElement(false);
                },
                deep: true,
            },
            recipe: {
                handler: function () {
                    if(this.scope().loading)
                        return;
                    this.scope().saveElement(false);
                },
                deep: true,
            },
            wizard: {
                handler: function () {
                    if(this.scope().loading)
                        return;
                    this.scope().saveElement(false);
                },
                deep: true,
            }
        },
        methods: {
            invokeHandler(handler) {
                if(Array.isUseful(handler)) {
                    for(let instance of handler) {
                        if(instance)
                            instance();
                    }
                } else if (!Array.isArray(handler) && handler) {
                    handler();
                }
            },
            onExpandElement(event) {
                this.expanded = event.detail.expanded;
            },
            getHistory() {
                let self= this
                let elementType = this.scope().type
                let formName= this.scope().properties.name
                let index = "user_" + elementType + "history"
                self.$dynamicElements.GetHistoryForIndexAndName(index, formName)
                    .then(item => {
                        self.historyItems = item
                    })
            },
            // async createFirstVersion(descriptor) {
            //     let self = this;
            //     let elementType = this.scope().type;
            //     let formName = this.scope().properties.name;
            //     let index = "user_" + elementType + "history";
            //     await self.$dynamicElements.GetHistoryForIndexAndName(index, formName)
            //         .then(item => {
            //             if(!Array.isUseful(item)) {
            //                 self.scope().saveHistory = true;
            //                 descriptor.thumbnail = null;
            //                 self.scope().version = 1;
            //                 let historyName = self.scope().properties.name + "_v" + self.scope().version;
            //                 self.selectedHistoryVersion = historyName; //TODO check usage of this
            //                 let historyType = self.scope().type + "history";
            //                 descriptor.timestamp = DateTimeUtils.getRfc3339TimeStamp(new Date()); //TODO RFC3339?
            //                 descriptor.version = self.scope().version;
            //                 descriptor.properties.modifiedBy = self.$store.state.shared.loggedUserName;
            //                 if (self.$dynamicElements.isItemDeployed(descriptor))
            //                     descriptor.deployedVersion = self.scope().version; //TODO check if this is correct
            //                 self.scope().deployedVersion = descriptor.deployedVersion;
            //                 self.$dynamicElements.SaveItem(historyName, descriptor, historyType)
            //                     .finally(() => {
            //                         self.scope().saveHistory = false;
            //                         self.$emit('dataItemsUpdated');
            //                     })
            //             }
            //         })
            // },
            async getDescriptorForVersion(name, version){
                let self = this;
                let elementType = self.scope().type;
                await self.$dynamicElements.LoadItem(self.scope().properties.name, elementType)
                    .then(item => {
                        let latestDescriptor = JSON.parse(item.descriptor);
                        self.scope().deployedVersion = latestDescriptor.deployedVersion;
                        self.scope().properties.deployStatus = latestDescriptor.properties.deployStatus;
                });
                this.selectedHistoryVersion = name + '_v' + version;
                let index = "user_" + elementType + "history";
                self.$dynamicElements.DescriptorForIndexAndVersion(index, name, version)
                    .then(item => {
                        const descriptor = (JSON.parse(item[0].descriptor));
                        descriptor.deployedVersion = self.scope().deployedVersion;
                        descriptor.properties.deployStatus = self.scope().properties.deployStatus;
                        descriptor.keepSession = true
                        descriptor.sessionId = self.scope().properties.sessionId
                        this.scope().load(descriptor);
                        this.$emit('dataItemsUpdated');
                    })
            },
            pauseAutosave() {
                this.scope().dontSave = true;
            },
            resumeAutosave() {
                let self = this;
                setTimeout(() => {
                    self.dontSave = false
                }, 1000);
            },
            saveAndRefresh() {
                if(this.scope().loading || this.scope().refreshing)
                    return;
                this.recalculateQuery();
                this.scope().saveElement(false);
                this.dataRefresh();
                this.invokeDataItemsChange();
            },
            invokeDataItemsChange() {
                //In case any child template needs notification of anything changed
                if (this.scope().childHandlers.onDataItemsChanged) {
                    clearInterval(this.dataItemsChangeNotificationDebouncer);

                    this.dataItemsChangeNotificationDebouncer = setInterval((function(self) {
                        return function() {
                            clearInterval(self.dataItemsChangeNotificationDebouncer);
                            self.scope().childHandlers.onDataItemsChanged();
                        }
                    })(this), 2000);
                }
            },
            recalculateQuery() {
                let startTime = new Date();
                this.synchronizeTransactionalDescriptor();
                if(this.$config.debug) {
                    console.log("Transactional Descriptor for {0} created in {1}".format(this.scope().properties.name, new Date() - startTime))
                }
                this.scope().queryDescriptor = DataApis.getDataQueryDescriptor(
                    this.scope().transactionalData.dataItems, this.scope().transactionalData.filterItems, this.scope().transactionalData.aggregationItems,
                    this.scope().parameterItems, this.scope().transactionalData.functionItems, this.scope().maxDataSetSize, this.scope().queriesGrouping);
                if(this.$config.debug) {
                    console.log("Element {0} query created in {1}".format(this.scope().properties.name, new Date() - startTime))
                }
            },
            async loadReusableQueries() {
                for(let fn of this.scope().functionItems) {
                    if(fn.isReusableQuery) {
                        await this.$dynamicElements.LoadItem(fn.parameters[0].queryName, "queries").then(descriptor => {
                            debugger
                            fn.parameters[0].descriptor = JSON.parse(descriptor.descriptor);
                        })
                    }
                }
            },
            synchronizeTransactionalDescriptor() {
                this.scope().transactionalData.dataItems = this.$utils.detach(this.scope().dataItems);
                this.scope().transactionalData.filterItems = this.$utils.detach(this.scope().filterItems);
                this.scope().transactionalData.aggregationItems = this.$utils.detach(this.scope().aggregationItems);
                this.scope().transactionalData.functionItems = this.$utils.detach(this.scope().functionItems);
                this.scope().transactionalData.dataItemsComponents = this.$utils.detach(this.scope().dataItemsComponents);
            },
            initResizer() {
                this.updateSize();
                this.scope().resizer = setInterval((function(self) {
                    return function() {
                        self.updateSize()
                    }
                })(this), 1000);
            },
            initLocker() {
                if(this.$config.isReportingService())
                    return;
                if(this.scope().lockTimer != null)
                    this.scope().releaseLock();
                let self = this;
                let lock = function() {
                    if(self.isEditMode) {
                        Mutex.lockItem(self.scope().type, self.scope().properties.name);
                        self.scope().locked = true;
                    } else  {
                        Mutex.isItemLocked(self.scope().type, self.scope().properties.name).then(lock => { self.scope().locked = Object.isUseful(lock); })
                    }
                };
                lock(); //Execute first shot immediately
                //Then refresh every 5 secs
                this.scope().lockTimer = setInterval(() => { lock(); }, 5000);
            },
            releaseLock(lockName) {
                clearInterval(this.scope().lockTimer);
                this.scope().lockTimer = null;
                if(!lockName)
                    lockName = this.scope().properties.name;
                if(this.isEditMode)
                    return Mutex.unLockItem(this.scope().type, lockName)
            },
            async checkLocked() {
                if(!this.isEditMode)
                    return null;
                return await Mutex.isItemLocked(this.scope().type, this.scope().properties.name);
            },
            initThumbnailSaver() {
                if(this.scope().dataExplorationMode.noPreview || !this.$dynamicElements.hasThumbnail(this.scope().type))
                    return;
                this.scope().thumbnailSaveTimer = setInterval((function(self) {
                    return function() {
                        if(self.scope().needsScreenShot && !self.saving) {
                            self.scope().saveElement(true);
                            self.scope().needsScreenShot = false;
                        }
                    }
                })(this), 20000);
            },

            updateSize() {
                let container = null;
                //When using components extension, all the template stuff (including $refs) will be found in the latest
                //component of the chain. Thus, we must loop through children to find the container $ref.
                //The loop length should resemble the maximum components extension depth. Typically, our max is 2
                //(DynamicElementBase -> RuleBase -> Component OR DynamicElementBase -> SomeElement -> SomeElementExtension).
                //In the future we might have depth 3 (DynamicElementBase -> RuleBase -> SomeRule -> SomeRuleExtension).
                //We limit search depth to 5 just to be future-proof while preserving function from looping in case of troubles.
                //Typically, we'll find container in first iteration, second at least.
                let nodesCrawler = function(node) {
                    if(Object.isNestedPropertyUseful(node, "$children", 0, "$refs", "container")) {
                        container = node.$children[0].$refs.container;
                        return container;
                    }
                    if(Array.isUseful(node.$children))
                        for(let child of node.$children) {
                            let container = nodesCrawler(child);
                            if (container)
                                return container;
                        }
                };
                container = nodesCrawler(this);
                // for(let i = 0 ; i < 5 ; i++) {
                //     if(Object.isNestedPropertyUseful(node, "$children", 0, "$refs", "container")) {
                //         container = node.$children[0].$refs.container;
                //         break;
                //     }
                //     if(Array.isUseful(node.$children))
                //         node = node.$children[0];
                // }
                if(container){
                    this.containerHeight = container.clientHeight;
                    this.containerWidth = container.clientWidth;
                }
            },

            //scope() must be always called to reference current element (like this.scope().BlaBlaBla) because the usage of derived
            //element causes problems in resolving the correct version of "this" when a base element data property is accessed
            //from the base element itself or from the derived one. The solution is a defacto workaround since the described
            //behaviour appears to be unpredictable (sometimes "this" is correct some times it is not with no evident rule).
            scope() {
                //If $parent is defined it could either be DynamicElementBase if called from a derived one or it could be the Vue app instance.
                //Actually, since elements are created programmatically, the $parent property is not binded to vue app instance; $parent is always
                //undefined for DynamicElementBase or equal to DynamicElementBase for derived elements, anyway, the DYNAMIC_ELEMENT_BASE control was added
                //for future proofness in case elements will be somewhen created through a standard Vue component instantiation.
                let returning = this;
                for(;;) {
                    if (!returning.$parent)
                        return returning;
                    else {
                        if(!Object.isDefined(returning.$parent.DYNAMIC_ELEMENT_BASE))
                            return returning;
                        else
                            returning = returning.$parent;
                    }
                }
            },

            /** Errors notification **/
            getErrorTarget(editingError) {
                return editingError ? this.scope().editingErrors : this.scope().dataErrors;
            },
            setError(msg, editingError = false) {
                if(!this.scope().getErrorTarget(editingError).includes(msg))
                    this.scope().getErrorTarget(editingError).push(msg);
            },
            clearError(msg, editingError = false) {
                this.scope().getErrorTarget(editingError).removeItem(msg);
            },
            clearErrors(editingError = false) {
                this.scope().getErrorTarget(editingError).clear();
            },
            setWarning(msg) {
                if(!this.scope().warnings.includes(msg))
                    this.scope().warnings.push(msg);
            },
            clearWarning(msg) {
                this.scope().warnings.removeItem(msg);
            },
            clearWarnings() {
                this.scope().warnings.clear();
            },
            /** Utilities to manage visualization settings items **/
            getTweak(id) {
                for(let i = 0 ; i < this.scope().visualizationTweaks.length ; i++)
                    if(this.scope().visualizationTweaks[i].id === id)
                        return this.scope().visualizationTweaks[i];
                return null;
            },
            getTweakValue(id) {
                let tweak = this.getTweak(id);
                if(tweak)
                    return tweak.value;
                return null;
            },
            setTweakValue(id, value) {
                for(let i = 0 ; i < this.scope().visualizationTweaks.length ; i++)
                    if(this.scope().visualizationTweaks[i].id === id)
                        this.scope().visualizationTweaks[i].value = value;
            },
            saveTweaks() {
                if(this.scope().loading)
                    return;
                let tempVisualizationTweaksValues = {};
                this.visualizationTweaks.forEach( tweak => {
                    if(tweak && tweak.id && tweak.hasOwnProperty("value"))
                        tempVisualizationTweaksValues[tweak.id] = tweak.value;
                });
                this.properties.visualizationTweaksValues = tempVisualizationTweaksValues;  //Trigger save
            },

            isLoading() {
                return this.scope().loading;
            },

            /** Create, load, save **/
            newElement() {
                this.scope().pauseAutosave();
                try {
                    let objName = "";
                    let titleName = "";
                    setTimeout(() => { this.registerBaseValidators() }, 1000);
                    //used for audits Create/modify
                    this.isNewElement = true;
                    switch (this.scope().type) {
                        case "reports":
                            objName = this.$gettext("New Report {0}");
                            titleName = this.$gettext("New Report Title");
                            break;
                        case "rules":
                            objName = this.$gettext("New Rule {0}");
                            titleName = this.$gettext("New Rule Name");
                            break;
                        case "forms":
                            objName = this.$config.isAvionics ? this.$gettext("New Form {0}") : (this.textForField);
                            titleName = this.$config.isAvionics ? this.$gettext("New Form Name") : (this.textTitle);
                            break;
                        case "recipes":
                            objName = this.$gettext("New Recipe {0}");
                            titleName = this.$gettext("New Recipe Name");
                            break;
                        case "queries":
                            objName = this.$gettext("New Query {0}");
                            titleName = this.$gettext("");
                            break;
                        case "wizards":
                            objName = this.$gettext("New Wizard {0}");
                            titleName = this.$gettext("New Wizard Name");
                            break;
                        default:
                            objName = this.$gettext("New Widget {0}");
                            titleName = this.$gettext("New Widget Title");
                            break;
                    }

                    this.scope().properties.visibleToLevels = ["all"];
                    this.scope().properties.editableToLevels = ["all"];
                    this.scope().properties.exposableToLevels = ["all"];

                    //Check if template has anything to say regarding default name
                    if (this.scope().childHandlers.getDefaultName) {
                        let defaultName = this.scope().childHandlers.getDefaultName();
                        objName = this.$gettext("New {0} {1}").format(defaultName, "{0}");
                        titleName = this.$gettext("New {0} Title").format(defaultName);
                    }

                    //Initialize common element properties
                    //If we created a brand new element assign a default unique name
                    this.scope().loading = false;
                    this.scope().properties.name = objName.format((new Date()).format("yyyy-MM-dd HH-mm-ss"));
                    this.scope().properties.title = titleName;
                    this.scope().savedName = this.scope().properties.name;
                    this.scope().properties.createdBy = this.$root.userName;
                    this.scope().properties.modifiedBy = this.$store.state.shared.loggedUserName;
                    this.scope().properties.timestamp = new Date();
                    this.scope().properties.sessionId = new Date();
                    this.scope().mergeVisualizationTweaksValues();
                    this.scope().startDataRefreshTimer();
                    this.scope().initResizer();
                    this.scope().initLocker();
                    this.scope().initThumbnailSaver();
                    this.invokeHandler(this.childHandlers.onNewElementCreated);
                    if (this.scope().dataExplorationMode.requiresDoubleActivation)
                        this.scope().properties.deployStatus = -1;
                    else
                        this.scope().properties.deployStatus = 0;
                }
                finally {
                    //Save newly created item
                    document.addEventListener("expandElement", this.onExpandElement);
                    this.scope().saveElement(false, false, true, false);
                    this.scope().resumeAutosave();
                }
            },
            async load(descriptor, externalOptions = null) {
                this.selectedHistoryVersion = descriptor.properties.name + "_v" + descriptor.version;
                this.scope().pauseAutosave();
                this.scope().loading = true;
                try {
                    setTimeout(() => { this.registerBaseValidators() }, 1000);
                    this.mergeProperties(descriptor.properties, externalOptions);
                    this.mergeVisualizationTweaksValues();
                    this.scope().dataItems = descriptor.data || [];
                    this.scope().filterItems = descriptor.filters || [];
                    this.scope().aggregationItems = descriptor.aggregations || [];
                    this.scope().functionItems = descriptor.functions || [];
                    this.scope().dataItemsComponents = descriptor.dataItemsComponents || [];
                    this.scope().rules = descriptor.rules || [];
                    this.scope().formVariables = descriptor.formVariables || [];
                    this.scope().outputs = descriptor.outputs || [];
                    this.scope().deployedVersion = descriptor.deployedVersion;
                    this.scope().version = descriptor.version;
                    this.scope().savedName = this.scope().properties.name;
                    this.scope().reportTemplate = descriptor.reportTemplate;
                    this.scope().reportTemplateName = descriptor.reportTemplateName;
                    this.scope().recipe = descriptor.recipe;
                    this.scope().wizard = descriptor.wizard;
                    if (!descriptor.keepSession)
                        this.scope().properties.sessionId = new Date();
                    else {
                        this.scope().properties.sessionId = descriptor.sessionId
                    }
                    await this.loadReusableQueries();
                    if (this.scope().childHandlers.setCustomDescriptor) {
                        this.scope().childHandlers.setCustomDescriptor(descriptor.customDescriptor)
                    }
                    //From version to version updates may occur to object descriptors, clean-up items to keep them compatible
                    DataApis.updateDataItemsToCurrentVersion(this.scope().filterItems, this.scope().dataItems, this.scope().aggregationItems, this.scope().functionItems);
                    this.manageElementsBackwardCompatibility();
                    this.scope().$nextTick(() => {
                        this.scope().loading = false;
                        this.scope().startDataRefreshTimer();
                    });
                    this.scope().initResizer();
                    this.scope().initLocker();
                    this.scope().initThumbnailSaver();
                    this.scope().thumbnail = descriptor.thumbnail;
                    if(this.isEditMode) {
                        this.getHistory(); //Load history to obtain revisions head
                        // if (this.$dynamicElements.isItemDeployed(descriptor))
                        //     await this.createFirstVersion(descriptor); //TODO check this
                    }
                    this.invokeHandler(this.childHandlers.onElementLoaded);
                } finally {
                    document.addEventListener("expandElement", this.onExpandElement);
                    this.scope().resumeAutosave();
                }
            },
            registerBaseValidators() {
                this.scope().validators.insertItem(0, {
                    onDeploy: true,
                    onChange: false,
                    validator: this.checkDefaultName
                });
                if(this.scope().isWidget || this.scope().isReport || this.scope().isQuery) {
                    this.scope().validators.insertItem(1, {
                        onDeploy: true,
                        onChange: false,
                        validator: this.checkItemUsefulness
                    });
                }
            },
            getElementDescriptor() {
                let removePicturesFromFormVariables = function (variables, self) {
                    for (let variable of variables) {
                        variable.evidence = null;
                        variable.icon = null;
                        if (Array.isUseful(variable.children))
                            removePicturesFromFormVariables(variable.children, self);
                    }
                };
                removePicturesFromFormVariables(this.scope().formVariables, this);
                return {
                    "properties": this.scope().properties,
                    "data": this.scope().dataItems,
                    "filters": this.scope().filterItems,
                    "formVariables":this.scope().formVariables,
                    "aggregations": this.scope().aggregationItems,
                    "functions": this.scope().functionItems,
                    "dataItemsComponents": this.scope().dataItemsComponents,
                    "thumbnail": this.scope().thumbnail,
                    "queryDescriptor": this.scope().queryDescriptor,
                    "reportTemplateName": this.scope().reportTemplateName ,
                    "reportTemplate": this.scope().reportTemplate,
                    "rules": this.scope().rules,   //TODO specialize for rules only
                    "outputs": this.scope().outputs,
                    "recipe": this.scope().recipe,
                    "wizard": this.scope().wizard,
                    "customDescriptor": this.scope().childHandlers.getCustomDescriptor ? this.scope().childHandlers.getCustomDescriptor() : "",
                    "deployedVersion": this.scope().deployedVersion,
                    "version": this.scope().version
                }
            },
            saveKeepingVersion() { this.scope().saveElement(false, false, true, true) },
            saveElement(takeScreenshot, keepDeployedVersion = false, forceSave = false, keepVersion = false) {

				//Only save when is edit mode
                if(!this.isEditMode)
                    return;

                //If autosave is paused, continue only if save is explicitly forced
                if(this.dontSave && !forceSave)
                    return;

                this.saveAudits = false;

                if(this.scope().dataExplorationMode.noPreview)
                    takeScreenshot = false;

                //Reminds that we need to update thumbnail. It will be refreshed on a separate timer
                this.needsScreenShot = true;

                //Autosave is debounced to avoid hammering when user is quickly changing stuff or when all watches trigger together on load.
                //Stop any previous pending save and restart timer
                clearInterval(this.saveDebouncer);

                /* INTERNAL HANDLERS NEEDED BY SAVE, SAVE PROCEDURE COMPLETES AT THE END OF FUNCTION*/
                let saveLauncher = function(self) {
                    //So, finally we can save, notify outer world that we are saving
                    self.saving = true;
                    if(self.scope().properties.name !== self.scope().savedName) {
                        let nameValidation = self.$dynamicElements.validateName(self.scope().properties.name, self.scope().type);
                        if(nameValidation.status) {
                            let historyType = self.scope().type + "history";
                            self.$dynamicElements.UpdateHistoryAfterNameChange(historyType, self.scope().savedName, self.scope().properties.name);
                            //Delete old element before saving with new name
                            self.$dynamicElements.rawDelete(self.scope().savedName, self.scope().type)
                                .then(() => {
                                    self.releaseLock(self.scope().savedName)    //Release lock for old item name
                                        .finally(() => {
                                            self.scope().initLocker();  //Relock with new name
                                        });
                                    self.selectedHistoryVersion = self.scope().properties.name + "_v" + self.scope().version;
                                    self.scope().savedName = self.scope().properties.name;
                                    save(self);
                                    self.scope().version = +self.selectedHistoryVersion.split('_v')[1];
                                    self.scope().saveHistory = false;
                                })
                                .catch(error => {
                                    debugger
                                    console.error(error);
                                    self.saving = false;
                                    // TODO: add snackbar
                                });
                            return;
                        } else return;  //Name conflict, can't save, visual feedback of conflict is given by the property editor
                    }

                    if(takeScreenshot)
                        shotAndSave(self);
                    else {
                        save(self);
                        self.validate(true, false);
                    }
                };

                let shotAndSave = function (self) {
                    self.scope().printElement()
                        .then(shot => {
                            if(shot && shot !== "data:,")
                                self.scope().thumbnail = shot;
                            else {
                                debugger;
                            }
                            save(self);
                        })
                        .catch(err => {
                            console.log(err);
                            save(self);
                        })
                };

                let save = function(self) {
                    let descriptor = self.getElementDescriptor();

                    if (!takeScreenshot && !keepVersion) {
                        let nextVersion = 1;
                        if(self.historyItems.length > 0)
                            nextVersion = self.historyItems.length + 1;
                        descriptor.version = keepDeployedVersion ? descriptor.deployedVersion : nextVersion;
                        self.scope().version = descriptor.version;
                        self.scope().saveHistory = !(keepDeployedVersion || takeScreenshot);
                    }

                    let onSaveComplete = function(self) {
                        self.saving = false;
                        self.scope().invokeHandler(self.scope().childHandlers.onSavedChanges)
                    };
                    self.$dynamicElements.SaveItem(self.scope().properties.name, descriptor, self.scope().type)
                        .finally(() => {
							self.scope().saveAudits =  true;

                            //Tags to settings management onActivate save formName property with tag array
                            self.tagsManagement(self.scope().properties.name, descriptor);

                            //self.createFirstVersion(descriptor);
                            if (self.scope().saveHistory && self.scope().version) {
                                self.createHistoryVersion(descriptor)
                                onSaveComplete(self);
                            } else {
                                onSaveComplete(self);
                            }
                        });

                    if (takeScreenshot)
                        self.scope().needsScreenShot = false;

                    let reload = self.scope().reloadDynamicLinks;
                    if (self.scope().reloadDynamicLinks && !takeScreenshot)
                        self.scope().reloadDynamicLinks = false;
                    if (reload)
                        self.$root.reloadDynamicLinks = true;
                };

                //Execute explicitly forced saves synchronously
                if(takeScreenshot || forceSave)
                    saveLauncher(this);

                //Debounce all the others
                else this.saveDebouncer = setInterval((function(self) {
                    return function() {
                        clearInterval(self.saveDebouncer);
                        saveLauncher(self);
                    }
                })(this), 2000);
            },
            saveVarAudits(){
                let actionTrail = "";
                switch (this.scope().type) {
                    case "forms" :
                        actionTrail = this.isNewElement ? this.$audits.items().formCreated : this.$audits.items().formModified;
                        break;
                    case "reports" :
                        actionTrail = this.isNewElement ? this.$audits.items().reportCreated : this.importedReportTemplate ? this.$audits.items().reportTemplateImported : this.$audits.items().reportModified;
                        this.importedReportTemplate = false;
                        break;
                    case "rules" :
                        actionTrail = this.isNewElement ? this.$audits.items().ruleCreated : this.$audits.items().ruleModified;
                        break;
                    case "widgets" :
                        actionTrail = this.isNewElement ? this.$audits.items().widgetCreated : this.$audits.items().widgetModified;
                        break;
                    case "queries" :
                        actionTrail = this.isNewElement ? this.$audits.items().queryCreated : this.$audits.items().queryModified;
                        break;
                    case "recipes" :
                        actionTrail = this.isNewElement ? this.$audits.items().recipeCreated : this.$audits.items().recipeModified;
                        break;
                }
                let self = this;
                this.scope().saveAudits = false;
                this.$audits.save(self.$root.userName, actionTrail, "", "", this.scope().properties.name)
                    .catch(err => {
                        debugger;
                        console.error(err);
                        self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                    });
            },
            tagsManagement(formName, descriptor) {
                let self = this;
                let tags = self.$settings.getTagsSettings();
                if (!tags.hasOwnProperty('bindings'))
                    tags.bindings = {};
                if (descriptor.properties.deployStatus === 1 && descriptor.properties.tags.length > 0) {
                    tags.bindings[formName] = descriptor.properties.tags;
                }
                // Do not remove tags when form is deactivated
                // else {
                //     if (tags.bindings.hasOwnProperty(self.scope().properties.name))
                //         delete tags.bindings[self.scope().properties.name];
                // }
                self.$settings.saveTagsSettings(tags)
                    .catch(err => {
                        console.log(err);
                    })
            },
            saveActivateDeactivateAudits(){
                let actionTrail = "";
                switch (this.scope().type) {
                    case "forms" :
                        if (this.scope().properties.deployStatus > 0)
                            actionTrail =  this.$audits.items().formActivated;
                        else
                            actionTrail =  this.$audits.items().formDeactivated;
                        break;
                    case "reports" :
                        if (this.scope().properties.deployStatus > 0)
                            actionTrail =  this.$audits.items().reportActivated;
                        else
                            actionTrail =  this.$audits.items().reportDeactivated;
                        break;
                    case "widgets" :
                        if (this.scope().properties.deployStatus > 0)
                            actionTrail =  this.$audits.items().widgetActivated;
                        else
                            actionTrail =  this.$audits.items().widgetDeactivated;
                        break;
                    case "queries" :
                        if (this.scope().properties.deployStatus > 0)
                            actionTrail =  this.$audits.items().queryActivated;
                        else
                            actionTrail =  this.$audits.items().queryDeactivated;
                        break;
                    case "recipes" :
                        if (this.scope().properties.deployStatus > 0)
                            actionTrail =  this.$audits.items().recipeActivated;
                        else if (this.scope().properties.deployStatus === 0)
                            actionTrail =  this.$audits.items().recipeDeactivated;
                        else
                            actionTrail =  this.$audits.items().recipeRequestedActivation;
                }
                let self = this;
                this.scope().saveAudits = false;
                this.$audits.save(this.$root.userName, actionTrail, "", "", this.scope().properties.name)
                    .catch(err => {
                        debugger
                        self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                    });
            },
            async printElement() {
                function filter (el) {
                    if((el.scrollWidth > 2000 || el.scrollHeight > 2000) &&
                        //Manage special case for graphs
                        (el.className !== "chartjs-size-monitor-expand" && el.parentElement.className !== "chartjs-size-monitor-expand")) {
                        debugger
                        return false;
                    }
                    return true;
                }

                return domtoimage.toPng(this.scope().$el, {filter: filter})
            },
            async validate(onChange, onDeploy) {
                for(let validator of this.validators) {
                    if(validator.onChange && onChange || validator.onDeploy && onDeploy)
                        if(validator.validator) {
                            let value = await Promise.resolve(validator.validator());
                            if(!value)
                               return false;
                        }
                }
                return true;
            },
            checkDefaultName() {
                if (this.$dynamicElements.isDefaultItemName(this.properties.name)) {
                    this.$root.showErrorNotification(this.$gettext("Please provide a valid name instead of default one"), true);
                    return false;
                }
                return true;
            },
            checkItemUsefulness() {
                if(this.scope().childHandlers.checkItemUsefulness)  //If handler is customized execute the custom one
                    return this.scope().childHandlers.checkItemUsefulness();
                //Otherwise apply the base one that is fine for most widgets, reports and rules
                if (!Array.isUseful(this.aggregationItems) && !Array.isUseful(this.filterItems) && !Array.isUseful(this.dataItems) && !Array.isUseful(this.functionItems)) {
                    this.$root.showErrorNotification(this.$gettext(`No fields configured. This element is empty`), true, true);
                    return false;
                }
                return true;
            },
            onDeployOperationCompleted() {
                this.saveElement(true, true, true, true);
                this.scope().reloadDynamicLinks = true;
                this.resumeAutosave();
            },
            async deployElement() {
                if (this.scope().childHandlers.checkIsDeployed) {
                    let self = this;
                    await Promise.resolve(this.scope().childHandlers.checkIsDeployed())
                        .then((deployed) => {
                            if (deployed) {
                                self.scope().properties.deployStatus = 1;
                            } else {
                                if (self.scope().dataExplorationMode.requiresDoubleActivation)
                                    self.scope().properties.deployStatus = -1;
                                else
                                    self.scope().properties.deployStatus = 0;
                            }
                        })
                        .catch(() => {
                            if (self.scope().dataExplorationMode.requiresDoubleActivation)
                                self.scope().properties.deployStatus = -1;
                            else
                                self.scope().properties.deployStatus = 0;
                        })
                }
                if (this.scope().properties.deployStatus > 0)
                    return false;

                try {
                    this.pauseAutosave();
                    let sessionId = this.scope().properties.sessionId;
                    if (await this.validate(false, true)) {
                        let wd = this.getElementDescriptor();
                        this.scope().deployedVersion = wd.version;
                        let historyItem = this.historyItems.find(item => item.version === wd.version );
                        if(historyItem)
                            this.scope().properties.sessionId = historyItem.sessionId;
                        if (historyItem && historyItem.activeVersion > 0)
                            this.scope().properties.activeVersion = historyItem.activeVersion;
                        else {
                            this.scope().properties.activeVersion = Math.max(...this.historyItems.map(o => o.activeVersion)) + 1;
                            this.scope().properties.deployTime = new Date();
                        }
                        if (wd.version === null)
                            this.scope().deployedVersion = +this.selectedHistoryVersion.split('_v')[1];
                        this.scope().properties.deployStatus = 1;
                        this.createHistoryVersion(wd, sessionId);
                        //Perform template specific operations.
                        if (this.scope().childHandlers.onDeploy) {
                            let self = this;
                            await Promise.resolve(this.scope().childHandlers.onDeploy())
                                .catch(() => {
                                    if (self.scope().dataExplorationMode.requiresDoubleActivation)
                                        self.scope().properties.deployStatus = -1;
                                    else
                                        self.scope().properties.deployStatus = 0;
                                })
                                .finally(() => { self.onDeployOperationCompleted(); })
                        } else {
                            await this.onDeployOperationCompleted();
                            this.saveActivateDeactivateAudits()
                        }
                        return this.scope().properties.deployStatus;
                    }
                    return false;
                } catch(err) {
                    if (this.scope().dataExplorationMode.requiresDoubleActivation)
                        this.scope().properties.deployStatus = -1;
                    else
                        this.scope().properties.deployStatus = 0;
                    this.onDeployOperationCompleted();
                } finally {
                    this.scope().resumeAutosave();
                }
                return false;
            },
            async undeployElement() {
                if(this.scope().properties.deployStatus <= 0)
                    return false;
                try {
                    this.pauseAutosave();
                    if (this.scope().dataExplorationMode.requiresDoubleActivation)
                        this.scope().properties.deployStatus = -1;
                    else
                        this.scope().properties.deployStatus = 0;
                    //Perform template specific operations.
                    if (this.scope().childHandlers.onUnDeploy) {
                        let self = this;
                        Promise.resolve(this.scope().childHandlers.onUnDeploy())
                            .catch(() => { self.scope().properties.deployStatus = 1; })
                            .finally(() => { self.onDeployOperationCompleted(); })
                    } else {
                        await this.onDeployOperationCompleted();
                        this.saveActivateDeactivateAudits()
                    }
                    this.deployedVersion = null;
                    return this.scope().properties.deployStatus <= 0;
                } catch(err) {
                    this.scope().properties.deployStatus = 1;
                    this.onDeployOperationCompleted();
                } finally {
                    this.scope().resumeAutosave();
                }
                return false;
            },
            async requestActivation() {
                if (await this.validate(false, true)) {
                    this.saveActivateDeactivateAudits();
                    this.properties.deployStatus = 0;
                } else {
                    return false;
                }
            },
            hasUndeployedChanges() {
                return this.scope().properties.deployStatus > 0 && (!this.scope().deployedVersion /*Legacy*/ || this.scope().version !== this.scope().deployedVersion);
            },
            canBeDeleted() {
                //If unDeletable flag is not set it means that we may just delete it without additional burden
                if(!this.scope().properties.unDeletable)
                    return true;
                //Otherwise, we ask to template it self if it could be deleted based on it's own logic
                if(this.scope().childHandlers.canBeDeleted)
                    return this.scope().childHandlers.canBeDeleted;
                else return false;
            },

            //Function merges saved properties with default properties.
            //This allows loading elements saved with older element version
            //keeping newly defined properties.
            mergeProperties(properties, externalProperties = null) {
                //Added for rolling timeWindow bug for legacy visualizations elements
                if (properties.timeWindow)
                    properties.timeWindow.timeWindowIndex = 0;

                //Manage some fields that were renamed across versions
                //V4.0.0 visualizationTweaks renamed into elementOptions
                // if(properties.visualizationTweaksValues)
                //     properties.elementOptions = properties.visualizationTweaksValues;

                //Merge properties from saved item
                for (let attribute in properties) {
                    if(Object.isDefined(this.scope().properties[attribute]))
                        this.scope().properties[attribute] = properties[attribute];
                }
                if (externalProperties){
                    for (let attribute in externalProperties) {
                        this.scope().properties[attribute] = externalProperties[attribute].value;
                    }
                }
                //Load saved time window properties into correct object prototype
                if(properties.descriptorVersion) {
                    TimeSpan.load(this.scope().properties.timeWindow);
                    this.scope().properties.timeWindow.setHidden((this.isL2 ? [] : ['shift']));
                }
            },
            //Manage version changes back compatibility
            manageElementsBackwardCompatibility() {
                //Descriptors versions before 3 didn't support deploying. Convert items to new version if possible
                if(this.scope().properties.descriptorVersion < 3) {
                    if(this.validate(false, true)) {
                        this.scope().properties.deployStatus = 1;
                        this.scope().deployedVersion = this.scope().version;
                    }
                }
                if(this.scope().properties.descriptorVersion === 3 && this.scope().properties.isDeployed) {
                    this.scope().properties.deployStatus = 1;
                }
                this.scope().properties.descriptorVersion = 4;
            },
            //Merge saved values on descriptor or set default if newly created
            mergeVisualizationTweaksValues() {
                let self = this.scope();
                self.visualizationTweaks.forEach( tweak => {
                    self.$set(tweak, "value", self.properties.visualizationTweaksValues.hasOwnProperty(tweak.id) ? self.properties.visualizationTweaksValues[tweak.id] : tweak.default());
                });
            },

            /** Data querying **/
            startDataRefreshTimer(debounced = false) {
                if (this.$config.isReportingService()){
                    return
                }
                if(!debounced) {
                    if (this.timeWindowChangeRefreshDebouncer)
                        clearTimeout(this.timeWindowChangeRefreshDebouncer);
                    let self = this;
                    this.timeWindowChangeRefreshDebouncer = setTimeout(() => {
                        self.timeWindowChangeRefreshDebouncer = null;
                        self.startDataRefreshTimer(true);
                    }, 2000);
                    return;
                }

                if(this.scope().childHandlers.onTimeWindowUpdate)
                    this.scope().childHandlers.onTimeWindowUpdate();
                //If data refresher is running stop it and restart (for instance to change time frequency)
                if (this.scope().dataRefreshTimer)
                    clearInterval(this.scope().dataRefreshTimer);

                if(this.scope().properties.autoRefresh) {

                    let refresh = 5;
                    if (this.scope().properties.forcedDataRefreshInterval >= 5)
                        refresh = this.scope().properties.forcedDataRefreshInterval;
                    else {
                        //We increase the refresh timer by 5 seconds each 24 hours up to half-hour refresh
                        refresh = (Math.round(this.scope().properties.timeWindow.duration / (3600 * 24)) + 1) * 5;
                        if (refresh > 1800)
                            refresh = 1800;
                        this.scope().manualRefresh = (refresh > 60);
                    }

                    //Then let the timer run
                    this.scope().dataRefreshTimer = setInterval(this.dataRefresh, refresh * 1000);
                }

                //Call first run immediately to let UI show data,
                //some elements may need some initial loading before being able to display data.
                //loading flag makes first refresh wait
                let self = this;
                if(this.initializing) {
                    let firstRun = setInterval(() => {
                        if (!self.initializing) {
                            self.dataRefresh();
                            clearInterval(firstRun)
                        }
                    }, 1000);
                } else this.dataRefresh()
            },
            dataRefresh(force = false) {

                //If element is still refreshing it means that the refresh interval
                //is too tight for selected query. Just wait next tick.
                //Destroyed and loading checks are a workaround to some rare cases where element is
                // destroyed before full creation and something remains hanged
                if(!force && (this.refreshing || this.destroyed || this.loading)) {
                    return;
                }

                let self = this.scope();

                //If element registered a customized query function call it. customQueryHandler is assumed to return a result/reject promise
                if(self.customQueryHandler != null) {
                    let promise = self.customQueryHandler();
                    if(!promise)
                        return;
                    self.refreshing = true;
                    promise.then(result => {
                        if(self.refreshData)
                            self.refreshData(result);
                        //self.$emit('dataRefresh', result);
                        self.clearError(self.QUERY_ERROR_MESSAGE);
                    });
                    promise.catch(err => {
                        console.log(err);
                        self.setError(self.QUERY_ERROR_MESSAGE);
                    });
                    promise.finally(() => {
                        self.refreshing = false;
                    });
                    return;
                }

                /**** We have no custom query handler, use standard query api ****/

                //If query was not compiled yet (from data and filters descriptors to query API format), do it at first run
                //This case is hit on first element run after being loaded from DB, when data or filters are edited the query is compiled in their watch functions
                if (!self.queryDescriptor)
                    self.recalculateQuery();

                //Execute query
                if(Array.isUseful(self.queryDescriptor.agg) || Array.isUseful(self.queryDescriptor.raw) || Array.isUseful(self.queryDescriptor.info) ||
                    Array.isUseful(self.queryDescriptor.comp) || Array.isUseful(self.queryDescriptor.func) || Array.isUseful(self.transactionalData.functionItems)) {
                    self.refreshing = true;
                    DataApis.getDataBlob(self.queryDescriptor, DateTimeUtils.getRfc3339TimeStamp(self.properties.timeWindow.getStart(true)), DateTimeUtils.getRfc3339TimeStamp(self.properties.timeWindow.getEnd()))
                        .then(result => {
                            this.clearWarnings();
                            this.clearErrors();

                            //Reformat datasets to be representable
                            let data = DataApis.unwrapDataSets(self.transactionalData.dataItems, self.transactionalData.aggregationItems, self.transactionalData.functionItems, result, self.$config.debug ? result["queryIndex"] : -1, self.transactionalData.dataItemsComponents);

                            //Check weather datasets were subsampled or have error and give info to user
                            self.handleErrors(data);

                            //If element bound a customized data transformer invoke it on received data otherwise just show them, see comment to inLineDataTransformer declaration
                            if(self.childHandlers.inLineDataTransformer !== null)
                                data = self.childHandlers.inLineDataTransformer(data);

                            if(self.refreshData)
                                self.refreshData(data);

                            if (self.reportDataHandler) {
                                self.reportDataHandler(data);
                                self.reportDataHandler = null;
                            }

                            //self.$emit('dataRefresh', {source: self.properties.name, data: data});

                            self.clearError(self.QUERY_ERROR_MESSAGE);
                        })
                        .catch(err => {
                            console.log(err);
                            self.setError(self.QUERY_ERROR_MESSAGE);
                        })
                        .finally(() => {
                            self.refreshing = false;
                        })
                }
            },
            handleErrors(data) {
                let self = this;
                let subsampled = "";
                let errors = "";
                data.forEach((dataSet) => {
                    if(dataSet.subSampled)
                        subsampled += (subsampled ? ", " : " ") + dataSet.label;
                    if (dataSet.error)
                        errors += (errors ? ", " : " ") + dataSet.label;
                });
                if(subsampled)
                    self.setWarning(self.$gettext("Datasets: {0} are subsampled. To increase accuracy either reduce time window or use the rolling mode").format(subsampled));
                if(errors)
                    self.setError(self.$gettext("Unable to retrieve data for: {0}").format(errors));
            },

            /** TIME WINDOW MANAGEMENT **/
            zoomIn() {
                //FN useTimeWindowDefinedInWidget property is modified from the Widget synoptic
                if(Object.isUseful(this.scope().properties.useTimeWindowDefinedInWidget) && !this.scope().properties.useTimeWindowDefinedInWidget && this.currentTimeWindow.predefined !== this.customTimePicker.span.predefined) {
                    this.scope().properties.timeWindow.from(this.currentTimeWindow);
                } else {
                    this.isTimeWindowIndexed ? this.scope().properties.timeWindow.nextStepWindow() : this.scope().properties.timeWindow.zoomIn(this.scope().maxTimeWindow);
                }
                this.startDataRefreshTimer();
            },
            zoomOut() {
                //FN useTimeWindowDefinedInWidget property is modified from the Widget synoptic
                if(Object.isUseful(this.scope().properties.useTimeWindowDefinedInWidget) && !this.scope().properties.useTimeWindowDefinedInWidget && this.currentTimeWindow.predefined !== this.customTimePicker.span.predefined) {
                    this.scope().properties.timeWindow.from(this.currentTimeWindow);
                } else {
                    this.isTimeWindowIndexed ? this.scope().properties.timeWindow.prevStepWindow() : this.scope().properties.timeWindow.zoomOut(this.scope().maxTimeWindow);
                }
                this.startDataRefreshTimer();
            },
            openTimeWindowPicker() {
                //Ensure that data in time picker are copied from element time window and not assigned, otherwise after the first run
                //It will be no more possible to cancel the time picker without affecting the element time span
                //this.customTimePicker.span = this.properties.timeWindow; //No no no
                this.customTimePicker.span.from(this.scope().properties.timeWindow);
                this.customTimePicker.show = true;
            },
            customTimePickerResult(result) {
                this.customTimePicker.show = false;
                if(result) {
                    //FN useTimeWindowDefinedInWidget property is modified from the Widget synoptic
                    if(Object.isUseful(this.scope().properties.useTimeWindowDefinedInWidget) && !this.scope().properties.useTimeWindowDefinedInWidget && this.currentTimeWindow.predefined !== this.customTimePicker.span.predefined) {
                        this.scope().properties.timeWindow.from(this.currentTimeWindow);
                    } else {
                        //Ensure that data from time picker are copied to element time window and not assigned, otherwise after the first run
                        //It will be no more possible to cancel the time picker without affecting the element time span
                        //this.scope().properties.timeWindow = this.customTimePicker.span; //No no no
                        this.scope().properties.timeWindow.from(this.customTimePicker.span);
                    }
                }
                this.scope().properties.timeWindow.setIndexedMode(this.isTimeWindowIndexed);
                this.startDataRefreshTimer();   //Execute a refresh anyway when getting back to view
            },

            SetGlobalParams(globalParams, applySelfFiltering = false) {
                if(!globalParams)
                    return;
                //TODO clarify and cleanup applySelfFiltering, excludeGlobalParamsUpdate and onGlobalFiltersReceived handling in general
                if(!applySelfFiltering && this.scope().excludeGlobalParamsUpdate) {
                  //FN It could be useful add a property executeGlobalFilterReceived !? (or as a generic property)
                  if(this.scope().childHandlers.onGlobalFiltersReceived && globalParams.variables) {
                    return this.scope().childHandlers.onGlobalFiltersReceived(globalParams);
                  }
                  return;
                }
                this.pauseAutosave();
                let recompile = false;
                try {
                    console.log("applying global params", globalParams)
                    if (!applySelfFiltering && globalParams.time) {
                        if (this.scope().maxTimeWindow && TimeSpan.timeSpanExceedsLimit(globalParams.time.predefined, this.scope().maxTimeWindow)){
                            this.properties.timeWindow.setPredefined(this.scope().maxTimeWindow)
                        }
                        else {
                            this.scope().properties.timeWindow = this.$utils.detach(globalParams.time);
                        }
                        TimeSpan.load(this.scope().properties.timeWindow);
                        this.scope().properties.timeWindow.setHidden((this.isL2 ? [] : ['shift']));
                    }
                    if(!applySelfFiltering && this.scope().childHandlers.onGlobalFiltersReceived) {
                      recompile = this.scope().childHandlers.onGlobalFiltersReceived(globalParams);
                    } else {
                        if(globalParams.variables) {
                            //replace each dataItem and filterItem with globalkey flag with globalparams.variables
                            let applyGlobalVariableToItem = function(items, childrenNamespace) {
                                items.forEach(item => {
                                    if(item.globalKey) {
                                        recompile = true;
                                        let initialRoot = item.root;
                                        let itemRootToken = item.root.split(".");
                                        let globalParamsVarToken = globalParams.variables.split(".");
                                        if(itemRootToken.length > globalParamsVarToken.length) {
                                            let lastItemRootTokens = itemRootToken.slice(- (itemRootToken.length - globalParamsVarToken.length));
                                            item.root =  [...globalParamsVarToken, ...lastItemRootTokens].join(".");
                                        } else {
                                            item.root = globalParams.variables;
                                        }
                                        //Align dataset name in case it contains var name as default
                                        item[childrenNamespace].forEach(subitem => {
                                            subitem.name = subitem.name.replace(initialRoot, item.root)
                                        })
                                    }
                                })
                            }
                            applyGlobalVariableToItem(this.scope().dataItems, "representations");
                            applyGlobalVariableToItem(this.scope().filterItems, "filters");
                        }
                        if(globalParams.filters) {
                            this.currentGlobalFilters = globalParams.filters;
                            //Iterate over parametric filters. Assign values to filters that match those defined in global filters
                            //and unset all other so that they won't disturb query
                            this.scope().filterItems.forEach(filterItem => {
                                filterItem.filters.forEach(filter => {
                                    if (filter.parametric) {
                                        recompile = true;
                                        if (Object.isUseful(globalParams.filters[filterItem.root + "." + filterItem.name])) {
                                            filter.assigned = true;
                                            filter.conditions.map(elCondition => {
                                                if (!Array.isArray(globalParams.filters[filterItem.root + "." + filterItem.name])) {
                                                    elCondition.value = globalParams.filters[filterItem.root + "." + filterItem.name];
                                                }
                                                else {
                                                    let operators = [];
                                                    let conditions = [];
                                                    //Get All Unique operators
                                                    filter.conditions.map(elCondition => {
                                                        operators.push(elCondition.operator)
                                                    });
                                                    operators = [...new Set(operators)];
                                                    //Assign operator and value to a condition
                                                    globalParams.filters[filterItem.root + "." + filterItem.name].forEach(item => {
                                                        operators.map(operatorItem => {
                                                            conditions.push({operator: operatorItem, value: item})
                                                        })
                                                    });
                                                    filter.conditions = conditions;
                                                }
                                            });
                                        } else {
                                            filter.assigned = false;
                                        }
                                    }
                                });
                            });
                        }
                    }
                } finally {
                    this.resumeAutosave();
                }
                console.log("recompile",recompile)
                if(recompile)
                    this.recalculateQuery();
                this.startDataRefreshTimer();
            },
            importExcel(file) {
                const reader = new FileReader();

                let self = this;
                reader.onload = () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    self.reportTemplate = encoded;
                    self.reportTemplateName = file.name;
                    self.importedReportTemplate = true;
                    self.saveElement(false)
                };

                reader.readAsDataURL(file);
            },
            exportReport(format) {
                let self=this
                this.$dynamicElements.downloadReport(self.scope().properties.name, self.scope().type, format,
                    self.scope().properties.timeWindow.getStart().format(), self.scope().properties.timeWindow.getEnd().format(),
                    ()=> {
                        self.scope().exporting = true;
                    },
                    ()=> {
                        self.scope().exporting = false;
                    },
                    self.getElementDescriptor())
            },
            deleteExcel() {
                this.reportTemplateName = null;
                this.reportTemplate = null;
                this.saveElement(false);
            },
            onFullScreenClick(){
                this.isFullScreen=!this.isFullScreen;
                this.scope().$emit("OnFullScreen",this.isFullScreen);
            },
            onSplitScreenClick(){
                this.isSplitScreen=!this.splitScreenMode;
                this.scope().$emit("OnSplitScreen", this.isSplitScreen);
            },
            changeViewMode(){
                this.splitScreenOrientation=!this.splitScreenOrientationMode;
                this.scope().$emit("OnChangeViewMode",this.splitScreenOrientation);
            },
            showAsTimeWindow() {
                this.isTimeWindowIndexed =! this.isTimeWindowIndexed;
                this.scope().properties.timeWindow.setIndexedMode(this.isTimeWindowIndexed)
            },
            showAsTimeSlider() {
                this.isTimeWindowSliding = !this.isTimeWindowSliding;
                this.scope().properties.timeWindow.setSlidingMode(this.isTimeWindowSliding);
            },
            setTimeSlider(value) {
                this.scope().properties.timeWindow.setSlidingBounds(value);
                this.startDataRefreshTimer();
            },
            editView() {
                this.$router.push({path:"/explore/" + this.scope().type + "/" + new Date().toISOString(), query: { elementToLoad: this.scope().properties.name } });
            },
            getItemStatusDefault() {
                if (!this.$dynamicElements.isItemDeployed(this))
                    return {status: 0, message: this.$gettext("This item is draft")};
                return {status: 1, message: this.$gettext("This item is active")};
            },
            getItemStatus() {
                if (this.scope().childHandlers.getItemStatus)
                    return this.scope().childHandlers.getItemStatus()

                return this.getItemStatusDefault();
            },
            createHistoryVersion(descriptor, sessionId = '') {
                descriptor.thumbnail = null;
                let historyName = this.scope().properties.name + "_v" + this.scope().version;
                this.selectedHistoryVersion = historyName;
                let historyType = this.scope().type + "history";
                descriptor.properties.modifiedBy = this.$store.state.shared.loggedUserName;
                let self = this;
                this.$dynamicElements.SaveItem(historyName, descriptor, historyType)
                    .finally(() => {
                        self.scope().saveHistory = false;
                        self.scope().properties.activeVersion = 0;
                        self.scope().properties.deployTime = '';
                        if (sessionId)
                            self.scope().properties.sessionId = sessionId
                    })
            }
        },
    }

</script>

<style scoped>

    .dynamic-element-title {
        padding-left: 12px;
        line-height: 24px!important;
    }

    .dynamic-element-header {
        height: 60px;
        min-width:100%;
        padding-left:10px;
    }

    .context-menu-span{
        cursor: pointer;
    }

    .timeWindowInfo{
        background-color: #f5f5f5;
        cursor:pointer;
        text-transform: uppercase;
        font-weight: bold;
        margin:5px;
        padding:5px;
        min-width: 100px;
        max-width: 550px;
        z-index: 999;
        text-align: center;
        max-height: 40px;
        overflow-y: auto;
        white-space: nowrap;
        border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
        box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12)
    }

    .timeWindowInfo:hover{
        background-color: rgba(0,0,0,.14);
    }

</style>
