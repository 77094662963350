<template>
    <v-dialog v-model="showDialog" width="720px" height="350px" :fullscreen="$vuetify.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <v-card id="antares-annotation-dialog-card">
            <v-toolbar card dense>
                <v-toolbar-title class="headline text-xs-center">
                    {{title}}
                </v-toolbar-title>
            </v-toolbar>
            <v-layout column pa-3>
                <v-spacer />
                <v-spacer />
                <v-spacer />
                <v-spacer />
                <label v-if="!error" class="title font-weight-regular" >{{ getItemLabel(newStatus) }}</label>
                <label v-if="error" style="color: red" class="title my-3">{{error}}</label>
                <v-card-actions id="antares-annotation-dialog-card-actions" v-if="!showLoader">
                    <v-spacer></v-spacer>
                    <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="callBack(true)" v-translate>
                        OK
                    </v-btn>
                    <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat" @click="callBack(false)"  v-translate>
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
    import AvProgress from '@/components/av-components/av-progress'

    export default {
        name: "WorkorderManualLineSchedulingDialog",
        components: {AvProgress},
        data: () => {
            return {
                title: this.$gettext("Open workorder manual line Scheduling"),
                showDialog: false,
                workorder: null,
                workorderCallback: null,
                user: "",
                callerInstance: null,
                error: "",
                message: "",
            }
        },
        computed: {
        },

        methods: {
            setCaller(caller) {
                this.callerInstance = caller;
            },

            async callBack(result) {
                this.error ="";
                if (result) {
                    if(this.workorderCallBack)
                        this.workorderCallBack(this.workorder);
                    this.showDialog = false;
                    if(this.callerInstance)
                        this.callerInstance.annotationDialogOpen = false;
                }
                else { // Close Dialog
                    this.showDialog = false;
                    if(this.callerInstance)
                        this.callerInstance.annotationDialogOpen = false;
                }
            },
            Show(user, workorder, workorderCallBack) {
                this.showDialog = true;
                this.user = user;
                this.workorder = workorder;
                this.workorderCallBack = workorderCallBack;
            },
            getItemLabel(labelId) {
                return this.$gettext("Do you want to open the new page?".format(labelId));
            },
        },
    }
</script>

<style scoped>

</style>