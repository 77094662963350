<template>
    <v-card flat :style="cardStyle">
        <v-card-text>
            <v-layout column>
                <template v-for="(variable,i) in formVariables">
                    <template v-if="variable.type === 'repeatableGroup'">
                        <label class="title text-xs-left my-2" v-if="variable.title">{{variable.title}}</label>
                        <label class="text-xs-left" v-if="variable.description">{{variable.description}}</label>
                        <hr style="max-width:700px" class="my-2"/>
                        <template v-for="(subGroup, subGroupIndex) in variable.repeatableChildren">
                            <v-layout row align-center>
                                <av-button v-if="variable.repeatableChildren.length > 1" ma-0 fab buttonIcon="fa-trash" iconColor="light" color="error"
                                        @click="deleteRepeatedVariables(i, subGroupIndex)"></av-button>
                                <FormTree style="width: 100%; max-width: 600px;" :variables="subGroup" v-if="subGroup.length > 0 && !isTakingEvidence"
                                          :is-children="true" :repeatable="true" mr-3></FormTree>
                                <av-button v-if="subGroupIndex === variable.repeatableChildren.length - 1" ma-0 fab buttonIcon="fa-plus" iconColor="light" color="info"
                                       @click="addRepeatedVariables(i)"></av-button>
                            </v-layout>
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="variable.inputMode.toLowerCase() === 'scan' && !isTakingEvidence">
                            <v-layout wrap row :style="'margin-top: {0}px;'.format(repeatable ? 20 : 30)">
                                <v-flex xs1 v-if="variable.icon">
                                    <v-avatar :size="50" color="grey lighten-4">
                                        <img :src="variable.icon" alt="avatar">
                                    </v-avatar>
                                </v-flex>
                                <v-flex style="max-width:500px">
                                    <label class="title">{{variable.title}}</label><br/>
                                    <label>{{variable.value ? variable.value : variable.description}}</label>
                                </v-flex>
                                <v-flex xs2>
                                    <av-button :text="$gettext('')" fab @click="takeQrCode(i)" buttonIcon="fa fa-qrcode"/>
                                </v-flex>
                                <v-spacer></v-spacer>
                            </v-layout>
                        </template>
                        <template v-if="variable.inputMode.toLowerCase() === 'normal + scan' && !isTakingEvidence">
                            <v-layout wrap row :style="'margin-top: {0}px;'.format(repeatable ? 20 : 30)">
                                <v-flex xs1 v-if="variable.icon">
                                    <v-avatar :size="50" color="grey lighten-4">
                                        <img :src="variable.icon" alt="avatar">
                                    </v-avatar>
                                </v-flex>
                                <v-flex xs6>
                                    <v-layout row>
                                        <field :key="variable.name + variable.type + variable.inputMode"
                                                :variable="variable"
                                               :onlyShowVarName="isMachine"
                                               v-model="variable.value"
                                               @onVariableChange="onVariableChange($event)"
                                               :dynamicID="variable.name"
                                               :restricted-mode="variable.restrictedMode"
                                               :disabled="variable.primaryCharacteristic && Object.isUseful(entity) && entity.id !== ''"
                                               :fullFormVariables="fullFormVariables"
                                               :valuesChangedEvent="valuesChangedEvent"/>
                                        <av-button :text="$gettext('')" fab v-if="requirePhoto(variable)"
                                                   @click="takeEvidence(i)" buttonIcon="fa fa-camera"/>
                                        <v-avatar v-if="requirePhoto(variable) && variable.evidence" :size="70"
                                                  color="grey lighten-4">
                                            <img :src="variable.evidence">
                                        </v-avatar>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs2>
                                    <av-button :text="$gettext('')" fab @click="takeQrCode(i)" buttonIcon="fa fa-qrcode"/>
                                </v-flex>
                                <v-spacer></v-spacer>
                            </v-layout>
                        </template>
                        <template v-if="variable.inputMode.toLowerCase()!=='scan' && variable.inputMode.toLowerCase()!=='normal + scan' && !isTakingEvidence && $utils.isVariableUnusefulOrTrue(variable.userExposed)">
                            <v-layout row :style="'width: 100%; margin-top: {0}px;'.format(repeatable ? 20 : 30)">
                                <template v-if="variable.icon">
                                    <v-flex xs1 style="max-width: 100px; padding-top: 10px">
                                        <v-avatar :size="50" color="grey lighten-4">
                                            <img :src="variable.icon" alt="avatar">
                                        </v-avatar>
                                    </v-flex>
                                    <div style="width: 30px"/>
                                </template>
                                <v-flex xs10>
                                    <v-layout row>
                                        <field :key="variable.name + variable.type + variable.inputMode"
                                               :variable="variable"
                                               :onlyShowVarName="isMachine"
                                               v-model="variable.value"
                                               @onVariableChange="onVariableChange($event)"
                                               :dynamicID="variable.name"
                                               :restricted-mode="variable.restrictedMode"
                                               :disabled="variable.primaryCharacteristic && Object.isUseful(entity) && entity.id !== ''"
                                               :fullFormVariables="fullFormVariables"
                                               :valuesChangedEvent="valuesChangedEvent"/>
                                        <av-button :text="$gettext('')" fab v-if="requirePhoto(variable)"
                                                   @click="takeEvidence(i)" buttonIcon="fa fa-camera"/>
                                        <v-avatar v-if="requirePhoto(variable) && variable.evidence" :size="70"
                                                  color="grey lighten-4">
                                            <img :src="variable.evidence">
                                        </v-avatar>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </template>
                        <FormTree @onVariableChange="onVariableChange($event)" :variables="variable.children" :fullFormVariables="fullFormVariables"
                                  v-if="showChildren(variable)" :is-children="true" :repeatable="variable.type === 'repeatableGroup'" :valuesChangedEvent="valuesChangedEvent"></FormTree>
                    </template>
                    <PictureShooter @done="onEvidence($event,variable,i)" @qrDone="onQrRead($event,variable,i)"
                                    :qrDecode="qrCode" class="elevation-2 ma-2"
                                    v-if="Array.isUseful(takingEvidence) && takingEvidence[i].value"
                                    style="z-index: 999999;"/>
                </template>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>

    import FormTree from './FormTree'
    import Field from './Field'
    import PictureShooter from "../../graphics/PictureShooter";

    export default {
        name: "FormTree",
        components: {FormTree, PictureShooter, Field},
        props: {
            variables: {
                default() {
                    return []
                }
            },
            isChildren: {
                default() {
                    return false
                }
            },
            repeatable: {
                default() {
                    return false
                }
            },
            entity: {
                default() {
                    return null
                }
            },
            isMachine: Boolean,
            fullFormVariables: {
                default() {
                    return []
                }
            },
            valuesChangedEvent: {
                default() {
                    return 0;
                }
            }
        },

        data: function () {
            return {
                takingEvidence: [],
                qrCode: false,
                isTakingEvidence: false,
                variablesDisplay: [],
            }
        },
        mounted() {
            let self = this;
            this.variables.forEach(() => {
                self.takingEvidence.push({value: false});
            });
        },
        computed: {
            cardStyle() {
                return {
                    padding: '0',
                    margin: !this.isChildren ? '0' : '0 0 0 40px',
                }
            },
            isVariableValid() {
                return variable => {
                    return this.$dataEntry.validateVariable(variable)
                }
            },
            requirePhoto() {
                return variable => {
                    return variable.requireEvidence === 'photo';
                }
            },
            showChildren() {
                return variable => {
                    let valid = this.isVariableValid(variable);
                    if(variable.type === 'Boolean') {
                        valid = variable.value;
                    }
                    return Array.isUseful(variable.children) && !this.isTakingEvidence && valid;
                }
            },
            formVariables() {
                return this.variables;
                // if (this.entity) {
                //     this.$dataEntry.unwrapEntity(this.entity, this.variables, false);
                    // let sourceDocument = this.entity[this.entity.NameSpace];
                    // if(Object.isUseful(sourceDocument["RawDocument"]))
                    //     sourceDocument = sourceDocument["RawDocument"];
                    // let varLoop = function (variables, source, self) {
                    //     for (let variable of variables) {
                    //         if (source.hasOwnProperty(variable.name)) {
                    //             if (variable.type === "repeatableGroup") {
                    //                 variable.repeatableChildren = [];
                    //                 for (let subValues of source[variable.name]) {
                    //                     variable.repeatableChildren.push(self.$utils.detach(variable.children));
                    //                     varLoop(variable.repeatableChildren.last(), subValues, self);
                    //                 }
                    //                 continue;
                    //             }
                    //             let childrenNameSpace = source;
                    //             if (variable.type === "Boolean" && Array.isUseful(variable.children)) {
                    //                 variable.value = true;
                    //                 childrenNameSpace = source[variable.name];
                    //             } else {
                    //                 variable.value = source[variable.name];
                    //             }
                    //             if (source.hasOwnProperty(variable.name + "_Evidence"))
                    //                 variable.evidence = source[variable.name + "_Evidence"];
                    //             if (Array.isUseful(variable.children)) {
                    //                 varLoop(variable.children, childrenNameSpace, self);
                    //             }
                    //         }
                    //     }
                    // };
                    // varLoop(this.variables, sourceDocument, this);
                // }
                // return this.variables;
            }
        },
        methods: {
            onVariableChange(event) {
                this.$emit("onVariableChange", event)
            },
            onEvidence(picture, variable, i) {
                variable.evidence = picture;
                this.takingEvidence[i].value = false;
                this.onVariableChange();
            },
            onQrRead(value, variable, i) {
                if (value)
                    variable.value = value;

                this.takingEvidence[i].value = false;
                this.onVariableChange();
            },
            takeQrCode(i) {
                this.qrCode = true;
                this.takingEvidence[i].value = true;
            },
            takeEvidence(i) {
                this.qrCode = false;
                this.takingEvidence[i].value = true;
            },
            addRepeatedVariables(groupIndex) {
                let newGroup = this.$utils.detach(this.formVariables[groupIndex].repeatableChildren[0]);
                this.$dataEntry.clearVariables(newGroup);
                this.formVariables[groupIndex].repeatableChildren.push(newGroup);
            },
            deleteRepeatedVariables(groupIndex, subGroupIndex) {
                this.$root.showDialogBox(this.$gettext("Are you sure you want to delete this item?"), null, "Yes", () => {
                    this.formVariables[groupIndex].repeatableChildren.removeAt(subGroupIndex)
                }, "No", null);
            }
        },
        watch: {
            variables() {
                this.takingEvidence.clear();
                let self = this;
                this.variables.forEach(variable => {
                    self.takingEvidence.push({value: false})
                });
                if (this.entity) {
                    this.$dataEntry.unwrapEntity(this.entity, this.variables, false);
                    // let sourceDocument = this.entity[this.entity.NameSpace];
                    // if(Object.isUseful(sourceDocument["RawDocument"]))
                    //     sourceDocument = sourceDocument["RawDocument"];
                    // let varLoop = function (variables, source, self) {
                    //     for (let variable of variables) {
                    //         if (source.hasOwnProperty(variable.name)) {
                    //             if (variable.type === "repeatableGroup") {
                    //                 variable.repeatableChildren = [];
                    //                 for (let subValues of source[variable.name]) {
                    //                     variable.repeatableChildren.push(self.$utils.detach(variable.children));
                    //                     varLoop(variable.repeatableChildren.last(), subValues, self);
                    //                 }
                    //                 continue;
                    //             }
                    //             let childrenNameSpace = source;
                    //             if (variable.type === "Boolean" && Array.isUseful(variable.children)) {
                    //                 variable.value = true;
                    //                 childrenNameSpace = source[variable.name];
                    //             } else {
                    //                 variable.value = source[variable.name];
                    //             }
                    //             if (source.hasOwnProperty(variable.name + "_Evidence"))
                    //                 variable.evidence = source[variable.name + "_Evidence"];
                    //             if (Array.isUseful(variable.children)) {
                    //                 varLoop(variable.children, childrenNameSpace, self);
                    //             }
                    //         }
                    //     }
                    // };
                    // varLoop(this.variables, sourceDocument, this);
                }
            },
            takingEvidence: {
                deep: true,
                handler() {
                    let res = false;
                    for (let i = 0; i < this.takingEvidence.length; i++) {
                        res = res || this.takingEvidence[i].value;
                    }
                    this.isTakingEvidence = res;
                }
            }
        }
    }
</script>

<style scoped>

</style>
