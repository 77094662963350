<template>
    <div>
        <template v-for="item in descriptor">
            <template v-if="!item.hidden">
                <av-text-field v-if="item.type === 'string'" clearable :placeholder="$i18n.CommonAttributes()['type here']" :label="item.name + ':'" :value="getValue(item.id)" @click:clear="setValue(item.id, '')" @change="setValue(item.id, $event)" data-qa-type="input-text" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"></av-text-field>
                <av-text-field v-if="item.type === 'number'" :label="item.name + ':'" :value="getValue(item.id)" @change="setValue(item.id, $event)" type="number" data-qa-type="input-text" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"></av-text-field>
                <av-switch v-if="item.type === 'bool' && (!item.visibleForType || item.visibleForType.includes(dataType))" :label="item.name" :value="getValue(item.id)" @change="setValue(item.id, $event)" data-qa-type="input-switch" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"/>
                <av-select v-if="item.type === 'option' && !item.itemText" :multiple="item.multi" :chips="item.multi" :placeholder="$i18n.CommonAttributes()['select']" :items="item.options" :label="item.name + ':'" :value="getValue(item.id)" @change="setValue(item.id, $event)" data-qa-type="input-select" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"/>
                <av-select v-if="item.type === 'indexed_option' && !item.itemText" :multiple="item.multi" :chips="item.multi" :placeholder="$i18n.CommonAttributes()['select']" :items="item.options" :label="item.name + ':'" :value="getIndexedValue(item)" @change="setIndexedValue(item, $event)" data-qa-type="input-select" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"/>
                <av-multi-select v-if="item.type === 'multi_option'" :item-text="item.itemText || 'text'" :placeholder="$i18n.CommonAttributes()['select']" :items="item.options" :label="item.name + ':'" :value="getValue(item.id)" @change="setValue(item.id, $event)" :filterable="item.optionsFilterable" :item-search="item.searchField" data-qa-type="input-multiselect" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"/>
                <av-select v-if="item.type === 'option' && item.itemText" :item-text="item.itemText" return-object :multiple="item.multi" :chips="item.multi" :placeholder="$i18n.CommonAttributes()['select']" :items="item.options" :label="item.name + ':'" :value="getValue(item.id)" @change="setValue(item.id, $event)" data-qa-type="input-select" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"/>
                <av-select v-if="item.type === 'indexed_option' && item.itemText" :item-text="item.itemText" return-object :multiple="item.multi" :chips="item.multi" :placeholder="$i18n.CommonAttributes()['select']" :items="item.options" :label="item.name + ':'" :value="getIndexedValue(item)" @change="setIndexedValue(item, $event)" data-qa-type="input-select" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"/>
                <ColorPicker v-if="item.type === 'color'"
                        v-bind:field-name="item.name + ':'"
                        v-bind:init-color="getValue(item.id)"
                        :label="item.name + ':'" :value="getValue(item.id)" v-on:update-color="setValue(item.id, $event)"
                        data-qa-type="input-color-picker" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"
                ></ColorPicker>
                <template v-if="item.type === 'file'">
                    <label><translate>Template name</translate>: {{ getValue(item.id) }}</label>
                    <upload-btn noTitleUpdate v-if="item.type === 'file'"
                                @file-update="item.importCallback" class="btn btn-default"
                                labelClass="templateButton" style="padding-left: 0px" color="#f5f5f5" :title="item.name"
                                data-qa-type="button" data-qa-name="upload-file">
                        <template slot="icon-left">
                            <av-icon color="blue" style="margin-right: 10px">fas fa-file</av-icon>
                            <av-icon color="blue" style="margin-right: 10px">fas fa-angle-double-up</av-icon>
                        </template>
                    </upload-btn>
                    <v-btn style="margin-bottom: 20px" class="templateButton" color="error" @click="item.deleteCallback" data-qa-type="button" data-qa-name="delete-file">
                        <av-icon color="error" style="margin-right: 10px">fas fa-trash</av-icon>
                        <translate>Delete file</translate>
                    </v-btn>
                </template>
                <template v-if="item.type === 'duration'">
                    <v-layout wrap row>
                        <v-flex xs6>
                            <v-text-field :label="item.name + ':'" :value="getValue(item.id).value" @change="setValue(item.id,{value:$event,unit:getValue(item.id).unit})" type="number" style="margin-right: 5px;" data-qa-type="input-text" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"/>
                        </v-flex>
                        <v-flex xs6>
                            <v-select label="Unit" :items="durationOption" :value="getValue(item.id).unit"  @change="setValue(item.id, {value:getValue(item.id).value,unit:$event})" data-qa-type="input-select" :data-qa-name="item.id.toLowerCase().replaceAll(' ','-')"></v-select>
                        </v-flex>
                    </v-layout>
                </template>
                <template v-if="item.type === 'options-group'">
                    <v-label v-if="item.name">{{item.name}}</v-label>
                    <v-layout column class="ml-3">
                        <template v-for="(_,idx) in item.value">
                            <av-select :label="idx + ':'" return-object :placeholder="$i18n.CommonAttributes()['select']" :items="item.options"  :value="getOptionsGroupValue(item.id,idx)" @change="setOptionsGroupValue(item.id,idx, $event)" data-qa-type="input-select" :data-qa-name="(item.id + idx).toLowerCase().replaceAll(' ','-')"/>

                        </template>

                    </v-layout>
                </template>
                <PopUpTimePicker v-if="item.type === 'time'" :value="getValue(item.id)" type="minsColonSecs" @change="setValue(item.id, $event)"/>
                <div class="mt-3" /> <!-- Just a vertical spacer -->
            </template>
        </template>
    </div>
</template>

<script>

    import UploadButton from 'vuetify-upload-button';
    import ColorPicker from "@/components/utilities/ColorPicker.vue";
    import PopUpTimePicker from '@/components/utilities/PopUpTimePicker'

    export default {
        name: "SimpleConfigurationsEditor",
        components: {
            'upload-btn': UploadButton,
            ColorPicker,
            PopUpTimePicker
        },
        data() {
            return {
                durationOption:["Seconds","Minutes","Hours","Days"],
                duration:{
                    value:0,
                    mode:""
                }
            }
        },
        props: {
            descriptor: {
                type: Array,
                default: null
            },
            values: {
                type: Object,
                default: null
            },
            dataType: {
                type: String,
                default: ''
            },
        },
        computed: {
            items() {
                if(this.values)
                    return this.values;
                return this.descriptor;
            },
            hasTextAndValue() {
                return item=> {
                    return (item.itemText && item.itemValue);
                }
            }
        },
        methods: {
            getDescriptorById(id) {
                for(let item of this.descriptor)
                    if(item.id === id)
                        return item;
                return null
            },
            getIndexedValue(item) {
                if(item.multi) {
                    let returning = [];
                    for(let value of this.getValue(item.id))
                        returning.push(item.options[value]);
                    return returning;
                }
                return item.options[this.getValue(item.id)];
            },
            getValue(id) {
              if(id === "namespace"){
                this.$root.$emit('sendPrototypeFormNamespace', this.getDescriptorById(id).value)
              }
                if(!Object.isUseful(this.values))
                    return this.getDescriptorById(id).value;
                if(!this.values.hasOwnProperty(id)) {
                    let defaultValue = this.getDescriptorById(id).default;
                    if(typeof defaultValue === "function")
                        defaultValue = defaultValue();
                    this.$set(this.values, id, defaultValue);
                }


                return this.values[id];

            },
            getOptionsGroupValue(id,idx){
                return this.getDescriptorById(id).value[idx]
            },
            setIndexedValue(item, value) {
                if(item.multi) {
                    let values = [];
                    for(let val of value)
                        values.push(item.options.indexOf(val));
                    this.setValue(item.id, values);
                } else this.setValue(item.id, item.options.indexOf(value));
            },
            setValue(id, value) {
                if(!Object.isUseful(this.values)) {
                    this.getDescriptorById(id).value = value;
                    this.$emit("change", this.descriptor);
                    return
                }
                this.values[id] = value;
                this.$emit("change", this.values);
            },
            setOptionsGroupValue(id,idx,value){
                this.getDescriptorById(id).value[idx]=value;
                this.$emit("change", this.descriptor);
            },
            changeDurationValue(id,value){
                this.value[id]["durationValue"]=value
            }
        }
    }
</script>

<style scoped>

</style>
