import datetimeutils from "./datetimeutils"
import VueInstance from "./vueinstance"
import Grants from '@/api/grants'
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import TimeTracking from '@/api/timetracking'

export default {
    //used for returning the name of the property
    items() {
        let items = {};
        for (let key in this.itemsProperties())
            items[key] = key;
        return items;
    },

    formatBackendStatus(status) {

    },

    formatAvionicsSystemTrail(type, prev, next) {
        if (type === "Stop") {
            return {
                show: VueInstance.get().$gettext("Line stopped producing"),
                prev: VueInstance.get().$gettext("Line producing"),
                next: VueInstance.get().$gettext("Line stopped")
            }
        }
        if (type === "Run") {
            return {
                show: VueInstance.get().$gettext("Line resumed production"),
                prev: VueInstance.get().$gettext("Line stopped"),
                next: VueInstance.get().$gettext("Line producing")
            }
        }
    },

    itemsProperties() {
        return {
            quantities: {
                show: VueInstance.get().$gettext("User updated workorder counters"),
                valueFormat: VueInstance.get().$gettext("Produced: {0} Rejected: {1}"),
            },
            rejects: {
                show: VueInstance.get().$gettext("User added reject items"),
                valueFormat: VueInstance.get().$gettext("Rejected: {0}"),
            },
            timeTracking: {
                show: VueInstance.get().$gettext("Change Time Tracking"),
                valueFormat: "{0}",
                convert: (audit) => {
                    if(audit["Operator"] && audit["Operator"].toLowerCase().startsWith("avionics system")) {
                        //BE PreviousVal and NextVal are arrays of TtdStatus struct (category, activity, stoppedMachine, stopAlarm...)
                        audit["PreviousVal"] = TimeTracking.formatTimeTracker(audit["PreviousVal"][0], true);
                        audit["NextVal"] = TimeTracking.formatTimeTracker(audit["NextVal"][0], true);
                    }
                }
            },
            pastTimeTracking: {
                show: VueInstance.get().$gettext("Change Past Time Tracking"),
                valueFormat: VueInstance.get().$gettext("Categorized {0} line stops with total duration of: {1} as {2}"),
            },
            pastTimeTrackingSingle: {
                show: VueInstance.get().$gettext("Change Past Time Tracking"),
                valueFormat: VueInstance.get().$gettext("Categorized line stop from {0} to {1} with total duration of: {2} as {3}"),
            },
            login: {    //AVionics 2.0 compatibility, nextgen trails are managed in backend
                show: VueInstance.get().$gettext("User Login"),
                valueFormat: "{0}",
            },
            logout: {    //AVionics 2.0 compatibility, nextgen trails are managed in backend
                show: VueInstance.get().$gettext("User Logout"),
                valueFormat: "{0}",
            },
            workorders: {
                show: VueInstance.get().$gettext("Operator changed workorder for line"),
                valueFormat: "{0}",
            },
            workOrdersManualSet: {
                show: VueInstance.get().$gettext("Operator changed workorder for line (manual assigment)"),
                valueFormat: "{0}",
            },
            settingsAdded: {
                show: VueInstance.get().$gettext("Added new value"),
                valueFormat: "{0}",
            },
            settingsModified: {
                show: VueInstance.get().$gettext("Changed value"),
                valueFormat: "{0}",
            },
            settingsDeleted: {
                show: VueInstance.get().$gettext("Deleted value"),
                valueFormat: "{0}",
            },
            settingsImported: {
                show: VueInstance.get().$gettext("Imported new values"),
                valueFormat: "{0}",
            },
            settingsSorted: {
                show: VueInstance.get().$gettext("Sorted values"),
                valueFormat: "{0}",
            },
            lineStopCausesAdded: {
                show: VueInstance.get().$gettext("New category added in line stop causes"),
                valueFormat: "{0}",
            },
            lineStopCausesDeleted: {
                show: VueInstance.get().$gettext("Deleted category in line stop causes"),
                valueFormat: "{0}",
            },
            lineStopCausesModified: {
                show: VueInstance.get().$gettext("Changed category description in line stop causes"),
                valueFormat: "{0}",
            },
            lineStopCausesDisabled: {
                show: VueInstance.get().$gettext("Disabled category in line stop causes"),
                valueFormat: "{0}",
            },
            lineStopCausesEnabled: {
                show: VueInstance.get().$gettext("Enabled Category in line stop causes"),
                valueFormat: "{0}",
            },
            lineStopCausesUpdated: {
                show: VueInstance.get().$gettext("Imported new categories in line stop causes from upper level"),
                valueFormat: "{0}",
            },
            lineStopCausesPositionChanged: {
                show: VueInstance.get().$gettext("Line stop causes reordered"),
                valueFormat: VueInstance.get().$gettext("From position {0} : {1} to position {2} : {3}"),
            },
            lineStopCausesImported: {
                show: VueInstance.get().$gettext("Imported new categories in line stop causes from file"),
                valueFormat: "{0}",
            },
            lineStopCausesStandardized: {
                show: VueInstance.get().$gettext("Line stop causes standard category changed"),
                valueFormat: "{0}",
            },
            opcUaDeviceAdded: {
                show: VueInstance.get().$gettext("New device added in opc-ua clients"),
                valueFormat: "{0}",
            },
            opcUaDeviceDeleted: {
                show: VueInstance.get().$gettext("Device deleted in opc-ua clients"),
                valueFormat: "{0}",
            },
            opcUaDeviceDeployed: {
                show: VueInstance.get().$gettext("Device deployed in opc-ua clients"),
                valueFormat: "{0}",
            },
            opcUaDeviceRelaunched: {
                show: VueInstance.get().$gettext("Device relaunched in opc-ua clients"),
                valueFormat: "{0}",
            },
            opcUaDeviceSuspended: {
                show: VueInstance.get().$gettext("Device suspended in opc-ua clients"),
                valueFormat: "{0}",
            },
            microserviceDeployed: {
                show: VueInstance.get().$gettext("Microservice deployed"),
                valueFormat: "{0}",
            },
            microserviceRelaunched: {
                show: VueInstance.get().$gettext("Microservice relaunched"),
                valueFormat: "{0}",
            },
            microserviceSuspended: {
                show: VueInstance.get().$gettext("Microservice suspended"),
                valueFormat: "{0}",
            },
            opcUaVariableAdded: {
                show: VueInstance.get().$gettext("New variable added in opc-ua clients"),
                valueFormat: "{0}",
            },
            opcUaVariableEdited: {
                show: VueInstance.get().$gettext("Variable edited in opc-ua clients"),
                valueFormat: "{0}",
            },
            opcUaVariableDeleted: {
                show: VueInstance.get().$gettext("Variable deleted in opc-ua clients"),
                valueFormat: "{0}",
            },
            opcUaConfigurationImported: {
                show: VueInstance.get().$gettext("New configuration imported in opc-ua clients"),
                valueFormat: "{0}",
            },
            operationModeAdded: {
                show: VueInstance.get().$gettext("New operation mode added"),
                valueFormat: "{0}",
            },
            operationModeEdited: {
                show: VueInstance.get().$gettext("Operation mode modified"),
                valueFormat: "{0}",
            },
            operationModeDeleted: {
                show: VueInstance.get().$gettext("Operation mode deleted"),
                valueFormat: "{0}",
            },
            workOrderAdjustement: {
                show: VueInstance.get().$gettext("Changed workorder ID"),
                valueFormat: VueInstance.get().$gettext("Changed workorder for period {0} to {1} from {2} to {3}"),
            },
            shiftClosed: {
                show: VueInstance.get().$gettext("Closed shift"),
                valueFormat: VueInstance.get().$gettext("Closed shift from {0} to {1}"),
            },
            shiftOpened: {
                show: VueInstance.get().$gettext("Opened shift"),
                valueFormat: VueInstance.get().$gettext("Shift from {0} to {1}"),
            },
            shiftScheduled: {
                show: VueInstance.get().$gettext("Changed shift scheduling"),
                valueFormat: VueInstance.get().$gettext("Changed shift scheduling for week from {0} to {1}"),
            },
            timeTrackingAdjustement: {
                show: VueInstance.get().$gettext("Changed time tracking ID"),
                valueFormat: VueInstance.get().$gettext("Changed time tracking for period {0} to {1} from {2} to {3}"),
            },
            translationNewLanguageAdded: {
                show: VueInstance.get().$gettext("New Language added in Translations"),
                valueFormat: "{0}",
            },
            translationLanguageDeleted: {
                show: VueInstance.get().$gettext("Language deleted in Translations"),
                valueFormat: "{0}",
            },
            translationImported: {
                show: VueInstance.get().$gettext("Imported new translation from file"),
                valueFormat: "{0}",
            },
            translationEdited: {
                show: VueInstance.get().$gettext("Added new text to translation"),
                valueFormat: "{0}",
            },
            formFilled: {
                show: VueInstance.get().$gettext("Form filled in"),
                valueFormat: "{0}",
            },
            taskResolved: {
                show: VueInstance.get().$gettext("Task resolved"),
                valueFormat: VueInstance.get().$gettext("Form compiled: {0}; Comment: {1}"),
            },
            taskCreated: {  //TODO translate user profiles as for formFillRequested
                show: VueInstance.get().$gettext("Task created"),
                valueFormat: VueInstance.get().$gettext("Task notified to: {0}"),
            },
            formFillRequested: {
                show: VueInstance.get().$gettext("Form fill in requested"),
                valueFormat: VueInstance.get().$gettext("Rule notified to: {0}"),
                convert: (audit) => {
                    if(audit["Operator"] && audit["Operator"].toLowerCase().startsWith("avionics system")) {
                        audit["NextVal"] = audit["NextVal"].length > 2 ? this.getUserDescription(audit["NextVal"]) : VueInstance.get().$gettext("All users");
                        audit["NextVal"] = (audit["NextVal"] ? this.itemsProperties()[audit["Action"]].valueFormat.format(audit["NextVal"]).replace('[', '').replace(']', '').replace('{', "").replace(new RegExp(',', 'g'), ' ').replace('}', '').replace(new RegExp('"', 'g'), '') : '');
                    }
                }
            },
            workorderCreated: {
                show: VueInstance.get().$gettext("Opened new workorder"),
                valueFormat: VueInstance.get().$gettext("Workorder: {0}"),
            },
            languageChanged: {
                show: VueInstance.get().$gettext("Language changed"),
                valueFormat: "{0}",
            },
            grantsNewGroupAdded: {
                show: VueInstance.get().$gettext("New group added in Grants"),
                valueFormat: VueInstance.get().$gettext("{0} to level {1}"),
            },
            grantsTestNewAdminGroupsAdded: {
                show: VueInstance.get().$gettext("New admin groups added in Grants"),
                valueFormat: "{0}",
            },
            grantsGroupDeleted: {
                show: VueInstance.get().$gettext("Group deleted in Grants"),
                valueFormat: VueInstance.get().$gettext("{0} to level {1}"),
            },
            grantEnabled: {
                show: VueInstance.get().$gettext("Grant enabled"),
                valueFormat: VueInstance.get().$gettext("{0} in {1} for user level {2}"),
            },
            grantDisabled: {
                show: VueInstance.get().$gettext("Grant disabled"),
                valueFormat: VueInstance.get().$gettext("{0} in {1} for user level {2}"),
            },
            alarmCreated: {
                show: VueInstance.get().$gettext("Created alarm"),
                valueFormat: "{0}",
            },
            alarmsDescriptionsImported: {
                show: VueInstance.get().$gettext("Imported alarms descriptions from file"),
                valueFormat: "{0}",
            },
            alarmsDescriptionsNewLanguageAdded: {
                show: VueInstance.get().$gettext("New language added in Alarms descriptions"),
                valueFormat: "{0}",
            },
            stopCauseAddedToAlarm: {
                show: VueInstance.get().$gettext("Added default stop cause to alarm"),
                valueFormat: "{0}",
            },
            stopCauseDeletedFromAlarm: {
                show: VueInstance.get().$gettext("Removed default stop cause from alarm"),
                valueFormat: "{0}",
            },
            alarmSearchPatternEdited: {
                show: VueInstance.get().$gettext("Modified alarm search pattern"),
                valueFormat: "{0}",
            },
            alarmIdEdited: {
                show: VueInstance.get().$gettext("Modified alarm identifier"),
                valueFormat: "{0}",
            },
            alarmDescriptionEdited: {
                show: VueInstance.get().$gettext("Modified alarm description"),
                valueFormat: "{0}",
            },
            alarmSuppressEnabled: {
                show: VueInstance.get().$gettext("Enabled alarm ignored"),
                valueFormat: "{0}",
            },
            alarmSuppressDisabled: {
                show: VueInstance.get().$gettext("Disabled alarm ignored"),
                valueFormat: "{0}",
            },
            alarmStoppingEnabled: {
                show: VueInstance.get().$gettext("Enabled alarm stopping"),
                valueFormat: "{0}",
            },
            alarmStoppingDisabled: {
                show: VueInstance.get().$gettext("Disabled alarm stopping"),
                valueFormat: "{0}",
            },
            alarmSeverityEdited: {
                show: VueInstance.get().$gettext("Modified alarm severity"),
                valueFormat: "{0}",
            },
            alarmConfiguredFromStopCategorization: {
                show: VueInstance.get().$gettext("User configured alarm stop cause from stop categorization"),
                valueFormat: "{0}",
            },
            alarmChangeSystemCategoryDetected: {
                show: VueInstance.get().$gettext("Alarm stop causes reconfigured"),
                valueFormat: "{0}",
            },

            configFileImported: {
                show: VueInstance.get().$gettext("Configuration file imported"),
                valueFormat: "{0}",
            },
            // configFileExported: {
            //     show: VueInstance.get().$gettext("Configuration file exported"),
            //     valueFormat: "{0}",
            // },
            configFileModified: {
                show: VueInstance.get().$gettext("Configuration file modified"),
                valueFormat: "{0}",
            },
            configFileDeleted: {
                show: VueInstance.get().$gettext("Configuration file deleted"),
                valueFormat: "{0}",
            },
            mailConfiguration: {
                show: VueInstance.get().$gettext("Mail Configuration modified"),
                valueFormat: "{0}",
            },
            dashboardCreated: {
                show: VueInstance.get().$gettext("Dashboard created"),
                valueFormat: "{0}",
            },
            dashboardModified: {
                show: VueInstance.get().$gettext("Dashboard modified"),
                valueFormat: "{0}",
            },
            dashboardDeleted: {
                show: VueInstance.get().$gettext("Dashboard deleted"),
                valueFormat: "{0}",
            },
            reportTemplateImported: {
                show: VueInstance.get().$gettext("Report template imported"),
                valueFormat: "{0}",
            },
            reportCreated: {
                show: VueInstance.get().$gettext("Report created"),
                valueFormat: "{0}",
            },
            reportModified: {
                show: VueInstance.get().$gettext("Report modified"),
                valueFormat: "{0}",
            },
            reportDeleted: {
                show: VueInstance.get().$gettext("Report deleted"),
                valueFormat: "{0}",
            },
            reportActivated: {
                show: VueInstance.get().$gettext("User activated report"),
                valueFormat: "{0}",
            },
            reportDeactivated: {
                show: VueInstance.get().$gettext("User deactivated report"),
                valueFormat: "{0}",
            },
            queryCreated: {
                show: VueInstance.get().$gettext("Query created"),
                valueFormat: "{0}",
            },
            queryModified: {
                show: VueInstance.get().$gettext("Query modified"),
                valueFormat: "{0}",
            },
            queryDeleted: {
                show: VueInstance.get().$gettext("Query deleted"),
                valueFormat: "{0}",
            },
            queryActivated: {
                show: VueInstance.get().$gettext("User activated query"),
                valueFormat: "{0}",
            },
            queryDeactivated: {
                show: VueInstance.get().$gettext("User deactivated query"),
                valueFormat: "{0}",
            },
            widgetCreated: {
                show: VueInstance.get().$gettext("Data visualization created"),
                valueFormat: "{0}",
            },
            widgetModified: {
                show: VueInstance.get().$gettext("Data visualization modified"),
                valueFormat: "{0}",
            },
            widgetDeleted: {
                show: VueInstance.get().$gettext("Data visualization deleted"),
                valueFormat: "{0}",
            },
            widgetActivated: {
                show: VueInstance.get().$gettext("User activated widget"),
                valueFormat: "{0}",
            },
            widgetDeactivated: {
                show: VueInstance.get().$gettext("User deactivated widget"),
                valueFormat: "{0}",
            },
            formCreated: {
                show: VueInstance.get().$gettext("Form created"),
                valueFormat: "{0}",
            },
            formModified: {
                show: VueInstance.get().$gettext("Form modified"),
                valueFormat: "{0}",
            },
            formDeleted: {
                show: VueInstance.get().$gettext("Form deleted"),
                valueFormat: "{0}",
            },
            formActivated: {
                show: VueInstance.get().$gettext("User activated form"),
                valueFormat: "{0}",
            },
            formDeactivated: {
                show: VueInstance.get().$gettext("User deactivated form"),
                valueFormat: "{0}",
            },
            ruleCreated: {
                show: VueInstance.get().$gettext("Rule created"),
                valueFormat: "{0}",
            },
            ruleModified: {
                show: VueInstance.get().$gettext("Rule modified"),
                valueFormat: "{0}",
            },
            ruleDeleted: {
                show: VueInstance.get().$gettext("Rule deleted"),
                valueFormat: "{0}",
            },
            ruleStarted: {
                show: VueInstance.get().$gettext("User started rule"),
                valueFormat: "{0}",
            },
            ruleStopped: {
                show: VueInstance.get().$gettext("User stopped rule"),
                valueFormat: "{0}",
            },
            recipeCreated: {
                show: VueInstance.get().$gettext("Recipe created"),
                valueFormat: "{0}",
            },
            recipeModified: {
                show: VueInstance.get().$gettext("Recipe modified"),
                valueFormat: "{0}",
            },
            recipeDeleted: {
                show: VueInstance.get().$gettext("Recipe deleted"),
                valueFormat: "{0}",
            },
            recipeActivated: {
                show: VueInstance.get().$gettext("User activated recipe"),
                valueFormat: "{0}",
            },
            recipeDeactivated: {
                show: VueInstance.get().$gettext("User deactivated recipe"),
                valueFormat: "{0}",
            },
            recipeRequestedActivation: {
                show: VueInstance.get().$gettext("User requested activation recipe"),
                valueFormat: "{0}",
            },
            wizardDeleted: {
                show: VueInstance.get().$gettext("Wizard deleted"),
                valueFormat: "{0}",
            },
            activityRescheduled: {
                show: VueInstance.get().$gettext("Activity rescheduled"),
                valueFormat: "{0}",
            },
            activityDurationChanged: {
                show: VueInstance.get().$gettext("Activity duration changed"),
                valueFormat: "{0}",
            },
            assetRegisterDeleted: {
                show: VueInstance.get().$gettext("Asset register item deleted"),
                valueFormat: "{0}",
            },
            maintenanceRegisterDeleted: {
                show: VueInstance.get().$gettext("Maintenance register item deleted"),
                actionFormat: VueInstance.get().$gettext("Asset: {0}"),
                valueFormat: VueInstance.get().$gettext("Deleted record id: {0}"),
            },
            tableVisualizationProfileCreated: {
                show: VueInstance.get().$gettext("Table visualization profile created"),
                valueFormat: "{0}",
            },
            tableVisualizationProfileUpdated: {
                show: VueInstance.get().$gettext("Table visualization profile updated"),
                valueFormat: "{0}",
            },
            tableVisualizationProfileDeleted: {
                show: VueInstance.get().$gettext("Table visualization profile deleted"),
                valueFormat: "{0}",
            },
            activityExportProfileCreated: {
                show: VueInstance.get().$gettext("Activity export profile created"),
                valueFormat: "{0}",
            },
            activityExportProfileUpdated: {
                show: VueInstance.get().$gettext("Activity export profile updated"),
                valueFormat: "{0}",
            },
            activityExportProfileDeleted: {
                show: VueInstance.get().$gettext("Activity export profile deleted"),
                valueFormat: "{0}",
            },
            //NextGen audits
            Logon: {
                show: VueInstance.get().$gettext("User Login"),
                valueFormat: "{0}",
                convert: (audit) => {
                    if(audit["NextVal"] === "Logged in")
                        audit["NextVal"] = "Success";
                    audit["PreviousVal"] = "";
                }
            },
            Logoff: {
                show: VueInstance.get().$gettext("User Logout"),
                valueFormat: "{0}",
                convert: (audit) => {
                    if(audit["NextVal"] === "Logged out")
                        audit["NextVal"] = "Success";
                    audit["PreviousVal"] = "";
                }
            },
            productionManagementCurrentOperationModeChange: {
                show: VueInstance.get().$gettext("Change current operation mode"),
                valueFormat: "{0}",
            },
            productionManagementChangeWoStatus: {
                show: VueInstance.get().$gettext("Change Workorder Status"),
                valueFormat: "{0}",
            },
            productionManagementUpdateWorkorder: {
                show: VueInstance.get().$gettext("Updated Workorder Document"),
                valueFormat: "{0}",
            },
            productionManagerChangeWorkFlowStatus: {
                show: VueInstance.get().$gettext("Starting Workflow Transition"),
                valueFormat: "{0}",
            },
            changeWorkorderToLine:{
                show: VueInstance.get().$gettext("Changed Workorder to Line assignation"),
                valueFormat: "{0}",
            },
            changeWorkorderToSubLine:{
                show: VueInstance.get().$gettext("Changed Workorder to SubLine assignation"),
                valueFormat: VueInstance.get().$gettext("{0} {1}"),
            },
            assignWorkorderToLine:{
                show: VueInstance.get().$gettext("Assign Workorder to Line"),
                valueFormat: "{0}",
            },
            lineStopCausesSystemCategoryDeleted: {
                show: VueInstance.get().$gettext("Deleted system category in line stop causes"),
                valueFormat: "{0}",
            },
            workorderRequestReceived: {
                show: VueInstance.get().$gettext("Workorder Request Received"),
                actionFormat: VueInstance.get().$gettext("Line ID: {0}, Workorder ID: {1}"),
                valueFormat: "{0}",
                valueFormatOptions: {
                    error: VueInstance.get().$gettext("Error"),
                    created: VueInstance.get().$gettext("Created"),
                    updated: VueInstance.get().$gettext("Updated"),
                }
            },
            productionDataRequestReceived: {
                show: VueInstance.get().$gettext("Production data Request Received"),
                actionFormat: VueInstance.get().$gettext("Production data ID: {0}"),
                valueFormatOptions: {
                    error: VueInstance.get().$gettext("Error"),
                    created: VueInstance.get().$gettext("Created"),
                    updated: VueInstance.get().$gettext("Updated"),
                }
            },
            changeWorkorderStatus: {
                show: VueInstance.get().$gettext("Change Workorder values"),
                valueFormat: "{0}",
            },
            changeWorkorderFields: {
                show: VueInstance.get().$gettext("Change Workorder values fields"),
                valueFormat: "{0}",
            },
            productionManagementTransitionCompleted: {
                show: VueInstance.get().$gettext("Workflow Transition completed"),
                valueFormat: "{0}",
            },
            productionManagementTransitionFailed: {
                show: VueInstance.get().$gettext("Workflow Transition failed"),
                valueFormat: "{0}",
            },
            documentUploaded: {
                show: VueInstance.get().$gettext('User uploaded a document'),
                valueFormat: "{0}",
            },
            documentDeleted: {
                show: VueInstance.get().$gettext('User deleted a document'),
                valueFormat: "{0}",
            },
            workorderUpdated: {
                show: VueInstance.get().$gettext('Workorder Value Updated'),
                valueFormat: "{0}",
            },
            sublineAdded: {
                show: VueInstance.get().$gettext("New subline added"),
                valueFormat: "{0}",
            },
            sublineEdited: {
                show: VueInstance.get().$gettext("Subline modified"),
                valueFormat: "{0}",
            },
            sublineDeleted: {
                show: VueInstance.get().$gettext("Subline deleted"),
                valueFormat: "{0}",
            },
            CloseApplication: {
                show: VueInstance.get().$gettext("Close Application"),
                valueFormat: VueInstance.get().$gettext("Close Application"),
            },

            // "13": "Modules changed",
            // "14": "Application settings changed",
            // "15": "Privileges changed",
            // "16": "User group created",
            // "17": "User group deleted",
            // "18": "User group updated",
            // "19": "Language created",
            // "20": "Translation updated",
            // "21": "Translation created",
            // "22": "Translation file exported",
            // "23": "Translation file imported",
            // "25": "Option changed",
            // "26": "License activated",
            // "27": "Language deleted",
            // "28": "Translation deleted",
            // "63": "External key created",
            // "64": "External key deleted"
        }
    },

    async getAuditsList(from, to) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.auditsUrl + (from ? "/" : "") + (from || "") + (to ? "/" : "") + (to || ""))
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                    reject(VueInstance.get().$gettext("Unable to find audits"));
                });
        });
    },

    getSettingChangedTrailObject(groupName, settingName, actionObj, settingsValues, settingsDescriptor, parameterScope = [], subGroup = false) {

        if (settingsValues === null)
            settingsValues = {};

        //Manage of audit trails
        let previousVal = '';
        let nextVal = '';
        let action = '';
        let lenOdlObject;
        let lenNewObject;
        let setting;
        if (subGroup) {
            setting = settingsDescriptor[groupName.groupParentName].settings[groupName.settingParentName].settings[settingName];
            lenOdlObject = ((actionObj.item !== '' && Array.isUseful(settingsValues[groupName.settingParentName][setting.index])) ? settingsValues[groupName.settingParentName][setting.index].length : 0);
            lenNewObject = (actionObj.item !== '' ? setting.value.length : 0);
        } else {
            lenOdlObject = ((actionObj.item !== '' && Array.isUseful(settingsValues[settingName])) ? settingsValues[settingName].length : 0);
            lenNewObject = (actionObj.item !== '' ? settingsDescriptor[groupName].settings[settingName].value.length : 0);
        }

        //Check for added action
        if (actionObj.action !== 'loaded') {
            if (lenNewObject > lenOdlObject) {
                actionObj.action = 'added';
            } else if (!subGroup && (lenNewObject < lenOdlObject) && (settingsDescriptor[groupName].settings[settingName].type === "multiChecks")) {
                actionObj.action = 'deleted';
            }
        }

        switch (actionObj.action) {
            case 'loaded':
                action = this.items().settingsImported;
                break;
            case 'added':
                action = this.items().settingsAdded;
                //case of not object type component (i.e.: number ,..)
                if (actionObj.item === '') {
                    if (settingsDescriptor[groupName].settings[settingName].type === "multiChecks") {
                        nextVal = JSON.stringify((settingsDescriptor[groupName].settings[settingName].option[action.item]));
                    } else {
                        nextVal = JSON.stringify((settingsDescriptor[groupName].settings[settingName]));
                    }
                } else {
                    //Managed for timespan (also copy&paste)
                    if (subGroup) {
                        // previousVal = JSON.stringify(settingsValues[groupName.settingParentName][setting.index]);
                        nextVal = JSON.stringify(setting.value[actionObj.item]);
                    } else {
                        if (settingsDescriptor[groupName].settings[settingName].type === "multiChecks") {
                            [nextVal, previousVal] = this.setValueMultichecksType(groupName, settingName, settingsValues, settingsDescriptor);
                        } else if(settingsDescriptor[groupName].settings[settingName].type === "stringsList" && settingsDescriptor[groupName].settings[settingName].objectsArray) {
                            nextVal = JSON.stringify((settingsDescriptor[groupName].settings[settingName].value)[actionObj.item].value);
                        }
                        else {
                            nextVal = JSON.stringify((settingsDescriptor[groupName].settings[settingName].value)[actionObj.item]);
                        }
                    }
                }
                break;
            case 'modified':
                action = this.items().settingsModified;
                if (actionObj.item === '') {
                    //Managed for timespan copy&paste)
                    if (subGroup) {
                        // let setting= settingsDescriptor[groupName.groupParentName].settings[groupName.settingParentName].settings[settingName];
                        previousVal = JSON.stringify(settingsValues[groupName.settingParentName][setting.index]);
                        nextVal = JSON.stringify((setting.value));
                    } else {
                        previousVal = JSON.stringify(settingsValues[settingName]);
                        nextVal = JSON.stringify((settingsDescriptor[groupName].settings[settingName].value));
                    }
                } else {
                    if (subGroup) {
                        // let setting= settingsDescriptor[groupName.groupParentName].settings[groupName.settingParentName].settings[settingName];
                        previousVal = JSON.stringify(settingsValues[groupName.settingParentName][setting.index][actionObj.item]);
                        nextVal = JSON.stringify((setting.value[actionObj.item]));
                    } else {
                        previousVal = JSON.stringify(settingsValues[settingName][actionObj.item]);
                        //Managed for stringsList used as array of objects
                        if(settingsDescriptor[groupName].settings[settingName].type === "stringsList" && settingsDescriptor[groupName].settings[settingName].objectsArray) {
                            nextVal = JSON.stringify((settingsDescriptor[groupName].settings[settingName].value)[actionObj.item].value);
                        } else {
                            nextVal = JSON.stringify((settingsDescriptor[groupName].settings[settingName].value)[actionObj.item]);
                        }
                    }
                }
                break;
            case 'deleted':
                action = this.items().settingsDeleted;
                if (actionObj.item === '') {
                    previousVal = JSON.stringify(settingsValues[settingName]);
                } else {
                    //Managed for timespan (also copy&paste)
                    if (subGroup) {
                        // let setting= settingsDescriptor[groupName.groupParentName].settings[groupName.settingParentName].settings[settingName];
                        previousVal = JSON.stringify(settingsValues[groupName.settingParentName][setting.index][actionObj.item]);
                        // nextVal = JSON.stringify(setting.value[actionObj.item]);
                    } else {
                        if (settingsDescriptor[groupName].settings[settingName].type === "multiChecks") {
                            [nextVal, previousVal] = this.setValueMultichecksType(groupName, settingName, settingsValues, settingsDescriptor);
                        } else {
                            previousVal = JSON.stringify(settingsValues[settingName][actionObj.item]);
                        }
                    }
                }
                break;
            //Managed for stringsList used as array of objects
            case 'sorted':
                action = this.items().settingsSorted;
                previousVal = this.isString(JSON.stringify(settingsValues[settingName][actionObj.item])) ? JSON.stringify(settingsValues[settingName][actionObj.item]) : JSON.stringify(settingsValues[settingName][actionObj.item].value);
                nextVal = this.isString(JSON.stringify((settingsDescriptor[groupName].settings[settingName].value)[actionObj.item])) ? JSON.stringify((settingsDescriptor[groupName].settings[settingName].value)[actionObj.item].value) : JSON.stringify((settingsDescriptor[groupName].settings[settingName].value)[actionObj.item].value);
                break;
        }

        let actionId = groupName + '.' + settingName;
        if(subGroup) {
            actionId = groupName.groupParentName + (groupName.settingParentName ? "." + groupName.settingParentName : "") + '.' + settingName;
        }
        if(Array.isUseful(parameterScope)) {
            actionId = parameterScope.join(".") + "." + actionId;
        }

        return {
            operator: VueInstance.get().$root.userName,
            actionId: actionId,
            action: action,
            previousVal: previousVal,
            nextVal: nextVal
        };
    },

    isString(inputText){
        return typeof inputText === 'string' || inputText instanceof String;
    },

    setValueMultichecksType(groupName, settingName, settingsValues, settingsDescriptor) {
        //Array with all days selected
        let arrayValuesSelected = this.iterator(groupName, settingName, settingsDescriptor[groupName].settings[settingName].value.values(), settingsDescriptor);
        let nextVal = JSON.stringify(arrayValuesSelected);
        //Array with old days selected
        arrayValuesSelected = this.iterator(groupName, settingName, settingsValues[settingName], settingsDescriptor);
        let previousVal = JSON.stringify(arrayValuesSelected);

        return [nextVal, previousVal]
    },

    iterator(groupName, settingName, arrayIterator, settingsDescriptor) {
        if(!arrayIterator)
            return []
        let iterator = arrayIterator;
        let arrayDaysWeek = [];

        for (let element of iterator) {
            arrayDaysWeek.push(settingsDescriptor[groupName].settings[settingName].options[element]);
        }
        return arrayDaysWeek;
    },

    async save(operator, action, previousVal, nextVal, actionId) {

        let audit =
            {
                Operator: operator,
                Action: action,
                PreviousVal: previousVal,
                NextVal: nextVal,
                ActionId: actionId,
                '@timestamp': datetimeutils.getRfc3339TimeStamp(new Date()),
            };

        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.auditsUrl, audit)
            // Api().post('/audits', audit)
                .then(() => {
                    resolve(true);
                })
                .catch(r => {
                    console.log(r);
                    reject(VueInstance.get().$gettext("Error in saving audit."));
                })
        });
    },

    getUserDescription(selectedUserLevels) {
        let userLevels = Grants.getLevels();
        let result;
        userLevels.forEach(item => {
            result = selectedUserLevels.replace(item.key, item.show + ";");
            selectedUserLevels = result;
        });
        return result;
    },

    formatAudits(dataValues, readableTimestamp = true) {
        if (!dataValues) {
            return [];
        }

        let itemProperties = this.itemsProperties();

        for(let i = 0 ; i < dataValues.length ; i++) {
            let audit = dataValues[i];

            if(itemProperties[audit["Action"]]) {
                let descriptor = itemProperties[audit["Action"]];
                if(descriptor.convert)
                    descriptor.convert(audit);
                audit["Action"] = descriptor.show;
                if(descriptor.actionFormat)
                    audit["ActionId"] = descriptor.actionFormat.format(audit["ActionId"]);
                if (descriptor.valueFormat) {
                    audit["PreviousVal"] = (audit["PreviousVal"] ? descriptor.valueFormat.format(audit["PreviousVal"]).replace('[', '').replace(']', '').replace('{', "").replace(new RegExp(',', 'g'), ' ').replace('}', '').replace(new RegExp('"', 'g'), '') : '');
                    audit["NextVal"] = (audit["NextVal"] ? descriptor.valueFormat.format(audit["NextVal"]).replace('[', '').replace(']', '').replaceAll('{', "").replace(new RegExp(',', 'g'), ' ').replaceAll('}', '').replace(new RegExp('"', 'g'), '') : '');                         // nextValue = (audit["NextVal"] ? this.itemsPropertie
                }
                if(descriptor.valueFormatOptions) {
                    audit["PreviousVal"] = (audit["PreviousVal"] ? descriptor.valueFormatOptions[(audit["PreviousVal"])] : "");
                    audit["NextVal"] = (audit["NextVal"] ? (descriptor.valueFormatOptions[(audit["NextVal"])] ? descriptor.valueFormatOptions[(audit["NextVal"])] :descriptor.valueFormat.format(audit["NextVal"]).replace('[', '').replace(']', '').replaceAll('{', "").replace(new RegExp(',', 'g'), ' ').replaceAll('}', '').replace(new RegExp('"', 'g'), '')) : "");
                }
            }
            audit["@timestamp"] = new Date(audit["@timestamp"]);
            if(readableTimestamp)
                audit["@timestamp"] = (new Date(audit["@timestamp"])).format();
            else audit["@timestamp"] = new Date(audit["@timestamp"]);
            audit.humanReadable = true;
        }
    },

    getDataDescriptor(filters) {
        let returning = {filters: [], data: [], aggregations: []};
        if (Array.isUseful(filters)) {
            let conditions = []
            filters.forEach(filter => {
               let condition = {modifier: "should", operator: '=', value: filter}
                conditions.push(condition)
            })
            returning.filters.push({
                index: "audit_trails",
                root: "",
                name: 'Action',
                type: 'keyword',
                selectedForFiltering: true,
                filters: [{
                    conditions: conditions,
                    defaultName: "Select current workorder",
                    name: "Select current workorder",
                    enabled: true,
                    filterId: "SelectCurrentWorkorder" //Create a unique id that survives changes
                }]
            });
        }
        returning.data.push(
            {
                index: 'audit_trails',
                root: '',
                name: 'PreviousVal',
                type: 'keyword',
                selectedForVisualization: true,
                representations: [
                    {
                        type: 'raw',
                        filters: ['SelectCurrentWorkorder'],
                        target: '',
                        defaultName: "audit_trails.PreviousVal",
                        name: "PreviousVal",
                        enabled: true,
                        dataType: 'text',
                        id: 'PreviousVal'
                    },
                ],
            },
            {
                index: 'audit_trails',
                root: '',
                name: 'Operator',
                type: 'keyword',
                selectedForVisualization: true,
                representations: [
                    {
                        type: 'raw',
                        filters: ['SelectCurrentWorkorder'],
                        target: '',
                        defaultName: "audit_trails.Operator",
                        name: "Operator",
                        enabled: true,
                        dataType: 'text',
                        id: 'Operator'
                    },
                ],
            },
            {
                index: 'audit_trails',
                root: '',
                name: 'NextVal',
                type: 'keyword',
                selectedForVisualization: true,
                representations: [
                    {
                        type: 'raw',
                        filters: ['SelectCurrentWorkorder'],
                        target: '',
                        defaultName: "audit_trails.NextVal",
                        name: "NextVal",
                        enabled: true,
                        dataType: 'text',
                        id: 'NextVal'
                    },
                ],
            },
            {
                index: 'audit_trails',
                root: '',
                name: 'ActionId',
                type: 'keyword',
                selectedForVisualization: true,
                representations: [
                    {
                        type: 'raw',
                        filters: ['SelectCurrentWorkorder'],
                        target: '',
                        defaultName: "audit_trails.ActionId",
                        name: "ActionId",
                        enabled: true,
                        dataType: 'text',
                        id: 'ActionId'
                    },
                ],
            },
            {
                index: 'audit_trails',
                root: '',
                name: 'Action',
                type: 'keyword',
                selectedForVisualization: true,
                representations: [
                    {
                        type: 'raw',
                        filters: ['SelectCurrentWorkorder'],
                        target: '',
                        defaultName: "audit_trails.Action",
                        name: "Action",
                        enabled: true,
                        dataType: 'text',
                        id: 'Action'
                    },
                ],
            },
        );
        return returning
    }
}
