export default async ({ app, router, store, Vue }) => {
    Vue.prototype.$isEmpty = function (value) {
        return (
            // null or undefined
            (value === null) ||

            (value === undefined) ||

            // has length and it's zero
            (value.hasOwnProperty('length') && value.length === 0) ||

            // is an Object and has no keys
            (value.constructor === Object && Object.keys(value).length === 0)
        )
    }

    Vue.prototype.$levelNameByType = function (availableLevels, level) {
        if (!this.$isEmpty(availableLevels[level])) {
            return this.$t(availableLevels[level].Name)
        }
        return this.$t('Unknown')
    }

    Vue.prototype.$getStrDateByObj = function (dateObj) {
        return dateObj.Year + '-' + dateObj.Month + '-' + dateObj.Day
    }

    Vue.prototype.$checkboxVarDataAreValid = function (varDataList) {
        for (let i = 0; i < varDataList.length; i++) {
            let varData = varDataList[i]
            if (varData.Type === 3 && varData.IsMandatory && this.$isEmpty(varData.Value)) {
                this.$notifyError({ Message: 'Select a value for {0}', Parameters: [varData.Description] }, [{ label: 'Close', color: 'white', handler: () => {} }])
                return false
            }
        }
        return true
    }

    Vue.prototype.$showPDFPreview = function (base64PdfContent) {
        let binary = atob(base64PdfContent.replace(/\s/g, ''))
        let buffer = new ArrayBuffer(binary.length)
        let view = new Uint8Array(buffer)
        for (let i = 0; i < binary.length; i++) {
            view[i] = binary.charCodeAt(i)
        }
        let blob = new Blob([view], { type: 'application/pdf' })
        let url = URL.createObjectURL(blob)
        let printWindow = window.open(url, '')
        printWindow.print()
    }

    Vue.prototype.toCamelCase = function (stringToReplace) {
        return stringToReplace.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
            if (p2) return p2.toUpperCase()
            return p1.toLowerCase()
        })
    }
}
