export function setAlarmViewed (state, index) {
    state.shared.alarms[index].IsViewed = true
    let f = state.webSocketHooks['raisesharedstorechanged']
    if (f !== undefined) {
        f()
    }
}
export function setAlarmNotNew (state, index) {
    state.shared.alarms[index].IsNew = false
    let f = state.webSocketHooks['raisesharedstorechanged']
    if (f !== undefined) {
        f()
    }
}
export function addAlarm (state, alarm) {
    state.shared.alarms.unshift(alarm)
    let f = state.webSocketHooks['raisesharedstorechanged']
    if (f !== undefined) {
        f()
    }
}
export function setAlarms (state, alarms) {
    state.shared.alarms = alarms
}
export function callingApi (state, status) {
    state.callingApi = status
}
export function ClearBreadcrumbs (state) {
    state.breadcrumbs = []
}
export function saveModules (state, modules) {
    state.shared.modules = modules
}
export function PushBreadcrumb (state, breadcrumb) {
    if (state.shared.breadcrumbs[breadcrumb.name] === undefined) {
        state.shared.breadcrumbs[breadcrumb.name] = {}
    }
    if (breadcrumb.dbkey === undefined) {
        state.shared.breadcrumbs[breadcrumb.name] = breadcrumb
        return
    }
    state.shared.breadcrumbs[breadcrumb.name][breadcrumb.dbkey] = breadcrumb
    let f = state.webSocketHooks['raisesharedstorechanged']
    if (f !== undefined) {
        f()
    }
}
export function languages (state, languages) {
    state.shared.languages = languages
    let f = state.webSocketHooks['raisesharedstorechanged']
    if (f !== undefined) {
        f()
    }
}
export function selectedLanguage (state, newValue) {
    if (state.shared.preferences === undefined || state.shared.preferences.length === 0) {
        state.shared.preferences.push({ ParameterName: 'language', ParameterValue: newValue })
        return
    }
    for (let i = 0; i < state.shared.preferences.length; i++) {
        if (state.shared.preferences[i].ParameterName.toLowerCase() !== 'language') {
            continue
        }
        state.shared.preferences[i].ParameterValue = newValue
        let f = state.webSocketHooks['raisesharedstorechanged']
        if (f !== undefined) {
            f()
        }
        return
    }
}
export function setAddresses (state, addresses) {
    state.shared.addresses = addresses
    let f = state.webSocketHooks['raisesharedstorechanged']
    if (f !== undefined) {
        f()
    }
}
export function setUserPreferences (state, preferences) {
    state.shared.preferences = preferences
    let f = state.webSocketHooks['raisesharedstorechanged']
    if (f !== undefined) {
        f()
    }
}
export function setOptions (state, options) {
    let optionsMap = {}
    for (let i = 0; i < options.length; i++) {
        optionsMap[options[i].ParameterName.toLowerCase()] = options[i].ParameterValue
    }
    state.shared.options = optionsMap
    let f = state.webSocketHooks['raisesharedstorechanged']
    if (f !== undefined) {
        f()
    }
}
export function setSettings (state, settings) {
    state.shared.settings = settings
    let f = state.webSocketHooks['raisesharedstorechanged']
    if (f !== undefined) {
        f()
    }
}


export function setHeaderVisibility(state,visible){
    state.globalHeader = visible;
}
export function setToolbarVisibility(state,visible){
    state.globalDrawer = visible;
}
export function setSidebarVisibility(state,visible){
    state.globalMenu = visible;
}
export function setIsEmbedded(state,isEmbedded) {
    state.isEmbedded = isEmbedded;
}

export function setAutoSaveEnabled(state,autoSaveEnabled) {
    state.shared.autoSaveEnabled = autoSaveEnabled;
}

export function setProfiles(state,profiles) {
    state.shared.profiles = profiles;
}

export function setUnauthenticatedLogin(state,value) {
    state.unAuthenticatedLogin = value;
}
export function setHaveHistory(state,value) {
    state.haveHistory = value;
}