import Defines from './defines'
import Utils from './jsutils'
import VueInstance from './vueinstance'

function error(schedule, error) {
    schedule.errors.push(error);
    return schedule;
}

let targets = { plannedStart: 200, actualStart: 201, plannedEnd: 202, quantityToProduce: 203, quantityProduced: 204, productionStart: 205,
    workorder: 206, estimatedEnd: 207, actualEnd: 208, status: 209, statusString: 210, quantityRemaining: 211, progress: 212 };

export default {

    targets: targets,
    errors: { NoError: 0, NoData: 1 },
    batchComponents: [
        { name: "PlannedStart", type: "BATCHString", target: targets.plannedStart, show: "Planned start" },
        { name: "ActualStart", type: "BATCHString", target: targets.actualStart, show: "Actual start" },
        { name: "TheoreticalNetDuration", type: "BATCHString", target: targets.plannedEnd, show: "Planned end" },
        { name: "EstimatedNetDuration", type: "BATCHString", target: targets.estimatedEnd, show: "Estimated end" },
        { name: "ActualEnd", type: "BATCHString", target: targets.actualEnd, show: "Actual end" },
        { name: "Status", type: "BATCHString", target: targets.status, show: "Status" },
        { name: "StatusString", type: "BATCHString", target: targets.statusString, show: "Status textual" },
        { name: "Workorder", type: "BATCHString", target: targets.workorder, show: "Current workorder" },
        { name: "QuantityToProduce", type: "BATCHComponent", target: targets.quantityToProduce, show: "Quantity to produce" },
        { name: "QuantityProduced", type: "BATCHComponent", target: targets.quantityProduced, show: "Quantity produced" },
        { name: "QuantityRemaining", type: "BATCHComponent", target: targets.quantityRemaining, show: "Quantity remaining" },
        { name: "Progress", type: "BATCHComponent", target: targets.progress, show: "Progress" }
    ],
    batchGoodComponents: [
        { name: "EstimatedEndGood", type: "BATCHComponent", target: targets.estimatedEnd, show: "Estimated End Good" ,relatedTo: "EstimatedEnd"},
        { name: "QuantityProducedGood", type: "BATCHComponent", target: targets.quantityProduced, show: "Quantity Produced Good" ,relatedTo: "QuantityProduced"},
        { name: "QuantityRemainingGood", type: "BATCHComponent", target: targets.quantityRemaining, show: "Quantity Remaining Good" ,relatedTo: "QuantityRemaining"},
        { name: "ProgressGood", type: "BATCHComponent", target: targets.progress, show: "Progress", relatedTo: "Progress Good" }
    ],

    getDataDescriptor(productionSource, targets, goodProductionData) {

        if(!productionSource.startsWith("BATCH_"))
            productionSource = "BATCH_" + productionSource;

        let returning = { filters: [], data: [], aggregations: [] };
        let batchGoodComponents = [];

        for(const component of this.batchComponents) {

            if(Array.isUseful(targets))
                if(!targets.includes(component.target))
                    continue;

            if(component.name === "PlannedStart") {
                returning.filters.push({
                    index: "",
                    root: "Line",
                    name: 'WorkorderID',
                    type: 'keyword',
                    selectedForFiltering: true,
                    filters: [{
                        conditions: [{operator: '=', value: "@CurrentWorkOrder"}],
                        defaultName: "Select current workorder",
                        name: "Select current workorder",
                        enabled: true,
                        filterId: "SelectCurrentWorkorder" //Create a unique id that survives changes
                    }]
                });
                returning.data.push({
                    index: productionSource.replace("BATCH_", ""),
                    root: 'Line',
                    name: 'WorkorderID',
                    type: 'keyword',
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: Defines.allAggregations.first.id,
                            filters: [ "SelectCurrentWorkorder" ],
                            target: component.target,
                            defaultName: "Planned start",
                            name: "Planned start",
                            enabled: true,
                            timeless: true,
                        },
                    ],
                });
                continue;
            }

            returning.data.push({
                index: productionSource,
                root: "Line",
                name: component.name,
                type: component.type,
                selectedForVisualization: true,
                representations: [
                    {
                        type: Defines.allAggregations.raw.id,
                        filters: [  ],
                        target: component.target,
                        defaultName: component.show,
                        name: component.show,
                        enabled: true,
                        timeless: true,
                    },
                ],
            })

        }

        if (goodProductionData) {
            for (const component of this.batchGoodComponents) {
                batchGoodComponents.push({
                    index: productionSource,
                    root: "Line",
                    name: component.name,
                    type: component.type,
                    selectedForVisualization: true,
                    representations: [
                        {
                            type: Defines.allAggregations.raw.id,
                            filters: [],
                            target: component.target,
                            defaultName: component.show,
                            name: component.show,
                            enabled: true,
                            timeless: true,
                        },
                    ],
                })

            }
            //replace proper objects with "good" replacement
            returning.data = returning.data.map(obj => batchGoodComponents.find(o => o.name.replace("Good", "") === obj.name) || obj);
        }

        return returning;
    },

    calculate(dataValues) {

        let schedule = {
            plannedStart: 0,
            actualStart: 0,
            plannedEnd: 0,
            estimatedEnd: 0,
            quantityToProduce: 0,
            quantityProduced: 0,
            progress: 0,
            status: 0,
            workorder: "",
            startDelta: 0,
            endDelta: 0,
            errors: [],
            warnings: []
        };

        if(dataValues.length === 0) {
            return error(schedule, this.errors.NoData);
        }

        schedule.plannedEnd = 0;

        for(const dataSet of dataValues) {
            if (dataSet && Array.isUseful(dataSet.data)) {
                if (dataSet.target === this.targets.quantityToProduce)
                    schedule.quantityToProduce = dataSet.data.last().y;
                else if (dataSet.target === this.targets.quantityProduced)
                    schedule.quantityProduced = dataSet.data.last().y;
                else if (dataSet.target === this.targets.statusString)
                    schedule.statusString = dataSet.data.last().y;
                else if (dataSet.target === this.targets.progress)
                    schedule.progress = Utils.roundToDigits(dataSet.data.last().y, 1);
                else if (dataSet.target === this.targets.plannedEnd)
                    schedule.plannedEnd = dataSet.data.last().y;
                else if (dataSet.target === this.targets.estimatedEnd)
                    schedule.estimatedEnd = dataSet.data.last().y;
                else if (dataSet.target === this.targets.plannedStart)
                    schedule.plannedStart = dataSet.data[0].x;
                else if (dataSet.target === this.targets.actualStart)
                    schedule.actualStart = Date.parse(dataSet.data.last().y);
                else if (dataSet.target === this.targets.workorder)
                    schedule.workorder = dataSet.data.last().y;

                if(Object.isUseful(dataSet.data.last().error) && dataSet.data.last().error && dataSet.data.last().error !== "NoError")
                    schedule.errors.push(dataSet.data.last().error);
                if(Object.isUseful(dataSet.data.last().warning) && dataSet.data.last().warning && dataSet.data.last().warning !== "NoWarning")
                    schedule.warnings.push(dataSet.data.last().warning);
            }
        }

        if(!schedule.workorder)
            schedule.errors.push(VueInstance.get().$gettext("No workorder scheduled on the line"));

        //Graph tweaks
        schedule.startDelta = Math.abs(schedule.actualStart - schedule.plannedStart) / 60000;
        schedule.endDelta = Math.abs(schedule.estimatedEnd - schedule.plannedEnd);

        return schedule;
    },

}
