import VueInstance from "./vueinstance";

let avionicsDataTypes = {
    time: {show: "time", id: "time"},
    text: {show: "text", id: "text"},
    number: {show: "number", id: "number"},
    boolean: {show: "bool", id: "bool"},
    geopoint: {show: "geopoint", id: "geopoint"},
    unknown: {show: "unknown", id: "unknown"},
    date: {show: "date", id: "date"},
};

let allAggregations = {
    raw: {show: "Raw data", id: "raw"},
    intervals: {show: "Data intervals", id: "intervals"},
    changes: {show: "Data changes", id: "changes"},
    first: {show: "First value", id: "first"},
    last: {show: "Last value", id: "last"},
    avg: {show: "Average", id: "avg"},
    min: {show: "Min", id: "min"},
    max: {show: "MAX", id: "max"},
    "stats.std_deviation": {show: "Standard deviation", id: "stats.std_deviation"},
    "stats.variance": {show: "Variance", id: "stats.variance"},
    // stddevlow: {show: "Standard deviation bound low", id: "stddevlow"},
    // stddevhigh: {show: "Standard deviation bound high", id: "stddevhigh"},
    sum: {show: "Sum", id: "sum"},
    cavg: {show: "Cross Recursion Average", id: "cavg"},
    cmin: {show: "Cross Recursion Min", id: "cmin"},
    cmax: {show: "Cross Recursion MAX", id: "cmax"},
    csum: {show: "Cross Recursion Sum", id: "csum"},
    percentile: {show: "Percentile", id: "percentile"},
    terms: {show: "Values survey", id: "terms"},
    histogram: {show: "Histogram", id: "histogram"},
    filter: {show: "Data filter", id: "filter"},
    cumusum: {show: "Cumulative sum", id: "cumusum"},
    // movesum: {show: "Moving sum", id: "movesum"},  //V5.1 movesum is deprecated due to unusage
    count: {show: "Count", id: "count"},
    ttsurvey: {show: "All categories survey", id: "ttsurvey"},
    ttranking: {show: "All categories ranking", id: "ttranking"},
    ttlog: {show: "Time log", id: "ttlog"},
    oeeraw: {show: "Raw data", id: "oeeraw"},
    buckets: {show: "Buckets aggregation", id: "buckets"},
    manual: {show: "Manual aggregation", id: "manual"},
    inner: {show: "Inner join", id: "inner"},
    outer: {show: "Outer join", id: "outer"},
    tablegroup: {show: "Group as table", id: "tablegroup"},
    duration: {show: "Duration (Seconds)", id: "duration"},
    countdistinct: {show: "Count distinct values", id: "countdistinct"},
    categorygroup: {show: "Group by category", id: "categorygroup"},
    "variable.index": {show: "Variable index", id: "variable.index"},
    "variable.name": {show: "Variable name", id: "variable.name"},
    "variable.root": {show: "Variable root", id: "variable.root"}
};

let allFunctions = {
    value: {show: "Constant value", id: "value"},
    cp: {show: "Process capability (Cp)", id: "Cp"},
    cpk: {show: "Process capability (Cpk)", id: "Cpk"},
    statmerge: {show: "Merge Statistic (StatMerge)", id: "StatMerge"},
};

let WellKnownFields = {
    PlainFields: {
        minuteofday: {id: "minuteofday", comparers: null, aggregations: null},
        dayofweek: {id: "dayofweek", comparers: null, aggregations: null},
        dayofyear: {id: "dayofyear", comparers: null, aggregations: null},
        weekofyear: {id: "weekofyear", comparers: null, aggregations: null},
        month: {id: "month", comparers: null, aggregations: null},
        position: {id: "position", comparers: null, aggregations: null},
        random: {id: "random", comparers: [ "=", "!=", ">", "<", ">=", "<=", "RAND" ], aggregations: null},
        serial: {id: "serial", comparers: [ "=", "!=", "VALID" ], aggregations: null},
        workorderid: {id: "workorderid", comparers: null, aggregations: null},
    },
    NestedFields: {
        workorder: {id: {id: "workorderid", comparers: null, aggregations: null}},
        recipe: {id: {id: "recipeid", comparers: null, aggregations: null}},
        line: {
            workorderid: {id: "workorderid", comparers: null, aggregations: null},
            recipeid: {id: "recipeid", comparers: null, aggregations: null}
        },
        timetracking: {
            activity: {
                id: "timetracking",
                comparers: [ "=", "!=", ">", "<", ">=", "<=" ],
                defaultCompareValue: 0,
                aggregations: [allAggregations.duration, allAggregations.last, allAggregations.count, allAggregations.ttsurvey, allAggregations.ttranking, allAggregations.ttlog, allAggregations.raw, allAggregations.changes, allAggregations.intervals],
                visualizationOptions:  [{
                    name: "Include inactive result",
                    id: "includeInactiveResults",
                    type: "bool",
                    default: false
                }]
            }
        },
        tag: {device: {id: "device", comparers: null, aggregations: null}},
    },
    RootNodes: {
        location: {id: "location", comparers: [ "=", "!=" ], aggregations: null, fullNodeComparable: true},
        position: {id: "position", comparers: [ "=", "!=" ], aggregations: null, fullNodeComparable: true, fullNodeQuerable: true},
    }
};

let WellKnownAliases = {
    workorderid: [{name: 'WorkorderID', root: 'Line', type: 'keyword'}, {name: 'Id', root: 'Workorder', type: 'keyword'}, {name: 'WorkorderID', root: '', type: 'keyword'}],
}

let WellKnownVariables = [
        {id: "Serial", type: 'Text', mandatory: false, mustMatch: false},
        {id: "Gtin", type: 'Text', mandatory: false, mustMatch: false},
];

export default {

    avionicsDataTypes: avionicsDataTypes,
    allAggregations: allAggregations,
    allFunctions: allFunctions,
    numericAggregations: [
        allAggregations.raw, allAggregations.sum, allAggregations.duration, allAggregations.first, allAggregations.last, allAggregations.avg, allAggregations.min,
        allAggregations.max, allAggregations.cumusum, allAggregations.intervals, allAggregations.changes, allAggregations["stats.std_deviation"], allAggregations["stats.variance"],
        allAggregations.count, allAggregations.terms, allAggregations.histogram, allAggregations.countdistinct, /*allAggregations.movesum,*/
        allAggregations["variable.name"], allAggregations["variable.index"], allAggregations["variable.root"]
    ],
    stringAggregations : [ allAggregations.raw, allAggregations.duration, allAggregations.intervals, allAggregations.changes, allAggregations.first, allAggregations.last, allAggregations.count, allAggregations.terms, allAggregations.countdistinct ],
    oeeAggregations : [ allAggregations.oeeraw, allAggregations.avg, allAggregations.min, allAggregations.max,allAggregations.cavg, allAggregations.cmin, allAggregations.cmax],
    crossAggregations: [ allAggregations.avg, allAggregations.min, allAggregations.max, allAggregations.sum,allAggregations.cavg, allAggregations.cmin, allAggregations.cmax, allAggregations.csum, allAggregations.buckets, allAggregations.manual, allAggregations.inner, allAggregations.outer, allAggregations.tablegroup, allAggregations.categorygroup ],
    crossAggregationsNoGrouping: [ allAggregations.avg, allAggregations.min, allAggregations.max, allAggregations.sum,allAggregations.cavg, allAggregations.cmin, allAggregations.cmax, allAggregations.csum, allAggregations.buckets, allAggregations.manual ],
    functions: [ allFunctions.value, allFunctions.cp, allFunctions.cpk, allFunctions.statmerge ],
    //cross aggregation target was deprecated in v2.0, definition is left here for all datasets updating procedures
    crossAggregationTarget: { show: "Aggregate with other data", id: "crossAggregation" },
    numericComparers: ["=", "!=", ">", "<", ">=", "<=", "exists", "not_exists"],
    stringComparers: ["=", "!=", "exists", "IsEmpty", "IsNotEmpty"],
    dateTimeComparers: ["=", "!=", "exists"],

    WellKnownItems: {
        MinuteOfDay: WellKnownFields.PlainFields.minuteofday.id,
        DayOfWeek: WellKnownFields.PlainFields.dayofweek.id,
        DayOfYear: WellKnownFields.PlainFields.dayofyear.id,
        WeekOfYear: WellKnownFields.PlainFields.weekofyear.id,
        Month: WellKnownFields.PlainFields.month.id,
        WorkOrder: WellKnownFields.NestedFields.workorder.id.id,
        Recipe: WellKnownFields.NestedFields.recipe.id.id,
        TimeTracking: WellKnownFields.NestedFields.timetracking.activity.id,
        Device: WellKnownFields.NestedFields.tag.device.id,
        Position: WellKnownFields.PlainFields.position.id,
        Location: WellKnownFields.RootNodes.location.id,
        Random: WellKnownFields.PlainFields.random.id,
        Serial: WellKnownFields.PlainFields.serial.id
    },

    WellKnownTypes: { OEEComponent: { show: "number", id: "OEEComponent" } },

    OrderByItems: {
        categories: "key",
        values: "value",
        absoluteValues: "absoluteValues"
    },

    OrderItems: {
        ascending: "asc",
        descending: "desc"
    },

    DatasetFormats: {
        xy: "xy",
        categories: "categories",
        ttsurvey: "ttsurvey",
        ttranking: "ttranking",
        ttlog: "ttlog",
        buckets: "buckets",
        table: "table"
    },

    getDataPatternDescriptor(tokens, isMultiSelect, selectVirtualIndexes) {
        return { pattern: tokens, multiSelect: isMultiSelect, virtualIndexes: selectVirtualIndexes };
    },

    getWellKnownItem(item) {
        //Root nodes
        if(item.isRoot) {
            if(WellKnownFields.RootNodes.hasOwnProperty(item.name.toLowerCase()))
                return WellKnownFields.RootNodes[item.name.toLowerCase()];
            else return null;
        }
        //Variables
        if(WellKnownFields.PlainFields.hasOwnProperty(item.name.toLowerCase()))
            return WellKnownFields.PlainFields[item.name.toLowerCase()];
        else if((WellKnownFields.NestedFields.hasOwnProperty(item.root.toLowerCase()) && WellKnownFields.NestedFields[item.root.toLowerCase()].hasOwnProperty(item.name.toLowerCase())))
            return WellKnownFields.NestedFields[item.root.toLowerCase()][item.name.toLowerCase()];
        return null;
    },

    isWellKnowAlias(item) {
      let wellKnownItem = this.getWellKnownItem(item);
      if (wellKnownItem) {
          if (WellKnownAliases.hasOwnProperty(wellKnownItem.id)) {
              return wellKnownItem.id;
          }
      }
      return "";
    },

    getWellKnownAliases(item) {
        let wellKnownItem = this.getWellKnownItem(item);
        let aliases = [];
        if (wellKnownItem)
            if (WellKnownAliases.hasOwnProperty(wellKnownItem.id))
                aliases = [...WellKnownAliases[wellKnownItem.id]];
        return aliases;
    },

    getWellKnownVariable(item) {
        return WellKnownVariables.filter(variable => variable.id === item)
    },

    getWellKnownVariables() {
        return WellKnownVariables
    },

    getWellKnownItemId(item) {
        let wellKnownItem = this.getWellKnownItem(item);
        if(Object.isUseful(wellKnownItem))
            return wellKnownItem.id.toLowerCase();
        return null;
    },

    getWellKnownItemAggregations(item) {
        let wellKnownItem = this.getWellKnownItem(item);
        if(Object.isUseful(wellKnownItem))
            return wellKnownItem.aggregations;
        return null;
    },

    getWellKnownItemComparers(item) {
        let wellKnownItem = this.getWellKnownItem(item);
        if(Object.isUseful(wellKnownItem))
            return wellKnownItem.comparers;
        return null;
    },

    getWellKnownItemCompareValue(item) {
        let wellKnownItem = this.getWellKnownItem(item);
        if(Object.isUseful(wellKnownItem))
            return wellKnownItem.defaultCompareValue;
        return "";
    },

    isWellKnownItem(item, wellKnownItem) {
        return (Object.isUseful(wellKnownItem) && wellKnownItem && this.getWellKnownItemId(item) === wellKnownItem.toLowerCase());
    },

    isFilterable(item) {

        let specialCases = this.getWellKnownItemComparers(item);
        if(specialCases != null && specialCases.length === 0)
            return false;
        let avionicsType = this.getAvionicsDataType(item.type);
        return (avionicsType.id === this.avionicsDataTypes.time.id || avionicsType.id === this.avionicsDataTypes.text.id || avionicsType.id === this.avionicsDataTypes.boolean.id || avionicsType.id === this.avionicsDataTypes.number.id);
    },

    isVisualizable(item) {
       return true; //TODO requires filtering also on well known fields
    },

    isGrouping(representationType) {
        return (representationType === allAggregations.inner.id || representationType === allAggregations.outer.id);
    },

    isRawAggregation(representationType) {
        return (representationType === allAggregations.raw.id
            || representationType === allAggregations.intervals.id
            || representationType === allAggregations.changes.id
            || representationType === allAggregations.first.id
            || representationType === allAggregations.last.id
            || representationType === allAggregations.min.id
            || representationType === allAggregations.max.id
            || representationType === allAggregations.oeeraw.id);
    },

    isTermsAggregation(representationType) {
        return (representationType === allAggregations.terms.id
                || representationType === allAggregations.ttsurvey.id
                || representationType === allAggregations.ttranking.id
                || representationType === allAggregations.countdistinct.id);
    },

    isHistogramAggregation(representationType) {
        return representationType === allAggregations.histogram.id
    },

    isBucketingAggregation(representationType) {
        return this.isTermsAggregation(representationType) || this.isHistogramAggregation(representationType)
    },

    isCategoricalAggregation(representationType) {
        return ((this.isTermsAggregation(representationType) && representationType !== allAggregations.countdistinct.id)
            || representationType === allAggregations.histogram.id);
    },

    // isStatsAggregation(representationType) {
    //     return (representationType === allAggregations.stddev.id || representationType === allAggregations.stddevlow.id || representationType === allAggregations.stddevhigh.id);
    // },

    getAvionicsDataType(elasticType) {
        if (elasticType === "date")
            return this.avionicsDataTypes.time;
        else if (elasticType === "keyword" || elasticType === "text")
            return this.avionicsDataTypes.text;
        else if (elasticType === "integer" || elasticType === "long" || elasticType === "float" || elasticType === "short" || elasticType === "byte" || elasticType === "double" || elasticType === "half_float" || elasticType === "scaled_float")
            return this.avionicsDataTypes.number;
        else if (elasticType === "boolean")
            return this.avionicsDataTypes.boolean;
        else if (elasticType === "geo_point")
            return this.avionicsDataTypes.geopoint;
        else if (elasticType === this.WellKnownTypes.OEEComponent.id)
            return this.WellKnownTypes.OEEComponent;
        else return this.avionicsDataTypes.unknown;
    },

    getAggregationsForDataItem(dataItem) {

        //First of all verify if current variable is a well known special one, in case it will eventually have his own aggregations...
        let specialCases = this.getWellKnownItemAggregations(dataItem);
        if(Object.isUseful(specialCases))
            return specialCases;

        //... otherwise select aggregations by type
        let avionicsType = this.getAvionicsDataType(dataItem.type);

        if (avionicsType.id === this.avionicsDataTypes.time.id || avionicsType.id === this.avionicsDataTypes.text.id || avionicsType.id === this.avionicsDataTypes.boolean.id)
            return this.stringAggregations;
        else if (avionicsType.id === this.avionicsDataTypes.number.id)
            return this.numericAggregations;
        else if (avionicsType.id === this.WellKnownTypes.OEEComponent.id)
            return this.oeeAggregations;
        else
            return ["raw"];
    },

    getVisualizationsOptionsForDataItem(dataItem) {
            return this.getWellKnownItemVisualizationOptions(dataItem);
    },

    getWellKnownItemVisualizationOptions(item) {
        let wellKnownItem = this.getWellKnownItem(item);
        if(Object.isUseful(wellKnownItem))
            return wellKnownItem.visualizationOptions;
        return null;
    },

    isNumericItem(dataItem) {
        return (this.getAvionicsDataType(dataItem.type).id === this.avionicsDataTypes.number.id);
    },

    filterComparers(comparers, enabled) {
        if(!enabled)
            return comparers;
        comparers.removeItem("exists");
        comparers.removeItem("not_exists");
        comparers.removeItem("IsEmpty");
        comparers.removeItem("IsNotEmpty");
        return comparers;
    },

    getComparersForDataItem(dataItem, celCompatibleOnly = false) {
        const stringComparers = [...this.stringComparers];
        const numericComparers = [...this.numericComparers];
        const dateTimeComparers = [...this.dateTimeComparers];

        //First of all verify if current variable is a well known special one, in case it will eventually have his own comparers...
        let specialCases = this.getWellKnownItemComparers(dataItem);
        if(Object.isUseful(specialCases)) {
            return this.filterComparers(specialCases, celCompatibleOnly);
        }

        //... otherwise select comparers by type
        let avionicsType = this.getAvionicsDataType(dataItem.type);

        if (avionicsType.id === this.avionicsDataTypes.text.id || avionicsType.id === this.avionicsDataTypes.boolean.id)
            return this.filterComparers(stringComparers, celCompatibleOnly);
        else if (avionicsType.id === this.avionicsDataTypes.number.id || avionicsType.id === this.WellKnownTypes.OEEComponent.id)
            return this.filterComparers(numericComparers, celCompatibleOnly);
        else if (avionicsType.id === this.avionicsDataTypes.time.id || avionicsType.id === this.avionicsDataTypes.date.id) {
            console.log(dataItem)
            return this.filterComparers(dateTimeComparers, celCompatibleOnly);
        }
        else
            return [];
    },

    //Returns operator applicable to the output of a query.
    getComparersForRepresentation(dataItem, representation, celCompatibleOnly = false) {
        let comparers = [];
        if(this.isRawAggregation(representation.type)) //If query is raw, the output type is the same of variable
            comparers = this.getComparersForDataItem(dataItem, celCompatibleOnly);
        else if(this.isTermsAggregation(representation.type)) //Terms aggregation results are not directly comparable to anything
            return [];
        else comparers = this.filterComparers(this.numericComparers, celCompatibleOnly);   //All the rest are numbers
        return comparers;
    },

    getElasticRepresentationType(representation) {
        //Some representation types are special in that they represent different rearrangements of results of a standard query.
        //We translate the "virtual" type into the real elastic one
        if(this.isTermsAggregation(representation.type))
            return this.allAggregations.terms.id;
        if(representation.type === this.allAggregations.oeeraw.id || representation.type === this.allAggregations.intervals.id
            || representation.type === this.allAggregations.changes.id)
            return this.allAggregations.raw.id;
        if(representation.type === this.allAggregations.duration.id)
            return this.allAggregations.count.id;
        return representation.type;
    },
}
