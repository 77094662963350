import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en_GB',
    fallbackLocale: 'en_GB'
})

export default ({ app, router, store, Vue }) => {
    app.i18n = i18n
    Vue.prototype.$loadLanguageTranslations = function (languageID) {
        if (Vue.prototype.$isEmpty(languageID)) {
            return
        }
        Vue.prototype.LanguageAPI.loadTranslations(languageID).then(t => {
            if (t.data.Status === 'success' && i18n.setLocaleMessage !== undefined) {
                let translations = JSON.parse(t.data.Data)
                i18n.setLocaleMessage(languageID, translations)
                if (i18n.locale !== languageID) {
                    i18n.locale = languageID
                }
            } else {
                Vue.prototype.$sendErrorNotification(t.data.ErrorMessage, [])
            }
        }).catch(t => {
            Vue.prototype.$sendErrorNotification({ Message: 'Error occurred: {0}', Parameters: [t] })
        })
    }
    Vue.prototype.$baseMount = function ($route){
        if ($route.query.u !== undefined && $route.query.u !== '') {
            Vue.prototype.$store.dispatch('setBaseURL', Vue.prototype.$decode($route.query.u))
        }
        Vue.prototype.setPageInLoading()
        Vue.prototype.$store.dispatch('SetWebSocketHook', { Scope: 'OnSharedStoreChanged', Function: onSharedStoreChanged })
        Vue.prototype.$store.dispatch('SetWebSocketHook', { Scope: 'RaiseOnRequestToStoreChange', Function: raiseOnRequestToStoreChange })
        obtainTokenFromBookedRequest($route)
    }
    Vue.prototype.updateBreadcrumbs = function ($route) {
        if (Vue.prototype.$isEmpty($route.meta.breadcrumb)) {
            return
        }
        let parameters = {}
        if (!Vue.prototype.$isEmpty($route.query) && !Vue.prototype.$isEmpty($route.query.d)) {
            parameters = $route.query.d
        }
        Vue.prototype.OrchestratorAPI.notifyEvent('OnRequestBreadcrumbUpdate', { breadcrumb: $route.meta.breadcrumb, parameters: parameters })
    }
    let obtainTokenFromBookedRequest = function($route) {
        Vue.prototype.WebUIAPI.getTokenFromServer($route.query.t).then(t => {
            if (t.data.Status === 'success') {
                let dataToken = JSON.parse(t.data.Data)
                Vue.prototype.$store.dispatch('setSession', {
                    token: dataToken,
                    userName: '',
                    addresses: []
                })
                Vue.prototype.$connectWebSocket()
                if (Vue.prototype.$checkAuth){
                    Vue.prototype.$checkAuth(okChechAuth, badCheckAuth)
                }
                Vue.prototype.updateBreadcrumbs($route)
            } else {
                Vue.prototype.$sendErrorNotification(t.data.ErrorMessage)
            }
        }).catch(t => {
            Vue.prototype.$sendErrorNotification({ Message: 'Error occurred: {0}', Parameters: [t] })
        })
    }
    let raiseOnRequestToStoreChange = function (mutationName, payload) {
        Vue.prototype.OrchestratorAPI.notifyEvent('OnRequestToStoreChange', { mutationName: mutationName, payload: payload }).then(t => {
            if (t.data.Status !== 'success') {
                Vue.prototype.$sendErrorNotification(t.data.ErrorMessage)
            }
        }).catch(t => {
            Vue.prototype.$sendErrorNotification({ Message: 'Error occurred: {0}', Parameters: [t] })
        }).finally(() => {})
    }
    let onSharedStoreChanged = function (val) {
        if (Vue.prototype.$isEmpty(val.Message)) {
            return
        }
        console.log('update store event received')
        Vue.prototype.$store.dispatch('updateSharedState', val.Message)
        Vue.prototype.$loadLanguageTranslations(Vue.prototype.$store.getters.selectedLanguage)
        i18n.locale = Vue.prototype.$store.getters.selectedLanguage
        Vue.prototype.setPageLoadingCompleted()
    }
    let okChechAuth = function () {
        if (Vue.prototype.$route.fullPath === '/') {
            Vue.prototype.$goToPage('index')
        }
    }
    let badCheckAuth = function () {
        Vue.prototype.$goToPage('login')
    }
}

export { i18n }
