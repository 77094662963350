<template>
    <v-dialog v-model="showDialog" width="840px" height="700px" min-height="700px" :persistent="true" id="antares-timespan-dialog" data-qa-type="dialog" ata-qa-name="timespan-dialog">
        <v-card :height="setHeight" id="antares-timespan-dialog-card" data-qa-type="card" data-qa-name="timespan-dialog-card">
            <v-container fill-height pa-3>
                <v-layout column>
                    <label class="display-1 text-xs-center" id="antares-timespan-dialog-card-title" v-translate>Choose a visualization time window</label>
                    <v-layout row justify-space-between align-center v-if="!showChooseTime">
                        <label class="sectionTitle text-xs" id="antares-timespan-dialog-card-subtitle-not" v-translate>Do not apply time window filter</label>
                    </v-layout>
                    <v-layout column v-show="showChooseTime">
                        <v-layout row justify-space-between align-center>
                            <label class="sectionTitle text-xs" id="antares-timespan-dialog-card-subtitle-choos">{{timeTo ? $gettext('Choose Time To:') : $gettext('Choose Time From:')}}</label>
                            <v-btn style="width: 100px" id="btn-timespan-now" :color="getColor('now')" @click="setSpan('now')" data-qa-type="button" data-qa-name="set-span-now">NOW</v-btn>
                        </v-layout>
                        <v-spacer></v-spacer>
                        <v-layout row justify-space-between>
                            <v-flex xs2>
                                <v-btn style="width: 100px" :id="'btn-timespan-'+displayedTimeWindow(_minutes).replaceAll(' ','-')" v-for="_minutes in minutes" v-if="!hiddenFields.includes(_minutes)" :disabled="isDisabled(_minutes) && !timeTo" :color="getColor(_minutes)" @click="setSpan(_minutes)" data-qa-type="button" :data-qa-name="'btn-timespan-'+displayedTimeWindow(_minutes).replaceAll(' ','-')">{{displayedTimeWindow(_minutes)}}</v-btn>
                            </v-flex>
                            <v-flex xs2>
                                <v-btn style="width: 100px" :id="'btn-timespan-'+displayedTimeWindow(_hours).replaceAll(' ','-')" v-for="_hours in hours" v-if="!hiddenFields.includes(_hours)" :disabled="isDisabled(_hours) && !timeTo" :color="getColor(_hours)" @click="setSpan(_hours)" data-qa-type="button" :data-qa-name="'btn-timespan-'+displayedTimeWindow(_hours).replaceAll(' ','-')">{{displayedTimeWindow(_hours)}}</v-btn>
                            </v-flex>
                            <v-flex xs2>
                                <v-btn style="width: 100px" :id="'btn-timespan-'+displayedTimeWindow(_days).replaceAll(' ','-')" v-for="_days in days" v-if="!hiddenFields.includes(_days)" :disabled="isDisabled(_days) && !timeTo" :color="getColor(_days)" @click="setSpan(_days)" data-qa-type="button" :data-qa-name="'btn-timespan-'+displayedTimeWindow(_days).replaceAll(' ','-')">{{displayedTimeWindow(_days)}}</v-btn>
                            </v-flex>
                            <v-flex xs2>
                                <v-btn style="width: 100px" :id="'btn-timespan-'+displayedTimeWindow(_months).replaceAll(' ','-')" v-for="_months in months" v-if="!hiddenFields.includes(_months)" :disabled="isDisabled(_months) && !timeTo" :color="getColor(_months)" @click="setSpan(_months)" data-qa-type="button" :data-qa-name="'btn-timespan-'+displayedTimeWindow(_months).replaceAll(' ','-')">{{displayedTimeWindow(_months)}}</v-btn>
                            </v-flex>
                            <v-flex xs2>
                                <v-btn style="width: 100px" :id="'btn-timespan-'+displayedTimeWindow(_years).replaceAll(' ','-')" v-for="_years in years" v-if="!hiddenFields.includes(_years)" :disabled="isDisabled(_years) && !timeTo" :color="getColor(_years)" @click="setSpan(_years)" data-qa-type="button" :data-qa-name="'btn-timespan-'+displayedTimeWindow(_years).replaceAll(' ','-')">{{displayedTimeWindow(_years)}}</v-btn>
                            </v-flex>
                            <v-flex xs2>
                                <v-btn style="width: 100px" :id="'btn-timespan-'+displayedTimeWindow(_namedMonths).replaceAll(' ','-')" v-for="_namedMonths in namedMonths1" v-if="!hiddenFields.includes(_namedMonths)" :disabled="isDisabled(_namedMonths) && !timeTo" :color="getColor(_namedMonths)" @click="setSpan(_namedMonths)" data-qa-type="button" :data-qa-name="'btn-timespan-'+displayedTimeWindow(_namedMonths).replaceAll(' ','-')">{{displayedTimeWindow(_namedMonths)}}</v-btn>
                            </v-flex>
                            <v-flex xs2>
                                <v-btn style="width: 100px" :id="'btn-timespan-'+displayedTimeWindow(_namedMonths).replaceAll(' ','-')" v-for="_namedMonths in namedMonths2" v-if="!hiddenFields.includes(_namedMonths)" :disabled="isDisabled(_namedMonths) && !timeTo" :color="getColor(_namedMonths)" @click="setSpan(_namedMonths)" data-qa-type="button" :data-qa-name="'btn-timespan-'+displayedTimeWindow(_namedMonths).replaceAll(' ','-')">{{displayedTimeWindow(_namedMonths)}}</v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout v-if="timespan.predefined" row class="mt-4 mb-4">
                            <av-switch style="margin-top: 10px" id="switch-timespan-align-to-time-of-day" :label="$gettext('Align data to a specific time of day')" :value="timespan.alignToTimeOfDay !== ''" @change="enableAlignToTimeOfDay($event)" :disabled="disableTimeWindowAlignment" data-qa-type="input-switch" data-qa-name="align-to-time-of-day"/>
                            <PopUpTimePicker v-if="timespan.alignToTimeOfDay !== ''" v-model="timespan.alignToTimeOfDay" type="minsColonSecs" :disabled="disableTimeWindowAlignment"/>
                            <v-spacer></v-spacer>
                        </v-layout>

                        <v-card v-if="!timespan.predefined" height="40px" style="line-height: 40px !important;" class="title text-xs-center" align-center fill-height color="info" data-qa-type="card" data-qa-name="custom-time-info"><span style="color: white" v-translate>Custom time window</span></v-card>
                        <label v-if="timeWindowRestricted" class="subtitle-1 text-xs-center font-weight-bold my-2" style="color: var(--av-red)" id="antares-timespan-dialog-timewindow-restricted-message" v-translate>Time window was automatically restricted to a maximum time window</label>
                        <v-layout row justify-center class="mt-3">
                            <i class="fa fa-hand-pointer-o" aria-hidden="true"></i>
                            <v-flex xs6 mr-2>
                                <v-datetime-picker id="pck-timespan-start" :label="lbStartDateTime" :datetime="rangeDisplay.start" @input="setAbsoluteStart($event)">
                                </v-datetime-picker>
                            </v-flex>
                            <v-flex xs6 ml-2>
                                <v-datetime-picker id="pck-timespan-end" :label="lbEndDateTime" :datetime="rangeDisplay.end" @input="setAbsoluteEnd($event)" />
                            </v-flex>
                        </v-layout>
                        <!--<label class="subheading">Click on start or stop date times to select an absolute time span</label>-->
                        <!--<label class="subheading">{{timespan}}</label>-->
                        <label class="subheading">{{error}}</label>
                        </v-layout>
                    <v-spacer></v-spacer>
                    <v-card-actions id="antares-timespan-dialog-card-actions">
                        <av-switch :label="timespan.isDisabled?$gettext('Ever (time window filter not applied)'):$gettext('Ever (do not apply time window filter)')" v-model="timespan.isDisabled"
                                   @change="timeFilterSwitch($event)" v-if="timespan.allowDisabling"
                                   style="width: 30%; margin-top: 15px" id="switch-timespan-is-disabled"
                                   data-qa-type="input-switch" data-qa-name="switch-timespan-is-disabled"/>
                        <v-spacer></v-spacer>
                        <v-btn v-if="showChooseTime" id="btn-timespan-time-to" color="blue darken-1" flat="flat" :disabled="timeToDisabled()"  @click="switchTimeFromTimeTo" data-qa-type="button" data-qa-name="time-span-select">
                            {{ timeTo ? $gettext('Select Time From') : $gettext('Select Time To') }}
                        </v-btn>
                        <v-btn id="btn-timespan--yes" color="green darken-1" flat="flat" @click="callBack(true)" v-translate data-qa-type="button" data-qa-name="time-span-ok">
                            OK
                        </v-btn>
                        <v-btn id="btn-timespan-no" color="red darken-1" flat="flat" @click="callBack(false)" v-translate data-qa-type="button" data-qa-name="time-span-cancel">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-layout>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>

    import {TimeSpan} from '@/api/datetimeutils'
    import PopUpTimePicker from '@/components/utilities/PopUpTimePicker'

    export default {
        name: 'TimeSpanDialog',
        components: {
            PopUpTimePicker
        },
        props: {
            timespan: {
                type: TimeSpan,
                required: true,
                default: null
            },
            showDialog: false,
            hidden: {
                type: Array,
                default: () => []
            },
            maxTimeWindow: ""
        },
        data () {
            return {
                callerInstance: null,
                ticker: this.startTicker(),
                refreshSpan: false,
                error: "",
                snoozeWatch: false,
                timeWindowRestricted: false,
                timeTo: false,
            }
        },
        computed: {
            hiddenFields() { return this.hidden },
            minutes() { return TimeSpan.minutesTimeWindowsMinutes },
            hours() { return TimeSpan.hoursTimeWindowsMinutes },
            days() { return !this.timeTo ? TimeSpan.daysTimeWindowsMinutes : TimeSpan.daysTimeWindowsMinutes.filter(timespan => timespan != 'shift') },
            months() { return TimeSpan.monthsTimeWindowsMinutes },
            namedMonths1() { return !this.timeTo ? TimeSpan.namedMonthsTimeWindowsMinutes.slice(0, 6) : [] },
            namedMonths2() { return !this.timeTo ? TimeSpan.namedMonthsTimeWindowsMinutes.slice(6, 12) : [] },
            years() { return TimeSpan.yearsTimeWindowsMinutes },
            rangeDisplay() {
                let dummy = this.refreshSpan;
                return { start: this.timespan.getStart(), end: this.timespan.getEnd() }
            },
            lbStartDateTime() { return this.$gettext("Start Datetime (click to set absolute value)") },
            lbEndDateTime() { return this.$gettext("End Datetime (click to set absolute value)") },
            disableTimeWindowAlignment() { return (this.timespan.predefined < 1440 || this.timespan.predefined === "shift") },
            getColor() { return (timespan) => (!this.timeTo && this.timespan.predefined === timespan || this.timeTo && this.timespan.timeToPredefined === timespan ? 'info' : '') },
            setHeight() {
                return this.showChooseTime ? '700px' : '250px';
            },
            showChooseTime() {
                return !this.timespan.isDisabled;
            }
        },
        watch: {
            timespan: {
                handler: function () {
                    this.error = "";
                    if (!this.timespan.predefined) {
                        if (!this.timespan.start)
                            this.error = this.$gettext("Please choose time window start");
                        if (!this.timespan.end)
                            this.error = this.$gettext("Please choose time window end");
                    } else {
                        if(this.snoozeWatch) {
                            this.snoozeWatch = false;
                            return;
                        }
                        if (!this.start || !this.end) {
                            this.setSpan(this.timespan.predefined);
                        }
                    }
                },
                deep: true
            }
        },
        methods: {
            timeFilterSwitch(switchStatus) {
                this.timespan.isDisabled = switchStatus;
                if(switchStatus) {
                    this.setSpan('ever');
                } else {
                    this.setSpan(15);
                }
                debugger;
            },
            switchTimeFromTimeTo() {
                this.snoozeWatch = true;
                this.timeTo = !this.timeTo
            },
            enableAlignToTimeOfDay(enable) {
                if(enable)
                    this.timespan.setAlignToTimeOfDay("23:59");
                else {
                    this.timespan.setAlignToTimeOfDay("");
                }
            },
            isDisabled(timeSpan) {
                return TimeSpan.timeSpanExceedsLimit(timeSpan, this.maxTimeWindow);
            },
            timeToDisabled() {
                if (!this.timespan.predefined || this.timespan.predefined === 'shift' || this.namedMonths1.includes(this.timespan.predefined) || this.namedMonths2.includes(this.timespan.predefined))
                    return true
                return false
            },
            startTicker() {
                let self = this;
                return setInterval(function() {
                    self.refreshSpan = !self.refreshSpan
                }, 1000);
            },
            setSpan(minutes) {
                this.snoozeWatch = true;    //Prevent watch looping
                if (this.timeTo)
                    this.timespan.setPredefinedTimeTo(minutes);
                else
                    this.timespan.setPredefined(minutes);
                this.error = "";
            },
            setAbsoluteStart(start) {
                this.setAbsolute();
                this.timespan.start = start;
                if (this.maxTimeWindow)
                    this.restrictTimeWindow()
            },
            restrictTimeWindow() {
                let end = Date.parse(this.timespan.getEnd())
                let start = Date.parse(this.timespan.getStart())
                if (start && end) {
                    let windowSizeMillis = parseInt(end - start) / 60000;
                    if (windowSizeMillis > this.maxTimeWindow){
                        let parseStart = new Date(this.timespan.start);
                        let startInMilis = parseStart.getTime();
                        let restrictedEnd = startInMilis + (this.maxTimeWindow * 60000)
                        this.timespan.end = new Date(restrictedEnd);
                        this.timeWindowRestricted = true
                    }
                }

            },
            setAbsoluteEnd(end) {
                this.setAbsolute();
                this.timespan.end = end;
                if (this.maxTimeWindow)
                    this.restrictTimeWindow()
            },
            setAbsolute() {
                // if(this.startRefresher)
                //     clearInterval(this.startRefresher);
                // if(this.nowRefresher && !this.timespan.end)
                //     clearInterval(this.nowRefresher);
                this.timespan.predefined = null;
            },
            formatDate(date) {
                if(date === 0)
                    return "";
                return (new Date(date)).format();
            },
            displayedTimeWindow(minutes) {
                return TimeSpan.format(minutes);
            },
            setCaller(caller) {
                this.callerInstance = caller;
            },
            callBack(result){
                if(result && this.error)
                    return;
                this.$emit('exit', result);
            },
        }
    }

</script>
