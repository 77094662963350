
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@babel/polyfill'
import './plugins/vuetify'
import 'material-design-icons/iconfont/material-icons.css'
import 'typeface-roboto/index.css'
import '@/api/customcomponents.js'
import '@/api/jsapis.js'
import '@/api/i18n.js'
import {IdleTimer} from "@/api/autologoff"

Vue.config.productionTip = false;
export const bus = new Vue();

import AuthApi from "@/api/auth";
import initializer from '@/init/client-entry'
import OrchestratorAPI from "@/api/orchestrator";
initializer.Init({

    data: {
        currentPageTitle: "",
        configured: false,
        startedUp: false,
        reloadDynamicLinks: false,
        reloadActions: false,
        hideFrame: false,
        annotationDialogOpen: false,
        loading: false,
        loadingMessage: "",
        loadingMinTime: 2000,
        workFlowSettings: null,
        standardTimes: null,
        productionTimeThreshold: 5000,
        notifications: [],
        messageBoxes: [],
        checkOutTimer: null,
        oskTimer: null,
        virtualKeyboard: {
            showVirtualKeyboard: false,
            target: null
        },
        toggleOsk: false,
        version: '',
        startingUp: false,
        notificationInfo: null,
        idleTimer: null,
        identification: {}
    },
    methods: {
        //Operations to be executed when main page loads
        async applicationStartUp(from, isMaximized) {
            if(this.startingUp) {
                console.log(from + " - Application already initializing");
                return;
            }
            let startTime = 0;
            try {
                this.startingUp = true;
                startTime = performance.now();
                console.log("Application startup set page loading " + from);
                Vue.prototype.setPageInLoading();
                //If there is no token, login as a default user
                if (!this.$config.isReportingService() && this.$route.path.match("/dashboards/.*/maximize") && from !== 'default_auth'){
                    AuthApi.doDefaultLogin()
                        .then(t => {
                            let status = t.data.Status;
                            if (status === "failed") {
                                this.showErrorNotification(this.$gettext("Error while login as a default user"));
                            } else if(t.data.Data!=="DEFAULT_PROFILE_NOT_FOUND" && t.data.Data!=="UNAUTHENTICATED_LOGIN_DISABLED"){
                                let answer = JSON.parse(t.data.Data);
                                this.$store.dispatch('setSession', {
                                    token: answer.access_token,
                                    userName: answer.userName,
                                    addresses: answer.AdditionalData.Addresses
                                });
                                this.$store.dispatch('setProfiles', answer.profiles);
                                this.applicationStartUp("default_auth", true);
                            }
                        })
                        .catch(t => {
                            console.log(t);
                        });
                    return false
                }

                if (this.$config.isReportingService()) {
                    this.reportingServiceStartUp();
                    return
                }
                console.log("ApplicationStartup load app config " + from);
                await this.loadAppConfig();
                if (!this.$store.state.isEmbedded || isMaximized) {
                    await this.loadActiveModules();
                    await this.loadOptions();
                    await this.$grants.init();
                }
                await this.$license.getInstalledModules();
                this.loadStrings();
                this.loadLanguages();
                this.loadLanguage();
                if (!await this.loadVersion())
                    return;
                this.initializeAvionicsMapping();
                if (this.$config.isAvionics) {
                    if(!this.$config.isTransactionMode()) {
                        this.$alarms.loadDescriptors();
                        this.$aliases.loadDescriptors();
                        await this.$settings.loadLineStopCauses(this.$config.deployment);
                        await this.$timeTracking.getUpdatedStopCauses();
                    }
                    if (this.$config.deployment === 2) {
                        await this.$settings.loadLineSettings();
                        this.workFlowSettings = await this.$settings.loadWorkFlowSettings();  //TODO L3?
                        this.standardTimes = await this.$settings.loadStandardTimesSettings()  //TODO L3?
                    }
                    if (this.$config.deployment === 3) {
                        await this.$settings.loadPlantSettings()
                    }
                    await this.$settings.loadTagsSettings();
                    await this.loadIdentificationSettings();
                } else {
                    this.$root.configured = true;
                    this.$root.startedUp = true;
                    this.raiseNotificationChannel();    //Raise web socket on L2 only for now
                }

                console.log("application startup set page loading complete");
                Vue.prototype.setPageLoadingCompleted();
                if (this.$config.deployment === 0) {
                    this.$root.startedUp = false;
                    return;
                }
                let self = this;
                this.checkOutTimer = setInterval(() => {
                    // Check every 5seconds if the authenticated session is still alive
                    self.$checkAuth(undefined, this.checkAuthKO)
                }, 5000);

                this.addListenerOsk();

                this.initAutoLogOffTimer();

                //load default dashboard if exists
                if (this.workFlowSettings && this.workFlowSettings.defaultDashboard && this.$config.isStandardOperationMode()) {
                    if (String.prototype.isString(this.workFlowSettings.defaultDashboard)) {
                        this.$router.push("/dashboards/" + this.workFlowSettings.defaultDashboard + "/show");
                    } else if (this.workFlowSettings.defaultDashboard[this.$grants.getLevel()[0].key]) {
                        this.$router.push("/dashboards/" + this.workFlowSettings.defaultDashboard[this.$grants.getLevel()[0].key] + "/show");
                    }
                }
            } catch(error) {
                console.error("Initialization error: " + error)
            } finally {
                this.startingUp = false;
                if(startTime > 0)
                    console.log(`Application loaded in ${performance.now() - startTime} milliseconds`)
            }
        },
        async reportingServiceStartUp() {

            console.log("Initializing reporting service");
            let webSocketUrl = await this.$reportingService.getWebsocketUrl();
            this.$connect(webSocketUrl);
            this.$options.sockets.onmessage = this.$reportingService.handleExportData;
            this.$options.sockets.onerror = (error) => {
                console.log("Export channel error; error: " + error);
            };
            let self = this;
            let checkToken = () => {
                let timeOut = setTimeout(() => {
                    if (self.$store.state.shared.token && self.$store.state.shared.token !== "") {
                        clearTimeout(timeOut);
                        self.finalizeReportingServicesStartUp();
                    } else {
                        checkToken()
                    }
                }, 200)
            };
            checkToken();
        },
        async finalizeReportingServicesStartUp() {

            await this.loadAppConfig();
            if(!this.$store.state.isEmbedded) {
                await this.loadActiveModules();
                await this.loadOptions();
                await this.$grants.init();
            }
            await this.$license.getInstalledModules();
            this.loadStrings();
            this.loadLanguages();
            this.loadLanguage();
            this.loadVersion();
            this.initializeAvionicsMapping();
            if(this.$config.isAvionics) {
                this.$alarms.loadDescriptors();
                this.$aliases.loadDescriptors();
                await this.$settings.loadLineStopCauses(this.$config.deployment);
                await this.loadIdentificationSettings();
            } else {
                this.$root.configured = true;
                this.$root.startedUp = true;
                //this.$router.push("/home");   //Raise web socket on L2 only for now
            }
            if ( this.$config.deployment === 2 && this.$config.isAvionics) {
                await this.$settings.loadLineSettings();
                await this.$settings.loadTagsSettings();
                if (this.$settings.getTagsSettings() === null) {
                    let tags = { widgets: [], rules: [], forms: [], reports: [] }
                    await this.$settings.saveTagsSettings(tags);
                }
                this.workFlowSettings = await this.$settings.loadWorkFlowSettings();  //TODO L3?
                this.standardTimes = await this.$settings.loadStandardTimesSettings()  //TODO L3?
            }
            Vue.prototype.setPageLoadingCompleted();
            if(this.$config.deployment === 0) {
                this.$root.startedUp = false;
                return;
            }
        },
        initAutoLogOffTimer() {
            if (!this.$store.state.unAuthenticatedLogin && !this.idleTimer && this.workFlowSettings && this.workFlowSettings.autoLogoffSeconds > 0 && this.$config.isStandardOperationMode()) {
                this.idleTimer = new IdleTimer({
                    timeout: this.workFlowSettings.autoLogoffSeconds,
                    onTimeout: () => {
                        this.resetApp();
                    }
                });
            }
        },
        async checkAuthKO (message) {
            await this.stop();
            this.clearSession()
            this.$store.dispatch('clearSession');
            if (!this.$isEmpty(message) && this.$isEmpty(message.data)) {
                this.showErrorNotification('Error occurred: {0}'.format(message));
            } else if (!this.$isEmpty(message)) {
                this.showErrorNotification(message.data.ErrorMessage);
            }
        },
        async addListenerOsk(){
            if(this.oskTimer)
                return;

            let self = this;
            //used to link the v-field-text with the virtual keyboard
            this.oskTimer = setInterval(() => {
                if(self.toggleOsk) {
                    let textFields = document.querySelectorAll('.osk');
                    if(Object.isUseful(textFields))
                        for (let i = 0; i < textFields.length; i++)
                            textFields[i].addEventListener('click', self.showOsk, false);
                    else self.virtualKeyboard.showVirtualKeyboard = false;
                }
            }, 1000);
        },
        showOsk(event){
            if(!this.toggleOsk)
                return;
            this.virtualKeyboard.target = event.target;
            if(!this.virtualKeyboard.target.readOnly) {
                this.virtualKeyboard.target.readOnly = true;
                this.virtualKeyboard.showVirtualKeyboard = true;
            } else
                this.virtualKeyboard.target = null;
        },
        //Connects to the backend web socket to receive notifications
        raiseNotificationChannel() {
            this.$connectWebSocket();
            this.registerWebSocketHooks();
        },
        stopNotificationChannel() {
            // Connect to the websocket target specified in the configuration
            this.$disconnect();
        },
        registerWebSocketHooks() {

            //Dynamic or warning notifications always displayed
            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'uinotification', Function: (data) => {
                    this.handleNotification(data, this.onUINotification)
                }
            });
            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'producingWithoutWorkorder', Function: (data) => {
                    this.handleNotification(data, this.onProducingWithoutWorkorder)
                }
            });
            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'unscheduledProduction', Function: (data) => {
                    this.handleNotification(data, this.onUnscheduledProduction)
                }
            });
            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'notificationChanges', Function: (data) => {
                    this.handleNotification(data, this.onNotificationChanges)
                }
            });

            //Do not connect accessory notifications if we are not granted or on a maximized dash
            if(!this.$grants.hasNotifications() || window.location.hash.match("/dashboards/.*/maximize"))
                return;

            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'endOfWorkorder', Function: (data) => {
                    this.handleNotification(data, this.onEndOfWorkorder)
                }
            });
            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'productionStop', Function: (data) => {
                    this.handleNotification(data, this.onProductionStop)
                }
            });
            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'workflowTransactionComplete', Function: (data) => {
                    this.handleNotification(data, this.onWorkflowTransactionComplete)
                }
            });
            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'systemStatusMessage', Function: (data) => {
                    this.handleNotification(data, this.onSystemMonitorNotification)
                }
            });
            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'diagnosticNotification', Function: (data) => {
                    this.handleNotification(data, this.onDiagnosticNotification)
                }
            });
            this.$store.dispatch('SetWebSocketHook', {
                Scope: 'workorderAvailableForSubline', Function: (data) => {
                    this.handleNotification(data, this.onWorkorderAvailableForSubline)
                }
            });
        },
        handleNotification(data, handle) {
            if(!data) {
                console.log("Empty event received");
                return
            }
            handle(this.$utils.detach(data));
        },
        async onProductionStop(event){
            if (!this.workFlowSettings || this.workFlowSettings.lineStopped && this.$grants.get().notifications.lineStoppage) {
                if(this.annotationDialogOpen)
                    return;
                let alarms = [];
                if(Object.isUseful(event.Message)) {
                    if(Object.isUseful(event.Message.Alarms)) {
                        if (Array.isUseful(event.Message.Alarms.alarmsOther) || Array.isUseful(event.Message.Alarms.alarmsSuitable)) {
                            alarms = await this.$alarms.formatAlarmsGroups(event.Message.Alarms);
                        }
                    }
                }
                if (!this.workFlowSettings || (parseInt(event.Message.RunningTime) > parseInt(this.workFlowSettings.productionTimeThreshold))) {
                    if(this.$license.hasPerformance()) {
                        if(!event.Message.AlreadyCategorizedStop)
                            this.$timeTracking.openTimeTrackingDialog(this, this.$root.userName, true, false, null, alarms, event.Message.StoppedMachines);
                        else {
                            let i=0;
                            if(!alarms[0].suitable)
                                i=1;
                            let stopCause = this.$timeTracking.formatTimeTracker(alarms[i].items[0].stopCause);
                            this.showInfoNotification(this.$gettext("Stop already categorized.<br/> Stopped machine: ") + event.Message.StoppedMachines + this.$gettext("<br/> Stop cause: ") + stopCause, true, true);
                        }
                    }
                    else this.showWarningNotification(this.$gettext("Line stopped producing."), true, true);
                }
            }

        },
        onEndOfWorkorder(){
            debugger
            //if (!this.workFlowSettings || this.workFlowSettings.workorderCompleted && this.$grants.get().notifications.workOrderChange) {
                //this.$workorders.openBackAnnotationDialog(this, this.$root.userName, true)
            //}
        },
        onProducingWithoutWorkorder(){
            this.showWarningNotification(this.$gettext("Line is producing even though no workorder is scheduled. Did you forget to schedule workorder?"), true, true);
        },
        onUnscheduledProduction(){
            this.showWarningNotification(this.$gettext('Line is producing even though that is not scheduled. Check your Performance calculation preferencies in Workflow settings'), true, true);
        },
        onUINotification(event){
            if (event.Message.index === "user_production.data") {
                this.showWarningNotification(this.$gettext('Your production data <<{0}>> is expired').format(event.Message.Name), true, true);
            }
            let notification=event.Message;
            if (this.$grants.hasMatchingProfile(notification.recipients)) {
                if(notification.blocking){
                    this.showDialogBox("",notification.message,"",null,"OK",null,notification.level.toLowerCase(), true)
                }else {
                    this.showNotification(notification.message,true, false, notification.level.toLowerCase())
                }
            }
        },
        onSystemMonitorNotification(event){
            let notification=event.Message;
            if (this.$grants.hasMatchingProfile(notification.recipients)) {
                if(notification.blocking){
                    this.showDialogBox("",notification.message,"",null,"OK",null,notification.level.toLowerCase(), true)
                }else {
                    this.showNotification(notification.message,true, false, notification.level.toLowerCase())
                }
            }
        },

        onDiagnosticNotification(event){
            let message = ""
            let notification= event.Message;

            if (notification.subject === "diskUsagePercentageThreshold") {
                if (notification.message) {
                    notification.message =  this.$gettext("disk usage threshold exceeded for {0}").format(notification.message)
                } else {
                    notification.message = this.$gettext("disk usage threshold exceeded");
                }
            }

            if (this.$grants.hasMatchingProfile(notification.recipients)) {
                if(notification.blocking){
                    this.showDialogBox("",notification.message,"",null,"OK",null,notification.level.toLowerCase(), true)
                }else {
                    this.showNotification(notification.message,true, false, notification.level.toLowerCase())
                }
            }
        },

        onNotificationChanges(event){
            this.notificationInfo = event.Message;
        },

        onWorkflowTransactionComplete(event) {
            if(this.$grants.get().notifications.workOrderChange)
                this.$workflows.handleTransactionComplete(event.Message);
        },
        onWorkorderAvailableForSubline(event){
            this.showDialogBox("",this.$gettext("Workorder {0} available on subline {1}").format(event.Message.Workorder,event.Message.Subline),"",null,"OK",null)
        },
        checkIdentificationSettings(settings) {
            this.$root.configured = this.$settings.checkIdentificationSettings(settings, this.$config.deployment);
            return this.$root.configured;
        },
        setCurrentPageTitle(title) {
            this.$root.currentPageTitle = title;
            let route=this.$route;
            if(!route.meta) {
                route.meta = {
                    breadcrumb: [ { name: title } ]
                }
            } else {
                if (route.meta.breadcrumb)
                    route.meta.breadcrumb[0].name = title;
                else
                    route.meta.breadcrumb = [{name: title}]
            }
            this.updateBreadcrumbs(route)
        },
        /***** MESSAGE BOX *****/
        showDialogBox(title, message, yesLabel, yesFunction, noLabel, noFunction, severity="", timestamped = false) {
            let severityStyle = this.getMessageSeverity(severity);
            this.messageBoxes.push({
                title: title,
                message: message,
                yesLabel: yesLabel,
                yesFunction: yesFunction,
                icon: severityStyle.icon,
                color: severityStyle.color,
                noLabel: noLabel,
                noFunction: noFunction,
                severity: severity,
                timestamp: (timestamped ? new Date() : "")
            })
        },
        /***** NOTIFICATIONS *****/
        clearNotifications(notifications) {
            for(let clearIndex = 0 ; clearIndex < notifications.length ; clearIndex++)
                for(let i = 0 ; i < this.notifications.length ; i++)
                    if(this.notifications[i].message === notifications[clearIndex]) {
                        this.notifications.splice(i, 1);
                    }
        },
        showNotification(message, persist, singleInstance, severity = "", time = 10000) {

            if(this.$utils.isObject(message)) {
                if(message.Message)
                    message = message.Message.format(message.Parameters);
            }

            if(this.$store.state.isEmbedded){
                Vue.prototype.sendNotificationRequest(severity,{Message:message});
                return
            }

            //If notification is single instance, instead of pushing a new one
            //first check if notification is already in queue and eventually push it to front
            if(singleInstance)
                for(let i = 0 ; i < this.notifications.length ; i++)
                    if(this.notifications[i].message === message) {
                        this.notifications.push(this.notifications.splice(i, 1)[0]);
                        return;
                    }

            let severityStyle = this.getMessageSeverity(severity);

            this.$root.notifications.push({
                message: message,
                persist: persist,
                time: persist ? 0 : time,
                icon: severityStyle.icon,
                color: severityStyle.color,
                yesLabel: "OK",
                yesFunction: null,
                noLabel: '',
                noFunction: null,
                timestamp: new Date()
            });
        },
        getMessageSeverity(severity) {
            let returning = {
                icon: "fa-info",
                color: this.$avStyle.colors.blue
            };
            switch (severity) {
                case "warning":
                    returning.icon="fas fa-exclamation";
                    returning.color = this.$avStyle.colors.orange;
                    break;
                case "error":
                    returning.icon="fa-exclamation-triangle";
                    returning.color = this.$avStyle.colors.red;
                    break;
            }
            return returning;
        },
        showErrorNotification(message, persist, singleInstance) {
            this.showNotification(message, persist, singleInstance, "error")
        },
        showWarningNotification(message, persist, singleInstance) {
            this.showNotification(message, persist, singleInstance, "warning")
        },
        showInfoNotification(message, persist, singleInstance) {
            this.showNotification(message, persist, singleInstance, "info")
        },
        setLoading(loading, message) {
            this.loading = loading;
            this.loadingMessage = message;
            this.loadingMinTime = 1000;
        },
        stop() {
            return new Promise(resolve => {
                if(this.idleTimer)
                    this.idleTimer.cleanUp();
                clearInterval(this.checkOutTimer);
                this.checkOutTimer = null;
                clearInterval(this.oskTimer);
                this.oskTimer = null;
                this.stopNotificationChannel();
                this.$router.push({name:'login'});
                this.startedUp = false;
                resolve()
            })
        },
        clearSession() {
            this.$store.dispatch('setSession', {
                token: '',
                userName: ''
            });
            this.$grants.clear();
        },
        async onAppLoadingError() {
            this.showErrorNotification(this.$gettext("Application error please retry login"));
            await this.stop()
            this.clearSession()
        },
        async loadAppConfig() {
            let self = this;
            let promise = await this.$config.load()
                .catch(err => {
                    self.onAppLoadingError();
                });
            await promise;
        },
        //TODO move to APIS
        async loadActiveModules () {
            await this.OrchestratorAPI.loadActiveModules()
                .then(t => {
                    if (t.data.Status === 'success') {
                        let data = JSON.parse(t.data.Data);
                        this.$store.dispatch('saveModules', data);
                    } else {
                        this.showErrorNotification(t.data.ErrorMessage, true, true);
                    }
                }).catch(t => {
                    this.showErrorNotification('Error occurred: {0}'.format(t), true, true);
                })
        },
        async loadOptions () {
            await this.OrchestratorAPI.options().then(t => {
                if (t.data.Status === 'success') {
                    let data = JSON.parse(t.data.Data);
                    this.$store.dispatch('setOptions', data);
                } else {
                    this.$notifyError(t.data.ErrorMessage, [{ label: 'Close', color: 'white', handler: () => { } }])
                }
            }).catch(t => {
                this.$notifyError({ Message: 'Error occurred: {0}', Parameters: [t] }, [{ label: 'Close', color: 'white', handler: () => { } }])
            })
        },
        //TODO verify if could be moved to some dedicated module or loaded dynamically
        initializeAvionicsMapping() {
            let self = this;
            this.$datalayer.setDefaultMappingDescriptor( {
                ignored: ["user*", "*configuration*", "*prototype*", "*history*"],
                rename: [
                    {
                        source: "alarms",
                        dest: "Alarms (raw events)"
                    },
                    {
                        source: "production counters@5s",
                        dest: "Main production index (5 seconds)"
                    },
                    {
                        source: "data_tracking*",
                        dest: "Data tracking"
                    },
                    {
                        source: "data_data.entry.records",
                        dest: "Data entry records"
                    },
                    {
                        source: "audit_trails",
                        dest: "Events"
                    }
                ],
                fieldsRemoval: [
                    {
                        match: ["data_tracking*"],
                        recursive: true,
                        func: (item, items, index) => {
                            //TODO this is temp for rules generated artifacts in TMW
                            return ((item.name === "conditions" || item.name === "inputs") && item.isRoot);
                        }
                    },
                    {
                        //DO it twice due to remove-while-loop artifacts
                        match: ["data_tracking*"],
                        recursive: true,
                        func: (item, items, index) => {
                            //TODO this is temp for rules generated artifacts in TMW
                            return ((item.name === "conditions" || item.name === "inputs") && item.isRoot);
                        }
                    },
                    {
                        match: ["Maintenance", "Assets"],
                        recursive: true,
                        func: (item, items, index) => {
                            return (item.name.indexOf("SystemEntity") > -1 || item.name.indexOf("SystemAsset") > -1 || item.name === "tag");
                        }
                    },
                    {
                        match: ["audit_trails"],
                        recursive: true,
                        func: (item, items, index) => {
                            let rawName = item.name.replace(".keyword", "");
                            return (rawName.length <= 2 && !isNaN(Number(rawName)));
                        }
                    }
                ],
                fieldsRemapping: [
                    {
                        match: ["alarms", "production counters@5s"],
                        recursive: true,
                        func: (item) => {
                            //Explicitly jump variable to avoid improper matching with configured alarms
                            if(item.name === "AlarmsCount" || item.isRoot)
                                return;
                            let alarmName = self.$alarms.formatAlarmName(item.name, item.root.split(".")[0]);
                            if(alarmName) {
                                item["additionalInfo"] = alarmName;
                            }
                        }
                    },
                    {
                        //push all non nested variables to the top including root nodes that are managed as single variables
                        match: ["*"],
                        recursive: true,
                        func: (item, items, index) => {
                            let wellKnown = self.$defines.getWellKnownItem(item);
                            if(wellKnown) {
                                if(wellKnown.fullNodeComparable || wellKnown.fullNodeQuerable) {
                                    items.moveItem(index, 0);
                                }
                            }
                            if(!item.isRoot) {
                                items.moveItem(index, 0);
                            }
                        }
                    },
                    {
                        //Timestamp explosion variables appears sparse in mapping, that's not very well looking,
                        //perform another loop to push all timestamp vars to the top
                        match: ["*"],
                        recursive: false,
                        func: (item, items, index) => {
                            if(self.$defines.isWellKnownItem(item, self.$defines.WellKnownItems.MinuteOfDay) || self.$defines.isWellKnownItem(item, self.$defines.WellKnownItems.DayOfWeek) ||
                                self.$defines.isWellKnownItem(item, self.$defines.WellKnownItems.DayOfYear) || self.$defines.isWellKnownItem(item, self.$defines.WellKnownItems.Month) ||
                                self.$defines.isWellKnownItem(item, self.$defines.WellKnownItems.WeekOfYear))
                                items.moveItem(index, 0);
                        }
                    },
                    {
                        match: ["*"],
                        recursive: false,
                        func: (item, items, index) => {
                            if(self.$defines.isWellKnownItem(item, self.$defines.WellKnownItems.Position))
                                item.type = "geo_point";
                        }
                    },
                    {
                        match: ["*"],
                        recursive: true,
                        func: async (item) => {
                                let alias = ""; // used for machine name
                                let aliasVariable = ""; // used for variable name
                                let aliasRemapped = async function(name) {
                                    let tokens = name.split(".");
                                    return await self.$aliases.getMachineAlias(tokens[0]);
                                };
                                if(item.isRoot) {
                                    alias = await aliasRemapped(item.name);
                                    if(alias)
                                        item["displayName"] = alias;
                                } else {
                                    alias = await aliasRemapped(item.root);
                                    if(alias)
                                        item["displayRoot"] = alias;

                                    aliasVariable = await self.$aliases.getVariableAlias(item.root, item.name);
                                    if(aliasVariable)
                                        item["displayName"] = aliasVariable;
                                }
                        }
                    },
                    {
                        match: ["Maintenance", "Assets"],
                        recursive: true,
                        func: (item) => {
                            if (item.name === "Entity" && item.isRoot) {
                                item.displayName = "Asset";
                            }
                            if (item.name === "Transaction" && item.isRoot) {
                                item.displayName = "Maintenance intervention"
                            }
                            if (item.name.indexOf("Entity") > -1) {
                                item.displayName = item.name.replace("Entity", "Asset")
                            }
                            if (item.type === "keyword" && item.displayName && item.displayName.indexOf("keyword") > -1) {
                                item.displayName = item.displayName.replace(".keyword", "")
                            }
                        },
                    },
                    {
                        match: ["audit_trails"],
                        recursive: true,
                        func: (item) => {
                            if (item.name.indexOf("Action") > -1) {
                                item.displayName = "EventType";
                            }
                            if (item.name.indexOf("ActionId") > -1) {
                                item.displayName = "Subject"
                            }
                            if (item.name.indexOf("PreviousVal") > -1) {
                                item.displayName = "Value before event"
                            }
                            if (item.name.indexOf("NextVal") > -1) {
                                item.displayName = "Value after event"
                            }
                        }
                    }
                ],
                groups:[
                    {
                        display: "Main data sources",
                        match: [
                            "*production counters*",
                            "*Workorders statistics",
                            "data_data.entry.records",
                            "*alarms",
                            "Maintenance",
                            "Assets",
                            "audit_trails"
                        ]
                    },
                    {
                        display: "Raw machine data",
                        match: [
                            "avopcua*",
                            "avmodbus*"
                        ]
                    },
                    {
                        display: "Tracking",
                        match: [
                            "data_tracking*",
                        ]
                    },
                    {
                        display: "Other data sources",
                        match: [
                            "*"
                        ]
                    }
                ]
            });
        },
        changeLanguage(lang, oldLang) {
            this.$settings.save({current: lang}, this.$settings.Languages, this.$settings.Current).then(() => {
                this.$store.dispatch('selectedLanguage', (lang=== 'default' ? 'en_GB' : (lang + '_' + lang.toUpperCase())))
                this.$audits.save(this.$root.userName, this.$audits.items().languageChanged, this.$language.available[oldLang], this.$language.available[lang] , '')
                    .catch(err => {
                        debugger
                        self.$root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                    });
            });
        },
        loadStrings() {
            this.$settings.load(this.$settings.Translations)
                .then(translations => {
                    if(translations) {
                        //Override defaults with custom translations
                        for(let lang in translations) {
                            if (!Vue.$translations[lang])
                                Vue.$translations[lang] = {};
                            for (let key in translations[lang])
                                Vue.$translations[lang][key] = translations[lang][key];
                        }
                    }
                })
                .catch(() => {
                    debugger
                })
        },
        loadLanguages() {
            let self = this;
            this.$settings.load(this.$settings.Languages, this.$settings.Available)
                .then(languages => {
                    if(languages) {
                        //Merge custom and default languages
                        for(let langCode in languages) {
                            if(!self.$language.available[langCode])
                                self.$language.available[langCode] = languages[langCode];
                        }
                    }
                    else self.$settings.save(self.$language.available, self.$settings.Languages, self.$settings.Available)
                        .catch(() => {
                            debugger
                        })
                })
                .catch(() => {
                    debugger
                })
        },
        loadLanguage() {
            let self = this;
            this.$settings.load(this.$settings.Languages, this.$settings.Current)
                .then(language => {
                    if(language.current)
                        self.$language.current = language.current;
                    else
                        self.$language.current = 'default';
                    self.$vuetify.lang.current = self.$language.current !== 'default' ? self.$language.current : 'en'
                })
                .catch(() => {
                    self.$language.current = 'default';
                    self.$vuetify.lang.current = 'en'
                })
        },
        async loadIdentificationSettings() {
            try {
                let settings = await this.$settings.loadIdentificationSettings();
                if(this.$config.isReportingService() || this.$config.isTransactionMode())
                    return;

                if (Object.isUseful(settings)) {
                    //Check whether line is configured or not, in case lock interface on settings page
                    if(this.checkIdentificationSettings(settings)) {
                        if(!window.location.hash.match("/dashboards/.*/maximize") && this.$config.isStandardOperationMode())
                            this.$router.push("/home");
                        this.raiseNotificationChannel();    //Raise web socket
                        this.identification = settings;
                    } else {
                        this.$root.configured = false;
                        this.showErrorNotification(this.$gettext("Wrong or missing identification settings. {appName} is not correctly configured").format({appName: this.$config.appName}), true);
                        this.$router.push("/settings");
                    }
                } else {
                    this.$root.configured = false;
                    this.$root.showErrorNotification(this.$gettext("Error in retrieving settings from DB. {appName} is not correctly configured").format({appName: this.$config.appName}), true);
                }
            }
            finally {
                this.$root.startedUp = true;
            }
        },
        openDashboard(name,gParams,showHistory){
            if (showHistory){
                this.$store.commit('setHaveHistory',true)
            }
            this.$router.push({path:`/dashboards/${name}/show`,query:{globalParams:gParams}});
        },
        async loadVersion() {
            let self = this;
            return new Promise((resolve) => {
                OrchestratorAPI.applicationVersionDetails()
                    .then((version) => {
                        version = JSON.parse(version.data.Data);
                        let appVersion = version.Major + "." + version.Minor + "." + version.Patch
                        console.log("Frontend version: {0} {1}.{2}.{3} build: {4}".format(self.$version.VERSION, self.$version.VERSION_MAJOR, self.$version.VERSION_MINOR, self.$version.VERSION_PATCH, self.$version.VERSION_TAG));
                        console.log("Backend version: {0}.{1}.{2} build: {3}".format(version.Major, version.Minor, version.Patch, version.Build));
                        if (!(version.Major === self.$version.VERSION_MAJOR && version.Minor === self.$version.VERSION_MINOR &&
                            version.Patch === self.$version.VERSION_PATCH && version.Build === self.$version.VERSION_TAG ||
                            version.Build === "0" || self.$version.VERSION === 'Avionics dev')) {
                            console.log("Version mismatch");
                            if(!this.$config.isReportingService()) {
                                let appBuild = version.Build.split("_")[0];
                                let feBuild = self.$version.VERSION_TAG.split("_")[0];
                                this.$router.push({path: '/versionMismatchPage',
                                    query: {
                                        frontEndVersion: this.$version.VERSION + "." + feBuild,
                                        appVersion: appVersion + "." + appBuild
                                    }
                                });
                                Vue.prototype.$store.state.shared.token = ""; //Release token to let next refresh restart from login
                                resolve(false);
                            } else resolve(true); //In reporting service this shouldn't happen and anyway no one can hit ctrl+f5
                            return
                        }
                        resolve(true);
                    })
            })
        },
        async resetApp() {
            let self = this;
            await self.stop()
            await AuthApi.logout()
            self.clearSession()
        },
    },
    computed:{
        userName() {
            return this.$store.state.shared.loggedUserName
        },
        isMobile() {
            return this.$store.state.isEmbedded ? this.$vuetify.breakpoint.smAndDown : this.$vuetify.breakpoint.mdAndDown;
        }
    }
})
