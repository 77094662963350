<template>
    <RuleBase />
</template>

<script>

import RuleBase from '@/components/dynamic-elements/rules/RuleBase.vue';

export default {
    name: "RuleActivity",
    extends: RuleBase,
    components: {
        RuleBase
    },
    data() {
        return {
            activityType: null,
            activityTypes: {
                timeBased: 0,
                counterBased: 1,
                machineBased: 2
            }
        }
    },
    computed: {
    },
    mounted() {
        this.childHandlers.onDataItemsChanged = this.onDataItemsChanged;
        this.dataExplorationMode.requiresDataFiltering = false;
        this.dataExplorationMode.requiresCrossAggregation = false;
        this.dataExplorationMode.requiresFunctions = false;
        this.dataExplorationMode.requiresDataSelection = false;
        this.dataExplorationMode.requiresDataExploration = false;
        this.dataExplorationMode.ruleType = 'activity';
        this.dataExplorationMode.rulesExecutionSchedulingMode.requiresTimeSchedulingRestrictions = true;
        this.dataExplorationMode.rulesExecutionSchedulingMode.requiresActivityDuration = true;
        this.properties.ruleScheduling.cron = "*/1 * * * *"; //Check every minute by default
        this.properties.isActivity = true;

        this.visualizationTweaks = [
            {
                name: this.$gettext("Activity rule types"),
                id: "ActivityType",
                type: "indexed_option",
                options: [this.$gettext("Time based activity"), this.$gettext("Counter based activity")/*, this.$gettext("Machine runtime based activity")*/],
                default: function() {
                    return 0;
                }
            }];
    },
    watch: {
        visualizationTweaks: {
            handler: function () {
                let previousActivityType = this.activityType;
                let resetScheduling = !(this.properties.activityType === this.getTweakValue("ActivityType"))
                this.activityType = this.getTweakValue("ActivityType");
                this.properties.activityType=this.getTweakValue("ActivityType");
                if(previousActivityType !== null) {
                    this.dataItems.clear();
                    this.filterItems.clear();
                    this.aggregationItems.clear();
                    this.rules.clear();
                }

                // To be implemented with simplified view
                if(this.activityType !== previousActivityType)
                    this.dataRepresentationConfiguratorChange(resetScheduling);

                this.saveTweaks();
            },
            deep: true,
        },
        'dataExplorationMode.simplifiedView'() {
            if (this.dataExplorationMode.simplifiedView) {
                this.setDefaultsForActivityType();
            } else {
                this.dataExplorationMode.filterVariables = "";
                this.dataExplorationMode.requiresDataFiltering = true;
                this.dataExplorationMode.requiresDataSelection = true;
                this.dataExplorationMode.requiresRulesConditions = true;
            }
        },
    },
    methods: {
        setDefaultScheduling() {
            this.dataExplorationMode.rulesExecutionSchedulingMode.required = true;
            this.dataExplorationMode.rulesExecutionSchedulingMode.fixedAbsoluteQueryWindow = false;
            this.dataExplorationMode.rulesExecutionSchedulingMode.requiresFixedAbsoluteQueryWindow = true;
            this.dataExplorationMode.rulesExecutionSchedulingMode.periodicOnlyMode = false;
            this.dataExplorationMode.rulesExecutionSchedulingMode.fixedPeriod = false;
            this.dataExplorationMode.rulesExecutionSchedulingMode.requiresTimeSchedulingRestrictions = true;
            this.dataExplorationMode.rulesExecutionSchedulingMode.requiresActivityDuration = true;
            this.properties.ruleScheduling.queryFromLastUsefulRuleResult = false;
            this.properties.ruleScheduling.cron = "*/1 * * * *"; //Check every minute by default
            this.properties.ruleScheduling.executionSchedulingType = "evaluatePeriodicallyModel";
            this.properties.ruleScheduling.oneShoot = false;
        },
        dataRepresentationConfiguratorChange(resetScheduling) {
            this.dataExplorationMode.simplifiedView = true;
            this.setDefaultsForActivityType(resetScheduling);
            this.$emit('dataItemsUpdated');
        },
        setDefaultsForActivityType(resetScheduling) {
            if (resetScheduling) {
                this.setDefaultScheduling();
            }
            this.dataExplorationMode.requiresDataFiltering = false;
            this.dataExplorationMode.requiresCrossAggregation = false;
            this.dataExplorationMode.requiresFunctions = false;
            this.dataExplorationMode.requiresDataSelection = false;
            this.dataExplorationMode.requiresDataExploration = false;
            this.dataExplorationMode.noPreview = true;
            //Time based activity
            if(this.activityType === 0) {
                this.dataExplorationMode.filterVariables = "";
                this.dataExplorationMode.requiresRulesConditions = false;
                this.dataExplorationMode.rulesExecutionSchedulingMode.requiresFixedAbsoluteQueryWindow = false;
                this.dataExplorationMode.rulesExecutionSchedulingMode.fixedPeriod = false;
            }
            //Counter based activity
            else if(this.activityType === 1) {
                this.dataExplorationMode.filterVariables = "_Delta";
                this.dataExplorationMode.requiresDataExploration = true;
                this.dataExplorationMode.requiresRulesConditions = true;
                this.dataExplorationMode.rulesExecutionSchedulingMode.requiresFixedAbsoluteQueryWindow = true;
                this.dataExplorationMode.rulesExecutionSchedulingMode.fixedAbsoluteQueryWindow = true;
                this.dataExplorationMode.rulesExecutionSchedulingMode.periodicOnlyMode = true;
                this.dataExplorationMode.rulesExecutionSchedulingMode.fixedPeriod = true;
                this.properties.ruleScheduling.queryFromLastUsefulRuleResult = true;
            }
            //Machine runtime based rule
            else if(this.activityType === 2) {
                //  To be implemented later
                this.dataExplorationMode.filterVariables = "";
                this.dataExplorationMode.rulesExecutionSchedulingMode.requiresFixedAbsoluteQueryWindow = true;
                this.dataExplorationMode.rulesExecutionSchedulingMode.fixedAbsoluteQueryWindow = true;
                this.dataExplorationMode.rulesExecutionSchedulingMode.periodicOnlyMode = true;
                this.properties.ruleScheduling.queryFromLastUsefulRuleResult = true;
                this.dataExplorationMode.rulesExecutionSchedulingMode.fixedPeriod = true;
            }
        },
        onDataItemsChanged() {
            if (this.activityType === 1) {
                this.dataItems.forEach(dataItem => {
                    if (!Array.isUseful(dataItem.representations)) {
                        let representation = this.$datalayer.getDefaultDataRepresentation(dataItem);
                        representation.type = this.$defines.allAggregations.sum.id;
                        representation.name = dataItem.name;
                        dataItem.representations.push(representation);
                    }
                })
            }
            // this.$emit('dataItemsUpdated');
        },
    }
}

</script>

<style scoped>
table th { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
table td { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
table tr { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
</style>
