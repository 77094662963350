/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/
import Vue from 'vue'


import App from '@/App.vue'


import createStore from '@/store/index'

import createRouter from '@/router/index'





export default async function (data,methods,computed) {
  // create store and router instances
  
  const store = typeof createStore === 'function'
    ? await createStore({Vue})
    : createStore

  
  // make router instance available in store
  const router = typeof createRouter === 'function'
      ? await createRouter({Vue, store})
      : createRouter
  

  // Create the app instantiation Object.
  // Here we inject the router, store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    router,
    store,
    data:()=>{return data},
    methods:methods,
    computed:computed,
    render: h => h(App)
  }


  
  app.el = '#app'
  

  // expose the app, the router and the store.
  // note we are not mounting the app here, since bootstrapping will be
  // different depending on whether we are in a browser or on the server.
  return {
    app,
    store,
    router
  }
}
