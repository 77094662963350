<template>
    <v-dialog v-model="showDialog" width="720px" height="350px" :fullscreen="$vuetify.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <v-card id="antares-annotation-dialog-card">
            <v-toolbar card dense>
                <v-toolbar-title class="headline text-xs-center" v-translate>
                    Alarm search pattern configuration
                </v-toolbar-title>
            </v-toolbar>
            <v-layout column pa-3>
                <v-spacer />
                <v-spacer />
<!--                <v-select v-if="Array.isUseful(machines)" solo max-width="600" :placeholder="lbStoppedMachine" class="title" :items="machines" item-text="show" item-value="id" v-model="stoppedMachine" />-->
                <label class="title font-weight-regular">{{$gettext("Alarms are received from a machine with a numerical identifier; in order to match them with description and settings, {appName} must know where to find the numerical identifier in the configured alarm ID. Configure which digits in the alarms ID represent the numerical identifier.").format({appName: $config.appName})}}</label>
                <v-spacer />
                <v-switch :value="isFullSearchPattern" label="Match alarms ids literally" style="padding-top: 20px" class="switchLabel" @change="setFullSearchPatternMode($event)" />
                <template v-if="!isFullSearchPattern">
                    <label style="padding-top: 15px" class="title font-weight-regular"><translate>Current search pattern</translate>: {{displayAlarmSearchPattern}}</label>
                    <v-spacer />
                    <v-layout style="padding-top: 15px" row wrap>
    <!--                    <div v-for="(element, index) in alarmIdElements" :key="index">-->
                            <v-btn-toggle
                                    rounded
                                    v-model="toggle_multiple"
                                    multiple
                            >
                                <div >
                            <v-btn v-for="(element, index) in alarmIdElements" :key="index" :ref="buttonPatternId(index)" @click="clicked(element,index)" pa-0 ma-0 active-class='' :class="alarmSearchPatternIndices.includes(index) ? 'selected' : 'unselected'">{{element}}</v-btn>
                                </div>
                            </v-btn-toggle>
                    </v-layout>
                </template>
                <v-spacer />
                <label v-if="error" style="color: var(--av-red); font-size: 1.4em">{{error}}</label>
                <v-spacer/>
                <v-card-actions id="antares-annotation-dialog-card-actions">
                    <v-spacer></v-spacer>
                    <v-btn v-if="enableOkButton" id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="callBack(true)" v-translate>
                        OK
                    </v-btn>
                    <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat" @click="callBack(false)"  v-translate>
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "AlarmSearchPatternDialog",
        props: {

        },
        data () {
            return {
                showDialog: false,
                user: "",
                callerInstance: null,
                alarmCallBack: null,
                checkSearchPatternCallBack: null,
                alarmSearchPattern: "",
                alarmSearchPatternIndices : [],
                alarmIdentifier: "",
                alarmIdElements : [],
                toggle_multiple: [],
                error: "",
                enableOkButton: true,
            }
        },
        beforeMount() {
            //array for buttons group used for selecting searchPattern
            this.alarmIdElements.forEach((item, index) => this.toggle_multiple.push(index));
        },
        computed: {
            isFullSearchPattern() {
                return this.alarmSearchPattern === "*"
            },
            alarmIdentifierText() {
              return this.$gettext("Alarm: ") + this.alarmIdentifier;
            },
            displayAlarmSearchPattern() {
                this.newSearchPattern();
                let text ='';
                for (let i = 0; i < this.alarmIdentifier.length; i++){
                    // text += this.alarmSearchPatternIndices.includes(i) ? '#' : this.alarmIdentifier[i];
                    if (this.alarmSearchPatternIndices.includes(i)) {
                        text += '#';
                        if (isNaN(this.alarmIdentifier[i])) {
                            this.error = this.$gettext("Please select a number.");
                            this.alarmSearchPatternIndices.removeItem(i);
                        }
                    } else {
                        text += this.alarmIdentifier[i];
                    }
                }

                let result;
                if (this.checkSearchPatternCallBack) {
                    result = this.checkSearchPatternCallBack(this.alarmSearchPattern);
                }

                if (result)
                    this.error += this.$gettext("The configured alarm search pattern doesn't match one or more alarms Ids: ") + result;

                return text;
            }
        },
        methods: {
            newSearchPattern() {
                let searchPattern = '';
                let placeholder = '{0}';
                let lowerBound = Math.min( ...this.alarmSearchPatternIndices );
                let upperBound = Math.max(...this.alarmSearchPatternIndices);
                let added = false;
                this.alarmIdElements.forEach((element, index) => {
                    if (index < lowerBound || index > upperBound) {
                        searchPattern += element;
                    }
                    else if(!added) {
                        added = true;
                        searchPattern += placeholder;
                    }
                });
                this.alarmSearchPattern = searchPattern;
            },
            //Create dinamically the button's id.
            buttonPatternId (index) {
                return "patternButtonId" + index;
            },
            setFullSearchPatternMode(mode) {
                if(mode)
                    this.alarmSearchPattern = "*";
                else this.newSearchPattern();
             },
            clicked(element, index) {
                this.error ="";
                if(isNaN(element)) {
                    this.error = this.$gettext("Please select a number.");
                    this.alarmSearchPatternIndices.removeItem(index);
                }
                else {
                    this.alarmIdElements.forEach((element, index) => {
                        if(isNaN(element)) {
                            this.alarmSearchPatternIndices.removeItem(index);
                        }
                    });
                    //It contains the selected index for the item that compose the search pattern
                    if (this.alarmSearchPatternIndices.includes(index)) {
                        this.alarmSearchPatternIndices.removeItem(index);
                    }
                    else {
                        this.alarmSearchPatternIndices.push(index);
                    }

                    if(!this.checkConsecutiveSearchPatternElements()) {
                        this.error = this.$gettext("Please select consecutive numbers.");
                        this.enableOkButton = false;
                        // this.alarmSearchPatternIndices.removeItem(index);
                    } else
                        this.enableOkButton = true;
                    }
                this.newSearchPattern();
            },
            splitAlarmIdentifier() {
               this.alarmIdElements = this.alarmIdentifier.split('');
                let max = Math.max(...this.alarmSearchPatternIndices);
                if (max > this.alarmIdElements.length)
                    this.alarmSearchPatternIndices.removeItem(max);
            },
            setCaller(caller) {
                this.callerInstance = caller;
            },
            Show(user, alarmSearchPattern, alarmSearchPatternIndices, alarmIdentifier, alarmCallBack, checkSearchPatternCallBack) {
                this.showDialog = true;
                this.user = user;
                this.alarmCallBack = alarmCallBack;
                this.alarmSearchPattern = alarmSearchPattern;
                this.alarmSearchPatternIndices = [...alarmSearchPatternIndices];
                this.alarmIdentifier = alarmIdentifier.toString();
                // this.$set(this.alarmIdentifier, alarmIdentifier)
                this.checkSearchPatternCallBack = checkSearchPatternCallBack;
                this.splitAlarmIdentifier();
            },
            setDialog(result) {
                this.showDialog = false;
                if(this.callerInstance)
                    this.callerInstance.annotationDialogOpen = false;
                // if(!result && this.pickerCallback)
                //     this.pickerCallback(0); // category 0 is not a valid manual time tracker
            },
            //check if the elements selected are consecutive in the AlarmIdentifier
            checkConsecutiveSearchPatternElements() {
                if (this.alarmSearchPatternIndices.length < 1)
                    return false;
                let min = Math.min( ...this.alarmSearchPatternIndices );
                let max = Math.max(...this.alarmSearchPatternIndices);

                //check all elements
                if (max - min + 1 === this.alarmSearchPatternIndices.length) {
                    let visited = new Array(this.alarmSearchPatternIndices.length).fill(false);

                    for (let i =0; i < this.alarmSearchPatternIndices.length, i++;) {

                        if (visited[this.alarmSearchPatternIndices[i] - min] !== false)
                            return  false;

                        visited[this.alarmSearchPatternIndices[i] - min] = true;
                    }

                    return true;
                }

                return false; // indices are not consecutive
            },
            callBack(result) {
                this.error ="";
                if (result) {
                    this.showDialog = false;
                    if(this.callerInstance)
                        this.callerInstance.annotationDialogOpen = false;
                    if(this.alarmCallBack)
                        this.alarmCallBack({alarmSearchPattern: this.alarmSearchPattern, alarmSearchPatternIndices: this.alarmSearchPatternIndices });                }
                else {
                    this.showDialog = false;
                    if(this.callerInstance)
                        this.callerInstance.annotationDialogOpen = false;
                }
            },
        }
    }
</script>

<style scoped>
.selected {
    background-color: var(--av-blue)!important;
    color: white!important;
    opacity: 1!important;
}
.unselected {
    background-color: #cccccc!important;
    opacity: 1!important;
}

.switchLabel >>> label {
    /*From vuetify title to be applied to v-switch label*/
    font-size: 20px !important;
    font-weight: 500;
    line-height: 1 !important;
    letter-spacing: 0.02em !important;
    font-family: 'Roboto', sans-serif !important;
}
</style>