<template>
  <DynamicElementBase>
    <RawDataTable class="ma-0 pa-0" :dataCollection="dataCollection" :containerHeight="containerHeight"/>
  </DynamicElementBase>
</template>

<script>
import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
import RawDataTable from '@/components/graphics/RawDataView.vue';

export default {
  name: "ReusableQuery",
  extends: DynamicElementBase,
  components: {
    DynamicElementBase,
    RawDataTable
  },
  data() {
    return {
      dataCollection: null,
    }
  },
  methods: {
    refreshData(dataValues) {
      this.dataCollection = dataValues;
    },
  }
}

</script>