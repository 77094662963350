<template>
    <v-card-text class="av-card-content" style="height: calc(100vh - 80px)">
        <v-flex style="max-width:640px">
            <table id="entity" class="table dt-body-nowrap">
                <!--Not standard fields-->
                <!-- AN scan first for media to show to move up in the table... not very efficient, to improve --->
                <tr v-for="(item, key, index) in details" :key="key">
                    <template v-if="isMediaFile(key)">
                        <!-- AN at the moment we're not handling array of files even if isMediaFile detect it -->
                        <td class="text-xs-left" style="min-width: 170px">{{ key }}</td>
                        <td class="text-xs-left pa-0 ma-0">
                            <pre><av-file-viewer  :type="item.Type" :data="item.Value" :file-name="fileName(item, key)" :mediaId="item.MediaId"  :preloadmedia="false" index="workorders_additional_documents"/> </pre>
                        </td>
                    </template>
                </tr>
                <tr v-for="field in standardFields">
                    <template>
                        <td class="text-xs-left" style="min-width: 170px">{{ field.text }}</td>
                        <td class="text-xs-left">
                            <pre>{{ details[field.value] }}</pre>
                        </td>
                    </template>
                </tr>
                <tr v-for="(item, key, index) in details" :key="key">
                    <template v-if="!isStandardField(key) && !isNoShowField(key) && !isMediaFile(key)">
                        <td class="text-xs-left" style="min-width: 170px">{{ key }}</td>
                        <td class="text-xs-left">
                            <pre>{{ parseField(item) }}</pre>
                        </td>
                    </template>
                </tr>
            </table>
        </v-flex>
        <v-spacer></v-spacer>
    </v-card-text>
</template>

<script>
import AvFileViewer from "@/components/av-components/av-file-viewer"
export default {
    name: "workorderDetail",
    components:{ AvFileViewer},
    props: {
        details: {
            required: true,
            default: () => {
                return []
            }
        }
    },
    data: () => {
        return {
            customFields: [],
            noShowFields: ["type", "tag", "BillOfMaterials", "Transitions", "Transform", "flows", "actionsGroup", "scheduleInfo"],
            standardFields: [],
        }
    },
    created() {
        this.parseStandardFields();
    },
    methods: {
        fileName(file,key) {
            return file.FileName ? file.FileName : this.details.Id + '_' + key  + '.' + file.Type.split('/')[1]
        },
        isMediaFile(key) {
            let field = this.details[key]
            if(!field)
                return false
            //array of documents
            if (Array.isUseful(field)) {
                field = field[0]
            } else if (Array.isArray(field)) {
                return false
            }
            return (field.hasOwnProperty('FileName') && field.hasOwnProperty('Type')) && (field.hasOwnProperty('MediaId') || field.hasOwnProperty('Value'));
        },
        parseStandardFields() {
            this.standardFields = [
                {
                    text: this.$gettext("Id"),
                    class: "subheading",
                    sortable: false,
                    value: "Id"
                },
                {
                    text: this.$gettext("PhysicalLineId"),
                    class: "subheading",
                    sortable: false,
                    value: "PhysicalLineId"
                },
                {
                    text: this.$gettext("RecipeId"),
                    class: "subheading",
                    sortable: false,
                    value: "RecipeId"
                },
                {
                    text: this.$gettext("Quantity To Produce"),
                    class: "subheading",
                    sortable: false,
                    value: 'QuantityToProduce'
                },
                {
                    text: this.$gettext("Material"),
                    class: "subheading",
                    sortable: false,
                    value: 'Material'
                },
                {
                    text: this.$gettext("Status"),
                    class: "subheading",
                    sortable: false,
                    value: 'StatusString',
                }
            ]
        },
        isStandardField(property) {
            return this.standardFields.some(obj => obj.value === property)
        },
        isNoShowField(property) {
            return this.noShowFields.some(obj => obj === property)
        },
        parseField(value) {
            try {
                if (this.$utils.isObject(value))
                    return JSON.stringify(value, null, 4);
            } catch (err) {
            }
            return value
        },
    }
}
</script>

<style scoped>

.table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
}

.table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table tr:hover {
    background-color: #ddd;
}

.dt-body-nowrap {
    white-space: nowrap;
}
</style>
