
export default ({ app, router, store, Vue }) => {

  Vue.prototype.$serverPort = function () {
    let port = window.location.port
    if (process.env.NODE_ENV === 'development') {
      port = '9090'
      if(Vue.prototype.$store && Vue.prototype.$store.state && Vue.prototype.$store.state.debugPort)
        port = Vue.prototype.$store.state.debugPort
    }
    return port
  }
  Vue.prototype.$getBaseURL = function () {
    let port = Vue.prototype.$serverPort()
    let baseURL = `//` + window.location.hostname
    if(Vue.prototype.$store && Vue.prototype.$store.state && Vue.prototype.$store.state.debugUrl)
      baseURL = `//` + Vue.prototype.$store.state.debugUrl
    baseURL += `:` + port
    if (baseURL.endsWith(':')) {
      baseURL = baseURL.substr(0, baseURL.length - 1)
    }

    try {
      if (Vue.prototype.$store !== undefined && Vue.prototype.$store.state !== undefined && Vue.prototype.$store.state.commonStore !== undefined && Vue.prototype.$store.state.commonStore.baseURL !== undefined && Vue.prototype.$store.state.commonStore.baseURL !== ''){
        //Use basedURL received in query string
        baseURL = Vue.prototype.$store.state.commonStore.baseURL
      } else {
        //If basedURL is empty on store check if the query string has it. If so use it otherwise use the default
        const urlParams = new URLSearchParams(window.location.hash);
        if (urlParams.get("u") !== undefined && urlParams.get("u") !== null && urlParams.get("u") !== '') {
          baseURL = Vue.prototype.$decode(urlParams.get("u"))
          Vue.prototype.$store.dispatch('setBaseURL', baseURL)
        } else {
          console.log('no baseURL set. Using default ' + baseURL)
        }
      }
    }catch (e) {
      console.log(e)
    }

    return baseURL
  }
}
