import Vue from 'vue'

export default ({ app, router, store, Vue }) => {
    Vue.prototype.OrchestratorAPI = {}
    
    Vue.prototype.OrchestratorAPI.proxyCall = function (method, apiURL, payload) {
        if (Vue.prototype.$isEmpty(payload)) {
            payload = ''
        }
        if (Vue.prototype.$store === undefined || Vue.prototype.$store.getters === undefined || Vue.prototype.$store.getters.useOrchestratorAsProxy === undefined || Vue.prototype.$store.getters.useOrchestratorAsProxy) {
            // Proxy the call through the orchestrator
            return Vue.prototype.$axios.post('/orchestrator/request', { APIToCall: apiURL, Payload: payload, Method: method }, { timeout: 60000 * 4 })
        }
        if (!apiURL.startsWith('/')) {
          apiURL = '/' + apiURL
        }
        apiURL = apiURL.replace('/api/v1', '')

        if (method.toLowerCase() === 'get') {
          return Vue.prototype.$axios.get(apiURL, { timeout: 60000 * 4 })
        } else {
          return Vue.prototype.$axios.post(apiURL, payload, { timeout: 60000 * 4 })
        }
    },
        
    Vue.prototype.OrchestratorAPI.notifyEvent = function(eventID, payload) {
        return Vue.prototype.$axios.post('/auth/orchestrator/notify', JSON.stringify({ 'Scope': eventID, 'Message': btoa(unescape(encodeURIComponent(JSON.stringify(payload)))) }))
    }

    Vue.prototype.OrchestratorAPI.applicationDetails = function () {
        return Vue.prototype.$axios.get('/auth/orchestrator/application/details')
    },

    Vue.prototype.OrchestratorAPI.loadActiveModules = function () {
        return Vue.prototype.$axios.get('/auth/orchestrator/modules/active')
    },

    Vue.prototype.OrchestratorAPI.loadModules = function () {
        return Vue.prototype.$axios.get('/auth/orchestrator/modules')
    },

    Vue.prototype.OrchestratorAPI.applicationVersion = function () {
        return Vue.prototype.$axios.get('/auth/orchestrator/application/version')
    },

    Vue.prototype.OrchestratorAPI.settings = function () {
        return Vue.prototype.$axios.get('/auth/orchestrator/private/options')
    },

    Vue.prototype.OrchestratorAPI.options = function () {
        return Vue.prototype.$axios.get('/auth/orchestrator/options')
    },

    Vue.prototype.OrchestratorAPI.preferences = function () {
        return Vue.prototype.$axios.get('/auth/orchestrator/preferences')
    }
}
