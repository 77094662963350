<template>

    <av-expansion-panel
                        style="margin-bottom: 10px; width: calc(100% - 20px); border-radius: 5px; border: 1px solid lightgrey; "
                        class="elevation-0 pl-1 pr-1"
                        expand>
        <v-expansion-panel-content class="pa-2">
            <template v-slot:header>
                <v-layout row  class="pr-2">
                    <v-flex class="title pt-4" >{{ label }}</v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs2 class="pt-4">
                        <v-switch v-if="!isChild" v-model="val.disabled" @click.native.stop :label="val.disabled ? $gettext('Disabled') : $gettext('Disable')"></v-switch>
                    </v-flex>
                    <v-flex xs1 class="pa-2 mr-2 pr-2">
                        <av-button  iconColor="error" buttonIcon="fas fa-trash" fab small
                                    @click="$emit('deleteValue')"
                                    class="ma-0 mr-2" style="margin-left: 5px"
                                    :toolTip="$gettext('Delete')" v-if="!disableDelete"/>
                    </v-flex>

                </v-layout>
            </template>
            <v-layout column >
                 <v-switch v-if="!isChild" v-model="val.failInSimulationMode" :label="$gettext('Enable fail result for simulation mode')" :disabled="val.disabled" ></v-switch>
                <template v-if="val.flows">
                    <v-switch :label="$gettext('Choose specific flows to apply this value')" v-model="chooseFlow" @change="onChangeFlow" :disabled="val.disabled"></v-switch>
                    <av-multi-select v-if="chooseFlow" class="mt-3" v-model="val.flows" :label="$gettext('Flows')"
                                     item-text="Show" :placeholder="$i18n.CommonAttributes()['select']"
                                     :items="usableFlows" @change="$emit('onChangeFlow',val.flows)"  :disabled="val.disabled">
                    </av-multi-select>
                    <br/><br/>
                </template>

                <template v-if="!parameters || parameters.length === 0">
                    <v-radio-group v-model="val.assignMode"  :disabled="val.disabled || isChild && disabled" >
                        <template v-for="assignMode in assignModes">
                            <v-radio
                                :key="assignMode.value"
                                :label="assignMode.text"
                                :value="assignMode.value"  @change="clearValue(assignMode.value)"
                            ></v-radio>
                            <v-card  elevation="0" v-if="val.assignMode===assignModes[0].value && assignMode.value===assignModes[0].value">
                                <v-card-text class="ml-3">
                                    <field v-model="val.value" :variable="variableConfiguration" :label="$gettext('Default value')" ></field>
                                    <v-radio-group v-model="value.restriction">
                                        <v-radio
                                            v-for="r in restrictions"
                                            :key="r.value"
                                            :label="r.text"
                                            :value="r.value"
                                        ></v-radio>
                                    </v-radio-group>
                                    <v-switch
                                        v-model="value.canChangeValueOnTransition"
                                        :label="$extracttext('Change value from operator')"
                                        v-if="value.restriction!=='fixed'"
                                    ></v-switch>
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" v-if="val.assignMode===assignModes[1].value && assignMode.value===assignModes[1].value">
                                <v-card-text class="ml-3">

                                    <av-select class="mt-3" v-model="val.value" :label="$gettext('Variables')"
                                               item-text="name" :placeholder="$i18n.CommonAttributes()['select']"
                                               :items="referenceVariables" @change="(v)=>{$emit('onReferenceVariableSelected',v)}">
                                    </av-select>
                                </v-card-text>
                            </v-card>
                            <v-card elevation="0" v-if="val.assignMode===assignModes[2].value && assignMode.value===assignModes[2].value">
                                <v-card-text class="ml-3">
                                    <v-icon  color="orange" small v-if="systemVariablesByType.length===0"> fas fa-exclamation-triangle</v-icon>
                                    <label v-if="systemVariablesByType.length===0" class="subheading ml-2" style="color: var(--av-orange);">{{$gettext("No matching system variables for type {0}").format(type.type)}}"</label>
                                    {{systemVariableText(val)}}
                                    <v-btn text @click="showVariablePicker=true" color="info"><translate>Pick Variable</translate></v-btn>
                                    <VariablePickerDialog :showDialog="showVariablePicker" @selectedItem="($event)=>{onSystemVariableSelected($event,val)}"
                                                          :select-field="true" :filtered-mapping="usableSystemVariables" :variables="usableSystemVariables"/>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-radio-group>
                    <av-select class="mt-3" v-model="val.ifSuccess" :label="$gettext('If Success')"
                               item-text="text" item-value="value" :placeholder="$i18n.CommonAttributes()['select']"
                               :items="failSuccessTypes"  :disabled="val.disabled || isChild && disabled">
                    </av-select>

                    <av-select class="mt-3" v-model="val.ifFail" :label="$gettext('If Fail')"
                               item-text="text" item-value="value" :placeholder="$i18n.CommonAttributes()['select']"
                               :items="failSuccessTypes"  :disabled="val.disabled || isChild && disabled">
                    </av-select>

                </template>
                <template v-else>
                    <recipe-value v-for="(value,i) in val.value" style="margin-bottom: 10px" elevation="0" :key="i" expand
                                  :type="value.type" :value="value" @onUpdate="(v)=>{$emit('onUpdate', val);}" :reference-variables="referenceVariables"
                                  :disableDelete="true" :mapping="mapping" :label="value.label" is-child="true" :disabled="val.disabled">
                    </recipe-value>
                </template>

            </v-layout>
        </v-expansion-panel-content>
    </av-expansion-panel>
</template>

<script>
    import Field from '@/components/dynamic-elements/forms/Field';
    import VariablePickerDialog from "@/components/dialogs/VariablePickerDialog";
    export default {
        name: "RecipeValue",
        components:{VariablePickerDialog, Field},
        props: {
            value: {
                required: true,
                default: () => {
                    return {}
                },
            },
            type: {
                required: true,
                default: () => {
                    return "text"
                }
            },
            referenceVariables: {
                required: true,
                default: () => {
                    return []
                }
            },
            excludeFlows:{
                required:false,
                default:() =>{ return []}
            },
            mapping:{
                required:true,
                default:()=>{return []}
            },
            parameters:{
                required: false,
                default:()=>{ return []}
            },
            disableDelete:{
                required:false,
                default:()=>{ return false}
            },
            label:{
                required:false,
                default:()=>{ return "Value"}
            },
            isChild:{
                required:false,
                default:()=>{ return false}
            },
            disabled:{
                required:false,
                default:()=>{ return false }
            }
        },
        data:()=>{
            return{
                val:{},
                chooseFlow:false,
                flows:[],
                restrictions:[
                    {
                        value:"fixed",
                        text:"Fixed value",
                    },
                    {
                        value:"canbeprovided",
                        text:"Can be provided",
                    },
                    {
                        value:"mustbeprovided",
                        text:"Must be provided",
                    }
                ],
                assignModes:[
                    {
                        value:"assignvalue",
                        text:"Assign value to variable"
                    },
                    {
                        value:"applyfromvariable",
                        text:"Apply from another variable"
                    },
                    {
                        value:"systemvariable",
                        text:"Assign system variable"
                    },
                ],
                systemVariables:[
                    {
                        value:"{{Workorder.Id}}",
                        text:"Workorder"
                    },
                    {
                        value:"{{Recipe.Name}}",
                        text:"Recipe"
                    },
                ],
                failSuccessTypes:[
                    {
                        value:"continue",
                        text:"Continue"
                    },
                    {
                        value:"return_success",
                        text:"Return Success"
                    },
                    {
                        value:"return_fail",
                        text:"Return Fail"
                    },
                ],
                showVariablePicker:false
            }
        },
        computed:{
            variableConfiguration(){
                return{
                    type:this.type.fieldType,
                    min:this.type.min,
                    max:this.type.max,
                    minChoice:this.type.min,
                    maxChoice:this.type.max
                }
            },
            usableFlows(){
                if(this.excludeFlows.length===0){
                    return this.flows;
                }
                let ss=[];
                let self=this;
                this.flows.forEach(flow=>{
                    if(!self.excludeFlows.find(es=>{return es.Id===flow.Id})){
                        ss.push(flow)
                    }
                });
                return ss;
            },
            usableSystemVariables() {
                return [
                    {
                        name:this.$gettext("Machine Recipes"),
                        group:this.$gettext("Machine Recipes"),
                        key:"machineRecipes",
                        root:"machineRecipes",
                        isRoot:true,
                        index:"machineRecipes",
                        children:[{
                            name:this.$gettext("RecipeId"),
                            group:this.$gettext("Machine Recipes"),
                            key:"{{machine.RecipeId}}",
                            root:"machineRecipes",
                            index:"machineRecipes",
                        }]
                    },
                    {
                        name:this.$gettext("Workorder"),
                        group:this.$gettext("Workorder"),
                        key:"workorder",
                        root:"workorder",
                        index:"workorder",
                        isRoot:true,
                        children:[...this.systemVariablesByType.map(v=>{
                            return {
                                name:this.$gettext(v.text),
                                group:this.$gettext("Workorder"),
                                key:v.value,
                                root:"workorder",
                                index:"workorder",
                            }
                        })]
                    }
                ]
                // return [
                //     {header: this.$gettext("Machine Recipes")},
                //     {divider: true},
                //     {
                //         value: "{{machine.RecipeId}}",
                //         text: "RecipeId",
                //         type: "string"
                //     },
                //     {header: this.$gettext("Workorder")},
                //     {divider: true},
                //     ...this.systemVariablesByType
                // ]
            },
            systemVariablesByType(){
                return  this.systemVariables.filter(sv => {
                    return this.checkVariableType(sv.type)
                })
            },
            systemVariableText() {
                return val => {
                    if (val.value !== "") {
                        let tmp = this.usableSystemVariables.find(sv => {
                            return sv.children.find(svc => {
                                return svc.key === val.value
                            })
                        })
                        if (tmp) {
                            return tmp.children.find(svc => {
                                return svc.key === val.value
                            }).name
                        }
                    }

                    return ""
                }
            }

        },
        mounted(){
            this.isOpening=true;
            this.val=this.value;
            if(this.val.flows){
                this.chooseFlow=!this.val.flows.find(s=>{return s.Id==="default"});
            }

            this.isOpening=false;
            this.loadFlows();
            this.unwrapMapping();
        },
        methods:{
            async loadFlows() {
                this.flows = await this.$workflows.flows();
            },
            clearValue(assignMode){
                // let flows=this.val.flows
                // let self=this;
                // debugger
                this.val.value="";
                this.val.restriction="";
            },
            onChangeFlow() {
                if(!this.chooseFlow) {
                    this.val.flows = [this.$workflows.getAllFlowsDescriptor()]
                } else {
                    this.val.flows=[];
                }
            },
            checkVariableType(elasticType){
                switch (elasticType) {
                    case "date":
                    case "keyword":
                    case "geo_point":
                    case "text":
                        return this.type.type==='string';
                    case "float":
                    case "half_float":
                    case "scaled_float":
                        return this.type.type==="float";
                    case  "long":
                    case "integer":
                    case "OEEComponent":
                    case "short":
                    case "byte":
                        return this.type.fieldType==='number';
                    // case  "long":
                    //     return this.type.type==='int64';
                    // case "integer":
                    // case "OEEComponent":
                    //     return this.type.type==="int32" || this.type.type==="uint32";
                    // case "short":
                    //     return this.type.type==="int16" || this.type.type==="uint16";
                    // case "byte":
                    //     return this.type.type==="byte" || this.type.type==="sByte";
                    case "bool":
                    case "boolean":
                        return this.type.type==="bool" || this.type.type==="boolean";
                    default:
                        return true;
                }
            },
            unwrapMapping() {
                let woMapping=[];
                let self = this;
                this.mapping.forEach(m => {
                    if (m.children.find(v => {
                        return v.index === 'workorders'
                    })) {
                        woMapping.push(...m.children.filter(v => {
                            return v.index === 'workorders'
                        }).filter(item => {
                            return item.name !== 'Transitions' && item.name !== 'tag'
                        }))
                    }
                });

                this.systemVariables.clear();
                this.mapWoVariable(woMapping)
                console.log(this.systemVariables)
            },
            mapWoVariable(variables) {
                //debugger
                for(let variable of variables) {
                    if(!Array.isUseful(variable.children)) {
                        this.systemVariables.push({
                            value: "{{.workorder." + (!variable.root ? "" : variable.root + ".") + variable.name + "}}",
                            text: (!variable.root ? "" : variable.root + ".") + variable.name,
                            type: variable.type
                        })
                    } else {
                        this.mapWoVariable(variable.children)
                    }
                }

            },
            onSystemVariableSelected(event,value) {
                value.value = event.key
                this.showVariablePicker = false
            }
        },
        watch:{
            val:{
                deep:true,
                handler(){
                    this.$emit('onUpdate', this.val);
                    for (let key in this.val){
                        this.$emit('input', { ...this.val, [key]: this.val[key] })
                    }
                    if (this.val.flows){
                        if((this.val.flows.length>1 || (this.val.flows.length ===1 && this.val.flows[0].Id!=='default'))  && !this.chooseFlow){
                            this.chooseFlow=true;
                        }
                    }


                }
            },
            value(){
                this.val=this.value;
            }
        }
    }
</script>

<style scoped>

</style>
