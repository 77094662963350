<template>
    <v-layout justify-start align-start column fill-height mx-0 style="overflow-y: auto">
        <template v-for="element in elementTypes" v-if="showElementType(element)">
            <v-layout align-center justify-start row ma-2>
                <v-hover>
                    <v-card style="height: 150px; min-width: 200px; max-width: 200px; margin-right: 5px;" :id="'newItem_' + element.name"
                            slot-scope="{ hover }" :class="`elevation-${hover ? 20 : 5}`" @click="createElement(element)">
                        <v-layout justify-center align-center fill-height>
                            <img style="width: 90%;" :src="element.thumbnail"/>
                        </v-layout>
                    </v-card>
                </v-hover>
                <v-layout justify-center align-start column fill-height ml-3 style="margin: 0 0 0 16px!important;">
                    <div class="title" style="text-align: start; margin-bottom: 5px;">{{element.name}}</div>
                    <div class="subheading" style="text-align: start; margin-top: 5px;">{{element.description}}</div>
                </v-layout>
            </v-layout>
        </template>
    </v-layout>
</template>

<script>
    export default {
        name: 'ElementsCreator',
        props: {
            elementTypes: {}
        },
        mounted(){
            if (this.elementTypes.length === 1)
                this.createElement(this.elementTypes[0]);
        },
        methods: {
            createElement(type) {
                this.$emit('createElement', type);
            },
            showElementType(element) {
                if(!this.$license.hasModules(element.modules))
                    return false;
				if (Object.isUseful(element.visible) && !element.visible)
					return false;
                if(Array.isUseful(element.deployLevels))
                    return element.deployLevels.includes(this.$config.deployment);
                return true
            }
        }
    }
</script>
