<template>
    <v-container id="ContentContainer" class="page-container" grid-list-md text-xs-center pa-3 ma-0 style="max-width: 100%; overflow: hidden">
        <v-card  class="mt-0 av-card" elevation="0" style="height: 80px;">
            <v-layout row wrap justify-end>
                <av-button v-if="!allConfigurationsConfirmed" class="ma-4" :text="$gettext('CONFIRM ALL')" color="info" @click="confirmAllConfigurations" buttonIcon="fas fa-check" iconColor="light"/>
                <av-button v-else class="ma-4" :text="$gettext('UNCONFIRM ALL')" color="info" @click="unconfirmAllConfigurations" buttonIcon="fas fa-check" iconColor="light"/>
                <av-button class="ma-4" :text="$gettext('BACK')" color="error" @click="openPage('workordersPage')" buttonIcon="fas fa-arrow-left" iconColor="light"/>
            </v-layout>
        </v-card>
        <v-card  class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)">
            <v-card-text  class="av-card-content" style="height: calc(100vh - 30px)">
                                    <v-data-table
                                                  :headers="machineRecipesHeaders"
                                                  :items="filteredMachineRecipes"
                                                  class="elevation-0 subheading fixed-header v-table__overflow"
                                                  style="height: calc(100vh - 100px)"
                                                  :custom-sort="customSort"
                                                  item-key="name"
                                                  hide-actions>
                                        <template v-slot:items="props">
                                            <tr>
                                                <td  class="text-xs-left subheading">
                                                    {{ props.item.Machine}}
                                                </td>
                                                <td class="text-xs-left subheading">
                                                    {{ props.item.Recipe }}
                                                </td>
                                                <td v-if="!props.item.Confirmed" class="text-md-left text-xs-left">
                                                    <av-button class="ma-4" :text="$gettext('UNCONFIRMED')" color="error" @click="confirmConfiguration(props.item)"  title="Click to confirm configuration" buttonIcon="fas fa-check" iconColor="light"/>
                                                </td>
                                                <td v-else class="text-md-left text-xs-left">
                                                    <av-button class="ma-4" :text="$gettext('CONFIRMED')" color="green" @click="unConfirmConfiguration(props.item)"  title="Click to unconfirm configuration" buttonIcon="fas fa-check" iconColor="light"/>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                    <v-layout style="height: 20px;" row wrap justify-end>
                                        <av-button v-if="allConfigurationsConfirmed"  :text="$gettext('START WORKORDER')" color="info" @click="updateWorkorderStatus" buttonIcon="fas fa-check" iconColor="light"/>
                                    </v-layout>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import AvProgress from '@/components/av-components/av-progress'
    import {bus} from '../../main.js'

    export default {
        name: "workorderManualLineScheduling",
        components: {AvProgress},
        data: () => ({
            machineRecipesHeaders: [
                {
                    text: "Machine",
                    class: "subheading",
                    value: "Machine"
                },
                {
                    text: "RecipeId",
                    class: "subheading",
                    value: "Recipe"
                },
            ],
            machineRecipesDescriptor: [
                {
                    "Machine": "L1_DEPALLETTIZZATORE_BOTTIGLIE",
                    "Recipe": "101"
                },
                {
                    "Machine": "L1_MICROFILTRAZIONE",
                    "Recipe": "102"
                },
                {
                    "Machine": "L1_QUADRIBLOCCO",
                    "Recipe": "103"
                },
                {
                    "Machine": "L1_CONTROLLO_LIV_TAPPO",
                    "Recipe": "104"
                },
                {
                    "Machine": "L1_GABBIETTATRICE",
                    "Recipe": "105"
                },
                {
                    "Machine": "L1_CAPSULATORE_SPUMANTE",
                    "Recipe": "106"
                },
                {
                    "Machine": "L1_CAPSULATORE",
                    "Recipe": "107"
                },
                {
                    "Machine": "L1_ETICHETTATRICE_BOTTIGLIA",
                    "Recipe": "108"
                },
                {
                    "Machine": "L1_LABEL_CONTROLLER",
                    "Recipe": "109"
                },
                {
                    "Machine": "L1_MARCATORE",
                    "Recipe": "110"
                },
                {
                    "Machine": "L1_FORMA_CARTONI",
                    "Recipe": "111"
                },
                {
                    "Machine": "L1_INCASSETTATRICE",
                    "Recipe": "112"
                },
                {
                    "Machine": "L1_ETICHETTATRICE_CARTONI",
                    "Recipe": "113"
                },
                {
                    "Machine": "L1_PESA_CARTONI",
                    "Recipe": "114"
                },
                {
                    "Machine": "L1_CHIUDI_CARTONI",
                    "Recipe": "115"
                },
                {
                    "Machine": "L1_PALLETTIZZATORE",
                    "Recipe": "116"
                },
                {
                    "Machine": "L1_AVVOLGITORE",
                    "Recipe": "117"
                },
                {
                    "Machine": "tableting-machine",
                    "Recipe": "118"
                },
                {
                    "Machine": "print-and-check",
                    "Recipe": "119"
                },
                {
                    "Machine": "pallettizer",
                    "Recipe": "120"
                },
                {
                    "Machine": "case-packer",
                    "Recipe": "121"
                },
                {
                    "Machine": "cartooner",
                    "Recipe": "122"
                },
                {
                    "Machine": "blisterer",
                    "Recipe": "123"
                },
            ],
            workorder: {},
            machineRecipes: [], // used to save original values
            allConfigurationsConfirmed: false,
            devices: [],
        }),
        computed: {
            filteredMachineRecipes() {
                let filtered = this.workorder.MachineRecipes;

                return filtered;
            },
        },
        mounted() {
            this.$root.setCurrentPageTitle(this.$gettext("workorder manual line scheduling"));
        },
        created: function () {
            //MachinesSettings
            this.devices = this.$aliases.getAliasesDescriptors();
            this.workorder = this.$route.params.data;
            //FN TODO implement logic for field RecipeId
            if(!this.workorder.hasOwnProperty("MachineRecipes")) {
                //TODO Manage RecipeId
                //FN descriptor used for test
                this.workorder.MachineRecipes = this.machineRecipesDescriptor;
                this.parseMachineRecipes();
            } else { //FN IF It exists is mandatory to use MachineRecipes field
                this.parseMachineRecipes();
            }
        },
        beforeDestroy () {

        },
        methods: {
            parseMachineRecipes() {
                let self = this;
                //detach to not modify the original MachineRecipes
                this.machineRecipes = this.$utils.detach(this.workorder.MachineRecipes);
                //INIT set Confirmed for each configuration , check if automaticScheduling is true
                this.workorder.MachineRecipes.forEach(async(machineRecipe) => {
                        let machineSetting = self.devices.get(machineRecipe.Machine);
                        let alias =  Object.isUseful(machineSetting) ? await self.$aliases.getMachineAlias(machineRecipe.Machine) : machineRecipe.Machine;
                        if(!alias)
                            alias = machineRecipe.Machine;
                        let isConfirmed = Object.isUseful(machineSetting) ? machineSetting.automaticScheduling :false;
                        self.$set(machineRecipe, 'Confirmed', isConfirmed);
                        self.$set(machineRecipe, 'Machine', alias);
                });
            },

            confirmConfiguration(configuration) {
                this.$set(configuration, 'Confirmed', true);
                this.checkConfirmedAllConfigurations();
            },
            unConfirmConfiguration(configuration) {
                this.$set(configuration, 'Confirmed', false);
                this.checkUnConfirmedAllConfigurations();
            },
            checkConfirmedAllConfigurations() {
                let counter = 0;
                this.workorder.MachineRecipes.forEach((machineRecipe, index) => {
                    if(machineRecipe.Confirmed === true) {
                     counter++;
                    }
                });
                if(counter === this.workorder.MachineRecipes.length) {
                    this.allConfigurationsConfirmed = true;
                }
            },
            checkUnConfirmedAllConfigurations() {
                let self = this;
                let counter = 0;
                this.workorder.MachineRecipes.forEach((machineRecipe, index) => {
                    if(machineRecipe.Confirmed === false) {
                        counter++;
                    }
                });
                if(counter === this.workorder.MachineRecipes.length) {
                    this.allConfigurationsConfirmed = false;
                }
            },
            confirmAllConfigurations() {
                this.setConfirmAllConfigurations(true);
            },
            unconfirmAllConfigurations() {
                this.setConfirmAllConfigurations(false);
            },
            setConfirmAllConfigurations(confirmValue) {
                //FN TODO audits
                let self = this;
                this.workorder.MachineRecipes.forEach((machineRecipe, index) => {
                    let mrTmp = self.$utils.detach(machineRecipe);
                    mrTmp.Confirmed = confirmValue;
                    self.$set(self.workorder.MachineRecipes, index, mrTmp);
                });
                this.allConfigurationsConfirmed = confirmValue;
            },
            updateWorkorderStatus() {
                //Return the original machineRecipes
                this.$root.setLoading(true, "");
                this.workorder.MachineRecipes = this.machineRecipes;
                this.workorder.RequestedStatus = this.$workorders.WorkorderStatus.Running;
                let physicalLineId = "";
                if(this.$config.isL2())
                    physicalLineId = this.$settings.getLineSettings().physicalLineId;
                if(this.$config.isL3())
                    physicalLineId = this.workorder.PhysicalLineId;
                let data = {workorder: this.workorder, line: physicalLineId};
                //Add event to listen updated workorder
                this.onEventUpdatedWorkorderStatus();
                bus.$emit('updateWorkorderStatus', data);
            },
            onEventUpdatedWorkorderStatus() {
                let self = this;
                bus.$on('updatedWorkorderStatus', async (data) => {
                    await self.sleep(5000);
                    self.$root.setLoading(false, "");
                    bus.$off('updatedWorkorderStatus');
                    self.openPage('workordersPage');
                })
            },
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            openPage(page){
                this.$router.push({name:page});
            }
        },
    }
</script>

<style scoped>

</style>