import OrchestratorAPI from "@/api/orchestrator";
import Microservices from "@/api/microservices";
import VueInstance from "@/api/vueinstance";
import DataApis from "./data";
import DateTime from "./datetimeutils";

export default {
    async createPrototype(prototype) {
        debugger
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.entityUrl + "/prototype", prototype)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    console.log(error);
                    reject(VueInstance.get().$gettext("Unable to create entity prototype"));
                });
        })
    },
    async getPrototype(prototypeID) {
        debugger
        return new Promise((resolve, reject) =>{
            OrchestratorAPI.proxyCall('get', Microservices.entityUrl + "/prototype/" + prototypeID)
                .then(response=> {
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    console.log(error);
                    reject(VueInstance.get().$gettext("Unable to get entity prototype by id"));
                });
        })
    },
    async getPrototypes() {
        debugger
        return new Promise((resolve, reject) =>{
            OrchestratorAPI.proxyCall('get', Microservices.entityUrl + "/prototype")
                .then(response=> {
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    console.log(error);
                    reject(VueInstance.get().$gettext("Unable to get all entity prototypes"));
                });
        })
    },
    async deletePrototype(prototypeID) {
        debugger
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('delete', Microservices.entityUrl + "/prototype/" + prototypeID)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    console.log(error);
                    reject(error)
                });
        });
    },
    //TODO verify API usage
    async getAllEntityByDbKey(entity) {
        debugger
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.entityUrl + "/getAllEntityByKey/"+entity)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    console.log(error);
                    reject(error)
                });
        });
    },
    //TODO verify API usage
    async getKeyValueForKeys(param, payload) {
        debugger
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.entityUrl + "/getKeyValueForKeys", payload)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        })
    },
    deleteProductionDataInstance(id) {
        debugger
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('delete', Microservices.entityUrl + "/deleteProductionData/" + id)
                .then(response=> {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(VueInstance.get().$gettext("Unable to delete data"));
                });
        })
    },
    async GetByIdProductionData(ProductionDataId) {
        debugger
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.entityUrl + "/productionData/" + ProductionDataId)
                .then(response=> {
                    resolve(response);
                })
                .catch(response => {
                    reject(VueInstance.get().$gettext("Unable to get entity  by id"));
                });
        })
    },

    /* ENTITIES APIs */
    async getAllEntities(from, to) {
        if(!from)
            from = new Date(0);

        if(!to)
            to = new Date();

        let query = {
            raw: [{
                name: "raw_0",
                q: "index=data_entity&filter=index.keyword,=,data_entity&sort=@timestamp,asc",
                max_results: 30000
            }]
        };

        return new Promise((resolve, reject) => {
            DataApis.getDataBlob(query, DateTime.getRfc3339TimeStamp(from), DateTime.getRfc3339TimeStamp(to))
                .then(result => {
                    resolve(result.raw_0);
                })
                .catch(err => {
                    if(Microservices.isIndexEmptyError(err))
                        resolve([]);
                    else {
                        debugger;
                        console.log(err);
                        reject(VueInstance.get().$gettext("Unable to retrieve maintenance register"));
                    }
                });
        });
    },
    saveEntity(data, exposedName, update = false) {
        if(exposedName) {
            data.tag = {
                ExposedName: exposedName
            }
        }
        return new Promise((resolve, reject) =>{
            OrchestratorAPI.proxyCall('post', Microservices.entityUrl + "/entity" + (update ? "/update" : ""), data)
                .then(response => {
                    response = JSON.parse(response);
                    resolve(response.result);
                })
                .catch(response => {
                    if(response && response.Message === "notUniqueKey")
                        reject(VueInstance.get().$gettext("Key values are not unique."));
                    else if(response && response.Message === "noEntityPrototype")
                        reject(VueInstance.get().$gettext("Entity was not correctly created. Please deactivate and reactivate it"));
                    else if(response && response.Message === "entityNotFound")
                        reject(VueInstance.get().$gettext("Unable to find requested entity, cannot execute update"));
                    else
                        reject(VueInstance.get().$gettext("Error occurred while saving item"));
                });
        })
    },
    saveEntityHistory(document){
        return new Promise((resolve, reject) =>{
            OrchestratorAPI.proxyCall('post', Microservices.entityUrl + "/history", { descriptor: document, id: document.id, name: document.name,
                internal_name: document.internal_name, version: document.version, modifiedBy: document.Operator })
                .then(response => {
                    response = JSON.parse(JSON.stringify(response));
                    resolve(response.result);
                })
                .catch((err) => {
                    console.error(err);
                    reject(VueInstance.get().$gettext("Error while saving history"));
                })
        })
    },
    async getEntity(entityId){
        debugger
        return new Promise((resolve, reject) =>{
            OrchestratorAPI.proxyCall('get', Microservices.entityUrl + "/entity/" + entityId)
                .then(response=> {
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    console.log(error);
                    reject(VueInstance.get().$gettext("Unable to get entity  by id"));
                });
        })
    },
    async getAllEntitiesAndChangeStatus(entity) {
        debugger
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.entityUrl + "/getAllEntitiesAndChangeStatus/"+entity)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                });
        });
    },
    async entityInstancesCount(entity) {
        debugger
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.entityUrl + "/instances/count/" + entity)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    if(Microservices.isIndexEmptyError(err))
                        resolve(0);
                    else {
                        debugger;
                        console.log(err);
                    }
                });
        });
    },

    async setInstancesActive(entity, enabled) {
        debugger
        let action = enabled ? "activate" : "deactivate";
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.entityUrl + "/instances/{0}/{1}".format(action, entity))
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    debugger;
                    console.error(error);
                    reject(error);
                });
        });
    },

    deleteInstance(id) {
        debugger
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('delete', Microservices.entityUrl + "/instances/" + id)
                .then(response=> {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(VueInstance.get().$gettext("Unable to delete entity instance"));
                });
        })
    }
}
