<template>
    <v-card class="pa-0 ma-0">
        <v-card-text class="pa-0 ma-0">
            <v-layout column pa-2 >
                <label class="subheading text-xs-center" v-translate>Rule execution scheduling</label>
                <v-divider></v-divider>
                <v-radio-group class="pa-2" v-model="ruleScheduling.executionSchedulingType" @change="executionSchedulingChange">
                    <v-radio :label="evaluatePeriodically" class="ml-2 mb-1" value="evaluatePeriodicallyModel"></v-radio>
                    <v-radio :label="periodicallyFromSpecificMoment" class="ml-2 mb-1" value="periodicallyFromSpecificMomentModel"></v-radio>
                    <v-radio v-if="!options.periodicOnlyMode" :label="evaluateOnceInSpecificMoment" class="ml-2 mb-1" value="evaluateOnceInSpecificMomentModel"></v-radio>
                </v-radio-group>
                <v-datetime-picker class="ma-2" v-if="ruleScheduling.executionSchedulingType === 'periodicallyFromSpecificMomentModel' || ruleScheduling.executionSchedulingType === 'evaluateOnceInSpecificMomentModel'" :label="selectTimestamp" :datetime="dateTimePicker" @input="onSelectedTime($event)" />
                <cron-editor v-if="!options.fixedPeriod && (ruleScheduling.executionSchedulingType === 'evaluatePeriodicallyModel' || ruleScheduling.executionSchedulingType === 'periodicallyFromSpecificMomentModel')" v-model="ruleScheduling.cron"></cron-editor>
                <av-switch v-if="!options.periodicOnlyMode" :value="ruleScheduling.oneShoot" @change="ruleScheduling.oneShoot = $event" :label="oneShootLabel" class="ma-2" :disabled="ruleScheduling.executionSchedulingType === 'evaluateOnceInSpecificMomentModel'"></av-switch>
                <!--TODO finalize <av-switch :value="ruleScheduling.executeRuleOnFirstRun" @change="ruleScheduling.executeRuleOnFirstRun = $event" :label="$gettext('Execute rule on first run')" class="ma-2" :disabled="ruleScheduling.executionSchedulingType === 'evaluateOnceInSpecificMomentModel'"></av-switch>-->
                <label v-if="schedulingExamples.length > 0" class="ma-2" v-translate>Next 3 Schedules based on your settings:</label>
                <div v-if="schedulingExamples.length > 0" class="ma-2">{{schedulingExamplesView}}</div>
                <template v-if="options.requiresFixedAbsoluteQueryWindow">
                    <label class="subheading text-xs-center mt-4">Special query time window management</label>
                    <v-divider></v-divider>
                    <v-layout row class="mt-2">
                        <av-switch :disabled="options.fixedAbsoluteQueryWindow" v-model="ruleScheduling.queryFromLastUsefulRuleResult" :label="$gettext('Execute query from last useful rule result to now')" class="ma-2"></av-switch>
                        <!--TODO create help button component -->
                        <v-menu offset-y transition="slide-y-transition" bottom>
                            <v-btn slot="activator" flat small icon><av-icon color="info">fas fa-question</av-icon></v-btn>
                            <v-card class="pa-2" style="max-width: 400px">
                                <v-card-text v-translate>If checked, rule will execute query on a time window starting from the last time the rule evaluated a useful (true condition) result. If not selected, query time window can be set on the rule preview. As an example, the setting is useful if you wish to execute an action every time a counter accumulates a certain amount independently of how much time it takes to accumulate it.</v-card-text>
                            </v-card>
                        </v-menu>
                    </v-layout>
                </template>
                <template v-if="options.requiresActivityDuration">
                    <label class="subheading text-xs-center mt-4" v-translate>Activity Information</label>
                    <v-divider></v-divider>
                    <v-layout row class="mt-2">
                        <div class="pa-2 ma-2">
                            <v-layout row>
                                <av-text-field  style="max-width: 250px" v-model="ruleScheduling.activityDuration" :prefix="$gettext('Duration: ')" type="number" min=1 @change="convertDurationToSeconds()"></av-text-field>
                                <av-select class="ml-2" v-model="ruleScheduling.activityTimeUnit" :items="timeUnits" @change="convertDurationToSeconds()" />
                            </v-layout>
                        </div>
                    </v-layout>
                </template>
                <template v-if="options.requiresTimeSchedulingRestrictions">
                    <label class="subheading text-xs-center mt-4" v-translate>Scheduling restrictions</label>
                    <v-divider></v-divider>
                    <av-switch v-model="ruleScheduling.timeSchedulingRestrictions" :label="timeSchedulingRestrictionsLabel" class="ma-2"></av-switch>
                    <ConfigurationsEditor v-if="ruleScheduling.timeSchedulingRestrictions" :descriptor="schedulingSettings"  @value-Changed="valueChanged" :filters="$license.modulesList()"></ConfigurationsEditor>
                </template>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
import VueCronEditorBuefy from 'vue-cron-editor-buefy';
import CronEditor from '@/components/utilities/CronEditor';
import Rules from '@/api/rules.js';
import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
import DateTime from "@/api/datetimeutils";

export default {
    name: "RuleExecutionScheduling",
    components: {
        VueCronEditorBuefy,
        CronEditor,
        ConfigurationsEditor,
    },
    props: {
        ruleScheduling: {
            type: Object,
            default: () => {
                return {};
            }
        },
        options: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            schedulingExamples: [],
            schedulingSettings: {
                openingTimeSettings: {
                    groupName: this.$gettext("Scheduling periods"),
                    settings: {
                        workDays: {
                            name: this.$gettext("Weekly work days"),
                            options: DateTime.daysOfWeek(),
                            value:  this.ruleScheduling.workDays,
                            description: "",
                            type: "multiChecks",
                            readOnly: false,
                        },
                        workHours: {
                            name: this.$gettext("Daily work schedule"),
                            description: "",
                            type: "subgroup",
                            groupParentName: "openingTimeSettings",
                            settings: {
                                mondayWorkHours: {
                                    name: this.$gettext("Monday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: Array.isUseful(this.ruleScheduling.workHours) ? this.ruleScheduling.workHours[0] : [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 0,
                                },
                                tuesdayWorkHours: {
                                    name: this.$gettext("Tuesday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: Array.isUseful(this.ruleScheduling.workHours) ? this.ruleScheduling.workHours[1] : [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 1,
                                },
                                wednesdayWorkHours: {
                                    name: this.$gettext("Wednesday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: Array.isUseful(this.ruleScheduling.workHours) ? this.ruleScheduling.workHours[2] : [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 2,
                                },
                                thursdayWorkHours: {
                                    name: this.$gettext("Thursday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: Array.isUseful(this.ruleScheduling.workHours) ? this.ruleScheduling.workHours[3] : [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 3,
                                },
                                fridayWorkHours: {
                                    name: this.$gettext("Friday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: Array.isUseful(this.ruleScheduling.workHours) ? this.ruleScheduling.workHours[4] : [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 4,
                                },
                                saturdayWorkHours: {
                                    name: this.$gettext("Saturday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: Array.isUseful(this.ruleScheduling.workHours) ? this.ruleScheduling.workHours[5] : [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 5,
                                },
                                sundayWorkHours: {
                                    name: this.$gettext("Sunday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: Array.isUseful(this.ruleScheduling.workHours) ? this.ruleScheduling.workHours[6] : [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 6,
                                },
                            }
                        },
                        notWorkingDaysOfYear: {
                            name: this.$gettext("Non working periods of years"),
                            fromLabel: this.$gettext("From"),
                            toLabel: this.$gettext("to"),
                            value:  this.ruleScheduling.notWorkingDaysOfYear,
                            description: "",
                            type: "dateSpans",
                            multi: true,
                            readOnly: false,
                        },
                    }
                }
            },
        }
    },
    computed: {
        evaluatePeriodically() {
            return this.$gettext("Evaluate starting from rule activation");
        },
        periodicallyFromSpecificMoment() {
            return this.$gettext("Evaluate starting from a specific moment");
        },
        evaluateOnceInSpecificMoment() {
            return this.$gettext("Evaluate only once in a specific moment");
        },
        selectTimestamp() {
            return this.$gettext("Select first execution moment")
        },
        dateTimePicker() {
            if (this.ruleScheduling.initialReferenceTime)
                return new Date(this.ruleScheduling.initialReferenceTime);
            return new Date()
        },
        oneShootLabel() {
            return this.$gettext("Evaluate just once than stop")
        },
        timeSchedulingRestrictionsLabel() {
            return this.$gettext("Enable time scheduling restrictions")
        },
        schedulingExamplesView() {
            let message = "";
            if(this.schedulingExamples.length > 0) {
                this.schedulingExamples.forEach((item, index) => {
                    message = message +  new Date(item).format();
                    if (index + 1 !== this.schedulingExamples.length)
                        message += ", "
                })
            }
            if(this.schedulingExamples.length > 1)
                message += " ...";
            return message;
        },
        timeUnits() {
            return [
                { value: "m", text: this.$gettext("minutes") },
                { value: "h", text: this.$gettext("hours") },
                { value: "d", text: this.$gettext("days") },
                { value: "w", text: this.$gettext("weeks") }
            ]
        },
        workDays() {
            this.schedulingSettings.openingTimeSettings.settings.workHours.visible = Array.isUseful(this.schedulingSettings.openingTimeSettings.settings.workDays.value);
            return this.schedulingSettings.openingTimeSettings.settings.workDays.value || [];
        }
    },
    watch: {
        ruleScheduling: {
            handler: function () {
                this.calculateTimestamps()
            },
            deep: true,
        },
        workDays: {
            handler: function () {
                this.schedulingSettings.openingTimeSettings.settings.workHours.settings.mondayWorkHours.visible = this.workDays.includes(0);
                this.schedulingSettings.openingTimeSettings.settings.workHours.settings.tuesdayWorkHours.visible = this.workDays.includes(1);
                this.schedulingSettings.openingTimeSettings.settings.workHours.settings.wednesdayWorkHours.visible = this.workDays.includes(2);
                this.schedulingSettings.openingTimeSettings.settings.workHours.settings.thursdayWorkHours.visible = this.workDays.includes(3);
                this.schedulingSettings.openingTimeSettings.settings.workHours.settings.fridayWorkHours.visible = this.workDays.includes(4);
                this.schedulingSettings.openingTimeSettings.settings.workHours.settings.saturdayWorkHours.visible = this.workDays.includes(5);
                this.schedulingSettings.openingTimeSettings.settings.workHours.settings.sundayWorkHours.visible = this.workDays.includes(6);
            },
            immediate: true
        }
    },
    mounted() {

        this.calculateTimestamps();
        this.convertDurationToSeconds();
    },
    methods: {
        convertDurationToSeconds() {
            if (this.ruleScheduling.activityTimeUnit === "m")
                this.ruleScheduling.duration = 60 * this.ruleScheduling.activityDuration
            else if (this.ruleScheduling.activityTimeUnit === "h")
                this.ruleScheduling.duration = 3600 * this.ruleScheduling.activityDuration
            else if (this.ruleScheduling.activityTimeUnit === "d")
                this.ruleScheduling.duration = 86400 * this.ruleScheduling.activityDuration
            else if (this.ruleScheduling.activityTimeUnit === "w")
                this.ruleScheduling.duration = 604800 * this.ruleScheduling.activityDuration
        },
        executionSchedulingChange() {
            this.ruleScheduling.oneShoot = false;
            if (this.ruleScheduling.executionSchedulingType === 'evaluatePeriodicallyModel')
                this.ruleScheduling.initialReferenceTime = '';
            if (this.ruleScheduling.executionSchedulingType === 'evaluateOnceInSpecificMomentModel') {
                this.ruleScheduling.oneShoot = true;
                this.ruleScheduling.executeRuleOnFirstRun = true;
                this.ruleScheduling.initialReferenceTime = Date.now();
            }
            if (this.ruleScheduling.executionSchedulingType === 'periodicallyFromSpecificMomentModel') {
                this.ruleScheduling.initialReferenceTime = Date.now();
            }
        },
        onSelectedTime(event) {
            this.ruleScheduling.initialReferenceTime = event.getTime();
            if (this.ruleScheduling.executionSchedulingType === 'evaluateOnceInSpecificMomentModel' && !this.validateDateTime()) {
                this.ruleScheduling.initialReferenceTime = Date.now();
                this.$root.showErrorNotification(this.$gettext("Triggering date must be in future!"), true);
            }
        },
        validateDateTime() {
            return this.ruleScheduling.initialReferenceTime > Date.now();
        },
        calculateTimestamps() {
            let schedulingPeriodType = '';
            let timestampToAdd = 0;
            this.schedulingExamples.clear();
            let repeats = 3;
            if (this.ruleScheduling.oneShoot)
                repeats = 1;
            // let startOnInitial = false;
            // if (this.ruleScheduling.executeRuleOnFirstRun)
            //     startOnInitial = true;

            switch (this.ruleScheduling.executionSchedulingType) {
                case "evaluatePeriodicallyModel":
                    schedulingPeriodType = this.getSchedulingPeriodType();
                    if (schedulingPeriodType === 'relative') {
                        timestampToAdd = this.calculateTimestampToAdd();
                        let timestamp = Date.now();
                        // if (!startOnInitial)
                        //     timestamp = timestamp + timestampToAdd ;
                        for (let i = 0; i < repeats; i++) {
                            this.schedulingExamples.push(timestamp);
                            timestamp = timestamp + timestampToAdd
                        }
                    }
                    if (schedulingPeriodType === 'absolute') {
                        let parser = require('cron-parser');
                        let cron = parser.parseExpression(this.ruleScheduling.cron);
                        for (let i = 0; i < repeats; i++)
                            this.schedulingExamples.push(cron.next()._date.ts);
                    }
                    break;
                case "evaluateOnceInSpecificMomentModel":
                    this.schedulingExamples.push(this.ruleScheduling.initialReferenceTime);
                    break;
                case "periodicallyFromSpecificMomentModel":
                    schedulingPeriodType = this.getSchedulingPeriodType();
                    if (schedulingPeriodType === 'relative') {
                        timestampToAdd = this.calculateTimestampToAdd();
                        let timestamp = this.ruleScheduling.initialReferenceTime;

                        while (this.schedulingExamples.length < repeats) {
                            if (timestamp >= Date.now())
                                this.schedulingExamples.push(timestamp);
                            timestamp = timestamp + timestampToAdd;
                        }
                        //if (!startOnInitial) { timestamp = timestamp + timestampToAdd }
                    }
                    if (schedulingPeriodType === 'absolute') {
                        let parser = require('cron-parser');
                        let cron = parser.parseExpression(this.ruleScheduling.cron);

                        while (this.schedulingExamples.length < repeats) {
                            let nextTick = cron.next()._date.ts;
                            if (this.ruleScheduling.initialReferenceTime < nextTick)
                                this.schedulingExamples.push(nextTick);
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        getSchedulingPeriodType() {
            if (this.ruleScheduling.cron.startsWith("interval"))
                return 'relative';
            return 'absolute';
        },
        calculateTimestampToAdd() {
            return Rules.getScheduleIntervalInMinutes(this.ruleScheduling.cron) * 60000
        },
        async valueChanged(groupName, settingName, actionObj, subGroup) {
            let values = this.$utils.detach(this.schedulingSettings);
            let workHoursValue = [];
            for (const key in values.openingTimeSettings.settings.workHours.settings) {
                let whs = values.openingTimeSettings.settings.workHours.settings[key].value;
                workHoursValue.push(whs);
            }
            this.ruleScheduling.workHours = workHoursValue;
            let workdays = values.openingTimeSettings.settings.workDays.value;
            this.ruleScheduling.workDays = (Object.isUseful(workdays) ? workdays.sort() : [])
            this.ruleScheduling.notWorkingDaysOfYear = values.openingTimeSettings.settings.notWorkingDaysOfYear.value;
        }
    }
}
</script>

<style scoped>

</style>
