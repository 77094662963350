<template>
    <DynamicElementBase>
        <v-layout row fill-height>
            <v-flex :xs5="!showRanking" :xs6="showRanking">
                <v-layout column fill-height>
                    <div style="position: relative; width: 100%; height: 100%">
                        <BarChart ref="graph" style="width: 100%; height: 100%" v-if="loaded" :chartdata="pareto.chartData" :options="options"></BarChart>
                    </div>
                    <!--<v-checkbox v-if="!alwaysShowRanking && !alwaysShowSurvey" class="ml-4" v-model="showRanking" label="Show ranking" @change="buildPareto"/>-->
                </v-layout>
            </v-flex>
            <v-flex :xs7="!showRanking" :xs6="showRanking">
                <v-layout column fill-height ml-2>
                    <div v-if="!showRanking" style="overflow: auto; margin-top: 15px;">
                        <v-treeview ref="tree1" class="subheading font-weight-bold labelsHeadersStyle" :items="standardTimeTracking" item-text="show">
                            <template v-slot:label="{item }" >
                                <label v-if="item.show" :style="'color: ' + item.color">
                                    {{item.show}}
                                </label>
                            </template>
                            <template v-slot:append="{ item }">
                                <label v-if="item.show" class="v-treeview-node__label font-weight-regular labelsHeadersStyle">
                                    : {{ item.duration !== 0 ? formatItem(item) : "" }}
                                </label>
                            </template>
                        </v-treeview>
                        <v-treeview ref="tree2" class="subheading font-weight-bold labelsHeadersStyle" :items="customTimeTracking" item-text="show">
                            <template v-slot:prepend="{ item }">
                                <av-icon v-if="item.show && Array.isUseful(item.children)">fa fa-chart-bar</av-icon>
                                <av-icon v-if="item.show && Array.isUseful(item.children)" style="margin-left: 2px" @click="selectForGraph(item)">
                                    {{ item.selectedForGraph ? 'far fa-check-square' : 'far fa-square' }}
                                </av-icon>
                            </template>
                            <template v-slot:label="{item}">
                                <label v-if="item.show" :style="'color: ' + item.color + ';' + (Array.isUseful(item.children) ? '' : 'margin-left: 37px;')">
                                    {{item.show}}
                                </label>
                            </template>
                            <template v-slot:append="{ item }">
                                <label v-if="item.show" class="v-treeview-node__label font-weight-regular labelsHeadersStyle">
                                    : {{ item.duration !== 0 ? formatItem(item) : "" }}
                                </label>
                            </template>
                        </v-treeview>
                    </div>
                    <div v-else style="overflow: auto; margin-top: 15px;">
                        <v-layout column>
                            <template v-for="item in ranking">
                                <label class="subheading font-weight-medium"><span :style="'color: ' + item.color + ';'">{{item.category}}</span><span class="labelsHeadersStyle font-weight-regular">: {{ item.value !== 0 ? formatItem(item) : "" }}</span></label>
                            </template>
                        </v-layout>
                    </div>
                    <!--<v-btn v-if="$grants.get().operations.timeTrackingChange && showButton" style="margin-top: 20px" @click="openTimeTrackingDialog"><translate>Change time tracking</translate><v-icon style="margin-left: 10px">fas fa-exchange-alt</v-icon></v-btn>-->
                </v-layout>
            </v-flex>
        </v-layout>
        <template slot="dropMenuItems">
            <v-list-tile>
                <v-list-tile-content>
                    <v-checkbox v-if="!alwaysShowRanking && !alwaysShowSurvey" class="mt-2" v-model="showRanking" label="Show ranking" @change="buildPareto"/>
                </v-list-tile-content>
            </v-list-tile>
        </template>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
    import BarChart from '@/components/graphics/BarChart.vue'
    import ColorSequence from '@/api/colorsequences'
    import DataApis from '@/api/data'
    import DateTime from '@/api/datetimeutils'

    export default {
        name: 'WidgetTimeTracking',
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            BarChart
        },
        data () {
            return {
                configurableCategoriesOffset: 0,
                standardTimeTracking: [],
                customTimeTracking: [],
                ranking: [],
                pareto: null,
                loaded: false,
                showRanking: false,
                alwaysShowRanking: false,
                alwaysShowSurvey: false,
                includeInactive: false,
                selectedNode: null,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false,
                    elements: {
                        line: {
                            tension: 0
                        }
                    },
                    propagate: false,
                    plugins: {
                        legend: {
                            display: false,
                            position: "top"
                        },
                        datalabels: {
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            type: "category",
                            grid: {
                                display: true
                            }
                        },
                        y: {
                            max: undefined,
                            ticks: {
                            }
                        }
                    },
                },
            }
        },
        props: {
        },
        mounted: function() {
            this.properties.timeWindow.setPredefined(10080);
            this.properties.forcedDataRefreshInterval = 10;
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], true));
            this.dataExplorationMode.autoDataSourcing = true;
            this.dataExplorationMode.deviceSelectMode = false;
            this.showTitleBar = true;
            this.loadTimeTrackingCategories();
            this.childHandlers.onElementLoaded.push(this.checkVisualizationOptions);

            this.visualizationTweaks = [
                {
                    name: this.$gettext("Automatic runtime datasource"),
                    id: "AutomaticRuntimeDatasource",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                },
                {
                    name: this.$gettext("Always show categories ranking"),
                    id: "ShowRanking",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                },
                {
                    name: this.$gettext("Always show categories survey"),
                    id: "ShowSurvey",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                },
                {
                    name: this.$gettext("Show ranking as default"),
                    id: "ShowRankingDefault",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                }
            ]
        },
        computed: {
            indices() {
                return this.properties.dataPatterns;
            }
        },
        watch: {
            indices() {
                if(!this.loading)
                    this.createDataItems();
            },
            visualizationTweaks: {
                handler: function () {
                    // this.showButton = this.visualizationTweaks[0].value;
                    //Update visualization tweaks values and save widget
                    let alwaysShowRanking = this.getTweakValue("ShowRanking");
                    let alwaysShowSurvey = this.getTweakValue("ShowSurvey");
                    if(alwaysShowRanking && (alwaysShowRanking !== this.alwaysShowRanking))
                        this.getTweak("ShowSurvey").value = false;
                    if(alwaysShowSurvey && (alwaysShowSurvey !== this.alwaysShowSurvey))
                        this.getTweak("ShowRanking").value = false;
                    //Morph interface to show ranking
                    this.showRanking = alwaysShowRanking;
                    //Immediately switch graph on option change
                    if(this.alwaysShowRanking !== alwaysShowRanking || this.alwaysShowSurvey !== alwaysShowSurvey)
                        this.buildPareto();
                    //Save state for next change
                    this.alwaysShowRanking = alwaysShowRanking;
                    this.alwaysShowSurvey = alwaysShowSurvey;
                    this.showRanking = this.getTweakValue("ShowRankingDefault");
                    this.saveTweaks();
                    if (this.getTweakValue("AutomaticRuntimeDatasource")) {
                        this.loadIndices();
                    }
                },
                deep: true,
            }
        },
        methods: {
            //In late 2.0 was introduced visualization option to choose whether to show
            //inactive stop causes or not. By default, backend includes them while an undefined
            //visualization option will cause widget to load stop causes without inactive.
            //This would cause weird results, thus we ensure that visualization options are initialized
            checkVisualizationOptions() {
                if(Array.isUseful(this.dataItems))
                    for(let item of this.dataItems)
                        if(this.$defines.isWellKnownItem(item, this.$defines.WellKnownItems.TimeTracking))
                            for(let representation of item.representations)
                                if(!Object.isNestedPropertyUseful(representation, "visualizationOptions", "includeInactiveResults"))
                                    this.$set(representation, "visualizationOptions", {includeInactiveResults: false});
            },
            loadIndices() {
                let self = this;
                DataApis.loadDataDefinitions()
                    .then(data => {
                        let indicesTmp = DataApis.selectIndexesByPatterns(data, self.dataExplorationMode.compatibleDataPatterns).multiSelect.map((x) => x.index);
                        //Replacing line description with wild card * for query on all lines
                        let lineDescription = indicesTmp[0].split('~|~')[2];
                        indicesTmp[0] = indicesTmp[0].replace(lineDescription, '*');
                        self.properties.dataPatterns = indicesTmp;
                    })
                    .catch(t => {
                        console.log(t);
                    });
            },

            selectForGraph(item) {
                item.selectedForGraph = !item.selectedForGraph;
                let selection = item.selectedForGraph;
                //We have an item selected for subcategories visualization, unselect all other.
                //Quickest way is to unselect all and reselect only current item
                if(selection) {
                    for(let category of this.customTimeTracking) {
                        category.selectedForGraph = false;
                        if(category.children) {
                            category.selectedForGraph = false;
                            for(let subCategory of category.children) {
                                subCategory.selectedForGraph = false;
                                if(subCategory.children) {
                                    subCategory.selectedForGraph = false;
                                    for(let subSubCategory of subCategory.children) {
                                        subSubCategory.selectedForGraph = false;
                                        if(subSubCategory.children)
                                            subSubCategory.selectedForGraph = false;
                                    }
                                }
                            }
                        }
                    }
                }
                item.selectedForGraph = selection;
                this.selectedNode = selection ? item.children : null;
                this.colorize();
                this.buildPareto();
            },
            colorize() {
                //First decolorize everything
                for(let category of this.standardTimeTracking)
                    category.color = "#000000";

                for(let category of this.customTimeTracking) {
                    category.color = "#000000";
                    if(category.children)
                        for(let subCategory of category.children) {
                            subCategory.color = "#000000";
                            if(subCategory.children)
                                for(let subSubCategory of subCategory.children) {
                                    subSubCategory.color = "#000000";
                                    if(subSubCategory.children)
                                        for(let subSubSubCategory of subSubCategory.children)
                                            subSubSubCategory.color = "#000000";
                                }

                        }
                }

                let nodeToColorize = this.selectedNode;
                //If we have no subcategory selected, colorize main categories
                if(!nodeToColorize) {
                    this.standardTimeTracking[3].color = this.$avStyle.colors.red;  //Keep uncategorized stops as red since they are the most critical
                    this.standardTimeTracking[2].color = this.$avStyle.colors.green;  //Keep production as green
                    this.standardTimeTracking[1].color = "#dddddd";  //Keep idle grey
                    nodeToColorize = this.customTimeTracking;
                }

                //Colorize current categories
                let colors = ColorSequence.getColors(nodeToColorize.length, ColorSequence.palettes.rainbow);
                for(let [index, category] of nodeToColorize.entries())
                    category.color = colors[index];
                this.$refs.tree1.updateAll();
                this.$refs.tree2.updateAll();
            },

            formatItem(item) {
                let label = DateTime.formatTime(item.duration, false, false);
                if(item.eventsCount) {
                    label = "tot: " + label;
                    label += " #: " + item.eventsCount;
                    label += " avg: " + DateTime.formatTime(item.averageDuration, false, false);
                }
                return label;
            },

            initNode(node, color = "") {
                node.duration = 0;
                node.eventsCount = 0;
                node.averageDuration = 0;
                if(color)
                    node.color = color;
            },

            loadTimeTrackingCategories() {

                this.standardTimeTracking.clear();
                this.customTimeTracking.clear();
                this.standardTimeTracking.push(
                    { show: this.$gettext("Total workorder duration"), duration: 0, color: "#000000"},
                    { show: this.$gettext("Idle time"), duration: 0, color: "#dddddd"},
                    { show: this.$gettext("Production"), duration: 0, color: this.$avStyle.colors.green},
                    { show: this.$gettext("Uncategorized stops"), duration: 0, color: this.$avStyle.colors.red},
                    { show: ""/*Not showing automatic change over items*/, duration: 0} );

                let categories = this.$timeTracking.getFilteredLineStopCauses(this.includeInactive);
                if (Object.isUseful(categories)) {
                    for(let category of categories) {
                        this.initNode(category, '#000000');
                        if (category.children) {
                            category.selectedForGraph = false;
                            for (let subCategory of category.children) {
                                this.initNode(subCategory, '#000000');
                                if (subCategory.children) {
                                    subCategory.selectedForGraph = false;
                                    for (let subSubCategory of subCategory.children) {
                                        this.initNode(subSubCategory, '#000000');
                                        if (subSubCategory.children) {
                                            subSubCategory.selectedForGraph = false;
                                            for (let subSubSubCategory of subSubCategory.children) {
                                                this.initNode(subSubSubCategory, '#000000');
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.customTimeTracking.push(category);
                    }
                }
                this.colorize();
            },
            createDataItems() {
                let trackingData = this.$timeTracking.getTimeTrackingStatsDataDescriptor(this.properties.dataPatterns);
                this.dataItems.clear();
                this.dataItems = trackingData.data;
                this.filterItems.clear();
                this.filterItems = trackingData.filters;
                this.aggregationItems.clear();
                this.$emit('dataItemsUpdated');
            },
            clearTimeTracking(container) {
                for(const item of container) {
                    item.duration = 0;
                    if(Array.isUseful(item.children))
                        for(const subitem of item.children) {
                            subitem.duration = 0;
                            if(Array.isUseful(subitem.children))
                                for(const subsubitem of subitem.children) {
                                    subsubitem.duration = 0;
                                    if(Array.isUseful(subsubitem.children))
                                        for(const subsubsubitem of subsubitem.children)
                                            subsubsubitem.duration = 0;
                                }
                        }
                }
            },
            refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view

                if (!Array.isUseful(dataValues))
                    return;

                this.clearTimeTracking(this.standardTimeTracking);
                this.clearTimeTracking(this.customTimeTracking);
                this.ranking.clear();

                for(const dataset of dataValues) {
                    if(Array.isUseful(dataset.data)) {
                        if(dataset.target === this.$timeTracking.targets.activity) {
                            let includeInactive = Object.isNestedPropertyUseful(dataset, "visualizationOptions", "includeInactiveResults") &&
                                dataset.visualizationOptions.includeInactiveResults;
                            if(includeInactive !== this.includeInactive) {
                                this.includeInactive = includeInactive;
                                this.loadTimeTrackingCategories();
                            }
                            for (const [index,item] of dataset.data.entries()) {
                                if(index < 4) {
                                    this.standardTimeTracking[index].duration = item.duration;
                                    this.standardTimeTracking[index].eventsCount = item.eventsCount;
                                    this.standardTimeTracking[index].averageDuration = item.averageDuration;
                                }
                                else
                                    this.assignDuration(item, this.customTimeTracking[index - 4]);
                            }
                        }
                        if(dataset.target === this.$timeTracking.targets.ranking) {
                            this.ranking = dataset.data;
                            //Colorize current categories
                            let colors = ColorSequence.getColors(this.ranking.length, ColorSequence.palettes.rainbow);
                            for(let [index, category] of this.ranking.entries())
                                category.color = colors[index];
                        }
                    }
                }
                this.buildPareto();
                this.loaded = true;
            },
            assignDuration(itemSource, itemTarget) {
                if(!itemTarget)
                    return;
                itemTarget.duration = itemSource.duration;
                itemTarget.eventsCount = itemSource.eventsCount;
                itemTarget.averageDuration = itemSource.averageDuration;
                if(Array.isUseful(itemSource.children) && Array.isUseful(itemTarget.children) && itemSource.children.length === itemTarget.children.length) {
                    for (const [index, item] of itemSource.children.entries()) {
                        itemTarget.children[index].duration = item.duration;
                        this.assignDuration(item, itemTarget.children[index]);
                    }
                }
            },
            buildPareto() {
                //Clear data collection
                let newDataCollection = {
                    chartData: {
                        labels: [""],
                        datasets: []
                    },
                };

                if (this.showRanking) {
                    this.ranking.forEach(function (dataSet, index) {
                        if (dataSet) {
                            newDataCollection.chartData.datasets.push({
                                label: dataSet.category,
                                data: [dataSet.value ? (dataSet.value / 60) : 0],
                                borderColor: dataSet.color,
                                backgroundColor: dataSet.color
                            });
                        }
                    });
                } else {
                    let unsortedCategories = [];
                    let sortedCategories = [];
                    if (this.standardTimeTracking === null || this.customTimeTracking === null) {
                        this.pareto = null;
                        return;
                    }

                    if (this.selectedNode) {
                        unsortedCategories = unsortedCategories.concat(this.selectedNode);
                    } else {
                        unsortedCategories.push(this.standardTimeTracking[2]);
                        unsortedCategories.push(this.standardTimeTracking[3]);
                        unsortedCategories = unsortedCategories.concat(this.customTimeTracking);
                    }

                    sortedCategories = unsortedCategories.sort(function (a, b) {
                        try {
                            if (!Object.isUseful(a.duration) || !Object.isUseful(b.duration))
                                return 0;
                            if (a.duration > b.duration)
                                return -1;
                            if (b.duration > a.duration)
                                return 1;
                            return 0;
                        } catch (err) {
                            return 0;
                        }
                    });

                    //Unwrap graph targeted data
                    sortedCategories.forEach(function (dataSet, index) {
                        if (dataSet) {
                            newDataCollection.chartData.datasets.push({
                                label: dataSet.show,
                                data: [dataSet.duration ? (dataSet.duration / 60) : 0],
                                borderColor: dataSet.color,
                                backgroundColor: dataSet.color
                            });
                        }
                    });
                }

                this.pareto = newDataCollection;

                if (this.$refs.graph) {
                    this.$nextTick(() => {
                        this.$refs.graph.refreshGraph();
                    })
                }
            },
            openTimeTrackingDialog() {
                this.$timeTracking.openTimeTrackingDialog(this, this.$root.userName, false);
            },
        }
    }
</script>

<style>

    .v-treeview-node__root {
        min-height: 0px;
    }

</style>

<style scoped>

    .redNode {
        color: #00b8d4;
    }
    .labelsStyle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 3px;
    }

    .labelsHeadersStyle  {
        color: black;
    }

</style>
