import Settings from '@/api/settings'

let StandardVariables = Object.freeze(
    {
        "GoodItems": "GoodItems",
        "RejectedItems": "RejectedItems",
        "Status": "Status",
        "TotalItems": "TotalItems"
    });

let aliasesDescriptors = new Map();
let configuredDevices = null;

function loadDescriptors() {
    configuredDevices = [];
    Settings.list(Settings.MachinesSettings)
        .then(devices => {
            for(let i = 0 ; i < devices.length ; i++) {
                if(devices[i].Name)
                    configuredDevices.push(devices[i].Name);
            }

            for (let device of configuredDevices) {
                Settings.load(Settings.MachinesSettings, device)
                    .then(aliasesDescriptor => {
                        aliasesDescriptors.set(device, aliasesDescriptor);
                    })
                    .catch(err => {
                        debugger
                    });
            }
        })
        .catch(err => {
            debugger;
        });
}

export default {
    StandardVariables: StandardVariables,    
    loadDescriptors: loadDescriptors,

    getAliasesDescriptors() {
        return aliasesDescriptors;
    },

    updateAliasesDescriptor(device, descriptor) {
        aliasesDescriptors.set(device, descriptor);
    },

    async getMachineAlias(device) {
        let result = "";
        let item = aliasesDescriptors.get(device);
        if (item)
            if (item.hasOwnProperty("uiAlias")) {
                result = item.uiAlias;
            }
        return  result;
    },

    async getMachinesAliasesObjects(devices) {
        let obj;
        let select = [];
        for(let i = 0 ; i < devices.length ; i++) {
            obj = await this.getMachineAliasObject(devices[i]);
            if(obj) {
                select.push(obj);
            }
        }
        return select;
    },
    async getMachineAliasObject(device) {
        let alias = await this.getMachineAlias(device);
        let obj;
        if(alias) {
            obj = {"text": alias, "value": device};
        } else {
            obj = {"text": device, "value": device};
        }
        return  obj;
    },
    async getVariableAlias(device, variable) {
        let result = "";
        let item = aliasesDescriptors.get(device);
        //debugger
        if (item)
            if (item.hasOwnProperty("variablesAliases")) {
                result = this.filterValue(item.variablesAliases, 'key', variable);
                if(Object.isUseful(result)) {
                    result = result.value;
                }
            }
        return  result;
    },

    async getStandardVariableName(device, variable) {
        let result = "";
        let item = aliasesDescriptors.get(device);
        if (item)
            if (item.hasOwnProperty("standardVariables")) {
                result = this.filterValue(item.standardVariables, 'key', variable);
                if(Object.isUseful(result)) {
                    result = result.value;
                }
            }
        return  result;
    },

    filterValue(obj, key, value) {
        return obj.find(function(v){ return v[key] === value});
    },
}