<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer"  class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card  class="ma-0 av-card" elevation="0">
            <v-card-text class="av-card-content">

        <v-layout row>
        <ImportExport class="ml-1 mt-0" :data="actions" fileName="LineStopCauses" :exportOnly="true" @fileLoaded="save($event,true)"/>
        <v-btn style="width: 50%"  :loading="loading"
                :disabled="loading" v-if="canDelete" color="info" @click="loader='loading'; checkDeletableStopCauses()">
            <av-icon left light>fas fa-sync</av-icon>
            <translate>Check deletable Stop Causes</translate>
            <template v-slot:loader>
                <span><translate>Checking deletable stop causes</translate></span><img :src="loaderGif" style="width:50px; height:50px;" />
            </template>
        </v-btn>
        </v-layout>
                <v-layout row ma-3>
                    <v-flex xs4>
                        <v-list>
                            <v-card>
                                <v-toolbar>
                                    <v-toolbar-title v-translate>Line stop causes categories</v-toolbar-title>
                                </v-toolbar>
                            </v-card>
                            <template v-for="(action, categoryIndex) in actions">
                                <v-divider />
                                <v-list-tile :key="action.id" :style="'height: 60px; ' + (category === categoryIndex ? 'background-color: var(--av-lightblue)' : '')">
                            <v-list-tile-action v-if="canDelete">
                                <av-icon v-if="action.deletable" @click="deleteItem(action,categoryIndex,actions,0)" :style="selectedItemStyle(action)" color="red">fas fa-trash</av-icon>
                            </v-list-tile-action>
                                    <v-list-tile-content @click="showFirstSub(action, categoryIndex)">
                                        <v-list-tile-title v-if="action.active" :style="selectedItemStyle(action)">{{action.show}}</v-list-tile-title>
                                        <v-list-tile-title v-else style="color: lightgrey"><strike>{{action.show}}</strike></v-list-tile-title>
                                        <v-list-tile-sub-title :style="action.active?selectedItemStyle(action):{color:'lightgrey'}">
                                            <v-menu offset-y transition="slide-y-transition" left v-if="action.active">
                                                <span slot="activator">
                                                    Standard: {{categorizeText(action)}}
                                                </span>
                                                <v-list >
                                                    <v-list-tile v-for="category in stopCausesCategories" @click="categorizeAction(action,category)"  style="cursor:pointer">
                                                        <v-list-tile-content >
                                                            <span class="context-menu-span">
                                                                {{category.text}}
                                                            </span>
                                                        </v-list-tile-content>
                                                    </v-list-tile>

                                                </v-list>
                                            </v-menu>
                                            <template v-else>
                                                Standard: {{categorizeText(action)}}
                                            </template>
                                        </v-list-tile-sub-title>
                                    </v-list-tile-content>
                                    <v-list-tile-action class="pr-1">

                                    </v-list-tile-action>
                            <v-list-tile-action v-if="canEdit">
                                        <v-layout ma-0 pt-1 row>
                                            <v-flex xs6>
                                                <v-layout column mt-1>
                                                    <av-icon @click="edit(action,actions)" :style="iconColor({action:action,c:'selectedItemStyle'})">fas fa-edit</av-icon>
                                                    <av-icon style="margin-top: 5px;" @click="disable(action, 0)" :style="iconColor({action:action,c:'selectedItemStyle'})">{{action.active ? 'fas fa-eye-slash' : 'fas fa-eye'}}</av-icon>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs6>
                                                <v-layout column mt-1>
                                                    <av-icon style="margin-left: 10px;" @click="move(actions, categoryIndex, 'up')" :style="iconColor({action:action,c:'selectedItemStyle'})">fas fa-arrow-circle-up</av-icon>
                                                    <av-icon style="margin-left: 10px; margin-top: 5px;" @click="move(actions, categoryIndex, 'down')" :style="iconColor({action:action,c:'selectedItemStyle'})">fas fa-arrow-circle-down</av-icon>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </v-list-tile-action>
                                    <v-list-tile-action style="min-width: 30px; padding-top: 12px;" @click="showFirstSub(action, categoryIndex)">
                                        <av-icon v-if="Array.isUseful(action.children)" :style="iconColor({action:action,c:'selectedItemStyle'})">fas fa-arrow-right</av-icon>
                                    </v-list-tile-action>
                                </v-list-tile>
                            </template>
                            <v-divider />
                        </v-list>
                        <v-btn v-if="canAdd" color="info" @click="addItem(actions)">
                            <av-icon left light>fas fa-plus-circle</av-icon>
                            <translate>Add item</translate>
                        </v-btn>
                    </v-flex>
                    <v-flex xs3 v-if="category >= 0">
                        <v-list>
                            <v-card>
                                <v-toolbar>
                                    <v-toolbar-title><translate>Subcauses of</translate> {{firstSubTitle}}</v-toolbar-title>
                                </v-toolbar>
                            </v-card>
                            <template v-for="(child, categoryIndex) in mainCategoryChildren">
                                <v-divider />
                                <v-list-tile :key="child.id" :style="'height: 60px; ' + (firstSubCategory === categoryIndex ? 'background-color: var(--av-lightblue)' : '')">
                            <v-list-tile-action v-if="canDelete">
                                <av-icon v-if="child.deletable" @click="deleteItem(child,categoryIndex,mainCategoryChildren,1)" :style="iconColor({action:child,c:'selectedSubItemStyle'})">fas fa-trash</av-icon>
                            </v-list-tile-action>
                                    <v-list-tile-content @click="showSecondSub(child, categoryIndex)">
                                        <v-list-tile-title v-if="child.active" :style="selectedSubItemStyle(child)">{{child.show}}</v-list-tile-title>
                                        <v-list-tile-title v-else style="color: lightgrey"><strike>{{child.show}}</strike></v-list-tile-title>
                                        <v-list-tile-sub-title :style="child.active?selectedSubItemStyle(child):{color:'lightgrey'}">
                                            <v-menu offset-y transition="slide-y-transition" left v-if="child.active">
                                                <span slot="activator">
                                                    Standard: {{categorizeText(child)}}
                                                </span>
                                                <v-list >
                                                    <v-list-tile v-for="category in stopCausesCategories" @click="categorizeAction(child,category)"  style="cursor:pointer">
                                                        <v-list-tile-content >
                                                            <span class="context-menu-span">
                                                                {{category.text}}
                                                            </span>
                                    </v-list-tile-content>
                                                    </v-list-tile>

                                                </v-list>
                                            </v-menu>
                                            <template v-else>
                                                Standard: {{categorizeText(child)}}
                                            </template>
                                        </v-list-tile-sub-title>
                                    </v-list-tile-content>
                            <v-list-tile-action style="min-width: 30px" v-if="canEdit">
                                        <v-layout ma-0 pt-1 row>
                                            <v-flex xs6>
                                                <v-layout column mt-1>
                                                    <av-icon @click="edit(child,mainCategoryChildren)" :style="iconColor({action:child,c:'selectedSubItemStyle'})">fas fa-edit</av-icon>
                                                    <av-icon style="margin-top: 5px;" @click="disable(child, 1)" :style="iconColor({action:child,c:'selectedSubItemStyle'})">{{child.active ? 'fas fa-eye-slash' : 'fas fa-eye'}}</av-icon>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs6>
                                                <v-layout column mt-1>
                                                    <av-icon style="margin-left: 10px;" @click="move(mainCategoryChildren, categoryIndex, 'up')" :style="iconColor({action:child,c:'selectedSubItemStyle'})">fas fa-arrow-circle-up</av-icon>
                                                    <av-icon style="margin-left: 10px; margin-top: 5px;" @click="move(mainCategoryChildren, categoryIndex, 'down')" :style="iconColor({action:child,c:'selectedSubItemStyle'})">fas fa-arrow-circle-down</av-icon>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </v-list-tile-action>
                                    <v-list-tile-action style="min-width: 30px; padding-top: 12px;" @click="showSecondSub(child, categoryIndex)">
                                        <av-icon v-if="Array.isUseful(child.children)" :style="iconColor({action:child,c:'selectedSubItemStyle'})">fas fa-arrow-right</av-icon>
                                    </v-list-tile-action>
                                </v-list-tile>
                            </template>
                            <v-divider />
                        </v-list>
                        <v-btn v-if="canAdd" color="info" @click="addItem(mainCategoryChildren)">
                            <av-icon left light>fas fa-plus-circle</av-icon>
                            <translate>Add item</translate>
                        </v-btn>
                    </v-flex>
                    <v-flex xs3 v-if="firstSubCategory >= 0">
                        <v-list>
                            <v-card>
                                <v-toolbar>
                                    <v-toolbar-title><translate>Subcauses of</translate> {{secondSubTitle}}</v-toolbar-title>
                                </v-toolbar>
                            </v-card>
                            <template v-for="(child, categoryIndex) in firstSubChildren">
                                <v-divider />
                                <v-list-tile :key="child.id" :style="'height: 60px; ' + (secondSubCategory === categoryIndex ? 'background-color: var(--av-lightblue)' : '')">
                                    <v-list-tile-action v-if="canDelete">
                                <av-icon v-if="child.deletable" @click="deleteItem(child,categoryIndex,firstSubChildren,2)" :style="iconColor({action:child,c:'selectedFirstSubItemStyle'})">fas fa-trash</av-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content @click="showThirdSub(child, categoryIndex)">
                                        <v-list-tile-title v-if="child.active" :style="selectedFirstSubItemStyle(child)">{{child.show}}</v-list-tile-title>
                                        <v-list-tile-title v-else style="color: lightgrey"><strike>{{child.show}}</strike></v-list-tile-title>
                                        <v-list-tile-sub-title  :style="child.active?selectedFirstSubItemStyle(child):{color:'lightgrey'}">
                                            <v-menu offset-y transition="slide-y-transition" left v-if="child.active">
                                                <span slot="activator">
                                                    Standard: {{categorizeText(child)}}
                                                </span>
                                                <v-list id="toolbar-dropdown-menu">
                                                    <v-list-tile v-for="category in stopCausesCategories" @click="categorizeAction(child,category)"  style="cursor:pointer">
                                                        <v-list-tile-content >
                                                            <span class="context-menu-span">
                                                                {{category.text}}
                                                            </span>
                                    </v-list-tile-content>
                                                    </v-list-tile>

                                                </v-list>
                                            </v-menu>
                                            <template v-else>
                                                Standard: {{categorizeText(child)}}
                                            </template>
                                        </v-list-tile-sub-title>
                                    </v-list-tile-content>
                            <v-list-tile-action style="min-width: 30px" v-if="canEdit">
                                        <v-layout ma-0 pt-1 row>
                                            <v-flex xs6>
                                                <v-layout column mt-1>
                                                    <av-icon @click="edit(child,firstSubChildren)" :style="iconColor({action:child,c:'selectedFirstSubItemStyle'})">fas fa-edit</av-icon>
                                                    <av-icon style="margin-top: 5px;" @click="disable(child, 2)" :style="iconColor({action:child,c:'selectedFirstSubItemStyle'})">{{child.active ? 'fas fa-eye-slash' : 'fas fa-eye'}}</av-icon>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs6>
                                                <v-layout column mt-1>
                                                    <av-icon style="margin-left: 10px;" @click="move(firstSubChildren, categoryIndex, 'up')" :style="iconColor({action:child,c:'selectedFirstSubItemStyle'})">fas fa-arrow-circle-up</av-icon>
                                                    <av-icon style="margin-left: 10px; margin-top: 5px;" @click="move(firstSubChildren, categoryIndex, 'down')" :style="iconColor({action:child,c:'selectedFirstSubItemStyle'})">fas fa-arrow-circle-down</av-icon>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </v-list-tile-action>
                                    <v-list-tile-action style="min-width: 30px; padding-top: 12px;" @click="showThirdSub(child, categoryIndex)">
                                        <av-icon v-if="Array.isUseful(child.children)" :style="iconColor({action:child,c:'selectedFirstSubItemStyle'})">fas fa-arrow-right</av-icon>
                                    </v-list-tile-action>
                                </v-list-tile>
                            </template>
                            <v-divider />
                        </v-list>
                        <v-btn v-if="canAdd" color="info" @click="addItem(firstSubChildren)">
                            <av-icon left light>fas fa-plus-circle</av-icon>
                            <translate>Add item</translate>
                        </v-btn>
                    </v-flex>
                    <v-flex xs2 v-if="secondSubCategory >= 0">
                        <v-list>
                            <v-card>
                                <v-toolbar>
                                    <v-toolbar-title><translate>Subcauses of</translate> {{thirdSubTitle}}</v-toolbar-title>
                                </v-toolbar>
                            </v-card>
                            <template v-for="(child, categoryIndex) in secondSubChildren">
                                <v-divider />
                                <v-list-tile :key="child.id" style="height: 60px;">
                            <v-list-tile-action v-if="canDelete" style="min-width:32px">
                                <av-icon v-if="child.deletable" @click="deleteItem(child,categoryIndex,secondSubChildren,3)" :style="iconColor({action:child})">fas fa-trash</av-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content class="pl-0">
                                        <v-list-tile-title v-if="child.active">{{child.show}}</v-list-tile-title>
                                        <v-list-tile-title v-else style="color: lightgrey"><strike>{{child.show}}</strike></v-list-tile-title>
                                        <v-list-tile-sub-title  :style="child.active?'':{color:'lightgrey'}">
                                            <v-menu offset-y transition="slide-y-transition" left v-if="child.active">
                                                <span slot="activator">
                                                    Standard: {{categorizeText(child)}}
                                                </span>
                                                <v-list >
                                                    <v-list-tile v-for="category in stopCausesCategories" @click="categorizeAction(child,category)"  style="cursor:pointer">
                                                        <v-list-tile-content >
                                                            <span class="context-menu-span">
                                                                {{category.text}}
                                                            </span>
                                                        </v-list-tile-content>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-menu>
                                            <template v-else>
                                                Standard: {{categorizeText(child)}}
                                            </template>
                                        </v-list-tile-sub-title>
                                    </v-list-tile-content>
                            <v-list-tile-action style="min-width: 30px" v-if="canEdit" >
                                        <v-layout ma-0 pt-1 row>
                                            <v-flex xs6>
                                                <v-layout column mt-1>
                                                    <av-icon @click="edit(child,secondSubChildren)" :style="iconColor({action:child})"  class="mt-1">fas fa-edit</av-icon>
                                                    <av-icon :style="iconColor({action:child})" @click="disable(child, 3)"  class="mt-1">{{child.active ? 'fas fa-eye-slash' : 'fas fa-eye'}}</av-icon>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs6>
                                                <v-layout column mt-1>
                                                    <av-icon  @click="move(secondSubChildren, categoryIndex, 'up')" :style="iconColor({action:child})"  class="mt-1 ml-2">fas fa-arrow-circle-up</av-icon>
                                                    <av-icon  @click="move(secondSubChildren, categoryIndex, 'down')" :style="iconColor({action:child})"  class="mt-1 ml-2">fas fa-arrow-circle-down</av-icon>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </v-list-tile-action>
                                </v-list-tile>
                            </template>
                            <v-divider />
                        </v-list>
                        <v-btn v-if="canAdd" color="info" @click="addItem(secondSubChildren)">
                            <av-icon left light>fas fa-plus-circle</av-icon>
                            <translate>Add item</translate>
                        </v-btn>
                    </v-flex>
                </v-layout>
                <av-input-box id="av-inputbox-alarms" ref="inputBox" :validator="categoryNameValidator"/>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import ImportExport from "@/components/utilities/ImportExport";

    export default {
        name: "LineStopActions",
        components: {ImportExport},
        data() {
            return {
                actions: [],
                mainCategoryChildren: [],
                firstSubChildren: [],
                secondSubChildren: [],
                category: -1,
                firstSubCategory: -1,
                secondSubCategory: -1,
                firstSubTitle: "",
                secondSubTitle: "",
                thirdSubTitle: "",
                upperLevelStopCauses: [],
                mergedLineStopCauses: [],
                newLineStopCauses: [],
                availableNewUpperLevelMaster: false,
                actionTrail: "",
                actionTrailId: "",
                nextValTrail: "",
                previousValTrail: "",
                imported: false,
                selectedCategoriesGroup: [],
                timeout: null,
                loadingCheckStopCauses: false,
                loader: null,
                loading: false,
                stopCausesCategories:[
                    {
                        value:-1,
                        text:"Not assigned",
                    },
                    {
                        value:1004,
                        text:"Change Over"
                    },
                    {
                        value:1005,
                        text:"Planned Maintenance"
                    },
                    {
                        value:1006,
                        text:"Unplanned Maintenance"
                    },
                    {
                        value:1007,
                        text:"Breaks & Meals"
                    },
                ],
            }
        },
        mounted: function () {
            this.loadConfig();
            this.resetAllDeletableStopCauses(this.actions);
            this.checkDeletableStopCauses();
        },
        beforeDestroy () {
            clearTimeout(this.timeout);
            this.timeout= null;
        },
        computed: {
            canEdit() {
                return this.$grants.get().settings.lineStopCausesEdit;
            },
            canAdd() {
                return (this.$config.deployment > 2 || this.$config.l3Collapsed) && this.canEdit;
            },
            canDelete() {
                return this.canAdd && this.$grants.get().settings.lineStopCausesDelete;
            },
            loaderGif() { return require('@/assets/avloader.png') },
            showLoader() { return this.loadingCheckStopCauses },
            categorizeText() {
                let self = this;
                return action => {
                    if(!self.stopCausesCategories.find(c => { return c.value === action.category })) {
                        return self.$gettext('Not assigned')
                    }
                    return (action.category && action.category > 0 ? self.stopCausesCategories.find( c=> { return c.value === action.category}).text: self.$gettext('Not assigned'))
                }
            },
            selectedItemStyle(){
                let self=this;
                return action=>{
                    return {
                        color:self.category>=0 && self.actions[self.category].id=== action.id? "#EEEEEE":""
                    }
                }
            },
            selectedSubItemStyle(){
                let self=this;
                return action=>{
                    return {
                        color:self.firstSubCategory>=0 && self.mainCategoryChildren[self.firstSubCategory].id=== action.id?"#EEEEEE":""
                    }
                }
            },
            selectedFirstSubItemStyle(){
                let self=this;
                return action=>{
                    return {
                        color:self.secondSubCategory>=0 && self.firstSubChildren[self.secondSubCategory].id=== action.id?"#EEEEEE":""
                    }
                }
            },
            iconColor(){
                let self=this;
                return obj=>{
                    return !obj.action.active?{color:"#EEEEEE"}:(self[obj.c]?self[obj.c](obj.action):'')
                }
            }
        },
        watch: {
            loader() {
                const l = this.loader;
                this[l] = !this[l];

                if(this[l])
                 this.loader = null;
                }
            },
        methods: {

            //TODO evaluate a more quick efficient query for a single item (stop category), used for delete item
            checkDeletableStopCauses() {
               let self = this;
                if(this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout= null;
                }
                return new Promise((resolve, reject) => {
                    self.$timeTracking.getAllStopCauses()
                        .then(allStopCauses => {
                            self.checkDeletableStopCausesRecursive(allStopCauses,self.actions);
                            resolve(allStopCauses)
                        })
                        .finally(()=>{
                            self.timeout = setTimeout(() => self.checkDeletableStopCauses(), 120000);
                            if(self.loading) {
                                setTimeout(() => {
                                    self.loading = false;
                                    self.loader = self.loading;
                                }, 3000);
                            }
                        });
                });
            },

            checkDeletableStopCausesRecursive(arr=[], arr2=[]) {
            // base case, to break the recursion when the array is empty
                if (!Array.isUseful(arr) || !Array.isUseful(arr2)) {
                    return [];
                }
                for(let i=0;i<=arr.length-1;i++) {
                    if(arr[i].hasOwnProperty("children") && arr2[i].hasOwnProperty("children"))
                        this.checkDeletableStopCausesRecursive(arr[i].children,arr2[i].children);
                    this.$set(arr2[i], "deletable", arr[i].duration === 0);
                }
             },

            // recursive function
            resetAllDeletableStopCauses(arr=[]) {
                // base case, to break the recursion when the array is empty
                if (!Array.isUseful(arr)) {
                    return;
                }
                for(let i=0;i<=arr.length-1;i++) {
                    if(arr[i].hasOwnProperty("children"))
                        this.resetAllDeletableStopCauses(arr[i].children);
                    this.$set(arr[i], "deletable", false);
                }
            },

            async loadConfig() {
                this.actions = await this.$timeTracking.getUpdatedStopCauses();
            },
            addItem(group) {
                this.selectedCategoriesGroup = group;
                let self = this;

                let newId = 0;
                for(newId = 1 ; newId <= group.length ; newId++) {
                    if(!Array.isUseful(group.filter(item => item.id === newId)))
                        break;
                }

                this.$refs.inputBox.ShowInputBox(this.$gettext("Enter new description"), "", "", this.$gettext("OK"),
                    function (value) {
                        self.newItem(group,newId,value);
                    },
                    this.$gettext("Cancel"), null
                );
            },
            newItem(group,newId,value){
                value = value.trim();
                if(value && this.getOccurrence(group,value) === 0) {
                    group.push({
                        id: newId,
                        show: value,
                        children: [],
                        active: true,
                        category:-1,
                        //Create a secondary id to allow understanding that stop cause has changed.
                        //This is needed because when stop causes are deleted, ids could be recycled.
                        //This allows L3 -> L2 merging algorithms to understand that a stop cause is changed
                        creationTag: this.$utils.timeTag(value, false)
                    });
                    this.$set(group[newId-1], "deletable", true);
                    //Audits
                    this.actionTrail = this.$audits.items().lineStopCausesAdded;
                    this.nextValTrail = group.slice(-1)[0].show;
                    this.save();
                }
            },
            categoryNameValidator(name) {
                if(this.getOccurrence(this.selectedCategoriesGroup,name)>0) {
                    let error1 = this.$gettext("Element name already used in DB, please choose a different one");
                    this.$root.showErrorNotification(error1, false, true);
                }
                return true
            },
            getOccurrence(array, value) {
                let len = array.filter((v) => (v.show === value)).length;
                return len;
                // return array.filter((v) => (v.value === value)).length;
            },
            resetView(level) {
                if(level > 2)
                    return;

                this.secondSubCategory = -1;
                this.thirdSubTitle = "";

                if(level < 2) {
                    this.firstSubCategory = -1;
                    this.secondSubTitle = "";
                }
                if(level < 1) {
                    this.category = -1;
                    this.firstSubTitle = "";
                }
            },
            showFirstSub(action, categoryIndex) {
                if(!action.active)
                    return;
                this.resetView(0);
                this.firstSubTitle = action.show;
                this.mainCategoryChildren = action.children;
                this.category = categoryIndex;
            },
            showSecondSub(action, categoryIndex) {
                if(!action.active)
                    return;
                this.resetView(1);
                this.secondSubTitle = action.show;
                this.firstSubChildren = action.children;
                this.firstSubCategory = categoryIndex;
            },
            showThirdSub(action, categoryIndex) {
                if(!action.active)
                    return;
                this.resetView(2);
                this.thirdSubTitle = action.show;
                if(!action.children)
                    action.children = [];
                this.secondSubChildren = action.children;
                this.secondSubCategory = categoryIndex;
            },
            edit(item,group) {
                let self = this;
                self.selectedCategoriesGroup = group;
                self.previousValTrail = item.show;
                this.$refs.inputBox.ShowInputBox(this.$gettext("Change description, for data integrity, it should describe the same category as before"), "", item.show, this.$gettext("OK"), function(value) {
                        if(value && self.getOccurrence(group,value) === 0) {
                    item.show = value;
                    self.actionTrail = self.$audits.items().lineStopCausesModified;
                    self.nextValTrail = value;
                    self.actionTrailId = "";
                            self.save();
                        }
                    }, this.$gettext("Cancel"), null);
            },
            deleteItem(item,index,group,resetLevel) {
                    let self = this;
                    item.deletable = false;

                    this.checkDeletableStopCauses()
                        .then(response => {
                            if(Array.isUseful(response) && item.deletable) {
                                this.$root.showDialogBox(this.$gettext("Are you sure you want to delete this category?"), null, this.$gettext("Yes"), function () {
                                    self.delete(item, index, group, resetLevel);
                                }, "CANCEL", null);
                            } else {
                                this.$root.showWarningNotification(this.$gettext("Impossible to delete the stop cause because it was used recently."), true);
                            }
                        });
            },
            delete(item,index,group,resetLevel) {
                    this.selectedCategoriesGroup = group;
                    this.previousValTrail = item.show;
                    this.actionTrail = this.$audits.items().lineStopCausesDeleted;
                    this.nextValTrail = "";
                    this.actionTrailId = "";
                    group.removeAt(index);
                    this.resetView(resetLevel);
                    this.save();
            },
            disable(item, resetLevel) {
                let self = this;
                if(item.active)
                    this.$root.showDialogBox(this.$gettext("Are you sure you want to disable this category and all of its subcategories? They will not appear anywhere in {appName} any more. You can reenable it later if necessary.").format({appName: this.$config.appName}), "",
                        this.$gettext("Yes"),
                        function() {
                            item.active = false;
                            self.actionTrail = self.$audits.items().lineStopCausesDisabled;
                            self.actionTrailId = item.show; self.previousValTrail =""; self.nextValTrail ="";
                            self.save();
                            self.resetView(resetLevel);
                        },
                        this.$gettext("No"), null);
                else {
                    item.active = true;
                    //Audits
                    self.actionTrail = self.$audits.items().lineStopCausesEnabled;
                    self.actionTrailId = item.show;
                    self.previousValTrail ="";
                    self.nextValTrail ="";
                    this.save();
                }
            },
            move(group, itemIndex, direction) {

                if((direction === 'up' && itemIndex === 0) || (direction === 'down' && itemIndex === group.length - 1))
                    return;

                //Audits
                // this.actionTrail = direction ==='up' ? this.$audits.items().lineStopCausesMovedUp : this.$audits.items().lineStopCausesMovedDown;
                this.actionTrailId = "";

                if(direction === 'up') {
                    this.previousValTrail = [itemIndex  ,group[itemIndex -1].show, itemIndex + 1, group[itemIndex].show];
                    group.moveItem(itemIndex, itemIndex - 1);
                    this.nextValTrail = [itemIndex ,group[itemIndex -1].show, itemIndex + 1, group[itemIndex].show];
                    this.actionTrail = this.$audits.items().lineStopCausesPositionChanged;
                }
                else {
                    this.previousValTrail = [itemIndex  + 1 ,group[itemIndex].show, itemIndex + 2, group[itemIndex +1].show];
                    group.moveItem(itemIndex, itemIndex + 1);
                    this.nextValTrail = [itemIndex + 1, group[itemIndex].show, itemIndex + 2, group[itemIndex + 1].show];
                    this.actionTrail = this.$audits.items().lineStopCausesPositionChanged;
                }
                this.save();
            },

            save(data, imported) {
                let self = this;
                if (data) {
                    this.actions.clear();
                    this.resetView(0);
                    for (let i = 0; i < data.length; i++) {
                        this.actions.push(data[i])
                    }
                }

                if (imported){
                    //Audits
                    self.actionTrail = self.$audits.items().lineStopCausesImported;
                    self.actionTrailId = "";
                    self.previousValTrail = "";
                    self.nextValTrail = "";
                    imported = false;
                }

                let tmpActions = this.$utils.detach(this.actions);

                this.resetAllDeletableStopCauses(tmpActions);

                this.$settings.saveLineStopCauses(tmpActions, this.$config.deployment)
                    .then(() => {
                        self.$audits.save(this.$root.userName, self.actionTrail, self.previousValTrail, self.nextValTrail, self.actionTrailId)
                            .catch(err => {
                                debugger;
                                console.error(err);
                                self.$root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .catch(err => {
                        console.error(err);
                        this.$root.showErrorNotification(this.$gettext("An error occurred while saving settings to DB"), true);
                    })
            },
            categorizeAction(action,category) {
                //if (action.category !== category.value){
                    this.$root.showDialogBox("Are you sure you want to change system category? This will be automatically applied also to all children categories", "", "YES", ()=>{
                        this.setStandardCategory(action,category)
                    }, "NO", null)
                // } else {
                //     this.setStandardCategory(action,category)
                // }
            },
            setStandardCategory(action, category) {
                //Audits
                this.actionTrail = this.$audits.items().lineStopCausesStandardized;
                this.actionTrailId = action.show;
                this.previousValTrail = this.categorizeText(action);
                this.nextValTrail = category.text;
                action.category = category.value;
                if (Array.isUseful(action.children))
                    this.setStandardCategoryRecursive(action.children, category);
                this.save();
            },
            setStandardCategoryRecursive(actions, category) {
                for(let action of actions) {
                    // if (action.category !== category.value && action.category > 0) {
                    //     self.$root.showDialogBox("Do you want change standard category for " + action.show + "?", "", "YES", () => {
                    //         action.category = category.value;
                    //         if (Array.isUseful(action.children)) {
                    //             self.setStandardCategoryRecursive(action.children, category)
                    //         }
                    //     }, "NO", null)
                    // } else {
                        action.category = category.value;
                        if (Array.isUseful(action.children)) {
                            this.setStandardCategoryRecursive(action.children, category)
                        }
                    //}
                }
            }
        },
    }
</script>