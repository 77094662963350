<template>
    <DynamicElementBase>
        <template slot="titleButtons">
            <v-menu  :close-on-click="true" :close-on-content-click="false" offset-y
                     transition="slide-y-transition" left>
                <v-btn slot="activator" fab small class="btn">
                    <v-badge style="font-size: inherit;" v-if="Array.isUseful(filters)" color="grey lighten-1">
                        <template v-slot:badge>
                            <span>{{ filters.length }}</span>
                        </template>
                        <av-icon style="font-size: inherit;">fas fa-filter</av-icon>
                    </v-badge>
                    <av-icon size="20" v-if="!Array.isUseful(filters)">fas fa-filter fa-5x</av-icon>
                </v-btn>
                <v-list class="ma-2">
                    <v-list-tile>
                        <v-list-tile-content>
                            <span class="title">Filter events to show:</span>
                        </v-list-tile-content>
                    </v-list-tile>
                    <hr/>
                    <v-autocomplete
                        v-model="filters"
                        :items="eventTypes"
                        chips
                        deletable-chips
                        item-text="show"
                        item-value="value"
                        multiple
                        class="pa-2"
                        style="width: 400px"
                        :search-input.sync="searchEventTypes"
                    >
                    </v-autocomplete>
                </v-list>
            </v-menu>
        </template>
        <v-layout wrap row style="height: 100%; width:100%; overflow: auto;" id="visualizer">
            <v-flex xs12 style="overflow:auto;">
                <template>
                    <v-timeline>
                        <v-timeline-item
                            v-for="(event, id) in listOfEvents"
                            :key="id"
                            class="mb-3 mr-4"
                            color="pink"
                            small
                            right
                        >
                            <template v-slot:opposite>
                                <label class="subheading">Timestamp: </label>
                                <label class="subheading font-weight-bold">{{ event.Timestamp }}</label>
                                <br>
                                <label class="subheading">Operator: </label>
                                <label class="subheading font-weight-bold">{{ event.Operator }}</label>
                            </template>
                            <v-card style="max-width: 500px; overflow: auto;">
                                <v-card-title  class="justify-start" :style="'backgroundColor: ' + event.color">
                                    <label class="subheading font-weight-bold text-xs-left" :style="{color: $avStyle.whiteTextNeeded(event.color) ? 'white' : ''}">{{ event.Action }}</label>
                                    <v-icon dark size="42">mdi-home-outline</v-icon>
                                </v-card-title>
                                <v-container>
                                    <div v-for="(value, key, id) in event">
                                        <div style="text-align: left"
                                             v-if="hiddenDataInCards(key, value)">
                                            <label class="subheading">{{ getEventPropertyValue(key) }}: </label>
                                            <label class="subheading font-weight-bold">{{ value }}</label>
                                        </div>
                                    </div>
                                </v-container>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </template>
            </v-flex>
        </v-layout>
    </DynamicElementBase>
</template>

<script>

import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
import ColorSequence from "@/api/colorsequences";


export default {
    name: "EventTimelineWidget",
    extends: DynamicElementBase,
    components: {
        DynamicElementBase
    },
    data() {
        return {
            filters: [],
            savedFilters: [],
            listOfEvents: [],
            searchEventTypes: '',
            mapping: [],
            stateChanged: false,
            colorActionValues: {}
        }
    },
    created() {
        this.childHandlers.getCustomDescriptor = () => {
            return this.filters
        };
        this.childHandlers.setCustomDescriptor = (filters) => {
            this.savedFilters = filters
            this.filters = filters
        };
    },
    mounted() {
        this.dataExplorationMode.autoDataSourcing = true;
        this.dataExplorationMode.requiresDataExploration = false;
        this.childHandlers.checkItemUsefulness = () => { return true; };
        this.childHandlers.onDataItemsChanged = this.onDataItemsChanged;
        this.getDataDefinitions()

        this.visualizationTweaks = [
            {
                name: this.$gettext("Automatic runtime datasource"),
                id: "AutomaticRuntimeDatasource",
                type: "bool",
                default: function() {
                    return false;
                }
            },
            {
                name: this.$gettext("Apply selected filters statically"),
                id: "fixedFilters",
                type: "bool",
                default: function () {
                    return false;
                }
            }
        ];
    },
    watch: {
        visualizationTweaks: {
            handler: function () {
                if (this.getTweakValue("AutomaticRuntimeDatasource")){
                    this.stateChanged = true;
                    if (Array.isUseful(this.dataItems) || Array.isUseful(this.filterItems))
                        this.$root.showDialogBox(this.$gettext("Item is set to source data automatically, do you want to overwrite previous configuration?"),
                        null, this.$gettext("Yes"), this.loadEventsList, this.$gettext("No"), null);
                    else {
                        this.loadEventsList();
                    }
                }
                this.saveTweaks();

            },
            deep: true
        },
        filters: {
            handler: function () {
                this.loadEventsList();
                this.dataRefresh();
                if (this.editMode)
                    this.saveElement();
            },
            deep: true
        }
    },
    computed: {
        eventTypes() {
            let arrayOfItems = []
            let itemsProperties = this.$audits.itemsProperties()
            if (this.getTweakValue("fixedFilters") && !this.editMode && this.savedFilters){
                this.savedFilters.forEach( filters => {
                        arrayOfItems.push({show: itemsProperties[filters[0]].show, value: filters})
                });
                return arrayOfItems
            }
            else {
                Object.entries(itemsProperties).forEach(([property, value]) => {
                    let duplicateElement = false
                    const index = arrayOfItems.findIndex(object => {
                        return object.show === value.show;
                    });
                    if (index > 0) {
                        arrayOfItems[index].value.push(property);
                        duplicateElement = true
                    }
                    if (!duplicateElement)
                        arrayOfItems.push({show: value.show, value: [property]})
                })
                return arrayOfItems
            }
        },
    },
    methods: {
        hiddenDataInCards(key, value) {
            return key !== 'Timestamp' && key !== 'Operator' && key !== 'Action' && key !== 'color' && value !== ''
        },
        onDataItemsChanged() {
            if (this.stateChanged) {
                this.stateChanged = false;
            }
            else {
                this.setTweakValue("AutomaticRuntimeDatasource", false);
            }
        },
        refreshData(dataValues) {
            let data = this.$datalayer.showAsTable(dataValues)
            //get text labels
            let textLabels = []
            data.headers.forEach(item => {
                let labelObject = {}
                labelObject[item.value] = item.text
                textLabels.push(labelObject)
            })
            //get colors for cards
            let uniqueActions = data.values.filter((value, index, self) => self.findIndex((m) => m.Action === value.Action) === index);
            let colors = ColorSequence.getColors(uniqueActions.length, ColorSequence.palettes.rainbow);
            uniqueActions.forEach((item, i) => {
                let label = item.Action
                if (!this.colorActionValues[label])
                    this.colorActionValues[label] = colors[i]
            })

            //substitute new text labels with old values
            data.values.forEach(item => {
                textLabels.forEach((label) =>{
                    let objProperty = Object.keys(label)[0]
                    let newLabel = label[objProperty];
                    if(item[objProperty] && objProperty !== newLabel) {
                        let oldValue = item[objProperty];
                        item[newLabel] = oldValue
                        delete item[objProperty]
                    }
                    item.color = this.colorActionValues[item.Action]
                })
            });
            this.listOfEvents = data.values
        },
        getEventPropertyValue(value){
            let propertyValue = value.toLowerCase()
            let mappingValue = this.mapping.filter(item => propertyValue.startsWith(item.name.toLowerCase().split('.')[0]))
            if (Array.isUseful(mappingValue) && mappingValue[0].hasOwnProperty("displayName")) {
                return  mappingValue[0].displayName
            }
            else{
                return value
            }
        },
        flattenArray(deepArray) {
            if(!Array.isArray(deepArray))
                return

            const flatArray = deepArray.flat()
            const filteredArray = flatArray.filter(item => !!item)
            const uniqueArray = new Set(filteredArray)

            return [...uniqueArray]
        },
        remove (item) {
            const index = this.filters.indexOf(item.name)
            if (index >= 0) this.filters.splice(index, 1)
        },
        getDataDefinitions(forceReload = false) {
            let self = this;
            self.$datalayer.dataDefinitionsForIndex("audit_trails", forceReload)
                .then(mapping => {
                    this.mapping = mapping[0].children
                    if (forceReload)
                        self.$root.showInfoNotification(self.$gettext("Data definitions refreshed"), true);
                })
                .catch(t => {
                    debugger
                    console.error(t);
                    self.$root.showErrorNotification(self.$gettext("Error in retrieving data definitions from DB."), true);
                });
        },
        loadEventsList() {
            let filters = this.flattenArray(this.filters)
            let events = this.$audits.getDataDescriptor(filters)
            this.filterItems = events.filters;
            if (this.getTweakValue("AutomaticRuntimeDatasource")) {
                this.dataItems = events.data;
                this.aggregationItems = events.aggregations;
            }
            this.$emit('dataItemsUpdated');

        },
    }
}

</script>

<style scoped>

</style>
