import VueNativeSock from 'vue-native-websocket'

export default async ({ app, router, store, Vue }) => {
  Vue.use(VueNativeSock, 'ws://' + window.location.hostname + ':' + Vue.prototype.$serverPort() + '/ws', {
    store: store,
    format: 'json',
    connectManually: true,
    reconnection: false,
    reconnectionAttempts: 5,
    reconnectionDelay: 5000
  })
  Vue.prototype.$connectWebSocket = function () {
    let protocol = 'wss'
    if (window.location.protocol === 'http:') {
      protocol = 'ws'
    }
    let wsURL = protocol + ':' + Vue.prototype.$getBaseURL() + '/ws';
    console.log("web sockect connection: " + wsURL)
    this.$connect(wsURL + '?token=' + this.$store.state.shared.token)
  }
}
