<template>
    <v-container grid-list-md text-xs-center :class="!isEditingState?'page-container':''"
                 :pt-3="!isEditingState"
                 :pl-2="!isEditingState"
                 :pr-3="!isEditingState"
                 :pb-0="!isEditingState"
                 :pa-0="isEditingState"
                 :ma-0="isEditingState">
                <template v-if="isNewState">
                    <v-card v-if="!isEditingState" class="av-card" elevation="0" style="height: 80px;" data-qa-type="card" data-qa-name="new-state">
                            <v-layout row fill-height align-center>
                                <v-layout fill-height align-center justify-end class="mt-2">
                                    <v-btn color="error" id="backFromNewButton" @click="setOpenState" class="mr-4" data-qa-type="button" data-qa-name="back-from-new-state">
                                        <av-icon light left>fas fa-arrow-left fa-5x</av-icon>
                                        <translate>BACK</translate>
                                    </v-btn>
                                </v-layout>
                            </v-layout>
                    </v-card>
                    <v-card class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)" data-qa-type="card" data-qa-name="create-element">
                        <v-card-text class="av-card-content">
                                <ElementsCreator :elementTypes="elementTypes" v-on:createElement="createElement"/>
                        </v-card-text>
                    </v-card>
                </template>
                <template v-else-if="isOpenState">
                    <v-card class="mt-0 av-card" elevation="0" style="height: 80px;" data-qa-type="card" data-qa-name="toolbar-filter">
                        <v-layout row fill-height align-center justify-space-around>
                            <template v-if="error">
                                <av-icon size="30" color="error">fas fa-exclamation-triangle</av-icon>
                                <label class="subheading" style="color: var(--av-red)">{{error}}</label>
                            </template>
                            <v-layout v-else fill-height align-center class="mt-2">
                                <av-text-field class="ml-4" :label="$gettext('Filter by name')" v-model="filterByName" style="max-width: 400px" data-qa-type="input-text" data-qa-name="filter-name"></av-text-field>
                                <av-select class="ml-4 control" :label="$gettext('Filter by status')" v-model="statusSelected" :items="statusItems" multiple style="max-width: 250px" data-qa-type="input-select" data-qa-name="filter-status"></av-select>
                                <av-select class="ml-4 control" :label="$gettext('Filter by type')" v-model="typeSelected" :items="typesWithNames" :item-text="'name'" style="max-width: 250px" data-qa-type="input-select" data-qa-name="filter-type"></av-select>
                                <av-select class="ml-4 control" :label="$gettext('Filter by tag')" v-model="tagSelected" :items="tags" style="max-width: 250px" data-qa-type="input-select" data-qa-name="filter-tag"></av-select>
                            </v-layout>
                            <v-btn color="info" id="setNewStateBtn" @click="setNewState" class="mt-2 mr-3" data-qa-type="button" data-qa-name="set-new-state">
                                <av-icon light left>fas fa-plus fa-5x</av-icon>
                                {{newButton}}
                            </v-btn>
                        </v-layout>
                    </v-card>
                    <v-card class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)" data-qa-type="card" data-qa-name="load-element">
                        <v-card-text class="av-card-content">
                            <ElementsLoader :type="type" :filterByName="filterByName" :statusSelected="statusSelected" :typeSelected="typeSelected" v-on:OpenElement="openElement" v-on:CloneElement="cloneElement" v-on:error="onError($event)"
                                               v-on:TypesAndTagsLoaded="getTypesAndTags" :tagSelected="tagSelected"/>
                        </v-card-text>
                    </v-card>
                </template>
                <template v-else-if="isLoadingExternalItemState">
                    <av-loader loading="true" message="Loading item" minTime="2000"/>
                </template>
<!--                <template v-else-if="isRulesSchedulingState">-->
<!--                    <v-layout row justify-space-between class="sectionTitle">-->
<!--                        <label class="sectionTitle" v-translate>Rules scheduling</label>-->
<!--                        <v-btn color="error" id="backFromRulesSchedulerButton" @click="setBlankState">-->
<!--                            <av-icon light left>fas fa-arrow-left fa-5x</av-icon>-->
<!--                            <translate>BACK</translate>-->
<!--                        </v-btn>-->
<!--                    </v-layout>-->
<!--                    <div style="height:calc(100% - 80px); overflow-y: auto; overflow-x: hidden">-->
<!--                        <RulesScheduler v-on:OpenView="openView"/>-->
<!--                    </div>-->
<!--                </template>-->
        <template v-else>
            <av-split-pane v-if="needsPreview" style="max-height: calc(100% - 16px); min-height: calc(100% - 16px);" :min-percent='expandElement ? 100 : 20' :default-percent='expandElement ? 100 : 50' :fixed="expandElement" split="horizontal" data-qa-type="panel">
                <template slot="paneL">
                    <div ref="elementContainer" id="elementContainer" class="pl-1 pr-3 pb-1 full-size" style="overflow: hidden;" data-qa-type="container"/>
                </template>
                <template slot="paneR">
                    <PropertiesEditor class="ml-1 mr-2 mt-2" style="width: calc(100% - 22px); height: calc(100% - 5px)" v-if="loaded"
                                      :properties="currentElement.properties"
                                      :visualizationTweaks="currentElement.visualizationTweaks"
                                      :renamable="renamable"/>
                </template>
            </av-split-pane>
            <template v-else>
                <PropertiesEditor class="ml-1 mr-3 mt-0" style="max-height: calc(100% - 8px); min-height: calc(100% - 8px); width: calc(100% - 20px);" v-if="loaded" singleColumn
                                  :properties="currentElement.properties" :visualizationTweaks="currentElement.visualizationTweaks" :renamable="renamable"/>
            </template>
        </template>
    </v-container>
</template>

<script>

import ElementsCreator from "./ElementsCreator.vue";
import PropertiesEditor from '@/components/utilities/PropertiesEditor.vue'
import ElementsLoader from "./ElementsLoader";
import RulesScheduler from "@/components/dynamic-elements/rules/RulesScheduling"
import Mutex from "@/api/mutex"

let ComponentStates = Object.freeze({
        "Blank": 0,
        "NewElement": 1,
        "OpenElement": 2,
        "CloningElement": 3,
        "EditingElement": 4,
        "LoadingExternalItem": 5,
        "SchedulingRules": 6
    });

    export default {
        name: "ElementsComposer",
        components: {
            ElementsLoader,
            ElementsCreator,
            PropertiesEditor,
            RulesScheduler
        },
        props: {
            type: {
                default: null
            },
            expandElement: false,
            renamable: true,
            itemStatus: {
                default: null
            }
        },
        data() {
            return {
                typesWithNames: [],
                statusItems:  [
                    {text: this.$gettext('Active'), value: 'Active'},
                    {text: this.$gettext('Draft'), value: 'Draft'},
                ],
                statusSelected: ['Active', 'Draft'],
                typeSelected: "All",
                filterByName: "",
                componentState: ComponentStates.Blank,
                currentElement: null,
                currentElementType: "",
                loaded: false,
                defaultSelected: "Widgets",
                tagSelected: "All",
                tags: [],
                error: "",
                reportTypes: [
                    {
                        name: this.$gettext("Report View"),
                        description: this.$gettext("Shows data in a customizable table layout or a simple values list with icons. Layout and data can be fully customized"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.Report
                    },
                ],
                widgetTypes: [
                    {
                        name: this.$gettext("Reusable Query"),
                        description: this.$gettext("Allows definition of data queries to be reused in other widgets, reports or recalled by expernal systems"),
                        thumbnail: require('../../assets/widgets/query.png'),
                        type: this.$dynamicElements.Types.ReusableQuery,
                        modules: [],
                        visible: true
                    },
                    {
                        name: this.$gettext("Map"),
                        description: this.$gettext("Shows geopoints on a map."),
                        thumbnail: require('../../assets/widgets/oee.png'),
                        type: this.$dynamicElements.Types.Map,
                        modules: [],
                        visible: !this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("OEE Monitor"),
                        description: this.$gettext("Shows production line OEE. Automatically selects relevant data"),
                        thumbnail: require('../../assets/widgets/oee.png'),
                        type: this.$dynamicElements.Types.Oee,
                        modules: [this.$license.PerformanceModule],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Graph View"),
                        description: this.$gettext("Shows numerical data on a graph together with up to four textual data fields. Graph type and data can be fully customized"),
                        thumbnail: require('../../assets/widgets/graphandtextbot.png'),
                        type: this.$dynamicElements.Types.Graph
                    },
                    {
                        name: this.$gettext("Gauge View"),
                        description: this.$gettext("Shows a single numerical value on a configurable gauge."),
                        thumbnail: require('../../assets/widgets/gauge.png'),
                        type: this.$dynamicElements.Types.Gauge
                    },
                    {
                        name: this.$gettext("Data View"),
                        description: this.$gettext("Shows data in a customizable table layout or a simple values list with icons. Layout and data can be fully customized"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.DataTable
                    },
                    {
                        name: this.$gettext("Semaphore View"),
                        description: this.$gettext("Shows the results of a set of data queries with a three color semaphore and a status label. Data and conditions could be fully customized"),
                        thumbnail: require('../../assets/widgets/semaphore.png'),
                        type: this.$dynamicElements.Types.Semaphore
                    },
                    {
                        name: this.$gettext("Batch Schedule View"),
                        description: this.$gettext("Shows current workorder time schedule. Automatically selects relevant data"),
                        thumbnail: require('../../assets/widgets/schedule.png'),
                        type: this.$dynamicElements.Types.Schedule,
                        modules: [this.$license.PerformanceModule],
                        deployLevels: [2],
						visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Production trend"),
                        description: this.$gettext("Shows workorder production progress together with expected trend lines"),
                        thumbnail: require('../../assets/widgets/productiontrend.png'),
                        type: this.$dynamicElements.Types.ProductionTrend,
                        modules: [this.$license.PerformanceModule],
                        deployLevels: [2],
						visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Workorder info"),
                        description: this.$gettext("When added to a dashboard with global workorder filter shows information regarding selected workorder"),
                        thumbnail: require('../../assets/widgets/woinfo.png'),
                        type: this.$dynamicElements.Types.WoInfo,
                        deployLevels: [2],
						visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Shift information"),
                        description: this.$gettext("Shows information regarding current shift"),
                        thumbnail: require('../../assets/widgets/shift.png'),
                        type: this.$dynamicElements.Types.Shift,
                        deployLevels: [2],
						visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Alarms Log"),
                        description: this.$gettext("Shows a filterable log of alarms from the line"),
                        thumbnail: require('../../assets/widgets/alarmslog.png'),
                        type: this.$dynamicElements.Types.AlarmsLog,
                        deployLevels: [2],
						visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Plant capacity"),
                        description: this.$gettext("Shows number of active production lines to total lines count ratio"),
                        thumbnail: require('../../assets/widgets/plantcapacity.png'),
                        type: this.$dynamicElements.Types.Capacity,
                        modules: [this.$license.PerformanceModule],
                        visible: this.$config.isAvionics
                    },
                    // {
                    //     name: this.$gettext("Alarms statistics"),
                    //     description: this.$gettext("Shows number of active production lines to total lines count ratio"),
                    //     thumbnail: require('../assets/widgets/alarmsstat.png'),
                    //     type: this.$dynamicElements.Types.AlarmsStats
                    // },
                    // {
                    //     name: this.$gettext("Line information"),
                    //     description: this.$gettext("Shows information regarding current line status and allows changing production and time tracking"),
                    //     thumbnail: require('../assets/widgets/alarmsstat.png'),
                    //     type: this.$dynamicElements.Types.LineInfo
                    // },
                    {
                        name: this.$gettext("Time tracking"),
                        description: this.$gettext("Shows information regarding the distribution of stop time on configured categories"),
                        thumbnail: require('../../assets/widgets/timetracking.png'),
                        type: this.$dynamicElements.Types.TimeTracking,
                        modules: [this.$license.PerformanceModule],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Global filters"),
                        description: this.$gettext("When added to a dashboard enables changing time and data filters on all other widgets in the dashboard"),
                        thumbnail: require('../../assets/widgets/globalfilters.png'),
                        type: this.$dynamicElements.Types.Filters
                    },
                    {
                        name: this.$gettext("Synoptic"),
                        description: this.$gettext("Shows overall line status through configurable graphics"),
                        thumbnail: require('../../assets/widgets/synoptic.png'),
                        type: this.$dynamicElements.Types.Synoptic,
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Plant View"),
                        description: this.$gettext("Shows status of all production lines in a compact view. Automatically selects relevant data"),
                        thumbnail: require('../../assets/widgets/plant.png'),
                        type: this.$dynamicElements.Types.Plant,
                        modules: [this.$license.PerformanceModule],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Actions register"),
                        description: this.$gettext("Allows inspection of the data entry logs"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.ActionsRegister,
                        modules: [this.$license.QualityModule],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Reject statistics"),
                        description: this.$gettext("Shows distribution of rejections on machines and rejection causes"),
                        thumbnail: require('../../assets/widgets/alarmsstat.png'),
                        type: this.$dynamicElements.Types.RejectsStatistics,
                        modules: [this.$license.QualityModule],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Function button"),
                        description: this.$gettext("Allows pointing to specific customizable forms or functionalities"),
                        thumbnail: require('../../assets/widgets/query.png'),
                        type: this.$dynamicElements.Types.FunctionButton,
                        modules: [],
                        visible: this.$config.isAvionics,
                    },
                    {
                        name: this.$gettext("Line stops monitor"),
                        description: this.$gettext(""),
                        thumbnail: require('../../assets/widgets/query.png'),
                        type: this.$dynamicElements.Types.TtBackAnnotation,
                        modules: [this.$license.PerformanceModule],
                        visible: this.$config.isAvionics,
                    },
                    {
                        name: this.$gettext("Event Timeline"),
                        description: this.$gettext(""),
                        thumbnail: require('../../assets/widgets/query.png'),
                        type: this.$dynamicElements.Types.EventTimeline,
                        visible: this.$config.isAvionics,
                    }
                ],
                rulesTypes: [
                    {
                        name: this.$gettext("Activity"),
                        description: this.$gettext("Set rules for batch change events"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.RuleActivity,
                        visible: this.$config.isAvionics && this.$grants.get().activities.canCreateActivities
                    },
                    {
                        name: this.$gettext("Batch related events"),
                        description: this.$gettext("Set rules for batch change events"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.RuleBatch,
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Time tracking related events"),
                        description: this.$gettext("Set rules on duration of customizable phases such as line stops"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.RuleTimeTracking,
                        modules: [this.$license.PerformanceModule],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Dispatch report"),
                        description: this.$gettext("Exports and dispatches a report on a specific time base"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.RuleReport,
                    },
                    {
                        name: this.$gettext("Freely configurable rule"),
                        description: this.$gettext("Allows creating a custom rule from scratch"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.RuleFree,
                    },
                    {
                        name: this.$gettext("Create workorders from machine variables automatically"),
                        description: this.$gettext("Allows automatically opening workorders by polling variables in the system. This is useful if all information is available in machines or is static"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.RuleAutomaticWorkorder,
                        deployLevels: [2],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Transaction management"),
                        description: this.$gettext("Allows creating rules to be evaluated on incoming transactions"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.RuleTransaction,
                        visible: !this.$config.isAvionics
                    }
                ],
                entitiesTypes: [
                    {
                        name: this.$gettext("Empty Entity"),
                        description: this.$gettext("Configure an entity from scratch"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.FormEntity,
                        modules: [this.$license.QualityModule],
                        visible: false
                    },
                    {
                        name: this.$gettext("Basic Entity"),
                        description: this.$gettext("Useful to define reusable types or entities components. Can only be inherited and won't show in entities menu"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.FormBasicEntity,
                        modules: [this.$license.QualityModule],
                        visible: false
                    },
                    {
                        name: this.$gettext("Empty Transaction"),
                        description: this.$gettext("Configure an transaction from scratch"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.FormTransactions,
                        modules: [this.$license.QualityModule],
                        visible: false
                    },
                ],
                formsType: [
                    {
                        name: this.$gettext("Free form"),
                        description: this.$gettext("Configure a general purpose form to add production data or register actions"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.FormConfigurator,
                        modules: [this.$license.QualityModule],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Workorder registration form"),
                        description: this.$gettext("Configure a form whose output will be a new workorder registered in the system. It could be scheduled in rule engine to source data from machines variables"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.FormWorkorder,
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Asset registration form"),
                        description: this.$gettext("Configure a form to record asset details"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.FormAsset,
                        modules: [this.$license.MaintenanceModule],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Maintenance intervention registration form"),
                        description: this.$gettext("Configure a form to record maintenance intervention on an asset"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.FormMaintenance,
                        modules: [this.$license.MaintenanceModule],
                        visible: this.$config.isAvionics
                    },
                    {
                        name: this.$gettext("Machine process handling form"),
                        description: this.$gettext("Configure a form to handle process variables and phases for machines"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.FormMachineProcess,
                        modules: [this.$license.ManagerModule],
                        visible: this.$config.isAvionics && this.$config.experimental.machineProcesses
                    }
                ],
                recipeTypes:[
                    {
                        name: this.$gettext("Template Recipe"),
                        description: this.$gettext("Configure a general purpose form to add production data or register actions"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.TemplateRecipe,
                        modules: [this.$license.ManagerModule],
                        visible: this.$config.isAvionics && this.$grants.get().recipes.canEditTemplateRecipes
                    },
                    {
                        name: this.$gettext("Line Recipe"),
                        description: this.$gettext("Configure a general purpose form to add production data or register actions"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.LineRecipe,
                        modules: [this.$license.ManagerModule],
                        visible: this.$config.isAvionics && this.$grants.get().recipes.canEditLineRecipes
                    },
                    {
                        name: this.$gettext("Machine Recipe"),
                        description: this.$gettext("Configure a general purpose form to add production data or register actions"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.MachineRecipe,
                        modules: [this.$license.ManagerModule],
                        visible: this.$config.isAvionics && this.$grants.get().recipes.canEditMachineRecipes
                    },
                ],
                wizardTypes: [
                    {
                        name: this.$gettext("Wizard base"),
                        description: this.$gettext("Lorem Ipsum is simply dummy text of the printing and typesetting industry."),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.WizardBase
                    },
                    {
                        name: this.$gettext("Wizard base 2"),
                        description: this.$gettext("Lorem Ipsum is simply dummy text of the printing and typesetting industry."),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.WizardBase
                    },
                ],
            }
        },
        mounted() {
            if(this.$config.deployment === 2) {
                if(this.$config.demo) {
                    this.widgetTypes.push({
                        name: this.$gettext("Activities schedule"),
                        description: this.$gettext("Monitor and manage activities and maintenance"),
                        thumbnail: require('../../assets/widgets/datatable.png'),
                        type: this.$dynamicElements.Types.ActivitiesSchedule,
                        avionicsOnly: true
                    })
                }
            }
        },
        computed: {
            isBlankState() {
                return this.componentState === ComponentStates.Blank;
            },
            isNewState() {
                return this.componentState === ComponentStates.NewElement;
            },
            isOpenState() {
                return this.componentState === ComponentStates.OpenElement;
            },
            isCloneState() {
                return this.componentState === ComponentStates.CloningElement;
            },
            isEditingState() {
                return this.componentState === ComponentStates.EditingElement;
            },
            isLoadingExternalItemState() {
                return this.componentState === ComponentStates.LoadingExternalItem;
            },
            isRulesSchedulingState() {
                return this.componentState === ComponentStates.SchedulingRules;
            },
            elementTypes() {

                switch (this.type) {
                    case "rules":
                        return this.rulesTypes;
                    case "reports":
                        return this.reportTypes;
                    case "forms":
                        return !this.$config.options.forms.entities ? [...this.formsType,...this.entitiesTypes] : this.entitiesTypes;
                    case "recipes":
                        return this.recipeTypes;
                    case "wizards":
                        return this.wizardTypes;
                    default:
                        return this.widgetTypes;
                }
            },
            icon() {
                switch (this.type) {
                    case "rules":
                        return 'fas fa-project-diagram fa-10x';
                    case "reports":
                        return 'fas fa-file-excel fa-10x';
                    case "forms":
                        return 'fas fa-tasks fa-10x';
                    case "dashboards":
                        return 'fas fa-tachometer-alt fa-10x';
                    case "recipes":
                        return 'fas fa-book fa-10x';
                    default:
                        return 'fas fa-chart-line fa-10x';
                }
            },
            openButton() {
                switch (this.type) {
                    case "rules":
                        return this.$gettext("Open rule");
                    case "reports":
                        return this.$gettext("Open report");
                    case "forms":
                        return !this.$config.options.forms.entities ? this.$gettext("Open form") : this.$gettext("Open entity");
                    case "dashboards":
                        return this.$gettext("Open Dashboard");
                    case "recipes":
                        return this.$gettext("Open Recipe");
                    default:
                        return this.$gettext("Open data visualization");
                }
            },
            cloneButton() {
                switch (this.type) {
                    case "rules":
                        return this.$gettext("Clone existing rule");
                    case "reports":
                        return this.$gettext("Clone existing report");
                    case "forms":
                        return !this.$config.options.forms.entities ? this.$gettext("Clone existing form") : this.$gettext("Clone existing entity");
                    case "dashboards":
                        return this.$gettext("Clone Dashboard");
                    case "recipes":
                        return this.$gettext("Clone recipe");
                    default:
                        return this.$gettext("Clone existing visualization");
                }
            },
            scheduleButton() {
                return this.$gettext("Rules scheduling");
            },
            newButton() {
                switch (this.type) {
                    case "rules":
                        return this.$gettext("New rule");
                    case "reports":
                        return this.$gettext("New report");
                    case "forms":
                        return !this.$config.options.forms.entities ? this.$gettext("New form") : this.$gettext("New entity");
                    case "dashboards":
                        return this.$gettext("New Dashboard");
                    case "recipes":
                        return this.$gettext("New recipe");
                    case "wizards":
                        return this.$gettext("New wizard");
                    default:
                        return this.$gettext("New data visualization");
                }
            },
            needsPreview() {
                return this.currentElement && !this.currentElement.dataExplorationMode.noPreview
            }
        },
        beforeDestroy() {
            this.destroyElement()
        },
        methods: {
            onError(message) {
                this.error = message;
            },
            setBlankState() {
                this.statusSwitch(ComponentStates.Blank);
            },
            setNewState() {
                this.statusSwitch(ComponentStates.NewElement);
            },
            setOpenState() {
                this.error = "";
                this.statusSwitch(ComponentStates.OpenElement);
            },
            setCloneState() {
                this.statusSwitch(ComponentStates.CloningElement);
            },
            setEditingState() {
                this.statusSwitch(ComponentStates.EditingElement);
            },
            setLoadingExternalItemState() {
                this.statusSwitch(ComponentStates.LoadingExternalItem)
            },
            setRulesSchedulingState() {
                this.statusSwitch(ComponentStates.SchedulingRules);
            },

            statusSwitch(newStatus) {
                this.componentState = newStatus;
                this.destroyElement();
                this.loaded = false;
                this.$emit('NoLoadedElement', null);
            },

            destroyElement() {
                if(this.currentElement) {
                    this.currentElement.$destroy();
                    this.currentElement = null;
                }
            },
            createElement(element) {
                this.setEditingState();
                this.currentElementType = element.type;
                this.$nextTick(() => {
                    //Create element
                    this.currentElement = this.$dynamicElements.createNew(this.currentElementType, true);
                    if(!this.currentElement)
                        return; //License issue or huge error (unlikely)
                    this.currentElement.properties.type = element.type;
                    this.currentElement.newElement();
                    this.$nextTick(() => {
                        if (!this.currentElement.dataExplorationMode.noPreview)
                            this.$refs.elementContainer.appendChild(this.currentElement.$el);
                    });
                    this.loaded = true;
                    this.$emit('NewElementCreated', this.currentElement);
                })
            },
            async loadElement(itemName) {
                let self = this;
                this.$dynamicElements.LoadItem(itemName, this.type)
                    .then(item => {
                        self.openElement(JSON.parse(item.descriptor))
                    })
            },
            async openElement(descriptor) {

                //Check again lock before opening since from page load to item selection someone could have locked it in the meanwhile
                let lock = await Mutex.isItemLocked(this.type, descriptor.properties.name);
                if(lock) {
                    this.$root.showErrorNotification(this.$gettext("Requested entity is being already edited by {0}. Please try again later or request {0} to release it.").format(lock.user), true);
                    return;
                }
                this.setEditingState();
                this.currentElementType = descriptor.properties.type;
                this.$nextTick(() => {
                    //Create element
                    this.currentElement = this.$dynamicElements.createNew(this.currentElementType, true);
                    if(!this.currentElement)
                        return; //License issue or huge error (unlikely)
                    this.currentElement.load(descriptor);
                    this.$nextTick(() => {
                        if (!this.currentElement.dataExplorationMode.noPreview)
                            this.$refs.elementContainer.appendChild(this.currentElement.$el);
                    });
                    this.loaded = true;
                    this.$emit('SavedElementLoaded', this.currentElement);
                })
            },

            cloneElement(descriptor) {
                this.setEditingState();
                this.currentElementType = descriptor.properties.type;
                this.$nextTick(() => {
                    //Create element
                    this.currentElement = this.$dynamicElements.createNew(this.currentElementType, true);
                    if(!this.currentElement)
                        return; //License issue or huge error (unlikely)
                    let cloneId = 1;
                    while(cloneId < 1000) {
                        let nameValidation = this.$dynamicElements.validateName("Clone " + cloneId + " of " + descriptor.properties.name, this.type);
                        if(nameValidation.status)
                            break;
                        cloneId++;
                    }
                    if(cloneId === 1000)
                        return false;   //Extreme error, shouldn't happen but it's worth to add a timeout to while loop
                    descriptor.properties.name = "Clone " + cloneId + " of " + descriptor.properties.name;
                    if (this.currentElement.dataExplorationMode.requiresDoubleActivation)
                        descriptor.properties.deployStatus = -1;
                    else
                        descriptor.properties.deployStatus = 0;
                    descriptor.properties.version = 0;
                    this.currentElement.load(descriptor);
                    this.$nextTick(() => {
                        if (!this.currentElement.dataExplorationMode.noPreview)
                            this.$refs.elementContainer.appendChild(this.currentElement.$el);
                    });
                    this.loaded = true;
                    this.$emit('SavedElementLoaded', this.currentElement);
                    this.currentElement.saveKeepingVersion();
                })
            },
            getTypesAndTags(typesAndTags) {
                this.typesWithNames = typesAndTags.types;
                this.typesWithNames.unshift({name: 'All'})
                this.tags = typesAndTags.tags;
                this.tags.unshift('All')
            }
        }
    }
</script>

<style scoped>

    .overlayedIconsContainer {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        text-align:center;
    }

    .overlayedIconsCanvas {
        position: absolute;
        top: 62px;
        left: 130px;
    }

    .overlayedIconsOverlay {
        position: absolute;
        top: 70px;
        left: 137px;
    }

</style>
