<template>
    <div class="align-center containerProgress" >
        <div class="progress">
            <div v-bind:style="progressStatusStyle">{{progress >= 0 ? progress + '%' : ""}}</div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "av-progress",
        props:{
            value:{
                required:true,
                default:()=>{return 0}
            },
            target:{
                required:true,
                default:()=>{return 100}
            },
            floor:{
                required:false,
                default:()=>{return false}
            },
            invertColors: {
                required: false,
                default: false
            }
        },
        computed:{
            backgroundImage(){
                let grey = '-webkit-linear-gradient(left, #66BB6A ' + this.progress + '%, white 1%)';
                let yellow = '-webkit-linear-gradient(left, yellow ' + this.progress + '%, white 1%)';
                let red = '-webkit-linear-gradient(left, red ' + this.progress + '%, white 1%)';
                if (this.progress> 80)
                    return this.invertColors ? red : grey;
                else if (this.progress > 60)
                    return yellow;
                else
                    return this.invertColors ? grey : red;

            },
            progress(){
                let progress = !this.floor?((this.value/this.target)*100).toFixed(2): Math.floor((this.value/this.target)*100);
                if (progress>100)
                    return 100;
                return progress
            },
            progressStatusStyle(){
                return{
                    "text-align": "center",
                    "height": "20px",
                    "line-height":"20px",
                    "border-radius": "5px",
                    "border": "1px solid lightgray",
                    "background-image": this.backgroundImage
                }
            }
        }
    }
</script>

<style scoped>
    .containerProgress{
        width:100%;
        display:inline-block;
        white-space:nowrap;
    }
    .progress{
        width:100%;
        display:inline-block;
        vertical-align: middle;

    }

</style>