<template>
    <v-layout :row="horizontal" :column="!horizontal">
        <v-btn v-if="!importOnly" @click="exportFile">
            <av-icon color="blue" style="margin-right: 10px">fas fa-file</av-icon>
            <av-icon color="blue" style="margin-right: 10px">fas fa-arrow-right</av-icon>
            <translate v-if="textIconVisible">Export to file</translate>
        </v-btn>
        <v-btn v-if="!exportOnly" @click.native="openFileDialog">
            <av-icon color="blue" style="margin-right: 10px">fas fa-file</av-icon>
            <av-icon color="blue" style="margin-right: 10px">fas fa-arrow-left</av-icon>
            <translate v-if="textIconVisible">import from file</translate>
        </v-btn>
        <input type="file" :id="id" style="display:none" @change="importFile">
    </v-layout>
</template>

<script>
    export default {
        name: "ExportImport",
        data() {
            return {
                fr: null,
            }
        },
        props: {
            data: {
                default: null
            },
            fileName: {
                default: null
            },
            horizontal: Boolean,
            textIconVisible: {
                default: true
            },
            importOnly: {
                default: false
            },
            exportOnly: {
                default: false
            },
            textMode: {
                default: false
            }
        },
        computed: {
            id() {
                return 'file-upload' + this._uid;
            }
        },
        methods: {
            openFileDialog() {
                document.getElementById(this.id).click();
            },
            importFile(e) {
                let files = e.target.files || e.dataTransfer.files;

                if (files.length > 0) {
                    this.fr = new FileReader();

                    this.fr.onloadend = this.save;
                    this.fr.readAsText(files[0]);
                }
            },
            save() {
                try {
                    let res = "";
                    if(!this.textMode)
                        res = JSON.parse(this.fr.result);
                    else res = this.fr.result;
                    this.$emit('fileLoaded', res);
                } catch (e) {
                    debugger
                    console.error(e);
                    this.$root.showErrorNotification(this.$gettext("Unable to save file, please try again"), true);
                }
            },
            exportFile() {
                // let self = this;
                let downloadURI = function (uri, name) {
                    let link = document.createElement("a");

                    link.download = name;
                    link.href = uri;
                    link.style.display = "none";

                    document.body.appendChild(link);
                    if (typeof MouseEvent !== "undefined") {
                        link.dispatchEvent(new MouseEvent("click"));
                    } else {
                        link.click();
                    }
                    document.body.removeChild(link);
                };
                let file = "";
                if(!this.textMode)
                    file = JSON.stringify(this.data, null, 2);
                else file = this.data;
                let blob = new Blob([file], {type: "application/json"});
                let url = window.URL.createObjectURL(blob);
                let name = this.fileName;
                if(!name.endsWith(".json") && !this.textMode)
                    name += ".json";
                downloadURI(url, name );
                window.URL.revokeObjectURL(url);
            }
        }
    }
</script>

<style scoped>

</style>