<template>
    <v-layout column id="container" class="spreadsheet-container"  v-on:scroll="onScroll" :style="spreadSheetContainerStyle">
        <v-toolbar dense ref="toolbar" id="toolbar" :style="toolbarPosition" :flat="flatToolbar">
            <v-spacer></v-spacer>
            <v-btn flat icon @click="addRow()" small v-if="showAddButton">
                <av-icon small color="blue">fa-plus</av-icon>
            </v-btn>
            <v-btn flat icon @click="enableDisableEditingMode" small v-if="showEditButton">
                <av-icon small color="blue">fa-pen</av-icon>
            </v-btn>

            <ImportExportExcel v-if="!noImportExport"
                    :text-icon-visible="true"
                               :horizontal="true"
                               :headers="headersExport"
                               :data-collection="rowsExport"
                               :work-sheet="'Sheet'"
                               :file-name="fileName + (new Date()).format('yyyyMMddHHmmss')"
                               :flat-buttons="true"
                               :small-icons="true"
                               :include-in-layout="false"
                               :menu-bar="true"
                                @fileLoaded="rowsImported"/>
        </v-toolbar>
        <div class="table-container">
            <table class="v-datatable v-table theme--light content">
                <thead>
                <tr v-if="type==='custom'">
                    <th role="columnheader"
                        scope="col"
                        aria-sort="none"
                        class="column text-md-left" :colspan="headers.length" style="font-size: 1.5em;">
                        <slot></slot>
                    </th>
                </tr>
                <tr>
                    <th role="columnheader"
                        scope="col"
                        aria-sort="none"
                        class="column text-md-left" v-for="(header,index) in headers" :style="styleCell(index)">
                        {{header.text}}
                        <i aria-hidden="true"
                           class="v-icon material-icons theme--light"
                           :style="styleSorter(index)"
                           @click="sortRows(header.value,header.sorted==='up'?'down':'up')" v-if="header.sortable && !editMode">
                            arrow_{{header.sorted==='none'?'up':header.sorted}}ward
                        </i>
                    </th>
                    <th style="width: 20px" v-if="editMode && showDeleteButton"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,idx,k) in rows" :key="idx" style="height: 40px">
                    <td v-for="(header,key) in headers" :key="key" class="text-start" :style="styleCell(1,key) "
                        @click="onCellClick(idx,header.value)">
                        <template v-if="headerColumns[key].type.indexOf('button',0)>-1">
                            <v-btn fat icon @click="item[header.value].method(idx)" style="height: 30px;width: 30px">
                                <av-icon small :color="item[header.value].color">{{item[header.value].icon}}</av-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-layout row class="text-md-left" :style="key===0?'width:130px':''">
                                    <v-flex >

                                            <template v-if="!editMode || !headerColumns[key].editable">
                                                <span style="white-space: pre-line;">{{item[header.value]}}</span>
                                            </template>
                                            <template v-else>
                                                <v-text-field
                                                    class="osk"
                                                    v-model="item[header.value]"
                                                    label=""
                                                    single-line
                                                    v-if="headerColumns[key].type==='text'"
                                                    @change="onChangeValue(idx,header.value,$event)"
                                                ></v-text-field>
                                                <v-text-field
                                                    class="osk"
                                                    v-model.number="item[header.value]"
                                                    label=""
                                                    single-line
                                                    v-if="headerColumns[key].type==='number'"
                                                    type="number"
                                                    @change="onChangeValue(idx,header.value,$event)"
                                                ></v-text-field>
                                                <av-autocomplete
                                                    class="osk"
                                                    :value="item[header.value]"
                                                    :items="headerColumns[key].options"
                                                    v-if="headerColumns[key].type==='autocomplete'"
                                                    wildCardMatch="true"
                                                    style="min-width: 190px"
                                                    @change="(event)=>autoCompleteChange(header.value,idx,event)"
                                                ></av-autocomplete>

                                            </template>
                                    </v-flex>
                                    <v-flex xs2>
                                        <!--<v-icon small right color="grey lighten-1" @click.stop="dialog=true">fa-ellipsis-v</v-icon>-->
                                        <v-menu offset-y transition="slide-y-transition" left v-if="headerColumns[key].contextMenu"  >
                                            <v-btn slot="activator" flat icon small>
                                                <av-icon id="toolbar-dropdown" small color="blue">fa-ellipsis-v</av-icon>
                                            </v-btn>
                                            <v-list id="toolbar-dropdown-menu">
                                                <v-list-tile >
                                                    <v-list-tile-content >
                                                    <span @click="openDialog(item[header.value],idx,header.value)" class="context-menu-span">
                                                        <av-icon small left color="blue" >fa-calculator</av-icon >
                                                        <translate>
                                                            Copy To Column
                                                        </translate>
                                                    </span>
                                                    </v-list-tile-content>
                                                </v-list-tile>
                                                <v-list-tile>
                                                    <v-list-tile-content>
                                                    <span  @click="copyValueToRow(idx,item[header.value],header.value)" class="context-menu-span">
                                                        <av-icon small left color="blue">fa-arrows-alt-h</av-icon >
                                                        <translate>
                                                            Copy to entire row
                                                        </translate>
                                                    </span>
                                                    </v-list-tile-content>
                                                </v-list-tile><v-list-tile>
                                                <v-list-tile-content>
                                                    <span  @click="DeleteCellValue(idx,item[header.value],header.value)" class="context-menu-span">
                                                        <av-icon small left color="blue">fa-trash</av-icon >
                                                        <translate>
                                                            Delete Cell
                                                        </translate>
                                                    </span>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                            </v-list>
                                        </v-menu>
                                    </v-flex>
                            </v-layout>
                        </template>
                    </td>
                    <td v-if="editMode && showDeleteButton">
                        <v-btn fat icon @click="removeRow(idx)" style="height: 30px;width: 30px">
                            <av-icon small color="red">fa-trash</av-icon>
                        </v-btn>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <v-dialog
                v-model="dialog"
                max-width="390"
        >
            <v-card>
                <v-card-title class="headline">Copy To Column</v-card-title>
                <v-card-text>

                        <v-checkbox
                                v-model="dialogData.copyToAllColumns"
                                label="Copy to all columns"
                                @change="onCopyToAllColumnsClick"
                        ></v-checkbox>
                        <v-checkbox
                                v-model="dialogData.copyToAllRows"
                                label="All rows"
                        ></v-checkbox>
                        <v-select
                                v-model="dialogData.selectedColumns"
                                :items="dialogColumns"
                                :menu-props="{ maxHeight: '400' }"
                                label="Columns"
                                item-text="name"
                                item-value="key"
                                multiple
                                :disabled="dialogData.copyToAllColumns"
                                return-object
                                persistent-hint
                                prepend-icon="fa-columns" dense small
                        ></v-select>
                        <v-text-field
                                class="osk"
                                label="Formula" prepend-icon="fa-calculator"
                                v-model="dialogData.formula" dense small v-if="enabledFormula"
                        ></v-text-field>
                        <div v-else>
                            <h4>Value: </h4>{{dialogData.formula}}
                        </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="saveDialog">
                        <translate>
                            OK
                        </translate>
                    </v-btn>
                    <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat" @click="dialog=false">
                        <translate>
                            Cancel
                        </translate>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-layout>
</template>

<script>

    import ImportExportExcel from "@/components/utilities/ImportExportExcel";

    export default {
        name: "AvSpreadsheet",
        components: {ImportExportExcel},
        props: {
            headerColumns: {
                default: function () {
                    return [];
                }
            },
            widthColumn: {
                default: function () {
                    return "150px"
                },
            },
            rows: {
                default:
                    function () {
                        return [];
                    }
            },
            rawRows: {
                default:
                    function () {
                        return [];
                    }
            },
            disableColumns: [],
            fileName:{
                default:"Spreadsheet_"
            },
            flatToolbar:{
                default:false
            },
            maxSpreadsheetHeight:{
                default:600
            },
            type:{
                default:""
            },
            showAddButton:{
                default:true
            },
            showDeleteButton:{
                default:true
            },
            showEditButton:{
                default:true
            },
            enabledFormula:{
                default:true
            },
            noImportExport:{
                type: Boolean,
                default: false
            },
            raw: {
                type: Boolean,
                default: false
            },
            toolbarLayoutPosition: {
                type: Object,
                default: () => {},
            },
            importValidatorHandler: {
                default: function () {
                    return true;
                },
            },
            confirmationDeleteMessage:{
                default:function (){
                    return ""
                }
            },
            deleteMethod:{
                default:function (){
                    return undefined
                }
            }
        },
        data() {
            return {
                containerOffset: 0,
                editMode: false,
                importedFile:false,
                opening:true,
                records:[],
                headers:[],
                onSort:false,
                dialog:false,
                dialogData:{
                    currentColumn:"",
                    rowIndex:-1,
                    selectedColumns:[],
                    copyToAllColumns:false,
                    copyToAllRows:false,
                    formula:"",
                    rawFormula:"",
                },
                toolbarDefaultProps:{
                   position: 'relative',
                   zIndex: 20,
                },
            }
        },
        mounted() {
        },
        computed: {
            headersExport() {
                let result = [];
                this.headerColumns.forEach(header => {
                    result.push({
                        label: header.name,
                        field: header.key,
                    });
                });
                return result;
            },
            rowsExport() {
                return this.raw ? this.rawRows : this.rows;
            },
            dialogColumns(){
                let result = [];
                this.headerColumns.forEach(header => {
                    if(header.contextMenu){
                        result.push(header);
                    }
                });
                return result;
            },
            toolbar() {
                return document.getElementById("toolbar");
            },
            sticky() {
                return this.toolbar.offsetTop;
            },
            offsetTop() {
                return this.containerOffset;
            },
            spreadSheetContainerStyle(){
                return {
                    "max-height": this.maxSpreadsheetHeight+"px"
                }
            },
            toolbarPosition() {
                return {
                    position: Object.isUseful(this.toolbarLayoutPosition) ? this.toolbarLayoutPosition.position || this.toolbarDefaultProps.position : this.toolbarDefaultProps.position,
                    top: this.offsetTop + "px",
                    "z-index": Object.isUseful(this.toolbarLayoutPosition) ? this.toolbarLayoutPosition.zIndex || this.toolbarLayoutPosition.zIndex === 0 || this.toolbarDefaultProps.zIndex : this.toolbarDefaultProps.zIndex,
                }

            },
            styleSorter(){
                return header=>{
                    return{
                        cursor:"pointer",
                        "font-size": "16px",
                        color:"#000000",
                        opacity:this.headers.sorted==='none'?'0.5':'1',
                        float:"right"
                    }
                }
            }
        },
        methods: {
            styleCell(index,key) {
                if(index === 0) {
                    return "min-width: 200px";
                } else return {
                    border: this.type === "custom" ? "1px solid #eee" : "",
                    width: key ? (this.headerColumns[key].type.indexOf("button", 0) > -1 ? "40px !important" : "") : "",
                    padding: key ? (this.headerColumns[key].type.indexOf("button", 0) > -1 ? "0" : "") : ""
                }
            },
            addRow() {
                let objTmp = {};
                let rawObjTmp = {};
                let self=this;
                this.headers.forEach((header,idx) => {
                    if(self.headerColumns[idx].type==="number"){
                        objTmp[header.value] = 0
                        rawObjTmp[header.value] = 0
                    }else {
                        objTmp[header.value] = "";
                        rawObjTmp[header.value] = "";
                    }
                });
                this.rows.push(objTmp);
                if(this.raw)
                    this.rawRows.push(rawObjTmp);
                this.$emit('addedRow',this.rows.length-1)
            },
            removeRow(i) {
                if (this.confirmationDeleteMessage) {
                    let col = this.confirmationDeleteMessage.match(/[\{](.*)[\}]/g)
                    let msg = this.confirmationDeleteMessage
                    for (let idx = 0; idx < col.length; idx++) {
                        let v=col[idx].replace("{","").replace("}","")
                        msg = msg.replace(col[idx], this.rows[i][v])
                    }
                    let self=this
                    this.$root.showDialogBox(msg, null, "Yes", function () {
                        self.deleteRow(i);
                    }, "No", null)
                    return
                }
                this.deleteRow(i)
            },
            deleteRow(i){
                if (this.deleteMethod){
                    this.deleteMethod(i,()=>{
                        this.rows.removeAt(i);
                        if(this.raw)
                            this.rawRows.removeAt(i);
                        this.$emit("deleteRow",i)
                    })
                }

            },
            valueChanged(idx) {
                this.$emit('changed', idx);
                this.$emit('rowsChanged', this.rows);
            },
            autoCompleteChange(value, key, event) {
                this.rows[key][value] = event;
                this.onChangeValue(key,value,event)
                //item[header.value]=event;
            },
            onChangeValue(row,col,value){
               this.$emit('change',{ row:row, col:col,value:value})
            },
            onScroll() {
                let container = document.getElementById("container");
                this.containerOffset = container.scrollTop;
            },
            rowsImported(data){
                let self=this;
                let headerName = "";
                let msgErr = this.$gettext("Unable to use imported excel");
                try {
                data.headers.forEach(header=>{
                    let headerValue="";
                        //Check if the name column is "" and fill the errMsg with index of header;
                        let headerName = header.includes("UNKNOWN") ? "'empty' in position " +  header.match(/(\d+)/)[0] : header;
                        msgErr = Array.isUseful(data.records) ? this.$gettext("Column name {0} is not valid").format(headerName) : this.$gettext("Empty file was not imported");
                        headerValue = self.headers.find(el => {
                            return el.text === header
                        }).value;
                        if (headerValue !== header) {
                        data.records.forEach(record=>{
                            self.renameJson(record,header,headerValue);
                        })
                    }
                });
                    // this.rows = data.records;
                    if (Array.isUseful(data.records)) {
                            if (this.importValidatorHandler(data)) {
                this.rows=data.records;
                if(this.raw) {
                    this.rawRows = JSON.parse(JSON.stringify(this.rows));
                }
                this.importedFile=true;
                //setted to false for $emit('fileLoaded'..) in watch
                this.opening=false;
                            }
                       } else {
                            this.$root.showErrorNotification(this.$gettext("Empty file was not imported"), true);
                    }
                }
                catch(ex) {
                    debugger
                    this.$root.showErrorNotification(msgErr,true);
                }

            },
            renameJson(json,oldkey,newkey) {

                if (oldkey !== newkey) {
                    Object.defineProperty(json, newkey,
                        Object.getOwnPropertyDescriptor(json, oldkey));
                    delete json[oldkey];
                }
                return json;
            },
            sortRows(propToSort,sorted){
                let descending=sorted==="down";
                this.onSort=true;
                this.rows.sortOnProperty(propToSort,descending);
                this.headers.forEach(header=>{
                    if(header.value===propToSort){
                        header.sorted=descending?"down":"up";
                    }else{
                        header.sorted="none"
                    }
                });
            },
            onCopyToAllColumnsClick(){
                this.dialogData.selectedColumns=this.dialogData.copyToAllColumns?this.headerColumns:[];
            },
            copyValueToRow(rowIndex,value,columnValue) {
                let actualColumn=this.headerColumns.find(column=>{
                    return column.key===columnValue;
                });

                let rawValue;
                if(this.raw)
                    rawValue = this.rawRows[rowIndex][columnValue];
                this.headerColumns.forEach(column => {
                    if(column.type===actualColumn.type && column.key!=columnValue) {
                        this.rows[rowIndex][column.key] = value;
                        if(this.raw)
                            this.rawRows[rowIndex][column.key] = rawValue;
                    }
                })

            },
            DeleteCellValue(rowIndex,value,columnValue){
                let actualColumn=this.headerColumns.find(column=>{
                    return column.key===columnValue;
                });
                if (actualColumn.type==="number"){
                    this.rows[rowIndex][columnValue]=0;
                    if(this.raw)
                        this.rawRows[rowIndex][columnValue]=0;
                }else{
                    this.rows[rowIndex][columnValue]="";
                    if(this.raw)
                        this.rawRows[rowIndex][columnValue]="";
                }
            },
            openDialog(value,idx,columnValue){
                this.dialog=true;
                this.dialogData.formula=value;
                this.dialogData.rowIndex=idx;
                this.dialogData.currentColumn=columnValue;
                if(this.raw)
                    this.dialogData.rawFormula=this.rawRows[idx][columnValue];
            },
            saveDialog() {
                let fromRow = this.dialogData.copyToAllRows ? 0 : this.dialogData.rowIndex;
                let toRow = this.dialogData.copyToAllRows ? this.rows.length - 1 : this.dialogData.rowIndex;
                let actualColumn=this.headerColumns.find(column=>{
                    return column.key===this.dialogData.currentColumn;
                });
                for (fromRow; fromRow <= toRow; fromRow++) {
                    this.dialogData.selectedColumns.forEach(column => {
                        if (actualColumn.type===column.type &&
                            (fromRow!==this.dialogData.rowIndex ||
                                column.key!==this.dialogData.currentColumn)) {
                            this.rows[fromRow][column.key] = this.type !== "custom" ? eval(this.dialogData.formula) : this.dialogData.formula;
                            if(this.raw)
                                this.rawRows[fromRow][column.key] = this.type !== "custom" ? eval(this.dialogData.rawFormula) : this.dialogData.rawFormula;
                        }
                    })
                }
                this.dialog=false;

            },
            onCellClick(row,key) {

                let idx= 0;
                this.headers.forEach((header,index)=>{
                    if (header.value===key)  {
                        idx= index;
                    }
                });
                this.$emit("onCellClick",{row:row,key:key,columnIndex:idx})
            },
            enableDisableEditingMode(){
                this.editMode=!this.editMode
                this.$emit('onEditModeChange',this.editMode)
            }
        },
        watch: {
            headerColumns:{
                deep:true,
                handler(){
                    this.headers=[];
                    this.headerColumns.forEach(header => {
                        this.headers.push({
                            text: header.name,
                            value: header.key,
                            align: "left",
                            sortable: header.sortable,
                            sorted:"none"
                        });
                    });
                }
            },
            rows: {
                deep:true,
                handler: function () {
                    let self=this;
                    if (this.opening) {
                        this.opening = false;
                    }else if(this.onSort){
                        this.onSort=false;
                    }else{
                        let index=-1;
                        if (!this.importedFile) {
                            if(this.rows.length!==this.records.length){
                                index=self.rows.length>self.records.length?self.rows.length-1:self.records.length-1
                            }else{
                                self.records.map((record,idx)=>{
                                    for(let prop in record){
                                        if(record[prop]!==self.rows[idx][prop]){
                                            index=idx;
                                            break;
                                        }
                                    }
                                    if(index>-1)
                                        return index;
                                        return index;
                                })
                            }
                            self.valueChanged(index);
                        } else {
                            self.$emit('fileLoaded', this.rows);
                            self.importedFile = false;
                        }
                    }
                    self.records = JSON.parse(JSON.stringify(self.rows));
                }
            }
        }

    }
</script>

<style scoped>

    .spreadsheet-container {
        width: 100%;
        padding: 0;
        background: white;
    }

    .table-container{
        width: 100%;
        overflow-y: auto;
        overflow-x: auto;
        height:calc(100vh - 10px)
    }
    table.v-table{
        width: calc(100% - 20px)
    }

    #toolbar >>> .v-toolbar__content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .context-menu-span{
        cursor: pointer;
    }

    div::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    /* Track */
    div::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    div::-webkit-scrollbar-thumb {
        background: #BDBDBD;
    }

    /* Handle on hover */
    div::-webkit-scrollbar-thumb:hover {
        background: #2D76CB;
    }
    .theme--light.v-table tbody tr:hover{
        background-color: white;
    }
    .theme--light.v-table tbody tr td:hover{
        background-color: #eee;
    }
</style>
