<template>
    <v-dialog v-model="showDialog" width="720px" height="350px" :fullscreen="$vuetify.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <v-card id="antares-annotation-dialog-card">
            <v-toolbar card dense>
                <v-toolbar-title class="headline text-xs-center">
                    {{title}}
                </v-toolbar-title>
            </v-toolbar>
            <v-layout column pa-3>
                <v-spacer />
                <v-spacer />
                <v-select :items="operationModes" v-model="operationMode" :label="$gettext('Select an operation mode')"/>
                <v-card-actions id="antares-annotation-dialog-card-actions" v-if="!showLoader">
                    <v-spacer></v-spacer>
                    <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="callBack(true)" v-translate>
                        OK
                    </v-btn>
                    <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat" @click="callBack(false)"  v-translate>
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "WorkorderOperationModeSelectionDialog",
        data () {
            return {
                showDialog: false,
                user: "",
                callerInstance: null,
                workorderOperationModeCallback: null,
                title: this.$gettext("Operation Mode Selection"),
                newStatus: "",
                operationModes: [],
                operationMode: "",
                error: "",
                showLoader: false,
                message: "",
                waitingForConfirmation: false,
            }
        },
        methods: {
            setCaller(caller) {
                this.callerInstance = caller;
            },
            Show(user, operationModes, workorderOperationModeCallback) {
                this.showDialog = true;
                this.user = user;
                this.operationModes = operationModes;
                this.workorderOperationModeCallback = workorderOperationModeCallback;
            },
            async callBack(result) {
                this.error ="";
                if (result) {
                    if(this.workorderOperationModeCallback) {
                        this.workorderOperationModeCallback(this.operationMode);
                        this.showDialog = false;
                        if(this.callerInstance)
                            this.callerInstance.annotationDialogOpen = false;
                        if(this.workorderOperationModeCallback)
                            this.workorderOperationModeCallback();
                    }
                } else {
                    this.showDialog = false;
                    if(this.callerInstance)
                        this.callerInstance.annotationDialogOpen = false;
                }
            },
        },
    }
</script>

<style scoped>

</style>