<template>
  <div class="table-container" v-if="!$root.loading">
    <v-btn to="/reexecutionTransactions" small color="info"><v-icon left small>fas fa-plus</v-icon>reexecution status</v-btn>
    <v-data-table
        v-if="listOfOrphanTransactions.length > 0"
        :headers="headersFirst"
        :items="listOfOrphanTransactions"
        :expand="expand"
        item-key= "key"
        :hide-actions="groups.length < 10"
    >
      <template v-slot:items="props">
        <tr>
          <td @click="props.expanded = !props.expanded" style="width: 60px"><i :class="props.expanded ? 'fas fa-caret-up fa-2x' : 'fas fa-caret-down fa-2x'"></i></td>
          <td @click="props.expanded = !props.expanded">
            <span v-for="(val,key) of props.item.entities" :key="key">
              <span v-if="(props.item.entities).length - 1 === key">{{val}}</span>
              <span v-else>{{val + ", "}}</span>
            </span>
          </td>
          <td @click="props.expanded = !props.expanded">
            <i v-for="(val,key) of props.item.keys" :key="key">
              <i v-if="(props.item.keys).length - 1 === key">{{ val }}</i>
              <i v-else>{{ val + ", " }}</i>
            </i>
          </td>
          <td @click="props.expanded = !props.expanded">{{ props.item.count }}</td>
          <td @click="props.expanded = !props.expanded" style="color: green">{{ props.item.resolvable }}</td>
          <td>
            <v-btn @click="newEntity(props.item.keys)" v-if="props.item.entities[0] === 'No target entity'" small color="info"><v-icon left small>fas fa-plus</v-icon>new entity</v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expand="props">
        <v-card flat style="margin-left: 120px">
          <v-data-table
              :headers="headersSecond"
              :items="props.item.children"
              :expand="expand"
              item-key="key"
              :hide-actions="groups.length < 10"
          >
            <template v-slot:items="props1">
              <tr>
                <td @click="props1.expanded = !props1.expanded" style="width: 60px" v-on="!props1.expanded ? { click: () => loadOT(props1.item.keys, props.item.key, props1.item.key) } : {}"><i :class="props1.expanded ? 'fas fa-caret-up fa-2x' : 'fas fa-caret-down fa-2x'"></i></td>
                <td @click="props1.expanded = !props1.expanded" v-on="!props1.expanded ? { click: () => loadOT(props1.item.keys, props.item.key, props1.item.key) } : {}">
                  <span v-for="(val,key) of props1.item.keys" :key="key">
                    <span v-if="val.split(':')[0] !== 'EntityName'">
                      <span v-if="(props1.item.keys).length - 1 === key">{{val}}</span>
                      <span v-else>{{val + ", "}}</span>
                    </span>
                  </span>
                </td>
                <td @click="props1.expanded = !props1.expanded" v-on="!props1.expanded ? { click: () => loadOT(props1.item.keys, props.item.key, props1.item.key) } : {}">{{ props1.item.count }}</td>
                <td>
                  <v-btn v-if="props1.item.children.length > 0 && props1.item.children[0].OrphanTransactionStatus === 'not_resolvable' && props.item.entities[0] !== 'No target entity'" @click="newInstance(props.item.entities, props1.item.keys)" small color="info">
                    <v-icon left small>fas fa-plus</v-icon>new instance
                  </v-btn>
                  <v-btn v-if="props1.item.children.length > 0 && props1.item.children[0].OrphanTransactionStatus === 'resolvable'" small color="green" @click="resolve(props1.item.children)">
                    <v-icon left small>fas fa-play</v-icon>Resolve
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:expand="props1">
              <v-card flat style="margin-left: 120px">
                <v-data-table
                    :headers="headersThird"
                    :items="props1.item.children"
                    :expand="expand"
                    item-key="id"
                    hide-headers
                    :hide-actions="groups.length < 10"
                >
                  <template v-slot:items="props">
                    <tr @click="props.expanded = !props.expanded">
                      <td style="width: 60px"><i :class="props.expanded ? 'fas fa-caret-up fa-2x' : 'fas fa-caret-down fa-2x'"></i></td>
                      <td>{{ props.item["@timestamp"] }}</td>
                      <td>{{ props.item.source }}</td>
                    </tr>
                  </template>
                  <template v-slot:expand="props">
                    <v-card  style="height: auto">
                      <table id="entity">
                        <tr>
                          <td>TransactionID:</td>
                          <td>{{props.item.id}}</td>
                        </tr>
                        <tr>
                          <td>EntityKeys:</td>
                          <td>{{props.item.EntityKeys}}</td>
                        </tr>
                        <tr>
                          <td>OrphanTransactionStatus</td>
                          <td>{{props.item.OrphanTransactionStatus}}</td>
                        </tr>
                        <tr>
                          <td>TransactionSource</td>
                          <td>{{props.item.TransactionSource}}</td>
                        </tr>
                      </table>
                    </v-card>
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </v-data-table>
        </v-card>
      </template>
    </v-data-table>
    <label v-else class="sectionTitle">No orphan transactions available</label>
  </div>

</template>


<script>
import Transaction from "@/api/transactions";
import SplitPane from "@/components/av-split-pane/AvSplitPane";
import DynamicElementBase from "@/components/dynamic-elements/DynamicElementBase";

export default {
  name: "OrphanTransactions",
  components: {SplitPane},
  extend: DynamicElementBase,
  data() {
    return {
      listOfOrphanTransactions: [],
      uniqueOrphanTransactions: [],
      formVariable: {
        children: Array(0),
        description: "",
        editableName: true,
        editableType: true,
        evidence: null,
        expectedValue: null,
        hideHints: false,
        icon: "",
        inputMode: "Normal",
        join: {
          entity: "",
          key: ""
        },
        mandatory: false,
        max: "",
        maxChoices: null,
        min: "",
        minChoices: 0,
        mustMatch: false,
        name: "",
        primaryCharacteristic: true,
        options: Array(0),
        requireEvidence: "",
        step: "",
        title: "",
        type: "Text",
        unit: "",
        userExposed: true,
        value: null,
        valueAsCategory: false,
        uniquenessLevel: "entity",
        restrictedMode: true
      },
      expand: false,
      headersFirst: [
        {
          text: '',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Target entities',
          align: 'left',
          sortable: true,
          value: 'entities'
        },
        { text: 'Keys', value: 'keys' },
        { text: 'Transactions count', value: 'count' },
        { text: 'Resolvable', value: 'resolvable' },
        { text: '', value: '' }
      ],
      headersSecond: [
        {
          text: '',
          align: 'left',
          sortable: false,
        },
        { text: 'Key values', value: 'keys' },
        { text: 'Transactions count', value: 'count' },
        { text: '', value: '' }
      ],
      headersThird: [
        {
          text: '',
          align: 'left',
          sortable: false,
        },
        { text: 'Timestamp', value: 'timestamp' },
        { text: '', value: '' }
      ],
      groups: [
        {
          entities: 'No entity',
          keys: 'Size, Color',
          count: 5,
          resolvable: 5,
          children: [
            {
              keys: 'Gtin: "12345"',
              count: 2,
              resolvable: true,
              children: [
                {
                  timestamp: "2021-03-01 13:45:06"
                }
              ]
            },
            {
              keys: 'Gtin: "234565"',
              count: 1
            },
            {
              keys: 'Gtin: "34567"',
              count: 2
            },
          ]
        },
        {
          entities: 'Bottle, Shoes',
          keys: 'Gtin',
          count: 5,
          resolvable: 2,
          children: [
            {
              keys: 'Gtin: "12345"',
              count: 2,
              resolvable: true,
              children: [
                {
                  timestamp:"2021-03-10T10:45:54.4578029+01:00",
                  EntityKeys:{
                    Gtin: "12345"
                  },
                  OrphanTransactionStatus:"resolvable",
                  TargetEntityName:"Bottle",
                  TransactionSource:"API",
                  id:"2021-02-22T15:14:57.1541727+01:00",
                  tag:{
                    ExposedName:"data_orphantransactions",
                    Source:"dashboard"
                  }

                },
                {
                  timestamp:"2021-03-10T10:45:55.4578029+01:00",
                  EntityKeys:{
                    Gtin: "12345"
                  },
                  OrphanTransactionStatus:"resolvable",
                  TargetEntityName:"Shoes",
                  TransactionSource:"API",
                  id:"2021-02-22T15:14:57.1541727+01:00",
                  tag:{
                    ExposedName:"data_orphantransactions",
                    Source:"dashboard"
                  }
                }
              ]
            },
            {
              keys: 'Gtin: "54321"',
              count: 3,
              resolvable: false,
              children: [
                {
                  timestamp:"2021-03-10T10:45:54.4578029+01:00",
                  EntityKeys:{
                    Gtin: "54321"
                  },
                  OrphanTransactionStatus:"not resolvable",
                  TargetEntityName:"Bottle",
                  TransactionSource:"API",
                  id:"2021-02-22T15:15:57.1541727+01:00",
                  tag:{
                    ExposedName:"data_orphantransactions",
                    Source:"dashboard"
                  }

                },
                {
                  timestamp:"2021-03-10T10:42:55.4578029+01:00",
                  EntityKeys:{
                    Gtin: "54321"
                  },
                  OrphanTransactionStatus:"resolvable",
                  TargetEntityName:"Shoes",
                  TransactionSource:"API",
                  id:"2021-02-22T15:14:57.1541727+01:00",
                  tag:{
                    ExposedName:"data_orphantransactions",
                    Source:"dashboard"
                  }
                },
                {
                  timestamp:"2021-03-10T11:45:55.4578029+01:00",
                  EntityKeys:{
                    Gtin: "54321"
                  },
                  OrphanTransactionStatus:"resolvable",
                  TargetEntityName:"Shoes",
                  TransactionSource:"API",
                  id:"2021-02-22T15:14:57.1541727+01:00",
                  tag:{
                    ExposedName:"data_orphantransactions",
                    Source:"dashboard"
                  }
                },
              ]
            },
          ]
        },
        {
          entities: 'Auto brand',
          keys: 'Serial',
          count: 2,
          resolvable: 2,
          children: [
            {
              keys: 'Serial: "12345"',
              count: 2,
              resolvable: true,
              children: [
                {
                  timestamp: "2021-03-01 13:45:06"
                },
                {
                  timestamp: "2021-03-01 13:45:07"
                }
              ]
            },
          ]
        }
      ],
    }
  },
  mounted() {
    this.$root.setLoading(true, "");
    this.$root.setCurrentPageTitle("List of orphan transactions");
    this.getGroupedOrphanTransactions()
  },
  methods: {
    getGroupedOrphanTransactions() {
      Transaction.getGroupedOrphanTransactions().then(t => {
        if (t !== null) {
          t.map(item => {
            item["key"] = item.entities.join('')
            item.children.forEach(i => {
              i["key"] = i.keys.join('')
              i.children = []
            })
            this.listOfOrphanTransactions.push(item)
          })
        }
      }).finally(() => {
        this.$root.setLoading(false);
      })
    },
    loadOT(keys, lvl1Key, lvl2Key) {
      if (this.listOfOrphanTransactions.find(ot => ot.key === lvl1Key).children.find(ot2 => ot2.key === lvl2Key).children.length === 0) {
        let payload = {}
        keys.forEach(key => {
          let tmpArray = key.split(":")
          payload[tmpArray[0]] = tmpArray[1]
        })
        Transaction.getOrphanTransactions(payload).then(res => {
          this.listOfOrphanTransactions.find(ot => ot.key === lvl1Key).children.find(ot2 => ot2.key === lvl2Key).children = res
        })
      }
    },
    newEntity(keys) {
      let formVariables = []
      keys.forEach(key => {
        this.formVariable.name = key; this.formVariable.title = key;
        formVariables.push({...this.formVariable})
      })
      this.$router.push({path:"/explore/forms",query: { newFormFromVariables: formVariables } });
    },
    newInstance(entity, keys) {
      this.$router.push({ path: '/forms/' + entity[0], query: { keys: keys }})
    },
    resolve(orphanTransactions) {
      Transaction.reExecuteOrphanTransactions(orphanTransactions).then(t => {
        this.listOfOrphanTransactions = []
        this.getGroupedOrphanTransactions()
      })
    }
  }
}
</script>

<style scoped>
  table.table {
    margin:0 auto;
    width: 98%;
    max-width: 98%;
  }

  .datatable-cell-wrapper {
    width: inherit;
    position: relative;
    z-index: 4;
    padding: 10px 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .datatable__expand-content .card__text {
    z-index: 3;
    position: relative;
  }
  .datatable-container {
    position: absolute;
    background-color: white;
    top: -50px;
    left: -14px;
    right: -14px;
    bottom: 0;
    z-index: 2;
    border:1px solid #ccc;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.15), 0 1px 10px 0 rgba(0,0,0,0.15), 0 2px 4px -1px rgba(0,0,0,0.2);
  }

  #entity {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 18px;
  }

  #entity td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #entity tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #entity tr:hover {
    background-color: #ddd;
  }
</style>