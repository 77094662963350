<template>
    <PopUpRadioGroupPicker class="ma-2" :dialog="true" :options="options" :value="value" icon="fas fa-calendar" @change="onChanged($event)"/>
</template>

<script>

    import PopUpRadioGroupPicker from '@/components/utilities/PopUpRadioGroupPicker'

    export default {
        name: "PopUpMonthOfYearPicker",
        components: { PopUpRadioGroupPicker },
        data () {
            return {
                options: [ this.$gettext("January"), this.$gettext("February"), this.$gettext("March"), this.$gettext("April"), this.$gettext("May"), this.$gettext("June"), this.$gettext("July"), this.$gettext("August"), this.$gettext("September"), this.$gettext("October"), this.$gettext("November"), this.$gettext("December")],
            }
        },
        props: {
            value:  [Number],
        },
        methods: {
            onChanged(value) {
                this.$emit('input', value);
                this.$emit('change', value);
            }
        }
    }
</script>

<style scoped>

</style>