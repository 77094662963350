
import VueInstance from "./vueinstance"
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();
let fingerPrint = ""

;(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();

    // This is the visitor identifier:
    fingerPrint = result.visitorId;
    console.log("Aquired browserID: " + fingerPrint)
})();

export default {
   lockItem(type, item) {
       let lock = {
           user: VueInstance.get().$root.userName,
           lockerId: fingerPrint
       };
       return new Promise((resolve, reject) => {
           OrchestratorAPI.proxyCall('post', Microservices.documentsUrl + '/doc/user/locks/{0}_{1}'.format(type, item), lock)
               .then(t => {
                   resolve(t);
               })
               .catch(t => {
                   console.log(t);
                   reject(t);
               });
       });
   },

    unLockItem(type, item) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('delete', Microservices.documentsUrl + '/doc/user/locks/{0}_{1}'.format(type, item))
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    console.log(t);
                    reject(t);
                });
        });
    },

    //We use a very relaxed timeout since many browsers (expecially chrome) intentionally slow down timers when
    //tabs are not focused. Items are locked on a nominal 5 seconds rate but when unfocused it slows down to 1 minute.
    isItemLocked(type, item, maxLockAgeSeconds = 90) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.documentsUrl + '/doc/user/locks/{0}_{1}'.format(type, item))
                .then(lock => {
                    if(!Array.isUseful(lock) || (new Date(lock[0]["@timestamp"]) < new Date(Date.now() - maxLockAgeSeconds * 1000)) ||
                        ((lock[0]["lockerId"] && lock[0]["lockerId"] === fingerPrint) && (lock[0]["user"] === VueInstance.get().$root.userName)))
                        resolve(null);
                    else resolve(lock[0]);
                })
                .catch(t => {
                    debugger
                    console.log(t);
                    resolve(null);
                });
        });
    },

    areItemsLocked(type, items, maxLockAgeSeconds = 90) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.documentsUrl + '/doc/user/locks')
                .then(locks => {
                    let returning = [];
                    for(let item of items) {
                        let found = false;
                        for (let lock of locks) {
                            if(lock && lock.id === type + "_" + item) {
                                if((new Date(lock["@timestamp"]) < new Date(Date.now() - maxLockAgeSeconds * 1000)) ||
                                    ((lock["lockerId"] && lock["lockerId"] === fingerPrint) && (lock["user"] === VueInstance.get().$root.userName)))
                                    returning.push(null);
                                else returning.push(lock);
                                found = true;
                                break;
                            }
                        }
                        if(!found)
                            returning.push(null);
                    }
                    resolve(returning);
                })
                .catch(error => {
                    if(Microservices.isIndexEmptyError(error)) {
                        resolve(Array(items.length).fill(null))
                    } else {
                        debugger;
                        console.log(error);
                        reject(error);
                    }
                });
        });
    }
}