<template>
    <DynamicElementBase>
        <div style="height: 100%; width:100%; overflow: hidden;">
            <v-layout column ma-3 pb-3 style="overflow: hidden">
                <template v-if="noWo">
                    <label style="margin-bottom: 10px" class="title font-weight-regular" v-translate>No workorder selected</label>
                </template>
                <template v-else v-for="value in values">
                    <label style="margin-bottom: 10px" class="title font-weight-regular">{{value.key}}: {{value.value}}</label>
                </template>
            </v-layout>
        </div>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'

    export default {
        name: "WidgetWorkorderInfo",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase
        },
        data () {
            return {
                dataCollection: null,
                keyValuesView: true,
                headers: [],
                values: [],
                options: {},
                noWo: true
            }
        },
        mounted () {
            this.properties.showTimeWindow = false;
            this.visualizationTargets = [ ];
            this.customQueryHandler = this.getData;
            this.childHandlers.checkItemUsefulness = () => { return true; };
        },
        watch: {},
        methods: {
            getData() {
                let wo = "";
                if(!this.filterItems)
                    return null;
                for(let i = 0 ; i < this.filterItems.length ; i++)
                    if(this.filterItems[i].name.toLowerCase() === "workorderid" && Array.isUseful(this.filterItems[i].filters) && this.filterItems[i].filters.length === 1)
                        wo = this.filterItems[i].filters[0].conditions[0].value;
                if(wo && wo !== "@CurrentWorkOrder") {
                    this.noWo = false;
                    return this.$workorders.get(wo);
                }
                else {
                    this.noWo = true;
                    return null;
                }
            },
            refreshData(dataValues) {

                this.headers.clear();
                this.values.clear();
                if(!dataValues.found)
                    return;
                this.values.push( { key: this.$gettext("Workorder ID"), value: dataValues.Id} );
                this.values.push( { key: this.$gettext("Product"), value: dataValues.ProductDescription} );
                this.values.push( { key: this.$gettext("Material"), value: dataValues.Material} );
                this.values.push( { key: this.$gettext("Recipe"), value: dataValues.RecipeId} );
                this.values.push( { key: this.$gettext("Batch size"), value: dataValues.QuantityToProduce} );
                this.values.push( { key: this.$gettext("Production line"), value: dataValues.PhysicalLineId} );
                this.values.push( { key: this.$gettext("Expiry"), value: dataValues.Expiry} );
                this.values.push( { key: this.$gettext("Lot"), value: dataValues.Lot} );
                this.values.push( { key: this.$gettext("Status"), value: dataValues.StatusString} );

                // CloseTime: "2019-10-20T12:00:00.500Z"
                // Manufactured: 20190614
                // OpenTime: "2019-10-01T00:03:40.540Z"
            },
        }
    }

</script>

<style scoped>


</style>