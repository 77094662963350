import AvAutosizeText from './AvAutosizeText.js'
import Vue from 'vue'

const install = function(Vue) {
  Vue.directive('ResizeText', AvAutosizeText);
};

if (window.Vue) {
  window.AvAutosizeText = AvAutosizeText;
  Vue.use(install); // eslint-disable-line
}

AvAutosizeText.install = install;

export default AvAutosizeText;