<template>
    <FormBase :formId="formId"/>
</template>
<script>
    import FormBase from '@/components/dynamic-elements/forms/FormBase.vue';

    export default {
        name: "FormBasicEntity",
        extends: FormBase,
        inject: ['formId'],
        components: {
            FormBase
        },
        data() { return {
            tweaksUpdating: false
        } },
        mounted() {
            this.formConfigurationMode.requiresJoin = false;
            this.formConfigurationMode.requiresPrimaryKey = true;
            this.formConfigurationMode.requiresTargetEntity = false;
            this.formConfigurationMode.isEntity = false;
            this.formConfigurationMode.isTransaction = false;
            this.properties.unDeletable = false;
            this.childHandlers.getDefaultName = function() { return "Basic Entity" };
            this.childHandlers.onNewElementCreated.push(this.onNewEntity);
            this.childHandlers.onElementLoaded.push(this.onOpenEntity);
            this.properties.sidebarVisibilityLink = false;
        },
        methods: {
            setAppropriateTweaks() {
                for (let tweak of this.visualizationTweaks)
                    tweak.hidden = true;
                this.setTweakValue("basicentity", true);
                //this.saveTweaks()
            },
            onOpenEntity() {
                this.setAppropriateTweaks();
            },
            onNewEntity() {
                this.setAppropriateTweaks();
                this.formVariables = [];
                this.$emit('dataItemsUpdated');
            },
        }
    }

</script>

<style scoped>
    table th {
        border: 3px solid #dddddd;
        height: 50px !important;
        max-height: 50px !important;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }

    table td {
        border: 3px solid #dddddd;
        height: 50px !important;
        max-height: 50px !important;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }

    table tr {
        border: 3px solid #dddddd;
        height: 50px !important;
        max-height: 50px !important;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }
</style>
