import VueInstance from "./vueinstance"
import OrchestratorAPI from '@/api/orchestrator'

let processTypeMap = {
    AvionicsRest: "Avionics data service",
    Backnote: "Avionics back-annotator",
    Elasticsearch: "Avionics DB",
    ETL: "Avionics Data transfer and aggregation",
    AvOpcUa: "Antares OPC-UA client",
    AvModbus: "Antares Modbus client",
};

export default {

   processTypeMap : processTypeMap,

    getProcessName(processId) {
        let [type, ...name] = processId.split("_");

        if (name.length > 0) {
            return name.join("_");
        }
        return "";
    },

    getProcessType(processId){
        let toks = processId.split("_");

       if (Object.isUseful(processTypeMap[toks[0]])){
           return processTypeMap[toks[0]]
       }
       return toks[0];
    },
    
    async getProcessesStatusList(){
        return new Promise((resolve, reject) =>{
            OrchestratorAPI.status()
                .then(response=> {
                    let microservices = JSON.parse(response.data.Data);
                    for(let microservice of microservices) {
                        microservice.Version = JSON.parse(microservice.microserviceVersion).FileVersionInfo.FixedFileInfo.FileVersion.Build;
                        microservice["@timestamp"] = new Date(microservice.LastHeartBeat.seconds * 1000)
                    }
                    resolve(microservices)
                })
                .catch(response => {
                    console.log(response);
                    reject(VueInstance.get().$gettext("Unable to find processes status"));
                });
        })
    },
}
