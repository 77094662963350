<template>
    <DynamicElementBase>
        <v-layout row style="width: 100%" align-center justify-start fill-height>
            <av-icon v-if="green" :size="containerHeight < 100 ? (containerHeight - 10) : 100" color="green" style="padding-left: 10px;">fas fa-check-circle</av-icon>
            <av-icon v-else :size="containerHeight < 100 ? (containerHeight - 10) : 100" color="red" style="padding-left: 10px;">fas fa-times-circle</av-icon>
            <label style="margin-left: 20px" class="headline">{{label}}</label>
        </v-layout>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'

    let Targets = { "green" : 0, "red" : 1 };


    export default {
        name: 'WidgetSemaphore',
        extends: DynamicElementBase,
        components: {
            DynamicElementBase
        },
        data () {
            return {
                green: false,
                red: true,
                label: "",
            }
        },
        mounted: function() {
            //Remove window control
            this.properties.showTimeWindow = false;
            //Fix window to a small value to gather only actual data
            this.properties.timeWindow.setPredefined(2);
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], false));
            this.dataExplorationMode.autoDataSourcing = true;
            this.dataExplorationMode.deviceSelectMode = true;
            this.dataExplorationMode.enumerateLineInDevices = true;
            this.fixedHeight = 150;

            //Enumerate all the possible tweaks and assign saved values
            this.visualizationTweaks = [];

            this.visualizationTargets = [
                { show: "Green condition", id: Targets.green },
                { show: "Red condition", id: Targets.red }];
        },
        watch: {
            device() {
                if(!this.loading)
                    this.$root.showDialogBox(this.$gettext("Do you want to automatically create data sources for line/machine status?"), null, "Yes", this.createDataItems, "No", null);
            }
        },
        computed: {
            device() {
                return this.properties.selectedDevices[0];
            }
        },
        methods: {
            getFilterId(index) {
                return this.filterItems[0].filters[index].filterId;
            },
            createDataItems() {

                let device = this.properties.selectedDevices[0];
                let label = "Line";
                if(device.device !== "Line")
                    label = "Machine";

                this.filterItems = [
                    {
                        index: device.dataIndex,
                        root: device.device,
                        name: 'Status',
                        type: 'integer',
                        selectedForFiltering: true,
                        filters: [
                            {
                                conditions: [{operator: '>', value: 0}],
                                defaultName: label + " running",
                                name: label + " running",
                                enabled: true,
                                filterId: this.$utils.timeTag(label + " running")
                            },
                            {
                                conditions: [{operator: '=', value: 0}],
                                defaultName: label + " stopped",
                                name: label + " stopped",
                                enabled: true,
                                filterId: this.$utils.timeTag(label + " stopped")
                            }
                        ],
                    }
                ];

                let filterKeys = { Running: 0, Stopped: 1 };

                this.dataItems = [
                    {
                        index: device.dataIndex,
                        root: device.device,
                        name: 'Status',
                        type: 'integer',
                        selectedForVisualization: true,
                        representations: [
                            {
                                type: this.$defines.allAggregations.raw.id,
                                filters: [ this.getFilterId(filterKeys.Running)],
                                target: Targets.green,
                                defaultName: device.device.toUpperCase() + " is running",
                                name: device.device.toUpperCase() + " is running",
                                enabled: true
                            },
                            {
                                type: this.$defines.allAggregations.raw.id,
                                filters: [ this.getFilterId(filterKeys.Stopped)],
                                target: Targets.red,
                                defaultName: device.device.toUpperCase() + " is stopped",
                                name: device.device.toUpperCase() + " is stopped",
                                enabled: true
                            }
                        ],
                    },
                ];
                this.$emit('dataItemsUpdated');
            },
            refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view

                if(dataValues.length === 0) {
                    this.red = true;
                    this.green = false;
                }

                //Create data structure to hold components values
                let data = {
                    green: null,
                    red: null
                };

                for(let dataIndex = 0 ; dataIndex < dataValues.length ; dataIndex++) {
                    if(dataValues[dataIndex].data.length > 0) {
                        let target = Object.keys(Targets)[dataValues[dataIndex].target];
                        let item = dataValues[dataIndex].data.last();
                        if((data[target] === null) || (item.x > data[target].x)) {
                            data[target] = item;
                            data[target]["label"] = dataValues[dataIndex].label
                        }
                    }
                }

                if(data.green === null && data.red === null) {
                    this.green = false;
                    this.red = false;
                    this.label = "Unknown status";
                    return;
                }

                if(data.green !== null && data.red !== null) {
                    if(data.green.x > data.red.x) {
                        this.green = true;
                        this.red = false;
                        this.label = data.green.label;
                    }
                    else {
                        this.green = false;
                        this.red = true;
                        this.label = data.red.label;
                    }
                    return;
                }

                if(data.green !== null) {
                    this.green = true;
                    this.red = false;
                    this.label = data.green.label;
                    return;
                }

                if(data.red !== null) {
                    this.green = false;
                    this.red = true;
                    this.label = data.red.label;
                    return;
                }
            },
        },
    }
</script>

<style scoped>
</style>