import Api from '@/api/api'
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import Vue from 'vue'
import VueInstance from './vueinstance'
import DataApis from "./data"
import Utils from "./jsutils"
import Config from "./config"
import DateTime from "./datetimeutils";

export default {

    targetOfflineMeasurements: "data/data entry records",
    targetPendingActions: "user/activities.tasks",
    targetProductionData: "user/production data",

    getPendingActions() {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.activityRuleUrl + '/pendingtasks')
                .then((actions) => {
                    resolve(actions);
                })
                .catch((err) => {
                    if(Microservices.isIndexEmptyError(err)) {
                        resolve([])
                    } else {
                        debugger
                        console.error(err);
                        reject(VueInstance.get().$gettext("Error while retrieving actions."));
                    }
                })
        });
    },
    getProductionData() {
        let self = this;
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.documentsUrl + '/doc/' + self.targetProductionData)
                .then((actions) => {
                    resolve(actions);
                })
                .catch((err) => {
                    if(Microservices.isIndexEmptyError(err))
                        resolve([]);
                    else reject(VueInstance.get().$gettext("Error while retrieving actions."));
                })
        });
    } ,

    scanCycleAnalytics() {
        if(!Config.isL2())
            return;
        OrchestratorAPI.proxyCall('get', Microservices.analyticsUrl + '/scanCycleAnalytics')
            .catch((err) => {
                console.log(err)
            })
    },

    setActionCompleted(id, username) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post',Microservices.activityRuleUrl + "/{0}/{1}".format(id, "resolve"))
                .then((result) => {
                    if (!Object.isUseful(result))
                        throw("Can't find action " + id + " to resolve");

                    resolve(true);
                })
                .catch((err) => {
                    debugger
                    console.error(err);
                    reject(false)
                })
        });
    } ,

    saveForm(data, target){
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.documentsUrl + '/doc/' + target, data)
            //Api().post('/doc/' + target,data)
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    console.log(t);
                    reject(VueInstance.get().$gettext("Error while saving form data."));
                });
        });
    },

    deleteForm(target, id){
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('delete', Microservices.documentsUrl + '/doc/' + target + '/' + id)
                //Api().post('/doc/' + target,data)
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    console.log(t);
                    reject(VueInstance.get().$gettext("Error while deleting form data."));
                });
        });
    },

    getMeasurements(from, to, namespaceFilters, internalFilters) {
        return new Promise((resolve, reject) => {

            let api = '/doc/' + this.targetOfflineMeasurements;
            if(Object.isUseful(from) && Object.isUseful(to))
                api += '/time/{0}/{1}'.format(from, to);
            OrchestratorAPI.proxyCall('get',  Microservices.documentsUrl + api)
                .then(entries => {
                    //FN It's ok that the API can return entries == "" ????
                    if(Array.isUseful(namespaceFilters) && entries) {
                        entries = entries.filter(measure => namespaceFilters.includes(measure.NameSpace));
                    }
                    if(Array.isUseful(internalFilters)) {
                        for(let i = 0 ; i < entries.length ; i++) {
                            let found = false;
                            for(let filter of internalFilters)
                                if(Object.hasOwnPropertyCaseInsensitive(entries[i][entries[i].NameSpace], filter)) {
                                    found = true;
                                    break;
                                }
                            if(!found) {
                                entries.removeAt(i);
                                i--;
                            }
                        }
                    }

                    resolve(entries);
                })
                .catch(t => {
                    console.log(t);
                    reject(VueInstance.get().$gettext("Error while retrieving actions records."));
                });
        });
    },

    loadActionsNames(from, to) {
        return DataApis.getDistinctValues('data_data.entry.records', '', 'NameSpace', 'keyword', from, to)
    },

    /***************** VALIDATION ******************/
    returnWhatRequested(result, message, returnMessage = false) {
        return (returnMessage ? message : result);
    },

    //Executes validation logics that allows/blocks form saving
    validateVariable(variable, returnMessage = false) {
        //Mandatory vars must be filled in to save forms
        if(variable.mandatory && !this.isValueValid(variable))
            return this.returnWhatRequested(false, VueInstance.get().$gettext('Field must be filled in'), returnMessage);
        if(variable.mustMatch && !this.validateVariableValue(variable))
            return this.returnWhatRequested(false, this.validateVariableValue(variable, true), returnMessage);
        if(variable.requireEvidence && !variable.evidence)
            return this.returnWhatRequested(false, VueInstance.get().$gettext('Photo evidence is required'), returnMessage);
        return this.returnWhatRequested(true, "", returnMessage);
    },

    //Executes validation logics regardless of the fact that they block/allow saving
    validateVariableValue(variable, returnMessage = false) {
        if(this.isExpectedValueValid(variable)) {
            if(this.convertValue(variable.type, variable.value) !== variable.expectedValue)
                return this.returnWhatRequested(false, VueInstance.get().$gettext("Value doesn't match expected: {0}").format(variable.expectedValue), returnMessage);
        }
        else if(variable.regex) {
            try {
                if(!new RegExp(variable.regex).test(variable.value))
                    return this.returnWhatRequested(false, variable.regexMessage || VueInstance.get().$gettext("Value doesn't match expected format"), returnMessage);
            } catch(err) {
                console.error("invalid regex: " + err);
                return this.returnWhatRequested(false, VueInstance.get().$gettext("Invalid validation criteria. Please check field configuration"), returnMessage);
            }
        }
        if((variable.type.toLowerCase() === 'number' || variable.type.toLowerCase() === 'text-number') && ((this.isLimitValid(variable.min) && variable.value < variable.min) ||
                (this.isLimitValid(variable.max) && variable.value > variable.max)) )
            return this.returnWhatRequested(false, this.getNumericLimitsValidationMessage(variable), returnMessage);

        return this.returnWhatRequested(true, "", returnMessage)
    },

    convertValue(variableType, value) {
        return ((variableType.toLowerCase() === 'number' || variableType.toLowerCase() === 'text-number') ? Number(value) : value);
    },

    isLimitValid(limit) {
        if(limit === "")
            return false;
        if(isNaN(limit))
            return false;
        return true;
    },

    isExpectedValueValid(variable) {
        if(Array.isArray(variable.expectedValue))
            return Array.isUseful(variable.expectedValue);
        else return(Object.isUseful(variable.expectedValue));
    },

    isValueValid(variable) {
        switch (variable.type.toLowerCase()) {
            case "text":
            case "single option":
                return (variable.value && variable.value.trim() !== "");
            case "datetime":
                return (variable.value && variable.value.toString().trim() !== "");
            case "multi options":
                return Array.isUseful(variable.value);
        }
        return Object.isUseful(variable.value);
    },
    getNumericLimitsValidationMessage(variable) {
        if (variable.type.toLowerCase() === 'number' || variable.type.toLowerCase() === 'text-number') {
            let high = this.isLimitValid(variable.max);
            let low = this.isLimitValid(variable.min);
            let unit = (variable.unit ? variable.unit : "");
            if(low && !high)
                return VueInstance.get().$gettext("Value must be greater than {0}{1}").format(variable.min, unit);
            if(!low && high)
                return VueInstance.get().$gettext("Value must be smaller than {0}{1}").format(variable.max, unit);
            if(low && high)
                return VueInstance.get().$gettext("Value must be between {0}{2} and {1}{2}").format(variable.min, variable.max, unit);
        }
        return "";
    },

    variableHints(variable) {
        if(this.isExpectedValueValid(variable))
            return VueInstance.get().$gettext("Expected Value: {0}").format(variable.expectedValue);

        if (variable.type.toLowerCase() === 'number' || variable.type.toLowerCase() === 'text-number') {
            let numberLimitsHint = this.getNumericLimitsValidationMessage(variable);
            if(numberLimitsHint)
                return numberLimitsHint;
        }
        //TODO evaluate adding a custom hint message
        return ""
    },

    //Form can be saved or not
    isFormValid(form) {
        if(!form)
            return false;
        let varsCrawler = function(variables, self) {
            for(let variable of variables) {
                if(variable.type === "repeatableGroup")
                    continue; //TODO temp bypass, sanitize this
                if(!self.validateVariable(variable))
                    return false;
                if(Array.isUseful(variable.children)) {
                    if(variable.type === "Boolean" && !variable.value)
                        return true;
                    if(!varsCrawler(variable.children, self)){
                        return false
                    }
                }
            }
            return true
        };
        return varsCrawler(form, this);
    },

    //Form fields are all within specification or not; regardless of the fact that it can be saved or not
    getFormResult(form) {
        let varsCrawler = function(variables, self) {
            for(let variable of variables) {
                if(!self.validateVariableValue(variable))
                    return false;
                if(Array.isUseful(variable.children))
                    return varsCrawler(variable.children, self);
            }
            return true
        };
        return varsCrawler(form, this);
    },
    getVariableValue(variable) {
        if (!Object.isUseful(variable.value)) { //Apply defaults
            if (variable.type.toLowerCase() === 'boolean')
                return false;
            else if (variable.type.toLowerCase() === 'number')
                return 0;
            else if (variable.type.toLowerCase() === 'multi options')
                return [];
            else if (variable.type.toLowerCase() === 'files')
                return [];
            else return "";
        } else {
            if (variable.type === "Number")
                return Number(variable.value);
            else if (variable.type.toLowerCase() === 'files' && !Array.isArray(variable.value))
                return [];
            else return variable.value;
        }
    },
    convertToReadableValue(value, variableType = "") {
        if(Array.isArray(value) && variableType !== "Files")
            return value.join(", ");
        else return DateTime.convertDateTime(value);
    },
    //Function merges values from a document into a form variables structure useful for representation
    //additionally returns a boolean to tell whether any file fields were found, it is useful in some pages
    unwrapEntity(entity, formVariables, convertToReadableValues = false) {

        if (!Array.isUseful(formVariables) || !entity)
            return false;

        let hasFiles = false;

        let sourceDocument = entity[entity.NameSpace];
        if(Object.isUseful(sourceDocument["RawDocument"]))
            sourceDocument = sourceDocument["RawDocument"];

        let varsCrawler = function (variables, source, self) {
            for (let variable of variables) {
                if (source.hasOwnProperty(variable.name)) {
                    let childrenNameSpace = source;
                    //Boolean parent nodes are treated as groups.
                    if (variable.type === "Boolean" && Array.isUseful(variable.children)) {
                        variable.value = true;
                        variable.valid = true;
                        childrenNameSpace = source[variable.name];
                    } else if (variable.type === "repeatableGroup") {
                        variable.repeatableChildren = [];
                        for (let subValues of source[variable.name]) {
                            variable.repeatableChildren.push(Utils.detach(variable.children));
                            varsCrawler(variable.repeatableChildren.last(), subValues, self);
                        }
                        continue;
                    } else {
                        if(convertToReadableValues) {
                            variable.value = self.convertToReadableValue(source[variable.name], variable.type);
                            variable.valid = self.validateVariableValue(variable);
                            variable.validationMessage = self.validateVariableValue(variable, true);
                        } else
                            variable.value = source[variable.name];

                        if (variable.type === "Files" && Array.isUseful(variable.value))
                            hasFiles = true;
                    }
                    if (source.hasOwnProperty(variable.name + "_Evidence"))
                        variable.evidence = source[variable.name + "_Evidence"];

                    if (Array.isUseful(variable.children))
                        varsCrawler(variable.children, childrenNameSpace, self);
                }
            }
        };
        varsCrawler(formVariables, sourceDocument, this);

        return hasFiles;
    },
    exportForm(format,name,title,data,reportTemplate,okCallBack,koCallBack) {
        let request = {
            name: name,
            title: title,
            type: format,
            data: data

        };
        Vue.prototype.$dynamicElements.ExportVisualization(format, request, reportTemplate)
            .then(r => {
                const link = document.createElement('a');
                link.href = r;
                link.setAttribute('download', name + "." + format);
                document.body.appendChild(link);
                link.click();
                if(okCallBack)
                    okCallBack()
            })
            .catch(err => {
                debugger;
                console.error(err);
                if(koCallBack)
                    koCallBack()
            })
    },
    
    clearVariables(variables) {
        if (!Array.isUseful(variables))
            return;
        let varsCrawler = function (variables) {
            for (let variable of variables) {
                variable.value = null;
                variable.evidence = null;
                if (Array.isUseful(variable.children))
                    varsCrawler(variable.children);
            }
        };
        varsCrawler(variables);
    }
}
