<template>
    <div class="pa-2 ma-2">
        <av-select :prefix="$gettext('Scheduling period: ')" v-model="mode" @change="setDefaults()" :items="modes" style="" />
        <av-text-field v-if="mode === 0" style="max-width: 250px" v-model="minutes"  :prefix="$gettext('Execute every: ')" :postLabel="$gettext('minutes')" type="number"></av-text-field>
        <av-text-field v-else-if="mode === 1" style="max-width: 250px" v-model="hours"  :prefix="$gettext('Execute every: ')" :postLabel="$gettext('hours')" type="number"></av-text-field>
        <v-layout row v-else-if="mode === 2">
            <av-text-field  style="max-width: 250px" v-model="days"  :prefix="$gettext('Execute every: ')" :postLabel="$gettext('days at:')" type="number"></av-text-field>
            <pop-up-time-picker class="ml-2" type="minsColonSecs" v-model="timeOfDay" />
        </v-layout>
        <v-layout column v-else-if="mode === 3">
            <v-layout row wrap style="max-height: 100px"><v-checkbox v-for="(day, index) in $dateTime.daysOfWeek()" v-model="daysOfWeek" :label="day" :value="index"/></v-layout>
            <v-layout row class="mt-2">
                <label class="subheading mx-2 mt-2 " v-translate>at:</label>
                <pop-up-time-picker type="minsColonSecs" v-model="timeOfDay" />
            </v-layout>
        </v-layout>
        <v-layout column v-else-if="mode === 4">
            <v-layout column wrap style="max-height: 150px"><v-checkbox v-for="(month, index) in $dateTime.monthsOfYear()" v-model="months" :label="month" :value="index"/></v-layout>
            <v-layout row class="mt-2">
                <av-text-field  style="max-width: 150px" v-model="dayOfMonth"  :prefix="$gettext('On day: ')" :postLabel="$gettext('at:')" type="number"></av-text-field>
                <pop-up-time-picker class="ml-2"  type="minsColonSecs" v-model="timeOfDay" />
            </v-layout>
        </v-layout>
        <v-layout column v-else-if="mode === 5">
            <v-layout row>
                <av-text-field  style="max-width: 250px" v-model="relativeValue" :prefix="$gettext('Execute every: ')" type="number" @input="relativeValue <= 0 ? relativeValue = 1 : null"></av-text-field>
                <av-select class="ml-2" v-model="relativeUnit" :items="timeUnits"/>
            </v-layout>
            {{$gettext('Starting from initial time')}}
        </v-layout>
    </div>
</template>

<script>

    import Vue from 'vue';
    import PopUpTimePicker from '@/components/utilities/PopUpTimePicker'
    import PopUpDayOfWeekPicker from '@/components/utilities/PopUpDayOfWeekPicker'
    import PopUpDayOfYearPicker from '@/components/utilities/PopUpDayOfYearPicker'
    import PopUpMonthOfYearPicker from '@/components/utilities/PopUpMonthOfYearPicker'
    import DateTime from '@/api/datetimeutils.js'

    export default {
        name: "CronEditor",
        data () {
            return {
                mode: 0, //Minutes
                minutes: 1,
                hours: 0,
                days: 1,
                daysOfWeek: [4],
                months: [0],
                dayOfMonth: 1,
                timeOfDay: "09:00",
                error: false, //TODO
                avoidParsing: false,
                parsedValue: "",
                relativeValue: 1,
                relativeUnit: 'h'
            }
        },
        components: { PopUpTimePicker, PopUpDayOfWeekPicker, PopUpDayOfYearPicker, PopUpMonthOfYearPicker },
        props: {
            value: {
                type: String,
                default: "*/1 * * * *" //Check every minute by default
            }
        },
        mounted() {
            this.parseCron();
        },
        computed: {
            modes() {
                return [
                    {text: this.$gettext("Minutes"), value: 0},
                    {text: this.$gettext("Hourly"), value: 1},
                    {text: this.$gettext("Daily"), value: 2},
                    {text: this.$gettext("Weekly"), value: 3},
                    {text: this.$gettext("Monthly"), value: 4},
                    {text: this.$gettext("Relative scheduling"), value: 5}
                ]
            },
            timeUnits() {
                return [
                    { value: "m", text: this.$gettext("minutes") },
                    { value: "h", text: this.$gettext("hours") },
                    { value: "d", text: this.$gettext("days") },
                    { value: "w", text: this.$gettext("weeks") }
                ]
            },
            cronString() {
                if(this.avoidParsing)
                    this.avoidParsing = true;
                if(this.mode === 0)
                    return "*/{0} * * * *".format(this.minutes);
                else if(this.mode === 1)
                    return "{0} */{1} * * *".format(this.minutes, this.hours);
                else if(this.mode === 2)
                    return "{0} {1} */{2} * *".format(this.minutes, this.hours, this.days);
                else if(this.mode === 3) {
                    if(!Array.isUseful(this.daysOfWeek))
                        this.daysOfWeek.push(4);
                    let cronDaysOfWeek = [];
                    for(let day of this.daysOfWeek) {
                        if(day === 6)
                            cronDaysOfWeek.push(0);
                        else cronDaysOfWeek.push(day + 1);
                    }
                    return "{0} {1} * * {2}".format(this.minutes, this.hours, cronDaysOfWeek.join(","));
                }
                else if(this.mode === 4) {
                    if(!Array.isUseful(this.months))
                        this.months.push(0);
                    let cronMonths = [];
                    for(let month of this.months)
                        cronMonths.push(month + 1);
                    return "{0} {1} {2} {3} *".format(this.minutes, this.hours, this.dayOfMonth, cronMonths.join(","));
                } else if(this.mode === 5) {
                    return("interval {0}{1}".format(this.relativeValue, this.relativeUnit));
                }
            }
        },
        watch: {
            value() {
                this.parseCron();
            },
            cronString() {
                this.parsedValue = this.cronString;
                this.$emit("input", this.parsedValue);
            },
            timeOfDay() {
                this.hours = parseInt(this.timeOfDay.split(":")[0]);
                this.minutes = parseInt(this.timeOfDay.split(":")[1]);
            }
        },
        methods: {
            setDefaults() {
                if(this.mode === 0) {
                    this.minutes = 1;
                } else if (this.mode === 1) {
                    this.hours = 1;
                    this.minutes = 0;
                } else if(this.mode === 2) {
                    this.days = 1;
                    this.timeOfDay = "09:00"
                } else if(this.mode === 3) {
                    this.daysOfWeek = [4];
                    this.timeOfDay = "17:00"
                } else if(this.mode === 4) {
                    this.months = [1];
                    this.dayOfMonth = 1;
                    this.timeOfDay = "09:00"
                } else if(this.mode === 5) {
                    this.relativeValue = 1;
                    this.relativeUnit = 'h';
                }
            },
            parseCron() {

                if (this.parsedValue === this.value)
                    return;
                this.parsedValue = this.value;
                let toks = this.value.split(" ");
                if (toks.length !== 5 && toks.length !== 2) {
                    this.error = true;
                    return;
                }
                let mode = -1;
                if (toks.length === 2) {
                    if (toks[0] === 'interval')
                        mode = 5; //Relative
                }
                else if(toks[4] !== "*")
                    mode = 3; //Days of week
                else if(toks[3] !== "*")
                    mode = 4; //Months
                else if(toks[2] !== "*")
                    mode = 2; //Daily
                else if(toks[1] !== "*")
                    mode = 1; //Hourly
                else if(toks[0] !== "*")
                    mode = 0; //Minutes
                if(mode === -1) {
                    this.error = true;
                    return;
                }
                this.mode = mode;
                if(this.mode === 5) {
                    this.relativeValue = parseInt(toks[1]);
                    this.relativeUnit = toks[1][toks[1].length - 1];
                    return
                }
                let parser = require('cron-parser');
                let cron = parser.parseExpression(this.value);

                if(mode === 4) {
                    this.months = this.toZeroBasedArray(cron.fields.month);
                    this.dayOfMonth = cron.fields.dayOfMonth;
                    this.timeOfDay = this.toTimeOfDay(toks[1], toks[0]);
                } else if(mode === 3) {
                    this.toReasonableDaysOfWeek(cron);
                    this.timeOfDay = this.toTimeOfDay(toks[1], toks[0]);
                } else if(mode === 2) {
                    this.days = toks[2].split("/")[1];
                    this.timeOfDay = this.toTimeOfDay(toks[1], toks[0]);
                } else if(mode === 1) {
                    this.hours = toks[1].split("/")[1];
                    this.minutes = toks[0];
                } else if(mode === 0) {
                    this.minutes = toks[0].split("/")[1];
                }
            },
            toZeroBasedArray(array) {
                let returning = [];
                for(let i = 0 ; i < array.length ; i++)
                    returning.push(array[i] - 1);
                return returning;
            },
            toTimeOfDay(hours, minutes) {
                if(hours.length === 1)
                    hours = "0" + hours.toString();
                if(minutes.length === 1)
                    minutes = "0" + minutes.toString();
                return hours + ":" + minutes;
            },
            toReasonableDaysOfWeek(cron) {
                let daysOfWeek = cron.fields.dayOfWeek;
                this.daysOfWeek.clear();
                for(let day of daysOfWeek) {
                    if(day === 0 || day === 7)  //Sundays
                        this.daysOfWeek.push(6);
                    else this.daysOfWeek.push(day - 1);
                }
            }
        }
    }
</script>

<style scoped>

</style>