<template>
    <v-dialog v-model="showDialog" width="860px" height="820px" min-height="560px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-workorder-dialog">
        <v-card id="antares-workorder-dialog-card" style="overflow: hidden">
            <v-toolbar card dense>
                <v-toolbar-title class="display-1 text-xs-center">
                    <translate>Production scheduling</translate>
                </v-toolbar-title>
            </v-toolbar>
            <v-layout column pa-3>
                <label style="margin-top: 5px" class="title">{{workorder ? $gettext('Line is currently producing:') : $gettext('Line is not currently producing.')}}</label>
                <template v-if="workorder">
                    <label class="mt-2 ml-3 subheading"><translate>Workorder:</translate> {{workorder}}</label>
                    <label class="mt-2 ml-3 subheading"><translate>Product:</translate> {{product}}</label>
                    <label class="mt-2 ml-3 subheading"><translate>Quantity:</translate> {{quantity}}</label>
                </template>
                <v-layout mt-3 pa-0 v-if="!manualWorkorder" column>
                    <label class="subheading" v-translate>Select a planned workorder (better option):</label>
                    <v-select solo :placeholder="lbSelectWo" class="subheading mt-3 mx-3" :items="workorders" item-text="show" item-value="id" v-model="newWorkorder" />
                </v-layout>
                <v-layout  mt-3 pa-0>
                    <v-switch class="subheading" :label="lbManualWo" v-model="manualWorkorder" />
                </v-layout>
                <v-layout row fluid>
                    <v-text-field v-if="manualWorkorder" clearable class="subheading ma-3 osk" prefix="Type here: " v-model="newWorkorder" />
                    <label v-if="manualWorkorder" class="subheading ma-4" v-translate>Or:</label>
                    <v-btn v-if="manualWorkorder && showButtonNewWorkOrders" class="ma-3" @click="openWorkorderForm" color="info">
                        <av-icon :id="'newActivityBtn'" light small left>fa-plus</av-icon>
                        New Workorder
                    </v-btn>
                </v-layout>
                <v-spacer></v-spacer>
                <template v-if="newWorkorder && (newWorkorder !== workorders[0].id) && !manualWorkorder">
                    <label class="mt-2 title"><translate>New workorder selected</translate></label>
                    <label class="mt-2 ml-3 subheading"><translate>Workorder</translate>: {{newWorkorder}}</label>
                    <label class="mt-2 ml-3 subheading"><translate>Product</translate>: {{newProduct}}</label>
                    <label class="mt-2 ml-3 subheading"><translate>Quantity to produce</translate>: {{newQuantity}}</label>
                    <label class="mt-2 ml-3 subheading"><translate>Status</translate>: {{newStatus}}</label>
                </template>
                <label style="color: red" class="title my-3">{{error}}</label>
                <v-card-actions id="antares-annotation-dialog-card-actions">
                    <v-spacer></v-spacer>
                    <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="callBack(true)" v-translate>
                        OK
                    </v-btn>
                    <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat" @click="callBack(false)" v-translate>
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>

    import DataApis from '@/api/data'
    import DateTimeUtils from '@/api/datetimeutils'

    export default {
        name: 'WorkorderScheduleDialog',
        props: {},
        data() {
            return {
                workorders: [ { show: this.$gettext("No workorder (switch line to idle)"), id: "NoWo" }, { divider: true } ],
                woDetails: {},
                newWorkorder: "",
                manualWorkorder: false,
                showDialog: false,
                workorder: "",
                quantity: "",
                progress: "",
                progressString: "",
                product: "",
                user: "",
                error: "",
                callerInstance: null,
                changeCallBack: null,
                root: null,
                workordersForms: [],
                WorkordersFormsList: false,
            }
        },
        computed: {
            newQuantity() {
                return ( (this.newWorkorder !== this.workorders[0].id && this.woDetails[this.newWorkorder]) ? this.woDetails[this.newWorkorder].quantity : "");
            },
            newProduct() {
                return ( (this.newWorkorder !== this.workorders[0].id && this.woDetails[this.newWorkorder]) ? this.woDetails[this.newWorkorder].product : "");
            },
            newStatus() {
                return ( (this.newWorkorder !== this.workorders[0].id && this.woDetails[this.newWorkorder]) ? this.woDetails[this.newWorkorder].status : "");
            },
            lbSelectWo() {
                return this.$gettext("Select new Workorder")
            },
            lbManualWo() {
                return this.$gettext("Manually specify workorder if not available in planned:");
            },
            showButtonNewWorkOrders() {
                return this.WorkordersFormsList;
            },
        },
        async mounted() {
            await this.loadWorkOrdersForms();
        },
        methods: {
            setVueInstance(instance) {
                this.root = instance;
            },
            setCaller(caller) {
                this.callerInstance = caller;
            },
            Show(callBack, user) {
                this.loadWorkorderInfo();
                this.user = user;
                this.newWorkorder = "";
                this.changeCallBack = callBack;
                this.showDialog = true;
            },
            callBack(result) {
                this.error = "";
                if (result) {
                    if (this.newWorkorder === this.workorder) {
                        this.error = this.$gettext("Selected workorder is already scheduled on this line.");
                        return;
                    }
                    if (this.newWorkorder && this.workorder)
                        this.root.showDialogBox(this.$gettext("There is a workorder scheduled on this line. Are you sure you want to change it?"), null, "Yes", this.confirmChange, "No", null);
                    else this.confirmChange();

                } else {
                    if (this.changeCallBack)
                        this.changeCallBack("");
                    this.showDialog = false;
                }
            },
            confirmChange() {

                let self = this;

                if (this.newWorkorder === this.workorders[0].id) {
                    this.$workorders.unScheduleWorkorder()
                        .then(() => {
                            if (self.changeCallBack){
                                self.changeCallBack(self.workorder, "NoWo");
                            }
                            self.showDialog = false;
                        })
                        .catch(() => {
                            self.error = self.$gettext("An error occurred while scheduling workorder, please try again");
                        })
                } else {
                    this.$workorders.scheduleWorkorder(this.newWorkorder)
                        .then(() => {
                            if (self.changeCallBack){
                                self.changeCallBack(self.workorder, self.newWorkorder);
                            }
                            self.showDialog = false;
                        })
                        .catch(() => {
                            self.error = self.$gettext("An error occurred while scheduling workorder, please try again");
                        })
                }
            },
            loadWorkorderInfo() {
                let self = this;

                let dataItems = [{
                    index: "production counters@5s",
                    root: "Line",
                    name: 'WorkorderID',
                    type: 'keyword',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.last.id, target: 0}]
                },
                {
                    index: "production counters@5s",
                    root: "Workorder",
                    name: 'QuantityToProduce',
                    type: 'integer',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.last.id, target: 1}]
                },
                {
                    index: "production counters@5s",
                    root: "Workorder",
                    name: 'ProductDescription',
                    type: 'keyword',
                    selectedForVisualization: true,
                    representations: [{type: this.$defines.allAggregations.last.id, target: 2}]
                }];
                let queryDescriptor = DataApis.getDataQueryDescriptor(dataItems);

                //Execute query
                if (queryDescriptor.agg.length > 0 || queryDescriptor.raw.length > 0 || queryDescriptor.comp.length > 0) {
                    DataApis.getDataBlob(queryDescriptor, DateTimeUtils.getRfc3339TimeFromNow(1), DateTimeUtils.getRfc3339TimeStamp(new Date()))
                        .then(result => {
                            let data = DataApis.unwrapDataSets(dataItems, [], [], result, this.$config.debug ? result["queryIndex"] : -1);
                            for (let i = 0; i < data.length; i++) {
                                if (Object.isUseful(data[i]))
                                    if (Array.isUseful(data[i].data))
                                        if (data[i].target === 0)
                                            self.workorder = data[i].data.last().y;
                                        else if (data[i].target === 1)
                                            self.quantity = data[i].data.last().y;
                                        else if (data[i].target === 2)
                                            self.product = data[i].data.last().y;
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            self.error = self.$gettext("Unable to retrieve current workorder information");
                        })
                        .finally(() => {
                            this.$workorders.getWorkordersByStatus('running')
                                .then(result => {
                                    if(Array.isUseful(result)) {
                                        self.workorders.push({ show: result.last().Id + "   -   " + self.$gettext('Running'), id: result.last().Id } );
                                        self.workorders.push({divider: true});
                                        self.woDetails[result.last().Id] = {
                                            quantity: result.last().QuantityToProduce,
                                            product: result.last().ProductDescription,
                                            status: result.last().StatusString
                                        }
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                    self.error = this.$gettext("Unable to retrieve running workorder");
                                })
                                .finally(() => {
                                    this.$workorders.getWorkordersByStatus('suspended')
                                        .then(result => {
                                            for (let i = 0; i < result.length; i++) {
                                                self.workorders.push({
                                                    show: result[i].Id + "   -   " + self.$gettext('Suspended'),
                                                    id: result[i].Id
                                                });
                                                self.woDetails[result[i].Id] = {
                                                    quantity: result[i].QuantityToProduce,
                                                    product: result[i].ProductDescription,
                                                    status: result[i].StatusString
                                                }
                                            }
                                            if (Array.isUseful(result))
                                                self.workorders.push({divider: true});
                                        })
                                        .catch(err => {
                                            console.log(err);
                                            self.error = this.$gettext("Unable to retrieve suspended workorders");
                                        })
                                        .finally(() => {

                                            this.$workorders.getWorkordersByStatus('planned')
                                                .then(result => {
                                                    for (let i = 0; i < result.length; i++) {
                                                        self.workorders.push({
                                                            show: result[i].Id + "   -   " + self.$gettext('Planned'),
                                                            id: result[i].Id
                                                        });
                                                        self.woDetails[result[i].Id] = {
                                                            quantity: result[i].QuantityToProduce,
                                                            product: result[i].ProductDescription,
                                                            status: result[i].StatusString
                                                        }
                                                    }
                                                    if (Array.isUseful(result))
                                                        self.workorders.push({divider: true});
                                                })
                                                .catch(err => {
                                                    console.log(err);
                                                    self.error = this.$gettext("Unable to retrieve planned workorders");
                                                })
                                                .finally(() => {
                                                    self.$workorders.loadWorkordersList(new Date(Date.now() - 259200000), new Date()) //Back 3 days
                                                        .then(result => {
                                                            let workordersIncludesWorkorder = function (searchWo) {
                                                                self.workorders.filter(workorder => {
                                                                    return workorder.id === searchWo;
                                                                })
                                                            };
                                                            for (let i = 0; i < result.length; i++) {
                                                                //Add latest workorders after the planned
                                                                if (!workordersIncludesWorkorder(result[i]) && self.workorder !== result[i]) {
                                                                    self.$workorders.get(result[i])
                                                                        .then(wo => {
                                                                            self.workorders.push({
                                                                                show: (wo.Id + "   -   " + (wo.found ? self.$gettext(wo.StatusString) : self.$gettext("Unknown"))),
                                                                                id: wo.Id
                                                                            });
                                                                            self.woDetails[wo.Id] = {
                                                                                quantity: (wo.found ? wo.QuantityToProduce : self.$gettext("Unknown")),
                                                                                product: (wo.found ? wo.ProductDescription : self.$gettext("Unknown")),
                                                                                status: (wo.found ? wo.StatusString : self.$gettext("Unknown"))
                                                                            }
                                                                        })
                                                                }
                                                            }
                                                        })
                                                        .catch(err => {
                                                            console.log(err);
                                                            self.error = this.$gettext("Unable to retrieve past workorders");
                                                        });
                                                })
                                            })
                                    })
                        })
                }
            },

            async loadWorkOrdersForms() {
                this.workordersForms.clear();
                let self = this;
                this.$dynamicElements.LoadItems("forms")
                    .then(result => {
                        if(Array.isUseful(result)) {
                            for (let form of result)
                                if (form.properties.type === self.$dynamicElements.Types.FormWorkorder)
                                    self.workordersForms.push(form.properties.name);
                            this.WorkordersFormsList = self.workordersForms.length > 0;
                        }

                    })
                    .catch(error => {
                        console.error(error);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving saved forms from DB."), true);
                    })
            },

            openWorkorderForm() {
                let self = this;
                if(this.workordersForms.length > 1) {
                    this.$workorders.openWorkorderFormSelectionDialog(this, this.$root.user, this.workordersForms, (value) => { if (value!==0) self.root.$router.push({ path: '/forms/' + value})})
                } else {
                    this.root.$router.push({ path: '/forms/' + this.workordersForms[0]});
                }
                this.callBack(false);
            },
        }
    }

</script>

<style>

</style>