<template>
	<div v-if="visible" :class="classes"></div>
</template>

<script>
  export default {
    props: {
      split: {
        validator(value) {
          return ['vertical', 'horizontal'].indexOf(value) >= 0
        },
        required: true
      },
      className: String,
      visible: true
    },
    computed: {
      classes() {
        const classes = ['splitter-pane-resizer', this.split, this.className];
        return classes.join(' ')
      }
    }
  }
</script>

<style scoped>
.splitter-pane-resizer {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: .6;
	z-index: 1;
}
.splitter-pane-resizer.horizontal {
	width: 50px;
    height: 10px;
	margin: -5px;
	border-top: 1px solid rgb(60, 100, 173);
	border-bottom: 1px solid rgb(60, 100, 173);
	cursor: row-resize;
}
.splitter-pane-resizer.vertical {
    width: 10px;
	height: 50px;
	margin: -5px;
	border-left: 1px solid rgb(60, 100, 173);
    border-right: 1px solid rgb(60, 100, 173);
    cursor: col-resize;
}
</style>