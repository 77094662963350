export default async ({ app, router, store, Vue }) => {
  Vue.prototype.$getMenu = function (menuID, category, destination, callback) {
    Vue.prototype.WebUIAPI.menu(menuID, category).then(t => {
      if (t.data.Status === 'success') {
        let data = JSON.parse(t.data.Data)
        for (let i = 0; i < data.length; i++) {
          destination.push(data[i])
        }
        if (callback !== undefined) {
          callback()
        }
      } else {
        Vue.prototype.$sendErrorNotification(t.data.ErrorMessage)
      }
    }).catch(t => {
      Vue.prototype.$sendErrorNotification({ text: 'Error occurred: {0}' })
    })
  }

  Vue.prototype.$getPageToOpen = function (action) {
    let route = router.options.routes[0].children[0]
    for (let i = 0; i < route.children.length; i++) {
      if (!Vue.prototype.$isEmpty(route.children[i].action) && route.children[i].action.toLowerCase() === action.toLowerCase()) {
        return route.children[i].name
      }
    }
    return ''
  }
}
