<template>
    <DynamicElementBase>
        <v-card class="elevation-0 ma-2" style="height: calc(100% - 20px)" v-show="!annotationWindowVisible">
            <div v-if="viewMode === 'Bars'" style="height: 99%; width: 100%; overflow-y: auto;">
                <BarChart ref="graph" style="width: 100%; height: 99%" :chartdata="chartdata" :options="options" :plugins="plugins"></BarChart>
            </div>
            <v-tabs v-else>
                <template>
                    <v-tab key="long" @click="selectedLineStops.clear()">
                        {{$gettext('{0} long stops - {1} minutes total').format(lineStops.length, longStopsDuration)}}
                    </v-tab>

                    <v-tab-item key="long">
                        <v-layout column fill-height px-1 py-0>
                            <template v-if="!splitView">
                                <v-radio-group class="radio-group-full-size" v-model="selectedStopRadio">
                                    <v-list :style="'overflow: auto; margin-top: 10px; height: {0}px'.format(containerHeight - 128)">
                                        <v-list-tile v-for="(stop, index) in lineStops" style="margin-left: 20px;">
                                            <v-list-tile-action><v-radio :key="index" :value="index" @change="processStop(stop)"/></v-list-tile-action>
                                            <v-list-tile-title><span class="title font-weight-regular">{{$timeTracking.formatStop(stop)}}</span></v-list-tile-title>
                                        </v-list-tile>
                                    </v-list>
                                </v-radio-group>
                            </template>
                            <template v-else>
                                <div :style="'padding: 0px; padding-top: 20px; margin: 0px; height: {0}px'.format(containerHeight - 57)">
                                    <template v-if="!splitCloseConfirm">
                                        <v-radio-group class="radio-group-full-size" :value="selectedSplitStops[0]" :style="'overflow: auto; height: {0}px'.format(containerHeight - 150)">
                                            <v-treeview open-all :items="selectedSplitStops" item-text="show" class="title" expand-icon="">
                                                <template v-slot:prepend="{ item }">
                                                    <v-radio :style="'margin-right: 0px;' + (Array.isUseful(item.children) ? 'margin-left: 24px;' : '')" :key="item.show" :value="item" @change="selectSplitItem(item)"/>
                                                </template>
                                                <template v-slot:label="{item}">
                                                    <label :style="(Array.isUseful(item.children) ? '' : 'margin-left: 0px;') + (item.done ? 'color: lightgrey; text-decoration: line-through;' : '')">
                                                        {{item.show}}
                                                    </label>
                                                </template>
                                            </v-treeview>
                                        </v-radio-group>
                                        <v-layout row>
                                            <v-btn fab small color="info" style="margin: 10px" @click="fineSplitDown()"><av-icon light>fas fa-angle-left</av-icon></v-btn>
                                            <v-slider step="0.1" v-model="splitPoint" color="info" @input="splitItem()" />
                                            <v-btn fab small color="info" style="margin: 10px" @click="fineSplitUp()"><av-icon light>fas fa-angle-right</av-icon></v-btn>
                                            <v-btn color="green darken-1" fab flat="flat" style="margin-top: 2px" @click="splitDone()" v-translate>OK</v-btn>
                                            <v-btn color="blue darken-1" fab flat="flat" style="margin-top: 2px" @click="showWizardAlarms" v-translate>Next</v-btn>
                                        </v-layout>
                                    </template>
                                    <template v-else>
                                        <label class="subheading" v-translate>Are you sure you want to close split view? Uncategorized splits will be lost</label>
                                        <v-spacer />
                                        <v-btn color="green darken-1" flat="flat" @click="splitClose()" v-translate>OK</v-btn>
                                        <v-btn color="red darken-1" flat="flat" @click="splitCloseConfirm = false" v-translate>Cancel</v-btn>
                                    </template>
                                </div>
                            </template>
                        </v-layout>
                    </v-tab-item>
                </template>
                <template v-if="!showLongStopsOnly">
                    <v-tab key="short" @click="selectedLineStops.clear()">
                        {{$gettext('{0} short stops - {1} minutes total').format(lineShortStops.length, shortStopsDuration)}}
                    </v-tab>
                    <v-tab-item key="short" >
                        <v-layout column fill-height px-1 py-0 >
                            <v-list style="margin-top: 10px;">
                                <v-list-tile @click="selectUnselectAllShortStops(), processStop('')">
                                    <v-list-tile-action><v-checkbox :value="selectAllShortStops"/></v-list-tile-action>
                                    <v-list-tile-title><span class="title font-weight-regular"><translate>Select all</translate></span></v-list-tile-title>
                                </v-list-tile>
                            </v-list>
                            <v-list :style="'overflow: auto; margin-top: 0px; height: {0}px;'.format(containerHeight - 180)">
                                <template v-for="stop in lineShortStops">
                                    <v-list-tile style="margin-left: 20px;"  @click="stop.selected = !stop.selected, processStop('')">
                                        <v-list-tile-action><v-checkbox :value="stop.selected"/></v-list-tile-action>
                                        <v-list-tile-title><span class="title font-weight-regular">{{$timeTracking.formatStop(stop)}}</span></v-list-tile-title>
                                    </v-list-tile>
                                </template>
                            </v-list>
                        </v-layout>
                    </v-tab-item>
                </template>
            </v-tabs>
            <v-card-actions v-if="!splitView">
                <v-spacer></v-spacer>
                <v-btn v-if="viewMode === 'List'" color="blue darken-1" flat="flat" class="subheading" @click="showWizardAlarms" v-translate>Next</v-btn>
                <v-btn v-if="splittable" color="green darken-1" flat="flat" class="subheading" @click="initSplit()" v-translate>split selected stop</v-btn>
                <v-btn v-if="canBeClosed" color="red darken-1" class="subheading" flat="flat" @click="$emit('complete')" v-translate>Cancel</v-btn>
            </v-card-actions>
        </v-card>
        <template v-if="annotationWindowVisible">
            <time-tracking v-if="renderComponent" :user="currentUser" :line-stops="selectedLineStops" :alarms="alarms" :stoppedMachines="stoppedMachines" no-title-bar show-stop-time-span touch-layout @complete="setDialog($event)"></time-tracking>
        </template>
        <template slot="toogleViewMode">
            <v-list-tile @click="toggleViewMode">
                <v-list-tile-content>
                    <v-layout row>
                        <av-icon small left >fas fa-sliders-h</av-icon >
                        <label v-translate style="margin-top: 12px">Toggle view mode</label>
                    </v-layout>
                </v-list-tile-content>
            </v-list-tile>
        </template>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
    import TimeTracking from "@/components/TimeTracking"
    import TimeSpanPicker from "@/components/utilities/TimeSpanPickerDialog"
    import DateTimeUtils from '@/api/datetimeutils'
    import Shifts from "@/api/shifts"
    import BarChart from '@/components/graphics/BarChart.vue'
    import VueInstance from '@/api/vueinstance'

    export default {
        name: "WidgetTimeTrackingBackAnnotationDialog",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            TimeTracking,
            TimeSpanPicker,
            BarChart
        },
        data () {
            return {
                workOrder: "",
                forceFillIn: false,
                lineShortStops: [],
                lineStops: [],
                timeTrackingArray: [],
                selectedLineStops: [],
                selectAllShortStops: false,
                selectedStopRadio: -1,
                alarms: null,
                stoppedMachines: [],
                selectionStartTime: Date.now(),
                splitView: false,
                selectedSplitStops: [],
                selectedSplitStop: null,
                splitPoint: 0,
                splitCloseConfirm: false,
                renderComponent: true,
                showNextButton: true,
                lastCategorization: 0,
                viewMode: '',
                options: {
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    scales: {
                        y: {
                            beginAtZero: true,
                            stacked: true,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        datalabels: {
                            display: false,
                        },
                        tooltip: {
                            xAlign: 'left',
                            yAlign: 'bottom',
                            callbacks: {
                                title: function (context) {
                                    if (context[0].dataset.label === 'Production') {
                                        return ''
                                    } else {
                                        let start = new Date(context[0].dataset.start).format(VueInstance.get().$gettext("dd/MM HH:mm:ss"));
                                        let stop = new Date(context[0].dataset.stop).format(VueInstance.get().$gettext("dd/MM HH:mm:ss"));
                                        return VueInstance.get().$gettext("From {0} to {1} \n{2}").format(start, stop, context[0].dataset.timeTrackingId);
                                    }
                                },
                                label: function (context) {
                                    return ''
                                }
                            }
                        }
                    },
                    onClick: this.onStoppageClick
                },
                plugins: [],
                chartdata: {
                    labels: [],
                    datasets:
                        [
                            {
                                label: 'Production',
                                data: [],
                                backgroundColor: [this.$avStyle.colors.green,],
                                borderColor: ['black',],
                                borderWidth: 1,
                                order: 2,
                            }
                        ],
                },
            }
        },
        props: {
            user: {
                type: String,
                default: ""
            },
            dialogMode: {
                type: Boolean,
                default: false
            },
            showLongStopsOnly: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.fixedHeight = -1;
            this.fixedMaxTimeWindow = 'week'; //Limit max time window to 1 week
            this.properties.forcedDataRefreshInterval = 20;
            this.customQueryHandler = this.fetchData;
            this.childHandlers.checkItemUsefulness = () => { return true; };

            this.visualizationTweaks = [
                {
                    name: this.$gettext("Show as horizontal bars as default"),
                    id: "ShowAsHorizontalBars",
                    type: "bool",
                    default: function() {
                        return false;
                    }
        },
                {
                    name: this.$gettext("Show short stops"),
                    id: "ShowShortStops",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                },
            ]

        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    this.viewMode = this.getTweakValue("ShowAsHorizontalBars") ? 'Bars' : 'List';
                    this.fetchData()
                    this.saveTweaks();

                },
                deep: true,
            },
        },
        computed: {
            hourLabels() {
                let hoursArray = [];
                this.timeTrackingArray.forEach(item => {
                    if (item.start)
                        hoursArray.push(DateTimeUtils.getRfc3339TimeStamp(new Date(item.start).format("dd/MM HH" + ':00')))
                });
                hoursArray = hoursArray.filter((x, i, a) => a.indexOf(x) === i);
                return hoursArray
            },
            currentUser() {
                if(this.user)
                    return this.user;
                if(this.$root.userName)
                    return this.$root.userName;
                return "";
            },
            canBeClosed() {
                if(!this.dialogMode)
                    return false;
                if(this.forceFillIn) {
                    if(this.showLongStopsOnly)
                        return this.lineStops.length === 0;
                    else
                        return this.lineStops.length === 0 && this.lineShortStops.length === 0;
                }
                else return true;
            },
            splittable() {
                //At least a stop is selected and it's not a short one
                return (this.selectedLineStops.length === 1 && !this.selectedLineStops[0].selected);
            },
            shortStopsDuration() {
                let duration = 0;
                for(let stop of this.lineShortStops)
                    duration += (stop.stop - stop.start);
                return Math.round(duration / 60000);
            },
            longStopsDuration() {
                let duration = 0;
                for(let stop of this.lineStops)
                    duration += (stop.stop - stop.start);
                return Math.round(duration / 60000);
            },
            annotationWindowVisible() {
                return Array.isUseful(this.selectedLineStops) && !this.showNextButton;
            }
        },
        methods: {
            onStoppageClick(e){
                let points = this.$refs.graph.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);

                let firstPoint = points[0];
                let value = this.$refs.graph.chart.data.datasets[firstPoint.datasetIndex];
                if (value.show === 'Pending')
                    return;
                if (value.data[0][0] === 0 && value.data[0][1] === 60)
                    return;
                if (value.activity !== 2){
                    let stoppageReason = value.timeTrackingId;
                    if (!stoppageReason)
                        stoppageReason = value.show;
                    let start = DateTimeUtils.getRfc3339TimeStamp(new Date(value.start).format("dd/MM HH:mm"));
                    let stop = DateTimeUtils.getRfc3339TimeStamp(new Date(value.stop).format("dd/MM HH:mm"));
                    this.$root.showDialogBox(this.$gettext("Stoppage reason: {0}").format(stoppageReason), this.$gettext("Start: {0}; Stop: {1}").format(start, stop), "OK", null, "", null);
                    return
                }
                this.processStop(value);

                this.showWizardAlarms()
            },
            calculateDataset() {
                if(this.viewMode.toLowerCase() !== 'bars')
                    return;
                this.chartdata.labels = this.hourLabels;
                this.chartdata.datasets[0].data = [];
                for (let i = 0; i < this.hourLabels.length; i++) {
                    //cut minutes in last iteration
                    if (i + 1 === this.hourLabels.length){
                        //check if current time is seenable on the chart
                        if(this.hourLabels[this.hourLabels.length - 1] === new Date().format("dd/MM HH" + ':00')){
                            let minutes = new Date().format("mm");
                            this.chartdata.datasets[0].data.push([0, minutes])
                        }
                        else {
                            this.chartdata.datasets[0].data.push([0, 60])
                        }
                    }
                    else {
                        this.chartdata.datasets[0].data.push([0, 60])
                    }
                }
                this.chartdata.datasets.splice(1);
                let tmpTimeTrackingArray = this.timeTrackingArray.filter(item => item.activity !== 1);
                tmpTimeTrackingArray.forEach((item, index)=>{
                    let color = this.$avStyle.colors.orange;
                    if (item.activity === 2)
                        color = this.$avStyle.colors.red;
                    if (item.activity === 0)
                        color = this.$avStyle.colors.grey;
                    let startMinutes = (DateTimeUtils.getRfc3339TimeStamp(new Date(item.start).format("mm")))
                    let startHours = (DateTimeUtils.getRfc3339TimeStamp(new Date(item.start).format("dd/MM HH" + ':00')))

                    let stopMinutes = (DateTimeUtils.getRfc3339TimeStamp(new Date(item.stop).format("mm")))
                    let stopHours = (DateTimeUtils.getRfc3339TimeStamp(new Date(item.stop).format("dd/MM HH" + ':00')))

                    let datasetObj =
                        {
                            label: stopHours + stopMinutes,
                            order: 0,
                            id: index,
                            data: [],
                            backgroundColor: [color],
                            borderColor: ['black'],
                            borderWidth: 0.5,
                            ...item
                        }

                    let lineStopInNextHour = false
                    if (startHours !== stopHours) {
                        lineStopInNextHour = true
                    }
                    if (Array.isUseful(this.hourLabels)) {
                        for (let hour of this.hourLabels) {
                            if (hour !== startHours) {
                                datasetObj.data.push([])
                            } else {
                                datasetObj.data.push([startMinutes, lineStopInNextHour ? 60 : stopMinutes]);
                                if (lineStopInNextHour) {
                                    datasetObj.data.push([0, stopMinutes]);
                                }
                                break;
                            }

                        }
                    }
                    if (item.show === 'Pending'){
                        const img = new Image();
                        img.src = 'https://upload.wikimedia.org/wikipedia/commons/5/53/Font_Awesome_5_regular_clock.svg'

                        const barAvatar = {
                            id:'barAvatar',
                            afterDraw(chart, args, options) {
                                const {ctx, chartArea: { top, bottom, left, right, width, height},
                                    scales: { x, y }} = chart;
                                ctx.save();
                                let xValueStart = parseInt(datasetObj.data[datasetObj.data.length - 1][0])
                                let xValueEnd = parseInt(datasetObj.data[datasetObj.data.length - 1][1])
                                let imageWidth = x.getPixelForValue(xValueEnd) - x.getPixelForValue(xValueStart)
                                let imageWidthResized = imageWidth * 0.8
                                let maxHeight =  (y.getPixelForValue(1) - y.getPixelForValue(0)) * 0.4
                                if (imageWidthResized > maxHeight) {
                                    imageWidthResized = maxHeight
                                }
                                ctx.drawImage(img, x.getPixelForValue(((xValueStart + xValueEnd)/2)) - imageWidthResized/2, (y.getPixelForValue(datasetObj.data.length - 1) + ((y.getPixelForValue(0)-y.getPixelForValue(1))/6)), imageWidthResized, imageWidthResized)
                            }
                        }
                        this.plugins.push(barAvatar)
                    }
                    item.show = ''
                    this.chartdata.datasets.push(datasetObj)
                }, this)
                    if(this.$refs.graph) {
                        this.$refs.graph.refreshGraph();
                        this.plugins = []
                    }


            },
            initDialog(workorder, forceFillIn) {

                this.workOrder = workorder;
                this.forceFillIn = forceFillIn;

                if(Object.isUseful(this.workOrder) && this.workOrder) {
                    this.properties.timeWindow.setPredefined(86400); //Back 2 months should be enough to capture workorder
                } else {
                    let settings = this.$settings.getLineSettings();
                    if(Array.isUseful(settings.shiftHours)) {
                        let curShifts = Shifts.getShiftsInfo();
                        if(Array.isUseful(curShifts)) {
                            this.properties.timeWindow.setPredefined("shift");
                        }
                    }
                }

                if(!this.workOrder && !this.forceFillIn)
                    this.properties.title = this.$gettext("Line stops categorization");
                else {
                    this.properties.title = this.$gettext("Please categorize all line stops");
                    this.properties.showTimeWindow = false;
                }
            },
            initSplit() {
                this.selectedSplitStops = [
                    {
                        show: this.$timeTracking.formatStop(this.selectedLineStops[0]),
                        stop: this.selectedLineStops[0],
                        children: [],
                        split: 50,
                        done: false
                    }
                ];
                this.splitPoint = 50;
                this.splitItem();
                this.splitView = true;
                this.selectedLineStops.clear();
            },
            selectSplitItem(item, init) {
                this.selectedSplitStop = item;
                if(!init)
                    this.splitPoint = this.selectedSplitStop.split;
                if(!Array.isUseful(this.selectedSplitStop.children))
                    this.processStop(this.selectedSplitStop.stop);
                else this.selectedLineStops.clear();
            },
            splitItem() {
                if(!Object.isUseful(this.selectedSplitStop))
                    this.selectSplitItem(this.selectedSplitStops[0], true);
                this.selectedSplitStop.children.clear();
                this.selectedSplitStop.split = this.splitPoint;
                if(this.splitPoint !== 0 && this.splitPoint !== 100) {
                    let firstDuration = this.selectedSplitStop.stop.duration * (this.splitPoint / 100);
                    let firstStop = {
                        start: this.selectedSplitStop.stop.start,
                        stop: this.selectedSplitStop.stop.start + firstDuration,
                        duration: firstDuration,
                        isShort: false
                    };
                    let secondStop = {
                        start: firstStop.stop + 1000,
                        stop: this.selectedSplitStop.stop.stop,
                        duration: this.selectedSplitStop.stop.duration - firstDuration,
                        isShort: false
                    };
                    this.selectedSplitStop.children.push( { show: this.$timeTracking.formatStop(firstStop), stop: firstStop, children: [], split: 0, done: this.selectedSplitStop.done } );
                    this.selectedSplitStop.children.push( { show: this.$timeTracking.formatStop(secondStop), stop: secondStop, children: [], split: 0, done: this.selectedSplitStop.done } );
                }
            },
            fineSplitDown() {
                this.splitPoint -= (100 / this.calculateStep());
                this.splitItem();
            },
            fineSplitUp() {
                this.splitPoint += (100 / this.calculateStep());
                this.splitItem();
            },
            splitDone() {
                if(this.selectedSplitStops[0].done)
                    this.splitClose();
                else this.splitCloseConfirm = true;
            },
            splitClose() {
                this.splitView = false;
                this.selectedSplitStops = [];
                this.selectedSplitStop = null;
                this.splitPoint = 0;
                this.splitCloseConfirm = false;
                this.fetchData(true);
            },
            calculateStep() {
                let step = 0;
                if(this.selectedSplitStop.stop.duration < 60 * 5 * 1000)
                    step = (this.selectedSplitStop.stop.duration / 1000); //1 second steps
                else if(this.selectedSplitStop.stop.duration < 60 * 30 * 1000)
                    step = (this.selectedSplitStop.stop.duration / 5000); //5 seconds steps
                else if(this.selectedSplitStop.stop.duration < 60 * 60 * 1000)
                    step = (this.selectedSplitStop.stop.duration / 10000); //10 seconds steps
                else if(this.selectedSplitStop.stop.duration < 60 * 120 * 1000)
                    step = (this.selectedSplitStop.stop.duration / 60000); //1 minute steps
                else step = (this.selectedSplitStop.stop.duration / 300000); //5 minutes steps
                return step;
            },
            waitForRefresh() {
                return ((Date.now() - this.lastCategorization) < 5000) ||
                    (Array.isUseful(this.selectedLineStops) && ((Date.now() - this.selectionStartTime) < 60 * 5 * 1000));
            },
            selectUnselectAllShortStops() {
                this.selectAllShortStops = !this.selectAllShortStops;
                for(let stop of this.lineShortStops)
                    stop.selected = this.selectAllShortStops;
            },
            async processStop(selectedStop) {
               this.showNextButton = true;
               this.selectedLineStops.clear(); // this.alarmsDescriptor.alarmsDescriptions[index][header.locale] = value;
               this.forceRerender();
                if (selectedStop) {//long stops
                    this.unselectShortStops();
                    this.selectedLineStops.push(selectedStop);
                } else { //Short stops
                    this.selectedStopRadio = -1;
                    for (let stop of this.lineShortStops) {
                        if (stop.selected) {
                            this.selectedLineStops.push(stop);
                        }
                    }
                    this.splitView = false;
                }
                this.selectionStartTime = Date.now();

                //Get alarms for the only stop selected
                this.alarms = [];
                this.stoppedMachines = [];
                if (this.selectedLineStops.length === 1) {
                    let stopInfo = await this.$timeTracking.getAlarmsOnStop(DateTimeUtils.getRfc3339TimeStamp(new Date(this.selectedLineStops[0].start)));
                    if(stopInfo) {
                        this.alarms = stopInfo.alarms || [];
                        this.stoppedMachines = stopInfo.stoppedMachines || [];
                    }
                }
            },
            setCaller(caller) {
                this.callerInstance = caller;
            },
            setDialog(result) {
                if(result) {
                    if(this.splitView) {
                        this.selectedSplitStop.done = true;
                        //Strike out root stops when all children are assigned a cause
                        let striker = function(item) {
                            if(Array.isUseful(item.children)) {
                                let steady = true;
                                if (item.children[0].done && item.children[1].done) {
                                    if (!item.done)
                                        steady = false;
                                    item.done = true;
                                }
                                return (steady && striker(item.children[0]) && striker(item.children[1]))
                            }
                            return true
                        };
                        while(!striker(this.selectedSplitStops[0])) {}
                    }
                    else {
                        for (let stop of this.selectedLineStops)
                            if (stop.isShort)
                                this.lineShortStops.removeItem(stop);
                            else {
                                this.lineStops.removeItem(stop);
                                if(this.viewMode.toLowerCase() === 'bars') {
                                    this.timeTrackingArray[stop.id].show = 'Pending';
                                    this.calculateDataset()
                                }
                            }
                        this.lastCategorization = Date.now();
                    }
                }
                this.selectedLineStops.clear();
                this.selectedStopRadio = -1;
                this.unselectShortStops();
                this.showWizardAlarms();
            },
            unselectShortStops() {
                this.selectAllShortStops = true;
                this.selectUnselectAllShortStops();
            },
            fetchData(force) {
                if(this.waitForRefresh() && !force)
                    return;
                this.setDialog(false);
                this.lineStops.clear();
                this.lineShortStops.clear();
                this.selectedLineStops.clear();
                this.refreshing = true;

                if(this.viewMode === 'Bars') {
                    this.getTimeTracking()
                } else {
                    this.getUncategorizedStops()
                }
            },
            getUncategorizedStops() {
                let self = this;
                this.$timeTracking.getUncategorizedStops(DateTimeUtils.getRfc3339TimeStamp(this.properties.timeWindow.getStart(true)), DateTimeUtils.getRfc3339TimeStamp(this.properties.timeWindow.getEnd()), this.workorder)
                    .then(stops => {
                        let shortStopsThreshold = 3 * 60;
                        let workFlowSettings = self.$settings.getWorkFlowSettings();
                        if(workFlowSettings && Object.isUseful(workFlowSettings.shortStopsThreshold))
                            shortStopsThreshold = workFlowSettings.shortStopsThreshold;
                        for (let i = 0 ; i < stops.length ; i++) {
                            if (stops[i].duration < (shortStopsThreshold * 1000))
                                self.lineShortStops.insertItem(0, {
                                    start: stops[i].start,
                                    stop: stops[i].stop,
                                    duration: stops[i].duration,
                                    isShort: true,
                                    selected: false
                                });
                            else
                                self.lineStops.insertItem(0, {
                                    start: stops[i].start,
                                    stop: stops[i].stop,
                                    duration: stops[i].duration,
                                    isShort: false
                                });
                        }
                        self.$emit('hasStops', Array.isUseful(self.lineShortStops) || Array.isUseful(self.lineStops));
                    })
                    .finally(() =>{
                        self.refreshing = false;
                    })
            },
            getTimeTracking() {
                let self = this;
                let shortStopsThreshold = 3 * 60;
                self.timeTrackingArray = [];
                if (!this.getTweakValue("ShowShortStops")) {
                    let workFlowSettings = self.$settings.getWorkFlowSettings();
                    if (workFlowSettings && Object.isUseful(workFlowSettings.shortStopsThreshold))
                        shortStopsThreshold = workFlowSettings.shortStopsThreshold;
                }
                let timeTrackingHistory = self.$timeTracking.getHistory(DateTimeUtils.getRfc3339TimeStamp(this.properties.timeWindow.getStart(true)), DateTimeUtils.getRfc3339TimeStamp(this.properties.timeWindow.getEnd()))
                timeTrackingHistory.then((timeTracking) => {
                    self.timeTrackingArray = timeTracking.filter(item => item.duration > (this.getTweakValue("ShowShortStops") ? 0 : shortStopsThreshold * 1000))
                })
                .finally(() => {
                    self.calculateDataset();
                    self.refreshing = false;
                })
            },

            forceRerender() {
                // Remove my-component from the DOM
                this.renderComponent = false;

                this.$nextTick(() => {
                    // Add the component back in
                    this.renderComponent = true;
                });
            },

            showWizardAlarms() {
                this.showNextButton =! this.showNextButton;
            },
            toggleViewMode() {
                if(this.viewMode === 'Bars') {
                    this.viewMode = 'List'
            }
                else {
                    this.viewMode = 'Bars'
        }
                this.fetchData()
    }
        }
    }

</script>

<style scoped>
    .radio-group-full-size >>> .v-input__control {
        width: 100%;
    }
</style>
