import { render, staticRenderFns } from "./DataNormalizationBuilder.vue?vue&type=template&id=2e749d72&scoped=true&"
import script from "./DataNormalizationBuilder.vue?vue&type=script&lang=js&"
export * from "./DataNormalizationBuilder.vue?vue&type=script&lang=js&"
import style0 from "./DataNormalizationBuilder.vue?vue&type=style&index=0&id=2e749d72&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e749d72",
  null
  
)

export default component.exports