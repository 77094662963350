import axios from 'axios'
import Store from '@/store.js'
import Router from '@/router.js'
import Config from './config.js'

export default () => {
    var supervisorApi = axios.create({
        baseURL: `//`+ (Config.debug ? (window.location.hostname + `:9090`) : (window.location.hostname + (window.location.port ? (":" + window.location.port) : ""))) + `/supervisor`,
        withCredentials: true,
        timeout: 200000000,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

    supervisorApi.interceptors.request.use(function (config) {
        // Do something before request is sent
        config.headers.common['Authorization'] = 'Bearer ' + Store.state.token;
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    supervisorApi.interceptors.response.use(
        function (response) {
            //do nothing if ok
            return response;
        }, function (error) {
            if(error && error.response && 401 === error.response.status) {
                Store.dispatch('setSession', {
                    token: '',
                    userName: '',
                });

                Router.push({name: 'login'});
            } else {
                return Promise.reject(error);
            }
        });

    return supervisorApi;
}