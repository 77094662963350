<template>
    <v-card flat style="width: 100%" id="formConfigurator" ref="formConfigurator">
        <v-card-actions>
            <av-button :text="$gettext('Add Variable')" style="float: right;" flat @click="addVariable(true)" id="AddVariable" size="medium" iconColor="info" buttonIcon="fas fa-plus"/>
            <!--<v-btn @click="addRepeatableVariable(true)"  style="float: right;" text flat>-->
            <!--<av-stacked-icon color="info" small style="margin-right: 5px" mainIcon="fas fa-plus" slaveIcon="fas fa-redo"/>-->
            <!--Add repeatable variable(s)-->
            <!--</v-btn>-->
            <v-flex xs5>
                <v-layout row class="ml-2" v-if="representationMode.requiresInheritance">
                    <av-icon color="info" small style="margin-right: 5px">fas fa-arrow-down</av-icon>
                    <av-select v-model="includedEntity" :items="formNames" @input="fillWithIncludedEntity()" :label="$gettext('Inherit entity')"/>
                </v-layout>
            </v-flex>
            <v-flex xs5>
                <v-layout row class="ml-2" v-if="representationMode.requiresSystemVariables">
                    <av-icon color="info" small style="margin-right: 5px">fas fa-desktop</av-icon>
                    <av-select v-model="selectedWellKnown" :items="getWellKnownVariables()" @input="addWellKnownVariable()" :label="$gettext('Add system variable')"/>
                </v-layout>
            </v-flex>
            <div style="clear: both"></div>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-text :style="styleTree">
            <v-treeview flat :items="formVariables" item-text="name" class="title pa-0" v-if="formVariables.length>0"  :id="computedTreeviewId + 'FormVariables'">
                <template v-slot:prepend="{ item }">
                    <template>
                        <av-button iconColor="info" buttonIcon="fas fa-edit" fab small flat :dynamicID="item.name"
                                   @click="edit(item)" :disabled="fromOrphan && item.primaryCharacteristic" :toolTip="$gettext('Edit field properties')" class="my-0" :style="Array.isUseful(item.children) ? 'margin-left: 8px' : ''"/>
                        <av-button iconColor="info" buttonIcon="fas fa-plus" fab small flat :dynamicID="item.name"
                                   @click="addVariable(true,item)" style="margin-left: -8px" class="my-0"
                                   :toolTip="$gettext('Add dependant field')" :disabled="item.disableAddVariable"/>
                        <av-button iconColor="error" buttonIcon="fas fa-trash" fab small flat :dynamicID="item.name"
                                   @click="deleteVariable(item)" style="margin-left: -8px" class="my-0"
                                   :toolTip="$gettext('Delete field (and sub-fields)')" :disabled="!isItemDeletable(item)"/>
                    </template>
                </template>
                <template v-slot:append="{item}">
                    <v-icon color="info" small :toolTip="$gettext('Move one step up')"
                            @click="itemUp(item)" class="ml-2">fas fa-arrow-up</v-icon>
                    <v-icon color="info" small :toolTip="$gettext('Move one step down')"
                            @click="itemDown(item)" class="ml-2">fas fa-arrow-down</v-icon>
                    <av-icon small color="warning" style="margin-left:10px" v-if="item.primaryCharacteristic">fas fa-key</av-icon>
                    <!--<av-stacked-icon color="info" style="margin-left:10px" small @click="addRepeatableVariable(true, item)" mainIcon="fas fa-plus" slaveIcon="fas fa-redo"/>-->
                    <av-icon small style="margin-left: 10px; margin-right: 5px" v-if="item.includedEntity">fas fa-arrow-left</av-icon>
                    <span class="subheading">{{ item.includedEntity }}</span>
                </template>
            </v-treeview>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text v-if="selectedItem" :style="styleForm">
            <UploadButton ref="button" style="visibility: hidden; height: 1px;" @file-update="uploadImageCallback"></UploadButton>
            <v-layout column pa-2>
                <label v-if="selectedItem.includedEntity" class="mb-3 subheading">
                    <translate>*Variable is inherited from entity</translate><span class="font-weight-bold">{{" " + selectedItem.includedEntity}}</span></label>
                <v-layout row>
                    <v-flex xs6>
                        <av-text-field :disabled="isWellKnownVariable" :label="$gettext('Variable display name')"
                                       v-model="selectedItem.title" @keyup="calcVariableName(selectedItem)"></av-text-field>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs5>
                        <v-layout column>
                            <av-text-field v-if="!selectedItem.valueAsCategory" :disabled="isWellKnownVariable || !isItemNameEditable(selectedItem)"
                                           :label="$gettext('Variable name in DB')" v-model="selectedItem.name"></av-text-field>
                            <av-switch v-if="canBeValueAsCategory" :label="$gettext('Use value as variables category')" v-model="selectedItem.valueAsCategory"></av-switch>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <template v-if="canBeKey && !fromOrphan && representationMode.requiresPrimaryKey">
                    <av-switch :disabled="!extendable" :label="$gettext('Primary key')" v-model="selectedItem.primaryCharacteristic" @change="setKeyValidationConstraints()"/>
                    <v-radio-group class="ml-5" v-if="selectedItem.primaryCharacteristic" v-model="selectedItem.uniquenessLevel" row>
                        <v-radio id="radioEntityUniqueness" :label="$gettext('Value must be unique within this entity')" value="entity" :disabled="!extendable"></v-radio>
                        <v-radio class="mt-2" id="radioSystemUniqueness" :label="$gettext('Value must be unique in the whole system')" value="system" :disabled="!extendable"></v-radio>
                    </v-radio-group>
                </template>
                <av-textarea :label="$gettext('Variable description')" v-model="selectedItem.description" rows="3"></av-textarea>
                <av-expansion-panel v-if="!isRepeatableGroup(selectedItem)" id="expansionPanelVariableType" style="margin-bottom: 10px" expand>
                    <v-expansion-panel-content class="pa-2">
                        <template v-slot:header>
                            <div class="title pa-0 ma-0" style="width: 90%;"><translate>Variable type</translate></div>
                        </template>
                        <av-switch class="mt-2 mb-3" v-if="selectedItem.type.toLowerCase() === 'single option' && selectedItem.inputMode.toLowerCase() !== 'big buttons'  && selectedItem.inputMode.toLowerCase() !== 'scan'" :label="$gettext('Allow user to add values')" v-model="selectedItem.enableManualEntryForSingleOption"/>
                        <v-layout wrap row>
                            <v-flex xs6>
                                <av-select :label="$gettext('Variable type')" :disabled="isWellKnownVariable || !isItemTypeEditable(selectedItem)"
                                           v-model="selectedItem.type" :items="allowedTypes()" @input="selectedItem.expectedValue = null" @change="checkTypeConstraints"></av-select>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex xs5>
                                <av-select :label="$gettext('Input Mode')" :items="getInputModes(selectedItem)" v-model="selectedItem.inputMode"></av-select>
                            </v-flex>
                        </v-layout>
                        <v-layout wrap row class="mb-3">
                            <v-flex xs7 v-if="(selectedItem.type.toLowerCase() === 'single option' || selectedItem.type.toLowerCase() === 'multi options') && (!selectedItem.hasOwnProperty('disableOptions'))">
                                <av-button :text="$gettext('Get options from variable')" iconColor="info"  buttonIcon="fa fa-link"
                                           @click="showPickerDialog = true"/>
                            </v-flex>
                            <v-flex xs5 class="mt-2 font-weight-medium" v-if="linkedVariables.length > 0">
                                <translate>Chosen variables:</translate> {{ linkedVariablesChosen }}
                            </v-flex>
                            <VariablePickerDialog :showDialog="showPickerDialog"
                                                  @selectedItems="selectedVariables($event)" @close="showPickerDialog=false"
                                                  :variables="linkedVariables"
                                                  :select-field="true" :multiple="true" :select-root="false"/>
                        </v-layout>
                        <v-layout wrap row class="mb-3" v-if="(selectedItem.canBeLinked)">
                            <v-flex>
                                <v-radio-group v-model="selectedItem.linkedToParent" @click="optionsLinking" readonly>
                                    <v-radio :label="$gettext('Link this variable to parent: ') + parentObj.name" :value="parentObj.name"></v-radio>
                                    <v-radio label="Default no link" value=""></v-radio>
                                </v-radio-group>
                            </v-flex>
                        </v-layout>
                        <v-layout wrap row class="mb-3" v-if="(selectedItem.type === 'Single option' || selectedItem.type === 'Multi options') && !selectedItem.linkedToParent">
                            <v-flex>
                                <v-autocomplete
                                    v-model="optionsTo"
                                    :items="optionsTo"
                                    chips
                                    deletable-chips
                                    :label="singleMultiOptionsLabel"
                                    hide-no-data
                                    hide-selected
                                    multiple
                                    :search-input.sync="searchTo"
                                    v-on:keyup.native="(event)=>keyUp(event)"
                                    v-on:blur="(event)=>focusOut(event)"
                                    :id="computedAutocompleteId"
                                    :disabled="isOptionsDisabled(selectedItem)"
                                ></v-autocomplete>
                            </v-flex>
                        </v-layout>
                        <v-layout wrap row class="mb-3" v-if="(selectedItem.type === 'Single option' || selectedItem.type === 'Multi options') && selectedItem.linkedToParent">
                            <v-flex xs12 v-for="(option, key) in parentObj.options" :key="option + key">
                                <label>Options for: {{ option }}</label>
                                <v-autocomplete chips deletable-chips hide-no-data v-model="optionsObj[option]" :items="optionsObj[option]"
                                                hide-selected multiple :id="computedAutocompleteId + key" :disabled="isOptionsDisabled(selectedItem)"
                                                v-on:keyup.native="(event)=>keyUp(event, option)"
                                                v-on:blur="(event)=>focusOut(event, option)"
                                                :search-input.sync="searchToObject[option]"
                                ></v-autocomplete>
                            </v-flex>
                        </v-layout>
                        <v-layout wrap row class="mb-3" v-if="selectedItem.type === 'documents'" :key="refreshDocDelete">
                            <v-flex xs4>
                                <av-file-viewer v-if="Array.isArray(selectedItem.documents) && Array.isUseful(selectedItem.documents)"
                                                :file-name="selectedItem.documents[0].FileName" :type="selectedItem.documents[0].Type" :mediaId="selectedItem.documents[0].MediaId"
                                                :file="selectedItem.documents[0].Value" :medium="false" :small="true" :vertical="true" class="pa-0" :allowDownload="false">
                                    <template slot="appendButton">
                                        <v-btn flat icon small @click="documentDelete" >
                                            <av-icon color="error" small>fa-trash</av-icon>
                                        </v-btn>
                                    </template>
                                </av-file-viewer>
                                <av-button v-else class="ma-2" :text="$gettext('Choose document')" color="info" buttonIcon="fas fa-file-invoice"
                                           iconColor="light" @click="openChooseFileDialog"/>
                            </v-flex>
                        </v-layout>
                    </v-expansion-panel-content>
                </av-expansion-panel>
                <av-expansion-panel id="expansionPanelValidation" style="margin-bottom: 10px" expand>
                    <v-expansion-panel-content class="pa-2">
                        <template v-slot:header>
                            <div class="title pa-0 ma-0" style="width: 90%;"><translate>Value validation</translate></div>
                        </template>
                        <av-switch class="mt-2 mb-3" :disabled="isWellKnownVariable || selectedItem.type === 'Boolean' || selectedItem.primaryCharacteristic" :label="$gettext('Value is required')" v-model="selectedItem.mandatory"/>

                        <template v-if="!isRepeatableGroup(selectedItem) && selectedItem.type !== 'Files'" :disabled="isWellKnownVariable">
                            <template v-if="!isRepeatableGroup(selectedItem)">
                                <av-text-field v-if="selectedItem.type==='Number'" :label="$gettext('Min value')" type="number" v-model.number="selectedItem.min"></av-text-field>
                                <av-text-field v-if="selectedItem.type==='Number'" :label="$gettext('MAX value')" type="number" v-model.number="selectedItem.max"></av-text-field>
                                <av-text-field v-if="selectedItem.type==='Number'" :label="$gettext('Value step')" type="number" v-model.number="selectedItem.step"></av-text-field>
                                <av-text-field v-if="selectedItem.type==='Multi options'" :label="$gettext('Required choices count')" type="number" v-model.number="selectedItem.minChoices"></av-text-field>
                                <av-text-field v-if="selectedItem.type==='Multi options'" :label="$gettext('Maximum choices')" type="number" v-model.number="selectedItem.maxChoices"></av-text-field>
                            </template>
                            <av-switch :label="$gettext('Set an expected value for variable')" v-if="selectedItem.type!=='Datetime'" :value="selectedItem.expectedValue !== null" @change="toggleExpectedValueControl($event)"/>
                            <v-layout row v-if="selectedItem.expectedValue != null" class="ml-3 mt-2">
                                <field  :variable="selectedItem"
                                        :label="'Expected Value'"
                                        :preview="selectedItem.type.toLowerCase() === 'boolean'"
                                        :show-description="false"
                                        configurator=true
                                        v-model="selectedItem.expectedValue"/>
                                <av-button :text="$gettext('Generate QR')" style="float: right;"
                                           v-if="selectedItem.expectedValue && selectedItem.inputMode === 'Scan' && selectedItem.type !=='Boolean'"
                                           @click="generateQR(selectedItem.expectedValue)" id="qrCode" size="medium"
                                           iconColor="info" buttonIcon="fas fa-qrcode" flat/>
                            </v-layout>
                            <template v-if="selectedItem.type === 'Text'">
                                <av-text-field class="mt-2" :disabled="Object.isUseful(selectedItem.expectedValue)" :label="$gettext('Regex validation')" type="text-number" v-model="selectedItem.regex" />
                                <av-text-field class="mt-2" :disabled="Object.isUseful(selectedItem.expectedValue)" :label="$gettext('Regex bad validation message')" type="text-number" v-model="selectedItem.regexMessage" />
                            </template>
                            <!-- item has explicit id since it was renamed after TA already started before renaming -->
                            <av-switch class="mt-2" :label="$gettext('Allow saving only if validation succeeds')" :disabled="isWellKnownVariable" v-model="selectedItem.mustMatch" id="exploreformsSwitchValueMustMatchExpected"/>
                        </template>
                    </v-expansion-panel-content>
                </av-expansion-panel>
                <av-expansion-panel v-if="!isRepeatableGroup(selectedItem)" id="expansionPanelAdvanced" style="margin-bottom: 10px" expand>
                    <v-expansion-panel-content class="pa-2">
                        <template v-slot:header>
                            <div class="title pa-0 ma-0" style="width: 90%;"><translate>Advanced options</translate></div>
                        </template>
                        <v-layout wrap column>
                            <av-switch :label="$gettext('Require photo evidence')" :value="selectedItem.requireEvidence" @change="onRequireEvidenceChange" :disabled="isGroupParent(selectedItem)"/>
                            <av-switch :label="$gettext('Hide value hint')" :value="selectedItem.hideHints" @change="onHideHintsChange"/>
                            <v-layout wrap row>
                                <av-button :text="$gettext('Icon')" color="info" iconColor="light" @click="chooseIcon" id="icon" buttonIcon="fa fa-image"/>
                                <av-button :text="$gettext('Clear Icon')" color="info" iconColor="light" v-if="selectedItem.icon" @click="selectedItem.icon=''" id="clearIcon"
                                           size="medium" buttonIcon="fa fa-trash"/>
                            </v-layout>
                            <av-text-field :label="$gettext('Display unit of measurement')" v-model="selectedItem.unit" v-if="selectedItem.type!=='Datetime'"></av-text-field>
                        </v-layout>
                    </v-expansion-panel-content>
                </av-expansion-panel>
                <!--<av-expansion-panel v-if="representationMode.requiresJoin" id="expansionPanelJoin" style="margin-bottom: 10px" expand>-->
                <!--<v-expansion-panel-content pa-2">-->
                <!--<template v-slot:header>-->
                <!--<div class="title pa-0 ma-0" style="width: 90%;" v-translate>Join entity</div>-->
                <!--</template>-->
                <!--<v-layout wrap column>-->
                <!--<av-select :value="selectedItem.join.entity" :items="allEntitiesId"-->
                <!--:label="$gettext('Select target join entity')" @input="onEntityChange"/>-->
                <!--<av-select :value="selectedItem.join.key" :items="attributesOfSelectedEntity"-->
                <!--:label="$gettext('Select join field')" @input="onEntityKeyChange"/>-->
                <!--</v-layout>-->
                <!--</v-expansion-panel-content>-->
                <!--</av-expansion-panel>-->
                <av-expansion-panel v-if="representationMode.isEntity || representationMode.isTransaction" id="expansionPanelVisibility" style="margin-bottom: 10px" expand>
                    <v-expansion-panel-content class="pa-2">
                        <template v-slot:header>
                            <div class="title pa-0 ma-0" style="width: 90%;">Visibility</div>
                        </template>
                        <v-layout wrap column>
                            <v-flex xs4>
                                <av-switch style="margin-top: 10px;" :label="$gettext('Create form entry for this variable')" v-model="selectedItem.userExposed"></av-switch>
                            </v-flex>
                            <v-spacer></v-spacer>
                        </v-layout>
                    </v-expansion-panel-content>
                </av-expansion-panel>
            </v-layout>
        </v-card-text>
        <template v-else>
            <center><h4 translate>Please select a variables group at the top</h4></center>
        </template>
        <v-dialog v-model="qrToPrint" width="500" v-if="qrToPrint" :id="computedDialogId + 'QrPrint'">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    QR Content: {{ qrValue }}
                </v-card-title>
                <v-card-text>
                    <div id="QR">
                        <center>
                            <vue-qrcode :value="qrValue"/>
                        </center>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <av-button :text="$gettext('PRINT')" color="green darken-1" flat="flat" @click="printQR()" id="print"/>
                    <av-button :text="$gettext('CLOSE')" color="red darken-1" flat="flat" @click="qrToPrint = false"/>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="chooseFileDialog" scrollable max-width="820px">
            <v-card>
                <v-tabs v-model="activeTab" centered grow>
                    <template>
                        <v-tab key="newDoc">{{ $gettext('Upload new document') }}</v-tab>
                        <v-tab-item key="newDoc">
                            <UploadDocument :allowOnlyOneFile="true" :uploadDocumentObject="uploadDocumentObject"/>
                        </v-tab-item>
                    </template>
                    <template>
                        <v-tab key="extDoc">{{ $gettext('Choose existing document') }}</v-tab>
                        <v-tab-item key="extDoc">
                            <v-card-actions class="mt-4">
                                <av-text-field v-if="activeTab !== 0" class="ml-4" :label="$gettext('Filter by name')" style="max-width: 250px"
                                               v-model="filterByName"></av-text-field>
                                <av-select v-if="activeTab !== 0" class="ml-4 control" :label="$gettext('Filter by type')" style="max-width: 250px"
                                           v-model="docTypeSelected" :items="availableDocumentTypes"></av-select>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                            <v-card-text style="height: 600px; max-height: 600px">
                                <v-layout column>
                                    <template v-for="row in rowArray">
                                        <v-layout row style="max-width: 820px">
                                            <template v-for="file in row">
                                                <av-file-viewer :file-name="file.FileName" :type="file.Type" :mediaId="file.MediaId" :file="file.Value"
                                                                :medium="false" :small="true" :vertical="true" class="pa-0" :allowDownload="false" :key="file.FileName"
                                                                @documentClicked="documentSelected(file)" :clickableDocument="true">
                                                </av-file-viewer>
                                            </template>
                                        </v-layout>
                                    </template>
                                </v-layout>
                            </v-card-text>
                        </v-tab-item>
                    </template>
                </v-tabs>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="activeTab === 0" color="green darken-1" flat="flat" v-translate :disabled="disableUploadButton" @click="uploadDocument">
                        Upload
                    </v-btn>
                    <v-btn color="red darken-1" flat="flat" v-translate @click="chooseFileDialog = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <av-loader :loading="loading" :message="message" minTime="1500"/>
    </v-card>
</template>

<script>

    import UploadButton from 'vuetify-upload-button';
    import Field from './Field';
    import VueQrcode from 'vue-qrcode';
    import Entity from "@/api/entities";
    import Transactions from "@/api/transactions";
    import Defines from "@/api/defines";
    import VariablePickerDialog from '@/components/dialogs/VariablePickerDialog';

    export default {
        name: "FormConfigurator",
        props: {
            formVariables: {
                type: Array,
                default: null
            },
            form: {
                type: Object,
                default: null
            },
            representationMode: {
                type: Object,
                default: null
            },
            fromOrphan: {
                default: false
            },
        },
        components: {
            UploadButton, Field, VueQrcode, VariablePickerDialog
        },
        data: () => {
            return {
                selectedEntity: null,
                selectedItem: null,
                optionsTo: [],
                itemValues: [],
                optionsObj: {},
                searchOptions: [],
                searchTo: "",
                searchToObject: {},
                typeEvidence: ["Photo"],
                height: 0,
                qrToPrint: false,
                qrValue: "",
                entityPrototype: {id: "", properties: [], namespace: "", EntityKeys: []},
                transactionPrototype: {id: "", properties: [], namespace: ""},
                allEntitiesId: [],
                allEntities: [],
                joinChildSelected: true,
                attributesOfSelectedEntity: [],
                selectedEntityPrototype: null,
                selectedEntityPrototypeName: null,
                wellKnownVariables: null,
                selectedKnownVariable: '',
                isWellKnownVariable: false,
                selectedFromEditButton: false,
                selectedFromWellKnownComboBox: false,
                selectedWellKnown: '',
                formNames: [],
                includedEntity: "",
                includedEntityKey: "",
                showPickerDialog: false,
                linkedVariables: [],
                parentObj: null,
            }
        },
        mounted() {
            if(this.representationMode.isEntity){
                this.$root.setCurrentPageTitle(this.$gettext("Entities configuration"));
            }
            else if(this.representationMode.isTransaction){
                this.$root.setCurrentPageTitle(this.$gettext("Transactions configuration"));
            }
            this.$dynamicElements.getMetadataList("forms", true, false,false,true,true)
                .then((forms) => {
                    forms.map(form => { 
						if(form.name !== this.form.properties.name && (form.type === 3002 || form.type === 3004)) 
							this.formNames.push(form.name)})
                });
            this.wellKnownVariables = Defines.getWellKnownVariables();
            if (this.representationMode.isEntity || this.representationMode.isTransaction) {
                this.getAllEntityPrototypes();
            }
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            this.$root.$on('sendPrototypeTitle', (value) => {
                debugger;
                this.entityPrototype.id = value
            });
            this.$root.$on('sendPrototypeFormNamespace', (value) => {
                debugger;
                this.entityPrototype.namespace = value
            });
        },
        computed: {
            typeOptions() {
                return [
                    {value: 'Number', text: this.$gettext("Number")},
                    {value: 'Text', text: this.$gettext("Text")},
                    {value: 'Boolean', text: this.$gettext("Boolean")},
                    {value: 'Single option', text: this.$gettext("Single option")},
                    {value: 'Multi options', text: this.$gettext("Multi options")},
                    {value: 'Files', text: this.$gettext("Files")},
                    {value: 'Datetime', text: this.$gettext("Datetime")},
                    {value: 'repeatableGroup', text: this.$gettext("Group")}
                ]
            },
            computedDialogId() {
                return this.toCamelCase(this.$router.history.current.name + 'Dialog')
            },
            computedAutocompleteId() {
                return this.toCamelCase(this.$router.history.current.name + 'Autocomplete' + this.selectedItem.type)
            },
            computedTreeviewId() {
                return this.toCamelCase(this.$router.history.current.name + 'TreeView')
            },
            styleTree() {
                return {
                    height: '250px',
                    'overflow-y': 'auto',
                    padding: '5px 0 0 0'
                }
            },
            styleForm() {
                return {
                    'overflow-y': 'auto',
                    height: (this.height > 0 ? this.height - 320 : 400) + 'px'
                }
            },
            canBeKey() {
                return (this.selectedItem.isRootVariable && this.representationMode.requiresPrimaryKey &&
                    (this.selectedItem.type === 'Text' || this.selectedItem.type === 'Number' || this.selectedItem.type === 'Single option'));
            },
            isGroupParent() {
                return variable => {
                    return variable.type === "Boolean" && Array.isUseful(variable.children);
                }
            },
            canBeValueAsCategory() {
                let returning = !this.isRepeatableGroup(this.selectedItem) && this.representationMode.requiresValueAsCategory && (this.selectedItem.type === 'Text' || this.selectedItem.type === 'Single option');
                if(!returning)
                    this.selectedItem.valueAsCategory = false;
                return returning;
            },
            variables() {
                if (this.selectedItem != null && this.selectedItem.hasOwnProperty('linkedVariables'))
                    return this.selectedItem.linkedVariables;
                return  [];
            },
            linkedVariablesChosen() {
                let message = "";
                if (this.linkedVariables.length > 0) {
                    this.linkedVariables.forEach(variable => {
                        message += " " + variable.name + ";"
                    })
                }
                return message;
            },
            singleMultiOptionsLabel() {
                return Array.isUseful(this.linkedVariables)? "Additional options" : "Options";
            }
        },
        asyncComputed: {
            extendable() {
                //Allow full modification only if form has no dependencies
                if(this.form)
                    if(this.form.properties.unDeletable)
                        return this.form.canBeDeleted();
                    else return true;
                return false;
            },
        },
        methods: {
            isItemNameEditable(item) {
                return ( (!Object.isUseful(item.editableName) || item.editableName))
            },
            isItemTypeEditable(item) {
                return ( (!Object.isUseful(item.editableType) || item.editableType))
            },
            isItemDeletable(item) {
                return (!item.undeletable);//this.isItemNameEditable(item) && this.isItemTypeEditable(item))
            },
            isOptionsDisabled(item) {
                return (Object.isUseful(item.disableOptions) && item.disableOptions)
            },
            isRepeatableGroup(item) {
                return (item.type === "repeatableGroup");
            },
            cancelWellKnownVariable() {
                this.selectedItem.name = '';
                this.selectedItem.title = '';
            },
            clearWellKnown() {
                this.selectedWellKnown = '';
                this.isWellKnownVariable = false
            },
            checkForWellKnownVariable(item) {
                let knownVariable = Defines.getWellKnownVariable(item.title);
                knownVariable.length > 0 ? this.isWellKnownVariable = true : this.isWellKnownVariable = false
            },
            saveUniqueValues() {
                this.$dynamicElements.LoadItem(this.includedEntity, "forms")
                    .then(t => {
                        JSON.parse(t.descriptor).formVariables.map(variable => {
                            if (this.formVariables.find(form => form.name === variable.name)) {
                            } else {
                                variable.includedEntity = this.includedEntity;
                                this.formVariables.push(variable)
                            }
                        })
                    })
                    .catch(t => {
                        console.error(t);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving form {0} info from DB.").format(this.formToLoad), true);
                    });
            },
            fillWithIncludedEntity() {
                this.$dynamicElements.LoadItem(this.includedEntity, "forms")
                    .then(t => {
                        let found = false;
                        JSON.parse(t.descriptor).formVariables.map(variable => {
                            if (this.formVariables.find(form => form.name === variable.name)) {
                                this.$root.showDialogBox("You have some duplicated variables. Do you want to add unique variables?", "", "YES", this.saveUniqueValues, "NO", null)
                                found = true
                            }
                        });
                        if (!found) {
                            JSON.parse(t.descriptor).formVariables.map(variable => {
                                variable.includedEntity = this.includedEntity;
                                this.formVariables.push(variable)
                            })
                        }
                    })
                    .catch(t => {
                        console.error(t);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving form {0} info from DB.").format(this.formToLoad), true);
                    });
            },
            fillWellKnownVariable() {
                if (this.selectedFromWellKnownComboBox) {
                    this.selectedItem.name = this.selectedWellKnown;
                    this.selectedItem.title = this.selectedWellKnown;
                }
                let hasDuplicate = this.checkForDuplicateFormVariables();
                if (!hasDuplicate) {
                    this.isWellKnownVariable = true;
                    this.selectedWellKnown = this.selectedItem.name;
                    this.selectedItem.title = this.selectedWellKnown;
                    let knownVariable = Defines.getWellKnownVariable(this.selectedWellKnown);
                    this.selectedItem.type = knownVariable[0].type;
                    this.selectedItem.mandatory = knownVariable[0].mandatory;
                    this.selectedItem.mustMatch = knownVariable[0].mustMatch;
                } else {
                    this.selectedItem.name = '';
                    this.selectedItem.title = '';
                    this.clearWellKnown();
                    this.$nextTick(() => {
                        this.selectedWellKnown = null
                    })
                }
              return null
            },
            checkForDuplicateFormVariables() {
              let hasDuplicate = false;
              this.formVariables.map(v => v.name).sort().sort((a, b) => {
                if (a === b) hasDuplicate = true
              });
              return hasDuplicate
            },
            getWellKnownVariables() {
                let namesOfWellKnownVariables = [];
                if(this.wellKnownVariables)
                    this.wellKnownVariables.map(item => namesOfWellKnownVariables.push(item.id));
                return namesOfWellKnownVariables;
            },
            getAllEntityPrototypes() {
                Entity.getPrototypes().then(list => {
                    this.allEntities = list;
                    if (Array.isUseful(list)){
                        list.map(item => this.allEntitiesId.push(item.id))
                    }

                })
            },
            getInputModes(item) {
                if (item.type === "Single option" || item.type === "Multi options")
                    return [
                        {value: 'Normal', text: this.$gettext("Normal")},
                        {value: 'Scan', text: this.$gettext("Scan")},
                        {value: 'Normal + Scan', text: this.$gettext("Normal + Scan")},
                        {value: 'Big buttons', text: this.$gettext("Big buttons")},
                        ];
                else if (item.type === "Files" || item.type ==="Datetime")
                    return [{value: 'Normal', text: this.$gettext("Normal")}];
                else return [
                    {value: 'Normal', text: this.$gettext("Normal")},
                    {value: 'Scan', text: this.$gettext("Scan")},
                    {value: 'Normal + Scan', text: this.$gettext("Normal + Scan")}
                ];
            },
            checkUnique (vars, varName) {
                for (let v of vars) {
                    if (v.name === varName)
                        return true;
                    if(Array.isUseful(v.children)) {
                        let duplicate = this.checkUnique(v.children,varName);
                        if(duplicate)
                            return true;
                    }
                }
                return false;
            },
            addVariable(enableEdit, variable = null, wellKnown = false) {
                if(!wellKnown) {
                    this.$nextTick(() => {
                        this.selectedWellKnown = null
                    });
                    this.isWellKnownVariable = false;
                }
                //Assign default name to variable
                let varIndex = 0;
                let countVariables = function(vars) {
                    varIndex += vars.length;
                    for(let _var of vars)
                        if(Array.isUseful(_var.children))
                            countVariables(_var.children);
                };
                countVariables(this.formVariables);

                //Check again for uniqueness

                while(true) {
                    let hasDuplicate = this.checkUnique(this.formVariables, "NewField_" + varIndex);
                    if(!hasDuplicate)
                        break;
                    else varIndex++;
                }

                if(!variable) {
                    let findGlobalRoot = function(vars) {
                        for (let v of vars) {
                            if(variable)
                                break;
                            if (v.isGlobalRootVariable) {
                                variable = v;
                                break;
                            }
                            if(Array.isUseful(v.children)) {
                                findGlobalRoot(v.children)
                            }
                        }
                    };
                    findGlobalRoot(this.formVariables);
                }

                let tmpVariable = {
                    id: this.$utils.timeTag(),
                    name: "NewField_" + varIndex,
                    title: "New field " + varIndex,
                    description: "",
                    icon: "",
                    inputMode: "Normal",
                    type: "Text",
                    minChoices: 0,
                    maxChoices: null,
                    min: "",
                    max: "",
                    step: "",
                    unit: "",
                    userExposed: true,
                    requireEvidence: "",
                    value: "",
                    evidence: "",
                    mandatory: true,
                    mustMatch: false,
                    expectedValue: null,
                    options: [],
                    children: [],
                    repeatableChildren: [[]],
                    hideHints: false,
                    valueAsCategory: false,
                    disableAddVariable: false,
                    enableManualEntryForSingleOption: false,
                    editableName: true,
                    editableType: true,
                    join: {entity: "", key: ""},
                    primaryCharacteristic: false,
                    isRootVariable: true,
                    isGlobalRootVariable: false,
                    deployed: false,
                    uniquenessLevel: "entity",
                    isTransaction: false,
                    entityPrototype: this.entityPrototype.id,
                    linkedVariables:[],
                    allowedTypes: [], //Specify some types to restrict this variable, can only be specified in dedicate templates like workorders
                    parent: null,
                    linkedToParent: "",
                };
                if (variable) {
                    tmpVariable.isRootVariable = false;
                    tmpVariable.parentId = variable.id;
                    variable.children.push(tmpVariable);
                    if(this.isGroupParent(variable))
                        variable.requireEvidence = false;
                    if (this.isRepeatableGroup(variable))
                        variable.repeatableChildren[0].push(tmpVariable);
                } else
                    this.formVariables.push(tmpVariable);

                if (enableEdit) {
                    this.selectedItem = variable ? variable.children[variable.children.length - 1] : this.formVariables[this.formVariables.length - 1];
                    //clear actual optionsTo and linkedVariables
                    this.optionsTo=[]
                    this.linkedVariables=[]
                    this.optionsObj={}
                }
            },
            allowedTypes() {
                if(Array.isUseful(this.selectedItem.allowedTypes))
                    return this.selectedItem.allowedTypes;
                else return this.typeOptions;
            },
            addWellKnownVariable() {
                this.addVariable(true, null, true);
                //this.selectedWellKnown = wellKnownVariable;
                this.selectedFromWellKnownComboBox = true;
                this.fillWellKnownVariable();
            },
            addRepeatableVariable(enableEdit, variable = null) {
                let tmpVariable = {
                    name: "",
                    title: "",
                    description: "",
                    icon: "",
                    type: "repeatableGroup",
                    mandatory: true,
                    children: [[]],
                    hideHints: false
                };
                if (variable)
                    variable.children.push(tmpVariable);
                else
                    this.formVariables.push(tmpVariable);

                if (enableEdit) {
                    this.selectedItem = variable ? variable.children[variable.children.length - 1] : this.formVariables[this.formVariables.length - 1];
                }
            },
            deleteVariable(variable) {
                this.formVariables.removeItemRecursive(variable, "children");
                this.selectedItem = null;
            },
            calcVariableName(variable) {
                if(!this.isItemNameEditable(variable))
                    return;
                variable.name = this.$utils.camelize(variable.title, true);
                this.checkName(variable)
                this.selectedFromEditButton = false;
                this.selectedFromWellKnownComboBox = false
            },
            edit(item) {
                this.selectedFromEditButton = true;
                this.selectedItem = item;
                if (this.selectedItem.parentId) {
                    let item = this.formVariables.findItemByKeyRecursive('id', this.selectedItem.parentId);
                    this.parentObj = item.items[item.itemIndex];
                } else
                    this.parentObj = {};
                this.checkForWellKnownVariable(item);
                this.selectedWellKnown = this.selectedItem.name;
                if (this.selectedItem.type === 'Single option' || this.selectedItem.type === 'Multi options') {
                    if (this.selectedItem.hasOwnProperty('linkedToParent') && this.selectedItem.linkedToParent !== '') {
                        let options = [];
                        for (let key in this.selectedItem.options) {
                            options.push.apply(options, this.selectedItem.options[key]);
                        }
                        this.optionsTo = options;
                        this.optionsObj = this.selectedItem.options;
                    } else {
                        this.optionsTo = this.selectedItem.options;
                    }
                }
                this.attributesOfSelectedEntity = [];
                this.selectedEntityPrototype = this.allEntities.find(entity => entity.id === this.selectedItem.join.entity);
                this.selectedEntityPrototype && this.selectedEntityPrototype.properties && this.selectedEntityPrototype.properties.map(prop => this.attributesOfSelectedEntity.push(prop.name));
                this.joinChildSelected = false;
                if (this.selectedItem.linkedVariables && Array.isUseful(this.selectedItem.linkedVariables))
                    this.linkedVariables = this.selectedItem.linkedVariables;
                if ((this.selectedItem.type === 'Single option' || this.selectedItem.type === 'Multi options')
                    && (this.parentObj && (this.parentObj.type === 'Single option' || this.parentObj.type === 'Multi options'))) {
                    this.selectedItem.canBeLinked = true;
                } else {
                    this.selectedItem.canBeLinked = false;
                }
                if (this.checkLinkedOptions()) {
                    this.selectedItem.linkedToParent = this.parentObj.name;
                } else if (Array.isUseful(this.selectedItem.options)) {
                    this.selectedItem.linkedToParent = "";
                }
            },
            async deploy() {
                let payload = {
                    keysForSearch: [],
                    entityName: ""
                }
                if(this.representationMode.isTransaction && this.form && await this.form.deployElement()) {
                    return true
                }
                //TODO Move prototype logics to entity template thus also move this validator there
                //Check joins correcteness
                // if (this.representationMode.isEntity || this.representationMode.isTransaction) {
                //     this.formVariables.forEach(element => {
                //         if (element.join.entity === "" && element.join.key === "") {
                //             this.entityPrototype.properties.push({name: element.name, type: element.type, uniquenessLevel: (element.primaryCharacteristic ? element.uniquenessLevel : "none")})
                //         } else if (element.join.entity !== "" && element.join.key !== "") {
                //             this.entityPrototype.properties.push({name: element.name, type: element.type, uniquenessLevel: (element.primaryCharacteristic ? element.uniquenessLevel : "none"), join: element.join})
                //         } else {
                //             this.$root.showErrorNotification(this.$gettext("You must fill both of join entity and key"), true, true);
                //             return false;
                //         }
                //         if (element.primaryCharacteristic) {
                //             this.entityPrototype.EntityKeys.push(element.name)
                //             payload.keysForSearch.push(element.name)
                //         }
                //     });
                //     this.entityPrototype.EntityKeys.push(this.entityPrototype.id)
                //     payload.entityName = this.entityPrototype.id
                //     if(this.form && await this.form.deployElement()) {
                //         //Deploy prototype
                //         Entity.createPrototype(null, this.entityPrototype);
                //         Transactions.updateOrphanTransactionsAddEntityName(payload)
                //
                //         //Entity.getAllEntitiesAndChangeStatus(this.entityPrototype.id);
                //         this.entityPrototype.properties = [];
                //         return true;
                //
                //     }
                // }
                return false;
            },
            async undeploy() {
                if(this.form && await this.form.undeployElement()) {
                    //Deploy prototype
                    let payload = {}
                    payload["EntityName"] = this.entityPrototype.id
                    if (this.representationMode.isEntity || this.representationMode.isTransaction) {
                        Entity.deletePrototype(this.entityPrototype.id)
                        Transactions.updateOrphanTransactionsStatusAfterEntityDelete(payload)
                    }
                    //Entity.getAllEntitiesAndChangeStatus(this.entityPrototype.id);
                    return true
                }
                return false;
            },
            keyUp(event, option) {
                if (event.key === ";" || event.key==="Enter") {
                    this.addOption(event, option)
                }
            },
            focusOut(event, option) {
                this.addOption(event, option)
            },
            optionsLinking() {
                if (!this.selectedItem.linkedToParent) {
                    if (Array.isUseful(this.selectedItem.options)) {
                        this.$root.showDialogBox(this.$gettext("This will remove previous saved options"), null, "Yes", this.resetOptions, "CANCEL", null);
                    } else {
                        this.resetOptions();
                    }
                } else {
                    if (this.checkLinkedOptions()) {
                        this.$root.showDialogBox(this.$gettext("This will remove previous saved options"), null, "Yes", this.resetLinkedOptions, "CANCEL", null);
                    } else {
                        this.resetLinkedOptions();
                    }
                }
            },
            checkLinkedOptions() {
                let self = this;
                let hasData = false;
                Object.keys(self.selectedItem.options).forEach(function(key,index) {
                    if (Array.isUseful(self.selectedItem.options[key]))
                        hasData = self;
                });
                return hasData;
            },
            resetOptions() {
                this.selectedItem.options = {};
                this.optionsObj = {};
                this.parentObj.options.forEach(option => {
                    this.$set(this.optionsObj, option, []);
                    this.$set(this.selectedItem.options, option, []);
                    this.$set(this.searchToObject, option, "");
                });
                this.selectedItem.linkedToParent = this.parentObj.name;
            },
            resetLinkedOptions() {
                this.optionsTo = [];
                this.selectedItem.options = [];
                this.selectedItem.linkedToParent = "";
            },
            addOption(event, option) {
                let text = event.target.value ? event.target.value.replace(";", "") : "";
                let isLinkedOptions = this.selectedItem.linkedToParent && option;
                if (text !== "") {
                    let tmp = "";
                    if (isLinkedOptions)
                        tmp = this.optionsObj[option].find(el => el === text);
                    else
                        tmp = this.optionsTo.find(el => el === text);
                    if (!tmp)
                        (isLinkedOptions)? this.optionsObj[option].push(text): this.optionsTo.push(text);
                    if (isLinkedOptions)
                        tmp = this.selectedItem.options[option].find(el => el === text);
                    else
                        tmp = this.selectedItem.options.find(el => el === text);
                    if (!tmp)
                        (isLinkedOptions)? this.selectedItem.options[option].push(text) : this.selectedItem.options.push(text);

                    (isLinkedOptions)? this.searchToObject[option] = "" : this.searchTo = "";
                }
            },
            onRequireEvidenceChange(event) {
                this.selectedItem.requireEvidence = event ? "photo" : "";
            },
            onEntityKeyChange(event) {
                this.selectedItem.join.key = event || "";
                this.selectedItem.join.name = this.selectedItem.name
            },
            onEntityChange(event) {
                this.selectedItem.join.entity = event || "";
                this.selectedItem.join.key = "";
                this.selectedItem.join.name = this.selectedItem.name;

                this.attributesOfSelectedEntity = [];
                this.selectedEntityPrototype = this.allEntities.find(item => item.id === this.selectedItem.join.entity);
                this.selectedEntityPrototype.properties && this.selectedEntityPrototype.properties.map(item => this.attributesOfSelectedEntity.push(item.name));
                this.joinChildSelected = false
            },
            onHideHintsChange(event) {
                this.selectedItem["hideHints"] = event || false;
            },
            chooseIcon() {
                document.getElementsByName("uploadFile")[0].click()
            },
            checkForPrimaryKey() {
                var hasPrimaryKey = false;
                this.formVariables.map(item => {
                    if (item.primaryCharacteristic === true) {
                        hasPrimaryKey = true
                    }
                })
                return hasPrimaryKey
            },
            uploadImageCallback(file) {
                if (file) {
                    const reader = new FileReader();
                    let self = this;
                    reader.onload = () => {
                        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                        if ((encoded.length % 4) > 0) {
                            encoded += '='.repeat(4 - (encoded.length % 4));
                        }
                        let img = new Image();

                        img.onload = () => {
                            self.selectedItem.icon = "data:image/png;base64," + encoded;

                        };

                        img.src = "data:image/png;base64," + encoded;
                    };
                    reader.readAsDataURL(file);
                }
            },
            handleResize() {
                if(document.getElementById('formConfigurator'))
                    this.height = document.getElementById('formConfigurator').offsetParent.clientHeight;
            },
            generateQR(value) {
                this.qrValue = value.toString();
                if (value)
                    this.qrToPrint = true;
            },
            printQR() {
                this.$htmlToPaper('QR');
            },
            checkTypeConstraints() {
                if(this.selectedItem.primaryCharacteristic && !this.canBeKey) {
                    this.selectedItem.primaryCharacteristic = false;
                    this.$root.showWarningNotification(
                        this.$gettext("Primary key attribute was automatically unset for variable {0} since selected type {1} cannot be primary key. Only Text, Number and Single option fields are allowed as keys"
                            .format(this.selectedItem.name, this.selectedItem.type)), true);
                }
                if(this.selectedItem.type === "Boolean") {
                    this.selectedItem.mandatory = false;
                    this.selectedItem.value = false;
                    if(this.isGroupParent(this.selectedItem))
                        this.selectedItem.requireEvidence = false;
                }
                if ((this.selectedItem.type === 'Single option' || this.selectedItem.type === 'Multi options')
                    && (this.parentObj && (this.parentObj.type === 'Single option' || this.parentObj.type === 'Multi options'))) {
                    this.selectedItem.canBeLinked = true;
                } else {
                    this.selectedItem.canBeLinked = false;
                }
            },
            toggleExpectedValueControl(value) {
                if(value) {
                    if(this.selectedItem.type === "Boolean")
                        this.selectedItem.expectedValue = false;
                    else this.selectedItem.expectedValue = "";
                } else this.selectedItem.expectedValue = null;
            },
            setKeyValidationConstraints() {
                if(this.selectedItem.primaryCharacteristic)
                    this.selectedItem.mandatory = true;
            },

            itemUp(item) {
                let data = this.formVariables.findItemRecursive(item,'children');
                if(data)
                    data.items.moveItem(data.itemIndex, data.itemIndex - 1);
            },
            itemDown(item) {
                let data = this.formVariables.findItemRecursive(item,'children');
                if(data && data.itemIndex >= 0 && data.itemIndex < data.items.length - 1)
                    data.items.moveItem(data.itemIndex , data.itemIndex + 1);
            },
            selectedVariables(variables) {
                this.linkedVariables = variables;
                this.selectedItem.linkedVariables = variables;
                this.showPickerDialog = false;
            },
            checkName(variable) {
                let varIndex = 0;
                let checkUnique=function  (vars, varName) {
                        for (let v of vars) {
                            if (v.name === varName && !Object.deepEquals(v, variable))
                                return true;
                            if(Array.isUseful(v.children)) {
                                let duplicate = checkUnique(v.children,varName);
                                if(duplicate)
                                    return true;
                            }
                        }
                        return false;
                    }
                while(checkUnique(this.formVariables, variable.name)) {
                    variable.name=variable.name.replace("_"+varIndex,"")
                    varIndex++;
                    variable.name+="_"+varIndex

                }
            },
        },
        watch: {
            //TODO Apply this logic only to entities
            'selectedItem.name': function (val) {
                if (this.representationMode.isEntity || this.representationMode.isTransaction) {
                    //Check if variable is a well-known one
                    if (!this.selectedFromEditButton && !this.selectedFromWellKnownComboBox) {
                        let arrayOfVars = this.wellKnownVariables;
                        let equalsToWellKnownVar = arrayOfVars.some(item => item.id.toUpperCase() === val.toUpperCase());
                        if (equalsToWellKnownVar === true) {
                            this.selectedItem.name = this.selectedItem.title.charAt(0).toUpperCase() + this.selectedItem.title.slice(1);
                            this.selectedItem.title = this.selectedItem.name;
                            this.$root.showDialogBox(this.$gettext("This Variable is already defined as a well known variable. Do you want to select it?"), null, "Yes", this.fillWellKnownVariable, "No", this.cancelWellKnownVariable);
                        }
                    }
                }
            },
            optionsTo:{
                handler(){
                    if (!this.selectedItem.linkedToParent)
                        this.selectedItem.options=this.optionsTo;
                }
            }
        }
    }
</script>

<style scoped>
    div .v-treeview-node v-treeview-node--leaf {
        margin-left: 0px
    }
</style>
