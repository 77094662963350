<template>
    <DynamicElementBase>
        <div style="height: 100%; width:100%; overflow: auto;">
            <v-layout>
                <v-flex xs12 md6>
                    <v-data-table :headers="headers" :items="values" class="elevation-1" total-items='50' hide-actions style="overflow: hidden;">
                        <template v-slot:headers="props">
                            <tr style="border-bottom: none;">
                                <th class="text-xs-left" v-for="header in props.headers">
                                    {{ header.name }}
                                </th>
                                <th v-translate>Anomalies</th>
                            </tr>
                        </template>
                        <template slot="items" slot-scope="values">
                            <td style="border-bottom: none;" class="text-xs-left" v-for="header in headers">
                                {{ values.item[header.DataProperty] }}
                            </td>
                        </template>
                        <td>
                            <v-btn icon @click="showDetails(values.item)">
                                <av-icon>slideshow</av-icon>
                            </v-btn>
                        </td>
                    </v-data-table>
                </v-flex>
                <v-flex xs12 md6>
                    <BarChart ref="graph" style="width: 100%; height: 100%;" :chartdata="dataCollection.chartData" :options="options"></BarChart>
                </v-flex>
            </v-layout>

        </div>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
    import BarChart from '@/components/graphics/BarChart.vue'
    import JsUtils from '@/api/jsutils'

    let traceColors = [
        "blue",
        "red",
        "purple",
        "orange",
        "green",
        "pink",
        "brown",
        "yellow",
        "indigo",
        "blue-grey"
    ];

    export default {
        name: "WidgetDeviceAlarms",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            BarChart,
        },
        data () {
            return {
                keyValuesView: false,
                dataCollection: {
                    chartData: {
                        datasets: []
                    }
                },
                headers: [],
                values: [],
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false,
                    tension: 0.4,
                    propagate: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: "top"
                        },
                        datalabels: {
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                bounds: 'data',
                                // format: "HH:mm",
                                // unit: 'minutes',
                                // unitStepSize: 1,
                                displayFormats: {
                                    'millisecond':	'hh:mm:ss.SSS',
                                    'second': 'hh:mm:ss',
                                    'minute': 'hh:mm',
                                    'hour': "MMM DD hh:mm",
                                    'day':	'DD/MM/YYYY',
                                    'week': 'DD/MM/YYYY',
                                    'month': 'MMM YYYY',
                                    'quarter':	'[Q]Q-YYYY',	//'Q1 - 2018'
                                    'year': 'YYYY',
                                },
                                // tooltipFormat: 'HH:mm:ss'
                            },
                            grid: {
                                display: true
                            }
                        },
                        y: {
                            ticks: {
                                max: undefined
                            }
                        }
                    }
                },
            }
        },
        mounted () {
        },
        methods: {
            refreshData(dataValues) {

                let graphToBeRefreshed = false;

                //Clear data collection
                let newDataCollection = {
                    chartData: {
                        datasets: []
                    },
                };

                this.headers = []
                dataValues.forEach(dataSet => {
                    if(dataSet) {
                        if(!this.keyValuesView) {
                            this.headers.push({DataProperty: dataSet.label, name: dataSet.label, sortable: false, align: 'left'});
                            for(let valueIndex = 0 ; valueIndex < dataSet.data.length ; valueIndex++)
                            {
                                if(!this.values[valueIndex])
                                    this.values[valueIndex] = {};
                                this.values[valueIndex][dataSet.label] = dataSet.data[valueIndex].y;
                            }
                        } else {
                            if(dataSet.data && dataSet.data.length > 0)
                                this.values.push( { key: dataSet.label, value: dataSet.data[dataSet.data.length - 1].y } );
                            else
                                this.values.push( { key: dataSet.label, value: "" } );
                        }

                        newDataCollection.chartData.datasets.push({label: dataSet.label, data: dataSet.data});
                    }
                });

                newDataCollection.chartData.datasets.forEach(function (dataset, index) {
                    dataset.borderColor = JsUtils.ColorWithOpacity(JsUtils.NamedColorToHex(traceColors[index % traceColors.length]), 90);
                    dataset.backgroundColor = JsUtils.ColorWithOpacity(JsUtils.NamedColorToHex(traceColors[index % traceColors.length]), 40);
                });

                // Verify whether previous data set contains same series as this one, in this switch data points only to avoid flickering
                if(this.dataCollection && this.dataCollection.chartData.datasets.length === newDataCollection.chartData.datasets.length) {
                    for (let dataSetIndex = 0; dataSetIndex < this.dataCollection.chartData.datasets.length; dataSetIndex++) {
                        if (this.dataCollection.chartData.datasets[dataSetIndex].label === newDataCollection.chartData.datasets[dataSetIndex].label) {
                            //Datasets match, only replace data points

                            this.dataCollection.chartData.datasets[dataSetIndex].data = newDataCollection.chartData.datasets[dataSetIndex].data;
                            //Graph must be refreshed manually, this is because the watch on data running within the chart component
                            //requires the deep property to catch changing to data points only but on the other hand this causes a
                            //continuous triggering of the watch (for unknown reasons, maybe something internal to graph.js),
                            //thus deep watching was removed and manual refresh is required somewhere
                            graphToBeRefreshed = true;
                        }
                        else {
                            //Datasets don't match
                            this.dataCollection = newDataCollection;
                            break;
                        }
                    }
                }
                else this.dataCollection = newDataCollection;   //Datasets don't match

                let axisIsScaled = false;

                //Verify if we have any databound graph option
                dataValues.forEach(dataSet => {
                    if(dataSet && dataSet.target === this.visualizationTargetsIndexes["graph max"]) {
                        this.options.scales.y.max = dataSet.data[dataSet.data.length - 1].y;
                        graphToBeRefreshed = true;
                        axisIsScaled = true;
                    }
                });

                if(!axisIsScaled)
                    this.options.scales.y.max = undefined;

                if(this.$refs.graph)
                    this.$refs.graph.refreshGraph();
            },

            showDetails(item) {
            },
        }
    }

</script>

<style scoped>

    .v-treeview-node__label {
        flex-grow: 0;
    }

    table.v-table tbody td, table.v-table tbody th {
        height: 25px;
    }

    table.v-table tbody tr:not(:last-child) {
        border-bottom: none;
    }

</style>
