<template>
    <v-card ma-1>
        <template v-if="qrDecode">
            <v-card-text style="max-width: 100%; max-height: 100%; margin: 0 auto; display: block;">
                <p class="error">{{ error }}</p>
                <!--<qrcode-stream style="max-width: 900px; margin: 0 auto; display: block;" @decode="onDecode" @init="onInit" />-->
                <center>
                    <StreamBarcodeReader id="barCodeReader" class="no-overlay"
                            @decode="onDecode"
                            @loaded="onInit"
                    ></StreamBarcodeReader>
                </center>
            </v-card-text>
            <v-card-actions >
                <v-spacer />
                <v-btn fab color="info" @click="onDecode('')">CLOSE</v-btn>
            </v-card-actions>
        </template>
        <template v-else>
            <v-card-text class="">
                <video style="max-width: 100%; max-height: 450px; margin: 0 auto; display: block;" class="" v-show="streaming" ref="video" autoplay></video>
                <canvas style="max-width: 100%; max-height: 450px; margin: 0 auto; display: block;" class="" v-show="!streaming" ref="canvas"></canvas>
            </v-card-text>
            <v-card-actions >
                <v-spacer />
                <v-btn fab color="info" v-show="!streaming" @click="takePicture"><v-icon>fas fa-camera</v-icon></v-btn>
                <v-btn fab color="white" text-color="primary" v-show="streaming" @click="accept"><v-icon>fas fa-image</v-icon></v-btn>
                <v-btn fab color="info" v-show="streaming" @click="changeDevice"><v-icon>fas fa-exchange-alt</v-icon></v-btn>
                <v-btn fab color="info" @click="$emit('done', picture)">OK</v-btn>
            </v-card-actions>
        </template>

    </v-card>
</template>

<script>

    //import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
    import { StreamBarcodeReader } from "vue-barcode-reader";

    export default {
        name: 'PictureShooter',
        components: {
            // QrcodeStream,
            // QrcodeDropZone,
            // QrcodeCapture,
            StreamBarcodeReader
        },
        data () {
            return {
                editor: '',
                streaming: true,
                stream: null,
                devices: [],
                selectedDevice: 0,
                picture: "",
                result: "",
                error: ""
            }
        },
        props: {
            qrDecode: false
        },
        // async created () {
        //     await this.getDevices();
        //     this.selectedDevice = 0;
        //     this.takePicture();
        // },
        async mounted() {
            //Remove annoying overlays from reader, there is an open request on github
            //https://github.com/olefirenko/vue-barcode-reader/issues/9
            if(this.qrDecode) {
                let reader = document.getElementById("barCodeReader");
                reader.getElementsByClassName("overlay-element")[0].remove();
                reader.getElementsByClassName("laser")[0].remove();
            } else {
                await this.getDevices();
                this.selectedDevice = 0;
                this.takePicture();
            }
        },
        methods: {
            onDecode (result) {
                this.$emit('qrDone', result)
            },

            async onInit (promise) {
                try {
                    await promise
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                        this.error = "ERROR: you need to grant camera access permisson"
                    } else if (error.name === 'NotFoundError') {
                        this.error = "ERROR: no camera on this device"
                    } else if (error.name === 'NotSupportedError') {
                        this.error = "ERROR: secure context required (HTTPS, localhost)"
                    } else if (error.name === 'NotReadableError') {
                        this.error = "ERROR: is the camera already in use?"
                    } else if (error.name === 'OverconstrainedError') {
                        this.error = "ERROR: installed cameras are not suitable"
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        this.error = "ERROR: Stream API is not supported in this browser"
                    }
                }
            },
            changeDevice: function () {
                this.selectedDevice++;
                if (this.selectedDevice > this.devices.length) {
                    this.selectedDevice = 0
                }
                this.takePicture()
            },
            getDevices: async function () {
                this.devices = [];
                let devices = await navigator.mediaDevices.enumerateDevices();
                devices.forEach((d) => {
                    if (d.kind === 'videoinput') {
                        this.devices.push(d)
                    }
                })
            },
            takePicture: async function () {
                if (this.streaming && this.stream) {
                    this.stream.getTracks().forEach(function (track) {
                        track.stop()
                    })
                }
                this.streaming = true;
                try {
                    this.stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            deviceId: { exact: this.devices[this.selectedDevice].deviceId }
                        }
                    });
                    let video = this.$refs.video;
                    video.srcObject = this.stream
                } catch (err) {
                    /* handle the error */
                    console.log(err)
                }
            },
            accept: async function () {
                this.streaming = false;
                let canvas = this.$refs.canvas;
                let video = this.$refs.video;
                let width = video.offsetWidth;
                let height = video.offsetHeight;
                canvas.width = width;
                canvas.height = height;
                let context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, width, height);
                this.stream.getTracks().forEach(function (track) {
                    track.stop()
                });
                this.picture = canvas.toDataURL();
            }
        }
    }
</script>

<style>

    .no-overlay {
        background: rgba(30, 30, 30, 1)!important;
    }

</style>