import { render, staticRenderFns } from "./MachinesSettings.vue?vue&type=template&id=5472ba62&scoped=true&"
import script from "./MachinesSettings.vue?vue&type=script&lang=js&"
export * from "./MachinesSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5472ba62",
  null
  
)

export default component.exports