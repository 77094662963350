import utils from '@/api/jsutils'

export default {
    colors: {
        background: '#F9F9F9',
        lightGrey: '#D9D9D9',
        grey: '#757575',
        red: '#E71D24',
        orange: '#F49739',
        yellow: '#FFCC03',
        lightgreen: '#9AC43A',
        green: '#70ab2d',
        lightblue: '#398BCB',
        blue: '#3C63AD',
    },

    getAvColor(color) {
        if(Object.isDefined(this.colors[color]))
            return this.colors[color];
        return color;
    },

    whiteTextNeeded(backcolor) {
        let components = utils.colorComponents(backcolor);
        //Apply threshold based on percieved color brightness
        if(((0.21 * components.r) + (0.72 * components.g) + (0.07 * components.b)) < 128)
            return true;
    }
}