<template>
    <v-container fluid grid-list-md >
    </v-container>
</template>

<script>

    export default {
        name: "Home",
        data:() => ({
        }),
        mounted() {
            this.$root.setCurrentPageTitle("");
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>