<template>
    <av-page v-if="this.$root.startedUp" toolbar-activation-on="true"  ref="page">
        <template slot="toolbarFixedItems">
            <v-layout class="pa-3 pl-4 pt-4">
                <av-select :items="lines" v-model="selectedLine" :label="$gettext('Line')" @change="loadMachines"></av-select>
            </v-layout>
        </template>
        <template slot="pageContent">
            <v-layout wrap row>
                <v-flex :class="viewPanel ? leftPanelClass : ''" >

                    <av-spreadsheet :header-columns="headers"
                                    :rows="records"
                                    :file-name="'Scheduler'"
                                    :flat-toolbar="true"
                                    :show-add-button="true"
                                    :show-delete-button="true"
                                    :show-edit-button="true"
                                    :enabled-formula="false"
                                    @onCellClick="onCellClick($event)"
                                    :max-spreadsheet-height="spreadSheetHeight"
                                    noImportExport
                                    :toolbarLayoutPosition="{zIndex: 5}"
                                    @change="onChangeMachineRecipe"
                                    @addedRow="onAddedRow"
                                    :confirmation-delete-message="$gettext('Are you sure you want to delete item: {id}?')"
                                    :delete-method="onDeleteRow" @onEditModeChange="onEditingModeChange"
                    >
                    </av-spreadsheet>
                </v-flex>
                <v-flex :class="rightPanelClass" v-if="viewPanel" >
                    <v-card flat style="border-left: 1px solid #eee;">
                        <v-card-title>
                            <h3>{{panelTitle}}</h3>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text :style="{height:(spreadSheetHeight - 100) +'px'}">
                            <recipe-configurator :current-recipe="currentRecipe" :recipe="recipe" v-if="currentRecipe"></recipe-configurator>
                            <template v-else>
                                <v-list>
                                    <v-list-tile v-for="(recipe,name) in currentLineRecipe" style="border-bottom:1px solid lightgrey">
                                        <v-list-tile-title><span style="font-weight: bold">{{name}}</span>: {{recipe.status.message}}</v-list-tile-title>
                                        <v-list-tile-action>
                                            <v-btn fat icon  @click="recipe.status.status>0? recipe.elm.undeployElement():deployRecipeElement(recipe.elm,recipe.status)" >
                                                <av-icon small :color="recipe.status.status>0?'error':'info'">{{recipe.status.status>0?'fa fa-stop':'fa fa-play'}}</av-icon>
                                            </v-btn>
                                        </v-list-tile-action>
                                    </v-list-tile>
                                </v-list>
                            </template>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1"
                                   flat="flat" @click="closePanel">
                                <translate>Close</translate>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </template>
    </av-page>
</template>

<script>
import AvPage from "@/components/av-components/av-page";
import lines from '@/api/lines'
import devices from "@/api/devices";
import recipes from "@/api/recipes";
import RecipeConfigurator from "@/components/dynamic-elements/recipes/RecipeConfigurator";
import lineRecipe from "@/components/dynamic-elements/recipes/LineRecipe";
export default {
    name: "LineRecipesConfigurator",
    components: {RecipeConfigurator, AvPage},
    data:()=>{
        return{
            viewPanel:false,
            headers:[
                {
                    name: "Id",
                    key: "id",
                    type: "text",
                    contextMenu: false,
                    sortable: true,
                    editable: true,
                }
            ],
            records:[],
            selectedLine:"",
            lines:[],
            currentRecipe:undefined,
            recipe:[],
            panelTitle:"",
            spreadSheetHeight:0,
            recipesElements:[],
            editingActive:false,
            currentLineRecipe:{},
            oldIdValue:""
        }
    },
    computed:{
        recipeType(){
            let type="";
            if(this.currentLineRecipe){
                return "line"
            }
            if(this.currentRecipe){
                switch (this.currentRecipe.properties.type) {
                    case this.$dynamicElements.Types.TemplateRecipe:
                        type= 'template';
                        break;
                    case this.$dynamicElements.Types.MachineRecipe:
                        type = 'machine';
                        break;
                    case this.$dynamicElements.Types.LineRecipe:
                        type = 'line';
                        break;
                }
            }
            return type;
        },
        leftPanelClass(){
            switch (this.recipeType){
                case "template":
                    return "sm3 md3"
                case "machine":
                case "line":
                default:
                    return "sm7 md7"
            }
        },
        rightPanelClass(){
            switch (this.recipeType){
                case "template":
                    return "sm9 md9"
                case "machine":
                case "line":
                    return "sm5 md5"
            }
        },
        recipeIsActive(){
            return true
        },
    },
    mounted(){
        //this.$dynamicElements.listAll('recipes', true)
        this.loadLines()
        this.$nextTick(()=>{
            window.addEventListener("resize",this.handleResize)
            this.handleResize()
        })
    },
    beforeDestroy() {
        window.removeEventListener("resize",this.handleResize)
        this.recipesElements.forEach(r=>{
            //todo call destroy method
            r.$destroy()
        })
    },
    methods:{
        async loadLines() {
            this.$root.setCurrentPageTitle(this.$gettext("Line Recipes Configuration"));
            this.lines = await lines.getAvailable();
            if (this.lines.length === 1){
                this.selectedLine=this.lines[0]
                this.loadMachines()
            }
        },
        async loadMachines() {
            let machines = await devices.getAvailableByLine(this.selectedLine);
            for (let i = 0; i < machines.length; i++) {
                let machine = machines[i]
                this.headers.push({
                    name: machine,
                    key: machine,
                    type: "autocomplete",
                    options: await recipes.getAvailableRecipesByMachines(machine, true),
                    contextMenu: false,
                    sortable: false,
                    editable: true,
                })
            }
            //
            this.headers.push({
                    name: "Status",
                    key: "status",
                    type: "text",
                    editable: false,
                    sortable: true
                },
                {
                    name: "",
                    key: "activate",
                    type: "icon-button",
                    editable: true,
                    sortable: false
                })

            this.loadLineRecipes()

        },
        async loadLineRecipes(){
          let lineRecipes=await recipes.getFullRecipes("line")
          let self=this
            for (let i=0;i< lineRecipes.length;i++){
                let lr=lineRecipes[i]
                let record={
                    id:lr.properties.name
                }
                self.headers.forEach(h=>{
                    if (h.key!=='id'){
                        record[h.key]=""
                        let found=lr.recipe.find(r=>{ return r.target.includes(h.key)})
                        if (found){
                            record[h.key]=found.idRecipe
                        }
                    }
                })
                self.records.push(record)
                await  self.loadRecipeElement(i)
            }
        },
        async onCellClick(evt){
            if(this.editingActive){
                //take old value
                if(evt.key==='id'){
                    this.oldIdValue=this.records[evt.row][evt.key]
                }
                return
            }
            if( evt.key==='activate' || evt.key==="status"){
                return
            }
            this.clearCurrentRecipes()
            if(evt.key==='id'){
                let currentLineRecipe={}
                //read all recipe for line selected and take statuses
                for(let key in this.records[evt.row]){
                    if(key!=='id' && key!=='activate' && key!=="status"){
                        let idDesc=this.records[evt.row][key]
                        if (idDesc && !currentLineRecipe[idDesc]){
                            let recipe=await this.$dynamicElements.LoadItem(idDesc,"recipes")
                            let desc=JSON.parse(recipe.descriptor)
                            let view= this.$dynamicElements.createNew(desc.properties.type,true);
                            view.load(desc)
                            currentLineRecipe[idDesc]= {
                                elm: view, status: {
                                    status: view.getItemStatus().status,
                                    message: view.getItemStatus().message
                                }
                            }
                            currentLineRecipe[idDesc].elm.childHandlers.onSavedChanges = () => {
                                currentLineRecipe[idDesc].status.status=view.getItemStatus().status
                                currentLineRecipe[idDesc].status.message=view.getItemStatus().message
                            }
                        }

                    }
                }
                this.$set(this,"currentLineRecipe",currentLineRecipe)
                this.viewPanel=true
                this.panelTitle=this.records[evt.row].id
                return
            }
            this.viewPanel=false
            let idDescriptor=this.records[evt.row][evt.key]
            if (idDescriptor && idDescriptor!==''){
                let item=await this.$dynamicElements.LoadItem(idDescriptor,"recipes")
                let descriptor=JSON.parse(item.descriptor)
                if (descriptor.properties.type===this.$dynamicElements.Types.MachineRecipe){
                    this.currentRecipe = this.$dynamicElements.createNew(descriptor.properties.type,true);
                    if(!this.currentRecipe)
                        return; //License issue or huge error (unlikely)
                    this.currentRecipe.load(descriptor);
                    this.recipe=descriptor.recipe
                    this.panelTitle="{0} - {1}".format(evt.key,idDescriptor)
                    this.viewPanel=true
                }

            }

        },
        handleResize() {
            if (this.$refs.page)
                this.spreadSheetHeight = this.$refs.page.$refs.pageContent.$el.clientHeight - 30
        },
        async onChangeMachineRecipe(evt) {
            //take recipe name
            if (this.records[evt.row].id === '') {
                //throw error is new recipe without id
                return
            }
            if (evt.col === 'id') {
                let err=this.$gettext("Element name already used in DB, please choose a different one")
                //check if exist another recipe with same name
                if (!this.$dynamicElements.validateName(evt.value, 'recipes').status) {
                    err = this.$dynamicElements.validateName(evt.value, 'recipes').message;
                    this.$root.showErrorNotification(err, false, true)
                    this.records[evt.row].id = this.oldIdValue
                    return
                }
                //take recipe to update
                let recipeElm = this.findRecipeByName(this.oldIdValue)
                recipeElm.properties.name = evt.value
                this.oldIdValue=""
                return
            }
            let elm = this.findRecipeByRecord(evt.row)
            //apply changes
            elm.recipe.forEach(r => {
                //search machine for each targets of recipe
                if (r.target.find(m => {
                    return m === evt.col
                })) {
                    //if find delete
                    r.target.removeItem(evt.col)
                }
            })
            //check if already exist recipe
            if (elm.recipe.find(r => {
                return r.idRecipe === evt.value
            })) {
                //if exist push target machine
                elm.recipe.find(r => {
                    return r.idRecipe === evt.value
                }).target.push(evt.col)
            } else {
                //if not exist create item in recipe
                elm.recipe.push({
                    idRecipe: evt.value,
                    target: [evt.col],
                    type: 'machine'
                })
            }

        },
        loadRecipeElement(idx) {
            return new Promise(async (resolve) => {
                if (this.recipesElements[idx]) {
                    resolve (this.recipesElements[idx])
                }
                let item = await this.$dynamicElements.LoadItem(this.records[idx].id, "recipes")
                this.$nextTick(() => {
                    let recipeView = this.$dynamicElements.createNew(this.$dynamicElements.Types.LineRecipe, true);
                    let descriptor = JSON.parse(item.descriptor)
                    recipeView.load(descriptor);
                    let self = this
                    this.recipesElements.push(recipeView)
                    this.setRecipeStatusByIdx(idx)
                    //set method called after saved changes
                    recipeView.childHandlers.onSavedChanges = () => {
                        self.setRecipeStatusByName(recipeView.properties.name)
                    }

                    resolve(this.recipesElements[idx])
                })
            })
        },
        onAddedRow(idx){
            this.$nextTick(() => {
                let recipeView = this.$dynamicElements.createNew(this.$dynamicElements.Types.LineRecipe, true);
                recipeView.properties.type = this.$dynamicElements.Types.LineRecipe;
                recipeView.newElement();
                this.$nextTick(()=>{
                    recipeView.setTweakValue("target", this.selectedLine)
                    this.recipesElements.push(recipeView)
                    this.records[idx].id=this.recipesElements[idx].properties.name
                    recipeView.childHandlers.onSavedChanges = () => {
                        this.setRecipeStatusByName(recipeView.properties.name)
                    }
                })

            })
        },
        setRecipeStatusByIdx(idx){
            let recipeView=this.findRecipeByRecord(idx)
            this.setRecipeStatus(recipeView,idx)

        },
        setRecipeStatusByName(name) {
            let recipeView = this.findRecipeByName(name)
            //find idx record
            let idx = this.records.findIndex(r => {
                return r.id === name
            })
            this.setRecipeStatus(recipeView, idx)
        },
        setRecipeStatus(recipeView,idx){
            let self=this
            let status = recipeView.getItemStatus()
            this.records[idx].status = status.message
            if (status.status > 0) {
                this.records[idx].activate = {
                    color: "error",
                    icon: "fa fa-stop",
                    method: () => {
                        recipeView.undeployElement()

                    }
                }
            } else {
                this.records[idx].activate = {
                    color: "info",
                    icon: "fa fa-play",
                    method: (idx) => {
                        //call method to deploy
                        self.deployRecipeElement(recipeView, recipeView.getItemStatus())
                    }
                }
            }
        },
        async onDeleteRow(idx, callback){
            let recipeElement=this.findRecipeByRecord(idx)
            let itemName=recipeElement.properties.name
            let itemType=this.$dynamicElements.getItemCategory(recipeElement.properties.type)
            //recipeElement.$destroy()
            await this.$dynamicElements.delete(itemName, itemType)
            //recipeElement.delete=true
            //this.recipesElements.removeAt(idx)
            if(callback){
                callback()
                this.recipesElements.removeItem(recipeElement)
            }
        },
        clearCurrentRecipes(){
            for(let mr in this.currentLineRecipe){
                if(this.currentLineRecipe[mr])
                    this.currentLineRecipe[mr].elm.$destroy()
            }
            this.currentLineRecipe=undefined
            if (this.currentRecipe){
                this.currentRecipe.$destroy()
                this.currentRecipe=undefined
            }
        },
        closePanel(){
            this.clearCurrentRecipes()
            this.viewPanel=false
        },
        deployRecipeElement(elm,status){
            if(this.deployGrant(status))
                elm.deployElement()
            //check with grant if call deployElement or this
            if(this.requestActivationGrant(status))
                elm.requestActivation()
        },
        deployGrant(status) {
            if ((status.status === 0 || status.status === -1) )
                return this.$grants.get().recipes.canActivate
            else return status.status === 0;
        },
        requestActivationGrant(status) {
            //if user have grant for activation, he doesn't have to request activation
            if (status.status === -1  && this.$grants.get().recipes.canActivate)
                return false
            else if (status.status === -1 )
                return this.$grants.get().recipes.canRequestActivation
            else return status.status === -1;
        },
        onEditingModeChange(evt){
            this.editingActive=evt
            if(this.editingActive && this.viewPanel){
                this.closePanel()
            }
        },
        findRecipeByRecord(idx){
            return this.recipesElements.find(re => {
                return re.properties.name === this.records[idx].id
            })
        },
        findRecipeByName(name){
            return this.recipesElements.find(re => {
                return re.properties.name === name
            })
        }
    },

}
</script>

<style scoped>

</style>