<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" grid-list-md text-xs-center pb-0 pt-3 style="max-width: 100%; overflow: hidden">
        <v-card  class="mt-0 av-card" elevation="0" style="calc(100% - 230px)">
            <v-card-text >
                <label class="headline font-weight-bold ma-4"><translate>Export configurations</translate></label>
                <hr class="ma-4" style="max-width: 100%;"/>
                <v-layout ma-5 pl-3 column style="max-width: 640px" text-xs-left>
                    <v-select chips multiple outlined :items="exportableItems" v-model="selectedExports"/>
                    <v-layout row>
                        <label class="subheading font-weight-bold mt-3 ma-1"> {{ allowEditPlaceholdersLabel }} </label>
                        <av-button :toolTip="allowEditPlaceholdersToolTip" fab small class="ma-1" :iconColor="allowEditPlaceholdersIconColor" :color="allowEditPlaceholdersColor"
                                   :buttonIcon="allowEditPlaceholdersButton" @click="enableEditAndDeletionClick"/>
                    </v-layout>
                    <v-layout v-if="enableEditAndDeletion" v-for="(placeholder, index) in bulkReplaceSources" mt-3 row>
                        <v-text-field class="osk" :label="$gettext('Source name to replace')"
                                      v-model="bulkReplaceSources[index]"/>
                        <v-spacer/>
                        <v-text-field class="osk" :label="$gettext('Placeholder to insert')"
                                      v-model="bulkReplaceTargets[index]"/>
                        <v-btn fab small color="error" class="ma-1"
                               @click="bulkReplaceSources.removeAt(index), bulkReplaceTargets.removeAt(index)">
                            <av-icon light>fas fa-trash</av-icon>
                        </v-btn>
                    </v-layout>
                    <v-btn v-if="enableEditAndDeletion" class="mt-3" @click="bulkReplaceSources.push(''), bulkReplaceTargets.push('')">
                        <translate>Add placeholder to substitute</translate>
                    </v-btn>
                    <av-switch class="mt-3" :label="$gettext('Export only active elements')" v-model="exportOnlyActiveElements" />
                    <av-switch class="mt-3" :label="$gettext('Prevent deactivating elements')" v-model="preventDeactivatingElements" />
                    <v-btn class="mt-3" color="info" @click="getVisualizationsBulk">
                        <translate>Get it</translate>!
                    </v-btn>
                </v-layout>
            </v-card-text >
        </v-card>
        <v-card class="mt-2 av-card" elevation="0" style="height: 200px">
            <v-card-text>
                <br/>
                <label class="headline font-weight-bold"><translate>Import configurations</translate></label>
                <hr class="ma-4" style="max-width: 100%;"/>
                <v-layout ml-5 pl-3 column style="max-width: 640px; min-height: 200px" text-xs-center>
                    <div class="text-xs-left">
                        <upload-btn ref="bulkFileInput" class="btn btn-default" color="#f5f5f5" :title="$gettext('Import bulk configuration')" @file-update="importBulk">
                            <template slot="icon-left">
                                <av-icon color="#0d47a1" style="margin-right: 10px">fas fa-file</av-icon>
                                <av-icon color="#0d47a1" style="margin-right: 10px">fas fa-arrow-left</av-icon>
                            </template>
                        </upload-btn>
                    </div>
                </v-layout>
            </v-card-text>
        </v-card>
        <av-loader :loading="loading" :message="message" minTime="1500"/>
    </v-container>
</template>

<script>

import ElasticBulk from "@/api/elasticbulk";
import UploadButton from 'vuetify-upload-button';

export default {
        name: "SystemSettings",
        components: {
            'upload-btn': UploadButton
        },
        data() {
            return {
                selectedExports: ['widgets', 'queries', 'reports', 'rules', 'dashboards', 'forms', 'line settings', 'workflow settings', 'line stop causes'],
                exportableItems: [
                    {text: this.$gettext('widgets'), value: 'widgets'},{text: this.$gettext('queries'), value: 'queries'},
                    {text: this.$gettext('reports'), value: 'reports'}, {text: this.$gettext('rules'), value: 'rules'},
                    {text: this.$gettext('dashboards'), value: 'dashboards'}, {text: this.$gettext('forms'), value: 'forms'},
                    {text: this.$gettext('identification settings'), value: 'identification settings'}, {text: this.$gettext('line settings'), value: 'line settings'},
                    {text: this.$gettext('workflow settings'), value: 'workflow settings'}, {text: this.$gettext('line stop causes'), value: 'line stop causes'},
                    {text: this.$gettext('alarms settings'), value: 'alarms settings'}, {text: this.$gettext('machines settings'), value: 'machines settings'},
                    {text: this.$gettext('recipes'), value: 'recipes', manager: true}, {text: this.$gettext('workorders workflow'), value: 'workorders workflow', manager: true}, {text: this.$gettext('operation modes'), value: 'operation modes', manager: true},
                        // {text: this.$gettext('grants'), value: 'grants'},
                    {text: this.$gettext('Mail settings'), value: 'mail settings'}, {text: this.$gettext('translations'), value: 'translations'}],
                bulkReplaceSources: [],
                bulkReplaceTargets: [],
                loadingParam: true,
                exportOnlyActiveElements: false,
                preventDeactivatingElements: false,
                enableEditAndDeletion: false,
                loading: false,
                message: "",
            }
        },
        mounted: function () {
            if(!this.$license.hasManager()) {
                this.exportableItems = this.exportableItems.filter(item => !item.manager)
            }
            this.selectedExports = this.exportableItems.map(item => item.value);
            this.$root.setCurrentPageTitle(this.$gettext("Export settings"));
            this.loadSystemSettings();
        },
        computed: {
            allowEditPlaceholdersIconColor() {
                return this.enableEditAndDeletion? "white" : "light"
            },
            allowEditPlaceholdersColor() {
                return this.enableEditAndDeletion? "red" : "info"
            },
            allowEditPlaceholdersButton() {
                return this.enableEditAndDeletion? "fa fa-times" : "fas fa-pen"
            },
            allowEditPlaceholdersToolTip() {
                return this.enableEditAndDeletion? this.$gettext("Remove default placeholders") : this.$gettext("Enable editing and deletion of default placeholders")
            },
            allowEditPlaceholdersLabel() {
                return this.enableEditAndDeletion? this.$gettext("Remove placeholders") : this.$gettext("Enable placeholders")
            },
        },
        methods: {
            loadSystemSettings() {
                let lineSettings = this.$settings.getLineSettings();
                if(lineSettings) {
                    this.bulkReplaceSources = [ lineSettings.customerId, lineSettings.factoryId, lineSettings.lineId ];
                    this.bulkReplaceTargets = [ "CustomerId", "FactoryId", "LineId" ];
                    if(lineSettings.masterMachine) {
                        this.bulkReplaceSources.push(lineSettings.masterMachine);
                        this.bulkReplaceTargets.push("ReferenceMachine");
                    }
                }
            },
            getVisualizationsBulk() {
                let self = this;
                self.message = self.$gettext('Export configurations');
                self.loading = true;
                ElasticBulk.exportVisualizations(this.selectedExports, this.bulkReplaceSources,
                    this.bulkReplaceTargets, this.exportOnlyActiveElements, this.preventDeactivatingElements)
                    .then(result => {
                        self.$utils.download("ElasticBulk.info.NULL", result);
                        self.$root.showInfoNotification(self.$gettext("Bulk file successfully exported"), true);
                    })
                    .catch(error=>{
                            self.$root.showErrorNotification(self.$gettext("An error occurred during configuration export"), true);
                        console.log(error)
                    })
                    .finally(()=>{
                        self.loading = false;
                    });
            },
            importBulk(file) {
                let self = this;
                self.message = self.$gettext('Import configurations');
                self.loading = true;
                if (!file.name.startsWith("ElasticBulk.")) {
                    this.$root.showErrorNotification(this.$gettext("Wrong file name! File name must start with ElasticBulk."), true);
                    return
                }

                if (file.size === 0) {
                    this.$root.showErrorNotification(this.$gettext("No data in bulk file!"), true);
                    return
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => {
                    const bulkInfo = { bulkContent: reader.result }
                    ElasticBulk.importBulkConfiguration(bulkInfo)
                        .then(response => {
                            if (response === "Ok")
                                self.$root.showInfoNotification(self.$gettext("Bulk file successfully loaded"), true);
                            else
                                throw(response)

                        })
                        .catch(error => {
                            self.$root.showErrorNotification(self.$gettext("An error occurred during configuration import"), true);
                            console.log(error)
                        })
                        .finally(() => {
                            self.loading = false;
                        });
                };

                reader.onerror = () => {
                    console.log(reader.error);
                }

                this.$refs.bulkFileInput.clear();
            },
            enableEditAndDeletionClick() {
                let message = "";
                let enableDeletion = false;
                if (!this.enableEditAndDeletion) {
                    message = "Changing default placeholders may break target system configuration when imported";
                    enableDeletion = true;
                } else {
                    message = "Reset placeholders?";
                    enableDeletion = false;
                }
                this.$root.showDialogBox(this.$gettext(message), null,
                    this.$gettext("Continue"), () => {
                        this.enableEditAndDeletion = enableDeletion;
                    }, this.$gettext("Cancel"), null);
            }
        }
    }

</script>

<style scoped>

</style>