<template>
    <v-card class="pa-0 ma-0">
        <v-card-text class="pa-0 ma-0">
            <v-layout column pa-2 >
                <template  v-for="(item,i) in outputs">
                    <v-layout row >
                        <av-select
                                :label="$gettext('Triggering rule: ')"
                                v-if="Array.isUseful(definedRules) && !isActivity"
                                v-model="item.trigger"
                                :items="definedRules"
                                item-text="name"
                                class="pa-2"
                                :dynamicID="'Output_' + i"
                                return-object
                        ></av-select>

                        <av-select
                                :label="$gettext('Triggering mode: ')"
                                v-if="Array.isUseful(definedRules) && !isActivity"
                                :items="triggerModes"
                                class="pa-2"
                                v-model="item.triggerMode"
                                :dynamicID="'Output_' + i"
                        ></av-select>

                    </v-layout>

                    <av-select  v-if="!options.fixedOutputs"
                                :label="$gettext('Output type:')"
                                :items="outputTypesFiltered"
                                class="pa-2"
                                v-model="item.outputType"
                                :dynamicID="'Output_' + i"
                                xs2
                                :disabled="item.outputType === 'activity'"
                                :item-disabled="disableActivityItem"
                    ></av-select>

                    <template v-if="item.outputType==='email'" class="pa-2">
                        <v-layout column>
                            <v-autocomplete
                                    v-model="item.email.recipients"
                                    :items="itemsTo"
                                    :error-messages="!emailIsValid[i].valid?'Invalid address':''"
                                    chips
                                    deletable-chips
                                    label="To"
                                    :ref="'mailAutoComplete-'+i"
                                    hide-no-data
                                    hide-selected
                                    :hide-details="emailIsValid[i].valid"
                                    multiple
                                    :search-input.sync="searchTo[i].text"
                                    v-on:keyup.native="(event)=>keyUp(event,i)"
                                    v-on:blur="(event)=>focusOut(event,i)"
                                    :id="'RecipientsOutput_' + i"
                                    class="pa-1"
                            ></v-autocomplete>
                            <av-text-field :label="$gettext('')" prefix="Subject:" class="osk pa-1" :dynamicID="'Output_' + i" v-model.number="item.email.subject"></av-text-field>
                            <av-select
                                    :items="reports"
                                    :label="$gettext('Attachment:')"
                                    v-model="item.email.attach"
                                    :dynamicID="'Output_' + i"
                                    item-text="name"
                                    chips
                                    clearable
                                    multiple
                                    return-object
                                    class="pa-1"
                                    v-if="!isActivity"
                            >
                                <template v-slot:selection='data'>
                                    <v-chip
                                            :key=data.item
                                            close
                                            class="chip--select-multi"
                                            @input="data.parent.selectItem(data.item)"
                                    >
                                        {{ data.item }}
                                    </v-chip>
                                </template>
                                <template v-slot:item='data'>
                                    <v-list-tile-content>
                                        <v-list-tile-title>
                                            {{ data.item }}
                                        </v-list-tile-title>
                                    </v-list-tile-content>
                                </template>
                            </av-select>
                            <label class="pa-1">Message:</label>
                            <ckeditor :editor="editor.type" v-model="item.email.data"
                                      :id="'mail-' + i"
                                      style="min-height: 150px; width: 98%; border: 1px solid gray"></ckeditor>
                            <v-layout row v-if="Array.isUseful(variables) && !isActivity" class="pa-1">
                                <av-select v-model="mailSelectedPlaceholder" :items="variables" :placeholder="$gettext('Select data to insert into text')" :dynamicID="'Output_' + i" return-object/>
                                <av-button :text="$gettext('')" @click="addToEditor(i)" buttonIcon="fa-plus" color="info" iconColor="light" :id="'AddMailPlaceHolderOutput_' + i" :toolTip="$gettext('Insert data placeholder at current cursor position')" fab small />
                            </v-layout>
                        </v-layout>
                    </template>
                    <template  v-if="item.outputType==='restapi'" class="pa-2">
                        <v-layout column>
                            <v-layout row>
                                <av-select :label="$gettext('method:')" v-model="item.api.method" :items="restAPIMethods" :dynamicID="'Output_' + i" class="pa-1" style="max-width: 150px"/>
                                <av-text-field :label="$gettext('API Endpoint:')" class="osk mt-2 ml-2" v-model="item.api.url" :rules="[apiRules.urlValidation]" :dynamicID="'Output_' + i"></av-text-field>
                            </v-layout>
                            <v-layout row v-if="Array.isUseful(variables)">
                                <av-select v-model="apiSelectedPlaceholder" :items="variables" :label="$gettext('Select data to insert in URL')" :dynamicID="'Output_' + i" return-object/>
                                <av-button :text="$gettext('')" @click="addToEditor(i, true)" buttonIcon="fa-plus" color="info" iconColor="light" :id="'AddApiUrlPlaceHolderOutput_' + i" fab small />
                            </v-layout>
                            <v-jsoneditor v-model="item.api.data" :plus="false" class="mt-2"
                                          height="200px" style="width: 98%;margin-left: 3px;" @error="onJsonEditorError" :options="{ modes: ['tree', 'view', 'form', 'code', 'text', 'preview'], mode: 'code' }"
                                          :ref="'postData-'+i" :id="'ApiBodyOutput_' + i"/>
                            <v-layout row v-if="Array.isUseful(variables)">
                                <av-select v-model="apiSelectedPlaceholder" :items="variables" :label="$gettext('Select data to insert into body')"
                                           :dynamicID="'Output_' + i" return-object/>
                                <av-button :text="$gettext('')" @click="addToEditor(i)" buttonIcon="fa-plus" color="info" iconColor="light" :id="'AddApiBodyPlaceHolderOutput_' + i" :toolTip="$gettext('Insert data placeholder at current cursor position')" fab small />
                            </v-layout>
                            <label class="mt-2 subheading" v-translate>Additional headers</label>
                            <table style="width: 100%;">
                                <tr>
                                    <td v-translate>Key</td>
                                    <td v-translate>Value</td>
                                </tr>
                                <tr v-for="(header, index) in item.api.headers">
                                    <td style="padding: 5px">
                                        <av-autocomplete :placeholder="$i18n.CommonAttributes()['type here']"
                                                         :numItems="standardHttpHeaders.length" :items="standardHttpHeaders" :value="header.key"
                                                         @change="header.key=$event" />
                                    </td>
                                    <td style="padding: 5px">
                                        <av-autocomplete :placeholder="$i18n.CommonAttributes()['type here']"
                                                         :numItems="standardHttpHeadersValues.length" :items="standardHttpHeadersValues" :value="header.value"
                                                         @change="header.value=$event" />
                                    </td>
                                    <td>
                                        <v-btn fab small color="error" @click="item.api.headers.removeAt(index)">
                                            <av-icon light>fas fa-trash</av-icon>
                                        </v-btn>
                                    </td>
                                    <td v-if="Array.isUseful(item.api.headers) && index === item.api.headers.length - 1">
                                        <v-btn fab small color="info" @click="addHttpHeader(item)">
                                            <av-icon light>fas fa-plus</av-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </table>
                            <v-btn v-if="!Array.isUseful(item.api.headers)" ma-0 color="info" @click="addHttpHeader(item)">
                                <av-icon left light>fas fa-plus</av-icon>
                                Add header
                            </v-btn>
                        </v-layout>
                    </template>

                    <template v-if="item.outputType === 'redirect'" class="pa-2">
                        <v-layout column>
                            <av-text-field :label="$gettext('Redirect URL:')" v-model="item.redirect.url" :rules="[apiRules.urlValidation]" :dynamicID="'Output_' + i"></av-text-field>
                            <!--TODO complete placeholders here -->
                            <!--<v-layout row v-if="Array.isUseful(variables)">-->
                                <!--<av-select v-model="redirectSelectedPlaceholder" :items="variables" :placeholder="$gettext('Select data to insert in URL')" :dynamicID="item.name" return-object/>-->
                                <!--<av-button :text="$gettext('')" @click="addToEditor(i, true)" buttonIcon="fa-plus" color="info" iconColor="light" :dynamicID="item.name" fab small />-->
                            <!--</v-layout>-->
                        </v-layout>
                    </template>

                    <template  v-if="item.outputType==='error'" class="pa-2">
                        <v-layout column>
                            <av-text-field :label="$gettext('Error message:')" v-model="item.error.message" :dynamicID="'Output_' + i"></av-text-field>
                            <!--<v-layout row v-if="Array.isUseful(variables)">-->
                            <!--<v-select placeholder="Select data to insert in URL"-->
                            <!--return-object-->
                            <!--:items="variables"-->
                            <!--v-model="redirectSelectedPlaceholder" />-->
                            <!--<v-btn fab small color="info" @click="addToEditor(i, true)"><v-icon>fa-plus</v-icon></v-btn>-->
                            <!--</v-layout>-->
                        </v-layout>
                    </template>

                    <template  v-if="item.outputType==='setvalues'" class="pa-2">
                        <v-layout column>
                            <v-layout v-for="(field, index) in item.setvalues.fields" row style="width: 100%">
                                <td style="width: calc(30% - 30px)"><v-combobox class="pa-1 mr-2" placeholder="Select or type field" :items="['Type', 'Message']" :value="field.key" @change="field.key = $event"/></td>
                                <td style="width: 30px; margin-top: 20px">=</td>
                                <td style="width: calc(70% - 100px)">
                                    <av-select v-if="field.key === 'Type'" :items="['info', 'warning', 'error']" :value="field.value"
                                               :placeholder="$gettext('Select')" @change="field.value = $event" :dynamicID="'CustomFieldsSeverityOutput_' + i" style="margin-top: 2px" class="pa-1"/>
                                    <av-text-field v-else :placeholder="$gettext('type here')" v-model="field.value" :dynamicID="'CustomFieldsMessageOutput_' + i" style="margin-top: 2px" class="pa-1"></av-text-field>
                                </td>
                                <td style="width: 50px">
                                    <av-button v-if="index === item.setvalues.fields.length - 1" :text="$gettext('')" @click="item.setvalues.fields.push({key: '', value: ''})"
                                               buttonIcon="fa-plus" color="info" iconColor="light" :dynamicID="'CustomFieldsAddOutput_' + i" class="ma-1" fab small />
                                </td>
                                <td style="width: 50px">
                                    <av-button :text="$gettext('')" @click="item.setvalues.fields.removeAt(index)"
                                               buttonIcon="fa-trash" color="error" iconColor="light" :dynamicID="'CustomFieldsRemoveOutput_' + i" class="ma-1" fab small />
                                </td>
                            </v-layout>
                            <av-button v-if="!Array.isUseful(item.setvalues.fields)" :text="$gettext('Add field')" @click="item.setvalues.fields.push({key: '', value: ''})"
                                       buttonIcon="fa-plus" color="info" iconColor="light" :dynamicID="'CustomFieldsNewOutput_' + i" />
                        </v-layout>
                    </template>

                    <template v-if="item.outputType==='uinotification'" class="pa-2">
                        <v-layout column>
                            <v-autocomplete
                                    v-model="item.uiNotification.recipients"
                                    :items="grants"
                                    chips
                                    label="To"
                                    hide-no-data
                                    hide-selected
                                    item-text="show"
                                    item-value="key"
                                    deletable-chips
                                    multiple
                                    :id="'NotificationRecipientsOutput_' + i"
                                    class="pa-1"
                            ></v-autocomplete>
                            <av-select  v-model="item.uiNotification.level" :label="$gettext('Level')"
                                        :items="notificationLevels" :dynamicID="'NotificationOutput_' + i" class="pa-1"/>

                            <!--                              <av-checkbox :label="$gettext('Blocking')" v-model="item.uiNotification.blocking" :dynamicID="item.name"/>-->
                            <!--<v-checkbox v-model="item.uiNotification.blocking" label="Blocking" :id="'NotificationBlockingOutput_' + i"></v-checkbox>-->
                            <label class="pa-1">Message:</label>
                            <ckeditor :editor="editor.type" v-model="item.uiNotification.data" :id="'uinotification-'+i" style="min-height: 150px; width: 98%; border: 1px solid gray"></ckeditor>
                            <v-layout row v-if="Array.isUseful(variables) && !isActivity">
                                <av-select  v-model="uiNotificationSelectedPlaceholder" :placeholder="$gettext('Select data to insert into text')"
                                            :items="variables" :id="'NotificationVarsOutput_' + i" return-object/>
                                <av-button :text="$gettext('')" @click="addToEditor(i)" buttonIcon="fa-plus" color="info" iconColor="light"
                                           :id="'NotificationAddPlaceHolderOutput_' + i" :toolTip="$gettext('Insert data placeholder at current cursor position')" fab small />
                            </v-layout>
                        </v-layout>
                    </template>
                    <template v-if="item.outputType === 'formfill'" class="pa-2">
                        <v-layout column>
                            <av-switch  :label="$gettext('Choose custom form')" v-model="item.formFill.customFormSelect"/>
                            <av-select  v-model="item.formFill.form"
                                        :items="forms"
                                        :label="$gettext('Form to compile:')"
                                        :dynamicID="'Output_' + i"
                                        v-if="item.formFill.customFormSelect"/>
                            <img style="width: 100%;" :src="formThumbnail(item.formFill.form)" v-if="item.formFill.customFormSelect"/>
                            <v-autocomplete
                                    v-model="item.formFill.recipients"
                                    :items="grants"
                                    chips
                                    label="Notify To:"
                                    hide-no-data
                                    hide-selected
                                    item-text="show"
                                    item-value="key"
                                    multiple
                                    deletable-chips
                                    :id="'FormRecipientsOutput_' + i"
                            ></v-autocomplete>
                            <av-select  v-model="item.formFill.level"
                                        :items="notificationLevels"
                                        :label="$gettext('Notification criticality level')"
                                        :dynamicID="'Output_' + i"/>
                            <!-- TODO complete this part -->
                            <!--<v-checkbox v-model="item.formFill.blocking" label="Require immediate fill-in"></v-checkbox>-->
                            <label v-translate>Notification message:</label>
                            <ckeditor :editor="editor.type" v-model="item.formFill.data"
                                      :id="'formfill-'+i"
                                      style="min-height: 150px; width: 98%; border: 1px solid gray"></ckeditor>
                            <v-layout row v-if="Array.isUseful(variables)">
                                <av-select  v-model="formFillSelectedPlaceholder" :items="variables" :placeholder="$gettext('Select data to insert into text')"
                                            :id="'FormFillPlaceHoldersOutput_' + i" return-object/>
                                <av-button :text="$gettext('')" @click="addToEditor(i)" buttonIcon="fa-plus" color="info" iconColor="light"
                                           :id="'FormFillAddPlaceHolderOutput_' + i" :toolTip="$gettext('Insert data placeholder at current cursor position')" fab small />
                            </v-layout>
                        </v-layout>
                        <v-expansion-panel>
                            <v-expansion-panel-content>
                                <template v-slot:header>
                                    <label class="title" v-translate>Automatic Form Filling</label>
                                </template>
                                <v-layout column>
                                    <v-treeview flat :items="item.formFill.form.formVariables" item-text="name"
                                                v-if="item.formFill.form.formVariables">
                                        <template v-slot:append="{item}">
                                            <av-select  v-model="item.defaultValue"
                                                        :items="variables"
                                                        :label="$gettext('select')"
                                                        :dynamicID="item.name"
                                                        style="max-width: 30%; margin-left: 20px;"
                                                        return-object/>
                                        </template>
                                    </v-treeview>
                                </v-layout>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </template>
                    <template v-if="item.outputType === 'automaticworkorderscheduling'" class="pa-2">
                        <v-layout column>
                            <v-checkbox class="my-2" v-model="item.workorderscheduling.unSchedule" :label="$gettext('Close active workorder')" />
                            <v-combobox v-if="!item.workorderscheduling.unSchedule" v-for="variable in item.workorderscheduling.variables" pa-0 validate-on-blur :label="variable.title"
                                        v-model="variable.defaultValue" :items="variables"/>
                        </v-layout>
                    </template>
                    <template v-if="item.outputType === 'activity'" class="pa-2">
                        <v-layout column>
                            <av-switch  :label="$gettext('Choose custom form')" v-model="item.activityOutput.customFormSelect" class="pa-1"
                                        @change="() => { item.activityOutput.asset = '' }"/>
                            <av-select  v-model="item.activityOutput.form"
                                        :items="forms"
                                        :label="$gettext('Form to compile:')"
                                        :dynamicID="'Output_' + i"
                                        v-if="item.activityOutput.customFormSelect"
                                        class="pa-1"/>
                            <img style="width: 100%;" :src="formThumbnail(item.formFill.form)" v-if="item.activityOutput.customFormSelect" class="pa-1"/>
                            <av-select  v-if="Array.isUseful(assetsFiltered)"
                                        v-model="item.activityOutput.asset"
                                        :items="assetsFiltered"
                                        item-text="id"
                                        :label="$gettext('Bind to asset')"
                                        :dynamicID="'Output_' + i"
                                        class="pa-1 mb-2"/>
                            <v-autocomplete
                                v-model="item.activityOutput.recipients"
                                :items="grants"
                                chips
                                label="Notify To:"
                                hide-no-data
                                hide-selected
                                item-text="show"
                                item-value="key"
                                multiple
                                deletable-chips
                                :id="'FormRecipientsOutput_' + i"
                                class="pa-1"
                            ></v-autocomplete>
                            <av-select  v-model="item.activityOutput.level"
                                        :items="notificationLevels"
                                        :label="$gettext('Notification criticality level')"
                                        :dynamicID="'Output_' + i"
                                        class="pa-1"/>
                            <!-- TODO complete this part -->
                            <!--<v-checkbox v-model="item.formFill.blocking" label="Require immediate fill-in"></v-checkbox>-->
                            <label v-translate class="pa-1">Notification message:</label>
                            <ckeditor :editor="editor.type" v-model="item.activityOutput.data"
                                      :id="'activityOutput-'+i"
                                      style="min-height: 150px; width: 98%; border: 1px solid gray" class="pa-1"></ckeditor>
                        </v-layout>
                    </template>
                    <template v-if="item.outputType === 'reportExport'" class="pa-2">
                        <v-layout column>
                            <av-select
                                :items="reports"
                                :label="$gettext('Report') + ':'"
                                v-model="item.reportExport.report"
                                :dynamicID="'Output_' + i"
                                item-text="id"
                                item-value="id"
                                chips
                                clearable
                                return-object
                                class="pa-2"
                            >
                                <template v-slot:selection='data'>
                                    <v-chip
                                        :key=data.item
                                        close
                                        class="chip--select-multi"
                                        @input="data.parent.selectItem(data.item)"
                                    >
                                        {{ data.item }}
                                    </v-chip>
                                </template>
                                <template v-slot:item='data'>
                                    <v-list-tile-content>
                                        <v-list-tile-title>
                                            {{ data.item }}
                                        </v-list-tile-title>
                                    </v-list-tile-content>
                                </template>
                            </av-select>
                            <div v-if="item.reportExport.report" class="mt-2">
                                <v-layout row>
                                    <av-text-field :label="'Path and new name for: ' + item.reportExport.report.name" class="osk mt-2 ml-2" v-model="item.reportExport.report.newName" :id="i + '-reportsExport-'"></av-text-field>
                                </v-layout>
                                <v-layout row class="pa-1">
                                    <av-select v-model="item.reportExport.report.placeholder" :items="variablesWithTimeFormatting" :label="$gettext('Select data to insert into name')"
                                               :dynamicID="'Output_' + i" return-object v-if="Array.isUseful(variablesWithTimeFormatting) && !isActivity"/>
                                    <av-button :text="$gettext('')" @click="addToEditor(i, false)" buttonIcon="fa-plus" color="info" iconColor="light" :dynamicID="'Output_' + i"
                                               :toolTip="$gettext('Insert data placeholder in the file path')" fab small v-if="Array.isUseful(variablesWithTimeFormatting) && !isActivity"/>
                                </v-layout>
                                <av-select multiple chips deletable-chips v-model="item.reportExport.report.parametricFilters" :items="variables" :label="$gettext('Select data to provide as parametric filters to report')"
                                           :dynamicID="'Output_' + i" return-object v-if="Array.isUseful(variables) && !isActivity"/>
                            </div>
                        </v-layout>
                    </template>
                    <template v-if="item.outputType === 'customMessage'" class="pa-2">
                        <v-layout column>
                            <av-text-field label="Topic: string configurable by user" class="osk mt-2 ml-2" v-model="item.customMessage.topic" :id="i + '-customMessage-'"></av-text-field>
                            <label class="pa-1 mt-2 subheading" v-translate>Body: json with template parameters</label>
                            <v-jsoneditor v-model="item.customMessage.data" :plus="false" class="mt-2" height="220px" style="width: 98%;margin-left: 3px;" @error="onJsonEditorError"
                                          :options="{ modes: ['tree', 'view', 'form', 'code', 'text', 'preview'], mode: 'code' }" :ref="'customMessageBody-' + i"
                                          :id="'CustomMessageBodyOutput_' + i"/>
                            <v-layout row class="pa-1 mt-3" v-if="Array.isUseful(variablesWithTimeFormatting) && !isActivity">
                                <av-select v-model="customMessageSelectedPlaceholder" :items="variablesWithTimeFormatting" :label="$gettext('Select data to insert into body')"
                                           :dynamicID="'Output_' + i" return-object/>
                                <av-button :text="$gettext('')" @click="addToEditor(i)" buttonIcon="fa-plus" color="info" iconColor="light" :id="'AddCustomMessagePlaceHolderOutput_' + i"
                                           :toolTip="$gettext('Insert data placeholder at current cursor position')" fab small />
                            </v-layout>
                        </v-layout>
                    </template>
                    <v-layout v-if="options.fixedOutputs !== true" ma-2 row>
                        <v-spacer></v-spacer>
                        <av-button :text="$gettext('Delete Output')"  @click="deleteOutput(i)" buttonIcon="fa-trash" color="error" iconColor="light" v-if="item.outputType !== 'activity'"/>
                    </v-layout>
                </template>
                <av-button :text="$gettext('Add Output')"  @click="addOutput('')" buttonIcon="fa-plus" color="info" iconColor="light"/>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>


import InlineEditor from '@ckeditor/ckeditor5-build-inline'
import VJsoneditor from 'v-jsoneditor/src/index';
import Rules from '@/api/rules'
import Grants from '@/api/grants'

export default {
    name: "OutputsEditor",
    components: {
        VJsoneditor
    },
    props: {
        rules: {
            type: Array,
            default: () => []
        },
        dataItems: {
            type: Array,
            default: null
        },
        outputs: {
            type: Array,
            default: () => []
        },
        options: null,
        fieldMode: {
            type: Boolean,
            default: false
        },
        isActivity: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            outputTypes: this.$config.isAvionics ? [
                {text: this.$gettext("Email"), value: "email"},
                {text: this.$gettext("External service"), value: "restapi"},
                {text: this.$gettext("UI notification"), value: "uinotification"},
                {text: this.$gettext("Form fill request"), value: "formfill"},
                {text: this.$gettext("Export report to folder"), value: "reportExport"},
                {text: this.$gettext("Custom message output"), value: "customMessage"}] : [
                {text: this.$gettext("Email"), value: "email"},
                {text: this.$gettext("Page redirect"), value: "redirect"},   //TODO TMW Only
                {text: this.$gettext("Add custom fields"), value: "setvalues"},   //TODO TMW Only add placeholders
                {text: this.$gettext("External service"), value: "restapi"}],
            restAPIMethods: ["GET", "POST", "PUT", "DELETE"],
            standardHttpHeaders: [],
            standardHttpHeadersValues: [],
            triggerModes: [{text: this.$gettext("If true"), value: "onTrue"}, {
                text: this.$gettext("If NOT true"),
                value: "onFalse"
            }],
            notificationLevels: this.isActivity ? ["Normal", "Urgent"] : ["Info", "Warning", "Error"],
            emailIsValid: [],
            editor: {
                type: InlineEditor,
            },
            mailSelectedPlaceholder: {},
            apiSelectedPlaceholder: {},
            customMessageSelectedPlaceholder: {},
            uiNotificationSelectedPlaceholder: {},
            formFillSelectedPlaceholder: {},
            redirectSelectedPlaceholder: {},
            itemsTo: [],
            searchTo: [],
            apiRules: {
                urlValidation: value => RegExp("^(ht|f)tp(s?)\\:\\/\\/[0-9a-zA-Z]([-.\\w]*[0-9a-zA-Z])*(:(0-9)*)*(\\/?)([a-zA-Z0-9\\-\\.\\?\\,\\'\\/\\\\\\+&%{}\\$#=_]*)?$").test(value) || "invalid URL"
            },
            forms: [],
            customFormSelect: false,
            reports: [],
            dateTimeFormat: '',
            currentI: 0,
            currentIndex: 0,
            assetsForActivity: [],
            itemsToLoadProfiles: {
                Asset: {
                    profileSettingName: 'assetRegisterVisualizationOptions',
                    listOfItems: [],
                    functionForListOfItems: this.loadAssets,
                    listOfProfilesNames: [],
                    pagePath: 'assetsRegister',
                    itemType: 'generic.assetsRegister',
                },
                Maintenance: {
                    profileSettingName: 'maintenanceRegisterVisualizationOptions',
                    listOfItems: [],
                    functionForListOfItems: this.loadMaintenance,
                    listOfProfilesNames: [],
                    pagePath: 'maintenanceRegister',
                    itemType: 'generic.maintenanceRegister',
                },
                Activities: {
                    profileSettingName: 'activityExportProfile',
                    listOfItems: [],
                    functionForListOfItems: this.loadActivities,
                    listOfProfilesNames: [],
                    pagePath: 'activitiesPage',
                    itemType: 'activity.activitiesPage',
                },
            },
        }
    },
    created() {
    if (this.outputs.length > this.emailIsValid.length) {
      for (let i = this.emailIsValid.length; i < this.outputs.length; i++) {
        this.emailIsValid.push({valid: true});
        this.searchTo.push({text: ""});
        if (this.outputs[i].outputType === "email") {
          for (let r = 0; r < this.outputs[i].email.recipients.length; r++) {
            this.itemsTo.push({text: this.outputs[i].email.recipients[i]})
          }
        }
      }
    }
    //Get available forms list
    this.loadForms();
    //Get available reports for email attachments
    this.getReports();
    //Get user profiles for generic table exportation
    this.loadUserProfiles();

    if (this.isActivity && (this.outputs.length === 0 || !this.outputs.some(o => o.outputType === 'activity'))) {
      this.addOutput("activity")
    }
    if (this.isActivity) {
      this.outputTypes.push({text: this.$gettext("Activity output"), value: "activity"});
      //Removed if we loadUserProfiles (that function load assets also)
      // this.loadAssetsForActivity();
    }


  },
    computed: {
        definedRules() {
            return this.rules;
        },
        variables() {
            return Rules.getVariablesDescriptors(this.dataItems, this.fieldMode, true);
        },
        variablesWithTimeFormatting() {
            let data = [{name: 'addSecond', text: 'Add Second'}, {name: 'addMinute', text: 'Add Minute'}, {name: 'addHour', text: 'Add hour'},
                {name: 'addDay', text: 'Add Day'}, {name: 'addMonth', text: 'Add Month'}, {name: 'addYear', text: 'Add Year'}];
            let variables = Rules.getVariablesDescriptors(this.dataItems, this.fieldMode, true);
            return data.concat(variables);
        },
        grants() {
            return Grants.getLevels()
        },
        formThumbnail() {
            return selectedForm => {
                if (!selectedForm)
                    return "";
                for (let form of this.forms) {
                    if (selectedForm.properties.name === form.value.properties.name)
                        return form.value.thumbnail;
                }
            }
        },
        outputTypesFiltered() {
            if (this.isActivity)
                return this.outputTypes.filter(ot => ot.value === 'email' || ot.value === 'uinotification' || ot.value === 'activity')
            return this.outputTypes
        },
        assetsFiltered() {
            let filtered = this.assetsForActivity;
            let activityOutput = this.outputs.find(output => output.outputType === 'activity');
            // activityOutput.activityOutput.machine = "";
            if (!activityOutput.activityOutput.customFormSelect)
                return filtered;
            else if (activityOutput.activityOutput.customFormSelect && activityOutput.activityOutput.form && activityOutput.activityOutput.form.properties.type === 3401)
                return  filtered.filter(asset => asset.NameSpace === activityOutput.activityOutput.form.properties.visualizationTweaksValues.targetEntity);
            else
                return [];
        }
    },
    watch: {
      searchTo: {
        deep: true,
        handler(searchValue) {
          for (let i = 0; i < searchValue.length; i++) {
            if (searchValue[i].text === "") {
              this.emailIsValid[i].valid = true;
            }
          }
        }
      },
      rules: {
        deep: true,
        handler(rulesData) {
         let resetTriggerParams;
         //FN clear outputs's  trigger and triggerMode property when the rule's condition is deleted
          for (let i=0;i<this.outputs.length;i++) {
            resetTriggerParams = true;
            for(let j=0;j<rulesData.length;j++) {
              if(this.outputs[i].trigger.name === rulesData[j].name) {
                resetTriggerParams = false;
                break;
              }
            }
            if(resetTriggerParams) {
              this.outputs[i].trigger.name = "";
              this.outputs[i].trigger.value = null;
              this.outputs[i].trigger.type = "";
              this.outputs[i].triggerMode = "";
            }
          }
        }
      },
  },
    methods: {
        keyUp(event, i) {
            if (event.key === ";") {
                this.addRecipient(event, i)
            }
        },
        focusOut(event, i) {
            this.addRecipient(event, i)
        },
        addRecipient(event, i) {

            let regex = new RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$");
            let mail;//= Object.isUseful(event.target.value) ? event.target.value.replace(";","") : event.target.value;
            if (Object.isUseful(event.target.value)) {
                mail = event.target.value.replace(";", "");
                if (mail !== "") {
                    if (regex.test(mail)) {
                        this.itemsTo.push(mail);
                        this.outputs[i].email.recipients.push(mail);
                        this.searchTo[i].text = "";
                        this.emailIsValid[i].valid = true;
                    } else {
                        if (event.type === "keyup") {
                            this.emailIsValid[i].valid = false;
                        }
                    }
                }
            }
        },
        addOutput(outputType) {
            if (this.options.fixedOutputs) {
                if (typeof this.options.fixedOutputs === "function")
                    this.options.fixedOutputs();
                return;
            }
            let output = {
                outputType: outputType,
                trigger: (this.isActivity && this.definedRules.length) ? this.definedRules[0] : "",
                triggerMode: (this.isActivity && this.definedRules.length) ? "onTrue" : "",
                email: {
                    type: "mail",
                    recipients: [],
                    subject: "",
                    data: "",
                    attach: ""
                },
                api: {
                    type: "api",
                    url: "",
                    method: "",
                    data: "",
                    headers: []
                },
                redirect: {
                    type: "redirect",
                    message: ""
                },
                error: {
                    type: "error",
                    message: ""
                },
                setvalues: {
                    type: "setvalues",
                    fields: [{key: '', value: ''}]
                },
                uiNotification: {
                    type: "uinotification",
                    recipients: this.grants.map(g => g.key),
                    data: "",
                    blocking: false,
                    level: this.notificationLevels[0]
                },
                formFill: {
                    type: "formfill",
                    recipients: this.grants.map(g => g.key),
                    form: "",
                    blocking: false,
                    level: this.notificationLevels[0],
                    customFormSelect: false
                },
                activityOutput: {
                    type: "activity",
                    recipients: this.grants.map(g => g.key),
                    form: "",
                    asset: "",
                    blocking: false,
                    level: this.notificationLevels[0],
                    customFormSelect: false
                },
                reportExport: {
                    type: "reportExport",
                    report: null
                },
                customMessage: {
                    type: "customMessage",
                    topic: "",
                    data: "",
                },
                emailPlaceholders: [],
                apiPlaceholders: [],
                redirectPlaceholders: [],
                uiNotificationPlaceholders: [],
                formFillPlaceholders: [],
                reportExportPlaceholders: [],
                customMessagePlaceholders: []
            };

            this.outputs.push(output);
            this.searchTo.push({text: ""});
            this.emailIsValid.push({valid: true})
        },
        deleteOutput(i) {
            this.outputs.removeAt(i);
            this.searchTo.removeAt(i);
            this.emailIsValid.removeAt(i);
        },
        addHttpHeader(item) {
            if (!Array.isUseful(item.api.headers))
                this.$set(item.api, "headers", []);
            item.api.headers.push({key: '', value: ''})
        },
        addToEditor(i, url) {
            let textToInsert = "";
            let editor = null;
            let textField = null;
            let editorInstance = null;
            let rangeSelection = null;
            let idSelector = "";
            let oType = this.outputs[i].outputType;
            let timeFormattingPlaceholders = {
                'addSecond': '{{now|TimeSecond}}',
                'addMinute': '{{now|TimeMinute}}',
                'addHour': '{{now|TimeHour}}',
                'addDay': '{{now|TimeDay}}',
                'addMonth': '{{now|TimeMonth}}',
                'addYear': '{{now|TimeYear}}'
            };

            switch (oType) {
                case "email":
                case "uinotification":
                case "formfill":
                    if (oType === "email") {
                        idSelector = 'mail-' + i;
                        textToInsert = "{{" + this.mailSelectedPlaceholder.text + "}}";
                    } else if (oType === "uinotification") {
                        idSelector = 'uinotification-' + i;
                        textToInsert = "{{" + this.uiNotificationSelectedPlaceholder.text + "}}";
                    } else if (oType === "formfill") {
                        idSelector = 'formfill-' + i;
                        textToInsert = "{{" + this.formFillSelectedPlaceholder.text + "}}";
                    }
                    editor = document.getElementById(idSelector);

                    editorInstance = editor.ckeditorInstance;
                    rangeSelection = editorInstance.model.document.selection.getFirstRange();

                    editorInstance.model.change(writer => {
                        writer.insertText(textToInsert, editorInstance.model.document.selection.getFirstPosition());
                    });

                    if (oType === "email")
                        this.outputs[i].emailPlaceholders.push(this.mailSelectedPlaceholder);
                    else if (oType === "uinotification")
                        this.outputs[i].uiNotificationPlaceholders.push(this.uiNotificationSelectedPlaceholder);
                    else if (oType === "formfill")
                        this.outputs[i].formFillPlaceholders.push(this.formFillSelectedPlaceholder);
                    break;
                case "restapi":
                    if (!url) {
                        textToInsert = "{{" + this.apiSelectedPlaceholder.text + "}}";
                        editor = this.$refs['postData-' + i];
                        editorInstance = editor[0].editor;
                        editorInstance.aceEditor.insert(textToInsert);
                        this.outputs[i].apiPlaceholders.push(this.apiSelectedPlaceholder)
                    } else {
                        textToInsert = "{{" + this.apiSelectedPlaceholder.text + "}}";
                        this.outputs[i].api.url += textToInsert;
                        this.outputs[i].apiPlaceholders.push(this.apiSelectedPlaceholder)
                    }
                    break;
                case "redirect":
                    if (url) {
                        textToInsert = "{{" + this.redirectSelectedPlaceholder.text + "}}";
                        this.outputs[i].redirect.url += textToInsert;
                        this.outputs[i].redirectPlaceholders.push(this.redirectSelectedPlaceholder)
                    }
                    break;
                case "reportExport":
                    let report = this.outputs[i].reportExport.report
                    this.outputs[i].reportExportPlaceholders.push(report.placeholder);
                    textToInsert = timeFormattingPlaceholders[report.placeholder.name]?
                        timeFormattingPlaceholders[report.placeholder.name] : "{{" + report.placeholder.text + "}}";
                    report.newName += textToInsert;
                    break;
                case "customMessage":
                    textToInsert = timeFormattingPlaceholders[this.customMessageSelectedPlaceholder.name]?
                        timeFormattingPlaceholders[this.customMessageSelectedPlaceholder.name] : "{{" + this.customMessageSelectedPlaceholder.text + "}}";
                    editor = this.$refs['customMessageBody-' + i];
                    editorInstance = editor[0].editor;
                    editorInstance.aceEditor.insert(textToInsert);
                    this.outputs[i].customMessagePlaceholders.push(this.customMessageSelectedPlaceholder);
                    break;
            }
        },
        onJsonEditorError(error) {
            this.$root.showWarningNotification(error, true);
        },
        loadForms() {
            this.forms.clear();
            let self = this;
            this.$dynamicElements.LoadItems('forms', false, false, true)
                .then(result => {
                    for (let form of result)
                        self.forms.push({
                            text: form.properties.name,
                            value: form
                        });
                })
                .catch(error => {
                    console.error(error);
                    this.$root.showErrorNotification(this.$gettext("Error in retrieving saved forms from DB."), true);
                })
        },
        loadAssetsForActivity() {
            let self = this;
            self.$entities.getAllEntities()
                .then(result => {
                    if (result) {
                        self.assetsForActivity = result.filter(item => {
                            return item.EntityKeys.SystemEntity === 'Asset'
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        disableActivityItem(item) {
            return item.value === 'activity' && this.outputs.length > 1 && this.isActivity;
        },
        getReports() {
            let self = this;
            this.$dynamicElements.list("reports", "visibleToLevels").then((reports) => {
                if (Array.isUseful(reports)) {
                    self.reports.push({ header: this.$gettext("Reports")}, { divider: true });
                    reports.forEach(report => {
                        let reportPdfObj = {type: 'pdf', name: report + '.pdf', id: 'Reports - ' + report + 'pdf'};
                        let reportXlsxObj = {type: 'xlsx', name: report + '.xlsx', id: 'Reports - ' + report + 'xlsx' };
                        self.reports.push(reportPdfObj, reportXlsxObj);
                    });
                }
            });
        },
        async loadUserProfiles() {
            let self = this;
            for (const property in self.itemsToLoadProfiles) {
                let items = false;
                //Used not to break loop if there are any error
                try {
                    items = await self.itemsToLoadProfiles[property].functionForListOfItems();
                } catch {}

                //If there is not items no need to get profiles
                if (items) {
                    self.reports.push({ header: self.$gettext(property)}, { divider: true });

                    //Add exportation of default views
                    let profilePdfObj = { type: 'pdf', name: 'Default view.pdf', itemType: self.itemsToLoadProfiles[property].itemType,
                        id: self.$gettext(property) + ' - Default view.pdf'};
                    let profileXlsxObj = {type: 'xlsx', name: 'Default view.xlsx', itemType: self.itemsToLoadProfiles[property].itemType,
                        id: self.$gettext(property) + ' - Default view.xlsx'};
                    self.reports.push(profilePdfObj, profileXlsxObj);

                    self.itemsToLoadProfiles[property].listOfItems = items;
                    //Get visualizations profiles
                    let profiles = [];
                    try {
                        profiles = await self.$settings.list(self.itemsToLoadProfiles[property].profileSettingName);
                    } catch (err) {
                        console.log(err);
                        self.$root.showErrorNotification("An error occurred while loading settings from DB", true);
                    }
                    if (Array.isUseful(profiles)) {
                        profiles.forEach(profile => {
                            let profilePdfObj = {type: 'pdf', name: profile.Name + '.pdf', itemType: self.itemsToLoadProfiles[property].itemType,
                                id: self.$gettext(property) + ' - ' + profile.Name + 'pdf' };
                            let profileXlsxObj = {type: 'xlsx', name: profile.Name + '.xlsx', itemType: self.itemsToLoadProfiles[property].itemType,
                                id: self.$gettext(property) + ' - ' + profile.Name + 'xlsx'};
                            self.reports.push(profilePdfObj, profileXlsxObj);
                        });
                    }
                }
            }
        },
        async loadAssets() {
            let self = this;
            let items = await self.$entities.getAllEntities();
            if (Array.isUseful(items)) {
                self.assetsForActivity = items.filter(item => {
                    return item.EntityKeys.SystemEntity === 'Asset'
                });
                return Array.isUseful(self.assetsForActivity)
            }
        },
        async loadMaintenance() {
            return Array.isUseful(await this.$maintenance.getMaintenanceRegister());
        },
        async loadActivities() {
            //TODO Check if this function is needed for activities
            return true;
        }
    }
}
</script>

<style>

</style>
