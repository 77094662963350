import Vue from 'vue'

export default ({ app, router, store, Vue }) => {
    Vue.prototype.LanguageAPI = {}
    Vue.prototype.LanguageAPI.loadLanguages = function () {
        return Vue.prototype.$axios.get('/languages')
    },
    Vue.prototype.LanguageAPI.loadTranslations = function(languageID) {
        return Vue.prototype.$axios.get('/languages/' + languageID + '/strings')
    }
}
