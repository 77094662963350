<template>
    <GenericTable/>
</template>

<script>
import GenericTable from "@/components/utilities/GenericTable";
import Entity from "@/api/entities";
import Transaction from "@/api/transactions";
import Maintenance from '@/api/maintenance';

export default {
        name: "AssetsRegister",
        components: {GenericTable},
        extends: GenericTable,
        data: () => ({}),
        computed: {},
        mounted() {
            this.getListOfItems = this.loadAssets;
            this.scopeIndex = "Assets";
            this.timeWindow.setPredefined(2628000);
            this.scopeType = "assetRegisterVisualizationOptions";
            // load minimal staff needed for exportation
            if (this.$route.params.requiredExport) {
                this.loadData();
                return;
            }
            this.$root.setCurrentPageTitle(this.$gettext("Assets register"));
            this.itemDetails = this.assetDetails;
            this.editItem = this.editAsset;
            this.deleteItem = this.deleteAsset;
            this.auditType = "Asset register";
            this.needActionAndDetails = true;
            this.defaultVisualizationProfile = [{name: 'Asset.assetId', text: this.$gettext("Asset identifier")}, {name: 'Asset.assetDescription', text: this.$gettext("Asset description")}, {name: 'Name', text: this.$gettext("Entity Name")}, {name: 'IsEntityActive', text: this.$gettext("Activation Status")}];
            this.addDetailsButtons();
            this.addActionsGrants();
            this.loadData();
            this.loadForms();
        },
        methods: {
            async loadAssets() {
                this.waitItems = new Promise((resolve, reject) => {
                    let self = this;
                    if(self.$route.params && self.$route.params.listOfAssetsForMachine && self.$route.params.machine){
                        self.listOfItems = self.$route.params.listOfAssetsForMachine;
                        self.filterMessage = self.$gettext("Assets filtered by machine: {0}").format(self.$route.params.machine);
                        resolve(true);
                    } else {
                        Entity.getAllEntities(self.timeWindow.getStart(),self.timeWindow.getEnd())
                            .then(t => {
                                if (t) {
                                    self.listOfItems = t.filter(item => {
                                        return item.EntityKeys.SystemEntity === 'Asset'
                                    });
                                    resolve(Array.isUseful(self.listOfItems))
                                }
                                resolve(false)
                            })
                            .catch(err => {
                                debugger
                                resolve(false);
                            })
                    }
                });
            },
            assetDetails(entity) {
                this.$router.push({name: 'entityDetails', params: {entity: entity}})
            },
            async deleteAsset(item) {
                return new Promise(async (resolve, reject) => {
                    let self = this;
                    try {
                        let cannotBeDeleted = await Transaction.entityHasTransactions(item.id);
                        if (cannotBeDeleted) {
                            this.$root.showWarningNotification(this.$gettext("Item cannot be deleted as there are maintenance records associated to it"))
                            resolve(false);
                            return
                        }
                    } catch (err) {
                        this.$root.showErrorNotification(this.$gettext("Error occurred while verifying if item is deletable, deletion is aborted to prevent data corruption"));
                        resolve(false);
                        return
                    }
                    Entity.deleteInstance(item.id)
                        .then(() => {
                            //audit trail for asset deletion
                            self.$audits.save(self.$root.userName, self.$audits.items().assetRegisterDeleted, "", "", item.EntityKeys.assetId)
                                .catch(err => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                });
                            resolve(true);
                        })
                        .catch(err => {
                            self.$root.showErrorNotification(err, true, false);
                            resolve(false);
                        })
                });
            },
            editAsset(entity) {
                this.$router.push({
                    name: 'formsEdit',
                    params: {routeId: entity.Name, entity: entity, backPath: {name: 'assetsRegister'}}
                });
            },
            goToRegister(assetId) {
                const asset = this.listOfItems.find(item => item.id === assetId);
                this.$router.push({name: 'maintenanceRegister', params: {entity: asset}});
            },
            showMachineSettings(assetId) {
                const asset = this.listOfItems.find(item => item.id === assetId);
                return !!(asset[asset.NameSpace].LinkedMachines && Array.isUseful(asset[asset.NameSpace].LinkedMachines));
            },
            showDocumentsPage(assetId) {
                const asset = this.listOfItems.find(item => item.id === assetId);
                // console.log(asset[asset.NameSpace]);
                // console.log(this.iterate(asset[asset.NameSpace]));
                return true
            },
            // iterate(obj) {
            //     if(Array.isArray(obj) && obj.length > 0) {
            //         let testObj = obj[0];
            //         if (testObj.hasOwnProperty('FileName') && testObj.hasOwnProperty('MediaId') && testObj.hasOwnProperty('Type')) {
            //             return true
            //         }
            //     }
            //     Object.keys(obj).forEach(key => {
            //         if (typeof obj[key] === 'object' && obj[key] !== null) {
            //             this.iterate(obj[key]);
            //         }
            //     });
            //     return false
            // },
            goToMachineSettings(assetId) {
                let self = this;
                const asset = self.listOfItems.find(item => item.id === assetId);
                const linkedMachines = asset[asset.NameSpace].LinkedMachines;
                self.$root.setCurrentPageTitle(self.$gettext("Machines Settings"));
                self.$router.push({ name: 'machinesSettings', params: { linkedMachines : linkedMachines, backPath: '/assetsRegister' }})
            },
            goToDocumentsPage(assetId) {
                let self = this;
                const asset = self.listOfItems.find(item => item.id === assetId);
                self.$router.push({ name: 'documentationPage', params: { assetId: asset.EntityKeys.assetId, backPath: '/assetsRegister' }})
            },
            async goToActivitiesPage(assetId) {
                let self = this;
                const asset = self.listOfItems.find(item => item.id === assetId);
                let forms = [];
                forms.push(asset.EntityKeys.EntityName)
                let maintenanceForAsset = await Maintenance.getMaintenanceRegisterByAsset(asset.EntityKeys.assetId, asset.EntityKeys.EntityName);
                if (Array.isArray(maintenanceForAsset) && maintenanceForAsset.length > 0) {
                    maintenanceForAsset.forEach(maintenance => {
                       if (!forms.includes(maintenance.Transaction.NameSpace))
                           forms.push(maintenance.Transaction.NameSpace);
                    });
                }
                self.$router.push({ name: 'activitiesPage', params: { formName: forms, backPath: '/assetsRegister' }})
            },
            addDetailsButtons() {
                let self = this;
                self.detailsGroup.children.push({ icon: 'fas fa-books', text: 'Maintenance records', color: 'blue', handler: 'goToRegister', description: 'View related maintenance records',
                    getGrant: () => { return self.$grants.get().maintenance.canVisualizeMaintenanceRecords }});
                self.detailsGroup.children.push({ icon: 'fa fa-microchip', text: 'Machines settings', color: 'blue', handler: 'goToMachineSettings', description: 'Machines settings',
                    getGrant: (assetId) => { return self.$grants.get().settings.machineSettings && self.$config.deployment === 2 && self.showMachineSettings(assetId) }});
                self.detailsGroup.children.push({ icon: 'fas fa-folder-open', text: 'Documents', color: 'blue', handler: 'goToDocumentsPage', description: 'Documents page',
                    getGrant: (assetId) => { return self.showDocumentsPage(assetId) && self.$grants.get().documentationPage.canVisualizeDocumentationPage }});
                self.detailsGroup.children.push({ icon: 'fas fa-tasks-alt', text: 'Activities', color: 'blue', handler: 'goToActivitiesPage', description: 'Activities',
                    getGrant: () => { return self.$grants.get().activities.canVisualizeActivities }});
            },
            addActionsGrants() {
                let self = this;
                let editAction = self.actionsGroup.children.find(item => item.handler === 'editItem');
                if (editAction)
                    editAction.getGrant = () => { return self.$grants.get().maintenance.canModifyAssetsRecords }
                let deleteAction = self.actionsGroup.children.find(item => item.handler === 'deleteItem');
                if (deleteAction)
                    deleteAction.getGrant = () => { return self.$grants.get().maintenance.canDeleteAssetsRecords }
            }
        }
    }
</script>

<style scoped>
    .v-btn:before {
        opacity: 0 !important;
    }

    .v-ripple__container {
        opacity: 0 !important;
    }
</style>
