<template>
    <DynamicElementBase>
        <v-btn v-if="zoomOrPanned && (chartType === 'Line' || chartType === 'Bars') && loaded" fab style="position: absolute; top: 100px; left: 40px;" @click="$refs.graph.resetVisualization(), zoomOrPanned = false"><av-icon color="blue">fas fa-undo</av-icon></v-btn>
        <v-layout v-if="labelsPosition === 'right' && loaded" fill-height row>
            <v-flex :class="{ xs9: (labelsAreaVisible || commentsAreaVisible), xs12: !(labelsAreaVisible || commentsAreaVisible) }">
                <template v-if="chartType === 'Line'">
                    <LineChart ref="graph" style="width: 100%; height: 100%;" v-if="loaded" :chartdata="dataCollection.chartData" :options="options" :plugins="plugins"></LineChart>
                </template>
                <template v-else-if="chartType === 'Bars'">
                    <BarChart ref="graph" style="width: 100%; height: 100%;" v-if="loaded" :chartdata="dataCollection.chartData" :options="options"></BarChart>
                </template>
                <template v-else-if="chartType === 'Horizontal bars'">
                    <HorizontalBarChart ref="graph" style="width: 100%; height: 100%;" v-if="loaded" :chartdata="dataCollection.chartData" :options="options"></HorizontalBarChart>
                </template>
                <template v-else-if="chartType === 'Pie'">
                    <PieChart ref="graph" style="width: 100%; height: 100%;" v-if="loaded" :chartdata="dataCollection.chartData" :options="pieOptions"></PieChart>
                </template>
            </v-flex>
            <v-flex xs3 v-if="(labelsAreaVisible || commentsAreaVisible)">
                <div>
                    <v-layout column>
                        <div style="height: 20px" />
                        <label v-for="label in dataCollection.labels" class="subheading pa-0 pl-1" style="width: 100%;">{{label.key}}{{(label.key && label.value) ? ': ' : ' '}}<span class="subheading font-weight-medium pa-0">{{label.value}}</span></label>
                        <div style="height: 20px" />
                        <label v-for="comment in dataCollection.comments" class="body-2 pa-0 pl-1" style="width: 100%;">{{comment}}</label>
                    </v-layout>
                </div>
            </v-flex>
        </v-layout>
        <v-layout v-if="labelsPosition === 'bottom' && loaded" column fill-height>
            <template v-if="chartType === 'Line'">
                <LineChart ref="graph" :style="graphStyle" v-if="loaded" :chartdata="dataCollection.chartData" :options="options"></LineChart>
            </template>
            <template v-else-if="chartType === 'Bars'">
                <BarChart ref="graph" :style="graphStyle" v-if="loaded" :chartdata="dataCollection.chartData" :options="options"></BarChart>
            </template>
            <template v-else-if="chartType === 'Horizontal bars'">
                <HorizontalBarChart ref="graph" style="width: 100%; height: 100%;" v-if="loaded" :chartdata="dataCollection.chartData" :options="options"></HorizontalBarChart>
            </template>
            <template v-else-if="chartType === 'Pie'">
                <PieChart ref="graph" :style="graphStyle" v-if="loaded" :chartdata="dataCollection.chartData" :options="pieOptions"></PieChart>
            </template>
            <v-layout v-if="labelsAreaVisible" row wrap :style="'width: 100%; height: {0}px'.format(labelsAreaHeight)">
                <label v-for="label in dataCollection.labels" class="subheading pa-0 pl-1 pt-0"
                       :style="'width: {0}%;'.format(100 / (dataCollection.labels.length < 3 ? dataCollection.labels.length : Math.ceil(dataCollection.labels.length/2)))">
                    {{label.key}}{{(label.key && label.value) ? ': ' : ' '}}<span class="subheading font-weight-medium pa-0">{{label.value}}</span>
                </label>
            </v-layout>
            <v-layout v-if="commentsAreaVisible" column :style="'width: 100%; height: {0}px'.format(commentsAreaHeight)">
                <label v-for="comment in dataCollection.comments" class="body-2 pa-0 pl-1 pt-1">
                    {{comment}}
                </label>
            </v-layout>
        </v-layout>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
    import LineChart from '@/components/graphics/LineChart.vue'
    import BarChart from '@/components/graphics/BarChart.vue'
    import HorizontalBarChart from '@/components/graphics/HorizontalBarChart.vue'
    import PieChart from '@/components/graphics/PieChart.vue'
    import JsUtils from '@/api/jsutils'
    import ColorSequence from '@/api/colorsequences'

    export default {
        name: "WidgetChart",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            LineChart,
            BarChart,
            HorizontalBarChart,
            PieChart
        },
        data () {
            return {
                zoomOrPanned: false,
                visualizationTargetsIndexes: {
                    "graph": 0,
                    "LegacyLabelsStart": 1, "LegacyLabelsEnd": 6, //This were labels previous to AVionics 1.1 with fixed positions up to 6. Keep them reserved for compatibility
                    "graph max": 7,
                    "trend start": 8,
                    "trend final": 9,
                    "trend value": 10,
                    "label": 11,
                    "low is bad threshold": 12,
                    "low is good threshold": 13,
                    "comment": 14,
                    "graph second axis": 15,
                    "const value": 16,
                    "graph third axis": 17,
                    "vertical marker": 18
                },
                chartType: "Line",
                units: "", //TODO add to tweaks
                dataCollection: null,
                loaded: false,
                labelsPosition: "right",
                xAxesTicksCount: 10, //Controls density of x axes labels
                dataCollectionMustBeRefreshed: false,
                previousWidgetSize: {width: -1, height: -1},
                linesColoredArea: true,
                colorizeByValue: false,
                tweaksUpdating: false,
                //Graphs stuff
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: {
                        duration: 0,
                        onComplete: function () {
                            let ctx = this.ctx;
                            // ctx.font = this.scale.font;
                            // ctx.fillStyle = this.scale.textColor
                            // ctx.textAlign = "center";
                            // ctx.textBaseline = "bottom";
                            let self = this;

                            this.data.datasets.forEach(function (dataset, index) {
                                if(Array.isUseful(dataset.data) && Object.isUseful(dataset.data[0]) && Object.isUseful(dataset.data[0].stringValue)) {
                                    let previousValue = "";
                                    let meta = self.getDatasetMeta(index);
                                    if (!meta.hidden) {
                                        meta.data.forEach(function (element, index) {
                                            if (dataset.data[index].stringValue !== previousValue) {
                                                previousValue = dataset.data[index].stringValue;
                                                ctx.fillStyle = element.options.borderColor;
                                                // var fontSize = 16;
                                                // var fontStyle = 'normal';
                                                // var fontFamily = 'Helvetica Neue';
                                                // ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);
                                                // Make sure alignment settings are correct
                                                ctx.textAlign = 'left';
                                                ctx.textBaseline = 'middle';
                                                var position = element.tooltipPosition();
                                                ctx.fillText(dataset.data[index].stringValue, position.x, position.y /*- (fontSize / 2)*/ - 10);
                                            }
                                        });
                                    }
                                }
                            })
                        }
                    },
                    elements: {
                        line: {
                            tension: 0,
                            fill: true
                        }
                    },
                    propagate: false,
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                bounds: 'data',
                                // format: "HH:mm",
                                // unit: 'minutes',
                                // unitStepSize: 1,
                                displayFormats: {
                                    'millisecond':	'HH:mm:ss.SSS',
                                    'second': 'HH:mm:ss',
                                    'minute': 'HH:mm',
                                    'hour': "MMM DD HH:mm",
                                    'day':	'DD/MM/YYYY',
                                    'week': 'DD/MM/YYYY',
                                    'month': 'MMM YYYY',
                                    'quarter':	'[Q]Q-YYYY',	//'Q1 - 2018'
                                    'year': 'YYYY',
                                },
                                // tooltipFormat: 'HH:mm:ss'
                            },
                            ticks: {
                                callback: function (value, index, values) {
                                    return value;
                                },
                                source: 'auto'
                            },
                            grid: {
                                display: true
                            },
                            offset: false
                        },
                        'axis-default':
                            {
                                position: "left",
                                display: true,
                                max: undefined,
                                ticks: {
                                    //suggestedMin: 0,
                                    callback: (label,index,labels) => {
                                        label = label.toFixed(1);
                                        if(this.getTweakValue("decimalsSeparator"))
                                            label = label.toString().replace(".", this.getTweakValue("decimalsSeparator"));
                                        return this.$utils.numberThousandsSeparator(label, this.getTweakValue("thousandsSeparator")) + this.getTweakValue("y1Units");
                                    }
                                }
                                // ticks: { steps: 10,
                                //     stepValue: 5,
                                //     callback:(label,index,labels)=>{ return label + "%"; } }
                            },
                        'axis-aux':
                            {
                                position: "right",
                                display: false,
                                max: undefined,
                                ticks: {
                                    //suggestedMin: 0,
                                    callback: (label,index,labels)=>{
                                        if(!this.options.scales["axis-aux"].display)
                                            return label;
                                        if(this.getTweakValue("decimalsSeparator"))
                                            label = label.toString().replace(".", this.getTweakValue("decimalsSeparator"));
                                        return this.$utils.numberThousandsSeparator(label, this.getTweakValue("thousandsSeparator")) + this.getTweakValue("y2Units");
                                    }
                                }
                                // ticks: { steps: 10,
                                //     stepValue: 5,
                                //     callback:(label,index,labels)=>{ return label + " c"; } } }] }
                            },
                        'second-axis-aux':
                            {
                                position: "right",
                                display: false,
                                max: undefined,
                                ticks: {
                                    //suggestedMin: 0,
                                    callback: (label,index,labels)=>{
                                        if(!this.options.scales["second-axis-aux"].display)
                                            return label;
                                        if(this.getTweakValue("decimalsSeparator"))
                                            label = label.toString().replace(".", this.getTweakValue("decimalsSeparator"));
                                        return this.$utils.numberThousandsSeparator(label, this.getTweakValue("thousandsSeparator")) + this.getTweakValue("y3Units");
                                    }
                                }
                                // ticks: { steps: 10,
                                //     stepValue: 5,
                                //     callback:(label,index,labels)=>{ return label + " c"; } } }] }
                            },
                        'strings-axis':
                            {
                                position: "right",
                                display: false,
                                max: undefined,
                                suggestedMin: 0,
                                ticks: {
                                    //callback: (label,index,labels)=>{ return label + this.getTweakValue("y3Units"); }
                                }
                                // ticks: { steps: 10,
                                //     stepValue: 5,
                                //     callback:(label,index,labels)=>{ return label + " c"; } } }] }
                            }

                    },
                    plugins: {
                        zoom: {
                            pan: {
                                enabled: true,
                                mode: 'x',
                                onPanComplete: null
                            },
                            zoom: {
                                enabled: true,
                                mode: 'x',
                                onZoomComplete: null
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: function(tooltipItem, index, tooltipItems, data) {
                                    let label = tooltipItem.dataset.label || '';

                                    if (label) {
                                        label += ': ';
                                    }

                                    if (tooltipItem.formattedValue)
                                        label += tooltipItem.formattedValue;
                                    else
                                        label += Math.round(tooltipItem.raw * 1000) / 1000;
                                    // if(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].stringValue)
                                    //     label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].stringValue;
                                    // else
                                    //     label += Math.round(tooltipItem.yLabel * 1000) / 1000;
                                    return label;
                                }
                            }
                        },
                        legend: {
                            display: true,
                            position: "top"
                        },
                        arbitraryLine: {
                            display: false
                        },
                        datalabels: {
                            display: false,
                            color: 'black',
                            anchor: 'end',
                            align: 'top',
                            offset: 5,
                            font: {
                                size: 16
                            }
                        }
                    }
                },
                plugins: [],
                pieOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false,
                    tension: 0.4,
                    propagate: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: "top"
                        },
                        datalabels: {
                            display: false,
                            color: 'white',
                            // anchor: 'end',
                            // align: 'top',
                            // offset: 5,
                            font: {
                                size: 16
                            }

                        }
                    }
                }
            }
        },
        computed: {
            devices() {
                return this.properties.selectedDevices;
            },
            labelsAreaHeight() {
                if(!Array.isUseful(this.dataCollection.labels))
                    return 0;
                if(this.dataCollection.labels.length < 3)
                    return 40;
                else return 80;
            },
            commentsAreaHeight() {
                if(!Array.isUseful(this.dataCollection.comments))
                    return 0;
                return this.dataCollection.comments.length * 35
            },
            labelsAreaVisible() {
                return Array.isUseful(this.dataCollection.labels);
            },
            commentsAreaVisible() {
                return Array.isUseful(this.dataCollection.comments);
            },
            graphStyle() {
                return {
                    width: "100%",
                    height: "calc(100% - {0}px)".format(this.labelsAreaHeight + this.commentsAreaHeight)
                }
            }
        },
        mounted () {
            this.options.plugins.zoom.pan.onPanComplete = this.onPanOrZoom;
            this.options.plugins.zoom.zoom.onZoomComplete = this.onPanOrZoom;

            this.visualizationTweaks = [
                {
                    name: this.$gettext("Chart type"),
                    id: "ChartType",
                    type: "option",
                    options: ["Line", "Bars", /*"Horizontal bars",*/ "Pie"],
                    default: function() {
                        return this.options[0];
                    },
                },
                {
                    name: this.$gettext("Show legend"),
                    id: "ShowLegend",
                    type: "bool",
                    default: function() {
                        return true;
                    }
                },
                {
                    name: this.$gettext("Legend position"),
                    id: "LegendPosition",
                    type: "option",
                    options: [
                        {text: this.$gettext('left'), value: 'left'},
                        {text: this.$gettext('top'), value: 'top'},
                        {text: this.$gettext('right'), value: 'right'},
                        {text: this.$gettext('bottom'), value: 'bottom'}
                    ],
                    default: function() {
                        return this.options[1];
                    }
                },
                {
                    name: this.$gettext("Text position"),
                    id: "TextPosition",
                    type: "option",
                    options: [
                        {text: this.$gettext('right'), value: 'right'},
                        {text: this.$gettext('bottom'), value: 'bottom'}
                    ],
                    default: function() {
                        return this.options[0];
                    }
                },
                {
                    name: this.$gettext("Categories x axis"),
                    id: "CategoriesAxis",
                    type: "bool",
                    hidden: true,
                    default: function() {
                        return false;
                    }
                },
                {
                    name: this.$gettext("Colorize by category"),
                    id: "CategoryColor",
                    type: "bool",
                    hidden: true,
                    default: function() {
                        return false;
                    }
                },
                {
                    name: this.$gettext("Show lines colored area"),
                    id: "LinesFill",
                    type: "bool",
                    hidden: true,
                    default: function() {
                        return true;
                    }
                },
                {
                    name: this.$gettext("Show exact data points X spacing"),
                    id: "Spacing",
                    type: "bool",
                    hidden: false,
                    default: function() {
                        return false;
                    }
                },
                {
                    name: this.$gettext("decimals separator (dot default)"),
                    id: "decimalsSeparator",
                    type: "string",
                    hidden: false,
                    default: function() {
                        return "";
                    }
                },
                {
                    name: this.$gettext("Y axes thousands separator"),
                    id: "thousandsSeparator",
                    type: "string",
                    hidden: false,
                    default: function() {
                        return "";
                    }
                },
                {
                    name: this.$gettext("First Y axis units"),
                    id: "y1Units",
                    type: "string",
                    hidden: false,
                    default: function() {
                        return "";
                    }
                },
                {
                    name: this.$gettext("Second Y axis units"),
                    id: "y2Units",
                    type: "string",
                    hidden: false,
                    default: function() {
                        return "";
                    }
                },
                {
                    name: this.$gettext("Third Y axis units"),
                    id: "y3Units",
                    type: "string",
                    hidden: false,
                    default: function() {
                        return "";
                    }
                },
                {
                    name: this.$gettext("X units conversion"),
                    id: "xUnits",
                    type: "option",
                    options: [
                        {text: this.$gettext(''), value: ''},
                        {text: this.$gettext('Day of week'), value: 'day of week'},
                        {text: this.$gettext('Time of day'), value: 'time of day'},
                        {text: this.$gettext('Day of year'), value: 'day of year'}
                    ],
                    default: function() {
                        return this.options[0];
                    }
                },
                {
                    name: this.$gettext("Show value labels"),
                    id: "ShowValueLabels",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                },
            ];

            this.visualizationOptions = [
                {
                    name: this.$gettext("Color setting"),
                    id: "color",
                    type: "color",
                    default: "auto",
                    show: false
                }
            ];

            this.visualizationTargets = [
                { show: "graph",   id: this.visualizationTargetsIndexes.graph, default: true, expectsSingleValue: false },
                { show: "graph second axis",  id: this.visualizationTargetsIndexes["graph second axis"], expectsSingleValue: false },
                { show: "graph third axis",  id: this.visualizationTargetsIndexes["graph third axis"], expectsSingleValue: false },
                { show: "label", id: this.visualizationTargetsIndexes["label"], expectsSingleValue: true },
                { show: "low is BAD threshold", id: this.visualizationTargetsIndexes["low is bad threshold"], expectsSingleValue: false },
                { show: "low is GOOD threshold", id: this.visualizationTargetsIndexes["low is good threshold"], expectsSingleValue: false },
                { show: "constant value", id: this.visualizationTargetsIndexes["const value"], expectsSingleValue: false },
                { show: "graph max value", id: this.visualizationTargetsIndexes["graph max"], expectsSingleValue: true },
                { show: "target trend start time", id: this.visualizationTargetsIndexes["trend start"], expectsSingleValue: false },
                { show: "target trend final time", id: this.visualizationTargetsIndexes["trend final"], expectsSingleValue: false },
                { show: "target trend final value", id: this.visualizationTargetsIndexes["trend value"], expectsSingleValue: false },
                { show: "free comment", id: this.visualizationTargetsIndexes["comment"], expectsSingleValue: false },
                { show: "vertical marker", id: this.visualizationTargetsIndexes["vertical marker"], expectsSingleValue: false },
            ];
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    let self = this;
                    //TODO encapsulate tweaks watch in DynamicElementBase
                    //Prevent watch loops when dynamically changing visualization tweaks interdependencies
                    if(this.tweaksUpdating) {
                        this.tweaksUpdating = false;
                        return;
                    }

                    if (this.visualizationTweaks.length > 0) {
                        if (this.visualizationTweaks.length > 1) {
                            this.options.plugins.legend.display = this.getTweakValue("ShowLegend");
                            this.options.plugins.datalabels.display = this.getTweakValue("ShowValueLabels");
                            this.options.plugins.legend.position = this.$utils.isObject(this.getTweakValue("LegendPosition"))?this.getTweakValue("LegendPosition").value: this.getTweakValue("LegendPosition");
                            this.pieOptions.plugins.legend.display = this.getTweakValue("ShowLegend");
                            this.pieOptions.plugins.legend.position = this.$utils.isObject(this.getTweakValue("LegendPosition"))?this.getTweakValue("LegendPosition").value: this.getTweakValue("LegendPosition");
                            this.pieOptions.plugins.datalabels.display = this.getTweakValue("ShowValueLabels");
                            this.chartType = this.getTweakValue("ChartType");
                            this.labelsPosition = this.$utils.isObject(this.getTweakValue("TextPosition"))? this.getTweakValue("TextPosition").value : this.getTweakValue("TextPosition");
                            this.linesColoredArea = this.getTweakValue("LinesFill");
                            this.colorizeByValue = this.getTweakValue("CategoryColor");
                            if (this.getTweakValue("CategoriesAxis")) {
                                this.options.scales.x.type = 'category';
                            }
                            else
                                this.options.scales.x.type = 'time';
                            //Auto set and disable some tweaks that are graph type dependant
                            let previousHiddenState = [this.getTweak("CategoriesAxis").hidden, this.getTweak("LinesFill").hidden, this.getTweak("CategoryColor").hidden];
                            if(this.isBarChart()) {
                                this.linesColoredArea = true;
                                this.getTweak("CategoriesAxis").hidden = false;
                                this.getTweak("LinesFill").hidden = true;
                                this.getTweak("CategoryColor").hidden = false;
                                this.options.scales.x.offset = true;
                            } else if(this.chartType === "Line") {
                                this.colorizeByValue = false;
                                this.getTweak("CategoriesAxis").hidden = false;
                                this.getTweak("LinesFill").hidden = false;
                                this.getTweak("CategoryColor").hidden = true;
                                this.options.scales.x.offset = false;
                            } else {
                                this.getTweak("CategoriesAxis").hidden = true;
                                this.getTweak("LinesFill").hidden = true;
                                this.getTweak("CategoryColor").hidden = true;
                                this.options.scales.x.offset = false;
                            }
                            if(this.isBarChart() && !this.getTweakValue("CategoriesAxis")) {
                                this.setTweakValue("Spacing", true);
                            }
                            this.options.scales.x.ticks.source = (this.getTweakValue("Spacing") ? "data" : "auto");

                            let xConvertTweak = this.getTweak("xUnits");
                            if(xConvertTweak.value === xConvertTweak.options[1].value)
                                this.options.scales.x.ticks.callback = function(value, index, values) {
                                    return self.$dateTime.daysOfWeek()[value]
                                };
                            else if(xConvertTweak.value === xConvertTweak.options[2].value)
                                this.options.scales.x.ticks.callback = function(value, index, values) {
                                    return self.$dateTime.timeStringFromMinuteOfDay(value);
                                };
                            else if(xConvertTweak.value === xConvertTweak.options[3].value) {
                                this.options.scales.x.ticks.callback = function (value, index, values) {
                                    return (new Date(new Date(2000, 0, 1, 0, 0, 0, 0).getTime() + (value * 24 * 3600000)).format("MM/dd"))
                                }
                            } else {
                                const ticks = this.options.scales.x.ticks;
                                const {callback, ...ticksClone} = ticks;
                                this.options.scales.x.ticks = ticksClone;
                                //TODO check meaning of this else
                            }

                            if(JSON.stringify([this.getTweak("CategoriesAxis").hidden, this.getTweak("LinesFill").hidden, this.getTweak("CategoryColor").hidden]) !== JSON.stringify(previousHiddenState))
                                this.tweaksUpdating = true;
                        }

                        this.dataCollectionMustBeRefreshed = true;

                        if(this.$refs.graph)
                            this.$refs.graph.refreshGraph();
                        this.$emit("addedTweaks");
                    }
                    //Update visualization tweaks values and save widget
                    this.saveTweaks();
                },
                deep: true,
            },
            devices() {
                this.$emit("selectedDevicesChanged");
            }
        },
        methods: {
            widgetSize() {
                return {width: this.containerWidth, height: this.containerHeight };
            },
            onPanOrZoom() {
                this.zoomOrPanned = true;
            },
            isOnFirstAuxAxis(dataset) {
                return (dataset.target === this.visualizationTargetsIndexes["graph second axis"]);
            },
            isOnSecondAuxAxis(dataset) {
                return (dataset.target === this.visualizationTargetsIndexes["graph third axis"]);
            },
            isOnAuxAxis(dataset) {
                return (this.isOnFirstAuxAxis(dataset) || this.isOnSecondAuxAxis(dataset));
            },
            getAxis(dataset) {
                if(this.isOnFirstAuxAxis(dataset))
                    return "axis-aux";
                else if(this.isOnSecondAuxAxis(dataset))
                    return "second-axis-aux";
                else return "axis-default";
            },
            refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view

                let trend = {
                    start: "",
                    end: "",
                    value: 0,
                    valid: false,
                    slope: 0
                };

                let constValues = [];

                let lowIsGoodThreshold = null;
                let lowIsBadThreshold = null;

                let firstTimeSample = "";
                let lastTimeSample = "";

                //Clear data collection
                let newDataCollection = {
                    chartData: {
                        labels: [],
                        datasets: []
                    },
                    labels: [],
                    comments: []
                };

                if(dataValues === null) {
                    this.dataCollection = null;
                    return;
                }

                let order = 1;

                //Unwrap graph targeted data
                let self = this;
                let secondAxisNeeded = false;
                let thirdAxisNeeded = false;
                let stringsDataSetIndex = 2;    //Start with an offset to try raising trace over the other

                dataValues.forEach(function (dataSet, index) {
                    if (dataSet && Array.isUseful(dataSet.data)) {
                        //Process chart datasets
                        if (dataSet.target === self.visualizationTargetsIndexes.graph || self.isOnAuxAxis(dataSet) || dataSet.target === self.visualizationTargetsIndexes["vertical marker"]) {
                            if(self.isOnFirstAuxAxis(dataSet))
                                secondAxisNeeded = true;
                            else if(self.isOnSecondAuxAxis(dataSet))
                                thirdAxisNeeded = true;
                            if (!dataSet.isCategorical) {
                                //find oldest and latest time point in all series, will be needed for trends and single values datasets (if any)
                                for (let i = 0; i < dataSet.data.length; i++) {
                                    if (!firstTimeSample)
                                        firstTimeSample = dataSet.data[i].x;
                                    if (!lastTimeSample)
                                        lastTimeSample = dataSet.data[i].x;
                                    if (firstTimeSample > dataSet.data[i].x)
                                        firstTimeSample = dataSet.data[i].x;
                                    if (lastTimeSample < dataSet.data[i].x)
                                        lastTimeSample = dataSet.data[i].x;
                                }
                            }
                            //Create datasets with charts.js format. Formats differs based on chart type
                            if (dataSet.isCategorical || self.getTweakValue("CategoriesAxis")) {

                                if (!self.getTweakValue("CategoriesAxis")) {
                                    self.setTweakValue("CategoriesAxis",true);
                                    if(self.editMode)
                                        self.$root.showInfoNotification(self.$gettext("X Axis was auto-set as categorical to represent selected data"), true )
                                }
                                //Pie charts
                                if (self.chartType === "Pie")
                                    self.parsePieChartData(dataSet, newDataCollection);
                                if (dataSet.target === self.visualizationTargetsIndexes["vertical marker"]) {
                                    newDataCollection.chartData.datasets.push({
                                        categories: [],
                                        label: dataSet.label,
                                        data: [],
                                        order: order++,
                                        yAxisID: self.getAxis(dataSet),
                                        color: self.dataSetColor(dataSet),
                                        categorical: dataSet.isCategorical
                                    });
                                }
                                //Bars charts
                                else {
                                    newDataCollection.chartData.datasets.push({
                                        categories: dataSet.data.map(item => item.x),
                                        label: dataSet.label,
                                        data: dataSet.data,//.map(item => item.y),
                                        order: order++,
                                        yAxisID: self.getAxis(dataSet),
                                        color: self.dataSetColor(dataSet),
                                        categorical: dataSet.isCategorical
                                    });
                                    newDataCollection.chartData.labels.push(dataSet.data.map(item => item.x));
                                }
                            } else {
                                //Find whether values are string type, in this case we recode values to indexed numbers and add labels
                                //this allows showing string datasets on graphs as lined up segments with uniform values
                                if(dataSet.dataType === self.$defines.avionicsDataTypes.text.id) {
                                    //stringsAxisNeeded = true;
                                    let valuesMap = {};
                                    for(let i = 0 ; i < dataSet.data.length ; i++) {
                                        dataSet.data[i].stringValue = dataSet.data[i].y;
                                        if(!dataSet.data[i].stringValue) {
                                            dataSet.data[i].stringValue = "";
                                            dataSet.data[i].y = 2;
                                        } else {
                                            if (!valuesMap.hasOwnProperty(dataSet.data[i].y)) {
                                                stringsDataSetIndex += 1;
                                                valuesMap[dataSet.data[i].y] = stringsDataSetIndex;
                                            }
                                            dataSet.data[i].y = valuesMap[dataSet.data[i].y];
                                        }
                                    }
                                    newDataCollection.chartData.datasets.push({
                                        label: dataSet.label,
                                        data: dataSet.data,
                                        order: order++,
                                        yAxisID: 'strings-axis',
                                        color: self.dataSetColor(dataSet)
                                    });
                                } else {//Plain chart line
                                    if (self.chartType === 'Pie') {
                                        self.parsePieChartData(dataSet, newDataCollection);
                                    } else {
                                        newDataCollection.chartData.datasets.push({
                                            label: dataSet.label,
                                            data: dataSet.data,
                                            order: order++,
                                            yAxisID: self.getAxis(dataSet),
                                            color: self.dataSetColor(dataSet)
                                        });
                                    }
                                }
                            }
                        }
                        //Now process all other visualization targets datasets
                        else if (dataSet.target === self.visualizationTargetsIndexes["low is bad threshold"] && Array.isUseful(dataSet.data))
                            lowIsBadThreshold = {label: dataSet.label, data: dataSet.data, color: self.dataSetColor(dataSet)};
                        else if (dataSet.target === self.visualizationTargetsIndexes["low is good threshold"] && Array.isUseful(dataSet.data))
                            lowIsGoodThreshold = { label: dataSet.label, data: dataSet.data, color: self.dataSetColor(dataSet) };
                        else if (dataSet.target === self.visualizationTargetsIndexes["const value"] && Array.isUseful(dataSet.data))
                            constValues.push({label: dataSet.label, data: dataSet.data.last().y, color: self.dataSetColor(dataSet)});
                        else if (dataSet.target === self.visualizationTargetsIndexes["trend start"])
                            trend.start = Array.isUseful(dataSet.data) ? dataSet.data.last().y : "";
                        else if (dataSet.target === self.visualizationTargetsIndexes["trend final"])
                            trend.end = Array.isUseful(dataSet.data) ? dataSet.data.last().y : "";
                        else if (dataSet.target === self.visualizationTargetsIndexes["trend value"])
                            trend.value = Array.isUseful(dataSet.data) ? dataSet.data.last().y : "";
                    }
                });

                //If there are string traces increase a little bit area to avoid labels going out of graph
                if(stringsDataSetIndex > 2) {
                    for (const axis of Object.entries(this.options.scales)) {
                        if(axis[0] === 'x'){
                            continue
                        }
                        axis[1].suggestedMax = stringsDataSetIndex * 1.1;
                    }
                }

                //Create uniform categories datasets
                let finalCategoriesLabels = [];
                //Create the initial categories array by looking for the max length one
                for(let dataset of newDataCollection.chartData.datasets)
                    if(Array.isUseful(dataset.categories))
                        if(!Array.isUseful(finalCategoriesLabels) || dataset.categories.length > finalCategoriesLabels.length)
                            finalCategoriesLabels = dataset.categories;

                if(Array.isUseful(finalCategoriesLabels)) {
                    //Add missing categories from other datasets
                    for(let dataset of newDataCollection.chartData.datasets)
                        if(Array.isUseful(dataset.categories)) {
                            let difference = dataset.categories.filter(x => !finalCategoriesLabels.includes(x));
                            if (Array.isUseful(difference))
                                finalCategoriesLabels = finalCategoriesLabels.concat(difference);
                        }

                    //Assign final categories to dataset
                    newDataCollection.chartData.labels = finalCategoriesLabels;

                    //Finally fill categorical datasets with missing values to obtain a uniform bars visualization
                    for(let dataset of newDataCollection.chartData.datasets) {
                        if (Array.isUseful(dataset.categories) && dataset.categorical) {
                            let filledCategories = dataset.data.map((point) => point.x);
                            dataset.data = newDataCollection.chartData.labels.map(label => {
                                let indexOfFilledData = filledCategories.indexOf(label);
                                if (indexOfFilledData !== -1) return dataset.data[indexOfFilledData].y;
                                return null;
                            });
                        }
                    }
                }

                //Draw straight lines and apply trend line color coding (if any)
                if(this.chartType === "Line" || this.isBarChart()) {
                    if(lowIsBadThreshold || lowIsGoodThreshold || Array.isUseful(constValues)) {
                        //If x axis is by category we need a point for each category, find the longest dataset.
                        let longestDatasetLength = 0;
                        if(this.options.scales.x.type === 'category') {
                            newDataCollection.chartData.datasets.forEach((dataSet) => {
                                if (dataSet.data.length > longestDatasetLength)
                                    longestDatasetLength = dataSet.data.length;
                            });
                        }
                        //Unroll const values
                        for(let constValue of constValues)
                            newDataCollection.chartData.datasets = newDataCollection.chartData.datasets.concat(this.getConstDataSet(constValue, longestDatasetLength,firstTimeSample, lastTimeSample, "#00000080"));
                        //Unroll low is bad thrs
                        if(lowIsBadThreshold)
                            newDataCollection.chartData.datasets = newDataCollection.chartData.datasets.concat(this.getConstDataSet(lowIsBadThreshold, longestDatasetLength,firstTimeSample, lastTimeSample, lowIsBadThreshold.color !== "auto" ? lowIsBadThreshold.color : "#ff000080"));
                        //Unroll low is good thrs
                        if(lowIsGoodThreshold)
                            newDataCollection.chartData.datasets = newDataCollection.chartData.datasets.concat(this.getConstDataSet(lowIsGoodThreshold, longestDatasetLength,firstTimeSample, lastTimeSample, lowIsGoodThreshold.color !== "auto" ? lowIsGoodThreshold.color : "#00ff0080"));
                    }
                    if(trend.start && trend.end && trend.value) {
                        try {
                            trend.start = Date.parse(trend.start);
                            trend.end = Date.parse(trend.end);
                            if(!isNaN(trend.start) && !isNaN(trend.end)) {
                                trend.slope = trend.value / (trend.end - trend.start);
                                newDataCollection.chartData.datasets.push({
                                    label: "Expected trend",
                                    backgroundColor: "#00000000",
                                    borderColor: "#808080ff",
                                    data: [ { x: firstTimeSample, y: this.getTrendValue(trend, firstTimeSample) }, { x: lastTimeSample, y: this.getTrendValue(trend, lastTimeSample) } ],
                                    type: "line",
                                    order: 0
                                });
                                trend.valid = true;
                            }
                         } catch {}
                    }
                }

                //Apply line styles automatically
                this.colorize(newDataCollection, trend, lowIsBadThreshold, lowIsGoodThreshold);

                this.dataCollection = newDataCollection;   //Datasets don't match

                this.dataCollectionMustBeRefreshed = false;

                //Set points sizes
                for (let dataSetIndex = 0; dataSetIndex < this.dataCollection.chartData.datasets.length; dataSetIndex++) {
                    this.dataCollection.chartData.datasets[dataSetIndex]["pointRadius"] = 2;
                    this.dataCollection.chartData.datasets[dataSetIndex]["pointHoverRadius"] = 10;
                }

                //Unwrap labels data
                this.dataCollection.labels.clear();
                dataValues.forEach(dataSet => {
                    if (dataSet && dataSet.target === self.visualizationTargetsIndexes["label"] || //New single target for labels
                            //Old compatibility values
                        (dataSet.target >= self.visualizationTargetsIndexes.LegacyLabelsStart && dataSet.target <= self.visualizationTargetsIndexes.LegacyLabelsEnd))
                        self.dataCollection.labels.push( { key: dataSet.label, value: (Array.isUseful(dataSet.data) ? dataSet.data.last().y : "") });
                });

                //Unwrap comments
                this.dataCollection.comments.clear();
                dataValues.forEach(dataSet => {
                    if (dataSet && dataSet.target === self.visualizationTargetsIndexes["comment"])
                        self.dataCollection.comments.push( (Array.isUseful(dataSet.data) ? dataSet.data.last().y : dataSet.label) );
                });

                let axisIsScaled = false;

                //Verify if we have any databound graph option
                this.plugins = [];
                let arbritaryLineArray = [];
                dataValues.forEach((dataSet, index) => {
                    if(dataSet && dataSet.target === this.visualizationTargetsIndexes["graph max"] && dataSet.data.length) {
                        let max = Number(dataSet.data.last().y);
                        if(!isNaN(max)) {
                            this.options.scales['axis-default'].max = max;
                            axisIsScaled = true;
                        }
                    }
                    //Refresh data if vertical marker is selected
                    if (dataSet && dataSet.target === this.visualizationTargetsIndexes["vertical marker"]) {
                        // check if vertical marker is hidden
                        let verticalMarkerHidden = false;
                        if (Object.isUseful(this.$refs.graph)) {
                            if (this.$refs.graph.chart.legend.legendItems[index].hidden) {
                                verticalMarkerHidden = true;
                            }
                        }
                        if (!verticalMarkerHidden){
                            let color = dataSet.visualizationOptions.color;
                            let self = this;
                            const arbitraryLine = {
                                id: 'arbitraryLine' + Date.now(),
                                label: dataSet.label,
                                target: dataSet.target,
                                display: true,
                                beforeDraw(chart, args, options) {
                                    const {
                                        ctx,
                                        chartArea: {top, right, bottom, left, width, height},
                                        scales: {x, y}
                                    } = chart;
                                    let xValue = 0;
                                    if (self.getTweakValue("CategoriesAxis")) {
                                        let verticalLineValue = self.dataCollection.chartData.labels.indexOf(Math.round(dataSet.data[0].y))
                                        xValue = x.getPixelForValue(verticalLineValue);
                                        self.drawVerticalLine(ctx, xValue, top, height, color);
                                    } else {
                                        dataSet.data.forEach(item => {
                                            xValue = x.getPixelForValue(item.x);
                                            self.drawVerticalLine(ctx, xValue, top, height, color);
                                        })
                                    }
                                }
                            };
                            arbritaryLineArray.push(arbitraryLine);
                        }
                    }
                });
                this.plugins = arbritaryLineArray;

                //Update axes
                if(!axisIsScaled)
                    this.options.scales['axis-default'].max = undefined;
                this.options.scales["axis-aux"].display = secondAxisNeeded;
                this.options.scales['second-axis-aux'].display = thirdAxisNeeded;

                if (this.$refs.graph) {
                    this.$nextTick(() => {
                        this.$refs.graph.refreshGraph();
                    })
                }

                this.loaded = true;
            },

            drawVerticalLine(ctx, xValue, top, height, color) {
                ctx.save();
                ctx.strokeStyle = color;
                ctx.strokeRect(xValue, top, 0, height);
                ctx.restore();
            },
            getConstDataSet(source, longestDatasetLength,firstTimeSample, lastTimeSample, color) {
                    let data = [];
                    if(!source || !Array.isUseful(source.data))
                        return [];
                    //If dataset is already a time series represent it as is
                    if(source.data.length > 1)
                        data = source.data;
                    else {
                        //If dataset is a single point and graph is a bars one, replicate value for each
                        // category so that we can have a line spanning through all the bars
                        if (this.options.scales.x.type === 'category') {
                            for (let i = 0; i < longestDatasetLength; i++)
                                data.push(source.data[0]);
                        } else {
                            //If dataset is a single point and graph is a lines one, create a new
                            //constant line starting at the oldest point in the chart and finishing at the end
                            data.push({x: firstTimeSample, y: source.data[0].y});
                            data.push({x: lastTimeSample, y: source.data[0].y});
                        }
                    }
                    return [{
                        label: source.label,
                        backgroundColor: "#00000000",
                        borderColor: color,
                        data: data,
                        type: "line",
                        order: 0,
                    }];
            },

            parsePieChartData(dataSet, newDataCollection) {
                let colors =  ColorSequence.getColors(dataSet.data.length);
                for (let j = 0; j < dataSet.data.length; j++) {
                    let color = "";
                    if (!Array.isUseful(newDataCollection.chartData.datasets))
                        newDataCollection.chartData.datasets.push({data: []});

                    if (dataSet.isCategorical) {
                        newDataCollection.chartData.datasets[0].data.push(dataSet.data[j].value);
                        newDataCollection.chartData.labels.push(dataSet.data[j].category);
                    } else {
                        newDataCollection.chartData.datasets[0].data.push(dataSet.data[j].y);
                        newDataCollection.chartData.labels.push(dataSet.label);
                        let tmpColor = this.dataSetColor(dataSet);
                        if(!Array.isUseful(newDataCollection.chartData.datasets[0].colors)) {
                            newDataCollection.chartData.datasets[0].colors = [];
                        }
                        //FN If it's in not auto It finds the interception of the two arrays of colors and catch the first to add as new color for the pie
                        color = tmpColor !== "auto" ? tmpColor : newDataCollection.chartData.datasets[0].colors.length > 0 ? colors.filter(x => !newDataCollection.chartData.datasets[0].colors.includes(x))[0] : colors[0];

                        newDataCollection.chartData.datasets[0].colors.push(color);
                    }
                }
            },

            colorize(dataCollection, trend, lowIsBadThreshold, lowIsGoodThreshold) {

                let self = this;
                if(this.chartType !== "Pie" && !this.colorizeByValue) {
                    let colors = ColorSequence.getColors(dataCollection.chartData.datasets.length);
                    //Apply colorizing strategies based on user selections. If different data binded colorizations are specified
                    //between trend and thresholds we apply only the first one.
                    //Colorize based on trend line (if any)
                    if(trend.valid) {
                        //We can't colorize both on trend and thresholds but user can still select both, ensure we don't colorize threshold (and trend) lines
                        let datasetsToColorizeCount = (dataCollection.chartData.datasets.length - 1 - (lowIsGoodThreshold ? 1 : 0) - (lowIsBadThreshold ? 1 : 0));
                        for(const [index,dataset] of dataCollection.chartData.datasets.entries()) {
                            if(Array.isUseful(dataset.data) && index < datasetsToColorizeCount) {
                                dataset.borderColor = [];
                                for(const [pindex,point] of dataset.data.entries()) {
                                    let trendValue = this.getTrendValue(trend, point.x);
                                    if(point.y > trendValue) {
                                        dataset.borderColor.push(JsUtils.ColorWithOpacity(this.$avStyle.colors.green, 90));
                                        if(pindex === (dataset.data.length - 1)) dataset.backgroundColor = JsUtils.ColorWithOpacity(this.$avStyle.colors.green, 40);
                                    }
                                    else if(point.y > (trendValue * 0.95)) {
                                        dataset.borderColor.push(JsUtils.ColorWithOpacity(this.$avStyle.colors.yellow, 90));
                                        if(pindex === (dataset.data.length - 1)) dataset.backgroundColor = JsUtils.ColorWithOpacity(this.$avStyle.colors.yellow, 40);
                                    }
                                    else {
                                        dataset.borderColor.push(JsUtils.ColorWithOpacity(this.$avStyle.colors.red, 90));
                                        if(pindex === (dataset.data.length - 1)) dataset.backgroundColor = JsUtils.ColorWithOpacity(this.$avStyle.colors.red, 40);
                                    }
                                }
                            }
                        }
                    } else if (lowIsBadThreshold || lowIsGoodThreshold) {

                        let datasetsToColorizeCount = (dataCollection.chartData.datasets.length - (lowIsGoodThreshold ? 1 : 0) - (lowIsBadThreshold ? 1 : 0));
                        for(const [index,dataset] of dataCollection.chartData.datasets.entries()) {
                            if (Array.isUseful(dataset.data) && index < datasetsToColorizeCount) {
                                dataset.borderColor = [];
                                dataset.backgroundColor = [];
                                dataset.pointBackgroundColor = [];
                                dataset.pointBorderColor = [];
                                for (let [pindex, point] of dataset.data.entries()) {
                                    let color = this.$avStyle.colors.red;
                                    let value = point;
                                    if(Object.isUseful(value)) {
                                    if(!Object.isUseful(value.x))
                                        value = { x: dataset.categories[pindex], y: point };
                                    if(this.getPointColorByThreshold(value, lowIsBadThreshold, lowIsGoodThreshold))
                                        color = this.$avStyle.colors.green;
                                    }

                                    dataset.borderColor.push(this.isBarChart() ? color : JsUtils.ColorWithOpacity(colors[index], 30));
                                    dataset.pointBackgroundColor.push(this.isBarChart() ? color : JsUtils.ColorWithOpacity(color, 90));
                                    dataset.pointBorderColor.push(this.isBarChart() ? color : JsUtils.ColorWithOpacity(color, 90));
                                    if(this.isBarChart() && dataset.type !== "line")
                                        dataset.backgroundColor.push(color);
                                    else {
                                        dataset.backgroundColor = JsUtils.ColorWithOpacity(JsUtils.NamedColorToHex("White"), 0);
                                        //dataset.showLine = false;
                                    }
                                }
                            }
                        }
                    } else {
                        dataCollection.chartData.datasets.forEach(function (dataset, index) {
                            dataset.borderColor = JsUtils.ColorWithOpacity(dataset.color !== "auto" ? dataset.color: colors[index], 100);
                            if(self.linesColoredArea && !(self.isBarChart() && dataset.type === "line"))
                                dataset.backgroundColor = JsUtils.ColorWithOpacity(dataset.color !== "auto" ? dataset.color: colors[index], self.isBarChart() ? 100 : 40);
                            else
                                dataset.backgroundColor = JsUtils.ColorWithOpacity(dataset.color !== "auto" ? dataset.color: colors[index], 0);
                        });
                    }
                }
                else {
                    if(this.chartType === "Pie") {
                        dataCollection.chartData.datasets.forEach(function (dataset, index) {
                            // let colors =  ColorSequence.getColors(dataset.data.length);
                           dataset.backgroundColor = dataset.colors;
                        });
                    } else {
                        dataCollection.chartData.datasets.forEach(function (dataset, index) {
                            dataset.backgroundColor = ColorSequence.getColors(dataset.data.length);
                        });
                    }
                }
            },

            getPointColorByThreshold(point, lowIsBadThreshold, lowIsGoodThreshold) {

                let lowIsBadThr = NaN;
                let lowIsGoodThr = NaN;

                if(lowIsBadThreshold) {
                    //If thr is single point just compare point with the unique value
                    if(lowIsBadThreshold.data.length === 1)
                        lowIsBadThr = lowIsBadThreshold.data[0].y;
                    //Otherwise search for a matching abscissa to compare values.
                    //TODO evaluate approximate time abscissa matching based on intervals
                    else {
                        for(let thr of lowIsBadThreshold.data)
                            if(thr.x === point.x) {
                                lowIsBadThr = thr.y;
                                break;
                            }
                    }
                }
                if(lowIsGoodThreshold) {
                    //If thr is single point just compare point with the unique value
                    if(lowIsGoodThreshold.data.length === 1)
                        lowIsGoodThr = lowIsGoodThreshold.data[0].y;
                    //Otherwise search for a matching abscissa to compare values.
                    //TODO evaluate approximate time abscissa matching based on intervals
                    else {
                        for(let thr of lowIsGoodThreshold.data)
                            if(thr.x === point.x) {
                                lowIsGoodThr = thr.y;
                                break;
                            }
                    }
                }
                lowIsBadThr = Number(lowIsBadThr);
                lowIsGoodThr = Number(lowIsGoodThr);
                if(isNaN(lowIsBadThr))
                    return (point.y <= lowIsGoodThr);
                else if(isNaN(lowIsGoodThr))
                    return (point.y >= lowIsBadThr);
                else {
                    if(lowIsGoodThr > lowIsBadThr)
                        return (point.y <= lowIsGoodThr && point.y >= lowIsBadThr);
                    else return (point.y >= lowIsBadThr || point.y <= lowIsGoodThr);
                }
            },

            getTrendValue(trend, x) {
                if(x < trend.start)
                    return 0;
                return (trend.slope * (x - trend.start));
            },

            isBarChart() {
                return (this.chartType === 'Bars' || this.chartType === "Horizontal bars");
            },
            dataSetColor(dataSet) {
                return Object.isNestedPropertyUseful(dataSet, "visualizationOptions", "color") ? dataSet.visualizationOptions.color : "auto"
            }
        }
    }

</script>

<style scoped>


</style>
