<template>
    <div style="width: auto">
        <v-layout :row="horizontal" :column="!horizontal" justify-end v-if="includeInLayout">
            <vue-excel-xlsx v-if="enableExport"
                    :data="dataCollection"
                    :columns="headers"
                    :fileName="fileName"
                    :sheetName="workSheet"
            >
                <v-btn :flat="flatButtons" >
                    <av-icon color="blue" style="margin-right: 10px" :small="smallIcons">fas fa-file-excel</av-icon>
                    <av-icon color="blue" style="margin-right: 10px" :small="smallIcons">fas fa-arrow-right</av-icon>{{textIconVisible?$gettext('Export excel'):''}}</v-btn>
            </vue-excel-xlsx>
            <div class="text-xs-left" v-if="enableImport">
                <UploadButton accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ref="button" :flat="flatButtons" noTitleUpdate @file-update="importExcel" class="btn btn-default" color="#f5f5f5" :title="textIconVisible?$gettext('Import excel'):''">
                    <template slot="icon-left">
                        <av-icon color="blue" style="margin-right: 10px" :small="smallIcons">fas fa-file-excel</av-icon>
                        <av-icon color="blue" style="margin-right: 10px" :small="smallIcons">fas fa-arrow-left</av-icon>
                    </template>
                </UploadButton>
            </div>
        </v-layout>
        <template v-else-if="menuBar">
            <v-menu offset-y transition="slide-y-transition" left id="toolbar-user" :style="smallIcons?'padding-top: 5px':''" >
                <v-btn slot="activator" flat icon :small="smallIcons">
                    <av-icon id="toolbar-dropdown" :small="smallIcons" color="blue">fa-file-excel</av-icon>
                </v-btn>
                <v-list id="toolbar-dropdown-menu">
                    <v-list-tile >
                        <v-list-tile-content  id="toolbar-user-dropdown-menu-logout-text">
                            <vue-excel-xlsx
                                    :data="dataCollection"
                                    :columns="headers"
                                    :fileName="fileName"
                                    :sheetName="workSheet"
                            >
                                <v-btn :flat="flatButtons" >
                                    <av-icon color="blue" style="margin-right: 10px" :small="smallIcons">fas fa-file-excel</av-icon>
                                    <av-icon color="blue" style="margin-right: 10px" small>fas fa-arrow-right</av-icon>{{textIconVisible?$gettext('Export excel'):''}}</v-btn>
                            </vue-excel-xlsx>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile>
                        <v-list-tile-content>
                            <div class="text-xs-left">
                            <UploadButton ref="button" :flat="flatButtons" noTitleUpdate @file-update="importExcel" class="btn btn-default pa-0" color="#f5f5f5" :title="textIconVisible?$gettext('Import excel'):''">
                                <template slot="icon-left">
                                    <av-icon color="blue" style="margin-right: 10px" :small="smallIcons">fas fa-file-excel</av-icon>
                                    <av-icon color="blue" style="margin-right: 10px" :small="smallIcons">fas fa-arrow-left</av-icon>
                                </template>
                            </UploadButton>
                            </div>
                        </v-list-tile-content>
                    </v-list-tile>
                </v-list>
            </v-menu>
        </template>
        <template v-else-if="onlyButton">
            <vue-excel-xlsx v-if="enableExport"
                            :data="dataCollection"
                            :columns="headers"
                            :fileName="fileName"
                            :sheetName="workSheet"
            >
                <v-list-tile>
                    <v-list-tile-action>
                        <av-icon>fas fa-file-excel</av-icon>
                    </v-list-tile-action>
                    <v-list-tile-title class="subheading" v-translate>Export excel</v-list-tile-title>
                </v-list-tile>
            </vue-excel-xlsx>
        </template>
        <template  v-else>
            <vue-excel-xlsx v-if="enableExport"
                    :data="dataCollection"
                    :columns="headers"
                    :fileName="fileName"
                    :sheetName="workSheet" style="float: left;"
            >
                <v-btn :flat="flatButtons" >
                    <av-icon color="blue" style="margin-right: 3px" :small="smallIcons">fas fa-file-excel</av-icon>
                    <av-icon color="blue" style="margin-right: 3px" small>fas fa-arrow-right</av-icon>{{textIconVisible?$gettext('Export excel'):''}}</v-btn>
            </vue-excel-xlsx>
            <div class="text-xs-left" v-if="enableImport">
                <UploadButton ref="button" :flat="flatButtons" noTitleUpdate @file-update="importExcel" class="btn btn-default" color="#f5f5f5" :title="textIconVisible?$gettext('Import excel'):''">
                    <template slot="icon-left">
                        <av-icon color="blue" style="margin-right: 3px" :small="smallIcons">fas fa-file-excel</av-icon>
                        <av-icon color="blue" style="margin-right: 3px" :small="smallIcons">fas fa-arrow-left</av-icon>
                    </template>
                </UploadButton>
            </div>
        </template>
    </div>

</template>

<script>
    import UploadButton from 'vuetify-upload-button';
    import Excel from '@/api/excel'

    export default {
        name: "ImportExportExcel",
        components: {UploadButton,},
        data () {
            return {
                importedHeaders: null,
                importedRecords: null,
                importedData: [],

            }
        },
        props: {
            dataCollection: {
                default: null
            },
            headers: {
                default: null
            },
            workSheet: {
                default: null
            },
            fileName: {
                default: null
            },
            horizontal: {
                default: true
            },
            textIconVisible: {
                default: true
            },
            flatButtons:{
                default:false
            },
            smallIcons:{
                default:false
            },
            includeInLayout:{
                default:true
            },
            menuBar:{
                default:false
            },
            enableExport:{
                default: true
            },
            enableImport:{
                default: true
            },
            onlyButton:{
                default: false
            },
        },
        methods: {
            importExcel(file) {
                let self = this;
                if (!file)
                    return;
                Excel.readExcel(file)
                    .then(result => {
                        if(result.records) {
                            self.importedData = result;
                            this.save();
                        }
                        else {
                            //TODO
                        }
                    })
                    .catch(ex => {
                        debugger;
                    })
                    .finally(() => {
                        self.$refs.button.clear();
                    })
            },
            save() {
                try {
                    this.$emit('fileLoaded', this.importedData);
                } catch (e) {
                    debugger
                    console.error(e);
                    this.$root.showErrorNotification(this.$gettext("Unable to save file, please try again"), true);
                }
            },
        }
    }

</script>

<style scoped>

</style>