<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer"  class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <av-toolbar @update="onToolbarUpdate" :activation-on="toolbarActivationOn" v-if="!exludeToolbar">
            <template slot="itemsLeft">
                <slot name="toolbarFixedItems"></slot>
            </template>
            <template slot="itemsRight" v-if="toolbarActivationOn">
                <slot name="toolbarDynamicItems"></slot>
            </template>
        </av-toolbar>
        <v-card  class="mt-3 av-card" elevation="0" :style="{height: 'calc(100% - ' + (toolbarButton.height +16)+'px)'}" ref="pageContent">
            <v-card-text  class="av-card-content" style="overflow-y: hidden" >
                <slot name="pageContent"></slot>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import AvToolbar from "@/components/av-components/av-toolbar";
export default {
    name: "av-page",
    components: {AvToolbar},
    props: {
        toolbarActivationOn: {
            required: false,
            default: () => {
                return false
            }
        },
        exludeToolbar: {
            required: false,
            default: () => {
                return false
            }
        }
    },
    data:()=>{
        return {
            toolbarButton:{
                show:false,
                height:80
            }
        }
    },
    methods:{
        onToolbarUpdate(evt){
            this.toolbarButton=evt
            this.$emit('onToolbarUpdate',evt)
        }
    }
}
</script>

<style scoped>

</style>