<template>
    <v-text-field v-if="!postLabel" :for="computedID" v-bind="$attrs" :error-message="computedMessage" v-on="$listeners"
                  :label="labelText" :hint="translatedHint" :prefix="prefixText" :id="computedID" :type="type">
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    </v-text-field>
    <v-layout row v-else>
        <v-text-field :for="computedID" v-bind="$attrs" :error-message="computedMessage" v-on="$listeners"
                      :label="labelText" :hint="translatedHint" :prefix="prefixText" :id="computedID">
            <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
        </v-text-field>
        <label class="ml-2 mt-2 subheading ">{{postLabel}}</label>
    </v-layout>
</template>

<script>
export default {
    name: "av-text-field",
    props: {
        translate: { required: false, default: true },
        dynamicID: { required: false, default: '' },
        postLabel: { required: false, default: '' },
        type: { required: false, default: '' }
    },
    data () {
        return {}
    },
    computed: {
        computedID () {
            if (!this.$isEmpty(this.$attrs.id)) {
                return this.$attrs.id
            }
            let dynamicText = this.$attrs.label;
            if(!dynamicText)
                dynamicText = this.$attrs.placeholder;
            if(!dynamicText)
                dynamicText = this.$attrs.prefix;
            if(dynamicText)
                dynamicText = dynamicText.trim();
            else dynamicText = "";
            let dynamicPageName = this.$router && this.$router.history.current.params.routeId ? this.$router.history.current.params.routeId : "";
            return this.toCamelCase((this.$router?this.$router.history.current.name:'') + dynamicPageName + this.dynamicID + 'Input' + dynamicText)
        },
        translatedHint () {
            if (!this.translate) {
                return this.$attrs.hint
            }
            return this.$attrs.hint
        },
        prefixText () {
            if (!this.translate) {
                return this.$attrs.prefix
            }
            return this.$attrs.prefix
        },
        labelText () {
            if (!this.translate) {
                return this.$attrs.label
            }
            return this.$attrs.label
        },
        computedMessage () {
            if (!this.$isEmpty(this.$attrs['error-message'])) {
                if (this.translate) {
                    return this.$attrs['error-message']
                } else {
                    return this.$attrs['error-message']
                }
            }
            return ''
        }
    },
    mounted () {

    },
    methods: {}

}
</script>

<style scoped>

</style>
