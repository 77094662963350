import dashboards from "./dashboards";
import dynamicElements from "./dynamicElements";
import VueInstance from "./vueinstance";
import settings from "./settings";
import alarms from "./alarmsApi"
import config from "./config";
import machines from "./aliases"
import OrchestratorAPI from '@/api/orchestrator';
import Microservices from '@/api/microservices';
import RulesEngine from '@/api/rules.js';

export default {

    composeDocumentsBulk(documents, type, sources, targets) {
        let returning = "";
        try {
            let lineSettings = VueInstance.get().$settings.getIdentificationSettings();
            let filteredKeys = ['customerId', 'factoryId', 'lineId'];
            let filteredLineSettings = filteredKeys
                .reduce((obj, key) => ({...obj, [key]: lineSettings[key]}), {});
            filteredLineSettings.timestamp = VueInstance.get().$dateTime.getRfc3339TimeStamp(new Date());
            for (const document of documents) {
                //Build header
                returning += JSON.stringify({
                    index: {
                        _index: "user_" + type,
                        _type: "metrics",
                        _id: document.properties.name
                    }
                }) + "\n";
                // if(document.properties.deployStatus > 0) {
                //     document.deployedVersion = document.version //test
                // }

                document.exportInfo = filteredLineSettings;
                let exported = {
                    descriptor: JSON.stringify(document),
                    name: document.properties.name,
                    version: document.version,
                    internal_name: document.properties.name,
                    modifiedBy: document.properties.modifiedBy,
                    "@timestamp": document.exportInfo.timestamp,
                    grants: {}
                };
                exported.grants.exposableToLevels = document.properties.exposableToLevels;
                exported.grants.visibleToLevels = document.properties.visibleToLevels;
                exported.grants.editableToLevels = document.properties.editableToLevels;
                returning += this.substitutePlaceholders(JSON.stringify(exported), sources, targets);
            }
            return [null,returning];
        } catch (err) {
            return [err, returning];
        }
    },

    composeConfigurationBulk(document, type, name, option, sources, targets) {
        let returning = "";
        if(!document)
            return "";
        //Build header
        returning += JSON.stringify({
            index: {
                _index: "user_configuration",
                _type: "metrics",
                _id: type + "." + name + "." + option
            }
        }) + "\n";
        returning += this.substitutePlaceholders(JSON.stringify({
            DocumentContent: JSON.stringify(document),
            Type: type,
            Name: name,
            Option: option
        }), sources, targets);

        return returning;
    },

    exportSetting(setting, sources, targets) {
        let self = this;

        return new Promise((resolve, reject) => {
            let type = "";
            let name = "NULL";
            let option = "NULL";
            if (setting === 'alarms settings') {
                let alarmsDescriptors = alarms.getAlarmsDescriptors();
                if (!alarmsDescriptors)
                    resolve("");
                let devices = Array.from(alarmsDescriptors.keys());
                if (!Array.isUseful(devices)) {
                    resolve("");
                    return
                }
                let returning = "";
                for (let device of devices) {
                    let descriptor = alarmsDescriptors.get(device);
                    if(descriptor)
                        returning += self.composeConfigurationBulk(descriptor, settings.AlarmsDescriptions, device, option, sources, targets);
                }
                resolve(returning)
            } else if (setting === 'machines settings') {
                let machineSettings = machines.getAliasesDescriptors();
                let configuredDevices = [ ...machineSettings.keys()];
                if (!Array.isUseful(configuredDevices)) {
                    resolve("");
                    return;
                }
                let returning = "";
                for (let device of configuredDevices)
                    returning += self.composeConfigurationBulk(machineSettings.get(device), settings.MachinesSettings, device, option, sources, targets);

                resolve(returning)
            } else {
                switch (setting) {
                    case 'line settings':
                        type = settings.LineSettings;
                        break;
                    case 'identification settings':
                        type = settings.IdentificationSettings;
                        break;
                    case 'workflow settings':
                        type = settings.Workflow;
                        break;
                    case 'line stop causes':
                        type = settings.LineStopActions;
                        name = "MasterL" + config.deployment;
                        break;
                    case 'grants':
                        type = settings.Grants;
                        break;
                    case 'mail settings':
                        type = settings.MailSettings;
                        break;
                    case 'translations':
                        type = settings.Translations;
                        break;
                    case 'workorders workflow':
                        type = "Workflow";
                        name = "Workorder";
                        break;
                    case 'operation modes':
                        type = settings.OperationModesSettings;
                        break;
                }
                settings.load(type)
                    .then(document => {
                        resolve(self.composeConfigurationBulk(document, type, name, option, sources, targets));
                    })
                    .catch(error => {
                        reject(error)
                })
            }
        })
    },

    substitutePlaceholders(document, sources, targets) {
        if(!document)
            return "";
        let returning = "";
        if (Array.isUseful(sources) && Array.isUseful(targets) && sources.length === targets.length)
            returning += document.replaceMulti(sources, targets) + "\n";
        else
            returning += document + "\n";
        return returning;
    },

    exportVisualizations(objectsToExport, sourceStringsToSubstitute, placeholdersToSubstitute, exportOnlyActive) {
        let calls = [];
        let self = this;
        let targets = [];
        if(Array.isUseful(placeholdersToSubstitute))
            for(let placeholder of placeholdersToSubstitute)
                targets.push("[=[.Ex." + placeholder + "]]");

        for(let objectToExport of objectsToExport) {
            if(objectToExport === "dashboards")
                calls.push(new Promise((resolve, reject) => {
                    dashboards.loadAll(false,true, false)
                        .then(dashs => {
                            let returning = "";
                            for (const dashboard of dashs) {
                                //Build header
                                returning += JSON.stringify({
                                    index: {
                                        _index: "user_dashboards",
                                        _type: "metrics",
                                        _id: dashboard.name
                                    }
                                }) + "\n";
                                returning += self.substitutePlaceholders(JSON.stringify(dashboard), sourceStringsToSubstitute, targets);
                            }
                            resolve(returning);
                        }).catch(error => {
                            reject(error)
                    })
                }));

            else if(objectToExport === "widgets" || objectToExport === "reports" || objectToExport === "rules" || objectToExport === 'forms' || objectToExport === 'queries'|| objectToExport === 'recipes')
                calls.push(new Promise(async (resolve, reject) => {
                    let elementsForDeployVersion = ["forms", "recipes"];
                    let getDeployedVersion = elementsForDeployVersion.includes(objectToExport);
                    let runningRules = [];
                    if (objectToExport === "rules") {
                        runningRules = await RulesEngine.getRunningRules();
                    }
                    dynamicElements.LoadItems(objectToExport, true, false, true)
                        .then(documents => {
                            documents.forEach((document, index) => {
                                if (exportOnlyActive) {
                                    if (!VueInstance.get().$dynamicElements.isItemDeployed(document) && objectToExport !== "rules") {
                                        documents.splice(index, 1);
                                    } else if (objectToExport === "rules" && !runningRules.includes(document.properties.name)) {
                                        documents.splice(index, 1);
                                    } else {
                                        if (getDeployedVersion && document.deployedVersion && document.deployedVersion !== document.version) {
                                            let historyIndex = "user_" + objectToExport + "history";
                                            VueInstance.get().$dynamicElements.DescriptorForIndexAndVersion(historyIndex, document.properties.name, document.deployedVersion)
                                                .then(item => {
                                                    if (Array.isUseful(item)) {
                                                        let documentFromHistory = JSON.parse(item[0].descriptor);
                                                        documentFromHistory.deployedVersion = documentFromHistory.version
                                                        documents[index] = documentFromHistory;
                                                    }
                                                })
                                        }
                                    }
                                }
                                //Since we are not exporting items history set deployed version equal to latest
                                //to avoid issues in opening the item since application will look for the specific
                                //version in history in case deployedVersion is not the latest
                                if (document.deployedVersion && document.deployedVersion !== document.version) {
                                    document.deployedVersion = document.version;
                                }

                                //Forms, rules and recipes are imported inactive and will require an explicit activation
                                //on target host. This ensures consistent system state.
                                if(["forms", "rules", "recipes"].includes(objectToExport))
                                    document.properties.deployStatus = 0;
                            });
                            setTimeout(() => {
                                const [error, result] = self.composeDocumentsBulk(documents, objectToExport, sourceStringsToSubstitute, targets);
                                if (error != null) {
                                    reject(error);
                                } else {
                                    resolve(result);
                                }
                            }, 2000)
                        }).catch(error => {
                         resolve("");
                    })
                }));

            else {
                calls.push(new Promise((resolve, reject) => {
                    self.exportSetting(objectToExport, sourceStringsToSubstitute, targets)
                        .then(document => {
                            resolve(document)
                        })
                        .catch(error => {
                            reject(error)
                        })
                }));
            }
        }


        return new Promise((resolve, reject) => {
            Promise.all(calls)
                .then(t => {
                    resolve(t.join(""));
                })
                .catch(t => {
                    debugger
                    console.error(t);
                    reject(VueInstance.get().$gettext("Data exportation error."));
                });
        })
    },

    async importBulkConfiguration(bulkFile) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.elasticTerraformUrl + "/bulk", bulkFile)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}