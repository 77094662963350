import ShiftChangeDialog from "@/components/dialogs/ShiftChangeDialog";
import Vue from 'vue';
import VueInstance from "@/api/vueinstance"
import Settings from "@/api/settings"

export default {

    getShiftsInfo(shifts, startTime) {

        if(!shifts) {
            let settings = Settings.getLineSettings();
            if(!settings)
                return null;
            shifts = settings.shiftHours || [];
        }
        if(!Array.isUseful(shifts))
            return null;

        let currentShifts = [];
        if(!startTime)
            startTime = new Date();
        let now = Date.parseTimeString(startTime.format("HH:mm:ss"));


        let tmpStart = 0;
        let tmpEnd = 0;
        for(let i = 0 ; i < shifts.length ; i++){
            tmpStart = Date.parseTimeString(shifts[i].from);
            tmpEnd = Date.parseTimeString(shifts[i].to);
            let found = false;
            if(tmpEnd > tmpStart) {
                if (tmpStart <= now && tmpEnd >= now) {
                    found = true;
                }
            } else {
                if (tmpStart <= now || now <= tmpEnd) {
                    found = true;
                }
            }
            if(found)
                currentShifts.push({
                    previousFrom: "",
                    previousTo: "",
                    from: shifts[i].from,
                    to: shifts[i].to,
                    nextFrom: "",
                    nextTo: "",
                    progress: 0,
                    index: i,
                    id: ""
                });
        }

        if(!Array.isUseful(currentShifts))
            return [];

        for(let shift of currentShifts) {

            let start = Date.parseTimeString(shift.from);
            let end = Date.parseTimeString(shift.to);
            let duration = 0;
            let completed = 0;
            if (end > start) {
                duration = end - start;
                completed = now - start;
            } else {
                duration = 24 * 3600 * 1000 - start + end;
                if (now < start)
                    completed = 24 * 3600 * 1000 - start + now;
                else completed = now - start;
            }
            shift.progress = (completed / duration) * 100;
            let previousIndex = shift.index > 0 ? (shift.index - 1) : (shifts.length - 1);
            let nextIndex = shift.index < (shifts.length - 1) ? (shift.index + 1) : 0;
            shift.previousFrom = shifts[previousIndex].from;
            shift.previousTo = shifts[previousIndex].to;
            shift.nextFrom = shifts[nextIndex].from;
            shift.nextTo = shifts[nextIndex].to;
            shift.id = "{0} - {1}".format(shift.from, shift.to);
        }

        return currentShifts;
    },

    getCurrentShift() {
        let self = this;
        let settings = Settings.getLineSettings();
        let shiftHours = settings.shiftHours || [];
        let shiftRoles = settings.shiftRoles || [];
        let returning = null;

        //Load currently opened shift descriptor
        return new Promise((resolve, reject) => {
            Settings.load(Settings.ShiftChange, Settings.Current)
                .then(currentShift => {
                    if (!currentShift)
                        throw("");  //No open shift, case is managed in catch and finally
                    //We have a shift running
                    let found = false;
                    //Verify weather open shift is a preconfigured one by looking in shift settings
                    //If not (could happen only across a shifts settings change period) add current shift
                    //to possible shifts register
                    for (const [index, shift] of shiftHours.entries())
                        if (shift.from === currentShift.from && shift.to === currentShift.to) {
                            currentShift["index"] = index;
                            found = true;
                            break;
                        }
                    if (!found) {
                        shiftHours.push({from: currentShift.from, to: currentShift.to});
                        currentShift["index"] = shiftHours.length - 1;
                    }

                    //Get current shift info
                    let currentStandardShifts = self.getShiftsInfo(shiftHours);
                    for (let shift of currentStandardShifts) {
                        if (shift.id === currentShift.id) {
                            returning = shift;
                            break;
                        }
                    }

                    if (!returning) {  //shift not found. This shouldn't happen, anyway...
                        self.shift = self.shifts[currentShift.index].value;
                        self.shift.progress = -1;
                        self.shift.nextFrom = self.shift.nextTo = self.shift.previousFrom = self.shift.previousTo = "";
                    }

                    if(Array.isUseful(currentShift.variables) && currentShift.variables[0].key === "scheduled") {
                        returning.scheduled = true;
                        currentShift.variables.removeAt(0);
                    }
                    returning.variables = currentShift.variables;
                    returning.valid = true;
                    resolve(returning);
                })
                .catch(() => {
                    debugger
                    let shifts = self.getShiftsInfo(shiftHours);
                    if (Array.isUseful(shifts)) {
                        returning = shifts[0];
                        for (const role of shiftRoles)
                            returning.variables = [];
                    }
                    if(returning)
                        returning.valid = false;
                    resolve(returning);
                });
        });
    },

    getCurrentShiftInfo() {
        return new Promise((resolve, reject) => {
            Settings.load(Settings.ShiftChange, Settings.Current)
                .then(currentShift => {
                    resolve(currentShift);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    openShiftChangeDialog(caller, user, callback) {

        let componentClass = Vue.extend(ShiftChangeDialog);
        let changeShiftDialog = new componentClass();
        changeShiftDialog.setVueInstance(VueInstance.get());
        changeShiftDialog.$mount();
        changeShiftDialog.setCaller(caller);
        changeShiftDialog.Show(user, callback);
    },

}