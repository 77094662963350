<template>
    <v-dialog v-model="showDialog" width="960px" height="480px" min-height="480px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <v-card id="antares-annotation-dialog-card">
            <v-toolbar card dense>
                <v-toolbar-title class="display-1 text-xs-center" id="antares-annotation-dialog-card-title" v-translate>Event root cause</v-toolbar-title>
            </v-toolbar>
            <v-layout column pa-3>
                <v-spacer></v-spacer>
                <label class="headline"><translate>Event:</translate> {{this.eventDescription}}</label>
                <label class="headline"><translate>Source device:</translate> {{this.sourceDevice}}</label>
                <v-spacer />
                <v-layout row justify-center>
                    <v-flex xs6 mr-2>
                        <v-select solo :label="causeGroup" max-width="500" :placeholder="$i18n.CommonAttributes()['select']" class="title" :items="annotationCauses" item-text="show" item-value="id" v-model="annotationCause" @change="onChange"/>
                    </v-flex>
                    <v-spacer />
                    <v-flex xs6 ml-2>
                        <v-select solo :label="cause" :placeholder="$i18n.CommonAttributes()['select']" class="title" :items="annotationSubCauses" item-text="show" item-value="id" v-model="annotationSubCause" @change="onChange" />
                    </v-flex>
                </v-layout>
                <v-spacer></v-spacer>
                <label class="title font-weight-regular" v-translate>DESCRIPTION:</label>
                <v-textarea clearable solo no-resize single-line counter maxlength="200" :placeholder="$i18n.CommonAttributes()['type here']" class="title osk" v-model="annotationText"></v-textarea>
                <label v-if="error" style="color: var(--av-red); font-size: 1.4em">{{error}}</label>
                <v-card-actions id="antares-annotation-dialog-card-actions">
                    <v-spacer></v-spacer>
                    <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="callBack(true)">
                        OK
                    </v-btn>
                    <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat" @click="callBack(false)">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>

    import alarmsApi from '@/api/alarmsApi'

export default {
    name: 'MessageBox',
    props: {
    },
    data () {
        return {
            eventId: "",
            eventName: "",
            eventDescription: "",
            sourceDevice: "",
            showDialog: false,
            annotationCauses: [],
            annotationCause: null,
            annotationSubCause: null,
            annotationText: "",
            user: "",
            error: "",
            callerInstance: null,
        }
    },
    computed : {
        annotationSubCauses() {
            if (this.annotationCause != null) {
                let index = this.annotationCause;
                return this.annotationCauses[this.annotationCause].children;
            }
        },
        causeGroup() { return this.$gettext("Cause group") },
        cause() { return this.$gettext("Cause") }
    },
    methods: {
        setCaller(caller) {
            this.callerInstance = caller;
        },
        onChange(item) {
        },
        Show(event, user) {
            this.user = user;

            if(!event)
                return;

            this.eventName = event.name;
            this.eventDescription = event.description;
            this.sourceDevice = event.device;
            this.eventId = event.id;
            if(event.annotationObject) {
                this.annotationCause = event.annotationObject.cause;
                this.annotationSubCause = event.annotationObject.subcause;
                this.annotationText = event.annotationObject.description;
            }

            this.showDialog = true;

        },
        async ShowNotified(event, user){
            this.user = user;

            if(!event)
                return;

            //event = event[0];

            if(!event.fields["Active"])
                return;

            this.sourceDevice = event.tag["Device"];
            let descriptor = await alarmsApi.getDescriptorById(event.fields["VariableName"], this.sourceDevice);
            if(!descriptor || (!descriptor.requiresAnnotation)) {
                return;
            }

            this.eventName = event.fields["VariableName"];
            if(this.eventName.startsWith("Alarm_")) {
                this.eventName = this.eventName.replace("Alarm_", "");
            }
            this.eventDescription = descriptor.description;
            this.sourceDevice = event.tag["Device"];
            this.eventId = event.refId;
            console.log('EventAnnotation');
            console.log('description:' + this.eventDescription);
            this.showDialog = true;

            if(this.callerInstance)
                this.callerInstance.annotationDialogOpen = true;
        },
        callBack(result) {
            this.error = "";
            if (this.annotationCause === 2 && !this.annotationText) {
                this.error = this.$gettext("Please add a comment for this event");
                return;
            }

            let self = this;

            if (result) {
                alarmsApi.annotateAlarm(this.eventId, this.user, this.annotationCause, this.annotationSubCause, this.annotationText)
                    .then(result => {
                        self.showDialog = false;
                        if(self.callerInstance)
                            self.callerInstance.annotationDialogOpen = false;
                    })
                    .catch(error => {
                        console.log(error);
                        self.error = self.$gettext("An error occurred during event annotation, please try again");
                    })
            } else{
                this.showDialog = false;
                if(this.callerInstance)
                    this.callerInstance.annotationDialogOpen = false;
            }
        },
    }
}

</script>