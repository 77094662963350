  import Vue from 'vue'

// actions example: [{ label: 'Reply', color: 'yellow', l4authority: () => { do something },{ label: 'Dismiss', color: 'white', l4authority: () => { do something } }]
export default () => {
  Vue.prototype.sendNotificationRequest = function (severity, message) {
    Vue.prototype.OrchestratorAPI.notifyEvent('OnShowNotificationRequest', { severity: severity, request: message }).then(t => {
      if (t.data.Status !== 'success') {
        this.$notifyError(t.data.ErrorMessage, [{ label: 'Close', color: 'white', handler: () => { } }])
      }
    }).catch(t => {
      this.$notifyError({ Message: 'Error occurred: {0}', Parameters: [t] }, [{ label: 'Close', color: 'white', handler: () => { } }])
    })
  }

  Vue.prototype.$sendErrorNotification = function (message) {
    Vue.prototype.sendNotificationRequest('error', message)
  }

  Vue.prototype.$sendInfoNotification = function (message) {
    Vue.prototype.sendNotificationRequest('info', message)
  }

  Vue.prototype.$sendConfirmNotification = function (message, parameters) {
    Vue.prototype.sendNotificationRequest('confirm', message)
  }

  Vue.prototype.$notifyError = function (message, actions) {
    let parsedError = this.$parseError(message)
    let translatedMessage = this.$getTranslatedMessage(parsedError)
    let timeout = 6000
    if (!this.$isEmpty(message.persist) && message.persist) {
      timeout = 0
    }
    this.$q.notify({
      color: 'orange',
      textColor: 'white',
      icon: 'warning',
      progress: true,
      message: translatedMessage,
      position: 'top-right',
      multiLine: true,
      actions: actions,
      timeout: timeout,
      html: true
    })
  }
  Vue.prototype.$notifyInfo = function (message, actions) {
    let parsedError = this.$parseError(message)
    this.$q.notify({
      color: 'blue',
      textColor: 'white',
      progress: true,
      icon: 'info',
      message: this.$getTranslatedMessage(parsedError),
      position: 'top-right',
      multiLine: true,
      actions: actions,
      timeout: 6000,
      html: true
    })
  }

  Vue.prototype.$notifyConfirm = function (message, actions) {
    let parsedError = this.$parseError(message)
    this.$q.notify({
      color: 'green',
      textColor: 'white',
      progress: true,
      icon: 'info',
      message: this.$getTranslatedMessage(parsedError),
      position: 'top-right',
      multiLine: true,
      actions: actions,
      timeout: 6000,
      html: true
    })
  }
}
