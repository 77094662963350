<template>
    <DynamicElementBase>
        <template slot="titleButtons" v-if="!keyValuesView">
            <v-tooltip bottom>
                <v-btn fab small slot="activator" @click="selectionHeaders=!selectionHeaders"  >
                    <v-badge v-model="columnsIsFiltered" color="info">
                        <template v-slot:badge>
                            <span>{{filteredColumns.length}}</span>
                        </template>
                        <av-icon>fas fa-filter</av-icon>
                    </v-badge>
                </v-btn>
                <span>{{$gettext('Filter columns')}}</span>
            </v-tooltip>

            <v-dialog persistent v-model="selectionHeaders" width="400">
                <v-card width="400">
                    <v-card-text>
                        <av-multi-select  :placeholder="$i18n.CommonAttributes()['select']"
                                          item-text="text" :label="$gettext('Select column to show')"
                                          :items="headers" v-model="headersSelected" :manage-everyone="true" :return-object="true"></av-multi-select>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="info" text flat @click="selectionHeaders=!selectionHeaders">OK</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>

        </template>
        <template v-if="!keyValuesView">

            <!--TODO XScrollBar should be made visible without scrolling to bottom-->
            <v-data-table :headers="filteredColumns" :items="values"
                          class="elevation-1 my-data-table fixed-header v-table__overflow" :total-items='50'
                          hide-actions style="overflow: hidden;">
                <template slot="headerCell" slot-scope="props" >
                    <v-layout row>
                        {{ props.header.text }}
                        <v-icon :style="styleSorter(props.header)" class="ml-2"
                                @click="sortRows(props.header.value,props.header.sorted==='up'?'down':'up')"
                                v-if="props.header.isSortable">
                                {{ props.header.sorted==='down'?'fas fa-arrow-down':'fas fa-arrow-up'}}
                        </v-icon>
                    </v-layout>
                </template>
                <!--<template v-slot:headers="props">-->
                <!--<tr style="border-bottom: none;">-->
                <!--<th class="text-xs-left subheading" v-for="header in props.headers">-->
                <!--{{ header.text }}-->
                <!--</th>-->
                <!--</tr>-->
                <!--</template>-->
                <template slot="items" slot-scope="values">
                    <td :style="'border-bottom: none; background-color: {0}'.format(values.item['rowevidence'] === 'error' ? 'rgba(255,0,0,0.5)' : '')" class="text-xs-left body-2" v-for="header in filteredColumns">
                        <template v-if="header.value==='globalparams'">
                            <v-btn @click="redirectDashboard(values.item,header.value)" fab small color="blue" dark>
                                <v-icon small>fa-share-square</v-icon>
                            </v-btn>
                            {{values.item[header.value]?values.item[header.value][Object.keys(values.item[header.value])[0]]:''}}

                        </template>
                        <template v-else-if="header.value==='rowevidence'">
                            <av-icon v-if="values.item[header.value] === 'error'" color="red">fas
                                fa-exclamation-circle
                            </av-icon>
                            <av-icon v-if="values.item[header.value] === 'warning'" color="warning">fas
                                fa-exclamation-circle
                            </av-icon>
                            <av-icon v-if="values.item[header.value] === 'info'" color="info">fas fa-info-circle
                            </av-icon>
                        </template>
                        <template v-else>
                            <v-btn v-if="haveGlobalFilter(values.item,header.value)"
                                   @click="redirectDashboard(values.item,header.value,true)" fab small color="blue"
                                   dark>
                                <v-icon small>fa-share-square</v-icon>
                            </v-btn>
                            {{ values.item[header.value] }}
                                <!--  header.value ==='key' && values.item[header.value].split('.').length > 0?values.item[header.value].split('.')[0] : values.item[header.value]   -->
                        </template>

                    </td>
                </template>
            </v-data-table>
        </template>
        <template v-else>
            <div v-if="!labelView" :style="'overflow: auto; height: {0}px'.format(containerHeight)">
                <v-layout column ma-3 pb-0>
                    <template v-for="value in values">
                        <label v-if="!labelView" style="margin-bottom: 10px" class="title font-weight-regular">{{value.key}}:
                            <span class="font-weight-medium">{{value.value}}</span></label>
                    </template>
                </v-layout>
            </div>
            <template v-else v-for="value in values">
                <div v-resize-text="{ratio: 0.3, delay: 200, minFontSize: 0, maxFontSize: containerHeight/1.5}"
                     style="width: 100%; height: 100%; white-space: nowrap;" class="font-weight-bold pa-0 ma-0 pl-1 text-xs-center">
                    {{value.value}}
                </div>
            </template>
        </template>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
    import ResizeText from '@/components/av-autosize-text/index.js'
    import DateTimeUtils from '@/api/datetimeutils'
    import DataApi from '@/api/data'
    import Vue from "vue";

    export default {
        name: "WidgetDataTable",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase
        },
        directives: {
            ResizeText
        },
        data() {
            return {
                dataCollection: null,
                keyValuesView: false,
                labelView: false,
                headers: [],
                values: [],
                options: {},
                dashboards: [],
                dashboardName: "",
                globalFilteringDashboards: {
                    all: "",
                },
                propToSort:"",
                propSorted:"",
                selectionHeaders:false,
                headersSelected:[]

            }
        },
        computed: {
            haveGlobalFilter() {
                let self = this;
                return (item, value) => {
                    return value === "key" &&
                        ((self.globalFilteringDashboards[item.key] &&
                            self.globalFilteringDashboards[item.key] !== "") ||
                            self.globalFilteringDashboards.all !== "");

                }

            },
            styleSorter(){
                return header=>{
                    return{
                        cursor:"pointer",
                        "font-size": "16px",
                        color:"#000000",
                        opacity:header.sorted==='none'?'0.5':'1',
                    }
                }
            },
            filteredColumns(){
                return (this.headersSelected.length>0 && this.headersSelected[0].key!=='all') || this.headersSelected.length===0 ? this.headersSelected:this.headers
            },
            columnsIsFiltered(){
                return this.headersSelected.length>0 && this.headersSelected[0].key!=='all' && this.headersSelected.length!==this.headers.length
            }
        },
        mounted() {
            this.visualizationTweaks = [
                {
                    name: this.$gettext("Show as key value pairs"),
                    id: "keyValuesView",
                    type: "bool",
                    default: function () {
                        return false;
                    },
                },
                {
                    name: this.$gettext("Hide automatic key column"),
                    id: "hideKey",
                    type: "bool",
                    default: function () {
                        return false;
                    },
                },
                {
                    name: this.$gettext("Show as Label"),
                    id: "showAsLabel",
                    type: "bool",
                    default: function () {
                        return false;
                    },
                },
                {
                    name: this.$gettext("Aggregate data by row"),
                    id: "aggregateDataByRow",
                    type: "bool",
                    default: function () {
                        return false;
                    },
                },
                {
                    name: this.$gettext("Automatic key column name"),
                    id: "keyName",
                    type: "string",
                    default: function () {
                        return "";
                    },
                },
                {
                    name: this.$gettext("Queries grouping mode"),
                    id: "grouping",
                    type: "indexed_option",
                    options: [this.$gettext("No grouping"), this.$gettext("Inner join"), this.$gettext("Outer join")],
                    default: function () {
                        return 0;
                    },
                },
                {
                    name: this.$gettext("Dashboard link (with paremetric filters)"),
                    id: "dashboards",
                    type: "option",
                    options: [],
                    default: function () {
                        return "";
                    },
                },
                {
                    name: this.$gettext("Dashboard link (with parametric variables)"),
                    id: "globalkey_dashboard",
                    type: "option",
                    options: [],
                    hidden: true,
                    default: function () {
                        return "";
                    },
                },
                {
                    name: this.$gettext("Choose target dashboard for each row"),
                    id: "globalkey_dashboards_enabled",
                    type: "bool",
                    hidden: true,
                    default: function () {
                        return "";
                    },
                },
                {
                    name: this.$gettext(""),
                    id: "globalkey_dashboards",
                    type: "options-group",
                    value: {},
                    options: [],
                    hidden: true,
                    default: function () {
                        return "";
                    },
                },

            ];

            this.loadDashboard();
            this.visualizationTargets = [
                {show: "Default", id: "default", default: true, expectsSingleValue: false },
                {show: "Parametric filter value", id: "globalparams", expectsSingleValue: false },
                {show: "Row status evidence", id: "rowevidence", expectsSingleValue: false },
            ];
            this.maxDataSetSize = 500; //Table becomes useless if too long
            this.setPreferredAggregations();
            this.clearFilterByColumns();
        },
        watch: {
            visualizationTweaks: {

                handler: function () {
                    let self = this;
                    this.keyValuesView = this.getTweakValue("keyValuesView");
                    this.labelView = this.getTweakValue("showAsLabel");

                    if (this.keyValuesView) {
                            this.visualizationTargets.forEach(target => target.expectsSingleValue = true)
                    }
                    else {
                        this.visualizationTargets.forEach(target => target.expectsSingleValue = false)
                    }

                    DataApi.dataItemsValidator(this.dataItems, this.visualizationTargets)
                    this.$emit('dataItemsUpdatedInWidget');

                    if (this.getTweakValue("dashboards"))
                        this.properties.dashboardToRedirect = this.getTweakValue("dashboards");

                    this.getTweak("hideKey").hidden = this.keyValuesView;
                    if (this.getTweakValue("globalkey_dashboard")) {
                        this.globalFilteringDashboards.all = this.getTweakValue("globalkey_dashboard");
                    }

                    if (this.getTweakValue("aggregateDataByRow") !== this.properties.aggregateDataByRow)
                        this.properties.aggregateDataByRow = this.getTweakValue("aggregateDataByRow");

                    this.getTweak("globalkey_dashboard").hidden = !this.getTweakValue("aggregateDataByRow");
                    this.getTweak("globalkey_dashboards_enabled").hidden = !this.getTweakValue("aggregateDataByRow");

                    this.getTweak("globalkey_dashboards").hidden = !this.getTweakValue("globalkey_dashboards_enabled");

                    for(let di of this.dataItems)
                        for(let re of di.representations)
                            if(Array.isUseful(re.recursions))
                                for(let recursion of re.recursions) {
                                    if (self.getTweak("globalkey_dashboards").value === "") {
                                        self.getTweak("globalkey_dashboards").value = {};
                                    }
                                    //initialize attribute of recursion root if not present in value
                                    if (!self.getTweak("globalkey_dashboards").value[recursion.root] || !this.getTweakValue("globalkey_dashboards_enabled")) {
                                        self.getTweak("globalkey_dashboards").value[recursion.root] = "";
                                    }
                                }

                    this.getTweak("dashboards").hidden = this.keyValuesView;
                    this.getTweak("showAsLabel").hidden = !this.keyValuesView;

                    let grouping = this.getTweakValue("grouping");
                    if (grouping === 0)
                        this.queriesGrouping = null;
                    else if (grouping === 1)
                        this.queriesGrouping = this.$defines.allAggregations.inner.id;
                    else if (grouping === 2)
                        this.queriesGrouping = this.$defines.allAggregations.outer.id;

                    let gkValue = this.getTweakValue("globalkey_dashboards");
                    if (gkValue) {
                        for (let idx in gkValue) {
                            self.globalFilteringDashboards[idx] = gkValue[idx]
                        }
                    }

                    this.setPreferredAggregations();
                    this.saveTweaks();
                    this.recalculateQuery();
                    this.dataRefresh();

                },
                deep: true,
            },
            currentTimeWindow: {
                handler: function () {
                        let currentTimeWindow = {
                            time: {},
                            filters: {},
                            variables: {}
                        };
                        currentTimeWindow.time = this.currentTimeWindow;
                    //FN used to propagate the widget's currentTimeWindowd embedded into the "Widget Synoptic"
                    if (this.properties.useTimeWindowDefinedInWidget) {
                        this.$emit('globalParamUpdated', currentTimeWindow);
                    }
                },
                deep: true,
            },
        },
        methods: {
            setPreferredAggregations() {
                if(this.getTweakValue("keyValuesView"))
                    this.preferredAggregations = [ this.$defines.allAggregations.last.id ];
                else this.preferredAggregations = [ this.$defines.allAggregations.raw.id ];
            },
            refreshData(dataValues) {
                this.headers.clear();
                this.values.clear();

                if (this.properties.aggregateDataByRow) {
                    dataValues = this.elaborateAggregateDataByRow(this.$utils.detach(dataValues))
                }

                if (!this.keyValuesView)
                    this.showAsTable(dataValues);
                else
                    this.showAsKeyValuePairs(dataValues);

                if (this.propToSort!=="" && this.propSorted!==""){
                    this.sortRows(this.propToSort,this.propSorted)
                }
            },
            elaborateAggregateDataByRow(dataValues) {
                let newDataValues = [];
                let self = this;
                let categoriesIdentifiers = [];
                let tmpLength = 0;
                //DS old logic can be deleted
                dataValues.forEach(dataSet => {
                    //FN used to add arrays in ascendig order .
                    let category = dataSet.identifier.split(".");
                    if(category.length >= tmpLength) {
                        categoriesIdentifiers.push(category.slice(0, category.length - 1));
                    } else {
                        categoriesIdentifiers.unshift(category.slice(0, category.length - 1));
                    }
                    tmpLength = tmpLength === 0 ? category.length-1 : tmpLength < category.length-1 ? tmpLength : category.length -1;
                });
                dataValues.forEach(dataSet => {
                    dataSet.representation = "terms";
                    dataSet.isCategorical = true;
                    dataSet.dataFormat = "categories";
                    self.calcData(dataSet, categoriesIdentifiers, tmpLength);
                    let dataSetToUnify = dataValues.filter(d => {
                        return d.label === dataSet.label && d.id !== dataSet.id &&
                            newDataValues.filter(nd => {
                                return nd.label === d.label
                            }).length === 0
                    });
                    if (dataSetToUnify.length > 0) {
                        dataSetToUnify.forEach(otherDataset => {
                            self.calcData(otherDataset, categoriesIdentifiers, tmpLength);
                            dataSet.data.push(...otherDataset.data)
                        });
                        newDataValues.push(dataSet)
                    } else if (newDataValues.filter(nd => {
                        return nd.label === dataSet.label
                    }).length === 0) {
                        newDataValues.push(dataSet)
                    }
                });
                return newDataValues
            },
            calcData(dataSet, categoriesIdentifiers = [], tmpLength = 0) {
                //DS Old Logic can be deleted
                let categories = dataSet.identifier.split(".");
                let category = [];
                for(let identifier of categoriesIdentifiers.filter(item => item.length < categories.length)) {
                    if(identifier.every(token => categories.includes(token))) {
                        if(identifier.length > tmpLength) {
                            identifier = identifier.slice(0, tmpLength);
                        }
                        category = identifier;
                        break;
                    }
                }
                if(category.length === 0)
                    category = categories.slice(0, categories.length - 1);
                if (Array.isUseful(dataSet.data)) {
                    dataSet.data = [dataSet.data.last()];
                    dataSet.data[0].x = category.join('.') //dataSet.identifier
                } else {
                    dataSet.data = [{x: category.join('.'), y: undefined}]
                }
                // let categories = dataSet.identifier.split(".")
                // categories = categories.slice(0, categories.length - 1)
                // console.log(categories)
                // if (Array.isUseful(dataSet.data)) {
                //     dataSet.data = [dataSet.data.last()]
                //     dataSet.data[0].x = categories.join('.')
                // } else {
                //     dataSet.data = [{x: categories.join('.'), y: undefined}]
                // }
            },
            showAsTable(dataValues) {
                let data = this.$datalayer.showAsTable(dataValues, this.getTweakValue("keyName"), this.getTweakValue("hideKey"));
                this.headers = data.headers;
                this.values = data.values;
                for(let error of data.errors)
                    this.setError(error);
            },
            showAsKeyValuePairs(dataValues) {
                //Adjust mobile height on content
                this.fixedHeight = 60 * dataValues.length;
                //Unwrap data and place each value at correct place
                dataValues.forEach(dataSet => {
                    if (dataSet) {
                        if (dataSet.data && dataSet.data.length > 0)
                            this.values.push({
                                key: dataSet.label,
                                value: DateTimeUtils.convertDateTime(dataSet.data.last().y),
                            });
                        else
                            this.values.push({key: dataSet.label, value: ""});
                    }
                });
            },
            loadDashboard() {
                let self = this;
                self.$dynamicElements.listAll("dashboards", false)
                    .then(result => {
                        self.getTweak("dashboards").options = result.active;
                        self.getTweak("globalkey_dashboard").options = [{
                            text: this.$gettext('Local dashboard'),
                            value: "_local_"
                        }, ...result.active];
                        self.getTweak("globalkey_dashboards").options = [{
                            text: this.$gettext('Local dashboard'),
                            value: "_local_"
                        }, ...result.active];
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            redirectDashboard(item = null, value = null, isGlobalKey = false) {
                let objToSend = {
                        time: {},
                        filters: {},
                        variables: {}
                    }

                let dashboard = this.properties.dashboardToRedirect
                if (!isGlobalKey && this.properties.dashboardToRedirect) {
                    if (this.getTweakValue("aggregateDataByRow")) {
                        let obj = this.$utils.detach(item[value])
                        let key = Object.keys(obj)[0]
                        let val = obj[key]
                        let keys = key.split(".")
                        keys[0] = item.key
                        obj[keys.join(".")] = val
                        delete obj[key]
                        objToSend.filters = obj
                    } else {
                        objToSend.filters = item[value]
                    }
                } else if (isGlobalKey) {
                    dashboard = this.globalFilteringDashboards[item[value]] || this.globalFilteringDashboards.all;
                    objToSend.time = this.properties.timeWindow;
                    objToSend.filters = this.currentGlobalFilters;
                    objToSend.variables = item[value]
                }

                if (Object.isUseful(dashboard.value) && dashboard.value === '_local_') {
                    this.$emit('globalParamUpdated', objToSend);
                    return
                }
                this.$root.openDashboard(dashboard, objToSend,!this.editMode);
            },
            sortRows(propToSort,sorted){
                this.propToSort = propToSort;
                this.propSorted = sorted;
                let descending = this.propSorted === "down";
                this.values.sortOnProperty(this.propToSort,descending);
                let headers= (this.headersSelected.length >0 && this.headersSelected[0].key !=='all')  || this.headersSelected.length===0 ? this.headersSelected : this.headers;
                headers.forEach(header=>{
                    if(header.value === propToSort){
                        header.sorted = descending?"down":"up";
                    }else{
                        header.sorted = "none";
                    }
                });
            },
            clearFilterByColumns(){
                this.headersSelected = [{
                    key:"all",
                    text:"Everyone"
                }];
                if(this.selectionHeaders) {
                    this.selectionHeaders = false;
                }
            },
        }
    }

</script>

<style scoped>

    .v-treeview-node__label {
        flex-grow: 0;
    }

    table.v-table tbody td, table.v-table tbody th {
        height: 25px;
    }

    table.v-table tbody tr:not(:last-child) {
        border-bottom: none;
    }

    .my-data-table {
        display: flex;
        flex-direction: column;
    }

    .my-data-table .v-datatable.v-table {
        order: -1;
    }

</style>
