/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import Vue from 'vue'
import createApp from './app.js'




//import Capacitor from 'boot/capacitor'

/*import qboot_Booti18n from 'boot/i18n'
*/
import Auth from '@/av-utilities/src/boot/api/auth.js'

import VueCrypt from '@/av-utilities/src/boot/utility/vuecrypt.js'

import Orchestrator from '@/av-utilities/src/boot/api/orchestrator.js'

import WebUi from '@/av-utilities/src/boot/api/webui.js'

import Lenguages from '@/av-utilities/src/boot/api/languages.js'

import ItemTypeDefinition from '@/av-utilities/src/boot/api/itemtypedefinition.js'

import UrlAddress from '@/av-utilities/src/boot/utility/urladdress.js'

import Axios from '@/av-utilities/src/boot/utility/axios.js'

import Confirmation from '@/av-utilities/src/boot/utility/confirmation.js'

import Errors from '@/av-utilities/src/boot/utility/errors.js'

import Sleep from '@/av-utilities/src/boot/utility/sleep.js'

import Index from '@/av-utilities/src/boot/utility/index.js'

import Notificaion from '@/av-utilities/src/boot/utility/notification.js'

import WebSocket from '@/av-utilities/src/boot/utility/websocket.js'

import Store from '@/av-utilities/src/boot/store.js'

import CheckAuth from '@/av-utilities/src/boot/utility/checkauth.js'

import CallBack from '@/av-utilities/src/boot/utility/kioskcallbacks.js'

import PageHandler from '@/av-utilities/src/boot/utility/pagehandler.js'

import Base from '@/av-utilities/src/boot/utility/base.js'

//import qboot_Antaresvisionquasarappextensioncommonutilitiessrcbootutilityfontawesomeprojs from '@/av-utilities/src/boot/utility/fontawesome-pro.js'

import CallingApi from '@/av-utilities/src/boot/utility/callingapi.js'

import Menu from '@/av-utilities/src/boot/utility/menu.js'

//import Register from '@/av-utilities/src/boot/register.js'



import { addPreFetchHooks } from './client-prefetch.js'



import vueInstanceExport from '@/api/vueinstance'



Vue.config.devtools = true
Vue.config.productionTip = false



console.info('[Vuetify] Running SPA.')

async function start (data,methods,computed) {
  const {app, store, router} = await createApp(data, methods,computed)

  let routeUnchanged = true
  const redirect = url => {
    routeUnchanged = false
    window.location.href = url
  }

  const urlPath = window.location.href.replace(window.location.origin, '')
  const bootFiles = [//qboot_Booti18n,
    //Capacitor,
    Auth,
    Orchestrator,
    WebUi,
    Lenguages,
    ItemTypeDefinition,
    UrlAddress,
    Axios,
    Confirmation,
    Errors,
    Sleep,
    Index,
    Notificaion,
    VueCrypt,
    WebSocket,
    Store,
    CheckAuth,
    CallBack,
    PageHandler,
    Base,
    //qboot_Antaresvisionquasarappextensioncommonutilitiessrcbootutilityfontawesomeprojs,
    CallingApi,
    Menu,
    //Register
    ]

  for (let i = 0; routeUnchanged === true && i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null,
        redirect,
        urlPath
      })
    } catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }

      console.error('App boot error:', err)
      return
    }
  }

  if (routeUnchanged === false) {
    return
  }


  addPreFetchHooks(router, store)


  let VueInstance=new Vue(app);

  vueInstanceExport.set(VueInstance);

}

export default{
  Init(obj){
    start(obj.data,obj.methods,obj.computed);
  }
}

