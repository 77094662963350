import Vue from 'vue'

import DownloadJs from 'downloadjs'
Vue.component('Download', DownloadJs);

import MessageBox from '@/components/utilities/MessageBox'
Vue.component('av-message-box', MessageBox);

import InputBox from '@/components/utilities/InputBox'
Vue.component('av-input-box', InputBox);

import Loader from '@/components/utilities/AvLoader'
Vue.component('av-loader', Loader);

import AvSplitPane from '@/components/av-split-pane/AvSplitPane.vue';
Vue.component('av-split-pane', AvSplitPane);

import AvIcon from '@/components/utilities/AvIcon.vue';
Vue.component('av-icon', AvIcon);

import AvAutocomplete from '@/components/utilities/Autocomplete.vue';
Vue.component('av-autocomplete', AvAutocomplete);

import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed);

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

import DatetimePicker from 'vuetify-datetime-picker'
import 'vuetify-datetime-picker/src/stylus/main.styl'
Vue.use(DatetimePicker);

import VueDraggableResizable   from 'vue-draggable-resizable'
Vue.component('vue-draggable-resizable', VueDraggableResizable);

import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );
Vue.component(CKEditor.component);

import annotationView from '@/components/dialogs/EventAnnotationDialog'

import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

import FileUpload from 'vue-upload-component'
Vue.component('FileUpload', FileUpload);

import VueFontAwesomePicker from "vfa-picker";
Vue.use(VueFontAwesomePicker);

import '@/assets/css/CustomDraggableResizable.css'

import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';

import VueHtmlToPaper from 'vue-html-to-paper';
Vue.use(VueHtmlToPaper, {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: []
});

import AvSpreadsheet from '@/components/utilities/AvSpreadsheet.vue';
Vue.component("av-spreadsheet", AvSpreadsheet);

import AvNotificationsQueue from '@/components/utilities/AvNotificationsQueue.vue';
Vue.component("av-notifications-queue", AvNotificationsQueue);

import AvQueuedSnackbar from '@/components/utilities/AvQueuedSnackbar.vue';
Vue.component("av-queued-snackbar", AvQueuedSnackbar);

import AVDrawLine from '@/components/utilities/AvDrawLine.vue'
Vue.component("av-draw-line",AVDrawLine);

import AvButton from '@/components/av-components/av-button.vue';
Vue.component("av-button", AvButton);

import AvStackedIcon from '@/components/utilities/AvStackedIcon.vue';
Vue.component("av-stacked-icon", AvStackedIcon);

import AvCheckbox from '@/components/av-components/av-checkbox';
Vue.component("av-checkbox", AvCheckbox);

import AvTextField from '@/components/av-components/av-text-field';
Vue.component("av-text-field", AvTextField);

import AvSwitch from '@/components/av-components/av-switch';
Vue.component("av-switch", AvSwitch);

import AvSelect from '@/components/av-components/av-select.vue';
Vue.component("av-select", AvSelect);


import AvMultiSelect from '@/components/av-components/av-multiselect.vue'
Vue.component("av-multi-select", AvMultiSelect);

import AvExpansionPanel from '@/components/av-components/av-expansion-panel.vue';
Vue.component("av-expansion-panel", AvExpansionPanel);

import AvTextArea from '@/components/av-components/av-text-area.vue';
Vue.component("av-textarea", AvTextArea);

Vue.prototype.toCamelCase = function (stringToReplace) {
    return stringToReplace.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    })
};

Vue.prototype.$isEmpty = function (value) {
    return (
        // null or undefined
        (value === null) ||

        (value === undefined) ||

        // has length and it's zero
        (value.hasOwnProperty('length') && value.length === 0) ||

        // is an Object and has no keys
        (value.constructor === Object && Object.keys(value).length === 0)
    )
};
