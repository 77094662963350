<template>
    <v-container :style="'min-width: 100%; height: ' + dashHeight + '; margin: 0; padding:0;'" fill-height id="elementContainer">
        <template v-if="descriptor.state === states.drawable">
            <v-hover>
                <template v-slot:default="{ hover }">
                    <div ref="container" :style="'width: 100%; height:100%; min-height: 100%; max-height: 100%; padding: 2px; border-color: white; margin: -0px; overflow: hidden; zoom: ' + zoom + '%'">
                        <v-fade-transition v-if="(empty || hover) && (descriptor.state === states.drawable) && !play">
                            <div absolute :class="empty ? 'overlayCoverTransparent' : 'overlayCover'">
                                <v-layout row class="overlayBar">
                                    <av-button icon @click="splitH" buttonIcon="fas fa-grip-lines" color="grey lighten-1" iconColor="red"
                                               :toolTip="$gettext('Split vertically')" />
                                    <av-button icon @click="splitV" buttonIcon="fas fa-grip-lines-vertical" color="grey lighten-1" iconColor="red"
                                               :toolTip="$gettext('Split horizontally')" />
                                    <av-button icon @click="addWidget" buttonIcon="fas fa-play" color="grey lighten-1" iconColor="red"
                                               :toolTip="$gettext('Add widget here')" />
                                    <av-button icon @click="deleteWidget" buttonIcon="fas fa-trash" color="grey lighten-1" iconColor="red"
                                               :toolTip="$gettext('Remove widget from here')" />
                                    <av-button v-if="descriptor.id!=='0' && descriptor.id.substr(descriptor.id.length -1) === '0'" icon
                                               @click="merge" buttonIcon="fas fa-undo" color="grey lighten-1" iconColor="red"
                                               :toolTip="$gettext('Merge back split cells')" />
                                    <av-button v-if="descriptor.id!=='0' && descriptor.id.substr(descriptor.id.length -1) === '1'" icon
                                               @click="merge" buttonIcon="fas fa-redo" color="grey lighten-1" iconColor="red"
                                               :toolTip="$gettext('Merge back split cells')" />
                                </v-layout>
                            </div>
                        </v-fade-transition>
                    </div>
                </template>
            </v-hover>
        </template>
        <template v-else style="width: 100%; min-height: 100%; max-height: 100%; margin: 0; padding:0;">
            <av-split-pane id="split" v-on:resize="resize" :min-percent='10' :default-percent="descriptor.splitRatio" :split="descriptor.state===states.splitVertical ? 'vertical' : 'horizontal'" :fixed="play" style="width: 100%; height: 100%; margin: 0; padding:0;">
                <template slot="paneL">
                    <DashBoardElement style="width: 100%; height: 100%" :visualizations="dVisualizations" v-if="descriptor.children.zero" :splitScreenView="splitScreenView" :descriptor="descriptor.children.zero" :globalParams="globalParams" :play="play" :zoom="zoom" v-on:changed="changed" v-on:globalParamUpdated="globalParamUpdated($event)" v-on:OnFullScreenWidget="onFullScreenWidget($event)"  v-on:OnSplitScreenWidget="onSplitScreenWidget($event)"  v-on:OnChangeViewMode="onChangeViewMode($event)" @visualizationsLoaded="onVisualizationLoaded($event)" />
                </template>
                <template slot="paneR">
                    <DashBoardElement style="width: 100%; height: 100%" v-if="descriptor.children.one" :visualizations="dVisualizations" :splitScreenView="splitScreenView" :descriptor="descriptor.children.one" :globalParams="globalParams" :play="play" :zoom="zoom" v-on:changed="changed" v-on:globalParamUpdated="globalParamUpdated($event)"  v-on:OnFullScreenWidget="onFullScreenWidget($event)" v-on:OnSplitScreenWidget="onSplitScreenWidget($event)" v-on:OnChangeViewMode="onChangeViewMode($event)" @visualizationsLoaded="onVisualizationLoaded($event)"/>
                </template>
            </av-split-pane>
        </template>


        <template v-if="selectTilePopup">
            <v-dialog v-model="selectTilePopup" width="1000" :persistent="true">
                <v-card class="av-card-bordless ma-0 pa-0" style="height: 800px">
                    <v-card-text  class="av-card-content ma-0 pa-0" style="height: calc(100% - 60px); overflow-y: hidden">
                        <v-tabs dark style="height: calc(100%)">
                            <v-tab key="user" ripple>
                                User visualizations
                            </v-tab>
                            <v-tab key="system" ripple>
                                System visualizations
                            </v-tab>
                            <v-tab-item key="user">
                                <v-card class="av-card-bordless ma-0 pa-0" style="height: calc(100%)">
                                    <v-toolbar card dense>
                                        <v-toolbar-title v-translate>Select widget to be displayed</v-toolbar-title>
                                    </v-toolbar>
                                    <v-container>
                                        <v-layout row>
                                            <av-text-field class="ml-3" label="Filter by name" v-model="filterByName"></av-text-field>
                                            <av-select class="ml-3 control" label="Filter by type" v-model="typeSelected" :items="typesWithNames" :item-text="'name'"></av-select>
                                            <av-select class="ml-3 control" :label="$gettext('Filter by tag')" v-model="tagSelected" :items="tags"></av-select>
                                        </v-layout>
                                    </v-container>
                                    <v-divider></v-divider>
                                    <v-card-text  class="av-card-content ma-0 pa-0" style="height: calc(100% - 160px); overflow-y: auto">
                                        <v-layout v-for="visualization in filteredVisualizations" align-center justify-start row ma-2 v-bind:key="visualization.id">
                                            <template v-if="$dynamicElements.isItemDeployed(visualization)">
                                                <v-hover>
                                                    <v-card slot-scope="{ hover }" style="width: 270px; height: 160px; padding: 5px; vertical-align: middle" :id="'openItem_' + visualization.properties.name"
                                                            :class="`elevation-${hover ? 20 : 5}`" @click="loadWidget(visualization), selectTilePopup=false">
                                                        <v-layout column justify-center fill-height align-center style="margin-top: 4px">
                                                            <img style="max-width: 250px; max-height: 150px" :src="visualization.thumbnail"/>
                                                        </v-layout>

                                                    </v-card>
                                                </v-hover>
                                                <v-layout justify-center align-start column fill-height style="overflow-y: auto;" class="ml-3">
                                                    <template v-if="visualization.properties">
                                                        <div class="title" style="text-align: start; margin-bottom: 5px;">Name:
                                                            {{visualization.properties.name}}
                                                        </div>
                                                        <div class="subheading" style="text-align: start; margin-top: 5px;">Title:
                                                            {{visualization.properties.title}}
                                                        </div>
                                                    </template>
                                                </v-layout>
                                                <v-spacer/>

                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{on}">
                                                        <v-btn icon class="mb-0" @click="loadWidget(visualization), selectTilePopup=false" v-on="on">
                                                            <av-icon color="primary">fas fa-arrow-alt-circle-right</av-icon>
                                                        </v-btn>
                                                    </template>
                                                    <label v-translate>Select</label>
                                                </v-tooltip>
                                            </template>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item key="system">
                                <v-card class="av-card-bordless ma-0 pa-0" style="height: calc(100%)">
                                    <v-toolbar card dense>
                                        <v-toolbar-title v-translate>Select widget to show</v-toolbar-title>
                                    </v-toolbar>
                                    <v-container>
                                        <v-layout row>
                                            <av-text-field class="ml-3" label="Filter by name" v-model="filterByNameSystem"></av-text-field>
                                            <av-select class="ml-3 control" label="Filter by type" v-model="typeSystemSelected" :items="typesWithNamesSystem" :item-text="'name'"></av-select>
                                        </v-layout>
                                    </v-container>
                                    <v-divider></v-divider>
                                    <v-card-text class="av-card-content ma-0 pa-0" style="height: calc(100% - 160px); overflow-y: auto">
                                        <v-layout v-for="visualization in filteredSystemWidgets" align-center justify-start row ma-2 v-bind:key="visualization.id">
                                            <v-hover>
                                                <v-card style="width: 50%; min-width: 200px; margin-right: 5px;"
                                                        slot-scope="{ hover }" :class="`elevation-${hover ? 20 : 5}`"
                                                        @click="loadWidget(visualization), selectTilePopup=false">
                                                    <v-layout justify-center align-center fill-height>
                                                        <img style="width: 100%;" :src="visualization.thumbnail"/>
                                                    </v-layout>
                                                </v-card>
                                            </v-hover>
                                            <v-layout justify-center align-start column fill-height style="overflow-y: auto;">
                                                <template v-if="visualization.properties">
                                                    <div class="title" style="text-align: start; margin-bottom: 5px;">Name:
                                                        {{visualization.properties.name}}
                                                    </div>
                                                    <div class="subheading" style="text-align: start; margin-top: 5px;">Title:
                                                        {{visualization.properties.title}}
                                                    </div>
                                                </template>
                                            </v-layout>
                                            <v-spacer/>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{on}">
                                                    <v-btn icon class="mb-0" @click="loadWidget(visualization), selectTilePopup=false" v-on="on">
                                                        <av-icon color="primary">fas fa-arrow-alt-circle-right</av-icon>
                                                    </v-btn>
                                                </template>
                                                <label v-translate>Select</label>
                                            </v-tooltip>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs>
                    <v-divider></v-divider>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" flat="flat" @click="selectTilePopup=false" v-translate>
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </template>
    </v-container>
</template>

<script>

    export default {
        name: "DashBoardElement",
        data() {
            return {
                empty: true,
                currentWidget: null,
                states: {drawable: 0, splitVertical: 1, splitHorizontal: 2, merged: 3},
                selectTilePopup: false,
                dVisualizations: [],
                filterByName: "",
                types: [],
                typesWithNames: [],
                typeSelected: "All",
                filterByNameSystem: "",
                typesSystem: [],
                typesWithNamesSystem: [],
                typeSystemSelected: "All",
                tagSelected: "All",
                tags: [],
            }
        },
        computed: {
            dashHeight() {
                if(this.currentWidget && this.currentWidget.isMobile)
                    return this.currentWidget.getFixedHeight + 15 + "px";
                if(this.descriptor && this.descriptor.id === "0" && !this.maximized)
                    if(!this.$store.state.isEmbedded)
                        return 'calc(100vh - 80px)';
                    else return 'calc(100vh - 16px)';
                else return 'calc(100vh - 8px)';
            },
            systemWidgets() {
                return this.$dynamicElements.SystemTypes
            },
            filteredVisualizations() {
                let filtered = this.dVisualizations;
                if(this.filterByName){
                    filtered = filtered.filter((visualization)=>{
                        return this.filterByName.toLowerCase().split(' ').every(v => visualization.properties.name.toLowerCase().includes(v))
                    })
                }
                if (this.typeSelected !== 'All') {
                    let type = this.typesWithNames.filter(item => item.name === this.typeSelected);
                    filtered = filtered.filter(item => item.properties.type === type[0].type)
                }
                if (this.tagSelected !== 'All')
                    //TODO remove item.properties.tags !== undefined statement when all visualizations have default tag property
                    filtered = filtered.filter(item => item.properties.tags !== undefined && item.properties.tags.includes(this.tagSelected))
                return filtered;
            },
            filteredSystemWidgets() {
                let filtered = this.systemWidgets;
                if(this.filterByNameSystem){
                    filtered = filtered.filter((visualization)=>{
                        return this.filterByNameSystem.toLowerCase().split(' ').every(v => visualization.properties.name.toLowerCase().includes(v))
                    })
                }
                if (this.typeSystemSelected !== 'All') {
                    let type = this.typesWithNamesSystem.filter(item => item.name === this.typeSystemSelected);
                    filtered = filtered.filter(item => item.properties.type === type[0].type)
                }
                return filtered;
            }
        },
        props: {
            descriptor: {
                type: Object,
                required: true
            },
            play: {
                type: Boolean,
                default: false
            },
            maximized: {
                type: Boolean,
                default: false
            },
            zoom: {
                type: Number,
                default: 100
            },
            globalParams: {
                type: Object,
                required: false
            },
            doNotEdit: {
                type: Boolean,
                default: false
            },
            splitScreenView: {
                type: Boolean,
                default: false
            },
            visualizations:{
                required:false,
                default: ()=> []
            }
        },
        watch: {
            descriptor: {
                handler: function () {
                    this.load();
                },
                deep: true,
            },
            globalParams: {
                handler: function () {

                    if(this.currentWidget) {
                        let useTimeWindowDefinedInWidget = false;
                        if(Object.isUseful(this.currentWidget.properties))
                        {
                            useTimeWindowDefinedInWidget = this.currentWidget.properties.useTimeWindowDefinedInWidget;
                        }
                        if(!useTimeWindowDefinedInWidget) {
                            this.currentWidget.SetGlobalParams(this.globalParams);
                        } else {
                            this.currentWidget.SetGlobalParams(this.globalParams, true);
                        }

                    }
                },
                deep: true,
            }
        },
        mounted:
            function () {
                this.load();
                if (Array.isUseful(this.visualizations)){
                    this.dVisualizations=this.visualizations
                }
            },
        beforeDestroy() {
            if(this.currentWidget) {
                this.currentWidget.$destroy();
                this.$refs.container.removeChild(this.currentWidget.$el);

                this.currentWidget = null;
            }
        },
        methods: {
            load() {
                let self = this;
                if (Object.isUseful(this.descriptor.children)){
                    if (this.descriptor.children.zero === null && !this.doNotEdit) {
                        this.descriptor.children.zero = {
                            id: this.descriptor.id + "0",
                            state: 0,
                            splitRatio: 50,
                            children: {zero: null, one: null}
                        };
                        this.descriptor.children.one = {
                            id: this.descriptor.id + "1",
                            state: 0,
                            splitRatio: 50,
                            children: {zero: null, one: null}
                        };
                        this.currentWidget = null;
                    }
                }
                if (this.descriptor.state === this.states.drawable && this.descriptor.widget && this.currentWidget === null || (this.descriptor.shapeOptions && this.descriptor.widget)) {
                    let startTime = new Date();
                    this.$dynamicElements.LoadItem(this.descriptor.widget)
                        .then(t => {
                            if(self.$config.debug) {
                                console.log("Widget descriptor {0} loaded in {1}".format(self.descriptor.widget, new Date() - startTime))
                            }
                            //Sometimes user changes page immediately after application loading.
                            //In this case landing dashboard might be still loading while user changes page.
                            //This condition avoids having dead widgets running in background that are loaded after dashboard is destroyed
                            if (this._isBeingDestroyed || this._isDestroyed) {
                                console.log("Widget {0} loading aborted due to dashboard early destruction".format(self.descriptor.widget));
                                return;
                            }
                            self.loadWidget(JSON.parse(t.descriptor), self.descriptor.shapeOptions);
                        })
                        .catch(t => {
                            console.log(t);
                            self.$root.showErrorNotification(self.$gettext("Error in retrieving widget {0} info from DB.").format(self.descriptor.widget), true);
                        });
                }
                this.$dynamicElements.SystemTypes.forEach(item => {
                    self.typesSystem.push(item.properties.type);
                });
                this.typesWithNamesSystem.clear();
                Object.keys(this.$dynamicElements.Types).forEach(e => this.typesWithNamesSystem.push({name: e, type: this.$dynamicElements.Types[e]}));
                this.typesWithNamesSystem = this.typesWithNamesSystem.filter(item => this.typesSystem.includes(item.type));
                this.typesWithNamesSystem.unshift({name: 'All'})
            },
            splitV() {
                this.descriptor.state = this.states.splitVertical;
                this.propagateChange();
            },
            splitH() {
                this.descriptor.state = this.states.splitHorizontal;
                this.propagateChange();
            },
            merge() {
                this.descriptor.state = this.states.merged;
                this.propagateChange();
            },
            async addWidget() {
                if (this.visualizations.length === 0 && this.dVisualizations.length === 0) {
                    await this.loadVisualizations()
                    if (Array.isUseful(this.dVisualizations)) {
                        this.selectTilePopup = true;
                        this.$emit("visualizationsLoaded", this.dVisualizations)
                    }
                    return
                }
                if (this.dVisualizations.length !== this.visualizations.length) {
                    this.dVisualizations = this.visualizations
                }
                this.selectTilePopup = true;
            },
            loadWidget(descriptor, externalOptions = null) {
                let currentView = descriptor.properties.type;
                this.$nextTick(() => {

                    let startTime = new Date();

                    //Create widget
                    this.currentWidget = this.$dynamicElements.createNew(currentView);
                    if(!this.currentWidget)
                        return; //License issue or huge error (unlikely)
                    this.currentWidget.load(descriptor, externalOptions);
                    this.currentWidget.isSplitScreen = false;
                    // set splitScreenOrientation to false if is splited vertical, and true if it is horizontal split
                    if (this.descriptor.parentState === this.states.splitVertical) {
                        this.currentWidget.splitScreenOrientation = false
                    } else {
                        this.currentWidget.splitScreenOrientation = true
                    }
                    if (this.splitScreenView) {
                        this.currentWidget.isSplitScreen = true
                    }
                    this.currentWidget.$on('globalParamUpdated', (param) => {
                        //Used to propagate global filters from WidgetGlobalFilters to a whole dashboard
                        this.globalParamUpdated(param);
                    });
                    this.currentWidget.$on("OnFullScreen", (param)=>{
                            if (param){
                                this.$refs.container.childNodes[0].remove();
                            }else{
                                this.$refs.container.insertBefore(this.currentWidget.$el, this.$refs.container.firstChild);
                            }
                            this.onFullScreenWidget(param?this.currentWidget:undefined);

                    });
                    this.currentWidget.$on("OnSplitScreen", (param)=>{
                        this.currentWidget.isSplitScreen=param;
                        this.onSplitScreenWidget(this.currentWidget);
                    });
                    //Change vertical and horizontal view
                    this.currentWidget.$on("OnChangeViewMode", (param)=>{
                         this.currentWidget.splitScreenOrientation=param;
                        this.onChangeViewMode(param);
                    });

                    this.$refs.container.insertBefore(this.currentWidget.$el, this.$refs.container.firstChild);
                    this.empty = false;
                    if(!this.doNotEdit) {
                        this.descriptor["widget"] = descriptor.properties.name;
                        this.propagateChange();
                    }

                    if(Object.isUseful(this.globalParams)) {
                        this.currentWidget.SetGlobalParams(this.globalParams);
                    }

                    if(this.$config.debug) {
                        console.log("Widget {0} loaded in {1}".format(descriptor.properties.name, new Date() - startTime))
                    }

                });
            },
            deleteWidget() {
                if(this.empty)
                    return;
                this.empty = true;
                this.$refs.container.childNodes[0].remove();
                this.currentWidget = null;
            },
            resize(splitRatio) {
                this.descriptor.splitRatio = splitRatio;
                this.propagateChange();
            },
            changed(descriptor) {
                if(this.doNotEdit)
                    return;
                if (descriptor.state === this.states.merged)
                {
                    this.descriptor.state = this.states.drawable;
                    this.descriptor.children.zero = null;
                    this.descriptor.children.one = null;
                    // } else this.descriptor.children.one = null;
                    // if(descriptor.id === this.descriptor.id + '0') {
                    //     this.descriptor.children.zero = null;
                    // } else this.descriptor.children.one = null;
                }
                else if(descriptor.id === this.descriptor.id + '0') {
                    this.descriptor.children.zero = descriptor;
                } else this.descriptor.children.one = descriptor;
                this.propagateChange();
            },
            propagateChange() {
                this.$emit('changed', this.descriptor);
            },
            globalParamUpdated(param) {
                this.$emit('globalParamUpdated', param);
            },
            onFullScreenWidget(param){
                this.$emit("OnFullScreenWidget",param)
            },
            onSplitScreenWidget(param){
                this.$emit("OnSplitScreenWidget",param)
                //Don't change split screen value in widget if widget is in a home page
                if (!this.splitScreenView && this.currentWidget){
                        this.currentWidget.isSplitScreen=false;
                    }
            },
            onChangeViewMode(param){
                this.$emit("OnChangeViewMode",param)
            },
            loadVisualizations(){
                return new Promise((resolve,reject)=>{
                    this.$dynamicElements.LoadItems("widgets", false, true, false)
                        .then(result => {
                            result.forEach(item => {
                                this.types.push(item.properties.type);
                                //TODO remove if statement when all visualizations have default tag property
                                if (item.properties.tags !== undefined) {
                                    item.properties.tags.forEach(item => {
                                        if (item != null && item !== "" && this.tags.indexOf(item) === -1)
                                            this.tags.push(item);
                                    })
                                }
                            });
                            this.dVisualizations = result;
                            Object.keys(this.$dynamicElements.Types).forEach(e => this.typesWithNames.push({name: e, type: this.$dynamicElements.Types[e]}));
                            this.typesWithNames = this.typesWithNames.filter(item => this.types.includes(item.type));
                            this.typesWithNames.unshift({name: 'All'});
                            this.tags.unshift('All');
                        })
                        .catch(error => {
                            debugger
                            console.error(error);
                            this.$root.showErrorNotification(this.$gettext("Error in retrieving saved visualizations from DB."), true);
                            reject(false)
                        })
                        .finally(() => {
                            this.$root.setLoading(false, "");
                            resolve(true)
                        });
                })

            },
            onVisualizationLoaded(visualizations){
                this.dVisualizations=visualizations
                this.$emit('visualizationsLoaded',this.dVisualizations)
            }
        }
    }


</script>

<style>

    .overlayBar {
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 100px);
        height: 40px;
        width: 200px;
        margin: 0;
        padding: 0;
    }

    .v-tabs .v-window{
        height:calc(100% - 40px)!important;
    }
    .v-tabs .v-window .v-window__container{
        height:calc(100%)!important;
    }
    .v-tabs .v-window .v-window__container .v-window-item{
        height:calc(100%)!important;
    }
</style>
