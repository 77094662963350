<template>
    <v-dialog v-model="showDialog" max-width="510" :persistent="true" id="antares-inputbox-dialog">
        <v-card class="pa-3" v-if="showDialog" id="antares-inputbox-dialog-card">
            <v-card-title class="title" style="overflow: hidden" id="antares-inputbox-dialog-card-title">{{this.title }}</v-card-title>
            <v-card-text id="antares-inputbox-dialog-description" v-html="description">
                {{description}}
            </v-card-text>
            <v-select  v-if="Array.isUseful(options)" :items="options"  item-text="show" return-object label="Select " @change="value = $event.key"/>
            <v-text-field class="osk" v-else v-model="value" clearable style="padding: 5px" @change="value = $event" @input="validate($event)"/>
            <v-card-actions id="antares-inputbox-dialog-card-actions">
                <v-spacer></v-spacer>
                <v-btn v-if="this.showYesButton" id="antares-inputbox-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="$emit('close', true), inputBoxCallBack(true)">
                    {{ this.yesLabel  }}
                </v-btn>
                <v-btn v-if="this.showNoButton" color="red darken-1" flat="flat" @click="$emit('close', false), inputBoxCallBack(false)" id="antares-inputbox-dialog-card-actions-no">
                    {{ this.noLabel  }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'InputBox',
        props: {
            validator: null
        },
        data () {
            return {
                title: "",
                description: "",
                showDialog: false,
                value: null,
                previousValue: "",
                yesLabel: "",
                noLabel: "",
                translate: true,
                noButtonCallback: function () {},
                yesButtonCallback: function () {},
                options: [],
            }
        },
        computed : {
            showYesButton(){
                return (this.yesLabel !== "")
            },
            showNoButton(){
                return (this.noLabel !== "")
            }
        },
        methods: {
            ShowInputBox(title, description, value, yesLabel, yesFunction, noLabel, noFunction, options){
                this.title = title;
                this.description = description;
                this.value = value;
                this.yesButtonCallback = yesFunction;
                this.noButtonCallback = noFunction;
                this.yesLabel = yesLabel;
                this.noLabel = noLabel;
                this.showDialog = true;
                this.options = options;
                this.previousValue = "";
            },
            validate() {
                if(!this.validator)
                    return true;
                if(!this.value) {
                    this.previousValue = "";
                    return;
                }
                if(!this.validator(this.value))
                    this.$nextTick(() => {
                        this.value = this.previousValue;
                    });
                else this.previousValue = this.value;
            },
            inputBoxCallBack(result){
                this.showDialog = false;
                if (result) {
                    if (this.yesButtonCallback !== null){
                        this.yesButtonCallback(this.value)
                    }
                } else{
                    if (this.noButtonCallback !== null){
                        this.noButtonCallback();
                    }
                }
            },
        }
    }

</script>