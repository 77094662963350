<template>
    <v-dialog v-model="showDialog" width="860px" height="640px" min-height="590px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <time-tracking :notified="notified" :showStopDetails="isStopCategorization" :user="user" :picker="picker" :alarms="alarms" :stoppedMachines="stoppedMachines" @complete="setDialog($event)" @picked="picked($event)"></time-tracking>
    </v-dialog>
</template>

<script>

import TimeTracking from "@/components/TimeTracking";

export default {
    name: 'MessageBox',
    components: {TimeTracking},
    props: {
    },
    data () {
        return {
            showDialog: false,
            user: "",
            callerInstance: null,
            notified: false,
            pickerCallback: null,
            picker : null,
            alarms: null,
            stoppedMachines: null,
            isStopCategorization: true
        }
    },

    methods: {
        setCaller(caller) {
            this.callerInstance = caller;
        },
        Show(event, user, picker, pickerCallback) {

            if(!this.$license.hasPerformance())
                return;

            if(!event)
                return;

            this.showDialog = true;

            this.user = user;
            this.notified = event["notified"];
            this.alarms = event["alarms"];
            this.stoppedMachines = event["stoppedMachines"];
            if(Object.isUseful(event["onStop"]))
                this.isStopCategorization = event["onStop"];

            this.picker = picker;
            this.pickerCallback = pickerCallback;

            if(this.callerInstance)
                this.callerInstance.annotationDialogOpen = true;

        },
       setDialog(result) {
           this.showDialog = false;
           if(this.callerInstance)
               this.callerInstance.annotationDialogOpen = false;
           if(!result && this.pickerCallback)
               this.pickerCallback(0); // category 0 is not a valid manual time tracker
       },
        picked(value) {
          this.setDialog(true);
          if(this.pickerCallback)
              this.pickerCallback(value);
        },
    }
}

</script>