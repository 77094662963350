<template>
    <v-card class="pa-0 ma-0">
        <v-card-text class="pa-0 ma-0">
            <v-layout column pa-2 >
                <template v-for="(rule, ruleIndex) in rules">
                  <av-text-field :placeholder="$gettext('type here')" class="osk" prefix="Rule name: " :value="rule.name" @change="rule.name=$event" :dynamicID="rule.name" clearable single-line v-if="!isActivity"></av-text-field>
                    <template v-for="(group, groupIndex) in rule.value">
                        <v-layout v-if="groupIndex > 0 && (group === 'AND' || group === 'OR')" ma-2 row justify-center align-center>
                            <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;" />
                          <v-btn fab small color="info" class="ma-1" @click="toggleInterGroupOperator(ruleIndex, groupIndex)">
                                <span :ref="'OPGEN_{0}_{1}'.format(ruleIndex, groupIndex)" class="subheading">{{group}}</span>
                            </v-btn>
                            <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;" />
                        </v-layout>
                        <template v-else v-for="(condition, conditionIndex) in group">
                            <template v-if="!(condition === 'AND' || condition === 'OR')">
                                <v-layout row style="width: 100%">
                                    <td style="width: calc(50% - 90px)"><v-select class="pa-1 mr-2" :background-color="condition.variable.error ? '#ff0000' : ''" :label="condition.variable.error ? 'error' : ''" :placeholder="$i18n.CommonAttributes()['type here']" return-object :items="variables" :value="condition.variable" @change="condition.variable = $event"/></td>
                                    <td style="width: 80px"><v-select class="pa-1 mr-2" :items="condition.variable.comparers" v-model="condition.operator" /></td>
                                    <td style="width: calc(50% - 100px)">
                                        <template v-if="!(['IsEmpty', 'IsNotEmpty', 'exists', 'not_exists', 'VALID'].includes(condition.operator)) && (condition.variable.wellKnown ? condition.variable.wellKnown.id !== $defines.WellKnownItems.Location : true)">
                                            <PopUpTimePicker v-if="(condition.variable.wellKnown && condition.variable.wellKnown.id === $defines.WellKnownItems.MinuteOfDay)" type="minuteOfDay" style="max-width: 40px" class="pa-1 mt-2" v-model="condition.value" />
                                            <PopUpDayOfWeekPicker v-else-if="(condition.variable.wellKnown && condition.variable.wellKnown.id === $defines.WellKnownItems.DayOfWeek)" style="max-width: 40px" class="pa-1 mt-2" v-model="condition.value" />
                                            <PopUpDayOfYearPicker v-else-if="(condition.variable.wellKnown && condition.variable.wellKnown.id === $defines.WellKnownItems.DayOfYear)" type="dayOfYear" style="max-width: 40px" class="pa-1 mt-2" v-model="condition.value" />
                                            <PopUpMonthOfYearPicker v-else-if="(condition.variable.wellKnown && condition.variable.wellKnown.id === $defines.WellKnownItems.Month)" style="max-width: 40px" class="pa-1 mt-2" v-model="condition.value" />
                                            <v-layout v-else-if="condition.operator === 'RAND'" row class="pa-1 mt-1">
                                              <av-text-field v-model="condition.value" @change="randValue($event, ruleIndex, groupIndex, conditionIndex)" :dynamicID="rule.name"
                                                             style="max-width: 50px; margin-top: 0px" type="number"></av-text-field>

                                              <label class="ml-2 mt-1 subheading font-weight-bold">% is TRUE</label>
                                            </v-layout>
                                            <v-combobox v-else class="pa-1 mt-1" :background-color="condition.value.error ? '#ff0000' : ''" :label="condition.value.error ? 'error' : ''" :placeholder="$i18n.CommonAttributes()['type here']" return-object :items="variables" :value="condition.value" @change="condition.value = $event" />
                                        </template>
                                    </td>
                                    <td style="width: 50px">
                                      <av-button v-if="isLastConditionInGroup(ruleIndex, groupIndex, conditionIndex)" :text="$gettext('')" @click="addCondition(ruleIndex, groupIndex)"
                                                 buttonIcon="fa-plus" color="info" iconColor="light" :dynamicID="rule.name" class="ma-1" fab small />

                                        <v-btn v-else fab small color="info" class="ma-1" @click="toggleOperator(ruleIndex, groupIndex, conditionIndex)">
                                            <span :ref="'OPGRP_{0}_{1}_{2}'.format(ruleIndex, groupIndex, conditionIndex)" class="subheading">{{group[conditionIndex + 1]}}</span>
                                        </v-btn>
                                    </td>
                                    <td style="width: 50px">
                                      <av-button @click="deleteCondition(ruleIndex, groupIndex, conditionIndex)" :text="$gettext('')"
                                                 buttonIcon="fa-trash" color="error" iconColor="light" :dynamicID="rule.name" class="ma-1" fab small />
                                    </td>
                                </v-layout>
                                <HereLocationSelector v-if="(condition.variable.wellKnown && condition.variable.wellKnown.id === $defines.WellKnownItems.Location)" class="pa-1 mt-2" v-model="condition.value"/>
                            </template>
                        </template>
                    </template>
                    <v-layout ma-2 row justify-center align-center>
                        <template v-if="variables.length">
                          <av-button @click="addConditionsGroup(ruleIndex)" :text="$gettext('Add conditions group')"
                                     buttonIcon="fa-plus" color="info" iconColor="light" :dynamicID="rule.name" />

                          <av-button @click="deleteRule(ruleIndex)" :text="$gettext('Delete rule')"
                                     buttonIcon="fa-trash" color="error" iconColor="light" :dynamicID="rule.name"/>
                        </template>
                    </v-layout>
                </template>
              <av-button v-if="variables.length && addNewRuleBtn" @click="addRule()" :text="$gettext('Add new rule')"
                         buttonIcon="fa-plus" color="info" iconColor="light"/>
                <label class="subheading text-xs-center" v-else-if="variables.length === 0" v-translate>Select any data to start creating rules</label>

            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>

    import Rules from '@/api/rules'
    import Data from '@/api/data'
    import Vue from 'vue';
    import PopUpTimePicker from '@/components/utilities/PopUpTimePicker'
    import PopUpDayOfWeekPicker from '@/components/utilities/PopUpDayOfWeekPicker'
    import PopUpDayOfYearPicker from '@/components/utilities/PopUpDayOfYearPicker'
    import PopUpMonthOfYearPicker from '@/components/utilities/PopUpMonthOfYearPicker'
    import VuePlaceAutocomplete from 'vue-place-autocomplete';
    import HereLocationSelector from "./HereLocationSelector";

Vue.use(VuePlaceAutocomplete);

    export default {
        name: "ConditionsEditor",
        data () {
            return {}
        },
        components: { HereLocationSelector, PopUpTimePicker, PopUpDayOfWeekPicker, PopUpDayOfYearPicker, PopUpMonthOfYearPicker },
        props: {
            dataItems: {
                type: Array,
                default: null
            },
            rules: {
                type: Array,
                default: () => []
            },
            representationMode: {
                type: Object,
                default: null
            },
            fieldMode: {
                type: Boolean,
                default: false
            },
            isActivity: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            variables() {
                //Pass true as a fourth parameter if it is activity type
                return this.representationMode.ruleType === 'activity' ? Rules.getVariablesDescriptors(this.dataItems, this.fieldMode, undefined, true) : Rules.getVariablesDescriptors(this.dataItems, this.fieldMode);
            },
            addNewRuleBtn() {
                if (this.isActivity && this.rules.length >= 1)
                    return false
                return true
            }
        },
        watch: {
            variables: {
                handler: function () {
                    //This watch is triggered when data definitions change. Traverse the conditions to
                    //find dead links to variables that are not defined anymore
                    let conditionsCrawler = function (conditions, variables) {
                        for (let condition of conditions) {
                            if (Array.isArray(condition))    //Nested condition
                                conditionsCrawler(condition, variables);
                            else if (Object.isUseful(condition.variable)) { //It's a condition
                                let variableFound = false;
                                let valueFound = !Object.isNestedPropertyUseful(condition, "value", "id"); //Check also the expression value in case it's a variable itself
                                for (let variable of variables) { //Check if variable is still included in available variables
                                    if (!variableFound && variable.id === condition.variable.id) {
                                        condition.variable = variable;  //Ensure variable is up to date
                                        variableFound = true;
                                    }
                                    if (!valueFound && variable.id === condition.value.id) {
                                        condition.value = variable;  //Ensure value is up to date
                                        valueFound = true;
                                    }
                                }
                                condition.variable.error = !variableFound;
                                if (Object.isNestedPropertyUseful(condition, "value", "id"))
                                    condition.value.error = !valueFound;
                            }
                        }
                    };
                    if (Array.isUseful(this.rules))
                        for (let rule of this.rules)
                            conditionsCrawler(rule.value, this.variables);
                },
                deep: true
            },
        },
        methods: {
            getDefaultCondition() {
                if(Array.isUseful(this.variables))
                    return {
                        variable: this.variables[0],
                        operator: Data.getDefaultFilterCondition(this.variables[0]).operator,
                        value: Data.getDefaultFilterCondition(this.variables[0]).value || this.variables[0],
                    }
            },
            addConditionsGroup(ruleIndex) {
                this.initRules();
                if(!Array.isUseful(this.dataItems))
                    return;
                if(Array.isUseful(this.rules[ruleIndex].value))
                    this.rules[ruleIndex].value.push("OR");
                this.rules[ruleIndex].value.push([ this.getDefaultCondition()]);
            },
            initRules() {
                if(!Array.isUseful(this.rules)) {
                    this.rules.push({
                        name: "Rule 1",
                        type: "expression",
                        value: []
                    });
                    return true;
                }
                return false;
            },
            addCondition(ruleIndex, groupIndex) {
                this.rules[ruleIndex].value[groupIndex].push("AND");
                this.rules[ruleIndex].value[groupIndex].push(this.getDefaultCondition());
            },
            toggleOperator(ruleIndex, groupIndex, conditionIndex) {
                if(this.rules[ruleIndex].value[groupIndex][conditionIndex + 1] === "AND")
                    this.rules[ruleIndex].value[groupIndex][conditionIndex + 1] = "OR";
                else this.rules[ruleIndex].value[groupIndex][conditionIndex + 1] = "AND";
                this.$refs['OPGRP_{0}_{1}_{2}'.format(ruleIndex, groupIndex, conditionIndex)][0].innerText = this.rules[ruleIndex].value[groupIndex][conditionIndex + 1];
            },
            toggleInterGroupOperator(ruleIndex, groupIndex) {
                if(this.rules[ruleIndex].value[groupIndex] === "AND")
                    this.rules[ruleIndex].value[groupIndex] = "OR";
                else this.rules[ruleIndex].value[groupIndex] = "AND";
                this.$refs['OPGEN_{0}_{1}'.format(ruleIndex, groupIndex)][0].innerText = this.rules[ruleIndex].value[groupIndex];
            },
            deleteCondition(ruleIndex, groupIndex, conditionIndex) {
                //If not last element remove relation with next item
                if(!this.isLastConditionInGroup(ruleIndex, groupIndex, conditionIndex)) {
                    this.rules[ruleIndex].value[groupIndex].removeAt(conditionIndex); //Remove condition
                    this.rules[ruleIndex].value[groupIndex].removeAt(conditionIndex); //Remove relation
                }
                //Otherwise remove relation with previous so that PLUS sign appears at the end of group
                else if(this.rules[ruleIndex].value[groupIndex].length > 1) {
                    this.rules[ruleIndex].value[groupIndex].removeAt(conditionIndex); //Remove condition
                    this.rules[ruleIndex].value[groupIndex].removeAt(conditionIndex - 1); //Remove relation
                }
                else this.rules[ruleIndex].value[groupIndex].removeAt(conditionIndex); //Remove condition only, no relations around
                //Finally check whether the group remained empty, in this case remove it
                //together with relation by applying the same rules as before.
                if(!Array.isUseful(this.rules[ruleIndex].value[groupIndex])) {
                    if(!this.isLastGroup(ruleIndex, groupIndex)) {
                        this.rules[ruleIndex].value.removeAt(groupIndex); //Remove group
                        this.rules[ruleIndex].value.removeAt(groupIndex); //Remove relation
                    }
                    else if(this.rules[ruleIndex].value.length > 1) {
                        this.rules[ruleIndex].value.removeAt(groupIndex); //Remove group
                        this.rules[ruleIndex].value.removeAt(groupIndex - 1); //Remove relation
                    }
                    else this.rules[ruleIndex].value.removeAt(groupIndex); //Remove group only, this means that we cleared all conditions
                }
            },
            isLastConditionInGroup(ruleIndex, groupIndex, conditionIndex) {
                //If it's last element at all it's also last in group
                return (conditionIndex === this.rules[ruleIndex].value[groupIndex].length - 1);
            },
            isLastGroup(ruleIndex, groupIndex) {
                //If it's last element at all it's also last group
                return (groupIndex === this.rules[ruleIndex].value.length - 1);
            },
            deleteRule(ruleIndex) {
                let self = this;
                let deleteRule = function() {
                    self.rules.removeAt(ruleIndex)
                };
                this.$root.showDialogBox(this.$gettext("Are you sure you want to delete rule {0}?").format(this.rules[ruleIndex].name), "", "Yes", deleteRule, "No", null);
            },
            addRule() {
                if(this.initRules())
                    return;
                let id = 0;
                let duplicateFound = true;
                while(duplicateFound) {
                    id++;
                    duplicateFound = false;
                    for (let rule of this.rules) {
                        if (rule.name === "Rule " + id) {
                            duplicateFound = true;
                            break;
                        }
                    }
                }
                this.rules.push({
                    name: "Rule " + id,
                    type: "expression",
                    value: []
                })
            },
            randValue(value, ruleIndex, groupIndex, conditionIndex) {
                debugger
                value = parseFloat(value);
                if(value < 0)
                    this.rules[ruleIndex].value[groupIndex][conditionIndex].value = 0;
                else if(value > 100)
                    this.rules[ruleIndex].value[groupIndex][conditionIndex].value = 100;
                else
                    this.rules[ruleIndex].value[groupIndex][conditionIndex].value = value;
            }
        }
    }
</script>

<style scoped>

    .roundedButton {
        margin: 5px; padding:5px; min-height:40px; max-width: 40px; min-width: 40px
    }

    .roundedButtonIcon {
        position: relative; left: 15px; top: -10px;
    }

</style>
