import cryptoJS from 'crypto-js'

export default async ({ app, router, store, Vue }) => {
  Vue.prototype.$encode = function (data) {
    var ciphertext = encodeURIComponent(cryptoJS.enc.Utf8.stringify(cryptoJS.enc.Utf8.parse(cryptoJS.AES.encrypt(data, 'secret key 123').toString())))
    return ciphertext
  }
  Vue.prototype.$decode = function (ciphertext) {
    var bytes = cryptoJS.AES.decrypt(decodeURIComponent(ciphertext), 'secret key 123')
    var decryptedData = bytes.toString(cryptoJS.enc.Utf8)
    return decryptedData
  }
  Vue.prototype.$decodeQueryParameters = function () {
    if (Vue.prototype.$isEmpty(this.$route.query) || Vue.prototype.$isEmpty(this.$route.query.d)) {
      return {}
    }
    let decoded = Vue.prototype.$decode(this.$route.query.d)
    if (Vue.prototype.$isEmpty(decoded)) {
      return {}
    }
    return JSON.parse(decoded)
  }
  Vue.prototype.$encodeQueryParameters = function (parameters) {
    return Vue.prototype.$encode(JSON.stringify(parameters))
  }
  Vue.prototype.$goToPage = function (pageToLoad, parameters) {
    try {
      if (this === undefined || this.$router === undefined) {
        return
      }
      if (Vue.prototype.$isEmpty(parameters)) {
        this.$router.push({ name: pageToLoad })
        return
      }
      let encoded = Vue.prototype.$encodeQueryParameters(parameters)
      this.$router.push({ name: pageToLoad, query: { d: encoded } })
    }catch(e) {}
  }
  Vue.prototype.$goToPageByServerNameAndAction = function (action, parameters) {
    let page = this.$getPageToOpen(action)
    this.$goToPage(page, parameters)
  }
}
