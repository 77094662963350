import * as d3 from 'd3-scale-chromatic'
import AvPalette from '@/api/avpalette'
import avpalette from "./avpalette";

export default {

    palettes: {
        standard: "standard",
        rainbow: "rainbow"
    },
    avPalette: [
        AvPalette.colors.blue,
        AvPalette.colors.red,
        AvPalette.colors.green,
        AvPalette.colors.orange
    ],

    calculatePoint(colorRangeInfo, i, intervalSize) {
        let { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
        return (useEndAsStart
            ? (colorEnd - (i * intervalSize))
            : (colorStart + (i * intervalSize)));
    },

    /* Must use an interpolated color scale, which has a range of [0, 1] */
    getColors(dataLength, colorScale, colorRangeInfo) {

        if((!colorScale || colorScale === this.palettes.standard) && dataLength <= this.avPalette.length)
            return this.avPalette;
        else colorScale = d3.interpolateTurbo;

        if(!colorRangeInfo) {
            colorRangeInfo = {
                colorStart: 0.1,
                colorEnd: 1,
                useEndAsStart: false,
            };
        }
        let { colorStart, colorEnd } = colorRangeInfo;
        let colorRange = colorEnd - colorStart;
        let intervalSize = colorRange / dataLength;
        let i, colorPoint;
        let colorArray = [];

        for (i = 0; i < dataLength; i++) {
            colorPoint = this.calculatePoint(colorRangeInfo, i, intervalSize);
            colorArray.push(colorScale(colorPoint));
        }

        return colorArray;
    },
}