<template>
    <RuleBase />
</template>

<script>

    import RuleBase from '@/components/dynamic-elements/rules/RuleBase.vue';

    export default {
        name: "RuleReport",
        extends: RuleBase,
        components: {
            RuleBase
        },
        data() {
            return {
                dataCollection: null,
            }
        },
        mounted() {
            this.fixedHeight = false;
            this.dataExplorationMode.requiresDataFiltering = false;
            this.dataExplorationMode.requiresDataExploration = false;
            this.dataExplorationMode.requiresDataSelection = false;
            this.dataExplorationMode.requiresRulesConditions = false;
            this.dataExplorationMode.requiresRulesProcessing = false;
            this.properties.timeWindow.setPredefined("week");
            this.properties.ruleScheduling.cron = "0 17 * * 5"; //Every friday at 17.00

            // For future implementations
            this.visualizationTweaks = [];
        },
        watch: {

            visualizationTweaks: {
                handler: function () {
                    this.saveTweaks();
                },
                deep: true,
            },
        },
        methods: {}
    }

</script>

<style scoped>
    table th { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table td { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table tr { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
</style>