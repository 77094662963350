<template>
  <v-expansion-panel style="margin-bottom: 10px" class="dense-panel" expand>
        <v-expansion-panel-content class="px-2">
            <template v-slot:header>
                <div class="subheading pa-0 ma-0" style="width: 90%;">Recursions ({{variables && variables.length}})
                </div>
            </template>
            <v-layout column class="mb-4">
                <v-btn color="info" class="subheading" @click="showPickerDialog=true">
                    <av-icon left light>fas fa-plus-circle</av-icon>
                    {{$gettext('Choose Variables')}}
                </v-btn>

                <v-btn color="info" class="subheading" @click="applyDatasetNameToRecursions"
                        v-if="recursionVariables.find(r=>{ return r.label !== representationName})">
                    <av-icon left light>fas fa-sync</av-icon>
                    {{$gettext('Apply DatasetName to all recursions')}}
                </v-btn>
            </v-layout>
            <v-layout column>
                <v-expansion-panel v-for="variable in cRecursionVariables"
                                   style="margin-bottom: 10px; border-bottom: 1px solid #b0bec5"
                                   class="elevation-0" expand>
                    <v-expansion-panel-content class="px-2">
                        <template v-slot:header>
                            <div class="subheading pa-0 ma-0" style="width: 90%;">{{variable.root + "." +
                                variable.name}}
                            </div>
                        </template>
                        <v-layout column pl-2>
                            <v-combobox pa-0 validate-on-blur :label="$gettext('Show as')" v-model="variable.label"
                                        :items="aggregationItems" @change="onChangeVariable"/>
                        </v-layout>

                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-layout>
            <VariablePickerDialog :showDialog="showPickerDialog" :search="item.name"
                                  :select-field="false" :multiple="true" :select-root="true"
                                  :search-disabled="true"
                                  @selectedItems="selectedVariables($event)"
                                  :exclude-item="item" @close="showPickerDialog=false"
                                  :variables="variables"/>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import VariablePickerDialog from '@/components/dialogs/VariablePickerDialog'

    export default {
        name: "RecursiveVariableConfigurator",
        components: {VariablePickerDialog},
        props: {
            item: {
                required: true,
                default: () => {
                    return undefined
                }
            },
            variables: {
                required: false,
                default: () => {
                    return []
                }
            },
            availableAggregationItems: {
                required: true,
                default: () => {
                    return []
                }
            },
            representationName:{
                required:false,
                default:()=>{
                    return ""
                }
            }
        },
        data: () => {
            return {
                showPickerDialog: false,
                recursionVariables:[],
            }
        },
        computed: {
            aggregationItems() {
                return this.availableAggregationItems.map(ai => {
                    return ai.show
                })
            },
            cRecursionVariables(){
                return this.recursionVariables
            }
        },
        mounted() {
            this.recursionVariables=JSON.parse(JSON.stringify(this.variables))
        },
        methods: {
            selectedVariables(variables) {
                this.showPickerDialog = false;
                let oldVariables=JSON.parse(JSON.stringify(this.recursionVariables))
                this.recursionVariables = variables

                this.recursionVariables.forEach(v=>{
                    let find=oldVariables.find(or=>{return v.key===or.key})
                    if (find && find.label !== v.label){
                        v.label=find.label
                    }
                })

                this.$emit("onSelectedItems", this.recursionVariables)
            },
            onChangeVariable(){
                this.$emit("onSelectedItems", this.recursionVariables)
            },
            applyDatasetNameToRecursions(){
                let self=this
                this.recursionVariables.filter(r=>{return r.label!== self.representationName}).forEach(v=>{
                    v.label=self.representationName
                })
                this.$emit("onSelectedItems", this.recursionVariables)
            }
        }
    }
</script>

<style scoped>
    .dense-panel >>> .v-expansion-panel__header {
        margin: 0;
        padding: 0;
        height: 44px !important;
        min-height: 40px;
    }
</style>