<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" class="page-container" grid-list-md text-xs-center
                 pa-3 ma-0 style="max-width: 100%;">
        <v-card class="mt-0 av-card" elevation="0" style="height: 80px;">
            <v-layout row wrap>
                <v-btn class="ma-4" @click="addSubline" color="info">
                    <av-icon :id="'newSublineBtn'" light small left>fa-plus</av-icon>
                    <translate>New subline</translate>
                </v-btn>
                <av-select class="ma-4" style="max-width: 300px" :items="sublineNames" v-model="subline"
                           :label="$gettext('Select')" @change="switchSubline($event)"
                           v-if="Array.isUseful(sublineNames)"/>
                <v-spacer></v-spacer>
                <v-btn v-if="subline" class="ma-4" @click="deleteSubline" color="error">
                    <av-icon :id="'deleteSublineBtn'" light small left>fas fa-trash</av-icon>
                    <translate>Delete subline</translate>
                </v-btn>
            </v-layout>
        </v-card>
        <v-card class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)">
            <v-card-text class="av-card-content">
                <ConfigurationsEditor v-if="subline" :descriptor="sublineDescriptor" @value-Changed="valueChanged"
                                      :filters="$license.modulesList()"></ConfigurationsEditor>
                <av-input-box ref="inputBox" :validator="sublineNameValidator" />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
    import Entity from "@/api/entities";
    import VariablePickerDialog from '@/components/dialogs/VariablePickerDialog';

    export default {
        name: "SublinesSettings",
        components: {ConfigurationsEditor},
        data() {
            return {
                loading: false,
                devices: [],
                sublines: {},
                subline: null,
                sublineDescriptor: {
                    default: {
                        visible: true,
                        groupName: "",
                        settings: {
                            machines: {
                                name: this.$gettext("Machines"),
                                value: [],
                                description: this.$gettext("Choose which machines belongs to subline"),
                                options: [],
                                type: "multiChecks",
                                readonly: false,
                            }
                        }
                    }
                },
                setting: null,
                index: 0,
                groupName: '',
                settingName: ''
            }
        },
        async mounted() {
            if (this.$root.startedUp) {
                this.loadSublines();
                this.devices = await this.$devices.getAvailable();
            }
        },
        computed: {
            sublineNames() {
                return Object.keys(this.sublines)
            }
        },
        methods: {
            loadSublines() {
                //Get the list of subline descriptors from DB
                let self = this;
                this.$set(this, "sublines", {});
                this.$settings.loadTypeMap(this.$settings.SublinesSettings)
                    .then(sublines => {
                        self.$set(self, "sublines", sublines);
                    })
                    .catch(err => {
                        console.error(err)
                        self.$root.showErrorNotification(self.$gettext("Unable to retrieve sublines info"), true);
                    })
            },
            switchSubline(subline) {
                let availableDevices = this.$utils.detach(this.devices);
                for(let sublineName in this.sublines)
                    if(sublineName != subline)
                        for(let device of this.devices)
                            if(this.sublines[sublineName].machines.includes(device))
                                availableDevices.removeItem(device)
                this.sublineDescriptor.default.settings.machines.options = availableDevices;
                this.sublineDescriptor.default.settings.machines.value = []
                if(Object.isNestedPropertyUseful(this.sublines, subline, "machines"))
                    for(let machine of this.sublines[subline].machines)
                        this.sublineDescriptor.default.settings.machines.value.push(this.sublineDescriptor.default.settings.machines.options.indexOf(machine))
            },
            sublineNameValidator(name) {
                if (!name)
                    return false;
                return true;
            },
            addSubline() {
                let self = this;
                this.$refs.inputBox.ShowInputBox(this.$gettext("Enter identifier of new subline."), "", "", "OK", function (value) {
                    self.newSubline(value)
                }, "Cancel", null);
            },

            newSubline(name) {
                try {
                    if (this.sublines.hasOwnProperty(name)) {
                        this.$root.showErrorNotification(this.$gettext("Subline with name {0} already exist").format(name), true);
                        return;
                    }

                    let self = this;
                    let audit = {
                        previousVal: "",
                        nextVal: "",
                        operator: this.$root.userName,
                        action: this.$audits.items().sublineAdded,
                        actionId: name
                    };

                    this.$root.setLoading(true, "Creating subline...");
                    this.subline = name;
                    this.sublines[name] = {
                        name: name,
                        machines: [],
                        order: Object.keys(this.sublines).length
                    }
                    this.switchSubline(name)

                    this.save(audit);

                    setTimeout(() => {
                        self.loadSublines();
                        self.$root.setLoading(false);
                    }, 3000)
                } catch (e) {
                    this.$root.setLoading(false);
                }
            },

            deleteSubline() {
                this.$root.showDialogBox(this.$gettext("Are you really sure you want to delete this subline? "), null, "Yes", this.delete, "CANCEL", null);
            },

            async delete() {
                try {
                    let self = this;
                    this.$root.setLoading(true, "Deleting subline...");

                    this.$settings.delete(this.$settings.SublinesSettings, this.subline)
                        .then(() => {
                            self.$audits.save(self.$root.userName, self.$audits.items().sublineDeleted, "", "", self.subline)
                                .catch(() => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                })
                            delete(self.sublines, self.subline)
                            self.subline = ""
                        })
                        .catch(() => {
                            self.$root.showErrorNotification("Error deleting subline, please retry", true);
                        });

                    setTimeout(async () => {
                        await self.loadSublines();
                        self.$root.setLoading(false);
                    }, 3000)
                }
                catch (e) {
                    self.$root.setLoading(false);
                }
            },

            mergeSetting(target, source) {
                if (Object.isUseful(source))
                    target.value = source;
            },
            async valueChanged(groupName, settingName, action) {
                if (this.sublines === null)
                    this.sublines = {};

                let indexedValue = {
                    machines: []
                }
                for(let machine of this.sublines[this.subline].machines)    //We need to convert from machine names to indexes to let audits work
                    indexedValue.machines.push(this.sublineDescriptor.default.settings.machines.options.indexOf(machine))
                let trail = this.$audits.getSettingChangedTrailObject(groupName, settingName, action, indexedValue, this.sublineDescriptor, ["SublinesSettings", this.subline]);
                this.sublines[this.subline].machines.clear()
                for(let index of this.sublineDescriptor.default.settings.machines.value)
                    this.sublines[this.subline].machines.push(this.sublineDescriptor.default.settings.machines.options[index])
                this.save(trail)
            },
            save(audit) {
                let self = this;
                this.$settings.save(this.sublines[this.subline], this.$settings.SublinesSettings, this.subline)
                    .then(() => {
                        self.$audits.save(audit.operator, audit.action, audit.previousVal, audit.nextVal, audit.actionId)
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            })
                    })
                    .catch(() => {
                        self.$root.showErrorNotification("Error saving subline description to DB.", true);
                    });
            }
        },
        watch: {
        }
    }
</script>

<style scoped>

</style>