<template>
    <v-dialog v-model="showDialog" width="1280px" height="640px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <av-loader :loading="loading" message="" minTime="0"/>
        <v-layout row>
            <v-flex xs7 pa-0 m-a>
                <TimeSpanPicker v-if="customTimePicker.show" :showDialog="customTimePicker.show"
                                :timespan="customTimePicker.span" @exit="customTimePickerResult($event)"/>
                <v-card height="590px">
                    <v-toolbar card dense>
                        <template>
                            <v-layout row align-center justify-end px-0 py-0>
                                <v-toolbar-title class="headline">
                                    <translate>Past wo adjustment</translate>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <template>
                                    <v-tooltip bottom>
                                        <v-btn fab small slot="activator" @click="zoomIn">
                                            <av-icon>fas fa-search-plus</av-icon>
                                        </v-btn>
                                        <span>Zoom In</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <v-btn small slot="activator" style="cursor:default;"
                                               @click="openTimeWindowPicker">
                                            {{displayedTimeWindow}}
                                        </v-btn>
                                        <span v-translate>Current time window</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <v-btn fab small slot="activator" @click="zoomOut">
                                            <av-icon>fas fa-search-minus</av-icon>
                                        </v-btn>
                                        <span v-translate>Zoom Out</span>
                                    </v-tooltip>
                                </template>
                            </v-layout>
                        </template>
                    </v-toolbar>
                    <v-layout column px-1 py-0 style="height: 500px;">
                        <v-layout column pa-0>
                            <v-card v-if="woHistory.length">
                                <v-list style="margin-top: 0px;">
                                    <v-list-tile style="height: 48px" @click="selectUnselectAllWoHistory(), processWo('')">
                                        <v-list-tile-action>
                                            <v-checkbox :value="selectAllWoHistory"/>
                                        </v-list-tile-action>
                                        <v-list-tile-title>
                                            {{$gettext("Workorders history ({0} min. total)").format(woHistoryDuration)}}
                                        </v-list-tile-title>
                                    </v-list-tile>
                                </v-list>
                                <v-list style="overflow: auto; margin-top: -25px; height: 450px">
                                    <template v-for="stop in woHistory">
                                        <v-list-tile style="margin-left: 15px; height: 30px" @click="stop.selected = !stop.selected, processWo('')">
                                            <v-list-tile-action>
                                                <v-checkbox :value="stop.selected"/>
                                            </v-list-tile-action>
                                            <v-list-tile-title>
                                                {{formatWoHistoryItem(stop)}}
                                            </v-list-tile-title>
                                        </v-list-tile>
                                    </template>
                                </v-list>
                            </v-card>
                        </v-layout>
                        <v-card-actions id="antares-annotation-dialog-card-actions">
                            <v-spacer></v-spacer>
                            <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat"
                                   @click="callback ? callback() : null, showDialog = false">
                                <translate>
                                    OK
                                </translate>
                            </v-btn>
                            <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat"
                                   @click="callback ? callback() : null, showDialog = false">
                                <translate>
                                    Cancel
                                </translate>
                            </v-btn>
                        </v-card-actions>
                    </v-layout>
                </v-card>
            </v-flex>
            <v-flex xs5>
                <template v-if="annotationWindowVisible">
                    <v-card height="590px">
                        <v-toolbar card dense>
                            <v-toolbar-title class="headline text-xs-center">
                                <av-icon v-if="isNotified" size="40" color="red" style="padding: 0px; margin: 0px; padding-right: 10px">fas fa-times-circle</av-icon>
                                {{title}}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-layout column pa-3>
                            <v-spacer />
                            <v-spacer />
                            <v-spacer />
                            <v-select solo class="title" :items="listWoHistory" :placeholder="lbSelectWo" item-text="show" item-value="id"
                                      v-on:change="changedWo" v-model="newWorkorder"></v-select>
                            <v-flex style="padding-top: 15px" xs7>
                                <v-switch :label="lbManualWo" v-model="manualWorkorder"/>
                            </v-flex>
                            <v-flex xs5>
                                <v-text-field class="osk" v-if="manualWorkorder" prefix="Type here: " v-model="newWorkorder"/>
                            </v-flex>
                            <template v-if="newWorkorder && (newWorkorder !== listWoHistory[0].id) && !manualWorkorder">
                                <label style="margin-top: 5px" class="subheading">
                                    <translate>Workorder</translate>: {{newWorkorder}}
                                </label>
                                <label style="margin-top: 5px" class="subheading">
                                    <translate>Product</translate>: {{newWoProduct}}
                                </label>
                                <label style="margin-top: 5px" class="subheading">
                                    <translate>Quantity to produce</translate>: {{newWoQuantity}}
                                </label>
                                <label style="margin-top: 5px" class="subheading">
                                    <translate>Status</translate>: {{newWoStatus}}
                                </label>
                                <label style="margin-top: 5px" class="subheading">
                                    <translate>Recipe</translate>: {{newWoRecipe}}
                                </label>
                            </template>
                        </v-layout>
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" flat="flat" @click="callBack(true)" v-translate>
                                OK
                            </v-btn>
                            <v-btn color="red darken-1" flat="flat" @click="callBack(false)" v-translate>
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-flex>
        </v-layout>
    </v-dialog>
</template>

<script>

    import TimeTracking from "@/components/TimeTracking";
    import TimeSpanPicker from "@/components/utilities/TimeSpanPickerDialog";
    import DateTimeUtils, {TimeSpan} from '@/api/datetimeutils';
    import BackAnnotations from "@/api/backannotation";

    export default {
        name: "WorkorderBackAnnotationDialog",
        components: {TimeTracking, TimeSpanPicker},
        data() {
            return {
                showDialog: false,
                user: "",
                timeWindow: new TimeSpan(), //Time window options
                customTimePicker:   //Object used to manage the advanced time span picker dialog
                    {
                        show: false,
                        span: new TimeSpan(),
                    },
                manualWorkorder: false,
                woHistory: [],
                woDetails: {},
                newWorkorder: "",
                newWoQuantity: "",
                newWoProduct: "",
                newWoStatus: "",
                newWoRecipe: "",
                recipeSpeeds: [],
                referenceMachine: "",
                listWoHistory: [{
                    show: this.$gettext("No workorder (switch line to idle)"),
                    id: "NoWo"
                }, {divider: true}],
                selectedWoHistory: [],
                selectAllWoHistory: false,
                annotationWindowVisible: false,
                loading: false,
                callback: null,
                title: this.$gettext("Change workorder"),
                root: null
            }
        },
        computed: {
            displayedTimeWindow() {
                return this.timeWindow.toString();
            },
            woHistoryDuration() {
                let duration = 0;
                for (let stop of this.woHistory) {
                    duration += (stop.stop - stop.start);
                }
                return Math.round(duration / 60000);
            },
            lbSelectWo() {
                return this.$gettext("Select new Workorder")
            },
            lbManualWo() {
                return this.$gettext("Manually specify workorder if not available in planned:");
            }
        },
        methods: {
            setVueInstance(instance) {
                this.root = instance;
            },
            callBack(result) {
                this.error = "";
                if (result) {
                    this.root.showDialogBox(this.$gettext("Are you sure you want to change the workorder?"), null, "Yes", this.confirmChange, "No", null);
                } else {
                    if (this.changeCallBack)
                        this.changeCallBack("");
                    this.annotationWindowVisible = false;
                }
            },
            confirmChange() {
                let self = this;
                let recipeSpeed = 0;
                let woRequests = [];
                let woProcessed = this.newWorkorder === "NoWo" ? "" : this.newWorkorder;

                //audits parameters
                let operator = self.user;
                let action = this.$audits.items().workOrderAdjustement;
                let actionId ='';
                let previousVal ='';
                let nextVal ='';

                for (let i = 0; i < this.recipeSpeeds.length; i++) {
                    if (this.$utils.matchWildcardString(this.newWoRecipe, this.recipeSpeeds[i].recipe)) {
                        recipeSpeed = this.recipeSpeeds[i][this.referenceMachine];
                    }
                }

                for (let i = 0; i < this.selectedWoHistory.length; i++) {
                    let wo = this.selectedWoHistory[i];
                    woRequests.push(this.$workorders.getBackAnnotationObject(woProcessed, wo.start, wo.stop, this.newWoRecipe, recipeSpeed, this.user, wo.start.toString()));
                }

                BackAnnotations.postBackAnnotations(woRequests)
                    .then(result => {
                        self.annotationWindowVisible = false;
                        if (result) {
                            for (let wo of self.selectedWoHistory) {
                                //[from, to, oldWo, newWo]
                                nextVal = [new Date(wo.start).format(), new Date(wo.stop).format(), wo.workorder, woProcessed];
                                self.$audits.save(operator, action, previousVal, nextVal, actionId);
                                self.woHistory.removeItem(wo);
                            }
                        }
                    })
                    .catch(error => {
                        debugger
                        console.error(error);
                        self.error = self.$gettext("An error occurred..., please try again");
                    });
            },
            formatWoHistoryItem(woItem) {
                let wo = woItem.workorder ? woItem.workorder : this.$gettext("No Workorder");
                return this.$gettext("{0} - {1} min. From {2} to {3}").format(wo, Math.round((woItem.stop - woItem.start) / 60000), new Date(woItem.start).format(), new Date(woItem.stop).format())
            },
            selectUnselectAllWoHistory() {
                this.selectAllWoHistory = !this.selectAllWoHistory;
                for (let stop of this.woHistory)
                    stop.selected = this.selectAllWoHistory;
            },
            processWo(selectedWo) {
                this.annotationWindowVisible = true;
                this.selectedWoHistory.clear();
                if (selectedWo) {
                    this.selectedWoHistory.push(selectedWo);
                } else {
                    for (let wo of this.woHistory) {
                        if (wo.selected) {
                            this.selectedWoHistory.push(wo);
                        }
                    }
                }
            },
            Show(user, callback) {
                this.showDialog = true;
                this.user = user;
                let settings = this.$settings.getLineSettings();
                if (settings) {
                    if (Array.isUseful(settings.lineSpeeds))
                        this.recipeSpeeds = settings.lineSpeeds;
                    this.referenceMachine = settings.masterMachine;
                }

                this.callback = callback;

                this.fetchData();
            },
            setDialog(result) {
                this.annotationWindowVisible = false;
                if (result) {
                    for (let stop of this.selectedWoHistory) {
                        if (stop.isShort) {
                            this.woHistory.removeItem(stop);
                        }
                    }
                }
            },
            /** TIME WINDOW MANAGEMENT **/
            zoomIn() {
                this.timeWindow.zoomIn();
                this.fetchData();
            },
            zoomOut() {
                this.timeWindow.zoomOut();
                this.fetchData();
            },
            openTimeWindowPicker() {
                //Ensure that data in time picker are copied from widget time window and not assigned, otherwise after the first run
                //It will be no more possible to cancel the time picker without affecting the widget time span
                //this.customTimePicker.span = this.properties.timeWindow; //No no no
                this.customTimePicker.span.from(this.timeWindow);
                this.customTimePicker.show = true;
            },
            customTimePickerResult(result) {
                this.customTimePicker.show = false;
                if (result) {
                    //Ensure that data from time picker are copied to widget time window and not assigned, otherwise after the first run
                    //It will be no more possible to cancel the time picker without affecting the widget time span
                    //this.scope().properties.timeWindow = this.customTimePicker.span; //No no no
                    this.timeWindow.from(this.customTimePicker.span);
                }
                this.fetchData();   //Execute a refresh anyway when getting back to view
            },
            changedWo() {
                let self = this;
                this.newWoQuantity = "";
                this.newWoProduct = "";
                this.newWoStatus = "";
                this.newWoRecipe = "";

                if (this.newWorkorder === "NoWo") {
                    return
                }

                this.$workorders.get(this.newWorkorder)
                    .then(wo => {
                        self.newWoQuantity = (wo.found ? wo.QuantityToProduce : self.$gettext("Unknown"));
                        self.newWoProduct = (wo.found ? wo.ProductDescription : self.$gettext("Unknown"));
                        self.newWoStatus = (wo.found ? wo.StatusString : self.$gettext("Unknown"));
                        self.newWoRecipe = (wo.found ? wo.RecipeId : self.$gettext("Unknown"));
                    })
            },
            fetchData() {
                let self = this;
                this.woHistory.clear();
                let loaderDelayer = setTimeout(() => {
                    self.loading = true
                }, 300);

                this.$workorders.getHistory(DateTimeUtils.getRfc3339TimeStamp(this.timeWindow.getStart(true)), DateTimeUtils.getRfc3339TimeStamp(this.timeWindow.getEnd()))
                    .then(workorders => {
                        let listWoHistoryContains = function (searchWo) {
                            self.listWoHistory.filter(workorder => {
                                return workorder.id === searchWo;
                            })
                        };

                        for (let i = 0; i < workorders.length; i++) {
                            self.woHistory.push({
                                start: workorders[i].start,
                                stop: workorders[i].stop,
                                duration: workorders[i].duration,
                                workorder: workorders[i].workorder,
                                selected: false

                            });

                            let wo = {};
                            if (workorders[i].workorder === "") {
                                wo = {show: this.$gettext("No workorder (switch line to idle)"), id: "NoWo"};
                            } else {
                                wo = {show: workorders[i].workorder, id: workorders[i].workorder};
                            }

                            if (!listWoHistoryContains(wo.id)) {
                                self.listWoHistory.push(wo);
                            }
                        }
                    })
                    .finally(() => {
                        clearTimeout(loaderDelayer);
                        self.loading = false;
                    });
            }
        }
    }

</script>

<style scoped>

</style>
