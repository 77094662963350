<template>
    <FormBase />
</template>
<script>
    import FormBase from '@/components/dynamic-elements/forms/FormBase.vue';

    export default {
        name: "FormMachineProcess",
        extends: FormBase,
        components: {
            FormBase
        },
        data() { return {} },
        mounted() {
            let self = this;
            this.childHandlers.getDefaultName = function() { return self.$gettext("MachineProcessManagement") };
            this.childHandlers.onNewElementCreated.push(this.onNew);
            this.childHandlers.onElementLoaded.push(this.onOpen);
            this.documentFormatHandler = this.formatRecord;
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    this.saveTweaks();
                },
                deep: true,
            }
        },
        methods: {
            setAppropriateTweaks() {
                this.setTweakValue("target", 2); //Target production data without namespace check
                this.setTweakValue("validityMode", 3);  //Validity mode ever
                this.setTweakValue("namespace", this.properties.name)
                for (let tweak of this.visualizationTweaks)
                    if(tweak.id !== "ruleToExecute")
                        tweak.hidden = true;
            },
            async onNew() {
                this.setAppropriateTweaks();
                this.formVariables = [
                    {
                        name: "MachineId",
                        title: this.$gettext("Machine"),
                        inputMode: "Normal",
                        type: "Single option",
                        value: "",
                        mandatory: true,
                        options: await this.loadMachines(),
                        editableName: false,
                        editableType: false,
                        undeletable: true,
                        isGlobalRootVariable: true,
                        children: []
                    }
                ];
                this.$emit('dataItemsUpdated');
            },
            async onOpen() {
                this.setAppropriateTweaks();
                this.formVariables[0].options = await this.loadMachines();
                if(this.entity) {
                    this.formVariables[0].readonly = true;
                    this.setTweakValue("namespace", this.properties.name)
                }
            },
            async loadMachines() {
                try {
                    let devs = await this.$devices.getAvailable();
                    return await this.$aliases.getMachinesAliasesObjects(devs)
                } catch (err) {
                    //TODO Notify user and close form?
                    debugger
                    console.log(err)
                    return [];
                }
            },
            formatRecord(document) {
                document.MachineId = this.formVariables[0].value;
                document.id = "Process.{0}.{1}".format(this.properties.name, document.MachineId);
                document.Transform = [
                    {
                        Type: "MoveStructure",
                        Source: document.NameSpace,
                        Destination: this.formVariables[0].value + ".Processes." + this.properties.name
                    },
                    {
                        Type: "SetField",
                        Key: "NameSpace",
                        Value: this.formVariables[0].value + ".Processes." + this.properties.name
                    }
                ]
                document.tag =  {
                    ExposedName: "Machine process"
                };
            }
        }
    }

</script>

<style scoped>
</style>
