    <template>
    <GenericTable>
        <av-button slot="backButton" :text="$gettext('BACK')" color="error"
                   buttonIcon="fas fa-arrow-left" iconColor="light" v-if="showBackButton" @click="goBack"/>
    </GenericTable>
</template>

<script>
import GenericTable from "@/components/utilities/GenericTable";
import Maintenance from '@/api/maintenance';

export default {
    name: "MaintenanceRegister",
    components: {GenericTable},
    extends: GenericTable,
    data: () => ({
        entity: null,
        showBackButton: false,
        backPath: "",
    }),
    methods: {
        async loadTransactions() {
            this.waitItems = new Promise(async (resolve, reject) => {
                let self = this;
                let listOfItems=[]
                try{
                    if (self.entity) {
                        listOfItems=await Maintenance.getMaintenanceRegisterByAsset(self.entity.EntityKeys.assetId, self.entity.EntityKeys.EntityName,
                            self.timeWindow.getStart(), self.timeWindow.getEnd())

                        self.filterMessage = self.$gettext("Maintenance interventions filtered by asset: {0}").format(self.entity.EntityKeys.assetId);
                    } else
                        listOfItems = await Maintenance.getMaintenanceRegister(self.timeWindow.getStart(), self.timeWindow.getEnd())

                }finally {
                    if(Array.isUseful(listOfItems)){
                        self.listOfItems=listOfItems
                    }
                    resolve(true);
                }


            });
        },
        entityDetails(entity) {
            this.$router.push({name: 'entityDetails', params: {entity: entity.Transaction,  backPath: this.backHerePath() }});
        },
        editEntity(entity) {
            this.$router.push({name: 'formsEdit', params: {routeId: entity.Transaction.Name, entity: entity.Transaction, backPath: this.backHerePath()}})
        },
        async deleteEntity(entity) {
            return new Promise(async (resolve, reject) => {
                let self = this;
                Maintenance.deleteRecord(entity.id)
                    .then(() => {
                        //audit trail for maintenance register deletion
                        self.$audits.save(self.$root.userName, self.$audits.items().maintenanceRegisterDeleted, entity.id, "", entity.Transaction.EntityKeys.assetId)
                            .catch(err => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                        resolve(true);
                    })
                    .catch(err => {
                        self.$root.showErrorNotification(err, true, false);
                        resolve(false);
                    })
            });

        },
        backHerePath() {
            if(this.entity)
                return {name: 'maintenanceRegister', params: {entity: this.entity, backPath: 'assetsRegister'}};
            else
                return {name: 'maintenanceRegister'};
        },
        goBack(){
            if(this.backPath)
                this.$router.push(this.backPath);
            else
                this.$router.go(-1);
        },
        goToActivitiesPage(maintenanceId) {
            let self = this;
            const maintenance = self.listOfItems.find(item => item.id === maintenanceId);
            let forms = [];
            forms.push(maintenance.Transaction.NameSpace);
            self.$router.push({ name: 'activitiesPage', params: { formName: forms, backPath: '/maintenanceRegister' }})
        },
        showDocumentsPage(maintenanceId) {
            return true
        },
        goToDocumentsPage(maintenanceId) {
            this.$router.push({ name: 'documentationPage', params: { maintenanceId: maintenanceId, backPath: '/maintenanceRegister' }});
        },
        addDetailsButtons() {
            let self = this;
            self.detailsGroup.children.push({ icon: 'fas fa-folder-open', text: 'Documents', color: 'blue', handler: 'goToDocumentsPage', description: 'Documents page',
                getGrant: (assetId) => { return self.showDocumentsPage(assetId) && self.$grants.get().documentationPage.canVisualizeDocumentationPage }});
            self.detailsGroup.children.push({ icon: 'fas fa-tasks-alt', text: 'Activities', color: 'blue', handler: 'goToActivitiesPage', description: 'Activities',
                getGrant: () => { return self.$grants.get().activities.canVisualizeActivities }});
        },
        addActionsGrants() {
            let self = this;
            let editAction = self.actionsGroup.children.find(item => item.handler === 'editItem');
            if (editAction)
                editAction.getGrant = () => { return self.$grants.get().maintenance.canModifyMaintenanceRecords }
            let deleteAction = self.actionsGroup.children.find(item => item.handler === 'deleteItem');
            if (deleteAction)
                deleteAction.getGrant = () => { return self.$grants.get().maintenance.canDeleteMaintenanceRecords }
        }
    },
    mounted() {
        this.getListOfItems = this.loadTransactions;
        this.scopeIndex = "Maintenance";
        this.timeWindow.setPredefined(129600);
        this.scopeType = "maintenanceRegisterVisualizationOptions";
        // load minimal staff needed for exportation
        if (this.$route.params.requiredExport) {
            this.loadData();
            return;
        }
        this.$root.setCurrentPageTitle(this.$gettext("Maintenance Register"));
        this.itemDetails = this.entityDetails;
        this.deleteItem = this.deleteEntity;
        this.editItem = this.editEntity;
        this.auditType = "Maintenance intervention";
        this.alwaysTimeFilter = true;
        this.needActionAndDetails = true;
        this.defaultVisualizationProfile = [{name: 'Entity.Asset.assetId', text: "Asset ID"}, {name: '@timestamp', text: 'Date'}, {name: 'Operator', text: 'Operator'}];
        if(this.$route.params && this.$route.params.entity){
            this.entity = this.$route.params.entity;
            this.showBackButton = true;
        }
        if(this.$route.params.backPath)
            this.backPath = this.$route.params.backPath;
        this.addDetailsButtons();
        this.addActionsGrants();
        this.loadData();
        this.loadForms();
    }
}
</script>

<style scoped>

</style>
