<template>
    <v-container style="min-width: 100%; max-width: 100vw; height: 100%;" ma-0 pa-2>
        <div ref="container"
             style="width: 100%; height:100%; max-height: 100%; margin: 0; padding:5px; overflow: hidden;"></div>
    </v-container>
</template>

<script>

    import Mutex from "@/api/mutex";

    export default {
        name: "FormContainer",
        data() {
            return {
                loaded: false,
                currentForm: null,
                lockedItem: ""
            }
        },
        props: {},
        beforeDestroy:
            function () {
                this.releaseLock();
            },
        beforeMount: function () {
            this.load();
        },
        watch: {
            formToLoad() {
                this.load();
            }
        },
        computed: {
            formToLoad() {
                return (this.$route.params.routeId);
            },
            actionId() {
                return (this.$route.params.actionId);
            },
            action() {
                return (this.$route.params.action);
            },
            entity() {
                return (this.$route.params.entity)
            },
            variables(){
                return this.$route.params.variables ? this.$route.params.variables : [];
            },
            backPath() {
                return this.$route.params.backPath;
            },
            applicationStartedUp() {
                return this.$root.startedUp;
            }
        },
        methods: {
            async load() {

                let self = this;

                this.releaseLock();
                //If formId is set, it means that we are reopening an entity for editing, check whether it is locked by other user
                if(this.entity && this.entity.id) {
                    let lock = await Mutex.isItemLocked("forms", this.formToLoad + "-" + this.entity.id);
                    if(lock) {
                        this.$root.showErrorNotification(this.$gettext("Requested entity is being already edited by {0}. Please try again later or request {0} to release it.").format(lock.user), true);
                        this.$router.push("/home");
                        return;
                    } else this.initLocker();
                }
                //First load live document to gather status information
                this.$dynamicElements.LoadItem(this.formToLoad, "forms")
                    .then(t => {
                        let latestDescriptor = JSON.parse(t.descriptor);
                        //Cannot edit a non-active form
                        if(latestDescriptor.properties.deployStatus < 1) {
                            self.$root.showErrorNotification(self.$gettext("Unable to open form {0} since it is not active").format(self.formToLoad), true);
                            return;
                        }
                        //Retrieve current deployed version
                        let neededVersion = latestDescriptor.deployedVersion ? latestDescriptor.deployedVersion : 1;
                        //If we are reopening an existing instance, we need the exact version used at creation time
                        if(self.entity && self.entity.id)
                            neededVersion = self.entity.FormVersion;
                        //If we need latest version we already have it
                        if (neededVersion === latestDescriptor.version || latestDescriptor.properties.descriptorVersion < 3 /*Legacy version-less forms management*/) {
                            self.drawForm(latestDescriptor);
                            return
                        }
                        //otherwise we load correct version descriptor
                        self.$dynamicElements.DescriptorForIndexAndVersion("user_formshistory", self.formToLoad, neededVersion)
                            .then(item => {
                                if (!Array.isUseful(item))
                                    throw("Version {0} not found for form {1}".format(neededVersion, self.formToLoad));
                                else {
                                    let descriptor = (JSON.parse(item[0].descriptor));
                                    descriptor.properties.deployStatus = latestDescriptor.properties.deployStatus;
                                    descriptor.deployedVersion = latestDescriptor.deployedVersion;
                                    self.drawForm(descriptor);
                                }
                            })
                            .catch(err => {
                                debugger;
                                console.error(err);
                                self.$root.showErrorNotification(self.$gettext("Unable to open form {0}. Cannot find needed version").format(self.formToLoad), true);
                            });
                    })
                    .catch(t => {
                        console.error(t);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving form {0} info from DB.").format(this.formToLoad), true);
                    });
            },
            drawForm(descriptor) {
                //This route param is activated by orphan transactions that is inactive at moment
                if (this.$route.query.keys) {
                    this.$route.query.keys.forEach(key => {
                        let keyValue = key.split(":");
                        if (keyValue[0] !== "EntityName") {
                            descriptor.formVariables.find(item => item.name === keyValue[0]).value = keyValue[1]
                        }
                    });
                    this.$route.query.keys = []
                } else {
                    descriptor.formVariables.forEach(formVariable => {
                        formVariable.restrictedMode = false
                    })
                }
                this.$root.setCurrentPageTitle(descriptor.properties.title);

                //Added descriptor for activities connected  to machine
                if (this.$route.params.description) {
                    if (this.$route.params.description.description)
                        descriptor.properties.description = this.$route.params.description.description;
                    if (this.$route.params.description.dynamicDescription)
                        descriptor.properties.dynamicDescription = this.$route.params.description.dynamicDescription;
                }

                let currentFormType = descriptor.properties.type;
                let self = this;
                this.$nextTick(() => {
                    //Create form
                    this.currentForm = this.$dynamicElements.createNew(currentFormType, false, { fillVariables: this.variables ? this.variables: [], entity: this.entity });
                    if(!this.currentForm)
                        return; //License issue or huge error (unlikely)
                    this.currentForm.load(descriptor);
                    this.currentForm.properties.showTitleBar = false;
                    this.$root.setCurrentPageTitle(this.currentForm.properties.title);
                    this.currentForm.$on('canceled', () => {
                        self.goToDefaultFormClosure();
                    });
                    this.currentForm.$on('complete', (continueEntering, Annotation) => {
                        //Call analytics method to refresh data if data target is production data
                        self.$dataEntry.scanCycleAnalytics();
                        if(self.action) {
                            try {
                                self.$root.setLoading(true, "Committing result...");
                                self.$dataEntry.setActionCompleted(self.action.id, self.$root.userName)
                                    .finally(() => {
                                        let auditTrailNext = [self.action.Action.name, Annotation];
                                        this.$audits.save(this.$root.userName, this.$audits.items().taskResolved, '', auditTrailNext,
                                            self.action.Name + ' - ' + new Date(self.action.NextTriggerTime).format())
                                        //Give DB time to digest change
                                        setTimeout(function () {
                                            self.$root.setLoading(false);
                                            self.$root.reloadActions = true;
                                            if(!continueEntering) {
                                                self.goToDefaultFormClosure()
                                            }
                                            else {
                                                self.action = null;
                                                self.currentForm.clearForm();
                                            }
                                        }, 2000)
                                    })
                            } catch(e) {
                                self.$root.setLoading(false);
                            }
                        } else if(!continueEntering) {
                            self.goToDefaultFormClosure()
                        } else self.currentForm.clearForm();
                        self.$root.showInfoNotification(this.$gettext("Form correctly saved"), false);
                    });
                    this.currentForm.$on('error', (message) => {
                        self.$root.showErrorNotification(this.$gettext(message + " "), false)
                    });
                    this.$refs.container.insertBefore(this.currentForm.$el, this.$refs.container.firstChild);
                });
            },
            goToDefaultFormClosure() {
                if(this.backPath)
                    this.$router.push(this.backPath);
                else {
                    if (this.$config.isTransactionMode()) {
                        let self = this;
                        setTimeout(() => {
                            self.$root.resetApp();
                        }, 2000);
                    }
                    else this.$router.push("/home");
                }
                this.$root.setCurrentPageTitle("");
            },
            initLocker() {
                //No lock needed
                if(!(this.entity && this.entity.id))
                    return;
                let self = this;
                let lock = function() {
                    //We need to keep a reference to lock name since form could switch without passing through destroy
                    self.lockedItem = self.formToLoad + "-" + self.entity.id;
                    Mutex.lockItem("forms", self.lockedItem);
                };
                lock(); //Execute first shot immediately
                this.lockTimer = setInterval(() => { lock(); }, 5000); //Then refresh every 5 secs
            },
            releaseLock() {
                clearInterval(this.lockTimer);
                if(this.lockedItem)
                    Mutex.unLockItem("forms", this.lockedItem);
            },
        },
    }

</script>

<style scoped>

</style>
