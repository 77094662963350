<template>
    <DynamicElementBase>
        <div :style="'height: 100%; width: {0}px; overflow: hidden; text-align: center; margin-top: {1}px'.format(previousWidgetSize.width, verticalOffset)" >
            <table style="height: 100%; width: 100%; overflow: hidden; text-align: center;">
                <tr :style="'width: {0}px'.format(previousWidgetSize.width)">
                    <td :style="'width: {0}px'.format(previousWidgetSize.width / 2)">
                        <div ref="box1" :style="'height: 100%; width: 100%; overflow: hidden; text-align: center; opacity:' + (OeeTotalValue === 'N/A' ? 0.5 : 1)" >
                            <center style="margin-top: 8px"><span class="headline font-weight-bold">OEE: {{this.gaugesInitStep != 0 ? 0 : this.OeeTotalValue}}%</span></center>
                            <radial-gauge style="width: 100%; height: 100%; margin-top: 8px" ref="gaugeElement1" :value="cleanGaugeValue(OeeTotalValue)" :options="OeeTotalOptions"></radial-gauge>
                        </div>
                    </td>
                    <td :style="'width: {0}px; height:100%'.format(componentBoxWidth)">
                        <div ref="box2" :style="'height: 100%; width: 100%; overflow: hidden; text-align: center; opacity: {0}; width: {1}px'.format((OeeAvailabilityValue === 'N/A' ? 0.5 : 1), componentBoxWidth)" >
                            <center><span ref="sampleLabel" class="subheading font-weight-bold ellipsis px-1" :style="'width: {0}px'.format(componentBoxWidth)"><translate>Availability</translate><br>{{this.gaugesInitStep !== 0 ? 0 : this.OeeAvailabilityValue}}%</span></center>
                            <linear-gauge ref="gaugeElement2" :value="cleanGaugeValue(OeeAvailabilityValue)" :options="OeeAvailabilityOptions"></linear-gauge>
                        </div>
                    </td>
                    <td :style="'width: {0}px; height:100%'.format(componentBoxWidth)">
                        <div ref="box3" :style="'height: 100%; width: 100%; overflow: hidden; text-align: center; opacity: {0}; width: {1}px'.format((OeeEfficiencyValue === 'N/A' ? 0.5 : 1), componentBoxWidth)" >
                            <center><span class="subheading font-weight-bold ellipsis px-1" :style="'width: {0}px'.format(componentBoxWidth)"><translate>Performance</translate><br>{{this.gaugesInitStep !== 0 ? 0 : this.OeeEfficiencyValue}}%</span></center>
                            <linear-gauge ref="gaugeElement3" :value="cleanGaugeValue(OeeEfficiencyValue)" :options="OeeEfficiencyOptions"></linear-gauge>
                        </div>
                    </td>
                    <td :style="'width: {0}px; height:100%'.format(componentBoxWidth)">
                        <div ref="box4" :style="'height: 100%; width: 100%; overflow: hidden; text-align: center; opacity: {0}; width: {1}px'.format((OeeQualityValue === 'N/A' ? 0.5 : 1), componentBoxWidth)" >
                            <center><span class="subheading font-weight-bold ellipsis px-1" :style="'width: {0}px'.format(componentBoxWidth)"><translate>Quality</translate><br>{{this.gaugesInitStep !== 0 ? 0 : this.OeeQualityValue}}%</span></center>
                            <linear-gauge ref="gaugeElement4" :value="cleanGaugeValue(OeeQualityValue)" :options="OeeQualityOptions"></linear-gauge>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
    import RadialGauge from 'vue2-canvas-gauges/src/RadialGauge'
    import LinearGauge from 'vue2-canvas-gauges/src/LinearGauge'
    import Oee from '@/api/oee'

    export default {
        name: 'WidgetOEE',
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            RadialGauge,
            LinearGauge
        },
        data () {
            return {
                dataCollection: null,
                gaugesInitStep: 2,
                missingRecipeAlreadyNotified: false,
                gaugesInitializer: null,
                initializer: null,
                previousWidgetSize: {
                    width: -1,
                    height: -1
                },
                oeeBoxWidth: -1,
                componentBoxWidth: -1,
                verticalOffset: -1,
                OeeTotalValue: 0,
                OeeTotalOptions: { // https://canvas-gauges.com/documentation/user-guide/configuration
                    units: '',
                    title: "",
                    minValue: 0,
                    maxValue: 100,
                    width: 240,
                    height: 250,
                    exactTicks: true,
                    startAngle: 90,
                    ticksAngle: 180,
                    colorMajorTicks: "rgba(0,0,0,0)",
                    strokeTicks: false,
                    // highlights: [
                    //     {"from": 0, "to": 30, "color": "red"},
                    //     {"from": 30, "to": 50, "color": "yellow"},
                    //     {"from": 50, "to": 70, "color": "lightgreen"},
                    //     {"from": 70, "to": 100, "color": "green"},
                    // ],
                    highlights: [
                        {"from": 0, "to": 38, "color": this.$avStyle.colors.red},
                        {"from": 38, "to": 100, "color": this.$avStyle.colors.green},
                    ],
                    majorTicks: [0, 30, 50, 70, 87.5, 100],
                    minorTicks: "[]",
                    borderShadowWidth: 0,
                    borders: false,
                    needle: true,
                    animationDuration: 1500,
                    animationRule: 'bounce',
                    animateOnInit: true,
                    colorBar: 'grey',
                    colorBarProgress: 'blue',
                    valueBox: false,
                    colorPlate: "rgba(255,255,255,0)"
                },
                OeeAvailabilityValue: 0,
                OeeEfficiencyValue: 0,
                OeeQualityValue: 0,
                // https://canvas-gauges.com/documentation/user-guide/configuration
                OeeAvailabilityOptions: {
                    units: '',
                    title: "",
                    minValue: 0,
                    maxValue: 100,
                    width: 130,
                    height: 150,
                    exactTicks: true,
                    colorMajorTicks: "rgba(0,0,0,0)",
                    strokeTicks: false,
                    highlights: [{"from": 0, "to": 60, "color": this.$avStyle.colors.red}, {
                        "from": 60,
                        "to": 80,
                        "color": this.$avStyle.colors.yellow
                    }, {
                        "from": 80,
                        "to": 100,
                        "color": this.$avStyle.colors.green
                    }],
                    highlightsWidth: 10,
                    majorTicks: [0, 20, 40, 60, 80, 100],
                    minorTicks: "[]",
                    borderShadowWidth: 0,
                    borders: false,
                    needle: true,
                    animationDuration: 500,
                    animationRule: 'linear',
                    animationTarget: "plate",
                    animateOnInit: true,
                    colorBar: 'grey',
                    colorBarProgress: 'blue',
                    barBeginCircle: false,
                    tickSide: "left",
                    numberSide: "right",
                    numbersMargin: -30,
                    needleSide: "left",
                    needleType: "arrow",
                    needleWidth: 10,
                    colorNeedle: "#222",
                    colorNeedleEnd: "#222",
                    barWidth: 0,
                    ticksWidth: 20,
                    valueBox: false,
                    colorPlate: "rgba(255,255,255,0)"
                },
                OeeEfficiencyOptions: {
                    units: '',
                    title: "",
                    minValue: 0,
                    maxValue: 100,
                    width: 130,
                    height: 150,
                    exactTicks: true,
                    colorMajorTicks: "rgba(0,0,0,0)",
                    strokeTicks: false,
                    highlights: [{"from": 0, "to": 60, "color": this.$avStyle.colors.red}, {
                        "from": 60,
                        "to": 80,
                        "color": this.$avStyle.colors.yellow
                    }, {
                        "from": 80,
                        "to": 100,
                        "color": this.$avStyle.colors.green
                    }],
                    highlightsWidth: 10,
                    majorTicks: [0, 20, 40, 60, 80, 100],
                    minorTicks: "[]",
                    borderShadowWidth: 0,
                    borderOuterWidth: 0,
                    borderMiddleWidth: 0,
                    borderInnerWidth:0,
                    borders: false,
                    needle: true,
                    animationDuration: 500,
                    animationRule: 'linear',
                    animationTarget: "plate",
                    animateOnInit: true,
                    colorBar: 'grey',
                    colorBarProgress: 'blue',
                    barBeginCircle: false,
                    tickSide: "left",
                    numberSide: "right",
                    numbersMargin: -30,
                    needleSide: "left",
                    needleType: "arrow",
                    needleWidth: 10,
                    colorNeedle: "#222",
                    colorNeedleEnd: "#222",
                    barWidth: 0,
                    ticksWidth: 20,
                    valueBox: false,
                    colorPlate: "rgba(255,255,255,0)"
                },
                OeeQualityOptions: {
                    units: '',
                    title: "",
                    minValue: 0,
                    maxValue: 100,
                    width: 130,
                    height: 150,
                    exactTicks: true,
                    colorMajorTicks: "rgba(0,0,0,0)",
                    strokeTicks: false,
                    highlights: [{"from": 0, "to": 60, "color": this.$avStyle.colors.red}, {
                        "from": 60,
                        "to": 80,
                        "color": this.$avStyle.colors.yellow
                    }, {
                        "from": 80,
                        "to": 100,
                        "color": this.$avStyle.colors.green
                    }],
                    highlightsWidth: 10,
                    majorTicks: [0, 20, 40, 60, 80, 100],
                    minorTicks: "[]",
                    borderShadowWidth: 0,
                    borders: false,
                    needle: true,
                    animationDuration: 500,
                    animationRule: 'linear',
                    animationTarget: "plate",
                    animateOnInit: true,
                    colorBar: 'grey',
                    colorBarProgress: 'blue',
                    barBeginCircle: false,
                    tickSide: "left",
                    numberSide: "right",
                    numbersMargin: -30,
                    needleSide: "left",
                    needleType: "arrow",
                    needleWidth: 10,
                    colorNeedle: "#222",
                    colorNeedleEnd: "#222",
                    barWidth: 0,
                    ticksWidth: 20,
                    valueBox: false,
                    colorPlate: "rgba(255,255,255,0)"
                },
            }
        },
        beforeDestroy: function() {
            clearInterval(this.gaugesInitializer);
        },
        mounted: function() {
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], true));
            this.dataExplorationMode.autoDataSourcing = true;
            this.dataExplorationMode.deviceSelectMode = true;
            this.dataExplorationMode.multipleDevicesSelection = true;
            this.dataExplorationMode.enumerateLineInDevices = true;
            this.fixedHeight = 300;

            let settings = this.$settings.getLineSettings();
            let targetOee = 60;
            if(settings && settings.targetOEE)
                targetOee = settings.targetOEE;
            let ticks = [0, 20, 40, 60, 80, 100];
            for(let i = 0 ; i < ticks.length - 1 ; i++)
                if(targetOee > ticks[i] && targetOee < ticks[i + 1])
                    ticks[i+1] = targetOee;
            this.$refs.gaugeElement1.chart.update({highlights: [
                    { "from": 0, "to": targetOee, "color": this.$avStyle.colors.red},
                    { "from": targetOee, "to": 100, "color": this.$avStyle.colors.green}],
                majorTicks: ticks});

            //AutomaticRuntimeDatasource is setting a true for level 3
            this.visualizationTweaks = [
                {
                    name: this.$gettext("Automatic runtime datasource"),
                    id: "AutomaticRuntimeDatasource",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                },
                {
                    name: this.$gettext("Include short stops in availability"),
                    id: "microstop",
                    type: "number",
                    default: function () {
                        return 0
                    }
                },
                {
                    name: this.$gettext("Availability green percent"),
                    id: "AvailabilityGreen",
                    type: "number",
                    default: function () {
                        return 60
                    },
                },
                {
                    name: this.$gettext("Availability yellow percent"),
                    id: "AvailabilityYellow",
                    type: "number",
                    default: function () {
                        return 50
                    },
                },
                {
                    name: this.$gettext("Efficiency green percent"),
                    id: "EfficiencyGreen",
                    type: "number",
                    default: function () {
                        return 90
                    },
                },
                {
                    name: this.$gettext("Efficiency yellow percent"),
                    id: "EfficiencyYellow",
                    type: "number",
                    default: function () {
                        return 80
                    },
                },
                {
                    name: this.$gettext("Quality green percent"),
                    id: "QualityGreen",
                    type: "number",
                    default: function () {
                        return 95
                    },
                },
                {
                    name: this.$gettext("Quality yellow percent"),
                    id: "QualityYellow",
                    type: "number",
                    default: function () {
                        return 95
                    }
                }];

            this.visualizationTargets = [
                { show: "Uptime",   id: Oee.targets.uptime },
                { show: "Produced items", id: Oee.targets.totalItems },
                { show: "Rejected items", id: Oee.targets.rejectedItems },
                { show: "Items to produce", id: Oee.targets.itemsToProduce },
                { show: "Scheduled production time", id: Oee.targets.scheduledTime },
                { show: "Availability", id: Oee.targets.availability },
                { show: "Efficiency", id: Oee.targets.efficiency },
                { show: "Quality", id: Oee.targets.quality },
                { show: "Total", id: Oee.targets.total }
            ];

            //Canvas gauges has some issue with data binding, thus first data value change is lost
            //and gauge keeps hanged to zero value until value changes again.
            //This workaround forces a first data change
            this.gaugesInitializer = setInterval((function(self) {
                return function() {
                    if (self.gaugesInitStep === 2) {
                        self.OeeQualityValue = 1;
                        self.OeeEfficiencyValue = 1;
                        self.OeeAvailabilityValue = 1;
                        self.OeeTotalValue = 1;
                        self.gaugesInitStep = 1;
                    }
                    else if (self.gaugesInitStep === 1) {
                        self.OeeQualityValue = 0;
                        self.OeeEfficiencyValue = 0;
                        self.OeeAvailabilityValue = 0;
                        self.OeeTotalValue = 0;
                        self.gaugesInitStep = 0;
                    }
                    else {
                        //clearInterval(self.gaugesInitializer);
                        self.resize()
                    }
                }
            })(this), 500);
        },
        computed: {
            devices() {
                return this.properties.selectedDevices;
            }
        },
        watch: {
            devices() {
                if(!this.loading && !this.getTweakValue("AutomaticRuntimeDatasource"))
                    this.$root.showDialogBox(this.$gettext("Do you want to automatically create data sources for OEE?"), null, this.$gettext("Yes"), this.createDataItems, this.$gettext("No"), null);
            },
            visualizationTweaks: {
                handler: function () {
                    if (this.visualizationTweaks.length > 0) {
                        this.parameterItems = [{
                            key: "microstop",
                            // value: this.visualizationTweaks[0].value
                            value: this.getTweakValue("microstop")
                        }];

                        this.$refs.gaugeElement2.chart.update({highlights: [
                            { "from": 0, "to": parseInt(this.getTweakValue("AvailabilityYellow")), "color": this.$avStyle.colors.red},
                            { "from": parseInt(this.getTweakValue("AvailabilityYellow")), "to": parseInt(this.getTweakValue("AvailabilityGreen")), "color": this.$avStyle.colors.yellow },
                            { "from": parseInt(this.getTweakValue("AvailabilityGreen")), "to": 100, "color": this.$avStyle.colors.green}]});
                        this.$refs.gaugeElement3.chart.update({highlights: [
                            { "from": 0, "to": this.getTweakValue("EfficiencyYellow"), "color": this.$avStyle.colors.red},
                            { "from": this.getTweakValue("EfficiencyYellow"), "to": this.getTweakValue("EfficiencyGreen"), "color": this.$avStyle.colors.yellow },
                            { "from": this.getTweakValue("EfficiencyGreen"), "to": 100, "color": this.$avStyle.colors.green}]});
                        this.$refs.gaugeElement4.chart.update({highlights: [
                            { "from": 0, "to": this.getTweakValue("QualityYellow"), "color": this.$avStyle.colors.red},
                            { "from": this.getTweakValue("QualityYellow"), "to": this.getTweakValue("QualityGreen"), "color": this.$avStyle.colors.yellow },
                            { "from": this.getTweakValue("QualityGreen"), "to": 100, "color": this.$avStyle.colors.green}]});
                    }

                    this.saveTweaks();
                    if (this.getTweakValue("AutomaticRuntimeDatasource")) {
                        this.loadDataMappings();
                    }
                },
                deep: true
            },
        },
        methods: {
            loadDataMappings() {
                let self = this;
                this.$devices.selectDeviceFromIndexPatterns(this.dataExplorationMode.compatibleDataPatterns, true)
                    .then(devices => {
                        self.createDataItems(devices);
                    })
            },
            resize() {
                if(!this.$refs.box1 || !this.$refs.box2 || !this.$refs.box3 || !this.$refs.box4)
                    return;
                let width = (this.containerWidth / 2);
                let height = (this.containerHeight - this.$refs.sampleLabel.offsetHeight) * 1.90;
                if(height !== this.previousWidgetSize.height || this.containerWidth !== this.previousWidgetSize.width) {
                    this.previousWidgetSize.height = height;
                    this.previousWidgetSize.width = this.containerWidth;
                    this.oeeBoxWidth = width > height ? height : width;

                    this.$refs.gaugeElement1.chart.update({
                        width:  this.oeeBoxWidth,
                        height: this.oeeBoxWidth - 10,
                    });
                    let wHeight = height;
                    if(wHeight > this.oeeBoxWidth * 1.25)
                        wHeight = this.oeeBoxWidth * 1.25;
                    wHeight /= 2;
                    this.verticalOffset = (this.containerHeight - wHeight - this.$refs.sampleLabel.offsetHeight) / 2;
                    if (this.verticalOffset < 5) {
                        this.verticalOffset = 0;
                    }
                    this.componentBoxWidth = (this.containerWidth - this.oeeBoxWidth) / 3;
                    this.$refs.gaugeElement2.chart.update({
                        width: this.componentBoxWidth,
                        height: wHeight,
                    });
                    this.$refs.gaugeElement3.chart.update({
                        width: this.componentBoxWidth,
                        height: wHeight,
                    });
                    this.$refs.gaugeElement4.chart.update({
                        width: this.componentBoxWidth,
                        height: wHeight,
                    });
                }
            },
            createDataItems(devices) {
                let descriptor = Array.isUseful(devices) ? Oee.getDataDescriptor(devices) : Oee.getDataDescriptor(this.properties.selectedDevices);
                this.filterItems = descriptor.filters;
                this.dataItems = descriptor.data;
                this.aggregationItems = descriptor.aggregations;

                this.$emit('dataItemsUpdated');
            },

            refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view

                // if(Config.deploy === "l3") {
                //     this.refreshDataL3(dataValues);
                //     return;
                // }
                this.OeeAvailabilityValue = 0;
                this.OeeEfficiencyValue = 0;
                this.OeeQualityValue = 0;
                this.OeeTotalValue = 0;
                let errors = [];
                let warnings = [];
                let validData = false;
                for(const dataSet of dataValues) {
                    if (dataSet && Array.isUseful(dataSet.data)) {
                        validData = true;
                        let error = Oee.translateError(dataSet.data.last().error);
                        let warning = Oee.translateError(dataSet.data.last().warning);
                        let valid = Oee.isIndicatorValid(dataSet.data.last().error, dataSet.data.last().warning);

                        if(error)
                            errors.push(error);
                        if(warning)
                            warnings.push(warning);
                        if (dataSet.target === Oee.targets.availability)
                            this.OeeAvailabilityValue = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.efficiency)
                            this.OeeEfficiencyValue = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.quality)
                            this.OeeQualityValue = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1): "N/A";
                        else if (dataSet.target === Oee.targets.total)
                            this.OeeTotalValue = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 2): "N/A";

                    }
                }

                this.clearErrors();
                this.clearWarnings();

                for(let i = 0 ; i < errors.length ; i++)
                    this.setError(errors[i]);
                for(let i = 0 ; i < warnings.length ; i++)
                    this.setWarning(warnings[i]);

                if(!validData) {
                    this.setError("No data available");
                    this.OeeAvailabilityValue = 0;
                    this.OeeEfficiencyValue = 0;
                    this.OeeQualityValue = 0;
                    this.OeeTotalValue = 0;
                }

                this.$refs.gaugeElement1.chart.update();
                this.$refs.gaugeElement2.chart.update();
                this.$refs.gaugeElement3.chart.update();
                this.$refs.gaugeElement4.chart.update();
            },
            cleanGaugeValue(value) {
                if(isNaN(value))
                    return 0;
                return Number(value)
            }
        },
    }
</script>

<style scoped>



</style>
