<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" grid-list-md text-xs-center pa-3 ma-0  class="page-container" style="max-width: 100%;">
        <v-card  class="mt-0 av-card" elevation="0">
            <v-card-text class="av-card-content"  style="max-height: calc(100vh + 70px)">
                <ConfigurationsEditor :descriptor="mailSettings" @value-Changed="valueChanged"></ConfigurationsEditor>
                <hr style="height: 1px; background-color: black; width: 100%" class="mt-4"/>

                <v-layout align-start justify-start column class="pa-4">
                    <label class="headline font-weight-bold"><translate>Configuration test</translate></label>
                    <v-layout row align-start justify-start mt-4 ml-5 :style="'width: 90%'">
                        <v-flex xs8 style="text-align: left; width: 90%">
                            <v-layout row="false" column="true" style="width: 100%">
                                <v-layout row>
                                    <label class="title"><translate>Receiver</translate></label>
                                </v-layout>
                                <div style="padding-left: 20px; padding-top: 16px">
                                    <v-text-field class="osk" single-line :placeholder="$gettext('Type here')" style="width: 400px" v-model="receiverMail"/>
                                </div>
                            </v-layout>
                            <av-button :text="$gettext('Send')" iconColor="light" color="info" id="testMail"
                                       buttonIcon="far fa-envelope" class="layout align-start justify-start column mt-3" @click="sendEmail"/>
                        </v-flex>
                    </v-layout>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
import Rules from "@/api/rules";

export default {
        name: "MailSettings",
        components: {
            ConfigurationsEditor
        },
        data() {
            return {
                mailSettings: {
                    parametersConfiguration: {
                        visible: true,
                        groupName: this.$gettext("Mail settings"),
                        settings: {
                            sender: {
                                name: this.$gettext("Sender"),
                                value: "",
                                description: "",
                                type: "string",
                                readOnly: false,
                            },
                            server: {
                                name: this.$gettext("Server"),
                                value: "",
                                description: "",
                                type: "string",
                                readOnly: false,
                            },
                            port: {
                                name: this.$gettext("Port"),
                                value: 2525,
                                description: "",
                                type: "number",
                                readOnly: false,
                            },
                            login: {
                                name: this.$gettext("Login"),
                                value: "",
                                description: "",
                                type: "string",
                                readOnly: false,
                            },
                            password: {
                                name: this.$gettext("Password"),
                                value: "",
                                description: "",
                                type: "password",
                                readOnly: false,
                            },

                        }
                    }
                },
                encryptedFields: ["password"],
                actionTrail: "",
                actionTrailId: "",
                nextValTrail: "",
                previousValTrail: "",
                receiverMail: "",
            }
        },
        mounted: function() {
            this.$root.setCurrentPageTitle(this.$gettext("Mail settings"));
            this.loadSettings();
        },

        methods: {
            loadSettings(){
                let self = this;
                self.$settings.load(self.$settings.MailSettings)
                    .then(result => {
                        self.settingsValues = result;
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(()=>{
                        if (Object.isUseful(self.settingsValues)){
                            self.mergeSetting(self.mailSettings.parametersConfiguration.settings.sender, self.settingsValues.sender);
                            self.mergeSetting(self.mailSettings.parametersConfiguration.settings.server, self.settingsValues.server);
                            self.mergeSetting(self.mailSettings.parametersConfiguration.settings.port, parseInt(self.settingsValues.port));
                            self.mergeSetting(self.mailSettings.parametersConfiguration.settings.login, self.settingsValues.login);
                            self.mergeSetting(self.mailSettings.parametersConfiguration.settings.password, self.settingsValues.password);
                        }
                    });
            },

            async valueChanged(groupName,settingName,action) {
                let self = this;
                if (this.settingsValues === null)
                    this.settingsValues = {};

                this.actionTrail = this.$audits.items().mailConfiguration;
                this.actionTrailId = settingName;
                this.previousValTrail = action.type !== 'password' ? this.settingsValues[settingName] : '';
                this.nextValTrail = action.type !== 'password' ?  this.mailSettings.parametersConfiguration.settings[settingName].value : '';

                this.settingsValues.sender = this.mailSettings.parametersConfiguration.settings.sender.value;
                this.settingsValues.server = this.mailSettings.parametersConfiguration.settings.server.value;
                this.settingsValues.port = this.mailSettings.parametersConfiguration.settings.port.value;
                this.settingsValues.login = this.mailSettings.parametersConfiguration.settings.login.value;
                this.settingsValues.password = this.mailSettings.parametersConfiguration.settings.password.value;
                this.settingsValues.encryptedFields = this.encryptedFields;


                await this.$settings.saveMailSettings(this.settingsValues)
                    .then(() => {
                        self.$audits.save(self.$root.userName, self.actionTrail, self.previousValTrail, self.nextValTrail, self.actionTrailId)
                            .catch(err => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .catch(err => {
                        debugger
                        this.$root.showErrorNotification(this.$gettext("An error occurred while saving settings to DB"), true);
                        this.loadSettings();
                    })
            },

            mergeSetting(target, source) {
                if(Object.isUseful(source))
                    target.value = source;
            },

            sendEmail() {
                let self = this;
                if (self.receiverMail !== "") {
                    let settingsCopy = { ...self.mailSettings.parametersConfiguration.settings, receiver: { name: "Receiver", value: self.receiverMail, description: "", type: "string", readOnly: false} }
                    settingsCopy.port.value = settingsCopy.port.value.toString();
                    return new Promise((resolve) => {
                        Rules.testMailConfiguration(settingsCopy)
                            .then(() => {
                                self.$root.showInfoNotification(self.$gettext("Mail sent successfully"), true, true);
                                resolve(true);
                            })
                            .catch(error => {
                                debugger
                                console.log(error);
                                self.$root.showErrorNotification(self.$gettext("An error occurred during operation. Error: {0}").format(error.Message), true, true);
                                resolve(false)
                            })
                    });
                } else {
                    self.$root.showErrorNotification(self.$gettext("Receiver field must not be empty."), true, true);
                }
            },
        },
    }
</script>

<style scoped>

</style>