import Api from '@/api/api'
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import VueInstance from "./vueinstance";
import Vue from 'vue'

export default {


    variableTypes:[
        {
            type:"boolean",
            fieldType:"boolean",
            min:0,
            max:1,
        },
        {
            type:"bool",
            fieldType:"boolean",
            min:0,
            max:1,
        },
        {
            type:"sByte",
            fieldType:"number",
            min:-128,
            max:127,
        },
        {
            type:"byte",
            fieldType:"number",
            min:0,
            max:255,
        },
        {
            type:"int16",
            fieldType:"number",
            min:-32768,
            max:32767,
        },
        {
            type:"uint16",
            fieldType:"number",
            min:0,
            max:32767,
        },
        {
            type:"int32",
            fieldType:"number",
            min:-2147483648,
            max:2147483647,
        },
        {
            type:"uint32",
            fieldType:"number",
            min:0,
            max:2147483647,
        },
        {
            type:"int64",
            fieldType:"number",
            min:-223372036854775808,
            max:9223372036854775807,
        },
        {
            type:"uint64",
            fieldType:"number",
            min:0,
            max:9223372036854775807,
        },
        {
            type:"float",
            fieldType:"text-number",
            min:-2147483648,
            max:2147483647,
        },
        {
            type:"double",
            fieldType:"number",
            min:-223372036854775808,
            max:9223372036854775807,
        },
        {
            type:"string",
            fieldType:"text",
            min:0,
            max:100,
        },

    ],
    modbusVariableTypes:[
        {
            type:"bit",
            fieldType:"number",
            min:0,
            max:65535,
        },
        {
            type:"int16",
            fieldType:"number",
            min:-32768,
            max:32767,
        },
        {
            type:"uint16",
            fieldType:"number",
            min:0,
            max:65535,
        },
        {
            type:"int32",
            fieldType:"number",
            min:-2147483648,
            max:2147483647,
        },
        {
            type:"uint32",
            fieldType:"number",
            min:0,
            max:4294967295,
        },
        {
            type:"int64",
            fieldType:"number",
            min:-223372036854775808,
            max:9223372036854775807,
        },
        {
            type:"uint64",
            fieldType:"number",
            min:0,
            max:18446744073709551615,
        },
        {
            type:"float32",
            fieldType:"text-number",
            min:-2147483648.00,
            max:2147483647.00,
        },
        {
            type:"float32-ieee",
            fieldType:"text-number",
            min:-2147483648.00,
            max:2147483647.00,
        },
        {
            type:"string",
            fieldType:"text",
            min:0,
            max:100,
        }
    ],

    async getRecipesId() {
        return new Promise((resolve,reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders + "/recipes")
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    console.log(response);
                    reject(VueInstance.get().$gettext("Unable to find recipes"));
                });
    });
    },

    async getRecipes(type = "template", onlyDeployed = false) {
        let recipes = await this.getFullRecipes(type, onlyDeployed)
        if (Array.isUseful(recipes)) {
            return recipes.map(descriptor => {
                return {
                    name: descriptor.properties.name,
                    recipe: descriptor.recipe
                }
            })
        }
    },

    getFullRecipes(type='template', onlyDeployed = false) {
        let vType = 0;
        switch (type) {
            case "template":
                vType = Vue.prototype.$dynamicElements.Types.TemplateRecipe;
                break;
            case "machine":
                vType = Vue.prototype.$dynamicElements.Types.MachineRecipe;
                break;
            case "line":
                vType = Vue.prototype.$dynamicElements.Types.LineRecipe;
                break;
        }
        return new Promise((resolve, reject) => {
            let deployStatus = -2
            if (onlyDeployed)
                deployStatus = 0
            Vue.prototype.$dynamicElements.LoadItems('recipes', false, false, true)
                .then(result => {
                    resolve(result
                        .filter(descriptor => {
                            return descriptor.properties.type === vType && descriptor.properties.deployStatus > deployStatus
                        }))
                })
                .catch(error => {
                    reject(error)
                    console.log(error);
                    this.$root.showErrorNotification(this.$gettext("Error in retrieving saved visualizations from DB."), true);
                })
        });
    },

    async getById(Id){
        return new Promise((resolve,reject)=>{
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.recipes + '/' + Id + '/full' )
                .then(response => {
                    let recipe=null
                    if(Object.isUseful(JSON.parse(atob(response)))){
                        recipe=JSON.parse(atob(response))
                        resolve(recipe);
                    }
                })
                .catch(err => {
                    debugger
                    if(Microservices.isIndexEmptyError(err))
                        resolve([]);
                    else reject(VueInstance.get().$gettext("Unable to load recipe {0} ").format(Id));
                })
        });
    },

    async getTargetMachinesFromTemplate(templateName){
        let templates=await this.getFullRecipes()
        return templates.find(t=>{ return t.properties.name===templateName})? templates.find(t=>{ return t.properties.name===templateName}).properties.targetMachine : []
    },

    async getAvailableRecipesByMachines(machines, onlyDeployed=false) {
        let recipes = [];
        let machineRecipes = await this.getFullRecipes("machine", onlyDeployed)
        if (Array.isUseful(machineRecipes)) {
            machineRecipes.forEach(mr => {
                if (Array.isUseful(mr.properties.targetMachine)) {
                    if (mr.properties.targetMachine.find(m => {
                        return machines.indexOf(m.device) > -1
                    })) {
                        recipes.push(mr.properties.name)
                    }
                }
            })
        }
        //take template as default recipes
        let templateRecipes = await this.getFullRecipes("template", onlyDeployed);
        if (Array.isUseful(templateRecipes)) {
            templateRecipes.forEach(tr => {
                if (tr.properties.useAsDefaultRecipe && tr.properties.targetMachine.find(tm => {
                    return machines.indexOf(tm.device) > -1
                })) {
                    recipes.push(tr.properties.name)
                }
            })
        }
        return recipes;
    },

    unwrapRecipeSteps(steps){
        let variables=[];
        let self=this;
        steps.forEach(step=>{
            switch (step.type) {
                case "opcwrite":
                case "opcread":
                case "systemread":
                case "opcmethodcall":
                case "modbuswrite":
                case "modbusread":
                    let variable={
                        title:step.name,
                        name:'',
                        description:step.description,
                        type: 'group',
                        inputMode:'Normal',
                        children:[]
                    }
                    step.configuration.values.forEach(value=>{
                        let child={};
                        if (step.type!=='opcmethodcall'){
                            if (value.assignMode === "assignvalue" && value.restriction!=="fixed") {
                                child = this.unwrapVariable(step,value)
                            }
                            if(step.configuration.values.filter(v=>{return v.restriction!=='fixed' && v.assignMode==='assignvalue'}).length>=1 && child.name){
                                variable.children.push(child)
                            }else if(child.name){
                                variables.push(child)
                            }
                        }else{
                            value.value.forEach(v=>{
                                if (v.assignMode === "assignvalue" && v.restriction!=="fixed") {
                                    child = this.unwrapVariable(step,v)
                                }
                                if(value.value.filter(vv=>{return vv.restriction!=='fixed' && vv.assignMode==='assignvalue'}).length>=1 && child.name){
                                    variable.children.push(child)
                                }else if(child.name){
                                    variables.push(child)
                                }
                            })
                        }

                    });
                    if (step.type!=='opcmethodcall'){
                        if(Array.isUseful(step.configuration.values) && step.configuration.values.filter(v=>{return v.restriction!=='fixed' && v.assignMode==='assignvalue'}).length>=1 && Object.isUseful(variable)){
                            variables.push(variable);
                        }
                    }else{
                        if(Array.isUseful(step.configuration.values) && step.configuration.values.filter(v=>{return v.value.filter(vv=>{vv.restriction!=='fixed' && vv.assignMode==='assignvalue'})}).length>=1 && Object.isUseful(variable)){
                            variables.push(variable);
                        }
                    }

                    break;
                // case "delay":
                //     let delays={
                //         title:"Delays",
                //         name:'',
                //         description:step.description,
                //         type: 'group',
                //         inputMode:'Normal',
                //         children:[]
                //     }
                //     step.configuration.values.forEach(delay=>{
                //         let child= {
                //             title:delay.flows.length>0?delay.flows.map(f=>{return f.Id}).join('-'):"Delay",
                //             name:delay.id,
                //             type: 'text-number',
                //             minChoices: delay.delay,
                //             maxChoices: delay.delay,
                //             min: delay.delay,
                //             max: delay.delay,
                //             value:delay.delay,
                //             inputMode:"Normal",
                //             children:[],
                //             readonly:true
                //         };
                //         delays.children.push(child)
                //     })
                //     variables.push(delays)
                //
                //     break;
            }
        })
        return variables;
    },
    unwrapVariable(step,value){
        let t=this.variableTypes.find(t=>{ return t.type === step.configuration.dataType});
        let child = {
                title: step.type !=='opcmethodcall'? (step.configuration.values.filter(v => {
                    return v.restriction !== 'fixed' && v.assignMode==='assignvalue'
                }).length > 1 ? value.flows.map(f=>{return f.Id}).join('-') : step.name):value.label,
                name:  value.id ,
                type: t && t.fieldType ? t.fieldType : 'text',
                minChoices: step.type !=='opcmethodcall'?step.configuration.min:value.min,
                maxChoices: step.type !=='opcmethodcall'?step.configuration.max:value.max,
                min: step.type !=='opcmethodcall'?step.configuration.min:value.min,
                max: step.type !=='opcmethodcall'?step.configuration.max:value.max,
                value: value.value,
                inputMode: 'Normal',
                children: [],
                mandatory: value.restriction === 'mustbeprovided',
                mustMatch: true,
            };
            return child;
        }
    ,
    wrapVariables(variables,template){
        if (!template){
            return
        }
        let _recipe=[];
        let steps=[];
        if(Array.isUseful(template)){
            steps=template;
        }
        if(Array.isUseful(template.recipe)){
            steps=template.recipe;
        }
        steps.forEach(step=>{
            let value={};
            switch (step.type) {
                case "opcwrite":
                case "opcread":
                case "systemread":
                case "modbuswrite":
                case "modbusread":
                    step.configuration.values.forEach(v=>{
                        if(v.restriction==='fixed'){
                            value={
                                id:v.id,
                                value:v.value,
                            }
                        }else{
                            switch (v.assignMode) {
                                case "systemvariable":
                                    value={
                                        id:v.id,
                                        value:v.value,
                                    }
                                    break;
                                case "applyfromvariable":
                                    let step=steps.find(s=>{return s.name===v.value })
                                    if (v.flows.length>0){
                                        v.flows.forEach(s=>{
                                            let valuesToAdd=step.configuration.values.filter(v=>{return v.flows.map(f=>{return f.Id}).indexOf(s.Id)>-1})
                                            valuesToAdd.forEach(vta=>{
                                                if(!_recipe.find(_v=>{return _v.id===v.id && _v.value===vta.value})) {
                                                    _recipe.push({
                                                        id:v.id,
                                                        value:vta.value
                                                    })
                                                }
                                            })
                                        })
                                    }else {
                                        let valuesToAdd = step.configuration.values.filter(v => {
                                            return v.flows.length === 0
                                        })
                                        valuesToAdd.forEach(vta => {
                                            if (!_recipe.find(_v => {
                                                return _v.id === v.id && _v.value === vta.value
                                            })) {
                                                _recipe.push({
                                                    id: v.id,
                                                    value: vta.value
                                                })
                                            }
                                        })
                                    }

                                    break;
                                 default:
                                     let variable=this.findVariable(v.id,variables)
                                     if (Object.isUseful(variable)){
                                         value={
                                             id:v.id,
                                             value:variable.value,
                                         }
                                     }
                                     break;

                            }

                        }
                        if(Object.isUseful(value) && value.id){
                            _recipe.push(value);
                        }
                    })
                    break;
                case "opcmethodcall":

                    step.configuration.values.forEach(v=>{
                        value={
                            id:v.id,
                            value:[],
                        }
                        v.value.forEach(vv=>{
                            if(vv.restriction==='fixed'){
                                value.value.push({
                                    id:vv.id,
                                    value:vv.value
                                })
                            }else{
                                switch (vv.assignMode) {
                                    case "systemvariable":
                                        value.value.push({
                                            id:vv.id,
                                            value:vv.value
                                        })
                                        break;
                                    case "applyfromvariable":
                                        let step=steps.find(s=>{return s.name===vv.value })
                                        if (v.flows.length>0){
                                            v.flows.forEach(s=>{
                                                let valuesToAdd=step.configuration.values.filter(v=>{return v.flows.map(f=>{return f.Id}).indexOf(s.Id)>-1})
                                                valuesToAdd.forEach(vta=>{
                                                    if(!_recipe.find(_v=>{return _v.id===vv.id && _v.value===vta.value})) {
                                                        value.value.push({
                                                            id:vv.id,
                                                            value:vta.value
                                                        })
                                                    }
                                                })
                                            })
                                        }else{
                                            let valuesToAdd=step.configuration.values.filter(v=>{return v.flows.length===0})
                                            valuesToAdd.forEach(vta=>{
                                                if(!_recipe.find(_v=>{return _v.id===vv.id && _v.value===vta.value})) {
                                                    value.value.push({
                                                        id:vv.id,
                                                        value:vta.value
                                                    })
                                                }
                                            })
                                        }
                                        break;
                                    default:
                                        let variable=this.findVariable(vv.id,variables)
                                        if (Object.isUseful(variable)){
                                            value.value.push({
                                                id:vv.id,
                                                value:variable.value
                                            })
                                        }
                                        break;

                                }

                            }
                        })

                        if(Object.isUseful(value) && value.id){
                            _recipe.push(value);
                        }
                    })

                    break;
                case "delay":
                    step.configuration.values.forEach(delay=>{
                        value={
                            id:delay.id,
                            value:delay.delay
                        };
                        _recipe.push(value);
                    })
                    break;
            }
        })
        return _recipe;
    },
    findVariable(id,variables){
        let find=undefined
        for(let i=0;i<variables.length;i++){
            if(variables[i].name===id){
                find = variables[i]
            }
            if(!find && Array.isUseful(variables[i].children)){
                find = this.findVariable(id,variables[i].children)
            }
        }
        return find
    },
    async unwrapMachineRecipe(recipe,template){
        if(!Array.isUseful(template.recipe)){
            return
        }
        template.recipe.forEach(step=>{
            switch (step.type) {
                case "opcwrite":
                case "opcread":
                case "modbuswrite":
                case "modbusread":
                    step.configuration.values.forEach(v=>{
                        let value=recipe.find(val=>{return val.id===v.id})
                        if(value){
                            v.value=value.value;
                        }
                    });
                    break;
                case "delay":
                    let value=recipe.find(val=>{return val.id===step.configuration.id});
                    if(value){
                        step.configuration.delay=value.value;
                    }
                    break;
                case "opcmethodcall":
                    step.configuration.values.forEach(v=>{
                        let value=recipe.find(val=>{return val.id===v.id})
                        if(value){
                            v.value.forEach((vv,i)=>{
                                let find=value.value.find(tv=>{return tv.id=== vv.id })
                                if (find){
                                    vv.value=find.value
                                }

                            });
                        }
                    });
            }
        });
        return this.unwrapRecipeSteps(template.recipe)
    }

}
