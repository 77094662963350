<template>
    <DynamicElementBase>
        <v-stepper v-model="currentStep" style="overflow-y: auto; height: 100%">
            <v-stepper-header>
                <template v-for="(step, key) in steps">
                    <v-stepper-step :key="key" :complete="currentStep > key + 1" :step="key + 1" color="info"> {{ step.name }} </v-stepper-step>
                    <v-divider :key="`${key}-divider`" v-if="key < steps.length - 1"></v-divider>
                </template>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content :step="currentStep">
                    <div ref="cardContainer"></div>
                    <v-btn color="info" @click="continueClick"> {{ $gettext("Continue") }}</v-btn>
                    <v-btn color="error" @click="backClick"> {{ $gettext("Back") }}</v-btn>
                    <v-btn flat>Cancel</v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </DynamicElementBase>
</template>

<script>
    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue';

    export default {
        name: "WizardBase",
        extends: DynamicElementBase,
        components:{ DynamicElementBase },
        data:()=>{
            return {
                currentStep: 1,
                e1: 0
            }
        },
        computed: {
            steps() {
                if (Array.isUseful(this.scope().wizard.steps))
                    return this.scope().wizard.steps
                else return []
            },
            currentStepItem() {
                return this.steps[this.currentStep - 1];
            },
        },
        props: {
        },
        async mounted() {

        },
        watch: {
            currentStepItem() {
                let self = this;
                if (this.currentStepItem && this.currentStepItem.type === 'form') {
                    this.$dynamicElements.LoadItem(this.currentStepItem.configuration.name, "forms")
                        .then(t => {
                            let latestDescriptor = JSON.parse(t.descriptor);
                            self.drawForm(latestDescriptor);
                        })
                }
            },
        },
        methods: {
            continueClick() {
                this.currentStep++;
            },
            backClick() {
                this.currentStep--;
            },
            drawForm(descriptor) {
                let currentFormType = descriptor.properties.type;

                if (this.currentForm) {
                    this.currentForm.$destroy();
                    this.$refs.cardContainer.removeChild(this.currentForm.$el);
                    this.currentForm = null;
                }

                this.$nextTick(() => {
                    //Create wizard
                    this.currentForm = this.$dynamicElements.createNew(currentFormType, false);
                    if(!this.currentForm)
                        return; //License issue or huge error (unlikely)
                    this.currentForm.load(descriptor);
                    this.currentForm.properties.showTitleBar = false;
                    // if (this.$refs.cardContainer.hasChildNodes())
                    //     this.$refs.cardContainer.replaceChild(this.currentForm.$el, this.$refs.cardContainer.firstChild)
                    // else
                    this.$refs.cardContainer.insertBefore(this.currentForm.$el, this.$refs.cardContainer.firstChild);
                });
            }
        }

    }
</script>

<style scoped>

</style>