import { render, staticRenderFns } from "./WizardBase.vue?vue&type=template&id=455f3e5e&scoped=true&"
import script from "./WizardBase.vue?vue&type=script&lang=js&"
export * from "./WizardBase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "455f3e5e",
  null
  
)

export default component.exports