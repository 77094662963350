<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer"  class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card class="av-card" elevation="0">
            <v-card-text >
                <div class="table-container">
                    <table class="v-datatable v-table theme--light content" style="width: 100%;">
                        <thead>
                        <tr>
                            <th class="column text-md-left text-sm-left" @click="sort('Name')" style="font-size: 1.5em;"
                                :id="computedSortByNameId + 'SortByName'"><translate>Name</translate>
                                <av-icon small>{{ nameSort ? "fa-sort-down" : "fa-sort-up" }}</av-icon>
                            </th>
                            <th class="column text-md-left text-sm-left" style="font-size: 1.5em;"><translate>Timestamp</translate></th>
                            <th class="column text-md-left text-sm-left" style="font-size: 1.5em;"><translate>Expiration</translate></th>
                            <th class="column text-md-left text-sm-left text-xs-left" style="font-size: 1.5em;"><translate>Actions</translate></th>
                        </tr>
                        </thead>
                        <tbody id="entitiesTable">
                        <tr v-for="(productionDataInstance,idx) in sorted" :key="idx" style="height: 40px">
                            <td class="text-md-left subheading" :style="styleCell">
                                {{ instanceName(productionDataInstance) }}
                            </td>
                            <td class="text-md-left subheading" :style="styleCell">
                                {{ (new Date(productionDataInstance["@timestamp"])).format() }}
                            </td>
                            <td class="text-md-left subheading" :style="styleCell">
                                <div v-if="productionDataInstance.Validity.Mode === 'time'">
                                    {{ productionDataInstance.Validity.Value }} seconds
                                </div>
                                <div v-else-if="productionDataInstance.Validity.Mode === 'workorder'">
                                    Current workorder
                                </div>
                                <div v-else-if="productionDataInstance.Validity.Mode === 'once'">
                                    Once
                                </div>
                                <div v-else>
                                    N/A
                                </div>
                            </td>
                            <td class="text-md-left text-xs-left" :style="styleCell">
                                <av-button :text="''" flat icon @click="productionDataDetails(productionDataInstance)" size="medium" title="View details"
                                           buttonIcon="fa-list" iconColor="blue"/>
                                <av-button :text="$gettext('')" flat icon @click="editProductionData(productionDataInstance)" size="medium"
                                           title="Edit entity" buttonIcon="fa-pen" iconColor="blue" :disabled="productionDataInstance.Source === 'integration'" />
                                <av-button :text="$gettext('')" flat icon @click="deleteProductionData(idx, productionDataInstance.id)" size="medium"
                                           title="Delete entity" buttonIcon="fa-trash" iconColor="red" />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import Entity from "@/api/entities";
    import FormBase from "@/components/dynamic-elements/forms/FormBase";

    export default {
        name: "productionInstances",
        components: {FormBase},
        data() {
            return {
                productionData: [],
                headers: [],
                currentSort: 'name',
                currentSortDir: 'asc',
                nameSort: true,
                statusSort: true
            }
        },
        computed: {

            computedSortByNameId() {
                return this.toCamelCase(this.$router.history.current.name + 'Table')
            },
            computedSortByStatusId() {
                return this.toCamelCase(this.$router.history.current.name + 'Table')
            },
            sorted: function () {
                return Array.isUseful(this.productionData) ? this.productionData.sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc')
                        modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return modifier;

                    return 0;
                }):[];
            },

        },
        async mounted() {
            await this.waitForPageLoading();
            this.loadData();
            this.$root.setCurrentPageTitle(this.$gettext("Active production data"));
        },
        methods: {
            instanceName(instance) {
                if(instance.Source === "integration")
                    return instance.Id;
                return instance.Name
            },
            sort: function (s) {
                if (s === "Name")
                    this.nameSort = !this.nameSort;
                else if (s === "Status")
                    this.statusSort = !this.statusSort;

                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                }
                this.currentSort = s;
            },
            styleCell(index) {
                if (index === 0) {
                    return "min-width: 200px";
                } else return {
                    border: this.type === "custom" ? "1px solid #eee" : "",
                }
            },
            loadData() {
                this.productionData = []
                let self = this;

                this.$dataEntry.getProductionData()
                    .then(result => {
                        result = result.filter(item => { return item.target !== 2 })
                        self.productionData = result;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving saved visualizations from DB."), true);
                    })
                    .finally(() => {
                        self.$root.setLoading(false)
                    })
            },
            productionDataDetails(data) {
                this.$router.push({name: 'entityDetails', params: {entity: data, backPath:{name: 'productionInstances'}}})
            },
            deleteProductionData(index, id) {
                let self = this;
                this.$root.showDialogBox(this.$gettext("Are you sure you want to delete entity: {0}?").format(this.productionData[index].Name), null, "Yes", function () {
                    Entity.deleteProductionDataInstance(id)
                        .then(() => {
                            self.productionData.removeAt(index);
                            setTimeout(function(){
                                self.loadData()
                            }, 2000);
                        })
                        .catch(err => {
                            self.$root.showErrorNotification(err, true, false);
                        })
                }, "No", null);
            },
            editProductionData(entity) {
                this.$router.push({name: 'formsEdit', params: {routeId: entity.Name, entity: entity, backPath:{name: 'productionInstances'}}})
            },
        }
    }
</script>
