<template>
    <DynamicElementBase>
        <template>
            <v-layout row wrap justify-center v-for="prop in ruleProps">
                <av-text-field :placeholder="'Value'" :label="prop" v-model="ruleBody[prop]"
                               style="max-width: 200px; margin: 10px;">
                </av-text-field>
            </v-layout>
            <v-layout row wrap justify-center>
                <v-hover>
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 20 : 5}`"
                            :style="'overflow: hidden; width: {0}px; height: {1}px; text-align: center; margin: 10px; font-weight:400; background-color: {2}; {3}'
                            .format((200), (100), buttonBgColor, ($avStyle.whiteTextNeeded(buttonBgColor) ? 'color: white' : ''))"
                            @click="executeFunctionality()">
                        <v-card-text style="word-wrap: break-word; font-weight: 400; padding: 10px" :class="'subheading'">{{buttonTitle}}</v-card-text>
                    </v-card>
                </v-hover>
            </v-layout>
        </template>
    </DynamicElementBase>
</template>

<script>
import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
import RulesEngine from '@/api/rules.js';

export default {
    name: "FunctionButton",
    extends: DynamicElementBase,
    components: {DynamicElementBase},
    data () {
        return {
            btnFunctionalities: [this.$gettext("Open a form"), this.$gettext("Call a triggered RTE")],
            forms: [],
            ruleProps: [],
            ruleBody: {}
        }
    },
    computed: {
        buttonTitle() {
            return this.getTweakValue("btnTitle");
        },
        buttonBgColor() {
            return this.getTweakValue("btnColor");
        }
    },
    mounted() {
        this.dataExplorationMode.noDataExploration = true;
        this.properties.showTimeWindow = false;
        this.scope().childHandlers.checkItemUsefulness = () => { return true; };


        this.visualizationTweaks = [
            {
                name: this.$gettext("Button title"),
                id: "btnTitle",
                type: "string",
                default: function() {
                    return "Default title";
                },
            },
            {
                name: this.$gettext("Button functionality"),
                id: "btnFunctionality",
                type: "indexed_option",
                options: this.btnFunctionalities,
                default: function() {
                    return 0;
                }
            },
            {
                name: this.$gettext("Form to open"),
                id: "formToOpen",
                type: "indexed_option",
                options: this.forms,
                default: function() {
                    return 0;
                },
                hidden: false
            },
            {
                name: this.$gettext("Rule to execute"),
                id: "ruleToExecute",
                type: "string",
                default: function() {
                    return "";
                },
                hidden: true
            },
            {
                name: this.$gettext("Rule properties"),
                id: "ruleProps",
                type: "string",
                default: function() {
                    return "";
                },
                hidden: true
            },
            {
                name: this.$gettext("Success message"),
                id: "ruleSuccessMsg",
                type: "string",
                default: function() {
                    return "";
                },
                hidden: true
            },
            {
                name: this.$gettext("Error message"),
                id: "ruleErrorMsg",
                type: "string",
                default: function() {
                    return "";
                },
                hidden: true
            },
            {
                name: this.$gettext("Button color"),
                id: "btnColor",
                type: "color",
                default: function() {
                    return "#3C63AD";
                },
            },
        ]

        if (this.$license.hasQuality() || this.$license.hasMaintenance())
            this.$dynamicElements.getMetadataList("forms", true,false,false,true)
                .then((forms) => {
                    this.forms = forms.ungrouped.map(f => f.name);
                    this.getTweak("formToOpen").options = this.forms;
                });

        this.validators.insertItem(2, {
            onDeploy: true,
            onChange: false,
            validator: this.checkRuleNameEmpty
        });
    },
    watch: {
        visualizationTweaks: {
            handler: function () {
                let formToOpenTweak = this.getTweak("formToOpen")
                let ruleToExecute = this.getTweak("ruleToExecute")
                let ruleProps = this.getTweak("ruleProps")
                let ruleSuccessMsg = this.getTweak("ruleSuccessMsg")
                let ruleErrorMsg = this.getTweak("ruleErrorMsg")

                let btnFunctionality = this.getTweakValue("btnFunctionality")
                switch (btnFunctionality) {
                    case 0: {
                        formToOpenTweak.hidden = false;
                        ruleToExecute.hidden = true;
                        ruleProps.hidden = true;
                        ruleSuccessMsg.hidden = true;
                        ruleErrorMsg.hidden = true;
                        this.ruleProps.clear();
                        this.ruleBody = {};
                        break;
                    }
                    case 1: {
                        this.ruleProps.clear();
                        this.ruleBody = {};
                        formToOpenTweak.hidden = true;
                        ruleToExecute.hidden = false;
                        ruleProps.hidden = false;
                        ruleSuccessMsg.hidden = false;
                        ruleErrorMsg.hidden = false;
                        if (ruleProps.value.includes(";")) {
                            this.ruleProps = ruleProps.value.split(";")
                        }
                        else if (ruleProps.value !== "") {
                            this.ruleProps.push(ruleProps.value)
                        }
                        break;
                    }
                }
                this.saveTweaks();
            },
            deep: true,
        },
    },
    methods: {
        executeFunctionality() {
            let btnFunctionality = this.getTweakValue("btnFunctionality")
            switch (btnFunctionality) {
                case 0: {
                    let form = this.getTweak("formToOpen").options[this.getTweakValue("formToOpen")]
                    this.$router.push({ name: 'forms', params: { routeId: form, actionId: form } })
                    break;
                }
                case 1: {
                    return new Promise((resolve, reject) => {
                        RulesEngine.callApiRule(this.getTweakValue("ruleToExecute"), this.ruleBody)
                            .then((result) => {
                                let successMsg = this.getTweakValue("ruleSuccessMsg")
                                this.$root.showInfoNotification(successMsg, true);
                                resolve()
                            })
                            .catch(err => {
                                let errorMsg = this.getTweakValue("ruleErrorMsg")
                                this.$root.showErrorNotification(errorMsg, true, true);
                                console.log(err);
                                reject()
                            })
                    });
                }
            }
        },
        checkRuleNameEmpty() {
            let self = this;
            if (this.getTweakValue("btnFunctionality") === 1) {
                if (this.getTweakValue("ruleToExecute") === "") {
                    self.$root.showErrorNotification(self.$gettext("No rule name provided"), true, true);
                    return false;
                }
            }
            return true;
        }
    }
}
</script>

<style scoped>

</style>