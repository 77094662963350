<template>
    <v-dialog :value="hasMessages" max-width="640" :persistent="true" id="antares-messagebox-dialog">
        <av-notifications-queue :messages="messages"/>
    </v-dialog>
</template>

<script>
export default {
    name: 'MessageBox',
    props: {
        messages: {
            type: Array,
            default: () => []
        },
    },
    data () {
        return {}
    },
    computed : {
        hasMessages() {
            return Array.isUseful(this.messages);
        },
    }
}
</script>