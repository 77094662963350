var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DynamicElementBase',[_c('v-layout',{attrs:{"row":"","fill-height":""}},[_c('v-flex',{attrs:{"xs5":!_vm.showRanking,"xs6":_vm.showRanking}},[_c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('div',{staticStyle:{"position":"relative","width":"100%","height":"100%"}},[(_vm.loaded)?_c('BarChart',{ref:"graph",staticStyle:{"width":"100%","height":"100%"},attrs:{"chartdata":_vm.pareto.chartData,"options":_vm.options}}):_vm._e()],1)])],1),_c('v-flex',{attrs:{"xs7":!_vm.showRanking,"xs6":_vm.showRanking}},[_c('v-layout',{attrs:{"column":"","fill-height":"","ml-2":""}},[(!_vm.showRanking)?_c('div',{staticStyle:{"overflow":"auto","margin-top":"15px"}},[_c('v-treeview',{ref:"tree1",staticClass:"subheading font-weight-bold labelsHeadersStyle",attrs:{"items":_vm.standardTimeTracking,"item-text":"show"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [(item.show)?_c('label',{style:('color: ' + item.color)},[_vm._v(" "+_vm._s(item.show)+" ")]):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item.show)?_c('label',{staticClass:"v-treeview-node__label font-weight-regular labelsHeadersStyle"},[_vm._v(" : "+_vm._s(item.duration !== 0 ? _vm.formatItem(item) : "")+" ")]):_vm._e()]}}],null,false,1785687110)}),_c('v-treeview',{ref:"tree2",staticClass:"subheading font-weight-bold labelsHeadersStyle",attrs:{"items":_vm.customTimeTracking,"item-text":"show"},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(item.show && Array.isUseful(item.children))?_c('av-icon',[_vm._v("fa fa-chart-bar")]):_vm._e(),(item.show && Array.isUseful(item.children))?_c('av-icon',{staticStyle:{"margin-left":"2px"},on:{"click":function($event){return _vm.selectForGraph(item)}}},[_vm._v(" "+_vm._s(item.selectedForGraph ? 'far fa-check-square' : 'far fa-square')+" ")]):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(item.show)?_c('label',{style:('color: ' + item.color + ';' + (Array.isUseful(item.children) ? '' : 'margin-left: 37px;'))},[_vm._v(" "+_vm._s(item.show)+" ")]):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item.show)?_c('label',{staticClass:"v-treeview-node__label font-weight-regular labelsHeadersStyle"},[_vm._v(" : "+_vm._s(item.duration !== 0 ? _vm.formatItem(item) : "")+" ")]):_vm._e()]}}],null,false,3329218128)})],1):_c('div',{staticStyle:{"overflow":"auto","margin-top":"15px"}},[_c('v-layout',{attrs:{"column":""}},[_vm._l((_vm.ranking),function(item){return [_c('label',{staticClass:"subheading font-weight-medium"},[_c('span',{style:('color: ' + item.color + ';')},[_vm._v(_vm._s(item.category))]),_c('span',{staticClass:"labelsHeadersStyle font-weight-regular"},[_vm._v(": "+_vm._s(item.value !== 0 ? _vm.formatItem(item) : ""))])])]})],2)],1)])],1)],1),_c('template',{slot:"dropMenuItems"},[_c('v-list-tile',[_c('v-list-tile-content',[(!_vm.alwaysShowRanking && !_vm.alwaysShowSurvey)?_c('v-checkbox',{staticClass:"mt-2",attrs:{"label":"Show ranking"},on:{"change":_vm.buildPareto},model:{value:(_vm.showRanking),callback:function ($$v) {_vm.showRanking=$$v},expression:"showRanking"}}):_vm._e()],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }