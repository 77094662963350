import Api from '@/api/api'
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import backAnnotationDialog from '@/components/dialogs/BackAnnotationDialog'
import WorkorderBackAnnotationDialog from "@/components/dialogs/WorkorderBackAnnotationDialog";
import VueInstance from './vueinstance'
import DateTime from "./datetimeutils";
import Vue from 'vue'

export default {

    openBackAnnotationDialog(parent, user, notified = false) {
        //Create widget
        let componentClass = Vue.extend(backAnnotationDialog);
        let dialog = new componentClass();
        dialog.setVueInstance(VueInstance.get());
        dialog.$mount();
        dialog.setCaller(parent);
        let event = {notified: notified};
        dialog.Show(event, null, user);
    },

    openBackAnnotationWorkordersDialog(parent, user, callback) {
        //Create widget
        let componentClass = Vue.extend(WorkorderBackAnnotationDialog);
        let dialog = new componentClass();
        dialog.setVueInstance(VueInstance.get());
        dialog.$mount();
        //dialog.setCaller(parent);
        dialog.Show(user, callback);
    },

    // async annotateQuantities(values) {
    //
    //     return new Promise((resolve, reject) => {
    //
    //         Api().post('/updateDoc', values)
    //             .then(t => {
    //                 resolve(true);
    //             })
    //             .catch(t => {
    //                 console.log(t);
    //                 reject(VueInstance.get().$gettext("Error in changing quantities."));
    //             });
    //     });
    // },

    async annotateQuantities(start, stop, totals, rejects, operator) {

        if(!operator)
            operator = "";
        let annotations = [{
            Begin:     DateTime.getRfc3339TimeStamp(new Date(start)),
            End:       DateTime.getRfc3339TimeStamp(new Date(stop)),
            '@timestamp': DateTime.getRfc3339TimeStamp(new Date()),
            SubField: "ManualCounters",
            Status: "",
            Annotation: {
                TotalItems_Delta: totals,
                RejectedItems_Delta: rejects
            },
            tag: {
                Operator: operator,
            }
        }];
        return new Promise((resolve, reject) => {
            this.postBackAnnotations(annotations)
                .then(t => {
                    resolve(true);
                })
                .catch(t => {
                    console.log(t);
                    reject(VueInstance.get().$gettext("Error in changing quantities."));
                });
        })
    },

    async postBackAnnotations(annotations) {

        let backAnnotations = [];

        for (let annotation of annotations)
            backAnnotations.push(new Promise((resolve, reject) => {
                OrchestratorAPI.proxyCall('post', Microservices.backannotationUrl + '/write', annotation)
                    .then(() => {
                    resolve("ok")
                }).catch(error => {
                    reject(error)
                })
            }));

        return new Promise((resolve, reject) => {
            Promise.all(backAnnotations)
                .then(t => {
                    resolve(true);
                })
                .catch(t => {
                    console.log(t);
                    reject(VueInstance.get().$gettext("Error in changing time tracking."));
                });
        })
    },

}