<template>
    <v-tooltip bottom v-if="tooltipText">
        <v-btn v-bind="$attrs" v-on="$listeners" :class="tooltipClasses" :id="computedID" slot="activator" :loading="loading">
          <av-icon :left="text !== ''" :size="iconSize" :color="iconColor" :id="computedID + '-icon'" v-if="buttonIcon !== ''">{{buttonIcon}}</av-icon>
            <div :id="computedID + '-text'">
                {{textToPrint}}
            </div>
        </v-btn>
        <span style="font-size: 14px" v-if="tooltipText">{{tooltipText}}</span>
    </v-tooltip>
    <v-btn v-else v-bind="$attrs" v-on="$listeners" :class="noTooltipClasses" :id="computedID" slot="activator" :loading="loading">
        <av-icon :left="text !== ''" :size="iconSize" :color="iconColor" :id="computedID + '-icon'" v-if="buttonIcon !== ''">{{buttonIcon}}</av-icon>
        <div :id="computedID + '-text'">
            {{textToPrint}}
        </div>
    </v-btn>
</template>

<script>
    export default {
        name: 'av-btn',
        props: {
            text: { required: false, default: '' },
            id: { required: false },
            buttonIcon: { required: false, default: '' },
            iconColor: { required: false, default: '' },
            size: { required: false, default: '' },
            translate: { required: false, default: true },
            toolTip: { required: false, default: '' },
            disabledTooltip: { required: false, default: '' },
            dynamicID: { required: false, default: '' },
            tooltipClasses: { required: false, default: '' },
            noTooltipClasses: { required: false, default: '' },
            loading:{requied: false, default: false }
        },
        data () {
            return {}
        },
        computed: {
            computedID () {
                if (!this.$isEmpty(this.$attrs.id)) {
                    return this.$attrs.id
                }
                let dynamicText = this.text;
                if(!dynamicText)
                    dynamicText = this.toolTip;
                if(!dynamicText)
                    dynamicText = this.disabledTooltip;
                if(!dynamicText)
                    dynamicText = this.buttonIcon;
                if(dynamicText)
                    dynamicText = dynamicText.trim();
                else dynamicText = "";
                let dynamicPageName = this.$router.history.current.params.routeId ? this.$router.history.current.params.routeId : "";
                return this.toCamelCase(this.$router.history.current.name + dynamicPageName + this.dynamicID + 'Button' + dynamicText)
            },
            tooltipText () {
                if (this.$attrs.disabled)
                    return (this.disabledTooltip ? this.disabledTooltip : this.toolTip);
                else return this.toolTip
            },
            textToPrint () {
                return this.text
            },
            iconSize() {
                if(this.size)
                    return this.size;
                if(Object.isUseful(this.$attrs.small) && this.text)
                    return "small";
                return "";
            }
        },
        mounted () {

        },
        methods: {}
    }
</script>
