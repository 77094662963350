<template>
    <v-card height="100%" width="100%" :style="'overflow: ' + (touchLayout? 'hidden' : 'auto')">
        <!--Card title-->
        <v-toolbar card dense v-if="!noTitleBar">
            <v-toolbar-title class="headline text-xs-center">
                <av-icon v-if="notified" size="40" color="red" class="ma-0 pa-0 pr-2">fas fa-times-circle</av-icon>
                {{title}}
            </v-toolbar-title>
        </v-toolbar>
        <div v-else style="height: 10px" />
        <!-- First page only in case of stops categorization. Allows selection of stopped machine and stooping alarm-->
        <template v-if="showStopInfoPage">

            <div v-if="showStopTimeSpan && lineStops.length === 1" style="font-weight: 500; margin-right: 20px; margin-top: 5px" class="subheading ma-3">{{'Stop: ' + $timeTracking.formatStop(lineStops[0])}}</div>

            <!-- Stopping machine -->
            <v-layout ma-3 column>
                <v-layout row>
                    <div style="font-weight: 500; margin-right: 20px; margin-top: 5px" class="subheading" v-translate>Stopping machine:</div>
                    <v-select style="margin-top: 0px" max-width="300px" :placeholder="lbStoppedMachine" class="subheading" :items="machines" v-model="stoppedMachine" :menu-props="{closeOnContentClick: true}">
                        <template v-slot:prepend-item>
                            <v-list-tile @click="stoppedMachine = initialStoppedMachine">
                                <v-list-tile-action>
                                    <av-icon>fas fa-robot</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>{{initialStoppedMachineAlias}}</v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-select>
                </v-layout>
                <!-- warn user in case of manual selection different from system selected one -->
                <v-layout row ml-4 v-if="initialStoppedMachine && (initialStoppedMachine !== stoppedMachine)">
                    <av-icon color="orange" size="30" style="margin-top:-15px; padding-right:20px">fas fa-exclamation-triangle</av-icon>
                    <p  class="body-1" style="color: var(--av-orange)">{{$gettext("According to {appName} line stop was caused by: {machine}. Do not change it unless you are really sure.").format({appName: $config.appName, machine: initialStoppedMachineAlias})}}</p>
                </v-layout>
            </v-layout>

            <v-spacer />

            <!-- Stopping alarm -->
            <v-layout ma-3 column>
                <!-- Alarms header -->
                <div style="font-weight: 500" class="subheading"><span style="color:red"><translate>Alarms</translate></span> <translate>on stop</translate> ({{alarmsCount > 0 ? alarmsCount : this.$gettext("No alarms found")}}) </div>
                <v-checkbox style="margin: 20px" v-if="alarmsCount > 0" v-model="noAlarmSelected" :label="$gettext('No suitable alarm found for stop categorization')" />
                <!-- Alarms lists -->
                <v-radio-group class="radio-group-full-size" v-if="!noAlarmSelected" :value="selectedDefaultItem" style="width: 100%; margin-top: -15px">
                    <v-list nav dense two-line style="padding-top: 0; direction: rtl; overflow: auto; text-overflow: ellipsis; width:100%; max-width:800px; height: 310px ">
                        <v-list-group v-for="item in alarms" :key="item.group" v-model="item.active" no-action>
                            <template v-slot:activator>
                                <v-list-tile>
                                    <v-list-tile-title>{{groupName(item)}}</v-list-tile-title>
                                </v-list-tile>
                            </template>
                            <v-list-tile v-for="(alarm) in item.items" style="direction: ltr;">
                                <v-list-tile-action style="margin-left: -25px;">
                                    <v-radio :key="alarm.index" label="" :value="alarm.index" @change="selectedItemChanged(alarm)"/>
                                </v-list-tile-action>
                                <v-list-tile-content style="overflow-x: hidden; text-overflow: ellipsis; margin-left: -20px">
                                    <v-list-tile-title class="subheading"><span style="text-transform: uppercase;">{{alarm.device}}</span>: <span class="body-1">{{alarm.alarmIdentifier}} {{alarm.alarmDescription}}</span></v-list-tile-title>
                                    <v-list-tile-sub-title class="body-1">Stop cause: {{$timeTracking.formatTimeTracker(alarm.stopCause)}}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list-group>
                    </v-list>
                </v-radio-group>
            </v-layout>
        </template>
        <!-- Second page. choose stop cause -->
        <template v-if="showTimeTrackingPicker">
            <template v-if="showStopInfoSummary">
                <v-layout column ma-3 pb-3 style="overflow: hidden">
                    <label v-if="showStopTimeSpan && lineStops.length === 1" style="margin-bottom: 10px" class="title font-weight-regular">Stop: <span class="font-weight-medium">{{$timeTracking.formatStop(lineStops[0])}}</span></label>
                    <label style="margin-bottom: 10px" class="title font-weight-regular">Stopped machine: <span class="font-weight-medium">{{stoppedMachine ? stoppedMachineAlias : "Unknown"}}</span></label>
                    <label style="margin-bottom: 10px" class="title font-weight-regular">Stopping alarm: <span class="font-weight-medium">{{stoppingAlarmInfo ? stoppingAlarmInfo.alarmDescription : "No alarm"}}</span></label>
                    <v-divider />
                    <label style="margin-bottom: 10px; margin-top: 20px" class="title font-weight-regular">Select stop root cause: <span class="font-weight-medium">{{currentTimeTrackerTitle}}</span></label>
                </v-layout>
            </template>
            <v-layout column px-3 py-1 v-if="!touchLayout">
                <v-spacer />
                <v-spacer />
                <v-select solo max-width="600" :placeholder="lbCategory" class="title" :items="annotationCauses" item-text="show" item-value="index" v-model="annotationCause" />
                <v-spacer />
                <v-select v-if="annotationFirstSubCauses.length" solo max-width="600" :placeholder="lbFirstSubCategory" class="title" :items="annotationFirstSubCauses" item-text="show" item-value="index" v-model="annotationFirstSubCause" />
                <v-spacer />
                <v-select v-if="annotationSecondSubCauses.length" solo max-width="600" :placeholder="lbSecondSubCategory" class="title" :items="annotationSecondSubCauses" item-text="show" item-value="index" v-model="annotationSecondSubCause" />
                <v-spacer />
                <v-select v-if="annotationThirdSubCauses.length" solo max-width="600" :placeholder="lbThirdSubCategory" class="title" :items="annotationThirdSubCauses" item-text="show" item-value="index" v-model="annotationThirdSubCause" />
            </v-layout>
            <v-flex xs12 style="overflow:auto; height: calc(100% - 220px)" v-else>
                <v-layout row wrap justify-center >
                    <v-hover v-for="(item, index) in currentTouchLayoutStepGroup">
                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 20 : 5}`" @click="touchLayoutClick(index)" :style="'overflow: hidden; width: {0}px; height: {1}px; text-align: center; margin: 10px; font-weight:400; background-color: {2}; {3}'.format((picker ? 150 : 200), (picker ? 70 : 100), touchWizardButtonsColors[index], ($avStyle.whiteTextNeeded(touchWizardButtonsColors[index]) ? 'color: white' : ''))">
                            <v-card-text style="word-wrap: break-word; font-weight: 400; padding: 10px" :class="picker ? 'subheading' : 'title'">{{item.show}}</v-card-text>
                        </v-card>
                    </v-hover>
                </v-layout>
            </v-flex>
        </template>

        <!-- final wizard page is composed of summary that is only visible for stop categorization and notes
        area that is also visible when we are not in wizard mode (plain time tracking change) -->
        <v-layout column ma-3 pb-3 style="overflow: hidden">
            <template v-if="showStopWizardFinalSummary">
                <label v-if="showStopTimeSpan && lineStops.length === 1" style="margin-bottom: 10px" class="title font-weight-regular">Stop: <span class="font-weight-medium">{{$timeTracking.formatStop(lineStops[0])}}</span></label>
                <label style="margin-bottom: 10px" class="title font-weight-regular">Stopped machine: <span class="font-weight-medium">{{stoppedMachine ? stoppedMachineAlias : "Unknown"}}</span></label>
                <label style="margin-bottom: 10px" class="title font-weight-regular">Stopping alarm: <span class="font-weight-medium">{{stoppingAlarmInfo ? stoppingAlarmInfo.alarmDescription : "No alarm"}}</span></label>
                <label style="margin-bottom: 10px" class="title font-weight-regular">Stop root cause: <span class="font-weight-medium">{{currentTimeTrackerTitle}}</span></label>
            </template>
            <v-textarea v-if="showStopWizardFinalSummary || (!showStopDetails && !picker)" clearable auto-grow class="title pt-2 mt-3 osk" :label="lbComment" v-model="annotationText" />
        </v-layout>


        <v-card-actions style="margin-top: 0; position:absolute; bottom:0; right:0">
            <label v-if="error && lastTouchLayoutStepVisible" style="color: var(--av-red); font-size: 1.4em; max-width: 600px; text-overflow: ellipsis; overflow: hidden">{{error}}</label>
            <v-spacer />
            <v-btn  :disabled="loading" v-if="picker || mainWizardStep === 2 || !showStopDetails || (lastTouchLayoutStepVisible && mainWizardStep === 2)" id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="callBack(true)" v-translate>
                OK
            </v-btn>
            <v-btn v-show="enableNextStep" color="blue darken-1" flat="flat" @click="nextWizardStep" v-translate>
                Next
            </v-btn>
            <v-btn :disabled="loading" color="red darken-1" flat="flat" @click="callBack(false)" v-translate>
                Cancel
            </v-btn>
            <v-btn :loading="loading" :disabled="loading || isLearn" color="blue darken-1" flat="flat" @click="saveStopCause"
                    v-show="!picker && mainWizardStep === 2 && (stoppingAlarmInfo && currentTimeTrackerTitle!==$gettext('No stop cause selected'))" v-translate>
                Learn this user choice
            </v-btn>
        </v-card-actions>
    </v-card>

</template>

<script>

    import DataApis from '@/api/data'
    import DateTime from '@/api/datetimeutils.js';
    import BackAnnotations from '@/api/backannotation';
    import ColorSequence from '@/api/colorsequences'

    export default {
        name: "TimeTracking",
        props: {
            notified: {
                type: Boolean,
                default: false
            },
            showStopDetails: {
                type: Boolean,
                default: true
            },
            showStopTimeSpan: {
                type: Boolean,
                default: false
            },
            noTitleBar: {
                type: Boolean,
                default: false
            },
            touchLayout: {
                type: Boolean,
                default: false
            },
            user : "",
            lineStops: {
                type: Array,
                default: ()=> []
            },
            auditAction: "",
            picker : {
                type: Boolean,
                default: false,
            },
            showSystemCategories: {
                type: Boolean,
                default: false,
            },
            alarms: {
                type: Array,
                default: ()=> []
            },
            stoppedMachines: {
                type: Array,
                default: ()=> []
            }
        },
        data () {
            return {
                machines: null,
                initialStoppedMachine: null,
                stoppedMachine: null,
                stoppedMachineAlias: null,
                initialStoppedMachineAlias: null,
                stoppingAlarm: "",
                stoppingAlarmInfo: null,
                annotationCauses: null,
                annotationCause: 0,
                annotationFirstSubCause: -1,
                annotationSecondSubCause: -1,
                annotationThirdSubCause: -1,
                annotationText: "",
                error: "",
                lineStatusRefresher: null,
                compiled: false,
                indicesComboBox: [],
                stopCauseAssigned: false,
                touchWizardStep: 0,
                touchWizardColors: [],
                alarmWizardStep: true,
                mainWizardStep: 0,
                noAlarmSelected: false,
                enableNextButton : false,
                alarmsDescriptor: {'alarmSearchPattern' : null, 'alarmSearchPatternIndices' : null, 'alarmsDescriptions' : null},
                loader: null,
                loading: false,
                isLearn:false,
            }
        },
        beforeDestroy: function() {
            clearInterval(this.lineStatusRefresher);
        },
        mounted:  function () {
            let self = this;
            //TODO reintegrate machines parameters as an add on to monitored machines
            //this.machines = this.$settings.getLineSettings().machines;
            this.$devices.getAvailable().then(async devs => {
                let lineMachines = this.$settings.getLineSettings().machines;
                if(Array.isUseful(lineMachines)) {
                    devs = lineMachines;
                }
                self.machines = await self.$aliases.getMachinesAliasesObjects(devs);
                self.machines.insertItem(0, "No machine")
            });

            this.loadAnnotationsCauses();

            if(this.selectedDefaultItem > 0 && !this.noAlarmSelected)
                this.selectedItemChanged(this.alarms[0].items[0]);
        },
        watch: {
            notified() {
                if(this.notified) {
                    this.lineStops = [ {start: new Date(), stop: null, duration: 0} ];
                    this.lineStatusRefresher = setInterval(this.checkLineStatus, 5000);
                }
            },
            alarms() {
                if(Array.isUseful(this.alarms)) {
                    if(this.selectedDefaultItem > 0/* && !this.noAlarmSelected*/) {
                        this.selectedItemChanged(this.alarms[0].items[0]);
                    }
                }
            },
            stoppedMachines() {
                if(Array.isUseful(this.stoppedMachines)) {
                    this.stoppedMachine = this.stoppedMachines[0];
                    this.initialStoppedMachine = this.stoppedMachine;
                }
            },
            noAlarmSelected() {
                this.enableNextButton = this.isSuitableAlarm() ? true : this.noAlarmSelected;
                if (this.noAlarmSelected) {
                    this.stopCauseAssigned = false;
                    this.stoppingAlarmInfo = null;
                    this.stoppingAlarm = '';
                    this.indicesComboBox.clear();
                    this.annotationCause = -1;
                    this.annotationFirstSubCause =  -1;
                    this.annotationSecondSubCause = -1;
                    this.annotationThirdSubCause = -1;
                } else {
                    if(this.selectedDefaultItem > 0)
                        this.selectedItemChanged(this.alarms[0].items[0]);
                }
            },
            async stoppedMachine() {
                this.stoppedMachineAlias = await this.$aliases.getMachineAlias(this.stoppedMachine);
                if(!this.stoppedMachineAlias)
                    this.stoppedMachineAlias = this.stoppedMachine;

            },
            async initialStoppedMachine() {
                this.initialStoppedMachineAlias = await this.$aliases.getMachineAlias(this.initialStoppedMachine);
                if(!this.initialStoppedMachineAlias)
                    this.initialStoppedMachineAlias = this.initialStoppedMachine;
            },

        },
        computed : {
            showStopInfoPage() {
                return !this.picker && this.showStopDetails && (this.mainWizardStep === 0);
            },
            showTimeTrackingPicker() {
                return this.mainWizardStep === 1 || this.picker || !this.showStopDetails;
            },
            showStopInfoSummary() {
                return !this.picker && this.showStopDetails
            },
            showStopWizardFinalSummary() {
                return (this.showStopInfoSummary && this.mainWizardStep === 2)
            },
            currentTouchLayoutStepGroup() {
                if(this.touchWizardStep === 0)
                    return this.annotationCauses;
                else if(this.touchWizardStep === 1)
                    return this.annotationFirstSubCauses;
                else if(this.touchWizardStep === 2)
                    return this.annotationSecondSubCauses;
                else if(this.touchWizardStep === 3)
                    return this.annotationThirdSubCauses;
            },
            touchWizardButtonsColors() {
                return ColorSequence.getColors(this.currentTouchLayoutStepGroup.length)
            },
            lastTouchLayoutStepVisible() {
                return ((this.touchLayout && this.touchWizardStep === 4) || !this.touchLayout);
            },
            currentTimeTrackerTitle() {
                return this.formatTimeTracking().annotationCauseDescription || this.$gettext("No stop cause selected");
            },
            currentTimeTrackerIds() {
                return this.formatTimeTracking().annotationCauseIds;
            },
            alarmsCount() {
                let tot =0;
                if (Array.isUseful(this.alarms)) {
                    this.alarms.forEach(alarm => {
                        tot += alarm.items.length;
                    });
                }
                return tot;
            },
            selectedDefaultItem() {

                this.enableNextButton = true;
                //If there are not suitable alarms than No suitable(no Alarms) is flagged
                if (this.isSuitableAlarm()) {
                    this.stoppedMachine = this.alarms[0].items[0].device;
                    this.stoppingAlarm = this.alarms[0].items[0].alarmRawIdentifier;
                    this.noAlarmSelected = false;
                    this.stopCauseAssigned = true;
                    return this.alarms[0].items[0].index;
                } else {
                    if(Array.isUseful(this.stoppedMachines)) {
                        this.stoppedMachine = this.stoppedMachines[0];
                        this.initialStoppedMachine = this.stoppedMachine;
                    }
                }
                this.stoppingAlarm = '';
                this.noAlarmSelected = true;
                return -1
            },
            enableNextStep() {
                return (this.enableNextButton && (this.mainWizardStep === 0 || (this.mainWizardStep === 1 && !this.touchLayout)) && !this.picker && this.showStopDetails);
            },
            annotationFirstSubCauses() {
                this.annotationFirstSubCause = -1;
                this.annotationSecondSubCause = -1;
                this.annotationThirdSubCause = -1;
                if (Array.isUseful(this.annotationCauses) && this.annotationCause >= 0) {
                    if(Array.isUseful(this.annotationCauses[this.annotationCause].children)) {
                        this.annotationFirstSubCause = (Array.isUseful(this.indicesComboBox) && this.indicesComboBox.length > 1) ? this.indicesComboBox[1] : 0;
                        return this.annotationCauses[this.annotationCause].children;
                    }
                    else if(this.touchWizardStep === 1)
                        this.finalizeWizard();
                }
                return [];
            },
            annotationSecondSubCauses() {
                this.annotationSecondSubCause = -1;
                this.annotationThirdSubCause = -1;
                if (Array.isUseful(this.annotationCauses) && this.annotationCause >= 0 && this.annotationFirstSubCause >= 0) {
                    if(Array.isUseful(this.annotationCauses[this.annotationCause].children[this.annotationFirstSubCause].children)) {
                        this.annotationSecondSubCause = (Array.isUseful(this.indicesComboBox) && this.indicesComboBox.length > 2) ? this.indicesComboBox[2] : 0;
                        return this.annotationCauses[this.annotationCause].children[this.annotationFirstSubCause].children;
                    }
                    else if(this.touchWizardStep === 2)
                        this.finalizeWizard();
                }
                return [];
            },
            annotationThirdSubCauses() {
                this.annotationThirdSubCause = -1;
                this.compiled = true;
                if (Array.isUseful(this.annotationCauses) && this.annotationCause >= 0 && this.annotationFirstSubCause >= 0 && this.annotationSecondSubCause >= 0) {
                    if(Array.isUseful(this.annotationCauses[this.annotationCause].children[this.annotationFirstSubCause].children[this.annotationSecondSubCause].children)) {
                        this.annotationThirdSubCause =  (Array.isUseful(this.indicesComboBox) && this.indicesComboBox.length > 3)  ? this.indicesComboBox[3] : 0;
                        return this.annotationCauses[this.annotationCause].children[this.annotationFirstSubCause].children[this.annotationSecondSubCause].children;
                    }
                    else if(this.touchWizardStep === 3)
                        this.finalizeWizard();
                }
                return [];
            },
            title() {
                if(this.notified) return this.$gettext("Line stopped producing, assign a cause");
                else if(this.picker) return this.$gettext("Select time tracking categories");
                else return this.$gettext("Change current time tracking");
            },
            lbStoppedMachine() { return this.$gettext("Select stopped machine") },
            lbCategory() { return this.$gettext("Select main category") },
            lbFirstSubCategory() { return this.$gettext("Select first sub category") },
            lbSecondSubCategory() { return this.$gettext("Select second sub category") },
            lbThirdSubCategory() { return this.$gettext("Select third sub category") },
            lbComment() { return this.$gettext("Type a free comment here") },
        },
        methods: {
            async loadAnnotationsCauses() {
                let self = this;
                this.$timeTracking.getUpdatedStopCauses()
                    .then(result => {
                        self.annotationCauses = result;
                        let indexer = function(items) {
                            for(let index = 0 ; index < items.length ; index++) {
                                if(!items[index].active) {
                                    items.splice(index, 1);
                                    index--;
                                }
                                else {
                                    items[index]["index"] = index;
                                    if (Array.isUseful(items[index].children))
                                        indexer(items[index].children);
                                }
                            }
                        };
                        indexer(self.annotationCauses);
                        if(self.showSystemCategories) {
                            self.annotationCauses.unshift({
                                active: true,
                                children: [
                                    {
                                        active: true,
                                        children: [],
                                        id: 0,
                                        show: self.$gettext("Idle time")
                                    },
                                    {
                                        active: true,
                                        children: [],
                                        id: 1,
                                        show: self.$gettext("Production")
                                    },
                                    {
                                        active: true,
                                        children: [],
                                        id: 2,
                                        show: self.$gettext("Uncategorized stop")
                                    }
                                ],
                                id: 0,
                                show: self.$gettext("System categories")
                            });
                        }
                    });
            },

            groupName(alarmsGroup) {
                return (alarmsGroup.suitable ? this.$gettext("Suitable alarms") : this.$gettext("Non stop related alarms"));
            },
            nextWizardStep() {
                this.mainWizardStep += this.stopCauseAssigned ? 2 : 1;
            },
            finalizeWizard() {
                if(!this.picker) {
                    this.touchWizardStep = 4;
                    this.compiled = true;
                    this.mainWizardStep += 1;
                }
                else {
                    this.callBack(true);    //If we are in picker mode, on wizard complete just push back result
                }
            },
            touchLayoutClick(index) {
                this.alarmWizardStep = false;
                this.touchWizardStep++;
                if(this.touchWizardStep === 1)
                    this.annotationCause = index;
                else if(this.touchWizardStep === 2)
                    this.annotationFirstSubCause = index;
                else if(this.touchWizardStep === 3)
                    this.annotationSecondSubCause = index;
                else if(this.touchWizardStep === 4) {
                    this.annotationThirdSubCause = index;
                    this.finalizeWizard();
                }
            },
            selectedItemChanged(alarm) {
                this.stoppedMachine = alarm.device;
                this.stoppingAlarmInfo = alarm;
                this.stoppingAlarm = alarm.alarmRawIdentifier;
                this.stopCauseAssigned = false;
                this.indicesComboBox.clear();
                this.annotationCause = -1;
                this.annotationFirstSubCause =  -1;
                this.annotationSecondSubCause = -1;
                this.annotationThirdSubCause = -1;
                this.enableNextButton = true;

                if(alarm.stopCause) {
                    this.indicesComboBox = this.$timeTracking.getTimeTrackerIndices(alarm.stopCause, this.annotationCauses);
                    this.stoppedMachine = alarm.device;
                    this.stoppingAlarm = alarm.alarmRawIdentifier;
                    if (Array.isUseful(this.indicesComboBox)) {
                        this.annotationCause = this.indicesComboBox[0];
                        //If not in touch layout, after assigning first stop cause the computed for the subitems are fired
                        //and sub indices are automatically calculated. In touch layout computed are unreferenced in layout,
                        //thus indices must be manually assigned
                        // if(this.touchLayout) {
                        this.annotationFirstSubCause = this.indicesComboBox[1];
                        this.annotationSecondSubCause = this.indicesComboBox[2];
                        this.annotationThirdSubCause = this.indicesComboBox[3];
                        // }
                        this.stopCauseAssigned = true;
                    }
                }
            },
            checkLineStatus() {
                let dataItems = [
                    {
                        index: 'production counters@5s',
                        root: 'Line',
                        name: 'Status',
                        type: 'integer',
                        representations: [
                            {
                                type: this.$defines.allAggregations.last.id,
                                target: 0,
                                name: 'LineStatus',
                            },
                        ],
                    },
                ];

                let queryDescriptor = DataApis.getDataQueryDescriptor(dataItems);
                let self = this;

                //Execute query
                if (queryDescriptor.agg.length > 0 || queryDescriptor.raw.length > 0 || queryDescriptor.comp.length > 0) {
                    DataApis.getDataBlob(queryDescriptor, DateTime.getRfc3339TimeStamp(new Date(Date.now() - 60000)), DateTime.getRfc3339TimeStamp(new Date()))
                        .then(result => {
                            if(result.raw_0[0].Line.Status) {
                                clearInterval(self.lineStatusRefresher);
                            }
                            self.lineStops[0].stop = new Date();
                            self.lineStops[0].duration = (self.lineStops[0].stop - self.lineStops[0].start);
                            let wfs = self.$settings.getWorkFlowSettings();
                            if(wfs && wfs.shortStopsThreshold && self.lineStops[0].duration >= wfs.shortStopsThreshold)
                                self.$emit("shortStopThresholdElapsed");
                        })
                        .catch(err => {
                            clearInterval(self.lineStatusRefresher);
                            console.error(err);
                        debugger
                        });
                }
            },
            getItem(level) {
                if(!Array.isUseful(this.annotationCauses) || this.annotationCause < 0)
                    return null;
                if(level === 0)
                    return this.annotationCauses[this.annotationCause];
                if(!Array.isUseful(this.annotationCauses[this.annotationCause].children) || this.annotationFirstSubCause < 0)
                    return null;
                if(level === 1)
                    return this.annotationCauses[this.annotationCause].children[this.annotationFirstSubCause];
                if(!Array.isUseful(this.annotationCauses[this.annotationCause].children[this.annotationFirstSubCause].children) || this.annotationSecondSubCause < 0)
                    return null;
                if(level === 2)
                    return this.annotationCauses[this.annotationCause].children[this.annotationFirstSubCause].children[this.annotationSecondSubCause];
                if(!Array.isUseful(this.annotationCauses[this.annotationCause].children[this.annotationFirstSubCause].children[this.annotationSecondSubCause].children) || this.annotationThirdSubCause < 0)
                    return null;
                if(level === 3)
                    return this.annotationCauses[this.annotationCause].children[this.annotationFirstSubCause].children[this.annotationSecondSubCause].children[this.annotationThirdSubCause];
                return null;
            },
            formatTimeTracking() {
                let annotationCauseInfo = {annotationCauseIds: [0,0,0,0], annotationCauseDescription: ""};
                for(let level = 0 ; level < 4 ; level++) {
                    let item = this.getItem(level);
                    if(item) {
                        annotationCauseInfo.annotationCauseIds[level] = item.id;
                        annotationCauseInfo.annotationCauseDescription += ((level > 0 ? " --> " : "") + item.show);
                    }
                }
                return annotationCauseInfo;
            },
            callBack(result) {

                let currentIds=this.$utils.detach(this.currentTimeTrackerIds)
                this.error = "";
                let self = this;
                let stepsToCompletion = 2; //Counter used to emit the complete action only when all apis are exausted

                if (result) {

                    //condition picker emit result picker --> return annotationCauseIds
                    if (this.picker) {
                        //In touch wizard mode user can select a macro category instead of a full time tracker.
                        //In this case we return the numeric range of category
                        let returning = null;
                        if (!this.compiled) {
                            let ids = this.currentTimeTrackerIds;
                            for (let i = this.touchWizardStep; i < ids.length; i++)
                                ids[i] = 0;
                            returning = this.$timeTracking.getTimeTrackerIdsRange(ids);
                        }
                        //Otherwise just return a simple tracker
                        else returning = this.$timeTracking.getTimeTrackerId(this.currentTimeTrackerIds);
                        this.$emit("picked", returning);

                        return;
                    }

                    let description = (this.currentTimeTrackerTitle + (self.annotationText ? (" Comment: " + self.annotationText) : ""));

                    if (!Array.isUseful(this.lineStops) || this.notified) {
                        this.$timeTracking.changeTimeTracker(this.$utils.detach(currentIds), (this.stoppedMachine ? this.stoppedMachine : "unknown"), (this.stoppingAlarm ? this.stoppingAlarm : "unknown"), this.user) /*this.user*/
                            .then(() => {
                                stepsToCompletion--;
                                self.$audits.save(this.user, this.$audits.items().timeTracking, "", description, "");
                                if (!stepsToCompletion)
                                    self.$emit('complete', true);
                            })
                            .catch(error => {
                                console.log(error);
                                self.error = self.$gettext("An error occurred while changing time tracker, please try again");
                            })
                    } else stepsToCompletion--;

                    if (Array.isUseful(this.lineStops)) {
                        let backAnnotationsRequest = [];
                        let lineStopsTotalDuration = DateTime.formatTime(((this.lineStops.map(item => item.duration).reduce((prev, next) => prev + next)) / 1000));
                        for (let stop of this.lineStops)
                            backAnnotationsRequest.push(this.$timeTracking.getBackAnnotationObject(stop.start - 1000, stop.stop + 1000, this.$utils.detach(currentIds), (this.stoppedMachine ? this.stoppedMachine : "unknown"), (this.stoppingAlarm ? this.stoppingAlarm : "unknown"), this.user, stop.start.toString()));

                        BackAnnotations.postBackAnnotations(backAnnotationsRequest)
                            .then(() => {

                                stepsToCompletion--;

                                if (self.auditAction === self.$audits.items().timeTrackingAdjustement) {
                                    for (let timeTracking of self.lineStops) {
                                        let timeTrackingDescription = this.$timeTracking.getTimeTrackerFromId(timeTracking.timeTrackingId).join(' --> ');
                                        let nextVal = [new Date(timeTracking.start).format(), new Date(timeTracking.stop).format(), timeTrackingDescription, description];
                                        self.$audits.save(this.user, self.auditAction, "", nextVal, "");
                                    }
                                } else {
                                    let action = '';
                                    let nextVal = null;
                                    if (self.lineStops.length > 1) {
                                        action = self.$audits.items().pastTimeTracking;
                                        nextVal = [this.lineStops.length, lineStopsTotalDuration, description];
                                    } else {
                                        action = self.$audits.items().pastTimeTrackingSingle;
                                        nextVal = [new Date(this.lineStops[0].start).format(), new Date(this.lineStops[0].stop).format(), lineStopsTotalDuration, description];
                                    }
                                    self.$audits.save(this.user, action, "", nextVal, "");
                                }

                                if (!stepsToCompletion)
                                    self.$emit('complete', true);

                            })
                            .catch(error => {
                                debugger;
                                console.error(error);
                                self.error = self.$gettext("An error occurred..., please try again");
                            })

                    } else stepsToCompletion--;


                } else self.$emit('complete', false);
            },

            isSuitableAlarm() {
                return Array.isUseful(this.alarms) && this.alarms[0].suitable;
            },

            async getDeviceAlarmsDescriptions(device) {
                await this.$settings.load(this.$settings.AlarmsDescriptions, device)
                    .then(descriptor => {
                        if (Object.isUseful(descriptor) && Array.isUseful(descriptor.alarmsDescriptions)) {
                            this.alarmsDescriptor.alarmsDescriptions = descriptor.alarmsDescriptions;
                            this.alarmsDescriptor.alarmSearchPattern = descriptor.alarmSearchPattern;
                            this.alarmsDescriptor.alarmSearchPatternIndices = descriptor.alarmSearchPatternIndices;
                            // this.checkSearchPattern();
                        }
                    })
                    .catch(err => {
                        debugger;
                    })
            },
            async saveStopCause(){
                let self = this;
                this.loader = 'loading';
                let trackerId = this.$timeTracking.getTimeTrackerId(this.currentTimeTrackerIds);
                let systemCategory = this.$timeTracking.getSystemCategory(this.currentTimeTrackerIds)
                await this.getDeviceAlarmsDescriptions(this.stoppedMachine);
                for(let i=0;i<this.alarmsDescriptor.alarmsDescriptions.length-1;i++){
                    if(this.alarmsDescriptor.alarmsDescriptions[i].id===this.stoppingAlarmInfo.alarmIdentifier){
                        this.alarmsDescriptor.alarmsDescriptions[i].stopCause =  trackerId;
                        //DS add system category to descriptor
                        this.alarmsDescriptor.alarmsDescriptions[i].category = systemCategory;
                    }
                }
                await this.$settings.save(this.alarmsDescriptor, this.$settings.AlarmsDescriptions, this.stoppedMachine)
                    .then( ()=> {
                         self.$alarms.updateAlarmsDescriptor(self.stoppedMachine, self.alarmsDescriptor );
                         self.isLearn=true;
                        let nextValTrail = this.$timeTracking.getTimeTrackerFromId(trackerId).join(' --> ');
                        let actionTrailId = self.stoppedMachine +'.' + self.stoppingAlarmInfo.alarmIdentifier;
                        self.$audits.save(self.user, self.$audits.items().alarmConfiguredFromStopCategorization, "", nextValTrail, actionTrailId)
                            .catch(() => {
                            debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .catch(err => {
                        console.error(err);
                        self.isLearn=false;
                    })
                    .finally( () => {
                        const l = self.loader;
                        self[l] = !self[l];
                        setTimeout(() => (self[l] = false), 2000);
                        self.loader = null;

                    });
            },
        }
    }
</script>

<style scoped>
    .radio-group-full-size >>> .v-input__control {
        width: 100%;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }
    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

</style>
