
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import VueInstance from'@/api/vueinstance'
import utils from "./jsutils"
import LandingServices from '@/api/landing-services'


let line = "line";
let identification = "identification";
let workflow = "workflow";
let stopCauses = "lineStopCauses";
let standardTimes = "standardTimes";
let landingPageStyles = "landingPageStyles";
let grants = "grants";
let lineSettings = null;
let identificationSettings = null;
let standardTimesSettings = null;
let workFlowSettings = null;
let lineStopCauses = null;
let mail = "mail";
let mailSettings = null;
let operationModesSettings = null;
let operationModes = "operationModes";
let tags = "tags";
let tagsSettings = null;
let plant = "plant";
let plantSettings = null;

async function load(type, name, option, scope = "user") {
    return new Promise((resolve) => {
        OrchestratorAPI.proxyCall('get', "{0}/configuration/{1}/".format(Microservices.documentsUrl, scope) +
            (type || "") + (name ? "/" : "") + (name || "") + (option ? "/" : "") + (option || ""))
            .then(t => {
                let settings = null;
                if (t.DocumentContent)
                    settings = JSON.parse(t.DocumentContent);
                resolve(settings);
            })
            .catch(t => {
                debugger;
                resolve(null);
            });
    });
}

async function save(settings, type, name, option, encrypted, scope = "user") {
    return new Promise((resolve, reject) => {
        OrchestratorAPI.proxyCall('post', "{0}/configuration/{1}/".format(Microservices.documentsUrl, scope) +
            (type || "") + (name ? "/" : "") + (name || "") + (option ? "/" : "") + (option || "") + (encrypted ? "/" : "") + (encrypted || ""),
            {"DocumentContent": settings})
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error);
            });
    });
}

async function loadIdentificationSettings() {
    try {
        identificationSettings = await load(identification);
        if(!identificationSettings) {
            identificationSettings = await getIdentificationSettingsFromLine();
        }
    }
    catch (e) {
        debugger;
        identificationSettings = await getIdentificationSettingsFromLine();
    }

    return utils.detach(identificationSettings);
}

async function getIdentificationSettingsFromLine() {
    let lineSettings = await loadLineSettings();
    if(lineSettings) {
        return {
            customerId: lineSettings.customerId,
            factoryId: lineSettings.factoryId,
            physicalLineId: lineSettings.physicalLineId,
            lineId: lineSettings.lineId
        }
    }
    return null;
}

function getIdentificationSettings() {
    return  utils.detach(identificationSettings);
}

async function loadLineSettings() {
    try {
        lineSettings = await load(line);
    }
    catch (e) {
        debugger;
        lineSettings = null;
    }

    return utils.detach(lineSettings);
}

function getLineSettings() {
    return  utils.detach(lineSettings);
}

async function saveLineSettings(settings){
    return new Promise((resolve, reject) => {
        save(settings, line)
            .then(result => {
                lineSettings = settings;
                resolve(true);
            })
            .catch(error => {
                reject(error);
            })
    })
}

async function loadPlantSettings() {
    try {
        plantSettings = await load(plant);
    }
    catch (e) {
        debugger;
        plantSettings = null;
    }

    return utils.detach(plantSettings);
}

function getPlantSettings() {
    return  utils.detach(plantSettings);
}

async function savePlantSettings(settings){
    return new Promise((resolve, reject) => {
        save(settings, plant)
            .then(result => {
                plantSettings = settings;
                resolve(true);
            })
            .catch(error => {
                reject(error);
            })
    })
}

async function loadStandardTimesSettings() {
    try {
        standardTimesSettings = await load(standardTimes);
    }
    catch (e) {
        debugger;
        standardTimesSettings = null;
    }

    return utils.detach(standardTimesSettings);
}

function getStandardTimesSettings() {
    return  utils.detach(standardTimesSettings);
}

async function loadWorkFlowSettings(){
    try {
        workFlowSettings = await load(workflow);
    }
    catch (e) {
        debugger;
        workFlowSettings = null;
    }

    return  utils.detach(workFlowSettings);
}

async function loadLandingPageSettings(){
    return LandingServices.GetConf();
}

function getWorkFlowSettings() {
    return  utils.detach(workFlowSettings);
}

async function loadLineStopCauses(deploymentLevel) {

    try {
        lineStopCauses = await load(stopCauses, "MasterL" + deploymentLevel);
    }
    catch (e) {
        debugger;
        lineStopCauses = null;
    }

    return  utils.detach(lineStopCauses);
}

async function loadUpperLevelStopCauses(upperLevel) {
    return new Promise((resolve, reject) => {
        load(stopCauses, "MasterL" + upperLevel)
            .then(upperLevelStopCauses => {
                resolve(upperLevelStopCauses);
             })
            .catch(t => {
                debugger;
                console.error(t);
                reject(t);
            });
    });
}

function getLineStopCauses() {
    if(Object.isUseful(lineStopCauses))
        return  utils.detach(lineStopCauses);
    return [];
}

async function saveStandardTimesSettings(settings){
    return new Promise((resolve, reject) => {
        save(settings, standardTimes)
            .then(() => {
                standardTimesSettings = settings;
                resolve(true);
            })
            .catch(error => {
                reject(error)
            })
    })
}
async function saveWorkFlowSettings(settings){
    return new Promise((resolve, reject) => {
        save(settings, workflow)
            .then(() => {
                workFlowSettings = settings;
                resolve(true);
            })
            .catch(error => {
                reject(error)
            })
    })
}

async function saveLandingPageSettings(settings){
    return new Promise((resolve, reject) => {
        save(settings, landingPageStyles)
            .then(() => {
                resolve(true);
            })
            .catch(error => {
                reject(error)
            })
    })
}

async function saveLineStopCauses(settings, deploymentLevel){
    return new Promise((resolve, reject) => {
        save(settings, stopCauses, "MasterL" + deploymentLevel)
            .then(() => {
                lineStopCauses = settings;
                resolve(true);
            })
            .catch(error => {
                debugger
                reject(error)
            })
    })
}

function getMailSettings() {
    return  utils.detach(mailSettings);
}

async function loadMailSettings(){
    try {
        mailSettings = await load(mail);
    }
    catch (e) {
        debugger;
        mailSettings = null;
    }

    return  utils.detach(mailSettings);
}

async function saveMailSettings(settings){
    return new Promise((resolve, reject) => {
        save(settings, mail,'NULL', 'NULL', 'encrypted')
            .then(() => {
                resolve(true);
            })
            .catch(error => {
                reject(error)
            });
    });
}

async function loadOperationModesSettings() {
    try {
        operationModesSettings = await load(operationModes);
    }
    catch (e) {
        debugger;
        operationModesSettings = null;
    }

    return  utils.detach(operationModesSettings);
}

function getOperationModesSettings() {
    return  utils.detach(operationModesSettings);
}

function getTagsSettings() {
    return  utils.detach(tagsSettings);
}

async function loadTagsSettings(){
    try {
        tagsSettings = await load(tags);
        if (!tagsSettings) {
            tagsSettings = {widgets: [], rules: [], forms: [], reports: []};
        }
    }
    catch (e) {
        debugger;
        tagsSettings = null;
    }

    return  utils.detach(tagsSettings);
}

async function saveTagsSettings(settings){
    return new Promise((resolve, reject) => {
        save(settings, tags)
            .then(() => {
                tagsSettings = settings;
                resolve(true);
            })
            .catch(error => {
                reject(error);
            })
    })
}

export default {

    LineSettings: line,
    IdentificationSettings: identification,
    Workflow: workflow,
    LineStopActions: stopCauses,
    StandardTimesSettings: standardTimes,
    Grants: grants,
    EtlSettings: "ETL",
    AlarmsDescriptions: "alarms",
    OpcDescriptions: "AvOpcUa",
    ModbusDescriptions: "AvModbus",
    Languages: "languages",
    Current: "current",
    Available: "available",
    Translations: "translations",
    ShiftChange: "shift",
    MailSettings: mail,
    MachinesSettings: "machines",
    SublinesSettings: "sublines",
    OperationModesSettings: operationModes,
    ShiftScheduler:"shift_scheduler",
    AppConfig: "appConfig",
    UserScope: "user",
    InstanceScope: "instance",
    SystemScope: "system",
    Null: "",
    load: load,
    save: save,
    loadLineSettings: loadLineSettings,
    loadIdentificationSettings: loadIdentificationSettings,
    loadStandardTimesSettings: loadStandardTimesSettings,
    loadWorkFlowSettings: loadWorkFlowSettings,
    loadLandingPageSettings: loadLandingPageSettings,
    loadLineStopCauses: loadLineStopCauses,
    loadUpperLevelStopCauses: loadUpperLevelStopCauses,
    loadMailSettings: loadMailSettings,
    loadTagsSettings: loadTagsSettings,
    loadOperationModesSettings: loadOperationModesSettings,
    getLineSettings: getLineSettings,
    getIdentificationSettings: getIdentificationSettings,
    getWorkFlowSettings: getWorkFlowSettings,
    getLineStopCauses: getLineStopCauses,
    getMailSettings: getMailSettings,
    getOperationModesSettings: getOperationModesSettings,
    getStandardTimesSettings: getStandardTimesSettings,
    getTagsSettings: getTagsSettings,
    saveLineSettings: saveLineSettings,
    saveWorkFlowSettings: saveWorkFlowSettings,
    saveLineStopCauses: saveLineStopCauses,
    saveMailSettings: saveMailSettings,
    saveStandardTimesSettings: saveStandardTimesSettings,
    saveLandingPageSettings: saveLandingPageSettings,
    saveTagsSettings: saveTagsSettings,
    loadPlantSettings: loadPlantSettings,
    getPlantSettings: getPlantSettings,
    savePlantSettings: savePlantSettings,

    async loadAll(scope = "user") {

        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', "{0}/configuration/{1}/*".format(Microservices.documentsUrl, scope))
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    console.log(t);
                    //No config created yet. At moment we assume we are on a fresh installation
                    if (t.response.status === 404)
                        resolve(null);
                    else
                        reject(VueInstance.get().$gettext("Error in retrieving settings from DB."));
                });
        });
    },

    async list(type, scope = "user") {

        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', "{0}/configuration/{1}".format(Microservices.documentsUrl, scope))
                .then(t => {
                    if (!type) {
                        resolve(t);
                    } else if (!Array.isArray(t)) { //Single document is returned with full body
                        if (t.Type === type)
                            resolve([t]);
                        else resolve([]);
                    } else {
                        let returning = [];
                        for (let i = 0; i < t.length; i++)
                            if (t[i].Type === type)
                                returning.push(t[i]);

                        resolve(returning);
                    }
                })
                .catch(t => {
                    console.log(t);
                    reject(VueInstance.get().$gettext("Error in retrieving settings from DB."));
                });
        });
    },

    async loadTypeMap(type, scope = "user") {
        let returning = {};
            return this.list(type)
                .then(list => {
                    if (Array.isArray(list)) {
                        let promises = []
                        for (let item of list) {
                            promises.push(new Promise((resolve, reject) => {
                                this.load(type, item.Name)
                                    .then(document => {
                                        returning[item.Name] = document
                                        resolve()
                                    })
                                    .catch(err => {
                                        debugger
                                        console.error(err)
                                        reject()
                                    })
                            }))
                        }
                        return new Promise((resolve, reject) => {
                            return Promise.all(promises)
                                .then(t => {
                                    resolve(returning);
                                })
                                .catch(t => {
                                    console.log(t);
                                    reject(VueInstance.get().$gettext("Error loading configs"));
                                });
                        })
                    }
                })
                .catch(err => {
                    debugger
                    console.log(err);
                });
    },

    checkIdentificationSettings(settings, level) {
        return (settings && (level > 2 || settings.physicalLineId) && settings.factoryId && settings.customerId)
    },

    async saveById(settings, id, scope = "instance") {
        return new Promise((resolve, reject) => {
            let tokens = id.split(".");
            if(tokens.length !== 3)
                reject("Wrong setting id format. Requested Id {0}".format(id));
            OrchestratorAPI.proxyCall('post', '{0}/configuration/{1}/{2}/{3}/{4}'.format(Microservices.documentsUrl, scope, tokens[0], tokens[1], tokens[2]), { "DocumentContent": settings })
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async delete(type, name, option, scope = "user") {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('delete', "{0}/configuration/{1}/".format(Microservices.documentsUrl, scope) +
                (type || "") + (name ? "/" : "") + (name || "") + (option ? "/" : "") + (option || ""))
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async deleteById(id, scope = "instance") {
        return new Promise((resolve, reject) => {
            let tokens = id.split(".");
            if(tokens.length !== 3)
                reject("Wrong setting id format. Requested Id {0}".format(id));
            OrchestratorAPI.proxyCall('delete', '{0}/configuration/{1}/{2}/{3}/{4}'.format(Microservices.documentsUrl, scope, tokens[0], tokens[1], tokens[2]))
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}
