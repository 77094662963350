<template>
    <v-dialog v-model="showDialog" width="860px" height="640px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <div ref="container" :style="'height: {0}px; margin: 0px; padding:0px; overflow: hidden;'.format(winHeight)" />
    </v-dialog>
</template>

<script>

    export default {
        name: "TimeTrackingBackAnnotationDialog",
        components: {},
        data () {
            return {
                showDialog: false,
                callerInstance: null,
                callback: null,
                forceFillIn: false,
                stopsManager: null,
                firstLoad: true
            }
        },
        computed: {
            winHeight() {
                if(this.$root.isMobile)
                    return window.innerHeight;
                else return 640;
            }
        },
        methods: {
            setCaller(caller) {
                this.callerInstance = caller;
            },
            Show(user, callback, workorder, forceFillIn) {

                if(!this.$license.hasPerformance())
                    return;

                this.callback = callback;
                this.forceFillIn = forceFillIn;
                let showLongStopsOnly = false;
                let workFlowSettings;

                //If dialog is opened in forced mode, show it only if there are some stops to show
                if(!forceFillIn)
                    this.showDialog = true;
                else {
                    workFlowSettings = this.$settings.getWorkFlowSettings();
                    showLongStopsOnly = workFlowSettings.forceCategorizationLongStopsOnly;
                }

                //If caller instance is passed, we expect it has a property to tell
                //him that a window is opened so that it won't open more
                if(this.callerInstance)
                    this.callerInstance.annotationDialogOpen = true;

                //Load the stops manager system widget in dialog
                let descriptor = this.$dynamicElements.getSystemStopsMonitorWidget();
                let props = {
                    user: user,
                    dialogMode: true,
                    showLongStopsOnly: showLongStopsOnly,
                };
                let self = this;
                this.$nextTick(() => {
                    //Create stops widget and add to dialog container
                    self.stopsManager = self.$dynamicElements.createNew(descriptor.properties.type, false, props);
                    if(!self.stopsManager)
                        return; //License issue or huge error (unlikely)
                    self.stopsManager.load(descriptor);
                    //Callback to close dialog on completion
                    self.stopsManager.$on('complete', (param) => {
                        if(self.callback)
                            self.callback();
                        self.stopsManager = null;
                        self.showDialog = false;
                    });
                    //Callback reports whether stops management has loaded any stops.
                    //If we are opened in forceFillIn mode, this is used to open dialog only
                    //if there are any stops to be categorized
                    self.stopsManager.$on('hasStops', (param) => {
                        if(self.forceFillIn && self.firstLoad) {
                            if(param)
                                self.showDialog = true;
                            else {
                                self.stopsManager = null;
                                self.$destroy();
                            }
                        }
                        self.firstLoad = false;
                    });
                    self.stopsManager.initDialog(workorder, forceFillIn);
                    self.$refs.container.appendChild(this.stopsManager.$el);
                });
            }
        }
    }

</script>