<template>
    <v-card-text class="av-card-content" style="height: calc(100vh - 20px)">
        <v-data-table  :headers="transitionsHeaders"
                       :items="transition"
                       class="elevation-0 subheading fixed-header v-table__overflow"
                       ma-0 pa-0
                       item-key="RequestTimestamp"
                       hide-actions>
            <template v-slot:items="props" >
                <tr>
                    <td class="text-xs-left subheading">
                        {{ new Date(props.item.RequestTimestamp).format()}}
                    </td>
                    <td class="text-xs-left subheading">
                        {{new Date(props.item.ExecutionTimestamp).format()}}
                    </td>
                    <td class="text-xs-left subheading">
                        {{ props.item.Requester.replace('""', '')}}
                    </td>
                    <td class="text-xs-left subheading">
                        {{ (props.item.SourceWorkFlowStatus).replace('""', '') }}
                    </td>
                    <td class="text-xs-left subheading">
                        {{ props.item.TargetWorkFlowStatus}}
                    </td>
                    <td class="text-xs-left subheading">
                        {{ props.item.RequestedTransition}}
                    </td>
                    <td class="text-xs-left subheading">
                        {{ props.item.RequestedTransition}}
                    </td>
                    <td class="text-xs-left subheading">
                        {{ props.item.Status}}
                    </td>
                    <td class="text-xs-left subheading">
                        {{ props.item.CommandError}}
                    </td>
                    <td class="text-xs-left subheading">
                        <av-button flat v-ripple="false" size="35px" icon :buttonIcon="props.item.TransitionsLog ? 'fa-list' : 'fa fa-fw'" iconColor="blue"   @click="props.item.TransitionsLog ? props.expanded = !props.expanded : null"/>
                    </td>
                </tr>
            </template>
            <template v-slot:expand="props">
                <v-data-table  :headers="transitionsLogHeaders"
                               :items="props.item.TransitionsLog"
                               class="elevation-0 subheading fixed-header v-table__overflow"
                               ma-0 pa-0
                               item-key="RequestTimestamp"
                               hide-actions>
                    <template v-slot:items="logProps" >
                        <tr>
                            <td class="text-xs-left subheading">
                                {{ new Date(logProps.item['@timestamp']).format() }}
                            </td>
                            <td class="text-xs-left subheading">
                                {{logProps.item.extrainfo}}
                            </td>
                            <td class="text-xs-left subheading">
                                {{ logProps.item.message}}
                            </td>
                            <td class="text-xs-left subheading">
                                {{ logProps.item.severity }}
                            </td>
                            <td class="text-xs-left subheading">
                                {{ logProps.item.source}}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </template>
        </v-data-table>
    </v-card-text>
</template>

<script>
    import Vue from 'vue'

    export default {
        name: "workorderHistory",
        props:{
            transition:{
                required:true,
                default:()=>{return []}
            }
        },
        data:() => {
            return {
                transitionsHeaders: [
                    {
                        text: Vue.prototype.$gettext("Request Date"),
                        class: "subheading",
                        value: 'RequestTimestamp'
                    },
                    {
                        text: Vue.prototype.$gettext("Execution Date"),
                        class: "subheading",
                        value: 'ExecutionTimestamp'
                    },
                    {
                        text: Vue.prototype.$gettext("Requester"),
                        class: "subheading",
                        value: 'Requester'
                    },
                    {
                        text: Vue.prototype.$gettext("Initial Line Status"),
                        class: "subheading",
                        value: "SourceWorkFlowStatus"
                    },
                    {
                        text: Vue.prototype.$gettext("Target Line Status"),
                        class: "subheading",
                        value: 'TargetWorkFlowStatus'
                    },
                    {
                        text: Vue.prototype.$gettext("Transition"),
                        class: "subheading",
                        value: 'RequestedTransition'
                    },
                    {
                        text: Vue.prototype.$gettext("Transition Status"),
                        class: "subheading",
                        value: "Status"
                    },
                    {
                        text: Vue.prototype.$gettext("Transitions Log"),
                        class: "subheading",
                        value: "Logs"
                    },
                    {
                        text: Vue.prototype.$gettext("Transitions Error"),
                        class: "subheading",
                        value: "CommandError"
                    },
                ],
                transitionsLogHeaders: [
                    {
                        text: Vue.prototype.$gettext("Timestamp"),
                        class: "subheading",
                        value: '@timestamp'
                    },
                    {
                        text: Vue.prototype.$gettext("Extra Info"),
                        class: "subheading",
                        value: 'extrainfo'
                    },
                    {
                        text: Vue.prototype.$gettext("Message"),
                        class: "subheading",
                        value: 'message'
                    },
                    {
                        text: Vue.prototype.$gettext("Severity"),
                        class: "subheading",
                        value: "severity"
                    },
                    {
                        text: Vue.prototype.$gettext("Source"),
                        class: "subheading",
                        value: 'source'
                    }
                ],
            }
        },

    }
</script>

<style scoped>
    .v-btn:before {
        opacity: 0 !important;
    }

    .v-ripple__container {
        opacity: 0 !important;
    }
</style>
