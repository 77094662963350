

import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import Vue from 'vue'
import alarmSearchPatternDialog from '@/components/dialogs/AlarmSearchPatternDialog'
import Settings from '@/api/settings'
import VueInstance from './vueinstance'
import TimeTracking from '@/api/timetracking'
import audits from "./audits";

let alarmDescriptors = new Map();
let configuredDevices = null;
let fieldsExportImport = ["id", "suppress", "stopCondition", "requiresAnnotation", "stopCause", "severity"];

function loadDescriptors() {
    configuredDevices = [];
    Settings.list(Settings.AlarmsDescriptions)
        .then(devices => {
            for(let i = 0 ; i < devices.length ; i++) {
                if(devices[i].Name)
                    configuredDevices.push(devices[i].Name);
            }

            for (let device of configuredDevices) {
                Settings.load(Settings.AlarmsDescriptions, device)
                    .then(alarmDescriptor => {
                        alarmDescriptors.set(device, alarmDescriptor);
                    })
                    .catch(err => {
                        debugger
                    });
            }
        })
        .catch(err => {
            debugger;
        });
}

function mapOut(sourceObject, fields) {
    const sourceKeys = Object.keys(sourceObject);
    const returnKeys = sourceKeys.filter(k => fields.includes(k));
    if(!Array.isUseful(returnKeys))
        return null;
    let returnObject = {};
    returnKeys.forEach(k => {
        let valueObject = String(sourceObject[k]).toLowerCase();
        if (valueObject === 'true' || valueObject === 'false') {
            if (valueObject === 'true')
                sourceObject[k] = 1;
            else
                sourceObject[k] = 0;
        }
        //Excel could be import id (only full digit id) as numeric, so it must be converted to string
        if (k === 'id')
            sourceObject[k] = sourceObject[k].toString();
        returnObject[k] = sourceObject[k];
    });
    if (!returnObject.hasOwnProperty('en')) {
        returnObject.en = "";
    }

    return returnObject;
}

function filterImportedData(importedData) {
    //The headers array from excel could have less fields than records array
    importedData.headers = Object.keys(importedData.records[0]);
    //countryListCode
    let clc = importedData.headers.filter( countryCode => countryCode.length === 2);
    // Merge array without duplicates
    fieldsExportImport = [...new Set([...fieldsExportImport, ...clc])];

    let filtered = importedData.records.map(obj => mapOut(obj, fieldsExportImport));
    return filtered.filter(obj => Object.isUseful(obj))
}

function formatExportData(sourceObject) {
    const sourceKeys = Object.keys(sourceObject);
    let returnObject = {};
    sourceKeys.forEach(k => {
        let valueObject = String(sourceObject[k]).toLowerCase();
        if (valueObject === '1' || valueObject ==='0') {
            if(valueObject ==='1')
                sourceObject[k] = "TRUE";
            else
                sourceObject[k] = "FALSE";
        }
        returnObject[k] = sourceObject[k];
    });

    return returnObject;
}

function filterExportedData(exportedData) {
    let newArray = exportedData.map(obj => { return {...obj}});
    return newArray.map(obj => formatExportData(obj));
}

export default {

    loadDescriptors: loadDescriptors,
    filterImportedData: filterImportedData,
    filterExportedData: filterExportedData,
    getAlarmsDescriptors() {
        return alarmDescriptors;
    },

    updateAlarmsDescriptor(device, descriptor) {
        alarmDescriptors.set(device, descriptor);
    },

    checkCategoryChanges() {
        alarmDescriptors.forEach(async (descriptor,device)=>{
            let saveDescriptor=false;
            descriptor.alarmsDescriptions.forEach(async (ad) => {
               if(ad.stopCause){
                    //check if timetracker is valid getTimeTrackerFromId
                    if(TimeTracking.getTimeTrackerFromId(ad.stopCause).length>0){
                        let categories = TimeTracking.getTimeTrackerIds(ad.stopCause);
                        let category = TimeTracking.getCategory(categories);
                        let saveAudit = false
                        if(ad.category){
                            if(category.category===-1){
                                //current category deleted
                                ad.category=-1
                                //audit
                                audits.save(VueInstance.get().$root.userName, audits.items().lineStopCausesSystemCategoryDeleted, "", "", "")
                                    .catch(err => {
                                        debugger
                                        VueInstance.get().$root.showErrorNotification(VueInstance.get().$gettext("An error occurred while saving audits to DB"), true);
                                    });
                                saveAudit=true
                            }else if(category.category!==ad.category){
                                //current category updated
                                ad.category = category.category;
                                saveAudit=true
                            }
                        }
                        if (saveAudit){
                            saveDescriptor=true
                            audits.save(self.user, audits.items().alarmChangeSystemCategoryDetected, "", "", device + "." + ad.id)
                                .catch(() => {
                                    debugger
                                    VueInstance.get().$root.showErrorNotification(Vue.prototype.$gettext("An error occurred while saving audits to DB"), true);
                                });
                            let trackerId = TimeTracking.getTimeTrackerId(categories);
                        }
                    }
                }
            });
            if(saveDescriptor){
                await Settings.save(descriptor, Settings.AlarmsDescriptions, device)
                    .catch(err => {
                        console.error(err);
                    })
            }
        })
    },

    validateAlarmIdentifier(alarmsDescriptions, newId) {
        const idExists = alarmsDescriptions.some(alarmDescription => alarmDescription.id === newId);
        return idExists;
    },

    getAlarmId(id, cleanOnlyPrefix) {
        //Alarms suffix could or could not have the underscore
        id = id.replace("Alarm_", "");
        id = id.replace("Alarm", "");

        if(cleanOnlyPrefix)
            return id;

        //APicaro in case alarms are obtained from different variables, the ids will be in the form Alarm_idvar_idalarm
        //to obtain the full alarm index we should multiply the tokens
        if(id.includes("_")) {
            let ids = id.split("_");
            ids = ids.filter(item => !isNaN(item));

            if(ids.length === 3)  {
                id = parseInt(ids[0]) * parseInt(ids[1]) + parseInt(ids[2]);  //Base variable size is declare in id
            } else if(ids.length === 2)  {
                id = parseInt(ids[0]) + parseInt(ids[1]);  //Variable encoded with offset
            } else return null;
        }
        return id;
    },

    getDescriptorById(id, device, forceResolution) {

        if((!id.startsWith("Alarm") && !forceResolution) || !device) //Not an alarm
            return null;

        if(device.endsWith(".Alarms"))
            device = device.replace(".Alarms", "");

        let rawId = id;

        let defaultDescriptor = {
            id: rawId,
            description: id,
            requiresAnnotation: false,
            suppress: false,
            stopCause: 0,
        };
        let errorDescriptor = {
            id: rawId,
            description: id + " - " + VueInstance.get().$gettext("Bad formatted alarm identifier"),
            requiresAnnotation: false,
            suppress: false,
            stopCause: 0,
        };

        // if(!id.startsWith("Alarm"))
        //     return errorDescriptor;

        if (!Array.isUseful(configuredDevices)) {
            //this.loadDescriptors(); //Retry loading descriptors, just in case they were somewhat loaded without a refresh
            //VueInstance.get().$root.showWarningNotification( VueInstance.get().$gettext("Device {0} is raising alarms but has no alarms configured in Avionics.").format(device), true, true);
            return defaultDescriptor;
        }

        let descriptor = alarmDescriptors.get(device);

        if (descriptor) {

            let language = ( VueInstance.get().$language.current ==='default') ? 'en' :  VueInstance.get().$language.current;
            let idPatternDescription = [];

            //Manage literal id matching
            if(descriptor.alarmSearchPattern === "*") {
                idPatternDescription.push(rawId, this.getAlarmId(rawId, true));   //We try the id as is and without Alarm_ prepend since we don't really know the format of identifiers
            } else {
                id = this.getAlarmId(rawId);
                if(id === null)
                    return errorDescriptor;
                //padding on id pattern
                idPatternDescription.push(descriptor.alarmSearchPattern.format(id), descriptor.alarmSearchPattern.format('0'+id), descriptor.alarmSearchPattern.format('00'+id), descriptor.alarmSearchPattern.format('000'+id));
            }

            for (let alarmDescription of descriptor.alarmsDescriptions) {
                for (let id of idPatternDescription) {
                    if (alarmDescription['id'] === id) {
                        return {
                            id: alarmDescription['id'],
                            description: alarmDescription[language],
                            requiresAnnotation: alarmDescription['requiresAnnotation'],
                            suppress: alarmDescription['suppress'],
                            stopCause: alarmDescription['stopCause'],
                            stopCondition: alarmDescription['stopCondition'],
                        };
                    }
                }
            }
            //Here we simplified a little bit alarms management. Frequently machine is throwing "named" alarms not just numeric.
            //In this case alarm name is frequently enough as is. To simplify, we impose that if pattern is "*" (user somewhat configured the alarms
            //and descriptor is empty, it will just use id as name and don't bother further.
            if(descriptor.alarmSearchPattern === "*") {
                defaultDescriptor.description = id;
                return defaultDescriptor;
            }
        }
        //VueInstance.get().$root.showWarningNotification( VueInstance.get().$gettext("Alarm {0} from device {1} is not configured or is not matched with configured alarms. Please review alarms and id pattern configuration.").format(rawId, device), true, true);
        return defaultDescriptor;
    },

    formatAlarmName(alarmRawId, device) {
        let descriptor = this.getDescriptorById(alarmRawId, device);
        if(descriptor) {
            return descriptor.id + " - " + descriptor.description;
        }
        return "";
    },

    formatAlarms(alarms, index = 1) {
        let alarmsInfo =[];
        for (let alarm of alarms) {
            let descriptor = this.getDescriptorById(alarm['Id'], alarm.tag['Device'], true);
            if(descriptor) {
                let alarmInfo = {
                    timestamp: Date.parse(alarm["@timestamp"]),
                    active: alarm["Active"],
                    device: alarm.tag['Device'],
                    alarmRawIdentifier: alarm['Id'],
                    alarmIdentifier: descriptor.id,
                    alarmDescription: descriptor.description,
                    requiresAnnotation: Object.isUseful(descriptor.requiresAnnotation) ? descriptor.requiresAnnotation : false,
                    suppress: Object.isUseful(descriptor.suppress) ? descriptor.suppress : false,
                    stopCause: descriptor.stopCause ? descriptor.stopCause : '',
                    stopCondition: Object.isUseful(descriptor.stopCondition) ? descriptor.stopCondition : false,
                    refid: alarm.RefId,
                    index: index++,
                };
                if (!alarmInfo.suppress)
                    alarmsInfo.push(alarmInfo);
            }
        }
        return alarmsInfo;
    },

    formatAlarmsGroups(alarms) {
        let alarmsInfo =[];
        let othersAlarmsGroup = {group: 'Non stop related alarms', suitable: false, active: false, items: []};
        let alarmSuitableGroup = {group: 'Suitable alarms', suitable: true, active: false, items: []};
        let index = 1;

        Object.keys(alarms).forEach(key => {
            let alarmsGroup = alarms[key];
            let formattedAlarms = alarmsGroup.length > 0 ? this.formatAlarms(alarmsGroup, index) : [];
            if (formattedAlarms.length > 0) {
                // allOthersGroup.active =true;
                if(key==='alarmsOther') {
                   othersAlarmsGroup.items = [...formattedAlarms];
                   alarmsInfo.push(othersAlarmsGroup);
                }
                else {
                    alarmSuitableGroup.items = [...formattedAlarms];
                    alarmsInfo.unshift(alarmSuitableGroup);
                }
                index += formattedAlarms.length;
            }
        });
        //The first group is active (with 2 groups, the most probable groups is active to open its expansion panel by default)
        if (Array.isUseful(alarmsInfo))
            alarmsInfo[0].active = true;

        return alarmsInfo;
    },

    async getAlarmsList(timeFrom, timeTo, index, byIndex, devicesToFilter) {

        let query = "";
        if(byIndex)
            query = "?index=" + index + ";time=@timestamp," + encodeURIComponent(timeFrom) + "," + encodeURIComponent(timeTo) + (Array.isUseful(devicesToFilter) ? "&filter=tag.Device.keyword,terms," + devicesToFilter.join(",") + ",=" : "");
        else
            query = "?time=@timestamp," + encodeURIComponent(timeFrom) + "," + encodeURIComponent(timeTo) + (Array.isUseful(devicesToFilter) ? "&filter=tag.Device.keyword,terms," + devicesToFilter.join(",") + ",=" : "");

        return new Promise((resolve, reject) => {

            OrchestratorAPI.proxyCall('get', Microservices.alarmsUrl + query)
            //Api().get(query)
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    console.log(t);
                    reject("Error in retrieving alarms list");
                });
        });
    },

    async annotateAlarm(id, operator, cause, subcause, description) {

        let query = "/events?refId=" + id + "&operator=" + operator + "&reason=" + JSON.stringify( {"cause": cause, "subcause": subcause, "description": description } );

        //let query = "/event/AnnotateEvent?refId=" + id + "&operator=" + operator + "&reason=" + JSON.stringify( {"cause": cause, "subcause": subcause, "description": description } );

        return new Promise((resolve, reject) => {

            OrchestratorAPI.proxyCall('post', Microservices.queryUrl + query)
            //Api().post(query)
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    console.log(t);
                    reject("Error in retrieving alarms list");
                });
        });
    },

    openAlarmSearchPatternDialog(parent, user, alarmSearchPattern, alarmSearchPatternIndices, alarmIdentifier, alarmCallBack = null, checkSearchPatternCallBack = null) {
        //Create widget
        let componentClass = Vue.extend(alarmSearchPatternDialog);
        let dialog = new componentClass();
        dialog.$mount();
        dialog.setCaller(parent);
        dialog.Show(user, alarmSearchPattern, alarmSearchPatternIndices, alarmIdentifier, alarmCallBack, checkSearchPatternCallBack);
    },

    checkSearchPattern(searchPattern, alarmsIdentifiers) {
        if(searchPattern === "*")
            return null;
        let splittedSearchPattern = searchPattern.replace('{0}', '//{0}//');
        splittedSearchPattern = splittedSearchPattern.split('//');
        //remove possible empty string from array
        splittedSearchPattern = splittedSearchPattern.filter(item => item);

        let  regExpSource = '';
        splittedSearchPattern.forEach(elem => {
            regExpSource += elem !== '{0}' ? elem : '[0-9]+';
        });
        let regExp = new RegExp(regExpSource);
        for (let i = 0; i < alarmsIdentifiers.length; i++) {
            let arrayRegExp = regExp.exec(alarmsIdentifiers[i].id);
            if (Array.isUseful(arrayRegExp)) {
                if (arrayRegExp[0] !==alarmsIdentifiers[i].id ) {
                    return alarmsIdentifiers[i].id
                }
            }
            else {
                return alarmsIdentifiers[i].id ? alarmsIdentifiers[i].id : i;
            }
        }
        return null;
    },
}