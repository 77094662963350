import axios from 'axios'

export default ({ app, router, store, Vue }) => {
  // We create our own axios instance and set a custom base URL.
  // Note that if we wouldn't set any config here we do not need
  // a named export, as we could just `import axios from 'axios'`
  const axiosInstance = axios.create({
    baseURL:  Vue.prototype.$getBaseURL() + `/api/v1`,
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })

  axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers.common['Authorization'] = 'Bearer ' + Vue.prototype.$store.state.shared.token

    // Set calling api to show the server loading status
    // if (config.url !== '/auth/check' && config.url !== '/orchestrator/system/status' && config.url !== '/auth/orchestrator/notify') {
    //   Vue.prototype.sendCallingAPIStarted()
    // }

    // attach random parameter avoiding browser cache
    let rand = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    if (config.url.includes('?')) {
      config.url = config.url + '&r=' + rand
    } else {
      config.url = config.url + '?r=' + rand
    }
    return config
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })

  axiosInstance.interceptors.response.use(
    function (response) {
      if (!response.config.url.startsWith('/auth/check') && !response.config.url.startsWith('/orchestrator/system/status') && !response.config.url.startsWith('/auth/orchestrator/notify')) {
        Vue.prototype.sendCallingAPICompleted()
      }
      return response
    }, function (error) {
        if (error.response !== undefined) {
          if (!error.response.config.url.startsWith('/auth/check') && !error.response.config.url.startsWith('/orchestrator/system/status') && !error.response.config.url.startsWith('/auth/orchestrator/notify')) {
            Vue.prototype.sendCallingAPICompleted()
          }
          let retVal = {}
          if (error.response.status === 401) {
            retVal = { data: { Status: 'failed', ErrorMessage: { Message: 'Please make login before to proceed', Parameters: [] } } }
          } else if (error.response.status === 410) {
            retVal = { data: { Status: 'failed', ErrorMessage: { Message: 'Authenticated session expired', persist: 'true', Parameters: [] } } }
          } else if (error.response.status === 423) {
            retVal = { data: { Status: 'failed', ErrorMessage: { Message: 'License key not valid or expired', Parameters: [] } } }
          } else if (error.response.status === 400) {
            retVal = { data: { Status: 'failed', ErrorMessage: { Message: 'Wrong username and password', Parameters: [] } } }
          } else if (error.response.status === 429) {
            retVal = { data: { Status: 'failed', ErrorMessage: { Message: 'Too many requests. User has been locked', Parameters: [] } } }
          } else if (error.response.status === 403) {
            retVal = { data: { Status: 'failed', ErrorMessage: { Message: 'The request is forbidden', Parameters: [] } } }
          } else {
            return Promise.reject(error)
          }
          //  logout()
          return retVal
        } else {
          return Promise.reject(error)
        }
      })

  let logout = function () {
    // Vue.prototype.OrchestratorAPI.notifyEvent('OnLogoutRequest', {})
  }

  // for use inside Vue files through this.$axios
  Vue.prototype.$axios = axiosInstance
}
