import DataApis from "./data";
import Config from "@/api/config"

export default {

    async getAvailable(from, to, index) {
        if(!Config.isAvionics)
            return [];
        return new Promise((resolve, reject) => {
            DataApis.getDistinctValues(index || 'production counters', 'Line', 'PhysicalLineId', 'keyword', from, to)
                .then(lines => {
                    if (!Array.isUseful(lines))
                        lines = [];
                    resolve(lines);
                })
                .catch(err => {
                    console.log(err);
                    reject("Unable to discover available devices");
                });
        });
    }
}