import { render, staticRenderFns } from "./IconSelector.vue?vue&type=template&id=e33ed60c&scoped=true&"
import script from "./IconSelector.vue?vue&type=script&lang=js&"
export * from "./IconSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e33ed60c",
  null
  
)

export default component.exports