<template>
  <v-expansion-panel v-bind="$attrs" v-on="$listeners" :label="translatedLabel" :id="computedID">
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'av-expansion-panel',
  props: {
    translateLabel: { required: false, default: true },
    dynamicID: { required: false, default: '' },
  },
  data () {
    return {}
  },
  computed: {
    computedID () {
      if (!this.$isEmpty(this.$attrs.id)) {
        return this.$attrs.id
      }
        let dynamicText = this.$attrs.label;
        if(dynamicText)
            dynamicText = dynamicText.trim();
        else dynamicText = "";
        let dynamicPageName = this.$router.history.current.params.routeId ? this.$router.history.current.params.routeId : "";
      return this.toCamelCase(this.$router.history.current.name + dynamicPageName + this.dynamicID + 'ExpansionPanel' + this.$attrs.label.trim())
    },
    translatedLabel () {
      if (this.$attrs.label === undefined){
        this.$attrs.label = ""
      }
      return this.$attrs.label;
    }
  },
  mounted () {},
  methods: {}
}
</script>