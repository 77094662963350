<template>
    <v-container id="DashBoardContainer" style="min-width: 100%; max-width: 100vw; height: 100%;" ma-0 pa-2>
        <template v-if="dashboardPropertiesDialog">
            <v-dialog v-model="dashboardPropertiesDialog" max-width="510" :persistent="true" data-qa-type="dialog" data-qa-name="dashboard-properties">
                <v-card>
                    <v-toolbar card dense>
                        <v-toolbar-title v-translate>Choose Dashboard Properties</v-toolbar-title>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field class="mt-4 mb-4 osk" clearable single-line :placeholder="$i18n.CommonAttributes()['type here']" :prefix="lbName" v-model="dashboardName" data-qa-type="input-text" data-qa-name="name"/>
                        <v-combobox pa-0 validate-on-blur placeholder="Select or create dashboards group" v-model="dashBoardGroup" @input.native="dashBoardGroup=$event.srcElement.value" :items="groups" :label="lbGroup" data-qa-type="input-select" data-qa-name="group"/>
                        <v-checkbox class="mt-4 mb-4" :label="lbMaximizable" v-model="maximizable" data-qa-type="input-checkbox" data-qa-name="maximizable"/>
                        <av-multi-select  v-if="!maximizable" :placeholder="$i18n.CommonAttributes()['select']"
                                          item-text="show" :label="lbMinView + ':'"
                                          :items="$grants.getLevels()" v-model="showLevel" :manage-everyone="true" :return-object="false" :keep-selected="['av.administrator']" data-qa-type="input-multiselect" data-qa-name="visible-to-levels"></av-multi-select>

                        <av-multi-select  :placeholder="$i18n.CommonAttributes()['select']"
                                          item-text="show" :label="lbMinEdit + ':'"
                                          :items="$grants.getLevels()" v-model="editLevel" :manage-everyone="true" :return-object="false" :keep-selected="['av.administrator']" data-qa-type="input-multiselect" data-qa-name="editable-to-levels"></av-multi-select>

                        <av-multi-select  :placeholder="$i18n.CommonAttributes()['select']" class="mt-5"
                                          item-text="show" :label="lblExposable + ':'"
                                          :items="$grants.getLevels()" v-model="exposableToLevels"
                                          :manage-everyone="true" :return-object="false"  data-qa-type="input-multiselect" data-qa-name="exposable-to-levels"></av-multi-select>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn v-if="readyToCreate" color="blue darken-1" flat="flat" @click="saveProperties(), dashboardPropertiesDialog=false">
                            <translate>Confirm properties and go to dashboard</translate>
                        </v-btn>
                        <v-btn color="red darken-1" flat="flat" @click="closeDialog">
                            <translate>
                                Cancel
                            </translate>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template v-else>
            <div v-if="isSplitScreen" style="height: 97%;">
                <DashBoardElement :key="splitViewMode" :descriptor="loadSplitScreenDescriptor" :visualizations="visualizations" :splitScreenView="isSplitScreen" :globalParams="globalParams" :play="play" :maximized="command === 'maximize'" :zoom="zoom" v-on:globalParamUpdated="globalParamUpdated($event)" v-on:OnSplitScreenWidget="OnSplitScreenWidget($event)" v-on:OnChangeViewMode="OnChangeViewMode($event)" @visualizationsLoaded="onVisualizationLoaded($event)" style="height: 100%"/>
            </div>
            <div v-show="fullScreenWidget" ref="fullScreenWidget" style="position:absolute; top: 8px; height: calc(100% - 16px); padding-right:16px; width:100%"></div>
            <DashBoardElement v-if="descriptor && !$root.isMobile" key="desktop" :visualizations="visualizations" v-show="!fullScreenWidget && !isSplitScreen" :descriptor="descriptor" :globalParams="globalParams" :play="play" :maximized="command === 'maximize'" :zoom="zoom" v-on:changed="changed" v-on:globalParamUpdated="globalParamUpdated($event)" v-on:OnFullScreenWidget="OnFullScreenWidget($event)" v-on:OnSplitScreenWidget="OnSplitScreenWidget($event)" @visualizationsLoaded="onVisualizationLoaded($event)" style="height: calc(100%);"/>
            <div v-else>
            <template v-if="$root.isMobile && mobileDescriptor" v-for="widget in mobileDescriptor">
                <DashBoardElement v-if="widget" :key="'mobile'+widget.widget" :visualizations="visualizations" do-not-edit v-show="!fullScreenWidget && !isSplitScreen" :descriptor="widget" :globalParams="globalParams" :play="play" :maximized="command === 'maximize'" :zoom="zoom" v-on:changed="changed" v-on:globalParamUpdated="globalParamUpdated($event)" v-on:OnFullScreenWidget="OnFullScreenWidget($event)" v-on:OnSplitScreenWidget="OnSplitScreenWidget($event)" @visualizationsLoaded="onVisualizationLoaded($event)"/>
            </template>
            </div>
        </template>
    </v-container>
</template>

<script>
    import DashBoardElement from '../../components/DashboardElement.vue'
    import DashboardsApi from "@/api/dashboards";
    import Utils from "@/api/jsutils"

    export default {
        name: "Dashboard",
        components: {DashBoardElement},
        data() {
            return {
                descriptor: null,
                play: false,
                newDashBoard: false,
                edit: false,
                clone: false,
                maximized: false,
                dashboardPropertiesDialog: false,
                dashboardName: '',
                dashBoardGroup: '',
                maximizable: false,
                showLevel: [],
                editLevel: [],
                exposableToLevels:[],
                globalParams: null,
                loaded: false,
                groups: [],
                fullScreenWidget:null,
                splitScreenWidget:null,
                isSplitScreen: false,
                widgetName: '',
                splitViewMode: 2,
                visualizations:[]
            }
        },
        props: {
            embeddedDash: {
                type: String,
                default: ""
            },
        },
        watch: {
            dashToLoad() {
                this.load();
            },
            command() {
                this.load();
            },
            applicationStartedUp() {
                if(this.maximized && this.$root.startedUp && !this.loaded)
                    this.loadDashboard();
            }
        },
        computed: {
            loadSplitScreenDescriptor() {
                return {
                    "id":"0",
                    "state": this.splitViewMode,
                    "splitRatio":50,
                    "children":{
                        "zero":{
                            "id":"00",
                            "state":0,
                            "splitRatio":50,
                            "parentState":  this.splitViewMode,
                            "children":{},
                            "widget":this.widgetName,
                            "splitScreenView":this.isSplitScreen
                        },
                        "one":{
                            "id":"01",
                            "state":0,
                            "splitRatio":50,
                            "parentState":  this.splitViewMode,
                            "children":{},
                            "widget":this.widgetName,
                            "splitScreenView":this.isSplitScreen
                        }
                    },
                    "maximizable":true,
                    "widget":this.widgetName,
                }
            },
            mobileDescriptor() {
                let descriptorCrawler = function(descriptor, target) {
                    if(descriptor.state === 0 && descriptor.widget)
                        target.push({state: 0, widget: descriptor.widget, children: {zero: null, one: null}});
                    else {
                        if(descriptor.children) {
                            if (descriptor.children.zero)
                                descriptorCrawler(descriptor.children.zero, target);
                            if (descriptor.children.one)
                                descriptorCrawler(descriptor.children.one, target);
                        }
                    }
                };
                if(this.$root.isMobile) {
                    if(this.descriptor) {
                        let returning = [];
                        descriptorCrawler(this.descriptor, returning);
                        return returning
                    }
                }
                return [];
            },
            readyToCreate() {
                if(this.embeddedDash)
                    return false;
                if(!(Object.isUseful(this.dashboardName) && this.dashboardName.length > 0))
                    return false;
                let error = this.$gettext("A dashboard with this name already exists, please choose a different one");
                if(!DashboardsApi.validateName(this.dashboardName) && (!this.edit || (this.edit && this.dashToLoad !== this.dashboardName))) {
                    this.$root.showErrorNotification(error, false, true);
                    return false;
                } else this.$root.clearNotifications([error]);

                this.$root.setCurrentPageTitle(this.dashboardName.replace("builtin-", ""));

                return true;
            },
            dashToLoad() {
                return (this.embeddedDash || this.$route.params.routeId);
            },
            command() {
                return (this.embeddedDash ? 'show' : this.$route.params.command);
            },
            zoom() {
                return (this.embeddedDash ? 100 : (this.$route.params.zoom || 100));
            },
            globalParam(){
                return this.$route.query.globalParams
            },
            lbMaximizable() {
                return this.$gettext("Allow maximization (No login, visible to anyone)")
            },
            lbName() {
                return this.$gettext("Name:")
            },
            lbGroup() {
                return this.$gettext("Group")
            },
            lbMinView() {
                return this.$gettext("User levels that can see dashboard")
            },
            lbMinEdit() {
                return this.$gettext("User levels that can edit dashboard")
            },
            lblExposable(){
                return this.$gettext('These users can see menu link to this item')
            },
            applicationStartedUp() {
                return this.$root.startedUp;
            }
        },
        beforeMount() {
            if(this.$store.state.isEmbedded){
                this.waitPageLoad()
            }
            if(!this.applicationStartedUp) {
                this.$root.applicationStartUp("dash")
            }
            this.load();
        },
        mounted(){},
        methods: {
            loadDashboardsGroups() {
                let self = this;
                DashboardsApi.loadAll(true,true)
                    .then((dashboards) => {
                        if (Array.isUseful(dashboards)) {
                            self.groups = [];
                            for (let dashboard of dashboards) {
                                if (dashboard.descriptor) {
                                    let descriptor = JSON.parse(dashboard.descriptor);
                                    if (Object.isUseful(descriptor.group) && !self.groups.includes(descriptor.group))
                                        self.groups.push(descriptor.group);
                                }
                            }
                        }
                    })
            },
            switchDashBoard(descriptor) {
                let self = this;
                if (this.descriptor != null) {
                    this.descriptor = null;
                    setTimeout(function () {
                        self.descriptor = descriptor;
                    }, 1000);
                } else {
                    this.descriptor = descriptor;
                }
            },
            async waitPageLoad() {
                await this.waitForPageLoading()
            },
            load() {
                this.fullScreenWidget = undefined;
                this.splitScreenWidget = undefined;
                if (this.$refs.fullScreenWidget && this.$refs.fullScreenWidget.childNodes.length > 0)
                    this.$refs.fullScreenWidget.childNodes[0].remove();
                this.newDashBoard = (this.command === 'create');
                this.edit = (this.command === 'edit');
                this.clone = (this.command === 'clone');
                this.maximized = (this.command === 'maximize');
                this.play = (this.command === 'show' || this.maximized);
                if (this.newDashBoard || this.edit || this.clone)
                    this.loadDashboardsGroups(true,true);

                // if(this.zoom) {
                //     if(this.zoom.endsWith("%"))
                //         document.body.style.zoom = this.zoom;
                //     else document.body.style.zoom = this.zoom + "%";
                // }

                this.maximize(this.maximized);

                if (this.newDashBoard) {
                    this.descriptor = {
                        id: "0",
                        state: 0,
                        splitRatio: 50,
                        children: {zero: null, one: null}
                    };
                    this.dashboardName = "";
                    this.maximizable = true;
                    this.showLevel = ["all"];
                    this.editLevel = ["all"];
                    this.exposableToLevels = ["all"];
                    this.$root.setCurrentPageTitle(this.$gettext("New dashboard"));
                } else {
                    this.dashboardName = this.dashToLoad;

                    //If we are maximized, it's possible that app is not started yet at this point, this would result in fireworks,
                    //we wait watch on application startup to proceed loading dashboard
                    if (!this.maximized)
                        this.loadDashboard();
                    if (this.clone)
                        this.dashboardName = this.dashToLoad + " Clone";

                    if (!this.embeddedDash)
                        this.$root.setCurrentPageTitle(this.dashToLoad.replace("builtin-", ""));
                }
                if (this.newDashBoard || this.edit || this.clone)
                    this.dashboardPropertiesDialog = true;
            },
            maximize(maximize) {
                this.$root.hideFrame = maximize;
            },
            loadDashboard() {
                let self = this;
                DashboardsApi.LoadDashboard(this.dashboardName)
                    .then(t => {
                        if (t.descriptor) {
                            let descriptor = JSON.parse(t.descriptor);
                            if (self.command === 'maximize' && !self.$root.userName && (!Object.isUseful(descriptor.maximizable) || descriptor.maximizable === false))
                                self.$router.push('login'); //We are not authorized to maximize this dash, kick back to login
                            else {
                                self.maximizable = descriptor.maximizable;

                                self.editLevel = t.grants.editableToLevels;
                                self.exposableToLevels=t.grants.exposableToLevels;
                                self.showLevel = t.grants.visibleToLevels;
                                // self.showLevel = descriptor.showLevel === null ? ["all"]:descriptor.showLevel;
                                // self.editLevel = descriptor.editLevel === null ? ["all"]:descriptor.editLevel;
                                self.dashBoardGroup = descriptor.group || "";
                                self.switchDashBoard(descriptor);
                            }
                        }
                    })
                    .catch(err => {
                        debugger
                        console.log(err);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving saved visualizations from DB."), true);
                    })
                    .finally(() => {
                        self.loaded = true;
                    });
            },
            changed(descriptor) {
                this.descriptor = descriptor;
                //console.log(JSON.stringify(this.descriptor));
                if (!this.play)
                    this.saveDash();

                this.globalParams = this.globalParam
            },
            saveProperties() {
                let self = this;
                this.descriptor["maximizable"] = this.maximizable;
                this.descriptor["showLevel"] = !Array.isUseful(this.showLevel) || (this.showLevel.length === this.$grants.getLevels().length) ? null : this.showLevel;
                this.descriptor["editLevel"] = !Array.isUseful(this.editLevel) || (this.editLevel.length === this.$grants.getLevels().length) ? null : this.editLevel;
                this.descriptor["exposableToLevels"] = !Array.isUseful(this.exposableToLevels) || (this.exposableToLevels.length === this.$grants.getLevels().length) ? null : this.exposableToLevels;
                this.descriptor["group"] = this.dashBoardGroup;
                let actionTrail = this.newDashBoard ? this.$audits.items().dashboardCreated : this.$audits.items().dashboardModified;
                let previousValTrail = this.dashboardName !== this.dashToLoad && !this.newDashBoard ? this.dashToLoad : "";
                let nextValTrail = this.dashboardName !== this.dashToLoad && !this.newDashBoard ? this.dashboardName : "";
                let actionTrailId = this.dashboardName;
                DashboardsApi.SaveDashboard(this.dashboardName, this.descriptor)
                    .then(() => {
                        self.$audits.save(self.$root.userName, actionTrail, previousValTrail, nextValTrail, actionTrailId)
                            .catch(err => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    });
                if (this.dashboardName !== this.dashToLoad && !this.newDashBoard && !this.clone)
                    DashboardsApi.DeleteVisualization(this.dashToLoad);
                this.$root.reloadDynamicLinks = true;
            },
            saveDash: function () {
                if (!this.play) {
                    DashboardsApi.SaveDashboard(this.dashboardName, this.descriptor);
                }
            },
            globalParamUpdated(params) {
                this.globalParams = params;
            },
            OnFullScreenWidget(param) {
                this.fullScreenWidget = param;
                if (this.fullScreenWidget) {
                    this.$refs.fullScreenWidget.insertBefore(this.fullScreenWidget.$el, this.$refs.fullScreenWidget.firstChild);
                } else if (this.$refs.fullScreenWidget && this.$refs.fullScreenWidget.childNodes.length > 0)
                    this.$refs.fullScreenWidget.childNodes[0].remove();
            },
            OnChangeViewMode(param) {
                if (!param)
                    this.splitViewMode = 1
                else
                    this.splitViewMode = 2
            },
            OnSplitScreenWidget(param) {
                this.isSplitScreen = param.isSplitScreen
                this.splitScreenWidget = param
                this.widgetName = param.properties.name
            },
            closeDialog() {
                this.descriptor = null;
                this.dashboardPropertiesDialog = false;
                this.$router.push("/explore/dashboard", {})
            },

            onVisualizationLoaded(visualizations){
                this.visualizations=visualizations
            }
        }
    }
</script>

<style scoped>

    /*body {*/
    /*display: none;*/
    /*}*/

    /*.page-container-section + .page-container-section {*/
    /*margin-top: 10px;*/
    /*}*/

</style>
