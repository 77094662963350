<template>
    <v-container ma-1 pa-1>
        <!--        <PieChart ref="graph" :class="clockFaceStyle" style="width: 100%;position: relative; top:-100px" :chartdata="chartData" :options="pieOptions"></PieChart>-->
        <PieChart ref="graph" :style="cssProps" :chartdata="chartData" :key="key" :options="pieOptions"></PieChart>
    </v-container>
</template>

<script>

    import PieChart from '@/components/graphics/PieChart.vue'
    import JsUtils from '@/api/jsutils.js'
    import dateTimeUtils from '@/api/datetimeutils.js'

    export default {
        name: "WidgetTimeSpanClock",
        components: {
            PieChart,
            dateTimeUtils,
        },
        data () {
            return {
                timer: null,
                chartData: {
                    labels: [],
                    datasets: []
                },
                pieOptions: {
                    responsive: true,
                    //maintainAspectRatio: true,
                    animation: false,
                    tension: 0.4,
                    propagate: false,
                    plugins: {
                        legend: {
                            display: false,
                            position: "top"
                        },
                        datalabels: {
                            display: false,
                        }
                    }
                    //aspectRatio: 1,
                    //rotation: (-0.5 * Math.PI)   + ((this.timeStart/24) * 2 * Math.PI)

                },
                totalMsecHours: 86400000,
                cssProps: {
                    backgroundImage: `url(${require('@/assets/24hoursClockFace.png')})`,
                    backgroundSize: "100% 100%",
                    //width: "100%",
                    // position: "relative",
                    // top:"-100px",
                },
                key: null
            }
        },
        props: ['timeStart', 'timeEnd'],

        mounted () {
            let self = this;
            this.timer = setInterval(function() { //Unwrap new data based on dataItems descriptor and print to view

                //start work in ms
                let  timeStartMs  = Date.parseTimeString(self.timeStart)
                //end work in ms
                let timeEndMs  = Date.parseTimeString(self.timeEnd)

                if(!isNaN(timeStartMs) && !isNaN(timeEndMs)) {
                    let minutes =  Math.floor(timeStartMs / 60000)
                     self.pieOptions.rotation = minutes * 0.25

                    //let now = Date.parseTimeString("18:00")
                    let currentDateTime = (new Date()).format("HH:mm:ss")
                    let now = Date.parseTimeString(currentDateTime)


                    //timeworked in ms
                    let workShift = 0

                    //timeworking left
                    let workShiftLeft = 0

                    //total workingtime
                    let totalWorkShift = 0

                    let freeTime = self.totalMsecHours
                    let deltaTime = 0
                    //
                    if (timeStartMs > timeEndMs) {

                        totalWorkShift = self.totalMsecHours - timeStartMs + timeEndMs
                        if (now >= timeStartMs) {
                            workShift = now - timeStartMs
                        } else if (now < timeStartMs) {
                            workShift = self.totalMsecHours - timeStartMs + now
                        }

                        workShiftLeft = (totalWorkShift - workShift) > 0 ? totalWorkShift - workShift : 0
                        freeTime = self.totalMsecHours - totalWorkShift

                    } else {
                        deltaTime = timeEndMs - timeStartMs
                        freeTime = self.totalMsecHours - deltaTime
                        workShift = now - timeStartMs

                        workShiftLeft = timeEndMs - now
                        if (timeEndMs < now) {
                            workShift = deltaTime
                            workShiftLeft = 0
                        }

                        if ((timeStartMs > now) && (timeStartMs < timeEndMs)) {
                            workShift = 0
                            workShiftLeft = deltaTime
                            console.log('deltaTime: ' + deltaTime)
                        }

                    }

                    self.chartData = {
                        labels: ["Green", "Yellow", "Gray"],
                        datasets: [
                            {
                                label: "Data One",
                                backgroundColor: [JsUtils.ColorWithOpacity(self.$avStyle.colors.green, 40), JsUtils.ColorWithOpacity(self.$avStyle.colors.yellow, 40), JsUtils.ColorWithOpacity(self.$avStyle.colors.grey, 40)],
                                data: [workShift, workShiftLeft, freeTime],
                            }
                        ],
                    }
                    if (self.chartData.datasets[0])
                        self.key = self.chartData.datasets[0].data[0]
                }
            }, 1000)
        }
    }

</script>

<style scoped>
    /*.clockFaceStyle{*/

    /*    background-repeat: no-repeat;*/
    /*    background-attachment: fixed;*/
    /*    background-position: center;*/


    /*}*/

</style>
