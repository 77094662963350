<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card  class="ma-0 av-card" elevation="0">
            <v-card-text class="av-card-content">
                <v-layout wrap row>
                    <v-flex class="xs3" v-if="viewPanel">
                        <v-card flat style="border-right: 1px solid #eee">
                            <v-card-title>
                                <h3>Recipes</h3>
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="form" v-model="validForm" lazy-validation>
                                    <v-flex>
                                        <h4>
                                            <v-icon left>far fa-newspaper</v-icon>
                                            {{this.standardTimes.byRecipe.settings.times.value[this.selectedCell.row].recipe}}
                                        </h4>
                                        <br/>
                                        <h4>
                                            <v-icon left>far fa-newspaper</v-icon>
                                            {{this.selectedCell.key}}
                                        </h4>
                                    </v-flex>
                                    <br/>
                                    <v-flex>
                                        <TimeTrackerPicker  v-model="annotationCauses.value"/>
                                    </v-flex>
                                    <v-flex>
                                        <v-text-field label="Time" type="number" v-model="timing"></v-text-field>
                                    </v-flex>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1"
                                       flat="flat" v-translate @click="save()" :disabled="!validForm">
                                    OK
                                </v-btn>
                                <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1"
                                       flat="flat" v-translate @click="viewPanel=false">
                                    Cancel
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                    <v-flex :class="viewPanel?'xs9':''">
                        <ConfigurationsEditor :descriptor="standardTimes"  @value-Changed="valueChanged" @on-CellClick="onCellClick"></ConfigurationsEditor>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
    import TimeTrackerPicker from '@/components/TimeTrackerPicker';

    export default {
        name: "StandardTimes",
        components: {
            ConfigurationsEditor,TimeTrackerPicker
        },
        data() {
            return {
                recipes : [],
                standardTimes: {
                    byRecipe: {
                        visible: true,
                        groupName: this.$gettext("Recipe standard times"),
                        settings:
                            {
                                times: {
                                    name: this.$gettext("Standard change over durations"),
                                    keysLabel: this.$gettext("Recipes identifiers"),
                                    valuesLabel: this.$gettext("Standard time (minutes)"),
                                    value: [],
                                    rawValue : [],
                                    description: this.$gettext(""),
                                    type: "spreadSheet",
                                    typeSpreadSheet: "custom",
                                    ItemsAutoComplete: [],
                                    noMatchError: this.$gettext("Selected recipe is not in the data base"),
                                    multi: true,
                                    readOnly: false,
                                    visible: this.$grants.get().settings.nominalSpeeds,
                                    headers:[],
                                    fileName:"StandardTimes_",
                                    raw: true
                                }
                            }
                    },
                },
                viewPanel: false,
                validForm: false,
                selectedCell: {
                    row: 0,
                    key: "",
                    columnIndex: 0,
                },
                annotationCauses: {},
                timing: 0,
            }
        },

        mounted: function () {
            this.annotationCauses.value = [];
            this.annotationCauses.value.push(0);
            this.loadSettings();

        },

        methods: {
            save() {
                this.standardTimes.byRecipe.settings.times.rawValue[this.selectedCell.row][this.selectedCell.key] = !Array.isArray(this.annotationCauses.value) ? "StopCause: " + this.annotationCauses.value + ";" + "Timing: " + this.timing : "StopCause: " + this.annotationCauses.value[0] + ";" + "Timing: " + this.timing;
                this.standardTimes.byRecipe.settings.times.value[this.selectedCell.row][this.selectedCell.key] = !Array.isArray(this.annotationCauses.value) ? "StopCause: " + this.tracker(this.annotationCauses.value) + "\n" + "Timing: " + this.timing : "StopCause: " + this.tracker(this.annotationCauses.value[0]) + "\n" + "Timing: " + this.timing;
                this.viewPanel = false;
            },
            tracker(annotationCauseId) {
                if(!Object.isUseful(annotationCauseId))
                    return "";
                let valueToShow = 0;
                if(Array.isArray(annotationCauseId)) {
                    if (Array.isUseful(annotationCauseId))
                        valueToShow = annotationCauseId[0];
                    else return "";
                }
                else valueToShow = annotationCauseId;

                return this.$timeTracking.formatTimeTracker(valueToShow, true);
            },
            loadSettings() {
                //Get line settings and merge values on descriptor
                try {
                    this.settingsValues = this.$settings.getStandardTimesSettings();
                    if (Object.isUseful(this.settingsValues)) {
                        //This variable is used to create a copy of settings to keep his old values for auditing purposes
                        let values = this.$utils.detach(this.settingsValues);
                        let rawValues = this.$utils.detach(this.settingsValues);
                        for (let i = 0; i < this.settingsValues.times.length; i++) {
                            for (const element of Object.entries(this.settingsValues.times[i])) {
                                const key = element[0]
                                    , value = element[1];
                                if (Object.isUseful(value) && key !== 'recipe' && value !== '') {
                                    values.times[i][key] = "StopCause: " + this.tracker(value.annotationCauses) + '\n' + "Timing: " + value.timing;
                                    rawValues.times[i][key] = "StopCause: " + value.annotationCauses + ';' + "Timing: " + value.timing;
                                }
                            }
                        }
                        //Merge settings values on descriptor
                        if (this.settingsValues) {
                            this.mergeSetting(this.standardTimes.byRecipe.settings.times, values.times);
                            this.standardTimes.byRecipe.settings.times.rawValue = rawValues.times;
                        }
                    }
                }
                finally {
                    this.loadRecipes();
                    //this.loadCategories();
                }
            },
            async valueChanged(groupName, settingName, actionObj) {
                let self = this;
                if(actionObj.item < 0)
                    return;

                if (this.settingsValues === null)
                    this.settingsValues = {};

                let trail = this.$audits.getSettingChangedTrailObject(groupName, settingName, actionObj, this.settingsValues, this.standardTimes, ["StandardTimes"]);

                //This variable is used to create a copy of settings to keep his old values , new values and use them to save the audits
                let values = this.$utils.detach(this.standardTimes);
                if(this.selectedCell.key==='recipe') {
                    values.byRecipe.settings.times.rawValue[this.selectedCell.row][this.selectedCell.key] = values.byRecipe.settings.times.value[this.selectedCell.row][this.selectedCell.key];
                    this.standardTimes.byRecipe.settings.times.rawValue[this.selectedCell.row][this.selectedCell.key] = values.byRecipe.settings.times.value[this.selectedCell.row][this.selectedCell.key];
                }

                //Managing imported data
                // let records = actionObj.action === 'loaded' ? values.byRecipe.settings.times.value : values.byRecipe.settings.times.rawValue;
                // let rawValues = actionObj.action === 'loaded' ? JSON.parse(JSON.stringify(actionObj.item)) : JSON.parse(JSON.stringify(values.byRecipe.settings.times.rawValue));
                let records;
                let rawValues;
                if( actionObj.action === 'loaded') {
                    records =  values.byRecipe.settings.times.value;
                    rawValues = JSON.parse(JSON.stringify(actionObj.item));
                    this.standardTimes.byRecipe.settings.times.rawValue = this.$utils.detach(actionObj.item);
                } else {
                    records =  values.byRecipe.settings.times.rawValue;
                    rawValues = JSON.parse(JSON.stringify(values.byRecipe.settings.times.rawValue));
                }

                for (let i = 0; i < this.standardTimes.byRecipe.settings.times.value.length; i++) {
                    for (const element of Object.entries(records[i])) {
                        const key = element[0]
                            , value = element[1];
                        if (Object.isUseful(value) && key !== 'recipe' && value !== '') {
                            //split for rawvalue
                            let cellValues = value.includes("\n") ? value.split("\n") : value.split(";");
                            rawValues[i][key] = { annotationCauses: cellValues[0].split(":")[1].trim(), timing: cellValues[1].split(":")[1].trim()};
                            //manage imported file
                            if(actionObj.action === 'loaded') {
                                self.standardTimes.byRecipe.settings.times.value[i][key] = "StopCause: " + this.tracker(cellValues[0].split(":")[1].trim()) + "\n" + "Timing: " + cellValues[1].split(":")[1].trim();
                                // self.standardTimes.byRecipe.settings.times.rawValue[i][key] = "StopCause: " + cellValues[0].split(":")[1].trim() + ";" + "Timing: " + cellValues[1].split(":")[1].trim();
                            }
                        }
                    }
                }

                this.settingsValues.times = rawValues;

                this.$settings.saveStandardTimesSettings(this.settingsValues)
                    .then(() => {
                        //check for avoiding empty "deleted" audits
                        if (!(actionObj.action==='deleted' &&  typeof trail.previousVal==='undefined')) {
                            self.$audits.save(trail.operator , trail.action, trail.previousVal , trail.nextVal, trail.actionId)
                                .catch(() => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                })
                        }
                    })
                    .catch(err => {
                    debugger
                        self.$root.showErrorNotification(self.$gettext("An error occurred while saving settings to DB"), true);
                        self.loadSettings();
                    })
            },

             formatData(sourceObject,index) {
                let self = this;
                const sourceKeys = Object.keys(sourceObject);
                let returnObject = {};
                sourceKeys.forEach(k => {
                    let valueObject = String(sourceObject[k]).toLowerCase();
                    if (valueObject !== '' && k !== 'recipe') {
                        sourceObject[k] = self.settingsValues.times[index][k];
                    }
                    returnObject[k] = sourceObject[k];
                });

                return returnObject;
            },

            filterData(exportedData) {
                let newArray = exportedData.map(obj => { return {...obj}});
                return newArray.map((obj, index) => this.formatData(obj,index));
            },
            mergeSetting(target, source) {
                if (source){
                    target.value = source;
                }
            },
            loadRecipes(){

                let self = this;
                self.$recipes.getRecipesId()
                    .then(result => {
                        self.recipes = result;
                        self.standardTimes.byRecipe.settings.times.ItemsAutoComplete = self.recipes;
                        self.standardTimes.byRecipe.settings.times.headers.push({
                            name:"recipe",
                            key:"recipe",
                            type:"autocomplete",
                            options:self.recipes,
                            contextMenu:false,
                            sortable:true,
                            editable:true,
                        });

                        self.recipes.forEach((recipe, index) => {
                            self.standardTimes.byRecipe.settings.times.headers.push({
                                name: recipe,
                                key: recipe,
                                type: "text",
                                contextMenu: true,
                                sortable: false,
                                editable: false,
                            })
                        });
                        // self.loadCategories();
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(()=>{
                        if(Array.isUseful(this.standardTimes.byRecipe.settings.times.value))
                            self.checkRecipes();
                    });
            },

            loadCategories() {
                this.standardTimes.byRecipe.settings.times.headers.push({
                    name:"Change over",
                    key:"Change over",
                    type:"number",
                    contextMenu:true,
                    options:[],
                    sortable:false,
                    editable:true,
                });
                this.standardTimes.byRecipe.settings.times.headers.push({
                    name:"Format change",
                    key:"Format change",
                    type:"number",
                    contextMenu:true,
                    options:[],
                    sortable:false,
                    editable:true,
                });
                this.standardTimes.byRecipe.settings.times.headers.push({
                    name:"Cleaning",
                    key:"Cleaning",
                    type:"number",
                    contextMenu:true,
                    options:[],
                    sortable:false,
                    editable:true,
                });
            },

            //Check the matching between the recipes of lineSetting and that in the DB and advise about the missing recipes
            checkRecipes() {

                let missingRecipes = '';
                if (!Array.isUseful(this.recipes))
                    return;
                let lineSettingsRecipes = [];
                if (this.standardTimes.byRecipe.settings.times.value[0].key)
                    lineSettingsRecipes = this.standardTimes.byRecipe.settings.times.value.map(({key}) => key);
                else {
                    lineSettingsRecipes = this.standardTimes.byRecipe.settings.times.value.map(record => {
                        return record.recipe
                    });
                }

                for (const inputRecipe of lineSettingsRecipes) {
                    let found = false;
                    for (const availableRecipe of this.recipes) {
                        if (this.$utils.matchWildcardString(availableRecipe, inputRecipe))
                            found = true;
                    }
                    if (!found)
                        missingRecipes += inputRecipe + ', '
                }

                if (missingRecipes)
                    this.$root.showErrorNotification(this.$gettext("These recipes are missing from DB: ") + missingRecipes.slice(0, -2), true);
            },

            onCellClick(groupName, settingName, actionObj) {
                this.selectedCell.row = actionObj.item.row;
                this.selectedCell.key = actionObj.item.key;
                this.selectedCell.columnIndex = actionObj.item.columnIndex;
                if(actionObj.item.columnIndex > 0) {
                    this.viewPanel = true;
                    this.annotationCauses.value = 0;
                    this.timing = 0;
                    if (this.settingsValues.times[this.selectedCell.row][this.selectedCell.key] && this.settingsValues.times[this.selectedCell.row][this.selectedCell.key] !== "") {
                        this.annotationCauses.value = this.settingsValues.times[this.selectedCell.row][this.selectedCell.key].annotationCauses;
                        this.timing = this.settingsValues.times[this.selectedCell.row][this.selectedCell.key].timing;
                    }
                }
            },
        }
    }
</script>

<style scoped>
    div {
        white-space: pre;
    }
</style>