<template>
    <DynamicElementBase>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue';
    import recipes from '@/api/recipes'
    import lines from '@/api/lines'
    import devices from '@/api/devices'

    export default {
        name: "RecipeBase",
        extends: DynamicElementBase,
        components:{DynamicElementBase},
        data:()=>{
            return {
                recipe:[],
                isOpening:true
            }
        },
        async mounted() {
            this.fixedHeight = -1;
            this.dataExplorationMode.requiresDataExploration = false;
            this.dataExplorationMode.noPreview = true;
            this.dataExplorationMode.requiresDoubleActivation = this.$settings.getWorkFlowSettings().requireDoubleActivationForRecipes;
            this.scope().childHandlers.getItemStatus = this.getRecipeStatus;

            this.visualizationTweaks = [
                {
                    name: this.$gettext("Template"),
                    id: "templateRecipe",
                    type: "option",
                    options: [],
                    hidden:true,
                    default: function () {
                        return "";
                    },
                },
                {
                    name: this.$gettext("Line"),
                    id: "target",
                    type: "option",
                    options: [],
                    hidden:true,
                    default: function () {
                        return "";
                    },
                },
                {
                    name: this.$gettext("Machine"),
                    id: "targetMachine",
                    type: "multi_option",
                    itemText:"text",
                    optionsFilterable:true,
                    options: [],
                    hidden:true,
                    default: function () {
                        return "";
                    },
                },
                {
                    name: this.$gettext("Use as default recipe"),
                    id: "useAsDefaultRecipe",
                    type: "bool",
                    hidden:true,
                    default: function () {
                        return "";
                    },
                }
            ];

            this.loadRecipes()
            this.loadLines();
        },
        methods: {
            async loadRecipes() {
                let templates = await recipes.getRecipes("template",true);
                if(Array.isUseful(templates)){
                    this.getTweak('templateRecipe').options = templates.map(r => {
                        return r.name
                    })
                }

            },
            async loadLines() {
                this.getTweak('target').options = await lines.getAvailable();
            },
            async loadMachines() {
                this.getTweak('targetMachine').options = await devices.getAll();
            },
            async loadMachineFromTemplate(){
                this.getTweak("targetMachine").options = await recipes.getTargetMachinesFromTemplate(this.properties.templateRecipe)
            },
            getRecipeStatus() {
                if (this.scope().properties.deployStatus === -1) {
                    return {status: -1, message: this.$gettext("This recipe is draft")}
                } else if (this.scope().properties.deployStatus === 0) {
                    if (this.dataExplorationMode.requiresDoubleActivation) {
                        return {status: 0, message: this.$gettext("This item is waiting for activation")}
                    } else {
                        return {status: 0, message: this.$gettext("This recipe is draft")}
                    }
                } else {
                    if (this.hasUndeployedChanges())
                        return {status: 2, message: this.$gettext("Recipe is active with a different configuration")};
                    else
                        return {status: 1, message: this.$gettext("This recipe is active")}
                }
            }
        },
        watch:{
            'properties.type':{
                handler(){
                    switch (this.scope().properties.type) {
                        case this.$dynamicElements.Types.TemplateRecipe:
                            this.getTweak('targetMachine').hidden=false;
                            this.getTweak('useAsDefaultRecipe').hidden=false;
                            this.loadMachines();
                            break;
                        case this.$dynamicElements.Types.MachineRecipe:
                            this.getTweak('templateRecipe').hidden=false;
                            this.getTweak('targetMachine').name=this.$gettext("Machine");
                            this.getTweak('targetMachine').hidden=false;
                            this.loadMachineFromTemplate();
                            break;
                        case this.$dynamicElements.Types.LineRecipe:
                            this.getTweak('target').hidden=false;
                            this.getTweak('useAsDefaultRecipe').hidden=false;
                            break;
                    }
                }
            },

            visualizationTweaks:{
                deep:true,
                async handler(){
                    if(this.getTweakValue("templateRecipe")) {
                        let reloadMachine=this.properties.templateRecipe!==this.getTweakValue("templateRecipe")
                        this.properties.templateRecipe = this.getTweakValue("templateRecipe");
                        if(reloadMachine || this.isOpening){
                            this.loadMachineFromTemplate();
                            if(this.isOpening)
                                this.isOpening=false;
                        }
                    }
                    if(this.getTweakValue("useAsDefaultRecipe")){
                        this.properties.useAsDefaultRecipe=this.getTweakValue("useAsDefaultRecipe");

                    }

                    if(this.getTweakValue("targetMachine")){
                        this.properties.targetMachine=this.getTweakValue("targetMachine");

                    }
                    if(this.getTweakValue("target")){
                        this.properties.target=this.getTweakValue("target");

                    }
                    this.saveTweaks();
                }
            }
        }

    }
</script>

<style scoped>

</style>
