import Vue from 'vue'

export default ({ app, router, store, Vue }) => {
  Vue.prototype.ItemTypeDefinitionAPI = {}

  Vue.prototype.ItemTypeDefinitionAPI.all = function () {
    return Vue.prototype.OrchestratorAPI.proxyCall('get', '/api/v1/auth/itemtypedefinition/all')
  },

  Vue.prototype.ItemTypeDefinitionAPI.allEnabled = function() {
    return Vue.prototype.OrchestratorAPI.proxyCall('get', '/api/v1/auth/itemtypedefinition/enabled')
  },

  Vue.prototype.ItemTypeDefinitionAPI.masterTypes = function () {
    return Vue.prototype.OrchestratorAPI.proxyCall('get', '/api/v1/auth/itemtypedefinition/mastertypes')
  },

  Vue.prototype.ItemTypeDefinitionAPI.byLevel = function (levelID) {
    return Vue.prototype.OrchestratorAPI.proxyCall('get', '/api/v1/auth/itemtypedefinition/level/' + levelID)
  },

  Vue.prototype.ItemTypeDefinitionAPI.masterType = function (levelID) {
    return Vue.prototype.OrchestratorAPI.proxyCall('get', '/api/v1/auth/itemtypedefinition/mastertypes/' + levelID)
  },

  Vue.prototype.ItemTypeDefinitionAPI.edit = function (itemType) {
    return Vue.prototype.OrchestratorAPI.proxyCall('post', '/api/v1/auth/itemtypedefinition/edit', JSON.stringify(itemType))
  }
}
