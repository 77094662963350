<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" class="page-container" grid-list-md text-xs-center pa-3 ma-0 style="max-width: 100%; overflow: hidden">
        <v-card  class="mt-0 av-card" elevation="0" style="height: 80px;">
            <v-layout row wrap justify-end>
                <v-btn class="my-4" color="info" v-if="workOrderDetails.BillOfMaterials && !historyView && !billOfMaterialsView"  @click="switchBillOfMaterials">
                    <av-icon :id="'billOfMaterialsView'" light small left>fa-question</av-icon>
                    {{$gettext("Bill of materials")}}
                </v-btn>
                <v-btn class="my-4" color="info" v-if="!historyView && !billOfMaterialsView" @click="switchTab">
                    <av-icon :id="'detailsView'" light small left>fa-history</av-icon>
                    {{tabsTitles[1]}}
                </v-btn>
                <v-btn class="my-4" color="info" v-if="historyView || billOfMaterialsView" @click="switchTab">
                    <av-icon :id="'historyView'" light small left>fa-table</av-icon>
                    {{tabsTitles[0]}}
                </v-btn>
                <av-button class="ma-4" :text="$gettext('BACK')" color="error" @click="$router.go(-1)" buttonIcon="fas fa-arrow-left" iconColor="light"/>
            </v-layout>
        </v-card>
        <v-card class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)">
            <workorder-detail v-if="!historyView && !billOfMaterialsView" :details="workOrderDetails">
            </workorder-detail>
            <workorder-history v-if="historyView && !billOfMaterialsView" :transition="reformatTransitions">
            </workorder-history>
            <bill-of-materials v-if="billOfMaterialsView && !historyView"  class="av-card-content"
                               style="height: calc(100vh - 80px)" :materials="materials">
            </bill-of-materials>
        </v-card>
    </v-container>
</template>

<script>
    import WorkorderHistory from '@/components/workorders/workorderHistory'
    import WorkorderDetail from '@/components/workorders/workorderDetail'
    import BillOfMaterials from '@/components/workorders/billOfMaterials'
    export default {
        name: "WorkorderDetails",
        components:{WorkorderDetail, WorkorderHistory, BillOfMaterials},
        data() {
            return {
                workOrderDetails: {},
                name: "",
                // standardFields: [],

                tabsTitles: [],

                historyView: false,
                billOfMaterialsView:false,

            }
        },
        mounted() {
            this.$root.setCurrentPageTitle(this.$gettext("Workorder Details"));
            this.tabsTitles.push(this.$gettext("Details View"));
            this.tabsTitles.push(this.$gettext("History View"));
        },

        created: function () {
            this.workOrderDetails = this.$route.params.data;
        },
        computed:{
            reformatTransitions() {
                if (this.workOrderDetails){
                    let transitions = this.workOrderDetails.Transitions;
                    transitions.forEach((transition, index) => {
                        let transitionLogs = [];
                        if(Array.isUseful(transition.TransitionLog))
                            for (let log of transition.TransitionLog) {
                                let toks = log.source.split(".")
                                toks.pop();
                                log.source = toks.join(".")
                                transitionLogs.push(log);
                            }
                        transitions[index].TransitionsLog = transitionLogs;
                    })
                    this.$set(this.workOrderDetails, 'Transitions', transitions);
                    return this.workOrderDetails.Transitions
                }
            },
            materials() {
                if(Object.isUseful(this.workOrderDetails.BillOfMaterials))
                    if(Object.isUseful(this.workOrderDetails.BillOfMaterials.Materials))
                        return this.$utils.objectToArray(this.workOrderDetails.BillOfMaterials.Materials, 'Id');
                return null
            }
        },
        methods: {
            switchTab() {
                if(this.billOfMaterialsView) {
                    this.billOfMaterialsView = false;
                    return
                }
                this.historyView = !this.historyView;
            },
            switchBillOfMaterials(){
                this.billOfMaterialsView = !this.billOfMaterialsView
                if(this.historyView) {
                    this.historyView = false;
                }
            },
        },
    }
</script>

<style scoped>
    .v-text-field >>> .v-counter {
        margin-top: 5px;
    }


</style>
