<template>
    <v-card class="mt-0 av-card" elevation="0" style="height: auto!important;">
        <v-layout row >
            <slot name="itemsLeft"></slot>
            <v-flex xs12>
                <av-toolbar-items @update="((evt)=>{ $emit('update',evt)})" :activation-on="activationOn">
                    <slot name="itemsRight"></slot>
                </av-toolbar-items>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
import AvToolbarItems from "@/components/av-components/av-toolbar-items";
export default {
    name: "av-toolbar",
    components: {AvToolbarItems},
    props:{
        activationOn:{
            required:false,
            default:()=>{return false}
        }
    },
    data:()=> {
        return {
            leftToolbarItems: {
                rows: 1,
                show: false
            },
            rightToolbarItems: {
                rows: 1,
                show: false
            }
        }
    },
    computed:{

    },
    methods:{

    },
    watch:{

    }

}
</script>

<style scoped>

</style>