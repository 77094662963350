<template>
    <div ref="draggableContainer" id="draggable-container">
        <div id="block_container">
            <div :style="splitHeader ? 'float: left' : 'float: none'" id="draggable-header" @mousedown="dragMouseDown">
                <slot name="header"></slot>
            </div>
            <div id="no-draggable-header" style="float: none;overflow: hidden">
                <slot name="noDraggableHeader"></slot>
            </div>
        </div>
        <slot name="main"></slot>
        <slot name="footer"></slot>
    </div>
</template>

<script>
    export default {
        name: 'DraggableContainer',
        props: {
            splitHeader: {
                default: false
            },
        },
        data: function () {
            return {
                positions: {
                    clientX: undefined,
                    clientY: undefined,
                    movementX: 0,
                    movementY: 0
                }
            }
        },
        mounted(){
            // let app = document.getElementById("app");
            // let w = app.offsetWidth;
            // let h = app.offsetHeight;
            let w = window.innerWidth;
            let h = window.innerHeight; // or
            let d = this.$refs.draggableContainer;
            this.positions.clientX = (w-d.offsetWidth)/2;
            this.positions.clientY = (h-d.offsetHeight)/2;
            this.$refs.draggableContainer.style.top = this.positions.clientY + 'px';
            this.$refs.draggableContainer.style.left = this.positions.clientX + 'px';

        },
        methods: {
            dragMouseDown: function (event) {
                event.preventDefault();
                // get the mouse cursor position at startup:
                this.positions.clientX = event.clientX;
                this.positions.clientY = event.clientY;
                document.onmousemove = this.elementDrag;
                document.onmouseup = this.closeDragElement;
            },
            elementDrag: function (event) {
                event.preventDefault();
                this.positions.movementX = this.positions.clientX - event.clientX;
                this.positions.movementY = this.positions.clientY - event.clientY;
                this.positions.clientX = event.clientX;
                this.positions.clientY = event.clientY;
                // set the element's new position:
                this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px';
                this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px';
            },
            closeDragElement () {
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }
    }
</script>

<style>
    #draggable-container {
        position: absolute;
        z-index: 9989;
        /*width: 1084px;*/
        width: 80%;
        margin: 0 auto;
        height: auto;
    }
    #draggable-header {
        z-index: 9999;
        /*height: 90px;*/
    }

    #no-draggable-header {
        z-index: 9999;
        /*height: 90px;*/
    }

    #block_container {
        /*background-color:black;*/
        z-index: 99999;
        height: auto;
    }
    /*#block_container > div {*/
    /*    !*display: inline-block;*!*/

    /*}*/
</style>