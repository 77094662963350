import OrchestratorAPI from '@/api/orchestrator';
import Microservices from '@/api/microservices';

export default {
    async GetMediaDefaultFile(mediaId) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.documentsUrl + '/doc/media/default/' +  mediaId)
                .then(result => {
                    if(Array.isUseful(result))
                        resolve(result[0].Media);
                    else resolve("")
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}