<template>
    <v-layout justify-start align-start column fill-height>
        <v-list style="width:100%"  two-line>
            <template v-for="(visualization, index) in dashboards">
                <v-list-tile class="headline" :key="visualizations.name">
                    <v-list-tile-content>
                        <v-list-tile-title >
                            <div class="headline" style="text-align: start; margin-bottom: 5px;">
                                <translate>Name:</translate>
                                {{ visualization.name }}
                            </div>
                        </v-list-tile-title>
                    </v-list-tile-content>

                    <v-list-tile-action >
                        <v-layout wrap row>
                            <v-btn class="ma-2 px-3" color="blue" dark @click="cloneView(visualization)">
                                <av-icon left light>fas fa-clone</av-icon>
                                <translate>Clone</translate>
                            </v-btn>
                            <v-btn class="ma-2 px-3" color="blue" dark @click="openView(visualization)">
                                <av-icon left light>fas fa-pen</av-icon>
                                <translate>Open</translate>
                            </v-btn>
                            <v-btn class="ma-2 px-3" color="error" @click="deleteDashBoardConfirm(visualization.name,index)">
                                <av-icon left light>fas fa-trash</av-icon>
                                <translate>Delete</translate>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-layout>
                    </v-list-tile-action>
                </v-list-tile>
                <v-divider></v-divider>
            </template>
        </v-list>
    </v-layout>
</template>

<script>

    export default {
        name: "DashboardNavigator",
        data: () => ({
            visualizations: [],
            runningRules: [],
            selectedDashBoardToDelete:""
        }),
        props: {
            type: {
                default: null
            }
        },
        computed:{
            dashboards(){
                return this.visualizations;
            }
        },
        methods: {
            async waitMount(){
                await this.waitForPageLoading();
            },
            mount(){
                this.$root.setLoading(true, "");
                this.loadDashboards();
            },
            openView(view) {
                this.$emit('OpenView', view);
            },
            cloneView(view) {
                this.$emit('CloneView', view);
            },
            deleteDashBoardConfirm(name,index) {
                this.selectedDashBoardToDelete = name;
                this.$root.showDialogBox(this.$gettext("Are you sure you want to delete dashboard: {0}?").format(name), null, "Yes", ()=>{this.deleteDashBoard(index)}, "No", null);
            },
            deleteDashBoard(index) {
                let self = this;
                this.$dashboards.DeleteVisualization(this.selectedDashBoardToDelete)
                    .then(()=>{
                        self.visualizations.removeAt(index)
                        self.$audits.save(self.$root.userName, self.$audits.items().dashboardDeleted, "", "", self.selectedDashBoardToDelete)
                            .catch(err => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .finally(() => {
                        self.$root.reloadDynamicLinks = true
                    })
            },
            loadDashboards() {
                this.visualizations.clear();
                let self = this;
                this.$dashboards.getMetadataList(false, true, false)
                    .then(result=>{
                        if (Object.entries(result)) {
                            for (let group in result) {
                                result[group].forEach(dash=>{
                                    self.visualizations.push(dash)
                                })
                            }
                        }
                        self.visualizations=self.visualizations.sortBy("name",false)
                    })
                    .catch(error => {
                        console.log(error);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving saved visualizations from DB."), true);
                    })
                    .finally(() => {
                        self.$root.setLoading(false)
                    })


            }
        },
        beforeMount() {
            // setInterval(async ()=>{
            //     console.log(this.$store.state.commonStore.pageReady)
            // },200)
            if (this.$store.state.isEmbedded){
                this.waitMount();
            }
            this.mount()
        }
    }
</script>

<style scoped>

</style>
