<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="listOfReexecution"
        item-key= "@timestamp"
    >
      <template v-slot:items="props">
        <tr>
          <td class="text-xs-left"></td>
          <td class="text-xs-left">{{ props.item["@timestamp"] }}</td>
          <td class="text-xs-left">{{ props.item.completionTimestamp }}</td>
          <td class="text-xs-left">{{ props.item.targetEntityName }}</td>
          <td class="text-xs-left">{{ props.item.numberOfTransactionExecuted }}</td>
          <td class="text-xs-left">{{ props.item.numberOfTransactionToBeExecuted }}</td>
          <td class="text-xs-left">{{ props.item.entityKeys }} </td>
          <td class="text-xs-left">{{ $root.userName }}</td>
          <td class="text-xs-left">{{ props.item.exitStatus }}</td>
          <td class="text-xs-center">
            <v-progress-linear v-model="props.item.percentage" height="25" v-if="props.item.percentage <= 33" color="red">
              <strong>{{ (props.item.percentage) }}%</strong>
            </v-progress-linear>
            <v-progress-linear v-model="props.item.percentage" height="25" v-else-if="props.item.percentage <= 66" color="yellow">
              <strong>{{ (props.item.percentage) }}%</strong>
            </v-progress-linear>
            <v-progress-linear v-model="props.item.percentage" height="25" v-else color="green">
              <strong>{{ (props.item.percentage) }}%</strong>
            </v-progress-linear>
          </td>
          <td class="text-xs-left">{{ props.item.reExecutionStatus }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Transaction from "@/api/transactions";

export default {
name: "ReexecutionTransactions",

  data() {
    return {
      listOfReexecution: [],
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Timestamp',
          align: 'left',
          sortable: true,
          value: '@timestamp'
        },
        { text: 'Completion Timestamp', value: 'completionTimestamp', align: 'left' },
        { text: 'Entity Name', value: 'EntityName' },
        { text: 'Executed', value: 'numberOfTransactionExecuted' },
        { text: 'To Be Executed', value: 'numberOfTransactionToBeExecuted' },
        { text: 'Key Values', value: 'KeyValues' },
        { text: 'Operator', value: 'Operator' },
        { text: 'Exit Status', value: 'exitStatus' },
        { text: 'Progress Bar', value: 'ProgressBar' },
        { text: 'Reexecution Status', value: 'reExecutionStatus' },
      ]
    }
  },
  mounted() {
    this.getReexecutionTransactions()
    this.timer = setInterval(this.getReexecutionTransactions, 1500);
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
  computed: {
    percentageOfExecuted() {
     return this.listOfReexecution.numberOfTransactionExecuted
      // return parseInt(this.listOfReexecution.numberOfTransactionExecuted)/(parseInt(this.listOfReexecution.numberOfTransactionExecuted) + parseInt(this.listOfReexecution.numberOfTransactionToBeExecuted)) * 100
    }
  },
  methods: {
    getReexecutionTransactions() {
      Transaction.GetReexecutionOrphanTransactions().then(t => {
        this.listOfReexecution = t
      }).finally(() => {
        console.log(this.listOfReexecution)
      })
    },
  }
}
</script>

<style scoped>

</style>