<template>
    <v-switch v-bind="$attrs" v-on="$listeners" :label="translatedLabel" :id="computedID" v-model="value" @change="change"/>
</template>

<script>
export default {
    name: "av-switch",
    props: {
        translate: { required: false, default: true },
        dynamicID: { required: false, default: '' },
        value: { required: true, default: '' },
        translateLabel: { required: false, default: true }
    },
    data () {
        return {}
    },
    computed: {
        computedID () {
            if (!this.$isEmpty(this.$attrs.id)) {
                return this.$attrs.id
            }
            let dynamicPageName = this.$router.history.current.params.routeId ? this.$router.history.current.params.routeId : "";
            return this.toCamelCase(this.$router.history.current.name + dynamicPageName + this.dynamicID + 'Switch' + this.$attrs.label.trim())
        },
        translatedLabel () {
            if (!this.translateLabel) {
                return this.$attrs.label
            }
            return this.$attrs.label
        }
    },
    mounted () {
    },
    methods: {
        change(event) {
            this.$emit("input", event);
            this.$emit("change", event);
        }
    },
}
</script>

<style scoped>

</style>
