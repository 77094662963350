import shifts from "./shifts";
import VueInstance from './vueinstance';
import jsutils from "./jsutils";
import i18n from '@/api/i18n.js';

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function getRfc3339TimeStamp(d) {

    if(!isValidDate(d))
        return d;

    function pad(n) {
        return n < 10 ? "0" + n : n;
    }

    function timezoneOffset(offset) {
        var sign;
        //offset = offset + 60;
        if (offset === 0) {
            return "Z";
        }
        sign = (offset > 0) ? "-" : "+";
        offset = Math.abs(offset);
        return sign + pad(Math.floor(offset / 60)) + ":" + pad(offset % 60);
    }

    return d.getFullYear() + "-" +
        pad(d.getMonth() + 1) + "-" +
        pad(d.getDate()) + "T" +
        pad(d.getHours()) + ":" +
        pad(d.getMinutes()) + ":" +
        pad(d.getSeconds()) +
        timezoneOffset(d.getTimezoneOffset());
}

function getRfc3339TimeFromNow(offset) {
    let now = new Date();
    now.setSeconds(Math.round(now.getSeconds() / 10) * 10); //Work with 10 seconds base to avoid windowing artifacts in elastic aggregations, results may jitter out of requested window
    now.setSeconds( now.getSeconds() - offset - 60 );
    now.setMilliseconds(0);
    return getRfc3339TimeStamp(now);
}

function getDateTimeFromNow(offset, alignStart = false) {

    let now = new Date();

    if(alignStart) {
        now.setSeconds(Math.round(now.getSeconds() / 10) * 10); //Work with 10 seconds base to avoid windowing artifacts in elastic aggregations, results may jitter out of requested window
        now.setSeconds(now.getSeconds() - offset - 60);
        now.setMilliseconds(0);
    }
    else
        now.setSeconds( now.getSeconds() - offset );

    return now;
}

function formatTime(seconds, shortFormat = false, includeIfZero = true) {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.round(seconds % 60);
    let returning = "";
    if(h || includeIfZero)
        returning += h + "h ";
    if(m || h || includeIfZero)
        returning += m + "m ";
    returning += s + "s";
    if(shortFormat)
        returning += " (" + jsutils.roundToDigits(seconds / 60, 1) + " mins)";
    return returning;
}

function daysOfWeek() {
    if (VueInstance.get() !== null) {
        return [VueInstance.get().$gettext("Monday"), VueInstance.get().$gettext("Tuesday"), VueInstance.get().$gettext("Wednesday"),
            VueInstance.get().$gettext("Thursday"), VueInstance.get().$gettext("Friday"), VueInstance.get().$gettext("Saturday"), VueInstance.get().$gettext("Sunday")]
    } else
        return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
}

function monthsOfYear() {
    if (VueInstance.get() !== null) {
        return [VueInstance.get().$gettext("January"), VueInstance.get().$gettext("February"), VueInstance.get().$gettext("March"), VueInstance.get().$gettext("April"),
            VueInstance.get().$gettext("May"), VueInstance.get().$gettext("June"), VueInstance.get().$gettext("July"), VueInstance.get().$gettext("August"),
            VueInstance.get().$gettext("September"), VueInstance.get().$gettext("October"), VueInstance.get().$gettext("November"), VueInstance.get().$gettext("December")];
    } else
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
}

function convertDateTime(text) {
    if (typeof text === 'string' && text && !text.includes(" ") && text.includesMulti("T", "Z", "-", ":"))
        return (new Date(text)).format();

    return text;
}

function secondsToString(spanInSeconds) {
    if (spanInSeconds < 60)
        return spanInSeconds + " " + VueInstance.get().$gettext("secs");
    else if (spanInSeconds < 3600)
        return spanInSeconds / 60 + " " + VueInstance.get().$gettext("mins");
    else if (spanInSeconds < 86400)
        return spanInSeconds / 3600 + " " + VueInstance.get().$gettext("hours");
    else if (spanInSeconds < 2592000)
        return spanInSeconds / 86400 + " " + VueInstance.get().$gettext("days");
    else if (spanInSeconds < 31536000)
        return spanInSeconds / 2592000 + " " + VueInstance.get().$gettext("months");
    else
        return spanInSeconds / 31536000 + " " + VueInstance.get().$gettext("years");
}

// add a few methods to date:
if (!Date.prototype.setToPreviousDayOfWeek){
    Date.prototype.setToPreviousDayOfWeek = function(dayToSet){
        let dayOffset = 0;
        if(this.getDay() === 0 && dayToSet !== 0) //Sunday
            dayOffset = dayToSet - 7;
        else
            dayOffset = dayToSet - this.getDay();
        this.setDate(this.getDate() + dayOffset);
    };
}

if (!Date.prototype.isLeapYear){
    Date.prototype.isLeapYear = function(){
        let year = this.getFullYear();
        if((year & 3) !== 0)
            return false;
        return ((year % 100) !== 0 || (year % 400) === 0);
    };
}

if (!Date.prototype.getMinuteOfDay){
    Date.prototype.getMinuteOfDay = function(){
        return (this.getMinutes() + this.getHours() * 60);
    };
}

if (!Date.prototype.getMinuteOfYear){
    Date.prototype.getMinuteOfYear = function(){
        // Get Day of Year
        let dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
        let mn = this.getMonth();
        let dn = this.getDate();
        let dayOfYear = dayCount[mn] + dn;
        if(mn > 1 && this.isLeapYear())
            dayOfYear++;
        return dayOfYear * 1440 + this.getHours() * 60 + this.getMinutes();
    };
}

if (!Date.prototype.getDayOfYear){
    Date.prototype.getDayOfYear = function(){
        // Get Day of Year
        let dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 364];
        if(this.isLeapYear())
            dayCount = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 365];
        let mn = this.getMonth();
        let dn = this.getDate();
        return dayCount[mn] + dn - 1;
    };
}

if (!Date.prototype.setDayOfYear){
    Date.prototype.setDayOfYear = function(day){
        // Get Day of Year
        let month = -1;
        let dayOfMonth = 0;
        let dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365];
        if(this.isLeapYear())
            dayCount = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366];
        for(let i = 0 ; i < dayCount.length - 1 ; i++)
            if(day >= dayCount[i] && day < dayCount[i+1]) {
                month = i;
                dayOfMonth = day - dayCount[i] + 1;
                break;
            }

        if(month === -1)
            throw "Requested number is not a valid day of year";

        this.setMonth(month, dayOfMonth);
    };
}

//From https://stackoverflow.com/questions/14638018/current-time-formatting-with-javascript
//Formats date times using .net like syntax
/*
    yyyy -> Year four digits
    yy   -> Year two digits
    MMMM -> Month extended (january)
    MMM  -> Month short (jan)
    MM   -> Month as number double digit (01)
    dddd -> Day extended (Monday)
    ddd  -> Day short (Mon)
    dd   -> Day as number double digit (01)
    d    -> Day as number single digit (1)
    HH   -> Hour 24h format two digits (09)
    H    -> Hour 24h format one digit (9)
    hh   -> Hour 12h format two digits (09) AM in this case but AM is not added automatically, read further
    h    -> Hour 12h format one digit (9) AM in this case but AM is not added automatically, read further
    TT   -> AM/PM token capital case
    tt   -> AM/PM token lower case
    mm   -> Minutes two digits
    m    -> minutes one digit
    ss   -> Seconds two digits
    s    -> Seconds one digit
 */
if (!Date.prototype.format) {
    Date.prototype.format = function(format, utc) {
        if(!isValidDate(this))
            return "";
        if (!format)
            format = i18n.getPredefinedDateTimeFormat();
        var MMMM = monthsOfYear();
        MMMM.insertItem(0, "\x00");
        var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var dddd = daysOfWeek();
        dddd.insertItem(0, "\x02");
        dddd.moveItem(7 , 1);
        var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        function ii(i, len) {
            var s = i + "";
            len = len || 2;
            while (s.length < len) s = "0" + s;
            return s;
        }

        var y = utc ? this.getUTCFullYear() : this.getFullYear();
        format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
        format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
        format = format.replace(/(^|[^\\])y/g, "$1" + y);

        var M = (utc ? this.getUTCMonth() : this.getMonth()) + 1;
        format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
        format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
        format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
        format = format.replace(/(^|[^\\])M/g, "$1" + M);

        var d = utc ? this.getUTCDate() : this.getDate();
        format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
        format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
        format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
        format = format.replace(/(^|[^\\])d/g, "$1" + d);

        var H = utc ? this.getUTCHours() : this.getHours();
        format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
        format = format.replace(/(^|[^\\])H/g, "$1" + H);

        var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
        format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
        format = format.replace(/(^|[^\\])h/g, "$1" + h);

        var m = utc ? this.getUTCMinutes() : this.getMinutes();
        format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
        format = format.replace(/(^|[^\\])m/g, "$1" + m);

        var s = utc ? this.getUTCSeconds() : this.getSeconds();
        format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
        format = format.replace(/(^|[^\\])s/g, "$1" + s);

        var f = utc ? this.getUTCMilliseconds() : this.getMilliseconds();
        format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])f/g, "$1" + f);

        var T = H < 12 ? "AM" : "PM";
        format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
        format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

        var t = T.toLowerCase();
        format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
        format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

        var tz = -this.getTimezoneOffset();
        var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
        if (!utc) {
            tz = Math.abs(tz);
            var tzHrs = Math.floor(tz / 60);
            var tzMin = tz % 60;
            K += ii(tzHrs) + ":" + ii(tzMin);
        }
        format = format.replace(/(^|[^\\])K/g, "$1" + K);

        var day = (utc ? this.getUTCDay() : this.getDay()) + 1;
        format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
        format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

        format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
        format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

        format = format.replace(/\\(.)/g, "$1");

        return format;
    };
}

//Parses a time string in the form hh:mm:ss and returns a zero based Date object.
//Value 0 corresponds to midnight, 86399999 corresponds to 23:59:59.999
if (!Date.parseTimeString){
    Date.parseTimeString = function(time){
        let fullDate = new Date('1970-01-01T' + time + 'Z');
        return Date.parse(fullDate);
    };
}

class TimeSpan {

    static timeWindowsInMinutes = [
        1, 2, 5, 10, 15, 30,
        60, 120, 240, 480, 720,
        'shift', 'day', 1440, 2880, 7200, 10080, 'week',
        'month', 43200, 86400, 129600, 259200,
        'year', 525600, 1051200, 1576800, 2628000,
        'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
    ];

    static minutesTimeWindowsMinutes = TimeSpan.timeWindowsInMinutes.slice(0, 6);
    static hoursTimeWindowsMinutes = TimeSpan.timeWindowsInMinutes.slice(6, 11);
    static daysTimeWindowsMinutes = TimeSpan.timeWindowsInMinutes.slice(11, 18);
    static monthsTimeWindowsMinutes = TimeSpan.timeWindowsInMinutes.slice(18, 23);
    static yearsTimeWindowsMinutes = TimeSpan.timeWindowsInMinutes.slice(23, 28);
    static namedMonthsTimeWindowsMinutes = TimeSpan.timeWindowsInMinutes.slice(28, 40);

    static timeSpanExceedsLimit(timeSpan, limit) {
        if(!limit)
            return false;
        let spanIndex = TimeSpan.timeWindowsInMinutes.indexOf(timeSpan);
        let limitIndex = TimeSpan.timeWindowsInMinutes.indexOf(limit);
        if(spanIndex < 0 || limitIndex < 0) {
            console.error("Requested time window {0} or limit {1} is wrong".format(timeSpan, limit));
            return false
        }
        if(spanIndex >= TimeSpan.timeWindowsInMinutes.indexOf('jan'))
            return limitIndex < TimeSpan.timeWindowsInMinutes.indexOf(43200); //If filtering a named month enable it only if limit includes 1 month window

        return limitIndex < spanIndex;
    }

    constructor() {
        this.start = null;
        this.end = null;
        this.predefined = 15;
        this.predefinedIndex = TimeSpan.timeWindowsInMinutes.indexOf(this.predefined);
        this.hiddenValues = [];
        this.isIndexed = false;
        this.timeWindowIndex = 1;
        this.isSliding = false;
        this.slidingBounds = [];
        this.alignToTimeOfDay = "";
        this.timeToPredefined = 'now';
        this.allowDisabling = false; //FN Show the time window filter in the time picker dialog
        this.isDisabled = false; //FN Enable/Disable the execution of the time window filter
    }

    setHidden(hidden) {
        this.hiddenValues = hidden;
        let self = this;
        TimeSpan.minutesTimeWindowsMinutes = TimeSpan.minutesTimeWindowsMinutes.filter( ( el ) => !self.hiddenValues.includes( el ) );
        TimeSpan.hoursTimeWindowsMinutes = TimeSpan.hoursTimeWindowsMinutes.filter( ( el ) => !self.hiddenValues.includes( el ) );
        TimeSpan.daysTimeWindowsMinutes = TimeSpan.daysTimeWindowsMinutes.filter( ( el ) => !self.hiddenValues.includes( el ) );
        TimeSpan.monthsTimeWindowsMinutes = TimeSpan.monthsTimeWindowsMinutes.filter( ( el ) => !self.hiddenValues.includes( el ) );
        TimeSpan.yearsTimeWindowsMinutes = TimeSpan.yearsTimeWindowsMinutes.filter( ( el ) => !self.hiddenValues.includes( el ) );
        TimeSpan.namedMonthsTimeWindowsMinutes = TimeSpan.namedMonthsTimeWindowsMinutes.filter( ( el ) => !self.hiddenValues.includes( el ) );
    }

    from(timespan) {
        // if(timespan.constructor.name !== "TimeSpan")
        //     throw "Impossible to assign non TimeSpan object to a TimeSpan one";
        let sourceSpanDetached = JSON.parse(JSON.stringify(timespan));  //Detach object properties from source (deep copy)
        this.start = sourceSpanDetached.start;
        this.end = sourceSpanDetached.end;
        this.predefined = sourceSpanDetached.predefined;
        this.timeToPredefined = sourceSpanDetached.timeToPredefined;
        this.predefinedIndex = TimeSpan.timeWindowsInMinutes.indexOf(this.predefined);
        this.alignToTimeOfDay = sourceSpanDetached.alignToTimeOfDay;
        if(!Object.isUseful(this.alignToTimeOfDay))
            this.alignToTimeOfDay = "";
        this.isIndexed = false;
        this.timeWindowIndex = 1;
        this.isSliding = false;
        this.slidingBounds = [];
        this.allowDisabling = sourceSpanDetached.allowDisabling;
        this.isDisabled = sourceSpanDetached.isDisabled;
    }

    static load(timespan) {
        timespan.isIndexed = false;
        timespan.timeWindowIndex = 1;
        timespan.isSliding = false;
        timespan.slidingBounds = [];
        if(!Object.isUseful(timespan.alignToTimeOfDay))
            timespan.alignToTimeOfDay = "";
        Object.setPrototypeOf(timespan, TimeSpan.prototype);
    }

    setPredefined(value) {
        this.predefined = value;
        this.predefinedIndex = TimeSpan.timeWindowsInMinutes.indexOf(this.predefined);
        this.start = null;
        this.end = null;
        this.timeWindowIndex = 1;
        this.slidingBounds = [];
    }

    setPredefinedTimeTo(value) {
        this.timeToPredefined = value;
        this.start = null;
        this.end = null;
        this.timeWindowIndex = 1;
        this.slidingBounds = [];
    }

    getStart(align = false, ignoreSliding = false) {
        if(this.isSliding && !ignoreSliding && this.slidingBounsValid()) {
            let start = this.getStart(align, true);
            start.setSeconds(start.getSeconds() + this.slidingBounds[0]);
            return start;
        }
        let alignH = 0;
        let alignM = 0;
        if(this.alignToTimeOfDay) {
            alignH = parseInt(this.alignToTimeOfDay.split(":")[0]);
            alignM = parseInt(this.alignToTimeOfDay.split(":")[1]);
        }
        if(!Object.isUseful(this.timeWindowIndex))
            this.timeWindowIndex = 1;
        if(this.predefined) {
            let start = new Date();
            if (this.predefined === 'now')
                return start
            if (this.predefined === 'day') {
                start.setHours(alignH, alignM, 0);
                if(new Date() < start)
                    start.setDate(start.getDate() - 1);
                if (this.isIndexed)
                    start.setDate(start.getDate() - (this.timeWindowIndex - 1));
                return start;
            }
            else if (this.predefined === 'week') {
                start.setHours(alignH, alignM, 0);
                start.setToPreviousDayOfWeek(1);  //Set to monday
                if(this.isIndexed)
                    start.setDate(start.getDate() - 7 * (this.timeWindowIndex - 1));
            }
            else if (this.predefined === 'month') {
                start.setHours(alignH, alignM, 0);
                start.setDate(1);
                if (this.isIndexed){
                    start.setMonth(start.getMonth() - (this.timeWindowIndex - 1))
                }
            }
            else if (this.predefined === 'year') {
                start.setHours(alignH, alignM, 0);
                start.setDate(1);
                start.setMonth(0);
                if (this.isIndexed){
                    start.setFullYear(start.getFullYear() - (this.timeWindowIndex - 1))
                }
            }
            else if (TimeSpan.namedMonthsTimeWindowsMinutes.includes(this.predefined)) {
                let selectedMonth = TimeSpan.namedMonthsTimeWindowsMinutes.indexOf(this.predefined);
                let currentMonth = (new Date()).getMonth();
                let year = (new Date()).getFullYear();
                if(currentMonth < selectedMonth)
                    year -= 1;
                start.setHours(alignH, alignM, 0);
                start.setFullYear(year, selectedMonth, 1);
                if(this.isIndexed){
                    start.setFullYear(year - (this.timeWindowIndex - 1), selectedMonth, 1);
                }
            }
            else if (this.predefined === "shift") {
                start = new Date();
                let iterations = this.isIndexed ? this.timeWindowIndex : 1;
                for(let i = 0 ; i < iterations ; i++) {
                    if(i > 0)
                        start.setMinutes(start.getMinutes() + 1);
                    let curShifts = shifts.getShiftsInfo(null, start);
                    if (!Array.isUseful(curShifts))
                        return start;

                    let shiftStart = new Date(start.getTime());
                    if(i === 0)
                        shiftStart.setHours(parseInt(curShifts[0].from.split(":")[0]), parseInt(curShifts[0].from.split(":")[1]), 0);
                    else
                        shiftStart.setHours(parseInt(curShifts[0].previousFrom.split(":")[0]), parseInt(curShifts[0].previousFrom.split(":")[1]), 0);

                    if (start < shiftStart) {
                        start.setDate(start.getDate() - 1);
                    }

                    start.setHours(shiftStart.getHours());
                    start.setMinutes(shiftStart.getMinutes());
                    start.setSeconds(0);
                }
            } else {
                let offset = this.predefined * this.timeWindowIndex * 60;
                start = new Date();
                if(this.alignToTimeOfDay && this.predefined >= 1440) //Start applying daytime alignment from 1 day over
                    start.setHours(alignH, alignM, 0);
                if(align) {
                    //TODO evaluate exposing alignment option to user or auto calculate it in case of time bucketed aggregations
                    //and align to 1minute to avoid flickering of data when aggregation window moves continuously
                    start.setSeconds(Math.round(start.getSeconds() / 5) * 5); //Work with 5 seconds base to avoid windowing artifacts in elastic aggregations, results may jitter out of requested window
                    start.setSeconds(start.getSeconds() - offset/* - 60*/);
                    start.setMilliseconds(0);
                }
                else start.setSeconds(start.getSeconds() - offset);
            }

            return start;
        }
        else return new Date(this.start);
    }

    getEnd(ignoreSliding = false) {
        if(this.isSliding && !ignoreSliding && this.slidingBounsValid()) {
            let start = this.getStart(false, true);
            start.setSeconds(start.getSeconds() + this.slidingBounds[1]);
            return start;
        }
        let alignH = 23;
        let alignM = 59;
        let alignS = 59;
        if(this.alignToTimeOfDay) {
            alignH = parseInt(this.alignToTimeOfDay.split(":")[0]);
            alignM = parseInt(this.alignToTimeOfDay.split(":")[1]);
            alignS = 0;
        }
        if(!Object.isUseful(this.timeWindowIndex))
            this.timeWindowIndex = 1;

        if(this.predefined) {
            let end = new Date();
            // if(alignToTimeOfDay)
            //     end.setHours(alignH, alignM, alignS);
            if (this.timeToPredefined && this.timeToPredefined !== 'now') {
                let alignH = 0;
                let alignM = 0;
                if (this.timeToPredefined === 'day') {
                    end = new Date(end.setHours(23, 59, 59, 999))
                    if (this.isIndexed)
                        end.setDate(end.getDate() + (this.timeWindowIndex - 1));
                }
                else if (this.timeToPredefined === 'week') {
                    end.setDate(end.getDate() - end.getDay() + 7);
                    end = new Date(end.setHours(23, 59, 59, 999))
                }
                else if (this.timeToPredefined === 'month') {
                    let lastDayOfMonth = new Date(end.getFullYear(), end.getMonth()+1, 0);
                    end = new Date(lastDayOfMonth.setHours(23, 59, 59, 999))
                }
                else if (this.timeToPredefined === 'year') {
                    let lastDayOfYear = new Date(end.getFullYear(), 11, 31);
                    end = new Date(lastDayOfYear.setHours(23, 59, 59, 999))
                }
                else {
                    let offset = this.timeToPredefined * this.timeWindowIndex * 60;
                    end = new Date();
                    if(this.alignToTimeOfDay && this.timeToPredefined >= 1440) //Start applying daytime alignment from 1 day over
                        end.setHours(alignH, alignM, 0);
                    else end.setSeconds(end.getSeconds() + offset);
                }
            }

            if (TimeSpan.namedMonthsTimeWindowsMinutes.includes(this.predefined)) {
                let selectedMonth = TimeSpan.namedMonthsTimeWindowsMinutes.indexOf(this.predefined);
                let currentMonth = (new Date()).getMonth();
                let year = (new Date()).getFullYear();
                if (currentMonth < selectedMonth)
                    year -= 1;
                end.setFullYear(year - (this.timeWindowIndex-1), selectedMonth, new Date(year, selectedMonth + 1, 0).getDate());
                end.setHours(alignH, alignM, alignS);
            }
            else if (this.predefined === "shift") {
                end = new Date();
                let iterations = this.isIndexed ? this.timeWindowIndex : 1;
                for(let i = 0 ; i < iterations ; i++) {
                    if(i > 0)
                        end.setMinutes(end.getMinutes() - 1);
                    let curShifts = shifts.getShiftsInfo(null, end);
                    if (!Array.isUseful(curShifts))
                        return end;

                    let shiftEnd = new Date(end.getTime());
                    if(i === 0) {
                        shiftEnd.setHours(parseInt(curShifts[0].to.split(":")[0]), parseInt(curShifts[0].to.split(":")[1]), 0);
                        if (end > shiftEnd)
                            end.setDate(end.getDate() + 1);
                    }
                    else {
                        shiftEnd.setHours(parseInt(curShifts[0].previousTo.split(":")[0]), parseInt(curShifts[0].previousTo.split(":")[1]), 0);
                        if (end < shiftEnd)
                            end.setDate(end.getDate() - 1);
                    }

                    end.setHours(shiftEnd.getHours());
                    end.setMinutes(shiftEnd.getMinutes());
                    end.setSeconds(0);
                }
            // } else if (this.predefined === 'day' && alignToTimeOfDay) {
            //     if(new Date() > end)
            //         end.setDate(end.getDate() + 1);
            } else if (this.isIndexed) {
                let start = this.getStart(true);
                if (this.predefined === 'day') {
                    end = start;
                    end.setHours(alignH, alignM, alignS);
                }
                else if (this.predefined === 'week') {
                    if((end.getTime() - start.getTime()) / (1000 * 3600 * 24)>6){
                        end = start;
                        end.setDate(end.getDate() + 6);
                        end.setHours(alignH, alignM, alignS);
                    }
                }
                else if (this.predefined === 'month') {
                    if(start.getMonth()!==end.getMonth()){
                        end=start;
                        end.setMonth(end.getMonth()+1);
                        end.setDate(end.getDate()-1);
                        end.setHours(alignH, alignM, alignS);
                    }
                }
                else if (this.predefined === 'year') {
                    if (start.getFullYear()!==end.getFullYear()){
                       end=start;
                       end.setFullYear(end.getFullYear()+1);
                       end.setDate(end.getDate()-1);
                       end.setHours(alignH, alignM, alignS);
                    }
                } else {
                    let offset = this.predefined * (this.timeWindowIndex - 1) * 60;
                    end = new Date();
                    if(this.alignToTimeOfDay)
                        end.setHours(alignH, alignM, alignS);
                    end.setSeconds(end.getSeconds() - offset);
                }
            }

            return end;
        }
        else if(this.end != null) return new Date(this.end);
        else return null;
    }

    get isFixedStart() {
        if(this.predefined)
            return ((['day','week','month','year'].concat(TimeSpan.namedMonthsTimeWindowsMinutes)).includes(this.predefined));
        else return true;
    }

    get duration() {
        return (this.getEnd() - this.getStart()) / 1000;
    }

    get predefinedDuration() {
        return (this.getEnd(true) - this.getStart(false,true)) / 1000;
    }
    // getTimeSpan(span, alignStart = true) {
    //
    //     let returning = { start: new Date(), end: new Date(), duration: null, isFixedStart: true };
    //     returning.start.setHours(0,0,0);
    //
    //     if(span === 'day') {/*ok with default*/}
    //     else if(span === 'week') returning.start.setToPreviousDayOfWeek(1);
    //     else if(span === 'month') returning.start.setDate(1);
    //     else if(span === 'year') { returning.start.setDate(1); returning.start.setMonth(0); }
    //     else {
    //         returning.start = this.getDateTimeFromNow(span * 60, alignStart);
    //         returning.isFixedStart = false;
    //     }
    //
    //     returning.duration = (returning.end - returning.start) / 1000;
    //
    //     return returning;
    // }

    zoomIn(maxTimeWindow = 0) {
        this.zoom('in', maxTimeWindow, -1);
    }

    zoomOut(maxTimeWindow = 0) {
        this.zoom('out', maxTimeWindow, -1);
    }

    zoom(direction, maxTimeWindow = "", initialValue = -1) {
        if(initialValue === -1)
            initialValue = this.predefinedIndex;
        if(direction === 'out' && this.predefinedIndex < TimeSpan.timeWindowsInMinutes.length - 1)
            this.predefinedIndex++;
        else if(direction === 'in' && this.predefinedIndex > 0)
            this.predefinedIndex--;
        else if(initialValue !== -1) {
            this.predefinedIndex = initialValue;
            this.predefined = TimeSpan.timeWindowsInMinutes[this.predefinedIndex];
            return;
        }
        this.predefined = TimeSpan.timeWindowsInMinutes[this.predefinedIndex];
        if(maxTimeWindow && TimeSpan.timeSpanExceedsLimit(this.predefined, maxTimeWindow))
            this.zoom(direction, maxTimeWindow, initialValue);
        if(this.hiddenValues.includes(this.predefined))
            this.zoom(direction, maxTimeWindow, initialValue);
    }

    setIndexedMode(value) {
        this.isSliding = false;
        this.isIndexed = value;
        this.timeWindowIndex = 1;
    }
    nextStepWindow() { this.timeWindowIndex++; }
    prevStepWindow() { this.timeWindowIndex--; }

    setSlidingMode(value) {
        this.isIndexed = false;
        this.isSliding = value;
        this.slidingBounds = [0, this.duration];
    }
    setSlidingBounds(bounds) { this.slidingBounds = bounds; }
    slidingBounsValid() {
        if(!Array.isUseful(this.slidingBounds))
            return false;
        if(this.slidingBounds.length !== 2)
            return false;
        if(this.slidingBounds[1] === 0)
            return false;
        return true;
    }
    setAlignToTimeOfDay(timeOfDay) {
        this.alignToTimeOfDay = timeOfDay
    }

    static format(span) {
        let temp = new TimeSpan();
        temp.setPredefined(span);
        return temp.toString();
    }

    fromToToString(dateTimeFormat = "yy/MM/dd HH:mm"){
        return VueInstance.get().$gettext("from {0} to {1}").format(this.getStart().format(dateTimeFormat), this.getEnd().format(dateTimeFormat));
    }

    toString(dateTimeFormat = "yy/MM/dd HH:mm") {
        if(this.predefined) {
            if (this.predefined === 'day') return !this.isIndexed || this.timeWindowIndex===1?VueInstance.get().$gettext("TODAY"):VueInstance.get().$gettext("DAY");
            if (this.predefined === 'week') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("THIS WEEK"):VueInstance.get().$gettext("WEEK");
            if (this.predefined === 'month') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("THIS MONTH"):VueInstance.get().$gettext("MONTH");
            if (this.predefined === 'year') return !this.isIndexed || this.timeWindowIndex===1?VueInstance.get().$gettext("THIS YEAR"):VueInstance.get().$gettext("YEAR");
            if (this.predefined === 'shift') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("THIS SHIFT"):VueInstance.get().$gettext("SHIFT");
            if (this.predefined === 'now') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("NOW"):VueInstance.get().$gettext("NOW");
            if (this.predefined === 'ever') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("EVER"):VueInstance.get().$gettext("EVER");
            if (TimeSpan.namedMonthsTimeWindowsMinutes.includes(this.predefined)) {
                let months = [VueInstance.get().$gettext("January"), VueInstance.get().$gettext("February"), VueInstance.get().$gettext("March"), VueInstance.get().$gettext("April"), VueInstance.get().$gettext("May"), VueInstance.get().$gettext("June"), VueInstance.get().$gettext("July"), VueInstance.get().$gettext("August"), VueInstance.get().$gettext("September"), VueInstance.get().$gettext("October"), VueInstance.get().$gettext("November"), VueInstance.get().$gettext("December")];
                return months[TimeSpan.namedMonthsTimeWindowsMinutes.indexOf(this.predefined)]
            }
            return secondsToString(this.predefined * 60)
        }
        else {
            return VueInstance.get().$gettext("from {0} to {1}").format(this.getStart().format(dateTimeFormat), this.getEnd().format(dateTimeFormat));
        }
    }

    timeToToString() {
        if(this.timeToPredefined) {
            if (this.timeToPredefined === 'day') return !this.isIndexed || this.timeWindowIndex===1?VueInstance.get().$gettext("TODAY"):VueInstance.get().$gettext("DAY");
            if (this.timeToPredefined === 'week') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("THIS WEEK"):VueInstance.get().$gettext("WEEK");
            if (this.timeToPredefined === 'month') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("THIS MONTH"):VueInstance.get().$gettext("MONTH");
            if (this.timeToPredefined === 'year') return !this.isIndexed || this.timeWindowIndex===1?VueInstance.get().$gettext("THIS YEAR"):VueInstance.get().$gettext("YEAR");
            if (this.timeToPredefined === 'shift') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("THIS SHIFT"):VueInstance.get().$gettext("SHIFT");
            if (this.timeToPredefined === 'now') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("NOW"):VueInstance.get().$gettext("NOW");
            if (this.timeToPredefined === 'ever') return !this.isIndexed|| this.timeWindowIndex===1?VueInstance.get().$gettext("EVER"):VueInstance.get().$gettext("EVER");

            return secondsToString(this.timeToPredefined * 60)
        }
    }

}

export {TimeSpan};

export default {

    getRfc3339TimeStamp: getRfc3339TimeStamp,
    getRfc3339TimeFromNow: getRfc3339TimeFromNow,
    getDateTimeFromNow: getDateTimeFromNow,
    isValidDate: isValidDate,
    formatTime: formatTime,
    daysOfWeek: daysOfWeek,
    monthsOfYear: monthsOfYear,
    convertDateTime: convertDateTime,
    secondsToString: secondsToString,
    indexedDaysOfWeek() {
        let daysOfWeek = this.daysOfWeek();
        let returning = [];
        for(let [index, day] of daysOfWeek.entries())
            returning.push({text: day, value: index});
        return returning;
    },
    timeStringFromMinuteOfDay(minuteOfDay) {
        let hours = Math.floor(minuteOfDay / 60);
        let minutes = minuteOfDay % 60;
        let format = hours < 10 ? "0{0}:" : "{0}:";
        format += minutes < 10 ? "0{1}" : "{1}";
        return format.format(hours, minutes);
    }
}
