export default async ({ app, router, store, Vue }) => {
  Vue.prototype.$kiosk = window
  Vue.prototype.$kiosk.IsDebug = function () {
    if (process.env.NODE_ENV === 'development' && !Vue.prototype.$kiosk.Enabled()) {
      return true
    } else if (Vue.prototype.$store.state.shared.settings.DisablePrinters === 'true') {
      return true
    } else {
      return false
    }
  }
  Vue.prototype.$kiosk.Enabled = function () {
    return Vue.prototype.$kiosk !== undefined && Vue.prototype.$kiosk.callbacks !== undefined && Vue.prototype.$kiosk.callbacks.print !== undefined
  }
}
