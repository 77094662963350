<template>
    <v-menu
            v-model="menuOpened"
            :close-on-content-click="false"
            lazy
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            full-width
            max-width="290px"
            min-width="290px"
            id="menu-time-picker">
        <template v-slot:activator="{ on }">
            <v-text-field
                class="osk"
                :value="ctime"
                :label="$i18n.CommonAttributes()['click to set']"
                prepend-icon="access_time"
                readonly
                v-on="on"
                :disabled="disabled"
                id="txt-timepicker-ctime"
            ></v-text-field>
        </template>
        <v-time-picker
                v-if="menuOpened"
                v-model="ctime"
                full-width
                format="24hr"
                id="pck-time-picker-ctime"
                @click:minute="menuOpened = false"
        ></v-time-picker>
    </v-menu>
</template>

<script>
    export default {
        name: "PopUpTimePicker",
        data () {
            return {
                time: "",
                menuOpened: false,
            }
        },
        props: {
            value: [Number,String,Date],
            type: {
                type: String,
                required: true,
                validator: function (value) {
                    // The value must match one of these strings
                    return ['date', 'minuteOfDay', 'minsColonSecs'].indexOf(value) !== -1
                }
            },
            disabled: false
        },
        watch: {
            menuOpened() {
                if(!this.menuOpened)
                    this.valueChanged();
            },
            value() {
                this.time = "";
            }
        },
        computed: {
            ctime: {
                get () {
                    if(!this.time) {
                        let returning = new Date('01/01/70 00:00');
                        if(Object.isUseful(this.value)) {
                            if (typeof this.value === 'number' && this.type === "minuteOfDay")  //Value expressed as minute of day
                                returning.setHours(this.value / 60, this.value % 60, 0);
                            else if (typeof this.value === 'string' && this.type === "minsColonSecs")
                                returning = new Date('01/01/70 ' + this.value);
                            else if (typeof this.value === 'string' && this.type === "date")
                                returning = new Date(this.value);
                            else if(this.type === "date")
                                returning = this.value;
                        }
                        this.time = returning.format("HH:mm");
                    }
                    return this.time;
                },
                set (val) {
                    this.time = val;
                }
            }
        },
        methods: {
            valueChanged() {
                //return time in the same format (Date or MinuteOfDay) as the input
                let toBeEmitted = null;
                if(this.type === 'minuteOfDay')
                    toBeEmitted = (new Date('01/01/70 ' + this.time)).getMinuteOfDay();
                else if(this.type === 'minsColonSecs')
                    toBeEmitted = this.time;
                else toBeEmitted = (new Date('01/01/70 ' + this.time));

                this.$emit('input', toBeEmitted);
                this.$emit('change', toBeEmitted);
            }
        }
    }
</script>

<style scoped>

</style>