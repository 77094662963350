<template>
    <v-container fluid fill-height :style="' width: 100%; background-color: ' + landingPageSettings.color">
        <v-layout v-if="!preloading" justify-center align-center column>
            <v-spacer></v-spacer>
            <v-layout row wrap align-center mt-0 mb-0 v-if="landingPageSettings.logo">
                <v-flex v-for="picture in landingPageSettings.logo">
                    <img class="mx-auto" :src="picture" width="300"/>
                </v-flex>
            </v-layout>
            <h2 class="text-xs-center mt-0" v-if="landingPageSettings.title">{{landingPageSettings.title}}</h2>
            <h3 class="text-xs-center mt-0" v-if="landingPageSettings.subtitle">{{landingPageSettings.subtitle}}</h3>
            <img v-if="image" :src="image" class="baseLoader mt-0" />
            <!--<label style="position: absolute; top: calc(50% + 80px);" class="title">{{message}}</label>-->
            <label class="title mt-0 mb-0" v-if="trackingMode">Tracking item {{$route.params.pathMatch}}</label>
            <label v-if="error" class="title">{{error}}</label>
            <v-spacer></v-spacer>
        </v-layout>
    </v-container>
</template>

<script>

    import Transactions from '@/api/transactions'
    import OrchestratorAPI from '@/api/orchestrator'
    import LandingServices from '@/api/landing-services'

    export default {
        name: 'Scan',
        data: () => ({
            landingPageSettings: {
                logo: "",
                title: "",
                subtitle: "",
                color: "",
                imageLoader: "",
                redirectOnError: ""
            },
            transaction: {},
            result: null,
            error: null,
            loading: true,
            preloading: true,
            trackingMode: false
        }),
        computed: {
            image() {
                if (this.loading) {
                    if(this.landingPageSettings.imageLoader)
                        return this.landingPageSettings.imageLoader;
                    else return require('@/assets/avloader.png');
                }
                else if (this.error)
                    return require('@/assets/sad.svg');
            },
            defaultLogo() {
                return require('@/assets/logo-avionics.png')
            }
        },
        async mounted() {
            this.loadSettings();
            this.$config.operationMode = "scan";
            await this.$root.applicationStartUp("scan");
            this.$store.state.globalHeader = false;
            this.$store.state.globalDrawer = false;
            this.transaction.EntityKeys = this.getTransactionKeys();
            let self = this;
            if(this.transaction.EntityKeys.form)
                setTimeout(() => {
                    self.loadForm();
                }, 2000);
            else {
                self.transaction = true;
                setTimeout(() => {
                    self.prepareTransaction();
                }, 500);
            }
        },
        methods: {
            loadSettings() {
                let self = this;
                this.$settings.loadLandingPageSettings()
                    .then(settings => {
                        if (settings) {
                            self.landingPageSettings.logo = settings.logo;
                            self.landingPageSettings.title = settings.title;
                            self.landingPageSettings.subtitle = settings.subtitle;
                            self.landingPageSettings.color = settings.color;
                            self.landingPageSettings.imageLoader = settings.imageLoader;
                            self.landingPageSettings.redirectOnError = settings.redirectOnError;
                        } else this.loadDefaultStyle();
                    })
                    .catch(err => {
                        this.loadDefaultStyle();
                    })
                    .finally(() => {
                        self.preloading = false;
                    })
            },
            loadDefaultStyle() {
                if(!this.trackingMode) {
                    this.landingPageSettings.logo = [ this.defaultLogo ];
                    this.landingPageSettings.title = "Antares Vision {appName}".format({appName: this.$config.appName});
                }
            },
            getTransactionKeys() {
                let rawPars = this.$route.params.pathMatch;
                if(rawPars[0] === "/")
                    rawPars = rawPars.substring(1);
                //AN encoded GS1 dlink
                if(rawPars.includesMulti(["01/", "21/"])) {
                    rawPars = rawPars.replace("01/", "gtin/");
                    rawPars = rawPars.replace("21/", "serial/");
                }
                let params = rawPars.split("/");
                let keys = {};
                for(let i = 0 ; i < params.length ; i += 2)
                    keys[params[i]] = ((i < (params.length - 1)) ? params[i + 1] : "");
                return keys;
            },
            executeTransaction(coordinates) {
                if(coordinates) {
                    this.transaction.Position = {lat: "", lon: ""};
                    this.transaction.Position.lat = coordinates.lat;
                    this.transaction.Position.lon = coordinates.lng;
                    this.transaction.PositionAccuracyInMetres = coordinates.accuracy;

                    if (coordinates.accuracy < 500)
                        this.transaction.PositionAccuracyLevel = 2;
                    else
                        this.transaction.PositionAccuracyLevel = 1;
                } else {
                    this.transaction.Position = null;
                    this.transaction.PositionAccuracyLevel = 0;
                }

                let self = this;
                Transactions.ExecuteTransaction(this.transaction)
                    .then((result) => {
                        self.loading = false;
                        self.result = result;
                        if (!result) {
                            if(self.landingPageSettings.redirectOnError)
                                window.location.replace(self.landingPageSettings.redirectOnError);
                            else
                                self.error = "Nothing here, sorry";
                            return
                        }
                        if (result && result.type === "redirect")
                            window.location.replace(JSON.parse(result.endpoint));
                        else if (result && result.type === "error")
                            self.error = result.message;
                        debugger
                    })
                    .catch((error) => {
                        console.log(error);
                        if(self.landingPageSettings.redirectOnError)
                            window.location.replace(self.landingPageSettings.redirectOnError);
                        else
                            self.error = "Nothing here, sorry";
                        self.loading = false;
                        debugger
                    })
            },
            prepareTransaction() {
                let self = this;
                this.$getLocation({
                    enableHighAccuracy: true,
                    //timeout: 5,
                })
                    .then(coordinates => {
                        self.executeTransaction(coordinates);
                        // self.transaction.Position.lat = Math.random() * 90 * (Math.random() > 0.5 ? -1 : 1);
                        // self.transaction.Position.lon = Math.random() * 180 * (Math.random() > 0.5 ? -1 : 1);
                        // AuthApi.doLogin("system", "scan")
                        //     .then(t => {
                        //       let answer = JSON.parse(t.data.Data);
                        //       answer.Grants = answer.Grants || '9';
                        //       self.$store.dispatch('setSession', {
                        //         token: answer.access_token,
                        //         userName: answer.userName,
                        //         grants: answer.Grants
                        //       })
                        //     })
                        //     .finally(() => {
                    })
                    .catch(error => {
                        debugger
                        LandingServices.GetIP(null, null)
                            .then((result) => {
                                self.executeTransaction(result);
                            })
                            .catch((error) => {
                                self.executeTransaction(null);
                            });
                        // self.error = "No access to position, please activate position services and rescan code";
                        // self.loading = false;
                        console.log(error);
                    })
            },
            loadForm() {
                this.$router.push({path:"/forms/" + this.transaction.EntityKeys.form});
            }
        }
    }
</script>
