<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer"  class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card  class="mt-0 av-card" elevation="0" style="height: 80px;">
            <v-layout row wrap>
                <v-btn class="ma-4" @click="addOperationMode" color="info">
                    <av-icon :id="'newOpModeBtn'" light small left>fa-plus</av-icon>
                    <translate>New operation mode</translate>
                </v-btn>
                <v-select class="mt-4 mb-4 ml-2 mr-2" style="max-width: 300px;" v-model="operationMode" :items="operationModes" :label="$gettext('Select an operation mode')" @change="getOperationModeDescription($event)"
                          ></v-select>
                <v-spacer></v-spacer>
                <v-btn v-if="operationMode" class="ma-4" @click="deleteOperationMode" color="error">
                    <av-icon :id="'newOpModeBtn'" light small left>fas fa-trash</av-icon>
                    <translate>Delete operation mode</translate>
                </v-btn>
            </v-layout>
        </v-card>
        <v-card  class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)">
            <v-card-text  class="av-card-content" >
                <ConfigurationsEditor style="max-height: calc(100vh - 100px)" v-if="operationModeDescription" :descriptor="operationModeDescription"  @value-Changed="editOperationMode"/>
                <av-input-box ref="inputBox" :validator="operationModeNameValidator" />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
    import Supervisor from "@/api/supervisorApi";
    import gmaps from "../../api/gmaps";

    export default {
        name: "OperationModes",
        components: {ConfigurationsEditor},
        data () {
            return {
                actionTrail: "",
                actionTrailId: "",
                nextValTrail: "",
                previousValTrail: "",
                operationModes:[],
                operationMode:"", // operation mode 's name
                templateOperationModeDescription: {
                    parametersConfiguration: {
                        visible: true,
                        groupName: this.$gettext("Operation mode settings"),
                        settings: {
                            //FN: DO NOT DELETE
                            // defaultOperationMode: {
                            //     name: this.$gettext("This operation mode is default"),
                            //     value: false,
                            //     description: "",
                            //     type: "bool",
                            //     readonly: false,
                            //     //TODO CHECK
                            //     //visible: this.$license.hasManager(),
                            // },
                            workordersReceptionFromExternalSystems: {
                                name: this.$gettext("Allow workorders reception from external systems"),
                                value: false,
                                description: "",
                                type: "bool",
                                readonly: false
                            },
                            workordersCreationInAVionics: {
                                name: this.$gettext("Allow workorders creation in {appName}").format({appName: this.$config.appName}),
                                value: false,
                                description: "",
                                type: "bool",
                                readonly: false
                            },
                            workordersStateTransitionsFromExternalSystems: {
                                name: this.$gettext("Allow workorders state transitions from external systems"),
                                value: false,
                                description: "",
                                type: "bool",
                                readonly: false
                            },
                            workordersStateTransitionsInAVionics: {
                                name: this.$gettext("Allow workorders state transitions in {appName}").format({appName: this.$config.appName}),
                                value: false,
                                description: "",
                                type: "bool",
                                readonly: false
                            },
                            convertWorkorderStatusToTransition: {
                                name: this.$gettext("Execute state transitions when external system changes workorder state"),
                                value: false,
                                description: "",
                                type: "bool",
                                readonly: false
                            },
                           continuousManufacturing: {
                                name: this.$gettext("Continuous manufacturing"),
                                value: false,
                                description: "",
                                type: "bool",
                                readonly: false
                            },
                            mandatoryHookL3:{
                                name: this.$gettext("Mandatory workorder notification to L3"),
                                value: false,
                                description: "",
                                type: "bool",
                                readonly: false
                            }
                        }
                    }
                },
                operationModeDescription: null,
                settingsValues: null,
                //FN Do not delete
                // flows: [],
                currentWoOperationModeName: "",
            }
        },
        async created() {
            if(this.$root.startedUp) {
                //FN Do not delete
                // await this.loadFlows();
                await this.loadCurrentWoOperationMode();
                await this.loadConfiguredOperationModesList();
            }
        },
        mounted: function () {
            // if(this.$root.startedUp) {
            //     await this.loadConfiguredOperationModesList();
            // }
        },
        computed: {},
        watch: {},
        methods: {
            //Get the list of operation modes that has a description file saved in DB
            // loadConfiguredOperationModesList() {
            //     let self = this;
            //     this.operationModes.clear();
            //
            //     this.$settings.load(this.$settings.OperationModesSettings, "", "")
            //         .then(result => {
            //             //TODO check
            //             this.settingsValues = result;
            //             if(Object.isUseful(result)) {
            //                 if(result.hasOwnProperty("OperationModes")) {
            //                     this.operationModes = Object.keys(result["OperationModes"]).filter(x=> x!=="default");
            //                 }
            //             }
            //         });
            // },

            //FN Do not delete
            // async loadFlows(){
            //     this.flows = await Workflow.flows();
            //     debugger;
            // },

            //the current operation mode selected in wo 's page
            async loadCurrentWoOperationMode() {
                let self = this;
                this.$workorders.getCurrentOperationMode()
                    .then((result) => {
                        // self.isInProduction = result["WoIsInProduction"];
                        //check If there is only the field isInProduction than the opmpode default was not created
                        delete result["WoIsInProduction"];
                        self.currentWoOperationModeName = Object.keys(result).length > 0 ? result.name : "";
                    })
                    .catch(err => {
                    debugger
                        self.initialStateOperationMode = 0;
                        self.$root.showErrorNotification(self.$gettext("An error occurred while loading current operation mode"), true, false);
                    })

            },


            async loadConfiguredOperationModesList() {
                let self = this;
                this.operationModes.clear();

                this.settingsValues = await this.$settings.loadOperationModesSettings();
                if(Object.isUseful(this.settingsValues)) {
                    if(this.settingsValues.hasOwnProperty("OperationModes")) {
                        //FN Do not delete
                        // this.operationModes = Object.keys(this.settingsValues["OperationModes"]).filter(x=> x!=="default" && x!=="workFlows");
                        this.operationModes = Object.keys(this.settingsValues["OperationModes"]).filter(x=> x!=="default");
                        debugger
                    }
                }
            },

            getOperationModeDescription(operationMode) {
                this.operationMode = operationMode;

               let settingValuesTmp = this.$utils.detach(this.settingsValues["OperationModes"][operationMode]);

               this.operationModeDescription = this.$utils.detach(this.templateOperationModeDescription);
                //FN: DO NOT DELETE
               // this.mergeSetting(this.operationModeDescription.parametersConfiguration.settings.defaultOperationMode, settingValuesTmp.defaultOperationMode);
               this.mergeSetting(this.operationModeDescription.parametersConfiguration.settings.workordersReceptionFromExternalSystems, settingValuesTmp.workordersReceptionFromExternalSystems);
               this.mergeSetting(this.operationModeDescription.parametersConfiguration.settings.workordersCreationInAVionics, settingValuesTmp.workordersCreationInAVionics);
               this.mergeSetting(this.operationModeDescription.parametersConfiguration.settings.workordersStateTransitionsFromExternalSystems, settingValuesTmp.workordersStateTransitionsFromExternalSystems);
               this.mergeSetting(this.operationModeDescription.parametersConfiguration.settings.workordersStateTransitionsInAVionics, settingValuesTmp.workordersStateTransitionsInAVionics);
               this.mergeSetting(this.operationModeDescription.parametersConfiguration.settings.convertWorkorderStatusToTransition, settingValuesTmp.convertWorkorderStatusToTransition);
               this.mergeSetting(this.operationModeDescription.parametersConfiguration.settings.continuousManufacturing, settingValuesTmp.continuousManufacturing);
               this.mergeSetting(this.operationModeDescription.parametersConfiguration.settings.mandatoryHookL3, settingValuesTmp.mandatoryHookL3);
            },

            editOperationMode(groupName, settingName, actionObj) {
                let self = this;
                if(settingName === "defaultOperationMode") {
                    let values = this.$utils.detach(this.operationModeDescription.parametersConfiguration.settings);
                    if(!this.settingsValues["OperationModes"]["default"] && values.defaultOperationMode.value) {
                        this.settingsValues["OperationModes"]["default"] = this.operationMode;
                        self.save(groupName, settingName, actionObj);
                    } else {
                        if(this.settingsValues["OperationModes"]["default"] !== this.operationMode && values.defaultOperationMode.value) {
                            this.$root.showDialogBox(this.$gettext("A default operation mode is already set. Are you really sure you want to set this operation mode as default? "), null, "Yes",  ()=> {
                                let tmpDefault = self.settingsValues["OperationModes"]["default"];
                                self.settingsValues["OperationModes"][tmpDefault].defaultOperationMode = false;
                                self.settingsValues["OperationModes"]["default"] = self.operationMode;
                                self.save(groupName, settingName, actionObj)
                            }, "CANCEL", function(){self.getOperationModeDescription(self.operationMode)});
                        } else {
                            //Check if there are not defaults
                            if(this.settingsValues["OperationModes"]["default"] === this.operationMode && values.defaultOperationMode.value) {
                                this.settingsValues["OperationModes"]["default"] = "";
                            }
                            self.save(groupName, settingName, actionObj);
                        }
                    }
                } else {
                    self.save(groupName, settingName, actionObj);
                }
            },

            addOperationMode() {
                let self = this;
                this.$refs.inputBox.ShowInputBox(this.$gettext("Enter identifier of new operation mode."), "", "", "OK", function (value) {
                    self.newOperationMode(value)
                }, "Cancel", null);
            },

            newOperationMode(name) {
                try {

                    if (!this.operationModeNameValidator(name) || this.operationModes.some(opMode => opMode === name)) {
                        this.$root.showErrorNotification(this.$gettext("Operation mode with name {0} already exist").format(name), true);
                        return;
                    }

                    let self = this;
                    let actionObj = {action: "added"};
                    this.actionTrail = this.$audits.items().operationModeAdded;
                    this.actionTrailId = name;
                    this.previousValTrail = "";
                    this.nextValTrail = "";

                    this.$root.setLoading(true, "Creating operation mode...");
                    this.operationMode = name;

                    this.operationModeDescription = this.$utils.detach(this.templateOperationModeDescription);

                    this.save("","", actionObj);

                    setTimeout(() => {
                        self.loadConfiguredOperationModesList();
                        self.$root.setLoading(false);
                    }, 3000)
                } catch (e) {
                    this.$root.setLoading(false);
                }
            },

            deleteOperationMode() {
                 let message = "";
                //FN: DO NOT DELETE
                // //Check the value of the defaultOperationMode parameter not yet saved
                // if(this.operationModeDescription.parametersConfiguration.settings.defaultOperationMode.value) {
                //     message = "This is the default operation mode.";
                // }
                if(this.currentWoOperationModeName === this.operationMode) {
                    message = "This is the current work orders operation mode. ";
                }
                this.$root.showDialogBox(this.$gettext("{0}Are you really sure you want to delete this operation mode? ").format(message), null, "Yes", this.delete, "CANCEL", null);
            },

            async delete() {
                try {
                    let self = this;
                    this.$root.setLoading(true, "Deleting operation mode...");

                    this.actionTrail = this.$audits.items().operationModeDeleted;
                    this.actionTrailId = this.operationMode;
                    this.previousValTrail = "";
                    this.nextValTrail = "";

                    let actionObj = {action: "deleted"};

                    await this.save("", "", actionObj);

                    setTimeout(async () => {
                        let self2 = this;
                        await self2.loadConfiguredOperationModesList();
                        //after deleting the selected op mode ,select the first op mode available
                        // if (self.settingsValues["OperationModes"].length > 1 ) {
                        //     for (let prop in self.settingsValues["OperationModes"]) {
                        //         if (self.settingsValues["OperationModes"].hasOwnProperty(prop)) {
                        //             if (typeof (self.settingsValues["OperationModes"][prop]) === 'object') {
                        //                 self.operationMode = prop;
                        //                 break;
                        //             }
                        //         }
                        //     }
                        //     //Reload the first  opmode available
                        //     self.getOperationModeDescription(self.operationMode);
                        // } else {
                        //     self.clear();
                        // }
                        if (self2.operationModes.length > 0 ) {
                            self2.operationMode = self2.operationModes[0];
                            self2.getOperationModeDescription(self2.operationMode);
                        } else {
                            self2.clear();
                        }

                        self2.$root.setLoading(false);
                    }, 3000)

                }
                catch (e) {
                    self.$root.setLoading(false);
                }
            },

            clear() {
                this.operationModes = this.operationModes.filter(opMode => opMode !== this.operationMode);
                this.operationMode = "";
                this.operationModeDescription = null;
            },

            operationModeNameValidator(name) {
                if (!name)
                    return false;
                return true;
            },    

            async save(groupName, settingName, actionObj) {
                let self = this;
                let deletedOpModeObj = {};
                let tmpOpModeName = "";
                if (Object.isUseful(actionObj) && actionObj.action !== "deleted") {
                    if(actionObj.action !== "added") {
                        this.actionTrail = this.$audits.items().operationModeEdited;
                        this.actionTrailId = this.operationMode + "." + settingName;
                        this.previousValTrail = Object.isUseful(this.settingsValues["OperationModes"][this.operationMode][settingName]) ? this.settingsValues["OperationModes"][this.operationMode][settingName].toString() : "";
                        this.nextValTrail = this.operationModeDescription[groupName].settings[settingName].value.toString();
                    } else { //Add Opearation mode
                        //TODO CHECK how to manage flows
                        if (this.settingsValues === null) {
                            this.settingsValues = {};
                            this.settingsValues["OperationModes"] = {};
                            this.settingsValues["OperationModes"]["default"] = "";
                        }
                        //FN Do not delete
                        // this.settingsValues["OperationModes"]["workFlows"] = this.flows;
                    }

                    if(!this.settingsValues["OperationModes"].hasOwnProperty(this.operationMode)) {
                        this.settingsValues["OperationModes"][this.operationMode] = {};
                    }

                    let values = this.$utils.detach(this.operationModeDescription.parametersConfiguration.settings);

                    // let values = this.$utils.detach(this.operationModeDescription.parametersConfiguration.settings);
                    //TODO make loop to autogenerate
                    // this.settingsValues["OperationModes"][this.operationMode]["defaultOperationMode"] = values.defaultOperationMode.value;
                    this.settingsValues["OperationModes"][this.operationMode]["workordersReceptionFromExternalSystems"] = values.workordersReceptionFromExternalSystems.value;
                    this.settingsValues["OperationModes"][this.operationMode]["workordersCreationInAVionics"] = values.workordersCreationInAVionics.value;
                    this.settingsValues["OperationModes"][this.operationMode]["workordersStateTransitionsFromExternalSystems"] = values.workordersStateTransitionsFromExternalSystems.value;
                    this.settingsValues["OperationModes"][this.operationMode]["workordersStateTransitionsInAVionics"] = values.workordersStateTransitionsInAVionics.value;
                    this.settingsValues["OperationModes"][this.operationMode]["convertWorkorderStatusToTransition"] = values.convertWorkorderStatusToTransition.value;
                    this.settingsValues["OperationModes"][this.operationMode]["continuousManufacturing"] = values.continuousManufacturing.value;
                    this.settingsValues["OperationModes"][this.operationMode]["mandatoryHookL3"] = values.mandatoryHookL3.value;
                } else {
                    deletedOpModeObj = this.$utils.detach(this.settingsValues["OperationModes"][this.operationMode]);
                    tmpOpModeName = this.operationMode;
                    //If delete the operationMode default must be set default =""
                    if(this.settingsValues["OperationModes"].default === this.operationMode) {
                        this.settingsValues["OperationModes"].default = "";
                    }
                    delete this.settingsValues["OperationModes"][this.operationMode];
                }

                this.$settings.save(this.settingsValues, this.$settings.OperationModesSettings, "", "json", false)
                    .then(() => {
                        self.$audits.save(self.$root.userName, self.actionTrail, self.previousValTrail, self.nextValTrail, self.actionTrailId)
                            .then(() => {})
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .catch(() => {
                        if (Object.isUseful(actionObj) && actionObj.action !== "deleted") {
                            self.$root.showErrorNotification(self.$gettext("Error saving operation mode description to DB."), true);
                        } else {
                            self.$root.showErrorNotification(self.$gettext("Error deleting operation mode description to DB."), true);
                            self.operationMode = tmpOpModeName;
                            self.settingsValues["OperationModes"][self.operationMode]  = deletedOpModeObj;
                        }
                    })
            },
            mergeSetting(target, source) {
                if (source) {
                    target.value = source;
                }
            }
        }

    }
</script>

<style scoped>

</style>