<template>
    <RuleBase />
</template>

<script>

    import RuleBase from '@/components/dynamic-elements/rules/RuleBase.vue';

    export default {
        name: "RuleAutomaticWorkorderFromVariables",
        extends: RuleBase,
        components: {
            RuleBase
        },
        data() {
            return {}
        },
        mounted() {
            this.fixedHeight = false;
            this.dataExplorationMode.requiresCrossAggregation = false;
            this.dataExplorationMode.requiresFunctions = false;
            this.dataExplorationMode.fixedOutputs = this.createDefaultOutput;
            this.properties.ruleScheduling.cron = "*/1 * * * *"; //Check every minute by default
            this.childHandlers.onNewElementCreated.push(this.createDefaultOutput);
        },
        methods: {
            createDefaultOutput() {
                let output = {
                    outputType: "automaticworkorderscheduling",
                    trigger: "",
                    triggerMode: "",
                    workorderscheduling: {
                        type: "automaticworkorderscheduling",
                        unSchedule: false,
                        variables: [
                            {
                                name: "Id",
                                title: "Workorder Id",
                                defaultValue: "",
                            },
                            {
                                name: "RecipeId",
                                title: "Recipe Id",
                                defaultValue: ""
                            },
                            {
                                name: "Material",
                                title: "Material",
                                defaultValue: ""
                            },
                            {
                                name: "QuantityToProduce",
                                title: "Quantity to produce",
                                defaultValue: ""
                            },
                            {
                                name: "ProductDescription",
                                title: "Product Description",
                                defaultValue: ""
                            }
                        ]
                    }
                };
                this.outputs.push(output);
                this.$emit('dataItemsUpdated');
            }
        }
    }

</script>

<style scoped>
    table th { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table td { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table tr { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
</style>