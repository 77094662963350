<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" class="page-container" grid-list-md text-xs-center
                 pa-3 ma-0 style="max-width: 100%;">
        <v-card class="mt-0 av-card" elevation="0" style="height: 80px;">
            <v-layout row wrap>
                <label class="headline font-weight-bold mt-4" style="margin-left: 40px">{{ machineFilter }}</label>
                <av-select class="ma-4" style="max-width: 300px" :items="devices" v-model="item" item-text="name"
                           item-value="name" return-object :label="$gettext('Select')"
                           @change="getAliasesDescriptions($event)"
                           v-if="devices.length > 1"/>
                <label v-else class="headline font-weight-bold mt-4" style="margin-left: 10px">{{ devices[0]?
                    devices[0].name : "" }}</label>
                <v-spacer></v-spacer>
                <av-button v-if="showBackButton" class="ma-4" :text="$gettext('BACK')" color="error"
                           buttonIcon="fas fa-arrow-left"
                           iconColor="light" @click="goBack"/>
                <av-button v-if="item && !showBackButton && showAssetRedirectButton" class="ma-4"
                           :text="$gettext('GO TO ASSETS')" color="info" buttonIcon="far fa-conveyor-belt"
                           iconColor="light" @click="goToAssets"/>
            </v-layout>
        </v-card>
        <v-card class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)">
            <v-card-text class="av-card-content">
                <ConfigurationsEditor v-if="item" :descriptor="machineDescriptor" @value-Changed="valueChanged"
                                      :filters="$license.modulesList()"></ConfigurationsEditor>
            </v-card-text>
        </v-card>
        <VariablePickerDialog :showDialog="showVariablePicker" @selectedItem="selectedVariable($event)" :select-field="true"
                              :filteredMapping="filteredMapping" v-if="Array.isUseful(filteredMapping)"/>
    </v-container>
</template>

<script>

    import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
    import Entity from "@/api/entities";
    import VariablePickerDialog from '@/components/dialogs/VariablePickerDialog';

    export default {
        name: "MachinesSettings",
        components: {ConfigurationsEditor, VariablePickerDialog},
        data() {
            return {
                loading: false,
                devices: [],
                item: null,
                mapping: [],
                filteredMapping: [],
                mappingForStandardVariables: [],
                mappingForStatusVariable: [],
                mappingForVariablesAliases: [],
                machineDescriptor: {
                    machineAliases: {
                        visible: true,
                        groupName: this.$gettext("Machines Aliases Settings"),
                        settings: {
                            uiAlias: {
                                name: this.$gettext("Ui Alias"),
                                value: "",
                                description: "",
                                type: "string",
                                readonly: false,
                            },
                            externalAlias: {
                                name: this.$gettext("External Alias"),
                                value: [],
                                description: "",
                                type: "stringsList",
                                multi: true,
                                readonly: false,
                            }
                        }
                    },
                    variablesConfiguration: {
                        visible: true,
                        groupName: this.$gettext("Standard Variables Settings"),
                        isDeletable: false,
                        settings: {
                            standardVariables: {
                                name: "",
                                keysLabel: this.$gettext("Standard Variable"),
                                valuesLabel: this.$gettext("DB Variable"),
                                value: [],
                                description: "",
                                type: "keyValuePair",
                                multi: true,
                                keysAutoComplete: [],
                                valuesAutoComplete: [],
                                keySelectOnlyKeys: true,
                                valuesSelectOnly: true,
                                avoidDuplicateItems: true,
                                enableImportExportExcel: false,
                                customAction: this.customAction
                            },
                        }
                    },
                    variablesAliases: {
                        visible: false,
                        groupName: this.$gettext("Variables Aliases Settings"),
                        isDeletable: false,
                        settings: {
                            variablesAliases: {
                                name: "",
                                keysLabel: this.$gettext("Variable Name"),
                                valuesLabel: this.$gettext("Variable Alias"),
                                value: [],
                                description: "",
                                type: "keyValuePair",
                                multi: true,
                                keysAutoComplete: [],
                                keySelectOnlyKeys: true, //Enable v-select for keys items
                                avoidDuplicateItems: true,
                                enableImportExportExcel: false,
                                enableTextField: true, //Enable v-text for values items
                                customAction: this.customAction
                            },
                        }
                    },
                    productionManagement: {
                        visible: true,
                        groupName: this.$gettext("Production management Settings"),
                        isDeletable: false,
                        filters: [this.$license.ManagerModule],
                        settings: {
                            automaticScheduling: {
                                name: this.$gettext("Machine shall receive recipes automatically"),
                                value: false,
                                description: this.$gettext("If enabled, when a workorder is scheduled for production, {appName} expects to find recipes for this machine and be able to configure it for production automatically").format({appName: this.$config.appName}),
                                type: "bool",
                                filters: [this.$license.ManagerModule],
                                readOnly: false,
                                visible: this.$license.hasManager()
                            },
                            simulationMode: {
                                name: this.$gettext("Use machine in simulation Mode."),
                                value: false,
                                description: this.$gettext("If checked, system will emulate machine control and recipe execution allowing you to test configuration correctness without disturbing physical machine"),
                                type: "bool",
                                filters: [this.$license.ManagerModule],
                                readOnly: false,
                                dependent: true,
                                visible: false
                            },
                        }
                    }
                },
                variablesDescription: null,
                templateVariables: null,
                // dbVariableNames: [],
                dbVariableDeltaNames: [],
                dbVariableAliases: [],
                excludedVariables: ['oee', 'nominalspeed', 'tag', 'estimatedspeed'],
                showBackButton: false,
                backPath: "",
                listOfAssets: [],
                showAssetRedirectButton: false,
                listOfAssetsForMachine: [],
                machineFilter: "",
                showVariablePicker: false,
                setting: null,
                index: 0,
                groupName: '',
                duplicatedItem: null,
                settingName: ''
            }
        },
        mounted() {
            if (this.$root.startedUp) {
                this.loadDataMappings();
            }
        },
        computed: {
            automaticSchedulingValue() {
                return this.machineDescriptor.productionManagement.settings.automaticScheduling.value
            }
        },
        methods: {
            findMachineVariables(mapping, machine) {
                let toks = machine.split(".");
                for(let tok of toks) {
                    mapping = mapping.children.find(item => item.name === tok);
                }
                return mapping
            },

            loadDataMappings() {
                this.$root.setLoading(true);
                this.$datalayer.loadDataDefinitions(null, true)
                    .then(data => {
                        if (Array.isUseful(data)) {
                            this.mapping = data;
                        }
                    })
                    .catch(t => {
                        debugger
                        console.error(t);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving data definitions from DB."), true);
                    })
                    .finally(() => {
                        this.loadConfiguredDevicesList();
                        if (!this.showBackButton)
                            this.loadAssets();
                        this.$root.setLoading(false);
                    });
            },
            loadConfiguredDevicesList() {
                //Get the list of devices that has a descriptions file saved in DB
                this.devices.clear();

                if (this.$route.params && this.$route.params.linkedMachines) {
                    this.showBackButton = true;
                    this.machineFilter = this.$gettext("Machines filtered by: ");
                    if (this.$route.params.backPath)
                        this.backPath = this.$route.params.backPath;
                    if (this.$route.params.linkedMachines.length > 1) {
                        this.$route.params.linkedMachines.forEach(linkedmachine => {
                            this.devices.push({name: linkedmachine, type: this.$settings.MachinesSettings});
                            this.machineFilter += linkedmachine + "; "
                        });
                        return;
                    } else {
                        this.devices.push({
                            name: this.$route.params.linkedMachines[0],
                            type: this.$settings.MachinesSettings
                        });
                        this.getAliasesDescriptions(this.devices[0]);
                        return;
                    }
                }

                let self = this;
                this.$devices.getAvailable()
                    .then(devices => {
                        for (let i = 0; i < devices.length; i++)
                            if (devices[i])
                                self.devices.push({name: devices[i], type: self.$settings.MachinesSettings});

                        if(self.devices.length === 1)
                            self.getAliasesDescriptions(self.devices[0]);
                    })
                    .catch(err => {
                        console.log(err);
                        self.item = null;
                        self.error = "Unable to retrieve devices info. Please try again";
                    });
            },
            getAliasesDescriptions(item) {
                this.$root.setLoading(true);
                this.item = item;
                let self = this;
                this.$settings.load(item.type, item.name)
                    .then(descriptions => {
                        self.settingsValues = descriptions;
                    })
                    .catch(err => {
                        debugger;
                        console.log(err);
                    })
                    .finally(() => {
                        //Devices
                        self.machineDescriptor.machineAliases.groupName = self.$gettext("Machines Aliases Settings");

                        //Standard Variables
                        self.dbVariableDeltaNames = [];
                        self.mappingForVariablesAliases = self.filterForAliasesMapping(self.$utils.detach(self.mapping));
                        let dbVariableNames = self.findMachineVariables(self.mapping[0], item.name);
                        self.mappingForStandardVariables = [self.$utils.detach(dbVariableNames)];
                        self.mappingForStatusVariable = [self.$utils.detach(dbVariableNames)];
                        self.mappingForStandardVariables = self.filterForStandardMapping(self.mappingForStandardVariables);
                        self.mappingForStatusVariable = self.filterForStatusMapping(self.mappingForStatusVariable);
                        dbVariableNames = self.filterItems(self.mapping, "name");
                        let dbVariables = self.extractValue(dbVariableNames, "text");
                        self.machineDescriptor.variablesConfiguration.settings.standardVariables.keysAutoComplete = Object.values(self.$aliases.StandardVariables);
                        self.machineDescriptor.variablesConfiguration.settings.standardVariables.valuesAutoComplete = dbVariables;

                        //Variables Aliases
                        self.dbVariableAliases = [];
                        self.machineDescriptor.variablesAliases.settings.variablesAliases.keysAutoComplete = self.$utils.detach(dbVariables);

                        if (Object.isUseful(self.settingsValues)) {
                            let values = self.$utils.detach(self.settingsValues);
                            self.mergeSetting(self.machineDescriptor.machineAliases.settings.uiAlias, values.uiAlias);
                            self.mergeSetting(self.machineDescriptor.machineAliases.settings.externalAlias, values.externalAlias);
                            self.mergeSetting(self.machineDescriptor.variablesConfiguration.settings.standardVariables, values.standardVariables);
                            // self.dbVariableAliases = values.variablesAliases;
                            self.mergeSetting(self.machineDescriptor.variablesAliases.settings.variablesAliases, values.variablesAliases);
                            self.mergeSetting(self.machineDescriptor.productionManagement.settings.automaticScheduling, values.automaticScheduling);
                            self.mergeSetting(self.machineDescriptor.productionManagement.settings.simulationMode, values.simulationMode);
                        } else {
                            self.machineDescriptor.machineAliases.settings.uiAlias.value = "";
                            self.machineDescriptor.machineAliases.settings.externalAlias.value = [];
                            self.machineDescriptor.variablesConfiguration.settings.standardVariables.value = [];
                            self.machineDescriptor.variablesAliases.settings.variablesAliases.value = [];
                            self.machineDescriptor.productionManagement.settings.automaticScheduling.value = false;
                            self.machineDescriptor.productionManagement.settings.simulationMode.value = false;
                        }

                        if (!self.showBackButton)
                            self.checkIfAssetsContainsMachine(item.name);
                        self.$root.setLoading(false);
                    });
            },

            extractValue(arr, prop) {
                // extract value from property
                return arr.map(item => item[prop]);
            },

            filterForStandardMapping(mapping) {
                return mapping
                    .filter((el) => el.isRoot || el.name.indexOf('_Delta') > 0)
                    .map((el) => {
                        el.name = el.name.split('_')[0];
                        if (el.displayName)
                            el.displayName = el.displayName.split('_')[0];
                        if (!el.children || !Array.isArray(el.children)) return el;
                        el.children = this.filterForStandardMapping(el.children);
                        return el;
                    });
            },
            filterForStatusMapping(mapping) {
                return mapping
                    .filter((el) => el.isRoot || (!el.name.includes('_Delta') && !mapping.some(item => item.name === el.name + '_Delta')))
                    .map((el) => {
                        if (!el.children || !Array.isArray(el.children)) return el;
                        el.children = this.filterForStatusMapping(el.children);
                        return el;
                    });
            },
            filterForAliasesMapping(mapping) {
                return mapping
                    .filter((el) => el.isRoot || !el.name.includes('_Delta'))
                    .map((el) => {
                        if (!el.children || !Array.isArray(el.children)) return el;
                        el.children = this.filterForAliasesMapping(el.children);
                        return el;
                    });
            },

            filterItems(arr, prop) {

                // extract value from property
                let variablesToRemove = [];
                let extractedValue = arr.flatMap(item => {
                    if (!this.excludedVariables.includes(item[prop].toLowerCase())) {
                        let tmpItem = item[prop];
                        if (tmpItem.indexOf('_Delta') >= 0) {
                            tmpItem = {
                                text: tmpItem.substr(0, item[prop].indexOf('_Delta')),
                                value: item[prop],
                                isCounter: true
                            };
                            variablesToRemove.push(tmpItem.text);
                        } else {
                            tmpItem = {text: item[prop], isCounter: false};
                        }
                        return tmpItem;

                    } else {
                        return []
                    }
                });

                return extractedValue.filter(item => (item.isCounter || (!item.isCounter && !variablesToRemove.includes(item.text))));
            },

            filterVariablesAliases(arr) {
                let filteredItems = [];
                if (Array.isUseful(arr)) {
                    filteredItems = arr.filter(item => (item.key.indexOf('_Delta') < 0));
                    this.dbVariableAliases = arr.filter(item => (item.key.indexOf('_Delta') >= 0));
                }
                return filteredItems;
            },

            filterVariablesDelta(arr) {
                let filteredItems = [];
                if (Array.isUseful(arr)) {
                    filteredItems = arr.filter(item => (item.value.indexOf('_Delta') < 0));
                    this.dbVariableDeltaNames = arr.filter(item => (item.value.indexOf('_Delta') >= 0));
                }
                return filteredItems;
            },

            mergeSetting(target, source) {
                if (Object.isUseful(source))
                    target.value = source;
            },

            async valueChanged(groupName, settingName, action) {
                let self = this;
                if (this.settingsValues === null)
                    this.settingsValues = {};

                let trail = this.$audits.getSettingChangedTrailObject(groupName, settingName, action, this.settingsValues, this.machineDescriptor, ["MachineSettings", this.item.name]);

                let values = this.$utils.detach(this.machineDescriptor);
                this.settingsValues.uiAlias = values.machineAliases.settings.uiAlias.value;
                this.settingsValues.externalAlias = values.machineAliases.settings.externalAlias.value;

                this.settingsValues.automaticScheduling = values.productionManagement.settings.automaticScheduling.value;
                this.settingsValues.simulationMode = values.productionManagement.settings.simulationMode.value;

                this.settingsValues["machineId"] = this.item.name;

                //Manage standard Variables or variables Aliases
                //Used to bind Variable Alias to counter variable (if exist)
                if (settingName === 'variablesAliases' && action.action !== 'deleted') {
                    let modifiedItem = values.variablesAliases.settings.variablesAliases.value[action.item];
                    if (modifiedItem.key !== '' && modifiedItem.value !== '') {
                        let counterVariable = values.variablesAliases.settings.variablesAliases.value.find(item => item.key === modifiedItem.key + '_Delta');
                        if (counterVariable !== undefined)
                            counterVariable.value = modifiedItem.value + '_Delta';
                    }
                }
                this.settingsValues.standardVariables = [...new Set([...values.variablesConfiguration.settings.standardVariables.value, ...values.variablesConfiguration.settings.standardVariables.value])];
                this.settingsValues.variablesAliases = [...new Set([...values.variablesAliases.settings.variablesAliases.value, ...values.variablesAliases.settings.variablesAliases.value])];

                this.$settings.save(this.settingsValues, this.item.type, this.item.name)
                    .then(() => {
                        self.$aliases.updateAliasesDescriptor(self.item.name, self.settingsValues);
                        //check for avoiding empty "deleted" audits
                        if (!(action.action === 'deleted' && typeof trail.previousVal === 'undefined')) {
                            self.$audits.save(trail.operator, trail.action, trail.previousVal, trail.nextVal, trail.actionId)
                                .catch(() => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                })
                        }
                    })
                    .catch(() => {
                        self.$root.showErrorNotification("Error saving aliases descriptions to DB.", true);
                    });
            },

            goBack() {
                if (this.backPath)
                    this.$router.push(this.backPath);
                else
                    this.$router.go(-1);
            },

            loadAssets() {
                let self = this;
                Entity.getAllEntities()
                    .then(t => {
                        if (t) {
                            self.listOfAssets = t.filter(item => {
                                return item.EntityKeys.SystemEntity === 'Asset'
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        self.$root.showErrorNotification(err, true, false);
                    })
            },

            checkIfAssetsContainsMachine(machine) {
                let self = this;
                self.listOfAssetsForMachine.clear();
                for (let asset of self.listOfAssets) {
                    if (asset[asset.NameSpace].LinkedMachines && asset[asset.NameSpace].LinkedMachines.includes(machine)) {
                        self.showAssetRedirectButton = true;
                        self.listOfAssetsForMachine.push(asset);
                    }
                }
                if (self.listOfAssetsForMachine.length === 0)
                    self.showAssetRedirectButton = false;
            },

            goToAssets() {
                let self = this;
                self.$router.push({
                    name: 'assetsRegister',
                    params: {listOfAssetsForMachine: self.listOfAssetsForMachine, machine: self.item.name}
                })
            },
            setSimulationModeVisible() {
                this.machineDescriptor.productionManagement.settings.simulationMode.visible = this.machineDescriptor.productionManagement.settings.automaticScheduling.value;
                // if (!this.machineDescriptor.productionManagement.settings.simulationMode.visible)
                //     this.$set(this.machineDescriptor.productionManagement.settings.simulationMode.visible, "value", false);
            },
            customAction(groupName, setting, index, settingName, key) {
                this.setting = setting;
                this.index = index;
                this.groupName = groupName;
                this.settingName = settingName;
                if (settingName === 'standardVariables')
                    if (key === 'Status')
                        this.filteredMapping = this.mappingForStatusVariable;
                    else
                        this.filteredMapping = this.mappingForStandardVariables;
                else if (settingName === 'variablesAliases')
                    this.filteredMapping = this.mappingForVariablesAliases;
                this.showVariablePicker = true;
            },
            selectedVariable(variable) {
                this.duplicatedItem = null;
                if (variable !== null) {
                    if (this.groupName === 'variablesConfiguration') {
                        let addRoot = '';
                        if (variable.root !== '' && variable.root !== this.item.name)
                            addRoot = variable.root.replace(this.item.name + '.', '');
                        this.setting.value[this.index] = { ...this.setting.value[this.index], value: addRoot !== ''? addRoot + '.' + variable.name: variable.name }
                    }
                    else if (this.groupName === 'variablesAliases') {
                        let index = this.mapping.find(item => item.index === variable.index);
                        this.setting.value[this.index] = { ...this.setting.value[this.index], displayName: variable.root !== ''? variable.root + '.' + variable.name: variable.name,
                            key: variable.name, visible: true }
                        this.checkForDeltaDuplicates(index.children, variable);
                        if (this.duplicatedItem)
                            this.setting.value[this.index + 1] = { ...this.setting.value[this.index], displayName: this.duplicatedItem.root !== ''?
                                    this.duplicatedItem.root + '.' + this.duplicatedItem.name: this.duplicatedItem.name , visible: false, key: this.duplicatedItem.name }
                    }
                    this.valueChanged(this.groupName, this.settingName, { action: 'modified', item: this.index })
                }
                this.filteredMapping = [];
                this.showVariablePicker = false;
            },
            checkForDeltaDuplicates(index, variable) {
                index.forEach(item => {
                    if (item.name.indexOf('_Delta') > 0)
                        if (item.name.split('_')[0] === variable.name && item.root === variable.root) {
                            this.duplicatedItem  = item;
                        }
                    if (item.children && Array.isUseful(item.children))
                        this.checkForDeltaDuplicates(item.children, variable);
                })
            }
        },
        watch: {
            automaticSchedulingValue: {
                handler: function () {
                    this.setSimulationModeVisible();
                },
                deep: true,
            }
        }
    }
</script>

<style scoped>

</style>