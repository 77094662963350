<template>
    <DynamicElementBase>
        <div style="height: 100%; width:100%; overflow: auto;">
            <v-select :items="['line 1', 'line 2', 'line 3']" @change="selectLine($event)"></v-select>
            <v-data-table :headers="headers" :items="dataCollection">
                <template v-slot:items="props">
                    <tr>
                        <td class="subheading">
                            {{ props.item.alarm }}
                        </td>
                        <td class="subheading">
                            {{ props.item.occurrencies }}
                        </td>
                        <td class="subheading">
                            {{ props.item.sources }}
                        </td>
                        <td class="subheading">
                            {{ props.item.durationMin != 0 ? Math.round(props.item.durationMin / 60) + "min " + Math.round(props.item.durationMin % 60) + "s" : "" }}
                        </td>
                        <td class="subheading">
                            {{ props.item.durationAvg != 0 ? Math.round(props.item.durationAvg / 60) + "min " + Math.round(props.item.durationAvg % 60) + "s" : ""}}
                        </td>
                        <td class="subheading">
                            {{ props.item.durationMax != 0 ? Math.round(props.item.durationMax / 60) + "min " + Math.round(props.item.durationAvg % 60) + "s" : "" }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
    import alarms from '@/api/alarmsApi'
    import DateTime from '@/api/datetimeutils'

    export default {
        name: "WidgetAlarmsStatistics",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase
        },
        data () {
            return {
                headers: [ { text: "Alarm name", class: "subheading", sortable: true, value: "alarms" }, { text: "Count", class: "subheading", sortable: true, value: "occurrencies" }, { text: "Source", class: "subheading" }, { text: "Min duration", class: "subheading", sortable: true, value: "durationMin" }, { text: "Avg duration", class: "subheading", sortable: true, value: "durationAvg" }, { text: "MAX duration", class: "subheading", sortable: true, value: "durationMax" } ],
                lineBreaks: [],
                dataCollection: [],
                initializer: null,
                currentIndex: ""
            }
        },
        mounted () {
            this.initializer = setInterval((function(self) {
                return function() {
                    self.customQueryHandler = self.getData;
                    clearInterval(self.initializer);
                }
            })(this), 1000);
        },
        computed () {

        },
        methods: {
            selectLine(line) {
                if(line === "line 1")
                    this.index = 'data_avionics_brescia_pec_alarms*';
                else if(line === "line 2")
                    this.index = 'data_avionics_brescia_ima_alarms*';//this.index = 'avionics~|~brescia~|~ima~|~alarms';
                else if(line === "line 3")
                    this.index = 'data_avionics_sorbolo_vri_alarms*';
                else
                    this.index = "";

                this.dataRefresh();
            },
            getData() {
                if(!this.index)
                    return null;
                return alarms.getAlarmsList(DateTime.getRfc3339TimeStamp(this.properties.timeWindow.getStart()), DateTime.getRfc3339TimeStamp(this.properties.timeWindow.getEnd()), this.index, true);
            },
            refreshData(dataValues) {
                if (!dataValues) {
                    return;
                }

                this.dataCollection.clear();
                let cleanAlarms = [];

                for(let i = 0 ; i < dataValues.length ; i++) {
                    let alarm = dataValues[i];
                    let annotation = "";
                    // let annotationObject = alarm.annotated ? JSON.parse(alarm.reason) : null;
                    // if(annotationObject) {
                    //     annotation = "Operator " + alarm.operator + " says reason is: " + alarms.getAnnotationString(annotationObject)
                    // }
                    cleanAlarms.push({
                        name: alarm.fields["VariableName"],
                        timestamp: Date.parse(alarm["@timestamp"]),
                        activateTimestamp: alarm.fields["Active"] ? (new Date(alarm["@timestamp"])).format() : "",
                        deactivateTimestamp: alarm.fields["Active"] ? "" : (new Date(alarm["@timestamp"])).format(),
                        active: alarm.fields["Active"],
                        source: alarm.tag["Source"],
                        device: alarm.tag["Device"],
                        description: alarms.getDescriptorById(alarm.fields["VariableName"], alarm.tag["Device"]).description,
                        annotation: annotation,
                        // annotationObject: annotationObject,
                        // id: alarm.RefId
                    })
                }

                cleanAlarms.sort(function (x, y) {
                    return y.timestamp - x.timestamp;
                });

                for(let i = 0 ; i < cleanAlarms.length ; i++) {
                    if(cleanAlarms[i].active == false && cleanAlarms[i].activateTimestamp === "") {
                        for(let j = i ; j < cleanAlarms.length ; j++) {
                            if(cleanAlarms[j].name == cleanAlarms[i].name && cleanAlarms[j].active && cleanAlarms[j].deactivateTimestamp === "") {
                                cleanAlarms[j].active = false;
                                cleanAlarms[j].deactivateTimestamp = cleanAlarms[i].deactivateTimestamp;
                                break;
                            }
                        }
                    }
                }

                for(let srcIndex = 0 ; srcIndex < cleanAlarms.length ; srcIndex++) {
                    let found = false;
                    let alarm = cleanAlarms[srcIndex];
                    for(let dstIndex = 0 ; dstIndex < this.dataCollection.length ; dstIndex++) {
                        if(this.dataCollection[dstIndex].alarm === cleanAlarms[srcIndex].description) {
                            found = true;
                            let duration = 0;
                            if(alarm.activateTimestamp && alarm.deactivateTimestamp)
                                duration = Math.abs((Date.parse(alarm.deactivateTimestamp) - Date.parse(alarm.activateTimestamp)) / 1000);
                            this.dataCollection[dstIndex].occurrencies++;
                            if(duration) {
                                if (duration < this.dataCollection[dstIndex].durationMin || this.dataCollection[dstIndex].durationMin === 0)
                                    this.dataCollection[dstIndex].durationMin = duration;
                                if (duration > this.dataCollection[dstIndex].durationMax)
                                    this.dataCollection[dstIndex].durationMax = duration;
                                this.dataCollection[dstIndex].occurrenciesForAvg++;
                                this.dataCollection[dstIndex].durationAvg = ((this.dataCollection[dstIndex].occurrenciesForAvg - 1) * this.dataCollection[dstIndex].durationAvg + duration) / this.dataCollection[dstIndex].occurrenciesForAvg;
                            }
                        }
                    }
                    if(!found) {
                        let duration = 0;
                        if(alarm.activateTimestamp && alarm.deactivateTimestamp)
                            duration = Math.abs((Date.parse(alarm.deactivateTimestamp) - Date.parse(alarm.activateTimestamp)) / 1000);

                        this.dataCollection.push({
                            alarm: alarm.description,
                            occurrencies: 1,
                            occurrenciesForAvg: 1,
                            sources: alarm.device,
                            durationAvg: duration,
                            durationMin: duration,
                            durationMax: duration,
                        })
                    }
                }
            },
        }
    }

</script>

<style scoped>

    table.v-table tbody td, table.v-table tbody th {
        height: 35px;
        margin: 0;
        padding: 0px;
    }

    table.v-table tbody tr:not(:last-child) {
        border-bottom: none;
        margin: 0;
        padding: 0px;
    }

    .running {
        background-color: var(--av-green);
    }

    .stopped {
        background-color: var(--av-red);
    }

    .inTime {
        background-color: var(--av-green);
    }

    .late {
        background-color: var(--av-red);
    }

</style>