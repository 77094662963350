import Vue from 'vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/dashboards/DashboardContainer.vue'
import DataExplorer from '@/views/dynamic-elements/DataExplorer'
import ExternalDash from '@/views/dashboards/ExternalDash.vue'
import ShiftScheduler from '@/views/ShiftScheduler.vue'
import Forms from '@/views/forms-and-entities/FormContainer.vue'
import Entities from '@/views/forms-and-entities/Entities.vue'
import ProductionInstances from '@/views/forms-and-entities/ActiveProductionData.vue'
import EntityDetails from '@/views/forms-and-entities/EntityDetails.vue'
import LicenseManagement from '@/views/LicenseManagement.vue'
import Scan from '@/views/Scan.vue'
import MailSettings from '@/views/configuration/MailSettings'
import LandingStyles from '@/views/configuration/LandingPageSettings'
import DashboardComposer from '@/views/dashboards/DashboardComposer'
import BulkImportExport from "@/views/configuration/BulkImportExport";
import OrphanTransactions from "@/views/OrphanTransactions";
import ReexecutionTransactions from "@/views/forms-and-entities/ReexecutionTransactions";
import ActivitiesPage from "@/views/ActivitiesPage";
import WorkordersPage from "@/views/production-management/WorkordersPage";
import PlantSettings from "@/views/configuration/PlantSettings";
import alarmsDescriptions from '@/views/configuration/AlarmsDescriptions.vue'
import LineStopActions from "@/views/configuration/LineStopCauses.vue"
import OpcDescriptions from "@/views/configuration/OpcDevices";
import OperationModes from "@/views/configuration/OperationModes";
import MachinesSettings from "@/views/configuration/MachinesSettings";
import SublinesSettings from "@/views/configuration/SublineSettings";
import workflow from "@/views/configuration/WorkflowSettings";
import translations from "@/views/Translations";
import systemSettings from "@/views/configuration/SystemSettings";
import StandardTimes from "@/views/configuration/StandardTimesSettings";
import LandingPageSettings from "@/views/configuration/LandingPageSettings";
import LineSettings from "@/views/configuration/LineSettings";
import ProductionDataDetails from "@/views/forms-and-entities/ProductionDataDetails";
import WorkorderDetails from "@/views/production-management/WorkorderDetails";
import WorkorderManualLineScheduling from "@/views/production-management/WorkorderManualLineScheduling";
import EmbeddedPage from "@/views/embeddedPage";
import SystemInfo from "@/views/SystemInfo"
import Audits from "@/views/Audits";
import Validation from "@/views/Validation"
import AssetsRegister from "@/views/forms-and-entities/AssetsRegister"
import MaintenanceRegister from "@/views/MaintenanceRegister";
import DocumentationPage from "@/views/DocumentationPage";
import VersionMismatchPage from "@/views/VersionMismatchPage"
import Processes from "@/views/production-management/MachineProcesses"
import LineRecipesConfigurator from "@/components/dynamic-elements/recipes/LineRecipesConfigurator";
import WizardContainer from "../views/forms-and-entities/WizardContainer";


const routes= [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/scan*',
    name: 'scan',
    component: Scan,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/explore/dashboard',
    name: 'explore',
    component: DashboardComposer,
  },
  {
    //Useful to deeply refresh current explorer view
    path: '/explore/:routeId/:key', /*RouteId used to make components IDs unique for TA. It is the type of elements: widgets, rules, reports, forms*/
    name: 'explore',
    component: DataExplorer
  },
  {
    //Direct path to data explorer. If refresh is needed call '/explore/:routeId/:key'above
    path: '/explore/:routeId', /*RouteId used to make components IDs unique for TA. It is the type of elements: widgets, rules, reports, forms*/
    name: 'explore',
    component: DataExplorer
  },
  {
    path: '/dashboards/:routeId/:command', /*RouteId used to make components IDs unique for TA. It is the dashboard name*/
    name: 'dashboards',
    component: Dashboard,
  },
  {
    path: '/dashboards/:routeId/:command/:zoom', /*RouteId used to make components IDs unique for TA. It is the dashboard name*/
    name: 'dashboards',
    component: Dashboard,
  },
  {
    path: '/realtime/:lineName/:dashName',
    name: 'realtime',
    component: ExternalDash
  },
  {
    path: '/shiftscheduler',
    name: 'shiftscheduler',
    component: ShiftScheduler,
  },
  {
    path: '/forms/:routeId', /*RouteId used to make components IDs unique for TA. It is the form name*/
    name: 'forms',
    component: Forms,
  },
  {
    path: '/forms/:routeId/:actionId', /*RouteId used to make components IDs unique for TA. It is the form name*/
    name: 'forms',
    component: Forms,
  },
  {
    path: '/formsedit',
    name: 'formsEdit',
    component: Forms,
  },
  {
    path: '/wizards/:routeId',
    name: 'forms',
    component: WizardContainer,
  },
  {
    path: '/entities',
    name: 'entities',
    component: Entities,
  },
  {
    path: '/entityDetails',
    name: 'entityDetails',
    component: EntityDetails,
  },
  {
    path: '/licensemanagement',
    name: 'licensemanagement',
    component: LicenseManagement
  },
  {
    path: '/configuration/mail-settings',
    name: 'mailsettings',
    component: MailSettings,
  },
  {
    path: '/configuration/scansettings',
    name: 'mscansettings',
    component: LandingStyles,
  },

  {
    path: '/configuration/line-settings',
    name: 'linesettings',
    component: LineSettings,
  },
  {
    path: '/configuration/workflow',
    name: 'workflow',
    component: workflow,
  },
  {
    path: '/configuration/landing-page',
    name: 'landingpage',
    component: LandingPageSettings,
  },
  {
    path: '/configuration/line-stop-actions',
    name: 'linestopactions',
    component: LineStopActions,
  },
  {
    path: '/configuration/standard-times',
    name: 'standardtimes',
    component: StandardTimes,
  },
  {
    path: '/configuration/translation',
    name: 'translation',
    component: translations,
  },
  {
    path: '/configuration/system-settings',
    name: 'systemsettings',
    component: systemSettings,
  },
  {
    path: '/configuration/alarms-description',
    name: 'alarmsdescription',
    component: alarmsDescriptions,
  },
  {
    path: '/configuration/opc-description',
    name: 'opcdescription',
    component: OpcDescriptions,
  },
  {
    path: '/configuration/operation-modes',
    name: 'operationmodes',
    component: OperationModes,
  },
  {
    path: '/configuration/machines-settings',
    name: 'machinesSettings',
    component: MachinesSettings
  },
  {
    path: '/configuration/sublines-settings',
    name: 'sublinesSettings',
    component: SublinesSettings
  },
  {
    path: '/configuration/bulkimportexport',
    name: 'bulkimportexport',
    component: BulkImportExport,
  },
  {
    path: '/info/system',
    name: 'systeminfo',
    component: SystemInfo,
  },
  {
    path: '/info/audits',
    name: 'audits',
    component: Audits,
  },
  {
    path: '/info/validation',
    name: 'validation',
    component: Validation,
  },
  {
    path: '/orphanTransactions',
    name: 'orphanTransactions',
    component: OrphanTransactions
  },
  {
    path: '/reexecutionTransactions',
    name: 'reexecutionTransactions',
    component: ReexecutionTransactions
  },
  {
    path: '/productionInstances',
    name: 'productionInstances',
    component: ProductionInstances
  },
  {
    path: '/productionDataDetails',
    name: 'productionDataDetails',
    component: ProductionDataDetails
  },
  {
    path: '/embeddedpage/:page',
    name: 'embeddedpage',
    component: EmbeddedPage
  },
  {
    path: '/activitiesPage',
    name: 'activitiesPage',
    component: ActivitiesPage
  },
  {
    path: '/assetsRegister',
    name: 'assetsRegister',
    component: AssetsRegister
  },
  {
    path: '/maintenanceRegister',
    name: 'maintenanceRegister',
    component: MaintenanceRegister
  },
  {
    path: '/documentationPage',
    name: 'documentationPage',
    component: DocumentationPage
  },
  {
    path: '/workordersPage',
    name: 'workordersPage',
    component: WorkordersPage
  },
  {
    path: '/workorderDetails',
    name: 'workorderDetails',
    component: WorkorderDetails
  },
  {
    path: '/workorderManualLineScheduling',
    name: 'workorderManualLineScheduling',
    component: WorkorderManualLineScheduling
  },
  {
    path: '/versionMismatchPage',
    name: 'versionMismatchPage',
    component: VersionMismatchPage
  },
  {
    path: '/configuration/plant-settings',
    name: 'plantSettings',
    component: PlantSettings
  },
  {
    path: '/processesPage',
    name: 'processesPage',
    component: Processes
  },
  {
    path: '/line-recipes-configuration',
    name: 'linerecipesconfiguration',
    component: LineRecipesConfigurator,
  },
];
// meta: {
//   breadcrumb: [
//     { name: 'logmanagement' }
//   ]
// }


// Always leave this as last one
// if (process.env.MODE !== 'ssr') {
//   routes.push({
//     path: '*',
//     component: () => import('pages/Error404.vue')
//   })
// }
Vue.prototype.$router = routes;
export default routes
