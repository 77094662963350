export default {

    alarms: "alarms",
    alarmsUrl: "/api/v1/alarms",

    analytics: "analytics",
    analyticsUrl: "/api/v1/auth/analytics",

    audits: "elastic-audits",
    auditsUrl: "/api/v1/auth/elastic-audits",

    backannotation: "avionics-backannotation",
    backannotationUrl: "/api/v1/auth/elastic-backannotator",

    documents: "elastic-documents",
    documentsUrl: "/api/v1/auth/elastic-documents",
    isIndexEmptyError(error) {
        return (error.Message && (error.Message.includes("no such index [type=index_not_found_exception]") || error.Message.toLowerCase().includes("elastic: error 404 (not found)") || error.Message.toLowerCase() === "no data"));
    },

    performance: "performance",
    performanceUrl: "/api/v1/auth/performance",

    quality: "quality",
    qualityUrl: "/api/v1/auth/quality",

    query: "ElasticQuery",
    queryUrl: "/api/v1/auth/elasticquery",
    reports: "Reports",
    reportsUrl: "/api/v1/auth",
    // { "/elasticquery/testone",  true,  http.MethodGet,  Dummy,  []string{}},
    // { "/elasticquery/listindex",  true,  http.MethodGet,  ListIndexHandler,  []string{}},
    // { "/elasticquery/query",  true,  http.MethodPost,  GetDataJSONHandler,  []string{}},
    // { "/elasticquery/getAllDataMapping",  true, http.MethodGet, GetAllDataMappingHandler, []string{}},
    // { "/elasticquery/listdevice",  true, http.MethodGet, ListDeviceHandler, []string{}},
    // { "/elasticquery/events",  true, http.MethodGet, EventsHandler, []string{}},
    // { "/elasticquery/events",  true, http.MethodPost, EventsHandler, []string{}},
    // { "/elasticquery/events/{source}/{param}",  true, http.MethodGet, EventsHandler, []string{}},

    workorders: "workorders",
    workordersUrl: {workOrders :"/api/v1/auth/workorders", recipes: "/api/v1/auth/recipes"},
    
    rules: "runtime-tasks-manager",
    rulesUrl: "/api/v1/auth/rules",

    entity: "Entities",
    entityUrl: "/api/v1/auth/entities",

    transactions: "Transactions",
    transactionUrl: "/api/v1/auth/transactions",
    transactionNoAuthUrl: "/api/v1/transactions",

    landing: "LandingServices",
    landingUrl: "/api/v1/landing",

    elasticTerraform: "ElasticTerraform",
    elasticTerraformUrl: "/api/v1/auth/elasticterraform",

    activityRule: "ActivityRule",
    activityRuleUrl: "/api/v1/auth/activities",

    opcua: "OpcUa",
    opcuaUrl: "/api/v1/auth/avopcua_",
}


