import Vue from 'vue'

export default () => {
  Vue.prototype.sendCallingAPIStarted = function () {
    if(Vue.prototype.$store && Vue.prototype.$store.state && Vue.prototype.$store.state.skipApiCallNotification)
      return;
    Vue.prototype.OrchestratorAPI.notifyEvent('OnAPICall', { status: true })
  }

  Vue.prototype.sendCallingAPICompleted = function () {
    if(Vue.prototype.$store && Vue.prototype.$store.state && Vue.prototype.$store.state.skipApiCallNotification)
      return;
    Vue.prototype.OrchestratorAPI.notifyEvent('OnAPICall', { status: false })
  }
}
