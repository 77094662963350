<template>
    <v-card style="height:calc(100% - 100px)" class="elevation-0">
        <v-card-text style="height: 100%; overflow-y: auto">
            <v-layout column>
                <template v-for="configuration in lineConfiguration">
                    <v-layout row>
                        <v-flex xs2>
                            <v-checkbox v-model="configuration.enabled" :label="$gettext(!configuration.enabled?'Disabled':'Enabled')"></v-checkbox>
                        </v-flex>
                        <v-flex xs3>
                            <label class="subheading">{{configuration.target}}</label>
                        </v-flex>
                        <v-flex class="pt-0">
                            <av-select :items="configuration.machineRecipes" v-model="configuration.idRecipe"
                                       :placeholder="$i18n.CommonAttributes()['Choose Recipe']" :label="$gettext('Choose Recipe')"
                                       class="mt-2" clearable @change="configuration.idRecipe=!$event?'':$event"></av-select>
                        </v-flex>

                    </v-layout>
                </template>
            </v-layout>
        </v-card-text>
<!--        <v-card-text :style="styleTreeOfLine">-->
<!--            <v-treeview flat :items="recipe" item-text="id" class="title pa-0" v-if="Array.isUseful(recipe)"  id="machines">-->
<!--                <template v-slot:prepend="{ item }">-->
<!--                    <template>-->
<!--                        <av-button iconColor="info" buttonIcon="fas fa-edit" fab small flat :dynamicID="item.id"-->
<!--                                   @click="edit(item)" :toolTip="$gettext('Edit configuration')" class="my-0"   />-->
<!--                        <av-button iconColor="error" buttonIcon="fas fa-trash" fab small flat :dynamicID="item.id"-->
<!--                                   @click="deleteStep(item)" style="margin-left: -8px" class="my-0"-->
<!--                                   :toolTip="$gettext('Delete field (and sub-fields)')" />-->
<!--                    </template>-->
<!--                </template>-->
<!--            </v-treeview>-->
<!--        </v-card-text>-->
<!--        <v-divider></v-divider>-->
<!--        <v-card-text v-if="selectedItem" :style="styleFormOfLine" class="pb-0">-->

<!--            <v-layout column>-->
<!--                <av-text-field v-model="selectedItem.id" :label="$gettext('Name')"></av-text-field>-->
<!--                <v-layout row >-->
<!--                    <v-flex xs5>-->
<!--                        <av-multi-select :items="machines" v-model="selectedItem.target"-->
<!--                                         :placeholder="$i18n.CommonAttributes()['Choose Machine']" :label="$gettext('Choose Machine')"-->
<!--                                         item-text="name" class="mt-2" @change="onChangeMachine" :return-object="false"></av-multi-select>-->

<!--                    </v-flex>-->
<!--                    <v-spacer></v-spacer>-->
<!--                    <v-flex xs5>-->
<!--                        <av-select :items="machineRecipes" v-model="selectedItem.idRecipe"-->
<!--                                   :placeholder="$i18n.CommonAttributes()['Choose Recipe']" :label="$gettext('Choose Recipe')"-->
<!--                                   class="mt-2"></av-select>-->
<!--                    </v-flex>-->
<!--                </v-layout>-->
<!--            </v-layout>-->
<!--        </v-card-text>-->
    </v-card>

</template>

<script>
import recipes from "@/api/recipes";

export default {
    name: "LineRecipe",
    props:{
        recipe:{
            required: true,
            default:()=>{return []}
        },
        machines:{
            required:true,
            default:()=>{
                return []
            }
        }
    },
    data:()=>{
        return {
            lineConfiguration:[],
            opening:true
        }
    },
    mounted(){

    },
    computed:{
        styleFormOfLine() {
            return {
                height: (this.height > 0 ? this.height - 320 : 400) + 'px'
            }
        },

        styleTreeOfLine() {
            return {
                height: '250px',
                'overflow-y': 'auto',
                padding: '5px 0 0 0'
            }
        },
    },
    methods: {
        async loadLineConfiguration() {
            this.lineConfiguration = []
            for (let i = 0; i < this.machines.length; i++) {
                let machine = this.machines[i]
                let self=this
                this.lineConfiguration.push({
                    idRecipe: "",
                    target: machine,
                    type: "machine",
                    enabled: true,
                    machineRecipes: await recipes.getAvailableRecipesByMachines(machine, true)
                })
            }
        }

    },
    watch:{
        async machines(){
            await this.loadLineConfiguration()

            if(this.recipe && this.recipe.length>0){
                let self=this
                this.recipe.forEach(r=>{
                    r.target.forEach(targetMachine=>{
                        let found=self.lineConfiguration.find(lc=>{return lc.target===targetMachine})
                        found.idRecipe=r.idRecipe
                    })
                })
                this.lineConfiguration.filter(lc=>{return lc.idRecipe===''}).forEach(lc=>{
                    lc.enabled=false
                })
            }
            this.opening=false
        },
        lineConfiguration:{
            deep:true,
            handler(){
                if (!this.opening){
                    let enabledMachines=this.lineConfiguration.filter(machine=>{return machine.enabled})
                    let recipe=[]
                    enabledMachines.forEach(em=>{
                        let found=recipe.find(r=>{return r.idRecipe===em.idRecipe})
                        if (recipe.length===0 || !found){
                            recipe.push({
                                idRecipe: em.idRecipe,
                                target: [em.target],
                                type: "machine",
                            })
                        }else{
                            found.target.push(em.target)
                        }
                    })
                    this.$emit('change',recipe)
                }

            }
        }
    }
}
</script>

<style scoped>

</style>