import { render, staticRenderFns } from "./RuleAutomaticWorkorderFromVariables.vue?vue&type=template&id=56bc1585&scoped=true&"
import script from "./RuleAutomaticWorkorderFromVariables.vue?vue&type=script&lang=js&"
export * from "./RuleAutomaticWorkorderFromVariables.vue?vue&type=script&lang=js&"
import style0 from "./RuleAutomaticWorkorderFromVariables.vue?vue&type=style&index=0&id=56bc1585&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56bc1585",
  null
  
)

export default component.exports