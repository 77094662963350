// import Api from '@/api/api'
// import OrchestratorAPI from '@/api/orchestrator'
// import Vue from "vue";
//
// export default {
//     async doLogin (username, password) {
//         // return Orchestrator.createAuthToken(username, password);
//         // return Api().post('/orchestrator/auth', JSON.stringify({username, password}));
//         return OrchestratorAPI.proxyCall("post",'/auth', JSON.stringify({username, password}));
//     },
//     async doLoginTest(username,password) {
//         // return Orchestrator.createAuthTokenTest(username, password);
//         // return Api().post('/orchestrator/authTest', JSON.stringify({username, password}));
//         return Api().post('/authTest', JSON.stringify({username, password}));
//     },
//     async logout() {
//         // return Orchestrator.logout();
//         // return Api().post('/orchestrator/auth/logout');
//         return Api().post('/auth/logout');
//     },
//     async ping () {
//         // return Orchestrator.checkToken();
//         return Api().get('/auth/check');
//         // return Api().get('/orchestrator/auth/check');
//     }
// }

import Vue from 'vue'

export default {

    async doDefaultLogin() {
        return Vue.prototype.$axios.get('/auth/default')
    },
    async doLogin (username, password) {
        return Vue.prototype.$axios.post('/auth', JSON.stringify({ username, password }))
    },
    async logout () {
        return Vue.prototype.$axios.post('/auth/logout')
    }
}