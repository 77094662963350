<template>
    <v-layout row class="pa-0 ma-0" :style="style">
        <v-layout wrap row class="mt-0 mb-0 pt-3 mr-0 pr-0 justify-end " ref="items"  >
            <v-spacer></v-spacer>
            <slot></slot>
        </v-layout>
        <v-btn icon class="mt-4" flat v-if="hiddenButtons" @click="show=!show">
            <v-icon small>{{ show?"fa fa-chevron-up":"fa-chevron-down"}}</v-icon>
        </v-btn>
    </v-layout>

</template>

<script>
export default {
    name: "av-toolbar-items",
    props:{
        activationOn:{
            required:false,
            default:()=>{return false}
        }
    },
    data:()=>{
        return {
            hiddenButtons:false,
            rows: 1,
            show: false
        }
    },
    mounted() {
        this.$nextTick(()=>{
            window.addEventListener("resize",this.handleResize)
        })
        this.handleResize()
    },
    beforeDestroy() {
        window.removeEventListener("resize",this.handleResize)
    },
    computed:{
        height() {
            let h = !this.show ? 80 : 65
            let rows = this.show ? this.rows : 1
            return (h * rows)
        },
        style() {
            let self=this
            return {
                height: self.height + 'px'
            }
        }
    },
    methods:{
        handleResize() {
            let rows = 1
            let width = 0
            let self = this
            let itemsWidth = self.$refs.items.clientWidth
            this.$refs.items.children.forEach(item => {
                if (width + item.clientWidth > itemsWidth) {
                    rows++
                    width = 0
                }
                if (rows > 1 && !self.show) {
                    item.style.visibility = 'hidden'
                } else {
                    item.style.visibility = 'visible'
                }
                width += item.clientWidth
            })
            this.rows = rows
            this.hiddenButtons = this.rows > 1
            if (this.show && rows === 1)
                this.show = false
        },
    },
    watch: {
        show: {
            handler() {
                this.handleResize()
                this.$emit('update',{show:this.show, height:this.height})
            }
        },
        activationOn:{
            handler(){
                this.$nextTick(()=>{
                    this.handleResize()
                    this.$emit('update',{show:this.show, height:this.height})
                })
            }
        }
    }
}
</script>

<style scoped>

</style>