export default async ({ app, router, store, Vue }) => {
  Vue.prototype.$checkAuth = function (okCallback, badCallback) {
    if (Vue.prototype.$store.state.shared.token !== '') {
      Vue.prototype.AuthAPI.ping().then(t => {
        if (t.data.Status === 'success' && okCallback !== undefined) {
          okCallback()
        } else if (t.data.Status !== 'success' && badCallback !== undefined) {
          badCallback(t)
        }
      }).catch(t => {
        badCallback(t)
      })
    } else {
      badCallback()
    }
  }
}
