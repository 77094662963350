// add new last() method:
if (!Array.prototype.last){
    Array.prototype.last = function(){
        return this[this.length - 1];
    };
}

// add new clear() method:
if (!Array.prototype.clear){
    Array.prototype.clear = function(){
        this.splice(0,this.length);
    };
}

// add new removeItem() method:
if (!Array.prototype.removeItem){
    Array.prototype.removeItem = function(item){
        let index = this.indexOf(item);
        if (index > -1) {
            this.splice(index, 1);
        }
    };
}

// removes an item at specified index with a cleaner sintax than splice
if (!Array.prototype.removeAt){
    Array.prototype.removeAt = function(index){
        this.splice(index, 1);
    };
}

// moves an item from on index to a different one
if (!Array.prototype.moveItem){
    Array.prototype.moveItem = function(oldIndex, newIndex, allowIndexOutOfRange = false){
        if(oldIndex >= this.length)
            return;
        if (newIndex >= this.length) {
            if(allowIndexOutOfRange) {
                let k = newIndex - this.length + 1;
                while (k--) {
                    this.push(undefined);
                }
            }
            else return;
        }
        this.splice(newIndex, 0, this.splice(oldIndex, 1)[0]);
    };
}

//Inserts an item at specifies position
if (!Array.prototype.insertItem) {
    Array.prototype.insertItem = function (index, ...item) {
        this.splice(index, 0, ...item);
    }
}

// add new removeItem() method:
if (!Array.isUseful){
    Array.isUseful = function(array){
        if(Object.isUseful(array))
            if(Array.isArray(array))
                if(array.length > 0)
                    return true;
        return false;
    };
}

// sorts array alphabetically with a compact notation
//FN numericToken handle the sorting on a numeric token of the name.
if (!Array.prototype.sortOnProperty){
    Array.prototype.sortOnProperty = function(property, descending = false, numericToken = false){
        return this.sort(function(a, b) {
            try {
                if (!Object.isUseful(a[property]) || !Object.isUseful(b[property]))
                    return 0;
                let propertyA = numericToken ? Number(a[property].match(/\d+$/)[0]) : a[property];
                let propertyB = numericToken ? Number(b[property].match(/\d+$/)[0]) : b[property];
                if(descending) {
                    if (propertyA > propertyB)
                        return -1;
                    if (propertyB > propertyA)
                        return 1;
                } else {
                    if (propertyA > propertyB)
                        return 1;
                    if (propertyB > propertyA)
                        return -1;
                }
                return 0;
            } catch (err) {
                return 0;
            }
        })
    };
}

if (!Array.prototype.sortBy) {
    Array.prototype.sortBy = function (p, descending = false) {
        return this.slice(0).sort(function (a, b) {
            return !descending ? ((a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0) : ((a[p] < b[p]) ? 1 : (a[p] > b[p]) ? -1 : 0);
        });
    }
}

if (!Array.prototype.removeItemRecursive) {
    Array.prototype.removeItemRecursive = function (item, childNodeName = "") {
        this.forEach((el, index) => {
            if (Object.deepEquals(el, item)) {
                this.splice(index, 1)
            } else if (childNodeName && Array.isUseful(el[childNodeName])) {
                el[childNodeName].removeItemRecursive(item, childNodeName)
            }
        });
    }
}

if (!Array.prototype.findItem) {
    Array.prototype.findItem = function (item) {
        for(let [index, el] of this.entries())
            if (Object.deepEquals(el, item))
                return index;
        return -1;
    }
}

if (!Array.prototype.findItemRecursive) {
    Array.prototype.findItemRecursive = function (item, childNodeName = "") {
        let index = this.findItem(item)
        if (index > -1)
            return { itemIndex: index, items: this };

        for(let el of this){
            if (childNodeName && Array.isUseful(el[childNodeName])){
                return el[childNodeName].findItemRecursive(item,childNodeName)
            }

        }
        return undefined;
    }
}

if (!Array.prototype.findItemByKey) {
    Array.prototype.findItemByKey = function (propertyName, propertyValue) {
        for(let [index, el] of this.entries())
            if (el[propertyName] === propertyValue)
                return index;
        return -1;
    }
}

if (!Array.prototype.getItemsByKey) {
    Array.prototype.getItemsByKey = function (propertyName, propertyValue) {
        return this.filter(item => {
            return item[propertyName] === propertyValue
        })
    }
}

if (!Array.prototype.findItemByKeyRecursive) {
    Array.prototype.findItemByKeyRecursive = function (propertyName, propertyValue, childNodeName = "children") {
        let index = this.findItemByKey(propertyName, propertyValue)
        if (index > -1)
            return { itemIndex: index, items: this };

        for(let el of this){
            if (childNodeName && Array.isUseful(el[childNodeName])){
                return el[childNodeName].findItemByKeyRecursive(propertyName, propertyValue, childNodeName)
            }
        }
        return undefined;
    }
}

if (!Array.prototype.getItemsByKeyRecursive) {
    Array.prototype.getItemsByKeyRecursive = function (propertyName, propertyValue, childNodeName = "children") {
        let items = this.getItemsByKey(propertyName, propertyValue);
        for(let item of this) {
            if(Array.isUseful(item[childNodeName]))
                items.push(...item[childNodeName].getItemsByKeyRecursive(propertyName, propertyValue, childNodeName))
        }
        return items;
    }
}

//TODO verify other place where duplicates are searched in project and apply this function wherever possible
if (!Array.prototype.findDuplicates) {
    Array.prototype.findDuplicates = function () {
        return this.filter((item, index) => this.indexOf(item) !== index)
    }
}

// if (!String.prototype.format) {
//     String.prototype.format = function () {
//         let newString = this;
//         let isPlainObject = function (obj) {
//             return Object.prototype.toString.call(obj) === '[object Object]';
//         }
//
//         if (arguments.length == 1 && Array.isArray(arguments[0])) {
//             for (let i = 0; i < arguments[0].length; i++)
//                 newString = newString.replace(new RegExp("\\{" + i + "\\}", "g"), arguments[0][i]);
//             return newString;
//         } else {
//
//             for (let i = 0; i < arguments.length; i++)
//                 if (isPlainObject(arguments[0])) {
//                     newString = newString.replace(new RegExp("\\{" + i + "\\}", "g"), JSON.stringify(arguments[i]));
//                 } else {
//                     newString = newString.replace(new RegExp("\\{" + i + "\\}", "g"), arguments[i]);
//                 }
//
//             return newString;
//         }
//     }
// }

if (!String.prototype.format) {
    String.prototype.format = function () {
        let newString = this;
        if (arguments.length === 1 && Array.isArray(arguments[0])) {
            for (let i = 0; i < arguments[0].length; i++)
                newString = newString.replace(new RegExp("\\{" + i + "\\}", "g"), arguments[0][i]);
            return newString;
        } else if (arguments.length === 1 && typeof arguments[0] === 'object' && arguments[0] !== null) {
            for (let key in arguments[0])
                newString = newString.replace(new RegExp("\\{" + key + "\\}", "g"), arguments[0][key]);
            return newString;
        } else {
            for (let i = 0; i < arguments.length; i++)
                newString = newString.replace(new RegExp("\\{" + i + "\\}", "g"), arguments[i]);
        }

        return newString;
    }
}

if (!String.prototype.includesMulti) {
    String.prototype.includesMulti = function(words) {
        let tokens = words;
        if(!Array.isArray(words)) {
            tokens = arguments;
        }
        for(let i = 0 ; i < tokens.length ; i++)
            if(!this.includes(tokens[i]))
                return false;
        return true;
    }
}

if (!String.prototype.replaceMulti) {
    String.prototype.replaceMulti = function (sources, replaces) {
        let re = new RegExp(sources.join("|"), "gi");

        return this.replace(re, function (matched) {
            let replace = replaces[sources.indexOf(matched)];
            if(replace)
                return replace;
            else return matched;
        });
    }
}

if (!String.prototype.isString) {
    String.prototype.isString = function (string) {
        return typeof string === 'string' || string instanceof String;
    }
}

//Checks whether an object is defined and not null
if (!Object.isUseful) {
    Object.isUseful = function(obj) {
        return (obj !== null && typeof obj !== 'undefined');
    }
}

//Check whether a set of objects are defined and not null.
//Control is short circuited on first non useful object, thus, function is good to check a full nested property path
if (!Object.areUseful) {
    Object.areUseful = function() {
        for (let i = 0; i < arguments.length; i++)
            if(!Object.isUseful(arguments[i]))
                return false;

        return true;
    }
}

//Check whether a full property path in object is useful.
//Control is short-circuited on first non-useful object, thus, it won't never throw exceptions
if (!Object.isNestedPropertyUseful) {
    Object.isNestedPropertyUseful = function() {
        if(!Object.isUseful(arguments[0]))
            return false;
        let baseObject = arguments[0];
        for (let i = 1; i < arguments.length; i++)
            if(!Object.isUseful(baseObject[arguments[i]]))
                return false;
            else baseObject = baseObject[arguments[i]];

        return true;
    }
}

//Checks whether a field is defined
if (!Object.isDefined) {
    Object.isDefined = function(obj) {
        return (typeof obj !== 'undefined');
    }
}

//Checks whether multiple fields are all defined
if (!Object.areDefined) {
    Object.areDefined = function() {
        for (let i = 0; i < arguments.length; i++)
            if(typeof arguments[i] === 'undefined')
                return false;

        return true;
    }
}

//Checks whether an object is defined and not null
if (!Object.isEmpty) {
    Object.isEmpty = function(obj) {
        return (Object.keys(obj).length === 0);
    }
}

//A case insensitive implementation of hasOwnProperty
if (!Object.hasOwnPropertyCaseInsensitive) {
    Object.hasOwnPropertyCaseInsensitive = function (obj, prop) {
        return Object.keys(obj)
            .filter(function (v) {
                return v.toLowerCase() === prop.toLowerCase();
            }).length > 0;
    }
}

if(!Object.deepEquals) {
    Object.deepEquals = function(obj1, obj2) {
        if (obj1 === obj2) {
            return true;
        } else if ((typeof obj1 == "object" && obj1 != null) && (typeof obj2 == "object" && obj2 != null)) {
            if (Object.keys(obj1).length !== Object.keys(obj1).length)
                return false;

            for (let prop in obj1) {
                if (obj2.hasOwnProperty(prop)) {
                    if (!Object.deepEquals(obj1[prop], obj2[prop]))
                        return false;
                } else
                    return false;
            }
            return true;
        } else
            return false;
    }
}


import Md5 from '@/api/md5'

export default {

    //use with caution
    sleep(milliseconds) {
        let start = Date.now();
        while ((Date.now() - start) < milliseconds){}
    },

    //Validates a file name (without extension)
    validateFileName(name) {
        return (/^[^\\\/\:\*\?\"\<\>\|\.]+$/.test(name))
    },

    //creates a copy of the object (properties only not prototype):
    detach(obj) {
        if(obj === undefined)
            return undefined;
        if(obj === null)
            return null;
        return JSON.parse(JSON.stringify(obj));
    },

    //Traverses each single nested field inside an object and fires a custom callback on field
    forEachItemInObject(object, callback) {
        var iter = function (that) {
            for (var property in that) {
                if (typeof (that[property]) !== 'function') {
                    callback(that[property]);
                    if (!!that[property] && typeof (that[property]) === 'object') {
                        iter(that[property]);
                    }
                }
            }
        };
        iter(object);
    },

    matchWildcardString(input, rule) {
        let escapeRegex = (input) => input.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(input);
    },

    fnOrVal(value) {
        if(typeof value === "function") {
            return value();
        } else return value
    },

    isValorized(value) {
        if(Array.isUseful(value))
            return true;
        if(Array.isArray(value))
            return false;
        if(!Object.isUseful(value))
            return false;
        return (value !== "" && value !== false);
    },

    isObject(maybeAnObject) {
        return (typeof maybeAnObject === 'object' && maybeAnObject !== null);
    },

    timeTag(additionalToken = "", digest = true) {
        let tag = ((additionalToken ? (additionalToken + "_") : "") + new Date().toISOString());
        if(digest)
            return Md5.md5(tag);
        else return tag
    },

    //Function to convert hex format to a rgb color
    rgb2hex(rgb){
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? "#" +
            ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
    },

    //Adds opacity to an existing rgb hex format color
    ColorWithOpacity(color, opacity) {
        let components = this.colorComponents(color);
        return 'rgba(' + components.r + ',' + components.g + ',' + components.b + ',' + opacity / 100 + ')';
    },

    colorComponents(color) {

        let components = {
            r: 0,
            g: 0,
            b: 0
        };
        if (color && color.startsWith("rgb"))
            color = this.rgb2hex(color);

        if(color && color.startsWith('#')) {
            color = color.replace('#', '');
            components.r = parseInt(color.substring(0, 2), 16);
            components.g = parseInt(color.substring(2, 4), 16);
            components.b = parseInt(color.substring(4, 6), 16);

        }

        return components;
    },

    //Converts named colors to hex string
    NamedColorToHex(color) {
        var colors = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
            "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
            "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
            "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
            "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
            "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
            "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
            "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
            "honeydew":"#f0fff0","hotpink":"#ff69b4",
            "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
            "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
            "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
            "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
            "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
            "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
            "navajowhite":"#ffdead","navy":"#000080",
            "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
            "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
            "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
            "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
            "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
            "violet":"#ee82ee",
            "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
            "yellow":"#ffff00","yellowgreen":"#9acd32"};

        if (typeof colors[color.toLowerCase()] != 'undefined')
            return colors[color.toLowerCase()];

        return false;
    },

    roundToDigits(number, digits) {
        let scale = Math.pow(10, digits);
        return (Math.round(number * scale) / scale);
    },

    camelize(str, startUpperCase = true) {
        let val = str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
        return (startUpperCase ? val.replace(/^./, val[0].toUpperCase()) : val.replace(/^./, val[0].toLowerCase()));
    },

    replaceLast(str, search, replace) {
        return str.replace(new RegExp(search+"([^"+search+"]*)$"), replace+"$1");
    },

    //Converts milliseconds to minutes and seconds with format mm:ss
    formatMsToMinutesAndSeconds(ms) {
        let minutes = Math.floor(ms / 60000);
        let seconds = ((ms % 60000) / 1000).toFixed(0);
        return (seconds === 60 ? (minutes+1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
    },

    //Return the number of times a same value appears in an array
    getOccurrence(array, value) {
        return array.filter((v) => (v === value)).length;
    },

    //Check if a variable exists, if not it means that flag was later added so we return
    //a true by default, otherwise we return the value it self
    isVariableUnusefulOrTrue(flag) {
        return (!Object.isUseful(flag) || flag)
    },

    objectToArray(obj,idField){
        let result=[];
        Object.keys(obj).forEach(id=>{

            if(!obj[id][idField]){
                obj[id][idField]=id
            }
            result.push(obj[id])
        });
        return result;
    },

    numberThousandsSeparator(number, separator) {
        if(!separator)
            return number;
        return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, separator)
    },

    download(filename, content) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    },
}
