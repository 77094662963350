<template>
    <DynamicElementBase>
        <div :style="globalFiltersStyle">
            <v-layout v-if="globalFilter" v-for="(globalFilterItem, key) in cGlobalFilters" :key="key" row align-center
                      justify-center mx-3 my-0>
                <template v-if="isFieldVisible(globalFilterItem, key)">
                    <v-flex xs6 pt-0>
                        <label class="headline">{{ globalFilterItem.label }}</label>
                    </v-flex>
                    <v-flex v-if="globalFilterItem.useValuesAsParametricVariables" xs6 ml-3 mr-3 pt-1>
                        <v-select solo outlined :items="globalFilterItem.options"
                                  return-object
                                  item-text="value"
                                  item-value="id" :clearable="true"
                                  v-model="selected"
                                  @change="selectedFilterChanged($event,key,globalFilterItem.allowMultipleItemsSelection,globalFilterItem.useValuesAsParametricVariables)"/>
                    </v-flex>
                    <v-flex v-else-if="globalFilterItem.showAsTimePicker" xs6 ml-3 mr-3 pt-1>
                        <v-layout row align-center fill-height style="height: 48px; margin-bottom: 8px">
                            <TimePicker :time-window="globalFilterItem.timespan"
                                        :call-back="timePickerCallback" :filter-item="globalFilterItem"
                                        :row-key="key"></TimePicker>
                        </v-layout>
                    </v-flex>
                    <v-flex v-else xs6 ml-3 mr-3 pt-1>
                        <v-select solo outlined :items="globalFilterItem.options"
                                  return-object
                                  :multiple="globalFilterItem.allowMultipleItemsSelection"
                                  item-text="value"
                                  item-value="value" :clearable="true"
                                  v-model="selectedFilters[key]"
                                  @change="selectedFilterChanged($event,key,globalFilterItem.allowMultipleItemsSelection,globalFilterItem.useValuesAsParametricVariables)"/>
                    </v-flex>
                </template>
            </v-layout>
        </div>
    </DynamicElementBase>
</template>

<script>

import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase';
import TimePicker from '@/components/utilities/TimePicker';
import DateTimeUtils, {TimeSpan} from "@/api/datetimeutils";


export default {
    name: 'WidgetGlobalFilters',
    extends: DynamicElementBase,
    components: {
        DynamicElementBase,
        TimePicker
    },
    data() {
        return {
            currentGlobalParam: null,
            selectedFilters: [],
            globalFilter: true,
            globalFilters: [],
            timeFiltering: {},
            customFilters: [],
            firstLoadingComplete: false,
            currentGlobalFilters: null,
        }
    },
    mounted: function () {
        //Remove window control
        //this.properties.showTimeWindow = true;
        //Fix window to a small value to gather only actual data
        this.properties.timeWindow.setPredefined(10080);
        this.properties.autoRefresh = false; //Do not update periodically
        this.dataExplorationMode.autoDataSourcing = false;
        this.dataExplorationMode.deviceSelectMode = false;
        this.childHandlers.onGlobalFiltersReceived = this.onGlobalFilterReceived;
        this.childHandlers.onTimeWindowUpdate = this.timeWindowUpdated;
        this.excludeGlobalParamsUpdate = false;
        this.childHandlers.checkItemUsefulness = () => {
            return true;
        };


        this.visualizationOptions = [
            {
                name: this.$gettext("Allow multiple items selection"),
                id: "AllowMultipleItemsSelection",
                type: "bool",
                default: false,
            },
            {
                name: this.$gettext("Use values as parametric variables"),
                id: "UseValuesAsParametricVariables",
                type: "bool",
                default: false
            },
            {
                name: this.$gettext("Show As a Time Picker"),
                id: "ShowAsTimePicker",
                type: "bool",
                visibleForType: [this.$defines.avionicsDataTypes.time.id],
                default: false,
            },
            //Used in reports exportation to handle dependant filters automatically
            //If true, dependent filters are:
            // - hidden if master filter is not selected
            // - auto-selected and still hidden if after master selection they contain a single value (filter is resolved)
            // - shown if after master selection they result in multiple possible choices
            {
                name: this.$gettext("automaticDependentFilterHandling"),
                id: "automaticDependentFilterHandling",
                type: "bool",
                default: false,
                //Internal usage, must remain hidden to user at moment. If promoted to user space, please provide appropriate description
                show: false
            }
            // {
            //     name: this.$gettext("Hide filter if empty"),
            //     id: "HideIfEmpty",
            //     type: "bool",
            //     default: false,
            // },
            // {
            //     name: this.$gettext("Automatically select single value"),
            //     id: "AutoSelectSingle",
            //     type: "bool",
            //     default: false,
            // },
        ];

        this.preferredAggregations = [this.$defines.allAggregations.terms.id];

    },
    watch: {},
    computed: {
        cGlobalFilters() {
            return this.globalFilters;
        },
        globalFiltersStyle() {
            return {
                width: "100%",
                "overflow-y": "auto",
                height: this.containerHeight + "px"
            }
        },
        selected() {
            return this.currentGlobalParam
        },
        isFieldVisible() {
            return (field, key) => {
                if(field.automaticDependentFilterHandling) {
                    //If master filter is not selected, hide field
                    if (!Array.isUseful(this.filterItems.filter(filterItem =>
                        Array.isUseful(filterItem.filters.filter(filter => field.dependsOn.includes(filter.filterId) && filter.assigned)))))
                        return false

                    //We have a single option, select value and hide field
                    if (field.options.length === 1) {
                        //Check the value that was previously selected to break the loop that will be inevitably
                        //triggered by selection -> global filters dispatching -> items update -> back here to computed and auto-selection
                        if(!this.selectedFilters[key] || this.selectedFilters[key].value !== field.options[0].value) {
                            this.selectedFilterChanged(field.options[0], key, field.allowMultipleItemsSelection, field.useValuesAsParametricVariables)
                        }
                        return false
                    } else {
                        if(this.selectedFilters[key] && !field.options.map(item => item.value).includes(this.selectedFilters[key].value))
                            this.selectedFilterChanged(null, key, field.allowMultipleItemsSelection, field.useValuesAsParametricVariables)
                        //No choice -> hide field
                        //Multiple choices -> show field to allow user selection
                        return Array.isUseful(field.options)
                    }
                }
                return true
            }
        }
    },
    methods: {
        timeWindowUpdated() {
            this.filterSelectionComplete(false);
        },
        selectedFilterChanged(filter, i, allowMultipleItemsSelection, useValuesAsParametricVariables) {
            this.selectedFilters[i] = filter;
            if (this.selectedFilters[i]) {
                this.selectedFilters[i].allowMultipleItemsSelection = allowMultipleItemsSelection || false;
                this.selectedFilters[i].useValuesAsParametricVariables = useValuesAsParametricVariables || false;
            }
            this.filterSelectionComplete(true);
        },
        filterSelectionComplete(applySelfFiltering = false) {
            let params = null;
            params = {time: this.properties.timeWindow, filters: {}, variables: ""};
            if (this.globalFilter)
                this.selectedFilters.forEach(filter => {
                    if (filter) {
                        if (Array.isUseful(filter) && filter.allowMultipleItemsSelection) {
                            let filterValues = []
                            filter.forEach(filterItem => {
                                filterValues.push(filterItem.value)
                            });
                            params.filters[filter[0].id] = filterValues;
                        } else if (typeof filter.values === 'object') {
                            params.filters[filter.id] = filter.values;
                        } else if (!filter.allowMultipleItemsSelection) {
                            if (filter.useValuesAsParametricVariables) {
                                params.variables = filter.id;
                                this.currentGlobalParam = filter.id;
                            } else {
                                params.filters[filter.id] = filter.value;
                            }
                        }
                    }
                });
            if (params) {
                if(Object.isUseful(this.currentGlobalFilters)) {
                    params.filters = this.currentGlobalFilters;
                }
                this.$emit('globalParamUpdated', params);
                // if(applySelfFiltering)
                //     this.SetGlobalParams(params, true);
            }
        },
        timePickerCallback(timeFilter) {
            if (timeFilter.filterItem && timeFilter.filterItem.showAsTimePicker) {
                let filter = {
                    id: timeFilter.filterItem.identifier,
                    values: {
                        from: this.$dateTime.getRfc3339TimeStamp(timeFilter.filterItem.timespan.getStart()),
                        to: this.$dateTime.getRfc3339TimeStamp(timeFilter.filterItem.timespan.getEnd()),
                        type: "timeWindow",
                        timespan: timeFilter.filterItem.timespan
                    }
                }
                this.selectedFilterChanged(filter, timeFilter.rowKey, false);
            } else {
                delete this.selectedFilters[timeFilter.rowKey];
                this.filterSelectionComplete();
            }
        },
        addCustomFilters(filters) {
            this.customFilters = filters;
            this.refreshData(null);
        },
        refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view
            let self = this;
            let tmpFilters = [];
            const map = new Map();
            this.excludeGlobalParamsUpdate = true;
            //Append dynamic items obtained by query to static filters explicitly added from outside
            let data = this.$utils.detach(this.customFilters);
            if(Array.isUseful(dataValues))
                data.push(...dataValues)
            if (data) {
                data.forEach((elm, index) => {
                    let filter = {
                        identifier: elm.identifier,
                        label: elm.label,
                        options: [],
                        allowMultipleItemsSelection: elm.visualizationOptions.AllowMultipleItemsSelection,
                        showAsTimePicker: elm.visualizationOptions.ShowAsTimePicker,
                        timespan: elm.visualizationOptions.ShowAsTimePicker && Object.isNestedPropertyUseful(this.globalFilters, index, "timespan") ? this.globalFilters[index].timespan : new TimeSpan(),
                        useValuesAsParametricVariables: elm.visualizationOptions.UseValuesAsParametricVariables,
                        automaticDependentFilterHandling: (elm.visualizationOptions ? elm.visualizationOptions.automaticDependentFilterHandling : false),
                        dependsOn: Object.isUseful(self.dataItems.filter(item => (item.root + "." + item.name) === elm.identifier)[0]) ? self.dataItems.filter(item => (item.root + "." + item.name) === elm.identifier)[0].dependsOn : {}
                    };
                    if(!this.firstLoadingComplete && filter.showAsTimePicker) {
                        filter.timespan.allowDisabling = true;
                        filter.timespan.isDisabled = true;
                        filter.timespan.predefined = 'ever';
                    }
                    let identifier = elm.identifier;
                    if (elm.visualizationOptions.UseValuesAsParametricVariables) {
                        let splitIdentifier = identifier.split(".");
                        identifier = splitIdentifier.slice(0, splitIdentifier.length - 1).join(".");
                    }

                    elm.data.forEach(element => {
                        filter.options.push({
                            id: identifier,
                            value: elm.dataFormat === this.$defines.DatasetFormats.xy ? element.y : element.x
                        });
                    });

                    //In case of dependent filters we remove empty strings to facilitate automatic selection
                    //This is fine for current use case, for instance select workorderID from another workorder field.
                    //It shall be clarified whether this may cause troubles in different context were for instance empty
                    //value might be a desirable value to select
                    if(filter.dependsOn)
                        filter.options = filter.options.filter(item => item.value !== "")

                    if (filter.useValuesAsParametricVariables) {
                        if (map.has(elm.recursiveParentId)) {
                            let index = map.get(elm.recursiveParentId);
                            filter.options.forEach(option => {
                                tmpFilters[index].options.push(option)
                            })
                        } else {
                            let index = Array.isUseful(tmpFilters) ? tmpFilters.length : 0;
                            map.set(elm.recursiveParentId, index);
                            tmpFilters.push(filter);
                        }
                    } else {
                        tmpFilters.push(filter);
                    }
                });
            }
            this.globalFilters = tmpFilters;

            if (this.currentGlobalParam) {
                for (let j = 0; j < this.globalFilters.length; j++) {
                    if (this.globalFilters[j].useValuesAsParametricVariables) {
                        for (let k = 0; k < this.globalFilters[j].options.length; k++) {
                            if (this.globalFilters[j].options[k].id.includes(this.currentGlobalParam + ".")) {
                                this.currentGlobalParam = this.globalFilters[j].options[k].id;
                                break;
                            }
                        }
                        break;
                    }
                }
            }
            this.firstLoadingComplete = true;
            // this.selectedFilters=[this.globalFilters.length];
        },
        onGlobalFilterReceived(globalFilters) {
            debugger;
            if (globalFilters && globalFilters.variables) {
                this.excludeGlobalParamsUpdate = false;
                this.currentGlobalFilters = globalFilters.filters;
                this.currentGlobalParam = globalFilters.variables;
            } else {
                this.excludeGlobalParamsUpdate = true;
            }
        }
    },
}
</script>

<style scoped>


</style>
