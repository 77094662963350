import Api from '@/api/api'
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import timeTrackingDialog from '@/components/dialogs/TimeTrackingDialog'
import timeTrackingBackAnnotationDialog from '@/components/dialogs/TimeTrackingBackAnnotationDialog'
import timeTrackingAdjustmentDialog from '@/components/dialogs/TimeTrackingAdjustmentDialog'
import VueInstance from './vueinstance'
import Defines from "./defines";
import DataApis from "./data";
import DateTime from "./datetimeutils";
import settings from "./settings";
import workorders from "./workorders";
import alarmsApi from "./alarmsApi";
import config from "./config";
import Utils from "./jsutils";
import Vue from 'vue'
import audits from "./audits";

export default {

    TimeTrack: "TIMETRACK",
    targets: { category: 0, activity: 1, machine: 2, ranking: 3  },

    formatStop(stop){
        return VueInstance.get().$gettext("{0} min. From {1} to {2}").format(Math.round((stop.stop - stop.start) / 60000), new Date(stop.start).format(),new Date(stop.stop).format())
    },

    //Return the stop causes  as concatenated formatted string
    formatTimeTracker(value, includeSystem = false) {
        //Value 0 and 1 are not manual time tracker valid
        if (value > 2 || includeSystem) {
            let tt = this.getTimeTrackerFromId(value);
            if(Array.isUseful(tt))
                return tt.join(' --> ');
        }
        return '';
    },
    //Return the index comboBox for category, activity...
    getTimeTrackerIndices(id, annotationCauses) {
        let indicesComboBox = [];
        let category = Math.floor(id / 1000000);
        let activity = Math.floor((id % 1000000) / 10000);
        let subActivity = Math.floor((id % 10000) / 100);
        let subSubActivity = id % 100;
        let categories = this.getFilteredLineStopCauses(false);
        if(Array.isUseful(annotationCauses))
            categories = annotationCauses;
        let token = this.getTimeTrackerTokenById(categories, category);
        if(token) {
            indicesComboBox.push(token.index);
            token = this.getTimeTrackerTokenById(token.children, activity);
            if(token) {
                indicesComboBox.push(token.index);
                token = this.getTimeTrackerTokenById(token.children, subActivity);
                if(token) {
                    indicesComboBox.push(token.index);
                    token = this.getTimeTrackerTokenById(token.children, subSubActivity);
                    if(token)
                        indicesComboBox.push(token.index);
                }
            }
        }

        return indicesComboBox;
    },

    async changeTimeTracker(categories, machine, alarm, operator) {

        let tracker = this.getTimeTrackerId(categories);
        let systemCategory=this.getSystemCategory(categories);

        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.analyticsUrl + '/user/TIMETRACK', {
                tag: {
                    Category: systemCategory, Activity: tracker, StoppedMachine: machine, StoppingAlarm: alarm, Operator: operator } } )
                .then(t => {
                    resolve(true);
                })
                .catch(t => {
                    console.log(t);
                    reject(VueInstance.get().$gettext("Error in changing time tracking."));
                });
        });
    },

    getTimeTrackerFromId(id, includeInactiveResults = false) {
        let timeTracking = [];
        let categories = this.getFilteredLineStopCauses(includeInactiveResults);
        let category = Math.floor(id / 1000000);
        let activity = Math.floor((id % 1000000) / 10000);
        let subActivity = Math.floor((id % 10000) / 100);
        let subSubActivity = id % 100;

        let lenghtArray=category>0?1:0
        if (activity>0)
            lenghtArray++;
        if(subActivity>0)
            lenghtArray++;
        if(subSubActivity>0)
            lenghtArray++;

        if (category === 0) {
           if (subSubActivity === 0) {
               return [VueInstance.get().$gettext("Idle time")];
           } else if (subSubActivity === 1) {
               return [VueInstance.get().$gettext("Production")];
           } else if (subSubActivity === 2) {
               return [VueInstance.get().$gettext("Uncategorized stop")];
           }
        }
        else {
            let token = this.getTimeTrackerTokenById(categories, category);
            if(token) {
                timeTracking.push(token.show);
                token = this.getTimeTrackerTokenById(token.children, activity);
                if(token) {
                    timeTracking.push(token.show);
                    token = this.getTimeTrackerTokenById(token.children, subActivity);
                    if(token) {
                        timeTracking.push(token.show);
                        token = this.getTimeTrackerTokenById(token.children, subSubActivity);
                        if(token)
                            timeTracking.push(token.show);
                    }
                }
            }

            // if (Object.isUseful(categories)) {
            //     if (category >= 0 && categories.length > category) {
            //         timeTracking.push(categories[category].show);
            //         if (activity >= 0 && Array.isUseful(categories[category].children) && categories[category].children.length > activity) {
            //             timeTracking.push(categories[category].children[activity].show);
            //             if (subActivity >= 0 && Array.isUseful(categories[category].children[activity].children) && categories[category].children[activity].children.length > subActivity) {
            //                 timeTracking.push(categories[category].children[activity].children[subActivity].show);
            //                 if (subSubActivity >= 0 && Array.isUseful(categories[category].children[activity].children[subActivity].children) && categories[category].children[activity].children[subActivity].children.length > subSubActivity) {
            //                     timeTracking.push(categories[category].children[activity].children[subActivity].children[subSubActivity].show);
            //                 }
            //             }
            //         }
            //     }
            // }

            //DS if timetracking length not equal to expected length
            if (timeTracking.length<lenghtArray){
                timeTracking=[]
            }
            return  timeTracking;
        }
    },

    getTimeTrackerTokenById(items, id) {
        if(id > 0 && Array.isUseful(items)) {
            for(let index = 0 ; index < items.length ; index++) {
                if(Object.isUseful(items[index].id) && items[index].id === id) {
                    let returning = { show: items[index].show, children: null, index: index};
                    if(Array.isUseful(items[index].children))
                        returning.children = items[index].children;
                    return returning;
                }
            }
        }
        return null;
    },

    getCurrentTimeTracker() {

            let dataItems = [{
                index: 'production counters@5s',
                root: 'TimeTracking',
                name: 'Activity',
                type: 'integer',
                representations: [
                    {
                        type: Defines.allAggregations.last.id,
                        name: 'Activity',
                        target: 0
                    }]
            }];

            let queryDescriptor = DataApis.getDataQueryDescriptor(dataItems);
            return new Promise((resolve, reject) => {
                DataApis.getDataBlob(queryDescriptor, DateTime.getRfc3339TimeStamp(new Date(Date.now() - 30000)), DateTime.getRfc3339TimeStamp(new Date(Date.now())))
                    .then(result => {
                        let data = DataApis.unwrapDataSets(dataItems, [], [], result, config.debug ? result["queryIndex"] : -1);
                        if (Array.isUseful(data[0].data)) {
                            resolve({
                                text: data[0].data[0].tracker,
                                value: data[0].data[0].activity
                            });
                        } else {
                            resolve({
                                text: [ "NO TimeTracking" ],
                                value: -1
                            });
                        }
                    })
                    .catch(err => {
                        debugger;
                        console.log(err);
                        reject(VueInstance.get().$gettext("Unable to retrieve current time tracking information"));
                    });
        });
    },

    getTimeTrackerId(categories) {
        if (!Array.isUseful(categories)) {
            throw "Invalid time tracking category";
        }
        let tracker = categories[0] * 1000000;
        tracker += (categories.length > 1 ? categories[1] * 10000 : 0);
        tracker += (categories.length > 2 ? categories[2] * 100 : 0);
        tracker += (categories.length > 3 ? categories[3] : 0);

        return tracker;
    },

    getTimeTrackerIds(id){
        let category = Math.floor(id / 1000000);
        let activity = Math.floor((id % 1000000) / 10000);
        let subActivity = Math.floor((id % 10000) / 100);
        let subSubActivity = id % 100;
        return [category,activity,subActivity,subSubActivity]
    },

    getSystemCategory(categories,stopCauses) {
        let sc = this.getCategory(categories,stopCauses)
        return Object.isNestedPropertyUseful(sc, "category") ? sc.category : -1;
    },
    getCategory(categories,stopCauses) {
        if (!stopCauses) {
            stopCauses = stopCauses = this.getFilteredLineStopCauses();
        }
        for (let i = 0; i < stopCauses.length; i++) {
            let sc = stopCauses[i];
            if (sc.id === categories[0]) {
                if (Array.isUseful(sc.children) && categories.length > 1) {
                    categories.shift();
                    return this.getCategory(categories, sc.children);
                } else {
                    return sc
                }
            }
        }
    },
    getTimeTrackerIdsRange(categories) {
        if (!Array.isUseful(categories)) {
            throw "Invalid time tracking category";
        }
        let range = [0, 1999999];
        if(categories[0] === 0)
            range[0] = range[1] = categories[1];
        else {
            range[0] += categories[0] * 1000000;
            range[0] += (categories.length > 1 ? categories[1] * 10000 : 0);
            range[0] += (categories.length > 2 ? categories[2] * 100 : 0);
            range[0] += (categories.length > 3 ? categories[3] : 0);

            range[1] = range[0] + 999999;
            if (categories[1])
                range[1] = range[0] + 9999;
            if (categories[2])
                range[1] = range[0] + 99;
            if (categories[3])
                range[1] = range[0];
        }

        return range;
    },

    getBackAnnotationObject(start, stop, categories, machine, alarm, operator, identifier) {

        let tracker = this.getTimeTrackerId(categories);
        let systemCategory = this.getSystemCategory(categories);

        if(!operator)
            operator = "";
        let returning = {
            Begin:     DateTime.getRfc3339TimeStamp(new Date(start)),
            End:       DateTime.getRfc3339TimeStamp(new Date(stop)),
            '@timestamp': DateTime.getRfc3339TimeStamp(new Date()),
            SubField: "TimeTracking",
            Status: "",
            Identifier: identifier,
            Annotation: {
                Activity: tracker,
                Category: systemCategory,
                StoppedMachine: machine,
                StoppingAlarm: alarm,
            },
            tag: {
                Operator: operator,
            }
        };
        return returning
    },

    getHistory(from, to, filterWorkorder = false, limitResultsCount = 0) {
        let query = { filters: [], data: [], aggregations: []};
        let self = this;
        let filterItems = []
        let woFilterId = ""

        if (filterWorkorder) {
            query.filters.push(workorders.getProductionCountersWorkordersFilter());
            woFilterId = workorders.setProductionCountersWorkordersFilter(query.filters.last(), filterWorkorder !== true ? filterWorkorder : "");
        }

        query.data.push(
            {
            index: 'production counters@5s',
                root: 'TimeTracking',
                name: 'Activity',
                type: 'integer',
                selectedForVisualization: true,
                representations: [
                    {
                        type: Defines.allAggregations.raw.id,
                        filters: filterWorkorder ? [woFilterId] : [],
                        target: 0,
                        aggregationWindow: 0,
                        defaultName: 'History',
                        name: 'History',
                        id: 'History',
                        enabled: true
                    },
                ],
            },
        );

        let queryDescriptor = DataApis.getDataQueryDescriptor(query.data, query.filters, query.aggregations, [],  [], limitResultsCount);

        //Execute query
        return new Promise((resolve, reject) => {
            if (queryDescriptor.agg.length > 0 || queryDescriptor.raw.length > 0 || queryDescriptor.comp.length >0) {
                DataApis.getDataBlob(queryDescriptor, from, to)
                    .then(result => {
                        let timesTracking = DataApis.unwrapDataSets(query.data, [], [], result, config.debug ? result["queryIndex"] : -1).last().data;
                        resolve(self.getTimeTrackingInfo(timesTracking, limitResultsCount !== 0));
                    })
                    .catch(err => {
                        debugger;
                        console.error(err);
                        reject([]);
                    });
            }

        });
    },

    getTimeTrackingInfo(timesTracking, subsampled = false) {
        if (!Array.isUseful(timesTracking)) {
            return [];
        }
        let breakOnDifference = 15000;
        if(subsampled) {
            let totalTimeDiff = 0;
            for(let i = 0 ; i < timesTracking.length - 1 ; i++)
                totalTimeDiff += (timesTracking[i + 1].x - timesTracking[i].x)
            breakOnDifference = Math.round(totalTimeDiff / timesTracking.length) * 3;
        }

        let returning = [];
        let timeTrackingId = timesTracking[0].y;

        let startTime = timesTracking[0].x;
        let stopTime = startTime;

        let activity = timesTracking[0].activity

        for (let i = 1; i < timesTracking.length; i++) {
            let tmpTime = timesTracking[i].x;
            let tmpTimeTrackingId = timesTracking[i].y;

            if (tmpTime > (stopTime + breakOnDifference)) {
                returning.push({start: startTime, stop: stopTime, duration: (stopTime - startTime) , timeTrackingId: timeTrackingId, show: this.formatTimeTracker(timeTrackingId, true), activity: activity});
                startTime = tmpTime;
            } else if (tmpTimeTrackingId !== timeTrackingId) {
                returning.push({start: startTime, stop: stopTime, duration: (tmpTime - startTime) , timeTrackingId: timeTrackingId, show: this.formatTimeTracker(timeTrackingId, true), activity: activity});
                startTime = tmpTime;
            }

            stopTime = tmpTime;
            timeTrackingId = tmpTimeTrackingId;
            activity = timesTracking[i].activity
        }
        returning.push({start: startTime, stop: stopTime, duration: (stopTime - startTime), timeTrackingId: timeTrackingId, show: this.formatTimeTracker(timeTrackingId, true), activity: activity});
        return returning;
    },

    openTimeTrackingDialog(parent, user, notified = false, picker = false, pickerCallback = null, alarms, stoppedMachines) {
        //Create widget
        let componentClass = Vue.extend(timeTrackingDialog);
        let dialog = new componentClass();
        dialog.$mount();
        dialog.setCaller(parent);
        let event = { notified: notified, alarms: alarms, stoppedMachines: stoppedMachines, onStop: notified};
        dialog.Show(event, user, picker, pickerCallback);
    },

    openBackAnnotationTimeTrackingDialog(parent, user, callback, workorder, forceFillIn) {
        //Create widget
        let componentClass = Vue.extend(timeTrackingBackAnnotationDialog);
        let dialog = new componentClass();
        dialog.$mount();
        dialog.setCaller(parent);
        dialog.Show(user, callback, workorder, forceFillIn);
    },

    openAdjustementTimeTrackingDialog(parent, user, callback) {
       //Create widget
       let componentClass = Vue.extend(timeTrackingAdjustmentDialog);
       let dialog = new componentClass();
       dialog.$mount();
       dialog.Show(user, callback);
    },

    checkPendingUncategorizedStops(periodInSeconds, onResult) {
        this.getUncategorizedStops(DateTime.getRfc3339TimeFromNow(periodInSeconds), DateTime.getRfc3339TimeStamp(new Date()))
            .then(stops => {
                if(onResult)
                    if(stops.length)
                        onResult(true);
                    else onResult(false);
            })
            .catch(err => {
                // debugger
                if(onResult)
                    onResult(false);
            })
    },

    getPendingBackAnnotations(returnRawResults) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.backannotationUrl + '/read',{status:"pending",varpath:"TimeTracking"})
                .then(t => {
                    if(returnRawResults)
                        resolve(t);
                    else {
                        let returning = [];
                        for(let item of t)
                            returning.push(item.Identifier);
                        resolve(returning);
                    }
                })
                .catch(t => {
                    debugger
                    resolve([]);
                });
        })
    },

    getTimeTrackingStatsDataDescriptor(indices) {

        let returning = { filters: [], data: [], aggregations: []};

        returning.filters = [
            //Add base filters that could be useful, start with "accept-all" values
            {
                index: "",
                root: "",
                name: 'DayOfYear',
                type: 'integer',
                selectedForFiltering: true,
                filters: [
                    {
                        conditions: [{operator: '>=', value: 0}, {operator: '<=', value: 364}],
                        defaultName: VueInstance.get().$gettext("Yearly working periods"),
                        name: VueInstance.get().$gettext("Yearly working periods"),
                        enabled: true,
                        filterId: Utils.timeTag("Yearly working periods") //Create a unique id that survives changes
                    }
                ],
            },
            {
                index: "",
                root: "",
                name: 'DayOfWeek',
                type: 'integer',
                selectedForFiltering: true,
                filters: [
                    {
                        conditions: [{operator: '<=', value: 6}],
                        defaultName: "Weakly working days",
                        name: "Weakly working days",
                        enabled: true,
                        filterId: Utils.timeTag("Weakly working days") //Create a unique id that survives changes
                    }
                ],
            },
            {
                index: "",
                root: "",
                name: 'MinuteOfDay',
                type: 'integer',
                selectedForFiltering: true,
                filters: [
                    {
                        conditions: [{operator: '>=', value: 0}, {operator: '<=', value: 1440}],
                        defaultName: "Daily working minutes",
                        name: "Daily working minutes",
                        enabled: true,
                        filterId: Utils.timeTag("Daily working minutes") //Create a unique id that survives changes
                    }
                ],
            },
            {
                index: "",
                root: "Line",
                name: 'WorkorderID',
                type: 'keyword',
                selectedForFiltering: true,
                filters: [],
            }
        ];

        let filterKeys = { DayOfYear: 0, DayOfWeek: 1, MinuteOfDay: 2, Workorder: 3 };
        let index = indices[0];

        returning.data.push(
            {
                index: index,
                root: 'TimeTracking',
                name: 'Activity',
                type: 'integer',
                selectedForVisualization: true,
                representations: [
                    {
                        type: Defines.allAggregations.ttsurvey.id,
                        filters: [ returning.filters[filterKeys.DayOfYear].filters[0].filterId, returning.filters[filterKeys.DayOfWeek].filters[0].filterId, returning.filters[filterKeys.MinuteOfDay].filters[0].filterId ],
                        target: this.targets.activity,
                        aggregationWindow: 0,
                        defaultName: 'Activity',
                        name: 'Activity',
                        id: 'Activity',
                        visualizationOptions: { includeInactiveResults: false },
                        enabled: true
                    },
                    {
                        type: Defines.allAggregations.ttranking.id,
                        filters: [ returning.filters[filterKeys.DayOfYear].filters[0].filterId, returning.filters[filterKeys.DayOfWeek].filters[0].filterId, returning.filters[filterKeys.MinuteOfDay].filters[0].filterId ],
                        target: this.targets.ranking,
                        aggregationWindow: 0,
                        orderBy: 'values',
                        order: 'descending',
                        defaultName: 'Activity',
                        name: 'Activity',
                        id: 'Activity',
                        visualizationOptions: { includeInactiveResults: false },
                        enabled: true
                    },
                ],
            }
        );
        return returning;
    },

    getTimeTrackingSurvey(index, root, variable, type, from, to) {

        if(!from)
            from = new Date(0);

        if(!to)
            to = new Date();

        let varFullName = "{0}.{1}.{2}".format(index, root, variable);

        let dataItems = [
            {
                index: index,
                root: root,
                name: variable,
                type: type,
                selectedForVisualization: true,
                representations: [
                    {
                        type: Defines.allAggregations.ttsurvey.id,
                        filters: [],
                        name: "Values survey of {0}".format(varFullName),
                        visualizationOptions: {
                            includeInactiveResults: true,
                        },
                    },
                ],
            }
        ];

        let queryDescriptor = DataApis.getDataQueryDescriptor(dataItems);

        return new Promise((resolve, reject) => {

            //Execute query
            DataApis.getDataBlob(queryDescriptor, DateTime.getRfc3339TimeStamp(from), DateTime.getRfc3339TimeStamp(to))
                .then(result => {
                    let data = DataApis.unwrapDataSets(dataItems, [], [], result);
                    let values = [];
                    if (Array.isUseful(data[0].data))
                        for (let i = 0; i < data[0].data.length; i++)
                            if (data[0].data[i] && data[0].data[i].hasOwnProperty('id'))
                                values.push(data[0].data[i]);
                    resolve(values);
                })
                .catch(err => {
                    console.log(err);
                    reject("Error while surveying {0}".format(varFullName));
                });
        })
    },

    getUncategorizedStops(from, to, workorder) {
        let query = { filters: [], data: [], aggregations: []};
        let self = this;
        query.filters = [
            //Add base filters that could be useful, start with "accept-all" values
            {
                index: "production counters@5s",
                root: "TimeTracking",
                name: 'Activity',
                type: 'integer',
                selectedForFiltering: true,
                filters: [
                    {
                        conditions: [{operator: '=', value: 2}],
                        defaultName: "uncatFilter",
                        name: "uncatFilter",
                        enabled: true,
                        filterId: "uncatFilter"
                    }
                ],
            },
            {
                index: "",
                root: "Line",
                name: 'WorkorderID',
                type: 'keyword',
                selectedForFiltering: true,
                filters: [
                    {
                        conditions: [{operator: '=', value: workorder}],
                        defaultName: "SelectWorkorder",
                        name: "SelectWorkorder",
                        enabled: true,
                        filterId: "SelectWorkorder"
                    }
                ],
            }
        ];


        query.data.push(
            {
                index: 'production counters@5s',
                root: 'TimeTracking',
                name: 'Activity',
                type: 'integer',
                selectedForVisualization: true,
                representations: [
                    {
                        type: Defines.allAggregations.raw.id,
                        filters: [ "uncatFilter", workorder ? "SelectWorkorder" : "" ],
                        target: this.targets.activity,
                        aggregationWindow: 0,
                        defaultName: 'Activity',
                        name: 'Activity',
                        id: 'Activity',
                        enabled: true
                    },
                ],
            },
        );

        let queryDescriptor = DataApis.getDataQueryDescriptor(query.data, query.filters);

        //Execute query
        return new Promise((resolve, reject) => {
            if (queryDescriptor.agg.length > 0 || queryDescriptor.raw.length > 0 || queryDescriptor.comp.length > 0) {
                DataApis.getDataBlob(queryDescriptor, from, to)
                    .then(result => {
                        let stops = DataApis.unwrapDataSets(query.data, [], [], result, config.debug ? result["queryIndex"] : -1).last().data;
                        if(!Array.isUseful(stops)) {
                            resolve([]);
                            return;
                        }
                        this.getPendingBackAnnotations()
                            .then(pending => {

                                let workFlowSettings = settings.getWorkFlowSettings();
                                let returning = [];
                                let start = stops[0].x;
                                let stop = start;
                                let timeLapse = 15000;
                                if (workFlowSettings && parseInt(workFlowSettings.productionTimeThreshold) !== 0) {
                                    timeLapse = parseInt(workFlowSettings.productionTimeThreshold) * 1000;
                                }

                                // if (!workFlowSettings || workFlowSettings.productionTimeThreshold === 0) {
                                //     timeLapse = 15000;
                                // } else  {
                                //     timeLapse = parseInt(workFlowSettings.productionTimeThreshold) * 1000;
                                // }

                                for (let i = 1 ; i < stops.length ; i++) {
                                    let tmp = stops[i].x;
                                    if (tmp > (stop + timeLapse)) {

                                        if(!pending.includes(start.toString()))
                                            returning.push({start: start, stop: stop, duration: (stop - start)});

                                        start = tmp;
                                        stop = start;

                                    } else stop = tmp;
                                }
                                if(!pending.includes(start.toString()))
                                    returning.push({start: start, stop: stop, duration: (stop - start)});

                                resolve(returning);
                            })
                    })
                    .catch(err => {
                        console.log(err);
                        //self.error = self.$gettext("Unable to retrieve current workorder information");
                        reject([]);
                    });
            }
        });
    },

     async getAlarmsOnStop(startStop) {
        return new Promise((resolve, reject) => {
            //TODO FN must be checked .The Microservice responds with a error . Doesn't work
            OrchestratorAPI.proxyCall('get',Microservices.analyticsUrl + '/stopinfo/' + startStop)
            // Api().get('/events/onstop/' + startStop)
            .then(result => {
                let alarms = [];
                if(Array.isUseful(result.Alarms.alarmsOther) || Array.isUseful(result.Alarms.alarmsSuitable)) {
                    alarms = alarmsApi.formatAlarmsGroups(result.Alarms);
                }
                resolve({alarms: alarms, stoppedMachines: result.StoppedMachines});
            })
            .catch(t => {
               debugger;
               console.error(t);
               reject(VueInstance.get().$gettext("Error in retrieving alarms from DB."));
            })
        });
    },

    async getAllStopCauses() {
        return new Promise((resolve, reject) => {
            this.getTimeTrackingSurvey('production counters@5s', 'TimeTracking', 'Activity', 'integer', null, null)
                .then(allStopCauses => {
                    if (!Array.isUseful(allStopCauses))
                        allStopCauses = [];
                    resolve(allStopCauses);
                })
                .catch(err => {
                    console.log(err);
                    reject("Unable to find used stop causes");
                });
        });
    },

    //Call recursive function
    getFilteredLineStopCauses(includeInactiveResults = true) {
        let categories = settings.getLineStopCauses();
        if (!includeInactiveResults) {
            categories = this.deleteInactiveStopCauses(categories);
        }
        function setCategory(categories){

            categories.forEach(c => {
                if (!c.category) {
                    c.category = -1;
                }
                if(Array.isUseful(c.children)){
                    setCategory(c.children)
                }
            });
        }
        setCategory(categories);
        return categories;
    },

    //Recursive Function
     deleteInactiveStopCauses(arr = []) {
        // base case, to break the recursion when the array is empty
        if (!Array.isUseful(arr)) {
            return arr;
        }
        for(let i = 0 ; i < arr.length ; i++) {
            if(Object.isUseful(arr[i].active) && !arr[i].active) {
                arr.removeAt(i);
                i--;
            } else this.deleteInactiveStopCauses(arr[i].children);
        }
        return arr;
    },

    async getUpdatedStopCauses() {
        return new Promise((resolve, reject) => {
            let availableNewUpperLevelMaster = false;
            let mergedLineStopCauses = settings.getLineStopCauses();
            settings.loadUpperLevelStopCauses(config.deployment + 1)
                .then(upperLevelStopCauses => {
                    if (Array.isUseful(upperLevelStopCauses)) {
                        if (Array.isUseful(mergedLineStopCauses)) {
                            //Filled with current Level stop causes.
                            //This recursive func merge the lower level stop causes with the upper level stop cause.The lower level stop causes
                            //keeps its properties unchanged (i.e. active,show), only the upper level ids  and children are the same of the upper level.
                            let mergeLineStopCausesCrawler = function (upperLevelStopCauses, mergedLineStopCauses) {

                                //check if there are new Ids
                                let newStopCauses = upperLevelStopCauses.filter(obj => {
                                    return !(mergedLineStopCauses.map(el => el.id).includes(obj.id))
                                });

                                if (Array.isUseful(newStopCauses)) {
                                    mergedLineStopCauses.push(...newStopCauses);
                                    availableNewUpperLevelMaster = true;
                                }
                                //Check if anything was deleted or deactivated, since this happens on top level (globally)
                                //we override it also on line level
                                for (let i = 0; i < mergedLineStopCauses.length; i++) {
                                    let stopCause = upperLevelStopCauses.filter(obj => {
                                        return (obj.id === mergedLineStopCauses[i].id)
                                    });
                                    //First case, stop cause was removed on L3, remove also on L2
                                    if (!Array.isUseful(stopCause)) {
                                        mergedLineStopCauses.removeAt(i);
                                        availableNewUpperLevelMaster = true;
                                        i--;
                                        continue;
                                    }
                                    //Second case, we found the id but tag is changed (or created after a 2.0 update. This means that stop cause was
                                    //deleted on L3 and id was recycled with a new one, in this case we substitute the stop cause and move it to the bottom
                                    if (stopCause[0].creationTag !== mergedLineStopCauses[i].creationTag) {
                                        mergedLineStopCauses.removeAt(i);
                                        availableNewUpperLevelMaster = true;
                                        i--;
                                        mergedLineStopCauses.push(stopCause[0]);
                                        continue;
                                    }
                                    //Third case, stop cause was deactivated on L3, deactivate also on L2
                                    if (mergedLineStopCauses[i].active && !stopCause[0].active) {
                                        mergedLineStopCauses[i].active = false;
                                        availableNewUpperLevelMaster = true;
                                    }
                                    //For each item walk recursively on children
                                    mergeLineStopCausesCrawler(stopCause[0].children || [], mergedLineStopCauses[i].children || []);
                                }
                            };

                            mergeLineStopCausesCrawler(upperLevelStopCauses, mergedLineStopCauses);

                        } else {
                            mergedLineStopCauses = upperLevelStopCauses;
                            availableNewUpperLevelMaster = true;
                        }

                        if (availableNewUpperLevelMaster) {
                            settings.saveLineStopCauses(mergedLineStopCauses, config.deployment)
                                .then(() => {
                                    VueInstance.get().$root.showInfoNotification(VueInstance.get().$gettext("Line stop causes were updated from L3"), true,);
                                    audits.save(VueInstance.get().$root.userName, audits.items().lineStopCausesUpdated, "", "", "")
                                        .catch(err => {
                                            debugger
                                            VueInstance.get().$root.showErrorNotification(VueInstance.get().$gettext("An error occurred while saving audits to DB"), true);
                                        });
                                })
                                .catch(() => {
                                    VueInstance.get().$root.showErrorNotification(this.$gettext("An error occurred while updating line stop causes from L3"), true);
                                });
                        }
                    }
                    function addStandardCategory(list){
                        list.forEach(sc=>{
                            if (!sc.category){
                                sc.category=-1;
                            }
                            if (Array.isUseful(list.children)){
                                addStandardCategory(list.children)
                            }
                        })
                    }
                    addStandardCategory(mergedLineStopCauses)

                    resolve(mergedLineStopCauses)
                    })
                .catch(err => {
                    debugger;
                    console.log(err);
                    reject(VueInstance.get().$gettext("Error in retrieving updated stop causes."));
                });

        });
    },
}
