<template>
    <DynamicElementBase>
        <v-layout row fill-height>
            <v-flex xs5>
                <BarChart ref="graph" style="width: 100%; height: calc(100% - 50px)" v-if="loaded" :chartdata="pareto.chartData" :options="options"></BarChart>
            </v-flex>
            <v-flex xs7>
                <v-layout column fill-height ml-2>
                    <div style="overflow: auto; margin-top: 15px;">
                        <v-treeview ref="tree2" class="subheading font-weight-bold labelsHeadersStyle" :items="customRejects" item-text="show">
                            <template v-slot:prepend="{ item }">
                                <label v-if="item.show && item.options.unnested && item.visible" :style="'color: ' + item.color">
                                    {{item.show}}
                                </label>
                                <av-icon v-if="item.show && Array.isUseful(item.children) && !item.options.unnested && item.visible">fa fa-chart-bar</av-icon>
                                <av-icon v-if="item.show && Array.isUseful(item.children) && !item.options.unnested && item.visible" style="margin-left: 2px" @click="selectForGraph(item)">
                                    {{ item.selectedForGraph ? 'far fa-check-square' : 'far fa-square' }}
                                </av-icon>
                            </template>
                            <template v-slot:label="{item}">
                                <label v-if="item.show && !item.options.unnested && item.visible" :style="'color: ' + item.color + ';' + (Array.isUseful(item.children) ? '' : 'margin-left: 37px;')">
                                    {{item.show}}
                                </label>
                            </template>
                            <template v-slot:append="{ item }">
                                <label v-if="item.show && item.visible" class="v-treeview-node__label font-weight-regular labelsHeadersStyle">
                                    : {{ formatRejectCounter(item.counter, item.options, item.anchestors, item.isLeaf, item.show) }}<!--                                    : {{ item.counter !== 0 ? formatTime(item.counter) : "" }}-->
                                </label>
                            </template>
                        </v-treeview>
                    </div>
                </v-layout>
            </v-flex>
        </v-layout>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
    import BarChart from '@/components/graphics/BarChart.vue'
    import ColorSequence from '@/api/colorsequences'
    import DataApis from '@/api/data'

    export default {
        name: 'WidgetRejectsStatistics',
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            BarChart
        },
        data () {
            return {
                configurableCategoriesOffset: 0,
                pareto: null,
                loaded: false,
                //showButton: true,
                selectedNode: null,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    animation: false,
                    elements: {
                        line: {
                            tension: 0
                        }
                    },
                    propagate: false,
                    plugins: {
                        legend: {
                            display: false,
                            position: "top"
                        },
                        datalabels: {
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            type: "category",
                            time: {
                                bounds: 'data',
                                // format: "HH:mm",
                                // unit: 'minutes',
                                // unitStepSize: 1,
                                displayFormats: {
                                    'millisecond': 'hh:mm:ss.SSS',
                                    'second': 'hh:mm:ss',
                                    'minute': 'hh:mm',
                                    'hour': "MMM DD hh:mm",
                                    'day': 'DD/MM/YYYY',
                                    'week': 'DD/MM/YYYY',
                                    'month': 'MMM YYYY',
                                    'quarter': '[Q]Q-YYYY',	//'Q1 - 2018'
                                    'year': 'YYYY',
                                },
                                // tooltipFormat: 'HH:mm:ss'
                            },
                            grid: {
                                display: true
                            }
                        },
                        y: {
                            ticks: {
                                min: 0,
                                max: undefined
                            }
                        }
                    }
                },
                customRejects: [],
                tmpRejects: [],
                customRejectsMap : null,
                dataModelRefresh: true,
            }
        },
        props: {
        },
        mounted: function() {
            this.customRejectsMap = new Map();

            this.childHandlers.onDataItemsChanged = this.onDataItemsChanged;
            //Remove window control
            //this.properties.showTimeWindow = true;
            //Fix window to a reasonable week
            this.properties.timeWindow.predefined = 15;
            this.properties.timeWindow.predefinedIndex = 4;
            // this.properties.timeWindow.setPredefined(15);

            this.properties.forcedDataRefreshInterval = 10;
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], true, false));
            this.dataExplorationMode.autoDataSourcing = false;
            this.dataExplorationMode.deviceSelectMode = false;
            this.showTitleBar = true;

            this.visualizationTweaks = [
                {
                    name: this.$gettext("Automatic runtime datasource"),
                    id: "AutomaticRuntimeDatasource",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                },
            ];
            this.visualizationOptions = [
                {
                    name: this.$gettext("Set as parent node"),
                    id: "changedToParentNode",
                    type: "option",
                    options: [''],
                    get default() {
                        return this.options[0];
                    },
                },
                {
                    name: this.$gettext("Do not nest under parent"),
                    id: "unnested",
                    type: "bool",
                    options: [''],
                    default: false
                },
                {
                    name: this.$gettext("Do not sum value to parent"),
                    id: "noSum",
                    type: "bool",
                    options: [''],
                    default: false
                },
                // {
                //     name: this.$gettext("Test String"),
                //     id: "teststring",
                //     type: "string",
                //     default: "this is default"
                // },
                // {
                //     name: this.$gettext("Test Number"),
                //     id: "testnumber",
                //     type: "number",
                //     default: 5
                // },
                {
                    name: this.$gettext("Parent node"),
                    id: "parentOutOfTree",
                    type: "option",
                    options: [''],
                    get default() {
                        return this.options[0];
                    },
                },
                {
                    name: this.$gettext("Show as parent node value"),
                    id: "parentInsideTree",
                    type: "option",
                    options: [''],
                    get default() {
                        return this.options[0];
                    },
                },
                // {
                //     name: this.$gettext("Color setting"),
                //     id: "color",
                //     type: "color",
                //     default: "#009CE0",
                // },
            ];

        },
        computed: {
            indices() {
                return this.properties.dataPatterns;
            }
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    // this.showButton = this.visualizationTweaks[0].value;
                    //Update visualization tweaks values and save widget
                    this.saveTweaks();
                    if (this.getTweakValue("AutomaticRuntimeDatasource")) {
                        this.loadIndices();
                    }
                },
                deep: true,
            }
            },
        methods: {
            loadIndices() {
                // this.isAutoloading = true;
                let self = this;
                DataApis.loadDataDefinitions()
                    .then(data => {
                        //All Index compatibles
                        //For automatic datasource select all inidices
                        let indicesTmp = DataApis.selectIndexesByPatterns(data, self.dataExplorationMode.compatibleDataPatterns).multiSelect.map((x) => x.index);
                        //Replacing line description with wild card * for query on all lines
                        let lineDescription = indicesTmp[0].split('~|~')[2];
                        indicesTmp[0] = indicesTmp[0].replace(lineDescription, '*');
                        self.properties.dataPatterns = indicesTmp;
                    })
                    .catch(t => {
                        console.log(t);
                        // this.$root.showErrorNotification(this.$gettext("Error in retrieving data definitions from DB."), true);
                    });
                },
            onDataItemsChanged() {
                let representationCount = 0;
                DataApis.forEachRepresentation(this.dataItems, (representation, dataIndex) => {
                    representationCount++;
                });
                representationCount += this.aggregationItems.length;
                representationCount += this.functionItems.length;
                //with no data representation clear the graph
                if (representationCount === 0) {
                    this.customRejects.clear();
                    this.buildPareto();
                }
                this.selectedNode = null;
                this.visualizationOptions[2].options.clear();
                this.visualizationOptions[2].options.push('');
                this.visualizationOptions[3].options.clear();
                this.visualizationOptions[3].options.push('');
                this.dataModelRefresh = true;
            },

            getDataItems(dataValues) {
                let self = this;

                this.customRejectsMap = new Map();
                this.customRejects.clear();

                if(Array.isUseful(dataValues)) {
                    dataValues.forEach(item =>{
                        if (item.target === self.$defines.crossAggregationTarget.id)
                            return;
                        let root;
                        let tokens = (item.identifier || "").split(".");
                        let labelTokens = item.isDefaultLabel ? [] : (item.label || "").split(".");

                        if(Array.isUseful(labelTokens) && labelTokens.length > 1) {
                            tokens = labelTokens;
                        }
                        // else if(Array.isUseful(tokens))
                        //     tokens.pop();
                        // else
                        //     tokens = labelTokens;

                        if(item.visualizationOptions.hasOwnProperty("unnested") && !item.visualizationOptions.unnested) {
                            root = self.customRejectsMap;
                        } else {
                            //if there are multilevels Take only the last
                            tokens = tokens.length > 1 ? [ tokens.last() ] : tokens;
                            root = self.customRejectsMap;
                        }

                        for(let [index, token] of tokens.entries()) {
                            let anchestors = tokens.slice(0,index);
                            let isLeaf = Array.isUseful(anchestors) && tokens.length === index +1 || tokens[tokens.length -1 ] === "";
                            let identifier=tokens.filter((i,idx)=>{return idx<=index}).join(".")

                            if (root.has(token)) {//It's a node parent
                                if(index === tokens.length - 1) {//used for sibling node with the same name
                                    let i = 0;
                                    let tmpToken;
                                    while (root.get(token)) {
                                        i++;
                                        tmpToken = token.split('_');
                                        token = tmpToken[0] +'_' + i;
                                    }
                                    root.set(token, {
                                        children:  new Map(),
                                        anchestors : anchestors,
                                        isLeaf: isLeaf,
                                        show: token,
                                        counter:  item.data.last().y,
                                        target: '',
                                        options: item.visualizationOptions,
                                        color: "#000000",
                                        selectedForGraph: false,
                                        visible: true,
                                        identifier:identifier
                                    });

                                    if(!root.get(token).options.unnested && root.get(token).options.parentInsideTree && token !== root.get(token).options.parentInsideTree) {
                                        root.get(token).visible = false;
                                }
                                } else { // used for show a node when it is a parent and option.parentInsideTree is not ""
                                    if(anchestors.length > 0) {
                                        if(!root.get(token).visible)
                                        {
                                            root.get(token).visible = true;
                                        }
                                    }
                                    let item = "";
                                    for(let anchestor of anchestors) {
                                        item += (item ? "." : "") + anchestor;
                                    }
                                    if(!root.get(token).options.unnested && !self.visualizationOptions[3].options.includes(token)) {
                                        item += (item ? "." : "") + token;
                                        // self.visualizationOptions[3].options.push(token);
                                        self.visualizationOptions[3].options.push(item);
                                    }
                                }
                            } else { //it is the first node as root/leaf
                                root.set(token, {
                                    children: new Map(),
                                    anchestors : anchestors,
                                    isLeaf: isLeaf,
                                    show: token,
                                    counter:  item.data.last().y,
                                    target: '',
                                    options: item.visualizationOptions,
                                    color: "#000000",
                                    selectedForGraph: false,
                                    visible: true,
                                    identifier:identifier
                                });
                                if(!root.get(token).options.unnested && root.get(token).options.parentInsideTree && token !== root.get(token).options.parentInsideTree) {
                                    root.get(token).visible = false;
                                }
                                // if(anchestors.length > 1) {
                                //     if(!root.get(anchestors[anchestors.length -1]).visible)
                                //     {
                                //         root.get(anchestors[anchestors.length -1]).visible = true;
                                //     }
                                // }
                                if(root.get(token).options.unnested && !self.visualizationOptions[2].options.includes(token))
                                    self.visualizationOptions[2].options.push(token);
                                // if(!root.get(token).options.unnested && !self.visualizationOptions[3].options.includes(token) && root.get(token).isLeaf) {
                                //     self.visualizationOptions[3].options.push(token);
                                // }
                                if(!root.get(token).options.unnested && !self.visualizationOptions[3].options.includes(token) && anchestors.length===0) {
                                    self.visualizationOptions[3].options.push(token);
                                }
                            }

                            root = root.get(token).children;
                        }

                    });

                    this.mapToArrayObjectRec(this.customRejectsMap);
                    this.customRejects = this.tmpRejects.concat(this.customRejects);

                    //Fn used to delete the unused leafs of parent node(this node must have a check-box icon but must not have children
                    let clearData= function traverse(nodes) {
                        for(let node of nodes) {

                            if (node.children.length > 0) {
                                for (let i = 0; i < node.children.length; i++) {
                                    if (!node.children[i].show) {
                                        node.children.splice(i, 1);
                                        i--;
                                    } else {
                                        traverse(node.children);
                                    }
                                }
                            }
                        }
                    };
                   clearData(this.customRejects);

                    this.tmpRejects.clear();
                }
            },

            getVisualizationOptions(item,isLeaf) {
                let vOptions = JSON.parse(JSON.stringify(item));
                // vOptions.parentOutOfTree = this.visualizationOptions[2].options.includes(vOptions.parentOutOfTree) ? vOptions.parentOutOfTree : '';

                item.parentOutOfTree = isLeaf ? item.parentOutOfTree : '';
                return vOptions;
            },
            mapToArrayObjectRec(m, lev=0) {
                // debugger

                let ro = {};
                lev = lev +1;
                let key;
                for(let[k,v] of m) {
                    key = k;
                    if(v.children instanceof Map) {
                        if(v.options.parentOutOfTree!=='') {
                            if(!this.visualizationOptions[2].options.includes(v.options.parentOutOfTree))
                                v.options.parentOutOfTree = '';
                        }
                        if(v.options.parentInsideTree!=='') {
                            if(!this.visualizationOptions[3].options.includes(v.options.parentInsideTree))
                                v.options.parentInsideTree = '';
                        }
                        ro[k] = JSON.parse(JSON.stringify(v));
                        ro[k].children = [];
                        ro[k].counter = v.children.size > 0 ? 0 : ro[k].counter;

                        // if(lev===1) {
                            ro[k].counterParentInsideTree = [];
                        // }

                        for (const [kc,vc] of v.children) {
                            let mc = new Map();
                            mc.set(kc,vc);
                            ro[k].children.push(this.mapToArrayObjectRec(mc, lev));

                            ro[k].counter = isNaN(vc.counter) || vc.options.noSum ? ro[k].counter + 0 : parseFloat((ro[k].counter+=Number(vc.counter)).toFixed(10));
                            if(vc.options.parentInsideTree !== '' && !vc.options.noSum)
                                ro[k].counterParentInsideTree.push(vc.counter);

                            v.counter = ro[k].counter;
                        }

                        if(ro[k].counterParentInsideTree.length > 0) {
                            ro[k].counter = ro[k].counterParentInsideTree.reduce((a, b) => a + b, 0);
                            v.counter =  ro[k].counter;
                        }

                        if(lev===1) {
                            // debugger;
                            ro[k].options.noSum = true;
                            if(ro[k].options.unnested)
                                this.tmpRejects.push(ro[k]);
                            else this.customRejects.push(ro[k]);
                        }

                    }
                }
                return ro[key];
            },
            selectForGraph(item) {
                item.selectedForGraph = !item.selectedForGraph;
                let selection = item.selectedForGraph;
                //We have an item selected for subcategories visualization, unselect all other.
                //Quickiest way is to uselect all and reselect only current item

                let clearGraphRec = function(root, selection) {
                    for(let rejectItem of root) {
                        rejectItem.selectedForGraph = selection;
                        if(rejectItem.children) {
                            rejectItem.selectedForGraph = selection;
                            root = rejectItem.children;
                            clearGraphRec(root, selection);
                        }
                    }
                };

                // if(!selection)
                    clearGraphRec([item], selection);

                // item.selectedForGraph = selection;
                this.selectedNode = selection ? item.children : null;
                this.colorize();
                this.buildPareto();
            },
            colorize() {
                let decolorizeRec = function(root) {
                    for(let rejectItem of root) {
                        rejectItem.color = "#000000";
                        if(rejectItem.children) {
                            rejectItem.color = "#000000";
                            root = rejectItem.children;
                            decolorizeRec(root);
                        }
                    }
                };

                decolorizeRec(this.customRejects);

                let nodeToColorize = this.selectedNode;
                //If we have no subcategory selected, colorize main categories
                let standardRejects = this.customRejects.filter(x => x.options.unnested === true);
                if(!nodeToColorize) {
                    if(Array.isUseful(standardRejects)) {
                        nodeToColorize = standardRejects;
                        let colors = ColorSequence.getColors(nodeToColorize.length, ColorSequence.palettes.rainbow);
                        nodeToColorize.forEach((rejectItem,index)=>{
                            rejectItem.color = colors[index];
                        })
                        // for(let [index, rejectItem] of nodeToColorize.entries())
                        //     rejectItem.color = colors[index];
                    }
                    nodeToColorize = this.customRejects.filter(x => !x.options.unnested );
                }

                let searchRejectItem = (items, id) => {
                    for (let item of items) {
                        if(item.identifier === id) {
                            return item;
                        }
                        if (Array.isUseful(item.children)) {
                            let childItem = searchRejectItem(item.children, id)
                            if (childItem)
                                return childItem;
                        }
                    }
                    return null
                }
                //Colorize current categories
                let self=this
                let colors = ColorSequence.getColors(nodeToColorize.length, ColorSequence.palettes.rainbow);
                nodeToColorize.forEach((rejectItem,index) => {
                    let itemToColorize = searchRejectItem(self.customRejects,rejectItem.identifier);
                    if(itemToColorize)
                        itemToColorize.color = colors[index];
                })
                let applySelected=(arr)=>{
                    arr.forEach(item=>{
                        if(!item.selectedForGraph){
                            item.selectedForGraph=item.children.filter(c=>{return c.color==="#000000"}).length===0
                        }
                        if(item.children.length>0){
                            applySelected(item.children)
                        }
                    })
                }
                applySelected(this.customRejects)
                // this.$refs.tree1.updateAll();
                this.$refs.tree2.updateAll();
            },


            formatRejectCounter(count, options, anchestors, isLeaf,show) {
                // const standardRejectsTable = this.standardRejects.reduce((srej, op) => (srej[op.show] = op, srej), {});
                const lookUpTable = this.customRejects.filter(op=> op.options.unnested).reduce((srej, op) => (srej[op.show] = op, srej), {});
                let tmpAnchestors = [...anchestors];
                let node = new Map();
                let counter = count;
                if(Array.isUseful(tmpAnchestors) && !options.noSum) {
                    node = this.customRejectsMap.get(tmpAnchestors[0]);
                    tmpAnchestors.shift();
                    tmpAnchestors.forEach(n => {
                        node = node.children.size === 0 ? node.get(n) : node.children.get(n);
                    });
                    counter = node.counter;
                }

                if(options.parentOutOfTree && (isLeaf || options.unnested)) {
                    let fCount = isNaN(count / lookUpTable[options.parentOutOfTree].counter) ? 0 : (count / lookUpTable[options.parentOutOfTree].counter);
                    return count + " ({0}% of {1})".format(this.$utils.roundToDigits(fCount * 100, 1), options.parentOutOfTree);
                }
                else if(!options.noSum) {
                    return count + " ({0}%)".format(this.$utils.roundToDigits(((count / counter) || 0) * 100, 1));
                }
                else {
                    return count;
                }
            },

            // formatStandardCounter(count, options) {
            //     if(Array.isUseful(this.standardRejects)){
            //         const standardRejectsTable = this.standardRejects.reduce((srej, op) => (srej[op.show] = op, srej), {});
            //         if(options.parentOutOfTree) {
            //             let fCount = isNaN(count / standardRejectsTable[options.parentOutOfTree].counter) ? 0 : (count / standardRejectsTable[options.parentOutOfTree].counter);
            //             return count + " ({0}% of {1})".format(this.$utils.roundToDigits(fCount * 100, 1), options.parentOutOfTree);
            //             // return count + " ({0}% of {1})".format(this.$utils.roundToDigits((count / standardRejectsTable[options.parentOutOfTree].counter) * 100, 1), options.parentOutOfTree);
            //         }
            //         // else if(this.standardRejects[2].counter > 0)
            //         else
            //             return count;
            //             // return count + " ({0}%)".format(this.$utils.roundToDigits((count / this.standardRejects[2].counter) * 100, 1));
            //     }
            //     else return 0;
            // },

            assign(item, value, target) {
                item.counter = value;
                item.target = target;
            },
            clearRejectsItems(container) {
                for(const item of container) {
                    item.counter = 0;
                    if(Array.isUseful(item.children))
                        for(const subitem of item.children) {
                            subitem.counter = 0;
                            if(Array.isUseful(subitem.children))
                                for(const subsubitem of subitem.children) {
                                    subsubitem.counter = 0;
                                    if(Array.isUseful(subsubitem.children))
                                        for(const subsubsubitem of subsubitem.children)
                                            subsubsubitem.counter = 0;
                                }
                        }
                }
            },
            refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view
                //if(this.dataModelRefresh) {
                    this.getDataItems(dataValues);
                    this.dataModelRefresh = false;
                //}
                this.colorize();
                this.buildPareto();
                this.loaded = true;
            },
            buildPareto() {
                //Clear data collection
                let newDataCollection = {
                    chartData: {
                        labels: [""],
                        datasets: []
                    },
                };

                if(this.customRejects === null) {
                    this.pareto = null;
                    return;
                }

                let unsortedRejectsItems = [];
                if(this.selectedNode) {
                    for(let i = 0 ; i < this.selectedNode.length ; i++) {
                        unsortedRejectsItems.push(this.selectedNode[i]);
                    }
                } else {
                    unsortedRejectsItems = unsortedRejectsItems.concat(this.customRejects);
                }

                let sortedRejectsItems = unsortedRejectsItems.sort(function(a, b) {
                    try {
                        if (!a.counter || !b.counter)
                            return 0;
                        if (a.counter > b.counter)
                            return -1;
                        if (b.counter > a.counter)
                            return 1;
                        return 0;
                    }
                    catch(err) {
                        return 0;
                    }
                });

                //Unwrap graph targeted data
                let self = this;
                sortedRejectsItems.forEach(function (dataSet, index) {
                    if(dataSet) {
                        newDataCollection.chartData.datasets.push({
                            label: dataSet.show,
                            data: [ dataSet.counter ? dataSet.counter : 0 ],
                            borderColor: dataSet.color,
                            backgroundColor: dataSet.color
                        });
                    }
                });

                this.pareto = newDataCollection;

                if (this.$refs.graph) {
                    this.$nextTick(() => {
                        this.$refs.graph.refreshGraph();
                    })
                }
            },
        }
    }
</script>

<style>

    .v-treeview-node__root {
        min-height: 0px;
    }

</style>

<style scoped>

    .redNode {
        color: #00b8d4;
    }
    .labelsStyle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 3px;
    }

    .labelsHeadersStyle  {
        color: black;
    }

</style>
