<template>
    <v-snackbar
            :value="hasMessages"
            color=""
            :timeout="0"
            :auto-height="true"
            :multi-line="true"
            :absolute="absolute"
            :bottom="bottom"
            :left="left"
            :right="right"
            :top="top"
            class="ma-0 pa-0 no-snack-padding">
        <av-notifications-queue :messages="messages" colorFull/>
    </v-snackbar>
</template>

<script>
    export default {
        name: "AvQueuedSnackbar",
        props: {
            messages: {
                type: Array,
                default: () => []
            },
            /*v-snackbar properties*/
            absolute: {
                type: Boolean,
                default: false
            },
            bottom: {
                type: Boolean,
                default: false
            },
            left: {
                type: Boolean,
                default: false
            },
            right: {
                type: Boolean,
                default: false
            },
            top: {
                type: Boolean,
                default: false
            },
            vertical: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hasMessages() {
                return Array.isUseful(this.messages);
            },
        }
    }
</script>

<style scoped>
    .no-snack-padding >>> .v-snack__content {
        padding: 0px;
    }
</style>