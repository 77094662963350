<template>
    <v-layout align-start justify-start column style="max-height:100%; overflow-y:auto; overflow-x: hidden">
        <template>
            <template v-if="options.deviceSelectMode">
                <label class="sectionTitle" v-translate style="margin-left: 5px">Select device</label>
                <label class="subheading" v-translate style="margin-left: 5px">Select the device(s) for which you want your element to source data</label>
                <av-select :chips="options.multipleDevicesSelection"
                           :small-chips="options.multipleDevicesSelection"
                           :multiple="options.multipleDevicesSelection"
                           :items="availableDevices"
                           :value="selectedDevs"
                           @change="selectedDevicesChanged($event)"
                           @blur="devicesSelectionComplete()"
                           style="margin-left: 5px"
                           validate-on-blur
                           outlined
                           return-object data-qa-type="input-select" data-qa-name="select-devices"/>
            </template>

            <v-expansion-panel expand class="pa-0" data-qa-type="expansion-panel-group">
                <!--Scheduling editing for rule engine -->
                <template v-if="options.rulesExecutionSchedulingMode.required">
                    <v-expansion-panel-content class="pa-0 mt-2 expansionPanelHeader">
                        <template v-slot:header>
                            <v-layout row class="sectionTitle">
                                <av-icon>fas fa-clock</av-icon>
                                <label class="mx-3" v-translate>Scheduling</label>
                            </v-layout>
                        </template>
                        <RuleExecutionScheduling :ruleScheduling="properties.ruleScheduling"
                                                 :options="options.rulesExecutionSchedulingMode"/>
                    </v-expansion-panel-content>
                    <v-layout class="data-identifier mb-2" row justify-center>
                        <av-icon>fas fa-arrow-down</av-icon>
                    </v-layout>
                </template>

                <!--Filter items representations-->
                <v-expansion-panel-content data-qa-type="expansion-panel" data-qa-name="filters" v-if="options.requiresDataFiltering" class="pa-0 expansionPanelHeader">
                    <template v-slot:header>
                        <v-layout row class="sectionTitle">
                            <av-icon>fas fa-filter</av-icon>
                            <label class="mx-3" v-translate>Filters</label>
                            <template v-if="Array.isUseful(filterItems)">
                                <av-button :text="$gettext('Clear')"
                                           @click="$root.showDialogBox($gettext('Are you sure you want to delete filter definitions?'), null, 'Yes', clearFilters, 'No', null)"
                                           buttonIcon="fa-trash" iconColor="red" size="medium" fab small/>
                                <v-badge left color="info"
                                         style="margin-left: 8px; margin-top: 15px; line-height: 1.5!important;">
                                    <template v-slot:badge>
                                        <span style="color:white">{{filterItems.length}}</span>
                                    </template>
                                </v-badge>
                            </template>
                        </v-layout>
                    </template>
                    <v-expansion-panel expand class="pa-0" data-qa-type="expansion-panel">
                        <v-expansion-panel-content v-for="(item, itemIndex) in filterItems"
                                                   class="pa-0 data-expansion-panel">
                            <template v-slot:header>
                                <v-layout row fill-height align-center>
                                    <av-button v-if="!item.matched"
                                               :toolTip="$gettext('Cannot find this variable. Click to replace it')"
                                               @click="replaceFilter(itemIndex)"
                                               buttonIcon="fa-exclamation" iconColor="red" size="medium" fab small/>
                                    <av-button v-else :toolTip="$gettext('Click to replace this variable')"
                                               @click="replaceFilter(itemIndex, true)"
                                               buttonIcon="fa-exchange" iconColor="info" size="medium" fab small/>
                                    <v-layout :style="item.matched ? 'width: 90%;' : 'width: 90%; color: red'" column ml-2>
                                        <!--<div class="subheading scrollingContainer" style="width: 90%;" ><a class="scrollingText" href="">{{visualizableIndexName(item)}}</a></div>-->
                                        <div class="subheading ellipses-text" style="width: 90%;">
                                            {{visualizableIndexName(item)}}
                                        </div>
                                        <div class="title"><span class="subheading">{{item.displayRoot ? item.displayRoot : item.root}}{{item.root || item.displayRoot ? '.' : ''}}</span>{{
                                            item.displayName ? item.displayName : item.name }}
                                        </div>
                                        <v-switch :label="$gettext('Parametric variable')" v-model="item.globalKey"
                                                  style="margin-top:10px"></v-switch>
                                    </v-layout>
                                </v-layout>
                            </template>
                            <v-card class="pa-0 ma-1">
                                <v-card-text v-if="enabled" class="pa-0 ma-1">
                                    <v-layout column pa-2>
                                        <v-layout mb-3 row justify-center>
                                            <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;">
                                        </v-layout>

                                        <template v-for="(filter, filterIndex) in item.filters">
                                            <v-layout row align-center>
                                                <v-checkbox v-model="filter.parametric"
                                                            @change="parametricChange(filter.parametric, filter)"
                                                            :label="lbParametric"
                                                            style="margin-right: 20px"></v-checkbox>
                                                <av-select v-model="filter.filterMode" :items="filterModeOptions"/>
                                            </v-layout>
                                            <av-select
                                                v-if="options.enableDependentFilters && filter.parametric && availableParametricFilterItems.length > 1"
                                                :items="availableParametricFilterItems.filter(item => item.id !== filter.filterId)"
                                                item-text="show" item-value="id"
                                                v-model="filter.dependsOn" clearable
                                                :prefix="$gettext('Depends on:')"/>
                                            <av-text-field :placeholder="$gettext('type here')"
                                                           :prefix="$gettext('Filter label: ')" class="value osk"
                                                           :value="filter.name" @change="filter.name=$event" single-line
                                                           clearable data-qa-type="input-text" data-qa-name="filter-name"/>
                                            <template v-for="(condition, conditionIndex) in filter.conditions">
                                                <v-layout row style="width: 100%" justify-space-around fill-height
                                                          align-center>
                                                    <av-select v-model="condition.operator"
                                                               :items="getComparersForType(item, filter.parametric)"
                                                               :dynamicID="item.name" style="max-width: 60px;"/>
                                                    <div v-if="!(['exists', 'not_exists', 'IsEmpty', 'IsNotEmpty'].includes(condition.operator))"
                                                         style="margin-left: 20px; width: calc(100% - 200px)">
                                                        <PopUpTimePicker
                                                                v-if="$defines.isWellKnownItem(item, $defines.WellKnownItems.MinuteOfDay)"
                                                                type="minuteOfDay" pa-0 v-model="condition.value"/>
                                                        <PopUpDayOfWeekPicker
                                                                v-else-if="$defines.isWellKnownItem(item, $defines.WellKnownItems.DayOfWeek)"
                                                                pa-0 v-model="condition.value"/>
                                                        <PopUpDayOfYearPicker
                                                                v-else-if="$defines.isWellKnownItem(item, $defines.WellKnownItems.DayOfYear)"
                                                                type="dayOfYear" pa-0 v-model="condition.value"/>
                                                        <PopUpMonthOfYearPicker
                                                                v-else-if="$defines.isWellKnownItem(item, $defines.WellKnownItems.Month)"
                                                                class="pa-0" v-model="condition.value"/>
                                                        <v-combobox
                                                                v-else-if="$defines.isWellKnownItem(item, $defines.WellKnownItems.WorkOrder)"
                                                                pa-0
                                                                :placeholder="$i18n.CommonAttributes()['type here']"
                                                                :value="condition.value"
                                                                @change="condition.value = $event"
                                                                :items="[ '@CurrentWorkOrder' ]" label="Workorder"
                                                                persistent-hint prepend-icon="assignment"/>
                                                        <v-combobox
                                                                v-else-if="$defines.isWellKnownItem(item, $defines.WellKnownItems.Recipe)"
                                                                pa-0
                                                                :placeholder="$i18n.CommonAttributes()['type here']"
                                                                :value="condition.value"
                                                                @change="condition.value = $event"
                                                                :items="[ '@CurrentRecipe' ]" label="Recipe"
                                                                persistent-hint prepend-icon="description"/>
                                                        <v-combobox
                                                                v-else-if="$defines.isWellKnownItem(item, $defines.WellKnownItems.Device)"
                                                                pa-0
                                                                :placeholder="$i18n.CommonAttributes()['type here']"
                                                                :value="condition.value"
                                                                @change="condition.value = $event" :items="devices"
                                                                label="Device" persistent-hint
                                                                prepend-icon="fas fa-microchip"/>
                                                        <TimeTrackerPicker
                                                                v-else-if="$defines.isWellKnownItem(item, $defines.WellKnownItems.TimeTracking)"
                                                                v-model="condition.value"/>
                                                        <TimePicker
                                                                v-else-if="item.type === $defines.avionicsDataTypes.date.id"
                                                                :apply-time-window="condition.value && condition.value.timespan || null"
                                                                :row-key="itemIndex"
                                                                :filter-item="condition.value"
                                                                :call-back="timeWindowFilterChanged"
                                                                />
                                                        <av-text-field v-else :placeholder="$gettext('type here')"
                                                                       single-line class="osk" :value="condition.value"
                                                                       @change="condition.value = $event"
                                                                       :dynamicID="item.name" data-qa-type="input-text" data-qa-name="condition-value"></av-text-field>
                                                    </div>
                                                    <div style="width: 50px; margin-left: 20px">
                                                        <av-button
                                                                v-if="conditionIndex === filter.conditions.length - 1 && !filter.parametric"
                                                                :text="$gettext('')"
                                                                @click="addFilterCondition(itemIndex, filterIndex)"
                                                                buttonIcon="fa-plus" color="info" iconColor="light"
                                                                :dynamicID="item.name" class="ma-1" fab small/>
                                                    </div>
                                                    <div style="width: 50px">
                                                        <av-button v-if="filter.conditions.length > 1"
                                                                   :text="$gettext('')"
                                                                   @click="deleteFilterCondition(itemIndex, filterIndex, conditionIndex)"
                                                                   buttonIcon="fa-trash" color="error" iconColor="light"
                                                                   :dynamicID="item.name" class="ma-1" fab small/>
                                                    </div>
                                                </v-layout>
                                            </template>

                                            <v-layout row justify-end>
                                                <av-button :text="$gettext('Apply to all data')"
                                                           @click="applyFilterToAllVariables(filter.filterId, item)"
                                                           color="info" :dynamicID="item.name" class="mb-0"/>
                                                <av-button :text="$gettext('Delete filter')"
                                                           @click="deleteFilter(itemIndex, filterIndex)"
                                                           buttonIcon="fa-trash" color="error" iconColor="light"
                                                           :dynamicID="item.name" class="mb-0"/>
                                            </v-layout>

                                            <v-layout ma-3 row justify-center>
                                                <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;">
                                            </v-layout>

                                        </template>
                                        <v-layout row justify-center>
                                            <av-button :text="$gettext('Add filter')" @click="addFilter(itemIndex)"
                                                       buttonIcon="fa-plus-circle" color="info" iconColor="light"
                                                       :dynamicID="item.name"/>
                                            <v-btn v-if="!Array.isUseful(item.filters)" fab small color="error"
                                                   class="ma-1" @click="deleteFilterItem(itemIndex)">
                                                <av-icon light>fas fa-trash</av-icon>
                                            </v-btn>
                                        </v-layout>

                                    </v-layout>
                                </v-card-text>
                                <v-card-text v-else v-translate>Please choose a visualization first by hitting New visualization button</v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panel-content>

                <!--Data items representations-->
                <v-expansion-panel-content data-qa-type="expansion-panel" data-qa-name="data" v-if="options.requiresDataSelection" class="pa-0 mt-2 expansionPanelHeader">
                    <template v-slot:header>
                        <v-layout row class="sectionTitle">
                            <av-icon>fas fa-search</av-icon>
                            <label class="mx-3" v-translate>Data</label>
                            <template v-if="Array.isUseful(dataItems)">
                                <av-button :text="$gettext('Clear')"
                                           @click="$root.showDialogBox($gettext('Are you sure you want to delete data definitions?'), null, 'Yes', clearData, 'No', null)"
                                           buttonIcon="fa-trash" iconColor="red" size="medium" fab small/>
                                <v-badge left color="info"
                                         style="margin-left: 8px; margin-top: 15px; line-height: 1.5!important;">
                                    <template v-slot:badge>
                                        <span style="color:white">{{dataItems.length}}</span>
                                    </template>
                                </v-badge>
                            </template>
                        </v-layout>
                    </template>
                    <v-expansion-panel expand class="pa-0" data-qa-type="expansion-panel">
                        <v-expansion-panel-content v-for="(item, itemIndex) in dataItems"
                                                   class="pa-0 data-expansion-panel">
                            <template v-slot:header>
                                <v-layout row fill-height align-center ml-0 pl-0>
                                    <av-button v-if="!item.matched"
                                               :toolTip="$gettext('Cannot find this variable. Click to replace it')"
                                               @click="replaceVariable(itemIndex)"
                                               buttonIcon="fa-exclamation" iconColor="red" size="medium" fab small/>
                                    <av-button v-else :toolTip="$gettext('Click to replace this variable')"
                                               @click="replaceVariable(itemIndex, true)"
                                               buttonIcon="fa-exchange" iconColor="info" size="medium" fab small/>
                                    <v-layout :style="item.matched ? 'width: 90%;' : 'width: 90%; color: red'" column
                                              ml-2>
                                        <!--<div class="subheading scrollingContainer" style="width: 90%;" ><a class="scrollingText" href="">{{visualizableIndexName(item)}}</a></div>-->
                                        <div class="subheading ellipses-text" style="width: 90%;">
                                            {{visualizableIndexName(item)}}
                                        </div>
                                        <div class="title ellipses-text"><span class="subheading">{{item.displayRoot ? item.displayRoot : item.root}}{{item.root || item.displayRoot ? '.' : ''}}</span>{{
                                            item.displayName ? item.displayName : item.name }}
                                        </div>
                                        <v-switch :label="$gettext('Parametric variable')" v-model="item.globalKey"
                                                  style="margin-top:10px"></v-switch>
                                    </v-layout>
                                </v-layout>
                            </template>

                            <v-card class="pa-0 ma-1">
                                <v-card-text v-if="enabled" class="pa-0 ma-1">
                                    <v-layout column pa-2>

                                        <v-layout mb-3 row justify-center>
                                            <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;">
                                        </v-layout>

                                        <template v-for="(representation, representationIndex) in item.representations">
                                            <av-text-field :placeholder="$gettext('type here')" clearable single-line
                                                           class="osk" :prefix="datasetNameLb"
                                                           :value="representation.name"
                                                           @change="representation.name=$event"
                                                           :dynamicID="item.name" data-qa-type="input-text" data-qa-name="representation-name"></av-text-field>
                                            <v-layout column align-left>
                                                    <v-alert v-if="hasSuggestion(representation, 'singleValueSuggestion') || hasSuggestion(representation, 'stringValueSuggestion')"
                                                        :value="true"
                                                        color="warning"
                                                        icon="warning"
                                                        outline>
                                                        {{hasSuggestion(representation, 'singleValueSuggestion') || hasSuggestion(representation, 'stringValueSuggestion')}}
                                                    </v-alert>
                                                <v-layout row>
                                                    <v-select style="max-width: 95%" pa-0 :prefix="selectLb"
                                                              :items="getAggregationsForType(item)" item-text="show"
                                                              item-value="id" :value="representation.type"
                                                              :disabled="!representation.enabled"
                                                              @change="aggregationChanged(itemIndex, representationIndex, $event)"/>
                                                    <!--TODO create help button component -->
                                                    <v-menu offset-y transition="slide-y-transition"
                                                            v-if="representation.type === $defines.allAggregations.duration.id"
                                                            bottom>
                                                        <v-btn slot="activator" flat small icon>
                                                            <av-icon color="info">fas fa-question</av-icon>
                                                        </v-btn>
                                                        <v-card class="pa-2" style="max-width: 400px">
                                                            <v-card-text v-translate>Add filters to variable to choose the specific conditions you want to measure the duration.
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                </v-layout>
                                                <v-layout row style="max-width: 95%" pa-0>
                                                    <v-switch style="margin-top: 10px;"
                                                              :label="showSwitchLabel(representation)"
                                                              v-model="representation.show"></v-switch>
                                                    <av-select
                                                            v-if="Array.isUseful(visualizationTargets) && representation.show"
                                                            :items="visualizationTargets"
                                                            item-text="show"
                                                            item-value="id"
                                                            v-model="representation.target"
                                                            :prefix="$gettext('Show on:')"
                                                            :disabled="!representation.enabled"
                                                            style="margin-left: 10px;"/>
                                                </v-layout>
                                                <template v-if="showAggregationBucket(item, representation)">
                                                    <v-alert
                                                        v-if="hasSuggestion(representation, 'aggregationWindow')"
                                                        :value="true"
                                                        color="warning"
                                                        icon="warning"
                                                        outline
                                                    >
                                                        {{hasSuggestion(representation, 'aggregationWindow')}}
                                                    </v-alert>
                                                    <v-layout row style="max-width: 95%">
                                                        <av-select number
                                                                   single-line
                                                                   @change="setAggregationWindowUnits(dataItems[itemIndex].representations[representationIndex], $event)"
                                                                   :disabled="!representation.enabled"
                                                                   :items="aggregationWindowUnits"
                                                                   :value="getAggregationWindowUnit(representation)"
                                                                   :prefix="$gettext('Aggregation window: ')"
                                                                   :item-disabled="representation.type === 'cumusum'? disableAggregationWindowUnitItem : ''"
                                                        />
                                                        <av-text-field
                                                                v-if="Object.isDefined(representation.aggregationWindow) && !(representation.aggregationWindow === 0 || representation.aggregationWindow === -1)"
                                                                class="osk" style="margin-left: 10px; max-width: 50px;"
                                                                :value="representation.aggregationWindow" number
                                                                single-line
                                                                @change="setAggregationWindow(dataItems[itemIndex].representations[representationIndex], $event)"
                                                                :dynamicID="item.name" data-qa-type="input-text" data-qa-name="representation-aggregationWindow"></av-text-field>
                                                    </v-layout>
                                                </template>
                                                <av-text-field
                                                        v-if="representation.type === $defines.allAggregations.histogram.id"
                                                        class="ml-4 osk"
                                                        :prefix="$gettext('Histogram interval: ')"
                                                        @change="setRepresentationProperty(itemIndex, representationIndex, 'histogramStep', $event)"
                                                        :value="representation.histogramStep"
                                                        :dynamicID="item.name"
                                                        number single-line data-qa-type="input-text" data-qa-name="representation-histogramStep"></av-text-field>
                                                <v-layout row v-if="$defines.isCategoricalAggregation(representation.type)">
                                                    <av-select
                                                            @change="setRepresentationProperty(itemIndex, representationIndex, 'orderBy', $event)"
                                                            :disabled="!representation.enabled"
                                                            :items="['categories', 'values']"
                                                            :value="representation.orderBy"
                                                            :prefix="$gettext('Order by: ')"
                                                            style="max-width: 40%"
                                                            pa-0/>
                                                    <av-select :disabled="!representation.enabled"
                                                               @change="setRepresentationProperty(itemIndex, representationIndex, 'order', $event)"
                                                               :items="['ascending', 'descending']"
                                                               :value="representation.order"
                                                               prefix=""
                                                               style="max-width: 40%; margin-left: 20px"
                                                               pa-0/>
                                                </v-layout>
                                                <v-alert
                                                    v-if="hasSuggestion(representation, 'filtersFromDifferentIndexForbidden')"
                                                    :value="true"
                                                    color="info"
                                                    icon="info"
                                                    outline
                                                >
                                                    {{hasSuggestion(representation, 'filtersFromDifferentIndexForbidden')}}
                                                </v-alert>
                                                <av-select v-if="Array.isUseful(availableFilterItemsFiltered(item))"
                                                           :value="representation.filters"
                                                           :prefix="$gettext('Filters:')"
                                                           @change="filterChanged(itemIndex, representationIndex, $event)"
                                                           :items="availableFilterItemsFiltered(item)"
                                                           item-text="show"
                                                           item-value="id"
                                                           style="max-width: 95%"
                                                           pa-0
                                                           attach
                                                           chips
                                                           multiple
                                                />
                                                <DataNormalizationBuilder
                                                        :normalizations="representation.normalizations" :item="item"
                                                        :aggregation="representation.type"/>
                                                <recursive-variable-configurator :item="item" :representation-name="representation.name"
                                                                                 @onSelectedItems="($event)=>{onSelectedRecursiveVariables(representation,$event)}"
                                                                                 :variables="representation.recursions"
                                                                                 :available-aggregation-items="availableCrossAggregationItems(true)" @change="(event)=>{onRecursiveVariableChange(representation,event)}"></recursive-variable-configurator>
                                                <SimpleConfigurationsEditor :descriptor="getVisualizationsOptions(item)"
                                                                            :values="representation.visualizationOptions  || {}"
                                                                            :dataType="representation.dataType  || ''"
                                                                            @change="visualizationOptionsChanged(itemIndex, representationIndex, $event)"/>
                                                <v-layout row justify-end>
                                                    <template v-if="allowReordering">
                                                        <av-button :text="$gettext('')"
                                                                   @click="moveRepresentation(itemIndex, representationIndex, -1)"
                                                                   buttonIcon="fa-arrow-up" color="info"
                                                                   iconColor="light" :dynamicID="item.name" class="ma-1"
                                                                   fab small/>
                                                        <label>
                                                            <center>
                                                                <translate>ORDER</translate>
                                                                <br>{{representation.zIndex}}
                                                            </center>
                                                        </label>
                                                        <av-button :text="$gettext('')"
                                                                   @click="moveRepresentation(itemIndex, representationIndex, 1)"
                                                                   buttonIcon="fa-arrow-down" color="info"
                                                                   iconColor="light" :dynamicID="item.name" class="ma-1"
                                                                   fab small/>
                                                        <av-button :text="$gettext('Delete')"
                                                                   @click="deleteRepresentation(itemIndex, representationIndex)"
                                                                   buttonIcon="fa-trash" color="error" iconColor="light"
                                                                   :dynamicID="item.name" class="mb-0"
                                                                   style="max-width: 300px;"
                                                                   size="large"/>
                                                    </template>
                                                </v-layout>
                                            </v-layout>
                                            <v-layout ma-3 row justify-center>
                                                <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;">
                                            </v-layout>
                                        </template>

                                        <v-layout row justify-center>
                                            <av-button :text="$gettext('Add representation')"
                                                       @click="addRepresentation(itemIndex)"
                                                       buttonIcon="fa-plus-circle" color="info" iconColor="light"
                                                       :dynamicID="item.name"/>
                                            <v-btn v-if="!Array.isUseful(item.representations)" fab small color="error"
                                                   class="ma-1" @click="deleteDataItem(itemIndex)">
                                                <av-icon light>fas fa-trash</av-icon>
                                            </v-btn>

                                        </v-layout>
                                    </v-layout>
                                </v-card-text>
                                <v-card-text v-else v-translate>Please choose a visualization first by hitting New visualization button</v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panel-content>

                <!--Aggregations representations-->
                <v-expansion-panel-content data-qa-type="expansion-panel" data-qa-name="aggregations" v-if="options.requiresCrossAggregation"
                                           class="pa-0 mt-2 expansionPanelHeader">
                    <template v-slot:header>
                        <v-layout row class="sectionTitle">
                            <av-icon>fas fa-code-branch</av-icon>
                            <label class="mx-3" v-translate>Aggregations</label>
                            <template v-if="Array.isUseful(aggregationItems)">
                                <av-button :text="$gettext('Clear')"
                                           @click="$root.showDialogBox($gettext('Are you sure you want to delete aggregation definitions?'), null, 'Yes', clearCrossAggregations, 'No', null)"
                                           buttonIcon="fa-trash" iconColor="red" size="medium" fab small/>
                                <v-badge left color="info"
                                         style="margin-left: 8px; margin-top: 15px; line-height: 1.5!important;">
                                    <template v-slot:badge>
                                        <span style="color:white">{{aggregationItems.length}}</span>
                                    </template>
                                </v-badge>
                            </template>
                        </v-layout>
                    </template>
                    <v-card class="pa-0 ma-0">
                        <v-card-text v-if="enabled">
                            <v-layout column pa-2>

                                <v-layout mb-3 row justify-center>
                                    <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;">
                                </v-layout>

                                <template v-for="(item, itemIndex) in aggregationItems">
                                    <av-text-field :placeholder="$gettext('type here')"
                                                   class="osk"
                                                   :prefix="datasetNameLb"
                                                   @change="item.name = $event"
                                                   :value="item.name"
                                                   :dynamicID="item.name"
                                                   clearable single-line data-qa-type="input-text" data-qa-name="aggregation-item-name"></av-text-field>

                                    <v-layout column align-left>
                                        <av-select :value="item.type"
                                                   :disabled="!item.enabled"
                                                   @change="crossAggregationChanged(itemIndex, $event)"
                                                   :prefix="$gettext('Show:')"
                                                   :items="getAvailableCrossAggregationsForItem(item)"
                                                   item-text="show"
                                                   item-value="id"
                                                   style="max-width: 95%"
                                                   :dynamicID="item.name"
                                                   pa-0
                                        />
                                        <template v-if="availableCrossAggregationItems(false).length > 0">
                                            <av-select v-if="item.type !== $defines.allAggregations.buckets.id"
                                                       :items="availableCrossAggregationItems(false)"
                                                       item-text="show"
                                                       item-value="id"
                                                       :value="item.buckets"
                                                       :prefix="$gettext('Fields')"
                                                       @change="crossAggregationBucketsChanged(itemIndex, $event)"
                                                       style="max-width: 95%"
                                                       :dynamicID="item.name"
                                                       pa-0
                                                       attach
                                                       chips
                                                       multiple
                                            />
                                            <template v-else>
                                                <!--Show selection for first bucket-->
                                                <label v-if="Array.isUseful(availableCrossAggregationItems(false))"
                                                       class="subheading" v-translate>Grouping configuration</label>
                                                <label v-else class="subheading" v-translate>No groupings available, please configure them in data section</label>
                                                <template v-if="!Array.isUseful(item.buckets)">
                                                    <label class="subheading" v-translate>Select data to visualize</label>
                                                    <av-select
                                                            :items="getAvailableBucketedCrossAggregationBuckets(itemIndex)"
                                                            item-text="show"
                                                            item-value="id"
                                                            @change="bucketedCrossAggregationBucketAdded(itemIndex, $event)"
                                                            style="max-width: 95%"
                                                            :dynamicID="item.name"
                                                            pa-0
                                                    />
                                                </template>
                                                <!--Show already selected buckets as plain strings-->
                                                <template v-for="(bucket,bucketIndex) in item.buckets">
                                                    <v-layout row
                                                              :style="'height: 30px; margin-left: {0}px'.format(bucketIndex * 16)">
                                                        <template v-if="bucketIndex === 0">
                                                            <!--First item is not an aggregation bucket -->
                                                            <label class="subheading ml-2 mt-1"><translate>Calculate:</translate> <span
                                                                    style="font-weight: bold">{{getRepresentationById(bucket).name}}</span></label>
                                                        </template>
                                                        <template v-else>
                                                            <av-icon>fas fa-level-up-alt fa-rotate-90</av-icon>
                                                            <label class="subheading ml-4 mt-1"><translate>Aggregated by:</translate> <span
                                                                    style="font-weight: bold">{{getRepresentationById(bucket).name}}</span></label>
                                                        </template>
                                                        <av-button :text="$gettext('')"
                                                                   v-if="bucketIndex === item.buckets.length - 1"
                                                                   color="error" class="ma-0 ml-3"
                                                                   @click="item.buckets.pop()" buttonIcon="fa-trash"
                                                                   iconColor="light" :dynamicID="item.name" fab small/>
                                                    </v-layout>
                                                </template>
                                                <div style="height: 24px"/>
                                                <!--Show selection to add more buckets (if available)-->
                                                <av-select
                                                        v-if="Array.isUseful(getAvailableBucketedCrossAggregationBuckets(itemIndex)) && Array.isUseful(item.buckets)"
                                                        :style="'max-width: 300px; margin-top: 10px; margin-left: {0}px'.format((item.buckets.length - 1) * 30)"
                                                        :prefix="$gettext('Add grouping:')"
                                                        :items="getAvailableBucketedCrossAggregationBuckets(itemIndex)"
                                                        item-text="show"
                                                        item-value="id"
                                                        @change="bucketedCrossAggregationBucketAdded(itemIndex, $event)"
                                                        :dynamicID="item.name"
                                                />
                                            </template>
                                        </template>
                                        <template v-if="!$defines.isGrouping(item.type)">
                                            <v-layout row v-if="item.type === $defines.allAggregations.manual.id">
                                                <av-text-field :placeholder="$gettext('type here')"
                                                               class="osk"
                                                               v-model="item.script"
                                                               number single-line
                                                               :prefix="$gettext('Expression: ')"
                                                               :dynamicID="item.name" data-qa-type="input-text" data-qa-name="expression"/>
                                                <!--TODO create help button component -->
                                                <v-menu offset-y transition="slide-y-transition" bottom>
                                                    <v-btn slot="activator" flat small icon>
                                                        <av-icon color="info">fas fa-question</av-icon>
                                                    </v-btn>
                                                    <v-card class="pa-2" style="max-width: 400px">
                                                        <v-card-text v-translate>Use value1 ... valueN to reference selected fields in the order they appear. Use standard mathematical operators or write any javascript expression. For example: (value1 + value2) / value3</v-card-text>
                                                    </v-card>
                                                </v-menu>
                                            </v-layout>
                                            <v-layout row v-if="item.type === $defines.allAggregations.categorygroup.id">
                                                <av-select
                                                        @change="setAggregationProperty(itemIndex, 'orderBy', $event)"
                                                        :disabled="!item.enabled"
                                                        :items="[{text: $gettext('categories'), value: $defines.OrderByItems.categories},
                                                                {text: $gettext('values'), value: $defines.OrderByItems.values},
                                                                {text: $gettext('absolute values'), value: $defines.OrderByItems.absoluteValues}]"
                                                        :value="item.orderBy"
                                                        :prefix="$gettext('Order by: ')"
                                                        style="max-width: 40%"
                                                        pa-0
                                                />
                                                <av-select :disabled="!item.enabled"
                                                           @change="setAggregationProperty(itemIndex, 'order', $event)"
                                                           :items="['ascending', 'descending']"
                                                           :value="item.order"
                                                           prefix=""
                                                           style="max-width: 40%; margin-left: 20px"
                                                           pa-0
                                                />
                                            </v-layout>
                                            <v-layout row style="max-width: 95%" pa-0>
                                                <v-switch style="margin-top: 10px;" :label="showSwitchLabel(item)"
                                                          v-model="item.show"></v-switch>
                                                <av-select v-if="Array.isUseful(visualizationTargets) && item.show"
                                                           style="margin-left: 10px;"
                                                           :items="visualizationTargets"
                                                           item-text="show"
                                                           item-value="id"
                                                           v-model="item.target"
                                                           :prefix="$gettext('Show on:')"
                                                           :disabled="!item.enabled"
                                                           :dynamicID="item.name"
                                                />
                                            </v-layout>
                                            <av-select v-if="item.type === $defines.allAggregations.tablegroup.id"
                                                       @change="setAggregationProperty(itemIndex, 'order', $event)"
                                                       :items="['descending', 'ascending']"
                                                       :value="item.order"
                                                       :prefix="$gettext('Sort table') + ': '"
                                                       style="max-width: 95%"
                                                       pa-0
                                            />
                                            <DataNormalizationBuilder :normalizations="item.normalizations" :item="null"
                                                                      @change="$emit('change')"/>
                                            <SimpleConfigurationsEditor :descriptor="visualizationOptions"
                                                                        :values="item.visualizationOptions || {}"
                                                                        @change="crossAggregationVisualizationOptionsChanged(itemIndex, $event)"/>
                                        </template>
                                        <v-layout row justify-end>
                                            <template v-if="!$defines.isGrouping(item.type)">
                                                <template v-if="allowReordering">
                                                    <av-button :text="$gettext('')" class="ma-1"
                                                               @click="moveCrossAggregation(itemIndex, -1)" color="info"
                                                               buttonIcon="fa-arrow-up" iconColor="light"
                                                               :dynamicID="item.name" fab small/>

                                                    <label>
                                                        <center>ORDER<br>{{item.zIndex}}</center>
                                                    </label>
                                                    <av-button :text="$gettext('')" class="ma-1"
                                                               @click="moveCrossAggregation(itemIndex, 1)" color="info"
                                                               buttonIcon="fa-arrow-down" iconColor="light"
                                                               :dynamicID="item.name" fab small/>
                                                </template>
                                            </template>
                                            <av-button :text="$gettext('Delete')" class="mb-0"
                                                       @click="deleteCrossAggregation(itemIndex)" color="error"
                                                       buttonIcon="fa-trash" iconColor="light" :dynamicID="item.name"
                                                       style="max-width: 300px;"/>
                                        </v-layout>
                                    </v-layout>
                                    <v-layout ma-3 row justify-center>
                                        <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;">
                                    </v-layout>
                                </template>
                                <av-button :text="$gettext('Add aggregation')" @click="addCrossAggregation()"
                                           color="info"
                                           buttonIcon="fa-plus-circle" iconColor="light"/>
                            </v-layout>
                        </v-card-text>
                        <v-card-text v-else v-translate>Please choose a visualization first by hitting New visualization button</v-card-text>
                    </v-card>
                </v-expansion-panel-content>

                <!-- FUNCTIONS -->
                <v-expansion-panel-content data-qa-type="expansion-panel" data-qa-name="functions" v-if="options.requiresFunctions" class="pa-0 mt-2 expansionPanelHeader">
                    <template v-slot:header>
                        <v-layout row class="sectionTitle">
                            <av-icon>fas fa-calculator</av-icon>
                            <label class="mx-3" v-translate>Functions</label>
                            <template v-if="functionItems.length">
                                <av-button :text="$gettext('Clear')"
                                           @click="$root.showDialogBox($gettext('Are you sure you want to delete functions definitions?'), null, 'Yes', clearFunctions, 'No', null)"
                                           buttonIcon="fa-trash" iconColor="red" size="medium" fab small/>
                                <v-badge left color="info"
                                         style="margin-left: 8px; margin-top: 15px; line-height: 1.5!important;">
                                    <template v-slot:badge>
                                        <span style="color:white">{{functionItems.length}}</span>
                                    </template>
                                </v-badge>
                            </template>
                        </v-layout>
                    </template>
                    <v-card class="pa-0 ma-0">
                        <v-card-text v-if="enabled">
                            <v-layout column pa-2>
                                <v-layout mb-3 row justify-center>
                                    <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;">
                                </v-layout>

                                <template v-for="(item, itemIndex) in functionItems">
                                    <av-text-field v-if="!item.isReusableQuery" :placeholder="$gettext('type here')"
                                                   :prefix="$gettext('Function label: ')"
                                                   :value="item.name"
                                                   @change="item.name = $event"
                                                   clearable single-line class="osk"
                                                   :dynamicID="item.name" data-qa-type="input-text" data-qa-name="function-name"/>
                                    <v-layout column align-left>
                                        <av-select style="max-width: 95%"
                                                   :prefix="$gettext('Show:')"
                                                   :items="functions"
                                                   item-text="show"
                                                   item-value="id"
                                                   :value="item.type"
                                                   :disabled="!item.enabled"
                                                   @change="functionChanged(itemIndex, $event)"
                                                   :dynamicID="item.name"
                                        />
                                        <av-text-field :placeholder="$gettext('type here')"
                                                       style="max-width: 95%"
                                                       v-if="item.type === $defines.allFunctions.value.id"
                                                       clearable single-line
                                                       :prefix="$gettext('Value: ')"
                                                       :value="item.value"
                                                       @change="item.value = $event"
                                                       :dynamicID="item.name" data-qa-type="input-text" data-qa-name="function-value"/>

                                        <template v-else-if="item.type === $defines.allFunctions.cp.id || item.type === $defines.allFunctions.cpk.id">
                                            <av-select style="max-width: 95%" :prefix="$gettext('Process variable:')"
                                                       :items="cpItems" :disabled="!item.enabled"
                                                       :value="getFunctionParameter(itemIndex, 'variable')"
                                                       return-object
                                                       @change="functionParametersChanged(itemIndex, 'variable', $event, true)"
                                                       :dynamicID="item.name"
                                            />
                                            <v-combobox style="max-width: 95%" :prefix="$gettext('Upper threshold:')"
                                                        :items="getCrossAggregationItemsByDataType($defines.avionicsDataTypes.number.id)"
                                                        item-text="show" item-value="id" :disabled="!item.enabled"
                                                        :value="getFunctionParameter(itemIndex, 'upper_threshold')"
                                                        @change="functionParametersChanged(itemIndex, 'upper_threshold', $event)"/>
                                            <v-combobox style="max-width: 95%" :prefix="$gettext('Lower threshold:')"
                                                        :items="getCrossAggregationItemsByDataType($defines.avionicsDataTypes.number.id)"
                                                        item-text="show" item-value="id" :disabled="!item.enabled"
                                                        :value="getFunctionParameter(itemIndex, 'lower_threshold')"
                                                        @change="functionParametersChanged(itemIndex, 'lower_threshold', $event)"/>
                                            <v-layout row style="max-width: 95%">
                                                <av-select style="" :prefix="$gettext('Aggregation window: ')"
                                                           :items="aggregationWindowUnits"
                                                           :value="getAggregationWindowUnit(item)"
                                                           :disabled="!item.enabled"
                                                           @change="setAggregationWindowUnits(functionItems[itemIndex], $event)"
                                                           :dynamicID="item.name"
                                                />
                                                <!--                                                <v-select style="" prefix="Aggregation window: " :items="aggregationWindowUnits" :value="getAggregationWindowUnit(item)" :disabled="!item.enabled" @change="setAggregationWindowUnits(functionItems[itemIndex], $event)" />-->
                                                <av-text-field
                                                        v-if="Object.isDefined(item.aggregationWindow) && !(item.aggregationWindow === 0 || item.aggregationWindow === -1)"
                                                        style="margin-left: 10px; max-width: 50px;"
                                                        :value="item.aggregationWindow" number single-line
                                                        @change="setAggregationWindow(functionItems[itemIndex], $event)"
                                                        :dynamicID="item.name" data-qa-type="input-text" data-qa-name="cp-cpk-aggregationWindow"/>
                                            </v-layout>
                                        </template>
                                         <template
                                                v-else-if="item.type === $defines.allFunctions.statmerge.id">
                                            <av-select style="max-width: 95%" :prefix="$gettext('Count:')"
                                                       :items="cpItems" :disabled="!item.enabled"
                                                       :value="getFunctionParameter(itemIndex, 'count')"
                                                       return-object
                                                       @change="functionParametersChanged(itemIndex, 'count', $event, true)"
                                                       :dynamicID="item.name"
                                            />
                                            <av-select style="max-width: 95%" :prefix="$gettext('Mean')"
                                                       :items="cpItems" :disabled="!item.enabled"
                                                       :value="getFunctionParameter(itemIndex, 'mean')"
                                                       return-object
                                                       @change="functionParametersChanged(itemIndex, 'mean', $event, true)"
                                                       :dynamicID="item.name"
                                            />
                                            <av-select style="max-width: 95%" :prefix="$gettext('Mu2')"
                                                       :items="cpItems" :disabled="!item.enabled"
                                                       :value="getFunctionParameter(itemIndex, 'mu2')"
                                                       return-object
                                                       @change="functionParametersChanged(itemIndex, 'mu2', $event, true)"
                                                       :dynamicID="item.name"
                                            />
                                            <v-layout row style="max-width: 95%">
                                                <av-select style="" :prefix="$gettext('Aggregation window: ')"
                                                           :items="aggregationWindowUnits"
                                                           :value="getAggregationWindowUnit(item)"
                                                           :disabled="!item.enabled"
                                                           @change="setAggregationWindowUnits(functionItems[itemIndex], $event)"
                                                           :dynamicID="item.name"
                                                />
                                                <!--                                                <v-select style="" prefix="Aggregation window: " :items="aggregationWindowUnits" :value="getAggregationWindowUnit(item)" :disabled="!item.enabled" @change="setAggregationWindowUnits(functionItems[itemIndex], $event)" />-->
                                                <av-text-field
                                                        v-if="Object.isDefined(item.aggregationWindow) && !(item.aggregationWindow === 0 || item.aggregationWindow === -1)"
                                                        style="margin-left: 10px; max-width: 50px;"
                                                        :value="item.aggregationWindow" number single-line
                                                        @change="setAggregationWindow(functionItems[itemIndex], $event)"
                                                        :dynamicID="item.name" data-qa-type="input-text" data-qa-name="statmerge-aggregationWindow"/>
                                                <!--                                                <v-text-field v-if="Object.isDefined(item.aggregationWindow) && item.aggregationWindow !== 0" style="margin-left: 10px; max-width: 50px;" :value="item.aggregationWindow" number single-line @change="setAggregationWindow(functionItems[itemIndex], $event)"/>-->
                                            </v-layout>
                                        </template>
                                        <template
                                                v-else-if="item.isReusableQuery && Array.isUseful(item.parameters) && Array.isUseful(item.parameters[0].targetBindings)"
                                                v-for="binding in item.parameters[0].targetBindings">
                                            <v-layout row style="max-width: 95%" pa-0>
                                                <v-switch style="margin-top: 10px; max-width: 50px"
                                                          v-model="binding.show"></v-switch>
                                                <label style="margin-top: 10px;" class="subheading">{{showSwitchLabel(binding)}}
                                                    <span class="font-weight-bold">{{binding.dataSet}}</span></label>
                                                <av-text-field v-if="binding.show" :placeholder="$gettext('type here')"
                                                               :prefix="$gettext('As: ')"
                                                               v-model="binding.alias"
                                                               clearable single-line class="osk ml-2"
                                                               style="max-width: 200px; margin-top: 6px"
                                                               :id="binding.dataSet + 'alias'" data-qa-type="input-text" data-qa-name="alias"/>
                                            </v-layout>
                                            <av-select v-if="Array.isUseful(visualizationTargets) && binding.show"
                                                       class="ml-4" prefix="On:" :items="visualizationTargets"
                                                       item-text="show" item-value="id" v-model="binding.target"
                                                       :id="binding.dataSet + 'target'"/>
                                            <av-select v-if="Array.isUseful(availableFilterItems)"
                                                       :value="item.filters"
                                                       :prefix="$gettext('Filters:')"
                                                       @change="reusableQueryFilterChanged(itemIndex, $event)"
                                                       :items="availableFilterItems"
                                                       item-text="show"
                                                       item-value="id"
                                                       style="max-width: 95%"
                                                       pa-0
                                                       attach
                                                       chips
                                                       multiple
                                            />
                                        </template>
                                        <template v-if="!item.isReusableQuery">
                                            <v-layout row v-if="!item.isReusableQuery" style="max-width: 95%" pa-0>
                                                <v-switch style="margin-top: 10px;" :label="showSwitchLabel(item)"
                                                          v-model="item.show"></v-switch>
                                                <av-select v-if="Array.isUseful(visualizationTargets) && item.show"
                                                           style="margin-left: 10px;"
                                                           :items="visualizationTargets" item-text="show"
                                                           item-value="id" v-model="item.target"
                                                           :prefix="$gettext('On:')"
                                                           :disabled="!item.enabled"
                                                           :dynamicID="item.name"
                                                />
                                                <!--                                            <v-select v-if="Array.isUseful(visualizationTargets) && item.show" style="margin-left: 10px;" :items="visualizationTargets" item-text="show" item-value="id" v-model="item.target" prefix="Show on:" :disabled="!item.enabled"/>-->
                                            </v-layout>
                                            <DataNormalizationBuilder :normalizations="item.normalizations"
                                                                      :item="null"/>
                                            <SimpleConfigurationsEditor :descriptor="visualizationOptions"
                                                                        :values="item.visualizationOptions || {}"
                                                                        @change="functionVisualizationOptionsChanged(itemIndex, $event)"/>
                                        </template>
                                        <v-layout row justify-end>
                                            <template v-if="!item.isReusableQuery && allowReordering">
                                                <av-button :text="$gettext('')" @click="moveFunction(itemIndex, -1)"
                                                           color="info" buttonIcon="fa-arrow-up" iconColor="light"
                                                           class="ma-1" :dynamicID="item.name" fab small/>

                                                <!--                                            <v-btn fab small color="info" class="ma-1" @click="moveFunction(itemIndex, -1)">-->
                                                <!--                                                <av-icon light>fas fa-arrow-up</av-icon>-->
                                                <!--                                            </v-btn>-->
                                                <label>
                                                    <center>ORDER<br>{{item.zIndex}}</center>
                                                </label>
                                                <av-button :text="$gettext('')" @click="moveFunction(itemIndex, 1)"
                                                           color="info" buttonIcon="fa-arrow-down" iconColor="light"
                                                           class="ma-1" :dynamicID="item.name" fab small/>
                                            </template>

                                            <!--                                          <v-btn fab small color="info" class="ma-1" @click="moveFunction(itemIndex, 1)">-->
                                            <!--                                                <av-icon light>fas fa-arrow-down</av-icon>-->
                                            <!--                                            </v-btn>-->
                                            <av-button :text="$gettext('Delete')" @click="deleteFunction(itemIndex)"
                                                       color="error" buttonIcon="fa-trash" iconColor="light"
                                                       class="mb-0" :dynamicID="item.name"/>

                                            <!--                                          <v-btn class="mb-0" style="max-width: 300px;" color="error" @click="deleteFunction(itemIndex)">-->
                                            <!--                                                <av-icon left light>fas fa-trash</av-icon>-->
                                            <!--                                                <translate>Delete</translate>-->
                                            <!--                                            </v-btn>-->
                                        </v-layout>

                                    </v-layout>

                                    <v-layout ma-3 row justify-center>
                                        <hr style="height: 1px; border: none; background-color: var(--av-blue); width: 50%;">
                                    </v-layout>

                                </template>
                                <av-button :text="$gettext('Add function')" @click="addFunction()"
                                           color="info" buttonIcon="fa-plus-circle" iconColor="light"/>

                                <!--                                <v-btn color="info" @click="addFunction()">-->
                                <!--                                    <av-icon left light>fas fa-plus-circle</av-icon>-->
                                <!--                                    <translate>Add function</translate>-->
                                <!--                                </v-btn>-->

                            </v-layout>
                        </v-card-text>
                        <v-card-text v-else v-translate>Please choose a visualization first by hitting New visualization button</v-card-text>
                    </v-card>
                </v-expansion-panel-content>

                <!--data section divider-->
                <v-layout v-if="(options.requiresDataSelection || options.requiresDataFiltering || options.requiresCrossAggregation) &&
                                (options.requiresRulesConditions || options.requiresRulesOutputs)"
                          class="data-identifier" row justify-center>
                    <av-icon>fas fa-arrow-down</av-icon>
                </v-layout>

                <!--Conditions editing for rule engine -->
                <template v-if="options.requiresRulesConditions">
                    <v-expansion-panel-content data-qa-type="expansion-panel" data-qa-name="conditions" class="pa-0 mt-2 expansionPanelHeader">
                        <template v-slot:header>
                            <v-layout row class="sectionTitle">
                                <av-icon>fas fa-clipboard-check</av-icon>
                                <label class="mx-3" v-translate>Conditions</label>
                                <template v-if="rules.length">
                                    <av-button :text="$gettext('Clear')"
                                               @click="$root.showDialogBox($gettext('Are you sure you want to delete conditions?'), null, 'Yes', clearRules, 'No', null)"
                                               buttonIcon="fa-trash" iconColor="red" size="medium" fab small/>
                                    <v-badge left color="info"
                                             style="margin-left: 8px; margin-top: 15px; line-height: 1.5!important;">
                                        <template v-slot:badge>
                                            <span style="color:white">{{rules.length}}</span>
                                        </template>
                                    </v-badge>
                                </template>
                            </v-layout>
                        </template>
                        <ConditionsEditor :representationMode=representationMode :dataItems="dataItems" :rules="rules"
                                          :fieldMode="options.documentSelectMode"
                                          :isActivity="properties.isActivity"></ConditionsEditor>
                    </v-expansion-panel-content>
                    <v-layout class="data-identifier" row justify-center>
                        <av-icon>fas fa-arrow-down</av-icon>
                    </v-layout>
                </template>

                <template v-if="options.requiresRulesOutputs">
                    <v-expansion-panel-content data-qa-type="expansion-panel" data-qa-name="outputs" class="pa-0 mt-2 expansionPanelHeader">
                        <template v-slot:header>
                            <v-layout row class="sectionTitle">
                                <av-icon>fas fa-envelope</av-icon>
                                <label class="mx-3" v-translate>Outputs</label>
                                <template v-if="clearOutputsBtn">

                                    <av-button :text="$gettext('Clear')"
                                               @click="$root.showDialogBox($gettext('Are you sure you want to delete outputs?'), null, 'Yes', clearOutputs, 'No', null)"
                                               buttonIcon="fa-trash" iconColor="red" size="medium" fab small/>
                                    <v-badge left color="info"
                                             style="margin-left: 8px; margin-top: 15px; line-height: 1.5!important;">
                                        <template v-slot:badge>
                                            <span style="color:white">{{ properties.isActivity? outputs.length - 1 : outputs.length }}</span>
                                        </template>
                                    </v-badge>
                                </template>
                            </v-layout>
                        </template>
                        <outputs-editor :rules="rules" :dataItems="dataItems" :outputs="outputs"
                                        :options="options" :fieldMode="options.documentSelectMode"
                                        :isActivity="properties.isActivity"></outputs-editor>
                        <!--TODO check fieldMode that was added in nextgen and attach it to options added in trunk-->
                    </v-expansion-panel-content>
                </template>
            </v-expansion-panel>
        </template>
        <VariablePickerDialog :showDialog="showVariablePicker" @selectedItem="selectedVariable($event)"
                              :select-field="true"/>
    </v-layout>
</template>

<script>

    import DataApis from '@/api/data'
    import Devices from '@/api/devices'
    import PopUpTimePicker from '@/components/utilities/PopUpTimePicker'
    import PopUpDayOfWeekPicker from '@/components/utilities/PopUpDayOfWeekPicker'
    import PopUpDayOfYearPicker from '@/components/utilities/PopUpDayOfYearPicker'
    import PopUpMonthOfYearPicker from '@/components/utilities/PopUpMonthOfYearPicker'
    import TimeTrackerPicker from '@/components/TimeTrackerPicker'
    import TimePicker from '@/components/utilities/TimePicker'
    import ConditionsEditor from '@/components/utilities/ConditionsEditor'
    import OutputsEditor from '@/components/utilities/OutputsEditor'
    import SimpleConfigurationsEditor from '@/components/utilities/SimpleConfigurationsEditor'
    import DataNormalizationBuilder from '@/components/utilities/DataNormalizationBuilder'
    import RuleExecutionScheduling from '@/components/dynamic-elements/rules/RuleExecutionScheduling'
    import VariablePickerDialog from '@/components/dialogs/VariablePickerDialog'
    import RecursiveVariableConfigurator from '@/components/utilities/RecursiveVariableConfigurator'
    import {TimeSpan} from "@/api/datetimeutils";

    export default {
        name: 'DataRepresentationConfigurator',
        components: {
            ConditionsEditor,
            PopUpTimePicker,
            PopUpDayOfWeekPicker,
            PopUpDayOfYearPicker,
            PopUpMonthOfYearPicker,
            TimeTrackerPicker,
            TimePicker,
            OutputsEditor,
            SimpleConfigurationsEditor,
            DataNormalizationBuilder,
            RuleExecutionScheduling,
            VariablePickerDialog,
            RecursiveVariableConfigurator
        },
        data() {
            return {
                devices: [],
                reusableQueries: [],
                showVariablePicker: false,
                variableBeingReplaced: -1,
                filterBeingReplaced: -1,
                previousDataItemsLength: -1,  //Used to auto add representations to newly added variables
                timepicker: new TimeSpan(),
                checkfiltersDebouncer: null
            }
        },
        props: {
            //Data item descriptor
            dataItems: {
                type: Array,
                default: null
            },

            filterItems: {
                type: Array,
                default: null
            },

            aggregationItems: {
                type: Array,
                default: null
            },

            functionItems: {
                type: Array,
                default: null
            },

            rules: {
                type: Array,
                default: null
            },


            outputs: {
                type: Array,
                default: null
            },

            //Where to show on element
            visualizationTargets: {
                type: Array,
                default: null
            },

            visualizationOptions: {
                type: Array,
                default: null
            },

            preferredAggregations: {
                type: Array,
                default: null
            },

            initialRepresentations: {
                type: Array,
                default: null
            },

            representationMode: {
                type: Object,
                default: null
            },

            availableDevices: {
                type: Array,
                default: null
            },

            selectedDevices: {
                type: [Array, Object],
                default: null
            },

            properties: {
                type: Object,
                default: null
            },

            enabled: false,

            allowReordering: true,

            filterItemRemoved: {
                type: Object,
                default: null
            },

            dataItemsComponents: {
                type: Array,
                default: null
            },
        },
        mounted: function () {

            let self = this;
            //Load devices list
            Devices.getAvailable(0, 0, true)
                .then((devices) => {
                    self.devices = devices;
                });
            //Load reusable queries
            this.$dynamicElements.list("queries", "visibleToLevels")
                .then(queries => {
                    queries.map(queryName => {
                        self.reusableQueries.push({show: queryName, id: "query~|~" + queryName})
                    })
                });

            this.previousDataItemsLength = this.dataItems.length;
        },
        watch: {
            //Automatically add representations to newly selected items
            dataItems() {
                if(this.previousDataItemsLength >= 0) {
                    if (this.dataItems.length > 0 && this.dataItems.length > this.previousDataItemsLength) {
                        for(let i = this.previousDataItemsLength ; i < this.dataItems.length ; i++) {
                            //Check filters compatibility for new added data
                            if (!Array.isUseful(this.dataItems[i].representations))
                                this.addRepresentation(i);
                        }
                    }
                }
                this.previousDataItemsLength = this.dataItems.length;
                this.checkFilterCompatibility();
            },

            filterItems() {
                this.checkFilterCompatibility();
            },
        },
        computed: {
            datasetNameLb() {
                return this.$gettext("Dataset name: ")
            },
            selectLb() {
                return this.$gettext("Select:")
            },
            aggregationWindowUnits() {
                return [
                    {value: "full", text: this.$gettext("Full time window")},
                    {value: "auto", text: this.$gettext("Auto")},
                    {value: "s", text: this.$gettext("seconds")},
                    {value: "m", text: this.$gettext("minutes")},
                    {value: "h", text: this.$gettext("hours")},
                    {value: "d", text: this.$gettext("days")},
                ]
            },
            options() {
                if (Object.isUseful(this.representationMode))
                    return this.representationMode;
                else return {}
            },
            selectedDevs() {
                if (!this.options.multipleDevicesSelection && Array.isArray(this.selectedDevices)) {
                    if (Array.isUseful(this.selectedDevices))
                        return this.selectedDevices[0];
                    else return null;
                }
                return this.selectedDevices;
            },
            availableFilterItems() {
                if (!this.filterItems)
                    return [];
                let returning = [];
                for (let filterItemIndex = 0; filterItemIndex < this.filterItems.length; filterItemIndex++) {
                    for (let filterIndex = 0; filterIndex < this.filterItems[filterItemIndex].filters.length; filterIndex++) {
                        let filter = this.filterItems[filterItemIndex].filters[filterIndex];
                        if (!Object.isDefined(filter.parametric)) {
                            filter["parametric"] = false
                        }
                        returning.push({id: filter.filterId, show: filter.name, parametric: filter.parametric,
                            type: this.filterItems[filterItemIndex].type, index: this.filterItems[filterItemIndex].indexDisplayName,
                            name: this.filterItems[filterItemIndex].name, root: this.filterItems[filterItemIndex].root})
                    }
                }
                return returning;
            },
            availableParametricFilterItems() {
                return this.availableFilterItems.filter(filter => filter.parametric)
            },
            availableCrossAggregationItems() {
                let self = this;
                return includeRecursion => {
                    if (!self.dataItems)
                        return [];
                    let returning = [];
                    DataApis.forEachRepresentation(this.dataItems, function (representation) {
                        returning.push({id: representation.id, show: representation.name});
                        if (includeRecursion && Array.isUseful(representation.recursions)) {
                            representation.recursions.forEach(r => {
                                returning.push({
                                    id: r.key,
                                    show: r.label ? r.label : r.name
                                })
                            })
                        }
                    });
                    //Add cross aggregations to allow multiple reaggregations
                    for (let aggregation of this.aggregationItems) {
                        //Reaggregations were introduced later. Old elements won't have ids on aggregations, just add them
                        if (!aggregation.id)
                            aggregation.id = "cross_" + this.$utils.timeTag(aggregation.defaultName);
                        returning.push({id: aggregation.id, show: aggregation.name})
                    }
                    return returning;
                }
            },
            /**********Cp/Cpk**********/
            cpItems() {
                let returning = [];
                for (let dataItem of this.dataItems) {
                    if (this.$defines.isNumericItem(dataItem))
                        returning.push({
                            text: DataApis.getVisualizableFieldName(dataItem),
                            value: DataApis.getVisualizableFieldName(dataItem),
                            dataItem: dataItem
                        });
                }
                return returning;
            },
            /*********Reusable queries*********/
            functions() {
                let functions = this.$utils.detach(this.$defines.functions);
                if (Array.isUseful(this.reusableQueries)) {
                    functions.push({show: "User functions", disabled: true});
                    functions = functions.concat(this.reusableQueries);
                }
                return functions;
            },
            clearOutputsBtn() {
                if (this.properties.isActivity) {
                    if (this.outputs.length === 0 || (this.outputs.length === 1 && this.outputs.some(o => o.outputType === 'activity')))
                        return false
                    else
                        return true
                } else
                    return this.outputs.length
            },
            lbParametric() {
                return this.$gettext("Parametric")
            },
            filterModeOptions() {
                return [
                    {value: 0, text: this.$gettext("All conditions must be satisfied")},
                    {value: 1, text: this.$gettext("At least one condition shall be satisfied")}
                ]
            },
            hasSuggestion() {
                return (representation, suggestionProp) => {
                    if (Array.isUseful(representation.suggestions)) {
                        let suggestion = representation.suggestions.find(suggestion => suggestion.target === suggestionProp)
                            return Object.isUseful(suggestion) ? suggestion.message : false
                    }
                    return false;
                }
            },
        },
        methods: {
            timeWindowFilterChanged(timePicker) {
                let newFilter = {
                        from: timePicker.start,
                        to: timePicker.end,
                        type: "timeWindow",
                        timespan: timePicker.timespan
                };
                this.filterItems[timePicker.rowKey].filters[0].conditions[0].value = newFilter
                this.$emit('ItemsChanged');
            },
            replaceVariable(index, confirm = false) {
                if (confirm)
                    this.$root.showDialogBox(this.$gettext("Are you sure you want to replace variable {0}?").format(this.dataItems[index].name), "", "OK", () => {
                        this.showVariablePicker = true;
                        this.variableBeingReplaced = index
                    }, "CANCEL", null);
                else {
                    this.showVariablePicker = true;
                    this.variableBeingReplaced = index;
                }
            },
            replaceFilter(index, confirm = false) {
                if (confirm)
                    this.$root.showDialogBox(this.$gettext("Are you sure you want to replace variable {0}?").format(this.filterItems[index].name), "", "OK", () => {
                        this.showVariablePicker = true;
                        this.filterBeingReplaced = index
                    }, "CANCEL", null);
                else {
                    this.showVariablePicker = true;
                    this.filterBeingReplaced = index;
                }
            },
            selectedVariable(variable) {
                this.showVariablePicker = false;
                if (this.variableBeingReplaced >= 0) {
                    if (variable) {
                        for (let key in variable)
                            this.dataItems[this.variableBeingReplaced][key] = variable[key];
                        this.dataItems[this.variableBeingReplaced].matched = true;
                        this.$emit('ItemsChanged');
                    }
                    this.variableBeingReplaced = -1;
                }
                if (this.filterBeingReplaced >= 0) {
                    if (variable) {
                        for (let key in variable)
                            this.filterItems[this.filterBeingReplaced][key] = variable[key];
                        this.filterItems[this.filterBeingReplaced].matched = true;
                        this.$emit('ItemsChanged');
                    }
                    this.filterBeingReplaced = -1;
                }
            },
            onSelectedRecursiveVariables(representation, variables) {
                representation.recursions = variables
                this.$emit('ItemsChanged');
            },
            onRecursiveVariableChange(representation,variable){
                let find = representation.recursions.find(r=>{return r.key=== variable.key})
                if (find){
                    find.label=variable.label
                }
                this.$emit("ItemsChanged")
            },
            applyFilterToAllVariables(filterId, filter) {
                let notAppliedData = [];
                this.dataItems.forEach(data => {
                   if (data.filtersFromDifferentIndex && data.filtersFromDifferentIndex.some(f => !f.filterFound && f.filter.name === filter.name && f.filter.root === filter.root)) {
                       let dataName = data.displayName? data.displayName : data.name;
                       let indexName = data.indexDisplayName ? data.indexDisplayName : data.index;
                       notAppliedData.push(indexName + '.' + dataName);
                   }
                });
                if (Array.isUseful(notAppliedData))
                    this.$root.showInfoNotification(this.$gettext("Filter was not applied on: {0}, because it is incompatible with variables").format(notAppliedData.join(', ')), true);

                DataApis.forEachRepresentation(this.dataItems, function (representation) {
                    representation.filters.push(filterId);
                    representation.filters = [...new Set(representation.filters)];
                }, filter);
            },
            visualizableIndexName(item) {
                if (item.indexDisplayName)
                    return item.indexDisplayName;
                let tokens = item.index.split("~|~");
                if (tokens.length === 4 && tokens[2])
                    return tokens[2] + " " + tokens[3];
                else return tokens.last();
            },
            showSwitchLabel(item) {
                if (item.show) return this.$gettext("Show");
                else return this.$gettext("Don't show");
            },
            //This is a compatibility function, starting from 2.1 zIndex is assigned to each new representation.
            //Before it was only bulk-assigned the first time a representation was moved.
            //The idea is that, based on the previous behaviour, if even a single item has zIndex unassigned, it was unassigned for all items.
            initializezIndex() {
                let index = 0;
                DataApis.forEachRepresentation(this.dataItems, function (representation) {
                    if (!Object.isUseful(representation.zIndex) || representation.zIndex < 0)
                        representation.zIndex = index++;
                });

                for (let representation of this.aggregationItems) {
                    if (!Object.isUseful(representation.zIndex) || representation.zIndex < 0)
                        representation.zIndex = index++;
                }

                for (let representation of this.functionItems) {
                    if (!Object.isUseful(representation.zIndex) || representation.zIndex < 0)
                        representation.zIndex = index++;
                }
            },
            getNextRepresentationIndex() {
                let highestIndex = -1;
                this.initializezIndex();
                DataApis.forEachRepresentation(this.dataItems, function (representation) {
                    if (representation.zIndex > highestIndex)
                        highestIndex = representation.zIndex;
                });

                for (let representation of this.aggregationItems) {
                    if (representation.zIndex > highestIndex)
                        highestIndex = representation.zIndex;
                }

                for (let representation of this.functionItems) {
                    if (representation.zIndex > highestIndex)
                        highestIndex = representation.zIndex;
                }
                return highestIndex + 1;
            },
            checkItemIndex(item, offset) {
                this.initializezIndex();
                let maxIndex = this.getNextRepresentationIndex() - 1;
                let requestedPosition = item.zIndex + offset;
                return !(requestedPosition < 0 || requestedPosition > maxIndex);
            },
            removeSequenceGaps() {
                let raiseEvent = false;
                let anOtherRound = true;
                while (anOtherRound) {
                    anOtherRound = false;
                    let maxIndex = this.getNextRepresentationIndex() - 1;
                    for (let index = 0; index <= maxIndex; index++) {
                        if (!(this.doForAllRepresentations((representation) => {
                            return representation.zIndex === index
                        }).find(el => el === true))) {
                            anOtherRound = true;
                            raiseEvent = true;
                            this.doForAllRepresentations((representation) => {
                                if (representation.zIndex > index)
                                    representation.zIndex--;
                            });
                        }
                    }
                }
                if (raiseEvent)
                    this.$emit('itemsOrderingChanged');
            },
            changeItemOrder(item, offset) {
                if (this.checkItemIndex(item, offset)) {
                    let oldIndex = item.zIndex;
                    let newIndex = item.zIndex + offset;
                    this.doForAllRepresentations((item) => {
                        if (item.zIndex === newIndex)
                            item.zIndex = oldIndex;
                    });
                    item.zIndex = newIndex;
                    this.$emit('itemsOrderingChanged');
                }
            },
            doForAllRepresentations(operation) {
                let results = [];
                DataApis.forEachRepresentation(this.dataItems, function (representation) {
                    if (operation)
                        results.push(operation(representation));
                });

                for (let representation of this.aggregationItems) {
                    if (operation)
                        results.push(operation(representation));
                }

                for (let representation of this.functionItems) {
                    if (operation)
                        results.push(operation(representation));
                }

                return results;
            },

            /** FUNCTIONS **/
            clearFunctions() {
                this.functionItems.clear();
                this.removeSequenceGaps();
                this.$emit('ItemsChanged');
            },
            functionChanged(itemIndex, selectedFunction) {
                let self = this;
                this.functionItems[itemIndex].type = selectedFunction;
                this.functionItems[itemIndex].parameters.clear();
                if (selectedFunction.startsWith("query~|~")) {
                    this.$set(this.functionItems[itemIndex], "isReusableQuery", true);
                    this.$set(this.functionItems[itemIndex], "deepWatchTrigger", 0);
                    this.$dynamicElements.LoadItem(selectedFunction.replace("query~|~", ""), "queries")
                        .then(queryDescriptor => {
                            debugger
                            let targetBindings = [];
                            let descriptor = JSON.parse(queryDescriptor.descriptor);
                            descriptor.data.map(dataItem => dataItem.representations.map(representation => {
                                representation.show && targetBindings.push({
                                    show: true,
                                    dataSet: representation.name,
                                    target: "",
                                    alias: representation.name
                                })
                            }));
                            descriptor.aggregations.map(aggregationItem => {
                                aggregationItem.show && targetBindings.push({
                                    show: true,
                                    dataSet: aggregationItem.name,
                                    target: "",
                                    alias: aggregationItem.name
                                })
                            });
                            self.$set(self.functionItems[itemIndex], "parameters", [{
                                queryName: selectedFunction.replace("query~|~", ""),
                                descriptor: descriptor,
                                targetBindings: targetBindings
                            }]);
                        });
                }
            },
            deleteFunction(itemIndex) {
                this.functionItems.splice(itemIndex, 1);
                this.removeSequenceGaps();
            },
            addFunction() {
                if (!this.functionItems)
                    this.$set(this, "functionItems", []);
                this.functionItems.push(DataApis.getDefaultFunction(this.getNextRepresentationIndex()));
            },
            functionVisualizationOptionsChanged(itemIndex, options) {
                this.functionItems[itemIndex].visualizationOptions = options;
            },
            moveFunction(itemIndex, offset) {
                this.changeItemOrder(this.functionItems[itemIndex], offset);
            },
            functionParametersChanged(itemIndex, parameterName, parameterValue, isDataItem) {
                let parameterIndex = -1;
                for (let [index, parameter] of this.functionItems[itemIndex].parameters.entries()) {
                    if (parameter.name === parameterName) {
                        parameterIndex = index;
                        break;
                    }
                }
                if (parameterIndex < 0) {
                    this.functionItems[itemIndex].parameters.push({});
                    parameterIndex = this.functionItems[itemIndex].parameters.length - 1;
                }

                this.functionItems[itemIndex].parameters[parameterIndex].name = parameterName;
                if (typeof parameterValue === 'object' && parameterValue.hasOwnProperty("id")) {
                    this.$set(this.functionItems[itemIndex].parameters[parameterIndex], "type", "query");
                } else {
                    if (isDataItem) {
                        this.$set(this.functionItems[itemIndex].parameters[parameterIndex], "type", "dataItem");
                        this.$set(this.functionItems[itemIndex].parameters[parameterIndex], "value", parameterValue.value);
                        this.$set(this.functionItems[itemIndex].parameters[parameterIndex], "dataItem", parameterValue.dataItem);
                        return
                    } else
                        this.$set(this.functionItems[itemIndex].parameters[parameterIndex], "type", "value");
                }
                this.$set(this.functionItems[itemIndex].parameters[parameterIndex], "value", parameterValue);
            },
            getFunctionParameter(itemIndex, parameterName) {
                for (let parameter of this.functionItems[itemIndex].parameters) {
                    if (parameter.name === parameterName) {
                        //Queries might have been updated, find the correct query based on id or return empty
                        //in case query have been deleted
                        if (parameter.type === "query") {
                            for (let item of this.availableCrossAggregationItems(false))
                                if (item.id === parameter.value.id)
                                    return item;
                        } else return parameter.value;
                    }
                }
                return null;
            },
            reusableQueryFilterChanged(itemIndex, selectedFilters) {
                this.setObjectProperty(this.functionItems[itemIndex], "filters", selectedFilters);
            },

            /** CROSS AGGREGATIONS**/
            getAvailableCrossAggregationsForItem(item) {
                if (item.type === this.$defines.allAggregations.inner.id || item.type === this.$defines.allAggregations.outer.id) {
                    return this.$defines.crossAggregations;
                } else {
                    for (let i = 0; i < this.aggregationItems.length; i++)
                        if (this.aggregationItems[i].type === this.$defines.allAggregations.inner.id || this.aggregationItems[i].type === this.$defines.allAggregations.outer.id)
                            return this.$defines.crossAggregationsNoGrouping;

                    return this.$defines.crossAggregations;
                }
            },
            clearCrossAggregations() {
                this.aggregationItems.clear();
                this.removeSequenceGaps();
                this.$emit('ItemsChanged');
            },
            addCrossAggregation() {
                if (!this.aggregationItems)
                    this.$set(this, "aggregationItems", []);
                this.aggregationItems.push(DataApis.getDefaultCrossAggregation(this.aggregationItems, this.getNextRepresentationIndex()));
            },
            deleteCrossAggregation(itemIndex) {
                this.aggregationItems.splice(itemIndex, 1);
                this.removeSequenceGaps();
            },
            crossAggregationBucketsChanged(itemIndex, selectedFields) {
                //Check if aggregation windows are compatible
                if (selectedFields.length > 1) {
                    //Get aggregation window of first item, the first one will impose the window for all successive items
                    let referenceWindow = DataApis.calculateAggregationWindow(this.getRepresentationById(selectedFields[0].id));
                    for (let sourceIndex = 0; sourceIndex < selectedFields.length; sourceIndex++) {
                        let itemWindow = DataApis.calculateAggregationWindow(this.getRepresentationById(selectedFields[sourceIndex].id));
                        if (itemWindow !== referenceWindow) {
                            this.$root.showDialogBox(this.$gettext("Last selected data item has an aggregation window ({0}) incompatible with the other items ({1}). Please modify aggregation window or select a different cross-aggregation source.").format(itemWindow === 0 ? "FULL WINDOW" : (itemWindow + " seconds"), referenceWindow === 0 ? "FULL WINDOW" : (referenceWindow + " seconds")), null, "OK", null, "", null);
                            selectedFields.splice(sourceIndex, 1);
                        }
                    }
                }
                this.aggregationItems[itemIndex].buckets = selectedFields;
            },
            checkRepresentationDataType(representationId, dataType) {
                if (representationId.startsWith("cross_")) {
                    for (let bucket of this.getRepresentationById(representationId).buckets)
                        if (!this.checkRepresentationDataType(bucket, dataType))
                            return false;
                    return true;
                } else if (this.getRepresentationById(representationId).dataType === dataType)
                    return true;
            },
            getCrossAggregationItemsByDataType(type) {
                let returning = [];
                let aggregationItems = this.availableCrossAggregationItems(false);
                for (let item of aggregationItems) {
                    if (this.checkRepresentationDataType(item.id, type))
                        returning.push(item);
                }
                return returning;
            },
            getAvailableBucketedCrossAggregationBuckets(itemIndex) {
                //Get all available items
                let availableBuckets = this.$utils.detach(this.availableCrossAggregationItems(false));
                if (Array.isUseful(this.aggregationItems[itemIndex].buckets)) {
                    //If any item is already used remove it from list
                    availableBuckets = availableBuckets.filter((buck) => !this.aggregationItems[itemIndex].buckets.includes(buck.id));
                    //After first element is selected, all other can only be terms aggregations
                    for (let i = 0; i < availableBuckets.length; i++) {
                        if (!this.$defines.isBucketingAggregation(this.getRepresentationById(availableBuckets[i].id).type)) {
                            availableBuckets.removeAt(i);
                            i -= 1;
                        }
                    }
                } else {
                    //If this is the first item offer only non-terms aggregations as data to be visualized
                    for (let i = 0; i < availableBuckets.length; i++) {
                        if (this.$defines.isBucketingAggregation(this.getRepresentationById(availableBuckets[i].id).type)) {
                            availableBuckets.removeAt(i);
                            i -= 1;
                        }
                    }
                }
                return availableBuckets;
            },
            bucketedCrossAggregationBucketAdded(itemIndex, selectedBucket) {
                if (selectedBucket) {
                    if (!Array.isUseful(this.aggregationItems[itemIndex].buckets))
                        this.aggregationItems[itemIndex].buckets = [];
                    this.aggregationItems[itemIndex].buckets.push(selectedBucket)
                }
            },
            getRepresentationById(representationID) {
                for (let itemIndex = 0; itemIndex < this.dataItems.length; itemIndex++)
                    for (let representationIndex = 0; representationIndex < this.dataItems[itemIndex].representations.length; representationIndex++)
                        if (this.dataItems[itemIndex].representations[representationIndex].id === representationID)
                            return this.dataItems[itemIndex].representations[representationIndex];

                for (let aggregation of this.aggregationItems)
                    if (aggregation.id === representationID)
                        return aggregation;
                return {};
            },
            crossAggregationChanged(itemIndex, selectedCrossAggregation) {
                this.aggregationItems[itemIndex].type = selectedCrossAggregation;
                //In case of aggregation, add the aggregation window field. It is expressed in points, the query window
                // will be divided by this number to obtain the aggregation window size in seconds. Value 0 causes
                //query api to auto-calculate it as a fixed fraction of query window size
                //this.dataItems[itemIndex].representations[representationIndex]["aggregationWindow"] = 0;

                //Update default name on aggregation changed
                //this.dataItems[itemIndex].representations[representationIndex].defaultName = DataApis.getDefaultDataRepresentationName(this.dataItems[itemIndex], this.dataItems[itemIndex].representations[representationIndex]);
            },
            crossAggregationVisualizationOptionsChanged(itemIndex, options) {
                this.aggregationItems[itemIndex].visualizationOptions = options;
            },
            moveCrossAggregation(itemIndex, offset) {
                this.changeItemOrder(this.aggregationItems[itemIndex], offset);
            },

            /** FILTERS**/
            deleteFilter(itemIndex, filterIndex) {
                this.filterItems[itemIndex].filters.removeAt(filterIndex);

                //Remove filters from dataItems available and forbidden arrays
                // if (!Array.isUseful(this.filterItems[itemIndex].filters)) {
                //     this.removeFilterFromDataItems(this.filterItems[itemIndex]);
                // }
            },
            addFilterCondition(itemIndex, filterIndex) {
                this.filterItems[itemIndex].filters[filterIndex].conditions.push(DataApis.getDefaultFilterCondition(this.filterItems[itemIndex]));
            },
            deleteFilterCondition(itemIndex, filterIndex, conditionIndex) {
                this.filterItems[itemIndex].filters[filterIndex].conditions.splice(conditionIndex, 1);
            },
            addFilter(itemIndex) {
                if (!Object.isDefined(this.filterItems[itemIndex].filters))
                    this.$set(this.filterItems[itemIndex], "filters", []);

                this.filterItems[itemIndex].filters.push(DataApis.getDefaultFilterRepresentation(this.filterItems[itemIndex]));
            },
            clearFilters() {
                // this.filterItems.forEach(filter => {
                //     this.removeFilterFromDataItems(filter);
                // })
                this.filterItems.clear();
                this.$emit('ItemsChanged');
            },

            /** DATA REPRESENTATIONS**/
            clearData() {
                this.dataItems.clear();
                this.dataItemsComponents.clear();
                this.removeSequenceGaps();
                this.$emit('ItemsChanged');
            },
            showAggregationBucket(item, representation) {
                return (representation.type !== this.$defines.allAggregations.raw.id &&
                    representation.type !== this.$defines.allAggregations.intervals.id &&
                    representation.type !== this.$defines.allAggregations.changes.id &&
                    representation.type !== this.$defines.allAggregations.last.id &&
                    representation.type !== this.$defines.allAggregations.first.id &&
                    representation.type !== this.$defines.allAggregations.ttlog.id);
            },
            addRepresentation(itemIndex) {
                if (!this.dataItems[itemIndex].representations)
                    this.$set(this.dataItems[itemIndex], "representations", []);
                this.dataItems[itemIndex].representations.push(DataApis.getDefaultDataRepresentation(this.dataItems[itemIndex], this.getNextRepresentationIndex(),
                    this.preferredAggregations, this.properties.type));
                if (Array.isUseful(this.visualizationTargets))
                    for (let target of this.visualizationTargets)
                        if (target.default)
                            this.dataItems[itemIndex].representations.last().target = target.id
            },
            deleteRepresentation(itemIndex, representationIndex) {
                this.dataItems[itemIndex].representations.splice(representationIndex, 1);
                this.removeSequenceGaps();
            },
            deleteDataItem(itemIndex) {
                this.dataItems.removeAt(itemIndex);
                if (Array.isUseful(this.dataItemsComponents)) {
                    this.dataItemsComponents.removeAt(itemIndex);
                }
                this.removeSequenceGaps();
                this.$emit('ItemsChanged');
            },
            deleteFilterItem(itemIndex) {
                this.filterItems.removeAt(itemIndex);
                this.$emit('ItemsChanged');
            },
            aggregationChanged(itemIndex, representationIndex, selectedAggregation) {

                this.dataItems[itemIndex].representations[representationIndex].type = selectedAggregation;

                //Update default name on aggregation changed
                this.dataItems[itemIndex].representations[representationIndex].defaultName = DataApis.getDefaultDataRepresentationName(this.dataItems[itemIndex], this.dataItems[itemIndex].representations[representationIndex]);
            },
            setFullAggregationWindow(item, value) {
                if (value)
                    item["aggregationWindow"] = 0; //0 seconds means match window
                else
                    item["aggregationWindow"] = 60; //Set one minute as default
            },
            setAggregationWindow(item, value) {
                item["aggregationWindow"] = value;
            },
            getAggregationWindowUnit(item) {
                if (item.type === "cumusum" && item.aggregationWindow === 0) {
                    this.setAggregationWindowUnits(item, "h");
                    return "h"
                }
                if (Object.isUseful(item.aggregationWindowUnit))
                    return item.aggregationWindowUnit;
                if (Object.isUseful(item.aggregationWindow) && item.aggregationWindow === 0)
                    return "full";
                else return "s";
            },
            setAggregationWindowUnits(item, unit) {
                if ((item["aggregationWindowUnit"] === "full" || item["aggregationWindowUnit"] === "auto") && !(unit === "full" || unit === "auto")) {
                    if (unit === "s")
                        this.setAggregationWindow(item, 60);
                    else this.setAggregationWindow(item, 1);
                } else if (unit === "full")
                    this.setAggregationWindow(item, 0);
                else if (unit === "auto")
                    this.setAggregationWindow(item, -1);
                this.$set(item, "aggregationWindowUnit", unit);
            },
            setAggregationProperty(itemIndex, field, value) {
                this.setObjectProperty(this.aggregationItems[itemIndex], field, value);
            },
            setObjectProperty(targetObject, field, value) {
                if (!targetObject.hasOwnProperty(field))
                    this.$set(targetObject, field, "");
                targetObject[field] = value;
            },
            setRepresentationProperty(itemIndex, representationIndex, field, value) {
                this.setObjectProperty(this.dataItems[itemIndex].representations[representationIndex], field, value);
            },
            filterChanged(itemIndex, representationIndex, selectedFilters) {
                //TODO check mapping and aliases
                this.setRepresentationProperty(itemIndex, representationIndex, "filters", selectedFilters);
            },
            visualizationOptionsChanged(itemIndex, representationIndex, options) {
                this.setRepresentationProperty(itemIndex, representationIndex, "visualizationOptions", options);
            },
            moveRepresentation(itemIndex, representationIndex, offset) {
                this.changeItemOrder(this.dataItems[itemIndex].representations[representationIndex], offset);
            },
            getComparersForType(item, parametric) {
                return this.$defines.getComparersForDataItem(item, parametric);
            },
            getAggregationsForType(item) {
                return this.$defines.getAggregationsForDataItem(item);
            },
            getVisualizationsOptions(item) {
                let wellKnownVisualizationOption = this.$defines.getVisualizationsOptionsForDataItem(item);
                let arrayMerged = [];
                if (Array.isUseful(wellKnownVisualizationOption))
                    return arrayMerged = [...this.visualizationOptions, ...wellKnownVisualizationOption];
                return this.visualizationOptions;
            },

            /** DEVICES SELECTION LOGIC**/
            selectedDevicesChanged(devs) {
                this.selectedDevices = devs;
            },

            devicesSelectionComplete() {
                if (!Array.isArray(this.selectedDevices))
                    if (Object.isUseful(this.selectedDevices))
                        this.$emit('selectedDevicesChanged', [this.selectedDevices]);
                    else this.$emit('selectedDevicesChanged', []);
                else
                    this.$emit('selectedDevicesChanged', this.selectedDevices);
            },
            clearRules() {
                this.rules.clear();
            },
            clearOutputs() {
                if (this.properties.isActivity) {
                    let activity = this.outputs.find(o => o.outputType === 'activity');
                    this.outputs.length = 0
                    this.outputs.push(activity);
                } else
                    this.outputs.clear();
            },
            parametricChange(parametricValue, filter) {
                if (parametricValue)
                    this.$root.showDialogBox(this.$gettext("Parametric filter only supports a single condition. Are you sure that you want to delete current conditions?"), null, "Yes", () => {
                        this.deleteAllConditions(filter)
                    }, "Cancel", null);
            },
            deleteAllConditions(filter) {
                filter.conditions = [];
                filter.conditions.push({operator: '=', value: null})
            },
            disableAggregationWindowUnitItem(item) {
                return item.value === 'full'
            },
            availableFilterItemsFiltered(dataItem) {
                return this.availableFilterItems.filter(item => item.index === dataItem.indexDisplayName ||
                    (item.index !== dataItem.indexDisplayName && dataItem.filtersFromDifferentIndex &&
                        dataItem.filtersFromDifferentIndex.some(f => f.filterFound && f.filter.name === item.name && f.filter.root === item.root)))
            },
            checkFilterCompatibility() {
                clearInterval(this.checkfiltersDebouncer);
                this.checkfiltersDebouncer = setInterval((function(self) {
                    return async function () {
                        clearInterval(self.checkfiltersDebouncer);
                        self.$datalayer.checkFilterCompatibility(self.dataItems, self.filterItems);
                    }
                })(this), 1000);
            }
        }
    }
</script>

<style scoped>
    .data-expansion-panel >>> .v-expansion-panel__header {
        height: 120px;
    }

</style>
