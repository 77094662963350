<template>
    <v-menu
            v-model="menuOpened"
            :close-on-content-click="false"
            lazy
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            full-width
            max-width="290px"
            min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field
                class="osk"
                :value="(new Date(cdate)).format('MMM,dd')"
                :label="$i18n.CommonAttributes()['click to set']"
                prepend-icon="fas fa-calendar-alt"
                readonly
                v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
                v-if="menuOpened"
                v-model="cdate"
                :header-date-format="function(day) { return (new Date(day)).format('MMMM') }"
                :title-date-format="function(day) { return (new Date(day)).format('MMMM, dd') }"
                :weekday-format="function(day) { return '' }"
                :year-format="function(day) { return '' }"
                min="2019-01-01"
                max="2019-12-31"
                full-width
                @click:date="menuOpened = false, valueChanged()"
        ></v-date-picker>
    </v-menu>
</template>

<script>
    export default {
        name: "PopUpDayOfYearPicker",
        data () {
            return {
                date: "",
                menuOpened: false,
            }
        },
        props: {
            value:  [Number,String,Date],
            type: {
                type: String,
                required: true,
                validator: function (value) {
                    // The value must match one of these strings
                    return ['date', 'dayOfYear', 'monthDashDay'].indexOf(value) !== -1
                }
            }
        },
        computed: {
            cdate: {
                get () {
                    if(!this.date) {
                        let returning = new Date("2019-01-01");
                        if(Object.isUseful(this.value)) {
                            if (typeof this.value === 'number' && this.type === "dayOfYear")  //Value expressed as day of year
                                returning.setDayOfYear(this.value);
                            else if (typeof this.value === 'string'  && (this.type === "monthDashDay" || this.type === "date"))
                                returning = new Date(this.value);
                            else if (this.type === "date")
                                returning = this.value;
                        }
                        returning.setFullYear(2019);    //We stay fixed on a reference year. 2019 was leap
                        this.date = returning.toISOString();
                    }
                    return this.date;
                },
                set (val) {
                    this.date = val;
                }
            }
        },
        methods: {
            valueChanged() {
                //return time in the same format (Date or MinuteOfDay) as the input
                let toBeEmitted = null;
                if(this.type === 'dayOfYear')
                    toBeEmitted = (new Date(this.date)).getDayOfYear();
                else if(this.type === 'monthDashDay')
                    toBeEmitted = this.date;
                else toBeEmitted = (new Date(this.date));

                this.$emit('input', toBeEmitted);
                this.$emit('change', toBeEmitted);
            }
        }
    }
</script>

<style scoped>

</style>