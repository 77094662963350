<template>
    <v-dialog v-model="showDialog" width="720px" height="480px" min-height="480px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-workorder-dialog">
        <v-card id="antares-workorder-dialog-card">
            <v-toolbar card dense>
                <v-toolbar-title class="display-1 text-xs-center">
                    {{title}}
                </v-toolbar-title>
            </v-toolbar>
            <v-layout pa-3 column>
                <v-spacer></v-spacer>
                <v-layout row>
                    <v-flex xs-8>
                        <v-layout v-if="shiftValid" column>
                            <label v-if="scheduled" class="subheading mt-1" v-translate>This shift was opened by scheduler</label>
                            <label class="subheading mt-1">{{currentShift}}</label>
                            <label class="subheading mt-1">{{progress}}</label>
                            <label class="subheading mt-1">{{nextPreviousShift}}</label>
                        </v-layout>
                    </v-flex>
                    <v-flex xs-4>
                        <v-btn v-if="shiftRunning" color="error" @click="endShift()">
                            <av-icon light left>fas fa-trash</av-icon>
                            <translate>End shift</translate>
                        </v-btn>
                    </v-flex>
                </v-layout>
                <v-spacer />
                <v-select ma-2 single-line :prefix="selectShiftPlaceholder + ':  '" :items="shiftData" :value="selectedShift" @change="selectShift($event)"/>
                <template v-for="(shiftRole,index) in shiftRoles">
                    <v-select ma-2 single-line :prefix="$i18n.CommonAttributes()['select'] + ' ' + shiftRole + ':  '" :items="shiftManagers" v-model="newManagers[index]"/>
                </template>
                <v-card-actions id="antares-annotation-dialog-card-actions">
                    <v-spacer></v-spacer>
                    <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat" @click="callBack(true)">
                        <translate>
                            OK
                        </translate>
                    </v-btn>
                    <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat" @click="callBack(false)">
                        <translate>
                            Cancel
                        </translate>
                    </v-btn>
                </v-card-actions>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>

    import Shifts from "@/api/shifts"

    export default {
        name: 'MessageBox',
        props: {},
        data() {
            return {
                shiftRoles: [],
                shiftHours: [],
                shiftManagers: [],
                newManagers: [],
                scheduled: false,
                currentShift: "",
                nextPreviousShift: "",
                shift: null,
                shifts: [],
                progress: 0,
                showDialog: false,
                user: "",
                error: "",
                callerInstance: null,
                changeCallBack: null,
                shiftRunning: false,
                root: null
            }
        },
        computed: {
            shiftValid() {
                return (this.shift && this.shift.valid);
            },
            title() {
                if(this.shift && this.shift.valid)
                    return this.$gettext("Change shift");
                else return this.$gettext("Open new shift");
            },
            shiftData: {
                get() {
                    this.shifts.clear();
                    let availableShifts = Shifts.getShiftsInfo();
                    if(Array.isUseful(availableShifts))
                        for (let i = 0; i < availableShifts.length; i++) {
                            this.shifts.push( { text: this.$gettext("From {0} to {1}").format(availableShifts[i].from, availableShifts[i].to),
                                value: {
                                    from: availableShifts[i].from,
                                    to: availableShifts[i].to,
                                    id: "{0} - {1}".format(availableShifts[i].from, availableShifts[i].to),
                                    index: i
                                }
                            });
                        }
                    return this.shifts;
                }
            },
            selectShiftPlaceholder() { return this.$gettext("Select shift") },
            selectedShift() {
                if(this.shift) {
                    for (let shift of this.shifts)
                        if (this.shift.id === shift.value.id)
                            return shift.value;
                }
                else return {};
            }
        },
        methods: {
            setVueInstance(instance) {
                this.root = instance;
            },
            setCaller(caller) {
                this.callerInstance = caller;
            },
            selectShift(shift) {
                if(shift)
                    this.shift = this.shifts[shift.index].value;
            },
            Show(user, callBack) {
                this.user = user;
                let self = this;

                let settings = this.$settings.getLineSettings();
                this.shiftHours = settings.shiftHours || [];
                this.shiftRoles = settings.shiftRoles || [];
                this.shiftManagers = settings.shiftManagers || [];
                this.shiftManagers.unshift("");

                //Load currently opened shift descriptor
                Shifts.getCurrentShift()
                    .then(currentShift => {
                        self.scheduled = currentShift.scheduled;
                        self.shift = currentShift;
                        if(currentShift)
                            self.shiftRunning = currentShift.valid;
                        self.newManagers = currentShift.variables.map((item)=>{ return item.value });
                    })
                    .catch(err => {
                        debugger
                    })
                    .finally(() => {
                        if (!self.shift)
                            return;

                        self.currentShift = self.$gettext("Current shift from {0} to {1}").format(self.shift.from, self.shift.to);
                        self.progress = "";
                        if (self.shift.progress > 95) {
                            self.progress = self.$gettext("Shift is about to end");
                            //self.nextPreviousShift = self.$gettext("Next shift from {0} to {1}").format(self.shift.nextFrom, self.shift.nextTo);
                        } else if(self.shift.progress < 0) {
                            self.progress = "";
                        } else if (self.shift.progress < 5) {
                            self.progress = self.$gettext("Shift has just started");
                            //self.nextPreviousShift = self.$gettext("Previous shift from {0} to {1}").format(self.shift.previousFrom, self.shift.previousTo);
                        } else {
                            self.progress = self.$gettext("Shift progress: {0}%").format(Math.round(self.shift.progress));
                        }
                    });

                this.changeCallBack = callBack;
                this.showDialog = true;
            },
            endShift() {
                let self = this;
                //Manage of audit trails
                let operator = self.user;
                let actionId = '';
                let previousVal = '';
                let nextVal = [self.shift.from, self.shift.to];
                let action = self.$audits.items().shiftClosed;
                let confirmShiftEnd = function() {
                    self.$settings.delete(self.$settings.ShiftChange, self.$settings.Current)
                        .then(result => {
                            if (self.changeCallBack)
                                self.changeCallBack("NoShift");
                            self.showDialog = false;
                            self.$audits.save(operator, action, previousVal, nextVal, actionId)
                                .catch(err => {
                                    debugger
                                    self.root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                                });
                        })
                        .catch(err => {
                            this.error = this.$gettext("An error occurred while saving settings to DB");
                        });
                };
                this.root.showDialogBox(this.$gettext("Are you sure you want to close current shift?"), null, this.$gettext("Yes"), confirmShiftEnd, this.$gettext("No"), null);
            },
            callBack(result) {
                this.error = "";
                if (result) {
                    // if (this.shiftValid && this.shift.progress < 95 && this.shift.progress > 5)
                    //     this.root.showDialogBox(this.$gettext("There is a shift already running. Are you sure you want to change it?"), null, this.$gettext("Yes"), this.confirmChange, this.$gettext("No"), null);
                    // else this.confirmChange();
                    this.confirmChange();

                } else {
                    if (this.changeCallBack)
                        this.changeCallBack(null);
                    this.showDialog = false;
                }
            },
            confirmChange() {
                let self = this;

                //Manage of audit trails
                let operator = self.user;
                let actionId = '';
                let previousVal = ''; // [self.shift.previousFrom, self.shift.previousTo];
                let nextVal = [self.shift.from, self.shift.to];
                let action = self.$audits.items().shiftOpened;

                let result = {
                    id: this.shift.id,
                    from: this.shift.from,
                    to: this.shift.to,
                    source: "Operator",
                    variables: []
                };

                for (let i = 0; i < this.shiftRoles.length; i++) {
                    result.variables.push({key: this.shiftRoles[i], value: this.newManagers[i]});
                }

                this.$settings.save(result, this.$settings.ShiftChange, this.$settings.Current)
                    .then(() => {
                        if (self.changeCallBack)
                            self.changeCallBack(this.newManagers);
                        self.showDialog = false;

                        self.$audits.save(operator, action, previousVal, nextVal, actionId)
                            .catch(err => {
                                debugger
                                self.root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .catch(err => {
                        self.error = this.$gettext("An error occurred while saving settings to DB");
                    });
            }
        }
    }

</script>
