<template>
    <av-page toolbar-activation-on="true"  ref="page">
        <template slot="toolbarFixedItems">
                <av-text-field class="ma-4" style="width:100%; max-width: 400px;" :label="$gettext('Filter by name')"
                               v-model="filterByName"/>
                <av-select class="ma-4" style="width:100%; max-width: 300px"
                           :label="$gettext('Filter by activity tag')" v-model="tagSelected"
                           :items="tagFilters"/>
                <av-select class="ma-4" style="width:100%; max-width: 300px" :label="$gettext('Filter by asset')"
                           v-model="assetSelected" :items="assets"/>
                <v-layout v-if="calendarView" style="min-width: 150px; line-height: 34px">
                    <v-icon large color="red">stop</v-icon>
                    Elapsed Activities
                </v-layout>
                <span class="subheading" style="margin-left: 40px">{{ filterMessage }}</span>
        </template>
        <template slot="toolbarDynamicItems">
                <v-select v-if="exportProfiles.length > 1 && calendarView" :items="exportProfiles" item-text="Name" :prefix="$gettext('Export profile:')"
                          style="max-width: 230px" class="mr-3 mt-1" return-object v-model="exportProfileObj.profileName" @change="selectExportProfile($event)" />
                <v-btn v-if="$grants.get().activities.canCreateActivities"  @click="newActivity"
                       color="info">
                    <av-icon :id="'newActivityBtn'" light small left>fa-plus</av-icon>
                    <translate>New Activity</translate>
                </v-btn>
                <v-btn  color="info" v-if="!calendarView"
                       @click="calendarView = !calendarView">
                    <av-icon :id="'calendarView'" light small left>fa-calendar</av-icon>
                    {{ tabsTitles[0] }}
                </v-btn>
                <v-btn  color="info" v-if="calendarView"
                       @click="calendarView = !calendarView">
                    <av-icon :id="'calendarView'" light small left>fa-table</av-icon>
                    {{ tabsTitles[1] }}
                </v-btn>
                <v-menu offset-y left >
                    <av-button slot="activator" :text="$gettext('Export')" buttonIcon="fa-arrow-down" color="info" iconColor="light"/>
                    <v-list>
                        <v-list-tile @click="exportReport('xlsx')">
                            <v-list-tile-action>
                                <av-icon>fas fa-file-pdf</av-icon>
                            </v-list-tile-action>
                            <v-list-tile-title class="subheading" v-translate>Export excel</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile @click="exportReport('pdf')">
                            <v-list-tile-action>
                                <av-icon>fas fa-file-pdf</av-icon>
                            </v-list-tile-action>
                            <v-list-tile-title class="subheading" v-translate>Export pdf</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile v-if="calendarView">
                            <av-button :text="$gettext('Export options')" @click="showExportOptionsDialog = true"
                                       color="info" buttonIcon="fas fa-cog" iconColor="light" />
                        </v-list-tile>
                    </v-list>
                </v-menu>
                <av-button v-if="backPath !== ''" class="mr-4" style="margin-left: -3px" :text="$gettext('BACK')"
                           color="error" buttonIcon="fas fa-arrow-left"
                           iconColor="light" @click="goBack"/>

        </template>
        <template slot="pageContent">
            <template v-if="!calendarView" >
                <v-data-table
                    :headers="activityRulesHeaders"
                    :items="filteredActivities"
                    class="elevation-0 subheading fixed-header v-table__overflow"
                    ma-0 pa-0
                    item-key="name"
                    :expand="true"
                    hide-actions>
                    <template v-slot:items="props">
                        <tr>
                            <td class="text-xs-left subheading">
                                {{ activityName(props.item) }}
                            </td>
                            <td>
                            <span  @click="props.expanded = !props.expanded" class="ml-2" v-if="filteredPendingActions(activityName(props.item)).length>0">
                                <v-chip color="orange" text-color="white">
                                    <v-avatar class="orange darken-4">{{ filteredPendingActions(activityName(props.item)).length }}</v-avatar>
                                    <translate>Required Actions</translate>
                                </v-chip>
                                <v-icon style="position: relative; top:5px">{{!props.expanded ?'fal fa-chevron-down':'fal fa-chevron-up'}}</v-icon>
                            </span>
                            </td>
                            <td class="text-xs-left subheading pl-0 pr-0">
                                <av-progress :invertColors="true" :value="props.item[props.item.Name].currentValue" :target="props.item[props.item.Name].targetValue" :floor="props.item.activityType==='timeBased'"  v-if="props.item[props.item.Name].currentValue"></av-progress>
                                <template v-else>
                                    {{props.item[props.item.Name].msg}}
                                </template>
                            </td>
                            <td class="text-xs-left subheading">{{ props.item.LastTriggerTime }}</td>
                            <td class="text-xs-left subheading">
                                <label :style="nextTriggerLabelOpacity(props.item)">{{ formatDate(props.item.NextTriggerTime) }}</label>
                                <v-tooltip bottom left v-if="nextTriggerIconTooltip(props.item)">
                                    <av-icon slot="activator" color="warning" :style="nextTriggerIconOpacity(props.item)" small class="ml-4">fas fa-exclamation-triangle</av-icon>
                                    <span>{{nextTriggerIconTooltip(props.item)}}</span>
                                </v-tooltip>
                            </td>
                            <td class="text-xs-left subheading">{{ props.item.Duration ? props.item.Duration : "N/A" }}</td>
                            <td class="text-xs-left subheading">{{ props.item.ActivityTargets.asset }}</td>
                            <td class="text-xs-left subheading">
                                <span :style="'color: '+(props.item.Status==='disabled'?'var(--av-red)':'var(--av-green)')">{{props.item.Status==='disabled'?$gettext('Disabled'):$gettext('Active')}}</span>
                            </td>
                            <td class="text-xs-left">
                                <v-menu offset-y transition="slide-y-transition" left >
                                    <v-btn slot="activator" flat icon small>
                                        <av-icon :id="'ruleMenuOpen'" small >fa-bars</av-icon>
                                    </v-btn>
                                    <v-list dense>
                                        <v-list-tile :disabled="!$grants.enabledForEditable(props.item.visualization.properties.editableToLevels)" key="edit" @click="editRule(props.item.visualization)">
                                            <v-list-tile-content >
                                            <span style="cursor:pointer">
                                                <av-icon color="info" small left>fas fa-pen</av-icon>
                                                EDIT
                                            </span>
                                            </v-list-tile-content>
                                        </v-list-tile>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:expand="props">
                        <v-card class="elevation-0" style="border: 1px solid lightgrey !important; border-bottom: none">
                           <v-card-text>
                               <v-data-table
                                   :headers="pendingActionsHeaders"
                                   :items="filteredPendingActions(activityName(props.item))"
                                   class="elevation-0 subheading fixed-header v-table__overflow"
                                   ma-0 pa-0
                                   item-key="name">
                                   <template v-slot:items="props">
                                       <tr>
                                           <td class="text-xs-left subheading" style="width: 50px">
                                           <v-btn  @click="openAction(props.item)" flat icon small>
                                               <av-icon small color="info" >fas fa-edit</av-icon>
                                           </v-btn>
                                           </td>
                                           <td class="text-xs-left subheading">
                                               {{ props.item.Action.name === 'system-DefaultForm' ? props.item.Name : props.item.Action.name }}
                                           </td>
                                           <td class="text-xs-left subheading">{{ formatDate(props.item.LastTriggerTime) }}</td>

                                       </tr>
                                   </template>
                               </v-data-table>
                           </v-card-text>
                        </v-card>

                    </template>
                </v-data-table>
            </template>
            <template v-if="calendarView" >
                <v-layout row align-center justify-center>
                    <v-flex sm2 xs12 class="text-sm-left text-xs-center">
                        <v-btn small @click="$refs.calendar.prev()">
                            <av-icon dark left>keyboard_arrow_left</av-icon>
                            {{$gettext('Previous')}}
                        </v-btn>
                    </v-flex>
                    <v-flex sm2 xs12 class="text-xs-center mt-2">
                        <v-select v-model="type" :items="typeOptions"></v-select>
                    </v-flex>
                    <v-flex sm2 xs12 class="text-sm-right text-xs-center">
                        <v-btn small @click="$refs.calendar.next()">
                            {{$gettext('Next')}}
                            <av-icon right dark>keyboard_arrow_right</av-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
                <v-calendar
                    :type="type"
                    v-model="today"
                    ref="calendar"
                    end="end"
                    :now="today"
                    :value="today"
                    color="primary"
                    @change = "changeDate"
                    :interval-format="intervalFormat"
                    style="height:calc(100% - 50px); overflow: auto;"
                >
                    <template v-slot:day="{ date }">
                        <template v-for="event in eventsMap[date]" :v-key="event.NextTriggerTime + event.name">
                            <v-menu :key="event.NextTriggerTime + event.name" v-model="event.open" full-width offset-x>
                                <!-- Type Month -->
                                <template v-slot:activator="{ on }">
                                    <div
                                        @click="resetCardParams()"
                                        v-ripple
                                        :class="event.Resolution === 'Open' ? 'activityTask' : 'week'"
                                        :style="taskStyle(event)"
                                        v-on="on"
                                        v-html="event.name">
                                    </div>
                                </template>
                                <v-card color="grey lighten-4" min-width="350px" flat @click.native.stop>
                                    <v-toolbar :style="taskStyle(event)" dark>
                                        <v-toolbar-title v-html="event.name"></v-toolbar-title>
                                    </v-toolbar>
                                    <v-layout column>
                                        <label class="subheading font-weight-bold ma-2">Last trigger time: {{ formatDate(event.lastTriggerTime) ? formatDate(event.lastTriggerTime) : "N/A" }}</label>
                                        <v-layout row v-if="!nextTriggerTimeEdit">
                                            <label class="subheading font-weight-bold ma-2" :style="nextTriggerLabelOpacity(event)">
                                                Next trigger time: {{ formatDate(event.NextTriggerTime) }}
                                            </label>
                                            <v-tooltip bottom left v-if="nextTriggerIconTooltip(event)">
                                                <av-icon slot="activator" color="warning" :style="'margin-top: 11px;' + nextTriggerIconOpacity(event)" small class="ml-3">fas fa-exclamation-triangle</av-icon>
                                                <span>{{nextTriggerIconTooltip(event)}}</span>
                                            </v-tooltip>
                                            <av-icon v-if="$grants.get().activities.canRescheduleActivities" @click="nextTriggerTimeEdit = true" small class="ml-2">fa fa-pencil</av-icon>
                                        </v-layout>
                                        <v-layout row v-else>
                                            <v-datetime-picker class="ma-2" label="Pick new next trigger time" :datetime=showNextTriggerTime(event.NextTriggerTime) @input="onSelectedTime($event)" />
                                            <av-icon class="ma-2" @click="nextTriggerTimeEdit = false">fas fa-window-close</av-icon>
                                        </v-layout>
                                        <label class="subheading font-weight-bold ma-2">Description: {{ event.description ? event.description : "N/A" }}</label>
                                        <v-layout row v-if="!durationEdit">
                                            <label class="subheading font-weight-bold ma-2">Duration: {{ event.Duration ? secondsToTime(event.Duration) : "N/A" }}</label>
                                            <av-icon v-if="$grants.get().activities.canRescheduleActivities" small class="ml-2" @click="durationEdit = true">fa fa-pencil</av-icon>
                                        </v-layout>
                                        <v-layout row v-else>
                                            <av-text-field style="max-width: 150px" class="ma-2" v-model="defaultDuration" :prefix="$gettext('Duration: ')" type="number" min=1></av-text-field>
                                            <av-select style="max-width: 150px" class="ma-2" v-model="defaultUnit" :items="timeUnits"/>
                                            <av-icon v-if="$grants.get().activities.canRescheduleActivities" class="ma-2" @click="durationEdit = false">fas fa-window-close</av-icon>
                                        </v-layout>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-btn flat color="info" @click="scheduleInAdvance(event.id, event.NextTriggerTime, date, event.Duration)" :disabled="disableReschedule">
                                            Save changes
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </template>
                    </template>
                    <!-- Type Week -->
                    <template v-slot:dayHeader="{ date }">
                        <template v-for="event in eventsMap[date]" :v-key="event.NextTriggerTime + event.name">
                            <!-- all day events don't have time -->
                            <div
                                v-if="!event.time"
                                :key="event.NextTriggerTime + event.name"
                                class="week"
                                v-html="event.name"
                            ></div>
                        </template>
                    </template>
                    <template v-slot:dayBody="{ date, timeToY, minutesToPixels }">
                        <template v-for="event in eventsMap[date]" :v-key="event.NextTriggerTime + event.name">
                            <v-menu :key="event.NextTriggerTime + event.name"  v-model="event.open" full-width offset-x>
                                <!-- Type Day -->
                                <template v-slot:activator="{ on }">
                                    <div
                                        @click="resetCardParams()"
                                        v-ripple
                                        v-if="event.time"
                                        :key="event.NextTriggerTime + event.name"
                                        :style="{
                                                        top: timeToY(event.time) + 'px',
                                                        height: minutesToPixels(event.durationConverted) + 'px',
                                                        width: 100/event.overlappingActivitiesCounter + '%',
                                                        left: 100/event.overlappingActivitiesCounter*event.overlappingActivityIndex + '%',
                                                        float: 'left',
                                                        backgroundColor:event.color,
                                                        color: $avStyle.whiteTextNeeded(event.color) ? 'white' : 'black'
                                                    }"
                                        class="week with-time"
                                        :class="event.Resolution === 'Open' ? 'weekActivityTask' : 'week'"
                                        v-html="event.name"
                                        v-on="on"
                                    ></div>
                                </template>
                                <v-card color="grey lighten-4" min-width="350px" flat @click.native.stop>
                                    <v-toolbar :style="{backgroundColor:event.color, color: $avStyle.whiteTextNeeded(event.color) ? 'white' : 'black'}" dark>
                                        <v-toolbar-title v-html="event.name"></v-toolbar-title>
                                    </v-toolbar>
                                    <v-layout column>
                                        <label class="subheading font-weight-bold ma-2">Last trigger time: {{ formatDate(event.lastTriggerTime) ? formatDate(event.lastTriggerTime) : "N/A" }}</label>
                                        <v-layout row v-if="!nextTriggerTimeEdit">
                                            <label class="subheading font-weight-bold ma-2" :style="nextTriggerLabelOpacity(event)">
                                                Next trigger time: {{ formatDate(event.NextTriggerTime) }}
                                            </label>
                                            <v-tooltip bottom left v-if="nextTriggerIconTooltip(event)">
                                                <av-icon slot="activator" color="warning" :style="'margin-top: 11px;' + nextTriggerIconOpacity(event)" small class="ml-3">fas fa-exclamation-triangle</av-icon>
                                                <span>{{nextTriggerIconTooltip(event)}}</span>
                                            </v-tooltip>
                                            <av-icon v-if="$grants.get().activities.canRescheduleActivities" @click="nextTriggerTimeEdit = true" small class="ml-2">fa fa-pencil</av-icon>
                                        </v-layout>
                                        <v-layout row v-else>
                                            <v-datetime-picker class="ma-2" label="Pick new next trigger time" :datetime=showNextTriggerTime(event.NextTriggerTime) @input="onSelectedTime($event)" />
                                            <av-icon class="ma-2" @click="nextTriggerTimeEdit = false">fas fa-window-close</av-icon>
                                        </v-layout>
                                        <label class="subheading font-weight-bold ma-2">Description: {{ event.description ? event.description : "N/A" }}</label>
                                        <v-layout row v-if="!durationEdit">
                                            <label class="subheading font-weight-bold ma-2">Duration: {{ event.Duration ? secondsToTime(event.Duration) : "N/A" }}</label>
                                            <av-icon v-if="$grants.get().activities.canRescheduleActivities" small class="ml-2" @click="durationEdit = true">fa fa-pencil</av-icon>
                                        </v-layout>
                                        <v-layout row v-else>
                                            <av-text-field style="max-width: 150px" class="ma-2" v-model="defaultDuration" :prefix="$gettext('Duration: ')" type="number" min=1></av-text-field>
                                            <av-select style="max-width: 150px" class="ma-2" v-model="defaultUnit" :items="timeUnits"/>
                                            <av-icon v-if="$grants.get().activities.canRescheduleActivities" class="ma-2" @click="durationEdit = false">fas fa-window-close</av-icon>
                                        </v-layout>
                                        <label v-if="event.ActivityTargets.asset" class="subheading font-weight-bold ma-2">Target asset: {{ event.ActivityTargets.asset }}</label>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-btn flat color="info" @click="scheduleInAdvance(event.id, event.NextTriggerTime, date, event.Duration)" :disabled="disableReschedule">
                                            Save changes
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </template>
                    </template>
                </v-calendar>
            </template>
        </template>
        <v-dialog v-model="showExportOptionsDialog" width="620px"
                  :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" scrollable>
            <v-card>
                <v-toolbar-title class="headline text-xs-center pa-3" style="height: 100px">
                    <v-layout align-center row px-0 py-1 wrap>
                        <av-text-field v-if="newExportProfile" :label="$gettext('New profile')" v-model="exportProfileObj.profileName"
                                       style="max-width: 200px" @input="nameChanging"/>
                        <av-select v-else :items="exportProfiles" item-text="Name" :label="$gettext('Export profiles')"
                                   style="max-width: 200px" return-object @change="selectExportProfile($event)" v-model="exportProfileObj.profileName"/>
                        <av-switch class="ma-4" :label="$gettext('Set to default')"
                                   v-model="exportProfileObj.checkAsDefault"/>
                        <v-btn @click="changeProfile" style="width: 160px" v-if="exportProfiles.length > 0">
                            <av-icon small left>fa fa-user</av-icon>
                            {{ newExportProfile ? $gettext('Choose profile') : $gettext('New profile') }}
                        </v-btn>
                    </v-layout>
                </v-toolbar-title>
                <v-card-text>
                    <v-layout column style="height: 800px;">
                        <v-expansion-panel class="mb-2" expand>
                            <v-expansion-panel-content>
                                <template v-slot:header>
                                    <div class="subheading pa-0 ma-0">
                                        <translate>Time picker</translate>
                                    </div>
                                </template>
                                <v-layout row justify-start fill-height>
                                    <v-layout column>
                                        <time-picker :time-window="exportProfileObj.timeWindow" :call-back="loadDataForTimeWindow" class="px-4 py-2"/>
                                    </v-layout>
                                </v-layout>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="mb-2" expand>
                            <v-expansion-panel-content>
                                <template v-slot:header>
                                    <div class="subheading pa-0 ma-0">
                                        <translate>Task options</translate>
                                    </div>
                                </template>
                                <v-layout row justify-space-between>
                                    <v-autocomplete v-model="exportProfileObj.taskOptions" :items="taskOptions" item-text="displayName" item-value="name"
                                                    chips label="Options" hide-no-data hide-selected deletable-chips multiple class="px-4 py-2"
                                    ></v-autocomplete>
                                </v-layout>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-layout>
                </v-card-text>
                <v-card-actions style="height: 100px">
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" flat="flat" @click="saveExportProfile" :disabled="!exportProfileObj.profileName || disableExportProfileSave">
                        {{ newExportProfile ? $gettext("Save profile") : $gettext('Update profile') }}
                    </v-btn>
                    <v-btn color="red darken-1" flat="flat" v-if="!newExportProfile" :disabled="!exportProfileObj.profileName" @click="deleteExportProfile">
                        {{ $gettext("Delete profile")  }}
                    </v-btn>
                    <v-btn color="red darken-1" flat="flat" @click="showExportOptionsDialog = false">
                        {{ $gettext("Cancel")  }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </av-page>
</template>

<script>
import RulesEngine from '@/api/rules.js';
import AvProgress from '@/components/av-components/av-progress'
import DateTime, {TimeSpan} from '@/api/datetimeutils';
import ColorSequence from "@/api/colorsequences";
import Vue from 'vue'
import TimePicker from '@/components/utilities/TimePicker';
import AvPage from "@/components/av-components/av-page";

export default {
    name: 'ActivitiesPage',
    components: {
        AvPage,
        AvProgress,
        TimePicker,
    },
    data: () => ({
        today: new Date().format(Vue.prototype.$gettext("yyyy-MM-dd")),
        type: 'month',
        typeOptions: [
            {text: Vue.prototype.$gettext('Day'), value: 'day'},
            {text: Vue.prototype.$gettext('Week'), value: 'week'},
            {text: Vue.prototype.$gettext('Month'), value: 'month'}
        ],
        calendarView: false,
        activities: [],
        calendarActivities: [],
        interval: undefined,
        tagFilters: [],
        tagSelected: "All",
        filterByName: "",
        defaultUnit: 'h',
        defaultDuration: 0,
        durationEdit: false,
        nextTriggerTimeEdit: false,
        nextTriggerTimeEditNewValue: "",
        tabsTitles: [],
        backPath: '',
        assetId: '',
        filterMessage: '',
        assets: [],
        assetSelected: "All",
        showExportOptionsDialog: false,
        //Used to store export profile data
        exportProfileObj: {
            timeWindow: new TimeSpan(),
            profileName: '',
            checkAsDefault: false,
            taskOptions: ['Open', 'Resolved']
        },
        exportProfileSettingsType: 'activityExportProfile',
        exportProfiles: [],
        disableExportProfileSave: false,
        newExportProfile: false,
        taskOptions: [{name: 'Open', displayName: 'Planned tasks'}, {name: 'Resolved', displayName: 'Executed tasks'}],
        pendingActions: [],
        paInterval: undefined,
    }),

    async mounted() {
        //Used from report ms to export data, no need to load unnecessary stuff
        if (this.$route.params.requiredExport) {
            await this.loadProfiles();
            this.exportReport(this.$route.params.format, true);
            return
        }
        this.$root.setLoading(true, "");
        let today = new Date();
        let startToday = DateTime.getRfc3339TimeStamp(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0));
        let endToday = DateTime.getRfc3339TimeStamp(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 59));
        this.getActivitiesWithinTimeWindow(startToday, endToday);
        this.loadPendingActions()
        this.paInterval = setInterval(this.loadPendingActions, 6000)
        try {
            this.$root.setCurrentPageTitle(this.$gettext("Activities"));
            this.tabsTitles.push(this.$gettext("Calendar View"));
            this.tabsTitles.push(this.$gettext("Table View"));
            this.activities = await RulesEngine.getActivities();
            this.calculateColumns();
            //Redirect form asset register page
            if (this.$route.params && this.$route.params.backPath && this.$route.params.formName) {
                this.backPath = this.$route.params.backPath;
                this.activities = this.activities.filter(activity => this.$route.params.formName.includes(activity.Actions[0].formName));
                this.filterMessage = this.$gettext("Activities filtered by form: {0}").format(this.$route.params.formName.join());
            }
            //this.activities = this.activities.filter(activity => this.$grants.enabledForVisible(activity.visualization.properties.visibleToLevels))
            this.calculateTagFilters();
            this.loadActivities();
            this.assetsForFiltering();
            this.loadProfiles();
            if (this.$route.params.calendarView) {
                this.calendarView = true;
            }
        } finally {
            this.$root.setLoading(false, "");
        }

    },
    beforeDestroy() {
        clearInterval(this.interval)
        clearInterval(this.paInterval)
    },
    computed: {
        formatDate() {
            return date => {
                if (!date || date.includes("no value"))
                    return "";
                return (new Date(date)).format("yyyy-MM-dd HH:mm:ss")
            }
        },
        taskStyle() {
            return item => {
                if (!this.formatDate(item.NextTriggerTime) || !Object.isUseful(item.NextTriggerAccuracy) || item.NextTriggerAccuracy > 1)
                    return {
                        opacity: 1,
                        backgroundColor: item.color,
                        color: this.$avStyle.whiteTextNeeded(item.color) ? 'white' : 'black'
                    };
                return {
                    opacity: (item.NextTriggerAccuracy + 0.2),
                    backgroundColor: item.color,
                    color: this.$avStyle.whiteTextNeeded(item.color) ? 'white' : 'black'
                };
            }
        },
        nextTriggerLabelOpacity() {
            return item => {
                if (!this.formatDate(item.NextTriggerTime) || !Object.isUseful(item.NextTriggerAccuracy) || item.NextTriggerAccuracy > 1)
                    return "opacity: 1";
                return "opacity: " + (item.NextTriggerAccuracy + 0.2)
            }
        },
        nextTriggerIconOpacity() {
            return item => {
                if (!this.formatDate(item.NextTriggerTime) || !Object.isUseful(item.NextTriggerAccuracy) || item.NextTriggerAccuracy > 1)
                    return "opacity: 0";
                return "opacity: " + (1 - item.NextTriggerAccuracy)
            }
        },
        nextTriggerIconTooltip() {
            return item => {
                if (!this.formatDate(item.NextTriggerTime) || !Object.isUseful(item.NextTriggerAccuracy) || item.NextTriggerAccuracy >= 1)
                    return "";
                return this.$gettext("This date is estimated and may change. Estimation confidence is: {0}%").format(Math.round(item.NextTriggerAccuracy * 100));
            }
        },
        eventsMap() {
            let map = {}
            let activities = this.calendarActivities
            const colorizedActivities = this.addColorsToActivities(activities)
            activities.forEach(key => {
                colorizedActivities.map(item => {
                    if (item.name === key.name)
                        key.color = item.color
                })
                if (key.redColor)
                    key.color = "red"
                key.nextTriggerTimeFormated = key.NextTriggerTime.slice(0, 10)
                key.open = false
                key.time = key.NextTriggerTime.slice(11, 16)
                key.durationConverted = key.Duration / 60
                key.percent = 20
                if (key.id === "")
                    key.id = key.name
                var endTime = new Date(key.NextTriggerTime);
                endTime.setSeconds(endTime.getSeconds() + key.Duration);
                endTime = new Date(endTime);
                key.endTime = (endTime.getHours() < 10 ? '0' : '') + endTime.getHours() + ":" + (endTime.getMinutes() < 10 ? '0' : '') + endTime.getMinutes()
                key.overlappingActivitiesCounter = 1
            })
            if (this.filterByName) {
                activities = activities.filter((activity) => {
                    return this.filterByName.toLowerCase().split(' ').every(v => activity.name.toLowerCase().includes(v))
                })
            }
            //sort by time
            activities.sort(function (a, b) {
                return a.time.localeCompare(b.time);
            });
            //add activities in map
            activities.forEach(e => (map[e.nextTriggerTimeFormated] = map[e.nextTriggerTimeFormated] || []).push(e))
            // If Activities runs at the same time, add properties to visualize them properly on calendar view
            map = this.checkIfActivitiesRunAtTheSameTime(map)
            return map
        },
        filteredActivities() {
            let filtered = this.activities;
            if (this.filterByName) {
                filtered = filtered.filter((activity) => {
                    return this.filterByName.toLowerCase().split(' ').every(v => activity.name.toLowerCase().includes(v))
                })
            }
            if (this.tagSelected !== 'All')
                filtered = filtered.filter(activity => activity.visualization.properties.tags.includes(this.tagSelected));
            if (this.assetSelected !== 'All')
                filtered = filtered.filter(activity => activity.ActivityTargets.asset === this.assetSelected);

            return filtered;
        },
        timeUnits() {
            return [
                {value: "m", text: this.$gettext("minutes")},
                {value: "h", text: this.$gettext("hours")},
                {value: "d", text: this.$gettext("days")},
                {value: "w", text: this.$gettext("weeks")}
            ]
        },
        disableReschedule() {
            if (this.nextTriggerTimeEditNewValue !== "" || this.defaultDuration !== 0)
                return false;
            return true
        },
        activityRulesHeaders() {
            return [
                {
                    text: this.$gettext("Activity"),
                    class: "subheading",
                    sortable: false,
                    exportName: "name"
                },
                {
                    text: this.$gettext(""),
                    class: "subheading",
                    sortable: false,
                    exportable: false,
                    exportName: "name"
                },
                {
                    text: this.$gettext("Waiting progress"),
                    class: "subheading text-xs-center",
                    sortable: true,
                    value: 'waitingProgress',
                    exportName: "WaitingProgress"
                },
                {
                    text: this.$gettext("Last Execution"),
                    class: "subheading",
                    sortable: true,
                    value: "lastUsefulResultTime",
                    exportName: "LastTriggerTime"
                },
                {
                    text: this.$gettext("Next trigger"),
                    class: "subheading",
                    sortable: true,
                    value: 'nextTriggerTime',
                    exportName: "NextTriggerTime"
                },
                {
                    text: this.$gettext("Duration"),
                    class: "subheading",
                    sortable: true,
                    value: "duration",
                    exportName: "Duration"
                },
                {
                    text: this.$gettext("Target Asset"),
                    class: "subheading",
                    sortable: true,
                    value: "asset",
                    exportName: "TargetAsset"
                },
                {
                    text: this.$gettext("Status"),
                    class: "subheading",
                    sortable: false,
                    value: "status",
                    exportable:false,
                    exportName: "Status"
                },
                {
                    text: this.$gettext("Actions"),
                    class: "subheading",
                    sortable: false,
                    value: 'actions'
                }
            ]
        },
        pendingActionsHeaders() {
            return [
                {
                    text: this.$gettext(""),
                    class: "subheading",
                    sortable: false,
                    exportName: "name"
                },
                {
                    text: this.$gettext("Activity"),
                    class: "subheading",
                    sortable: false,
                    exportName: "name"
                },
                {
                    text: this.$gettext("Triggered On"),
                    class: "subheading",
                    sortable: false,
                    exportName: "name"
                },
            ]
        },
        filteredPendingActions() {
            let self = this
            return activityName => {
                return self.pendingActions.filter(pa => {
                    return pa.Name === activityName
                })
            }
        }
    },
    methods: {
        intervalFormat(interval) {
            return interval.time
        },
        checkIfActivitiesRunAtTheSameTime(map) {
            for (var m in map) {
                let index = 0
                for (var i = 0; i < map[m].length; i++) {
                    let counter = 1
                    let endTime = map[m][i].endTime
                    do {
                        if (i === map[m].length - 1)
                            break
                        if (endTime >= map[m][i + 1].time) {
                            if (endTime < map[m][i + 1].endTime) {
                                endTime = map[m][i + 1].endTime
                            }
                            counter++
                            i++
                        } else {
                            break
                        }
                    } while (i < map[m].length);
                    let result = counter + index
                    let overlappingCounter = 0
                    for (index; index < result; index++) {
                        if (index > result) {
                            index = index + counter
                            break
                        }
                        map[m][index].overlappingActivitiesCounter = counter
                        map[m][index].overlappingActivityIndex = overlappingCounter
                        overlappingCounter++
                    }
                    i = index - 1

                }
            }
            return map
        },
        addColorsToActivities(activities) {
            let colorizedActivities = [];
            const uniqueActivities = [...new Set(activities.map(item => item.name))];
            let colors = ColorSequence.getColors(uniqueActivities.length, ColorSequence.palettes.rainbow);
            for (let i = 0; i < uniqueActivities.length; i++) {
                colorizedActivities.push({
                    color: colors[i],
                    name: uniqueActivities[i]
                })
            }
            return colorizedActivities
        },
        resetCardParams() {
            this.nextTriggerTimeEditNewValue = "";
            this.durationEdit = false;
            this.nextTriggerTimeEdit = false;
        },
        async changeDate(e) {
            let startDate = DateTime.getRfc3339TimeStamp(new Date(e.start.year, e.start.month - 1, e.start.day, 0, 0, 0, 0));
            let endDate = DateTime.getRfc3339TimeStamp(new Date(e.end.year, e.end.month - 1, e.end.day, 23, 59, 59, 59));
            this.calendarActivities = await RulesEngine.GetActivitiesWithinTimeWindow(startDate, endDate)
        },
        newActivity() {
            this.$router.push({path: "/explore/rules", query: {elementToCreate: {element: {type: 2005}}}});
        },
        showNextTriggerTime(nextTriggerTime) {
            if (!this.nextTriggerTimeEditNewValue) {
                return nextTriggerTime
            }
            return this.nextTriggerTimeEditNewValue
        },
        async getActivitiesWithinTimeWindow(timeFrom, timeTo) {
            this.calendarActivities = await RulesEngine.GetActivitiesWithinTimeWindow(timeFrom, timeTo)
        },
        async schedulingInAdvance(id, nextSchedules) {
            await RulesEngine.schedulingInAdvance(id, nextSchedules)
        },
        customSort(items, index, isDesc) {
            items.sort((a, b) => {
                if (index === "waitingProgress") {
                    if (!isFinite(a[a.name].currentValue)) {
                        return 1;
                    }
                    if (!isFinite(b[b.name].currentValue)) {
                        return -1;
                    }
                    if (isDesc) {
                        return Math.floor((b[b.name].currentValue / b[b.name].targetValue) * 100) - Math.floor((a[a.name].currentValue / a[a.name].targetValue) * 100);
                    } else {
                        return Math.floor((a[a.name].currentValue / a[a.name].targetValue) * 100) - Math.floor((b[b.name].currentValue / b[b.name].targetValue) * 100);
                    }
                } else if (index === "nextTriggerTime") {
                    if (isDesc) {
                        return new Date(b.nextTriggerTime) - new Date(a.nextTriggerTime);
                    } else {
                        return new Date(a.nextTriggerTime) - new Date(b.nextTriggerTime);
                    }
                } else if (index === "LastTriggerTime") {
                    if (!a.LastTriggerTime) {
                        return 1;
                    }
                    if (!b.LastTriggerTime) {
                        return -1;
                    }
                    if (isDesc) {
                        return new Date(b.LastTriggerTime) - new Date(a.LastTriggerTime);
                    } else {
                        return new Date(a.LastTriggerTime) - new Date(b.LastTriggerTime);
                    }
                }
            });
            return items;
        },
        checkDescriptionVersion(descriptor) {
            return descriptor.version === ((descriptor.deployedVersion) ? descriptor.deployedVersion : -1);
        },
        editRule(visualization) {
            this.$router.push({path: "/explore/rules", query: {elementToLoad: visualization.properties.name}});
        },
        loadActivities() {
            let self = this;
            this.interval = setInterval(async () => {
                self.activities = await RulesEngine.getActivities();
                if (self.$route.params && self.$route.params.formName)
                    self.activities = self.activities.filter(activity => this.$route.params.formName.includes(activity.Actions[0].formName));
                //self.activities = self.activities.filter(activity => this.$grants.enabledForVisible(activity.visualization.properties.visibleToLevels));
                this.calculateColumns();
            }, 10000)
        },
        activityName(item) {
            let name = item.name;
            if (item.visualization.properties.description)
                name += " - " + item.visualization.properties.description;
            return name;
        },
        calculateTagFilters() {
            this.tagFilters = [];
            this.activities.forEach(activity => {
                if (activity.visualization.properties.tags) {
                    activity.visualization.properties.tags.forEach(item => {
                        if (item != null && item !== "" && this.tagFilters.indexOf(item) === -1)
                            this.tagFilters.push(item);
                    })
                }
            });
            this.tagFilters.unshift("All")
        },
        secondsToTime(seconds) {
            return new Date(seconds * 1000).toISOString().substr(11, 8);
        },
        onSelectedTime(event) {
            this.nextTriggerTimeEditNewValue = DateTime.getRfc3339TimeStamp(new Date(event.getTime()))
        },
        async scheduleInAdvance(id, oldTriggerTime, dateParam, duration) {
            if (this.nextTriggerTimeEditNewValue === "")
                this.nextTriggerTimeEditNewValue = oldTriggerTime
            let nextSchedules = {
                "OriginalTriggerTime": oldTriggerTime,
                "NextTriggerTime": this.nextTriggerTimeEditNewValue
            }
            if (this.defaultDuration !== 0) {
                nextSchedules["Duration"] = this.convertDurationToSeconds()
                this.defaultDuration = 0
            }
            let self = this
            new Promise(resolve => {
                this.schedulingInAdvance(id, nextSchedules)
                    .then(() => {
                        resolve(true)
                        this.$root.showInfoNotification("Rule rescheduled successfully");
                        // save duration audit trail if duration is changed
                        if (nextSchedules["Duration"]) {
                            self.$audits.save(self.$root.userName, self.$audits.items().activityDurationChanged, duration, nextSchedules["Duration"], id)
                                .catch(err => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                });
                        }
                        // save next trigger time audit trail if next trigger time is changed
                        if (oldTriggerTime !== this.nextTriggerTimeEditNewValue) {
                            self.$audits.save(self.$root.userName, self.$audits.items().activityRescheduled, oldTriggerTime, self.nextTriggerTimeEditNewValue, id)
                                .catch(err => {
                                    debugger
                                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                });
                        }
                    })
                    .catch((error) => {
                        debugger;
                        resolve(false)
                        console.log(error)
                    })

            })
            setTimeout(() => {
                let startDate = this.$refs.calendar.lastStart ? DateTime.getRfc3339TimeStamp(new Date(this.$refs.calendar.lastStart.date)) : null
                let endDate = this.$refs.calendar.lastEnd ? DateTime.getRfc3339TimeStamp(new Date(this.$refs.calendar.lastEnd.date)) : null
                if (!startDate || !endDate) {
                    let date = new Date(dateParam);
                    startDate = DateTime.getRfc3339TimeStamp(new Date(date.getFullYear(), date.getMonth() - 1, 26));
                    endDate = DateTime.getRfc3339TimeStamp(new Date(date.getFullYear(), date.getMonth() + 1, 4));
                }
                this.calendarActivities = []
                this.$nextTick().then(() => {
                    this.getActivitiesWithinTimeWindow(startDate, endDate)
                });
            }, 2000)
        },
        convertDurationToSeconds() {
            if (this.defaultUnit === "m")
                return 60 * this.defaultDuration
            else if (this.defaultUnit === "h")
                return 3600 * this.defaultDuration
            else if (this.defaultUnit === "d")
                return 86400 * this.defaultDuration
            else if (this.defaultUnit === "w")
                return 604800 * this.defaultDuration
        },
        goBack() {
            if (this.backPath)
                this.$router.push(this.backPath);
            else
                this.$router.go(-1);
        },
        assetsForFiltering() {
            let self = this;
            self.activities.forEach(activity => {
                let asset = activity.ActivityTargets.asset;
                if (asset !== '' && self.assets.indexOf(asset) === -1) {
                    self.assets.push(asset);
                }
            });
            self.assets.unshift("All");
        },
        async exportReport(format, requiredExport = false) {
            let self = this;
            let data = {};
            let title = '';
            let startDate = null;
            let endDate = null;
            //Exportation of activities
            if (!self.calendarView && !requiredExport) {
                let visibleData = [...self.filteredActivities];
                let headers = [...self.activityRulesHeaders()];
                headers = headers.filter(header => header.value !== 'actions');
                title = 'Activities-Default view';
                data = {
                    data: visibleData,
                    dataFormat: 'table',
                    headers: headers.map(item => {
                        return {
                            order: 1,
                            text: item.text,
                            value: item.exportName
                        }
                    })
                };
            } else {
                //Exportation of tasks
                startDate = DateTime.getRfc3339TimeStamp(new Date(self.exportProfileObj.timeWindow.getStart()))
                endDate = DateTime.getRfc3339TimeStamp(new Date(self.exportProfileObj.timeWindow.getEnd()))
                let result = await RulesEngine.GetActivitiesWithinTimeWindow(startDate, endDate);
                startDate = self.formatDate(startDate);
                endDate = self.formatDate(endDate);
                if (Array.isUseful(result)) {
                    result.forEach(res => {
                        res.LastTriggerTime = self.formatDate(res.LastTriggerTime);
                        res.NextTriggerTime = self.formatDate(res.NextTriggerTime);
                        res.InitialReferenceTime = self.formatDate(res.InitialReferenceTime);
                        res.Duration = self.secondsToTime(res.Duration);
                        if (res.ResolutionTime) res.ResolutionTime = self.formatDate(res.ResolutionTime)
                        res.Recipients = res.Actions.find(a => a.type === "activity").recipients.toString();
                    })

                    let tasks = result.filter(res => res.hasOwnProperty('Resolution'));

                    //Order by NextTriggerTime from oldest to latest
                    tasks.sort((a, b) => (a.NextTriggerTime > b.NextTriggerTime) ? 1 : ((b.NextTriggerTime > a.NextTriggerTime) ? -1 : 0))

                    if (self.exportProfileObj.taskOptions.length === 1)
                        tasks = tasks.filter(task => task.Resolution === self.exportProfileObj.taskOptions[0]);
                    let taskHeaders = [{order: 1, text: self.$gettext('Activity'), value: 'name'}, {
                        order: 1,
                        text: self.$gettext('Activity start'),
                        value: 'InitialReferenceTime'
                    },
                        {order: 1, text: self.$gettext('Due date'), value: 'NextTriggerTime'}, {
                            order: 1,
                            text: self.$gettext('Duration'),
                            value: 'Duration'
                        },
                        {order: 1, text: self.$gettext('Assignees'), value: 'Recipients'}, {
                            order: 1,
                            text: self.$gettext('Resolution'),
                            value: 'Resolution'
                        },
                        {order: 1, text: self.$gettext('ResolutionTime'), value: 'ResolutionTime'}];
                    title = self.exportProfileObj.profileName ? 'Tasks-' + self.exportProfileObj.profileName : 'Tasks - Default view'
                    data = {
                        data: tasks,
                        dataFormat: 'table',
                        headers: taskHeaders
                    };
                }
            }
            if (!Array.isUseful(data.data) || !Array.isUseful(data.headers)) {
                self.$root.showInfoNotification(this.$gettext("No data to be exported"), true);
                return
            }

            let request = {
                name: '',
                title: title,
                type: format,
                data: [data],
                from: startDate,
                to: endDate
            };
            if (requiredExport && self.$route.params.exportDataHandler) {
                let response = {
                    data: {
                        name: '',
                        title: title,
                        type: format,
                        data: [data],
                        from: startDate,
                        to: endDate
                    },
                    template: null,
                };
                //Return requested export data to report ms
                self.$route.params.exportDataHandler(response, this.$route.params.messageId);
            } else {
                self.$dynamicElements.ExportVisualization(format, request, null)
                    .then(r => {
                        const link = document.createElement('a');
                        link.href = r;
                        link.setAttribute('download', title + "." + format);
                        document.body.appendChild(link);
                        link.click();
                    });
            }
        },
        //Used to modify columns data for the purposes of the page, useful for exportation
        calculateColumns() {
            let self = this;
            self.activities.forEach(activity => {
                activity.Duration = self.secondsToTime(activity.Duration);
                activity.LastTriggerTime = self.formatDate(activity.LastTriggerTime);
                activity.NextTriggerTime = self.formatDate(activity.NextTriggerTime);
            });
        },
        loadDataForTimeWindow() {
        },
        saveExportProfile() {
            let self = this;
            if (!Array.isUseful(self.exportProfileObj.taskOptions)) {
                self.$root.showErrorNotification(this.$gettext("At least one task option must be selected"), true);
                return;
            }
            self.$settings.save(self.exportProfileObj, self.exportProfileSettingsType, self.exportProfileObj.profileName)
                .then((result) => {
                    if (result === 'created' || result === 'updated') {
                        self.$root.showInfoNotification(this.$gettext("Successfully {createdOrUpdated} profile").format({createdOrUpdated: result}), true);
                        self.showExportOptionsDialog = false;
                        if (result === 'created') {
                            let profileTimeWindow = Object.assign({}, self.exportProfileObj.timeWindow);
                            let profileFull = {
                                Name: self.exportProfileObj.profileName,
                                Type: self.exportProfileSettingsType,
                                profileData: {
                                    profileName: self.exportProfileObj.profileName,
                                    checkAsDefault: self.exportProfileObj.checkAsDefault,
                                    timeWindow: profileTimeWindow,
                                    taskOptions: self.exportProfileObj.taskOptions
                                }
                            };
                            self.exportProfiles.push(profileFull);
                            self.$audits.save(self.$root.userName, self.$audits.items().activityExportProfileCreated, "", "", self.exportProfileSettingsType + " /" + self.exportProfileObj.profileName)
                                .catch(err => {
                                    debugger
                                    self.$root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                                });
                        } else if (result === 'updated') {
                            let profileTimeWindow = Object.assign({}, self.exportProfileObj.timeWindow);
                            let profileToBeUpdated = self.exportProfiles.find(profile => profile.Name === self.exportProfileObj.profileName).profileData;
                            profileToBeUpdated.timeWindow = profileTimeWindow;
                            profileToBeUpdated.taskOptions = self.exportProfileObj.taskOptions;
                            self.$audits.save(self.$root.userName, self.$audits.items().activityExportProfileUpdated, "", "", self.exportProfileSettingsType + " /" + self.exportProfileObj.profileName)
                                .catch(err => {
                                    debugger
                                    self.$root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                                });
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    self.$root.showErrorNotification("An error occurred while saving settings to DB", true);
                    self.showExportOptionsDialog = false;
                });
        },
        async loadProfiles() {
            let self = this;
            try {
                let profiles = await self.$settings.list(self.exportProfileSettingsType);
                if (Array.isUseful(profiles)) {
                    for (const profile of profiles) {
                        let profileDetails = {};
                        try {
                            profileDetails = await self.$settings.load(profile.Type, profile.Name);
                        } catch (err) {
                            console.log(err);
                            self.$root.showErrorNotification("An error occurred while loading settings from DB", true);
                        }
                        if (Object.isUseful(profileDetails)) {
                            self.exportProfiles.push({
                                Name: profile.Name,
                                Type: profile.Type,
                                profileData: profileDetails
                            })
                        }
                    }
                    //Used from report ms to export data,
                    if (self.$route.params.requiredExport) {
                        if (this.$route.params.itemName === 'Default view') {
                            self.exportProfileObj.timeWindow.setPredefined("week");
                            self.exportProfileObj.timeWindow.setPredefinedTimeTo(10080);
                        } else {
                            let profile = self.exportProfiles.find(profile => profile.Name === this.$route.params.itemName);
                            if (profile) {
                                self.selectExportProfile(profile)
                                return
                            }
                        }
                    }
                    let defaultFound = false;
                    for (let profile of self.exportProfiles) {
                        if (profile.profileData.checkAsDefault) {
                            self.selectExportProfile(profile);
                            defaultFound = true;
                        }
                    }
                    if (!defaultFound) {
                        //Create a default exportation profile
                        self.newExportProfile = true;
                        self.exportProfileObj.timeWindow.setPredefined("week");
                        self.exportProfileObj.timeWindow.setPredefinedTimeTo(10080);
                    }
                } else {
                    //Create a default exportation profile
                    self.newExportProfile = true;
                    self.exportProfileObj.timeWindow.setPredefined("week");
                    self.exportProfileObj.timeWindow.setPredefinedTimeTo(10080);
                }
            } catch (err) {
                console.log(err);
                self.$root.showErrorNotification("An error occurred while loading settings from DB", true);
            }
        },
        nameChanging() {
            let self = this;
            if (self.exportProfiles.some(profile => profile.Name.toLowerCase() === self.exportProfileObj.profileName.toLowerCase())) {
                self.$root.showErrorNotification(self.$gettext("Exportation profile with name {0} already exists").format(self.exportProfileObj.profileName), true);
                self.disableExportProfileSave = true;
            } else if (self.disableExportProfileSave === true) {
                self.disableExportProfileSave = false;
            }
        },
        changeProfile() {
            let self = this;
            self.newExportProfile = !self.newExportProfile;
            if (self.newExportProfile) {
                self.resetExportProfile();
            }
        },
        selectExportProfile(event) {
            let self = this;
            self.exportProfileObj.profileName = event.profileData.profileName;
            self.exportProfileObj.timeWindow.setPredefined(event.profileData.timeWindow.predefined);
            self.exportProfileObj.timeWindow.setPredefinedTimeTo(event.profileData.timeWindow.timeToPredefined);
            self.exportProfileObj.checkAsDefault = event.profileData.checkAsDefault;
            self.exportProfileObj.taskOptions = event.profileData.taskOptions;
        },
        deleteExportProfile() {
            let self = this;
            self.$root.showDialogBox(this.$gettext("Are you sure you want to delete export profile {0}?").format(self.exportProfileObj.profileName), "", "Yes",
                () => {
                    self.$settings.delete(self.exportProfileSettingsType, self.exportProfileObj.profileName)
                        .then((result) => {
                            if (result === 'deleted') {
                                self.$root.showInfoNotification("Successfully deleted profile", true);
                                self.exportProfiles = self.exportProfiles.filter(item => item.Name !== self.exportProfileObj.profileName);
                                let deleteProfileName = self.exportProfileObj.profileName;
                                self.resetExportProfile();

                                //If there are no profiles to be selected switch to new profile mode
                                if (!Array.isUseful(self.exportProfiles))
                                    self.newExportProfile = true;
                                self.$audits.save(self.$root.userName, self.$audits.items().activityExportProfileDeleted, "", "", self.exportProfileSettingsType + " /" + deleteProfileName)
                                    .catch(err => {
                                        debugger
                                        self.root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                    });
                            }
                        })
                        .catch(err => {
                            self.$root.showErrorNotification("An error occurred while deleting settings from DB", true);
                        });
                }, "No", null);
        },
        resetExportProfile() {
            this.exportProfileObj.profileName = "";
            this.exportProfileObj.timeWindow = new TimeSpan();
            this.exportProfileObj.taskOptions = ['Open', 'Resolved'];
        },
        loadPendingActions() {
            let self = this;
            this.$dataEntry.getPendingActions()
                .then((actions) => {
                    self.pendingActions = actions || [];
                })
                .catch(() => {
                    self.pendingActions.clear();
                })
        },

        openAction(action) {
            let activityAction = action.Actions.find(action => action.type === 'activity');
            let dynamicDescription = [];
            if (activityAction.Message.message)
                dynamicDescription.push(activityAction.Message.message.replace(/<\/?[^>]+(>|$)/g, ""))
            if (activityAction.formType === 'wizards') {
                this.$router.push({
                    name: 'wizards', params: {
                        routeId: activityAction.formName, action: action, variables: action.variables, isTask: "true",
                        asset: action.ActivityTargets.asset, description: {
                            dynamicDescription: dynamicDescription,
                            description: action.ActivityTargets.asset ? this.$gettext('Target asset for this activity: {0}').format(action.ActivityTargets.asset) : ''
                        },
                        backPath:"/activitiesPage"
                    }
                })
            } else {
                this.$router.push({
                    name: 'forms', params: {
                        routeId: action.Action.name, action: action, variables: action.variables, isTask: "true",
                        asset: action.ActivityTargets.asset, description: {
                            dynamicDescription: dynamicDescription,
                            description: action.ActivityTargets.asset ? this.$gettext('Target asset for this activity: {0}').format(action.ActivityTargets.asset) : ''
                        },
                        backPath:"/activitiesPage"
                    }
                })
            }
        },
    }
}
</script>

<style lang="stylus" scoped>
.week {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #398bcb;
    color: #ffffff;
    border: 1px solid white;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
    left: 4px;
    margin-right: 8px;
    position: relative;
}

.weekActivityTask {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: orange;
    color: #ffffff;
    border: 1px solid white;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
    left: 4px;
    margin-right: 8px;
    position: relative;
}

.activityTask {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: orange;
    color: #ffffff;
    border: 1px solid white;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
}

.with-time {
     position: absolute;
     right: 4px;
     margin-right: 0px;
 }

.my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #398bcb;
    color: #ffffff;
    border: 1px solid white;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
}
</style>
