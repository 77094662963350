<template>
    <v-card v-if="descriptor" class="av-card elevation-0" :style="'overflow: hidden; height: ' + containerHeight">
        <v-card-title>
            <v-layout row>
                <template v-if="!bigPic">
                    <h3 class="headline">{{descriptor.properties.title}}</h3>
                    <v-spacer></v-spacer>
                    <img v-show="isExporting" :src="loaderGif" style="width:50px; height:50px;"/>
                    <v-btn :dark="!isExporting" fab small color="#f5f5f5" @click="exportForm('xlsx')"
                           :disabled="isExporting">
                        <av-icon medium color="#1D6F42">fas fa-file-excel</av-icon>
                    </v-btn>
                    <v-btn :dark="!isExporting" fab small color="#f5f5f5" @click="exportForm('pdf')"
                           :disabled="isExporting">
                        <av-icon medium color="#F40F02">fas fa-file-pdf</av-icon>
                    </v-btn>
                    <v-btn :dark="!isExporting" fab small color="#f5f5f5" @click="exportForm('zip')" v-if="hasFiles"
                           :disabled="isExporting">
                        <av-icon medium color="grey">fas fa-file-archive</av-icon>
                    </v-btn>
                    <v-btn v-if="showExpandButton" fab small @click="expandVisualizer">
                        <av-icon>fas fa-{{expand ? 'compress':'expand'}}</av-icon>
                    </v-btn>
                    <v-btn v-if="showCloseButton" color="error" fab small @click="closeVisualizer">
                        <av-icon light>fas fa-times</av-icon>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn class="ma-0 pa-0" style="margin-top: -10px" color="error" fab small @click="bigPic = ''">
                        <av-icon light>fas fa-times</av-icon>
                    </v-btn>
                </template>
            </v-layout>
        </v-card-title>
        <v-card-text class="av-card-content" style="height: calc(100% - 96px)">
            <img v-if="bigPic" class="my-0 pa-0" style="overflow: auto; height: 100%; display: block; margin-left: auto; margin-right: auto;" :src="bigPic"/>
            <template v-else>
                <!--<v-layout row>-->
                <!--<h3 class="headline">{{descriptor.properties.title}}</h3>-->
                <!--<v-spacer></v-spacer>-->
                <!--<v-btn v-if="showExpandButton" fab small @click="expandVisualizer">-->
                <!--<av-icon >fas fa-{{expand ? 'compress':'expand'}}</av-icon>-->
                <!--</v-btn>-->
                <!--<v-btn v-if="showCloseButton" color="error" fab small @click="closeVisualizer">-->
                <!--<av-icon light>fas fa-times</av-icon>-->
                <!--</v-btn>-->
                <!--</v-layout>-->
                <!--<br/>-->
                <v-layout column align-start justify-start>
                    <variables-viewer :variables="descriptor.formVariables" @showPicture="bigPic = $event" />
                    <v-spacer></v-spacer>
                    <label class="title mt-4" style="font-weight: normal">Annotations: <span style="font-weight: bold">{{form.Annotation}}</span></label>
                </v-layout>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>

    import VariablesViewer from "@/components/dynamic-elements/forms/VariablesViewer";

    export default {
        name: "FormVisualizer",
        components: { VariablesViewer },
        data() {
            return {
                type: 0,
                descriptor: null,
                bigPic: "",
                expand: false,
                reportTemplate: "",
                exporting: false,
                hasFiles: false
            }
        },
        props: {
            form: {
                required: true,
                default: () => {
                    return 0
                }
            },
            maxHeight: {
                required: false,
                default: () => {
                    return 0
                }
            },
            showCloseButton: {
                required: false,
                default: () => {
                    return true
                }
            },
            showExpandButton: false
        },
        computed: {
            loaderGif() {
                return require('@/assets/avloader.png')
            },
            isExporting() {
                return this.exporting
            },
            containerHeight() {
                return (this.maxHeight !== 0 ? this.maxHeight + 'px' : "calc(100%)")
            },
            variables() {
                if (this.descriptor && Array.isUseful(this.descriptor.formVariables))
                    return this.descriptor.formVariables;
                return [];
            }
        },
        watch: {
            form: {
                deep: true,
                handler() {
                    this.loadForm();
                }
            },
        },
        methods: {
            loadForm() {
                let self = this;
                if (this.form) {

                    // try {
                    //     let d = await this.$dynamicElements.LoadItem(this.form.Name, "forms");
                    //     let descriptor = (JSON.parse(d.descriptor));
                    //     this.reportTemplate = descriptor.reportTemplate;
                    //     if (this.form.FormVersion) {
                    //         d = await this.$dynamicElements.DescriptorForIndexAndVersion("user_formshistory", this.form.FormVersion);
                    //        descriptor=(JSON.parse(d[0].descriptor));
                    //     }
                    //     self.descriptor = descriptor;
                    //     self.createForm();
                    //
                    // } catch (e) {
                    //     console.error(e);
                    //     self.$root.showErrorNotification(self.$gettext("Error in retrieving form {0} info from DB.").format(this.form.Name), true);
                    //
                    // }

                    // if (!this.form.FormVersion || this.form.FormVersion === 1) {
                    //     this.$dynamicElements.LoadItem(this.form.Name, "forms")
                    //         .then(t => {
                    //             self.descriptor = (JSON.parse(t.descriptor));
                    //             self.createForm();
                    //         })
                    //         .catch(t => {
                    //             console.error(t);
                    //             self.$root.showErrorNotification(self.$gettext("Error in retrieving form {0} info from DB.").format(self.form.Name), true);
                    //         });
                    // } else {

                    //First load live document to gather status information
                    this.$dynamicElements.LoadItem(this.form.Name, "forms")
                        .then(t => {
                            let latestDescriptor = JSON.parse(t.descriptor);
                            //If we need latest version we already have it
                            if (!self.form.FormVersion || self.form.FormVersion === latestDescriptor.version || latestDescriptor.properties.descriptorVersion < 3 /*Legacy version-less forms management*/) {
                                self.descriptor = latestDescriptor;
                                self.createForm();
                                return
                            }
                            //otherwise we load correct version descriptor

                            self.$dynamicElements.DescriptorForIndexAndVersion("user_formshistory", self.form.Name, self.form.FormVersion)
                                .then(t => {
                                    self.descriptor = (JSON.parse(t[0].descriptor));
                                    self.createForm();
                                })
                                .catch(t => {
                                    console.error(t);
                                    self.$root.showErrorNotification(self.$gettext("Error in retrieving form {0} info from DB.").format(self.form.Name), true);
                                });
                            // }

                        })
                }
            },
            expandVisualizer() {
                this.expand = !this.expand;
                this.$emit('expand', this.expand);
            },
            closeVisualizer() {
                this.expand = false;
                this.$emit('closed');
            },
            createForm() {
                this.hasFiles = this.$dataEntry.unwrapEntity(this.form, this.descriptor.formVariables, true);
                // let self = this;
                // let formValues = self.form[self.form.NameSpace];
                // if (Object.isUseful(formValues.RawDocument))
                //     formValues = formValues.RawDocument;
                // if (Array.isUseful(self.descriptor.formVariables) && self.form) {
                //     let varsCrawler = function (variables, values, self) {
                //         for (let variable of variables) {
                //             if (values.hasOwnProperty(variable.name)) {
                //                 let childrenNameSpace = values;
                //                 //Boolean parent nodes are treated as groups.
                //                 if (variable.type === "Boolean" && Array.isUseful(variable.children)) {
                //                     variable.value = true;
                //                     variable.valid = true;
                //                     childrenNameSpace = values[variable.name];
                //                 } else if (variable.type === "repeatableGroup") {
                //                     variable.repeatableChildren = [];
                //                     for (let subValues of values[variable.name]) {
                //                         variable.repeatableChildren.push(self.$utils.detach(variable.children));
                //                         varsCrawler(variable.repeatableChildren.last(), subValues, self);
                //                     }
                //                 } else {
                //                     variable.value = self.convertValue(values[variable.name], variable.type);
                //                     variable.valid = self.$dataEntry.validateVariableValue(variable);
                //                     variable.validationMessage = self.$dataEntry.validateVariableValue(variable, true);
                //                     if (variable.type === "Files" && Array.isUseful(variable.value))
                //                         self.hasFiles = true;
                //                 }
                //                 if (formValues.hasOwnProperty(variable.name + "_Evidence"))
                //                     variable.evidence = formValues[variable.name + "_Evidence"];
                //
                //                 if (Array.isUseful(variable.children))
                //                     varsCrawler(variable.children, childrenNameSpace, self);
                //             }
                //         }
                //     };
                //     varsCrawler(self.descriptor.formVariables, formValues, self);
                // }
            },
            exportForm(format) {
                this.exporting = true;
                let data = [{
                    dataFormat: "form",
                    form: this.descriptor.formVariables
                }];
                let self = this;
                this.$dataEntry.exportForm(format, this.descriptor.properties.name, this.descriptor.properties.title, data, this.descriptor.reportTemplate, () => {
                    self.exporting = false
                }, () => {
                    self.$root.showErrorNotification("Unable to export requested form");
                    self.exporting = false
                })
            }
        }
    }

</script>

<style scoped>

</style>