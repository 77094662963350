<template>
    <v-card-text class="av-card-content" style="height: calc(100vh - 80px);" >
        <v-data-table
                :headers="billOfMaterialsFields"
                :items="materials"
                item-key="Id"
                class="elevation-0" :pagination.sync="pagination"
                hide-actions
                show-expand style="width:99%; border-bottom:1px solid #e0e0e0"
        >
            <template v-slot:headers="props">
                <tr>
                    <th v-for="header in props.headers"
                        :key="header.text"
                        :class="[header.sortable?'column sortable':'', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                        @click="changeSort(header.value)"
                        class="text-xs-left" style="font-size: 1.1em; padding:0px">
                        <template v-if="header.sortable">
                            <v-icon small>arrow_upward</v-icon>
                        </template>
                        {{ header.text }}
                    </th>
                </tr>
            </template>
            <template v-slot:items="props">
                <tr style=" border-left: 1px solid #e0e0e0; border-right: 1px solid #e0e0e0;">
                    <td style="width: 60px;"><i :class="props.expanded ? 'fas fa-angle-up fa-2x' : 'fas fa-angle-down fa-2x'" v-if="canExpand(props.item.Details)" @click="props.expanded = !props.expanded" style="cursor: pointer;"></i></td>
                    <td style="font-size: 1.1em; padding:10px" class="text-xs-left">{{ props.item.Id }}</td>
                    <td style="font-size: 1.1em; padding:10px" class="text-xs-left">{{ props.item.Quantity }}</td>
                    <td style="font-size: 1.1em; padding:10px;" class="text-xs-left pl-0">{{ props.item.Description }}</td>
                </tr>
            </template>
            <template v-slot:expand="props">
                <v-card flat style="padding-left: 120px;border: 1px solid #E0E0E0; border-bottom:none">
                    <table class="v-datatable v-table theme--light" style="width:100%">
                        <thead>
                        <tr>
                            <th
                                    class="text-xs-left" style="font-size: 1.1em; padding:0px">
                                {{$gettext("Name")}}
                            </th>
                            <th class="text-xs-left" style="font-size: 1.1em; padding:0px">
                                {{$gettext("Description")}}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="detail in  props.item.Details">
                            <td style="font-size: 1.1em; padding:10px" class="text-xs-left pl-0">{{detail.Description}}</td>
                            <td style="font-size: 1.1em; padding:10px" class="text-xs-left pl-0">
                                <av-file-viewer v-if="isFile(detail)" :type="detail.Type" :file="detail.Value" :file-name="fileName(props.item, detail)" :mediaId="detail.MediaId"/>
                                <template v-else>
                                    {{detail.Value}}
                                </template>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </v-card>
            </template>
        </v-data-table>
    </v-card-text>
</template>

<script>
    import AvFileViewer from "@/components/av-components/av-file-viewer"
    export default {
        name: "billOfMaterials",
        components:{ AvFileViewer},
        props:{
            materials:{
                required:true,
                default:()=>{ return []}
            }
        },
        data:()=>{
            return{

                billOfMaterialsFields:[
                    {
                        text: "",
                        sortable: false,
                        value: ''
                    },
                    {
                        text: "Id",
                        sortable: true,
                        value: 'Id'
                    },
                    {
                        text: "Quantity",
                        sortable: true,
                        value: 'Quantity'
                    },
                    {
                        text: "Description",
                        sortable: false,
                        value: 'Description'
                    },
                ],
                pagination: {
                    sortBy: 'Id',
                    descending:false,
                    page: 1,
                    rowsPerPage: -1
                },
            }
        },
        computed:{
            isFile(){
                var mimeDb = require('mime-db');
                return detail=>{
                    return detail.Type in mimeDb
                }
            },
            canExpand(){
                return details => {
                    return Array.isUseful(details)
                }
            },
        },
        methods:{
            changeSort (column) {
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending
                } else {
                    this.pagination.sortBy = column
                    this.pagination.descending = false
                }
            },
            fileName(material,detail) {
                return detail.FileName ? detail.FileName : material.Id + '_' + detail.Id  + '.' + detail.Type.split('/')[1]
            }
        }
    }
</script>

<style scoped>

</style>