import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import states from './module/state.js'
import * as getters from './module/getters'
import * as actions from './module/actions'
import * as mutations from './module/mutations'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
    },
    plugins: [createPersistedState({
      storage: window.sessionStorage
    })],
    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV,
    state: states,
    getters,
    mutations,
    actions
  });
  Store.state.skipApiCallNotification = true;
  Store.state.debugPort = 80;  //Deviate default debug port from 9090 to 80 since Digital Factory is deployed on 80 by default
  Store.state.debugUrl = ""; //"10.20.0.226";  //Deviate default API IP to debug a remote server
  Vue.prototype.$store = Store;
  return Store;
}
