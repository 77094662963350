<template>
    <v-dialog v-model="showDialog" width="1280px" height="640px" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <av-loader :loading="loading" message="" minTime="0"/>
        <TimeSpanPicker v-if="customTimePicker.show" :showDialog="customTimePicker.show" :timespan="customTimePicker.span" @exit="customTimePickerResult($event)"/>
        <v-toolbar card dense>
            <template>
                <v-layout row align-center justify-end px-0 py-0>
                    <v-toolbar-title class="headline">
                        <translate>Time Tracking adjustment</translate>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <template>
                        <v-tooltip bottom>
                            <v-btn fab small slot="activator" @click="zoomIn">
                                <av-icon>fas fa-search-plus</av-icon>
                            </v-btn>
                            <span>Zoom In</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <v-btn small slot="activator" style="cursor:default;"
                                   @click="openTimeWindowPicker">
                                {{displayedTimeWindow}}
                            </v-btn>
                            <span v-translate>Current time window</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <v-btn fab small slot="activator" @click="zoomOut">
                                <av-icon>fas fa-search-minus</av-icon>
                            </v-btn>
                            <span v-translate>Zoom Out</span>
                        </v-tooltip>
                    </template>
                </v-layout>
            </template>
        </v-toolbar>
        <v-layout row>
            <v-flex xs7 pa-0 m-a>
                <v-card height="590px">
                    <v-layout column px-1 py-0 style="height: 500px;">
                        <v-layout column pa-0>
                            <v-card v-if="timeTrackingHistory.length">
                                <v-list style="margin-top: 0px;">
                                    <v-list-tile style="height: 48px" @click="toggleAllTimeTrackingHistory(), processTimeTracking('')">
                                        <v-list-tile-action>
                                            <v-checkbox :value="selectAllTimeTrackingHistory"/>
                                        </v-list-tile-action>
                                        <v-list-tile-title>
                                            {{$gettext("Time tracking history ({0} min. total)").format(timeTrackingHistoryDuration)}}
                                        </v-list-tile-title>
                                    </v-list-tile>
                                </v-list>
                                <v-list two-line style="overflow: auto; margin-top: -25px; height: 450px">
                                    <template v-for="stop in timeTrackingHistory">
                                        <v-list-tile style="margin-left: 15px; height: 50px" @click="stop.selected = !stop.selected, processTimeTracking('')">
                                            <v-list-tile-action>
                                                <v-checkbox :value="stop.selected"/>
                                            </v-list-tile-action>
                                            <v-list-tile-content>
                                                <v-list-tile-title>{{formatTimeTrackingHistoryItem(stop)[0]}}</v-list-tile-title>
                                                <v-list-tile-sub-title>{{formatTimeTrackingHistoryItem(stop)[1]}}</v-list-tile-sub-title>
                                            </v-list-tile-content>
                                        </v-list-tile>
                                    </template>
                                </v-list>
                            </v-card>
                        </v-layout>
                        <v-card-actions id="antares-annotation-dialog-card-actions">
                            <v-spacer></v-spacer>
                            <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat"
                                   @click="callback ? callback() : null, showDialog = false">
                                <translate>
                                    OK
                                </translate>
                            </v-btn>
                            <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat"
                                   @click="callback ? callback() : null, showDialog = false">
                                <translate>
                                    Cancel
                                </translate>
                            </v-btn>
                        </v-card-actions>
                    </v-layout>
                </v-card>
            </v-flex>
            <v-flex xs5>
                <template v-if="annotationWindowVisible">
                    <time-tracking :audit-action="auditAction" :user="user" :line-stops="selectedTimeTrackingHistory" :showStopDetails="false" noTitleBar @complete="setDialog($event)"></time-tracking>
                </template>
            </v-flex>
        </v-layout>
    </v-dialog>
</template>

<script>

    import TimeTracking from "@/components/TimeTracking"
    import TimeSpanPicker from "@/components/utilities/TimeSpanPickerDialog"
    import DateTimeUtils, {TimeSpan} from '@/api/datetimeutils'

    export default {
        name: "TimeTrackingAdjustmentDialog",
        components: {TimeTracking,TimeSpanPicker},
        data() {
            return {
                showDialog: false,
                user: "",
                timeWindow: new TimeSpan(), //Time window options
                customTimePicker:   //Object used to manage the advanced time span picker dialog
                    {
                        show: false,
                        span: new TimeSpan(),
                    },
                timeTrackingHistory: [],
                timeTrackingDetails: {},
                selectedTimeTrackingHistory: [],
                selectAllTimeTrackingHistory: false,
                annotationWindowVisible: false,
                loading: false,
                callback: null,
                title: this.$gettext("Change time tracking"),
                auditAction: "",
                root: null
            }
        },
        computed: {
            displayedTimeWindow() {
                return this.timeWindow.toString();
            },
            timeTrackingHistoryDuration() {
              let duration =0;
              for ( let timeTracking of this.timeTrackingHistory) {
                  duration += (timeTracking.stop - timeTracking.start);
              }
              return Math.round(duration / 60000);
            },
        },
        methods: {
            setVueInstance(instance) {
                this.root = instance;
            },
            Show(user, callback) {

                if(!this.$license.hasPerformance())
                    return;

                if(!event)
                    return;

                this.showDialog = true;
                this.user = user;
                this.callback = callback;

                this.fetchData();
            },

            formatTimeTrackingHistoryItem(timeTrackingItem) {
                return ["{0} \n".format(timeTrackingItem.timeTrackingId), this.$timeTracking.formatStop(timeTrackingItem)];
            },

            toggleAllTimeTrackingHistory() {
                this.selectAllTimeTrackingHistory = !this.selectAllTimeTrackingHistory;
                for (let stop of this.timeTrackingHistory) {
                    stop.selected = this.selectAllTimeTrackingHistory;
                }
            },

            processTimeTracking(selectedTimeTracking) {
                this.annotationWindowVisible = true;
                this.selectedTimeTrackingHistory.clear();
                if (selectedTimeTracking) {
                    this.selectedTimeTrackingHistory.push(selectedTimeTracking);
                } else {
                    for (let timeTracking of this.timeTrackingHistory) {
                        if (timeTracking.selected) {
                            this.selectedTimeTrackingHistory.push(timeTracking);
                        }
                    }
                }
            },

            fetchData() {
                let self = this;
                this.auditAction = this.$audits.items().timeTrackingAdjustement;
                this.timeTrackingHistory.clear();
                let loaderDelayer = setTimeout(() => {
                    self.loading = true;
                }, 300);

                this.$timeTracking.getHistory(DateTimeUtils.getRfc3339TimeStamp(this.timeWindow.getStart(true)), DateTimeUtils.getRfc3339TimeStamp(this.timeWindow.getEnd()))
                    .then(timesTracking => {

                        for (let i = 0; i < timesTracking.length; i++) {
                            self.timeTrackingHistory.push({
                                start: timesTracking[i].start,
                                stop: timesTracking[i].stop,
                                duration: timesTracking[i].duration,
                                timeTrackingId: timesTracking[i].timeTrackingId,
                                selected: false,
                            });
                        }
                    })
                    .finally(() => {
                        clearTimeout(loaderDelayer);
                        self.loading = false;
                    });
            },

            setDialog(result) {
                this.annotationWindowVisible = false;
                if (result) {
                    for (let stop of this.selectedTimeTrackingHistory) {
                        this.timeTrackingHistory.removeItem(stop);
                    }
                }
            },

            /** TIME WINDOW MANAGEMENT **/
            zoomIn() {
                this.timeWindow.zoomIn();
                this.fetchData();
            },
            zoomOut() {
                this.timeWindow.zoomOut();
                this.fetchData();
            },
            openTimeWindowPicker() {
                //Ensure that data in time picker are copied from widget time window and not assigned, otherwise after the first run
                //It will be no more possible to cancel the time picker without affecting the widget time span
                //this.customTimePicker.span = this.properties.timeWindow; //No no no
                this.customTimePicker.span.from(this.timeWindow);
                this.customTimePicker.show = true;
            },
            customTimePickerResult(result) {
                this.customTimePicker.show = false;
                if (result) {
                    //Ensure that data from time picker are copied to widget time window and not assigned, otherwise after the first run
                    //It will be no more possible to cancel the time picker without affecting the widget time span
                    //this.scope().properties.timeWindow = this.customTimePicker.span; //No no no
                    this.timeWindow.from(this.customTimePicker.span);
                }
                this.fetchData();   //Execute a refresh anyway when getting back to view
            },
        }
    }
</script>

<style scoped>

</style>
