<template>
    <DynamicElementBase>
        <v-layout wrap row style="width:100%" id="visualizer">
            <v-flex v-show="!expandVisualizer" :xs6="selectedMeasurement" :xs12="!selectedMeasurement">
                <v-data-table :rows-per-page-items="[5]"
                          :headers="headers"
                          :items="actions"
                          class="elevation-0 subheading ma-1"
                          :style="'overflow: auto; height: ' + (containerHeight - 10) + 'px'">
                    <template slot="headerCell" slot-scope="props">
                        <v-layout row v-if="props.header.text === 'go'">
                            <div class="text-xs-left" style="padding: 0px; margin:0px">
                                <vue-excel-xlsx
                                        :data="actions"
                                        :columns="exportFields"
                                        :fileName="exportFileName"
                                        :sheetName="exportWorksheet"
                                >
                                    <v-btn >
                                        <av-icon color="blue" medium left>fas fa-file-excel</av-icon>Export
                                    </v-btn>
                                </vue-excel-xlsx>
                            </div>
                            <v-menu v-if="showFiltersControl" :close-on-click="true" :close-on-content-click="false"  offset-y transition="slide-y-transition" left  >
                                <v-btn slot="activator" fab small class="btn">
                                    <v-badge style="font-size: inherit;" v-if="Array.isUseful(filters)" color="grey lighten-1">
                                        <template v-slot:badge>
                                            <span>{{filters.length}}</span>
                                        </template>
                                        <av-icon style="font-size: inherit;">fas fa-filter</av-icon>
                                    </v-badge>
                                    <av-icon size="20" v-if="!Array.isUseful(filters)">fas fa-filter fa-5x</av-icon>
                                </v-btn>
                                <v-list class="ma-2">
                                    <v-list-tile>
                                        <v-list-tile-content>
                                             <span class="title">
                                                Filter actions to show:
                                            </span>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <hr/>
                                    <v-select chips multiple v-model="filters" :items="actionTypes" />
                                </v-list>
                            </v-menu>
                        </v-layout>
                        <template v-else>
                          {{ props.header.text }}
                        </template>
                    </template>
                    <template v-slot:items="props">
                        <tr >
                            <td style="width: 18%">
                                {{ new Date(props.item["@timestamp"]).format() }}
                            </td>
                            <td style="width: 10%">
                                {{ props.item.Operator }}
                            </td>
                            <td style="width: 17%">
                                {{ props.item.Name }}
                            </td>
                            <td style="width: 7%">
                                <av-icon v-if="props.item.Result" color="green" small>fa-check</av-icon>
                                <av-icon v-else color="red" small>fa-times</av-icon>
                            </td>
                            <td style="width: 40%">
                                {{ props.item.Annotation }}
                            </td>
                            <td style="width: 8%">
                                <v-btn icon @click="selectMeasurementItem(props.item)">
                                    <av-icon small>fa fa-arrow-right</av-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex :xs6="!expandVisualizer" :xs12="expandVisualizer" v-show="selectedMeasurement" :style="!expandVisualizer?'border-left: 1px solid #e0e0e0':''">
                <FormVisualizer :form="selectedMeasurement" :maxHeight="containerHeight"
                                :showCloseButton="true" @closed="onCloseVisualizer" style="overflow: hidden;"
                :showExpandButton="true" @expand="onExpandVisualizer"/>
            </v-flex>
        </v-layout>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
    import DateTimeUtils from '@/api/datetimeutils'
    import FormVisualizer from '@/components/dynamic-elements/forms/FormVisualizer'

    export default {
        name: "WidgetActionsRegister",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase, FormVisualizer
        },
        data () {
            return {
                actions: [],
                selectedMeasurement: null,
                actionTypes: [],
                filters: [],
                parametricFilters: [],
                expandVisualizer:false,
                headers: [
                    {
                        text: 'TimeStamp',
                        align: 'left',
                        sortable: true,
                        value:"@timestamp"
                    },
                    {
                        text: 'Operator',
                        align: 'left',
                        sortable: true,
                        value:"operator"
                    },
                    {
                        text: 'Measure',
                        align: 'left',
                        sortable: true,
                        value:"name"
                    },
                    {
                        text: 'Result',
                        align: 'left',
                        sortable: true,
                        value:"result"
                    },
                    {
                        text: 'Annotation',
                        align: 'left',
                        sortable: true,
                        value:"annotation"
                    },
                    {
                        text: 'go',
                        align: 'left',
                        sortable: false,
                        value:""
                    }
                ]
            }
        },
        props: {
            user: {
                type: String,
                default: ""
            }
        },
        created() {
            this.childHandlers.getCustomDescriptor = () => {return this.filters};
            this.childHandlers.setCustomDescriptor = (filters) => {this.filters = filters};
            this.childHandlers.onGlobalFiltersReceived = this.onGlobalFiltersReceived;
        },
        mounted() {
            this.customQueryHandler = this.getMeasurements;
            this.childHandlers.onTimeWindowUpdate = this.loadActionsTypes;
            this.childHandlers.onNewElementCreated.push(this.loadActionsTypes);
            this.childHandlers.onElementLoaded.push(this.loadActionsTypes);
            this.properties.autoRefresh = false; //Do not update periodically
            //We don't require any data sourcing (at moment)
            this.dataExplorationMode.noDataExploration = true;
            this.scope().childHandlers.checkItemUsefulness = () => { return true; };
            this.visualizationTweaks = [
                {
                    name: this.$gettext("Apply selected filters statically"),
                    id: "fixedFilters",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                }
            ];
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    this.saveTweaks();
                },
                deep: true
            },
            filters: {
                handler: function () {
                    this.dataRefresh();
                    if (this.editMode)
                        this.saveElement();
                },
                deep: true
            }
        },
        computed: {
            showFiltersControl() {
                return this.editMode || !this.getTweakValue("fixedFilters");
            },
            exportFields() {
                if(this.actions && this.actions.length) {
                    let returning =[{label: "Date",  field: "@timestamp"},
                        {label: "Operator",  field: "Operator"},
                        {label: "Measure",    field: "Name"},
                        {label: "Result",  field: "Result"},
                        {label: "Annotation",  field: "Annotation"},
                    ];
                    return returning;
                }
                return [];
            },
            exportWorksheet() {
                return "{appName} actions".format({appName: this.$config.appName});
            },
            exportFileName() {
                return "{appName} actions ".format({appName: this.$config.appName}) + (new Date()).format("yyyyMMddHHmmss");
            },
        },
        methods: {
            loadActionsTypes() {
                let self = this;
                let from = this.isEditMode ? null : this.properties.timeWindow.getStart();
                let to = this.isEditMode ? null : this.properties.timeWindow.getEnd();
                this.$dataEntry.loadActionsNames(from, to)
                    .then(actionTypes => {
                        self.actionTypes = actionTypes;
                    })
            },
            getMeasurements(){
                let self = this;
                this.actions = [];
                this.$dataEntry.getMeasurements(DateTimeUtils.getRfc3339TimeStamp(this.properties.timeWindow.getStart()), DateTimeUtils.getRfc3339TimeStamp(this.properties.timeWindow.getEnd()), this.filters, this.parametricFilters)
                    .then(t => {
                        if(Array.isUseful(t))
                            self.actions = t.filter(res => Object.isUseful(res["@timestamp"]));
                    });
            },
            selectMeasurementItem(value) {
                this.selectedMeasurement = value;
            },
            onGlobalFiltersReceived(globalFilters) {
                debugger
                this.parametricFilters.clear();
                for(let globalFilter in globalFilters) {
                    if(globalFilter !== "time")
                        this.parametricFilters.push(globalFilters[globalFilter]);
                }
            },
            onExpandVisualizer(event){
                this.expandVisualizer=event
            },
            onCloseVisualizer(){
                this.expandVisualizer=false;
                this.selectedMeasurement = null
            }
        }
    }

</script>

<style scoped>

</style>
