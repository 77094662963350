<template>
    <DynamicElementBase>
        <template v-if="chartType === 0">
            <div ref="container" style="height: 100%; width: 100%; overflow: hidden; text-align: center;" >
                <center :style="'margin-top: {0}px'.format(properties.showTitleBar ? 5 : 15)"><span class="title">{{value}} {{units}} {{percentString}}</span></center>
                <radial-gauge style="width: 100%;" ref="gaugeElement1" :value="indexValue" :options="RadialGaugeOptions"></radial-gauge>
            </div>
        </template>
        <template v-else-if="chartType === 1">
            <div ref="container" style="height: 100%; width: 100%; overflow: hidden; text-align: center;" >
                <center :style="'position: relative; left: 0; z-index: 1; margin-top: {0}px'.format(properties.showTitleBar ? 5 : 15)"><span class="title">{{value}} {{units}} {{percentString}}</span></center>
                <linear-gauge :style="'width: 100%; margin-top: {0}px; z-index: 1'.format(labelToGaugeGap)" ref="gaugeElement1" :value="indexValue" :options="LinearGaugeOptions"></linear-gauge>
            </div>
        </template>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase'
    import RadialGauge from 'vue2-canvas-gauges/src/RadialGauge'
    import LinearGauge from 'vue2-canvas-gauges/src/LinearGauge'

    let Targets = { "value": 0, "min": 1, "max": 2, "expected": 3 };


    export default {
        name: 'WidgetGauge',
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            RadialGauge,
            LinearGauge
        },
        data () {
            return {
                dataCollection: null,
                firstRun: true,
                gaugeInitStep: 2,
                gaugeInitializer: null,
                tweaksSaveDebouncer: null,
                previousWidgetSize: {width: -1, height: -1},
                colorMode: 0,
                min: 0,
                max: 0,
                expected: 0,
                value: 0,
                RadialGaugeOptions: { // https://canvas-gauges.com/documentation/user-guide/configuration
                    units: '',
                    title: "",
                    minValue: 0,
                    maxValue: 100,
                    width: 300,
                    height: 300,
                    exactTicks: true,
                    startAngle: 90,
                    ticksAngle: 180,
                    colorMajorTicks: "rgba(0,0,0,0)",
                    strokeTicks: false,
                    highlights: [
                        {"from": 0, "to": 30, "color": this.$avStyle.colors.red},
                        {"from": 30, "to": 50, "color": this.$avStyle.colors.yellow},
                        {"from": 50, "to": 70, "color": this.$avStyle.colors.lightgreen},
                        {"from": 70, "to": 100, "color": this.$avStyle.colors.green},
                    ],
                    majorTicks: [0, 30, 50, 70, 100],
                    minorTicks: "[]",
                    borderShadowWidth: 0,
                    borders: false,
                    needle: true,
                    animationDuration: 1500,
                    animationRule: 'bounce',
                    animateOnInit: true,
                    colorBar: 'grey',
                    colorBarProgress: 'blue',
                    valueBox: false,
                },
                LinearGaugeOptions: {
                    units: '',
                    title: "",
                    minValue: 0,
                    maxValue: 100,
                    width: 150,
                    height: 150,
                    exactTicks: true,
                    colorMajorTicks: "rgba(0,0,0,0)",
                    strokeTicks: false,
                    highlights: [{"from": 0, "to": 60, "color": this.$avStyle.colors.red}, {
                        "from": 60,
                        "to": 80,
                        "color": this.$avStyle.colors.yellow
                    }, {
                        "from": 80,
                        "to": 100,
                        "color": this.$avStyle.colors.green
                    }],
                    highlightsWidth: 10,
                    majorTicks: [0, 20, 40, 60, 80, 100],
                    minorTicks: "[]",
                    borderShadowWidth: 0,
                    borders: false,
                    needle: true,
                    animationDuration: 500,
                    animationRule: 'linear',
                    animationTarget: "plate",
                    animateOnInit: true,
                    colorBar: 'grey',
                    colorBarProgress: 'blue',
                    colorPlate: "#fff",
                    barBeginCircle: false,
                    tickSide: "left",
                    numberSide: "right",
                    numbersMargin: -30,
                    needleSide: "left",
                    needleType: "arrow",
                    needleWidth: 10,
                    colorNeedle: "#222",
                    colorNeedleEnd: "#222",
                    barWidth: 0,
                    ticksWidth: 20,
                    valueBox: false
                },
            }
        },
        beforeDestroy: function() {
            //When widget is no more visualized stop timers otherwise they will survive forever
            clearInterval(this.gaugeInitializer);
        },
        mounted: function() {
            //Enumerate all the possible tweaks and assign saved values, later will manage
            //the dependencies
            this.visualizationTweaks = [
                {
                    name: this.$gettext("Gauge type"),
                    id: "gaugeType",
                    type: "indexed_option",
                    options: [this.$gettext("Radial gauge"), this.$gettext("Linear gauge")],
                    default: function() {
                        return 0;
                    },
                },
                {
                    name: this.$gettext("Units to show"),
                    id: "units",
                    type: "string",
                    default: function() {
                        return "";
                    },
                },
                {
                    name: this.$gettext("Min value"),
                    id: "minValue",
                    type: "number",
                    default: function() {
                        return 0;
                    },
                },
                {
                    name: this.$gettext("MAX value"),
                    id: "maxValue",
                    type: "number",
                    default: function() {
                        return 100;
                    },
                },
                {
                    name: this.$gettext("Coloring mode"),
                    id: "colorMode",
                    type: "indexed_option",
                    options: [this.$gettext("Symmetric"), this.$gettext("Stepped"), this.$gettext("Progress")],
                    default: function() {
                        return 0;
                    },
                },
                {
                    name: this.$gettext("Expected value (center of green)"),
                    id: "expectedValue",
                    type: "number",
                    hidden: false,
                    default: function() {
                        return 50;
                    },
                },
                {
                    name: this.$gettext("Expected tolerance (width of green)"),
                    id: "expectedTolerance",
                    type: "number",
                    hidden: false,
                    default: function() {
                        return 20;
                    },
                },
                {
                    name: this.$gettext("Warning tolerance (width of yellow)"),
                    id: "warningTolerance",
                    type: "number",
                    hidden: false,
                    default: function() {
                        return 40;
                    },
                },
                {
                    name: this.$gettext("Tolerances as percentage of expected value"),
                    id: "percentualTolerances",
                    type: "bool",
                    hidden: false,
                    default: function() {
                        return false;
                    },
                },
                {
                    name: this.$gettext("Red start point"),
                    id: "redStart",
                    type: "number",
                    hidden: true,
                    default: function () {
                        return 0
                    },
                },
                {
                    name: this.$gettext("Yellow start point"),
                    id: "yellowStart",
                    type: "number",
                    hidden: true,
                    default: function () {
                        return 0
                    },
                },
                {
                    name: this.$gettext("Green start point"),
                    id: "greenStart",
                    type: "number",
                    hidden: true,
                    default: function () {
                        return 50
                    },
                }
            ];

            this.visualizationTargets = [
                { show: this.$gettext("Value"), id: Targets.value, default: true },
                { show: this.$gettext("Min value"), id: Targets.min, default: false },
                { show: this.$gettext("MAX value"), id: Targets.max, default: false },
                { show: this.$gettext("Expected value"), id: Targets.expected, default: false }
            ];

            this.preferredAggregations = [ this.$defines.allAggregations.last.id ];

            //Canvas gauges has some issue with data binding, thus first data value change is lost
            //and gauge keeps hanged to zero value until value changes again.
            //This workaround forces a first data change
            this.gaugeInitializer = setInterval((function(self) {
                return function() {
                    if (self.gaugeInitStep === 2) {
                        self.value = 1;
                        self.gaugeInitStep = 1;
                    }
                    else if (self.gaugeInitStep === 1) {
                        self.value = 0;
                        self.gaugeInitStep = 0;
                    }
                    else
                        //clearInterval(self.gaugeInitializer);
                        self.resize();
                }
            })(this), 1000);
        },
        watch: {
            visualizationTweaks: {
                handler: function () {

                    let self = this;
                    clearInterval(this.tweaksSaveDebouncer);    //Stop any previous pending save

                    //Update visualization tweaks values and save widget, we use a debouncer
                    //to avoid continuous saves while typing in fields
                    this.tweaksSaveDebouncer = setInterval((function() {
                        return function() {
                            self.min = parseInt(self.getTweakValue("minValue"));
                            self.max = parseInt(self.getTweakValue("maxValue"));
                            self.colorMode = self.getTweakValue("colorMode");
                            self.getTweak("expectedValue").hidden = (self.colorMode === 1 || self.colorMode === 2);
                            self.getTweak("expectedValue").hidden = (self.colorMode === 1 || self.colorMode === 2);
                            self.getTweak("expectedTolerance").hidden = (self.colorMode === 1 || self.colorMode === 2);
                            self.getTweak("warningTolerance").hidden = (self.colorMode === 1 || self.colorMode === 2);
                            self.getTweak("percentualTolerances").hidden = (self.colorMode === 1 || self.colorMode === 2);
                            self.getTweak("redStart").hidden = (self.colorMode === 0 || self.colorMode === 2);
                            self.getTweak("yellowStart").hidden = (self.colorMode === 0 || self.colorMode === 2);
                            self.getTweak("greenStart").hidden = (self.colorMode === 0 || self.colorMode === 2);

                            if(self.colorMode === 0) {
                                self.expected = parseInt(self.getTweakValue("expectedValue"));
                            } else if(self.colorMode === 1) {
                                self.expected = parseInt(self.getTweakValue("greenStart"));
                            } else self.expected = 0; //Data binded
                            self.resize(true);
                            self.colorize();
                            self.saveTweaks();
                            clearInterval(self.tweaksSaveDebouncer);
                        }
                    })(), 1000);
                },
                deep: true,
            },
        },
        computed: {
            chartType() {
                return this.getTweakValue("gaugeType");
            },
            units() {
                return this.getTweakValue("units");
            },
            percentString() {
                if(this.colorMode === 2)
                    return "({0}%)".format(this.$utils.roundToDigits((this.indexValue / this.max) * 100, 1));
                return "";
            },
            indexValue() {
                let value = this.value;
                if(this.value && isNaN(this.value)) {
                    let parsed = parseInt(this.value);
                    if(isNaN(parsed)) {
                        parsed = this.value.match(/(\d+)/);
                        if(Array.isUseful(parsed))
                            value = parseInt(parsed[0]);
                    } else value = parsed
                }
                if(value > parseInt(this.max))
                    return parseInt(this.max);
                else return value;
            },
            labelToGaugeGap() {
                if(this.containerWidth > this.containerHeight)
                    return -50;
                else return -20
            }
        },
        methods: {
            widgetSize() {
                return {width: this.containerWidth, height: this.containerHeight };
            },
            resize(force) {
                if(!this.$refs.gaugeElement1)
                    return;
                let boxSize = this.widgetSize();
                if(boxSize.height !== this.previousWidgetSize.height || boxSize.width !== this.previousWidgetSize.width || force) {
                    this.previousWidgetSize.height = boxSize.height;
                    this.previousWidgetSize.width = boxSize.width;
                    if(this.chartType === 0) {
                        let size = boxSize.width > (boxSize.height - 30) * 2 ? (boxSize.height - 30) * 2 : boxSize.width;
                        this.$refs.gaugeElement1.chart.update({
                            width: size * 0.8,
                            height: size * 0.8,
                        });
                    } else if(this.chartType === 1) {
                        if(boxSize.width > boxSize.height)
                            this.$refs.gaugeElement1.chart.update({
                                width: boxSize.width * 0.95,
                                height: 200
                            });
                        else
                            this.$refs.gaugeElement1.chart.update({
                                width: 200,
                                height: boxSize.height * 0.95
                            });
                    }
                }
            },
            refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view

                if(dataValues.length === 0) {
                    this.value = 0;
                    return;
                }

                let valueFound = false;
                let needsColorize = false;
                for(let dataSet of dataValues) {
                    if(!Array.isUseful(dataSet.data)) {
                        if (dataSet.target === Targets.value && !valueFound)
                            this.value = 0;
                        continue;
                    }
                    let dataSetValue = dataSet.data.last().y;
                    if (dataSet.target === Targets.min && dataSetValue !== this.min) {
                        this.min = dataSetValue;
                        needsColorize = true;
                    }
                    if (dataSet.target === Targets.max && dataSetValue !== this.max) {
                        this.max = dataSetValue;
                        needsColorize = true;
                    }
                    if (dataSet.target === Targets.expected && dataSetValue !== this.expected) {
                        this.expected = dataSetValue;
                        needsColorize = true;
                    }
                    if (dataSet.target === Targets.value) {
                        this.value = dataSetValue;
                        valueFound = true;
                        if(this.colorMode === 2)
                            needsColorize = true;
                    }
                }

                if(needsColorize)
                    this.colorize();

                this.$refs.gaugeElement1.chart.update();
            },
            colorize() {

                if(!this.$refs.gaugeElement1)
                    return;

                let majorTicks = [];
                let highlights = [];
                let needle = true;

                majorTicks.push(this.min);
                majorTicks.push(this.max);

                if(this.colorMode === 0) {
                    let expectedTolerance = parseInt(this.getTweakValue("expectedTolerance"));
                    let warningTolerance = parseInt(this.getTweakValue("warningTolerance"));
                    let isPercentualTolerance = this.getTweakValue("percentualTolerances");
                    if(isPercentualTolerance) {
                        expectedTolerance = this.expected / 100 * expectedTolerance;
                        warningTolerance = this.expected / 100 * warningTolerance;
                    }
                    let expectedStart = Math.round(this.expected - (expectedTolerance / 2));
                    let warningStart = warningTolerance !== 0 ? Math.round(this.expected - (warningTolerance / 2)) : expectedStart;
                    let expectedEnd = Math.round(this.expected + (expectedTolerance / 2));
                    let warningEnd = warningTolerance !== 0 ? Math.round(this.expected + (warningTolerance / 2)) : expectedEnd;
                    if (this.min > warningStart)
                        this.min = warningStart;
                    if (this.max < warningEnd)
                        this.max = warningEnd;
                    highlights.push({
                        "from": expectedStart,
                        "to": expectedEnd,
                        "color": this.$avStyle.colors.green
                    });
                    majorTicks.push(expectedStart);
                    if(warningTolerance > 0) {
                        highlights.push({
                            "from": warningStart,
                            "to": expectedStart,
                            "color": this.$avStyle.colors.yellow
                        });
                        majorTicks.push(warningStart);
                        highlights.push({
                            "from": expectedEnd,
                            "to": warningEnd,
                            "color": this.$avStyle.colors.yellow
                        });
                    }
                    majorTicks.push(expectedEnd);
                    highlights.push({"from": this.min, "to": warningStart, "color": this.$avStyle.colors.red});
                    highlights.push({"from": warningEnd, "to": this.max, "color": this.$avStyle.colors.red});
                    majorTicks.push(warningEnd);
                    majorTicks.push(this.expected);
                } else if(this.colorMode === 1) {
                    let bands = [];
                    let redStart = parseInt(this.getTweakValue("redStart"));
                    let yellowStart = parseInt(this.getTweakValue("yellowStart"));
                    if(yellowStart !== 0 && yellowStart !== redStart && yellowStart !== this.expected)
                        bands.push({color: this.$avStyle.colors.yellow, value: yellowStart});
                    bands.push({color: this.$avStyle.colors.red, value: redStart});
                    bands.push({color: this.$avStyle.colors.green, value: this.expected});
                    bands.sortOnProperty("value");
                    bands.push({value: this.max});
                    for(let i = 0 ; i < bands.length - 1 ; i++) {
                        highlights.push({
                            "from": bands[i].value,
                            "to": bands[i + 1].value,
                            "color": bands[i].color
                        });
                        majorTicks.push(bands[i].value);
                    }
                } else if(this.colorMode === 2) {
                    highlights.push({
                        "from": this.min,
                        "to": this.indexValue,
                        "color": this.$avStyle.colors.green
                    });
                    //majorTicks.push(this.indexValue);
                    highlights.push({
                        "from": this.indexValue,
                        "to": this.max,
                        "color": this.$avStyle.colors.lightGrey
                    });
                    needle = false;
                }
                this.$refs.gaugeElement1.chart.update({
                    minValue: this.min,
                    maxValue: this.max,
                    majorTicks: majorTicks,
                    highlights: highlights,
                    needle: needle
                });
            }
        },
    }
</script>

<style scoped>



</style>