<template>
    <RuleBase />
</template>

<script>

    import RuleBase from '@/components/dynamic-elements/rules/RuleBase.vue';
    import RulesEngine from '@/api/rules.js';

    export default {
        name: "RuleTimeTracking",
        extends: RuleBase,
        components: {
            RuleBase
        },
        data() {
            return {
                eventType: 0,
                eventTypes: {
                    stopped: 0,
                    unscheduled: 1,
                    custom: 2
                }
            }
        },
        computed: {
            indices() {
                return this.properties.dataPatterns;
            }
        },
        mounted() {
            this.fixedHeight = false;
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], false));
            this.dataExplorationMode.autoDataSourcing = true;
            this.dataExplorationMode.deviceSelectMode = false;
            this.dataExplorationMode.requiresCrossAggregation = false;
            this.dataExplorationMode.requiresFunctions = false;
            this.properties.ruleScheduling.cron = "*/1 * * * *"; //Check every minute by default
            this.childHandlers.inLineDataTransformer = this.showAsTime;
            // For future implementations
            this.visualizationTweaks = [
                {
                    name: this.$gettext("Predefined events to notify"),
                    id: "NotifyOn",
                    type: "option",
                    options: ["Line stopped (but should produce)", "Line unscheduled (no workorder assigned)", "Specific stop cause (configure it in filters section)"],
                    default: function() {
                        return this.options[0];
                }
            }];
        },
        watch: {
            indices() {
                if(!this.loading)
                    this.createDataItems();
            },
            visualizationTweaks: {
                handler: function () {
                    // Event type change must trigger a recalculation of rule conditions
                    let previousEventType = this.eventType;
                    this.eventType = this.visualizationTweaks[0].options.indexOf(this.getTweakValue("NotifyOn"));
                    if(this.eventType !== previousEventType)
                        this.createDataItems();

                    this.saveTweaks();
                },
                deep: true,
            },
        },
        methods: {
            lineStoppedFilters() {
                return {
                    index: this.indices[0] ? this.indices[0] : "production counters@5s",
                    root: "TimeTracking",
                    name: 'Activity',
                    type: 'integer',
                    selectedForFiltering: true,
                    filters: [
                        {
                            conditions: [{operator: '!=', value: 0}, {operator: '!=', value: 1}],
                            defaultName: "Line stopped",
                            name: "Line stopped",
                            enabled: true,
                            filterId: "Line stopped"
                        }
                    ],
                }
            },
            lineUnscheduledFilters() {
                return {
                    index: this.indices[0] ? this.indices[0] : "production counters@5s",
                    root: "TimeTracking",
                    name: 'Activity',
                    type: 'integer',
                    selectedForFiltering: true,
                    filters: [
                        {
                            conditions: [{operator: '=', value: 0}],
                            defaultName: "Line unscheduled",
                            name: "Line unscheduled",
                            enabled: true,
                            filterId: "Line unscheduled"
                        }
                    ],
                }
            },
            customTimeTrackerFilter() {
                return {
                    index: this.indices[0] ? this.indices[0] : "production counters@5s",
                    root: "TimeTracking",
                    name: 'Activity',
                    type: 'integer',
                    selectedForFiltering: true,
                    filters: [
                        {
                            conditions: [{operator: '=', value: 2}],
                            defaultName: "Custom time tracking filter",
                            name: "Custom time tracking filter",
                            enabled: true,
                            filterId: "Custom time tracking filter"
                        }
                    ],
                }
            },
            activeCondition(variable) {
                let windowSizeMillis = parseInt((Date.parse(this.properties.timeWindow.getEnd()) - Date.parse(this.properties.timeWindow.getStart())));
                return [
                    {
                        variable: variable,
                        operator: ">=",
                        value: Math.floor(windowSizeMillis / 60000)
                    }
                ]
            },
            createDataItems() {
                this.dataItems.clear();
                this.filterItems.clear();
                this.aggregationItems.clear();
                this.rules.clear();
                this.dataItems.push( {
                    index: 'production counters@5s',
                    root: 'TimeTracking',
                    name: 'Activity',
                    type: 'integer',
                    representations: [
                    {
                        type: this.$defines.allAggregations.count.id,
                        name: 'Duration (minutes)',
                        id: Date.now(),
                        aggregationWindow: 0,
                        normalization: "(value * 5) / 60",
                        target: 0
                    }]
                 });

                if(this.eventType === this.eventTypes.stopped)
                    this.filterItems.push(this.lineStoppedFilters());
                else if(this.eventType === this.eventTypes.unscheduled)
                    this.filterItems.push(this.lineUnscheduledFilters());
                else if(this.eventType === this.eventTypes.custom)
                    this.filterItems.push(this.customTimeTrackerFilter());

                this.dataItems[0].representations[0].filters = [ this.filterItems[0].filters[0].filterId ];


                let variables = RulesEngine.getVariablesDescriptors(this.dataItems);
                this.rules.push( {
                    name: "Time overdue",
                    type: "expression",
                    value: [ this.activeCondition(variables[0]) ]
                });

                this.$emit('dataItemsUpdated');
            },

            showAsTime(dataValues) {
                if(Array.isUseful(dataValues) && Array.isUseful(dataValues[0].data))
                    for(let value of dataValues[0].data)
                        value.y = this.$utils.roundToDigits(value.y, 1) + " Minutes";
                return dataValues;
            }
        }
    }

</script>

<style scoped>
    table th { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table td { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
    table tr { border:3px solid #dddddd; height: 50px!important; max-height: 50px!important; overflow:hidden; word-wrap:break-word; white-space: nowrap}
</style>