<template>
    <v-menu
            v-model="menuOpened"
            :close-on-content-click="false"
            lazy
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            full-width
            max-width="290px"
            min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field
                class="osk"
                :value="options[value]"
                :label="$i18n.CommonAttributes()['click to set']"
                :prepend-icon="icon || ''"
                readonly
                v-on="on"
            ></v-text-field>
        </template>
        <v-card class="pa-2">
            <v-radio-group v-model="value" :style="(dialog ? 'background-color: white' : '')">
                <v-radio
                        v-for="(option, index) in options"
                        :key="index"
                        :label="option"
                        :value="index"
                        @change="selectedItemChanged(index), menuOpened=false"
                ></v-radio>
            </v-radio-group>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: "PopUpRadioGroupPicker",
        data () {
            return {
                menuOpened: false,
            }
        },
        props: {
            options: {
                type: Array,
                required: true,
                validator: function (value) {
                    return value.length > 0;   //TODO verify if an explicit boolean is required
                }
            },
            value:  [Number],
            icon: null,
            dialog: false
        },
        methods: {
            selectedItemChanged(index) {
                this.$emit('input', index);
                this.$emit('change', index);
            }
        }
    }
</script>

<style scoped>

</style>