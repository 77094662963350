<template>
    <v-container fill-height pa-0>
        <TimeSpanPicker v-if="customTimePicker.show" :showDialog="customTimePicker.show" :timespan="customTimePicker.span" @exit="customTimePickerResult($event)" />
        <template>
            <v-tooltip bottom>
                <v-btn fab small slot="activator" @click="zoomIn" id="btn-time-picker-zoom-in">
                    <av-icon>fas fa-search-plus</av-icon>
                </v-btn>
                <span>Zoom In</span>
            </v-tooltip>
            <v-tooltip bottom>
                <v-btn  style="cursor:default; margin-left: 7px; margin-right: 7px" small slot="activator" @click="openTimeWindowPicker" id="btn-time-picker-window">
                    {{displayedTimeWindow}}
                </v-btn>
                <span v-translate>Current time window</span>
            </v-tooltip>
            <v-tooltip bottom>
                <v-btn fab small slot="activator" @click="zoomOut" id="btn-time-picker-zoom-out">
                    <av-icon>fas fa-search-minus</av-icon>
                </v-btn>
                <span v-translate>Zoom Out</span>
            </v-tooltip>
            <v-tooltip bottom v-if="showRefreshBtn">
                <v-btn fab small slot="activator" @click="refreshData(true)" id="btn-time-picker-refresh">
                    <av-icon>fas fa-sync-alt</av-icon>
                </v-btn>
                <span v-translate>Refresh data</span>
            </v-tooltip>
        </template>
    </v-container>
</template>

<script>
    import TimeSpanPicker from "./TimeSpanPickerDialog"
    import {TimeSpan} from '@/api/datetimeutils'

    export default {
            name: "TimePicker",
        components: {TimeSpanPicker},
        props: {
            callBack : Function,
            timeWindow: {
              type: TimeSpan,
              default: () => new TimeSpan()
            },
            showRefreshBtn: {
                type: Boolean,
                default: false
            },
            rowKey: {
                type: Number,
            },
            filterItem: {
                type: Object,
            },
            applyTimeWindow: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                customTimePicker:   //Object used to manage the advanced time span picker dialog
                    {
                        show: false,
                        span: new TimeSpan(),
                    },
                isTimeWindowIndexed: false,
            }
        },
      mounted() {
          if (this.applyTimeWindow) {
              if (this.applyTimeWindow.start || this.applyTimeWindow.end) {
                  this.timeWindow.start = this.applyTimeWindow.start;
                  this.timeWindow.end = this.applyTimeWindow.end;
                  this.timeWindow.predefined = null;
                  this.timeWindow.predefinedIndex= -1;
              }
              else{
                this.timeWindow.setPredefined(this.applyTimeWindow.predefined)
              }
          }
      },
    computed: {
        displayedTimeWindow() {
            return this.timeWindow.toString() + (this.isTimeToActive ? ` + ` + this.timeWindow.timeToToString() : "") + (this.isTimeWindowIndexed ? ` - ` + this.timeWindow.fromToToString() : "")
        },
        isTimeToActive() { return this.timeWindow.timeToPredefined && this.timeWindow.timeToPredefined !== 'now' && this.timeWindow.predefined !== 'ever' },
    },
        methods: {
            /** TIME WINDOW MANAGEMENT **/
            zoomIn() {
                this.timeWindow.zoomIn();
                this.timeWindow.isDisabled = false;
                let timePicker = {
                    timeWindow: this.timeWindow,
                    filterItem: this.filterItem,
                    rowKey: this.rowKey,
                    start: this.timeWindow.getStart(),
                    end: this.timeWindow.getEnd()
                }
                this.callBack(timePicker)

            },
            zoomOut() {
                this.timeWindow.zoomOut();
                this.timeWindow.isDisabled = false;
                let timePicker = {
                    timeWindow: this.timeWindow,
                    filterItem: this.filterItem,
                    rowKey: this.rowKey,
                    start: this.timeWindow.getStart(),
                    end: this.timeWindow.getEnd()
                }
                this.callBack(timePicker);

            },
            openTimeWindowPicker() {
                //Ensure that data in time picker are copied from element time window and not assigned, otherwise after the first run
                //It will be no more possible to cancel the time picker without affecting the element time span
                //this.customTimePicker.span = this.properties.timeWindow; //No no no
                this.customTimePicker.span.from(this.timeWindow);
                this.customTimePicker.show = true;
            },

            customTimePickerResult(result) {
                this.customTimePicker.show = false;
                if(result) {
                    //Ensure that data from time picker are copied to element time window and not assigned, otherwise after the first run
                    //It will be no more possible to cancel the time picker without affecting the element time span
                    //this.scope().properties.timeWindow = this.customTimePicker.span; //No no no
                    this.timeWindow.from(this.customTimePicker.span);
                }
                let timePicker = {
                    timeWindow: this.timeWindow,
                    filterItem: this.timeWindow.isDisabled ? {} : this.filterItem,
                    rowKey: this.rowKey,
                    start: this.timeWindow.getStart(),
                    end: this.timeWindow.getEnd()
                }
                this.callBack(timePicker);

                //Execute a refresh anyway when getting back to view
            },
            refreshData(force = false) {
                this.callBack(force);
            }
        },
    }
</script>

<style scoped>

</style>
