<template>
    <DynamicElementBase>
        <table style="height:95%;width:100%;border-spacing: 0px;border-collapse: collapse;">
            <thead style="text-align:center;">
            <tr>
                <td style="width:30%;">&nbsp;</td>
                <td style="width:15%;">&nbsp;</td>
                <td style="width:5%;">&nbsp;</td>
                <!--<td style="width:50%;">{{statusString}}</td>-->
                <td style="width:15%;"><div class="subheading" v-translate>Time</div></td>
                <td style="width:10%;"><div class="subheading" v-translate>Quantity</div></td>
                <td style="width:10%;"></td>
                <td style="width:10%;"></td>
            </tr>
            </thead>

            <tbody style="vertical-align:bottom;text-align:right;">
            <tr style="height: 0px">
                <td><div class="negativeMargin5">{{topMostDate.text}}</div></td>
                <td><div class="negativeMargin5">{{topMostDate.value}}</div></td>
                <td>&nbsp;</td>
                <td style="background-color:transparent;">&nbsp;</td>
                <td><div class="negativeMargin5">-</div></td>
                <td><div class="negativeMargin5">-</div></td>
                <td>&nbsp;</td>
            </tr>
            <tr :style="'height:'+endDeltaPercent+'%'">
                <td><div>{{lowerTopMostDate.text}}</div></td>
                <td><div>{{lowerTopMostDate.value}}</div></td>
                <td>&nbsp;</td>
                <td :style="'background-color: ' + (late ? 'rgba(255,0,0,0.5)' : 'rgba(0,200,0,0.5)') + ';border: 1px solid lightgray;'">&nbsp;</td>
                <td><div>{{dataCollection.quantityToProduce}}</div></td>
                <td><div>100%</div></td>
                <td>&nbsp;</td>
            </tr>
            <tr :style="'height:'+remainingPercent+'%'">
                <td></td>
                <td></td>
                <td></td>
                <td style="background-color:transparent;border: 1px solid lightgray;"></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr :style="'height:'+completePercent+'%'">
                <td valign="top" ><div style="margin-top:-5px;" v-translate>Estimate for completion</div></td>
                <td valign="top" ><div style="margin-top:-5px;">{{hoursToCompletion}}</div></td>
                <td valign="top" ><div style="margin-top:-15px;"><av-icon size="35" color="green" style="text-shadow: 2px 2px 4px gray;">play_arrow</av-icon></div></td>
                <td style="background-color:var(--av-green);border: 1px solid lightgray;"></td>
                <td valign="top" ><div style="margin-top:-5px;">{{dataCollection.quantityProduced}}</div></td>
                <td valign="top" ><div style="margin-top:-5px;">{{dataCollection.progress}}%</div></td>
                <td valign="top" ></td>
            </tr>
            <tr :style="'height:'+startDeltaPercent+'%'">
                <td valign="top"><div class="negativeMargin20" v-translate>actual start</div></td>
                <td valign="top"><div class="negativeMargin20">{{actualStartView}}</div></td>
                <td valign="top">&nbsp;</td>
                <td style="background-color:lightgray;border: 1px solid lightgray;">&nbsp;</td>
                <td valign="top"><div class="negativeMargin20">0</div></td>
                <td valign="top"><div class="negativeMargin20">0%</div></td>
                <td valign="top">&nbsp;</td>
            </tr>
            <!--<tr style="height: 0px">-->
                <!--<td valign="top"><div class="negativeMargin20" v-translate>planned start</div></td>-->
                <!--<td valign="top"><div class="negativeMargin20">{{plannedStartView}}</div></td>-->
                <!--<td valign="top">&nbsp;</td>-->
                <!--<td style="background-color:transparent;">&nbsp;</td>-->
                <!--<td valign="top"><div class="negativeMargin20"></div></td>-->
                <!--<td valign="top"><div class="negativeMargin20"></div></td>-->
                <!--<td valign="top">&nbsp;</td>-->
            <!--</tr>-->
            </tbody>
        </table>
    </DynamicElementBase>
</template>

<script>

    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
    import Production from '@/api/production.js'

    export default {
        name: "WidgetSchedule",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase
        },
        data () {
            return {
                endDeltaPercent: 10,
                remainingPercent: 80,
                completePercent: 0,
                startDeltaPercent: 10,
                dataCollection: {},
                now: "",
                late: false
            }
        },
        mounted () {

            //Remove window control
            this.properties.showTimeWindow = false;
            this.properties.timeWindow.setPredefined(525600); //1 year of time for a wo, it should be enough to capture the start date
            this.properties.forcedDataRefreshInterval = 10;
            this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], true));
            this.dataExplorationMode.autoDataSourcing = true;
            this.dataExplorationMode.deviceSelectMode = false;

            this.visualizationTweaks = [
                {
                    name: this.$gettext("Calculate only good production"),
                    id: "CalculateOnlyGoodProduction",
                    type: "bool",
                    default: function() {
                        return false;
                    }
                },
            ];

            this.visualizationTargets = [
                { show: "Planned start", id: Production.targets.plannedStart },
                { show: "Actual start", id: Production.targets.actualStart },
                { show: "Planned end", id: Production.targets.plannedEnd },
                { show: "Quantity to produce", id: Production.targets.quantityToProduce },
                { show: "Quantity produced", id: Production.targets.quantityProduced },
                { show: "Production start", id: Production.targets.productionStart },
                { show: "Progress", id: Production.targets.progress },
                { show: "Status string", id: Production.targets.statusString },];
        },
        computed: {
            //device() { return this.properties.selectedDevices[0]; },
            indices() { return this.properties.dataPatterns; },
            //progress() { return Math.round((this.quantityToProduce != 0 ? (this.quantityProduced / this.quantityToProduce) * 100 : 0 )) },
            plannedStartView() { return this.formatDate(this.dataCollection.plannedStart); },
            actualStartView() { return this.formatDate(this.dataCollection.actualStart); },
            plannedEndView() { return this.formatDate(this.dataCollection.plannedEnd); },
            estimatedEndView() { return this.formatDate(this.dataCollection.estimatedEnd); },
            topMostDate() {
                if(this.dataCollection.plannedEnd <= this.dataCollection.estimatedEnd) {
                    return {text: this.$gettext("Estimated duration"), value: this.formatDuration(this.dataCollection.estimatedEnd)};
                } else {
                    return {text: this.$gettext("Planned duration"), value: this.formatDuration(this.dataCollection.plannedEnd)};
                }
            },
            lowerTopMostDate() {
                if(this.dataCollection.estimatedEnd < this.dataCollection.plannedEnd) {
                    let value;
                    if(this.dataCollection.estimatedEnd < 0)
                        value = this.$gettext("OEE too low to estimate");
                    else value = this.formatDuration(this.dataCollection.estimatedEnd);
                    return {text: this.$gettext("Estimated end"), value: value};
                } else {
                    return {text: this.$gettext("Planned duration"), value: this.formatDuration(this.dataCollection.plannedEnd)};
                }
            },
            hoursToCompletion() {
                if(this.dataCollection.estimatedEnd < 0)
                    return this.$gettext("OEE too low to estimate");
                let remaining = (this.dataCollection.estimatedEnd * (this.dataCollection.quantityToProduce - this.dataCollection.quantityProduced)) / this.dataCollection.quantityToProduce;
                if(remaining > 0)
                    return this.formatDuration(remaining);
                else {
                    if(this.dataCollection.estimatedEnd === 0 || this.dataCollection.quantityProduced === 0)
                        return this.$gettext("Just started");
                    return this.$gettext("Completed")
                }
            }
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    this.createDataItems();
                },
                deep: true,
            },
            indices() {
                if(!this.loading)
                    this.createDataItems();
            },
            // device() {
            //     if(!this.loading)
            //         this.environment.root.showDialogBox(this.$gettext("Do you want to automatically create data sources for batch progress calculation?"), null, "Yes", this.createDataItems, "No", null);
            // }
        },
        methods: {
            formatDuration(seconds) {
                seconds /= 60;
                return Math.floor(seconds / 60)+"h" + Math.round(seconds % 60)+"m";
            },
            formatDate(date) {
                if(date === 0)
                    return "-";
                return (new Date(date)).format("MMM dd HH:mm");
            },
            createDataItems() {
                let goodProduction = this.getTweakValue("CalculateOnlyGoodProduction")
                let productionDescriptor = Production.getDataDescriptor(this.indices[0], [/*Get all indicators*/], goodProduction);
                this.filterItems = productionDescriptor.filters;
                this.dataItems = productionDescriptor.data;
                this.$emit('dataItemsUpdated');
            },
            refreshData(dataValues) { //Unwrap new data based on dataItems descriptor and print to view

                if(dataValues.length === 0) {
                    return;
                }

                this.clearErrors();
                this.clearWarnings();

                this.dataCollection = Production.calculate(dataValues);

                for(let i = 0 ; i < this.dataCollection.errors.length ; i++)
                    this.setError(this.dataCollection.errors[i]);
                for(let i = 0 ; i < this.dataCollection.warnings.length ; i++)
                    this.setWarning(this.dataCollection.warnings[i]);

                //Graph tweaks
                //let spanStart = this.dataCollection.actualStart < this.dataCollection.plannedStart ? this.dataCollection.actualStart : this.dataCollection.plannedStart;
                let span = 0;
                if(this.dataCollection.estimatedEnd < this.dataCollection.plannedEnd) {
                    span = this.dataCollection.plannedEnd;
                    this.late = false;
                }
                else {
                    span = this.dataCollection.estimatedEnd;
                    this.late = true;
                }

                this.endDeltaPercent = (this.dataCollection.endDelta / span) * 100;
                this.startDeltaPercent = (this.dataCollection.startDelta / span) * 100;
                if(this.dataCollection.estimatedEnd < 0) {
                    this.completePercent = 0;
                    this.remainingPercent = 100;
                } else if(this.dataCollection.progress < 95) {
                    this.completePercent = ((this.dataCollection.estimatedEnd * this.dataCollection.progress / 100) / span) * 100;
                    this.remainingPercent = ((this.dataCollection.estimatedEnd * (1 - this.dataCollection.progress / 100)) / span) * 100;
                } else {
                    this.completePercent = (this.dataCollection.estimatedEnd / span) * 100;
                    this.remainingPercent = 0;
                }

                let normalizer = (this.endDeltaPercent + this.startDeltaPercent + this.remainingPercent + this.completePercent) / 100;

                this.endDeltaPercent /= normalizer;
                this.startDeltaPercent /= normalizer;
                this.remainingPercent /= normalizer;
                this.completePercent /= normalizer;

                this.now = this.formatDate(Date.now());
            }
        }
    }

</script>

<style scoped>

    .negativeMargin20 {
        margin-bottom: -15px;
    }

    .negativeMargin5 {
        margin-bottom: -5px;
    }

</style>
