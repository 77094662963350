import VueInstance from './vueinstance'
import DateTime from "./datetimeutils";
import DataApis from "./data";
import OrchestratorAPI from "@/api/orchestrator";
import Microservices from "@/api/microservices";

export default {

    getMaintenanceRegisterByAsset(assetId, assetType, from, to) {

        if(!from)
            from = new Date(0);

        if(!to)
            to = new Date();

        let query = {
            raw: [{
                name: "raw_0",
                q: "index=data_transactions&filter=Transaction.EntityKeys.assetId.keyword,=,{0},Transaction.EntityKeys.EntityName.keyword,=,{1}&sort=@timestamp,asc".format(assetId, assetType),
                max_results: 30000
            }]
        };

        return new Promise((resolve, reject) => {
            DataApis.getDataBlob(query, DateTime.getRfc3339TimeStamp(from), DateTime.getRfc3339TimeStamp(to))
                .then(result => {
                    resolve(result.raw_0)
                })
                .catch(err => {
                    debugger;
                    console.log(err);
                    reject(VueInstance.get().$gettext("Unable to retrieve maintenance register"));
                });
        });
    },

    getMaintenanceRegister(from, to) {

        if(!from)
            from = new Date(0);

        if(!to)
            to = new Date();

        let query = {
            raw: [{
                name: "raw_0",
                q: "index=data_transactions&sort=@timestamp,asc",
                max_results: 30000
            }]
        };

        return new Promise((resolve, reject) => {
            DataApis.getDataBlob(query, DateTime.getRfc3339TimeStamp(from), DateTime.getRfc3339TimeStamp(to))
                .then(result => {
                    resolve(result.raw_0)
                })
                .catch(err => {
                    debugger;
                    console.log(err);
                    reject(VueInstance.get().$gettext("Unable to retrieve maintenance register"));
                });
        });
    },

    async deleteRecord(id) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('delete', Microservices.documentsUrl + "/doc/data/transactions/" + id)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    debugger
                    console.log(err);
                    reject(err)
                });
        });
    },
}