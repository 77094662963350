<template>
    <RuleBase />
</template>

<script>

    import RuleBase from '@/components/dynamic-elements/rules/RuleBase.vue';

    export default {
        name: "RuleFree",
        extends: RuleBase,
        components: {
            RuleBase
        },
        data() {
            return {
            }
        },
        computed: {
        },
        mounted() {
            this.fixedHeight = false;
            this.dataExplorationMode.autoDataSourcing = false;
            this.dataExplorationMode.deviceSelectMode = false;
            this.dataExplorationMode.requiresFunctions = false;
            this.properties.ruleScheduling.cron = "*/1 * * * *"; //Check every minute by default
            // For future implementations
            this.visualizationTweaks = [];
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    this.saveTweaks();
                },
                deep: true,
            },
        },
        methods: {}
    }

</script>
