<template>
    <v-container fluid grid-list-md id="licensemanagement-container">
        <v-flex xs12 id="licensemanagement-flex">
            <v-card id="licensemanagement-vcard">
                <v-card-title id="licensemanagement-vcard-title">
                    <h2><translate>License Management</translate></h2>
                </v-card-title>
                <v-card-text id="licensemanagement-vcard-text">
                    <v-layout id="licensemanagement-vcard-text-layout">
                        <v-flex px-4>
                            <v-flex px-12>
                                <v-chip color="white" text-color="black">
                                    <v-avatar class="orange" text-color="white">0</v-avatar>
                                    <translate>Generate and download new AvLicence.Id in case old file is corrupted</translate>
                                </v-chip>
                            </v-flex>
                            <v-flex px-12>
                                <v-tooltip left style="padding-right: 20px; cursor: pointer" id="licensemanagement-vcard-text-layout-flex-recreate-container-tooltip">
                                    <v-btn slot="activator" id="licensemanagement-vcard-text-layout-flex-recreate" block large round color="primary" @click="downloadLicense(true)" v-translate>Recreate file AVLicense.Id</v-btn>
                                    <span id="licensemanagement-vcard-text-layout-flex-recreate-container-tooltip-text" v-translate>Recreate file AVLicense.Id</span>
                                </v-tooltip>
                            </v-flex>
                        </v-flex>
                        <v-flex px-4 id="licensemanagement-vcard-text-layout-flex-download-container">
                            <v-flex px-12>
                                <v-chip color="white" text-color="black">
                                    <v-avatar class="orange" text-color="white">1</v-avatar>
                                    <translate>Download file AVLicense.Id to be sent to Antares</translate>
                                </v-chip>
                            </v-flex>
                            <v-flex px-12>
                                <v-tooltip left style="padding-right: 20px; cursor: pointer" id="licensemanagement-vcard-text-layout-flex-download-container-tooltip">
                                    <v-btn slot="activator" id="licensemanagement-vcard-text-layout-flex-download" block large round color="primary" @click="downloadLicense" v-translate>Download file AVLicense.Id</v-btn>
                                    <span id="licensemanagement-vcard-text-layout-flex-download-container-tooltip-text" v-translate>Download file AVLicense.Id</span>
                                </v-tooltip>
                            </v-flex>
                            <v-flex px-12>
                                <v-chip color="white" text-color="black">
                                    <v-avatar class="orange" text-color="white">2</v-avatar>
                                    <translate>Send file AVLicense.Id to Antares</translate>
                                </v-chip>
                            </v-flex>
                        </v-flex>
                        <v-flex px-4 id="licensemanagement-vcard-text-layout-flex-upload-container">
                            <v-flex px-12>
                                <v-chip color="white" text-color="black">
                                    <v-avatar class="orange" text-color="white">3</v-avatar>
                                    <translate>Upload new file AVLicense.Id received from Antares</translate>
                                    <av-icon v-if="licenseIdFile" color="info" class="ml-3">fas fa-check</av-icon>
                                </v-chip>
                            </v-flex>
                            <v-flex px-12>
                                <v-chip color="white" text-color="black">
                                    <v-avatar class="orange" text-color="white">4</v-avatar>
                                    <translate>Upload new file AVLicense.Key received from Antares</translate>
                                    <av-icon v-if="licenseKeyFile" color="info" class="ml-3">fas fa-check</av-icon>
                                </v-chip>
                            </v-flex>
                            <v-flex px-12>
                                <file-upload :custom-action="uploadFile" @input-filter="inputFilter" @input-file="inputFile" :multiple="false" :drop="false" :drop-directory="false" v-model="files" ref="upload">
                                    <v-tooltip left style="padding-right: 20px; cursor: pointer" id="licensemanagement-vcard-text-layout-flex-upload-tooltip">
                                        <v-btn slot="activator" block round large color="primary" ><translate>Select Id or Key file to upload</translate></v-btn>
                                        <span id="licensemanagement-vcard-text-layout-flex-upload-container-tooltip-text"><translate>Upload file AVLicense.Id and AVLicense.key</translate></span>
                                    </v-tooltip>
                                </file-upload>
                            </v-flex>

                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex>
            <v-chip color="white" text-color="black">
                <v-avatar class="orange">6</v-avatar>
                <translate>Load license and back to login</translate>
            </v-chip>
            <v-btn slot="activator" id="licensemanagement-vcard-text-layout-flex-login" block round large color="blue" @click="returnToHome" :disabled="!licenseIdFile || !licenseKeyFile" v-translate>Finalize</v-btn>
        </v-flex>
    </v-container>
</template>

<script>
    import Licensing from '@/api/licensing.js'
    import * as Download from 'downloadjs'

    export default {
        name: "LicenseManagement",
        data:() => ({
            files: [],
            uploadAuto: true,
            licenseIdFile: null,
            licenseKeyFile: null,
            fileUploaded: { IDFile: '', KeyFile: '' },
        }),
        mounted() {
        },
        computed: {
        },
        methods:{
            uploadFile (file) {
                if(/\.(Id)$/i.test(file.file.filename || file.name)) {
                    this.licenseIdFile = file;
                    let reader = new FileReader();
                    let self = this;
                    reader.onload = function () {
                        let fileString = reader.result;
                        self.fileUploaded.IDFile = btoa(fileString)
                    };
                    reader.readAsBinaryString(this.licenseIdFile.file);
                } else if(/\.(Key)$/i.test(file.file.filename || file.name)) {
                    this.licenseKeyFile = file;
                    let reader = new FileReader();
                    let self = this;
                    reader.onload = function () {
                        let fileString = reader.result;
                        self.fileUploaded.KeyFile = btoa(fileString);
                    };
                    reader.readAsBinaryString(this.licenseKeyFile.file);
                } else {
                    this.$root.showErrorNotification(this.$gettext(".Id or .Key file expected"), true);
                }
            },
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    // Filter non-image file
                    if (!/\.(Id|Key)$/i.test(newFile.name)) {
                        return prevent();
                    }
                }
                return null;
            },
            inputFile(newFile, oldFile) {
                // if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                //     if (this.uploadAuto && !this.$refs.upload.active) {
                        this.$refs.upload.active = true
                        //this.$refs.uploadKey.active = true
                //     }
                // }
            },
            downloadLicense(recreate = false) {
                let self = this;
                Licensing.downloadLicense(recreate)
                    .then(t => {
                        let parsed = atob(JSON.parse(t.data.Data));
                        Download(parsed, 'AVLicense.Id', 'text/plain');
                    }).catch(t => {
                    self.$root.showErrorNotification(self.$gettext("Error occurred during licence creation."), true, true);
                })
            },
            returnToHome() {
                Licensing.uploadLicense(this.fileUploaded)
                    .then(t => {
                        debugger
                        if (t.data.Status === "success"){
                            this.$root.showInfoNotification("File uploaded with success", false);
                            let page = this.$route.query.redirect;

                            if (page) {
                                this.$router.push(page);
                            } else {
                                this.$router.push('login');
                            }
                        } else {
                            this.$root.showErrorNotification(t.data.Data, false);
                        }
                    }) .catch(t => {
                    this.$root.showErrorNotification(t.response.data, false);
                });
            }
        }
    }
</script>
<style scoped>
</style>