<template>
    <div :class="keyboardClass" style="height: calc(100% - 0px)!important"></div>
</template>

<script>
    import Keyboard from "simple-keyboard";
    import "simple-keyboard/build/css/index.css";

    export default {
        name: "SimpleKeyboard",
        props: {
            keyboardClass: {
                default: "simple-keyboard",
                type: String
            },
            input: {
                type: String
            },
            enableNewLine: {
                default: false
            },
        },
        data: () => ({
            keyboard: null
        }),
        mounted() {
            this.keyboard = new Keyboard({
                onChange: this.onChange,
                onKeyPress: this.onKeyPress,
                preventMouseDownDefault: true,
                newLineOnEnter: this.enableNewLine,
                theme: "hg-theme-default"
            });
            this.input = this.$root.virtualKeyboard.target.value;
            this.keyboard.setInput(this.input);
            this.onChange(this.input);
        },
        methods: {
            onChange(input) {
                this.$emit("onChange", input);
            },
            onKeyPress(button) {
                this.$emit("onKeyPress", button);

                /**
                 * If you want to handle the shift and caps lock buttons
                 */
                if (button === "{shift}" || button === "{lock}") this.handleShift();
            },
            handleShift() {
                let currentLayout = this.keyboard.options.layoutName;
                let shiftToggle = currentLayout === "default" ? "shift" : "default";

                this.keyboard.setOptions({
                    layoutName: shiftToggle
                });
            }
        },
        watch: {
            input(input) {
                this.keyboard.setInput(input);
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .simple-keyboard {
        width: 100%;
        height: 100%;
    }

    .simple-keyboard.hg-theme-default .hg-row {
        height: 20%;
    }

    .simple-keyboard.hg-theme-default .hg-button {
        height: 100%;
        font-size: 22px;
        font-weight: bold;
    }
</style>