<template>
    <FormBase/>
</template>

<script>
    import FormBase from '@/components/dynamic-elements/forms/FormBase.vue';

    export default {
        name: "FormWorkorder",
        extends: FormBase,
        components: {
            FormBase
        },
        data() {
            return {
                parsedMachineRecipes: null,
                line: {
                    name: "PhysicalLineId",
                    title: "Line",
                    description: "",
                    icon: "",
                    inputMode: "Normal",
                    type: "Single Option",
                    min: "",
                    max: "",
                    step: "",
                    unit: "",
                    requireEvidence: "",
                    value: "",
                    evidence: "",
                    mandatory: true,
                    mustMatch: false,
                    expectedValue: "",
                    options: [],
                    children: [],
                    hideHints: false,
                    valueAsCategory: false,
                    editableName: false,
                    editableType: false,
                    undeletable: true
                },
                machineRecipes: {
                    name: "MachineRecipes",
                    title: "MachineRecipes",
                    description: "",
                    icon: "",
                    inputMode: "Normal",
                    type: "Boolean",
                    min: "",
                    max: "",
                    step: "",
                    unit: "",
                    requireEvidence: "",
                    value: "",
                    evidence: "",
                    mandatory: false,
                    mustMatch: false,
                    expectedValue: null,
                    options: [],
                    children: [],
                    hideHints: false,
                    valueAsCategory: false,
                    disableAddVariable: true,
                    editableName: false,
                    editableType: false,
                    undeletable: true
                },
            }
        },
        computed: {},
        async mounted() {
            this.childHandlers.onNewElementCreated.push(this.onNew);
            this.childHandlers.onElementLoaded.push(this.onOpen);
            this.formConfigurationMode.requiresValueAsCategory = false;
            this.documentFormatHandler = this.buildWorkorderDocument;
        },
        created() {
            if (this.$license.hasManager()) {
                this.loadMachines();
            }
        },
        watch: {
            'formVariables': {
                handler: function () {
                    if (this.$license.hasManager()) {
                        let machineRecipesFieldToBeQueued = [];
                        let indexIdField = this.formVariables.findIndex(x => x.name === 'Id');

                        if (indexIdField >= 0) {
                            let indexMachineRecipesField = this.formVariables[indexIdField].children.findIndex(x => x.name === 'MachineRecipes');
                            if (indexMachineRecipesField >= 0 && indexMachineRecipesField !== this.formVariables[indexIdField].children.length - 1) {
                                machineRecipesFieldToBeQueued = this.$utils.detach(this.formVariables[indexIdField].children[indexMachineRecipesField]);
                                this.formVariables[indexIdField].children.splice(indexMachineRecipesField, 1);
                                this.formVariables[indexIdField].children.push(machineRecipesFieldToBeQueued)
                            }
                        }
                    } else {
                        let indexIdField = this.formVariables.findIndex(x => x.name === 'Id');

                        if (indexIdField >= 0) {
                            let indexMachineRecipesField = this.formVariables[indexIdField].children.findIndex(x => x.name === 'MachineRecipes');
                            if (indexMachineRecipesField >= 0) {
                                this.formVariables[indexIdField].children.splice(indexMachineRecipesField, 1);
                            }
                        }
                    }
                },
                deep: true,
            },
        },
        methods: {
            async loadMachines() {
                let self = this;
                await this.$devices.getAvailable().then(async (devs) => {
                    let parsedDevs = await self.$aliases.getMachinesAliasesObjects(devs);
                    parsedDevs.forEach(dev => {
                        self.machineRecipes.children.push({
                            name: dev.value,
                            title: dev.text,
                            description: "",
                            icon: "",
                            inputMode: "Normal",
                            type: "Text",
                            min: "",
                            max: "",
                            step: "",
                            unit: "",
                            requireEvidence: "",
                            value: "",
                            evidence: "",
                            mandatory: false,
                            mustMatch: false,
                            expectedValue: null,
                            options: [],
                            children: [],
                            hideHints: false,
                            valueAsCategory: false,
                            disableAddVariable: false,
                            editableName: false,
                            editableType: true,
                            allowedTypes: ["Text", "Single option"],
                            undeletable: true
                        });
                    })
                });
            },
            setAppropriateTweaks() {
                this.setTweakValue("target", 1000);
                for (let tweak of this.visualizationTweaks)
                    tweak.hidden = true;
            },
            onOpen() {
                this.setAppropriateTweaks();
                //V5.0.0 update we changed the behaviour of variables so that now all user defined variables
                //are deletable. This required breaking the logic that was previously locking the system variables
                //This snippet will update definition and lock system variables
                let systemVariableLocker = function(variables) {
                    for(let variable of variables) {
                        if((!variable.editableName || !variable.editableType))
                            variable.undeletable = true;
                        if(Array.isUseful(variable.children))
                            systemVariableLocker(variable.children);
                    }
                };
                systemVariableLocker(this.formVariables)
            },
            onNew() {
                this.setAppropriateTweaks();
                this.formVariables = [
                    {
                        name: "Id",
                        title: "Workorder Id",
                        description: "",
                        icon: "",
                        inputMode: "Normal",
                        type: "Text",
                        min: "",
                        max: "",
                        step: "",
                        unit: "",
                        requireEvidence: "",
                        value: "",
                        evidence: "",
                        mandatory: true,
                        mustMatch: false,
                        expectedValue: null,
                        options: [],
                        hideHints: false,
                        valueAsCategory: false,
                        editableName: false,
                        editableType: false,
                        undeletable: true,
                        isGlobalRootVariable: true,
                        children: [
                            {
                                name: "RecipeId",
                                title: "Recipe Id",
                                description: "",
                                icon: "",
                                inputMode: "Normal",
                                type: "Text",
                                min: "",
                                max: "",
                                step: "",
                                unit: "",
                                requireEvidence: "",
                                value: "",
                                evidence: "",
                                mandatory: true,
                                mustMatch: false,
                                expectedValue: null,
                                options: [],
                                children: [],
                                hideHints: false,
                                valueAsCategory: false,
                                editableName: false,
                                editableType: true,
                                allowedTypes: ["Text", "Single option"],
                                undeletable: true
                            },
                            {
                                name: "QuantityToProduce",
                                title: "Quantity to produce",
                                description: "",
                                icon: "",
                                inputMode: "Normal",
                                type: "Number",
                                min: "",
                                max: "",
                                step: "",
                                unit: "",
                                requireEvidence: "",
                                value: "",
                                evidence: "",
                                mandatory: true,
                                mustMatch: false,
                                expectedValue: null,
                                options: [],
                                children: [],
                                hideHints: false,
                                valueAsCategory: false,
                                editableName: false,
                                editableType: false,
                                undeletable: true
                            }
                        ]
                    },
                ];
                if (this.$config.isL3()) {
                    this.formVariables.unshift(this.line);
                }
                if (this.$license.hasManager()) {
                    let indexIdField = this.formVariables.findIndex(x => x.name === 'Id');
                    this.formVariables[indexIdField].children.push(this.machineRecipes);
                }
                this.$emit('dataItemsUpdated');
            },
            buildWorkorderDocument(document) {

                let self = this;
                let frmVariables = this.$utils.detach(this.variables);

                //Process or remove machines recipes
                this.unwrapMachineRecipes(frmVariables);

                let workorder = this.unwrapVariables(frmVariables);

                if (Object.isUseful(this.parsedMachineRecipes)) {
                    workorder["MachineRecipes"] = this.parsedMachineRecipes;
                }

                workorder["OpenTime"] = this.$dateTime.getRfc3339TimeStamp(new Date());
                workorder["UserName"] = this.$root.userName;

                let physicalLineId = "";
                if (this.$config.isL2()) {
                    physicalLineId = this.$settings.getLineSettings().physicalLineId;
                    workorder["PhysicalLineId"] = physicalLineId;
                    if(!this.$license.hasManager())
                        workorder["StatusString"] = "running";
                }
                if (this.$config.isL3())
                    physicalLineId = workorder["PhysicalLineId"];

                this.$workorders.createWorkorder(workorder, physicalLineId)
                    .then(() => {
                        self.$audits.save(self.$root.userName, self.$audits.items().workorderCreated, "", "", workorder.Id);
                    })
                    .catch(err => {
                        debugger
                        self.$root.showErrorNotification(self.$gettext("An error occurred while saving form. Please retry or ask administrator for help"), true, false);
                        console.error(err)
                    });
                return document;
            },

            unwrapMachineRecipes(frmVariables) {

                let self = this;
                let machineRecipesToBeParsed = [];

                let indexIdField = frmVariables.findIndex(x => x.name === 'Id');
                if (indexIdField >= 0) {
                    let indexMachineRecipesField = frmVariables[indexIdField].children.findIndex(x => x.name === 'MachineRecipes');
                    if (indexMachineRecipesField >= 0) {
                        let isCheckedMachineRecipes = frmVariables[indexIdField].children[indexMachineRecipesField].value;
                        if (isCheckedMachineRecipes) {
                            machineRecipesToBeParsed = self.$utils.detach(frmVariables[indexIdField].children[indexMachineRecipesField].children);
                            frmVariables[indexIdField].children.splice(indexMachineRecipesField, 1);
                            if (Array.isUseful(machineRecipesToBeParsed)) {
                                machineRecipesToBeParsed.forEach(mr => {
                                    //Create machineRecipes node formatted as expected by workorders and productionmanager
                                    if (Object.isUseful(mr.value)) {
                                        if(!Object.isUseful(self.parsedMachineRecipes))
                                            self.parsedMachineRecipes = {};
                                        self.parsedMachineRecipes[mr.name] = { "Recipe": mr.value };
                                        if(Array.isUseful(mr.children))
                                            for(let recipeParameter of mr.children)
                                                if(Object.isUseful(recipeParameter.value))
                                                    self.parsedMachineRecipes[mr.name][recipeParameter.name] = recipeParameter.value;
                                    }
                                });
                            }
                        } else {
                            this.parsedMachineRecipes = null;
                        }
                    }
                    frmVariables[indexIdField].children.splice(indexMachineRecipesField, 1);
                }
            },
        }
    }

</script>

<style scoped>
    table th {
        border: 3px solid #dddddd;
        height: 50px !important;
        max-height: 50px !important;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }

    table td {
        border: 3px solid #dddddd;
        height: 50px !important;
        max-height: 50px !important;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }

    table tr {
        border: 3px solid #dddddd;
        height: 50px !important;
        max-height: 50px !important;
        overflow: hidden;
        word-wrap: break-word;
        white-space: nowrap
    }
</style>