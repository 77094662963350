<template>
    <DynamicElementBase>
        <template v-if="scope().editMode && scope().properties.fillableMode === 3" slot="titleButtons">
            <v-toolbar color="white" flat style="width: 50%; position: absolute; right: 0;">
                <v-tabs v-model="viewMode">
                    <v-tabs-slider color="blue"></v-tabs-slider>
                    <v-tab v-for="view in viewModes" :key="view">{{ view }}</v-tab>
                </v-tabs>
            </v-toolbar>
        </template>
        <v-card-text style="overflow-y: auto; height: calc(100% - 60px)">
            <v-layout row>
                <v-flex xs3 style="margin-top: 16px">
                    <label class="title">{{ scope().recordVersionMessage }}</label>
                </v-flex>
                <v-flex xs2 v-if="scope().showVersionSelector">
                    <av-select  v-model="selectedVersion"
                                :items="scope().historyItems"
                                :label="$gettext('Switch to newer version')"
                                @change="getNewerDescriptor"
                                item-text="name"
                                return-object/>
                </v-flex>
            </v-layout>
            <h2 v-if="isHumanView" class="heading text-xs-left" style="font-weight: normal;">{{ scope().properties.description }}</h2>
            <template v-if="Array.isUseful(scope().properties.dynamicDescription)">
                <h2 class="mt-4"> {{ $gettext('Additional information:') }} </h2>
                <v-layout row v-for="message in scope().properties.dynamicDescription">
                    <h2 class="heading text-xs-left" style="font-weight: normal;">{{ message }}</h2>
                </v-layout>
            </template>
            <FormTree :variables="variables" :fullFormVariables="variables" :isMachine="isMachineView" @onVariableChange="onVariableChange($event)" :entity="Entity"
                      :valuesChangedEvent="valuesChangedEvent"></FormTree>
            <av-textarea v-if="isHumanView" clearable no-resize :label="$gettext('Type comment here')" v-model="annotationText"/>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <av-button :text="'clear'" v-if="scope().editMode" color="blue darken-1" flat="flat"
                       @click="clearForm()" id="clear"/>
            <av-button :text="'cancel'" v-if="!scope().editMode" color="red darken-1" flat="flat"
                       @click="scope().$emit('canceled')" id="cancel" :disabled="savingData"/>
            <av-button color="green darken-1" :disabled="!isValidForm" flat="flat" :text="scope().editMode ? 'VALID' : 'save'"
                       @click="saveData" id="save" :loading="savingData"/>

        </v-card-actions>
    </DynamicElementBase>

</template>

<script>

    import DateTime from '@/api/datetimeutils'
    import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue';
    import FormTree from './FormTree'

    export default {
        name: "FormBase",
        extends: DynamicElementBase,
        components: {
            DynamicElementBase,
            FormTree
        },
        props: {
            fillVariables: {
                default() {
                    return []
                }
            },
            entity: {
                default() {
                    return null
                }
            }
        },
        data() {
            return {
                annotationText: "",
                tweaksUpdating: false,
                isFormValid: false,
                entityId: "",
                viewMode: 0,
                localVariables: [],
                viewModes: [
                    'Human view', 'Machine view',
                ],
                varArray: [],
                formConfigurationMode: {
                    requiresJoin: false, //Form configurator will show the data of join
                    requiresPrimaryKey: false, //Form configurator will show the primary key
                    requiresTargetEntity: false, //Form configurator will show the target entity
                    requiresSystemVariables: false,
                    requiresInheritance: false,
                    isEntity: false, //Will show entity template
                    isTransaction: false,//Will not show transaction template
                    requiresValueAsCategory: true
                },
                documentFormatHandler: null,
                documentSaveHandler: null,
                documentUpdateHandler: null,
                oldFormVariables: [],
                valuesChangedEvent: 1,
                historyItems: [],
                recordVersionMessage: '',
                selectedVersion: '',
                showVersionSelector: false,
                savingData:false,
                rulesForValidation: []
            }
        },
        beforeMount() {
            this.$root.$on('variableKeys', (item) => {
                this.varArray = item
            });
        },
        mounted() {
            this.fixedHeight = -1;
            if (!this.editMode)
                this.clearForm();
            this.properties.showTimeWindow = false;
            this.visualizationTweaks = [
                {
                    name: this.$gettext("Import excel template"),
                    id: "ImportTemplate",
                    type: "file",
                    importCallback: this.importExcelCallback,
                    deleteCallback: this.deleteTemplateCallback,
                    default: function() {
                        return "No template loaded";
                    }
                },
                {
                    name: this.$gettext("Data target (where to save and how to use information)"),
                    id: "target",
                    type: "indexed_option",
                    hidden: false,
                    options: [this.$gettext("Offline measurements"), this.$gettext("Production data")],
                    default: function () {
                        return 0;
                    }
                },
                {
                    name: this.$gettext("Validity Mode"),
                    id: "validityMode",
                    type: "indexed_option",
                    options: [this.$gettext("Once"), this.$gettext("Current Workorder only"), this.$gettext("Time"), this.$gettext("For ever")],
                    hidden: true,
                    default: function () {
                        return 0;
                    }
                },
                {
                    name: this.$gettext("Duration"),
                    id: "duration",
                    type: "duration",
                    hidden: true,
                    default: function () {
                        return 0;
                    }
                },
                {
                    name: this.$gettext("Form namespace (variables group name in DB)"),
                    id: "namespace",
                    type: "string",
                    hidden: false,
                    default: function () {
                        return ""
                    }
                },
                {
                    name: this.$gettext("Prompt a new form after filling in (continuous data entering)"),
                    id: "promptnew",
                    type: "bool",
                    hidden: false,
                    default: function () {
                        return false;
                    }
                },
                {
                    name: this.$gettext("Validation rule"),
                    id: "ruleToExecute",
                    type: "option",
                    options: [],
                    default: function () {
                        return ""
                    },
                    hidden: false
                },

            ];
            this.scope().childHandlers.checkItemUsefulness = () => { return true; };
            this.scope().childHandlers.getItemStatus = this.getFormItemStatus;

            this.validators.insertItem(2, {
                onDeploy: true,
                onChange: true,
                validator: this.checkForDuplicateFormVariables
            });

            this.validators.insertItem(2, {
                onDeploy: true,
                onChange: false,
                validator: this.checkNameSpaceValidity
            });

            this.validators.push({
                onDeploy: true,
                onChange: false,
                validator: this.checkEmptyForm
            });
            //TODO related to orphan transactions, move to formEntities
            setTimeout(() => {
                this.oldFormVariables =  JSON.parse(JSON.stringify(this.scope().formVariables))
            }, 1000);

            if (this.entity != null) {
                let self = this;
                self.$dynamicElements.GetHistoryForIndexAndName('user_formshistory', this.entity.Name)
                    .then(items => {
                        items = items.filter(i => i.activeVersion > 0);
                        items.sort((a,b) => b.activeVersion - a.activeVersion);
                        if (items[0].version === self.entity.FormVersion) {
                            self.scope().recordVersionMessage = self.$gettext('Record is from newest version of form, version: ') + self.entity.FormVersion;
                        }
                        else {
                            self.scope().showVersionSelector = true;
                            self.scope().historyItems = items.filter(i => i.version >= self.entity.FormVersion);
                            self.scope().recordVersionMessage = self.$gettext('Record is from old version of form, version: ') + self.entity.FormVersion;
                        }
                    })
            }
            this.$rules.getRunningRules(false)
                .then(result => {
                    result.unshift('No Rule');
                    this.getTweak("ruleToExecute").options = result;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        watch: {
            formVariables: {
                handler() {
                    this.clearForm();
                    if (this.autoFillVariables) {
                        this.autoCompleteForm()
                    }
                    // if(this.scope().editMode)
                    //     this.$set(this, "localVariables", this.$utils.detach(this.scope().formVariables));
                }
            },
            visualizationTweaks: {
                handler: function () {
                    if (this.tweaksUpdating) {
                        this.tweaksUpdating = false;
                        return;
                    }
                    //Some overloading forms (workorders creation at 2.0 time) use some built-in targets that are not
                    //user selectable normally. We catch those situation by verifying that tweak value is out of tweak user options space.
                    //In this case tweaks are custom managed inside overloaded form
                    let targetTweak = this.getTweak("target");
                    if (targetTweak != null)
                        if (targetTweak.value >= targetTweak.options.length)
                            return;
                    //Enable/disable validity options based on target and validity logic
                    let previousHiddenState = [this.getTweak("validityMode").hidden, this.getTweak("duration").hidden];
                    let target = targetTweak.value;
                    let validityMode = this.getTweak("validityMode");
                    let duration = this.getTweak("duration");
                    if (validityMode.hidden !== (target === 0)) {
                        validityMode.hidden = (target === 0);
                        if (validityMode.hidden)
                            validityMode.value = 0;
                    }
                    if(duration.hidden !== ([0,1,3].includes(validityMode.value))) {
                        duration.hidden = [0,1,3].includes(validityMode.value);
                        if (duration.hidden)
                            duration.value = {};
                    }
                    if (JSON.stringify([this.getTweak("validityMode").hidden, this.getTweak("duration").hidden]) !== JSON.stringify(previousHiddenState)) {
                        this.tweaksUpdating = true;
                    }
                    this.saveTweaks();
                },
                deep: true,
            },
        },
        computed: {
            Entity() {
                return this.scope().entity;
            },
            variables() {
                //In edit mode we avoid triggering saves when user tests form by working on a non-reactive clone
                if(this.scope().editMode) {
                    this.$set(this, "localVariables", this.$utils.detach(this.scope().formVariables));
                    return this.localVariables;
                }
                else return this.scope().formVariables;
            },
            isValidForm() {
                if(Array.isUseful(this.variables))
                    return this.$dataEntry.isFormValid(this.variables);
                else return this.annotationText;
            },
            autoFillVariables() {
                return this.scope().fillVariables;
            },
            isHumanView() {
                return (this.scope().properties.fillableMode === 1) || (this.scope().properties.fillableMode === 3 && this.viewMode === 0)
            },
            isMachineView() {
                return (this.scope().properties.fillableMode === 2) || (this.scope().properties.fillableMode === 3 && this.viewMode === 1)
            },
            isTask() {
                return !!this.$route.params.isTask
            }
        },
        methods: {
            //if event variable is declared, contains title description and value of variable updated
            onVariableChange(event) {
                this.valuesChangedEvent++;
                this.isFormValid = this.$dataEntry.isFormValid(this.variables);
                this.$emit("onVariableChange", event)
            },
            expandProductionDataDocument(document) {
                document[document.NameSpace] = {};
                document[document.NameSpace]["NewRecord"] = true;
                let validityMode = this.getTweak("validityMode");
                let value = 0;
                if (validityMode.value === 2) {
                    let duration = this.getTweakValue("duration");
                    let multiplier = 0;
                    if (duration.unit) {
                        switch (duration.unit) {
                            case "Seconds":
                                multiplier = 1;
                                break;
                            case "Minutes":
                                multiplier = 60;
                                break;
                            case "Hours":
                                multiplier = 3600;
                                break;
                            case "Days":
                                multiplier = 86400;
                                break;
                        }
                    }
                    value = duration.value * multiplier;
                }
                let validityModeKey;
                switch (validityMode.value) {
                    case 0:
                        validityModeKey = "once";
                        break;
                    case 1:
                        validityModeKey = "workorder";
                        break;
                    case 2:
                        validityModeKey = "time";
                        break;
                    case 3:
                        validityModeKey = "ever";
                        break;
                }
                document["Validity"] = {
                    Mode: validityModeKey,
                    Value: value,
                };
                return document;
            },
            saveData() {
                if (this.scope().editMode)
                    return;
                this.savingData = true;
                let self = this;
                setTimeout(()=>{
                    self.save()
                }, 200)
            },
            async save(){
                let self = this;
                try {

                    let target = self.getTweakValue("target");
                    let isStandardDataEntry = target === 0;
                    //Target 1 -> regular production data
                    //Target 2 -> production data handled internally by specific forms that
                    // allows spanning variables in root PDM document node. In this case base validator will not check
                    // whether a production data namespace was configured or not
                    let isProductionData = target === 1 || target === 2;

                    let namespace = self.getTweakValue("namespace");
                    if (!namespace)
                        namespace = self.scope().properties.name;
                    let document = {
                        id: this.$utils.timeTag(this.scope().properties.name, false),
                        '@timestamp': DateTime.getRfc3339TimeStamp(new Date()),
                        Name: this.scope().properties.name,
                        Operator: this.$root.userName,
                        Annotation: this.annotationText,
                        NameSpace: namespace,
                        FormVersion: (!this.scope().deployedVersion) ? "" : this.scope().version,
                        version: 1,
                        target: target
                    };
                    if (namespace)
                        document[namespace] = {};
                    //In case we are reediting a previous entity keep the id to update
                    if (Object.isUseful(self.Entity)) {
                        document.id = this.Entity.id;
                        document.version = this.Entity.version + 1;
                    }

                    if (this.scope().documentFormatHandler)
                        this.scope().documentFormatHandler(document, Object.isUseful(self.Entity));

                    if (!this.scope().documentSaveHandler)
                        this.scope().documentSaveHandler = self.saveStandardForms;

                    if (!document) {
                        this.savingData = false;
                        return; //Nothing to write, target completely handled in template
                    }

                    if (isProductionData) {
                        document = self.expandProductionDataDocument(document);
                    } else if (isStandardDataEntry) {
                        document.Result = self.$dataEntry.getFormResult(this.variables);
                        if (namespace)
                            document[namespace] = {
                                Name: self.scope().properties.name
                            };
                    }

                    //If form target is production data, document must be ovewritten with latest value when form is compiled.
                    //in this case we assign a unique id to document based on form name, main namespace and eventual "valueAsCategory" variables
                    let singleInstanceId = "";
                    let containsValueAsCategoryVariables = false;
                    let varsCrawler = function (variables, document, expandValueAsCategory) {
                        for (let variable of variables) {
                            if (variable.valueAsCategory)
                                containsValueAsCategoryVariables = true;
                            if (!variable.valueAsCategory || expandValueAsCategory) { //Value as category puts all child vars in a subnode named with value of variable
                                if (!(variable.type === "Boolean" && Array.isUseful(variable.children))) { //Do not save any value for boolean variables used to enable subvariables as they will appear as nodes
                                    document[variable.name] = self.$dataEntry.getVariableValue(variable);
                                    if (variable.evidence)
                                        document[variable.name + "_Evidence"] = variable.evidence;
                                }
                            } else {
                                singleInstanceId += "_{0}-{1}".format(self.$utils.camelize(variable.name), self.$utils.camelize(variable.value))
                            }
                            //Unroll children vars.
                            if (Array.isUseful(variable.children) //There must be children
                                && (!variable.valueAsCategory || Object.isUseful(variable.value)) //If variable is a category, it must be non-empty
                                && (variable.type !== "Boolean" || variable.value)) { //If variable is boolean unroll children only if selected
                                let subDocument = document;
                                if (variable.valueAsCategory && !expandValueAsCategory) { //In case of category parent variable, use variable value as namespace of children
                                    document[self.$utils.camelize(variable.value)] = {};
                                    subDocument = document[self.$utils.camelize(variable.value)];
                                } else if (variable.type === "Boolean") {    //In case of boolean parent variable, use variable name as namespace of children
                                    document[variable.name] = {};
                                    subDocument = document[variable.name];
                                }
                                varsCrawler(variable.children, subDocument);
                            }

                            if (variable.type === "repeatableGroup" && Array.isUseful(variable.repeatableChildren)) {
                                document[variable.name] = [];
                                let subDocument = document[variable.name];
                                for (let item of variable.repeatableChildren) {
                                    document[variable.name].push({});
                                    let subDocument = varsCrawler(item, document[variable.name].last(), self);
                                }
                            }
                        }
                    };
                    if (namespace) {
                        varsCrawler(this.variables, document[namespace], false);
                        if (containsValueAsCategoryVariables) {
                            document[namespace]["RawDocument"] = {};
                            varsCrawler(this.variables, document[namespace]["RawDocument"], true);
                        }
                    }
                    if (isStandardDataEntry || isProductionData) {
                        let self = this;
                        this.$workorders.getCurrentWorkorderId()
                            .then(result => {
                                if (!isProductionData && namespace)
                                    document[namespace]["WorkorderID"] = result;
                                document["WorkorderID"] = result;
                            })
                            .catch(() => {
                                if (!isProductionData && namespace)
                                    document[namespace]["WorkorderID"] = "";
                                document["WorkorderID"] = "";
                            })
                            .finally(() => {
                                self.writeMeasure(document, singleInstanceId);
                            })
                    } else self.writeMeasure(document, singleInstanceId);

                } finally {
                    //this.savingData = false;
                }
            },
            unwrapVariables(variables) {
                let ffVariables = {};

                function gV(variables, aV) {
                    variables.forEach(v => {
                        if (v.type === "Number")
                            aV[v.name] = Number(v.value);
                        else aV[v.name] = v.value;

                        if (v.children.length > 0) {
                            gV(v.children, aV)
                        }
                    });
                }

                gV(variables, ffVariables);
                return ffVariables;
            },
            async writeMeasure(document, singleInstanceId) {
                let self = this;
                let ruleToValidate = self.getTweakValue("ruleToExecute");
                if (ruleToValidate && String.prototype.isString(ruleToValidate) && ruleToValidate !== 'No Rule') {
                    let validationResult = await self.ruleValidation(ruleToValidate, document);
                    if (validationResult.message !== 'ok') {
                        self.$root.showErrorNotification(validationResult.message, true, true);
                        return
                    }
                }
                this.scope().documentSaveHandler(document, Object.isUseful(this.Entity), singleInstanceId)
                    .then(result => {
                        document.internal_name = document.id;
                        document.id = document.id + "_v" + document.version;
                        document.name = document.Name + "_v" + document.version;
                        this.$entities.saveEntityHistory(document);
                        if (!this.isTask)
                            this.$audits.save(this.$root.userName, this.$audits.items().formFilled, '', '', document.Name);
                        let continueEntering = this.getTweakValue("promptnew");
                        if (continueEntering)
                            this.clearForm();
                        this.scope().$emit("complete", result, document.Annotation);

                        this.savingData=false
                    })
                    .catch(err => {
                        this.scope().$emit("error", err);
                    })
                    .finally(() => {
                        self.savingData = false
                    })
            },
            saveStandardForms(document, update, singleInstanceId) {
                let target = this.getTweakValue("target");
                if (target === 1 || target === 2) {
                    target = this.$dataEntry.targetProductionData;
                    //Production data must have unique ids based on form content to allow updating
                    if(target === 1)
                        document.id = "{0}_{1}_{2}".format(document.Name, document.NameSpace, singleInstanceId);
                    //Prevent creation new production data instead of editing. Id could change when changing 'Value as category' field
                    if (this.Entity && this.Entity.id && this.Entity.id !== document.id)
                        this.$entities.deleteProductionDataInstance(this.Entity.id)
                } else target = this.$dataEntry.targetOfflineMeasurements;

                return new Promise((resolve, reject) => {
                    this.$dataEntry.saveForm(document, target).then(t => {
                        resolve(false);
                    })
                    .catch((err) => {
                        reject(err);
                    });
                });
            },
            clearForm() {
                if (!Array.isUseful(this.variables))
                    return;
                this.$dataEntry.clearVariables(this.variables);
                //Refresh form validation. We don't just set it to invalid since there could be cases where an empty form is valid
                this.isFormValid = this.$dataEntry.isFormValid(this.variables);
                this.annotationText = "";
            },
            autoCompleteForm() {
                let self = this;

                function getVariable(variables, name) {
                    for (let i=0 ; i < variables.length ; i++) {
                        let variable = variables[i];
                        if (variable.name === name) {
                            return variable
                        } else if (variable.children && variable.children.length > 0) {
                            return getVariable(variable.children, name)
                        }
                    }
                }

                this.autoFillVariables.forEach(variable => {
                    let v = getVariable(self.scope().variables, variable.name);
                    if (v)
                        v.value = variable.value;
                })
            },
            /* VALIDATORS */
            checkForDuplicateFormVariables() {

                //Check for variable uniqueness within it's parent
                let self = this;
                let checkDuplicate = function(vars, root) {
                    let duplicate = false;
                    vars.map(v => v.name).sort().sort((a, b) => {
                        if (a === b) {
                            self.$root.showErrorNotification(self.$gettext("Variable {0} is already defined, please select unique variable names").format(root + a), true);
                            duplicate = true;
                            return true;
                        }
                    });
                    if(duplicate)
                        return true;
                    for (let _var of vars) {
                        if (Array.isUseful(_var.children)) {
                            let duplicate = checkDuplicate(_var.children, root + _var.name + ".");
                            if (duplicate)
                                return true;
                        }
                    }

                    return false;
                };

                return !checkDuplicate(this.variables, "");
            },
            checkEmptyForm() {
                if (!Array.isUseful(this.variables) && !Array.isUseful(this.varArray)) {
                    this.$root.showErrorNotification(this.$gettext("No fields configured. This entity is empty"), true, true);
                    return false;
                }
                return true;
            },
            checkNameSpaceValidity() {
                let target = this.getTweakValue("target");
                let namespace = this.getTweakValue("namespace");
                if(target === 1) { //Production data must have a valid namespace
                    if(!namespace) {
                        this.$root.showErrorNotification(this.$gettext("Form must have a valid namespace set when used to create production data. We suggest to create a short and meaningful namespace as it will appear inside variables tree view. Set namespace in the bottom right properties area."), true, true);
                        return false;
                    }
                }
                return true;
            },
            getFormItemStatus() {
                if(!this.$dynamicElements.isItemDeployed(this))
                    return {status: 0, message: this.$gettext("This form is draft")};
                else if(this.hasUndeployedChanges())
                    return {status: 2, message: this.$gettext("Form is active with a different configuration")};
                else
                    return {status: 1, message: this.$gettext("This form is active")};
            },
            importExcelCallback(file) {
                this.visualizationTweaks[0].value = file.name;
                this.saveTweaks();
                this.importExcel(file);
            },
            deleteTemplateCallback() {
                this.deleteExcel();
                this.visualizationTweaks[0].value = this.visualizationTweaks[0].default();
                this.saveTweaks();
            },
            deleteExcel() {
                this.reportTemplateName = null;
                this.reportTemplate = null;
                this.saveElement(false);
            },
            getNewerDescriptor() {
                let self = this;
                let formVariablesCopy = [...self.scope().formVariables];

                self.$dynamicElements.DescriptorForIndexAndVersion('user_formshistory', self.selectedVersion.internal_name, self.selectedVersion.version)
                    .then(item => {
                        const descriptor = (JSON.parse(item[0].descriptor));
                        try {
                            self.$dataEntry.unwrapEntity(self.entity, descriptor.formVariables, false);
                            if(this.scope().documentUpdateHandler)
                                formVariablesCopy[0].children = descriptor.formVariables
                            else
                                formVariablesCopy = descriptor.formVariables;
                            self.scope().formVariables = formVariablesCopy;
                            self.scope().version = self.selectedVersion.version;
                        } catch (e) {
                            console.log(e);
                        }
                    })
            },
            ruleValidation(ruleToValidate, document) {
                let self = this;
                return new Promise((resolve, reject) => {
                    self.$rules.callApiRule(ruleToValidate, document)
                        .then((result) => {
                            resolve(result);
                        })
                        .catch(err => {
                            reject(err);
                        })
                });
            }
        }
    }
</script>

<style scoped>
    .v-text-field >>> .v-counter {
        margin-top: 5px;
    }
</style>
