<template>
    <v-container style="min-width: 100%; max-width: 100vw; height: 100%;" ma-0 pa-2>
        <div ref="container"
             style="width: 100%; height:100%; max-height: 100%; margin: 0; padding:5px; overflow: hidden;">
        </div>
    </v-container>
</template>

<script>
    export default {
        name: "WizardContainer",
        data: () => {
            return {
            }
        },
        computed: {
            wizardToLoad() {
                return this.$route.params.routeId;
            },
        },
        props: {
        },
        watch: {
            wizardToLoad() {
                this.load();
            }
        },
        beforeMount: function () {
            this.load();
        },
        mounted() {
        },
        methods: {
            async load() {
                let self = this;
                this.$dynamicElements.LoadItem(this.wizardToLoad, "wizards")
                    .then(wizard => {
                        let latestDescriptor = JSON.parse(wizard.descriptor);
                        //Cannot edit a non-active form
                        if(latestDescriptor.properties.deployStatus < 1) {
                            self.$root.showErrorNotification(self.$gettext("Unable to open form {0} since it is not active").format(self.formToLoad), true);
                            return;
                        }
                        self.drawWizard(latestDescriptor);

                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            drawWizard(descriptor) {
                let currentWizardType = descriptor.properties.type;
                this.$nextTick(() => {
                    //Create wizard
                    this.currentWizard = this.$dynamicElements.createNew(currentWizardType, false);
                    if(!this.currentWizard)
                        return; //License issue or huge error (unlikely)
                    this.currentWizard.load(descriptor);
                    this.currentWizard.properties.showTitleBar = false;
                    this.$root.setCurrentPageTitle(this.currentWizard.properties.title);
                    this.$refs.container.insertBefore(this.currentWizard.$el, this.$refs.container.firstChild);
                });
            }
        }
    }
</script>

<style scoped>

</style>