<template>
    <FormBase @onVariableChange="onVariableChange($event)"/>
</template>

<script>

    import FormBase from '@/components/dynamic-elements/forms/FormBase.vue';
    import Transactions from "@/api/transactions";

    export default {
        name: "FormTransactions",
        extends: FormBase,
        components: {
            FormBase
        },
        data() {
            return {
                entityName: "",
                formNames: [],
                templateVariables: [],
                transactionProperties: {
                    type: 'tracking',
                    systemTargets: []
                },
                exposedName: ""
            }
        },
        computed: {},
        mounted() {
            let self = this;
            this.formConfigurationMode.requiresJoin = false;
            this.formConfigurationMode.requiresPrimaryKey = false;
            this.formConfigurationMode.requiresTargetEntity = true;
            this.formConfigurationMode.isEntity = false;
            this.formConfigurationMode.isTransaction = true;
            this.childHandlers.getDefaultName = function() { return "Transaction" };
            this.childHandlers.onNewElementCreated.push(this.onNewElement);
            this.childHandlers.onElementLoaded.push(this.onOpenElement);
            this.documentFormatHandler = this.buildTransactionDocument;
            this.documentSaveHandler = this.executeTransaction;
            this.documentUpdateHandler = true;
            this.validators.push({
                onDeploy: true,
                onChange: false,
                validator: this.checkValidTarget
            });

            this.visualizationTweaks.push({
                name: this.$gettext("Target Entity"),
                id: "targetEntity",
                type: "option",
                options: this.formNames,
                default: function () {
                    return null
                }
            });
            this.$dynamicElements.getMetadataList("forms", true, false,false, true, true)
                .then((forms) => {
                    forms.map(form => {
                        if(form.type >= self.$dynamicElements.FormEntityTypeRangeMin && form.type <= self.$dynamicElements.FormEntityTypeRangeMax) {
                            if(Array.isUseful(self.scope().transactionProperties.systemTargets)) {
                                if(self.scope().transactionProperties.systemTargets.includes(form.type))
                                    self.scope().formNames.push(form.name)
                            } else self.scope().formNames.push(form.name)
                        }
                    })
                });
        },
        watch: {
            visualizationTweaks: {
                handler: function () {
                    this.saveTweaks();
                },
                deep: true,
            }
        },
        methods: {
            checkValidTarget() {
                if(!this.getTweakValue("targetEntity")) {
                    this.$root.showErrorNotification(this.$gettext("You must choose Target Entity"), true, true);
                    return false;
                }
                return true;
            },
            async createTargetFields() {
                let self = this.scope();
                let entityName = this.getTweakValue('targetEntity');
                if (!entityName) {
                    this.$root.showErrorNotification(this.$gettext("You must choose Target Entity"), true, true);
                    return
                }
                this.$entities.getPrototype(entityName)
                    .then(async entity => {
                        if(!Array.isUseful(entity))
                            throw "Target entity not found";
                        self.variableKeys = entity[0].properties.filter(item => item.uniquenessLevel === "entity" || item.uniquenessLevel === "system");
                        let keyValues = await this.getValuesForTransactionsKey({nextKey: self.variableKeys[0].name, entityPrototype: entityName});
                        let i = 1;
                        self.variableKeys.map(item => {
                            item.inputMode = "Normal";
                            item.options = this.$route.params.asset? [] : keyValues;
                            item.children = [self.variableKeys[i]];
                            item.userExposed = true;
                            item.mandatory = true;
                            item.type = this.$route.params.asset ? "Text" : "Single option";
                            item.isTransaction = true;
                            item.transactionEntityName = entityName;
                            item.readonly = !!this.$route.params.asset;
                            if(self.Entity) {
                                item.value = self.Entity.EntityKeys[item.name];
                                item.readonly = true;
                            } else item.value = "";
                            i = i+1;
                        });
                        self.variableKeys[self.variableKeys.length - 1].children = self.formVariables;
                        if (self.$route.params.asset)
                            self.scope().fillVariables.push({ name: "assetId", value: self.$route.params.asset });

                        self.$set(self, "formVariables", self.variableKeys);
                    })
                    .catch(err => {
                        debugger;
                        console.error("Error retrieving target transaction entities");
                        self.$root.showErrorNotification(self.$gettext("An error occurred while opening form"), true);
                        self.$router.push("/home");
                    });
            },
            onVariableChange(event) {
                //TODO restore and verify to manage multiple keys case,
                //this function loads compatible values for next keys after we select a value for previous one
                //let self = this;
                /*let findObjectByValue = async function(obj) {
                    let keyValueArray = [{key: obj[0].name, value: obj[0].value}];
                    if(obj[0].name === event.title && Array.isUseful(obj[0].children)) {
                        obj[0].children[0].options = [];
                        if (!obj[0].children[0].transactionEntityName)
                            return null;
                        let keyValues = self.getValuesForTransactionsKey({
                            nextKey: obj[0].children[0].name,
                            entityPrototype: obj[0].transactionEntityName,
                            transactionKeysAndValues: keyValueArray});
                        obj[0].children[0].options = keyValues;

                        return null;
                    }
                    for(let i in obj[0]) {
                        if(i === "children" && obj[0].transactionEntityName) {
                            let foundValue = findObjectByValue(obj[0].children);
                            if(!foundValue)
                                return foundValue;
                        }
                    }
                    return null;
                };
                if(Array.isUseful(this.scope().variableKeys))
                    findObjectByValue(this.scope().variableKeys)*/
            },
            async getValuesForTransactionsKey(keyName) {
                try {
                    return await this.$entities.getKeyValueForKeys(null, keyName);
                } catch(err) {
                    debugger;
                    return []
                }
            },
            setAppropriateTweaks() {
                this.setTweakValue("target", 1002);
                for (let tweak of this.visualizationTweaks) {
                    tweak.hidden = !(tweak.id === 'targetEntity' || tweak.id === 'ImportTemplate');
                }
            },
            onOpenElement() {
                this.setAppropriateTweaks();
                if(!this.editMode)
                    this.createTargetFields();
            },
            onNewElement() {
                this.setAppropriateTweaks();
                this.formVariables = this.scope().templateVariables;
                this.$emit('dataItemsUpdated');
            },
            getTransactionVariables(variables) {
                let tempResult = [];
                if(variables) {
                    tempResult = tempResult.concat(variables.filter(variable => variable.isTransaction === true));
                    tempResult.filter(temp => {
                        if(temp.hasOwnProperty('children')) {
                            let tempChildResult = this.getTransactionVariables(temp.children);
                            tempResult = tempResult.concat(tempChildResult)
                        }
                    })
                }
                return tempResult
            },
            buildTransactionDocument(document) {
                let primaryKeyVariablesNameAndValue = [];
                let primaryKeyVariables = this.getTransactionVariables(this.variables);
                primaryKeyVariables.forEach(variable => { primaryKeyVariablesNameAndValue.push({name: variable.name, value: variable.value}) });
                let EntityKeys = { EntityName: Array.isUseful(primaryKeyVariables) ? primaryKeyVariables[0].transactionEntityName : "Empty" };
                primaryKeyVariables.forEach(item => {
                    //All keys must be stringified to avoid type conflicts in ES
                    EntityKeys[item.name] = item.value.toString()
                });
                document["EntityKeys"] = EntityKeys;
                // document.IsEntityEnabled = !this.scope().properties.disabled; //Must be true by construction
                // document.IsInstanceEnabled = document.IsEntityEnabled; //Must be true by construction
                document.IsEntityActive = this.properties.deployStatus > 0; //Must be true by construction
                //document.IsInstanceActive = this.scope().properties.isDeployed; //Must be true by construction
                document.TransactionProperties = this.transactionProperties;
                document.Result = this.$dataEntry.getFormResult(this.variables);
            },
            executeTransaction(document) {
                let self = this;
                return new Promise((resolve, reject) => {
                    Transactions.ExecuteTransaction(document, document.version > 1, self.exposedName)
                        .then(() => {
                            resolve()
                        })
                        .catch(err => {
                            this.$root.showErrorNotification(this.$gettext("An error occurred while saving item."), true, true);
                            reject(err)
                        })
                });
            }
        }
    }

</script>

<style scoped>
</style>
